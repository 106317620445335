import React, { ReactElement } from 'react';
import { flex, ml10, mr10 } from '@utility-styles';
import { Title } from '@clickhouse/click-ui';
import {
  BreadCrumbLink,
  Breadcrumb
} from 'src/components/Breadcrumbs/Breadcrumb';

interface BreadcrumbsProps {
  links: ReadonlyArray<BreadCrumbLink>;
}

export function BreadCrumbs({ links }: BreadcrumbsProps): ReactElement {
  if (links.length === 0) {
    throw new Error('Please provide at least one link');
  }

  const crumbs = links.map((link, idx) => {
    return (
      <React.Fragment key={`breadcrumb-${idx}`}>
        <Breadcrumb link={link} />
        {idx < links.length - 1 && <span css={[ml10, mr10]}>/</span>}
      </React.Fragment>
    );
  });

  return (
    <Title type="h2" size="sm" css={flex}>
      {crumbs}
    </Title>
  );
}

export default React.memo(BreadCrumbs);
