import { createToast } from '@clickhouse/click-ui';
import { UserDetailsUpdatedPayload } from '@cp/common/protocol/Account';
import { UserPreferences } from '@cp/common/protocol/Account';
import { useCallback } from 'react';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import { useUserStateManager } from 'src/user/userState';

export const useInitUserState = (): void => {
  const user = useCurrentUserOrThrow();
  const { updateUser } = useUserStateManager();

  useWebSocketsNotification<UserDetailsUpdatedPayload>({
    subscriptionDetails: {
      type: 'USER_DETAILS_UPDATED',
      objId: user.id
    },
    handler: ({ payload }) => {
      updateUser(payload.userDetails);
    },
    dependencies: [user.id]
  });
};
type UserControllerResponse = {
  updateName: (newName: string) => Promise<void>;
  changePasswordWithRedirect: () => void;
  updatePreferences: (newPreferences: Partial<UserPreferences>) => Promise<void>;
};
export function useUserController(): UserControllerResponse {
  const { user, updateUser } = useUserStateManager();
  const apiClient = useApiClient();
  const updateName = useCallback(
    async (newName: string): Promise<void> => {
      const isInvalidNameChange = newName === user.name || newName === '';
      if (isInvalidNameChange) {
        return;
      }
      try {
        await apiClient.updateUserDetails({ name: newName });
        updateUser({ name: newName });
        createToast({
          title: 'Name updated successfully'
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: errorMessage(e) ?? 'Could not update name'
        });
      }
    },
    [apiClient, user]
  );
  const updatePreferences = useCallback(
    async (newPreferences: Partial<UserPreferences>): Promise<void> => {
      const currentPreferences = user.preferences ?? {};
      const preferencesUpdate = { ...currentPreferences, ...newPreferences } as UserPreferences;
      if (preferencesUpdate.theme && ['dark', 'light'].includes(preferencesUpdate.theme)) {
        try {
          updateUser({ preferences: preferencesUpdate });
          await apiClient.updateUserDetails({ preferences: preferencesUpdate });
          createToast({
            title: 'Theme updated',
            type: 'success'
          });
        } catch (e) {
          createToast({
            title: 'Error',
            type: 'danger',
            description: errorMessage(e) ?? 'Could not update preferences'
          });
        }
      }
    },
    [apiClient, user]
  );

  /**
   * changePasswordWithRedirect cannot be async function
   * Safari popup blocker will block any call to window.open() inside an async call.
   * https://stackoverflow.com/a/39387533
   */
  const changePasswordWithRedirect = (): void => {
    const windowReference = window.open();
    try {
      if (!windowReference) {
        throw new Error('Unable to open the page. Please check your popup blocker.');
      }
      void apiClient.getChangePasswordWithRedirectUrl().then(function (url) {
        windowReference.location = url;
      });
    } catch (e) {
      createToast({
        title: 'Error',
        type: 'danger',
        description: errorMessage(e) ?? 'Unable to change password at this time'
      });
    }
  };

  return {
    updateName,
    changePasswordWithRedirect,
    updatePreferences
  };
}
