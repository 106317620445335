import {
  Button,
  ButtonProps,
  Container,
  Separator,
  Spacer,
  Tooltip
} from '@clickhouse/click-ui';
import {
  ActiveMaintenanceKind,
  InstanceState,
  InstanceTier,
  STATES_VALID_FOR_PASSWORD_RESET
} from '@cp/common/protocol/Instance';
import { canBillingStatusUpgradeInstance } from '@cp/common/protocol/Organization';
import { getMaintenanceWindows } from '@cp/common/utils/MaintenanceWindowUtils';
import capitalize from 'lodash/capitalize';
import { ReactElement, useEffect, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import {
  useCurrentInstance,
  useInstanceController,
  useSecondaryInstanceCount
} from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { useOrgFeature, useUserFeature } from 'src/lib/features';
import { SectionTitle } from 'src/lib/pageElements';
import {
  useCurrentOrgUserRole,
  useCurrentOrganizationOrThrow
} from 'src/organization/organizationState';
import { DeleteServiceModal } from 'src/pages/SettingsPage/DeleteServiceModal';
import { StartMigrationModal } from 'src/pages/SettingsPage/StartMigrationModal';
import { StopServiceModal } from 'src/pages/SettingsPage/StopServiceModal';
import { UpgradeDialog } from 'src/pages/SettingsPage/UpgradeDialog';
import { ResetPasswordModal } from 'src/pages/SettingsPage/ResetPasswordModal';
import { ResetDataWarehousePasswordModal } from 'src/pages/SettingsPage/ResetDataWarehousePasswordModal';

export interface ActionsSectionViewProps extends ActionSectionProps {
  state: InstanceState;
  canUpgradeInstance: boolean;
  hasManageServicePermission: boolean;
  instanceTier: InstanceTier;
  onStop: () => void;
  onStart: () => void;
  onDelete: () => void;
  onUpgrade: () => void;
  onResetPassword: () => void;
  onStartMigration: () => void;
  activeMaintenanceKind?: ActiveMaintenanceKind;
  isMaintenanceScheduledWhenStopped?: boolean;
  isPrimary: boolean;
  secondaryInstanceCount: number;
}

export const ActionsSectionView = ({
  state,
  canUpgradeInstance,
  hasManageServicePermission,
  instanceTier,
  onStop,
  onStart,
  onDelete,
  onUpgrade,
  onResetPassword,
  onStartMigration,
  activeMaintenanceKind,
  isMaintenanceScheduledWhenStopped,
  isPrimary,
  secondaryInstanceCount
}: ActionsSectionViewProps): ReactElement | null => {
  const isStartOrStopLoading = [
    'stopping',
    'starting',
    'provisioning',
    'awaking'
  ].includes(state);
  const isStopAction = ['running', 'idle'].includes(state);
  const isStartAction = ['stopped'].includes(state);
  const canPasswordBeReset =
    STATES_VALID_FOR_PASSWORD_RESET.includes(state) &&
    !activeMaintenanceKind &&
    isPrimary;
  const isInstanceDead =
    !isStartAction && !isStopAction && !['stopping'].includes(state);
  const isMaintenanceDisabled = isInstanceDead || !!activeMaintenanceKind;

  const hasDependentInstances = isPrimary && secondaryInstanceCount > 0;
  const label = isStartOrStopLoading
    ? capitalize(state)
    : isStopAction
    ? 'Stop'
    : 'Start';

  const startOrStopButtonProps: ButtonProps = {
    loading: isStartOrStopLoading,
    label,
    onClick: () => {
      isStartAction ? void onStart() : void onStop();
    },
    showLabelWithLoading: true
  };

  if (!hasManageServicePermission) {
    return null;
  }

  return (
    <Wrapper
      orientation="vertical"
      isResponsive={false}
      data-testid="actions-wrapper"
    >
      <Spacer size="sm" />
      <SectionTitle>Service actions</SectionTitle>
      <Spacer />
      <ButtonsWrapper gap="md" alignItems="start" isResponsive={false}>
        <Button
          label="Reset password"
          data-testid="reset-pwd-btn"
          disabled={!canPasswordBeReset}
          onClick={onResetPassword}
        />
        <StartOrStopButton
          {...startOrStopButtonProps}
          loading={isStartOrStopLoading}
          disabled={isInstanceDead || !!activeMaintenanceKind}
          data-testid={`${state}-service-btn`}
        />

        <Tooltip disabled={!hasDependentInstances}>
          <Tooltip.Trigger>
            <Button
              label="Delete"
              onClick={onDelete}
              type="danger"
              loading={state === 'terminating'}
              disabled={!!activeMaintenanceKind || hasDependentInstances}
              data-testid="delete-service-button"
            />
          </Tooltip.Trigger>
          <Tooltip.Content data-testid="delete-button-disabled-tooltip">
            Cannot delete the primary service while other services in the data
            warehouse exist
          </Tooltip.Content>
        </Tooltip>

        {instanceTier === 'Development' && (
          <>
            {' '}
            <Separator size="lg" orientation="vertical" />
            <Tooltip open={canUpgradeInstance ? false : undefined}>
              <Tooltip.Trigger>
                <Button
                  type="secondary"
                  label="Upgrade to Production"
                  disabled={!canUpgradeInstance}
                  onClick={onUpgrade}
                />
              </Tooltip.Trigger>
              <Tooltip.Content>
                Please contact support to enable this feature
              </Tooltip.Content>
            </Tooltip>
          </>
        )}
        {isMaintenanceScheduledWhenStopped && (
          <Button
            label="Perform maintenance"
            onClick={onStartMigration}
            disabled={isMaintenanceDisabled}
            data-testid="perform-maintenance-button"
          />
        )}
      </ButtonsWrapper>
      <Spacer />
    </Wrapper>
  );
};

const Wrapper = Container;
const ButtonsWrapper = Container;
const StartOrStopButton = Button;
interface ActionSectionProps {
  state: InstanceState;
}

export const ActionsSection = (): ReactElement | null => {
  const currentUserRole = useCurrentOrgUserRole();
  const hydraEnabled = useOrgFeature('FT_ORG_HYDRA_UI');
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    currentUserRole === 'ADMIN'
  );
  const instance = useCurrentInstance();
  const {
    instanceState: { instancePasswords }
  } = useInstanceStateManager();
  const {
    startInstance,
    stopInstance,
    deleteInstance,
    resetInstancePassword,
    upgradeInstance,
    startFullMaintenance
  } = useInstanceController();

  const isPrimary = instance?.isPrimary ?? false;
  const secondaryInstanceCount = useSecondaryInstanceCount();

  const currentOrganization = useCurrentOrganizationOrThrow();
  const isUpgradeInstanceFeatureActive = useUserFeature('FT_UPGRADE_INSTANCE');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [stopDialogOpen, setStopDialogOpen] = useState(false);
  const [startMigrationDialogOpen, setStartMigrationDialogOpen] =
    useState(false);
  const [isMaintenanceTriggered, setIsMaintenanceTriggered] = useState(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);

  // TODO(#9283):: Move isMaintenanceTriggered to InstanceState
  useEffect(() => {
    if (!instance?.activeMaintenanceKind) {
      setIsMaintenanceTriggered(false);
    }
  }, [instance?.activeMaintenanceKind]);

  if (!instance) {
    return null;
  }

  const activeMaintenanceKind: ActiveMaintenanceKind | undefined =
    isMaintenanceTriggered ? 'fullMaintenance' : instance.activeMaintenanceKind;

  const isInstanceStopped = instance.state === 'stopped';
  const canUpgradeInstance =
    instance.instanceTier === 'Development' &&
    hasManageServicePermission &&
    canBillingStatusUpgradeInstance(currentOrganization.billingStatus) &&
    isUpgradeInstanceFeatureActive;

  const windows = getMaintenanceWindows([instance]);
  const isMaintenanceScheduledWhenStopped =
    windows.length > 0 && windows[0].initiateWhenStopped;

  const handleStop = (): void => {
    void stopInstance({
      instanceId: instance.id,
      organizationId: instance.organizationId
    });
    setStopDialogOpen(false);
  };

  const handleStartMigration = (runAfterMaintenance = true): void => {
    void startFullMaintenance({
      instanceId: instance.id,
      organizationId: instance.organizationId,
      runAfterMaintenance: runAfterMaintenance
    });
    setIsMaintenanceTriggered(true);
    setStartMigrationDialogOpen(false);
  };

  const handleStart = (): void => {
    void startInstance({
      instanceId: instance.id,
      organizationId: instance.organizationId
    });
  };

  const handleDelete = (): void => {
    void deleteInstance({
      instanceId: instance.id,
      organizationId: instance.organizationId
    });
    setDeleteDialogOpen(false);
  };

  const handlePasswordReset = async (): Promise<void> => {
    await resetInstancePassword({
      instanceId: instance.id,
      organizationId: instance.organizationId
    });

    setResetSuccess(true);
  };

  const showDeleteDialog = (): void => {
    setDeleteDialogOpen(true);
  };

  const showResetPasswordDialog = (): void => {
    setResetSuccess(false);
    setResetPasswordDialogOpen(true);
  };

  const showStopDialog = (): void => {
    setStopDialogOpen(true);
  };

  const showStartMigrationDialog = (): void => {
    setStartMigrationDialogOpen(true);
  };

  const handleUpgrade = (): void => {
    void upgradeInstance({
      instanceId: instance.id,
      organizationId: instance.organizationId
    });
  };
  const showUpgradeDialog = (): void => {
    setUpgradeDialogOpen(true);
  };

  return (
    <>
      <DeleteServiceModal
        open={deleteDialogOpen}
        name={instance.name}
        onDelete={handleDelete}
        onCancel={(): void => {
          setDeleteDialogOpen(false);
        }}
        data-testid="delete-service-modal"
      />
      {hydraEnabled ? (
        <ResetDataWarehousePasswordModal
          onClose={(): void => setResetPasswordDialogOpen(false)}
          dataWarehouseId={instance.dataWarehouseId}
          open={resetPasswordDialogOpen}
        />
      ) : (
        <ResetPasswordModal
          data-testid="reset-password-modal"
          onClose={(): void => setResetPasswordDialogOpen(false)}
          onConfirm={handlePasswordReset}
          open={resetPasswordDialogOpen}
          password={instancePasswords[instance.id]}
          resetSuccess={resetSuccess}
          username={instance.dbUsername}
        />
      )}
      <StopServiceModal
        open={stopDialogOpen}
        instanceName={instance.name}
        onStop={handleStop}
        onCancel={(): void => setStopDialogOpen(false)}
        onStartMaintenance={(): void => {
          handleStartMigration(false);
          setStopDialogOpen(false);
        }}
        maintenanceWindow={windows[0]}
        data-testid="stop-modal"
      />

      <StartMigrationModal
        instanceName={instance.name}
        maintenanceWindow={windows[0]}
        open={startMigrationDialogOpen}
        onConfirm={(): void => handleStartMigration(!isInstanceStopped)}
        onCancel={(): void => setStartMigrationDialogOpen(false)}
        data-testid="start-migration-modal"
      />

      <UpgradeDialog
        open={upgradeDialogOpen}
        onCancel={(): void => setUpgradeDialogOpen(false)}
        onConfirm={handleUpgrade}
      />
      <ActionsSectionView
        state={instance.state}
        instanceTier={instance.instanceTier}
        canUpgradeInstance={canUpgradeInstance}
        hasManageServicePermission={hasManageServicePermission}
        onStart={handleStart}
        onStop={showStopDialog}
        onDelete={showDeleteDialog}
        onResetPassword={showResetPasswordDialog}
        onUpgrade={showUpgradeDialog}
        onStartMigration={showStartMigrationDialog}
        activeMaintenanceKind={activeMaintenanceKind}
        isMaintenanceScheduledWhenStopped={isMaintenanceScheduledWhenStopped}
        isPrimary={isPrimary}
        secondaryInstanceCount={secondaryInstanceCount}
      />
    </>
  );
};
