import React, { PropsWithChildren } from 'react';

import { Theme, css, useTheme } from '@emotion/react';

import Label from 'src/components/primitives/lib/Label';

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  title: string | JSX.Element;
  error?: string;
};

export const wrapperStyle = css`
  width: 100%;
`;

export const labelStyle = (color: string | null) => css`
  padding-bottom: 12px;
  ${color ? `color: ${color};` : ''}
`;

export const errorStyle = (theme: Theme) =>
  css({
    color: theme.colors.alert.text.danger,
    marginTop: '12px',
    fontSize: '12px'
  });

export function LabelWrappedInput({
  title,
  children,
  error,
  ...otherProps
}: PropsWithChildren<Props>) {
  const theme = useTheme();
  /*
    FIXME: this can be a source of issues, as it's not guaranteed that title is a string
   */
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const testid = `tableOption-${title}`;
  return (
    <div css={wrapperStyle} {...otherProps} data-testid={testid}>
      <Label
        text={title}
        css={labelStyle(error ? theme.colors.alert.text.danger : null)}
      />

      {children}

      {error && <div css={errorStyle}>{error}</div>}
    </div>
  );
}

export default React.memo(LabelWrappedInput);
