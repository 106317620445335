import { Container, Icon, Tooltip } from '@clickhouse/click-ui';
import {
  NOTIFICATION_CHANNEL_TYPES,
  NotificationChannelType
} from '@cp/common/protocol/NotificationInventory';
import { NotificationChannelSetting } from '@cp/common/protocol/Notifications';
import { memo } from 'react';
import { getHumanReadableChannelType } from 'src/pages/NotificationsPage/helpers';

type Props = {
  setting?: NotificationChannelSetting;
};

export const NotifyViaCell = memo(({ setting }: Props) => {
  if (!setting) {
    // TODO add skeleton if we have it
    return <>Loading...</>;
  }

  const channels = getChannels(setting);
  const isMixOfChannelsEnabled = calcIsMixOfChannelsEnabled(setting);

  if (channels.length === 0) {
    return '-';
  }

  const uniqueChannels = Array.from(new Set(channels));

  // Sort channels in reverse order to have 'UI, Email' instead of 'Email, UI'
  uniqueChannels.sort((a, b) => a.localeCompare(b) * -1);

  return (
    <Container gap="xs">
      <>{uniqueChannels.map(getHumanReadableChannelType).join(', ')}</>
      {isMixOfChannelsEnabled && (
        <Tooltip>
          <Tooltip.Trigger>
            <Icon name="info-in-circle" data-testid="different-setup-icon" />
          </Tooltip.Trigger>
          <Tooltip.Content>
            Not all services use the same notification methods
          </Tooltip.Content>
        </Tooltip>
      )}
    </Container>
  );
});

const calcIsMixOfChannelsEnabled = (
  setting: NotificationChannelSetting
): boolean => {
  if ('settingsByService' in setting && setting.type === 'service') {
    const services = Object.values(setting.settingsByService);
    const enabledChannels = new Set<NotificationChannelType>();
    let everyChannelEnabled = true;
    services.forEach((service) => {
      const channels = Object.keys(service).filter(
        (key) => service[key as NotificationChannelType]
      ) as NotificationChannelType[];
      if (channels.length !== NOTIFICATION_CHANNEL_TYPES.length) {
        everyChannelEnabled = false;
      }
      channels.forEach((channel) => enabledChannels.add(channel));
    });
    return !everyChannelEnabled && enabledChannels.size > 1;
  }

  return false;
};

const getChannels = (
  setting: NotificationChannelSetting
): NotificationChannelType[] => {
  const channels: NotificationChannelType[] = [];
  if ('settingsByService' in setting && setting.type === 'service') {
    const services = Object.values(setting.settingsByService);
    services.forEach((service) => {
      channels.push(
        ...(Object.keys(service).filter(
          (key) => service[key as NotificationChannelType]
        ) as NotificationChannelType[])
      );
    });
  } else if ('settings' in setting && setting.type === 'billing') {
    channels.push(
      ...(Object.keys(setting.settings).filter(
        (key) => setting.settings[key as NotificationChannelType]
      ) as NotificationChannelType[])
    );
  } else {
    console.error('Unsupported setting type', setting);
  }
  return channels;
};
