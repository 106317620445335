import { Container, Icon, Text, Tooltip } from '@clickhouse/click-ui';
import { UIUserNotification } from '@cp/common/protocol/Notifications';
import { getNotificationType } from '@cp/common/utils/NotificationsUtils';
import { ReactElement, memo } from 'react';
import { NotificationItemBody } from 'src/components/Notifications/NotificationItemBody';
import {
  getContainerTypeBySeverity,
  getIconNameBySeverity
} from 'src/components/Notifications/NotificationItemHelpers';
import {
  formatDateLongSmart,
  formatMoment
} from 'src/lib/formatters/dateTimeFormatter';
import styled from 'styled-components';

const RelatedTime = memo(({ date }: { date: number }) => {
  const d = new Date(date);
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Text color="muted" size="sm">
          {formatMoment(d)}
        </Text>
      </Tooltip.Trigger>
      <Tooltip.Content side="bottom">
        {formatDateLongSmart(d, true)}
      </Tooltip.Content>
    </Tooltip>
  );
});

const NotificationContainer = styled(Container)`
  background-color: ${({ theme, $isRead }): string =>
    $isRead
      ? theme.global.color.background.default
      : theme.global.color.background.muted};
`;

export const NotificationItem = memo(
  ({
    notification
  }: {
    notification: UIUserNotification;
  }): ReactElement | null => {
    const { name: NotificationName } = notification;
    const notificationType = getNotificationType(NotificationName);

    const iconName = getIconNameBySeverity(notificationType?.severity);

    const containerType = getContainerTypeBySeverity(
      notificationType?.severity
    );

    if (!notificationType) {
      return null;
    }

    return (
      <NotificationContainer
        padding="md"
        gap="sm"
        fillWidth={true}
        alignItems="start"
        $isRead={notification.isRead}
        data-testid="notification-item"
      >
        <Icon name={iconName} size="sm" state={containerType} />
        <Container orientation="vertical" gap="xxs" justifyContent="start">
          <NotificationItemBody notification={notification} />
          <RelatedTime date={notification.createdAt} />
        </Container>
      </NotificationContainer>
    );
  }
);
