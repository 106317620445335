import { convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { truthy } from '@cp/common/utils/Assert';
import { Instance, InstanceCloudProvider, InstanceTier } from '@cp/common/protocol/Instance';
import { REGION_BY_ID, RegionId } from '@cp/common/protocol/Region';
import { GB_PER_TB } from '@cp/common/protocol/Units';
import { useEffect, useState } from 'react';
import { findAll } from 'src/util/strapi';
import { NOT_FOUND } from '@cp/common/utils/HttpError';

export interface StrapiPriceObject {
  computeUnitPrice: number;
  storageUnitPrice: number;
  backupsUnitPrice: number;
}

/** Resolves region name string given the cloud provider and region id*/
const getRegionName = (provider: InstanceCloudProvider, region: RegionId): string | null => {
  if (provider === 'azure') {
    return null;
  }
  const regionInfo = REGION_BY_ID[region];
  let adjustedRegionId: string = regionInfo.id;
  if (provider === 'gcp') {
    adjustedRegionId = adjustedRegionId.replace('gcp-', '');
  }
  return `${regionInfo.name} (${adjustedRegionId})`;
};

type Region = (typeof REGION_BY_ID)[keyof typeof REGION_BY_ID];
type CloudProviderRegionId = Region['cloudProviderId'];
/** Model of the Strapi pricing data */
export type RegionPricingData = {
  region: string;
  regionId: CloudProviderRegionId;
  computePricing: { priceUSD: string };
  storagePricing: { priceUSD: string };
  devComputePricing: { priceUSD: string };
  devStoragePricing: { priceUSD: string };
  backupsPricing: { priceUSD: string };
};

/** Retrieves Strapi pricing data */
const fetchStrapiPricing = async (): Promise<RegionPricingData[]> => {
  try {
    const { data } = await findAll<RegionPricingData>('pricing-per-regions', {
      populate: ['storagePricing', 'computePricing', 'devStoragePricing', 'devComputePricing', 'backupsPricing'],
      fields: ['cloudProvider', 'region', 'hasDevService', 'regionId']
    });
    return data;
  } catch (e) {
    console.error(e);
    throw new Error('Unable to fetch strapi data');
  }
};

/** Model of pricing table. Pricing by instance id. */
export interface StrapiPricingTable {
  error: Error | undefined;
  isLoading: boolean;
  pricingTable: Map<string, StrapiPriceObject>;
}

export function useStrapiPricingByRegion(): Record<RegionId, RegionPricingData> {
  const [pricingData, setPricingData] = useState<RegionPricingData[]>([]);

  useEffect(() => {
    async function getPricingData(): Promise<void> {
      try {
        const result = await fetchStrapiPricing();
        setPricingData(result);
      } catch (e) {
        console.error(e);
      }
    }

    void getPricingData();
  }, []);

  const mapCloudProviderRegionIdToUnifiedConsoleRegionId = (
    regionIdAsPerCloudProvider: CloudProviderRegionId
  ): RegionId =>
    truthy(
      Object.values(REGION_BY_ID).find((r) => r.cloudProviderId === regionIdAsPerCloudProvider),
      `${NOT_FOUND}: couldn't find a region with cloudProviderId: ${regionIdAsPerCloudProvider}`
    ).id;

  const record = convertArrayToRecord(
    pricingData.map((row) => ({
      ...row,
      region: mapCloudProviderRegionIdToUnifiedConsoleRegionId(row.regionId),
      id: mapCloudProviderRegionIdToUnifiedConsoleRegionId(row.regionId)
    }))
  );
  return record;
}

/** Fetches the Strapi pricing info for the given instances. */
export function useStrapiPricingTable(instances: Instance[]): StrapiPricingTable {
  const [pricingTable, setPricingTable] = useState(new Map<string, StrapiPriceObject>());
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [pricingData, setPricingData] = useState<RegionPricingData[] | undefined>();

  // load strapi data
  useEffect(() => {
    async function getPricingData(): Promise<void> {
      try {
        const result = await fetchStrapiPricing();
        setPricingData(result);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        }
      }
    }

    setIsCalculating(true);
    void getPricingData();
  }, []);

  useEffect(() => {
    if (pricingData !== undefined) {
      const pricingMap = new Map<string, StrapiPriceObject>();
      for (const i of instances) {
        const selectedRegionPricing = pricingData.find(
          (x: RegionPricingData) => x.region === getRegionName(i.cloudProvider, i.regionId)
        );
        const strapiPriceObject = getPrice(i.instanceTier, selectedRegionPricing);
        if (strapiPriceObject !== undefined) {
          pricingMap.set(i.id, strapiPriceObject);
        }
      }
      setPricingTable(pricingMap);
      setIsCalculating(false);
    }
  }, [pricingData]);

  return { error, isLoading: isCalculating, pricingTable };
}

/** Gets the price of a region by instanceTier */
function getPrice(
  instanceTier: InstanceTier,
  selectedRegionPricing?: RegionPricingData
): StrapiPriceObject | undefined {
  if (selectedRegionPricing !== undefined) {
    switch (instanceTier) {
      case 'Production':
        return {
          computeUnitPrice: parseFloat(selectedRegionPricing.computePricing.priceUSD) / 24 / 60,
          storageUnitPrice: parseFloat(selectedRegionPricing.storagePricing.priceUSD) / GB_PER_TB / 730 / 60,
          // backupsUnitPrice: parseFloat(selectedRegionPricing.backupsPricing.priceUSD) / GB_PER_TB / 730 / 60
          backupsUnitPrice: 0
        };
      case 'Development':
        return {
          computeUnitPrice: parseFloat(selectedRegionPricing.devComputePricing.priceUSD) / 16 / 60,
          storageUnitPrice: parseFloat(selectedRegionPricing.devStoragePricing.priceUSD) / GB_PER_TB / 730 / 60,
          // backupsUnitPrice: parseFloat(selectedRegionPricing.backupsPricing.priceUSD) / GB_PER_TB / 730 / 60
          backupsUnitPrice: 0
        };
    }
  }
  return undefined;
}
