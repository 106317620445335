import { ReactElement, useState } from 'react';
import { Container, Select, Text } from '@clickhouse/click-ui';
import { TimePeriod } from '@cp/common/protocol/Metrics';

export type SelectableTimePeriod = Omit<TimePeriod, 'LAST_15_MINUTES'>;

export type TimePeriodLabel =
  | 'Last hour'
  | 'Last 24 hours'
  | 'Last week'
  | 'Last month'
  | 'Last year';

export const getTimePeriodLabel = (
  timePeriod: SelectableTimePeriod
): string => {
  switch (timePeriod) {
    case 'LAST_15_MINUTES': {
      return 'Last 15 minutes';
    }
    case 'LAST_HOUR': {
      return 'Last hour';
    }
    case 'LAST_DAY': {
      return 'Last 24 hours';
    }
    case 'LAST_WEEK': {
      return 'Last week';
    }
    case 'LAST_MONTH': {
      return 'Last month';
    }
    case 'LAST_YEAR': {
      return 'Last year';
    }
    default: {
      return '';
    }
  }
};

export const getTimePeriodSQLInterval = (
  timePeriod: SelectableTimePeriod
): string => {
  switch (timePeriod) {
    case 'LAST_HOUR': {
      return '1 HOUR';
    }
    case 'LAST_DAY': {
      return '24 HOUR';
    }
    case 'LAST_WEEK': {
      return '7 DAY';
    }
    case 'LAST_MONTH': {
      return '1 MONTH';
    }
    case 'LAST_YEAR': {
      return '1 YEAR';
    }
    default: {
      return '24 HOUR';
    }
  }
};

export const getAggregatePeriod = (
  timePeriod: SelectableTimePeriod
): string => {
  switch (timePeriod) {
    case 'LAST_HOUR': {
      return '30 SECOND';
    }
    case 'LAST_DAY': {
      return '5 MINUTE';
    }
    case 'LAST_WEEK': {
      return '1 HOUR';
    }
    case 'LAST_MONTH': {
      return '6 HOUR';
    }
    case 'LAST_YEAR': {
      return '1 DAY';
    }
    default: {
      return '5 MINUTE';
    }
  }
};

export const getSecondsInSQLInterval = (interval: string): number => {
  const secondsPerHour = 60 * 60;

  switch (interval) {
    case '1 HOUR': {
      return secondsPerHour;
    }
    case '24 HOUR': {
      return 24 * secondsPerHour;
    }
    case '7 DAY': {
      return 7 * 24 * secondsPerHour;
    }
    case '1 MONTH': {
      return 30 * 24 * secondsPerHour;
    }
    case '1 YEAR': {
      return 365 * 24 * secondsPerHour;
    }
    default: {
      return 24 * secondsPerHour;
    }
  }
};

export const getSecondsInAggregatePeriod = (period: string): number => {
  switch (period) {
    case '30 SECOND': {
      return 30;
    }
    case '5 MINUTE': {
      return 5 * 60;
    }
    case '1 HOUR': {
      return 60 * 60;
    }
    case '6 HOUR': {
      return 6 * 60 * 60;
    }
    case '1 DAY': {
      return 24 * 60 * 60;
    }
    default: {
      return 5 * 60;
    }
  }
};

const timePeriodsFull = [
  { label: 'Last hour', value: 'LAST_HOUR' },
  { label: 'Last 24 hours', value: 'LAST_DAY' },
  { label: 'Last week', value: 'LAST_WEEK' },
  { label: 'Last month', value: 'LAST_MONTH' },
  { label: 'Last year', value: 'LAST_YEAR' }
];

const timePeriodsCompact = [
  { label: 'Last hour', value: 'LAST_HOUR' },
  { label: 'Last 24 hrs', value: 'LAST_DAY' },
  { label: 'Last week', value: 'LAST_WEEK' },
  { label: 'Last month', value: 'LAST_MONTH' },
  { label: 'Last year', value: 'LAST_YEAR' }
];

interface TimeSelectProps {
  isCompact?: boolean;
  onSelect: (timePeriod: TimePeriod) => void;
  selectedPeriod?: SelectableTimePeriod;
  testId?: string;
}

export const TimeSelect = ({
  isCompact = false,
  onSelect,
  selectedPeriod = 'LAST_HOUR',
  testId = 'queryInsightsTimeSelect'
}: TimeSelectProps): ReactElement => {
  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<SelectableTimePeriod>(selectedPeriod);

  const handleSelect = (timePeriod: string): void => {
    setSelectedTimePeriod(timePeriod as SelectableTimePeriod);
    onSelect(timePeriod as TimePeriod);
  };

  const timePeriods = isCompact ? timePeriodsCompact : timePeriodsFull;

  return (
    <Container
      fillWidth={false}
      minWidth={isCompact ? '135px' : '275px'}
      gap="xs"
      justifyContent="end"
    >
      {!isCompact && (
        <Text color="default" weight="medium">
          Time period
        </Text>
      )}
      <Container
        maxWidth={isCompact ? '135px' : '175px'}
        data-testid="selected-period"
      >
        <Select
          value={selectedTimePeriod as string}
          onSelect={handleSelect}
          data-testid={testId}
        >
          {timePeriods.map((timePeriod) => {
            return (
              <Select.Item key={timePeriod.label} value={timePeriod.value}>
                {timePeriod.label}
              </Select.Item>
            );
          })}
        </Select>
      </Container>
    </Container>
  );
};
