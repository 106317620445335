import { css } from '@emotion/react';

export default {
  applyButton: css`
    align-self: flex-end;
  `,
  container: css`
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: flex-start;
    max-height: inherit;
  `,
  header: css`
    margin-bottom: 10px;
    font-weight: 500;
    text-align: left;
    display: grid;
    grid-template-columns: 200px 200px 32px;
    justify-content: end;
    gap: 15px;
  `,
  headerColumn: css`
    margin-right: 10px;
    width: 200px;
  `,
  headerCriteria: css`
    margin-right: 10px;
    width: 200px;
  `,
  sortContainer: css`
    margin-bottom: 10px;
    width: fit-content;

    display: grid;
    grid-template-columns: 200px 200px auto;
    gap: 15px;
    align-items: center;
    will-change: scroll-position;
  `,
  footer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  `,
  footerSub: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  `,
  sortListContainer: css`
    display: flex;
    flex-flow: column nowrap;
    overflow: overlay;
    width: 100%;
  `
};
