import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { useOrgFeature } from 'src/lib/features';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';

export const DashboardsGuard = (): ReactElement => {
  const dashboardsIsEnabled = useOrgFeature('FT_ORG_DASHBOARDS');

  if (dashboardsIsEnabled) {
    return <Outlet />;
  }

  return <NotFoundPage />;
};
