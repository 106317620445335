import { Container, Icon } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { CreateNewService } from 'src/components/CreateNewService/CreateNewService';
import { ServiceDetails } from 'src/components/CreateNewService/ServiceDetails';
import {
  CreationStep,
  useCreateServiceForm
} from 'src/components/CreateNewService/useCreateService';
import { NetworkSettings } from 'src/components/CreateNewService/NetworkSettings/NetworkSettings';
import { useInstanceStateManager } from 'src/instance/instanceState';
import styled from 'styled-components';

const CustomContainer = styled(Container)<{ $hide: boolean }>`
  ${({ $hide }): string => ($hide ? 'display: none' : '')};
`;

const ServiceCreationStepper = ({
  type = 'default',
  onStepChange
}: {
  type?: 'default' | 'onboarding';
  onStepChange?: (step: CreationStep) => void;
}): ReactElement => {
  const { currentStep, setStepNext, serviceId, onCreateService } =
    useCreateServiceForm({ type, onStepChange });
  const {
    instanceState: { isCreatingInstance }
  } = useInstanceStateManager();

  return (
    <Container isResponsive={false} orientation="vertical">
      {(isCreatingInstance || currentStep === 'FINISHED') && (
        <Container
          isResponsive={false}
          orientation="vertical"
          fillHeight
          fillWidth
          grow="1"
          shrink="1"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
        >
          <Icon name="horizontal-loading" size="xxl" />
          {currentStep !== 'FINISHED' && 'Generating database credentials'}
        </Container>
      )}
      <CustomContainer $hide={isCreatingInstance ?? false}>
        {currentStep === 'CREATE_INSTANCE' && (
          <CreateNewService
            onCreateService={onCreateService}
            serviceName={type === 'onboarding' ? 'My First Service' : ''}
            type={type}
          />
        )}
        {currentStep === 'SECURITY' && (
          <NetworkSettings
            serviceId={serviceId}
            type={type}
            onSubmit={setStepNext}
          />
        )}
        {currentStep === 'SERVICE_DETAILS' && (
          <ServiceDetails
            serviceId={serviceId ?? ''}
            type={type}
            onSubmit={setStepNext}
          />
        )}
      </CustomContainer>
    </Container>
  );
};

export default ServiceCreationStepper;
