import {
  IconButton,
  Select,
  SelectOptionListItem,
  Text,
  TextField
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { LogFn } from 'src/lib/logger';
import { SchemaColumn } from 'shared/src/clickhouse/types';

import { getOperatorOptions } from 'src/components/TableView/DataView/Toolbar/Filter/operators';
import styles from 'src/components/TableView/DataView/Toolbar/Filter/styles';

type UpdateFilterHandler = (data: {
  column?: string;
  operator?: string;
  value?: string;
}) => void;

interface Props {
  column?: string;
  operator?: string;
  columns: Array<SchemaColumn>;
  value?: string;
  updateFilter: UpdateFilterHandler;
  removeFilter: () => void;
  logEvent: LogFn;
}

export default function FilterOption({
  column,
  operator = '=',
  removeFilter,
  columns,
  updateFilter,
  value,
  logEvent
}: Props): ReactElement {
  let columnObject: SchemaColumn | undefined;
  if (column) {
    columnObject = columns.find((col) => col.name === column);
  }
  const operators = getOperatorOptions(
    columnObject
  ) as Array<SelectOptionListItem>;

  const isValueInputEnabled = !['isNull', 'isNotNull'].includes(operator || '');

  return (
    <div css={styles.filterContainer}>
      <Select
        onSelect={(column: string): void => {
          logEvent('columnSelectorSelect');
          updateFilter({
            column
          });
        }}
        showSearch
        onFocus={(): void => logEvent('columnSelectorFocus', 'trigger')}
        onBlur={(): void => logEvent('columnSelectorBlur', 'trigger')}
        value={column}
        className="fs-exclude"
      >
        {columns.map((column) => (
          <Select.Item value={column.name} key={column.name}>
            <Text className="fs-exclude">{column.name}</Text>
            <Text data-hide-in-trigger color="muted">
              {column.type}
            </Text>
          </Select.Item>
        ))}
      </Select>
      <Select
        options={operators}
        onSelect={(operator: string): void => {
          logEvent('criteriaSelectorSelect');
          updateFilter({
            operator
          });
        }}
        onFocus={(): void => logEvent('criteriaSelectorFocus', 'trigger')}
        onBlur={(): void => logEvent('criteriaSelectorBlur', 'trigger')}
        value={operator}
      />
      <TextField
        disabled={!isValueInputEnabled}
        onChange={(value: string): void => {
          logEvent('valueInput', 'keypress');
          updateFilter({
            value
          });
        }}
        onFocus={(): void => logEvent('valueFocus', 'trigger')}
        onBlur={(): void => logEvent('valueBlur', 'trigger')}
        value={isValueInputEnabled ? value : ''}
        className="fs-exclude"
      />

      <IconButton
        size="sm"
        icon="cross"
        onClick={removeFilter}
        className="filter-close"
      />
    </div>
  );
}
