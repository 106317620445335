import {
  Alert,
  Button,
  Container,
  Link,
  Select,
  SelectOptionListItem,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { isDefined } from '@cp/common/protocol/Common';
import {
  idleTimeoutValuesMinutes,
  InstanceTier
} from '@cp/common/protocol/Instance';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { timeoutMinutesToString } from '@cp/common/utils/FormatUtils';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';
import { ReactElement, useEffect } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import {
  formatClusterMemoryAllocation,
  formatMemoryAllocation
} from 'src/instance/instance';
import { useInstanceAutoScalingController } from 'src/instance/instanceAutoScalingController';
import { useInstanceAutoScalingStateManager } from 'src/instance/instanceAutoScalingState';
import {
  useCurrentInstance,
  useSecondaryInstanceCount
} from 'src/instance/instanceController';
import {
  Section,
  SectionTitle,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import {
  useCurrentOrgUserRole,
  useCurrentOrganization,
  useOrganizationStateManager
} from 'src/organization/organizationState';
import { useServiceFeatureFlag } from 'src/state/service';
import styled from 'styled-components';

const CustomSubsection = styled(Subsection)`
  ${({ theme }) => `
  @container (max-width: ${theme.breakpoint.sizes.sm}) {
      flex-direction: column;
      [data-type="description"] {
        max-width: 100%;
      }
  }
      `}
`;
export interface MemorySubsectionProps {
  instanceId: string;
  minAutoscalingTotalMemory?: number;
  maxAutoscalingTotalMemory?: number;
  onMinMemoryChange: (v: number, instanceId: string) => void;
  onMaxMemoryChange: (v: number, instanceId: string) => void;
  onNumReplicasChange: (v: number, instanceId: string) => void;
  memoryOptions: Array<SelectOptionListItem>;
  onUpdateSettings?: (instanceId: string) => void;
  onCancelSettingsChanges?: (instanceId: string) => void;
  needsSaving?: boolean;
  instanceTier?: InstanceTier;
  numReplicas?: number;
  isLoading?: boolean;
  canBeScaled?: boolean;
  canBeHorizontallyScaled: boolean;
  maxAllowableReplicas: number;
  hasManageScalingConfigPermission: boolean;
  fixedScaling: boolean;
}

export const MemorySubsection = ({
  instanceId: instanceIdProp,
  instanceTier: instanceTierProp,
  isOrgByoc: isOrgByocProp
}: {
  instanceId?: string;
  instanceTier?: InstanceTier;
  isOrgByoc?: boolean;
}): ReactElement => {
  const hasManageScalingConfigPermission =
    useUserAndOrgRolesHasPermissionForInstance(
      'control-plane:service:manage-scaling-config',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const { autoScalingState } = useInstanceAutoScalingStateManager();
  const currentOrganization = useCurrentOrganization();
  let instanceId = useCurrentInstance()?.id || '';
  let instanceTier = useCurrentInstance()?.instanceTier;
  instanceId = instanceIdProp ?? instanceId;
  instanceTier = instanceTierProp ?? instanceTier;
  const isOrgByoc = isOrgByocProp ?? !!currentOrganization?.byocConfig;
  const instanceAutoScaling = autoScalingState?.[instanceId || ''];
  const isNewService =
    instanceId === 'newInstanceProd' || instanceId === 'newInstanceDev';
  const isHorizontalScalingEnabled = useServiceFeatureFlag(
    'FT_INSTANCE_HORIZONTAL_SCALING'
  );
  const maxAllowableReplicas = useCurrentInstance()?.maxAllowableReplicas ?? 20;
  const canBeHorizontallyScaled =
    isHorizontalScalingEnabled && instanceTier !== 'Development';
  const {
    updateMinMemory,
    updateMaxMemory,
    updateNumReplicas,
    saveMemoryAutoScalingSettings,
    cancelMemoryChanges
  } = useInstanceAutoScalingController();

  const handleMemorySavingChanges = (instanceId: string): void => {
    void saveMemoryAutoScalingSettings(instanceId, canBeHorizontallyScaled);
  };

  const handleNumReplicasChange = (v: number, instanceId: string): void => {
    void updateNumReplicas(v, v, instanceId);
  };

  const handleCancelChanges = (instanceId: string): void => {
    void cancelMemoryChanges(instanceId);
  };

  return (
    <MemorySubsectionView
      instanceId={instanceId}
      minAutoscalingTotalMemory={instanceAutoScaling?.minAutoScalingTotalMemory}
      maxAutoscalingTotalMemory={instanceAutoScaling?.maxAutoScalingTotalMemory}
      onMinMemoryChange={updateMinMemory}
      onMaxMemoryChange={updateMaxMemory}
      onNumReplicasChange={handleNumReplicasChange}
      memoryOptions={instanceAutoScaling?.memoryAutoScalingOptions || []}
      onUpdateSettings={handleMemorySavingChanges}
      onCancelSettingsChanges={handleCancelChanges}
      needsSaving={
        !isNewService &&
        (instanceAutoScaling?.memoryNeedsSaving ||
          instanceAutoScaling?.numReplicasNeedsSaving)
      }
      instanceTier={instanceTier}
      numReplicas={instanceAutoScaling?.minReplicas}
      isLoading={instanceAutoScaling?.isLoading}
      canBeScaled={instanceAutoScaling?.canBeScaled}
      canBeHorizontallyScaled={canBeHorizontallyScaled}
      maxAllowableReplicas={maxAllowableReplicas}
      hasManageScalingConfigPermission={hasManageScalingConfigPermission}
      fixedScaling={isOrgByoc}
    />
  );
};

function scalingDescription(
  minAutoscalingTotalMemory: number,
  numReplicas: number,
  maxAutoscalingTotalMemory: number,
  instanceTier: InstanceTier
): string {
  const minDescription = formatClusterMemoryAllocation(
    minAutoscalingTotalMemory,
    instanceTier,
    numReplicas
  );
  const maxDescription = formatClusterMemoryAllocation(
    maxAutoscalingTotalMemory,
    instanceTier,
    numReplicas
  );

  if (minAutoscalingTotalMemory === maxAutoscalingTotalMemory) {
    return `Your service will be pinned at ${minDescription}.`;
  }

  return `Your service will automatically scale between ${minDescription} and ${maxDescription} depending on your workload.`;
}

export const MemorySubsectionView = ({
  instanceId,
  minAutoscalingTotalMemory,
  maxAutoscalingTotalMemory,
  onMinMemoryChange,
  onMaxMemoryChange,
  onNumReplicasChange,
  memoryOptions,
  onUpdateSettings,
  onCancelSettingsChanges = (): void => {},
  needsSaving,
  instanceTier,
  numReplicas,
  isLoading,
  canBeScaled,
  canBeHorizontallyScaled = false,
  maxAllowableReplicas,
  hasManageScalingConfigPermission,
  fixedScaling
}: MemorySubsectionProps): ReactElement => {
  const memorySizePlaceholder = (
    memorySize: number | undefined
  ): string | undefined => {
    if (isLoading) {
      return 'Loading...';
    } else if (isDefined(memorySize) && isDefined(instanceTier)) {
      return formatMemoryAllocation(memorySize, instanceTier);
    }
  };

  const memorySizeValue = (
    memorySize: number | undefined
  ): string | undefined => {
    const optionSelected = memoryOptions.find(
      (option) => option.value === memorySize?.toString()
    );
    return optionSelected?.value;
  };

  const horizontalScalingOptions = (
    canBeHorizontallyScaled: boolean | undefined,
    numReplicas: number | undefined,
    maxAllowableReplicas: number
  ): Array<SelectOptionListItem> => {
    if (!numReplicas || !canBeHorizontallyScaled) {
      return [
        {
          label: numReplicas ? `${numReplicas} nodes` : 'Loading...',
          value: numReplicas?.toString() ?? '1'
        }
      ];
    }
    const options = Array(maxAllowableReplicas - 2)
      .fill(3)
      .map((x, y) => {
        return { label: `${x + y} nodes`, value: `${x + y}` };
      });
    return options;
  };

  return (
    <CustomSubsection justifyContent="space-between" data-tesid="sssss">
      <SubsectionDescription data-type="description">
        <SubsectionTitle>Service size</SubsectionTitle>
        <Spacer size="sm" />
        <Text color="muted">
          ClickHouse Cloud will automatically scale your total memory depending
          on your needs, use the controls below if you would like to override
          with precise limits.{' '}
          <Link
            href="https://clickhouse.com/docs/en/manage/scaling"
            target="_blank"
          >
            Learn more
          </Link>
        </Text>
      </SubsectionDescription>
      <SubsectionControls>
        <Container orientation="vertical" gap="lg">
          {!fixedScaling && (
            <>
              <Select
                label="Minimum vertical scaling (per node)"
                onSelect={(v): void =>
                  onMinMemoryChange(parseInt(v), instanceId)
                }
                placeholder={memorySizePlaceholder(minAutoscalingTotalMemory)}
                value={memorySizeValue(minAutoscalingTotalMemory)}
                data-testid="minimum-memory"
                disabled={!canBeScaled || !hasManageScalingConfigPermission}
                options={memoryOptions}
              />
              <Select
                label="Maximum vertical scaling (per node)"
                onSelect={(v): void =>
                  onMaxMemoryChange(parseInt(v), instanceId)
                }
                placeholder={memorySizePlaceholder(maxAutoscalingTotalMemory)}
                value={memorySizeValue(maxAutoscalingTotalMemory)}
                data-testid="maximum-memory"
                disabled={!canBeScaled || !hasManageScalingConfigPermission}
                options={memoryOptions}
              />
            </>
          )}
          {fixedScaling && (
            <Select
              label="Size (per node)"
              onSelect={(v): void => {
                onMinMemoryChange(parseInt(v), instanceId);
                onMaxMemoryChange(parseInt(v), instanceId);
              }}
              placeholder={memorySizePlaceholder(maxAutoscalingTotalMemory)}
              value={memorySizeValue(maxAutoscalingTotalMemory)}
              data-testid="fixed-memory"
              disabled={!canBeScaled || !hasManageScalingConfigPermission}
              options={memoryOptions}
            />
          )}
          <Container orientation="vertical" gap="xs">
            <Select
              label="Number of nodes"
              disabled={
                !canBeHorizontallyScaled ||
                !numReplicas ||
                !hasManageScalingConfigPermission
              }
              onSelect={(v): void =>
                onNumReplicasChange(parseInt(v), instanceId)
              }
              data-testid="replica-count"
              options={horizontalScalingOptions(
                canBeHorizontallyScaled,
                numReplicas,
                maxAllowableReplicas
              )}
              value={numReplicas?.toString() ?? '1'}
            ></Select>
            {!canBeHorizontallyScaled && (
              <Text size="sm" weight="medium">
                <Link
                  size="sm"
                  weight="medium"
                  href="mailto:support@clickhouse.com"
                >
                  Contact support
                </Link>{' '}
                to scale horizontally
              </Text>
            )}
          </Container>
        </Container>

        {canBeScaled &&
          minAutoscalingTotalMemory &&
          numReplicas &&
          maxAutoscalingTotalMemory &&
          instanceTier && (
            <>
              <Separator size="lg" />
              <ScalingDetailsMessageWrapper>
                {
                  <Text>
                    {scalingDescription(
                      minAutoscalingTotalMemory,
                      numReplicas,
                      maxAutoscalingTotalMemory,
                      instanceTier
                    )}
                  </Text>
                }
              </ScalingDetailsMessageWrapper>
            </>
          )}

        {needsSaving && onUpdateSettings && (
          <>
            <Spacer size="lg" />

            <Container gap="md">
              <Button
                label="Apply changes"
                onClick={(): void => onUpdateSettings(instanceId)}
                loading={isLoading}
                data-testid="apply-memory-changes"
              />

              <Button
                disabled={isLoading}
                type="secondary"
                label="Cancel"
                onClick={(): void => onCancelSettingsChanges(instanceId)}
              />
            </Container>
          </>
        )}
        {!canBeScaled && !isLoading && (
          <>
            <Spacer />
            <Alert
              state="info"
              text={
                <div>
                  Automatic scaling is only available for Production services.{' '}
                  <Link
                    size="sm"
                    href="https://clickhouse.com/docs/en/cloud/manage/service-types"
                    target="_blank"
                  >
                    Learn more
                  </Link>
                </div>
              }
            />
          </>
        )}
      </SubsectionControls>
    </CustomSubsection>
  );
};

const ScalingDetailsMessageWrapper = Container;

interface IdlingSubsectionProps {
  onIdleTimeoutChange: (v: string) => void;
}

export interface IdlingSubsctionViewProps {
  isIdlingEnabled: boolean | undefined;
  isIdlingControlDisabled: boolean | undefined;
  idleTimeout?: string;
  idleTimeoutOptions: Array<SelectOptionListItem>;
  onIdleTimeoutChange: (value: string) => void;
  onIdleTimeoutSave?: () => void;
  onCancelIdleTimeoutChanges?: () => void;
  needsSaving?: boolean;
  isLoading?: boolean;
  hasManageScalingConfigPermission: boolean;
  isPrimary: boolean;
  secondaryInstanceCount: number;
}

const IDLE_DISABLED = 0;
export const IdlingSubsectionView = ({
  isIdlingEnabled,
  idleTimeout,
  idleTimeoutOptions,
  onIdleTimeoutChange,
  onCancelIdleTimeoutChanges,
  onIdleTimeoutSave,
  needsSaving,
  isLoading,
  isIdlingControlDisabled,
  hasManageScalingConfigPermission,
  isPrimary,
  secondaryInstanceCount
}: IdlingSubsctionViewProps): ReactElement => (
  <Subsection justifyContent="space-between">
    <SubsectionDescription>
      <SubsectionTitle>Idling options</SubsectionTitle>
      <Spacer />
      <Text color="muted">
        After a period of inactivity, allow this service to idle. Idling
        services when inactive saves on costs. Services may take 20-30 seconds
        to resume after idling.{' '}
        <Link
          href="https://clickhouse.com/docs/en/manage/scaling#automatic-idling"
          target="_blank"
        >
          Learn more
        </Link>
      </Text>
    </SubsectionDescription>
    <SubsectionControls>
      <Select
        label="Minimum idle timeout"
        onSelect={onIdleTimeoutChange}
        value={
          isIdlingEnabled === false
            ? IDLE_DISABLED.toString()
            : idleTimeout?.toString()
        }
        disabled={
          !!isIdlingControlDisabled ||
          !hasManageScalingConfigPermission ||
          (isPrimary && secondaryInstanceCount !== 0)
        }
        options={idleTimeoutOptions}
        data-testid="idle-timeout"
      />
      {needsSaving && (
        <>
          <Spacer size="lg" />
          <Container gap="md">
            <Button
              label="Apply changes"
              onClick={onIdleTimeoutSave}
              loading={isLoading}
              data-testid="apply-idle-changes"
            />
            <Button
              disabled={isLoading}
              type="secondary"
              label="Cancel"
              onClick={onCancelIdleTimeoutChanges}
            />
          </Container>
        </>
      )}
    </SubsectionControls>
  </Subsection>
);

export const IdlingSubsection = ({
  instanceId: instanceIdProp
}: {
  instanceId?: string;
}): ReactElement => {
  const hasManageScalingConfigPermission =
    useUserAndOrgRolesHasPermissionForInstance(
      'control-plane:service:manage-scaling-config',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const { autoScalingState, updateInstanceIdleTimeout } =
    useInstanceAutoScalingStateManager();
  const { saveIdleTimeoutAutoScalingSettings, cancelIdleTimeoutChanges } =
    useInstanceAutoScalingController();

  const instance = useCurrentInstance();
  const isPrimary = instance?.isPrimary ?? false;
  const secondaryInstanceCount = useSecondaryInstanceCount();

  let instanceId = useCurrentInstance()?.id || '';
  instanceId = instanceIdProp ?? instanceId;

  const instanceAutoScaling = autoScalingState[instanceId || ''];
  const isNewService =
    instanceId === 'newInstanceProd' || instanceId === 'newInstanceDev';

  const idleTimeoutOptions = [
    {
      label: 'Disable Idling',
      value: IDLE_DISABLED.toString(),
      'data-testid': 'idle-timeout-option'
    },
    ...idleTimeoutValuesMinutes.map((value) => {
      const label = timeoutMinutesToString(value);
      const valueString = value.toString();
      return {
        value: valueString,
        label,
        'data-testid': 'idle-timeout-option'
      };
    })
  ];

  const handleIdleTimeoutChange = (value: string): void => {
    updateInstanceIdleTimeout({
      idleTimeoutMinutes: parseInt(value),
      instanceId: instanceId
    });
  };

  const handleIdleTimeoutSave = (): void => {
    void saveIdleTimeoutAutoScalingSettings(instanceId);
  };

  const handleCancelIdleTimeoutChanges = (): void => {
    void cancelIdleTimeoutChanges(instanceId);
  };

  return (
    <IdlingSubsectionView
      isIdlingEnabled={instanceAutoScaling?.enableIdleScaling}
      isIdlingControlDisabled={instanceAutoScaling?.isIdleControlDisabled}
      idleTimeout={instanceAutoScaling?.idleTimeoutMinutes?.toString()}
      idleTimeoutOptions={idleTimeoutOptions}
      onIdleTimeoutChange={handleIdleTimeoutChange}
      onIdleTimeoutSave={handleIdleTimeoutSave}
      onCancelIdleTimeoutChanges={handleCancelIdleTimeoutChanges}
      needsSaving={!isNewService && instanceAutoScaling?.idleTimeoutNeedsSaving}
      isLoading={instanceAutoScaling?.isLoading}
      hasManageScalingConfigPermission={hasManageScalingConfigPermission}
      isPrimary={isPrimary}
      secondaryInstanceCount={secondaryInstanceCount}
    />
  );
};

export type ScalingControlsViewProps = IdlingSubsectionProps;

export const ScalingControlsSection = (): ReactElement | null => {
  const instance = useCurrentInstance();
  const { organizations } = useOrganizationStateManager();
  const org = organizations[instance?.organizationId || ''];
  const isPayingOrg = isPayingStatus(org?.billingStatus);
  const isByocOrg = !!org && isOrgByoc(org);
  const isCustomValuesFeatureEnabled = useServiceFeatureFlag(
    'FT_CUSTOM_AUTOSCALE_VALUES'
  );
  const isDisableAutoscalingFeatureEnabled = useServiceFeatureFlag(
    'FT_DISABLE_AUTOSCALING'
  );

  const activeMaintenanceKind = instance?.activeMaintenanceKind;
  const { fetchAutoScalingSettings } = useInstanceAutoScalingController();

  useEffect(() => {
    if (!instance || !org) {
      return;
    }

    void fetchAutoScalingSettings(
      instance.id,
      instance.instanceTier,
      instance.customAutoscaleValues,
      instance.regionId,
      isCustomValuesFeatureEnabled,
      isPayingOrg,
      isByocOrg,
      isDisableAutoscalingFeatureEnabled,
      activeMaintenanceKind
    );
  }, [
    fetchAutoScalingSettings,
    instance,
    org,
    isCustomValuesFeatureEnabled,
    isPayingOrg,
    isDisableAutoscalingFeatureEnabled,
    activeMaintenanceKind
  ]);
  return (
    <Section>
      <Spacer />
      <SectionTitle>Scaling controls</SectionTitle>
      <Spacer />
      <MemorySubsection />
      <Spacer size="lg" />
      <IdlingSubsection />
      <Spacer />
    </Section>
  );
};
