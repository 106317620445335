import Papa, { ParseMeta, ParseResult } from 'papaparse';

import { CsvFormatType, RowPreview } from 'shared/src/dataLoading';

const MAX_PREVIEW_ROWS = 100;

function extractRows(rawRows: unknown[]): string[][] {
  const rowsArray: string[][] = [];

  rawRows.map((d: unknown) => {
    if (d && Array.isArray(d)) {
      rowsArray.push(d);
    } else if (d && typeof d === 'object') {
      rowsArray.push(Object.values(d));
    } else {
      console.error('Unexpected value');
    }
  });

  return rowsArray;
}
export async function parseCsvDataFromInput(
  data: string,
  formatType: CsvFormatType
): Promise<[RowPreview[][], ParseMeta]> {
  return await new Promise((resolve) => {
    let rowsArray: unknown[][] = [];
    const hasHeaders = formatType !== 'CSV';

    Papa.parse(data, {
      header: hasHeaders,
      skipEmptyLines: true,
      preview: MAX_PREVIEW_ROWS,
      complete: function (results: ParseResult<unknown>) {
        if (results.data.length === 0) {
          return [[], [], null];
        }

        rowsArray = [...extractRows(results.data)];

        for (const row of rowsArray) {
          for (const cell of row) {
            if (typeof cell !== 'string') {
              throw new Error(`Parsed a non string object: ${typeof cell}`);
            }
          }
        }
        if (formatType === 'CSVWithNamesAndTypes') {
          const [_types, ...rest] = rowsArray;
          rowsArray = rest;
        }

        resolve([rowsArray as RowPreview[][], results.meta]);
      }
    });
  });
}
