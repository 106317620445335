import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { Dashboard, DashboardObject } from 'shared/src/types/dashboard';

export const dashboardsAtom = atom<Record<string, Dashboard>>({});

interface EditingAtomFamilyArgs {
  id: string;
  init?: boolean;
}
export const editingDashboardFamily = atomFamily(
  ({ init }: EditingAtomFamilyArgs) => atom(init ?? false),
  (a, b) => a.id === b.id
);
export const selectedDashboardIdAtom = atom<string | null>(null);

export const editingObjectIdAtom = atom<string | null>(null);
export const editingObjectAtom = atom<DashboardObject | null>((get) => {
  const dashboard = get(selectedDashboardAtom);
  if (!dashboard) {
    return null;
  }
  return dashboard.config.dashboardObjects[get(editingObjectIdAtom) || ''];
});

type DashboardFlyoutType = 'filterConfig' | 'objectConfig';

const dashboardFlyoutOpenAtom = atom(false);
const dashboardFlyoutTypeAtom = atom<DashboardFlyoutType | null>(null);

export const selectedDashboardAtom = atom((get) => {
  const id = get(selectedDashboardIdAtom);
  if (!id) {
    return null;
  }
  return get(dashboardsAtom)[id];
});

const updateDashboardAtom = atom(null, (get, set, id: string, dashboard: Partial<Dashboard>) => {
  const dashboards = {
    ...get(dashboardsAtom)
  };
  const newDashboard = {
    ...(dashboards[id] || {}),
    ...dashboard
  };
  dashboards[id] = newDashboard;

  set(dashboardsAtom, dashboards);
});

export function useDashboard(id: string): Dashboard {
  const dashboards = useAtomValue(dashboardsAtom);
  return dashboards[id];
}

export function useDashboards(): Record<string, Dashboard> {
  return useAtomValue(dashboardsAtom);
}

export function useEditingDashboard(id: string): boolean {
  return useAtomValue(editingDashboardFamily({ id }));
}

export function useSetEditingDashboard(id: string): (editing: boolean) => void {
  return useSetAtom(editingDashboardFamily({ id }));
}

export function useSetSelectedDashboard(): (id: string) => void {
  return useSetAtom(selectedDashboardIdAtom);
}

export function useSelectedDashboard(): Dashboard | null {
  return useAtomValue(selectedDashboardAtom);
}

export function useUpdateDashboard(): (id: string, dashboard: Partial<Dashboard>) => void {
  return useSetAtom(updateDashboardAtom);
}

export function useDashboardFlyoutOpen(): boolean {
  return useAtomValue(dashboardFlyoutOpenAtom);
}

export function useSetDashboardFlyoutOpen(): (open: boolean) => void {
  return useSetAtom(dashboardFlyoutOpenAtom);
}

export function useDashboardFlyoutType(): DashboardFlyoutType | null {
  return useAtomValue(dashboardFlyoutTypeAtom);
}

export function useSetDashboardFlyoutType(): (type: DashboardFlyoutType | null) => void {
  return useSetAtom(dashboardFlyoutTypeAtom);
}

export function useCloseDashboardFlyout(): () => void {
  const setFlyoutOpen = useSetAtom(dashboardFlyoutOpenAtom);
  const setFlyoutType = useSetAtom(dashboardFlyoutTypeAtom);
  const setEditingObjectId = useSetAtom(editingObjectIdAtom);
  return () => {
    setFlyoutOpen(false);
    setFlyoutType(null);
    setEditingObjectId(null);
  };
}

export function useEditingDashboardObject(): DashboardObject | null {
  return useAtomValue(editingObjectAtom);
}

export function useSetEditingObjectId(): (id: string | null) => void {
  return useSetAtom(editingObjectIdAtom);
}
