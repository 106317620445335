import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactNode } from 'react';

interface ConfirmRemoveEndpointFromServiceProps {
  endpointId: string | undefined;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ConfirmRemoveEndpointFromService({
  endpointId,
  onClose,
  onConfirm
}: ConfirmRemoveEndpointFromServiceProps): ReactNode {
  return (
    <Dialog open={endpointId !== undefined}>
      <Dialog.Content
        title="Remove private endpoint?"
        data-testid="remove-private-endpoint-from-service-modal"
        showClose
        onClose={onClose}
      >
        <Text color="muted">
          This service will no longer be accessible from this private endpoint.
        </Text>
        <Spacer />
        <Separator size="xs" />
        <Spacer />
        <Container justifyContent="end" isResponsive={false} gap="sm">
          <Button
            label="Cancel"
            onClick={onClose}
            data-testid="remove-private-endpoint-from-service-close-button"
            type="secondary"
          />
          <Button
            label="Remove endpoint"
            onClick={onConfirm}
            data-testid="remove-private-endpoint-from-service-confirm-button"
            type="danger"
          />
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}
