import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { useGptConsentModal } from 'src/lib/gpt/GptConsentModalHook';
import { useGptConsent } from 'src/lib/gpt/GptConsentHook';
import {
  Button,
  Container,
  Dialog,
  Link,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

function GptConsentModal(): ReactElement | null {
  const { isGptConsentModalOpen, hideGptConsentModal } = useGptConsentModal();
  const { acceptConsent } = useGptConsent();
  const role = useCurrentOrgUserRole();
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    role === 'ADMIN'
  );

  if (isGptConsentModalOpen !== true) {
    return null;
  }

  const handleAccept = async (): Promise<void> => {
    await acceptConsent(true);
    hideGptConsentModal(true);
  };

  return (
    <Dialog open={isGptConsentModalOpen}>
      <Dialog.Content
        title={
          hasManageServicePermission
            ? 'Enable Generative AI features?'
            : 'Generative AI features'
        }
        showClose={!hasManageServicePermission}
        onClose={
          hasManageServicePermission
            ? undefined
            : (): void => hideGptConsentModal(false)
        }
        data-testid="gptConsentDialog"
      >
        <Text>
          ClickHouse Cloud now supports a suite of non-production features that
          use LLM providers such as OpenAI and Amazon Bedrock to help you build
          or fix queries. When you input a prompt, ClickHouse Cloud will
          generate and send a request to the LLM that includes both your prompt
          and database schema. The LLM will not receive access to any data
          stored in your database. More information can be found in the privacy
          policies of{' '}
          <Link
            href="https://openai.com/enterprise-privacy"
            weight="medium"
            icon="popout"
            target="_blank"
          >
            OpenAI
          </Link>{' '}
          and{' '}
          <Link
            href="https://docs.aws.amazon.com/bedrock/latest/userguide/data-protection.html"
            weight="medium"
            icon="popout"
            target="_blank"
          >
            Bedrock
          </Link>
          .
        </Text>
        {!hasManageServicePermission && (
          <>
            <Spacer size="md" />
            <Text>
              Please contact your organization's admin to enable these features.
            </Text>
          </>
        )}
        <Spacer />
        <Separator size="xs" />
        <Spacer />
        <Container justifyContent="end" gap="xs">
          {hasManageServicePermission ? (
            <>
              <Button
                label="No thanks"
                onClick={(): void => hideGptConsentModal(false)}
                data-testid="setup-mfa-cancel-button"
                type="secondary"
              />
              <Button
                label="I agree"
                onClick={(): void => {
                  handleAccept().catch(console.error);
                }}
                data-testid="setup-mfa-cancel-button"
                type="primary"
              />
            </>
          ) : (
            <>
              <Button
                label="Close"
                onClick={(): void => hideGptConsentModal(false)}
                data-testid="setup-mfa-cancel-button"
                type="primary"
              />
            </>
          )}
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

export default GptConsentModal;
