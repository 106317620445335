import { useMemo } from 'react';

import { StatementRange, statementRanges } from 'src/lib/sql/parse/statementRanges';

import { useQuery } from 'src/lib/query/QueryState/';

export function useQueryErrorRegion(runId: string | undefined, queryText: string): undefined | StatementRange {
  const query = useQuery(runId);

  const queryArgs = query?.args;
  const queryError = query?.result.error;

  return useMemo(() => {
    if (
      !queryArgs ||
      queryArgs.query !== queryText ||
      queryArgs.runId !== runId ||
      typeof queryError?.context.statementIndex !== 'number'
    ) {
      return;
    }

    const runQueryText = queryArgs.query;
    const statementIndex = queryError.context.statementIndex;

    const ranges = statementRanges(runQueryText, {});
    return ranges[statementIndex];
  }, [queryArgs, queryError, queryText, runId]);
}
