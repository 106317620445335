import { createToast } from 'primitives';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AlertBanner from 'src/components/AlertBanner';
import ServiceSidebar from 'src/layout/ServiceSidebar';
import InstanceTerminatingDialog from 'src/components/ServiceDeletedDialog/ServiceDeletedDialog';
import TrialServiceLimitReachedModal from 'src/components/ServiceLimitReachedModal/TrialServiceLimitReachedModal';
import { Container } from '@clickhouse/click-ui';

import {
  useCurrentInstance,
  useIsInstanceTerminating
} from 'src/instance/instanceController';
import { ResponsiveWrapper } from 'src/layout/SidebarWrapper/SidebarWrapper';
import NonTrialServiceLimitReachedModal from 'src/components/ServiceLimitReachedModal/NonTrialServiceLimitReachedModal';
import styled from 'styled-components';

const NotificationContainer = styled(Container)`
  position: absolute;
  width: fit-content;
`;
const RelativeContainer = styled(Container)`
  position: relative;
`;

function ServiceLayout({ children }: { children?: ReactNode }): ReactElement {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [notificationContainerRef, setNotificationContainerRef] =
    useState<HTMLDivElement | null>(null);

  const isInstanceTerminating = useIsInstanceTerminating();
  const instance = useCurrentInstance();
  const [maintenanceCompleted, setMaintenanceCompleted] = useState(false);

  useEffect(() => {
    if (instance?.activeMaintenanceKind && !maintenanceCompleted) {
      setMaintenanceCompleted(true);
    } else if (!instance?.activeMaintenanceKind && maintenanceCompleted) {
      createToast('Maintenance completed', 'success', null);
      setMaintenanceCompleted(false);
    }
  }, [
    instance?.activeMaintenanceKind,
    maintenanceCompleted,
    setMaintenanceCompleted
  ]);

  return (
    <Container
      orientation="vertical"
      isResponsive={false}
      overflow="hidden"
      fillHeight
    >
      <AlertBanner />
      <ResponsiveWrapper ref={setRef}>
        <InstanceTerminatingDialog
          open={isInstanceTerminating}
          serviceName={instance?.name ?? ''}
        />

        <RelativeContainer fillHeight>
          <Container
            overflow="hidden"
            grow="1"
            fillHeight
            isResponsive={false}
            alignItems="start"
          >
            {!children && <Outlet />}
            {children && <>{children}</>}
          </Container>
          <NotificationContainer fillHeight ref={setNotificationContainerRef} />
        </RelativeContainer>
        <ServiceSidebar
          container={ref}
          notificationContainer={notificationContainerRef}
        />

        <TrialServiceLimitReachedModal />
        <NonTrialServiceLimitReachedModal />
      </ResponsiveWrapper>
    </Container>
  );
}

export default ServiceLayout;
