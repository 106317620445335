import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import take from 'lodash/take';

const MAX_ROWS = 5000;
export function useDataLimit(data) {
  return useMemo(() => {
    return take(data, MAX_ROWS);
  }, [data]);
}

export function useResizeListener(labels) {
  const rootRef = useRef();
  const headerRef = useRef();
  const footerRef = useRef();
  const [height, setHeight] = useState('100%');
  const [width, setWidth] = useState('100%');

  const onResize = useCallback(() => {
    setTimeout(() => {
      if (rootRef.current && headerRef.current && footerRef.current) {
        const newHeight =
          rootRef.current.getBoundingClientRect().height -
          (headerRef.current.getBoundingClientRect().height +
            footerRef.current.getBoundingClientRect().height);
        const newWidth = rootRef.current.getBoundingClientRect().width;
        if (newHeight !== height) {
          setHeight(newHeight);
        }
        if (newWidth !== width) {
          setWidth(newWidth);
        }
      }
    });
  }, [...labels, rootRef, headerRef, footerRef]);

  useEffect(() => {
    if (rootRef.current && headerRef.current && footerRef.current) {
      const newHeight =
        rootRef.current.getBoundingClientRect().height -
        (headerRef.current.getBoundingClientRect().height +
          footerRef.current.getBoundingClientRect().height);
      const newWidth = rootRef.current.getBoundingClientRect().width;
      setHeight(newHeight);
      setWidth(newWidth);
    }
  }, []);

  useEffect(() => {
    onResize();
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [...labels, rootRef, headerRef, footerRef]);

  return [height, rootRef, headerRef, footerRef, onResize, width];
}

export function useVerticalInterval(data, width) {
  const [interval, setInterval] = useState(0);

  useEffect(() => {
    const itemsCanBeFit = Math.round(width / 30);
    const totalItems = data.length;
    const newInterval =
      itemsCanBeFit >= totalItems ? 0 : Math.round(totalItems / itemsCanBeFit);
    setInterval(newInterval);
  }, [data, width]);

  return interval;
}

export function useHorizontalInterval(data, height) {
  const [interval, setInterval] = useState(0);

  useEffect(() => {
    const itemsCanBeFit = Math.round(height / 12);
    const totalItems = data.length;
    const newInterval =
      itemsCanBeFit >= totalItems ? 0 : Math.round(totalItems / itemsCanBeFit);
    setInterval(newInterval);
  }, [data, height]);

  return interval;
}

export function useAnimate(data) {
  const [animate, setAnimate] = useState(false);
  const timeOut = useRef();

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    setAnimate(true);
    timeOut.current = setTimeout(() => {
      setAnimate(false);
    }, 2391);
  }, [data]);

  return animate;
}

export function useXAxisHeight(
  id,
  xAxisLabel,
  xAxis,
  xAxisTickVertical,
  ...recalculate
) {
  const [height, setHeight] = useState(15);
  const [yOffset, setYOffset] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      let height = 15;
      if (id && document.getElementById(id) && xAxis) {
        const group = document
          .getElementById(id)
          .querySelector('.recharts-xAxis > .recharts-cartesian-axis-ticks');
        if (group) {
          height = group.getBBox().height;
          if (xAxisTickVertical) {
            const x = group.getBBox().x;
            if (x < 0 && Math.abs(x) >= yOffset) {
              setYOffset(Math.abs(x));
            }
          } else {
            setYOffset(0);
          }
        }
      }

      if (typeof xAxisLabel === 'string' && xAxisLabel.trim().length > 0) {
        height = height + 20;
      }

      setHeight(height);
    });
  }, [id, xAxisLabel, xAxis, xAxisTickVertical, ...recalculate]);

  return [height, yOffset];
}

export function useYAxisWidth(id, yAxisLabel, ...recalculate) {
  const [width, setWidth] = useState(20);
  useEffect(() => {
    setTimeout(() => {
      let width = 20;
      if (id && document.getElementById(id)) {
        const group = document
          .getElementById(id)
          .querySelector('.recharts-yAxis > .recharts-cartesian-axis-ticks');
        if (group) {
          width = group.getBBox().width;
        }
      }

      if (typeof yAxisLabel === 'string' && yAxisLabel.trim().length > 0) {
        width = width + 27;
      } else {
        width = width + 5;
      }

      setWidth(width);
    });
  }, [id, yAxisLabel, ...recalculate]);

  return width;
}
