// @ts-nocheck
import {
  advancedTabsContainerStyle,
  avancedContentStyle,
  flyoutBodyStyle,
  formElementStyle,
  generalTabsContentStyle,
  tabStyle
} from 'src/components/ChartConfigSidebar/styles';

import {
  Container,
  Flyout,
  FullWidthTabs,
  NumberField,
  Select,
  Separator,
  Switch,
  TextField,
  Tooltip
} from '@clickhouse/click-ui';

import { css } from '@emotion/react';
import { ReactElement, useEffect, useMemo, useState } from 'react';

import { useChartData } from 'src/lib/chart';
import { appearanceConfig, chartMap } from 'src/lib/chart/config';
import { ChartConfig } from 'src/lib/chart/types';
import { flex } from 'src/lib/utility-styles';
import { logEvent } from 'src/components/ChartConfigSidebar/analytics';
import ConfigRangeList from 'src/components/ChartConfigSidebar/ConfigRangeList';
import FormElement from 'src/components/ChartConfigSidebar/FormElement';
import GeneralChartConfig from 'src/components/ChartConfigSidebar/GeneralChartConfig';

interface ChartConfigSidebarProps {
  chartConfig: ChartConfig;
  close: () => void;
  name: string;
  portalId: string;
  runId?: string;
  updateChart: (config: ChartConfig) => void;
  open?: boolean;
  container?: HTMLElement | null;
}

const ChartConfigSidebar = ({
  open = false,
  chartConfig,
  close,
  portalId,
  runId,
  updateChart,
  container
}: ChartConfigSidebarProps): ReactElement => {
  const type =
    typeof chartConfig.chartType === 'string' ? chartConfig.chartType : 'bar';
  const [selectedTab, setSelectedTab] = useState<string>('general');

  const data = useChartData({
    runId,
    returnData: true
  });

  const chartAppearanceConfig = useMemo(() => {
    return appearanceConfig({
      ...chartConfig,
      data,
      category: 'chart',
      chartType: type
    });
  }, [chartConfig, data, type]);

  const advancedSettings = useMemo(() => {
    if (chartMap[type] && chartMap[type].appearance) {
      return Object.entries({
        ...chartMap[type].appearance,
        ...chartAppearanceConfig
      }).map(([key, config], index) => {
        const eventType = config.event || key;
        switch (config.type) {
          case 'string': {
            return (
              <FormElement isFirst={index === 0} key={key}>
                <TextField
                  label={config.label}
                  value={chartConfig[key]}
                  onChange={(value: string): void => {
                    const config = { ...chartConfig };
                    config[key] = value;
                    updateChart(config);
                    logEvent(`${eventType}Input`);
                  }}
                  onBlur={(): void => {
                    logEvent(`${eventType}Blur`, 'trigger');
                  }}
                  onFocus={(): void => {
                    logEvent(`${eventType}Focus`, 'trigger');
                  }}
                  className="fs-exclude"
                />
              </FormElement>
            );
          }
          case 'number': {
            return (
              <FormElement isFirst={index === 0} key={key}>
                <NumberField
                  label={config.label}
                  onChange={(value: string): void => {
                    const config = { ...chartConfig };
                    config[key] = value;
                    updateChart(config);
                    logEvent(`${eventType}Input`);
                  }}
                  onBlur={(): void => {
                    logEvent(`${eventType}Blur`, 'trigger');
                  }}
                  onFocus={(): void => {
                    logEvent(`${eventType}Focus`, 'trigger');
                  }}
                  type={config.type === 'number' ? 'number' : 'text'}
                  value={chartConfig[key]}
                  event={eventType}
                  className="fs-exclude"
                />
              </FormElement>
            );
          }
          case 'select': {
            return (
              <FormElement isFirst={index === 0} key={key}>
                <Select
                  label={config.label}
                  onSelect={(value: string): void => {
                    logEvent(`${eventType}Input`, 'click');
                    const config = { ...chartConfig };
                    config[key] = value;
                    updateChart(config);
                  }}
                  onBlur={(): void => logEvent(`${eventType}Blur`, 'trigger')}
                  onClick={(): void => logEvent(`${eventType}Focus`, 'trigger')}
                  options={config.options}
                  value={chartConfig[key]}
                  className="fs-exclude"
                />
              </FormElement>
            );
          }
          case 'boolean': {
            return (
              <FormElement isFirst={index === 0} key={key}>
                <Switch
                  label={config.label}
                  orientation="horizontal"
                  dir="start"
                  onCheckedChange={(value: boolean): void => {
                    logEvent(`${eventType}Toggle${value ? 'On' : 'Off'}`);
                    const config = { ...chartConfig };
                    config[key] = value;
                    updateChart(config);
                  }}
                  checked={chartConfig[key]}
                  className="fs-exclude"
                />
              </FormElement>
            );
          }
          case 'rangeList': {
            return (
              <FormElement isFirst={index === 0} key={key}>
                <ConfigRangeList
                  fieldConfig={config}
                  value={chartConfig[key]}
                  onChange={(value) => {
                    updateChart({
                      ...chartConfig,
                      [key]: value
                    });
                  }}
                  event={eventType}
                />
              </FormElement>
            );
          }
          default: {
            return null;
          }
        }
      });
    }
  }, [type, chartAppearanceConfig, chartConfig, updateChart]);

  // Remove any columns that have disappeared from the results
  useEffect(() => {
    if (data.columns && data.columns.length > 0) {
      const columnNames = data.columns.map((col) => col.name);
      const axes = [
        'categories',
        'dataKey',
        'values',
        'xAxis',
        'yAxis',
        'yValue',
        'zAxis'
      ];

      let newConfig = chartConfig;
      let anyRemoved = false;

      for (const axis of axes) {
        if (axis in chartConfig) {
          const val = chartConfig[axis];
          if (Array.isArray(val)) {
            const newVal = val.filter((member) => {
              if (typeof member === 'string') {
                return columnNames.includes(member);
              } else if ('column' in member) {
                return columnNames.includes(member.column);
              } else {
                return false;
              }
            });
            anyRemoved = anyRemoved || newVal.length < val.length;
            newConfig = { ...newConfig, [axis]: newVal };
          } else if (typeof val === 'string') {
            if (!columnNames.includes(val)) {
              newConfig = { ...newConfig, [axis]: null };
              anyRemoved = true;
            }
          } else if (val && typeof val === 'object' && 'column' in val) {
            if (!columnNames.includes(val.column)) {
              newConfig = { ...newConfig, [axis]: null };
              anyRemoved = true;
            }
          }
        }
      }

      if (anyRemoved) {
        updateChart(newConfig);
      }
    }
  }, [data.columns, chartConfig, updateChart]);

  const onClose = (): void => {
    close();
    logEvent('closeButtonClick', 'click');
  };

  return (
    <Flyout
      open={open}
      onOpenChange={(open): void => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Flyout.Content container={container} size="narrow">
        <Flyout.Header title="Chart details" />
        <Flyout.Body align="top" css={flyoutBodyStyle}>
          <FullWidthTabs
            defaultValue={selectedTab}
            css={css({ height: '100%' })}
            onValueChange={(selectedTab: string): void => {
              setSelectedTab(selectedTab);
              logEvent(`navigation${selectedTab}Select`, 'click');
            }}
          >
            <FullWidthTabs.TriggersList>
              <Tooltip side="bottom">
                <Tooltip.Trigger css={[flex, tabStyle]}>
                  <FullWidthTabs.Trigger value="general" css={tabStyle}>
                    General
                  </FullWidthTabs.Trigger>
                </Tooltip.Trigger>
                <Tooltip.Content side="bottom" align="end">
                  General chart config
                </Tooltip.Content>
              </Tooltip>
              <Tooltip side="bottom">
                <Tooltip.Trigger css={[flex, tabStyle]}>
                  <FullWidthTabs.Trigger
                    value="advanced"
                    css={tabStyle}
                    data-testid="chart-advanced-trigger"
                  >
                    Advanced
                  </FullWidthTabs.Trigger>
                </Tooltip.Trigger>
                <Tooltip.Content side="bottom" align="end">
                  Advance chart config
                </Tooltip.Content>
              </Tooltip>
            </FullWidthTabs.TriggersList>
            <FullWidthTabs.Content
              value="general"
              css={selectedTab === 'general' && generalTabsContentStyle}
            >
              <Container grow="1" padding="md" orientation="vertical">
                <GeneralChartConfig
                  chartConfig={chartConfig}
                  data={data}
                  loading={data.loading}
                  portalId={portalId}
                  type={type}
                  updateChart={updateChart}
                />
              </Container>
            </FullWidthTabs.Content>
            <FullWidthTabs.Content
              value="advanced"
              css={selectedTab === 'advanced' && advancedTabsContainerStyle}
            >
              <div className="content-container" css={avancedContentStyle}>
                <div css={formElementStyle} key="subtitle">
                  <TextField
                    label="Subtitle"
                    value={chartConfig && chartConfig.subtitle}
                    onChange={(value: string): void => {
                      const config = { ...chartConfig };
                      config.subtitle = value;
                      updateChart(config);
                      logEvent('subtitleInput');
                    }}
                    onBlur={(): void => {
                      logEvent('subtitleBlur', 'trigger');
                    }}
                    onFocus={(): void => {
                      logEvent('subtitleFocus', 'trigger');
                    }}
                    event="subtitle"
                  />
                </div>
                <Separator size="md" />
                {advancedSettings}
              </div>
            </FullWidthTabs.Content>
          </FullWidthTabs>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
};

export default ChartConfigSidebar;
