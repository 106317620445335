import { TextField } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { LogFn } from 'src/lib/logger';
import styled from 'styled-components';

interface Props {
  name: string;
  onChange: (e: string) => void;
  type: string;
  value: string;
  logEvent: LogFn;
}

const FieldHeader = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 0.5rem;
`;

export function Field({
  name,
  onChange,
  type,
  value,
  logEvent
}: Props): ReactElement {
  const label = (
    <FieldHeader>
      <span>{name}</span>
      <span>{type}</span>
    </FieldHeader>
  );
  return (
    <TextField
      label={label}
      onChange={(value: string): void => {
        onChange(value);
      }}
      onFocus={(): void => logEvent('inputFocus', 'trigger')}
      onBlur={(): void => logEvent('inputBlur', 'trigger')}
      value={value}
      placeholder="Enter a value"
    />
  );
}
