import { Row } from 'src/lib/query/RowCache/types';

export interface OnRangeChangeArgs {
  rows: Map<number, Row>;
  numRows: number;
  filterFinished: boolean;
}

export type OnRangeChangeCallback = (args: OnRangeChangeArgs) => void;

export interface ViewRangeParams {
  readonly onChange: OnRangeChangeCallback;
  readonly filter: string;
}

export interface ViewRangeData {
  readonly onChange: OnRangeChangeCallback;
  readonly filter: string;
  filteredRows?: Row[];
}

export interface Range {
  readonly start: number;
  readonly end: number; //inclusive
  readonly viewRangeData?: ViewRangeData;
}

function subtractRange(a: Range, b: Range): Range[] {
  if (b.start > a.end || b.end < a.start) {
    return [a];
  } else if (b.start > a.start && b.end < a.end) {
    return [
      {
        start: a.start,
        end: b.start - 1
      },
      {
        start: b.end + 1,
        end: a.end
      }
    ];
  } else if (b.start > a.start) {
    return [
      {
        start: a.start,
        end: b.start - 1
      }
    ];
  } else if (b.end < a.end) {
    return [
      {
        start: b.end + 1,
        end: a.end
      }
    ];
  } else {
    return [];
  }
}

function makeRange(start: number, end: number, viewParams?: ViewRangeParams): Range {
  if (viewParams) {
    return {
      start,
      end,
      viewRangeData: viewParams
    };
  } else {
    return { start, end };
  }
}

function updateRange(oldRange: Range, start: number, end: number, viewParams?: ViewRangeParams): Range {
  if (viewParams) {
    return {
      start,
      end,
      viewRangeData: {
        onChange: viewParams.onChange,
        filter: viewParams.filter,
        filteredRows: oldRange.viewRangeData?.filteredRows
      }
    };
  } else {
    return { start, end };
  }
}

export class RangeList {
  ranges: Range[];

  constructor() {
    this.ranges = [];
  }

  add(start: number, end: number, viewParams?: ViewRangeParams): Range {
    const range = makeRange(start, end, viewParams);
    this.ranges.push(range);
    return range;
  }

  remove(range: Range): void {
    this.ranges = this.ranges.filter((r) => r !== range);
  }

  update(range: Range, start: number, end: number, viewParams?: ViewRangeParams): Range {
    const index = this.ranges.indexOf(range);
    if (index !== -1) {
      const newRange = updateRange(range, start, end, viewParams);
      this.ranges[index] = newRange;
      return newRange;
    } else {
      throw new Error('updating invalid range!');
    }
  }

  subtract(otherRangeList: RangeList): RangeList {
    const newRanges = new RangeList();
    newRanges.ranges = this.ranges;
    otherRangeList.ranges.forEach((otherRange) => {
      newRanges.ranges = newRanges.ranges.flatMap((range) => {
        return subtractRange(range, otherRange);
      });
    });
    return newRanges;
  }

  copy(): RangeList {
    const newList = new RangeList();
    newList.ranges = [...this.ranges];
    return newList;
  }

  toString(): string {
    const rangeStrings = this.ranges.map(({ start, end }) => `${start}-${end}`);
    return rangeStrings.join(', ');
  }
}
