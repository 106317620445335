import { css, Theme } from '@emotion/react';

const labelStyle = (margin?: Margin) => (theme: Theme) =>
  css({
    color: theme.global.color.text.default,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    marginTop: margin?.t ? '10px' : 0,
    marginBottom: margin?.b ? '10px' : 0,
    marginLeft: margin?.l ? '10px' : 0,
    marginRight: margin?.r ? '10px' : 0
  });

interface Margin {
  t?: boolean;
  b?: boolean;
  l?: boolean;
  r?: boolean;
}

interface Props {
  text: string | JSX.Element;
  margin?: Margin;
  className?: string;
}

export default function Label({ text, margin, className }: Props) {
  return (
    <div css={labelStyle(margin)} className={`input-label ${className}`}>
      {text}
    </div>
  );
}
