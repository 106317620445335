import { useMemo, useRef } from 'react';

export interface Range {
  readonly min: number;
  readonly max: number;
}

type GraphDomainMin = number | 'dataMin' | 'auto';
type GraphDomainMax = number | 'dataMax' | 'auto';
type GraphDomain = [GraphDomainMin, GraphDomainMax];
type GraphDomainExplicit = [number, number];
export type MaybeRange = Range | undefined | null;

interface CollapsedRanges {
  readonly domains: GraphDomain[];
  readonly dataDomainIndexes: number[];
}

type Row = Record<string, number | string>;

function inputRangeToGraphDomain(range: MaybeRange): GraphDomain {
  if (!!range && typeof range.min === 'number' && typeof range.max === 'number') {
    return [range.min, range.max];
  } else {
    return ['auto', 'auto'];
  }
}

function graphDomainsEqual(d1: GraphDomain, d2: GraphDomain): boolean {
  return d1[0] === d2[0] && d1[1] === d2[1];
}

export function collapseRanges(ranges: MaybeRange[]): CollapsedRanges {
  const config: CollapsedRanges = {
    domains: [],
    dataDomainIndexes: []
  };

  for (const range of ranges) {
    const domain = inputRangeToGraphDomain(range);
    const existingIndex = config.domains.findIndex((existingDomain) => graphDomainsEqual(domain, existingDomain));
    if (existingIndex >= 0) {
      config.dataDomainIndexes.push(existingIndex);
    } else {
      config.domains.push(domain);
      config.dataDomainIndexes.push(config.domains.length - 1);
    }
  }

  return config;
}

function axesInDomain(ranges: CollapsedRanges, domainIndex: number) {
  const axes: number[] = [];
  ranges.dataDomainIndexes.forEach((axisDomainIndex, axisIndex) => {
    if (domainIndex === axisDomainIndex) {
      axes.push(axisIndex);
    }
  });
  return axes;
}

// if a range is ['dataMin', 'dataMax'], calculate it as per ENG-1896, ENG-2242
// every axis that is a member of that domain will have a domain ranging from the minimum to the
// maximum of all data in the columns for those axes, plus a margin. Range will also be enlarged
// to include zero, if necessary
function calculateUnsetNumericRanges(
  ranges: CollapsedRanges,
  data: Row[],
  axisColumns: AxisColumnDef[]
): CollapsedRanges {
  const axisRanges = axisColumns.map((axisDef) => {
    let min: number | undefined;
    let max: number | undefined;
    data.forEach((row) => {
      const val = +row[axisDef.column];
      if (min === undefined || val < min) {
        min = val;
      }
      if (max === undefined || val > max) {
        max = val;
      }
    });
    return [min, max];
  });

  const domains: GraphDomain[] = ranges.domains.map(([min, max], domainIndex) => {
    if (min === 'dataMin' || max === 'dataMax') {
      const domainAxes = axesInDomain(ranges, domainIndex);
      const [newMin, newMax] = domainAxes.reduce<GraphDomainExplicit>(
        ([oldMin, oldMax], axisIndex) => {
          const [axisMin, axisMax] = axisRanges[axisIndex];
          if (axisMin === undefined || axisMax === undefined) {
            return [oldMin, oldMax];
          } else {
            return [Math.min(axisMin * 1.1, oldMin), Math.max(axisMax * 1.1, oldMax)];
          }
        },
        [0, 0]
      );
      return [newMin, newMax];
    } else {
      return [min, max];
    }
  });

  return {
    domains,
    dataDomainIndexes: ranges.dataDomainIndexes
  };
}

interface AxisColumnDef {
  readonly column: string;
  readonly color?: string;
}

export type AxisColumns = string | AxisColumnDef[];

function normalizeAxisColumns(axisColumns: AxisColumns): AxisColumnDef[] {
  if (typeof axisColumns === 'string') {
    return [{ column: axisColumns }];
  } else {
    return axisColumns;
  }
}

interface HorizontalDataAxisConfig {
  readonly color?: string;
  readonly dataKey: string;
  readonly xAxisId: number;
}

interface VerticalDataAxisConfig {
  readonly color?: string;
  readonly dataKey: string;
  readonly yAxisId: number;
}

export type DataAxisConfig = HorizontalDataAxisConfig | VerticalDataAxisConfig;

interface XOrYAxisConfig {
  readonly domain: GraphDomain;
  readonly dataKey: string | null;
  readonly orientation?: string;
}

interface AxisConfig {
  readonly xAxesConfigs: XOrYAxisConfig[];
  readonly yAxesConfigs: XOrYAxisConfig[];
  readonly dataAxisConfig: DataAxisConfig[];
  readonly chartKey: number;
}

function changeLengthNullFilled<T>(arr: (T | null)[], length: number): (T | null)[] {
  const result: (T | null)[] = [...arr].slice(0, length);
  const oldLength = result.length;
  result.length = length;
  return result.fill(null, oldLength);
}

export function useAxisConfig(
  layout: 'vertical' | 'horizontal',
  data: Row[],
  xAxisColumnsProp: AxisColumns,
  xAxisRangeProp: MaybeRange[] | null | undefined,
  yAxisColumnsProp: AxisColumns,
  yAxisType: string,
  yAxisRangeProp: MaybeRange[] | null | undefined
): AxisConfig {
  // hackery to redraw chart to work around what (I think?) is a recharts bug where an old
  // version of the axis remains
  const chartKey = useRef(0);

  return useMemo(() => {
    const xAxisColumns = normalizeAxisColumns(xAxisColumnsProp);
    const yAxisColumns = normalizeAxisColumns(yAxisColumnsProp);

    if (typeof yAxisRangeProp === 'undefined') {
      yAxisRangeProp = [];
    }

    const xAxisDomains = collapseRanges(changeLengthNullFilled(xAxisRangeProp ?? [null], xAxisColumns?.length ?? 1));
    let yAxisDomains = collapseRanges(changeLengthNullFilled(yAxisRangeProp ?? [null], yAxisColumns?.length ?? 1));

    if (yAxisType === 'number') {
      yAxisDomains = calculateUnsetNumericRanges(yAxisDomains, data, yAxisColumns);
    }

    const dataColumns = layout === 'vertical' ? yAxisColumns : xAxisColumns;
    const dataDomains = layout === 'vertical' ? yAxisDomains : xAxisDomains;

    const dataAxisConfig = dataColumns.map((column, i) => {
      const config = {
        dataKey: column.column,
        color: column.color
      };
      const axisId = dataDomains.dataDomainIndexes[i];
      if (layout === 'vertical') {
        return { ...config, yAxisId: axisId };
      } else {
        return { ...config, xAxisId: axisId };
      }
    });

    const xAxesConfigs = xAxisDomains.domains.map((domain, i) => ({
      domain,
      dataKey: layout === 'vertical' ? xAxisColumns?.[0]?.column : null,
      orientation: i % 2 === 0 ? 'bottom' : 'top'
    }));

    function toFixed(num: number, fixed: number) {
      const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
      return (num || 0).toString().match(re)?.[0];
    }

    const DataFormatter = (value: number) => {
      if (!(typeof value === 'number')) {
        return value;
      }

      const collatedValue = value || 0;

      if (collatedValue === -Infinity || collatedValue === Infinity) {
        return collatedValue;
      }

      const absoluteCollatedValue = Math.abs(collatedValue);
      if (absoluteCollatedValue > 1000000000) {
        return Math.floor(collatedValue / 1000000000).toString() + 'B';
      } else if (absoluteCollatedValue > 1000000) {
        return Math.floor(collatedValue / 1000000).toString() + 'M';
      } else if (absoluteCollatedValue > 1000) {
        return Math.floor(collatedValue / 1000).toString() + 'K';
      } else if (absoluteCollatedValue < 1 && absoluteCollatedValue % 1 !== 0) {
        return toFixed(collatedValue, 3);
      } else if (absoluteCollatedValue % 1 !== 0) {
        return toFixed(collatedValue, 2);
      } else {
        return collatedValue;
      }
    };

    const yAxesConfigs = yAxisDomains.domains.map((domain, i) => ({
      domain,
      dataKey: layout === 'horizontal' ? yAxisColumns?.[0]?.column : null,
      orientation: i % 2 === 0 ? 'left' : 'right',
      tickFormatter: DataFormatter
    }));

    return {
      xAxesConfigs,
      yAxesConfigs,
      dataAxisConfig,
      chartKey: chartKey.current++
    };
  }, [layout, xAxisColumnsProp, xAxisRangeProp, yAxisColumnsProp, yAxisRangeProp]);
}
