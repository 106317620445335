import { MouseEvent as ReactMouseEvent, useCallback, useRef } from 'react';

interface EventData {
  id: string;
  doubleClick?: true;
}

type ResultHandler = (e: ReactMouseEvent) => void;
type ClickHandler = (e: EventData) => void;

const doubleClickTimeout = 300;

// return handler that can be passed to onClick. onSingleClick is always called immediately.
// if another click occurs, onDoubleClick will also be called.
export default function useDoubleClickHandler(id: string, onClick: ClickHandler): ResultHandler {
  const timer = useRef<number | null>(null);

  const clearTimer = useCallback(function clearTimer() {
    if (timer.current !== null) {
      window.clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  return useCallback(
    function doubleClickHandlerReturn(e) {
      e.preventDefault();
      e.stopPropagation();
      if (timer.current === null) {
        // single click
        onClick({ id });
        timer.current = window.setTimeout(clearTimer, doubleClickTimeout);
      } else {
        // double click
        onClick({ id, doubleClick: true });
        clearTimer();
      }
    },
    [id, onClick]
  );
}
