import { Dispatch, ReactElement, SetStateAction } from 'react';

import PipeDetails from 'src/components/ImportRightBar/PipeDetails';
import { DbMetrics } from 'src/components/ClickPipesList/DbMetrics';
import { ImportRightbarOption } from 'src/components/ImportRightBar/types';
import KafkaSourceRightBar from 'src/components/ImportRightBar/KafkaSourceRightBar';
import KafkaDestinationRightBar from 'src/components/ImportRightBar/KafkaDestinationRightBar';

interface Props {
  isInstanceAwake: boolean;
  rightBarModel: ImportRightbarOption | null;
  dbMetrics: DbMetrics;
  updateRightBar: Dispatch<SetStateAction<ImportRightbarOption | null>>;
}

function ImportRightBar({
  isInstanceAwake,
  rightBarModel: option,
  dbMetrics,
  updateRightBar
}: Props): ReactElement | null {
  if (!option) {
    return null;
  }

  const onOpenChange = (open: boolean): void => {
    if (!open) {
      updateRightBar(null);
    }
  };

  switch (option.type) {
    case 'kafkaSource':
      return (
        <KafkaSourceRightBar
          data={option.data}
          format={option.format}
          onOpenChange={onOpenChange}
        />
      );
    case 'kafkaDestination':
      return (
        <KafkaDestinationRightBar
          data={option.data}
          onOpenChange={onOpenChange}
        />
      );
    case 'pipeDetails':
      return (
        <PipeDetails
          key={option.data.id}
          isInstanceAwake={isInstanceAwake}
          data={option.data}
          dbMetrics={dbMetrics}
          onOpenChange={onOpenChange}
          onClickAction={option.onClickAction}
          onClickRemove={option.onClickRemove}
          onClickViewData={option.onClickViewData}
          onClickScale={option.onClickScale}
        />
      );
    default:
      return null;
  }
}

export default ImportRightBar;
