import {
  Badge,
  Button,
  Container,
  Dropdown,
  Flyout,
  Icon,
  Select,
  Separator,
  Spacer,
  Tabs,
  Text
} from '@clickhouse/click-ui';
import groupBy from 'lodash/groupBy';
import { ReactElement, useState } from 'react';

import { SavedQueryPermissions } from 'shared/src/types/savedQuery';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { permissionsLabel } from 'src/components/QueryView/Toolbar/constants';
import DisableEndpointConfirmationModal from 'src/components/QueryView/Toolbar/DisableEndpointConfirmationModal';

import ShareModalUser from 'src/components/QueryView/Toolbar/ShareModalUser';
import { ShareQueryEndpointTab } from 'src/components/QueryEndpoints/ShareQueryEndpointTab';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';
import { useShareModalQueryEndpoints } from 'src/queryEndpoints/queryEndpointsController/useShareModalQueryEndpoints';
import { useSelectedTab } from 'src/state/tabs';
import styled from 'styled-components';

interface ShareQueryFlyoutProps {
  queryId: string;
  authorId?: string;
  deleteUserAccess: (userId: string) => void;
  onClose: () => void;
  updateGlobalAccess: (value: string) => void;
  updateUserAccess: (userId: string, value: string) => void;
  open: boolean;
  queryPermissions?: SavedQueryPermissions[];
}

type ShareQueryTabProps = {
  queryPermissions: SavedQueryPermissions[] | undefined;
  authorId: string;
  updateGlobalAccess: (value: string) => void;
  updateUserAccess: (userId: string, value: string) => void;
  deleteUserAccess: (userId: string) => void;
};

function ShareQueryTab({
  queryPermissions,
  authorId,
  updateGlobalAccess,
  updateUserAccess,
  deleteUserAccess
}: ShareQueryTabProps): ReactElement {
  const currentOrg = useCurrentOrganizationOrThrow();
  const currentUser = useCurrentUserOrThrow();
  const groupedAccesses = groupBy(
    queryPermissions || [],
    (access) => access.type
  );
  const globalAccess = groupedAccesses.global?.[0];
  const userAccesses = groupedAccesses.user || [];

  const isOwner = authorId === currentUser.id;

  const userAccessSet = new Set(
    userAccesses.map((userAccess) => userAccess.userId)
  );

  const addableUsers = Object.values(currentOrg.users).filter(
    (orgUser) =>
      orgUser.userId !== authorId && !userAccessSet.has(orgUser.userId)
  );

  const sortedAccesses = userAccesses.toSorted((a, b) =>
    currentOrg.users[a.userId || ''].name >
    currentOrg.users[b.userId || ''].name
      ? 1
      : -1
  );
  return (
    <Container orientation="vertical">
      <Spacer size="lg" />
      {globalAccess && (
        <Container
          orientation="horizontal"
          justifyContent="space-between"
          fillWidth
        >
          <Container orientation="horizontal" gap="xs" grow="1" maxWidth="90%">
            <Icon name="building" />
            <Text>Anyone from {currentOrg?.name} with the link has</Text>
          </Container>
          {isOwner ? (
            <Container maxWidth="11rem">
              <Select
                onSelect={updateGlobalAccess}
                value={globalAccess.permissions}
              >
                <Select.Item value="none">{permissionsLabel.none}</Select.Item>
                <Select.Item value="read">{permissionsLabel.read}</Select.Item>
                <Select.Item value="write">
                  {permissionsLabel.write}
                </Select.Item>
              </Select>
            </Container>
          ) : (
            <Text>{permissionsLabel[globalAccess.permissions]}</Text>
          )}
        </Container>
      )}
      <Separator size="lg" />
      <Container orientation="vertical" gap="md">
        {isOwner && (
          <Container minWidth="100%">
            <Dropdown>
              <Dropdown.Trigger>
                <Select value="Add a team member"></Select>
              </Dropdown.Trigger>
              <Dropdown.Content align="start" side="bottom">
                {addableUsers.map((orgUser) => (
                  <Dropdown.Item
                    key={orgUser.userId}
                    onClick={() => updateUserAccess(orgUser.userId, 'read')}
                  >
                    {orgUser.name} ({orgUser.role})
                  </Dropdown.Item>
                ))}
                {addableUsers.length === 0 && (
                  <Container padding="sm">
                    <Text color="muted">All team members have been added</Text>
                  </Container>
                )}
              </Dropdown.Content>
            </Dropdown>
          </Container>
        )}
        <Container orientation="vertical" gap="sm">
          {!!authorId && (
            <ShareModalUser
              isCurrentUser={currentUser.id === authorId}
              permissions="owner"
              user={currentOrg.users[authorId]}
            />
          )}
          {sortedAccesses
            .filter((userAccess) => {
              return (
                !!userAccess.userId && !!currentOrg.users[userAccess.userId]
              );
            })
            .map((userAccess) => {
              return (
                <ShareModalUser
                  editable={isOwner}
                  isCurrentUser={currentUser.id === userAccess.userId}
                  key={userAccess.userId}
                  onChange={(permissions: string) => {
                    updateUserAccess(userAccess.userId || '', permissions);
                  }}
                  onDelete={() => {
                    deleteUserAccess(userAccess.userId || '');
                  }}
                  permissions={userAccess.permissions}
                  user={currentOrg.users[userAccess.userId || '']}
                />
              );
            })}
        </Container>
      </Container>
    </Container>
  );
}

type TabValues = 'queries' | 'endpoint';

const TabContent = styled(Tabs.Content)`
  width: 100%;
`;

const FullWidthTabs = styled(Tabs)`
  width: 100%;
`;

function ShareQueryFlyout(props: ShareQueryFlyoutProps): ReactElement {
  const {
    authorId,
    deleteUserAccess,
    onClose,
    updateGlobalAccess,
    updateUserAccess,
    open,
    queryPermissions,
    queryId
  } = props;
  const selectedTab = useSelectedTab();
  let queryParameters = {};
  if (
    selectedTab &&
    selectedTab.type === 'query' &&
    selectedTab.queryVariables
  ) {
    queryParameters = { ...selectedTab.queryVariables };
  }

  const [disableEndpointConfirmModalOpen, setDisableEndpointConfirmModalOpen] =
    useState(false);
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const [activeTab, setActiveTab] = useState<TabValues>('queries');
  const hasShareEndpointEnabled = hasManageServicePermission;
  const useShareModalQueryEndpointResult = useShareModalQueryEndpoints(queryId);
  const { disableEndpoint, queryEndpoint } = useShareModalQueryEndpointResult;

  return (
    <Flyout
      open={open}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Flyout.Content strategy="fixed" size="wide">
        <Flyout.Header title="Share Query" />
        <Flyout.Body align="default">
          <Flyout.Element>
            <FullWidthTabs
              defaultValue="queries"
              ariaLabel="Share Query Tabs"
              onValueChange={(value: string) =>
                setActiveTab(value as TabValues)
              }
            >
              <Tabs.TriggersList>
                <Tabs.Trigger value="queries" data-testid="queriesTabButton">
                  <Container
                    orientation="horizontal"
                    gap="xs"
                    minHeight="2rem"
                    maxHeight="2rem"
                  >
                    With users
                  </Container>
                </Tabs.Trigger>
                {hasShareEndpointEnabled && (
                  <Tabs.Trigger
                    value="endpoint"
                    data-testid="endpointsTabButton"
                  >
                    <Container
                      orientation="horizontal"
                      gap="xs"
                      minHeight="2rem"
                      maxHeight="2rem"
                    >
                      API Endpoint <Badge size="sm" text="beta" state="info" />
                    </Container>
                  </Tabs.Trigger>
                )}
              </Tabs.TriggersList>
              <TabContent value="queries">
                <ShareQueryTab
                  authorId={authorId || ''}
                  deleteUserAccess={deleteUserAccess}
                  queryPermissions={queryPermissions}
                  updateGlobalAccess={updateGlobalAccess}
                  updateUserAccess={updateUserAccess}
                />
              </TabContent>
              {hasShareEndpointEnabled && (
                <TabContent value="endpoint">
                  <ShareQueryEndpointTab
                    queryId={queryId}
                    queryParameters={queryParameters}
                    onClose={onClose}
                    {...useShareModalQueryEndpointResult}
                  />
                  {disableEndpointConfirmModalOpen && (
                    <DisableEndpointConfirmationModal
                      onCancel={(): void =>
                        setDisableEndpointConfirmModalOpen(false)
                      }
                      disableEndpoint={disableEndpoint}
                    />
                  )}
                </TabContent>
              )}
            </FullWidthTabs>
          </Flyout.Element>
        </Flyout.Body>
        <Flyout.Footer>
          <Flyout.Close label="Close" />
          {activeTab === 'endpoint' && !!queryEndpoint && (
            <Button
              type="danger"
              data-testid="disableQueryApiEndpointBtn"
              onClick={(): void => {
                setDisableEndpointConfirmModalOpen(true);
              }}
            >
              Disable endpoint
            </Button>
          )}
        </Flyout.Footer>
      </Flyout.Content>
    </Flyout>
  );
}

export default ShareQueryFlyout;
