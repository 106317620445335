import { NotificationName } from '@cp/common/protocol/NotificationInventory';
import { getNotificationType } from '@cp/common/utils/NotificationsUtils';
import { ReactElement } from 'react';
import { getHumanReadableCategory } from 'src/pages/NotificationsPage/helpers';

export const NotificationCategory = ({
  notificationName
}: {
  notificationName: NotificationName;
}): ReactElement => {
  const { category } = getNotificationType(notificationName) || {
    category: ''
  };

  return <>{getHumanReadableCategory(category)}</>;
};
