import {
  Button,
  createToast,
  Dropdown,
  IconButton,
  Link,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
  Text
} from '@clickhouse/click-ui';
import { OrganizationPrivateEndpoint } from '@cp/common/protocol/Organization';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import {
  useCurrentInstanceOrThrow,
  useInstanceController
} from 'src/instance/instanceController';
import {
  Section,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import { routes } from 'src/lib/routes';
import { useOrganizationController } from 'src/organization/organizationController';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';
import ConfirmRemoveEndpointFromOrgAndService from 'src/pages/SettingsPage/ConfirmRemoveEndpointFromOrgAndService';
import ConfirmRemoveEndpointFromService from 'src/pages/SettingsPage/ConfirmRemoveEndpointFromService';
import { PrivateEndpointFlyout } from 'src/pages/SettingsPage/PrivateEndpointFlyout';

interface PrivateEndpointActionsMenuProps {
  hasManagePrivateEndpointsPermission: boolean;
  endpointId: string;
  onRemoveFromOrgAndService: (endpointId: string) => void;
  onRemoveFromService: (endpointId: string) => void;
}

function PrivateEndpointActionsMenu({
  hasManagePrivateEndpointsPermission,
  endpointId,
  onRemoveFromOrgAndService,
  onRemoveFromService
}: PrivateEndpointActionsMenuProps): ReactNode {
  return (
    <Dropdown>
      <Dropdown.Trigger>
        <IconButton
          data-testid={`private-endpoint-actions-${endpointId}`}
          icon="dots-vertical"
        />
      </Dropdown.Trigger>
      <Dropdown.Content showArrow>
        <Dropdown.Item
          data-testid={`private-endpoint-actions-${endpointId}-remove-from-org-and-service`}
          disabled={!hasManagePrivateEndpointsPermission}
          onClick={(): void => {
            onRemoveFromOrgAndService(endpointId);
          }}
        >
          <Text color="danger">Remove from organization</Text>
        </Dropdown.Item>
        <Dropdown.Item
          data-testid={`private-endpoint-actions-${endpointId}-remove-from-service`}
          disabled={!hasManagePrivateEndpointsPermission}
          onClick={(): void => {
            onRemoveFromService(endpointId);
          }}
        >
          <Text color="danger">Remove from service</Text>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown>
  );
}

interface PrivateEndpointTableProps {
  endpoints: Array<OrganizationPrivateEndpoint>;
  onRemoveEndpointFromService: (endpointId: string) => void;
  onRemoveEndpointFromOrgAndService: (endpointId: string) => void;
  hasManagePrivateEndpointsPermission: boolean;
}

const privateEndpointHeaders: Array<TableHeaderType> = [
  { label: 'Endpoint ID', width: '40%' },
  { label: 'Description', width: '40%' },
  { label: 'Actions', width: '20%' }
];

function PrivateEndpointTable({
  endpoints,
  onRemoveEndpointFromService,
  onRemoveEndpointFromOrgAndService,
  hasManagePrivateEndpointsPermission
}: PrivateEndpointTableProps): ReactElement | null {
  const removeFromService = useCallback(
    (endpointId: string) => {
      onRemoveEndpointFromService(endpointId);
    },
    [onRemoveEndpointFromService]
  );

  const removeFromOrgAndService = useCallback(
    (endpointId: string) => {
      onRemoveEndpointFromOrgAndService(endpointId);
    },
    [onRemoveEndpointFromOrgAndService]
  );

  const rows: Array<TableRowType> = useMemo(() => {
    return endpoints.map((endpoint) => ({
      id: endpoint.id,
      items: [
        { label: endpoint.id },
        { label: endpoint.description },
        {
          label: (
            <PrivateEndpointActionsMenu
              endpointId={endpoint.id}
              hasManagePrivateEndpointsPermission={
                hasManagePrivateEndpointsPermission
              }
              onRemoveFromOrgAndService={removeFromOrgAndService}
              onRemoveFromService={removeFromService}
            />
          )
        }
      ]
    }));
  }, [
    endpoints,
    hasManagePrivateEndpointsPermission,
    removeFromOrgAndService,
    removeFromService
  ]);

  if (rows.length === 0) {
    return null;
  }

  return (
    <Table
      data-testid="private-endpoint-table"
      headers={privateEndpointHeaders}
      rows={rows}
    />
  );
}

export function PrivateEndpointSection(): ReactElement | null {
  const hasManagePrivateEndpointsPermission =
    useUserAndOrgRolesHasPermissionForInstance(
      'control-plane:service:manage-private-endpoints',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [endpointToRemoveFromService, setEndpointToRemoveFromService] =
    useState<string>();
  const [
    endpointToRemoveFromOrgAndService,
    setEndpointToRemoveFromOrgAndService
  ] = useState<string>();

  const { updatePrivateEndpointIds } = useInstanceController();
  const { updatePrivateEndpoints } = useOrganizationController();

  const organization = useCurrentOrganizationOrThrow();
  const instance = useCurrentInstanceOrThrow();
  const privateEndpoints = useMemo(
    () =>
      organization.privateEndpoints.filter((endpoint) =>
        instance.privateEndpointIds.includes(endpoint.id)
      ),
    [instance.privateEndpointIds, organization.privateEndpoints]
  );

  const linkToOrgEndpoints =
    hasManagePrivateEndpointsPermission &&
    organization.privateEndpoints.length > 0;

  const availablePrivateEndpoints = useMemo(
    () =>
      organization.privateEndpoints.filter(
        (endpoint) =>
          !instance.privateEndpointIds.includes(endpoint.id) &&
          endpoint.cloudProvider === instance.cloudProvider &&
          endpoint.region === instance.regionId
      ),
    [
      instance.privateEndpointIds,
      instance.regionId,
      instance.cloudProvider,
      organization.privateEndpoints
    ]
  );

  const beginSetup = useCallback(() => {
    setFlyoutOpen(true);
  }, []);

  const createAndAddToInstance = useCallback(
    (endpointId: string, description: string) => {
      const create = async (): Promise<void> => {
        try {
          const newEndpoint: OrganizationPrivateEndpoint = {
            cloudProvider: instance.cloudProvider,
            id: endpointId,
            description,
            region: instance.regionId
          };
          const newOrganizationEndpoints = [
            ...organization.privateEndpoints,
            newEndpoint
          ];
          const newInstanceEndpointIds = [
            ...instance.privateEndpointIds,
            endpointId
          ];

          const orgEndpointCreated = await updatePrivateEndpoints({
            organizationId: organization.id,
            privateEndpoints: newOrganizationEndpoints
          });

          if (orgEndpointCreated) {
            await updatePrivateEndpointIds({
              organizationId: organization.id,
              instanceId: instance.id,
              privateEndpointIds: newInstanceEndpointIds
            });

            createToast({
              title: 'Success',
              type: 'success',
              description: 'Updated private endpoint list for instance'
            });

            setFlyoutOpen(false);
          }
        } catch (e) {
          console.error(e);
          createToast({
            type: 'danger',
            title: 'Error adding private endpoint to service'
          });
        }
      };

      void create();
    },
    [
      instance.id,
      instance.cloudProvider,
      instance.privateEndpointIds,
      instance.regionId,
      organization.id,
      organization.privateEndpoints,
      updatePrivateEndpointIds,
      updatePrivateEndpoints
    ]
  );

  const addToInstance = useCallback(
    (privateEndpointId: string) => {
      const add = async (): Promise<void> => {
        const newEndpointIds = [
          ...instance.privateEndpointIds,
          privateEndpointId
        ];
        try {
          await updatePrivateEndpointIds({
            organizationId: organization.id,
            instanceId: instance.id,
            privateEndpointIds: newEndpointIds
          });
          setFlyoutOpen(false);
        } catch (e) {
          createToast({
            type: 'danger',
            title: 'Error adding private endpoint to service'
          });
        }
      };

      void add();
    },
    [
      instance.id,
      instance.privateEndpointIds,
      organization.id,
      updatePrivateEndpointIds
    ]
  );

  const askToRemoveEndpointFromService = useCallback(
    (endpointIdToRemove: string) => {
      setEndpointToRemoveFromService(endpointIdToRemove);
    },
    []
  );

  const confirmRemoveEndpointFromService = useCallback(
    (endpointIdToRemove: string | undefined) => {
      if (!endpointIdToRemove) {
        return;
      }
      const newPrivateEndpointIds = instance.privateEndpointIds.filter(
        (endpointId) => endpointId !== endpointIdToRemove
      );
      void updatePrivateEndpointIds({
        organizationId: organization.id,
        instanceId: instance.id,
        privateEndpointIds: newPrivateEndpointIds
      });
      setEndpointToRemoveFromService(undefined);
    },
    [
      instance.id,
      organization.id,
      instance.privateEndpointIds,
      updatePrivateEndpointIds
    ]
  );

  const askToRemoveEndpointFromOrgAndService = useCallback(
    (endpointIdToRemove: string) => {
      setEndpointToRemoveFromOrgAndService(endpointIdToRemove);
    },
    []
  );

  const confirmRemoveEndpointFromOrgAndService = useCallback(
    (endpointIdToRemove: string | undefined) => {
      if (!endpointIdToRemove) {
        return undefined;
      }
      const newOrgPrivateEndpoints = organization.privateEndpoints.filter(
        (endpoint) => endpoint.id !== endpointIdToRemove
      );
      void updatePrivateEndpoints({
        organizationId: organization.id,
        privateEndpoints: newOrgPrivateEndpoints
      });
      setEndpointToRemoveFromOrgAndService(undefined);
    },
    [organization.id, updatePrivateEndpoints, organization.privateEndpoints]
  );

  if (instance.instanceTier === 'Development' || isOrgByoc(organization)) {
    return null;
  }

  return (
    <>
      <Section>
        <SubsectionTitle>Private endpoints</SubsectionTitle>
        <Spacer />
        <Subsection justifyContent="space-between">
          <SubsectionDescription>
            <Text color="muted">
              Set up one or more endpoints to connect privately and securely to
              your service.
            </Text>
          </SubsectionDescription>
          {hasManagePrivateEndpointsPermission && (
            <SubsectionControls>
              <div>
                <Button type="secondary" onClick={beginSetup}>
                  Set up private endpoint
                </Button>
                {linkToOrgEndpoints && (
                  <>
                    <Spacer size="sm" />
                    <Text>
                      {"View this organization's "}
                      <Link
                        data-testid="private-endpoint-link"
                        onClick={() => {
                          navigateTo(
                            routes.privateEndpointsPage({
                              orgId: organization.id
                            })
                          );
                        }}
                      >
                        private endpoints
                      </Link>
                    </Text>
                  </>
                )}
              </div>
            </SubsectionControls>
          )}
        </Subsection>
        <Spacer />
        <PrivateEndpointTable
          endpoints={privateEndpoints}
          onRemoveEndpointFromService={askToRemoveEndpointFromService}
          onRemoveEndpointFromOrgAndService={
            askToRemoveEndpointFromOrgAndService
          }
          hasManagePrivateEndpointsPermission={
            hasManagePrivateEndpointsPermission
          }
        />
      </Section>
      <PrivateEndpointFlyout
        instance={instance}
        endpoints={availablePrivateEndpoints}
        open={hasManagePrivateEndpointsPermission && flyoutOpen}
        onOpenChange={setFlyoutOpen}
        onCreateAndAddToInstance={createAndAddToInstance}
        onAddToInstance={addToInstance}
      />
      <ConfirmRemoveEndpointFromService
        endpointId={endpointToRemoveFromService}
        onClose={() => setEndpointToRemoveFromService(undefined)}
        onConfirm={() =>
          confirmRemoveEndpointFromService(endpointToRemoveFromService)
        }
      />
      <ConfirmRemoveEndpointFromOrgAndService
        endpointId={endpointToRemoveFromOrgAndService}
        onClose={() => setEndpointToRemoveFromOrgAndService(undefined)}
        onConfirm={() =>
          confirmRemoveEndpointFromOrgAndService(
            endpointToRemoveFromOrgAndService
          )
        }
      />
    </>
  );
}
