import { AppState } from '@auth0/auth0-react';
import { TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { usePendingUserActionController } from 'src/lib/pendingUserActions/usePendingUserActionController';

/**
 * Custom React hook that handles the Tackle subscription token from the appState URL parameter.
 */
export function useTackleTokenFromAppState(): (appState?: AppState) => void {
  const { addTackleSubscriptionAction } = usePendingUserActionController();

  /**
   * Extract Tackle token from the appstate and add it to the pending user actions.
   * @param {AppState} appState - The AppState object.
   * @returns {void}
   */
  function handleAppState(appState?: AppState): void {
    const tackleToken = appState?.tackleToken as TackleSubscriptionToken;
    if (!tackleToken) {
      return;
    }
    // Add tackle subscription pending action.
    addTackleSubscriptionAction(tackleToken);
  }

  return handleAppState;
}
