import { CardPrimary, FormContainer, Link, Text } from '@clickhouse/click-ui';
import {
  ChangeEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { formatBytesToSize } from 'src/lib/SizeUtils';
import styled from 'styled-components';
import { createToast } from 'src/components/primitives';

const StyledFileUpload = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

const FileCard = styled(CardPrimary)`
  position: relative;
  min-height: 145px;
`;

const CustomLink = styled(Link)`
  display: block;
  isolation: isolate;
`;

const DEFAULT_FILE_SIZE = 209715200;

interface Props {
  onChange: (files: FileList) => Promise<void>;
  maxFileSizeInBytes?: number;
  status: 'in_progress' | 'default' | 'completed';
  progress?: number;
  label?: string;
  fileList: FileList | null;
}
interface UploadCardProps extends Omit<Props, 'onChange' | 'label' | 'status'> {
  status: 'in_progress' | 'default' | 'completed' | 'processing';
  children: ReactNode;
  onRemove: () => void;
}

function ProgressCard({
  progress = 0,
  fileList,
  children
}: Omit<UploadCardProps, 'status' | 'onRemove'>): ReactElement {
  return (
    <FileCard icon="loading-animated" size="sm" alignContent="center">
      {children}
      <Text align="center">Uploading ({progress}%)</Text>
      {fileList && (
        <Text align="center">
          {fileList.length === 1
            ? fileList.item(0)?.name ?? '1 file'
            : `${fileList.length} files`}
        </Text>
      )}
    </FileCard>
  );
}

function DefaultCard({
  children,
  maxFileSizeInBytes = DEFAULT_FILE_SIZE
}: Omit<UploadCardProps, 'status' | 'onRemove'>): ReactElement {
  const maxSize = formatBytesToSize(maxFileSizeInBytes);
  return (
    <FileCard icon="plus" size="sm" alignContent="center">
      {children}
      <Text align="center">
        Drag and drop one or more files or <Link component="span">browse</Link>
      </Text>
      <Text align="center" color="muted">
        max size: {maxSize}
      </Text>
    </FileCard>
  );
}

function ProcessingCard({
  children
}: Omit<UploadCardProps, 'status' | 'onRemove'>): ReactElement {
  return (
    <FileCard icon="loading-animated" size="sm" alignContent="center">
      {children}
      <Text align="center">Processing</Text>
    </FileCard>
  );
}

function SuccessCard({
  fileList,
  children,
  onRemove
}: Omit<UploadCardProps, 'status'>): ReactElement {
  const fileNames = fileList
    ? Array.from(fileList)
        .map((file) => file.name)
        .join('\n')
    : '';
  return (
    <FileCard icon="document" size="sm" alignContent="center">
      {children}
      {fileList && (
        <Text align="center">
          {fileList.length === 1
            ? fileList.item(0)?.name ?? '1 file'
            : `${fileList.length} files`}
        </Text>
      )}
      <CustomLink component="div" onClick={onRemove} title={fileNames}>
        Remove
      </CustomLink>
    </FileCard>
  );
}
function UploadCard({
  status,
  onRemove,
  ...props
}: UploadCardProps): ReactElement {
  if (status === 'processing') {
    return <ProcessingCard {...props} />;
  }
  if (status === 'in_progress') {
    return <ProgressCard {...props} />;
  }
  if (status === 'completed') {
    return <SuccessCard onRemove={onRemove} {...props} />;
  }
  return <DefaultCard {...props} />;
}

function FileUploadElement({
  onChange: onChangeProp,
  status,
  label = 'Attachments',
  maxFileSizeInBytes = DEFAULT_FILE_SIZE,
  fileList,
  ...props
}: Props): ReactElement {
  const ref = useRef<HTMLInputElement>(null);

  const onRemove = useCallback((): void => {
    if (ref.current) {
      ref.current.value = '';
      void onChangeProp(new DataTransfer().files);
    }
  }, [onChangeProp]);

  useEffect(() => {
    if (ref.current) {
      ref.current.files = fileList;
    }
  }, [fileList]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    if (!files) {
      return;
    }

    const sizeLimitExceededFileNames = [];
    const fileBuffer = new DataTransfer();
    // append the file list to an array iteratively
    for (let i = 0; i < files.length; i++) {
      // Exclude file in specified index
      const uploadFile = files.item(i);
      if (uploadFile && uploadFile.size <= maxFileSizeInBytes) {
        fileBuffer.items.add(uploadFile);
      } else if (uploadFile) {
        sizeLimitExceededFileNames.push(uploadFile.name);
      }

      if (sizeLimitExceededFileNames.length > 0) {
        const plural = sizeLimitExceededFileNames.length !== 1;
        createToast(
          'Size Exceeded',
          'error',
          `File${plural ? 's' : ''} ${sizeLimitExceededFileNames.join(
            ','
          )} exceed${plural ? 's' : ''} the size limit`
        );
      }
    }
    // Assign buffer to file input
    e.target.files = fileBuffer.files;
    void onChangeProp(e.target.files);
  };

  return (
    <FormContainer htmlFor="" label={label}>
      <UploadCard
        status={status}
        onRemove={onRemove}
        maxFileSizeInBytes={maxFileSizeInBytes}
        fileList={fileList}
        {...props}
      >
        <StyledFileUpload
          ref={ref}
          type="file"
          onChange={onChange}
          multiple
          disabled={status === 'in_progress'}
        />
      </UploadCard>
    </FormContainer>
  );
}

export default FileUploadElement;
