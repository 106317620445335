import React from 'react';
import { useTheme } from '@emotion/react';
import { IconProps } from 'src/components/icons/iconInterface';

function DangerIcon({ color, ...otherProps }: IconProps): JSX.Element {
  const theme = useTheme();
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M3.09766 13.5044H12.9111C14.0156 13.5044 14.7139 12.7046 14.7139 11.708C14.7139 11.4033 14.625 11.0859 14.46 10.7939L9.54688 2.22461C9.2041 1.62793 8.60742 1.32324 8.00439 1.32324C7.39502 1.32324 6.79834 1.62793 6.46191 2.22461L1.54883 10.7939C1.37744 11.0923 1.29492 11.4033 1.29492 11.708C1.29492 12.7046 1.98682 13.5044 3.09766 13.5044ZM3.20557 12.311C2.80566 12.311 2.56445 12 2.56445 11.6509C2.56445 11.543 2.5835 11.4033 2.64697 11.2827L7.45215 2.88477C7.57275 2.67529 7.79492 2.58643 8.00439 2.58643C8.21387 2.58643 8.42969 2.67529 8.55029 2.88477L13.3555 11.2954C13.4189 11.4097 13.4443 11.543 13.4443 11.6509C13.4443 12 13.1904 12.311 12.7969 12.311H3.20557ZM8.00439 9.11816C8.34717 9.11816 8.55029 8.92139 8.55664 8.55322L8.65186 5.46826C8.66455 5.1001 8.38525 4.8335 7.99805 4.8335C7.61084 4.8335 7.33789 5.09375 7.35059 5.46191L7.43945 8.55957C7.45215 8.91504 7.64893 9.11816 8.00439 9.11816ZM8.00439 11.2192C8.41064 11.2192 8.74707 10.9209 8.74707 10.521C8.74707 10.1211 8.41699 9.8291 8.00439 9.8291C7.59814 9.8291 7.26172 10.1274 7.26172 10.521C7.26172 10.9209 7.59814 11.2192 8.00439 11.2192Z"
        fill={color ?? theme.colors.alert.text.danger}
      />
    </svg>
  );
}

export default React.memo(DangerIcon);
