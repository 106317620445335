import { ReactElement } from 'react';
import { KafkaDestinationOption } from 'src/components/ImportRightBar/types';
import {
  CellProps,
  DialogProps,
  Flyout,
  Grid,
  Text
} from '@clickhouse/click-ui';

type Props = DialogProps & Omit<KafkaDestinationOption, 'type' | 'id'>;

function KafkaDestinationRightBar({ onOpenChange, data }: Props): ReactElement {
  const columns = data?.columns || [];

  const Cell: CellProps = ({
    rowIndex,
    columnIndex,
    type,
    isScrolling,
    ...props
  }) => {
    if (type === 'header-cell') {
      return <span {...props}>{columns[columnIndex].name}</span>;
    }

    return <span {...props}>{data.rows[rowIndex - 1][columnIndex]}</span>;
  };

  return (
    <Flyout open onOpenChange={onOpenChange}>
      <Flyout.Content
        className="view-kafka-destination-data fs-exclude"
        data-testid="kafka-destination-rightbar"
        strategy="fixed"
      >
        <Flyout.Header
          title="Destination Data"
          description="This data is fetched from the destination table in ClickHouse."
        />
        <Flyout.Body>
          <Flyout.Element fillHeight>
            {data.rows.length > 0 ? (
              <Grid
                cell={Cell}
                rounded="sm"
                rowStart={1}
                rowCount={data.rows.length > 3 ? 3 : data.rows.length}
                columnCount={columns.length}
                className="fs-exclude"
                autoHeight
              />
            ) : (
              <Text>No records found in this table</Text>
            )}
          </Flyout.Element>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
}

export default KafkaDestinationRightBar;
