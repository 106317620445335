/* eslint-disable no-useless-catch */

import React, { useMemo, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import { CSVLink } from 'react-csv';

import NotAllowedIcon from 'src/components/icons/NotAllowedIcon';
import { getColumnType } from 'src/lib/chart';
import { chartMap, fontOptions } from 'src/lib/chart/config';

import {
  AreaChart,
  BarChart,
  HeatMap,
  LineChart,
  PieChart,
  ScatterPlot
} from 'src/components/primitives/lib/Charts';

import VizHouseChart from 'src/components/primitives/lib/Charts/VizHouseChart';
import VizHousePieChart from 'src/components/primitives/lib/Charts/VizHousePieChart';
import VizHouseHeatmapChart from 'src/components/primitives/lib/Charts/VizHouseHeatmapChart';
import { useOrgFeature } from 'src/lib/features';

import {
  chartContainer,
  chartNoDataContainerStyle,
  chartWrapperStyle,
  invalidConfigMessageStyle,
  messageContainerStyle,
  messageStyle
} from 'src/components/primitives/lib/Chart/style';
import {
  formatDate,
  formatTimestampAny
} from 'src/lib/formatters/dateTimeFormatter';

const Chart = React.memo(
  function Chart(chartProps) {
    const {
      csvName,
      customMessage,
      type,
      data: propData,
      dateFormat,
      showNoResults,
      size,
      style,
      weight,
      ...props
    } = chartProps;

    const vizHouseChartsFeature = useOrgFeature(
      'FT_ORG_QUERY_VIEW_VIZ_HOUSE_CHARTS'
    );
    const chartRef = useRef();
    const data = useMemo(() => {
      const newData = cloneDeep(propData);
      if (
        newData &&
        Array.isArray(newData.columns) &&
        newData.columns.length > 0
      ) {
        let columns = cloneDeep(newData.columns);
        columns = columns.map((col, index, arr) => {
          let num;
          let name;
          if (col.name) {
            while (!name) {
              const newName = num ? `${col.name}_${num}` : col.name;
              const conflict = arr.some((col, colIndex) => {
                if (index !== colIndex) {
                  return col.name === newName;
                }
                return false;
              });
              if (!conflict) {
                name = newName;
                arr[index].name = newName;
              } else {
                num = num ? num + 1 : 1;
              }
            }
          } else {
            name = col.name;
          }

          return {
            ...col,
            name
          };
        });

        if (newData && Array.isArray(newData.rows) && newData.rows.length > 0) {
          const rows = newData.rows.map((row) => {
            if (Array.isArray(row)) {
              return columns
                .map((item) => item.name)
                .reduce((acc, cur, index) => {
                  acc[cur] = row[index];
                  return acc;
                }, {});
            }

            return row;
          });

          newData.rows = rows;
        }

        newData.columns = columns;
      }

      return newData;
    }, [propData, chartProps]);

    const configInvalid = useMemo(() => {
      const configField = chartMap[type] && chartMap[type].data;
      if (configField) {
        const requiredFields = Object.entries(configField).filter(
          ([key, config]) => config.required
        );
        return requiredFields.some(([key, config]) => {
          if (config.type === 'column' && !props[key]) {
            return true;
          } else if (config.type === 'columnArray' && props[key]) {
            return props[key].length === 0;
          } else {
            return !props[key];
          }
        });
      }

      return false;
    }, [JSON.stringify(props), type]);

    if (data && data.rows && data.rows.length > 0 && !configInvalid) {
      let key;
      if (props.yAxis) {
        key = props.yAxis;
      } else if (props.xAxis || props.categories || props.dataKey) {
        key = props.xAxis || props.categories || props.dataKey;
      }

      let rows = data ? data.rows : [];

      if (key) {
        const dataProps =
          typeof key === 'string' ? [key] : key.map((a) => a.column);

        rows = rows.map((row) => {
          return dataProps.reduce((newRow, dataProp) => {
            const type = getColumnType(data, dataProp);
            if (type === 'date') {
              return {
                ...newRow,
                [dataProp]: formatTimestampAny(
                  newRow[dataProp],
                  dateFormat || 'MMMM D, YYYY'
                )
              };
            } else if (type === 'number') {
              return { ...newRow, [dataProp]: +newRow[dataProp] };
            } else {
              return newRow;
            }
          }, row);
        });
      }

      const axisType = (axisProps) => {
        if (typeof axisProps === 'string') {
          const axisName = axisProps;
          return getColumnType(data, axisName) === 'number'
            ? 'number'
            : 'category';
        } else if (Array.isArray(axisProps)) {
          const axisNames = axisProps.map((colDef) => colDef.column);
          for (const axisName of axisNames) {
            if (getColumnType(data, axisName) !== 'number') {
              return 'category';
            }
          }
          return 'number';
        } else {
          return 'category';
        }
      };

      props.xAxisType = axisType(props.xAxis);
      props.yAxisType = axisType(props.yAxis);
      props.zAxisType = axisType(props.zAxis);

      let chart = null;

      let font = props.font;
      if (!fontOptions.includes(font)) {
        font = 'Inter';
      }
      switch (type) {
        case 'area':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart {...props} chartType="area" data={rows} />
          ) : (
            <AreaChart data={rows} {...props} animate={false} />
          );
          break;
        case 'bar':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart
              {...props}
              chartType="bar"
              data={rows}
              layout="vertical"
            />
          ) : (
            <BarChart
              layout="vertical"
              data={rows}
              {...props}
              animate={false}
            />
          );
          break;
        case 'doughnut':
          chart = vizHouseChartsFeature ? (
            <VizHousePieChart chartType="doughnut" data={rows} {...props} />
          ) : (
            <PieChart type="doughnut" data={rows} {...props} animate={false} />
          );
          break;
        case 'hbar':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart
              {...props}
              chartType="bar"
              data={rows}
              layout="horizontal"
            />
          ) : (
            <BarChart
              layout="horizontal"
              data={rows}
              {...props}
              animate={false}
            />
          );
          break;
        case 'heatMap':
          chart = vizHouseChartsFeature ? (
            <VizHouseHeatmapChart data={rows} {...props} />
          ) : (
            <HeatMap data={rows} {...props} animate={false} />
          );
          break;
        case 'line':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart {...props} chartType="line" data={rows} />
          ) : (
            <LineChart data={rows} {...props} animate={false} />
          );
          break;
        case 'pie':
          chart = vizHouseChartsFeature ? (
            <VizHousePieChart chartType="pie" data={rows} {...props} />
          ) : (
            <PieChart data={rows} {...props} animate={false} />
          );
          break;
        case 'sbar':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart
              {...props}
              chartType="bar"
              data={rows}
              layout="vertical"
              stacked
            />
          ) : (
            <BarChart
              layout="vertical"
              stacked
              data={rows}
              {...props}
              animate={false}
            />
          );
          break;
        case 'scatter':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart {...props} chartType="scatter" data={rows} />
          ) : (
            <ScatterPlot data={rows} {...props} animate={false} />
          );
          break;
        case 'shbar':
          chart = vizHouseChartsFeature ? (
            <VizHouseChart
              {...props}
              chartType="bar"
              data={rows}
              layout="horizontal"
              stacked
            />
          ) : (
            <BarChart
              {...props}
              layout="horizontal"
              stacked
              data={rows}
              animate={false}
            />
          );
          break;
        default:
          chart = <div>Invalid chart configuration</div>;
      }

      if (chart) {
        return (
          <div
            css={chartContainer}
            ref={chartRef}
            id="chart_main_wrapper"
            data-testid="queryResultsChart"
            className="fs-exclude"
          >
            <div css={chartWrapperStyle}>
              {data && data.rows && (
                <CSVLink
                  id="csv_data_export"
                  data={data.rows}
                  filename={
                    csvName
                      ? `${snakeCase(csvName)}.csv`
                      : `csv_${formatDate(new Date())}.csv`.toLowerCase()
                  }
                  target="_blank"
                  className="csvLink"
                />
              )}
              {chart}
            </div>
          </div>
        );
      }
    }

    if (data && data.rows && data.rows.length > 0 && configInvalid) {
      return (
        <div
          css={chartContainer}
          id="chart_main_wrapper"
          data-testid="queryResultsChart"
        >
          <div css={chartNoDataContainerStyle}>
            <div css={messageContainerStyle}>
              <div css={invalidConfigMessageStyle}>{customMessage}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        css={chartContainer}
        style={style}
        id="chart_main_wrapper"
        data-testid="queryResultsChart"
      >
        <div css={chartNoDataContainerStyle}>
          <div className="fs-exclude">{props.title}</div>
          <div css={messageContainerStyle}>
            {showNoResults && (
              <React.Fragment>
                <NotAllowedIcon />
                <div css={messageStyle}>No results</div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return Object.entries(omit(nextProps, 'data')).reduce(
      (isEqual, [key, value]) => {
        return isEqual && prevProps[key] === value;
      },
      true
    );
  }
);

export default Chart;
