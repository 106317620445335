import { useAuth0 } from '@auth0/auth0-react';
import { createToast } from '@clickhouse/click-ui';
import { MfaMethod } from '@cp/common/protocol/Account';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import { useCallback } from 'react';
import config from 'src/lib/config';
import { useApiClient } from 'src/lib/controlPlane/client';
import { deleteMfaAuthenticator, getMfaAuthenticatorId } from 'src/lib/mfa';
import { useUserStateManager } from 'src/user/userState';

type MfaControllerResult = {
  isTotpEnabled: boolean;
  enableTotp: () => Promise<void>;
  disableTotp: () => Promise<void>;
  initiateEnrollment: () => Promise<void>;
};

/**
 * Hook to manage multi-factor authentication (MFA) with Time-based One-Time Password (TOTP).
 * @returns {MfaControllerResult} The result object containing MFA related functions and state.
 */
export function useMfaController(): MfaControllerResult {
  const { getAccessTokenWithPopup } = useAuth0();
  const { user, updateUser } = useUserStateManager();
  const apiClient = useApiClient();
  const isTotpEnabled = user.mfaPreferredMethod === 'SOFTWARE_TOKEN_MFA';

  /**
   * Get the MFA enrollment access token.
   * @returns {Promise<string | undefined>} The MFA enrollment access token.
   */
  const getMfaEnrollmentAccessToken = useCallback(async (): Promise<
    string | undefined
  > => {
    const { auth0domain } = config.auth0Web;
    const mfaAccessToken = await getAccessTokenWithPopup({
      authorizationParams: {
        scope: 'enroll',
        audience: `https://${auth0domain}/mfa/`,
        // A custom attribute that will be forwarded to auth0 post-login action
        // This is used to check whether auth0 mfa enrollment flow should be activated
        reason: 'mfa-enrollment'
      }
    });
    return mfaAccessToken;
  }, [getAccessTokenWithPopup]);

  /**
   * Get the MFA enrollment access token.
   * @returns {Promise<string | undefined>} The MFA enrollment access token.
   */
  const getMfaDeleteAuthenticatorsAccessToken = useCallback(async (): Promise<
    string | undefined
  > => {
    const { auth0domain } = config.auth0Web;
    const mfaAccessToken = await getAccessTokenWithPopup({
      authorizationParams: {
        scope: 'read:authenticators remove:authenticators',
        audience: `https://${auth0domain}/mfa/`
      }
    });
    return mfaAccessToken;
  }, [getAccessTokenWithPopup]);

  /**
   * Get the setup data for the TOTP authenticator app from the Auth0 API.
   * @returns {Promise<BarcodeSetupData>} The setup data for the TOTP authenticator app.
   */
  const initiateEnrollment = useCallback(async (): Promise<void> => {
    await getMfaEnrollmentAccessToken();
  }, [getMfaEnrollmentAccessToken]);

  /**
   * Set the preferred MFA method for the current user via Control Plane API.
   * @param {MfaMethod} mfaMethod The preferred MFA method.
   * @returns {Promise<void>}
   */
  const setMfaPreferredMethod = useCallback(
    async (mfaMethod: MfaMethod): Promise<void> => {
      const previousMfaMethod = user.mfaPreferredMethod;
      if (previousMfaMethod === mfaMethod) {
        return;
      }
      await apiClient.updateUserDetailsMfa({ mfaPreferredMethod: mfaMethod });
    },
    [apiClient, user]
  );

  /**
   * Enable TOTP MFA.
   * 1. Verify the OTP code and activate the OTP device.
   * 2. Update the local copy of the user object in the user state manager.
   * @param {string} code The OTP code to verify.
   * @returns {Promise<void>}
   */
  const enableTotp = useCallback(async (): Promise<void> => {
    await setMfaPreferredMethod('SOFTWARE_TOKEN_MFA');
    updateUser({ mfaPreferredMethod: 'SOFTWARE_TOKEN_MFA' });
  }, [setMfaPreferredMethod, updateUser]);

  /**
   * Disable TOTP MFA.
   * @returns {Promise<void>}
   */
  const disableTotp = useCallback(async (): Promise<void> => {
    try {
      const deleteAuthenticatorsMfaAccessToken =
        await getMfaDeleteAuthenticatorsAccessToken();

      if (!deleteAuthenticatorsMfaAccessToken) {
        await setMfaPreferredMethod('NOMFA');
        throw new Error('Failed to get MFA delete authenticators access token');
      }
      const authenticatorId = await getMfaAuthenticatorId(
        deleteAuthenticatorsMfaAccessToken
      );

      await deleteMfaAuthenticator(
        deleteAuthenticatorsMfaAccessToken,
        authenticatorId
      );
      await setMfaPreferredMethod('NOMFA');
    } catch (e) {
      console.log({ e });
      const message =
        getServerErrorMessage(e) ||
        'Something went wrong while updating your mfa configuration ';
      createToast({
        type: 'danger',
        title: 'Error',
        description: message,
        duration: 5000
      });
    }
    updateUser({ mfaPreferredMethod: 'NOMFA' });
  }, [
    setMfaPreferredMethod,
    updateUser,
    getMfaDeleteAuthenticatorsAccessToken
  ]);

  return {
    isTotpEnabled,
    enableTotp,
    disableTotp,
    initiateEnrollment
  };
}
