import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer
} from '@clickhouse/click-ui';
import { OrganizationInvitationDetails } from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import { InvitationList } from 'src/components/InvitationList/InvitationList';
import {
  AcceptInvitationArgs,
  useInvitationController
} from 'src/state/notification/invitationController';

interface InvitationsModalProps {
  acceptInvitation: (args: AcceptInvitationArgs) => Promise<void>;
  invitations: OrganizationInvitationDetails[];
  invitationsModalOpen: boolean;
  onClose: () => void;
}

export default function InvitationsModal({
  acceptInvitation,
  invitations,
  invitationsModalOpen,
  onClose
}: InvitationsModalProps): ReactElement {
  const { removeExpiredInvitations } = useInvitationController();
  const handleClose = (): void => {
    removeExpiredInvitations();
    onClose();
  };
  return (
    <Dialog open={invitationsModalOpen}>
      <Dialog.Content onClose={handleClose} showClose title="Join your team">
        <Spacer size="md" />
        <InvitationList
          invitations={invitations}
          acceptInvitation={acceptInvitation}
          onClose={handleClose}
          testId="invitations-modal"
        >
          {/* {invitations.length > 0 && <Text>Or</Text>} */}
          {/* <Button type="secondary">Create new organization</Button> */}
          <Separator size="lg" />
          <Container justifyContent="end">
            <Button onClick={handleClose} type="secondary">
              Close
            </Button>
          </Container>
        </InvitationList>
      </Dialog.Content>
    </Dialog>
  );
}
