import { Container, Flyout } from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import { DashboardConfig } from 'shared/src/types/dashboard';
import { findFilterableFields } from 'src/lib/dashboard/utils';
import { useCloseDashboardFlyout } from 'src/state/dashboard';
import Filter from 'src/components/Dashboards/DashboardView/FilterConfigFlyout/Filter';

interface FilterConfigFlyoutProps {
  config: DashboardConfig;
  dashboardId: string;
}

export function FilterConfigFlyout({
  config,
  dashboardId
}: FilterConfigFlyoutProps): ReactNode {
  const closeDashboardFlyout = useCloseDashboardFlyout();

  const filterableFields = findFilterableFields(config.dashboardObjects);

  return (
    <Flyout
      open={true}
      onOpenChange={(open: boolean) => {
        if (!open) {
          closeDashboardFlyout();
        }
      }}
    >
      <Flyout.Content
        strategy="fixed"
        size="wide"
        onClick={(e) => e.stopPropagation()} // prevent container from unselecting editing element
      >
        <Flyout.Header>Filter config</Flyout.Header>
        <Flyout.Body>
          <Container orientation="vertical" padding="sm" minHeight="100%">
            {filterableFields.map((field) => {
              const filterConfig = config.dashboardFilters.config[field];

              return (
                <Filter
                  config={filterConfig}
                  dashboardId={dashboardId}
                  field={field}
                  key={field}
                />
              );
            })}
          </Container>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
}
