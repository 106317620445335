import { CaseAttachment } from '@cp/common/protocol/Support';

export function getStorageIdFromAttachment(attachment: CaseAttachment): string {
  // Check if storage id exists in CaseAttachment (new style)
  if (attachment.storageId) {
    return attachment.storageId;
  }
  // Fallback to support old style CaseAttachments
  const urlParts = attachment.downloadUrl.split('/');
  return urlParts[urlParts.length - 1];
}
