import { VoluntaryInstanceState } from '@cp/common/protocol/Instance';
import { useCallback } from 'react';
import { RefreshDataArgs } from 'src/components/TableView';
import { useApiClient } from 'src/lib/controlPlane/client';

export interface DataViewController {
  confirmInstanceStatusChange: (newState: VoluntaryInstanceState) => Promise<void>;
}
export function useDataViewController(
  instanceId: string,
  refreshData: (args?: RefreshDataArgs) => void
): DataViewController {
  const apiClient = useApiClient();

  const confirmInstanceStatusChange = useCallback(
    async (newState: VoluntaryInstanceState): Promise<void> => {
      try {
        await apiClient.instance.updateServiceState({
          instanceId,
          state: newState
        });
      } catch (e) {
        console.error(`Failed to set ${newState} state for the service ${instanceId}`, e);
      }

      refreshData({ forceWakeService: true });
    },
    [apiClient, instanceId, refreshData]
  );
  return { confirmInstanceStatusChange };
}
