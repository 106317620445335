import { Dropdown, Icon, IconButton } from '@clickhouse/click-ui';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDeleteResultsFromCache } from 'src/state/queryResults';

import styles from 'src/components/App/MainView/TabBar/styles';
import { TabProps } from 'src/components/App/MainView/TabBar/types';

export default function Tab({
  onClick,
  onClose: onCloseProp,
  onDoubleClick,
  preview,
  selected,
  title,
  type,
  status,
  menuOptions = [],
  log,
  queryRunId
}: TabProps): ReactElement {
  const [open, setOpen] = useState(false);
  const onClose = (e: MouseEvent<HTMLElement>): void => {
    log(type, 'close');
    onCloseProp(e);
  };
  const deleteResultsFromCache = useDeleteResultsFromCache();

  useEffect(() => {
    return (): void => {
      deleteResultsFromCache(queryRunId);
    };
  }, [deleteResultsFromCache, queryRunId]);

  return (
    <Dropdown
      open={open}
      onOpenChange={(open: boolean): void => {
        if (!open) {
          setOpen(false);
          log(type, 'contextMenuClose');
        }
      }}
    >
      <Dropdown.Trigger
        onContextMenu={(e: MouseEvent<HTMLDivElement>): void => {
          e.preventDefault();
          e.stopPropagation();
          log(type, 'contextMenuOpen');
          setOpen(true);
        }}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-selected={selected}
        data-testid={`tab-${selected ? 'selected' : 'inactive'}`}
        tabIndex={0}
        css={styles.tabButton}
      >
        {type === 'query' && (
          <Icon
            name={status === 'running' ? 'loading-animated' : 'query'}
            size="xs"
          />
        )}
        {(type === 'table' || type === 'createTable') && (
          <Icon name="table" size="xs" />
        )}
        <span
          className={`tab-name ${preview ? 'preview-tab' : ''} fs-exclude`}
          data-testid="tabTitle"
        >
          {title || 'Untitled Tab'}
        </span>
        <div
          className="tab-func"
          role="button"
          tabIndex={-1}
          onMouseDown={(e): void => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="content">
            {type === 'query' && status && status !== 'running' && (
              <div className="status" data-status={status} />
            )}
            <IconButton
              onClick={onClose}
              icon="cross"
              type="ghost"
              size="sm"
              className="close-btn"
              data-type="close"
            />
          </div>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content
        side="bottom"
        align="start"
        css={styles.contextContentStyle}
      >
        {menuOptions.map((option) => (
          <Dropdown.Item key={option.label} onClick={option.onClick}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
