import { Container, Checkbox } from '@clickhouse/click-ui';

const ColumnBox = ({
  columnName,
  columnType,
  canBeReplicated
}: {
  columnName: string;
  columnType: string;
  canBeReplicated: boolean;
}) => {
  return (
    <Container orientation="vertical">
      <Container orientation="horizontal" gap="md">
        <Checkbox
          key={`postgres-clickpipe-source-column-${columnName}`}
          onCheckedChange={(state) => {}}
          checked={true}
          disabled={!canBeReplicated}
          data-testid="postgres-clickpipes-column-checkbox"
          label={columnName}
        />
      </Container>
    </Container>
  );
};

export default ColumnBox;
