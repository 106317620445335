import { MouseEvent, ReactElement, useEffect } from 'react';
import { CardModal, createToast } from 'primitives';
import { LogFn } from 'src/lib/logger';
import { minWidthStyle } from 'src/components/QueryView/Toolbar/style';
import { getModifierKeyPrefix } from 'src/lib/modiferKeyPrefix';
import GptConstructionTextField from 'src/components/QueryView/GptConstructionTextField';
import { useHotkeys } from 'react-hotkeys-hook';
import { logEvent } from 'src/components/QueryView/analytics';
import { useGptConsent } from 'src/lib/gpt/GptConsentHook';
import { useGptCorrection } from 'src/lib/gpt/GptCorrectionHook';
import GptConsentModal from 'src/components/QueryView/GptConsentModal';
import { Button, Icon, SplitButton, Tooltip } from '@clickhouse/click-ui';
import { useGptPopups } from 'src/lib/gpt/GptPopupsHook';
interface GptCompleteButtonProps {
  isRunning: boolean;
  constructQuery: (value: string) => Promise<void>;
  cancelConstructionRequest: () => void;
  log: LogFn;
  runId: string;
}

const GptCompleteButton = ({
  constructQuery,
  cancelConstructionRequest,
  isRunning,
  log,
  runId
}: GptCompleteButtonProps): ReactElement => {
  const {
    isGptConstructionPopupVisible,
    showGptConstructionPopup,
    hideGptConstructionPopup
  } = useGptPopups();

  useHotkeys(
    'meta+k, ctrl+k',
    () => {
      logEvent('editor', 'gptCompleteButton', 'shortcut');
      showGptConstructionPopup();
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      preventDefault: true
    },
    [showGptConstructionPopup]
  );
  const { hasConsent, acceptConsent, loadState } = useGptConsent();
  const { getCorrectedQueryText, isRequestRunning } = useGptCorrection();
  const correctedQuery = getCorrectedQueryText(runId);
  const buttonDisabled = !!correctedQuery || isRequestRunning;

  useEffect(() => {
    if (loadState === 'error') {
      createToast(
        'Error',
        'alert',
        'Something went wrong while fetching GPT consent'
      );
    }
  }, [loadState]);

  if (isRunning) {
    const onClick = (): void => {
      cancelConstructionRequest();
      log('GptCompleteCancelButton', 'click');
    };
    return (
      <div css={minWidthStyle(90)}>
        <Button
          iconLeft="loading-animated"
          data-testid="cancelGptCompletionBtn"
          onClick={onClick}
          fillWidth
        >
          Cancel
        </Button>
      </div>
    );
  }

  const modifierKeyPrefix = getModifierKeyPrefix();

  const options = [
    {
      label: 'Revoke access to DB schema',
      disabled: !hasConsent,
      onClick: (e: MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        log('generateSqlOptionsRevokeConsent', 'click');
        acceptConsent(false).catch(console.error);
      },
      'data-testid': 'revokeConsentOption'
    }
  ];

  const onVisibleCancel = (): void => hideGptConstructionPopup();

  return (
    <div css={minWidthStyle(90)}>
      <Tooltip>
        <Tooltip.Trigger
          onClick={(e: MouseEvent<HTMLElement>): void => {
            e.stopPropagation();
          }}
        >
          <SplitButton
            type="secondary"
            onClick={(): void => {
              logEvent('GptCompleteButton', 'click');
              showGptConstructionPopup();
            }}
            menu={options}
            disabled={buttonDisabled}
            data-testid="gptCompleteBtn"
            icon="sparkle"
          >
            SQL AI
          </SplitButton>
        </Tooltip.Trigger>
        <Tooltip.Content>
          Construct query with GPT | {modifierKeyPrefix} + K
        </Tooltip.Content>
      </Tooltip>
      <CardModal
        open={isGptConstructionPopupVisible}
        onClose={onVisibleCancel}
        onBackdropClick={onVisibleCancel}
        onEscapeKeyDown={onVisibleCancel}
        headerText=""
        width="auto"
        showOverlay
        showCloseButton={false}
      >
        <GptConstructionTextField
          onSubmit={(value: string): void => {
            logEvent('GptConstructionTextField', 'submit', 'keypress');
            hideGptConstructionPopup();
            if (!value) {
              createToast('Error', 'alert', 'Missing request text');
              return;
            }
            constructQuery(value).catch((error) => {
              const message =
                error instanceof Error ? error.message : 'Something went wrong';
              createToast('Error', 'alert', message);
            });
          }}
          icon={<Icon name="sparkle" />}
        />
      </CardModal>
      <GptConsentModal />
    </div>
  );
};

export default GptCompleteButton;
