import {
  ContextMenu,
  EllipsisContent,
  SidebarCollapsibleItem,
  Text
} from '@clickhouse/click-ui';
import { flex } from '@utility-styles';
import debounce from 'lodash/debounce';
import { ReactElement } from 'react';
import { logger } from 'src/lib/logger';

import { getCustomColumnType } from 'shared/src/clickhouse/columns';
import { Table } from 'shared/src/clickhouse/types';
import { TableRightBarOption } from 'src/state/tabs/types';

import styles from 'src/components/TableView/TableList/Table/styles';

export interface TableListingProps {
  table: Table;
  selected: boolean;
  onTableChange: (table: Table, rightBarType?: 'tableSchema') => void;
  onToggleTable: (tableName: string) => void;
  expanded: boolean;
  tableRightBarType?: TableRightBarOption[];
  tableName: string | undefined;
}

function logEvent(name: string, type?: string): void {
  void logger.track({
    view: 'sidebar',
    component: 'tableList',
    event: `table${name}${type || ''}`,
    interaction: 'click'
  });
}

function onToggle(open: boolean): void {
  logEvent('ContextMenu', open ? 'Open' : 'Blur');
}

export const TableListing = function TableListing({
  table,
  selected,
  onTableChange,
  onToggleTable,
  expanded,
  tableRightBarType,
  tableName
}: TableListingProps): ReactElement {
  const openTableWithSchema = debounce(
    (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const showSchema = event
        ? event?.detail === 2
        : tableName !== undefined && tableName === table.tableName
        ? !(tableRightBarType || []).includes('tableSchema')
        : true;

      onTableChange(
        table,
        (!event || event?.detail !== 1) && showSchema
          ? 'tableSchema'
          : undefined
      );
      logEvent(
        event ? 'Open' : `ContextMenuSchema${showSchema ? 'Show' : 'Hide'}`
      );
    },
    100,
    {
      leading: false,
      trailing: true
    }
  );

  const onOpenChange = (): void => {
    onToggleTable(table.tableName);
  };
  return (
    <ContextMenu onOpenChange={onToggle} key={table.tableName}>
      <ContextMenu.Trigger>
        <SidebarCollapsibleItem
          type="sqlSidebar"
          selected={selected}
          open={expanded}
          onOpenChange={onOpenChange}
          icon="table"
          label={
            <EllipsisContent className="fs-exclude">
              {table.tableName}
            </EllipsisContent>
          }
          onClick={openTableWithSchema}
          data-testid={`table-item-${encodeURIComponent(table.tableName)}`}
        >
          <div css={styles.columns}>
            {expanded &&
              table.columns.map((col) => (
                <div key={col.name} css={styles.column}>
                  <Text color="muted" weight="mono" className="name" css={flex}>
                    <EllipsisContent className="fs-exclude">
                      {col.name}
                    </EllipsisContent>
                  </Text>
                  <Text color="muted" weight="mono" className="type" css={flex}>
                    <EllipsisContent>
                      {getCustomColumnType(col.type)}
                    </EllipsisContent>
                  </Text>
                </div>
              ))}
          </div>
        </SidebarCollapsibleItem>
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        <ContextMenu.Item
          onClick={(): void => {
            onTableChange(table);
            logEvent('ContextMenuTableOpen');
          }}
        >
          View table
        </ContextMenu.Item>
        <ContextMenu.Item onClick={(): void => openTableWithSchema()}>
          {(tableRightBarType || []).includes('tableSchema') &&
          tableName !== undefined &&
          tableName === table.tableName
            ? 'Hide table schema'
            : 'Show table schema'}
        </ContextMenu.Item>
        {!expanded && (
          <ContextMenu.Item
            onClick={(): void => {
              onToggleTable(table.tableName);
            }}
          >
            Expand parameters
          </ContextMenu.Item>
        )}
      </ContextMenu.Content>
    </ContextMenu>
  );
};
