import { internalQuerySetting } from 'shared/src/clickhouse';
import { internalDatabaseUsers } from 'shared/src/clickhouse/internalDatabaseUsers';
import {
  SelectableTimePeriod,
  getAggregatePeriod,
  getSecondsInAggregatePeriod,
  getSecondsInSQLInterval,
  getTimePeriodSQLInterval
} from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { QueryRunner, useQueryRunner } from 'src/lib/clickhouse/query';
import config from 'src/lib/config';
import { clusterIfNecessary } from 'shared/src/sql/query';

export type QueryType = 'allQueries' | 'slowQueries' | 'errors';

export const getLabelForQueryType = (queryType: QueryType): string => {
  switch (queryType) {
    case 'allQueries': {
      return 'Query volume';
    }
    case 'slowQueries': {
      return 'Latency';
    }
    case 'errors': {
      return 'Errors';
    }
  }
};

export type SortOrder = 'ASC' | 'DESC';

export const useGetRecentQueries = (
  timePeriod: SelectableTimePeriod,
  sortField: string = "'Last run (UTC)'",
  sortOrder: SortOrder = 'ASC'
): QueryRunner => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);

  const sql = `SELECT
    normalizeQuery(any(query)) AS Query,
    user AS User,
    uniq(query_id) AS "Runs",
    uniqIf(query_id, exception_code != 0) as "Errors",
    arrayFilter(x -> x NOT LIKE '%.inner_id%', any(tables)) AS Table,
    quantilesTiming(0.5, 0.9, 0.99)(query_duration_ms / 1000) AS query_performance_raw,
    query_performance_raw[1] AS "p50 (s)",
    query_performance_raw[2] AS "p90 (s)",
    query_performance_raw[3] AS "p99 (s)",
    round(avg(written_rows)) AS "Avg. Written rows",
    round(avg(read_rows)) AS "Avg. Read rows",
    avg(memory_usage) AS "Avg. Mem Usage",
    MAX(event_time) AS "Last run (UTC)",
    normalized_query_hash
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
   event_date >= toDate(NOW() - INTERVAL ${interval})
   AND event_time >= NOW() - INTERVAL ${interval}
   AND is_initial_query = 1
   AND type != 'QueryStart'
   AND user not in (
    ${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')}
    )
    AND query_kind != 'AsyncInsertFlush'
    AND log_comment != 'sql console internal query'
GROUP BY
    normalized_query_hash,
    User
ORDER BY
    ${sortField} ${sortOrder}
SETTINGS ${internalQuerySetting};`;
  return useQueryRunner({ sql });
};

export const useGetQueryTypeForTimePeriod = (timePeriod: SelectableTimePeriod, queryType: QueryType): QueryRunner => {
  let sql;

  switch (queryType) {
    case 'allQueries': {
      sql = getAllQueriesSql(timePeriod);
      break;
    }
    case 'errors': {
      sql = getErrorQueriesSql(timePeriod);
      break;
    }
    case 'slowQueries': {
      sql = getSlowQueriesSql(timePeriod);
      break;
    }
    default: {
      sql = getAllQueriesSql(timePeriod);
    }
  }

  return useQueryRunner({ sql });
};

const getAllQueriesSql = (timePeriod: SelectableTimePeriod): string => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const aggregatePeriod = getAggregatePeriod(timePeriod);
  const secondsInInterval = getSecondsInSQLInterval(interval);
  const secondsInAggregatePeriod = getSecondsInAggregatePeriod(aggregatePeriod);

  return `WITH toStartOfInterval(
    now() - interval ${interval},
    interval ${aggregatePeriod}
) AS min_time,
toUInt32(${secondsInInterval}) AS duration,
toUInt32(${secondsInAggregatePeriod}) AS step,
timeSlots(min_time, duration, step) AS periods,
[ 'Select','Insert','Other' ] AS kinds,
interval_kinds AS (
    SELECT
        arrayJoin(periods) AS period,
        arrayJoin(kinds) AS kind
),
data AS (
    SELECT
        toStartOfInterval(event_time, interval ${aggregatePeriod}) AS period,
        if(
            query_kind not in ('Select', 'Insert'),
            'Other',
            query_kind
        ) AS kind,
 uniq(query_id) AS count
    FROM
        ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
    WHERE
        event_date >= toDate(NOW() - INTERVAL ${interval})
        AND toStartOfInterval(event_time, INTERVAL ${aggregatePeriod}) >= NOW() - INTERVAL ${interval}
        AND period <= toStartOfInterval(NOW(), INTERVAL ${aggregatePeriod})
        AND log_comment != 'sql console internal query'
        AND user not in (
            ${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')}
        )
        AND type != 'QueryStart'
        AND query_kind != 'AsyncInsertFlush'
        AND is_initial_query = 1
    GROUP BY
        period,
        kind
    ORDER BY
        period ASC
)
SELECT
    i.period,
    i.kind,
    data.count
FROM
    interval_kinds AS i
    left join data on data.period = i.period
    and data.kind = i.kind
ORDER BY
    i.period asc,
    i.kind asc
SETTINGS ${internalQuerySetting};`;
};

const getSlowQueriesSql = (timePeriod: SelectableTimePeriod): string => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const aggregatePeriod = getAggregatePeriod(timePeriod);
  const secondsInInterval = getSecondsInSQLInterval(interval);
  const secondsInAggregatePeriod = getSecondsInAggregatePeriod(aggregatePeriod);

  return `WITH toStartOfInterval(
    now() - interval ${interval},
    interval ${aggregatePeriod}
) AS min_time,
toUInt32(${secondsInInterval}) AS duration,
toUInt32(${secondsInAggregatePeriod}) AS step,
timeSlots(min_time, duration, step) AS periods,
[ 'Select','Insert','Other' ] AS kinds,
interval_kinds AS (
    select
        arrayJoin(periods) AS period,
        arrayJoin(kinds) AS kind
),
data AS (
SELECT
    toStartOfInterval(event_time, INTERVAL ${aggregatePeriod}) AS period,
    if(query_kind not in ('Select','Insert'), 'Other',query_kind) AS kind,
   quantilesTiming(0.5,0.9,0.99)(query_duration_ms) AS latency
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    event_date >= toDate(NOW() - INTERVAL ${interval})
    AND toStartOfInterval(event_time, interval ${aggregatePeriod}) >= NOW() - INTERVAL ${interval}
    AND period <= toStartOfInterval(NOW(), interval ${aggregatePeriod})
    AND log_comment != 'sql console internal query'
    AND user NOT IN (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    AND type = 'QueryFinish'
    AND query_kind != 'AsyncInsertFlush'
    AND is_initial_query = 1
GROUP BY
    period,
    kind
)
SELECT
    i.period,
    i.kind,
    data.latency
FROM
    interval_kinds AS i
    left join data on data.period = i.period
    and data.kind = i.kind
ORDER BY
    i.period asc,
    i.kind asc
SETTINGS ${internalQuerySetting}`;
};

const getErrorQueriesSql = (timePeriod: SelectableTimePeriod): string => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const aggregatePeriod = getAggregatePeriod(timePeriod);

  return `SELECT
    toStartOfInterval(event_time, INTERVAL ${aggregatePeriod}) as period,
    uniq(query_id) as count
FROM
   ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    event_date >= toDate(NOW() - INTERVAL ${interval})
    AND toStartOfInterval(event_time, interval ${aggregatePeriod}) >= NOW() - INTERVAL ${interval}
    AND log_comment != 'sql console internal query'
    AND user NOT IN (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    AND is_initial_query = 1
    AND exception_code != 0
GROUP BY
    period
ORDER BY
    period ASC WITH FILL FROM NOW() - INTERVAL ${interval} TO NOW() STEP INTERVAL ${aggregatePeriod} INTERPOLATE (count as 0)
SETTINGS ${internalQuerySetting};`;
};

export const useGetQueryLatencyCount = (timePeriod: SelectableTimePeriod): QueryRunner => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);

  const sql = `SELECT
    quantilesTiming(0.5,0.9,0.99)(query_duration_ms) AS latency
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    event_date >= toDate(NOW() - INTERVAL ${interval})
    AND event_time >= NOW() - interval ${interval}
    AND type != 'QueryStart'
    AND is_initial_query = 1
    AND user NOT IN (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
SETTINGS ${internalQuerySetting}`;

  return useQueryRunner({ sql });
};

export const useGetFlyoutDetails = (timePeriod: SelectableTimePeriod, queryHash: string): QueryRunner => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const sql = `SELECT
    normalizeQuery(any(query)) as normalized_query,
    max(event_time) as last_run,
    countIf(type='QueryFinish') as successful_runs,
    countIf(exception != '') as num_errors,
    groupUniqArray(user) as users,
    quantilesTiming(0.5, 0.9, 0.99)(query_duration_ms) as query_performance_raw,
    query_performance_raw[1] / 1000 as p50,
    query_performance_raw[2] / 1000 as p90,
    query_performance_raw[3] / 1000 as p99,
    round(avg(written_rows),2) AS "Avg. Written rows",
    round(avg(read_rows),2) AS "Avg. Read rows",
    avg(memory_usage) AS "Avg. Mem Usage"
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    event_date >= toDate(NOW() - INTERVAL ${interval})
    AND event_time >= NOW() - interval ${interval}
    AND user not in (
      ${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')}
      )
    AND normalized_query_hash = '${queryHash}'
    AND type != 'QueryStart'
GROUP BY
    normalized_query_hash
SETTINGS ${internalQuerySetting};`;

  return useQueryRunner({ sql });
};

export const useGetFlyoutQueryTypeForTimePeriod = (
  timePeriod: SelectableTimePeriod,
  queryType: QueryType,
  queryHash: string
): QueryRunner => {
  let sql;

  switch (queryType) {
    case 'allQueries': {
      sql = getAllQueriesFlyoutSql(timePeriod, queryHash);
      break;
    }
    case 'errors': {
      sql = getErrorQueriesFlyoutSql(timePeriod, queryHash);
      break;
    }
    case 'slowQueries': {
      sql = getSlowQueriesFlyoutSql(timePeriod, queryHash);
      break;
    }
    default: {
      sql = getAllQueriesFlyoutSql(timePeriod, queryHash);
    }
  }

  return useQueryRunner({ sql });
};

const getAllQueriesFlyoutSql = (timePeriod: SelectableTimePeriod, queryHash: string): string => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const aggregatePeriod = getAggregatePeriod(timePeriod);

  return `SELECT
    toStartOfInterval(event_time, INTERVAL ${aggregatePeriod}) as period,
    uniq(query_id) as count
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    normalized_query_hash = '${queryHash}'
    AND event_date >= toDate(NOW() - INTERVAL ${interval})
    AND toStartOfInterval(event_time, interval ${aggregatePeriod}) >= NOW() - INTERVAL ${interval}
    AND log_comment != 'sql console internal query'
    AND user NOT IN (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    AND type != 'QueryStart'
    AND is_initial_query = 1
GROUP BY
    period,
    normalized_query_hash
ORDER BY
    period ASC WITH FILL FROM NOW() - INTERVAL ${interval} TO NOW() STEP INTERVAL ${aggregatePeriod} INTERPOLATE (count as 0)
SETTINGS ${internalQuerySetting};`;
};

const getSlowQueriesFlyoutSql = (timePeriod: SelectableTimePeriod, queryHash: string): string => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const aggregatePeriod = getAggregatePeriod(timePeriod);

  return `SELECT
    toStartOfInterval(event_time, INTERVAL ${aggregatePeriod}) as period,
    uniq(query_id) as count
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    normalized_query_hash = '${queryHash}'
    AND period > NOW() - INTERVAL ${interval}
    AND log_comment != 'sql console internal query'
    AND user NOT IN (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    AND type = 'QueryFinish'
    AND is_initial_query = 1
GROUP BY
    period
HAVING
    avg(query_duration_ms) > quantileExact(query_duration_ms)
ORDER BY
    period ASC WITH FILL STEP INTERVAL ${aggregatePeriod} INTERPOLATE (count as 0)
SETTINGS ${internalQuerySetting};`;
};

const getErrorQueriesFlyoutSql = (timePeriod: SelectableTimePeriod, queryHash: string): string => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);
  const aggregatePeriod = getAggregatePeriod(timePeriod);

  return `SELECT
    toStartOfInterval(event_time, INTERVAL ${aggregatePeriod}) as period,
    uniq(query_id) as count
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    normalized_query_hash = '${queryHash}'
    AND period > NOW() - INTERVAL ${interval}
    AND log_comment != 'sql console internal query'
    AND user NOT IN (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    AND is_initial_query = 1
    AND exception_code != 0
GROUP BY
    period
ORDER BY
    period ASC WITH FILL FROM NOW() - INTERVAL ${interval} TO NOW() STEP INTERVAL ${aggregatePeriod} INTERPOLATE (count as 0)
SETTINGS ${internalQuerySetting};`;
};

export const useGetErrorsForTimePeriodAndQueryHash = (
  timePeriod: SelectableTimePeriod,
  queryHash: string
): QueryRunner => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);

  const sql = `SELECT
    event_time,
    query_duration_ms,
    user,
    exception
FROM
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
    normalized_query_hash=${queryHash}
    AND exception_code != 0
    AND user not in (
      ${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')}
      )
    AND event_date > NOW() - INTERVAL ${interval}
    AND event_time > NOW() - INTERVAL ${interval}
    AND event_date <= NOW()
    AND event_time <= NOW()
SETTINGS ${internalQuerySetting};`;

  return useQueryRunner({ sql });
};

export const useGetFlyoutHistory = (timePeriod: SelectableTimePeriod, queryHash: string): QueryRunner => {
  const interval: string = getTimePeriodSQLInterval(timePeriod);

  const sql = `SELECT
  event_time AS period,
  multiIf(exception_code=394,'cancelled',type='QueryFinish','completed','failed') AS status,
  query_id,
  user,
  query_duration_ms / 1000 AS query_duration
FROM
  ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
  period > NOW() - interval ${interval}
  AND normalized_query_hash = '${queryHash}'
  AND user not in (
    ${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')}
    )
  AND type != 'QueryStart'
  AND is_initial_query = 1
ORDER BY event_time DESC
SETTINGS ${internalQuerySetting};`;

  return useQueryRunner({ sql });
};

export const useGetQueryRunDetails = (queryId: string): QueryRunner => {
  const sql = `SELECT
  written_rows,
  read_rows,
  result_rows,
  formatReadableDecimalSize(result_bytes) AS result_size,
  formatReadableSize(memory_usage) AS mem_usage,
  ProfileEvents AS query_profiling,
  Settings AS query_settings,
  exception_code,
  exception,
  ifNull(formatQueryOrNull(query), query) as formatted_query
FROM
  ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
WHERE
  query_id = '${queryId}'
  and type != 'QueryStart'
SETTINGS ${internalQuerySetting};`;

  return useQueryRunner({ sql });
};
