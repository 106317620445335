import { BusinessType, GetOrganizationBillingDetailsResponse } from '@cp/common/protocol/Billing';
import { CompanySize } from '@cp/common/protocol/Organization';
import { isTaxSupportedCountry, TaxIdDatumType, TaxStatusType } from '@cp/common/protocol/Stripe';
import { isString } from '@cp/common/utils/ValidationUtils';
import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { useState } from 'react';
import { STATES_SELECT } from 'src/billing/common/stateSelection';
import { validateAddress } from 'src/billing/validators/billingValidators';
import { Setter } from 'src/metadata/metadataState';

export type BillingFormDetails = Pick<
  GetOrganizationBillingDetailsResponse,
  | 'shippingAddress'
  | 'bothAddressesSame'
  | 'organizationIsABusiness'
  | 'billingAddress'
  | 'companyName'
  | 'taxId'
  | 'taxIdType'
  | 'taxStatus'
  | 'businessType'
>;

export type FormFieldValue = string | undefined;
export type FormFieldSetter = Setter<string | undefined>;
type FormFieldError = string | undefined;

const defaultFormState: BillingFormDetails = {
  shippingAddress: {},
  bothAddressesSame: true,
  organizationIsABusiness: false,
  billingAddress: {},
  companyName: undefined,
  taxId: undefined,
  taxIdType: undefined,
  taxStatus: undefined
};
export const isEmpty = (value: string | undefined): boolean => {
  return !value || value === '';
};
const formStateAtom = atom<BillingFormDetails>(defaultFormState);
const numEmployeesAtom = atom<CompanySize | undefined>(undefined);
const websiteUrlAtom = atom<string | undefined>(undefined);

export const useFormState = () => {
  return useAtom(formStateAtom);
};

export const useBillingAddressLine1 = (): {
  billingAddressLine1: FormFieldValue;
  setBillingAddressLine1: FormFieldSetter;
  billingAddressLine1Error: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [billingAddressLine1Error, setError] = useState<string | undefined>(undefined);
  const billingAddressLine1 = formDetails.billingAddress?.line1;
  const setBillingAddressLine1 = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a valid billing address');
    } else {
      setError(undefined);
    }
    setFormDetails((existingFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(existingFormDetails, (draft) => {
        draft.billingAddress = draft.billingAddress ?? {};
        draft.billingAddress.line1 = value;
      });
      return newFormDetails;
    });
  };
  return { billingAddressLine1, setBillingAddressLine1, billingAddressLine1Error };
};

export const useBillingAddressLine2 = (): {
  billingAddressLine2: FormFieldValue;
  setBillingAddressLine2: FormFieldSetter;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const billingAddressLine2 = formDetails.billingAddress?.line2 || '';
  const setBillingAddressLine2 = (value: string | undefined): void => {
    setFormDetails((existingFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(existingFormDetails, (draft) => {
        draft.billingAddress = draft.billingAddress ?? {};
        draft.billingAddress.line2 = value;
      });
      return newFormDetails;
    });
  };
  return { billingAddressLine2, setBillingAddressLine2 };
};

export const useBillingAddressCity = (): {
  billingAddressCity: FormFieldValue;
  setBillingAddressCity: FormFieldSetter;
  billingAddressCityError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [billingAddressCityError, setError] = useState<string | undefined>(undefined);
  const billingAddressCity = formDetails.billingAddress?.city;
  const setBillingAddressCity = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a valid city');
    } else {
      setError(undefined);
    }
    setFormDetails((formDetails: BillingFormDetails) => {
      const newFormDetails = produce(formDetails, (draft) => {
        draft.billingAddress = draft.billingAddress ?? {};
        draft.billingAddress.city = value;
      });
      return newFormDetails;
    });
  };
  return { billingAddressCity, setBillingAddressCity, billingAddressCityError };
};

export const useBillingAddressPostalCode = (): {
  billingAddressPostalCode: FormFieldValue;
  setBillingAddressPostalCode: FormFieldSetter;
  billingAddressPostalCodeError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [billingAddressPostalCodeError, setError] = useState<string | undefined>(undefined);
  const billingAddressPostalCode = formDetails.billingAddress?.postalCode;
  const setBillingAddressPostalCode = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a valid postal code');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.billingAddress = draft.billingAddress ?? {};
        draft.billingAddress.postalCode = value;
      });
      return newFormDetails;
    });
  };
  return { billingAddressPostalCode, setBillingAddressPostalCode, billingAddressPostalCodeError };
};

export const useBillingAddressCountry = (): {
  billingAddressCountry: FormFieldValue;
  setBillingAddressCountry: FormFieldSetter;
  billingAddressCountryError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [billingAddressCountryError, setError] = useState<string | undefined>(undefined);
  const billingAddressCountry = formDetails.billingAddress?.country;
  const setBillingAddressCountry = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please select a country');
      return;
    } else if (formDetails.billingAddress?.country === value) {
      return;
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.billingAddress = draft.billingAddress ?? {};
        draft.billingAddress.country = value;
        draft.billingAddress.state = undefined; // Resets state when country changes
        draft.taxIdType = undefined;
        draft.taxStatus = isString(value) && isTaxSupportedCountry(value) ? undefined : 'unsupported';
        draft.taxId = undefined;
      });
      return newFormDetails;
    });
  };
  return { billingAddressCountry, setBillingAddressCountry, billingAddressCountryError };
};

export const useBillingAddressState = (): {
  billingAddressState: FormFieldValue;
  setBillingAddressState: FormFieldSetter;
  billingAddressStateError: FormFieldError;
  stateAvailable: boolean;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [billingAddressStateError, setError] = useState<string | undefined>(undefined);
  const billingAddressState = formDetails.billingAddress?.state;
  const stateAvailable =
    formDetails.billingAddress?.country !== undefined && STATES_SELECT[formDetails.billingAddress.country]?.length > 0;
  const setBillingAddressState = (value: string | undefined): void => {
    if (isEmpty(value) && stateAvailable) {
      setError('Please select a state / province');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.billingAddress = draft.billingAddress ?? {};
        draft.billingAddress.state = value;
      });
      return newFormDetails;
    });
  };
  return { billingAddressState, setBillingAddressState, billingAddressStateError, stateAvailable };
};

export const useAddressesSame = (): { bothAddressesSame: boolean; setBothAddressesSame: Setter<boolean> } => {
  const [formDetails, setFormDetails] = useFormState();
  const bothAddressesSame = formDetails.bothAddressesSame ?? true;
  const setBothAddressesSame = (value: boolean): void => {
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.bothAddressesSame = value;
        draft.shippingAddress = value ? draft.billingAddress : draft.shippingAddress ?? {};
      });
      return newFormDetails;
    });
  };
  return { bothAddressesSame, setBothAddressesSame };
};

export const useShippingAddressLine1 = (): {
  shippingAddressLine1: FormFieldValue;
  setShippingAddressLine1: FormFieldSetter;
  shippingAddressLine1Error: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [shippingAddressLine1Error, setError] = useState<string | undefined>(undefined);
  const shippingAddressLine1 = formDetails.shippingAddress?.line1;
  const setShippingAddressLine1 = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a valid shipping address');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.shippingAddress = draft.shippingAddress ?? {};
        draft.shippingAddress.line1 = value;
      });
      return newFormDetails;
    });
  };
  return { shippingAddressLine1, setShippingAddressLine1, shippingAddressLine1Error };
};

export const useShippingAddressLine2 = (): {
  shippingAddressLine2: FormFieldValue;
  setShippingAddressLine2: FormFieldSetter;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const shippingAddressLine2 = formDetails.shippingAddress?.line2;
  const setShippingAddressLine2 = (value: string | undefined): void => {
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.shippingAddress = draft.shippingAddress ?? {};
        draft.shippingAddress.line2 = value;
      });
      return newFormDetails;
    });
  };
  return { shippingAddressLine2, setShippingAddressLine2 };
};

export const useShippingAddressCity = (): {
  shippingAddressCity: FormFieldValue;
  setShippingAddressCity: FormFieldSetter;
  shippingAddressCityError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [shippingAddressCityError, setError] = useState<string | undefined>(undefined);
  const shippingAddressCity = formDetails.shippingAddress?.city;
  const setShippingAddressCity = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a valid city');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.shippingAddress = draft.shippingAddress ?? {};
        draft.shippingAddress.city = value;
      });
      return newFormDetails;
    });
  };
  return { shippingAddressCity, setShippingAddressCity, shippingAddressCityError };
};

export const useShippingAddressPostalCode = (): {
  shippingAddressPostalCode: FormFieldValue;
  setShippingAddressPostalCode: FormFieldSetter;
  shippingAddressPostalCodeError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [shippingAddressPostalCodeError, setError] = useState<string | undefined>(undefined);
  const shippingAddressPostalCode = formDetails.shippingAddress?.postalCode;
  const setShippingAddressPostalCode = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a valid postal code');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.shippingAddress = draft.shippingAddress ?? {};
        draft.shippingAddress.postalCode = value;
      });
      return newFormDetails;
    });
  };
  return { shippingAddressPostalCode, setShippingAddressPostalCode, shippingAddressPostalCodeError };
};

export const useShippingAddressCountry = (): {
  shippingAddressCountry: FormFieldValue;
  setShippingAddressCountry: FormFieldSetter;
  shippingAddressCountryError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [shippingAddressCountryError, setError] = useState<string | undefined>(undefined);
  const shippingAddressCountry = formDetails.shippingAddress?.country;
  const setShippingAddressCountry = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please select a country');
      return;
    } else if (formDetails.shippingAddress?.country === value) {
      return;
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.shippingAddress = draft.shippingAddress ?? {};
        draft.shippingAddress.country = value;
        draft.shippingAddress.state = undefined;
        draft.taxIdType = undefined;
        draft.taxStatus = isString(value) && isTaxSupportedCountry(value) ? undefined : 'unsupported';
        draft.taxId = undefined;
      });
      return newFormDetails;
    });
  };
  return { shippingAddressCountry, setShippingAddressCountry, shippingAddressCountryError };
};

export const useShippingAddressState = (): {
  shippingAddressState: FormFieldValue;
  setShippingAddressState: FormFieldSetter;
  shippingAddressStateError: FormFieldError;
  stateAvailable: boolean;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [shippingAddressStateError, setError] = useState<string | undefined>(undefined);
  const shippingAddressState = formDetails.shippingAddress?.state;
  const stateAvailable =
    formDetails.shippingAddress?.country !== undefined &&
    STATES_SELECT[formDetails.shippingAddress.country]?.length > 0;
  const setShippingAddressState = (value: string | undefined): void => {
    if (isEmpty(value) && stateAvailable) {
      setError('Please select a state / province');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.shippingAddress = draft.shippingAddress ?? {};
        draft.shippingAddress.state = value;
      });
      return newFormDetails;
    });
  };
  return { shippingAddressState, setShippingAddressState, shippingAddressStateError, stateAvailable };
};

export const useOrganizationType = (): [boolean, Setter<boolean>] => {
  const [formDetails, setFormDetails] = useFormState();
  const organizationIsABusiness = formDetails.organizationIsABusiness ?? false;
  const setOrganizationIsABusiness = (value: boolean): void => {
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      return produce(oldFormDetails, (draft) => {
        draft.organizationIsABusiness = value;
        draft.taxId = !value ? undefined : draft.taxId;
        draft.taxStatus = !value ? undefined : 'unregistered';
        draft.taxIdType = !value ? undefined : draft.taxIdType;
        draft.businessType = !value ? undefined : draft.businessType;
        draft.companyName = !value ? undefined : draft.companyName;
      });
    });
  };
  return [organizationIsABusiness, setOrganizationIsABusiness];
};

export const useNumEmployees = (): {
  numEmployees: CompanySize | undefined;
  setNumEmployees: Setter<CompanySize | undefined>;
} => {
  const [numEmployees, setNumEmployees] = useAtom(numEmployeesAtom);
  return { numEmployees, setNumEmployees };
};

export const useBusinessType = (): {
  businessType: BusinessType | undefined;
  setBusinessType: (businessType: BusinessType) => void;
  businessTypeError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [businessTypeError, setError] = useState<string | undefined>(undefined);
  const businessType = formDetails.businessType;
  const setBusinessType = (businessType: BusinessType | undefined): void => {
    if (isEmpty(businessType)) {
      setError('Please enter a business type');
    } else {
      setError(undefined);
    }
    setFormDetails((formDetails: BillingFormDetails) => {
      const newFormDetails = produce(formDetails, (draft) => {
        draft.businessType = businessType;
      });
      return newFormDetails;
    });
  };
  return { businessType, setBusinessType, businessTypeError };
};

export const useWebsiteUrl = (): { websiteUrl: string | undefined; setWebsiteUrl: Setter<string | undefined> } => {
  const [websiteUrl, setWebsiteUrl] = useAtom(websiteUrlAtom);
  return { websiteUrl, setWebsiteUrl };
};

export const useCompanyName = (): {
  companyName: FormFieldValue;
  setCompanyName: FormFieldSetter;
  companyNameError: FormFieldError;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [companyNameError, setError] = useState<string | undefined>(undefined);
  const companyName = formDetails.companyName;
  const setCompanyName = (value: string | undefined): void => {
    if (isEmpty(value)) {
      setError('Please enter a company or organization name');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.companyName = value;
      });
      return newFormDetails;
    });
  };
  return { companyName, setCompanyName, companyNameError };
};

export const useTaxId = (): {
  taxId: FormFieldValue;
  setTaxId: FormFieldSetter;
  taxIdError: FormFieldError;
  needsTaxId: boolean;
} => {
  const [formDetails, setFormDetails] = useFormState();
  const [taxIdError, setError] = useState<string | undefined>(undefined);
  const taxId = formDetails.taxId;
  const needsTaxId = formDetails.organizationIsABusiness === true && formDetails.taxStatus === 'registered';
  const setTaxId = (value: string | undefined): void => {
    if (isEmpty(value) && needsTaxId) {
      setError('Please enter a valid tax ID number');
    } else {
      setError(undefined);
    }
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.taxId = value;
      });
      return newFormDetails;
    });
  };
  return { taxId, setTaxId, taxIdError, needsTaxId };
};

type TaxInfo = {
  taxStatus: TaxStatusType | undefined;
  taxIdType: TaxIdDatumType | undefined;
};

export const useTaxInfo = (): { taxInfo: TaxInfo; setTaxInfo: Setter<TaxInfo> } => {
  const [formDetails, setFormDetails] = useFormState();
  const taxInfo = {
    taxStatus: formDetails.taxStatus,
    taxIdType: formDetails.taxIdType
  };
  const setTaxInfo = ({ taxStatus, taxIdType }: TaxInfo): void => {
    setFormDetails((oldFormDetails: BillingFormDetails) => {
      const newFormDetails = produce(oldFormDetails, (draft) => {
        draft.taxStatus = taxStatus;
        draft.taxIdType = taxIdType;
      });
      return newFormDetails;
    });
  };
  return { taxInfo, setTaxInfo };
};

export const useIsAddressFormValid = (): boolean => {
  const { billingAddressLine1 } = useBillingAddressLine1();
  const { billingAddressLine2 } = useBillingAddressLine2();
  const { billingAddressCity } = useBillingAddressCity();
  const { billingAddressPostalCode } = useBillingAddressPostalCode();
  const { billingAddressCountry } = useBillingAddressCountry();
  const { billingAddressState } = useBillingAddressState();
  const { bothAddressesSame } = useAddressesSame();
  const { shippingAddressLine1 } = useShippingAddressLine1();
  const { shippingAddressLine2 } = useShippingAddressLine2();
  const { shippingAddressCity } = useShippingAddressCity();
  const { shippingAddressPostalCode } = useShippingAddressPostalCode();
  const { shippingAddressCountry } = useShippingAddressCountry();
  const { shippingAddressState } = useShippingAddressState();
  const isValidBillingAddress = validateAddress({
    line1: billingAddressLine1,
    line2: billingAddressLine2,
    city: billingAddressCity,
    postalCode: billingAddressPostalCode,
    country: billingAddressCountry,
    state: billingAddressState
  });
  const isValidShippingAddress = validateAddress({
    line1: shippingAddressLine1,
    line2: shippingAddressLine2,
    city: shippingAddressCity,
    postalCode: shippingAddressPostalCode,
    country: shippingAddressCountry,
    state: shippingAddressState
  });
  return isValidBillingAddress && (bothAddressesSame || isValidShippingAddress);
};

export const isEntityInfoFormValid = (
  organizationIsABusiness: boolean,
  numEmployees: CompanySize | undefined,
  companyName: string | undefined,
  websiteUrl: string | undefined,
  taxStatus: TaxStatusType | undefined,
  taxId: string | undefined,
  businessType: BusinessType | undefined
): boolean => {
  if (organizationIsABusiness) {
    if (numEmployees === undefined || businessType === undefined || isEmpty(companyName) || isEmpty(websiteUrl)) {
      return false;
    }

    /** Registered user must have tax id. */
    if (taxStatus === 'registered') {
      return !!taxId;
    }
  }

  /** Individual does not need any other fields. */
  return true;
};

export const useIsEntityInfoFormValid = (): boolean => {
  const { numEmployees } = useNumEmployees();
  const { companyName } = useCompanyName();
  const { websiteUrl } = useWebsiteUrl();
  const { businessType } = useBusinessType();
  const [organizationIsABusiness] = useOrganizationType();
  const { taxInfo } = useTaxInfo();
  const { taxId } = useTaxId();

  return isEntityInfoFormValid(
    organizationIsABusiness,
    numEmployees,
    companyName,
    websiteUrl,
    taxInfo.taxStatus,
    taxId,
    businessType
  );
};
