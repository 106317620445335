import React, { memo, ReactElement } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';
import { Link as CUiLink } from '@clickhouse/click-ui';

type WrappedLinkProps = RouterLinkProps & {
  size?: React.ComponentProps<typeof CUiLink>['size'];
  weight?: React.ComponentProps<typeof CUiLink>['weight'];
  icon?: React.ComponentProps<typeof CUiLink>['icon'];
};

/**
  Link from Click UI wrapped in a Link from React Router
  Purpose: Link from React Router as renders fully accessible anchor tag with the proper href. User can command-click or right-click and open in a new tab.
  Link Click UI from provides the styling.
*/
export const WrappedLink = memo(
  ({
    to,
    children,
    size,
    weight,
    icon,
    ...props
  }: WrappedLinkProps): ReactElement => {
    const CUiProps = { size, weight, icon };
    return (
      <RouterLink to={to} {...props}>
        <CUiLink {...CUiProps}>{children}</CUiLink>
      </RouterLink>
    );
  }
);
