import { NotificationCategory, NotificationChannelType } from '@cp/common/protocol/NotificationInventory';

export const getHumanReadableChannelType = (typeChannel: NotificationChannelType): string => {
  switch (typeChannel) {
    case 'ui':
      return 'UI';
    case 'email':
      return 'Email';
    default:
      return typeChannel;
  }
};

export const getHumanReadableCategory = (notificationCategory: NotificationCategory | ''): string => {
  if (notificationCategory === 'service') {
    return 'Service';
  }
  if (notificationCategory === 'billing') {
    return 'Billing';
  }
  console.error('Unknown notification category');
  return 'Unknown';
};
