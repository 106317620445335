import React from 'react';
import FileUrlCard from 'src/components/ImportView/ImportTypeSelector/FileUrlCard';

import FileUploadCard from 'src/components/ImportView/ImportTypeSelector/FileUploadCard';
import SampleDataCard from 'src/components/ImportView/ImportTypeSelector/SampleDataCard';

import {
  Container,
  GridContainer,
  Link,
  Panel,
  Text
} from '@clickhouse/click-ui';
import KafkaDataCard from 'src/components/ImportView/ImportTypeSelector/KafkaDataCard';
import { MAX_FILE_SIZE_IN_GBS } from 'src/components/ImportView/ImportFileInput';

function ImportTypeSelector(): JSX.Element {
  return (
    <GridContainer fillWidth gridTemplateColumns="1fr 1fr" gap="lg">
      <KafkaDataCard />
      <Panel hasBorder gap="none">
        <Container orientation="vertical" fillWidth>
          <FileUploadCard maxFileSizeInGB={MAX_FILE_SIZE_IN_GBS} />
          <FileUrlCard />
          <SampleDataCard />
        </Container>
        <Container fillWidth padding="sm">
          <Text size="sm">
            Or load data from other data sources using our{' '}
            <Link
              href="https://clickhouse.com/docs/en/integrations"
              target="_blank"
              size="sm"
            >
              100+ supported integrations.
            </Link>
          </Text>
        </Container>
      </Panel>
    </GridContainer>
  );
}

export default React.memo(ImportTypeSelector);
