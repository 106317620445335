import {
  Button,
  Container,
  Spacer,
  Text,
  TextField
} from '@clickhouse/click-ui';
import { validateName } from '@cp/common/utils/ValidationUtils';
import { KeyboardEvent, ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import {
  Section,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

export const OrganizationNameSection = (props: {
  orgName: string;
  onRename: (name: string) => void;
}): ReactElement => {
  const hasManageOrganizationPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  const [orgName, setOrgName] = useState(props.orgName);
  const [validationMessage, setValidationMessage] = useState<string>();
  const orgNameNeedsSaving =
    orgName.trim() !== props.orgName.trim() && orgName !== '';

  const validateAndSetOrgName = (newName: string): void => {
    const validationResult = validateName(newName);
    setValidationMessage(validationResult.validationMessage);
    setOrgName(newName);
  };

  const saveOrgName = (): void => {
    const validationResult = validateName(orgName);
    if (!validationResult.isValid) {
      return;
    }
    void props.onRename(orgName.trim());
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && orgNameNeedsSaving) {
      saveOrgName();
    }
  };

  return (
    <Section padding="lg">
      <Subsection>
        <SubsectionDescription>
          <SubsectionTitle>Organization name</SubsectionTitle>
          <Spacer />
          <Text color="muted">The name of your business entity.</Text>
        </SubsectionDescription>
        <SubsectionControls gap="md">
          <TextField
            className="fs-exclude"
            data-testid="org-name"
            error={validationMessage}
            label="Name"
            onChange={validateAndSetOrgName}
            onKeyDown={handleKeyDown}
            type="text"
            value={orgName}
            disabled={!hasManageOrganizationPermission}
          />
          {orgNameNeedsSaving && (
            <Container alignItems="start">
              <Button
                data-testid="update-org-name-button"
                disabled={validationMessage !== undefined}
                fillWidth={false}
                label="Update"
                onClick={saveOrgName}
                type="secondary"
              />
            </Container>
          )}
        </SubsectionControls>
      </Subsection>
    </Section>
  );
};
