import React, {
  Children,
  FC,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useMemo,
  HTMLAttributes
} from 'react';

import { css } from '@emotion/react';
import { gridCol } from 'global-styles';
import { CloseButton } from 'primitives';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Button from 'src/components/primitives/lib/Button';

import {
  closeBtn,
  modal,
  modalBody,
  modalFooter,
  modalFooterBtn,
  modalHeader
} from 'src/components/primitives/lib/CardModal/styles';
import {
  CardModalProps,
  CardModalVariant,
  FooterProps
} from 'src/components/primitives/lib/CardModal/types';

function displayNameIs(node: ReactNode, text: string): boolean {
  if (React.isValidElement(node)) {
    const element = node as ReactElement<any, FC<any>>;
    return [
      element.type.displayName,
      node?.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName
    ].includes(text);
  }

  return false;
}

interface CardModalContextValue {
  variant: CardModalVariant;
}

const CardModalContext = React.createContext<CardModalContextValue>({
  variant: 'default'
});

const CardModal = ({
  children,
  showCloseButton,
  canDismiss = true,
  onClose,
  open,
  overlayStyle,
  style,
  customZIndex,
  headerText,
  width,
  showOverlay,
  variant = 'default',
  ...props
}: CardModalProps): ReactElement => {
  const header = Children.map(children, (child) =>
    displayNameIs(child, 'Header') ? child : null
  );
  const body = Children.map(children, (child) =>
    displayNameIs(child, 'Body') ? child : null
  );
  const footer = Children.map(children, (child) =>
    displayNameIs(child, 'Footer') ? child : null
  );

  const others = Children.map(children, (child) => {
    if (
      displayNameIs(child, 'Header') ||
      displayNameIs(child, 'Body') ||
      displayNameIs(child, 'Footer')
    ) {
      return null;
    } else {
      return child;
    }
  });

  const defaultWidth = variant === 'wide' ? 550 : 350;

  const providerInfo = useMemo(
    () => ({
      variant
    }),
    [variant]
  );

  return (
    <CardModalContext.Provider value={providerInfo}>
      <ReactModal
        ariaHideApp={false}
        closeTimeoutMS={300}
        isOpen={open}
        onRequestClose={(e: SyntheticEvent) => {
          if (typeof onClose === 'function') {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            onClose(e);
          }
        }}
        css={modal(width || defaultWidth)}
        className={`modal-variant-${variant}`}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnEsc={canDismiss && typeof onClose === 'function'}
        shouldCloseOnOverlayClick={canDismiss && typeof onClose === 'function'}
        style={{
          overlay: {
            zIndex: customZIndex || 1300,
            background: showOverlay ? 'rgb(0, 0, 0, 0.5)' : 'transparent',
            ...overlayStyle
          },
          ...style
        }}
        {...props}
      >
        <>
          {showCloseButton && onClose && (
            <div css={css({ position: 'relative' })} className="close-btn">
              <CloseButton css={closeBtn} onClick={onClose} height={10} />
            </div>
          )}
          {headerText && (
            <div css={modalHeader} className="modal-header">
              {headerText}
            </div>
          )}
          {header}
          {body}
          {others}
          {footer}
        </>
      </ReactModal>
    </CardModalContext.Provider>
  );
};
interface HeaderProps {
  children: ReactNode;
}
const Header = ({ children, ...props }: HeaderProps) => {
  return (
    <div css={modalHeader} className="modal-header" {...props}>
      {children}
    </div>
  );
};

Header.displayName = 'Header';
CardModal.Header = Header;

interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}
const Body = ({ children, ...props }: BodyProps) => (
  <div css={modalBody} className="modal-body" {...props}>
    {children}
  </div>
);

Body.displayName = 'Body';
CardModal.Body = Body;

const Footer = ({ options, children, ...props }: FooterProps) => {
  const rightAligned = options.filter(
    (option) => option.align === 'right' && !option.hide
  );
  const leftAligned = options.filter(
    (option) => option.align === 'left' && !option.hide
  );

  return (
    <div
      css={modalFooter(leftAligned.length > 0 && rightAligned.length === 0)}
      className="modal-footer"
      {...props}
    >
      {rightAligned.length > 0 && (
        <div css={gridCol(rightAligned.length, 10)} className="right-aligned">
          {rightAligned.map(({ component, label, type, ...otherProps }) =>
            React.isValidElement(component) ? (
              React.cloneElement(component, { key: label })
            ) : (
              <Button
                key={label}
                color="transparent"
                data-type={type}
                css={modalFooterBtn}
                {...otherProps}
              >
                {label}
              </Button>
            )
          )}
        </div>
      )}
      {children}
      {leftAligned.length > 0 && (
        <div css={gridCol(leftAligned.length, 10)} className="left-aligned">
          {leftAligned.map(({ component, label, type, ...otherProps }) =>
            React.isValidElement(component) ? (
              React.cloneElement(component, { key: label })
            ) : (
              <Button
                key={label}
                color="transparent"
                data-type={type}
                css={modalFooterBtn}
                {...otherProps}
              >
                {label}
              </Button>
            )
          )}
        </div>
      )}
    </div>
  );
};

Footer.displayName = 'Footer';
Footer.prototype = {
  children: PropTypes.node,
  footerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      align: PropTypes.oneOf(['left', 'right'])
    })
  ).isRequired
};
CardModal.Footer = Footer;

CardModal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  showCloseButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  style: PropTypes.object,
  overlayStyle: PropTypes.object,
  customZIndex: PropTypes.number,
  variant: PropTypes.oneOf(['default', 'wide'])
};

CardModal.defaultProps = {
  headerText: '',
  open: false,
  showCloseButton: true,
  showOverlay: false,
  variant: 'default'
};
export default CardModal;
