import { Container, Select, Text } from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import { assertTruthy } from '@cp/common/utils/Assert';
import { ByocCloudProviderConfig } from '@cp/common/protocol/Organization';
import { CloudProvider } from '@cp/common/protocol/Instance';

interface RegionSelectorProps {
  cloudProviderConfig: ByocCloudProviderConfig;
  selectedAccountId: string | undefined;
  onAccountChange: (newAccount: string) => void;
  disabled?: boolean;
}

interface AccountDescription {
  id: string;
  name: string;
}

export function CloudProviderAccountSelection({
  cloudProviderConfig,
  selectedAccountId,
  onAccountChange,
  disabled
}: RegionSelectorProps): ReactNode {
  const accounts: Array<AccountDescription> = Object.entries(
    cloudProviderConfig
  ).map(([accountId, accountConfig]) => ({
    id: accountId,
    name: accountConfig.name
  }));

  return (
    <Select
      label="Cloud provider account"
      value={selectedAccountId}
      data-testid="service-region-select"
      disabled={disabled}
      onSelect={(value) => {
        const newAccount = accounts.find((member) => member.id === value);
        assertTruthy(newAccount, 'Invalid account');
        onAccountChange(newAccount.id);
      }}
    >
      {accounts.map((account) => (
        <Select.Item
          value={account.id}
          key={account.id}
          data-testid="region-option"
        >
          <Container
            padding="none"
            gap="md"
            alignItems="center"
            isResponsive={false}
          >
            <Text>
              {account.name && account.name !== account.id
                ? `${account.id} - ${account.name}`
                : account.id}
            </Text>
          </Container>
        </Select.Item>
      ))}
    </Select>
  );
}
