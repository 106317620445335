import { ClickhouseLoading } from 'primitives';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { cssTransition, ToastContainer } from 'react-toastify';
import { useAuth } from 'src/components/auth';
import AuthProvider from 'src/components/auth/AuthProvider';
import { ClickUIWrapper } from 'src/components/ClickUIWrapper/ClickUIWrapper';
import { DebugOnly } from 'src/DebugOnly';
import Initializer from 'src/Initializer';
import { isCloudEnv } from 'src/lib/config/isCloudEnv';
import { removeCookiesByNamePrefix } from 'src/lib/Cookie';

import { removeLocalStorageKeysByKeyPrefix } from 'src/lib/local-storage';
import { WsClientProvider } from 'src/lib/websockets/WebSocketsClientProvider';
import { DISABLE_AUTO_SIGN_IN_QUERY_PARAM } from '@cp/common/utils/HttpUtils';
import config from 'src/lib/config';

import {
  clearGrowthBook,
  setGrowthBookUser
} from 'src/lib/growthbook/growthbook';

function disableAutoSigninOnAppInitAuth0(): void {
  removeLocalStorageKeysByKeyPrefix('@@auth0spajs@@');
  removeCookiesByNamePrefix('auth0');
  removeCookiesByNamePrefix('_legacy_auth0');
}

// Top level guard for the authenticated route
export function AuthenticatedAppGuard(): ReactElement {
  return (
    <AuthProvider>
      <LocalDebugWrapper>
        <ClickUIWrapper>
          <AuthenticatedApp />
        </ClickUIWrapper>
      </LocalDebugWrapper>
    </AuthProvider>
  );
}

const LocalDebugWrapper = ({
  children
}: {
  children: ReactNode;
}): ReactElement => {
  if (isCloudEnv() && config.env !== 'development') {
    return <>{children}</>;
  } else {
    return <DebugOnly>{children}</DebugOnly>;
  }
};

const toastModal = cssTransition({
  enter: 'toast-modal-in',
  exit: 'toast-modal-out',
  collapse: false
});

function AuthenticatedApp(): ReactElement {
  const { loading, isAuthenticated, currentUser } = useAuth();
  const ready = !loading && isAuthenticated && currentUser;
  const [searchParams] = useSearchParams();

  const partialLocalStorageKey = '@@auth0spajs@@::(.+)::@@user@@';
  const { logOut } = useAuth();

  useEffect(() => {
    if (searchParams.has(DISABLE_AUTO_SIGN_IN_QUERY_PARAM)) {
      disableAutoSigninOnAppInitAuth0();
      logOut().catch(console.error);
    }
  }, []);

  useEffect(() => {
    setGrowthBookUser({
      userId: currentUser?.id || '',
      email: currentUser?.email || ''
    });

    if (!isAuthenticated) {
      clearGrowthBook();
    }
  }, [currentUser?.id, currentUser?.email, isAuthenticated]);

  useEffect(() => {
    const handleLocalStorageChange = (event: StorageEvent): void => {
      const regex = new RegExp(partialLocalStorageKey);

      if (event.key && regex.test(event.key)) {
        const user = event.newValue;

        if (!user) {
          logOut().catch(console.error);
        }
      }
    };
    window.addEventListener('storage', handleLocalStorageChange);

    return () =>
      window.removeEventListener('storage', handleLocalStorageChange);
  }, [logOut]);

  return ready ? (
    <WsClientProvider>
      <Initializer>
        <Outlet />
        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          transition={toastModal}
          hideProgressBar={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          draggablePercent={40}
        />
      </Initializer>
    </WsClientProvider>
  ) : (
    <ClickhouseLoading isFullScreen />
  );
}
