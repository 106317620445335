import {
  Container,
  IconButton,
  Panel,
  Spacer,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import { Link } from 'react-router-dom';
import { Instance } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { useGettingStartedFeature } from 'src/instance/controller/useGettingStartedFeature';
import { getInstanceDatabaseAccess } from 'src/instance/instance';
import { SidebarElementsContainer } from 'src/layout/GlobalSidebarStyles';
import CollapsibleSidebarItem from 'src/layout/ServiceSidebar/CollapsibleSidebarItem';
import { GettingStartedSidebarItem } from 'src/layout/ServiceSidebar/GettingStartedSidebarItem';
import ServiceConnectSidebarItem from 'src/layout/ServiceSidebar/ServiceConnectSidebarItem';
import { SidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';
import { useMainSidebar } from 'src/layout/SidebarWrapper/SidebarWrapper';
import { useIsRegionBlocked } from 'src/lib/blockedRegion';
import { useOrgFeature, useUserFeature } from 'src/lib/features';
import { routes } from 'src/lib/routes';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { useSelectedTab } from 'src/state/tabs';
import styled from 'styled-components';
import { WrappedLink } from 'src/components/WrappedLink/WrappedLink';
import { WarehouseSelector } from 'src/layout/ServiceSidebar/WarehouseSelector';
import { ServiceSelector } from 'src/layout/ServiceSidebar/ServiceSelector';
import { Galaxy } from 'galaxy';

export interface SidebarMainNavProps {
  services?: Array<Instance>;
  currentService?: Instance;
}

const SidebarContainer = styled(Container)`
  padding-inline: 1rem;
`;

const FullWidthLink = styled(Link)`
  width: 100%;
`;

export const SidebarMainNav = ({
  services = [],
  currentService
}: SidebarMainNavProps): ReactElement => {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const isBlocked = useIsRegionBlocked();
  const { hasGettingStarted, removeGettingStarted } =
    useGettingStartedFeature();
  const showNewOnboarding = useUserFeature('FT_USER_SHOW_NEW_ONBOARDING');
  const { createNewServiceOrShowTrialLimitModal } =
    useServiceLimitReachedModal();
  const hydraFeatureEnabled = useOrgFeature('FT_ORG_HYDRA_UI');
  const dashboardsIsEnabled = useOrgFeature('FT_ORG_DASHBOARDS');

  const { closeSidebar, isDesktop } = useMainSidebar();
  const selectedTab = useSelectedTab();
  let sqlConsoleUrl = currentService
    ? routes.console({ serviceId: currentService.id })
    : '';
  const dashboardsUrl = currentService
    ? routes.dashboards({ serviceId: currentService.id })
    : '';

  if (selectedTab && currentService) {
    switch (selectedTab.type) {
      case 'table':
        sqlConsoleUrl = routes.tables({
          serviceId: currentService.id,
          databaseName: selectedTab.table.schema ?? '',
          tableName: selectedTab.table.tableName
        });
        break;
      case 'query':
        sqlConsoleUrl = routes.query({
          serviceId: currentService.id,
          queryId: selectedTab.queryId
        });
    }
  }

  const databaseAccess = getInstanceDatabaseAccess(currentService);

  return (
    <SidebarContainer orientation="vertical" padding="xs" fillHeight>
      <Container gap="xs" isResponsive={false}>
        <Container
          padding="none"
          isResponsive={false}
          minWidth="184px"
          overflow="hidden"
        >
          {hydraFeatureEnabled ? (
            <WarehouseSelector
              isDesktop={isDesktop}
              currentService={currentService}
            />
          ) : (
            <ServiceSelector
              isDesktop={isDesktop}
              services={services}
              currentService={currentService}
            />
          )}
        </Container>
        {hasManageServicePermission && !isBlocked && (
          <Tooltip>
            <Tooltip.Trigger>
              <IconButton
                icon="plus"
                onClick={createNewServiceOrShowTrialLimitModal}
              />
            </Tooltip.Trigger>
            <Tooltip.Content>Create new service</Tooltip.Content>
          </Tooltip>
        )}
      </Container>
      {currentService?.id ? (
        <>
          <Spacer size="md" />
          <SidebarElementsContainer
            orientation="vertical"
            fillHeight
            isResponsive={false}
          >
            {hasGettingStarted && showNewOnboarding && (
              <GettingStartedSidebarItem
                service={currentService}
                closeSidebar={closeSidebar}
                showBadge
                removeGettingStarted={removeGettingStarted}
                hasManagePermission={hasManageServicePermission}
              />
            )}
            {isDesktop && (
              <FullWidthLink
                to={sqlConsoleUrl}
                onClick={(evt) => {
                  if (!databaseAccess.enabled) {
                    evt.preventDefault();
                  }
                  closeSidebar();
                }}
              >
                <SidebarItem
                  type="main"
                  icon="display"
                  label="SQL Console"
                  disabled={!databaseAccess.enabled}
                  disabledTooltipMessage={databaseAccess.reason}
                  path="/services/:serviceId/console/*"
                  data-testid="consoleSidebarButton"
                />
              </FullWidthLink>
            )}
            {dashboardsIsEnabled && (
              <FullWidthLink
                to={dashboardsUrl}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <SidebarItem
                  icon="bar-chart"
                  label="Dashboards"
                  path="/services/:serviceId/dashboards"
                  type="main"
                />
              </FullWidthLink>
            )}
            {hasManageServicePermission && (
              <FullWidthLink
                to={routes.imports({ serviceId: currentService.id })}
                onClick={(evt) => {
                  if (!databaseAccess.enabled) {
                    evt.preventDefault();
                  }
                  closeSidebar();
                }}
              >
                <SidebarItem
                  type="main"
                  icon="data"
                  label="Data sources"
                  path="/services/:serviceId/imports/*"
                  data-testid="importsSidebarButton"
                  disabled={!databaseAccess.enabled}
                  disabledTooltipMessage={databaseAccess.reason}
                />
              </FullWidthLink>
            )}
            <FullWidthLink
              to={routes.backups({ serviceId: currentService.id })}
              onClick={() => {
                closeSidebar();
              }}
            >
              <SidebarItem
                type="main"
                icon="backups"
                label="Backups"
                path="/services/:serviceId/backups"
                data-testid="backupsSidebarButton"
              />
            </FullWidthLink>
            <FullWidthLink
              to={routes.serviceSettings({ serviceId: currentService.id })}
              onClick={() => {
                closeSidebar();
              }}
            >
              <SidebarItem
                type="main"
                icon="settings"
                label="Settings"
                path="/services/:serviceId/settings"
                data-testid="settingsSidebarButton"
              />
            </FullWidthLink>
            <CollapsibleSidebarItem
              icon="metrics-alt"
              paths={[
                '/services/:serviceId/health',
                '/services/:serviceId/queryInsights'
              ]}
              label="Monitoring"
              data-testid="monitoringSidebarButton"
            >
              <FullWidthLink
                to={routes.health({ serviceId: currentService.id })}
                onClick={() => {
                  Galaxy.galaxy().track('serviceHealth.page.navigatedTo', {
                    interaction: 'click'
                  });
                  closeSidebar();
                }}
              >
                <SidebarItem
                  type="main"
                  label="Service health"
                  path="/services/:serviceId/health"
                  data-testid="healthSidebarButton"
                />
              </FullWidthLink>
              <FullWidthLink
                to={routes.queryInsights({ serviceId: currentService.id })}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <SidebarItem
                  type="main"
                  label="Query insights"
                  path="/services/:serviceId/queryInsights"
                  data-testid="queryInsightsSidebarButton"
                />
              </FullWidthLink>
            </CollapsibleSidebarItem>
            <CollapsibleSidebarItem
              icon="question"
              paths={[
                '/services/:serviceId/learn',
                '/services/:serviceId/support'
              ]}
              label="Help"
              data-testid="helpSidebarButton"
            >
              <FullWidthLink
                to={routes.learn({ serviceId: currentService.id })}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <SidebarItem
                  type="main"
                  label="Learn"
                  path="/services/:serviceId/learn"
                  data-testid="learnSidebarButton"
                />
              </FullWidthLink>
              <FullWidthLink
                to={routes.support({ serviceId: currentService.id })}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <SidebarItem
                  type="main"
                  label="Support"
                  path="/services/:serviceId/support"
                  data-testid="supportSidebarButton"
                />
              </FullWidthLink>
              {!hasGettingStarted && showNewOnboarding && (
                <GettingStartedSidebarItem
                  service={currentService}
                  closeSidebar={closeSidebar}
                  removeGettingStarted={removeGettingStarted}
                  hasManagePermission={hasManageServicePermission}
                />
              )}
            </CollapsibleSidebarItem>
            <Spacer size="md" />
            <ServiceConnectSidebarItem
              type="main"
              icon="connect-alt"
              label="Connect"
            />
          </SidebarElementsContainer>
        </>
      ) : (
        <>
          <Spacer />
          <Panel hasBorder padding="sm">
            <Container orientation="vertical" gap="sm">
              <Text color="muted">
                Select a service to access the SQL console, sizing options and
                more.
              </Text>
              <Text color="muted">
                Or visit the{' '}
                <WrappedLink
                  to={routes.support({ serviceId: null })}
                  onClick={(evt) => {
                    closeSidebar();
                  }}
                  size="md"
                >
                  support portal
                </WrappedLink>
                to access your tickets.
              </Text>
              <FullWidthLink
                to={routes.learn({ serviceId: null })}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <SidebarItem
                  icon="question"
                  label="Learn"
                  path="/learn"
                  type="sqlSidebar"
                />
              </FullWidthLink>
              <FullWidthLink
                to={routes.support({ serviceId: null })}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <SidebarItem icon="lifebuoy" label="Support" path="/support" />
              </FullWidthLink>
            </Container>
          </Panel>
        </>
      )}
    </SidebarContainer>
  );
};
