import React from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useGptRequest } from 'src/lib/gpt/GptRequestHooks';
import { getCurrentServiceId } from 'src/state/service';
import { useCredentials } from 'src/state/connection';
import { EditorInstance } from 'src/components/primitives/lib/MonacoSQLEditor';
import useQueryCorrectionTracking from 'src/lib/gpt/GPTQueryTrackingHook';
import { atom } from 'jotai';

interface GptConstructionResult {
  cancelConstructionRequest: () => void;
  isRequestRunning: boolean;
  constructQuery: (promptText: string, tabId: string) => Promise<void>;
}

const abortControllerAtom = atom<AbortController | null>(null);

/**
 * A hook for making requests to OpenAI GPT to construct SQL queries using the request text.
 * @returns An object with functions and properties for making and managing requests
 */
export function useGptConstruction(
  editorRef: React.MutableRefObject<EditorInstance | null>
): GptConstructionResult {
  const api = useApiClient();
  const { sendRequest, cancelRequest, isRequestRunning } = useGptRequest(
    api.getGptConstruction.bind(api),
    abortControllerAtom
  );
  const serviceId = getCurrentServiceId() || '';
  const { startTrackingQuery } = useQueryCorrectionTracking();
  const credential = useCredentials();

  /**
   * Constructs a SQL query using the provided prompt text and replaces
   * the editor content with the result.
   * @param promptText The prompt text to use for the GPT request.
   */
  const constructQuery = async (
    promptText: string,
    tabId: string
  ): Promise<void> => {
    const response = await sendRequest({
      requestText: promptText,
      serviceId,
      credential
    });
    const newQueryText = response?.completion;
    if (!newQueryText) {
      // Process has been interrupted
      return;
    }
    const result = `-- ${promptText}\n${newQueryText}`;
    editorRef.current?.replaceEditorContent(result);
    startTrackingQuery({ tabId, traceId: response.traceId, queryText: result });
  };

  return {
    cancelConstructionRequest: cancelRequest,
    isRequestRunning,
    constructQuery
  };
}
