import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Container,
  Icon,
  Switch,
  Spacer,
  Text,
  Alert,
  useCUITheme
} from '@clickhouse/click-ui';

import {
  CDCTableSelection,
  ClickPipePostgresImport,
  PostgresImport
} from 'shared/src/dataLoading';
import { PostgresSourceTable } from 'types/protocol';
import { useApiClient } from 'src/lib/controlPlane/client';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { convertKeysToSnakeCase } from 'api/src/lib/http/toSnakeCase';
import LoadingContainer from 'src/components/LoadingContainer/LoadingContainer';
import { getPostgresConfigWithCredentials } from 'src/lib/dataLoading/clickpipes/postgres';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import TableBox from 'src/components/ImportView/ClickPipesImportForm/ConfigureTables/tableBox';

const SchemaBox = ({
  schemaName,
  searchedTable
}: {
  schemaName: string;
  searchedTable: string;
}) => {
  const api = useApiClient();
  const serviceId = getCurrentServiceIdOrFail();
  const theme = useCUITheme();
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipePostgresImport>();
  const [tables, setTables] = useState<PostgresSourceTable[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectAllLoading, setSelectAllLoading] = useState<boolean>(false);
  const [tablesLoading, setTablesLoading] = useState<boolean>(false);
  const publicationExists = importModel.data.cdcSettings.publicationName !== '';
  const initialLoadOnly =
    importModel.data.cdcSettings.replicationMode === 'snapshot';
  const tablesFiltered = useMemo(() => {
    return tables.filter((table: PostgresSourceTable) => {
      const publicationValidity =
        initialLoadOnly || !publicationExists || table.is_in_publication;
      const replidentValidity = initialLoadOnly || table.has_replident;
      return (
        table.name.toLowerCase().includes(searchedTable.toLowerCase()) &&
        publicationValidity &&
        replidentValidity
      );
    });
  }, [tables, searchedTable]);
  const [tableLoadError, setTableLoadError] = useState<string>('');
  const [showTables, setShowTables] = useState<boolean>(false);

  const fetchTables = async () => {
    if (schemaName === '') {
      return;
    }
    setTablesLoading(true);
    const request = getPostgresConfigWithCredentials(importModel);
    try {
      const response = await api.listPostgresTables(
        {
          ...request,
          schemaName: schemaName,
          publicationName: importModel.data.cdcSettings.publicationName
        },
        serviceId
      );
      const tablesData = convertKeysToSnakeCase(
        response.data.tables
      ) as PostgresSourceTable[];
      setTables(tablesData);
      setTablesLoading(false);
    } catch (e) {
      setTableLoadError('Failed to load tables');
      setTablesLoading(false);
    }
  };

  const handleSelectAll = (state: boolean) => {
    setAllSelected(state);
    if (state) {
      setSelectAllLoading(true);
      tables.forEach((table: PostgresSourceTable) => {
        updateImportModel((draft: PostgresImport) => {
          const tableSelections = draft.data.cdcSettings.tableSelections;
          const existingSelection = tableSelections?.find(
            (selection) =>
              selection.sourceSchemaName === schemaName &&
              selection.sourceTable === table.name
          );
          if (!existingSelection) {
            tableSelections?.push({
              sourceSchemaName: schemaName,
              sourceTable: table.name,
              targetTable: table.name,
              excludedColumns: [],
              selectedColumns: table.columns.map((column) => column.name)
            });
            draft.data.cdcSettings.tableSelections = tableSelections;
          }
        });
      });
      setSelectAllLoading(false);
    } else {
      setSelectAllLoading(true);
      updateImportModel((draft: PostgresImport) => {
        draft.data.cdcSettings.tableSelections =
          draft.data.cdcSettings.tableSelections?.filter(
            (table: CDCTableSelection) => table.sourceSchemaName !== schemaName
          );
      });
      setSelectAllLoading(false);
    }
  };

  const TableDisplayContainer = () => {
    if (tablesLoading || selectAllLoading) {
      return (
        <LoadingContainer
          data-testid={`postgres-clickpipes-table-loader-${schemaName}`}
        />
      );
    } else if (tableLoadError !== '') {
      return (
        <Alert
          text={tableLoadError}
          state="danger"
          size="medium"
          showIcon={false}
        />
      );
    } else if (tablesFiltered.length === 0) {
      return <Alert text="No tables found in this schema" />;
    } else {
      return (
        <Container orientation="vertical" gap="md">
          {tablesFiltered.map((table: PostgresSourceTable) => (
            <TableBox
              key={`postgres-clickpipe-table-${schemaName}.${table.name}`}
              table={{
                ...table,
                schemaName: schemaName
              }}
              preselected={allSelected}
            />
          ))}
        </Container>
      );
    }
  };

  useEffect(() => {
    if (showTables) {
      void fetchTables();
    }
  }, [showTables]);

  return (
    <Container orientation="vertical" gap="md">
      <Container
        key={`postgres-clickpipe-schema-box-${schemaName}`}
        orientation="vertical"
      >
        <Container
          orientation="horizontal"
          gap="md"
          justifyContent="space-between"
          fillWidth
        >
          <Button
            type="empty"
            style={{ paddingLeft: 0 }}
            data-testid={`postgres-clickpipe-schema-button-${schemaName}`}
            onClick={() => setShowTables((prev) => !prev)}
          >
            <Container>
              <Icon
                name={showTables ? 'chevron-down' : 'chevron-right'}
                color={theme.name === 'light' ? 'black' : undefined}
                size="md"
              />
              <Text color="default" style={{ fontWeight: '600' }}>
                {schemaName}
              </Text>
            </Container>
          </Button>
          {showTables && (
            <Switch
              orientation="horizontal"
              label="Select all tables"
              dir="end"
              checked={allSelected}
              onCheckedChange={handleSelectAll}
            />
          )}
        </Container>
        <Spacer size="sm" />

        {showTables && TableDisplayContainer()}
      </Container>
    </Container>
  );
};

export default SchemaBox;
