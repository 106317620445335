import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import { useLocation } from 'react-router-dom';

import { getCurrentServiceId } from 'src/state/service';

export type Sidebar = 'query' | 'table' | 'imports' | 'roles';

const storage = createJSONStorage<Sidebar>(() => sessionStorage);

export const selectedSidebarAtom = atomWithStorage<Sidebar>(
  'selectedSidebar',
  (sessionStorage.getItem('selectedSidebar') as Sidebar) || 'table',
  storage
);

const importsRegex = /services\/.*\/imports.*/;

function selectedSidebarFromUrl(pathname: string): Sidebar {
  if (pathname.match(importsRegex)) {
    return 'imports';
  } else {
    return 'table';
  }
}

export function useSelectedSidebar() {
  const { pathname } = useLocation();
  const [selectedSidebar, setSelectedSidebar] = useAtom(selectedSidebarAtom);
  const currentService = getCurrentServiceId();

  useEffect(() => {
    if (currentService && pathname === `/services/${currentService}`) {
      return;
    }

    const sidebarFromUrl = selectedSidebarFromUrl(pathname);

    if (
      !selectedSidebar ||
      (sidebarFromUrl === 'imports' && selectedSidebar !== 'imports') ||
      (selectedSidebar === 'imports' && sidebarFromUrl !== 'imports')
    ) {
      setSelectedSidebar(sidebarFromUrl);
    }
  }, [pathname]);

  return { selectedSidebar, setSelectedSidebar };
}

export default selectedSidebarAtom;
