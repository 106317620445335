import { useCallback, useState } from 'react';
import { useUserFeature } from 'src/lib/features';
import { useOnboardController } from 'src/onboard/controller/useOnboardController';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useGetPendingUserActions } from 'src/lib/pendingUserActions/useGetPendingUserActions';

export type CreationStep = 'CREATE_INSTANCE' | 'SECURITY' | 'SERVICE_DETAILS' | 'FINISHED';
export type CreateServiceType = 'onboarding' | 'default';

const redirectFunctionality = ({
  serviceId,
  showNewOnboarding
}: {
  serviceId: string | null;
  showNewOnboarding: boolean;
}): void => {
  if (!serviceId) {
    return;
  }

  if (showNewOnboarding) {
    navigateTo(routes.gettingStarted({ serviceId }));
  } else {
    navigateTo(routes.health({ serviceId }));
  }
};

interface UseCreateServiceFormReturnType {
  currentStep: CreationStep;
  setStepNext: () => void;
  serviceId: string | null;
  onCreateService: (instanceId: string) => void;
}

export const useCreateServiceForm = ({
  type,
  onStepChange
}: {
  type: CreateServiceType;
  onStepChange?: (step: CreationStep) => void;
}): UseCreateServiceFormReturnType => {
  const [currentStep, setCurrentStep] = useState<CreationStep>('CREATE_INSTANCE');
  const { setPostOnboardingPendingActions } = useOnboardController();
  const pendingUserActions = useGetPendingUserActions();

  const hasGettingStarted = pendingUserActions.some((userAction) =>
    ['onboarding', 'getting-started'].includes(userAction.type)
  );
  const showNewOnboarding = useUserFeature('FT_USER_SHOW_NEW_ONBOARDING');
  const [serviceId, setServiceId] = useState<string | null>(null);

  const setStepNext = useCallback(() => {
    setCurrentStep((step) => {
      let newStep = step;

      if (newStep === 'CREATE_INSTANCE') {
        newStep = showNewOnboarding ? 'SERVICE_DETAILS' : 'SECURITY';
      } else if (newStep === 'SECURITY') {
        newStep = 'SERVICE_DETAILS';
      } else if (newStep === 'SERVICE_DETAILS') {
        newStep = 'FINISHED';
        if (type === 'onboarding') {
          void setPostOnboardingPendingActions().then(() => {
            redirectFunctionality({ serviceId, showNewOnboarding: showNewOnboarding && hasGettingStarted });
          });
        } else {
          redirectFunctionality({ serviceId, showNewOnboarding: showNewOnboarding && hasGettingStarted });
        }
      }
      if (typeof onStepChange === 'function') {
        onStepChange(newStep);
      }
      return newStep;
    });
  }, [hasGettingStarted, onStepChange, setPostOnboardingPendingActions, serviceId, showNewOnboarding, type]);

  const onCreateService = useCallback(
    (instanceId: string): void => {
      setStepNext();
      setServiceId(instanceId);
    },
    [setStepNext, setServiceId]
  );

  return {
    currentStep,
    setStepNext,
    serviceId,
    onCreateService
  };
};
