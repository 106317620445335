import {
  CreateInstanceBackupRequest,
  DeleteInstanceBackupRequest,
  InstanceBackup,
  ListInstanceBackupsRequest,
  ListInstanceBackupsResponse,
  RestoreInstanceBackupRequest,
  RestoreInstanceBackupResponse
} from '@cp/common/protocol/Backup';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const instanceBackupApiUrl = `${config.controlPlane.apiHost}/api/backup`;
export interface BackupActionInput {
  instanceId: string;
  organizationId: string;
}
type BackupRequest =
  | CreateInstanceBackupRequest
  | ListInstanceBackupsRequest
  | DeleteInstanceBackupRequest
  | RestoreInstanceBackupRequest;

export type RestoreInstanceBackupInput = Omit<RestoreInstanceBackupRequest, 'rpcAction'>;

export class BackupsApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: BackupRequest, url = instanceBackupApiUrl): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async restore({
    backupId,
    instanceName,
    organizationId,
    instanceId,
    passwordHash,
    doubleSha1Password
  }: RestoreInstanceBackupInput): Promise<RestoreInstanceBackupResponse> {
    const request: RestoreInstanceBackupRequest = {
      rpcAction: 'restore',
      instanceName,
      backupId,
      organizationId,
      instanceId,
      passwordHash,
      doubleSha1Password
    };
    const response = await this.rpcRequest(request);
    if (response.ok) {
      const restoreResponse = (await response.json()) as unknown as RestoreInstanceBackupResponse;
      return restoreResponse;
    } else {
      const error = (await response.json()).message;
      throw new Error(error);
    }
  }

  async list({ instanceId, organizationId }: BackupActionInput): Promise<Array<InstanceBackup>> {
    const request: ListInstanceBackupsRequest = {
      rpcAction: 'list',
      organizationId,
      instanceId
    };
    const response = await this.rpcRequest(request);
    if (response.ok) {
      const backupsResponse = (await response.json()) as unknown as ListInstanceBackupsResponse;
      return backupsResponse.backups;
    } else {
      throw new Error('Could not fetch backups for instance');
    }
  }
}
