import { ReactElement, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getInstanceDatabaseAccess } from 'src/instance/instance';
import { useCurrentInstance } from 'src/instance/instanceController';

export const ByocDatabaseAccessGuard = (): ReactElement => {
  const currentService = useCurrentInstance();
  const databaseAccess = getInstanceDatabaseAccess(currentService);
  if (!databaseAccess.enabled && databaseAccess.shouldRedirect) {
    if (currentService) {
      return (
        <Navigate replace to={`/services/${currentService.id}/settings`} />
      );
    } else {
      return <Navigate replace to="/services" />;
    }
  }
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};
