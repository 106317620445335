import { ReactElement, useEffect, useRef, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd-cjs';
import { getEmptyImage } from 'react-dnd-html5-backend-cjs';

import LockIcon from 'src/components/icons/LockIcon';
import {
  logCreateFolder,
  sidebarLogEvent,
  onContextMenuToggle
} from 'src/components/QueryView/analytics';
import { MIDDLE_MOUSE_BTN } from 'src/lib/mouseBtns';

import useDoubleClickHandler from 'src/components/QueryView/QueryList/Folders/Tree/doubleClickHandler';
import NodeName from 'src/components/QueryView/QueryList/Folders/Tree/NodeName';
import {
  contentRootStyle,
  sidebarCloseBtn
} from 'src/components/QueryView/QueryList/Folders/Tree/styles';
import {
  ContentNodeProps,
  CollectedProps,
  ItemType
} from 'src/components/QueryView/QueryList/Folders/Tree/types';
import {
  IconButton,
  SidebarNavigationItem,
  Tooltip,
  ContextMenu,
  Icon,
  createToast
} from '@clickhouse/click-ui';
import { tooltipZIndex } from 'src/components/ChartConfigSidebar/styles';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';

const logName = 'queryContextMenu';

const ContentNode = ({
  category,
  currentTabId,
  id,
  isShared,
  level,
  mainRef,
  noAccess,
  onClick,
  onContentRename,
  onCreateFolder,
  onDelete,
  onDrop,
  onExpand,
  parentId,
  path,
  title: titleProp,
  type
}: ContentNodeProps): ReactElement => {
  const titleRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState(titleProp);
  const [editable, setEditable] = useState(false);
  const ref = useRef(null);
  const clickHandler = useDoubleClickHandler(id, onClick);
  const savedQuery = useSavedQuery(id);

  const [{ isDragging }, drag, preview] = useDrag({
    canDrag: !category,
    item: {
      title,
      type: 'node',
      isFolder: false,
      parentId,
      path,
      id
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [, drop] = useDrop<ItemType, unknown, Partial<CollectedProps>>({
    accept: 'node',
    canDrop: () => !category,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      if (item.parentId !== parentId) {
        onDrop(item.id, parentId, 'bottom', item);
      }
    },
    collect: (monitor) => {
      const isOver = !!monitor.isOver({ shallow: true });

      if (isOver) {
        mainRef.current?.setSelectedFolder(parentId);
      } else {
        mainRef.current?.setSelectedFolder(null);
      }

      return {
        isOver
      };
    }
  });

  drag(drop(ref));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const renameContent = (): void => {
    setEditable(true);
    setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.select();
      }
    });
  };

  const onRename = async (title: string): Promise<void> => {
    try {
      await onContentRename(id, title);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        createToast({
          description: e.message,
          title: 'Query rename failed',
          type: 'danger'
        });
      }
      setTitle(titleProp);
    }
  };

  const canRename =
    savedQuery &&
    (savedQuery.userPermissions === 'write' ||
      savedQuery.userPermissions === 'owner');
  const canDelete = savedQuery && savedQuery.userPermissions === 'owner';

  return (
    <ContextMenu onOpenChange={onContextMenuToggle}>
      <ContextMenu.Trigger>
        <SidebarNavigationItem
          aria-label={`contentNode:${title}`}
          type="sqlSidebar"
          ref={ref}
          data-testid="contentNode"
          css={contentRootStyle(level)}
          selected={id === currentTabId}
          data-dragging={isDragging}
          level={level}
          onClick={clickHandler}
          onMouseDown={(e): void => {
            if (e.button === MIDDLE_MOUSE_BTN) {
              e.preventDefault();
              onClick({ id, allowSetCurrentTab: false });
              sidebarLogEvent(type, 'Open');
            }
          }}
          icon="query"
          label={
            <>
              <NodeName
                onRename={onRename}
                editable={editable}
                setEditable={setEditable}
                title={title}
                setTitle={setTitle}
                currentTitle={titleProp}
                ref={titleRef}
              >
                {' '}
                {noAccess && <LockIcon />}
                {isShared && <Icon name="users" />}
              </NodeName>
              {!isDragging && (
                <Tooltip>
                  <Tooltip.Trigger
                    className="delete-icon"
                    css={sidebarCloseBtn}
                  >
                    <IconButton
                      icon="cross"
                      type="ghost"
                      size="xs"
                      onClick={(e): void => {
                        e.stopPropagation();
                        onDelete(path);
                        sidebarLogEvent(type, 'Delete');
                      }}
                    />
                  </Tooltip.Trigger>
                  <Tooltip.Content css={tooltipZIndex}>
                    Delete query
                  </Tooltip.Content>
                </Tooltip>
              )}
            </>
          }
        />
      </ContextMenu.Trigger>
      <ContextMenu.Content side="bottom" align="start" loop>
        {canRename && (
          <ContextMenu.Item
            onClick={(): void => {
              renameContent();
              sidebarLogEvent(logName, 'QueryRename');
            }}
          >
            Rename query
          </ContextMenu.Item>
        )}
        <ContextMenu.Item
          onClick={(): void => {
            onClick({ id });
            sidebarLogEvent(logName, 'QueryOpen');
          }}
        >
          Open query
        </ContextMenu.Item>
        <ContextMenu.Item
          onClick={(): void => {
            void onCreateFolder(id).then((newFolderId) => {
              onExpand(path, newFolderId);
              logCreateFolder();
            });
          }}
        >
          Create folder
        </ContextMenu.Item>
        {canDelete && (
          <ContextMenu.Item
            onClick={(): void => {
              onDelete(path);
              sidebarLogEvent(logName, 'QueryDelete');
            }}
          >
            Delete query
          </ContextMenu.Item>
        )}
      </ContextMenu.Content>
    </ContextMenu>
  );
};

export default ContentNode;
