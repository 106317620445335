import { ReactElement, useEffect } from 'react';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { ServiceMetrics } from 'src/components/ServiceMetrics/ServiceMetrics';
import Helmet from 'src/components/Helmet/Helmet';
import SplitLayout from 'src/layout/SplitLayout';
import { Galaxy } from 'galaxy';

export default function MonitoringPage(): ReactElement {
  const instance = useCurrentInstanceOrThrow();

  useEffect(() => {
    return (): void => {
      Galaxy.galaxy().track('serviceHealth.page.navigatedAway');
    };
  }, []);

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label={`${instance.name} - Health`} />
      <MainPaneLayout.Content>
        <Helmet>
          <title>{instance.name} - Health</title>
        </Helmet>
        <SplitLayout>
          <ServiceMetrics instance={instance} />
        </SplitLayout>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
}
