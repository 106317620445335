import config from 'src/lib/config';

export type ListAuthenticatorsResponse = Array<{
  authenticator_type: 'otp';
  id: string;
  active: boolean;
}>;

type VerifyResponse = {
  access_token: string;
};

export async function getMfaAuthenticatorId(mfaAccessToken: string): Promise<string> {
  const { domain } = config.auth0Web;
  const response = await fetch(`https://${domain}/mfa/authenticators`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${mfaAccessToken}`
    }
  });
  const data = (await response.json()) as ListAuthenticatorsResponse;
  if (!data) {
    throw new Error('Failed to get retrieve mfa details');
  }
  const activeAuthenticators = data.filter(({ active }) => active);
  return activeAuthenticators[0].id;
}

export async function deleteMfaAuthenticator(mfaAccessToken: string, authenticatorId: string): Promise<void> {
  const { domain } = config.auth0Web;
  const response = await fetch(`https://${domain}/mfa/authenticators/${authenticatorId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${mfaAccessToken}`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to delete TOTP authenticator');
  }
}
/**
 * Submit the OTP code to Auth0 to verify.
 * @param {string} mfaAccessToken The MFA access token.
 * @param {string} otpCode The OTP code to verify.
 * @returns {Promise<void>}
 * @throws {Error} If the verification fails.
 */
export async function verifyOtpCode(mfaAccessToken: string, otpCode: string): Promise<void> {
  const { domain, clientId } = config.auth0Web;
  const response = await fetch(`https://${domain}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${mfaAccessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      grant_type: 'http://auth0.com/oauth/grant-type/mfa-otp',
      client_id: clientId,
      mfa_token: mfaAccessToken,
      otp: otpCode
    })
  });
  const json = (await response.json()) as VerifyResponse;
  if (!json?.access_token) {
    throw new Error('MFA verification failed');
  }
}
