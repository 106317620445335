import React, { ReactElement, useState } from 'react';
import {
  Dialog,
  Button,
  TextField,
  Separator,
  Text,
  Spacer,
  Container
} from '@clickhouse/click-ui';

interface DeleteClickPipeModalProps {
  open: boolean;
  name: string;
  pipeId: string;
  onDelete: () => Promise<boolean>;
  onCancel: () => void;
}

const ClickPipeDeleteDialog = ({
  open,
  pipeId,
  name,
  onDelete,
  onCancel
}: DeleteClickPipeModalProps): ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const deletePipe = async (): Promise<void> => {
    setLoading(true);
    const isSuccess = await onDelete();
    setLoading(false);
    setInputValue('');

    // A toast will appear if the deletion is unsuccessful
    if (isSuccess) {
      onCancel();
    }
  };

  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
          setInputValue('');
        }
      }}
    >
      <Dialog.Content title="Delete ClickPipe" showClose onClose={onCancel}>
        <Text size="md">
          Terminating <b>{name}</b> ({pipeId}) will permanently delete all
          settings and ingest resources associated with this ClickPipe. This
          will not delete the destination or error tables. This action cannot be
          undone.
          <Spacer size={'sm'} />
          To continue, please enter the name of your ClickPipe.
        </Text>
        <Spacer />
        <TextField
          value={inputValue}
          onChange={setInputValue}
          data-testid="delete-clickpipe-modal-input-field"
        />
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Cancel" onClick={onCancel} />
          <Button
            type="danger"
            disabled={!(inputValue.trimEnd() === name)}
            loading={loading}
            onClick={deletePipe}
            data-testid="delete-clickpipe-modal-delete-button"
          >
            Delete ClickPipe
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};

export default React.memo(ClickPipeDeleteDialog);
