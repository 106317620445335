import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import ServiceLayout from 'src/layout/ServiceLayout';
import { useIsUnifiedConsole } from 'src/lib/features';

export const ServiceLayoutGuard = (): ReactElement => {
  const enableUnifiedConsole = useIsUnifiedConsole();

  if (enableUnifiedConsole) {
    return <ServiceLayout />;
  }
  return <Outlet />;
};
