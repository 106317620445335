import {
  CardHorizontal,
  CardPrimary,
  Container,
  Icon,
  Text
} from '@clickhouse/click-ui';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import * as uuid from 'uuid';
import {
  AccordionWrapper,
  CommonAccordionProps,
  GettingStartedValueType
} from 'src/components/GettingStarted/AccordionWrapper';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import {
  getClickPipeImportModel,
  getNewFileUploadModel,
  useImportModel
} from 'src/lib/dataLoading';
import { ServiceConnect } from 'src/components/ServiceConnect/ServiceConnect';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { useTabActions } from 'src/state/tabs';
import { Galaxy } from 'galaxy';

const dummyCreateTableQuery = `
/* Create your first table easily by pressing + New table in the table view, or type your query manually.If you get stuck, SQL AI is here to help (upper right corner).

You can also create tables when you upload a file, or ingest data from Kafka, S3, GCS, and others, when you navigate to Data Sources. */

CREATE TABLE my_first_table (
    user_id UInt32,
    message String,
    timestamp DateTime,
    metric Float32
)
ORDER BY (user_id, timestamp) -- allows you to put the same data nearby
PRIMARY KEY (user_id, timestamp)  -- OPTIONAL but recommended, the primary key of a ClickHouse table determines how the data is sorted when written to disk
PARTITION BY toYYYYMM(timestamp) -- OPTIONAL, mostly beneficial when you’re looking to roll data off

/* Next, navigate to “Data sources” in the sidebar to start ingesting data or visit our blog series for different ingestion methods: https://clickhouse.com/blog/getting-data-into-clickhouse-part-1/




`;

const ImportAccordion = ({
  moveToNextStep,
  showProgress,
  ...props
}: CommonAccordionProps & {
  moveToNextStep: (type: GettingStartedValueType) => void;
}): ReactElement => {
  useEffect(() => {
    if (props.isCompleted) {
      moveToNextStep('loadData');
    }
  }, [props.isCompleted, moveToNextStep]);

  const { setImportModel } = useImportModel();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addNewQueryTab } = useTabActions();
  const instance = useCurrentInstanceOrThrow();

  const openModal = useCallback((): void => {
    Galaxy.galaxy().track(
      'gettingStarted.importAccordion.connectModalClicked',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    setIsModalOpen(true);
  }, [showProgress]);

  const navigateToClickPipes = useCallback((): void => {
    const model = getClickPipeImportModel(uuid.v4());
    setImportModel(model);
    Galaxy.galaxy().track(
      'gettingStarted.importAccordion.clickPipesImportClicked',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    navigateTo(routes.import({ serviceId: instance.id, importId: model.id }));
  }, [instance.id, showProgress, setImportModel]);

  const navigateToUploadCSV = useCallback((): void => {
    const model = getNewFileUploadModel(uuid.v4());
    setImportModel(model);
    Galaxy.galaxy().track(
      'gettingStarted.importAccordion.uploadCSVImportClicked',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    navigateTo(routes.import({ serviceId: instance.id, importId: model.id }));
  }, [instance.id, showProgress, setImportModel]);

  const navigateToNewTableTab = useCallback((): void => {
    Galaxy.galaxy().track('gettingStarted.importAccordion.createTableClick', {
      interaction: 'click',
      from: showProgress ? 'main' : 'help'
    });
    addNewQueryTab({
      title: 'Create dummy table',
      type: 'query',
      saved: false,
      query: dummyCreateTableQuery
    });
  }, [addNewQueryTab, showProgress]);

  return (
    <AccordionWrapper title="Add data" value="loadData" {...props}>
      <Container isResponsive={false} orientation="vertical" gap="lg">
        <CardPrimary onClick={navigateToClickPipes}>
          <Container gap="md" justifyContent="center">
            <Icon name="kafka" size="xl" />
            <Icon name="confluent" size="xl" />
            <Icon name="aws-msk" size="xl" />
            <Icon name="aws-kinesis" size="xl" />
            <Icon name="redpanda" size="xl" />
            <Icon name="upstash" size="xl" />
            <Icon name="warpstream" size="xl" />
            <Icon name="azure-event-hub" size="xl" />
            <Icon name="aws-s3" size="xl" />
            <Icon name="gcs" size="xl" />
          </Container>
          <Text weight="semibold" align="center" fillWidth>
            Ingest data using ClickPipes
          </Text>
          <Container fillWidth isResponsive={false} justifyContent="center">
            <Text
              color="muted"
              align="center"
              component={Container}
              maxWidth="600px"
            >
              Ingest live data from sources like AWS S3, Apache Kafka, AWS MSK,
              Confluent, Google Cloud Storage, and many more
            </Text>
          </Container>
        </CardPrimary>
        <Container gap="md" alignItems="stretch">
          <CardHorizontal
            icon="cloud"
            title="Upload file"
            description="CSV, TSV, JSON (Max 1GB)"
            onClick={navigateToUploadCSV}
          />
          <CardHorizontal
            icon="connect-alt"
            title="Connect with your app"
            description="Select language or interface"
            onClick={openModal}
          />
          <CardHorizontal
            icon="table"
            title="Create an empty table"
            description="Go to the SQL console"
            onClick={navigateToNewTableTab}
          />
        </Container>
      </Container>
      <ServiceConnect
        instance={instance}
        isModal
        open={isModalOpen}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
      />
    </AccordionWrapper>
  );
};

export default ImportAccordion;
