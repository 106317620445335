import {
  Badge,
  Container,
  Icon,
  Panel,
  Text as CUIText,
  Title,
  Tooltip
} from '@clickhouse/click-ui';
import {
  InstanceTier,
  MaintenanceWindowStatus
} from '@cp/common/protocol/Instance';

import { ReactElement, useEffect, useState } from 'react';
import { StatusIcon } from 'src/components/InstanceStatusIcon';
import ServiceActionsMenu from 'src/components/ServiceActionsMenu/ServiceActionsMenu';
import {
  InstanceStateWithUpgrade,
  formatMemoryAllocation,
  formatStateForDisplay
} from 'src/instance/instance';
import styled from 'styled-components';
import { useSelectService } from 'src/state/service/selectService';
import { navigateToService } from 'src/components/DataWarehouseList/navigateToService';

export interface DataWarehouseServiceCardProps {
  serviceId: string;
  title: string;
  showDelete: boolean;
  instanceTier: InstanceTier;
  state: InstanceStateWithUpgrade;
  onClick: () => void;
  maintenanceStatus: MaintenanceWindowStatus;
  isDesktop: boolean;
  isEncrypted: boolean;
  minAutoscalingTotalMemory: number;
  maxAutoscalingTotalMemory: number;
  numReplicas: number;
  isPrimary: boolean;
  isReadOnly: boolean;
}

const CardContainer = styled(Panel)<{ disabled: boolean }>`
  transition: background-color 0.2s;
  align-self: stretch;
  gap: 0;
  &:hover,
  :focus {
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : '')};
    border-color: ${({ theme }): string =>
      theme.click.global.color.stroke.intense};
  }
`;

const LeftHeaderElemnt = styled(Container)`
  padding-right: 0;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
`;

const CardBody = styled(Container)`
  cursor: ${(props): string => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const TooltipTrigger = styled(Tooltip.Trigger)`
  overflow: hidden;
  max-width: 100%;
`;

const ServiceTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${({ theme }): string => theme.spaces[2]};
`;

const ServiceState = styled(CUIText)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const CustomBadge = styled(Badge)`
  width: max-content;
`;

function getAbbreviatedInstanceTier(tier: InstanceTier): string {
  switch (tier) {
    case 'Development':
      return 'Dev';
    case 'Production':
      return 'Prod';
    default:
      return tier;
  }
}

export const DataWarehouseServiceCard = ({
  showDelete,
  title,
  instanceTier,
  state,
  onClick,
  serviceId,
  maintenanceStatus,
  minAutoscalingTotalMemory,
  maxAutoscalingTotalMemory,
  numReplicas,
  isEncrypted,
  isDesktop,
  isPrimary,
  isReadOnly
}: DataWarehouseServiceCardProps): ReactElement => {
  const [isPerformMaintenanceConfirmed, setIsPerformMaintenanceConfirmed] =
    useState(false);
  const isDisabled = ['terminating', 'terminated', 'upgrading-error'].includes(
    state
  );

  // TODO(#9283):: Move isMaintenanceTriggered to InstanceState
  useEffect(() => {
    if (!maintenanceStatus) {
      setIsPerformMaintenanceConfirmed(false);
    }
  }, [maintenanceStatus]);

  const handleCardClick = (): void => {
    if (isDisabled) {
      return;
    }
    onClick();
  };

  const onPerformMaintenanceConfirm = (): void => {
    setIsPerformMaintenanceConfirmed(true);
  };

  const maintenanceButtonStatus: MaintenanceWindowStatus =
    isPerformMaintenanceConfirmed ? 'in-progress' : maintenanceStatus;

  const selectService = useSelectService();

  return (
    <CardContainer
      hasBorder
      width="100%"
      orientation="vertical"
      alignItems="start"
      data-testid="service-card"
      disabled={isDisabled}
      onClick={() => {
        navigateToService({
          serviceId,
          state,
          isDesktop
        });
        selectService({ serviceId });
      }}
      padding="md"
    >
      <Container isResponsive={false}>
        <LeftHeaderElemnt
          orientation="horizontal"
          alignItems="start"
          gap="xs"
          justifyContent="space-between"
          isResponsive={false}
          fillWidth={true}
          disabled={isDisabled}
          onClick={handleCardClick}
          maxWidth="calc(100% - 2.75rem)"
        >
          <Container
            orientation="horizontal"
            gap="md"
            isResponsive={false}
            overflow="hidden"
            fillHeight
          >
            <Container isResponsive={false} fillWidth={false}>
              <Icon name="cpu" />
              <Tooltip>
                <TooltipTrigger>
                  <ServiceTitle
                    type="h3"
                    size="sm"
                    color="default"
                    data-testid="instance-title"
                  >
                    {title}
                  </ServiceTitle>
                </TooltipTrigger>
                <Tooltip.Content data-testid="tooltip-content">
                  {title}
                </Tooltip.Content>
              </Tooltip>
            </Container>
            <Container wrap="wrap" isResponsive={false} gap="sm">
              {isPrimary && (
                <Tooltip>
                  <TooltipTrigger>
                    <Badge text="Primary" />
                  </TooltipTrigger>
                  <Tooltip.Content data-testid="tooltip-content">
                    This is the primary service
                  </Tooltip.Content>
                </Tooltip>
              )}
              <Badge text={isReadOnly ? 'Read only' : 'Read-write'} />
            </Container>
          </Container>
          <Container
            justifyContent="end"
            gap="sm"
            fillWidth={false}
            isResponsive={false}
          >
            <CustomBadge
              text={getAbbreviatedInstanceTier(instanceTier)}
              state={'neutral'}
              data-testid="instance-tier"
            />
            {isEncrypted && <Icon name="key" />}
          </Container>
        </LeftHeaderElemnt>
        <Container
          orientation="horizontal"
          alignItems="start"
          padding="sm"
          justifyContent="space-between"
          isResponsive={false}
          fillWidth={false}
        >
          <ServiceActionsMenu
            serviceId={serviceId}
            isDisabled={isDisabled}
            isInMaintenance={maintenanceButtonStatus === 'in-progress'}
            onStartMigrationMenuClick={onPerformMaintenanceConfirm}
            showDelete={showDelete}
            handleStartMigration={function (
              runAfterMaintenance: boolean
            ): void {
              throw new Error('Function not implemented.');
            }}
          />
        </Container>
      </Container>
      <CardBody
        orientation="vertical"
        gap="md"
        alignItems="start"
        isResponsive={false}
        disabled={isDisabled}
        onClick={handleCardClick}
      >
        <Container
          orientation="horizontal"
          gap="sm"
          justifyContent="start"
          isResponsive={false}
          fillWidth={false}
          data-testid="aaaaaa"
        >
          <StatusIcon status={state} />

          <ServiceState data-testid="instance-state">
            {formatStateForDisplay(state)}
          </ServiceState>
        </Container>
        <Panel
          color="muted"
          fillWidth
          orientation="vertical"
          alignItems="start"
        >
          <CUIText weight="mono">
            Min size per node:{' '}
            {formatMemoryAllocation(minAutoscalingTotalMemory, instanceTier)}
          </CUIText>
          <CUIText weight="mono">
            Max size per node:{' '}
            {formatMemoryAllocation(maxAutoscalingTotalMemory, instanceTier)}
          </CUIText>
          <CUIText weight="mono">Nubmer of nodes: {numReplicas}</CUIText>
        </Panel>
        {!!maintenanceButtonStatus && (
          <Container justifyContent="end" isResponsive={false}>
            <Badge
              text={
                maintenanceButtonStatus === 'in-progress'
                  ? 'Performing maintenance'
                  : `Maintenance ${maintenanceButtonStatus}`
              }
              state="info"
              data-testid="maintenance-status-state"
            />
          </Container>
        )}
      </CardBody>
    </CardContainer>
  );
};
