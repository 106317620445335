export default [
  {
    keyword: 'CREATE',
    text: 'CREATE TABLE TABLE_NAME (COLUMN_NAME DATATYPES);',
    type: 'command',
    description: 'Used to create a new table in the database.',
    tokens: ['TABLE_NAME', 'COLUMN_NAME', 'DATATYPES']
  },
  {
    keyword: 'UPDATE',
    text: 'UPDATE TABLE_NAME SET COLUMN_NAME = VALUE WHERE CONDITION;',
    description: 'Used to update or modify the VALUE of a column in the table.',
    type: 'command',
    tokens: ['TABLE_NAME', 'COLUMN_NAME', 'VALUE', 'CONDITION']
  },
  {
    keyword: 'DELETE',
    text: 'DELETE FROM TABLE_NAME WHERE CONDITION;',
    description: 'Used to remove one or more row from a table.',
    type: 'command',
    tokens: ['TABLE_NAME', 'CONDITION']
  },
  {
    keyword: 'INSERT',
    text: 'INSERT INTO TABLE_NAME (COLUMNS) VALUES (COLUMN_VALUES);',
    description: 'Used to insert data into the row of a table.',
    type: 'command',
    tokens: ['TABLE_NAME', 'COLUMNS', 'COLUMN_VALUES']
  },
  {
    keyword: 'ALTER',
    text: 'ALTER TABLE TABLE_NAME COMMAND COLUMN_NAME COLUMN_DEFINITION;',
    description: 'Used to alter the structure of a table.',
    type: 'command',
    tokens: ['TABLE_NAME', 'COMMAND', 'COLUMN_NAME', 'COLUMN_DEFINITION']
  },
  {
    keyword: 'SELECT',
    text: 'SELECT COLUMN_NAME FROM TABLE_NAME WHERE CONDITION;',
    description: 'Used to select data from a database.',
    type: 'command',
    tokens: ['COLUMN_NAME', 'TABLE_NAME', 'CONDITION']
  },
  {
    keyword: '(INNER) JOIN',
    text: 'SELECT COLUMN_NAME FROM TABLE_NAME JOIN TABLE_NAME ON COLUMN_NAME = COLUMN_NAME WHERE CONDITION;',
    description: 'Returns records that have matching values in both tables',
    type: 'command',
    tokens: ['COLUMN_NAME', 'TABLE_NAME', 'CONDITION']
  },
  {
    keyword: 'LEFT (OUTER) JOIN',
    text: 'SELECT COLUMN_NAME FROM TABLE_NAME LEFT JOIN TABLE_NAME ON COLUMN_NAME = COLUMN_NAME WHERE CONDITION;',
    description:
      'Returns all records from the left table, and the matched records from the right table',
    type: 'command',
    tokens: ['COLUMN_NAME', 'TABLE_NAME', 'CONDITION']
  },
  {
    keyword: 'RIGHT (OUTER) JOIN',
    text: 'SELECT COLUMN_NAME FROM TABLE_NAME RIGHT JOIN TABLE_NAME ON COLUMN_NAME = COLUMN_NAME WHERE CONDITION;',
    description:
      'Returns all records from the right table, and the matched records from the left table',
    type: 'command',
    tokens: ['COLUMN_NAME', 'TABLE_NAME', 'CONDITION']
  },
  {
    keyword: 'FULL (OUTER) JOIN',
    text: 'SELECT COLUMN_NAME FROM TABLE_NAME FULL OUTER JOIN TABLE_NAME ON COLUMN_NAME = COLUMN_NAME WHERE CONDITION;',
    description:
      'Returns all records when there is a match in either the left or right table',
    type: 'command',
    tokens: ['COLUMN_NAME', 'TABLE_NAME', 'CONDITION']
  },
  {
    keyword: 'TRUNCATE',
    text: 'TRUNCATE TABLE TABLE_NAME;',
    description:
      'Used to delete all rows from the table and free the space containing the table.',
    type: 'command',
    tokens: ['TABLE_NAME']
  },
  {
    keyword: 'DROP',
    text: 'DROP TABLE TABLE_NAME;',
    description:
      'Used to delete both the structure and record stored in the table.',
    type: 'command',
    tokens: ['TABLE_NAME']
  }
];
