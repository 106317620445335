import { MILLIS_PER_DAY } from '@cp/common/utils/DateTimeUtils';
import dayjs from 'dayjs';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);

const thresholds = [
  { l: 's', r: 1, d: 'second' },
  { l: 'ss', r: 56, d: 'second' },
  { l: 'm', r: 90, d: 'second' },
  { l: 'mm', r: 55, d: 'minute' },
  { l: 'h', r: 90, d: 'minute' },
  { l: 'hh', r: 22, d: 'hour' },
  { l: 'd', r: 40, d: 'hour' },
  { l: 'dd', r: 31, d: 'day' },
  { l: 'M', r: 45, d: 'day' },
  { l: 'MM', r: 11, d: 'month' },
  { l: 'y', r: 17, d: 'month' },
  { l: 'yy', r: 2, d: 'year' }
];

dayjs.extend(relativeTime, { thresholds });

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'In %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    w: '1 week',
    ww: '%d weeks',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years'
  }
});

export function formatDate(date: string | number | Date): string {
  // 2024-01-13
  return dayjs(date).format('YYYY-MM-DD');
}

export function formatTimeShort(timestamp: string | number | Date): string {
  // 2024-01-13 01:00am
  return dayjs(timestamp).format('YYYY-MM-DD hh:mm A');
}

export function formatDateHuman(timestamp: string | number | Date): string {
  // January 15, 2023
  return dayjs(timestamp).format('MMMM D, YYYY');
}

export function formatTimeHoursMinutes(timestamp: string | number | Date): string {
  // 01:00
  return dayjs(timestamp).format('HH:mm');
}

export function formatDayMonth(timestamp: string | number | Date): string {
  // 15. Jan
  return dayjs(timestamp).format('D. MMM');
}

export function formatMonthYear(timestamp: string | number | Date): string {
  // Jan. 23
  return dayjs(timestamp).format('MMM. YY');
}

export function formatDateShort(timestamp: string | number | Date): string {
  // Jan 15, 2023
  return dayjs(timestamp).format('MMM D, YYYY');
}

export function formatDateLong(timestamp: string | number | Date): string {
  return dayjs(timestamp).format('L LT');
}

export function formatDateHumanShort(date: string | number | Date): string {
  // locale aware format 13 Jan, 2024
  return dayjs(date).format('ll');
}

export function formatTime(timestamp: string | number | Date): string {
  // 13, Jan 24 01:00 AM
  return dayjs(timestamp).format('lll');
}

export function formatTimestamp(timestamp: string | number | Date): string {
  return dayjs(timestamp).format('YYYY-MM-DD HH:mm');
}

// 2024-01-13 01:00:00
export function formatTimestampWithSeconds(timestamp: string | number | Date): string {
  return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * If (current year)
 *  dd, hh:mm a.m./p.m (Time zone)
 * else
 *  dd, YYYY, hh:mm a.m./p.m (Time zone)
 *
 * Example: Sep 3, 10:12 a.m. (CET)
 */
export const formatDateLongSmart = (date: Date, withTZ?: boolean, today?: Date): string => {
  if (!today) {
    today = new Date();
  }
  let tz = '';
  let result = dayjs(date).format('MMM DD, hh:mm A');

  if (date.getFullYear() !== today.getFullYear()) {
    result = dayjs(date).format('MMM DD, YYYY, hh:mm A');
  }

  if (withTZ) {
    tz = ` (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
  }

  return `${result}${tz}`;
};

/** (yet another way to) Format a JavaScript date object as a locale-friendly date
 * 11:15:00, Feb 23 2024
 **/
export const formatFullDateForQueryInsights = (date: Date): string => {
  return dayjs(date).format('HH:mm:ss, MMM DD YYYY');
};

// 11:15:00, Feb 23
export const formatShortDateForQueryInsights = (date: Date): string => {
  return dayjs(date).format('HH:mm:ss, MMM DD');
};

// 11:15:00, Feb 23 2024
export function formatDateRangeInBrowserTz(startDate: string | number | Date, endDate: string | number | Date): string {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const yearFormat = startDateObj.getFullYear() !== endDateObj.getFullYear() ? 'numeric' : undefined;

  // Create dates in local time.
  const end = formatDateHumanShort(endDate);

  const from = new Date(startDate).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
    year: yearFormat
  });

  return `${from} - ${end}`;
}

// 15 seconds ago
export const formatMoment = (date: string | number | Date): string => {
  return dayjs.utc(date).fromNow();
};

export const formatMomentUptoWeek = (date: string | number): string => {
  const newDate = new Date(date);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  if (newDate < sevenDaysAgo) {
    return formatTimestampWithSeconds(date);
  }
  return dayjs.utc(date).fromNow();
};

export const humanizeDuration = (val: number, unit: DurationUnitType): string => {
  return dayjs.duration(val, unit).humanize();
};

export const formatUtcTimestamp = (timestamp: string | number | Date, format?: string): string => {
  return dayjs.utc(timestamp).format(format);
};

// used in charts. Not sure how to get rid of it
export function formatTimestampAny(timestamp: string | number | Date, format: string): string {
  return dayjs(timestamp).format(format);
}

/** Format a JavaScript Date object as a locale-friendly time
 * 11:15 AM, 5:37 PM
 * 11:15, 17:37
 **/
export const formatDateToTime = (date: Date, locale?: string): string => {
  const formatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric'
  });
  return formatter.format(date);
};

// 11:15:00
export const formatDateToTimeOrDate = (date: Date, locale?: string): string => {
  const olderThanOneDay = (Date.now() - date.getTime()) / MILLIS_PER_DAY >= 1;

  const formatter = new Intl.DateTimeFormat(locale, {
    month: olderThanOneDay ? '2-digit' : undefined,
    day: olderThanOneDay ? '2-digit' : undefined,
    hour: 'numeric',
    minute: 'numeric'
  });

  return formatter.format(date);
};

export const validateDateTimeFormat = (date: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
  return regex.test(date);
};

/** Converts a UTC 24 hour format (HH:MM) to the local hour 12hour format HH:MM AM/PM string */
export function convertUTCToLocalTime(utcTime: string): string {
  const [startHour, startMinute] = utcTime.split(':').map(Number);
  const now = new Date();
  now.setUTCHours(startHour, startMinute, 0, 0);

  const timeString = now.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return timeString.replace('am', 'a.m.').replace('AM', 'a.m.').replace('pm', 'p.m.').replace('PM', 'p.m.');
}

/** Converts a 24 hour format (HH:MM) to the 12 hour format HH:MM AM/PM string */
export function convert24hTo12hTime(utcTime: string): string {
  const [startHour, startMinute] = utcTime.split(':').map(Number);
  const now = new Date();
  now.setUTCHours(startHour, startMinute, 0, 0);

  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes().toString().padStart(2, '0'); // Ensure two digits
  const amPm = hours >= 12 ? 'p.m.' : 'a.m.';
  const formattedHour = (((hours + 11) % 12) + 1).toString().padStart(2, '0'); // Convert 24h to 12h format and ensure two digits

  return `${formattedHour}:${minutes} ${amPm}`;
}
