import { ReactElement } from 'react';
import {
  isClickPipesKafkaImport,
  isClickPipesObjectStorageImport,
  isClickPipesKinesisImport
} from 'shared/src/dataLoading/utils';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { KafkaSample } from 'src/components/ImportView/ClickPipesImportForm/IncomingData/KafkaSample';
import { KinesisSample } from 'src/components/ImportView/ClickPipesImportForm/IncomingData/KinesisSample';
import { ObjectStorageSourceListing } from 'src/components/ImportView/ClickPipesImportForm/IncomingData/ObjectStorageSourceListing';

export default function IncomingData(): ReactElement | null {
  const { importModel } = useClickPipesImport();

  if (isClickPipesKafkaImport(importModel)) {
    return <KafkaSample />;
  } else if (isClickPipesObjectStorageImport(importModel)) {
    return <ObjectStorageSourceListing />;
  } else if (isClickPipesKinesisImport(importModel)) {
    return <KinesisSample />;
  } else {
    console.error('Unknown ClickPipe type');
    return null;
  }
}
