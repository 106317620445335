import { css } from '@emotion/react';

export default {
  container: css`
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
  `
};
