import {
  Button,
  CardPrimary,
  Container,
  GridContainer,
  Icon,
  Link as CuiLink,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { DashboardOutletContext } from 'src/components/Dashboards/DashboardLayout';
import { BorderBottomContainer } from 'src/components/Dashboards/DashboardView/styles';
import { Loading } from 'src/components/Dashboards/Loading';
import { useFetchDashboards } from 'src/dashboard/dashboardController';
import { useDashboards } from 'src/state/dashboard';

const DashboardListHeader = (): ReactElement => {
  return (
    <BorderBottomContainer orientation="vertical">
      <Container
        justifyContent="space-between"
        orientation="horizontal"
        padding="md"
      >
        <Title type="h2" size="md">
          Dashboards
        </Title>
      </Container>
    </BorderBottomContainer>
  );
};

export const DashboardListPage = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showCreateDialog } = useOutletContext<DashboardOutletContext>();
  const fetchDashboards = useFetchDashboards();
  const dashboards = useDashboards();

  useEffect(() => {
    setIsLoading(true);
    fetchDashboards()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }, [fetchDashboards]);

  if (isLoading) {
    return (
      <Container minHeight="50%" orientation="vertical">
        <DashboardListHeader />
        <Loading />
      </Container>
    );
  }

  if (!Object.keys(dashboards).length) {
    return (
      <Container minHeight="50%" orientation="vertical">
        <DashboardListHeader />
        <Container
          justifyContent="center"
          minHeight="100%"
          orientation="horizontal"
        >
          <Container
            fillHeight={false}
            fillWidth={false}
            gap="md"
            orientation="vertical"
          >
            <Container justifyContent="center" orientation="horizontal">
              <Icon name="pie-chart" size="xxl" />
            </Container>
            <Container justifyContent="center" orientation="horizontal">
              <Title type="h3" size="xl">
                Create your first dashboard
              </Title>
            </Container>
            <Container justifyContent="center" orientation="horizontal">
              <Container maxWidth="60%">
                <Text align="center">
                  Visualize your data using dashboards. Add charts, text, and
                  tables to help identify patterns and display results.{' '}
                  <CuiLink href="" target="_blank" rel="noopener noreferrer">
                    Read more.
                  </CuiLink>
                </Text>
              </Container>
            </Container>
            <Spacer size="xs" />
            <Container orientation="horizontal" justifyContent="center">
              <Button iconLeft="plus" onClick={showCreateDialog}>
                New Dashboard
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
    );
  }

  return (
    <Container orientation="vertical">
      <DashboardListHeader />
      <Spacer size="md" />
      <Container padding="lg">
        <GridContainer
          gridTemplateColumns="repeat(2, 1fr)"
          gap="md"
          justifyContent="center"
        >
          {Object.values(dashboards).map((dashboard) => {
            return (
              <Link key={dashboard.id} to={dashboard.id}>
                <CardPrimary
                  alignContent="start"
                  size="md"
                  title={dashboard.name}
                >
                  <Icon name="activity" size="xxl" />
                </CardPrimary>
              </Link>
            );
          })}
        </GridContainer>
      </Container>
    </Container>
  );
};
