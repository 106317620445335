import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';
import { snakeCase } from 'snake-case';

export function convertKeysToSnakeCase(obj: Record<string, unknown> | unknown[]): Record<string, unknown> | unknown[] {
  if (isArray(obj)) {
    return obj.map((v) => convertKeysToSnakeCase(v as Record<string, unknown>));
  } else if (obj !== null && isObject(obj)) {
    return reduce(
      obj as Record<string, unknown>,
      (result, value, key) => {
        result[snakeCase(key)] = convertKeysToSnakeCase(value as Record<string, unknown>);
        return result;
      },
      {} as Record<string, unknown>
    );
  }
  return obj;
}
