import { IntegrationCategory } from 'shared/src/types/integration';

/** List of all integration categories to be displayed in the Marketplace page. */
const ALL_INTEGRATION_CATEGORIES: ReadonlyArray<IntegrationCategory> = [
  'CLICKPIPES',
  'DATA_INGESTION',
  'DATA_VISUALIZATION',
  'SQL_CLIENT',
  'LANGUAGE_CLIENT',
  'DATA_INTEGRATION',
  'AI_ML',
  'SECURITY_GOVERNANCE',
  'DATA_MANAGEMENT'
];

export const INTEGRATIONS_CATEGORY_OPTIONS = [{ label: 'View all', value: 'VIEW_ALL' }].concat(
  ALL_INTEGRATION_CATEGORIES.map((category) => ({
    value: category,
    label: createCategoryLabel(category)
  }))
);

export function createCategoryLabel(input: IntegrationCategory): string {
  switch (input) {
    case 'CLICKPIPES':
      return 'ClickPipes';

    case 'DATA_INGESTION':
      return 'Data Ingestion';

    case 'DATA_VISUALIZATION':
      return 'Data Visualization';

    case 'SQL_CLIENT':
      return 'SQL Client';

    case 'LANGUAGE_CLIENT':
      return 'Language Client';

    case 'DATA_INTEGRATION':
      return 'Data Integration';

    case 'AI_ML':
      return 'AI/ML';

    case 'SECURITY_GOVERNANCE':
      return 'Security & Governance';

    case 'DATA_MANAGEMENT':
      return 'Data Management';
  }
}
