import PropTypes from 'prop-types';
import React from 'react';
import { formatDataSize } from 'src/lib/formatters/numberFormatter';
import { summaryContainer } from 'src/components/Metrics/styles';

export interface MetricSummaryProps {
  summaryInput: {
    data: number;
    dataFormat?: string;
    title: string;
    summary?: string | null;
    helpTooltipText?: string;
  };
}

const MetricSummary: React.FC<MetricSummaryProps> = ({
  summaryInput
}: MetricSummaryProps) => {
  return (
    <div css={summaryContainer} className="summary_container">
      <div
        className="summary_s3_storage_usage"
        data-testid="summary-S3_STORAGE_USAGE"
      >
        {
          <>
            <div className="summary_title">{summaryInput.title}</div>
            <div className="summary_value" data-testid="current-usage-in-bytes">
              {formatDataSize(summaryInput.data, summaryInput.dataFormat, 2)}
            </div>
            <div className="summary_separator_line"></div>
            <div className="summary_details">
              <div className="summary_details_line">{summaryInput.summary}</div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

MetricSummary.propTypes = {
  summaryInput: PropTypes.shape({
    title: PropTypes.string.isRequired,
    summary: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    data: PropTypes.number.isRequired
  }).isRequired
};

export default MetricSummary;
