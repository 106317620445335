import { Config } from 'src/lib/config/';

export default (config: Config): Config => {
  config.env = 'test';
  config.controlPlane.mocked = true;
  config.features.dataLoading = true;
  config.querying.useClustering = false;

  config.auth0Web = {
    domain: 'auth.control-plane-local.clickhouse-dev.com',
    clientId: 'TUBu1o7uzBmaQKgSLuxyRoRhxppX2GKX',
    audience: 'control-plane-web',
    callbackPath: '/',
    auth0domain: 'ch-local-dev.us.auth0.com',
    databaseConnectionName: 'ControlPlaneUserDb'
  };

  config.websockets.endpoint = 'ws://localhost:3001';
  config.datadog = undefined;
  config.fullStory = {
    orgId: ''
  };
  config.tackle = {
    awsMarketplaceProdPageUrl: 'https://aws.amazon.com/marketplace/pp/prodview-test',
    gcpMarketplaceProdPageUrl: 'https://console.cloud.google.com/marketplace/orders',
    azureMarketplaceProdPageUrl: 'https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2'
  };

  config.stripe = {
    publishableKey:
      'pk_test_51LJ0trEqDIZfA797YyebhaMBghaLRMv4uhRhh1dLJYOFcthvbW7vPt8q7f3EeVehgNwkC5wvz2TAlSJp12kg0K9400zSHN7rLT'
  };

  config.recaptcha = {
    siteKey: '6Lf3eY4hAAAAADHuCPaw1RzIqbyTLm_b5fqWna3B'
  };
  return config;
};
