import { useState } from 'react';
import { Draft } from 'immer';
import { useAtom } from 'jotai';
import {
  Alert,
  Button,
  Container,
  Link,
  PasswordField,
  Text,
  TextField,
  Select,
  Spacer
} from '@clickhouse/click-ui';
import { useApiClient } from 'src/lib/controlPlane/client';
import {
  ClickPipeObjectStorageImport,
  ObjectStorageAuthType,
  CredentialsBased,
  IamBased,
  ClickPipeObjectStorageType
} from 'shared/src/dataLoading/types';
import { kafkaTopicsAtom } from 'src/state/dataLoading';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { isAwsRegionId } from '@cp/common/protocol/Region';

type ConnectionStatus = 'unknown' | 'error' | 'success';
type FlattenedFieldKey = 'integrationName' | 'description' | 'path';

type FieldKey = FlattenedFieldKey | 'secret' | 'access_key' | 'role_arn';

const isPresent = (value: string | undefined): boolean =>
  typeof value === 'string' && value.length > 0;

const defaultErrorState: Record<string, string | undefined> = {};

function validateConnectionObject(
  importModel: ClickPipeObjectStorageImport
): Map<FieldKey, string> {
  const { auth, path } = importModel.data;
  const errors: Map<FieldKey, string> = new Map();

  if (!isPresent(path)) {
    errors.set('path', 'Field is required');
  }

  if (auth.type === 'iam_user') {
    if (!isPresent(auth.access_key_id)) {
      errors.set('access_key', 'Field is required');
    }
    if (!isPresent(auth.secret_key)) {
      errors.set('secret', 'Field is required');
    }
  }
  if (auth.type === 'iam_role') {
    if (!isPresent(auth.role_arn)) {
      errors.set('role_arn', 'Field is required');
    }
  }
  return errors;
}

const docsLink = 'https://clickhouse.com/docs/en/integrations/clickpipes';
const docsLinkGlobPattern =
  'https://clickhouse.com/docs/en/integrations/clickpipes/object-storage#limitations';

const getShortName = (name: ClickPipeObjectStorageType): string => {
  switch (name) {
    case 's3':
      return 'S3';
    case 'gcs':
      return 'GCS';
    default:
      return name;
  }
};

const getExampleBucket = (name: ClickPipeObjectStorageType): string => {
  const s3default =
    'https://clickhouse-public-datasets.s3.eu-central-1.amazonaws.com';
  switch (name) {
    case 's3':
      return s3default;
    case 'gcs':
      return 'https://storage.googleapis.com/clickhouse-public-datasets';
    default:
      return s3default;
  }
};

export function SetupObjectStorageConnection(): JSX.Element | null {
  const api = useApiClient();
  const serviceId = getCurrentServiceIdOrFail();
  const instance = useCurrentInstanceOrThrow();
  const [loading, setLoading] = useState(false);
  const [, setTopics] = useAtom(kafkaTopicsAtom);
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>('unknown');
  const [connectionError, setConnectionError] = useState<string | undefined>(
    undefined
  );

  const { regionId } = instance;

  const [errors, setErrors] = useState(defaultErrorState);

  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipeObjectStorageImport>();
  const data = importModel.data;

  const onFieldChangeHandler =
    (field: FlattenedFieldKey) => (value: string) => {
      setConnectionStatus('unknown');
      setError(field, undefined);
      updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
        model.data[field] = value;
      });
    };

  const setIntegrationName = onFieldChangeHandler('integrationName');
  const setDescription = onFieldChangeHandler('description');
  const setBucketUrl = onFieldChangeHandler('path');

  const setAuthType = (_type: string): void => {
    setConnectionStatus('unknown');
    setError('access_key', undefined);
    setError('secret', undefined);

    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      const type = _type as ObjectStorageAuthType;

      if (type === 'iam_user') {
        model.data.auth = {
          type: type,
          access_key_id: '',
          secret_key: ''
        };
      } else if (type === 'iam_role') {
        model.data.auth = {
          type: type,
          role_arn: ''
        };
      } else {
        model.data.auth = { type };
      }
    });
  };

  const setAccessKey = (access_key_id: string): void => {
    setConnectionStatus('unknown');
    setError('access_key', undefined);
    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      const auth = model.data.auth as CredentialsBased;
      model.data.auth = {
        ...auth,
        access_key_id
      };
    });
  };

  const setSecret = (secret_key: string): void => {
    setConnectionStatus('unknown');
    setError('secret', undefined);
    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      const auth = model.data.auth as CredentialsBased;

      model.data.auth = {
        ...auth,
        secret_key
      };
    });
  };

  const setIamRole = (role: string): void => {
    setConnectionStatus('unknown');
    setError('role_arn', undefined);
    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      const auth = model.data.auth as IamBased;

      model.data.auth = {
        ...auth,
        role_arn: role
      };
    });
  };

  const setError = (key: FieldKey, error: string | undefined): void => {
    setErrors((oldErrors) => {
      const newErrors = { ...oldErrors };
      error === undefined ? delete newErrors[key] : (newErrors[key] = error);
      return newErrors;
    });
  };

  const validateModel = (): boolean => {
    let valid = true;

    if (!isPresent(importModel.data.integrationName)) {
      valid = false;
      setError('integrationName', 'Field is required');
    }

    if (
      !importModel.data.path.startsWith('s3://') &&
      !importModel.data.path.startsWith('https://')
    ) {
      valid = false;
      if (importModel.type === 's3') {
        setError('path', 'Path should start with s3:// or https://');
      } else if (importModel.type === 'gcs') {
        setError(
          'path',
          'Path should start with https://storage.googleapis.com'
        );
      }
    }

    if (importModel.data.path.includes(' ')) {
      valid = false;
      setError('path', 'Path should not contain spaces');
    }

    if (importModel.data.auth.type === 'iam_user') {
      if (importModel.data.auth.access_key_id.includes(' ')) {
        valid = false;
        setError('access_key', 'Access key should not contain spaces');
      }

      if (importModel.data.auth.secret_key.includes(' ')) {
        valid = false;
        setError('secret', 'Secret key should not contain spaces');
      }
    }

    if (importModel.data.auth.type === 'iam_role') {
      if (importModel.data.auth.role_arn.includes(' ')) {
        valid = false;
        setError('role_arn', 'IAM roles should not contain spaces');
      } else if (!importModel.data.auth.role_arn.startsWith('arn:aws:iam::')) {
        valid = false;
        setError(
          'role_arn',
          'Invalid IAM role. Should start with arn:aws:iam::'
        );
      } else if (
        !importModel.data.auth.role_arn.match('^arn:aws:iam::\\d{12}:role\\/.*')
      ) {
        valid = false;
        setError('role_arn', 'IAM role has invalid format');
      }
    }

    const validationErrors = validateConnectionObject(importModel);
    for (const [field, message] of validationErrors.entries()) {
      setError(field, message);
    }

    return valid && validationErrors.size === 0;
  };

  const checkConnection = async (): Promise<boolean> => {
    try {
      const validationErrors = validateConnectionObject(importModel);
      for (const [field, message] of validationErrors.entries()) {
        setError(field, message);
      }
      if (validationErrors.size === 0) {
        const ok = await api.checkConnection(
          {
            id: importModel.id,
            type: importModel.data.type,
            path: importModel.data.path,
            auth: importModel.data.auth
          },
          serviceId
        );

        // null is for empty bucket
        if (ok === null) {
          setConnectionError(undefined);
          setConnectionError('The bucket is empty. Please adjust the path.');
          setConnectionStatus('error');
        } else if (ok) {
          setConnectionError(undefined);
          setConnectionStatus('success');
          return true;
        } else {
          setConnectionError("Can't connect to the data source.");
          setConnectionStatus('error');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setConnectionError(error.message);
        setConnectionStatus('error');
      } else {
        setConnectionError("Can't connect to the data source.");
        setConnectionStatus('error');
      }
    }

    return false;
  };

  const validateSetupConnection = async (): Promise<void> => {
    const valid = validateModel();
    if (!valid) {
      return;
    }

    if (connectionStatus === 'unknown') {
      const valid = await checkConnection();

      if (!valid) {
        return;
      }
    }

    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      model.data.step = 2;
    });
  };

  const nextStep = async (): Promise<void> => {
    setLoading(true);
    await validateSetupConnection();
    setLoading(false);
  };

  const back = (): void => {
    setTopics([]);
    updateImportModel((draft: Draft<ClickPipeObjectStorageImport>) => {
      draft.data.step = 0;
    });
  };

  return (
    <Container orientation="vertical" gap="md">
      <Text>
        If you need any help to connect your {getShortName(data.type)} bucket{' '}
        <Link href={docsLink} target="_blank" rel="noreferrer">
          access the documentation
        </Link>
        for more details.
      </Text>
      <Container orientation="vertical" gap="md" maxWidth="800px">
        <TwoColsContainer>
          <TextField
            label="Integration name"
            error={errors['integrationName']}
            name="integrationName"
            value={data.integrationName ?? ''}
            onChange={setIntegrationName}
            placeholder={`My new ${getShortName(data.type)} pipe`}
            data-testid="integration-name"
            autoComplete="off"
            className="fs-exclude"
          />
          <TextField
            label="Description"
            error={errors['description']}
            name="description"
            value={data.description ?? ''}
            onChange={setDescription}
            placeholder={`Custom ${getShortName(data.type)} ingestion`}
            data-testid="description"
            autoComplete="off"
            className="fs-exclude"
          />
        </TwoColsContainer>

        <Select
          label="Authentication method"
          onSelect={setAuthType}
          value={data.auth.type}
          data-testid="auth-type"
        >
          <Select.Item value="iam_user">Credentials</Select.Item>
          {data.type === 's3' && (
            <Select.Item
              data-testid="iam_role_select"
              value="iam_role"
              disabled={!isAwsRegionId(regionId)}
            >
              IAM role
            </Select.Item>
          )}
          <Select.Item value="public">Public</Select.Item>
        </Select>

        {data.auth?.type === 'iam_user' && (
          <>
            <TextField
              label="Access Key"
              error={errors['access_key' as FieldKey]}
              name="access_key"
              placeholder="Input your access key"
              value={data.auth.access_key_id}
              onChange={setAccessKey}
              data-testid="object_storage_user"
              className="fs-exclude"
            />

            <PasswordField
              label="Secret"
              error={errors['secret' as FieldKey]}
              name="secret"
              placeholder="Input your secret"
              value={data.auth.secret_key}
              onChange={setSecret}
              data-testid="object_storage_secret"
              className="fs-exclude"
            />
          </>
        )}

        {data.auth?.type === 'iam_role' && (
          <TextField
            label="IAM ARN role"
            name="role"
            value={data.auth.role_arn}
            onChange={setIamRole}
            error={errors['role_arn']}
            placeholder="Input your IAM role"
            data-testid="iam_arn_role"
            className="fs-exclude"
          />
        )}

        <Container orientation="vertical" gap="xs">
          <Text>
            Provide a path to the file(s) you want to ingest. You can specify
            multiple files using bash-like wildcards. For more information,{' '}
            <Link href={docsLinkGlobPattern} target="_blank" rel="noreferrer">
              see the documentation on using wildcards in path.
            </Link>
          </Text>
          <TextField
            error={errors['path']}
            name="path"
            placeholder={`Your ${getShortName(
              data.type
            )} bucket, for example: ${getExampleBucket(data.type)}`}
            value={data.path ?? ''}
            onChange={setBucketUrl}
            data-testid="object-storage-path"
            className="fs-exclude"
          />
        </Container>
        {!loading && connectionStatus === 'error' && connectionError && (
          <Alert
            text={connectionError}
            state="danger"
            size="medium"
            showIcon={false}
          />
        )}
      </Container>
      <Spacer size="xs" />
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          Back
        </Button>
        <Button
          onClick={(): void => void nextStep()}
          loading={loading}
          disabled={connectionStatus === 'error' || loading}
          data-testid="setup-conn-next-step"
        >
          Next: Incoming Data
        </Button>
      </Container>
      <Spacer size="xs" />
    </Container>
  );
}
