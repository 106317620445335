import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import { DashboardConfig, DashboardConfigField, DashboardFilterConfig } from 'shared/src/types/dashboard';
import { SavedQueryResult } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';

export function cleanFilterConfig(config: DashboardConfig): DashboardConfig {
  // clean up filter config by removing any config that doesn't have a matching key in the object config
  const filterableFields = findFilterableFields(config).reduce(
    (fields, field) => ({
      ...fields,
      [field]: true
    }),
    {} as Record<string, boolean>
  );
  const cleanedFilterConfig = Object.entries(config.dashboardFilters.config).reduce(
    (filterConfig, [filterKey, filterKeyConfig]) => {
      if (filterableFields[filterKey]) {
        filterConfig[filterKey] = filterKeyConfig;
      }

      return filterConfig;
    },
    {} as Record<string, DashboardFilterConfig>
  );

  return {
    ...config,
    dashboardFilters: {
      config: cleanedFilterConfig
    }
  };
}

export function fillMissingParameterConfigs(
  config: DashboardConfig,
  savedQueries: SavedQueryResult[]
): DashboardConfig {
  const newConfig = cloneDeep(config);

  Object.entries(config.dashboardObjects).forEach(([objectId, objectConfig]) => {
    if (objectConfig.config.queryId) {
      const query = savedQueries.find((query) => query.id === objectConfig.config.queryId);

      if (query) {
        // clears unused configs as well
        const { newFilters, newParameters } = hydrateParameterConfig(config, objectId, query);

        newConfig.dashboardFilters.config = {
          ...newConfig.dashboardFilters.config,
          ...newFilters
        };
        newConfig.dashboardObjects[objectId].config.parameters = newParameters;
      }
    }
  });

  return newConfig;
}

export function findFilterableFields(config: unknown, key?: string): Array<string> {
  if (typeof config === 'object') {
    if (!config || Array.isArray(config)) {
      return [];
    } else {
      if ('type' in config && config.type === 'filter' && key) {
        return [key];
      }

      return Object.entries(config).reduce(
        (filterableFields, [key, value]) => uniq([...filterableFields, ...findFilterableFields(value, key)]),
        [] as Array<string>
      );
    }
  }

  return [];
}

export function hydrateParameterConfig(
  config: DashboardConfig,
  objectId: string,
  query: SavedQueryResult
): {
  newFilters: Record<string, DashboardFilterConfig>;
  newParameters: Record<string, DashboardConfigField<string>>;
} {
  return Object.entries(query.parameters).reduce(
    ({ newFilters, newParameters }, [parameter, defaultValue]) => {
      // use already existing config, otherwise default to filter
      newParameters[parameter] = config.dashboardObjects[objectId].config.parameters[parameter] || {
        type: 'filter'
      };

      if (!config.dashboardFilters.config[parameter]) {
        newFilters[parameter] = {
          defaultValue,
          filterType: 'string'
        };
      }

      return { newFilters, newParameters };
    },
    {
      newFilters: {} as Record<string, DashboardFilterConfig>,
      newParameters: {} as Record<string, DashboardConfigField<string>>
    }
  );
}
