import { ReactElement } from 'react';
import { EditColumn } from 'shared/src/tableSchema';
import {
  getColumn,
  useEditTable
} from 'src/components/CreateTableView/context';
import { useVisited } from 'src/components/CreateTableView/useVisited';
import { Select } from '@clickhouse/click-ui';
import { paddingStyle } from 'src/components/global-styles';

interface ColumnSelectProps {
  value: string;
  onChange: (val: string) => void;
  filter?: (column: EditColumn) => boolean;
  required?: boolean;
  label: string;
}

export function ColumnSelect({
  value,
  onChange,
  filter: filterProp,
  required = false,
  label
}: ColumnSelectProps): ReactElement {
  const { tableState } = useEditTable();
  const filter = filterProp || ((): boolean => true);
  const [visited, visit] = useVisited();

  const columnIds = tableState.columnIds.filter((columnId) => {
    const column = getColumn(tableState, columnId);
    return column && column.name.length > 0 && filter(column);
  });

  return (
    <Select
      label={label}
      error={visited && required && !value}
      css={paddingStyle({ right: 28 })}
      placeholder="Select column"
      value={value}
      onBlur={(): void => {
        if (!visited) {
          visit();
        }
      }}
      onSelect={onChange}
    >
      {columnIds.map((id) => (
        <Select.Item key={id} value={id}>
          {tableState.columns[id]?.name ?? id}
        </Select.Item>
      ))}
    </Select>
  );
}
