import { RegionPricingDetails } from '@cp/common/protocol/Billing';
import { RegionId } from '@cp/common/protocol/Region';
import { createToast } from 'primitives';
import { useEffect, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

export interface RegionPrices {
  prices: Record<RegionId, RegionPricingDetails>;
  isLoading: boolean;
}

export const useRegionsPrices = (): RegionPrices => {
  const apiClient = useApiClient();
  const organization = useCurrentOrganizationOrThrow();
  const [isLoading, setIsLoading] = useState(false);
  const [prices, setPrices] = useState<Record<RegionId, RegionPricingDetails>>(
    {} as Record<RegionId, RegionPricingDetails>
  );

  useEffect(() => {
    const getPrices = async (organizationId: string): Promise<void> => {
      try {
        setIsLoading(true);
        const res = await apiClient.billing.getRegionsPrices(organizationId);
        setPrices(res.prices);
      } catch (e) {
        createToast('Unable to get Usage Statement data', 'alert', errorMessage(e));
        throw e;
      } finally {
        setIsLoading(false);
      }
    };
    getPrices(organization.id).catch(console.error);
  }, [apiClient.billing, organization.id]);

  return { prices, isLoading };
};
