import { Container } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import ServiceCreationStepper from 'src/components/CreateNewService/ServiceCreationStepper';

export const CreateNewServicePage = (): ReactElement | null => {
  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Create new service" />
      <MainPaneLayout.Content>
        <Container
          orientation="vertical"
          alignItems="start"
          data-testid="create-new-service-page"
          maxWidth="800px"
          padding="lg"
          gap="xs"
        >
          <ServiceCreationStepper />
        </Container>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};
