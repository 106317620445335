import {
  Button,
  Container,
  Dialog,
  Link,
  Separator,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
  Text,
  TextAreaField
} from '@clickhouse/click-ui';
import { createToast } from 'primitives';
import { ReactElement, useState } from 'react';
import { useRequestAdditionalCredits } from 'src/billing/controllers/useRequestAdditionalCredits';
import { parseInvoiceDateRange } from 'src/components/BillingInfo/invoiceDateRangeFormatter';
import { formatDataSize } from 'src/lib/formatters/numberFormatter';

export type CreditBalance = {
  /** Unix timestamp in milliseconds. */
  periodStartDate: number;
  /** Unix timestamp in milliseconds. */
  periodEndDate: number;
  /** CHC amount allocated in this credit balance. */
  amountTotal: number;
  /** CHC amount spent until right now, this is not an historic value. */
  amountSpent: number;
  /** CHC amount still remaining right now, this is not an historic value. */
  amountRemaining: number;
};

export type CreditBalancesTableProps = { creditBalances: CreditBalance[] };

const CreditBalancesTable = ({
  creditBalances
}: CreditBalancesTableProps): ReactElement => {
  const [isCreditsModalVisible, setIsCreditsModalVisible] = useState(false);
  const [creditRequest, setCreditRequest] = useState<string | undefined>(
    undefined
  );
  const { requestAdditionalCredits, isLoading: isSaving } =
    useRequestAdditionalCredits();
  const headers: Array<TableHeaderType> = [
    { label: 'Credit validity period' },
    {
      label: (
        <Container fillWidth orientation="horizontal" justifyContent="end">
          Credits granted
        </Container>
      )
    },

    {
      label: (
        <Container fillWidth orientation="horizontal" justifyContent="end">
          Credits used
        </Container>
      )
    },
    {
      label: (
        <Container fillWidth orientation="horizontal" justifyContent="end">
          Remaining credits
        </Container>
      )
    }
  ];

  let lineNumber = 1;
  const rows: Array<TableRowType> = creditBalances.map((cb) => {
    return {
      id: `creditBalance_line_${lineNumber++}`,
      items: [
        {
          label: parseInvoiceDateRange(cb.periodStartDate, cb.periodEndDate)
        },
        {
          label: (
            <Text align="right">
              {formatDataSize(cb.amountTotal, 'human-readable-number')}
            </Text>
          )
        },

        {
          label: (
            <Text align="right">
              {formatDataSize(cb.amountSpent, 'human-readable-number')}
            </Text>
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <Text align="right">
              {formatDataSize(cb.amountRemaining, 'human-readable-number')}
            </Text>
          ),
          className: 'fs-exclude'
        }
      ]
    };
  });

  const onClose = (): void => {
    setIsCreditsModalVisible(false);
    setCreditRequest(undefined);
  };

  const onRequestCredits = async (): Promise<void> => {
    if (creditRequest) {
      try {
        await requestAdditionalCredits(creditRequest);
        setIsCreditsModalVisible(false);
        createToast(
          'Success',
          'success',
          'Our sales team will be contacting you shortly.'
        );
      } catch (e) {
        // ignore, controller handles the toast error
      }
    }
  };

  const isValid = (): boolean => !!(creditRequest && creditRequest.length > 0);

  return (
    <>
      <Table headers={headers} rows={rows} />
      <Container orientation="vertical" alignItems="end">
        <div>
          <Link onClick={(): void => setIsCreditsModalVisible(true)}>
            Contact us
          </Link>
          to add more credits
        </div>
      </Container>
      <Dialog open={isCreditsModalVisible} modal>
        <Dialog.Content
          title="Request more credits"
          onClose={onClose}
          showClose={true}
        >
          <Text size="md" data-testid={'dialog-description'}>
            Please indicate how many credits you’d like to request and our sales
            team will contact you shortly.
          </Text>
          <Spacer />
          <TextAreaField
            label="Credit request"
            disabled={isSaving}
            value={creditRequest}
            onChange={setCreditRequest}
            placeholder="Tell us more about your use case and how you might use your additional credits"
            orientation="vertical"
            className="fs-exclude"
          />
          <Separator size="xl" />
          <Container justifyContent="end" gap="xs">
            <Dialog.Close label="Close" onClick={onClose} />
            <Button
              loading={isSaving}
              disabled={isSaving || !isValid()}
              onClick={(): void => {
                onRequestCredits().catch(console.error);
              }}
            >
              Request credits
            </Button>
          </Container>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default CreditBalancesTable;
