import { ListOrganizationActivitiesResponse, OrganizationActivity } from '@cp/common/protocol/Organization';
import { OffsetPaginationParams } from '@cp/common/protocol/Pagination';
import { useEffect, useMemo } from 'react';
import config from 'src/lib/config';
import { useOrgFeature } from 'src/lib/features';
import { HttpClient, useHttpClient } from 'src/lib/http';
import { useSearchParam } from 'src/lib/routes/useSearchParam';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import useSWR, { Fetcher } from 'swr';

const maxRowsPerPageList = [100, 500, 1000];

type useOrganizationActivitiesReturnType = {
  activities?: Array<OrganizationActivity>;
  error?: Error;
  isLoading: boolean;
  pagination?: {
    currentPage: number;
    totalPages: number;
    rowCount: number;
    limit: number;
    maxRowsPerPageList: number[];
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
  };
};

const getPaginationParams = ({
  currentPage,
  limit
}: {
  currentPage: number;
  limit: number;
}): OffsetPaginationParams['pagination'] => {
  return { offset: (currentPage - 1) * limit, limit };
};

type fetcherArgs = {
  httpClient: HttpClient;
  organizationId: string;
  pagination?: OffsetPaginationParams['pagination'];
};
const fetchOrgActivities: Fetcher<ListOrganizationActivitiesResponse, fetcherArgs> = async ({
  httpClient,
  organizationId,
  pagination
}): Promise<ListOrganizationActivitiesResponse> => {
  const response = await httpClient.post(
    `${config.controlPlane.apiHost}/api/organization?listActivities`,
    {
      body: JSON.stringify({
        organizationId: organizationId,
        rpcAction: 'listActivities',
        pagination
      })
    },
    { includeAuthProviderHeader: false }
  );

  if (response.ok) {
    return response.json() as Promise<ListOrganizationActivitiesResponse>;
  } else {
    throw new Error('Could not fetch support List');
  }
};

export const useOrganizationActivities = (): useOrganizationActivitiesReturnType => {
  const httpClient = useHttpClient();
  const organization = useCurrentOrganizationOrThrow();
  const isPaginationEnabled = useOrgFeature('FT_ORG_AUDIT_PAGINATION');

  const [currentPage, setCurrentPage] = useSearchParam('page', 1, { type: 'number' });
  const [limit, setLimit] = useSearchParam('limit', maxRowsPerPageList[0], { type: 'number' });

  const { data, error, isLoading } = useSWR(
    {
      httpClient,
      organizationId: organization.id,
      ...(isPaginationEnabled && { pagination: getPaginationParams({ currentPage, limit }) })
    },
    fetchOrgActivities
  );

  const pagination = useMemo(() => {
    if (!isPaginationEnabled) {
      return undefined;
    }

    return {
      currentPage,
      totalPages: data?.pagination?.total_pages || 0,
      rowCount: data?.pagination?.total || 0,
      limit,
      maxRowsPerPageList,
      onPageChange: setCurrentPage,
      onPageSizeChange: setLimit
    };
  }, [
    currentPage,
    data?.pagination?.total,
    data?.pagination?.total_pages,
    limit,
    setCurrentPage,
    setLimit,
    isPaginationEnabled
  ]);

  useEffect(() => {
    if (limit === -1) {
      setCurrentPage(1);
    }
  }, [limit, setCurrentPage]);

  return {
    activities: data?.activities,
    error,
    isLoading,
    pagination
  };
};
