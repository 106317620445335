import React, { useEffect, useRef } from 'react';
import { editor } from 'monaco-editor';

interface RawQueryResultsProps {
  text: string;
}

/**
 * A React component that displays a raw query result in the text format
 * using Monaco editor.
 * @param text The text to show
 */
const RawQueryResults: React.FC<RawQueryResultsProps> = ({ text }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const monacoEditor = useRef<editor.IEditor | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    monacoEditor.current = editor.create(containerRef.current!, {
      readOnly: true,
      fontFamily: 'Inconsolata',
      fontWeight: '500',
      fontSize: 16,
      automaticLayout: true,
      minimap: {
        enabled: false
      }
    });
    return () => {
      // Dispose of the diff editor when the component unmounts
      if (monacoEditor.current) {
        monacoEditor.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (!monacoEditor.current) {
      return;
    }

    // Show the text in the editor.
    const model = editor.createModel(text);
    monacoEditor.current.setModel(model);
  }, [text]);

  return <div ref={containerRef} style={{ height: '100%', width: '100%' }} />;
};

export default RawQueryResults;
