import { ReactElement } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useMainSidebar } from 'src/layout/SidebarWrapper/SidebarWrapper';
import { useIsUnifiedConsole } from 'src/lib/features';
import { routes } from 'src/lib/routes';

const UnifiedConsoleGuard = (): ReactElement => {
  const { isDesktop } = useMainSidebar();
  const { serviceId } = useParams();
  const enableUnifiedConsole = useIsUnifiedConsole();
  const { pathname } = useLocation();

  if (!enableUnifiedConsole && pathname.includes('/console')) {
    return <Navigate to={pathname.replace('/console', '/')} />;
  }

  if (!isDesktop && serviceId) {
    return <Navigate to={routes.serviceSettings({ serviceId })} />;
  }

  return <Outlet />;
};

export default UnifiedConsoleGuard;
