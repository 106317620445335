import {
  Button,
  Dropdown,
  EllipsisContent,
  Icon,
  Separator,
  Tooltip
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useAuth } from 'src/components/auth';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import {
  buttonContainer,
  optionStyle,
  popoverBtnStyle
} from 'src/components/App/MainView/TabBar/ServiceSelector/styles';
import { ServiceType } from 'src/components/App/MainView/TabBar/ServiceSelector/types';
import { useCurrentInstance } from 'src/instance/instanceController';
import { Instance } from '@cp/common/protocol/Instance';
import { useInstanceStateManager } from 'src/instance/instanceState';

const ServiceSelectorView = ({
  currentService,
  currentOrganizationServices = [],
  onLogOut
}: {
  currentService?: Instance;
  currentOrganizationServices: Array<Instance>;
  onLogOut: () => void;
}): ReactElement => {
  return (
    <Dropdown>
      <Dropdown.Trigger>
        <Tooltip>
          <Tooltip.Trigger css={popoverBtnStyle}>
            <EllipsisContent className="fs-exclude">
              {currentService?.name ?? 'Select Service'}
            </EllipsisContent>
            <Icon name="chevron-down" />
          </Tooltip.Trigger>
          <Tooltip.Content>Select service</Tooltip.Content>
        </Tooltip>
      </Dropdown.Trigger>
      <Dropdown.Content align="end">
        {currentOrganizationServices.map((service: ServiceType) => (
          <Dropdown.Item
            key={`services-${service.name}`}
            data-state={
              currentService?.id === service.id ? 'checked' : 'uncheked'
            }
            onClick={(): void => {
              if (currentService?.id !== service.id) {
                navigateTo(routes.service({ serviceId: service.id }));
              }
            }}
            css={optionStyle}
            className="fs-exclude"
          >
            {service.name}
          </Dropdown.Item>
        ))}
        <Separator size="xs" />
        <div css={buttonContainer}>
          <Button fillWidth onClick={onLogOut}>
            Logout
          </Button>
        </div>
      </Dropdown.Content>
    </Dropdown>
  );
};

function ServiceSelector(): ReactElement {
  const { logOut } = useAuth();
  const currentService = useCurrentInstance();
  const { instances } = useInstanceStateManager();

  return (
    <ServiceSelectorView
      currentService={currentService}
      currentOrganizationServices={Object.values(instances)}
      onLogOut={() => {
        logOut().catch(console.error);
      }}
    />
  );
}

export default ServiceSelector;
