import {
  AcceptOrganizationInvitationRequest,
  AcceptOrganizationInvitationResponse,
  ListOrganizationsRequest,
  ListOrganizationsResponse,
  ORGANIZATION_API_PATH,
  ChangeOrganizationNameRequest,
  ChangeOrganizationUserRoleRequest,
  DeleteOrganizationInvitationRequest,
  InviteToOrganizationRequest,
  LeaveOrganizationRequest,
  OrganizationRole,
  RemoveUserFromOrganizationRequest,
  ResendInviteToOrganizationRequest,
  UpdatePrivateEndpointsRequest,
  OrganizationPrivateEndpoint,
  CreateDefaultOrganizationForUserRequest,
  MarkViewedInvitationsRequest,
  CreateByocAccountRequest,
  CreateByocInfrastructureRequest
} from '@cp/common/protocol/Organization';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';
import { WithOrganizationId } from '@cp/common/utils/ProtocolUtils';
import { ErrorResponse, Organization } from 'shared';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import { InstanceCloudProvider } from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';

const organizationApiUrl = `${config.controlPlane.apiHost}/api/${ORGANIZATION_API_PATH}`;

type OrganizationRequest =
  | RemoveUserFromOrganizationRequest
  | InviteToOrganizationRequest
  | ResendInviteToOrganizationRequest
  | DeleteOrganizationInvitationRequest
  | ChangeOrganizationUserRoleRequest
  | LeaveOrganizationRequest
  | ChangeOrganizationNameRequest
  | AcceptOrganizationInvitationRequest
  | ListOrganizationsRequest
  | UpdatePrivateEndpointsRequest
  | CreateDefaultOrganizationForUserRequest
  | MarkViewedInvitationsRequest
  | CreateByocAccountRequest
  | CreateByocInfrastructureRequest;

export interface RenameOrganizationInput extends WithOrganizationId {
  name: string;
}

export interface UpdatePrivateEndpointsInput extends WithOrganizationId {
  privateEndpoints: Array<OrganizationPrivateEndpoint>;
}

export class OrganizationApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: OrganizationRequest, url = organizationApiUrl): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async acceptInvitation(inviteKey: string): Promise<AcceptOrganizationInvitationResponse> {
    const request: AcceptOrganizationInvitationRequest = { rpcAction: 'acceptInvitation', inviteKey };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const body = (await response.json()) as ErrorResponse;
      throw new Error(body.message);
    }

    return response.json() as unknown as AcceptOrganizationInvitationResponse;
  }

  async listOrganizations(): Promise<ListOrganizationsResponse> {
    const response = await this.rpcRequest({ rpcAction: 'list' });

    if (!response.ok) {
      const body = (await response.json()) as ErrorResponse;
      throw new Error(body.message);
    }

    return response.json() as unknown as ListOrganizationsResponse;
  }

  async removeUser(userId: string, organizationId: string): Promise<void> {
    const request: RemoveUserFromOrganizationRequest = {
      rpcAction: 'removeUser',
      userId,
      organizationId
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Failed to remove user: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async deleteInvitation(email: string, organizationId: string): Promise<void> {
    const request: DeleteOrganizationInvitationRequest = {
      rpcAction: 'deleteInvitation',
      email,
      organizationId
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Failed to delete invitation: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async inviteToOrganization(
    emails: Array<string>,
    roles: Array<OrganizationRole>,
    organizationId: string
  ): Promise<void> {
    const request: InviteToOrganizationRequest = {
      rpcAction: 'invite',
      emails,
      roles,
      organizationId
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Failed to send invitation(s): ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async resendInvitation(email: string, role: OrganizationRole, organizationId: string): Promise<void> {
    const request: ResendInviteToOrganizationRequest = {
      rpcAction: 'resendInvitation',
      email,
      role,
      organizationId
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Failed to resend invitation: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async changeRole(userId: string, organizationId: string, role: OrganizationRole): Promise<void> {
    const request: ChangeOrganizationUserRoleRequest = {
      rpcAction: 'changeUserRole',
      userId,
      role,
      organizationId
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Failed to change role: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async leave({ organizationId }: WithOrganizationId): Promise<void> {
    const request: LeaveOrganizationRequest = {
      rpcAction: 'leave',
      organizationId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Error while leaving the organization: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async rename({ organizationId, name }: RenameOrganizationInput): Promise<void> {
    const request: ChangeOrganizationNameRequest = {
      rpcAction: 'rename',
      name,
      organizationId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Error while renaming the organization: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async updatePrivateEndpoints(props: Omit<UpdatePrivateEndpointsRequest, 'rpcAction'>): Promise<void> {
    const request: UpdatePrivateEndpointsRequest = {
      rpcAction: 'updatePrivateEndpoints',
      ...props
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const responseMessage = ((await response.json()) as ErrorResponse).message;
      const error = new Error(`Error while updating organization private endpoints: ${responseMessage}`);
      console.error(error);
      throw error;
    }
  }

  async createDefaultOrgForUser(): Promise<void> {
    const request: CreateDefaultOrganizationForUserRequest = { rpcAction: 'createDefault' };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      console.error(error);
      throw new Error(getErrorMessageFromError(error));
    }
  }

  async markViewedInvitations(expiredInvitationKeys: string[]): Promise<void> {
    const request: MarkViewedInvitationsRequest = {
      rpcAction: 'markViewedInvitations',
      inviteKeys: expiredInvitationKeys
    };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      console.error(error);
      throw new Error(getErrorMessageFromError(error));
    }
  }

  async createByocAccount(
    organizationId: string,
    cloudProvider: InstanceCloudProvider,
    accountId: string
  ): Promise<void> {
    const request: CreateByocAccountRequest = {
      rpcAction: 'createByocAccount',
      organizationId,
      cloudProvider,
      accountId,
      accountName: accountId
    };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      console.error(error);
      throw new Error(getErrorMessageFromError(error));
    }
  }

  async createByocAccountInfrastructure(organizationId: string, accountId: string, regionId: RegionId): Promise<void> {
    const request: CreateByocInfrastructureRequest = {
      rpcAction: 'createByocInfrastructure',
      organizationId,
      accountId,
      regionId
    };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      console.error(error);
      throw new Error(getErrorMessageFromError(error));
    }
  }
}
