import { formatDateRangeInBrowserTz, formatDateHumanShort } from 'src/lib/formatters/dateTimeFormatter';

export function parseInvoiceDateRange(periodStartDate?: number, periodEndDate?: number): string {
  if (!periodEndDate) {
    // No dates available to show.
    return 'N/A';
  }

  if (!periodStartDate) {
    // No start date, so this isn't a period but an invoice that doesn't correspond to a period (sales-led, finance correction, etc.).
    return formatDateHumanShort(periodEndDate);
  }

  return formatDateRangeInBrowserTz(periodStartDate, periodEndDate);
}
