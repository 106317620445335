import {
  Button,
  Container,
  Panel,
  Separator,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { InvitationList } from 'src/components/InvitationList/InvitationList';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import ClickHouseFullIcon from 'src/layout/ServiceSidebar/ClickHouseFullIcon';
import { routes } from 'src/lib/routes';
import { useOnboardController } from 'src/onboard/controller/useOnboardController';
import useCreateDefaultOrganization from 'src/organization/controller/useCreateDefaultOrganization';
import {
  AcceptInvitationArgs,
  useInvitationController
} from 'src/state/notification/invitationController';
import styled from 'styled-components';

const onClose = (): void => {
  navigateTo(routes.services());
};

const ApproveOrgContainer = styled(Container)`
  width: 100%;
  height: 100vh;
  padding-top: clamp(32px, 10vh, 128px);
  padding-bottom: 32px;
  background-image: linear-gradient(
    170deg,
    black 0%,
    black calc(45% - 20.8px),
    #fcff74 calc(45% - 20px),
    #fcff74 45%,
    white 45%,
    white
  );
  box-sizing: border-box;
  background-size: max(100%, 1100px) max(100%, 1024px);
  background-position: top min(-15%, -100px) left;
  background-repeat: no-repeat;
`;

const CustomClickHouseFullIcon = styled(ClickHouseFullIcon)`
  color: white;
  margin: auto;

  .logo-icon {
    color: #fcff74;
  }
`;

const CustomPanel = styled(Panel)`
  padding: 2.5rem 3rem;
  max-width: min(100%, 496px);

  @media screen and (max-width: 640px) {
    padding: 1rem 1.5rem;
  }
`;

const RelativeContainer = styled(Container)`
  position: relative;
`;

const OrText = styled(Text)`
  margin: auto;
  padding-inline: 1rem;
  background: ${({ theme }) => theme.global.color.background.default};
  isolation: isolate;
`;

const CustomSeparator = styled(Separator)`
  position: absolute;
`;

export const ApproveOrgInvitationsPage = (): ReactElement => {
  const { invitations, acceptInvitation } = useInvitationController();
  const { createDefaultOrgForUser } = useCreateDefaultOrganization();
  const { setPostOnboardingGettingStarted } = useOnboardController();

  const onAccept = async (props: AcceptInvitationArgs): Promise<void> => {
    // We need to remove the onboarding pending action before accepting the invitation
    // to avoid the user enters the onboard flow
    await setPostOnboardingGettingStarted();
    await acceptInvitation(props);
  };

  const onCreateNewOrg = async (): Promise<void> => {
    try {
      await createDefaultOrgForUser();
      navigateTo('/onboard');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ApproveOrgContainer
      justifyContent="center"
      alignItems="start"
      padding="lg"
      isResponsive={false}
      data-testid="approve-org-invitations"
    >
      <Container
        orientation="vertical"
        gap="lg"
        justifyContent="center"
        fillWidth={false}
        isResponsive={false}
        maxWidth="calc(100vw - 2rem)"
        maxHeight="calc(100vh - 2rem)"
      >
        <CustomClickHouseFullIcon height="35" width="100%" />
        <CustomPanel
          alignItems="center"
          hasShadow
          hasBorder
          orientation="vertical"
          gap="lg"
        >
          <Title type="h1" data-testid="join-your-team">
            Join your team
          </Title>
          <InvitationList
            invitations={invitations}
            acceptInvitation={onAccept}
            onClose={onClose}
            testId="approve-invitations-accept"
            component="ApproveOrgInvitation"
          />
          <RelativeContainer isResponsive={false}>
            <CustomSeparator size="xs" />
            <OrText align="center">OR</OrText>
          </RelativeContainer>
          <Button
            type="secondary"
            fillWidth
            onClick={onCreateNewOrg}
            data-testid="approve-invitations-page-create-new-org"
          >
            Create New Organization
          </Button>
        </CustomPanel>
      </Container>
    </ApproveOrgContainer>
  );
};
