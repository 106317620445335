import {
  Button,
  Checkbox,
  Container,
  Dialog,
  Separator,
  Spacer,
  Title
} from '@clickhouse/click-ui';
import { QuestionAnswerType } from '@cp/common/protocol/Account';
import { shuffle } from 'lodash';
import { ReactElement, useState } from 'react';
import { createToast } from 'src/components/primitives';
import useEntryQuestionnaire from 'src/entryQuestionnaire/controller/useEntryQuestionnaire';
import {
  ReasonListType,
  ReasonType,
  wrapQuestionAnswers
} from 'src/lib/questionnaire';
import styled from 'styled-components';
import QuestionnaireAnswerWithInput from 'src/components/QuestionnaireAnswerWithInput/QuestionnaireAnswerWithInput';
import { QuestionnaireAnswerWithSuboptions } from 'src/components/QuestionnaireAnswerWithSuboptions/QuestionnaireAnswerWithSuboptions';

export const reasonList = [
  'Starting a new project',
  'Migrating from another service',
  'Migrating from another service:Self managed ClickHouse',
  'Migrating from another service:Amazon Redshift',
  'Migrating from another service:Google BigQuery',
  'Migrating from another service:Postgres',
  'Migrating from another service:SnowFlake',
  'Migrating from another service:Other',
  'Learning ClickHouse'
];

export const clickhouseReason = [
  'Business Intelligence',
  'Logs, Events, & Traces',
  'Real-time Analytics',
  'Machine Learning & GenAI'
];

const isSomeSuboptionCheckedIfExist = ({
  option,
  optionsList
}: {
  option: ReasonType;
  optionsList: ReasonListType;
}): boolean => {
  const suboptions = optionsList.filter((o) =>
    o.label.startsWith(`${option.label}:`)
  );
  if (suboptions.length === 0) {
    return true;
  }
  return suboptions.some((sub) => sub.checked);
};

export const isSuboption = (label: string): boolean => {
  return label.split(':').length > 1;
};

const checkSuboptions = (options: ReasonListType): boolean => {
  return options.every((o) =>
    o.checked
      ? isSomeSuboptionCheckedIfExist({ option: o, optionsList: options })
      : true
  );
};

const selectSuboption = (
  label: string,
  options: ReasonListType
): ReasonListType => {
  const mainOption = label.split(':')[0];
  return options.map((o) =>
    o.label === label
      ? { ...o, checked: true }
      : o.label.startsWith(`${mainOption}:`)
      ? { ...o, checked: false }
      : o
  );
};

const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
`;

const EntryQuestionnaire = (): ReactElement => {
  const { addUserEntryQuestionnaire, removeEntryQuestionnaire } =
    useEntryQuestionnaire();
  const [mainReasonList, setMainReasonList] = useState<ReasonListType>(() => {
    const reasons = reasonList.map((reason) => ({
      label: reason,
      checked: false
    }));
    const optionsWithoutSuboptions = reasons.filter(
      (r) => r.label.split(':').length <= 1
    );
    const subOptions = reasons.filter((r) => r.label.split(':').length > 1);
    return [...shuffle(optionsWithoutSuboptions), ...subOptions];
  });

  const [clickhouseReasonList, setClickhouseReasonList] =
    useState<ReasonListType>(() => {
      return shuffle(clickhouseReason).map((reason) => ({
        label: reason,
        checked: false
      }));
    });
  const [mainReasonOther, setMainReasonOther] = useState({
    value: '',
    checked: false
  });
  const [clickhouseReasonOther, setClickhouseReasonOther] = useState({
    value: '',
    checked: false
  });
  const [hasMainOtherError, setHasMainOtherError] = useState(false);
  const [hasClickhouseOtherError, setHasClickhouseOtherError] = useState(false);

  const onCheckedMainReasonChange = (label: string, checked: boolean): void => {
    setMainReasonList((mainReasonList) => {
      const newMainReasonList = [...mainReasonList];
      if (!checked) {
        return newMainReasonList.map((r) =>
          r.label.startsWith(label) ? { ...r, checked: false } : r
        );
      }

      if (isSuboption(label)) {
        return selectSuboption(label, newMainReasonList);
      }
      return newMainReasonList.map((r) =>
        r.label === label ? { ...r, checked } : r
      );
    });
  };

  const onCheckedClickhouseReasonChange =
    (index: number) => (checked: boolean) => {
      setClickhouseReasonList((reasonList) => {
        const newReasonList = [...reasonList];
        newReasonList[index].checked = checked;
        return newReasonList;
      });
    };

  const onSubmit = async (): Promise<void> => {
    if (
      (clickhouseReasonOther.checked &&
        clickhouseReasonOther.value.length === 0) ||
      (mainReasonOther.checked && mainReasonOther.value.length === 0)
    ) {
      createToast('Error', 'error', 'Fill in all the values');
      return;
    }

    const questions: Array<QuestionAnswerType> = [];
    const mainQuestion = wrapQuestionAnswers(
      'What’s your main reason for signing up?',
      mainReasonList,
      mainReasonOther
    );
    questions.push(mainQuestion);

    const clickhouseQuestion = wrapQuestionAnswers(
      'What will you use ClickHouse for?',
      clickhouseReasonList,
      clickhouseReasonOther
    );
    questions.push(clickhouseQuestion);
    await addUserEntryQuestionnaire(questions);
  };

  const isButtonEnabled =
    !hasMainOtherError &&
    !hasClickhouseOtherError &&
    (mainReasonList.some((reason) => reason.checked) ||
      mainReasonOther.value.length > 0) &&
    checkSuboptions(mainReasonList) &&
    (clickhouseReasonList.some((reason) => reason.checked) ||
      clickhouseReasonOther.value.length > 0);

  return (
    <Dialog open>
      <DialogContent
        onClose={removeEntryQuestionnaire}
        title="Tell us a bit about your use case"
        data-testid="entry-questionnaire"
      >
        <Container orientation="vertical" gap="md" overflow="hidden">
          <Spacer size="xs" />
          <Container orientation="vertical" gap="md" overflow="auto">
            <Container orientation="vertical" gap="md">
              <Title type="h4" size="md">
                What’s your main reason for signing up?
              </Title>
              <Container orientation="vertical" gap="md">
                <QuestionnaireAnswerWithSuboptions
                  options={mainReasonList}
                  onCheckedChange={onCheckedMainReasonChange}
                />
                <QuestionnaireAnswerWithInput
                  label="Other"
                  value={mainReasonOther.value}
                  checked={mainReasonOther.checked}
                  onCheckedChange={(checked) =>
                    setMainReasonOther((value) => ({ ...value, checked }))
                  }
                  onChange={(value) => {
                    setMainReasonOther((reason) => ({ ...reason, value }));
                  }}
                  onErrorChange={setHasMainOtherError}
                />
              </Container>
            </Container>
            <Container orientation="vertical" gap="md">
              <Title type="h4" size="md">
                What will you use ClickHouse for?
              </Title>
              <Container orientation="vertical" gap="md">
                {clickhouseReasonList.map((reason, index) => (
                  <Checkbox
                    key={`clickhouse-reason-${index}`}
                    {...reason}
                    onCheckedChange={onCheckedClickhouseReasonChange(index)}
                    data-testid="clickhouse-reason-checkbox"
                  />
                ))}
                <QuestionnaireAnswerWithInput
                  label="Other"
                  value={clickhouseReasonOther.value}
                  checked={clickhouseReasonOther.checked}
                  onCheckedChange={(checked) =>
                    setClickhouseReasonOther((value) => ({ ...value, checked }))
                  }
                  onChange={(value) => {
                    setClickhouseReasonOther((reason) => ({
                      ...reason,
                      value
                    }));
                  }}
                  onErrorChange={setHasClickhouseOtherError}
                />
              </Container>
            </Container>
          </Container>
          <Separator size="xs" />
          <Container justifyContent="end" gap="sm">
            <Button
              disabled={!isButtonEnabled}
              onClick={onSubmit}
              data-testid="entry-questionnaire-button"
            >
              Let's go!
            </Button>
          </Container>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default EntryQuestionnaire;
