import { standardQuoteIdentifier } from 'shared';
import { ColumnProps } from 'src/components/TableView/DataView/RightBar/TableInspector/types';
import { useSqlQuery } from 'src/lib/clickhouse/query';

export type UseColumnTimestampsDistribution = {
  loading: boolean;
  values: Array<{ slot: string; count: number }>;
  error: string | undefined;
};
export function useColumnTimestampsDistribution({
  name,
  database,
  tableName,
  type
}: Omit<ColumnProps, 'dataUncompressedBytes'>): UseColumnTimestampsDistribution {
  const fullTableName = `${standardQuoteIdentifier(database)}.${standardQuoteIdentifier(tableName)}`;
  const columnName = standardQuoteIdentifier(name);

  const typeCastDuration = type.includes('DateTime64') ? 'toDecimal64(duration, 2)' : 'toUInt32(duration)';
  const typeCastWindowSize = type.includes('DateTime64') ? 'toDecimal64(window_size, 2)' : 'toUInt32(window_size)';

  const sql = `
with (
    select
        min(${columnName}),
    from
        ${fullTableName}
    where
        ${columnName} is not null
) as min_null,
coalesce(min_null, toDateTime(0)) as min_val,
(
    select
        dateDiff('second', min(${columnName}), max(${columnName}))
    from
        ${fullTableName}
) as duration_null,
coalesce(duration_null, 1) as duration,
multiIf(
    duration <= 60,
    1,
    duration <= 3600,
    60,
    duration <= 43200,
    900,
    duration <= 172800,
    3600,
    duration <= 1209600,
    43200,
    duration <= 5270400,
    86400,
    duration <= 15811200,
    604800,
    duration <= 63244800,
    2592000,
    15811200
) as window_size,
timeSlots(min_val, ${typeCastDuration}, ${typeCastWindowSize}) as slots,
data as (
    select
        toStartOfInterval(${columnName}, toIntervalSecond(window_size)) as slot,
        count() as c
    from
        ${fullTableName}
    group by
        slot
)
select
    s.slot,
    d.c
from
    (
        select
            arrayJoin(slots) as slot
    ) as s
    left join data as d on d.slot = s.slot
  `;

  const [, { loading, data, error }] = useSqlQuery(sql);

  const values =
    data?.rows && data.rows.length > 0 && data.rows[0].length > 1
      ? data.rows.map((row) => {
          const [slot, count] = row;
          return {
            slot: slot as string,
            count: Number(count)
          };
        })
      : [];

  return {
    loading,
    values,
    error
  };
}
