import { createToast } from '@clickhouse/click-ui';
import { useCallback, useEffect } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useGetPendingUserActions } from 'src/lib/pendingUserActions/useGetPendingUserActions';
import { useCurrentInstance } from 'src/instance/instanceController';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
interface UseGettingStartedFeatureReturnProps {
  hasGettingStarted: boolean;
  removeGettingStarted: () => Promise<void>;
}
export const useGettingStartedFeature = (): UseGettingStartedFeatureReturnProps => {
  const pendingUserActions = useGetPendingUserActions();
  const instance = useCurrentInstance();

  const hasGettingStarted = pendingUserActions.some((userAction) => userAction.type === 'getting-started');
  const apiClient = useApiClient();

  const removeGettingStarted = useCallback(async () => {
    try {
      await apiClient.updateUserDetails({
        pendingActionTypesToRemove: ['getting-started']
      });
      navigateTo(routes.console({ serviceId: instance?.id ?? '' }));
    } catch (e) {
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'Failed to skip Getting Started'
      });
    }
  }, [apiClient, instance?.id]);

  useEffect(() => {
    // remove getting started if all the items are completed
    const { createService, idlingOptions, inviteMembers, loadData, visitedIntegration } =
      instance?.gettingStarted ?? {};
    if (createService && idlingOptions && inviteMembers && loadData && visitedIntegration && hasGettingStarted) {
      void removeGettingStarted();
    }
  }, [hasGettingStarted, instance?.gettingStarted, removeGettingStarted]);

  return { hasGettingStarted, removeGettingStarted };
};
