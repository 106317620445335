import { Text } from '@clickhouse/click-ui';
import React from 'react';
import { humanFileSize } from 'src/lib/bytesUtils';

type Props = {
  bytes: number | null | undefined;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

function FileSize({ bytes, ...otherProps }: Props): JSX.Element | null {
  if (!bytes || bytes === 0) {
    return null;
  }

  return (
    <div {...otherProps}>
      <Text>({humanFileSize(bytes)})</Text>
    </div>
  );
}

export default React.memo(FileSize);
