import { TextField } from '@clickhouse/click-ui';
import {
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState
} from 'react';

import {
  nameContainer,
  nameContentStyling,
  nameInputContainer,
  nameStyle,
  nameTextStyle
} from 'src/components/primitives/lib/Toolbar/styles';
import { NameProps } from 'src/components/primitives/lib/Toolbar/types';

function Name({
  name,
  onNameChange,
  allowEditing,
  log,
  className
}: NameProps): ReactElement {
  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(name);

  useEffect(() => {
    setEditing(false);
    setEditedValue(name);
  }, [name]);

  return (
    <div css={nameContainer}>
      <div
        css={nameStyle}
        tabIndex={0}
        role="button"
        data-allow-editing={allowEditing}
        data-editing={editing}
        data-testid="toolBarNameWrapper"
        onClick={(): void => {
          if (allowEditing) {
            setEditing(true);
          }
        }}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          if (e.key === 'Enter' && allowEditing) {
            setEditing(true);
          }
        }}
      >
        <pre css={nameContentStyling(allowEditing)} className={className ?? ''}>
          {editedValue}
        </pre>
        {allowEditing && (
          <div css={nameInputContainer(!editing)}>
            <TextField
              data-testid="toolBarNameInput"
              readOnly={!editing}
              value={editedValue}
              css={nameTextStyle}
              className={className ?? ''}
              onFocus={(e: FocusEvent<HTMLInputElement>): void => {
                log && log('NameFocus', 'trigger');
                e.target.select();
              }}
              onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  onNameChange && onNameChange(editedValue || name);
                  setEditing(false);
                }
              }}
              onChange={(newValue: string): void => {
                setEditedValue(newValue);
                log && log('NameInput', 'keypress');
              }}
              onBlur={(): void => {
                if (name !== editedValue) {
                  onNameChange && onNameChange(editedValue || name);
                  if (editedValue.length === 0) {
                    setEditedValue(name);
                  }
                }
                setEditing(false);
                log && log('NameBlur', 'trigger');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Name;
