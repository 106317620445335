import { ReactElement } from 'react';
import { TableDetails } from 'shared/src/clickhouse/types';
import CellInspectorModal from 'src/components/RightBar/CellInspectorModal';
import { RefreshDataArgs } from 'src/components/TableView';
import InsertRow from 'src/components/TableView/DataView/RightBar/InsertRow';
import TableInspector from 'src/components/TableView/DataView/RightBar/TableInspector';
import ViewParameters from 'src/components/TableView/DataView/RightBar/ViewParameters';
import { generateInsertRowQuery } from 'src/components/TableView/utils';
import { QueryResult } from 'src/lib/clickhouse/query';
import { useCloseRightBar, useSelectedTab } from 'src/state/tabs';
import { TableTab, ViewParameterValues } from 'src/state/tabs/types';

interface RightBarProps {
  table: TableDetails;
  runQuery: (
    sql: string,
    variables?: Record<string, string>
  ) => Promise<QueryResult>;
  refreshData: (args?: RefreshDataArgs) => void;
  viewParameterValues?: ViewParameterValues;
  setViewParameterValues: (values: ViewParameterValues) => void;
  container: HTMLDivElement | null;
}

function RightBar({
  table,
  runQuery,
  refreshData,
  viewParameterValues,
  setViewParameterValues,
  container
}: RightBarProps): ReactElement | null {
  const selectedTab = useSelectedTab() as TableTab;
  const options = selectedTab?.rightBarType ?? [];
  const close = useCloseRightBar();

  if (options.length === 0) {
    return null;
  }

  const lastOptionType = options[options.length - 1];

  const insertRowFn = async (fields: Record<string, string>): Promise<void> => {
    const sql = generateInsertRowQuery({
      fields,
      table
    });
    if (sql !== null) {
      const response = await runQuery(sql);
      if ('error' in response) {
        throw new Error(response.error);
      }
      refreshData();
    }
  };
  const onOpenChange = (open: boolean): void => {
    if (!open) {
      close();
    }
  };

  switch (lastOptionType) {
    case 'tableSchema': {
      return (
        <TableInspector
          open
          table={table}
          onOpenChange={onOpenChange}
          container={container}
        />
      );
    }
    case 'insertRow':
      return (
        <InsertRow
          open
          insert={insertRowFn}
          columns={table?.columns}
          container={container}
          onOpenChange={onOpenChange}
        />
      );
    case 'viewParameters':
      return (
        <ViewParameters
          open
          values={viewParameterValues}
          update={setViewParameterValues}
          parameters={table.parameters}
          container={container}
          onOpenChange={onOpenChange}
        />
      );
    case 'viewCell':
      return (
        <CellInspectorModal
          open
          value={selectedTab.cellValue ?? ''}
          onOpenChange={onOpenChange}
          container={container}
        />
      );
    default:
      return null;
  }
}

export default RightBar;
