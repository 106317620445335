import { languages, IRange } from 'monaco-editor';

import { AutocompleteProviderOptions, AutocompleteSuggestion, Kind } from 'src/lib/autocomplete/types';
import { getAutocompleteWorker } from 'src/lib/autocomplete/workerProvider/getAutocompleteWorker';
import { buildProvider } from 'src/lib/autocomplete/models/CompletionProvider';

let autocompleteWorker: Worker | null = null;

export function initWorker(): void {
  if (!autocompleteWorker) {
    autocompleteWorker = getAutocompleteWorker();
  }
}

export function terminateWorker(): void {
  if (autocompleteWorker) {
    autocompleteWorker.terminate();
    autocompleteWorker = null;
  }
}

function transformKindToMonacoKind(kind: Kind): languages.CompletionItemKind {
  switch (kind) {
    case Kind.Function:
      return 1; //languages.CompletionItemKind.Function
    case Kind.Keyword:
      return 17; // languages.CompletionItemKind.Keyword
    case Kind.Variable:
      return 4; // languages.CompletionItemKind.Variable
  }

  return 9; // languages.CompletionItemKind.Property
}

function transformToCompletionItem(sugg: AutocompleteSuggestion, range: IRange): languages.CompletionItem {
  return {
    ...sugg,
    kind: transformKindToMonacoKind(sugg.kind),
    range
  };
}

// Uncomment to debug problems with autocomplete
// If you use the autocomplete bad imports are not shown on the logs so we need to inline all the
// code related to the suggestions instead of a worker
//
export const getInlineSuggestions = (
  statement: string,
  offset: number,
  range: IRange,
  options: AutocompleteProviderOptions
): Promise<languages.CompletionList> => {
  const provider = buildProvider(options);
  const suggestions = provider.calcSuggestions(undefined, statement, offset);
  return Promise.resolve({
    suggestions: suggestions.map((item) => transformToCompletionItem(item, range))
  });
};

export const getAutocompleteSuggestionFromWorker = (
  statement: string,
  offset: number,
  range: IRange,
  options: AutocompleteProviderOptions
): Promise<languages.CompletionList> => {
  return new Promise((resolve, reject) => {
    if (!autocompleteWorker) {
      reject(new Error('Worker not initialized'));
    } else {
      autocompleteWorker.onmessage = (event: MessageEvent<AutocompleteSuggestion[]>) => {
        resolve({
          suggestions: event.data.map((item) => transformToCompletionItem(item, range))
        });
      };

      autocompleteWorker.postMessage({
        statement,
        offset,
        options
      });
    }
  });
};

export const getSuggestions = (
  statement: string,
  offset: number,
  range: IRange,
  options: AutocompleteProviderOptions
): Promise<languages.CompletionList> => {
  // uncomment to debug problems with autocomplete
  // return getInlineSuggestions(statement, offset, range, options);
  return getAutocompleteSuggestionFromWorker(statement, offset, range, options);
};
