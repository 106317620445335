import { routes } from 'src/lib/routes';

import { Sidebar } from 'src/state/selectedSidebar';
import { getCurrentServiceId } from 'src/state/service';
import { useLastSelectedTab } from 'src/state/tabs';

export function useQueriesNavigationLink(sidebar: Sidebar): string | undefined {
  const serviceId = getCurrentServiceId();

  const lastSelectedTab = useLastSelectedTab();

  if (serviceId && lastSelectedTab && lastSelectedTab.tab.type === 'query') {
    return routes.query({ serviceId, queryId: lastSelectedTab.tab.queryId });
  }

  if (sidebar === 'imports') {
    return serviceId ? routes.home({ serviceId }) : '/';
  }

  return undefined;
}

export function useTablesNavigationLink(sidebar: Sidebar): string | undefined {
  const serviceId = getCurrentServiceId();

  const lastSelectedTab = useLastSelectedTab();

  if (serviceId && lastSelectedTab && lastSelectedTab.tab.type === 'table') {
    const table = lastSelectedTab.tab.table;
    const databaseName = table.schema;
    if (!databaseName) {
      throw new Error(`Table ${table.tableName} has no database`);
    }

    return routes.tables({
      serviceId,
      databaseName: databaseName,
      tableName: table.tableName
    });
  }

  if (sidebar === 'imports') {
    return serviceId ? routes.home({ serviceId }) : '/';
  }

  return undefined;
}
