import React, { ReactElement } from 'react';
import { Button, Separator, Container } from '@clickhouse/click-ui';

type Props = {
  onOpenQueryClick: () => void;
  onCreateImportClick: () => Promise<void>;
  loading: boolean;
};
function BottomBar(props: Props): ReactElement {
  return (
    <>
      <Separator size="xs" />
      <Container gap="xs" isResponsive={false}>
        <Button
          iconLeft="query"
          type="secondary"
          onClick={props.onOpenQueryClick}
        >
          Open as query
        </Button>

        <Button
          onClick={props.onCreateImportClick}
          iconLeft={props.loading ? undefined : 'arrow-down'}
          loading={props.loading}
        >
          Import to ClickHouse
        </Button>
      </Container>
    </>
  );
}

export default React.memo(BottomBar);
