import { IconButton, Tooltip } from '@clickhouse/click-ui';
import React, { PropsWithChildren } from 'react';
import InfoIconButton from 'src/components/InfoIconButton';
import {
  useCurrentInstance,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import { useMetadataLoading } from 'src/metadata/metadataState';

type ReloadMetadataButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

type SimpleTooltipProps = {
  tooltipText: string;
};

function WithTooltip({
  tooltipText,
  children
}: PropsWithChildren<SimpleTooltipProps>): React.ReactElement {
  return (
    <Tooltip>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
      <Tooltip.Content>{tooltipText}</Tooltip.Content>
    </Tooltip>
  );
}

function ReloadMetadataButton({
  onClick
}: ReloadMetadataButtonProps): React.ReactElement {
  const { isAsleep, isDeleted, isStopped } = useIsCurrentInstanceAwakeStatus();
  const isRefreshing = useMetadataLoading();
  const instance = useCurrentInstance();
  const maintenanceKind = instance?.activeMaintenanceKind;
  const isDisabled =
    isDeleted || isStopped || maintenanceKind === 'fullMaintenance';

  if (isDisabled) {
    const toolTipText =
      isDeleted || isStopped
        ? 'This service is stopped. Start your service to view table schema metadata and run queries.'
        : 'This service is in maintenance mode. Wait until maintenance is over to refresh the data.';
    return (
      <WithTooltip tooltipText={toolTipText}>
        <IconButton
          icon="refresh"
          disabled={true}
          data-testid="reload-metadata-button"
        />
      </WithTooltip>
    );
  }

  if (isAsleep) {
    return (
      <WithTooltip tooltipText="The data shown below is cached and may no longer be accurate">
        <InfoIconButton
          icon="refresh"
          disabled={isRefreshing}
          onClick={onClick}
          data-testid="reload-metadata-button"
        />
      </WithTooltip>
    );
  }

  return (
    <WithTooltip tooltipText="Refresh Database">
      <IconButton
        icon={isRefreshing ? 'horizontal-loading' : 'refresh'}
        disabled={isRefreshing}
        data-testid="reload-metadata-button"
        onClick={(e): void => {
          if (isRefreshing) {
            return;
          }

          onClick(e);
        }}
      />
    </WithTooltip>
  );
}

export default ReloadMetadataButton;
