import { useRef } from 'react';
import {
  FocusEventHandler,
  MouseEventHandler,
  useCallback,
  useState
} from 'react';

import {
  PaginationState,
  SelectionPos
} from 'primitives/lib/Spreadsheet/types';
import { OnPaginationStateChangeProps } from 'primitives/lib/Spreadsheet/usePaginationState';

import { ResultData } from 'src/lib/clickhouse/query';
import { InteractionType, logger } from 'src/lib/logger';

import { tableViewPageSize } from 'src/components/TableView/constants';
import { UpdateRightBarFn } from 'src/components/TableView/types';
import {
  Container,
  Pagination,
  WarningAlert,
  CellProps,
  Grid,
  SelectionAction,
  SelectionFocus,
  SelectedRegion,
  GridContextMenuItemProps,
  Text,
  Tooltip,
  Separator,
  createToast
} from '@clickhouse/click-ui';
import { SpreadsheetLoader } from 'src/components/primitives';
import { EllipsisContainer } from 'src/layout/GlobalStyles';
import styled from 'styled-components';
import copyGridElements from 'src/components/QueryView/Results/QueryResults/copyGridElements';
import { Row } from 'shared';
import { getValueOfCell } from 'src/lib/grid/getValueOfCell';

const GridPagination = styled(Pagination)`
  padding-left: ${({ theme }): string => theme.spaces[4]};
  padding-right: ${({ theme }): string => theme.spaces[4]};
`;

interface ResultsTableProps {
  error?: string;
  results?: ResultData;
  loading?: boolean;
  paginationState: PaginationState;
  setPaginationState: (partialState: OnPaginationStateChangeProps) => void;
  updateRightBar: UpdateRightBarFn;
  filterApplied: boolean;
  unknownTotalPages?: boolean;
  onChangeFocus: (where: SelectionPos) => void;
  selectedRow: number;
  selectedColumn: number;
  hasViewParams: boolean;
}

const logEvent = (
  event: string,
  interaction: InteractionType,
  from?: string
): void => {
  logger.track({
    view: 'tableView',
    component: from === 'footer' ? 'footer' : 'spreadsheet',
    event,
    interaction
  });
};

const onPrevPageClick: MouseEventHandler<HTMLButtonElement> = () => {
  logEvent('paginationBackwardButtonClick', 'click', 'footer');
};

const onNextPageClick: MouseEventHandler<HTMLButtonElement> = () => {
  logEvent('paginationForwardButtonClick', 'click', 'footer');
};

const onPageNumberFocus: FocusEventHandler<HTMLInputElement> = () => {
  logEvent('paginationFocus', 'trigger', 'footer');
};

const onPageNumberBlur: FocusEventHandler<HTMLInputElement> = () => {
  logEvent('paginationBlur', 'trigger', 'footer');
};

const onSelect = (action: SelectionAction) => {
  logEvent(action.type, action.event ?? 'click', 'grid');
};

export function ConsoleResultsTable({
  error,
  loading,
  results,
  paginationState,
  setPaginationState,
  updateRightBar,
  filterApplied,
  unknownTotalPages,
  onChangeFocus,
  selectedRow,
  selectedColumn,
  hasViewParams
}: ResultsTableProps): JSX.Element | null {
  const outerRef = useRef<HTMLDivElement>(null);
  const currentPage = paginationState.currentPage ?? 1;
  const pageSize = paginationState.pageSize ?? 30;
  const rowsLength = results?.rows.length ?? 0;
  const [selection, setSelection] = useState<SelectedRegion>({
    type: 'empty'
  });

  const lastRow = results?.rows[30];

  const page = paginationState.currentPage ?? 1;
  const rowStart = tableViewPageSize * (page - 1) + 1;

  const cellValue = useCallback(
    (rowIndex: number, columnIndex: number): string | null => {
      return results?.rows[rowIndex - rowStart]?.[columnIndex] ?? null;
    },
    [results?.rows, rowStart]
  );

  const getRowsByIndex = useCallback(
    (rowIndexes: Iterable<number>): Promise<(null | Row)[]> => {
      const offset = (currentPage - 1) * pageSize;
      const resultRows = [];
      for (const rowIndex of rowIndexes) {
        resultRows.push(results?.rows[rowIndex - offset] ?? null);
      }
      return Promise.resolve(resultRows);
    },
    [results?.rows, currentPage, pageSize]
  );

  const rowCount = rowsLength < pageSize ? rowsLength : pageSize;

  const onCopy = useCallback(
    async (selection: SelectedRegion, focus: SelectionFocus): Promise<void> => {
      try {
        await copyGridElements({
          selection,
          rowCount,
          columnCount: results?.columns?.length ?? 0,
          getRowsByIndex,
          focus,
          outerRef
        });
        createToast({
          title: 'Copied successfully',
          description: 'Now you can paste the content',
          type: 'success'
        });
      } catch (error) {
        console.log(error);
        createToast({
          title: 'Failed to copy',
          description: 'Encountered an error while copying. Try again later.',
          type: 'danger'
        });
      }
      logEvent(
        selection.type === 'empty'
          ? 'cellContextMenuCopy'
          : 'cellRangeContextMenuCopy',
        'click'
      );
    },
    [rowCount, results, getRowsByIndex]
  );

  const onEditCell = useCallback(
    (rowIndex: number, columnIndex: number) => {
      const value = cellValue(rowIndex, columnIndex);
      const isFilled = typeof value === 'string' && value.length > 0;

      if (isFilled) {
        updateRightBar('viewCell', String(value));
      }
    },
    [updateRightBar, cellValue]
  );

  const onFocusChange = useCallback(
    (row: number, column: number) => {
      onChangeFocus({ row, column });
    },
    [onChangeFocus]
  );

  const onGridSelect = useCallback(
    (action: SelectionAction, selectedRegion: SelectedRegion) => {
      onSelect(action);
      setSelection(selectedRegion);
    },
    []
  );
  const getMenuOptions = useCallback(
    (
      selection: SelectedRegion,
      focus: SelectionFocus
    ): Array<GridContextMenuItemProps> => {
      if (selection.type !== 'empty') {
        return [];
      }

      const value = cellValue(focus.row, focus.column);
      const items = [];
      if (typeof value !== 'string') {
        return [];
      }
      items.push({
        label: 'Inspect Cell',
        onSelect: (): void => {
          updateRightBar('viewCell', value);
          logEvent('cellContextMenuInspectCell', 'click');
        }
      });
      return items;
    },
    [cellValue, updateRightBar]
  );

  const onCellClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      if (e.detail > 1) {
        const cell = (e.target as HTMLElement).closest<HTMLElement>(
          '[data-grid-row][data-grid-column]'
        );
        if (cell && cell.dataset.gridRow && cell.dataset.gridColumn) {
          onEditCell(
            Number(cell.dataset.gridRow),
            Number(cell.dataset.gridColumn)
          );
        }
      }
    },
    [onEditCell]
  );

  const onContextMenu: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const eventName =
        selection.type === 'empty' ? 'cellContextMenu' : 'cellRangeContextMenu';
      logEvent(`${eventName}Open`, 'trigger');
      onCellClick(e);
    },
    [onCellClick, selection.type]
  );

  if (error) {
    return (
      <Container alignItems="start" fillWidth>
        <WarningAlert
          text={JSON.stringify(error, null, 2)}
          data-testid="errorBox"
        />
      </Container>
    );
  }

  if (!loading && !results && !hasViewParams) {
    return null;
  }

  const Cell: CellProps = ({
    rowIndex,
    columnIndex,
    type,
    isScrolling,
    width,
    ...props
  }) => {
    if (!results) {
      return null;
    }
    if (type === 'header-cell') {
      const name = results.columns[columnIndex].name;
      const type = results.columns[columnIndex].type;
      return (
        <Tooltip>
          <Tooltip.Trigger data-scrolling={isScrolling} {...props}>
            <EllipsisContainer>{name}</EllipsisContainer>
          </Tooltip.Trigger>
          <Tooltip.Content side="bottom">
            {name} - {type}
          </Tooltip.Content>
        </Tooltip>
      );
    }

    const isFocused =
      rowIndex === selectedRow && columnIndex === selectedColumn;

    return (
      <span data-scrolling={isScrolling} {...props}>
        {getValueOfCell(cellValue(rowIndex, columnIndex), isFocused, width)}
      </span>
    );
  };

  const onPaginationChange = (pageNumber: number): void => {
    if (pageNumber < 1) {
      return;
    }

    setPaginationState({
      currentPage: pageNumber
    });
    onFocusChange(tableViewPageSize * (pageNumber - 1) + 1, 0);
  };

  if (rowsLength === 0) {
    return (
      <Container
        fillHeight
        orientation="vertical"
        grow="1"
        fillWidth
        justifyContent="center"
      >
        {loading ? (
          <Container
            orientation="vertical"
            alignItems="start"
            grow="1"
            fillWidth
          >
            <SpreadsheetLoader columns={results?.columns?.length ?? 5} />
          </Container>
        ) : (
          <Container grow="1" fillWidth justifyContent="center">
            <Text>
              {filterApplied
                ? 'No records found matching specified filters'
                : 'No records found in this table'}
            </Text>
          </Container>
        )}

        {currentPage !== 1 && (
          <>
            <Separator size="xs" />
            <Pagination
              currentPage={currentPage}
              pageSize={paginationState.pageSize}
              onChange={onPaginationChange}
              padding="md"
              onPageNumberBlur={onPageNumberBlur}
              onPageNumberFocus={onPageNumberFocus}
              onPrevPageClick={onPrevPageClick}
              onNextPageClick={onNextPageClick}
              disableNextButton
            />
          </>
        )}
      </Container>
    );
  }

  return (
    <Container orientation="vertical" grow="1" fillHeight>
      <Container
        orientation="vertical"
        alignItems="start"
        grow="1"
        data-testid="spreadsheet"
      >
        <Grid
          autoFocus
          rowStart={rowStart}
          columnCount={results?.columns?.length ?? 0}
          rowCount={rowsLength < pageSize ? rowsLength : pageSize}
          cell={Cell}
          getMenuOptions={getMenuOptions}
          focus={{
            row: selectedRow > 0 ? selectedRow : rowStart,
            column: selectedColumn
          }}
          onFocusChange={onFocusChange}
          onMouseDown={onCellClick}
          onContextMenu={onContextMenu}
          onSelect={onGridSelect}
          onCopy={onCopy}
          showToast={true}
        />
      </Container>
      <Separator size="xs" />
      <GridPagination
        totalPages={
          unknownTotalPages || filterApplied
            ? undefined
            : paginationState.totalPages
        }
        currentPage={currentPage}
        pageSize={paginationState.pageSize}
        rowCount={filterApplied ? undefined : paginationState.totalRows}
        onChange={onPaginationChange}
        padding="xs"
        onPageNumberBlur={onPageNumberBlur}
        onPageNumberFocus={onPageNumberFocus}
        onPrevPageClick={onPrevPageClick}
        onNextPageClick={onNextPageClick}
        justifyContent={filterApplied ? 'end' : 'space-between'}
        disableNextButton={lastRow === undefined}
      />
      <Container ref={outerRef} />
    </Container>
  );
}
