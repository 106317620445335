import {
  Button,
  Container,
  Dialog,
  Text,
  TextField
} from '@clickhouse/click-ui';
import { InstanceBackup } from '@cp/common/protocol/Backup';
import { Instance } from '@cp/common/protocol/Instance';
import { Region } from '@cp/common/protocol/Region';
import { MAX_INSTANCE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import { ReactElement, useEffect, useState } from 'react';
import { formatTimestampAny } from 'src/lib/formatters/dateTimeFormatter';

interface BackupRestoreModalProps {
  backupToRestore: InstanceBackup | null;
  instance: Instance | null;
  onClose: () => void;
  onSubmit: (options: { name: string }) => Promise<void>;
  open: boolean;
  region: Region | null;
}

export default function BackupRestoreModal({
  backupToRestore,
  instance,
  onClose: onCloseProp,
  onSubmit,
  open,
  region
}: BackupRestoreModalProps): ReactElement {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const onClose = (): void => {
    onCloseProp();
  };

  useEffect(() => {
    if (backupToRestore && instance) {
      const restoreSuffix = ' (Restore)';
      const maxLength = MAX_INSTANCE_NAME_LENGTH - restoreSuffix.length;
      const defaultName = instance.name.slice(0, maxLength) + restoreSuffix;
      setName(defaultName);
    }
  }, [backupToRestore, instance]);

  return (
    <Dialog open={open}>
      <Dialog.Content
        onClose={onClose}
        showClose
        title="Restore this backup"
        data-testid="restore-backup-dialog"
      >
        <Container gap="md" orientation="vertical" fillWidth={false}>
          {backupToRestore && region && (
            <Text color="muted">
              The backup taken at{' '}
              {formatTimestampAny(
                backupToRestore.createdAt,
                'YYYY-MM-DD HH:mm:ss (UTC)'
              )}{' '}
              will be restored into a new service located in {region.name} (
              {region.displayId}).
            </Text>
          )}
          <TextField
            data-testid="backup-restore-input"
            label="Service name"
            onChange={(value): void => setName(value)}
            value={name}
          />
          <Container
            fillWidth
            gap="md"
            justifyContent="end"
            isResponsive={false}
          >
            <Button onClick={onClose} type="secondary">
              Cancel
            </Button>
            <Button
              data-testid="backup-restore-confirm"
              disabled={!name || loading}
              loading={loading}
              onClick={(): void => {
                setLoading(true);
                void onSubmit({
                  name
                })
                  .then(() => {
                    setName('');
                    setLoading(false);
                    onClose();
                  })
                  .catch((): void => {
                    setLoading(false);
                  });
              }}
            >
              {loading ? 'Restoring backup...' : 'Restore this backup'}
            </Button>
          </Container>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}
