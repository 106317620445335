import {
  AddUserEntryQuestionnaireRequest,
  AddUserExitQuestionnaireRequest,
  AddUserToCloudWaitlistRequest,
  POCRequest,
  UserCloudWaitlistRegistration,
  UserExitQuestionnaire,
  UserQuestionnaire
} from '@cp/common/protocol/Account';
import { controlPlaneUrl } from 'src/lib/controlPlane/apiUrls';
import { HttpClient } from 'src/lib/http';

const ACCOUNT_API_PATH = '/api/account';

export class AccountApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async addUserToCloudWaitlist(userCloudWaitlistRegistration: UserCloudWaitlistRegistration): Promise<void> {
    const addUserToWaitlistRequest: AddUserToCloudWaitlistRequest = {
      rpcAction: 'addUserToCloudWaitlist',
      ...userCloudWaitlistRegistration
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(addUserToWaitlistRequest)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }

  async addUserEntryQuestionnaire(userEntryQuestionnaire: UserQuestionnaire): Promise<void> {
    const addUserEntryQuestionnaireRequest: AddUserEntryQuestionnaireRequest = {
      rpcAction: 'addUserEntryQuestionnaire',
      ...userEntryQuestionnaire
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(addUserEntryQuestionnaireRequest)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }

  async addUserExitQuestionnaire(userExitQuestionnaire: UserExitQuestionnaire): Promise<void> {
    const addUserExitQuestionnaireRequest: AddUserExitQuestionnaireRequest = {
      rpcAction: 'addUserExitQuestionnaire',
      ...userExitQuestionnaire
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(addUserExitQuestionnaireRequest)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }

  async sendPOC(pocContent: Omit<POCRequest, 'rpcAction'>): Promise<void> {
    const pocRequest: POCRequest = {
      rpcAction: 'sendRequestPOC',
      ...pocContent
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(pocRequest)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }
}
