import {
  Container,
  Dialog,
  Link,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import {
  DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS,
  STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS
} from '@cp/common/environment/CommonEnvironment';
import { ReactElement } from 'react';

interface NonpaymentModalProps {
  hasManageBillingPermission: boolean;
  open: boolean;
  onEnterPaymentDetails: () => void;
  onContactSupport: () => void;
  onClose: () => void;
}

function NonpaymentModal({
  hasManageBillingPermission,
  open,
  onEnterPaymentDetails,
  onContactSupport,
  onClose
}: NonpaymentModalProps): ReactElement | null {
  if (open !== true) {
    return null;
  }

  return (
    <Dialog open={open}>
      <Dialog.Content
        title="Oops! Your payment didn’t go through"
        showClose={true}
        onClose={onClose}
      >
        <Text>
          We are unable to process payment for your latest invoice. Please
          {hasManageBillingPermission
            ? ' update your payment details '
            : ' contact your system admin '}
          to continue using ClickHouse Cloud.
        </Text>
        <Spacer size="md" />
        <Text color="muted">
          Services will be automatically suspended after{' '}
          {STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS - 1} days of nonpayment.
          Services and data will be permanently deleted after{' '}
          {DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS} days. If you need more time
          to resolve the payment issues or you think you’re receiving this
          message in error, please contact our{' '}
          <Link href="mailto:sales@clickhouse.com">sales team</Link>.
        </Text>

        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close
            label="Contact support"
            type={hasManageBillingPermission ? undefined : 'primary'}
            onClick={onContactSupport}
          />
          {hasManageBillingPermission && (
            <Dialog.Close
              type="primary"
              label="Enter payment details"
              onClick={onEnterPaymentDetails}
            />
          )}
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

export default NonpaymentModal;
