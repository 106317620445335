import {
  Container,
  Icon,
  SearchField,
  SidebarNavigationItem
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { QueryEndpointDetail } from 'types/protocol/queryEndpoints';
import { sidebarLogEvent } from 'src/components/QueryView/analytics';
import { useTabActions } from 'src/state/tabs';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { createToast } from 'src/components/primitives';
import { sortAlphabetically } from 'src/lib/sorters/sortAlphabetically';

type Props = {
  endpoints: QueryEndpointDetail[];
};

type EndpointProps = {
  endpoint: QueryEndpointDetail;
};

function Endpoint({ endpoint }: EndpointProps): ReactElement {
  const query = useSavedQuery(endpoint.queryId);
  const { selectQueryApiEndpoint } = useTabActions();

  const selectItem = (): void => {
    if (query) {
      selectQueryApiEndpoint(query, endpoint.id);
      sidebarLogEvent('apiEndpoint', 'Open');
    } else {
      createToast(
        'error',
        'Error',
        'Could not find the query for this endpoint'
      );
    }
  };

  return (
    <SidebarNavigationItem
      type="sqlSidebar"
      data-testid={`queryApiEndpointItem-${endpoint.id}`}
      key={endpoint.id}
      aria-label={`API endpoint ${endpoint.name}`}
      role="listitem"
      onClick={(e): void => {
        e.stopPropagation();
        selectItem();
      }}
      label={
        <Container gap="md">
          <Icon name="http" />
          {endpoint.name}
        </Container>
      }
    />
  );
}

export function ApiEndpointsList({ endpoints }: Props): ReactElement {
  const [search, setSearch] = useState('');
  const filteredEndpoints = endpoints
    .filter((endpoint: QueryEndpointDetail) => {
      if (search === '') {
        return true;
      }

      return endpoint.name.toLowerCase().includes(search.toLowerCase());
    })
    .sort((a, b) => sortAlphabetically(a.name, b.name));

  return (
    <Container gap="md" orientation="vertical" padding="md">
      <SearchField
        onChange={(value: string): void => {
          sidebarLogEvent('searchApiEndpoints', 'Input');
          setSearch(value);
        }}
        placeholder="Search endpoints"
        value={search}
        onFocus={(): void =>
          sidebarLogEvent('searchApiEndpoints', 'Focus', 'trigger')
        }
        onBlur={(): void =>
          sidebarLogEvent('searchApiEndpoints', 'Blur', 'trigger')
        }
        className="fs-exclude"
      />

      {filteredEndpoints.map((endpoint) => (
        <Endpoint key={endpoint.id} endpoint={endpoint} />
      ))}
    </Container>
  );
}
