import {
  Container,
  ContainerProps,
  Flyout,
  IconButton
} from '@clickhouse/click-ui';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Resizable } from 're-resizable';
import {
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect
} from 'react';
import { useMatch } from 'react-router-dom';
import { mainSidebarOpen } from 'src/state/sidebar';
import styled from 'styled-components';

const isOpenAtom = atom(false);
const isDesktop = atom(true);

interface SidebarTriggerProps extends ContainerProps {
  showMenu: boolean;
}
export const SidebarTrigger = ({
  children,
  showMenu = true,
  ...props
}: SidebarTriggerProps): ReactElement => {
  const setIsSidebarOpen = useSetAtom(isOpenAtom);
  const isDesktopOpen = useAtomValue(isDesktop);

  return (
    <Container padding="none" gap="xs" isResponsive={false} {...props}>
      {showMenu && !isDesktopOpen && (
        <Flyout.Trigger>
          <IconButton
            icon="burger-menu"
            onClick={() => setIsSidebarOpen((open) => !open)}
          />
        </Flyout.Trigger>
      )}
      {children}
    </Container>
  );
};

const SidebarContainer = styled(Container)`
  flex-direction: row-reverse;
`;

interface Props {
  children: ReactNode;
}

export const ResponsiveWrapper = forwardRef<HTMLDivElement, Props>(
  ({ children }, ref): ReactElement => {
    const [isDesktopOpen, setIsDesktopOpen] = useAtom(isDesktop);
    const [isMobileOpen, setIsMobileOpen] = useAtom(isOpenAtom);
    const consoleOpened = useMatch('/services/:serviceId/console/*');
    const dashboardsOpened = useMatch('/services/:serviceId/dashboards/*');
    const openSidebar = useAtomValue(mainSidebarOpen);

    const onOpenChange = (open: boolean): void => {
      if (!isDesktopOpen) {
        setIsMobileOpen(open);
      }
    };

    useEffect(() => {
      const handleResize = (): void => {
        setIsDesktopOpen(window.innerWidth > 1024);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [setIsDesktopOpen]);

    const open = isDesktopOpen
      ? consoleOpened || dashboardsOpened
        ? openSidebar
        : true
      : isMobileOpen;

    return (
      <Flyout open={open} modal={!isDesktopOpen} onOpenChange={onOpenChange}>
        <SidebarContainer
          fillHeight
          overflow="hidden"
          alignItems="start"
          justifyContent="start"
          isResponsive={false}
          ref={ref}
        >
          <Container
            orientation="vertical"
            grow="1"
            fillHeight
            overflow="hidden"
            isResponsive={false}
          >
            {children}
          </Container>
        </SidebarContainer>
      </Flyout>
    );
  }
);

const FlyoutHeader = styled(Flyout.Header)`
  padding: 1rem;
  align-items: center;
`;

const FlyoutContent = styled(Flyout.Content)`
  background: ${({ theme }) =>
    theme.click.sidebar.main.color.background.default};
  height: 100%;
  & > * {
    transition: width 0.3s ease-in-out;
  }
  @media screen and (min-width: 640px) {
    box-shadow: none;
  }
`;

export const ResizableContainer = styled(Resizable)`
  height: 100%;
  gap: inherit;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  --flyout-width: attr(width);
`;

export const MainSidebarBody = ({
  children,
  header,
  container,
  width
}: {
  children: ReactNode;
  header: ReactNode;
  container: HTMLDivElement | null;
  width?: string;
}): ReactElement => {
  const setIsMobileMenu = useSetAtom(isOpenAtom);
  const isDesktopOpen = useAtomValue(isDesktop);
  const onPointerDownOutside = (): void => {
    if (!isDesktopOpen) {
      setIsMobileMenu(false);
    }
  };
  return (
    <FlyoutContent
      container={container}
      align="start"
      showOverlay={!isDesktopOpen}
      strategy={isDesktopOpen ? 'relative' : 'fixed'}
      width={width ?? '250px'}
      onPointerDownOutside={onPointerDownOutside}
    >
      <ResizableContainer
        enable={{ right: true }}
        defaultSize={{
          width: 250,
          height: '100%'
        }}
        minWidth={250}
        maxWidth={600}
      >
        <FlyoutHeader showClose={!isDesktopOpen} showSeparator={false}>
          {header}
        </FlyoutHeader>
        <Flyout.Body fillHeight overflow="hidden auto">
          {children}
        </Flyout.Body>
      </ResizableContainer>
    </FlyoutContent>
  );
};

interface UseMainSidebarProps {
  closeSidebar: () => void;
  isDesktop: boolean;
}

export const useMainSidebar = (): UseMainSidebarProps => {
  const setIsOpenAtom = useSetAtom(isOpenAtom);
  const isDesktopOpen = useAtomValue(isDesktop);
  const closeSidebar = useCallback((): void => {
    if (!isDesktopOpen) {
      setIsOpenAtom(false);
    }
  }, [isDesktopOpen, setIsOpenAtom]);

  return {
    closeSidebar,
    isDesktop: isDesktopOpen
  };
};
