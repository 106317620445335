import {
  Button,
  Container,
  Link,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { CloudProvider } from '@cp/common/protocol/Instance';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useJoinWaitList } from 'src/account/controller/useJoinWaitList';

const AzureWaitlist = ({
  onClick: onClickProp,
  setSelectedProvider
}: {
  onClick: () => void;
  setSelectedProvider: Dispatch<SetStateAction<CloudProvider>>;
}): ReactElement => {
  const { joinAzureWaitlist, isLoading } = useJoinWaitList();
  const onClick = async (): Promise<void> => {
    try {
      await joinAzureWaitlist();
      onClickProp();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container
      justifyContent="center"
      orientation="vertical"
      alignItems="center"
      padding="xl"
    >
      <Title type="h4" size="lg">
        Azure is coming soon
      </Title>
      <Container maxWidth="85%" padding="md">
        <Text align="center" color="muted">
          Join the waitlist to be notified when it becomes available or get
          started with ClickHouse Cloud on{' '}
          <Link onClick={() => setSelectedProvider('AWS')}>AWS</Link> or{' '}
          <Link onClick={() => setSelectedProvider('GCP')}>GCP</Link>.
        </Text>
      </Container>
      <Spacer />
      <Button
        label="Join waitlist"
        loading={isLoading}
        fillWidth
        onClick={onClick}
      />
    </Container>
  );
};

export default AzureWaitlist;
