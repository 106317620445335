import {
  Container,
  Select,
  Spacer,
  Switch,
  TextField
} from '@clickhouse/click-ui';
import { COUNTRIES } from '@cp/common/protocol/Region';
import { ReactElement } from 'react';
import { STATES_SELECT } from 'src/billing/common/stateSelection';
import {
  useAddressesSame,
  useBillingAddressCity,
  useBillingAddressCountry,
  useBillingAddressLine1,
  useBillingAddressLine2,
  useBillingAddressPostalCode,
  useBillingAddressState,
  useShippingAddressCity,
  useShippingAddressCountry,
  useShippingAddressLine1,
  useShippingAddressLine2,
  useShippingAddressPostalCode,
  useShippingAddressState
} from 'src/components/BillingCardModal/FormState';

export const AddressInfoContainer = (): ReactElement => {
  const {
    billingAddressLine1,
    setBillingAddressLine1,
    billingAddressLine1Error
  } = useBillingAddressLine1();
  const { billingAddressLine2, setBillingAddressLine2 } =
    useBillingAddressLine2();
  const { billingAddressCity, setBillingAddressCity, billingAddressCityError } =
    useBillingAddressCity();
  const {
    billingAddressPostalCode,
    setBillingAddressPostalCode,
    billingAddressPostalCodeError
  } = useBillingAddressPostalCode();
  const {
    billingAddressCountry,
    setBillingAddressCountry,
    billingAddressCountryError
  } = useBillingAddressCountry();
  const {
    billingAddressState,
    setBillingAddressState,
    billingAddressStateError
  } = useBillingAddressState();
  const { bothAddressesSame, setBothAddressesSame } = useAddressesSame();
  const {
    shippingAddressLine1,
    setShippingAddressLine1,
    shippingAddressLine1Error
  } = useShippingAddressLine1();
  const { shippingAddressLine2, setShippingAddressLine2 } =
    useShippingAddressLine2();
  const {
    shippingAddressCity,
    setShippingAddressCity,
    shippingAddressCityError
  } = useShippingAddressCity();
  const {
    shippingAddressPostalCode,
    setShippingAddressPostalCode,
    shippingAddressPostalCodeError
  } = useShippingAddressPostalCode();
  const {
    shippingAddressCountry,
    setShippingAddressCountry,
    shippingAddressCountryError
  } = useShippingAddressCountry();
  const {
    shippingAddressState,
    setShippingAddressState,
    shippingAddressStateError
  } = useShippingAddressState();

  return (
    <>
      <Container
        orientation="vertical"
        gap="md"
        alignItems="start"
        data-testid="billing-card-modal-billing-address-form"
        fillWidth={false}
      >
        <Container orientation="vertical" gap="sm" alignItems="start" fillWidth>
          <TextField
            dir="start"
            label="Billing address"
            orientation="vertical"
            placeholder="Address line 1"
            value={billingAddressLine1}
            type="text"
            error={billingAddressLine1Error}
            onChange={setBillingAddressLine1}
            data-testid="billing-line-1"
          />
          <TextField
            dir="start"
            orientation="vertical"
            placeholder="Address line 2 (optional)"
            value={billingAddressLine2}
            type="text"
            onChange={setBillingAddressLine2}
            data-testid="billing-line-2"
          />
        </Container>
        <Container
          orientation="horizontal"
          justifyContent="space-between"
          alignItems="start"
          gap="md"
          fillWidth
        >
          <TextField
            dir="start"
            label="City"
            orientation="vertical"
            type="text"
            error={billingAddressCityError}
            value={billingAddressCity}
            onChange={setBillingAddressCity}
            data-testid="billing-city"
          />
          <TextField
            dir="start"
            label="Zip or postal code"
            orientation="vertical"
            value={billingAddressPostalCode}
            type="text"
            error={billingAddressPostalCodeError}
            onChange={setBillingAddressPostalCode}
            data-testid="billing-postal-code"
          />
        </Container>
        <Container
          orientation="horizontal"
          justifyContent="space-between"
          gap="md"
          alignItems="start"
          fillWidth
        >
          <Select
            onSelect={setBillingAddressCountry}
            value={billingAddressCountry}
            error={billingAddressCountryError}
            placeholder="Select a country"
            label="Country"
            data-testid="billing-country-select"
          >
            {COUNTRIES.map((country) => (
              <Select.Item
                value={country.code}
                key={country.code}
                data-testid="billing-country-option"
                data-testid-value={country.code}
              >
                {country.name}
              </Select.Item>
            ))}
          </Select>
          <Select
            disabled={
              billingAddressCountry === undefined ||
              !STATES_SELECT[billingAddressCountry]
            }
            onSelect={setBillingAddressState}
            value={billingAddressState}
            error={billingAddressStateError}
            placeholder={
              billingAddressCountry && !STATES_SELECT[billingAddressCountry]
                ? 'N/A'
                : 'Select a State / County / Province'
            }
            label="State / County / Province"
            data-testid="billing-state-select"
          >
            {billingAddressCountry !== undefined &&
              STATES_SELECT[billingAddressCountry] &&
              STATES_SELECT[billingAddressCountry].map((state) => (
                <Select.Item
                  value={state.value}
                  key={state.value}
                  data-testid={state.dataTestId}
                  data-testid-value={state.dataTestIdValue}
                >
                  {state.label}
                </Select.Item>
              ))}
          </Select>
        </Container>
        <Switch
          checked={bothAddressesSame}
          dir="end"
          label="Shipping address same as billing address"
          orientation="horizontal"
          data-testid="shipping-address-same-as-billing"
          onClick={(): void => {
            setBothAddressesSame(!bothAddressesSame);
          }}
        />
      </Container>
      {bothAddressesSame === false && (
        <Container
          orientation="vertical"
          gap="md"
          alignItems="start"
          data-testid="billing-card-modal-shipping-address-form"
          fillWidth={false}
        >
          <Spacer size="sm" />
          <Container
            orientation="vertical"
            gap="sm"
            alignItems="start"
            fillWidth
          >
            <TextField
              dir="start"
              label="Shipping address"
              orientation="vertical"
              placeholder="Address line 1"
              value={shippingAddressLine1}
              type="text"
              error={shippingAddressLine1Error}
              onChange={setShippingAddressLine1}
              data-testid="shipping-line-1"
            />
            <TextField
              dir="start"
              orientation="vertical"
              placeholder="Address line 2 (optional)"
              value={shippingAddressLine2}
              type="text"
              onChange={setShippingAddressLine2}
              data-testid="shipping-line-2"
            />
          </Container>
          <Container
            orientation="horizontal"
            justifyContent="space-between"
            gap="md"
            alignItems="start"
            fillWidth
          >
            <TextField
              dir="start"
              label="City"
              orientation="vertical"
              type="text"
              value={shippingAddressCity}
              error={shippingAddressCityError}
              onChange={setShippingAddressCity}
              data-testid="shipping-city"
            />
            <TextField
              dir="start"
              label="Zip or postal code"
              orientation="vertical"
              value={shippingAddressPostalCode}
              error={shippingAddressPostalCodeError}
              type="text"
              onChange={setShippingAddressPostalCode}
              data-testid="shipping-postal-code"
            />
          </Container>
          <Container
            orientation="horizontal"
            justifyContent="space-between"
            gap="md"
            alignItems="start"
            fillWidth
          >
            <Select
              onSelect={setShippingAddressCountry}
              value={shippingAddressCountry}
              error={shippingAddressCountryError}
              placeholder="Select a country"
              label="Country"
              data-testid="shipping-country-select"
            >
              {COUNTRIES.map((country) => (
                <Select.Item
                  value={country.code}
                  key={country.code}
                  data-testid="shipping-country-option"
                  data-testid-value={country.code}
                >
                  {country.name}
                </Select.Item>
              ))}
            </Select>
            <Select
              disabled={
                shippingAddressCountry === undefined ||
                !STATES_SELECT[shippingAddressCountry]
              }
              onSelect={setShippingAddressState}
              value={shippingAddressState}
              error={shippingAddressStateError}
              placeholder={
                shippingAddressCountry && !STATES_SELECT[shippingAddressCountry]
                  ? 'N/A'
                  : 'Select a State / County / Province'
              }
              label="State / County / Province"
              data-testid="shipping-state-select"
            >
              {shippingAddressCountry !== undefined &&
                STATES_SELECT[shippingAddressCountry] &&
                STATES_SELECT[shippingAddressCountry].map((state) => (
                  <Select.Item
                    value={state.value}
                    key={state.value}
                    data-testid={state.dataTestId}
                    data-testid-value={state.dataTestIdValue}
                  >
                    {state.label}
                  </Select.Item>
                ))}
            </Select>
          </Container>
        </Container>
      )}
    </>
  );
};
