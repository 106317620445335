import { ReactElement, useMemo } from 'react';
import { Select } from '@clickhouse/click-ui';
import { useDatabaseCapabilities } from 'src/metadata/metadataState';
import { useVisited } from 'src/components/CreateTableView/useVisited';
import { OptionProps } from 'src/components/CreateTableView/ColumnOptions/types';

function useDataTypes(): Array<string> {
  const capabilities = useDatabaseCapabilities();
  const allowObjectType = capabilities.allowObjectType ?? false;

  return useMemo(() => {
    const dataTypes = [
      'String',
      'Int',
      'Float',
      'Date',
      'DateTime',
      'UUID',
      'Bool',
      'Int16',
      'Int32',
      'Int64',
      'Int128',
      'Int256',
      'UInt8',
      'UInt16',
      'UInt32',
      'UInt64',
      'UInt256',
      'UInt128',
      'Decimal',
      'Float32',
      'Float64',
      'Date32',
      'IPv4',
      'IPv6',
      'Int8'
    ];

    if (allowObjectType) {
      dataTypes.push('JSON');
    }

    return dataTypes;
  }, [allowObjectType]);
}

function ColumnType({ column, change }: OptionProps): ReactElement {
  const [typeVisited, visitType] = useVisited();
  const dataTypes = useDataTypes();

  return (
    <Select
      disabled={column.removed}
      onBlur={(): void => {
        if (!typeVisited) {
          visitType();
        }
      }}
      placeholder="Select column type"
      onSelect={(type: string): void => {
        change({ type });
      }}
      showSearch
      value={column.type}
      error={!column.type && typeVisited}
      className="fs-exclude"
      allowCreateOption
    >
      {dataTypes.map((name) => (
        <Select.Item key={name} value={name}>
          {name}
        </Select.Item>
      ))}
    </Select>
  );
}

export default ColumnType;
