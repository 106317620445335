import { isNumericType } from 'shared/src/clickhouse/dataTypes';
import { EditColumn } from 'shared/src/tableSchema';
import {
  CollapsingMergeTreeConfig,
  ReplacingMergeTreeConfig,
  SummingMergeTreeConfig,
  TableEngineConfig,
  VersionedCollapsingMergeTreeConfig
} from 'shared/src/tableSchema/tableEngine';
import { ColumnMultiSelect } from 'src/components/CreateTableView/ColumnMultiSelect';
import { ColumnSelect } from 'src/components/CreateTableView/ColumnSelect';
import {
  useEditTable,
  useSortKey
} from 'src/components/CreateTableView/context';

type ColumnFilter = (col: EditColumn) => boolean;

function validReplacingVersionColumn(column: EditColumn): boolean {
  return (
    null !==
    column.type.match(/^(UInt.*|Date|DateTime|DateTime\(.*|DateTime64)$/)
  );
}

function validCollapsingVersionColumn(column: EditColumn): boolean {
  return null !== column.type.match(/^(UInt.*)$/);
}

function numericColumn(column: EditColumn): boolean {
  return isNumericType(column.type);
}

function int8Column(column: EditColumn): boolean {
  return column.type === 'Int8';
}

export function EngineParams(): JSX.Element | null {
  const { tableState, setTableState } = useEditTable();
  const sortKey = useSortKey();

  const setEngine = (engine: TableEngineConfig): void => {
    setTableState({
      ...tableState,
      engine
    });
  };

  const changeVersionColumn = (
    engine: ReplacingMergeTreeConfig | VersionedCollapsingMergeTreeConfig,
    versionColumnId: string
  ): void => {
    setEngine({ ...engine, versionColumnId });
  };

  const changeSummingColumns = (
    engine: SummingMergeTreeConfig,
    columnIds: string[]
  ): void => {
    setEngine({ ...engine, columnIds });
  };

  const changeSignColumn = (
    engine: CollapsingMergeTreeConfig | VersionedCollapsingMergeTreeConfig,
    signColumnId: string
  ): void => {
    setEngine({ ...engine, signColumnId });
  };

  const excludeSortKey =
    (filter: ColumnFilter): ColumnFilter =>
    (col) => {
      return (
        filter(col) &&
        !sortKey.some((keyColumn) => {
          const sortKeyColumn = tableState.columns[keyColumn.id];
          return sortKeyColumn && sortKeyColumn.name == col.name;
        })
      );
    };

  const engine = tableState.engine;

  switch (engine.type) {
    case 'ReplacingMergeTree':
      return (
        <ColumnSelect
          label="Version"
          filter={excludeSortKey(validReplacingVersionColumn)}
          value={engine.versionColumnId}
          onChange={(val: string): void => changeVersionColumn(engine, val)}
          required
        />
      );
    case 'SummingMergeTree':
      return (
        <ColumnMultiSelect
          label="Columns"
          filter={excludeSortKey(numericColumn)}
          selectedColumnIds={engine.columnIds}
          onChange={(val: string[]): void => changeSummingColumns(engine, val)}
          required
        />
      );
    case 'CollapsingMergeTree':
      return (
        <ColumnSelect
          label="Sign"
          required
          filter={excludeSortKey(int8Column)}
          value={engine.signColumnId}
          onChange={(val: string): void => changeSignColumn(engine, val)}
        />
      );
    case 'VersionedCollapsingMergeTree':
      return (
        <>
          <ColumnSelect
            required
            label="Sign"
            value={engine.signColumnId}
            filter={excludeSortKey(int8Column)}
            onChange={(val: string): void => changeSignColumn(engine, val)}
          />
          <ColumnSelect
            label="Version"
            required
            filter={excludeSortKey(validCollapsingVersionColumn)}
            value={engine.versionColumnId}
            onChange={(val: string): void => changeVersionColumn(engine, val)}
          />
        </>
      );
    case 'MergeTree':
    case 'AggregatingMergeTree':
    default:
      return null;
  }
}
