import { useCallback, useEffect } from 'react';
import { UserDetailsUpdate } from '@cp/common/protocol/Account';
import { TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { createToast } from 'primitives';
import { atom, useAtom } from 'jotai';
import { useAuth } from 'src/components/auth';
import { PendingUserAction } from '@cp/common/protocol/PendingUserActions';

type UserAddPendingUserActionType = {
  addTackleSubscriptionAction: (tackleToken: TackleSubscriptionToken) => void;
  removeTackleSubscriptionAction: () => void;
  // Pending user actions that have not been sent to the server yet.
  unsentPendingActions: PendingUserAction[];
};

// A list of pending user action updates.
const pandingActionChangesAtom = atom<UserDetailsUpdate[]>([]);
// A list of pending user actions that have not been sent to the server yet.
const unsentPendingActionsAtom = atom<PendingUserAction[]>([]);

/**
 * Custom hook to add or delete pending user actions for tackle subscription.
 * The pending user action are stored in the user details in the DB.
 */
export const usePendingUserActionController = (): UserAddPendingUserActionType => {
  const apiClient = useApiClient();
  const [pendingActionChanges, setPendingActionChanges] = useAtom(pandingActionChangesAtom);
  const { isAuthenticated } = useAuth();
  const [unsentPendingActions, setUnsentPendingActions] = useAtom(unsentPendingActionsAtom);

  // Wait until the user is authenticated to apply the pending user actions to the server.
  useEffect(() => {
    const applyPendingActionChanges = async (): Promise<void> => {
      // Prevent for multiple calls to applyPendingActionChanges.
      setPendingActionChanges([]);
      try {
        for (const pendingActionChange of pendingActionChanges) {
          await apiClient.updateUserDetails(pendingActionChange);
        }
      } catch (e) {
        createToast('There was a problem updating your details, please try again later', 'alert', errorMessage(e));
      }
    };

    if (!isAuthenticated || pendingActionChanges.length === 0) {
      return;
    }
    applyPendingActionChanges().catch(console.error);
  }, [apiClient, isAuthenticated, pendingActionChanges, setPendingActionChanges]);

  /**
   * Adds a pending user action for Tackle subscription.
   * @param tackleToken - The Tackle subscription token.
   */
  const addTackleSubscriptionAction = useCallback(
    (tackleToken: TackleSubscriptionToken) => {
      const newPendingAction: PendingUserAction = {
        type: 'tackle-subscription',
        data: JSON.stringify(tackleToken)
      };
      const update: UserDetailsUpdate = {
        pendingActionsToAdd: [newPendingAction]
      };
      // We cannot apply the changes right away because we need to wait for the user session to be initialized.
      // This is why we save the changes in a list and apply them later.
      setPendingActionChanges((changes) => [...changes, update]);
      setUnsentPendingActions((unsentActions) => [...unsentActions, newPendingAction]);
    },
    [setPendingActionChanges, setUnsentPendingActions]
  );

  /**
   * Removes pending user action for Tackle subscription.
   */
  const removeTackleSubscriptionAction = useCallback(() => {
    const update: UserDetailsUpdate = {
      pendingActionTypesToRemove: ['tackle-subscription']
    };
    // We cannot apply the changes right away because we need to wait for the user session to be initialized.
    // This is why we save the changes in a list and apply them later.
    setPendingActionChanges((changes) => [...changes, update]);
    setUnsentPendingActions((unsentActions) => {
      const newUnsentActions = unsentActions.filter((action) => action.type !== 'tackle-subscription');
      return newUnsentActions;
    });
  }, [setPendingActionChanges, setUnsentPendingActions]);

  return { addTackleSubscriptionAction, removeTackleSubscriptionAction, unsentPendingActions };
};
