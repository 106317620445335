import {
  Badge,
  Container,
  Icon,
  IconButton,
  Panel,
  Text,
  Title
} from '@clickhouse/click-ui';
import {
  InstanceCloudProvider,
  InstanceState,
  MaintenanceWindowStatus
} from '@cp/common/protocol/Instance';
import { REGION_BY_ID, RegionId } from '@cp/common/protocol/Region';
import { getMaintenanceStatus } from '@cp/common/utils/MaintenanceWindowUtils';
import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import CopyButton from 'src/components/CopyButton';
import { StatusIcon } from 'src/components/InstanceStatusIcon';
import { formatStateForDisplay } from 'src/instance/instance';
import {
  useCurrentInstanceOrThrow,
  useInstanceController
} from 'src/instance/instanceController';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { EditNameModal } from 'src/pages/SettingsPage/EditNameModal';
import styled from 'styled-components';

const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;

const NoWrapText = styled(Text)`
  white-space: nowrap;
`;

const LogoContainer = styled(Container)`
  @media (max-width: ${(props): string => props.theme.breakpoint.sizes.md}) {
    justify-content: start;
  }
`;

const InfoContainer = styled(Container)`
  @media (max-width: ${(props): string => props.theme.breakpoint.sizes.md}) {
    flex-direction: column-reverse;
  }
`;

export const HeaderSectionView = ({
  name,
  hasManageServicePermission,
  clickhouseVersion,
  cloudProvider,
  regionId,
  serviceId,
  serviceState,
  onNameEditClick,
  maintenanceStatus
}: HeaderSectionProps): ReactElement => {
  const region = REGION_BY_ID[regionId];

  return (
    <Container gap="lg" isResponsive={false}>
      <ServiceInfoWrapper hasBorder alignItems="start" orientation="vertical">
        <Container
          fillWidth
          justifyContent="space-between"
          isResponsive={false}
        >
          <NameWrapper gap="none" isResponsive={false}>
            <Title type="h2" size="md">
              {name}
            </Title>
            {hasManageServicePermission && (
              <IconButton
                type="ghost"
                icon="pencil"
                data-testid="edit-name-btn"
                onClick={onNameEditClick}
              />
            )}
          </NameWrapper>
          {maintenanceStatus && (
            <Container justifyContent="end">
              <Badge
                text={
                  maintenanceStatus === 'in-progress'
                    ? 'Performing maintenance'
                    : `Maintenance ${maintenanceStatus}`
                }
                state="info"
                size="md"
                icon={
                  maintenanceStatus === 'in-progress'
                    ? 'dots-horizontal'
                    : undefined
                }
                data-testid="settings-maintenance-status-badge"
              ></Badge>
            </Container>
          )}
        </Container>
        <InfoContainer orientation="horizontal">
          <RegionAndVersionWrapper
            gap="xxs"
            isResponsive={false}
            style={{ border: '2px solid deeppinzk' }}
          >
            <StatusIcon status={serviceState} />

            <CapitalizedText data-testid="instance-state" color="muted">
              {formatStateForDisplay(serviceState)}
            </CapitalizedText>
            <Text color="muted"> • </Text>
            <NoWrapText color="muted" data-testid="clickhouse-version">
              Version {clickhouseVersion}
            </NoWrapText>
            <Text color="muted"> • </Text>
            <NoWrapText color="muted">Service ID</NoWrapText>
            <CopyButton text={serviceId} />
          </RegionAndVersionWrapper>
          <LogoContainer
            justifyContent={'end'}
            gap="sm"
            alignItems="center"
            isResponsive={false}
          >
            <Icon
              size="xl"
              name={cloudProvider}
              data-testid={`${cloudProvider}-logo`}
            />
            <NoWrapText color="muted">
              {region.name} ({region.cloudProviderId})
            </NoWrapText>
          </LogoContainer>
        </InfoContainer>
      </ServiceInfoWrapper>
    </Container>
  );
};

const NameWrapper = Container;
const RegionAndVersionWrapper = Container;

const ServiceInfoWrapper = styled(Panel)`
  align-self: stretch;
  flex: 1;
  gap: 0;
`;

export interface HeaderSectionProps {
  name: string;
  hasManageServicePermission: boolean;
  clickhouseVersion: string;
  cloudProvider: InstanceCloudProvider;
  regionId: RegionId;
  serviceState: InstanceState;
  serviceId: string;
  onNameEditClick: () => void;
  maintenanceStatus: MaintenanceWindowStatus;
}

export const HeaderSection = (): ReactElement | null => {
  const { renameInstance } = useInstanceController();
  const instance = useCurrentInstanceOrThrow();
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = useState(false);
  const maintenanceStatus = getMaintenanceStatus(instance);

  const { name, clickhouseVersion, cloudProvider, regionId, id, state } =
    instance;

  const handleNameChange = async (newName: string): Promise<void> => {
    await renameInstance({
      name: newName,
      instanceId: instance.id,
      organizationId: instance.organizationId
    });
  };

  return (
    <>
      <EditNameModal
        open={isEditNameDialogOpen}
        name={name}
        onConfirm={handleNameChange}
        onCancel={(): void => setIsEditNameDialogOpen(false)}
      />

      <HeaderSectionView
        name={name}
        hasManageServicePermission={hasManageServicePermission}
        clickhouseVersion={clickhouseVersion}
        cloudProvider={cloudProvider}
        regionId={regionId}
        serviceId={id}
        serviceState={state}
        onNameEditClick={(): void => setIsEditNameDialogOpen(true)}
        maintenanceStatus={maintenanceStatus}
      />
    </>
  );
};
