import { css } from '@emotion/react';

export default {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `,
  buttonContainer: css`
    display: flex;
    justify-content: flex-start;
    margin: 16px;
    gap: 16px;
  `
};
