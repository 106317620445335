import isEqual from 'lodash/isEqual';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Row } from 'shared/src/clickhouse/types';
import { useCurrentInstanceId, useIsCurrentInstanceAwakeStatus } from 'src/instance/instanceController';

import { useHttpClient } from 'src/lib/http';

import { QueryErrorType, streamingQuery } from 'src/lib/query/streamingQuery';
import {
  Credential,
  PasswordCredential,
  useConnectionCredentials,
  useCredentials,
  validatePasswordCredential
} from 'src/state/connection';
import { useOrgIdFromServiceId } from 'src/state/service';
import { useSetWakeServiceModalOpen } from 'src/state/service/wakeService';
import { v4 as uuid } from 'uuid';
import { PASSWORD_CREDENTIAL_ERROR } from 'shared/src/errorCodes';
import { isDefined } from '@cp/common/protocol/Common';
import { errorMessage } from 'api/src/lib/errorHandling';
import { assertTruthy } from '@cp/common/utils/Assert';

export type QueryResults = [
  () => Promise<void>,
  {
    error?: string;
    loading: boolean;
    data?: ResultData;
  }
];

export interface ResultColumn {
  name: string;
  type: string;
}

export interface ResultData {
  columns: ResultColumn[];
  rows: Row[];
  runId: string;
  rawData?: string[];
}

export interface ResultError {
  error: string;
  type: QueryErrorType;
}

export type QueryResult = ResultData | ResultError;

export type QueryRunnerFn = (sql: string, credentials: Credential, options?: RunQueryOptions) => Promise<QueryResult>;

export type QueryFunctionResults = [
  QueryRunnerFn,
  {
    error?: string;
    loading: boolean;
    data?: ResultData;
  },
  MutableRefObject<() => void>
];

function isSelectStatement(sql: string): boolean {
  return sql.toUpperCase().startsWith('SELECT');
}

interface UseRawSqlQueryFunctionArgs {
  skipConnectionInit?: boolean;
}

interface RunQueryOptions {
  variables?: Record<string, string>;
  wakeService?: boolean;
}

/**
 * Helper method to determine if the result is good data
 **/
export const isResultData = (result?: QueryResult): result is ResultData => {
  return isDefined(result) && 'rows' in result;
};

/**
 * Helper method to determine if the result is an error
 **/
export const isResultError = (result: QueryResult): result is ResultError => {
  return !isDefined(result) || !('rows' in result);
};

/**
 * raw run query function hook. returns a function that runs a sql query given a set of credentials and returns the results
 */
export function useRawSqlQueryFunction({ skipConnectionInit }: UseRawSqlQueryFunctionArgs = {}): QueryFunctionResults {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResultData>();
  const cancelQueryRef = useRef<() => Promise<Response | void>>(async () => {});
  const http = useHttpClient();
  const { selectedDatabase, setCredentialModalOpen, updateCredentials } = useConnectionCredentials();
  const setWakeServiceModalOpen = useSetWakeServiceModalOpen();
  const currentServiceId = useCurrentInstanceId();
  const orgId = useOrgIdFromServiceId(currentServiceId) ?? '';
  const { isStopped, isAwaking, isStarting, isProvisioning } = useIsCurrentInstanceAwakeStatus();

  // As the query asynchronously runs, it should not modify (error, loading,
  // state) if its runId is different from this ref. When multiple concurrent
  // queries are running, this stops earlier queries racing with the latest over
  // this state
  const currentRunId = useRef<string>();

  const runQuery = useCallback(
    async (sql: string, credentials: Credential, options: RunQueryOptions = {}): Promise<QueryResult> => {
      const serviceId = (credentials.type === 'managed' && credentials.serviceId) || currentServiceId;
      assertTruthy(serviceId, 'No serviceId found for query');
      const runId = uuid();
      currentRunId.current = runId;
      setError(undefined);

      // if the service is already moving to a a state where he is not idle, we can skip the wake step in the middleware
      // this should prevent the user from seeing the "waking up" modal when the service is already in the process of waking up
      const forceAwakeService = options.wakeService || isAwaking || isStarting || isProvisioning;

      let lastValidCredentials = credentials;

      return new Promise<QueryResult>((resolve, reject) => {
        let columns: ResultColumn[] | undefined;
        const rows: Row[] = [];
        const rawData: string[] = [];

        setLoading(true);

        const { completion, abort } = streamingQuery({
          http,
          sql,
          runId,
          serviceId,
          variables: options.variables,
          connectionCredentials: credentials,
          onColumns: async (cols) => {
            columns = cols;
          },
          onData: async (row) => {
            rows.push(row);
          },
          onRawData: async (data) => {
            rawData.push(data);
            return Promise.resolve();
          },
          onEnd: async () => {
            if (!columns && isSelectStatement(sql)) {
              reject(new Error('No columns received!'));
            } else if (columns) {
              const data = { rows, columns, runId, rawData };
              if (currentRunId.current === runId) {
                setData(data);
              }
              resolve(data);
            } else {
              resolve({ rows: [], columns: [], runId });
            }
            if (currentRunId.current === runId) {
              setLoading(false);
            }
            cancelQueryRef.current = async (): Promise<void> => {};
          },
          onError: async (e, context) => {
            if (e.includes(PASSWORD_CREDENTIAL_ERROR)) {
              await new Promise<Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>>(
                (resolve, reject) => {
                  setCredentialModalOpen(true, { resolve, reject });
                }
              )
                .then((newCredentials) => {
                  lastValidCredentials = {
                    ...newCredentials,
                    database: selectedDatabase,
                    connected: false,
                    type: 'password'
                  };

                  // call runQuery with new credentials as override so the user doesn't need to
                  // enter credentials again
                  return runQuery(
                    sql,
                    {
                      ...newCredentials,
                      connected: true,
                      database: selectedDatabase,
                      type: 'password'
                    },
                    {
                      ...options,
                      wakeService: true
                    }
                  );
                })
                .then(resolve)
                .catch((error): void => {
                  resolve({ error: errorMessage(error), type: 'server' });
                });
            } else {
              if (currentRunId.current === runId) {
                setError(e);
              }
              resolve({
                error: e,
                type: context.type
              });
              cancelQueryRef.current = async (): Promise<void> => {};
            }
          },
          onMessage: async () => {},
          onMetrics: async () => {},
          onTotals: async () => {},
          onStatus: async () => {},
          onProgress: async () => {},
          orgId,
          skipConnectionInit,
          wakeService: forceAwakeService,
          isStopped
        });

        cancelQueryRef.current = abort;
        void completion.then((res) => {
          if (res.wakeServiceConfirmation) {
            return new Promise((resolve, reject) => {
              setWakeServiceModalOpen(true, skipConnectionInit ? 'test these credentials' : '', { resolve, reject });
            })
              .then(() => {
                return runQuery(sql, credentials, { ...options, wakeService: true });
              })
              .then((result) => resolve(result))
              .catch((e) => reject(e));
          } else if (!credentials.connected) {
            updateCredentials({
              ...lastValidCredentials,
              connected: true
            });
          }
        });
      });
    },
    [
      http,
      orgId,
      setCredentialModalOpen,
      selectedDatabase,
      skipConnectionInit,
      setWakeServiceModalOpen,
      isStopped,
      isAwaking,
      isStarting,
      isProvisioning,
      currentServiceId
    ]
  );

  return [runQuery, { error, loading, data }, cancelQueryRef];
}

export type SqlQueryFunction = (sql: string, options?: RunQueryOptions) => Promise<QueryResult>;

export type UseSqlQueryFunctionHook = [
  SqlQueryFunction,
  {
    error?: string;
    loading: boolean;
    data?: ResultData;
  },
  MutableRefObject<() => void>
];

/**
 * run query function hook. returns a function that runs a sql query using the cached credentials so that
 * the component doesn't need to access the state manually
 */
export function useSqlQueryFunction(): UseSqlQueryFunctionHook {
  const credentials = useCredentials();
  const [runQuery, status, cancelQueryRef] = useRawSqlQueryFunction();

  const queryFn = useCallback(
    (sql: string, options?: RunQueryOptions) => {
      return runQuery(sql, credentials, options);
    },
    [credentials, runQuery]
  );

  return [queryFn, status, cancelQueryRef];
}

interface CredentialsOverride {
  database: string;
  serviceId: string;
}

/**
 * hook to let a component subscribe to the results of a sql query
 * provides a reload function and access to the status and results of the sql query
 */
export function useSqlQuery(
  sql: string,
  variables?: Record<string, string>,
  runImmediately = true,
  credentialsOverride?: CredentialsOverride
): QueryResults {
  const [runQuery, { error, loading, data }] = useRawSqlQueryFunction();
  const [memoizedCredentialsOverride, setMemoizedCredentialsOverride] = useState(credentialsOverride);
  const systemCredentials = useCredentials();

  useEffect(() => {
    if (!isEqual(memoizedCredentialsOverride, credentialsOverride)) {
      setMemoizedCredentialsOverride(credentialsOverride);
    }
  }, [credentialsOverride, memoizedCredentialsOverride]);

  const reload = useCallback(async () => {
    await runQuery(
      sql,
      {
        ...systemCredentials,
        ...memoizedCredentialsOverride
      },
      variables
    );
  }, [runQuery, sql, variables, memoizedCredentialsOverride, systemCredentials]);

  const lastRunImmediately = useRef(false);

  useEffect(() => {
    if (runImmediately && runImmediately !== lastRunImmediately.current) {
      lastRunImmediately.current = runImmediately;
      void reload();
    }
  }, [reload, runImmediately]);

  return [
    reload,
    {
      error,
      loading,
      data
    }
  ];
}

interface Props {
  connectionCredentials?: PasswordCredential;
  skipConnectionInit?: boolean;
  sql: string;
  options?: RunQueryOptions;
}

interface QueryRunnerArgs {
  credentialsOverride?: CredentialsOverride;
}
export type QueryRunner = (queryRunnerArgs?: QueryRunnerArgs) => Promise<QueryResult>;

/**
 * hook to let a component run a sql query, while overriding credentials if needed
 * prefer this hook over `useSqlQuery` if the component knows exactly when it wants to run the query in question
 */
export function useQueryRunner({
  connectionCredentials = undefined,
  skipConnectionInit = false,
  sql,
  options
}: Props): QueryRunner {
  const systemCredentials = useCredentials();

  const [runQuery] = useRawSqlQueryFunction({ skipConnectionInit });

  return async ({ credentialsOverride } = {}): Promise<QueryResult> => {
    const credential = connectionCredentials ? validatePasswordCredential(connectionCredentials) : systemCredentials;
    return runQuery(
      sql,
      {
        ...credential,
        ...credentialsOverride
      },
      options
    );
  };
}
