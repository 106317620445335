import { HTMLAttributes, ReactElement } from 'react';
import { Container, Separator, Spacer } from '@clickhouse/click-ui';
import { formElementStyle } from 'src/components/ChartConfigSidebar/styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isFirst: boolean;
}
function FormElement({ isFirst, ...props }: Props): ReactElement {
  return (
    <Container orientation="vertical" gap="sm">
      {!isFirst && <Separator size="md" />}
      <div css={formElementStyle} {...props} />
    </Container>
  );
}

export default FormElement;
