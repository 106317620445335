import { Panel, Icon, Text, PanelProps } from '@clickhouse/click-ui';
import { ReactElement, ReactNode } from 'react';

interface Props extends Partial<PanelProps> {
  text?: ReactNode;
}
const LoadingPanel = ({
  text,
  hasBorder = true,
  ...props
}: Props): ReactElement => {
  return (
    <Panel hasBorder={hasBorder} alignItems="center" {...props}>
      <Icon name="horizontal-loading" size="lg" />
      {text && (
        <Text size="md" color="muted">
          {text}
        </Text>
      )}
    </Panel>
  );
};

export default LoadingPanel;
