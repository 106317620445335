import { Dispatch, useCallback, useRef, useState } from 'react';
import useSupportAttachment, { SupportAttachmentReturnType } from 'src/support/controller/useSupportAttachment';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { SetStateAction } from 'jotai';

interface ReplyResponseType extends Omit<SupportAttachmentReturnType, 'uploadFiles' | 'downloadAttachment'> {
  reply: string;
  setReply: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  sendReplyEvent: () => Promise<void>;
  fileList: FileList | null;
  onAttachmentChange: (files: FileList) => Promise<void>;
}

function useCreateReplyEvent(caseId: string): ReplyResponseType {
  const [reply, setReply] = useState('');
  const organization = useCurrentOrganizationOrThrow();
  const [isLoading, setLoading] = useState(false);
  const storageIds = useRef<Array<string>>([]);
  const api = useApiClient();
  const [fileList, setFileList] = useState<FileList | null>(null);
  const { progress, status, uploadFiles } = useSupportAttachment();
  const sendReplyEvent = useCallback(async () => {
    await api.support.reportCaseEvent(
      organization.id,
      caseId,
      'reply',
      reply,
      'WAITING_ON_CLICKHOUSE',
      storageIds.current
    );
  }, [api.support, organization.id, caseId, reply]);

  const onAttachmentChange = useCallback(
    async (files: FileList) => {
      setLoading(true);
      const fileItems = new DataTransfer();
      setFileList(files);
      try {
        const [uploadFileList, ids] = await uploadFiles(files, caseId);
        uploadFileList.forEach((file) => fileItems.items.add(file));
        storageIds.current = ids;
        setFileList(fileItems.files);
      } finally {
        setLoading(false);
      }
    },
    [uploadFiles]
  );

  return {
    reply,
    setReply,
    isLoading,
    sendReplyEvent,
    fileList,
    progress,
    status,
    onAttachmentChange
  };
}
export default useCreateReplyEvent;
