import {
  Container,
  Pagination,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
  Title
} from '@clickhouse/click-ui';
import { ComponentOffsetPaginationParams } from '@cp/common/protocol/Pagination';
import { ReactElement, ReactNode, memo } from 'react';
import LoadingPanel from 'src/components/LoadingPanel/LoadingPanel';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import { SortFn } from 'src/pages/OrgAuditPage/useFilterOrgActivities';

interface Props {
  isLoading: boolean;
  filteredRows: Array<TableRowType>;
  headers: Array<TableHeaderType>;
  onSort: SortFn;
  rowCount: number;
  pagination?: ComponentOffsetPaginationParams;
}

const Wrapper = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <MaxWidthContainer
      gap="md"
      padding="lg"
      orientation="vertical"
      alignItems="start"
      grow="1"
    >
      {children}
    </MaxWidthContainer>
  );
};

const OrgAudit = memo(
  ({
    isLoading,
    headers,
    filteredRows,
    onSort,
    rowCount,
    pagination
  }: Props): ReactElement => {
    if (isLoading) {
      return (
        <Wrapper>
          <LoadingPanel padding="xl" text="Loading activities" fillWidth />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Title type="h2" size="sm">
          {rowCount} activities
        </Title>
        <Table headers={headers} rows={filteredRows} onSort={onSort} />
        {pagination && (
          <>
            <Spacer size="sm" />
            <Container padding="sm" data-testid="pagination">
              <Pagination
                currentPage={pagination.currentPage}
                onChange={pagination.onPageChange}
                totalPages={pagination.totalPages}
                rowCount={rowCount}
                pageSize={pagination.limit}
                maxRowsPerPageList={pagination.maxRowsPerPageList}
                onPageSizeChange={pagination.onPageSizeChange}
              />
            </Container>
          </>
        )}
      </Wrapper>
    );
  }
);

export default OrgAudit;
