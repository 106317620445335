// New and not sorted utility methods.

import type { WithId } from '@cp/common/utils/ProtocolUtils';

/**
 * Returns message field value of the error object thrown by a request to server.
 * Server errors looks like: {error:{message: string}}.
 * Used in catch(e: unknown) cases.
 * Returns undefined if server error message can't be parsed.
 */
export function getServerErrorMessage(errorObject: unknown): string | undefined {
  if (typeof errorObject !== 'object') {
    return undefined;
  }
  const { error } = errorObject as { error?: unknown };
  if (!error || typeof error !== 'object') {
    return undefined;
  }
  const { message } = error as { message?: unknown };
  return typeof message === 'string' ? message : undefined;
}

export function parseCognitoErrorString(str?: string): string | undefined {
  if (typeof str !== 'string') return str;
  return str.replace(/.* ([^.]*)\./g, '$1');
}

export function getCognitoErrorCode(errorObject: unknown): string | undefined {
  if (typeof errorObject !== 'object') {
    return undefined;
  }
  const error = errorObject as { code?: string; message?: string };
  return error && error.code === 'UserLambdaValidationException' ? parseCognitoErrorString(error.message) : error?.code;
}

/** Returns the normalized version of the email string by trimming the string and converting to lower case. */
export function normalizeEmail(email: string): string {
  return email?.trim().toLocaleLowerCase();
}

export function getInitials(name: string): string {
  return name
    .trim()
    .replace(/(^.)([^ ]* )?(.).*/, '$1$3')
    .trim()
    .toUpperCase();
}

export function getEmailPrefix(email: string): string {
  return email.replace(/@.*$/, '').trim();
}

/** Returns true if 2 sets are equals. */
export function checkSetsEquality<T>(set1: Set<T>, set2: Set<T>): boolean {
  if (set1.size !== set2.size) {
    return false;
  }
  for (const key of set1) {
    if (!set2.has(key)) {
      return false;
    }
  }
  return true;
}

/** Returns is the array is sorted. */
export function checkArrayIsSorted<T>(array: Array<T>, comparator: (e1: T, e2: T) => number): boolean {
  for (let i = 1; i < array.length; i++) {
    if (comparator(array[i - 1], array[i]) > 0) return false;
  }
  return true;
}

export function checkArrayHasUniqueElements<T>(array: Array<T>, identity: (e: T) => string): boolean {
  if (array.length <= 1) return true;
  const set = new Set<string>();
  for (const e of array) {
    const id = identity(e);
    if (set.has(id)) return false;
    set.add(id);
  }
  return true;
}

/** Returns true if 2 arrays are equals. Use === operator to compare array elements. */
export function checkArraysEquality<T>(array1: Array<T> | undefined, array2: Array<T> | undefined | null): boolean {
  return checkArraysEqualityWithComparator(array1, array2, (e1, e2) => e1 === e2);
}

/** Returns true if 2 arrays are equals. Use comparator function to compare array elements. */
export function checkArraysEqualityWithComparator<T>(
  array1: Array<T> | undefined | null,
  array2: Array<T> | undefined | null,
  comparator: (e1: T, e2: T) => boolean
): boolean {
  if (array1 === array2) return true;
  if (!array1 || !array2) return false;
  if (array1.length !== array2.length) return false;

  for (let i = 0; i < array1.length; i++) {
    if (!comparator(array1[i], array2[i])) {
      return false;
    }
  }
  return true;
}

/**
 * Returns true if values are equal.
 * Uses direct comparison for primitive types or checkObjectEqualityByShallowCompare for object types.
 */
export function checkValuesEquality(value1: unknown, value2: unknown): boolean {
  if (value1 === value2) return true;
  if (!value1 || !value2) return false;
  const value1Type = typeof value1;
  const value2Type = typeof value2;
  if (value1Type !== value2Type) return false;
  if (value1Type !== 'object') return false;

  return checkObjectEqualityByShallowCompare(value1, value2);
}

/**
 * Returns true if objects have equal fields. Use === operator to compare fields.
 * If both objects are arrays calls 'checkArraysEquality'.
 */
export function checkObjectEqualityByShallowCompare<T extends object>(
  object1: T | undefined | unknown,
  object2: T | undefined | unknown
): boolean {
  if (object1 === object2) return true;
  if (!object1 || !object2) return false;
  if (Array.isArray(object1)) {
    return Array.isArray(object2) && checkArraysEquality(object1, object2);
  }

  if (typeof object1 !== 'object' || typeof object2 !== 'object') {
    return false;
  }

  const entries1 = Object.entries(object1);
  const entries2 = Object.entries(object2);
  if (entries1.length !== entries1.length) {
    return false;
  }
  entries1.sort((e1, e2) => (e1[0] < e2[0] ? 1 : -1));
  entries2.sort((e1, e2) => (e1[0] < e2[0] ? 1 : -1));
  for (let i = 0; i < entries1.length; i++) {
    if (entries1[i][0] !== entries2[i][0] || entries1[i][1] !== entries2[i][1]) {
      return false;
    }
  }
  return true;
}

/** Converts array into Record using {id} field. */
export function convertArrayToRecord<V extends WithId>(array: Array<V>): Record<string, V> {
  const result: Record<string, V> = {};
  for (const v of array) {
    result[v.id] = v;
  }
  return result;
}

/** Converts array into Record of grouped array using groupKey() function. */
export function convertArrayToRecordArray<V>(array: Array<V>, groupKey: (v: V) => string): Record<string, Array<V>> {
  const result: Record<string, Array<V>> = {};
  for (const v of array) {
    const key = groupKey(v);
    let arrayValue: Array<V> = result[key];
    if (!arrayValue) {
      arrayValue = [];
      result[key] = arrayValue;
    }
    arrayValue.push(v);
  }
  return result;
}

/** Value provider function used internally in getOrSetDefault. */
type ValueProvider<T> = () => T;

/**
 * Looks up a value in the map using 'key' and return it.
 * If the key is not in the map adds 'defaultValue' to the map for the given key and returns it.
 */
export function getOrSetDefault<Key, Value>(
  map: Map<Key, Value>,
  key: Key,
  defaultValue: Value | ValueProvider<Value>
): Value {
  let result = map.get(key);
  if (result === undefined && !map.has(key)) {
    // The value may be 'undefined', run extra check to handle this case.
    result = typeof defaultValue === 'function' ? (defaultValue as ValueProvider<Value>)() : defaultValue;
    map.set(key, result);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: if map contains 'undefined' the result may be 'undefined'
  return result;
}

/** Used by 'groupByKey' to store original array position of the element in group. */
export interface GroupElementInfo<T> {
  /** Index of the value in the original array. */
  index: number;
  /** The value. */
  value: T;
}

/** Returns map of arrays by group key. Each element in the array has the same group key and info about the position in the array. */
export function groupByKey<K extends string, V>(
  array: Array<V>,
  keyProvider: (t: V) => K
): Map<K, Array<GroupElementInfo<V>>> {
  const result = new Map<K, Array<GroupElementInfo<V>>>();
  for (let i = 0; i < array.length; i++) {
    const value = array[i];
    const key = keyProvider(value);
    getOrSetDefault(result, key, () => []).push({ index: i, value });
  }
  return result;
}

/**
 * Keeps only unique elements in the array.
 * Uses checkEquals() to check if two elements are equal and pickOne() to select the result element from the set of equal elements.
 * pickOne() always receives the first element with a smaller index in the original array than the second as arguments.
 */
export function keepUniqueElements<T>(
  arrayWithNonUniqueElements: Array<T>,
  checkEquals: (t1: T, t2: T) => boolean = (t1, t2): boolean => t1 === t2,
  pickOne: (t1: T, t2: T) => T = (t1): T => t1
): Array<T> {
  const result: Array<T> = [];
  for (const e of arrayWithNonUniqueElements) {
    let isUnique = true;
    for (let i = 0; i < result.length; i++) {
      if (checkEquals(result[i], e)) {
        result[i] = pickOne(result[i], e);
        isUnique = false;
        break;
      }
    }
    if (isUnique) {
      result.push(e);
    }
  }
  return result;
}

/**
 * Returns an array containing chunk-sized arrays of the originalArray. The last chunk could be smaller than chunkSize.
 * For example: breakArrayIntoFixedSizeChunks(['a', 'b', 'b', 'c', 'c', 'c', 'd', 'd', 'd', 'd'], 3) will return [['a', 'b', 'b'], ['c', 'c', 'c'], ['d', 'd', 'd'], ['d']]
 */
export function breakArrayIntoFixedSizeChunks<T>(originalArray: Array<T>, chunkSize: number): Array<Array<T>> {
  const resultArray: Array<Array<T>> = [];
  for (let chunkIndex = 0; chunkIndex * chunkSize < originalArray.length; chunkIndex++) {
    resultArray[chunkIndex] = originalArray.slice(chunkIndex * chunkSize, chunkIndex * chunkSize + chunkSize);
  }

  return resultArray;
}

/**
 * Returns an array of length numberOfChunks containing elements of the originalArray evenly distributed through the
 * chunks.
 *
 * If the numberOfChunks is smaller than the size of originalArray, each chunk will contain more than one item.
 *
 * If the numberOfChunks is larger than the size of originalArray, some chunks could be empty.
 * If you'd prefer to filter them out, set removeEmptyChunks to true.
 *
 * See the unit tests for examples of inputs and outputs
 */
export function breakArrayIntoFixedNumberOfChunks<T>(
  originalArray: Array<T>,
  numberOfChunks: number,
  removeEmptyChunks: boolean = false
): Array<Array<T>> {
  const chunkSize = originalArray.length / numberOfChunks;
  let resultArray: Array<Array<T>> = [];
  for (let chunkIndex = 0; chunkIndex < numberOfChunks; chunkIndex++) {
    const startIndex = chunkIndex * chunkSize;
    /** If this is the last chunk, dump the rest of the array into it. */
    const endIndex = chunkIndex >= numberOfChunks - 1 ? originalArray.length : startIndex + chunkSize;
    resultArray[chunkIndex] = originalArray.slice(startIndex, endIndex);
  }

  if (removeEmptyChunks) {
    resultArray = resultArray.filter((c) => c.length > 0);
  }
  return resultArray;
}

/**
 * Introduce Array.prototype.map-like functionality to objects.
 * Useful for mixing two instances of similar objects into one.
 * Especially handy when converting mongoose objects since it allows us to hide all the getters and only get the properties.
 *
 * @param originalObject The object used as the basis for mapping.
 * @param fn A transformation function that accepts the value and key from originalObject and returns the new value for the same key on the transformed object
 */
export function mapObject<T extends object, K extends keyof T>(
  originalObject: T,
  fn: (value: T[K], key: K) => T[K]
): T {
  const entries: Array<[K, T[K]]> = Object.entries(originalObject).map((entry) => [
    entry[0] as K,
    fn(entry[1], entry[0] as K)
  ]);

  return Object.fromEntries(entries) as unknown as T;
}

export function parseStringToBooleanCaseInsensitive(booleanStr: string | undefined): boolean | undefined {
  if (booleanStr === undefined) {
    return undefined;
  }
  switch (booleanStr.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
  }
  return undefined;
}

/** Returns url with query part removed. */
export function getPathWithNoQueryPartFromUrl(url: string): string {
  return url.split('?')[0];
}

/** Returns query part (with ? symbol) from the url. Returns empty string if there is no query part. */
export function getQueryPartFromUrl(url: string): string {
  const questionMarkIndex = url.indexOf('?');
  return questionMarkIndex === -1 ? '' : url.substring(questionMarkIndex);
}

/**
 * Mark a given Pii (Personal Identifiable Information) as deleted
 * (as requested by the compliance team)
 */
export function markPiiItemAsDeleted(item: string): string {
  return `${item}_deleted`;
}

export function unmarkPiiItemAsDeleted(item: string): string {
  return item.split('_deleted')[0];
}

/**
 * Common data type for partial arrays update.
 * Example: update of IP access list.
 * 'Remove' is executed first. Next 'Add' is executed.
 */
export interface ArrayPatch<T = unknown> {
  add?: Array<T>;
  remove?: Array<T>;
}

/**
 * Patches array. First removes all 'remove' elements. Next adds all 'add' elements from the patch.
 * Always returns a new array.
 */
export function patchArray<T>(
  array: Array<T>,
  { remove, add }: ArrayPatch<T>,
  comparator: (e1: T, e2: T) => boolean = (e1, e2): boolean => e1 === e2
): Array<T> {
  let result = [...array];
  if (isEmptyArrayPatch({ add, remove })) {
    return result;
  }
  result = remove ? array.filter((e1) => !remove.some((e2) => comparator(e1, e2))) : array;
  if (add) {
    result.push(...add);
  }
  return result;
}

export function isEmptyArrayPatch({ add, remove }: ArrayPatch): boolean {
  return (add === undefined || add.length === 0) && (remove === undefined || remove.length === 0);
}

export function isEmptyRecord(record: object): boolean {
  return Object.keys(record).length === 0;
}

/** Returns true if the object has at least one field !== undefined. */
export function hasDefinedFields(record: object): boolean {
  const entries = Object.entries(record);
  return entries.length > 0 && entries.some((e) => e[1] !== undefined);
}

/** Format the clickhouse versions stored in the instance object. */
export function formatClickHouseVersion(clickhouseVersion: string): string {
  return clickhouseVersion === 'head' ? 'Latest' : clickhouseVersion === '<unknown>' ? '' : `v${clickhouseVersion}`;
}

/** Deletes all top-level fields from the object that are undefined. */
export function deleteTopLevelUndefinedProperties<T extends object>(object: T): T {
  for (const key of Object.keys(object) as Array<keyof T>) {
    if (object[key] === undefined) {
      delete object[key];
    }
  }
  return object;
}
