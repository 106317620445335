import { ReactElement } from 'react';
import ChartWrapper from 'src/components/primitives/lib/Charts/ChartWrapper';
import { HeatmapChart } from '@clickhouse/viz-house';
import {
  convertToSeriesAndCategories,
  VizHouseHeatmapChartProps
} from 'src/components/primitives/lib/Charts/VizHouseHeatmapChartUtils';

const VizHouseHeatmapChart = (
  props: VizHouseHeatmapChartProps
): ReactElement => {
  const {
    footer,
    editable = false,
    subtitle,
    title,
    xAxisTickVertical = false,
    xAxisLabel,
    yAxisLabel,
    showDataLabel
  } = props;

  const { series, categories } = convertToSeriesAndCategories(props);

  return (
    <ChartWrapper
      title={title}
      subtitle={subtitle}
      footer={footer}
      editable={editable}
    >
      <HeatmapChart
        series={series}
        isLoading={false}
        xAxis={{
          title: xAxisLabel,
          verticalLabels: xAxisTickVertical
        }}
        yAxis={{
          categories,
          reversed: true,
          title: yAxisLabel
        }}
        hasDataLabels={showDataLabel}
      />
    </ChartWrapper>
  );
};

VizHouseHeatmapChart.displayName = 'HeatmapChart';

export default VizHouseHeatmapChart;
