import { useCallback } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useOnboardStateManager } from 'src/onboard/onboardState';

type ReturnType = {
  setPostOnboardingPendingActions: () => Promise<void>;
  setPostOnboardingGettingStarted: () => Promise<void>;
};

export const useOnboardController = (): ReturnType => {
  const apiClient = useApiClient();
  const { skipOnboarding } = useOnboardStateManager();

  const setPostOnboardingPendingActions = useCallback(async () => {
    try {
      await apiClient.updateUserDetails({
        pendingActionTypesToRemove: ['onboarding'],
        pendingActionsToAdd: [{ type: 'entry-questionnaire' }, { type: 'getting-started' }]
      });
      skipOnboarding();
    } catch (e) {
      console.error('Error while cleaning up onboarding and entry questionnaire action');
    }
  }, [apiClient, skipOnboarding]);

  const setPostOnboardingGettingStarted = useCallback(async () => {
    try {
      await apiClient.updateUserDetails({
        pendingActionTypesToRemove: ['onboarding'],
        pendingActionsToAdd: [{ type: 'getting-started' }]
      });
      skipOnboarding();
    } catch (e) {
      console.error('Error while cleaning onboarding action');
    }
  }, [apiClient, skipOnboarding]);

  return {
    setPostOnboardingPendingActions,
    setPostOnboardingGettingStarted
  };
};
