import { Button, CodeBlock, Container } from '@clickhouse/click-ui';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

export interface ExpandableCodeBlockProps {
  contents: string;
  language?: string;
  showLineNumbers?: boolean;
  wrapLines?: boolean;
}

const CustomCodeBlock = styled(CodeBlock)<{
  $isExpanded: boolean;
}>`
  & + button {
    display: none;
  }

  ${({ $isExpanded }) => `
    ${
      $isExpanded
        ? `
          &:has(> pre > code > span:nth-child(6)) {
            & + button {
              display: flex;
            }
          }
        `
        : `
          & > pre > code > span:nth-child(5) ~ span {
            display: none !important;
          }
          &:has(> pre > code > span:nth-child(6)) {
            & > pre > code {
              mask-image: linear-gradient(to bottom, white 50%, transparent);
            }
            & + button {
              display: flex;
            }
          }
        `
    }
  `}
`;
export const ExpandableCodeBlock = ({
  contents,
  language = 'sql',
  showLineNumbers = false,
  wrapLines = false
}: ExpandableCodeBlockProps): ReactNode => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Container
      orientation="vertical"
      padding="none"
      alignItems="center"
      gap="sm"
    >
      <CustomCodeBlock
        $isExpanded={isExpanded}
        language={language}
        showLineNumbers={showLineNumbers}
        wrapLines={wrapLines}
      >
        {`${contents}`}
      </CustomCodeBlock>
      <Button
        iconRight={isExpanded ? 'chevron-up' : 'chevron-down'}
        label={isExpanded ? 'Collapse' : 'Expand'}
        onClick={(): void => {
          setIsExpanded(!isExpanded);
        }}
        type="secondary"
      />
    </Container>
  );
};
