import { ReactElement, useEffect, useRef, useState } from 'react';
import { createToast } from 'primitives';
import { useParams } from 'react-router-dom';

import QueryView from 'src/components/QueryView';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { useSelectedTab, useTabActions } from 'src/state/tabs';
import Helmet from 'src/components/Helmet/Helmet';

const QueryPage = (): ReactElement | null => {
  const { addNewQueryTab, addQueryTab } = useTabActions();
  const { queryId } = useParams();
  const tabFoundRef = useRef(false);
  const selectedTab = useSelectedTab();
  const savedQueries = useSavedQueries();
  const { selectQuery } = useTabActions();
  const [initialQueryId, setInitialQueryId] = useState<
    string | undefined | null
  >(queryId);

  useEffect(() => {
    // Remember the queryId from the URL when the page is open.
    // Loading tabs will update the queryId in the URL, but we need to remember the initial queryId
    // in order to open the correct tab when the saved queries are loaded.
    setInitialQueryId(queryId);
  }, []);

  useEffect(() => {
    if (!savedQueries.loadError) {
      return;
    }
    createToast(
      'Error',
      'alert',
      `Error loading saved queries: ${savedQueries.loadError}`
    );
  }, [savedQueries.loadError]);

  useEffect(() => {
    if (
      savedQueries.loadingState !== 'done' ||
      savedQueries.loadError ||
      !queryId
    ) {
      // If saved queries are not loaded, or there is an error, or there is no queryId in the URL, do nothing
      return;
    }

    const query = savedQueries.getQueryById(queryId);
    if (initialQueryId && queryId !== initialQueryId) {
      // If the queryId in the URL has changed while the tabs were loading,
      // then we should load the query with the initial queryId.
      const initialQuery = savedQueries.getQueryById(initialQueryId);
      if (initialQuery) {
        selectQuery(initialQuery);
      }
    } else if (!tabFoundRef.current && !selectedTab && query) {
      addQueryTab(query);
    } else if (!tabFoundRef.current && !selectedTab) {
      addNewQueryTab({
        type: 'query',
        id: queryId,
        saved: false,
        title: 'Untitled query'
      });
    }
    setInitialQueryId(null); // Do not need it anymore
  }, [
    addNewQueryTab,
    addQueryTab,
    initialQueryId,
    queryId,
    savedQueries,
    savedQueries.getQueryById,
    savedQueries.loadError,
    savedQueries.loadingState,
    savedQueries.savedQueries,
    selectQuery,
    selectedTab
  ]);

  useEffect(() => {
    if (selectedTab) {
      tabFoundRef.current = true;
    }
  }, [selectedTab]);

  if (selectedTab?.type !== 'query') {
    return null;
  } else {
    return (
      <>
        <Helmet>
          <title>{selectedTab.title}</title>
        </Helmet>
        <QueryView key={selectedTab.id} tab={selectedTab} />
      </>
    );
  }
};

export default QueryPage;
