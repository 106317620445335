import {
  Button,
  Container,
  Icon,
  Link,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import PlusIcon from 'src/components/icons/PlusIcon';

interface NoKeysProps {
  openCreateKeyModal: () => void;
}

export default function NoKeys({
  openCreateKeyModal
}: NoKeysProps): ReactElement {
  return (
    <Container
      alignItems="center"
      justifyContent="center"
      orientation="vertical"
      fillWidth={false}
    >
      <Container
        alignItems="center"
        gap="md"
        orientation="vertical"
        fillWidth={false}
      >
        <Icon
          name="keys"
          size="xxl"
          strokeWidth="0.5"
          vectorEffect="non-scaling-stroke"
        />
        <Title type="h4">Create your first API key</Title>
        <Text align="center">
          Manage your ClickHouse Cloud service using APIs. Includes
          <br />
          services, users, network access and scaling information.{' '}
          <Link
            href="https://clickhouse.com/docs/en/cloud/manage/openapi"
            target="_blank"
          >
            Read more
          </Link>
        </Text>
        <Text>Does not include database access.</Text>
        <Button onClick={openCreateKeyModal}>
          <PlusIcon /> New API key
        </Button>
      </Container>
    </Container>
  );
}
