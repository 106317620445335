import { FlyoutProps } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

import ResizableFlyout from 'src/components/RightBar/ResizableFlyout';
import { prettyPrintJson } from 'src/components/RightBar/PrettyPrintJson';

interface CellInspectorModalProps extends FlyoutProps {
  value: string;
  container?: HTMLElement | null;
}

const CellInspectorModal = ({
  value,
  container,
  ...props
}: CellInspectorModalProps): ReactElement => {
  const [code, language] = prettyPrintJson(value);

  return (
    <ResizableFlyout header="Cell Inspector" container={container} {...props}>
      <ResizableFlyout.Code
        language={language}
        successMessage="Cell content copied successfully"
        successHeader="Cell Content copied"
        className="cell-inspect-modal fs-exclude"
      >
        {code}
      </ResizableFlyout.Code>
    </ResizableFlyout>
  );
};

export default CellInspectorModal;
