import {
  ButtonGroup,
  Container,
  Icon,
  Text,
  useCUITheme
} from '@clickhouse/click-ui';
import {
  TimeSeriesChart,
  AxisLabelsFormatterContext,
  TimeSeriesSeriesDescriptor
} from '@clickhouse/viz-house';
import {
  OrganizationUsagePeriodChart,
  UsageMetric
} from '@cp/common/protocol/Billing';
import { ChartDataSeries } from '@cp/common/protocol/Metrics';
import { truthy } from '@cp/common/utils/Assert';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { ReactElement, useState } from 'react';
import { formatDateShort } from 'src/lib/formatters/dateTimeFormatter';
import { useOrgFeature, useUserFeature } from 'src/lib/features';
import { formatDataSize } from 'src/lib/formatters/numberFormatter';

export type UsageChartProps = {
  metric: UsageMetric;
  onChangeMetricType: (metric: UsageMetric) => void;
  instanceColors: Map<string, string>;
  status: 'OK' | 'ERROR' | 'LOADING';
  usageData: OrganizationUsagePeriodChart;
};

const UsageChart = ({
  metric,
  onChangeMetricType,
  instanceColors,
  status,
  usageData
}: UsageChartProps): ReactElement | null => {
  const [selectedMetric, setSelectedMetric] = useState<UsageMetric>(metric);

  const hasVizHouseCharts = useUserFeature(
    'FT_USER_USAGE_PAGE_VIZ_HOUSE_CHARTS'
  );
  const displayValueAsCost = useOrgFeature(
    'FT_ORG_DISPLAY_COST_IN_USAGE_CHART'
  );

  const onMetricChange = (newMetric: UsageMetric): void => {
    setSelectedMetric(newMetric);
    onChangeMetricType(newMetric);
  };

  const chartData =
    status === 'OK'
      ? prepareChartComponentData(
          usageData,
          instanceColors,
          metric,
          displayValueAsCost
        )
      : undefined;
  if (status === 'ERROR') {
    return <ErrorInfo />;
  }
  if (status === 'LOADING') {
    return <LoadingInfo />;
  }
  if (status === 'OK' && chartData !== undefined) {
    const options = [
      {
        label: 'Compute',
        value: 'COMPUTE_UNIT_MINUTES'
      },
      {
        label: 'Storage',
        value: 'STORAGE_GB_MINUTES_TABLES'
      },
      {
        label: 'Backups',
        value: 'STORAGE_GB_MINUTES_PAID_BACKUPS'
      }
    ];
    return (
      <>
        <Container
          orientation="vertical"
          padding="none"
          alignItems="end"
          fillWidth
        >
          <ButtonGroup
            onClick={(newMetric: string): void =>
              onMetricChange(newMetric as UsageMetric)
            }
            options={options}
            selected={selectedMetric}
            type="default"
          />
        </Container>
        {!hasVizHouseCharts && <MemoedChart chartData={chartData} />}
        {hasVizHouseCharts && <VizHouseChart chartData={chartData} />}
      </>
    );
  } else {
    return null;
  }
};

const ErrorInfo = (): ReactElement => (
  <>
    <Text weight="semibold">These metrics cannot be shown at this time</Text>
    <Text color="muted">Please try again in a few minutes</Text>
  </>
);

const LoadingInfo = (): ReactElement => {
  const cuiTheme = useCUITheme();
  return (
    <Container
      orientation="vertical"
      fillWidth
      fillHeight
      gap="lg"
      padding="lg"
      alignItems="center"
      justifyContent="center"
      grow="4"
    >
      <Icon
        name="horizontal-loading"
        size="xxl"
        data-testid={'loading-spinner'}
        color={cuiTheme.global.color.text.default}
      />
    </Container>
  );
};

type ChartProps = {
  chartData: AdminUsageChartComponentData;
};

function sortUsageChartDataByInstanceName(
  seriesDataList: AdminUsageChartSeriesData[]
): void {
  seriesDataList.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    return 1;
  });
}

const Chart = ({ chartData }: ChartProps): ReactElement => {
  const series: Highcharts.SeriesColumnOptions[] = [];
  const seriesDataList = [...chartData.perInstanceData.values()];
  sortUsageChartDataByInstanceName(seriesDataList);
  const cuiTheme = useCUITheme();
  for (const seriesData of seriesDataList) {
    const seriesOptions: Highcharts.SeriesColumnOptions = { type: 'column' };
    seriesOptions.name = seriesData.name;
    seriesOptions.data = seriesData.data;
    seriesOptions.color = seriesData.color;
    seriesOptions.stacking = 'normal';
    series.push(seriesOptions);
  }

  const chartOptions: Highcharts.Options = {
    chart: {
      // top (to avoid cropping of y-axis text), right (no margin), bottom (space for x-axis text), left (space for y-axis text).
      margin: [10, 0, 30, 85],
      backgroundColor: cuiTheme.global.color.background.default,
      animation: false,
      numberFormatter: chartData.valueFormatter,

      spacingRight: 0,
      marginRight: 4,
      spacingLeft: 0,
      marginLeft: 70
    },
    // TODO: time: {useUTC: false},  // TODO: billing should not depend on the current user TZ. TODO to be fixed at https://github.com/ClickHouse/control-plane/issues/6225
    yAxis: {
      title: { text: null },
      gridLineWidth: 1,
      gridLineColor: cuiTheme.global.color.stroke.muted,
      lineColor: cuiTheme.global.color.stroke.default,
      labels: {
        formatter: ({ pos }: Highcharts.AxisLabelsFormatterContextObject) =>
          chartData.valueFormatter(pos),
        style: {
          color: cuiTheme.global.color.text.muted,
          fontSize: cuiTheme.sizes[4]
        }
      }
    },
    xAxis: {
      ...getXAxisDateTimeOptions(),
      min: chartData.startDate,
      max: chartData.endDate,
      gridLineColor: cuiTheme.global.color.stroke.muted,
      lineColor: cuiTheme.global.color.stroke.default,
      tickColor: cuiTheme.global.color.stroke.default,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: cuiTheme.global.color.text.muted,
          fontSize: cuiTheme.sizes[4]
        }
      }
    },
    plotOptions: {
      column: {
        maxPointWidth: 40,
        animation: false
      },
      series: {
        borderWidth: 0
      }
    },
    tooltip: {
      shared: true,
      pointFormat: `<span style="color:{point.color}">●</span> {series.name}: <span style="font-weight: 600">${chartData.valuePrefix}{point.y}</span>${chartData.unitName}<br/>`
    },

    title: { text: undefined }, // 'undefined' disables the title.
    credits: { enabled: false },
    legend: { enabled: false },
    series: series
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      immutable={true}
      containerProps={{ style: { width: '100%', height: '300px' } }}
    />
  );
};
/** performance hack to prevent rendering processing of a chart previously rendered*/
const MemoedChart = React.memo(Chart);

const PureVizHouseChart = ({ chartData }: ChartProps): ReactElement => {
  const seriesDataList = Array.from(chartData.perInstanceData.values());
  sortUsageChartDataByInstanceName(seriesDataList);
  const series: Array<TimeSeriesSeriesDescriptor> = [];
  for (const seriesData of seriesDataList) {
    series.push({
      type: 'column',
      name: seriesData.name,
      values: seriesData.data.map(([x, y]) => ({ x, y })),
      color: seriesData.color,
      fillColor: seriesData.color
    });
  }

  return (
    <TimeSeriesChart
      series={series}
      stacked
      height="300px"
      yAxis={{
        labelsFormatter: ({ pos }: AxisLabelsFormatterContext) =>
          chartData.valueFormatter(pos)
      }}
      numberFormatter={chartData.valueFormatter}
      xAxis={{
        labelsFormatter: (ctx) => formatDateShort(ctx.value),
        min: chartData.startDate,
        max: chartData.endDate
      }}
      highChartsPropsOverrides={{
        tooltip: {
          xDateFormat: null, // let it be determined by the automatic inference in highcharts
          pointFormat: `<tr><td><span style="color:{point.color}">●</span> {series.name}: </td><td style="text-align: right"><span style="font-weight: 600">${chartData.valuePrefix}>{point.y}${chartData.unitName}</span></td></tr>`
        }
      }}
    />
  );
};

const VizHouseChart = React.memo(PureVizHouseChart);
VizHouseChart.displayName = 'VizHouseChart';

/** Returns UI options for X-axis. */
function getXAxisDateTimeOptions(): Highcharts.XAxisOptions {
  return {
    title: { text: null },
    type: 'datetime',
    showEmpty: false,
    gridLineWidth: 1,
    // See https://api.highcharts.com/highstock/xAxis.dateTimeLabelFormats for defaults.
    // We have 'millis' rounded to 'seconds' to make 1-point mode
    // (when Highcharts can't detect the correct rounding interval) do not show millis at all.
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y'
    }
  };
}

/** Input of the admin chart component. */
export interface AdminUsageChartComponentData {
  startDate: number;
  endDate: number;
  valuePrefix: string;
  unitName: string;
  perInstanceData: Map<string, AdminUsageChartSeriesData>;
  /** Point value formatter on the chart: tooltip, y-axis. */
  valueFormatter: (value: number) => string;
}

/** Single chart series data. */
export interface AdminUsageChartSeriesData {
  name: string;
  color: string;
  data: ChartDataSeries;
  /** Values converted to units that conform to the website's pricing page. */
  websiteUnitData?: ChartDataSeries;
}

/** Prepares chart component data from the report data: adds UI options like colors and display texts */
function prepareChartComponentData(
  reportChartData: OrganizationUsagePeriodChart,
  instanceColors: Map<string, string>,
  metric: UsageMetric,
  displayValueAsCost: boolean
): AdminUsageChartComponentData {
  const unitNames: Record<UsageMetric, string> = {
    COMPUTE_UNIT_MINUTES: 'compute units',
    STORAGE_GB_MINUTES_TABLES: 'TB per month',
    STORAGE_GB_MINUTES_PAID_BACKUPS: 'TB per month'
  };

  return {
    startDate: Date.parse(reportChartData.startDate),
    endDate: Date.parse(reportChartData.endDateExclusive),
    valuePrefix: displayValueAsCost ? '$' : '',
    unitName: displayValueAsCost ? '' : ` ${unitNames[metric]}`,
    perInstanceData: Object.keys(reportChartData.perInstanceData).reduce(
      (map, instanceId) =>
        map.set(instanceId, {
          name: reportChartData.instanceNameById[instanceId] || instanceId,
          color: truthy(
            instanceColors.get(instanceId),
            `Instance ${instanceId} color not defined`
          ),
          data: truthy(
            reportChartData.websitePerInstanceData[instanceId],
            'Missing website data'
          )
        }),
      new Map<string, AdminUsageChartSeriesData>()
    ),
    valueFormatter: (value: number): string =>
      formatDataSize(
        value,
        displayValueAsCost ? 'cost-number' : 'human-readable-number'
      )
  };
}

export default UsageChart;
