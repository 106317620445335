import { createToast } from 'primitives';
import { useCallback, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';

type UseCancelDataImportReturn = {
  loading: boolean;
  cancelDataImport: () => Promise<void>;
};

type UseCancelDataImportArgs = {
  serviceId: string;
  dataImportId: string;
};
export function useCancelDataImport({ serviceId, dataImportId }: UseCancelDataImportArgs): UseCancelDataImportReturn {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);

  const cancelDataImport = useCallback(async () => {
    setLoading(true);
    try {
      await api.dataloading.cancelDataImport(dataImportId, serviceId);
    } catch (error) {
      console.error(error);
      createToast('Error', 'Failed to cancel data import', errorMessage(error));
    } finally {
      setLoading(false);
    }
  }, [api.dataloading, dataImportId, serviceId]);

  return {
    loading,
    cancelDataImport
  };
}
