import React, { createElement, useCallback, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { logger, InteractionType } from 'src/lib/logger';
import { useRunningQueries } from 'src/lib/query/QueryState';
import { useTabActions, useSelectedTabIndex, useSetTabs } from 'src/state/tabs';
import { QueryTab, Tab } from 'src/state/tabs/types';

import { RemoveTabModalProps } from 'src/components/App/MainView/TabBar/RemoveTabModal/types';
import { LogFn, LogType, MenuOption, StatusType } from 'src/components/App/MainView/TabBar/types';
interface RemoveTabOptions {
  closeModalProps: RemoveTabModalProps;
  getMenuOptions: (type: Tab['type'], index: number) => Array<MenuOption>;
  getStatus: (tab: Tab) => StatusType;
  closeCurrentTab: (index: number) => void;
  log: LogFn;
}

export const useRemoveTabs = (tabs: Tab[]): RemoveTabOptions => {
  const { closeTabs, setSelectedTab, useCloseTab } = useTabActions();
  const [closeModalProps, setCloseModalProps] = useState<RemoveTabModalProps>({});
  const closeTab = useCloseTab();

  const onCancel = (): void => setCloseModalProps({});

  const queryStatusList = useRunningQueries();
  const currentIndex = useSelectedTabIndex();

  const setTabs = useSetTabs();

  const getTabQueryStatus = useCallback(
    (tab: Tab): StatusType => {
      if (tab.type !== 'query' || !tab.queryRunId) {
        return null;
      }

      const status = queryStatusList[tab.queryRunId];
      if (status === 'running') {
        return 'running';
      }

      if (status === 'finished' || status === 'error') {
        return status;
      }

      return null;
    },
    [queryStatusList]
  );

  const getStatus = useCallback(
    (tab: Tab): StatusType => {
      if (tab.type !== 'query') {
        return null;
      }

      const { queryRunId: runId } = tab;

      const edited =
        tab.lastRunAt && tab.editedAt
          ? new Date(tab.lastRunAt) < new Date(tab.editedAt)
          : typeof tab.editedAt !== 'undefined';

      if (!runId) {
        return edited ? 'unsaved' : null;
      }

      const status = queryStatusList[runId];
      if (status === 'running') {
        return 'running';
      }

      if (edited) {
        return 'unsaved';
      }

      if (status === 'finished' || status === 'error') {
        return status;
      }

      return null;
    },
    [queryStatusList]
  );

  const closeCurrentTab = useCallback(
    (index: number): void => {
      const tab = tabs[index];
      const status = getStatus(tab);
      switch (status) {
        case 'running':
          setCloseModalProps({
            open: true,
            title: 'A query is running',
            content: 'Are you sure you want to close this tab? A query is running in this tab.',
            onCancel,
            onClick: () => closeTab(index)
          });
          break;
        case 'unsaved':
          setCloseModalProps({
            open: true,
            title: 'Unsaved query',
            content: createElement(
              'div',
              null,
              createElement(
                'span',
                {
                  className: 'dark'
                },
                `${tab.title ?? 'New tab'} has unsaved changes. `
              ),
              `Are you sure you want to close this tab?
              Your changes will be lost if you close this tab without saving it.`
            ),
            onCancel,
            onClick: () => closeTab(index)
          });
          break;
        default:
          closeTab(index);
          break;
      }
    },
    [tabs, getStatus, closeTab]
  );

  const closeTabsToRight = useCallback(
    (index: number) => {
      let newTabs = [...tabs];
      newTabs = newTabs.slice(0, index + 1);

      let newIndex = currentIndex;
      if (newTabs.length === 0) {
        newIndex = -1;
      } else if (index < currentIndex) {
        newIndex = Math.max(0, currentIndex - 1);
      }

      setSelectedTab(newTabs[newIndex] ?? null);
      setTabs(newTabs);
    },
    [currentIndex, setSelectedTab, tabs]
  );

  const duplicateTab = useCallback(
    function duplicateTab(index: number) {
      const newTabs = [...tabs];
      const tab = structuredClone(newTabs[index]) as QueryTab;
      if (tab) {
        tab.id = uuid();
        tab.queryId = uuid();
        tab.saved = false;
        tab.preview = false;
        tab.updatedAt = new Date().toISOString();
        tab.title = 'Untitled query';
        tab.resultsDisplayType = 'table';
        newTabs.push(tab);
        setTabs(newTabs);
        setSelectedTab(tab);
      }
    },
    [setSelectedTab, tabs]
  );

  const closeAllTabsToRight = useCallback(
    (index: number): void => {
      const otherTabs = tabs.slice(index + 1);
      const hasRunningTab = otherTabs.some((tab: Tab) => {
        const status = (getTabQueryStatus(tab) ?? '') as string;
        return status === 'runnning';
      });
      const isDirty = otherTabs.some((tab: Tab) => {
        return ['finished', 'error', 'unsaved'].includes(getStatus(tab) ?? '');
      });

      if (isDirty || hasRunningTab) {
        setCloseModalProps({
          open: true,
          title: 'Confirm close tabs',
          content: `Are you sure you want to close these tabs? ${
            hasRunningTab ? "At least one tab has a query that's running." : 'Some are unsaved.'
          }`,
          onCancel,
          onClick: () => closeTabsToRight(index)
        });
        return;
      }

      closeTabsToRight(index);
    },
    [closeTabsToRight, getStatus, getTabQueryStatus, tabs]
  );

  const closeAllTabs = useCallback((): void => {
    const hasRunningTab = tabs.some((tab: Tab) => {
      const status = (getTabQueryStatus(tab) ?? '') as string;
      return status === 'runnning';
    });
    const isDirty = tabs.some((tab: Tab) => {
      return ['finished', 'error', 'unsaved'].includes(getStatus(tab) ?? '');
    });

    if (isDirty || hasRunningTab) {
      setCloseModalProps({
        open: true,
        title: 'Confirm close tabs',
        content: `Are you sure you want to close these tabs? ${
          hasRunningTab ? "At least one tab has a query that's running." : 'Some are unsaved.'
        }`,
        onCancel,
        onClick: closeTabs
      });
      return;
    }
    closeTabs();
  }, [closeTabs, getStatus, getTabQueryStatus, tabs]);

  const closeOtherTabs = useCallback(
    (index: number) => {
      const tab = tabs[index];
      if (tab) {
        setTabs([tab]);
        setSelectedTab(tab);
      }
    },
    [setSelectedTab, tabs]
  );

  const closeAllOtherTabs = useCallback(
    (index: number): void => {
      const hasRunningTab = tabs.some((tab: Tab, tabIndex: number) => {
        if (index === tabIndex) {
          return false;
        }

        const status = (getTabQueryStatus(tab) ?? '') as string;
        return status === 'runnning';
      });
      const isDirty = tabs.some((tab: Tab, tabIndex: number) => {
        if (index === tabIndex) {
          return false;
        }

        return ['finished', 'error', 'unsaved'].includes(getStatus(tab) ?? '');
      });

      if (isDirty || hasRunningTab) {
        setCloseModalProps({
          open: true,
          title: 'Confirm close tabs',
          content: `Are you sure you want to close these tabs? ${
            hasRunningTab ? "At least one tab has a query that's running." : 'Some are unsaved.'
          }`,
          onCancel,
          onClick: () => closeOtherTabs(index)
        });
        return;
      }
      closeOtherTabs(index);
    },
    [closeOtherTabs, getStatus, getTabQueryStatus, tabs]
  );

  const log = (type: LogType, event: string, interaction?: InteractionType): void => {
    logger.track({
      view: 'tabbar',
      component: `${type}Tab`,
      event,
      interaction: interaction || 'click'
    });
  };

  const getMenuOptions = useCallback(
    (type: Tab['type'], index: number) => {
      const options = [
        {
          label: 'Close tab',
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
            e.stopPropagation();
            closeCurrentTab(index);
            log(type, 'contextMenuClose');
          }
        },
        {
          label: 'Close all tabs',
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
            e.stopPropagation();
            log(type, 'contextMenuCloseAll');
            closeAllTabs();
          }
        },
        {
          label: 'Close tabs to the right',
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
            e.stopPropagation();
            log(type, 'contextMenuCloseToRight');
            closeAllTabsToRight(index);
          }
        },
        {
          label: 'Close other tabs',
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
            e.stopPropagation();
            log(type, 'contextMenuCloseOthers');
            closeAllOtherTabs(index);
          }
        }
      ];
      if (type === 'query') {
        options.unshift({
          label: 'Duplicate tab',
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            duplicateTab(index);
            log(type, 'contextMenuDuplicate');
          }
        });
      }
      return options;
    },
    [closeAllOtherTabs, closeAllTabs, closeAllTabsToRight, closeCurrentTab, duplicateTab]
  );

  return {
    getMenuOptions,
    closeModalProps,
    getStatus,
    closeCurrentTab,
    log
  };
};
