import { createToast } from '@clickhouse/click-ui';
import { InstanceState } from '@cp/common/protocol/Instance';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useCurrentInstance } from 'src/instance/instanceController';
import { apiUrl } from 'src/lib/controlPlane/apiUrls';
import { ApiClient, useApiClient } from 'src/lib/controlPlane/client';
import { useHttpClient } from 'src/lib/http';
import {
  ConnectOptions,
  WebSocketsCpClient,
  getOrCreateWsClient as _getOrCreateWsClient
} from 'src/lib/websockets';
import { FCChild } from 'types/commonTypes';
import { dumpClientState } from 'src/lib/dumpClientState';
import { streamToAsyncIterator } from 'src/lib/stream/streamToAsyncIterator';
import { JSONLinesTransformStream } from 'src/lib/stream/JSONLinesTransformStream';

declare global {
  interface Window {
    unifiedConsole: {
      api: ReturnType<typeof useApiClient>;

      updateServiceState(
        serviceId: string,
        state: InstanceState
      ): Promise<void>;
      setCurrentServiceAwaking: () => Promise<void>;
      setCurrentServiceRunning: () => Promise<void>;
      setCurrentInstanceAsleep: () => Promise<void>;
      setCurrentInstanceStopped: () => Promise<void>;
      getOrCreateWsClient: (
        options: ConnectOptions
      ) => Promise<WebSocketsCpClient>;

      createSampleToast: () => void;
      dumpClientState: () => void;
      callQueryAPIEndpoint: (
        queryApiEndpointId: string,
        variables: Record<string, unknown>,
        openApiKeyId: string,
        openApiKeySecret: string,
        format?: string
      ) => Promise<void>;
      callQueryAPIEndpointWithJwt: (
        queryApiEndpointId: string,
        variables: Record<string, unknown>,
        format?: string
      ) => Promise<void>;
      callServiceQueryAPIEndpointWithJwt: (
        serviceId: string,
        sql: string,
        variables: Record<string, unknown>,
        format?: string
      ) => Promise<void>;
    };
  }
}

// Helper functions used for e2e testing
// Must be wrapped within AuthProvider because helper functions require authentication
export const DebugOnly: FCChild = ({ children }) => {
  const api: ApiClient = useApiClient();
  const httpClient = useHttpClient();
  const currentService = useCurrentInstance();

  const callQueryAPIEndpoint = async (
    queryEndpointId: string,
    variables: Record<string, unknown>,
    openApiKeyId: string,
    openApiKeySecret: string,
    format: string = 'JSONEachRow'
  ): Promise<void> => {
    const body =
      Object.values(variables).length > 0
        ? JSON.stringify(variables)
        : undefined;
    const response = await fetch(
      apiUrl(`/query-endpoints/${queryEndpointId}/run?format=${format}`),
      {
        method: 'POST',
        body,
        headers: {
          Authorization: `Basic ${btoa(`${openApiKeyId}:${openApiKeySecret}`)}`,
          'Content-Type': 'application/json',
          'X-Clickhouse-endpoint-version': '2'
        }
      }
    );

    console.log('Response', await response.text());
  };

  const callQueryAPIEndpointWithJwt = async (
    queryEndpointId: string,
    variables: Record<string, unknown>,
    format: string = 'JSONEachRow'
  ): Promise<void> => {
    const body =
      Object.values(variables).length > 0
        ? JSON.stringify(variables)
        : undefined;
    const response = await httpClient.post(
      apiUrl(`/query-endpoints/${queryEndpointId}/run?format=${format}`),
      {
        body,
        headers: {
          'Content-Type': 'application/json',
          'X-Clickhouse-endpoint-version': '2'
        }
      }
    );

    if (response.ok && response.body) {
      const readableStream = response.body.pipeThrough(
        new JSONLinesTransformStream()
      );

      let count = 0;
      for await (const jsonObject of streamToAsyncIterator(readableStream)) {
        console.log(jsonObject); // Process each JSON object
        count += 1;
      }

      console.log(`Returned ${count} rows`);
    }
  };

  const callServiceQueryAPIEndpointWithJwt = async (
    serviceId: string,
    sql: string,
    variables: Record<string, unknown>,
    format: string = 'JSONEachRow'
  ): Promise<void> => {
    const body =
      Object.values(variables).length > 0
        ? JSON.stringify(variables)
        : undefined;
    const urlEncodedSql = encodeURIComponent(sql);
    const response = await httpClient.post(
      apiUrl(
        `/services/${serviceId}/query?format=${format}&sql=${urlEncodedSql}`
      ),
      {
        body,
        headers: {
          'Content-Type': 'application/json',
          'X-Clickhouse-endpoint-version': '2'
        }
      }
    );

    if (response.ok && response.body) {
      const readableStream = response.body.pipeThrough(
        new JSONLinesTransformStream()
      );

      let count = 0;
      for await (const jsonObject of streamToAsyncIterator(readableStream)) {
        console.log(jsonObject); // Process each JSON object
        count += 1;
      }

      console.log(`Returned ${count} rows`);
    }
  };

  const updateServiceState = useCallback(
    async (serviceId: string, state: InstanceState): Promise<void> => {
      await api.instance.updateServiceStateSimulator({
        instanceId: serviceId,
        state
      });
    },
    [api.instance]
  );

  const setCurrentServiceAsleep = useCallback(async (): Promise<void> => {
    if (!currentService?.id) {
      return;
    }

    await updateServiceState(currentService.id, 'idle');
  }, [api.instance, currentService?.id]);

  const setCurrentServiceAwaking = useCallback(async (): Promise<void> => {
    if (!currentService?.id) {
      return;
    }

    await updateServiceState(currentService.id, 'awaking');
  }, [api.instance, currentService?.id]);

  const setCurrentServiceRunning = useCallback(async (): Promise<void> => {
    if (!currentService?.id) {
      return;
    }

    await updateServiceState(currentService.id, 'running');
  }, [api.instance, currentService?.id]);

  const setCurrentInstanceStopped = useCallback(async (): Promise<void> => {
    if (!currentService?.id) {
      return;
    }

    await updateServiceState(currentService.id, 'stopped');
  }, [api.instance, currentService?.id]);

  const getOrCreateWsClient = useCallback(
    async (connectOptions: ConnectOptions): Promise<WebSocketsCpClient> => {
      return await _getOrCreateWsClient(connectOptions);
    },
    []
  );

  useHotkeys(
    'meta+shift+e, ctrl+shift+e',
    () => {
      console.error(new Error('Forced manual error'));
    },
    {
      enabled: () => {
        return true;
      }
    },
    []
  );

  useEffect(() => {
    window.unifiedConsole = window.unifiedConsole || {};
    window.unifiedConsole.api = api;
    window.unifiedConsole.updateServiceState = updateServiceState;
    window.unifiedConsole.setCurrentServiceAwaking = setCurrentServiceAwaking;
    window.unifiedConsole.setCurrentServiceRunning = setCurrentServiceRunning;
    window.unifiedConsole.setCurrentInstanceAsleep = setCurrentServiceAsleep;
    window.unifiedConsole.setCurrentInstanceStopped = setCurrentInstanceStopped;
    window.unifiedConsole.getOrCreateWsClient = getOrCreateWsClient;
    window.unifiedConsole.dumpClientState = dumpClientState;
    window.unifiedConsole.callQueryAPIEndpoint = callQueryAPIEndpoint;
    window.unifiedConsole.callQueryAPIEndpointWithJwt =
      callQueryAPIEndpointWithJwt;

    window.unifiedConsole.callServiceQueryAPIEndpointWithJwt =
      callServiceQueryAPIEndpointWithJwt;

    window.unifiedConsole.createSampleToast = (): void => {
      createToast({
        type: 'default',
        title: 'Sample Toast',
        description: 'This is a sample toast message',
        duration: 120000
      });
    };
  }, [
    api,
    setCurrentServiceAwaking,
    setCurrentServiceRunning,
    setCurrentServiceAsleep,
    setCurrentInstanceStopped,
    getOrCreateWsClient,
    updateServiceState
  ]);

  return <>{children}</>;
};
