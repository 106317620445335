import { Spacer, Text, Link } from '@clickhouse/click-ui';
import {
  PASSWORD_MASK,
  getPasswordSubstitution
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import { ReactElement } from 'react';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';

const getJdbcConnectionString = (
  hostname: string,
  username: string,
  password: string,
  port: string
): string => {
  return (
    `jdbc:clickhouse://${hostname}:${port}` +
    `?user=${username}` +
    `&password=${getPasswordSubstitution(password)}` +
    '&ssl=true'
  );
};

interface JdbcConnectionTabProps {
  hostname: string;
  username: string;
  password: string;
  port: string;
  showPassword?: boolean;
}

const JdbcConnectionTab = ({
  hostname,
  username,
  password,
  port,
  showPassword
}: JdbcConnectionTabProps): ReactElement => {
  const unmasked = getJdbcConnectionString(hostname, username, password, port);
  const masked = password
    ? getJdbcConnectionString(hostname, username, PASSWORD_MASK, port)
    : undefined;

  return (
    <>
      <Spacer />
      <Text>Use the following as a ClickHouse JDBC connection string:</Text>
      <Spacer />
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        language="bash"
        data-testid="service-connect-code-block-jdbc-usage"
        showPassword={showPassword}
      />
      <Spacer />
      <Text>
        Visit the documentation to learn more about available{' '}
        <Link
          href="https://clickhouse.com/docs/en/integrations/java#configuration"
          target="_blank"
          icon="share-arrow"
        >
          JDBC options
        </Link>
      </Text>
    </>
  );
};

export default JdbcConnectionTab;
