import {
  Button,
  CellProps,
  Container,
  createToast,
  Grid,
  IconButton,
  Popover,
  Text
} from '@clickhouse/click-ui';
import { ReactElement, Suspense, useRef, useState } from 'react';
import styled from 'styled-components';
import { EditorInstance } from 'src/components/primitives/lib/MonacoSQLEditor';
import SqlEditorWithAutoComplete from 'src/components/primitives/lib/SqlEditorWithAutoComplete';
import { logEvent } from 'src/components/QueryView/analytics';
import {
  useAutocompleteOptions,
  useDebouncedQueryVariablesUpdate
} from 'src/components/QueryView/hooks';
import {
  useSavedQueries,
  useSavedQuery
} from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { EllipsisContainer } from 'src/layout/GlobalStyles';
import { QueryResult, useRawSqlQueryFunction } from 'src/lib/clickhouse/query';
import { useCredentials } from 'src/state/connection';
import QueryVariables from 'src/components/QueryView/QueryVariables';
import { SavedQueryDTO } from 'shared';
import InlineQueryResults from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/InlineQueryEditor/InlineQueryResults';

const EditorContainer = styled.div`
  height: 400px;
  width: 500px;

  display: flex;
  flex-flow: row nowrap;
`;

interface InlineQueryEditorProps {
  onSaveQuery: (newQuery: SavedQueryDTO) => void;
  queryId: string;
}

export default function InlineQueryEditor({
  onSaveQuery,
  queryId
}: InlineQueryEditorProps): ReactElement {
  const savedQuery = useSavedQuery(queryId);

  const autocompleteOptions = useAutocompleteOptions();
  const credentials = useCredentials();
  const editorRef = useRef<EditorInstance | null>(null);
  const [query, setQuery] = useState(savedQuery?.query || '');
  const [queryVariables, setQueryVariables] = useState(
    savedQuery?.parameters || {}
  );
  const [results, setResults] = useState<QueryResult | null>(null);
  const [runQueryFn, { loading }] = useRawSqlQueryFunction();
  const { updateQuery } = useSavedQueries();

  const runQuery = (): void => {
    runQueryFn(query, credentials, { variables: queryVariables })
      .then((result) => {
        setResults(result);
      })
      .catch((e) => {
        if (e instanceof Error) {
          setResults({
            error: e.message,
            type: 'server'
          });
        }
      });
  };

  const queryChanged = query !== savedQuery?.query;

  const debouncedHookUpdates = useDebouncedQueryVariablesUpdate({
    updateQueryVariables: setQueryVariables
  });

  const saveQuery = async (): Promise<void> => {
    if (!savedQuery) {
      return;
    }

    const updatedQuery = await updateQuery({
      id: queryId,
      name: savedQuery.name,
      query,
      database: savedQuery.database,
      updatedAt: new Date().toISOString(),
      parameters: queryVariables,
      chartConfig: savedQuery.chartConfig
    });

    if (updatedQuery) {
      createToast({
        title: 'Success',
        description: 'Query saved successfully'
      });
      onSaveQuery(updatedQuery);
    }
  };

  return (
    <Popover>
      <Popover.Trigger>
        <IconButton icon="code" />
      </Popover.Trigger>
      <Popover.Content side="left" sideOffset={550}>
        <Container gap="md" maxWidth="500px" orientation="vertical">
          <Container justifyContent="space-between">
            <Container gap="sm" orientation="horizontal">
              <Text>{savedQuery?.name}</Text>
              <Text color="muted">{queryChanged ? ' (unsaved)' : ''}</Text>
            </Container>
            <Button onClick={runQuery}>Run Query</Button>
          </Container>
          <EditorContainer>
            <Container>
              <Suspense fallback={null}>
                <SqlEditorWithAutoComplete
                  editorRef={editorRef}
                  preloadedQuery={query}
                  autocompleteOptions={autocompleteOptions}
                  onChange={(e): void => {
                    setQuery(e.sql || '');
                    debouncedHookUpdates({
                      oldQueryVariables: queryVariables,
                      newQueryVariablesList: e.queryVariables,
                      newSql: e.sql ?? ''
                    });
                  }}
                  readOnly={
                    savedQuery?.userPermissions === 'read' ||
                    savedQuery?.queryUnowned
                  }
                  runQuery={runQuery}
                  onSave={(): Promise<void> =>
                    saveQuery().catch((e) => {
                      console.error('error saving query', e);
                    })
                  }
                  runSQL={(sql): void => {
                    if (sql !== undefined) {
                      runQuery();
                    }
                  }}
                  logEvent={(event, interaction): void =>
                    logEvent('dashboard-query-editor', event, interaction)
                  }
                />
              </Suspense>
            </Container>
            <Container maxWidth="150px">
              <QueryVariables
                editable
                query={query}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            </Container>
          </EditorContainer>
          <InlineQueryResults loading={loading} results={results} />
        </Container>
      </Popover.Content>
    </Popover>
  );
}
