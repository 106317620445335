import {
  AdditionalCreditsRequest,
  ConfirmUpdatedPaymentMethodRequest,
  ConfirmUpdatedPaymentMethodResponse,
  GetClientSecretRequest,
  GetClientSecretResponse,
  GetOrganizationBillingDetailsRequest,
  GetOrganizationBillingDetailsResponse,
  GetPricingForAllRegionsRequest,
  GetPricingForAllRegionsResponse,
  GetUsageStatementRequest,
  GetUsageStatementResponse,
  OrganizationDetailsToUpdate,
  UpdateOrganizationBillingContact,
  UpdateOrganizationBillingDetailsRequest,
  UsageMetric,
  UsageReportChartRequest,
  UsageReportChartResponse,
  UsageReportRequest,
  UsageReportResponse
} from '@cp/common/protocol/Billing';
import { Organization } from '@cp/common/protocol/Organization';
import { TackleSubscriptionRequest, TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { assertTruthy } from '@cp/common/utils/Assert';
import { ErrorResponse } from 'shared';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const BILLING_API_URL = `${config.controlPlane.apiHost}/api/billing`;

type BillingRequest =
  | GetClientSecretRequest
  | GetOrganizationBillingDetailsRequest
  | GetUsageStatementRequest
  | AdditionalCreditsRequest
  | UpdateOrganizationBillingDetailsRequest
  | UpdateOrganizationBillingContact
  | UsageReportRequest
  | UsageReportChartRequest
  | TackleSubscriptionRequest
  | GetPricingForAllRegionsRequest
  | ConfirmUpdatedPaymentMethodRequest;

export class BillingApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: BillingRequest, url = BILLING_API_URL): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async getClientSecret(organizationId: string): Promise<GetClientSecretResponse> {
    const request: GetClientSecretRequest = {
      rpcAction: 'getClientSecret',
      organizationId
    };

    const response = await this.rpcRequest(request);
    assertTruthy(response.ok, `Could not fetch client secret for organizationId: ${organizationId}`);

    return (await response.json()) as unknown as GetClientSecretResponse;
  }

  async getOrganizationBillingDetails(organizationId: string): Promise<GetOrganizationBillingDetailsResponse> {
    const request: GetOrganizationBillingDetailsRequest = {
      rpcAction: 'getOrganizationBillingDetails',
      organizationId
    };
    const response = await this.rpcRequest(request);
    assertTruthy(response.ok, `Could not fetch details for organizationId: ${organizationId}`);

    return (await response.json()) as unknown as GetOrganizationBillingDetailsResponse;
  }

  async getUsageStatement(organizationId: string, billId: string): Promise<GetUsageStatementResponse> {
    const request: GetUsageStatementRequest = {
      rpcAction: 'getUsageStatement',
      organizationId,
      billId
    };
    const response = await this.rpcRequest(request);
    assertTruthy(
      response.ok,
      `Could not fetch usage statement for organizationId: ${organizationId}, and billId: ${billId}`
    );

    return (await response.json()) as unknown as GetUsageStatementResponse;
  }

  async requestAdditionalCredits(requestText: string): Promise<void> {
    const request: AdditionalCreditsRequest = { creditsText: requestText, rpcAction: 'requestCredits' };
    const response = await this.rpcRequest(request);
    assertTruthy(response.ok, 'Failed to request additional credits');
  }

  async updateOrganizationBillingContact(organizationId: string, billingContact: string): Promise<void> {
    const request: UpdateOrganizationBillingContact = {
      rpcAction: 'updateOrganizationBillingContact',
      organizationId,
      billingContact
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const error = (await response.json()).message;
      throw new Error(error);
    }
  }

  async updateOrganizationBillingDetails(
    organizationId: string,
    organizationDetails: OrganizationDetailsToUpdate
  ): Promise<void> {
    const request: UpdateOrganizationBillingDetailsRequest = {
      rpcAction: 'updateOrganizationBillingDetails',
      organizationId,
      ...organizationDetails
    };
    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const error = (await response.json()).message;
      throw new Error(error);
    }
  }

  async confirmUpdatedPaymentMethod(
    organizationId: string,
    paymentMethodUpdateId: string
  ): Promise<ConfirmUpdatedPaymentMethodResponse> {
    const request: ConfirmUpdatedPaymentMethodRequest = {
      rpcAction: 'confirmUpdatedPaymentMethod',
      paymentMethodUpdateId,
      organizationId
    };
    const response = await this.rpcRequest(request);
    assertTruthy(response.ok, 'Failed to confirm updated payment method');
    return (await response.json()) as unknown as ConfirmUpdatedPaymentMethodResponse;
  }

  async handleTackleSubscription(
    token: TackleSubscriptionToken,
    organizationDetails: OrganizationDetailsToUpdate
  ): Promise<Organization> {
    const request: TackleSubscriptionRequest = {
      rpcAction: 'handleTackleSubscription',
      token,
      organizationDetails
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const body = (await response.json()) as ErrorResponse;
      throw new Error(body.message);
    }

    const { organization } = (await response.json()) as { organization: Organization };
    return organization;
  }

  async getAdminUsageReport(
    organizationId: string,
    billDate: string | undefined,
    chartType: UsageMetric
  ): Promise<UsageReportResponse> {
    const request: UsageReportRequest = {
      rpcAction: 'getUsageReport',
      organizationId,
      billDate,
      chartMetric: chartType
    };
    const response = await this.rpcRequest(request);
    assertTruthy(
      response.ok,
      `Could not fetch usage report for organizationId: ${organizationId} for bill date: ${billDate ?? 'undefined'}`
    );
    return (await response.json()) as unknown as UsageReportResponse;
  }

  async getAdminUsageChart(
    organizationId: string,
    billDate: string,
    chartType: UsageMetric
  ): Promise<UsageReportChartResponse> {
    const response = await this.getAdminUsageReport(organizationId, billDate, chartType);
    return { chart: response.chart };
  }

  async getRegionsPrices(organizationId: string): Promise<GetPricingForAllRegionsResponse> {
    const request: GetPricingForAllRegionsRequest = {
      rpcAction: 'getPricingForAllRegions',
      organizationId
    };
    const response = await this.rpcRequest(request);
    assertTruthy(response.ok, 'Could not fetch prices for regions');
    return (await response.json()) as unknown as GetPricingForAllRegionsResponse;
  }
}
