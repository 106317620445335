// View the Model diagram here https://miro.com/app/board/uXjVNj6XEYs=/

export type Namespace = 'control-plane';
export type ResourceAction =
  // organization
  | 'organization:view'
  | 'organization:manage'

  // organization /billing
  | 'organization:manage-billing'

  // service
  | 'service:view'
  | 'service:manage'

  // service / backups
  | 'service:view-backups'
  | 'service:manage-backups'

  // service / db role mapping
  | 'service:manage-db-role-mapping'

  // service / ip access list
  | 'service:manage-ip-access-list'

  // service / private endpoints
  | 'service:view-private-endpoints'
  | 'service:manage-private-endpoints'

  // service / scaling
  | 'service:view-scaling-config'
  | 'service:manage-scaling-config'

  // support
  | 'support:manage'

  // other for testing purposes
  | 'no-one-allowed';

/**
 * Permission is the smallest unit of access control, i.e. `control-plane.instance.create`
 * It is re-used across Policies
 */
export type Permission = `${Namespace}:${ResourceAction}`;

/**
 * Role can be attached to an entity such as a User or an OrganizationUser
 */
export interface Role {
  id: string;
  name: string;
  context: RoleContext;
}

export type RoleContext = 'organization' | 'organizationUser' | 'user' | 'serviceUser';

/**
 * a Role contains a collection of Policies. A Policy allows or denies a Permission
 * Deny always takes precedence over Allow
 */
export interface Policy {
  roleId: string;
  allowDeny: ALLOW_DENY;
  permissionId: string;
  entityTarget?: string;
}

export const enum ALLOW_DENY {
  ALLOW = 'allow',
  DENY = 'deny'
}

export interface RoleMapping {
  roleId: string;
  actorEntityId: string;
  targetEntityId: string;
}

/**
 * This is the result of an authorization decision
 * This will be logged every time an authorization decision is made
 */
export interface AuthorizationDecision {
  requiredPermission: Permission; // permission that was required for this action
  entityTarget?: string; // target entity to check if user has access to
  allPolicies: Policy[];
  usedPolicy?: Policy; // policy that was used
  allowDeny: ALLOW_DENY; // decision
  reason?: AuthorizationDecisionReason; // reason for the decision
}

export type AuthorizationDecisionReason =
  | 'DENY_POLICY_FOUND'
  | 'ALLOW_POLICY_FOUND_WITHOUT_DENY_POLICY'
  | 'NO_POLICY_FOUND';

export const ROLE_TO_ID_MAP = {
  ADMIN: '11111111-1111-1111-9999-000000000001',
  DEVELOPER: '11111111-1111-1111-9999-000000000002',
  // org role with minimum permission to replace developer once RBAC phase 1 is complete
  ORG_MEMBER: '11111111-1111-1111-9999-000000000006',
  BILLING: '11111111-1111-1111-9999-000000000003',
  SERVICE_ADMIN: '11111111-1111-1111-9999-000000000004',
  SERVICE_READ_ONLY: '11111111-1111-1111-9999-000000000005'
} as const;

export type RoleName = keyof typeof ROLE_TO_ID_MAP;
export type RoleID = (typeof ROLE_TO_ID_MAP)[RoleName];

interface PredefinedPolicy {
  permissionId: Permission;
  allowDeny: ALLOW_DENY;
  entityTarget?: string;
}

/**
 * IMPORTANT
 * When updating predefined policies, be sure to run `OneShotManager.syncRBACPredefinedPoliciesAndPermissions`
 * Also use the output to update `docker/mongo/mongo-init.js`
 */
export const ADMIN_POLICIES: PredefinedPolicy[] = [
  // organization
  { permissionId: 'control-plane:organization:view', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:organization:manage', allowDeny: ALLOW_DENY.ALLOW },

  // organization / billing
  { permissionId: 'control-plane:organization:manage-billing', allowDeny: ALLOW_DENY.ALLOW },

  // service
  { permissionId: 'control-plane:service:view', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage', allowDeny: ALLOW_DENY.ALLOW },

  // service / backups
  { permissionId: 'control-plane:service:view-backups', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage-backups', allowDeny: ALLOW_DENY.ALLOW },

  // service / db role mapping
  { permissionId: 'control-plane:service:manage-db-role-mapping', allowDeny: ALLOW_DENY.ALLOW },

  // service / ip access list
  { permissionId: 'control-plane:service:manage-ip-access-list', allowDeny: ALLOW_DENY.ALLOW },

  // service / private endpoints
  { permissionId: 'control-plane:service:view-private-endpoints', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage-private-endpoints', allowDeny: ALLOW_DENY.ALLOW },

  // service / scaling
  { permissionId: 'control-plane:service:view-scaling-config', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage-scaling-config', allowDeny: ALLOW_DENY.ALLOW },

  // support
  { permissionId: 'control-plane:support:manage', allowDeny: ALLOW_DENY.ALLOW }
];

export const DEVELOPER_POLICIES: PredefinedPolicy[] = [
  // organization
  { permissionId: 'control-plane:organization:view', allowDeny: ALLOW_DENY.ALLOW },

  // service
  { permissionId: 'control-plane:service:view', allowDeny: ALLOW_DENY.ALLOW },

  // service / backups
  { permissionId: 'control-plane:service:view-backups', allowDeny: ALLOW_DENY.ALLOW },

  // service / scaling
  { permissionId: 'control-plane:service:view-scaling-config', allowDeny: ALLOW_DENY.ALLOW },

  // support
  { permissionId: 'control-plane:support:manage', allowDeny: ALLOW_DENY.ALLOW }
];

export const ORG_MEMBER_POLICIES: PredefinedPolicy[] = [
  // organization
  { permissionId: 'control-plane:organization:view', allowDeny: ALLOW_DENY.ALLOW }
];

export const BILLING_POLICIES: PredefinedPolicy[] = [
  // organization / billing
  { permissionId: 'control-plane:organization:manage-billing', allowDeny: ALLOW_DENY.ALLOW },

  // support
  { permissionId: 'control-plane:support:manage', allowDeny: ALLOW_DENY.ALLOW }
];

export const SERVICE_ADMIN_POLICIES: PredefinedPolicy[] = [
  // service
  { permissionId: 'control-plane:service:view', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage', allowDeny: ALLOW_DENY.ALLOW },

  // service / backups
  { permissionId: 'control-plane:service:view-backups', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage-backups', allowDeny: ALLOW_DENY.ALLOW },

  // service / db role mapping
  { permissionId: 'control-plane:service:manage-db-role-mapping', allowDeny: ALLOW_DENY.ALLOW },

  // service / ip access list
  { permissionId: 'control-plane:service:manage-ip-access-list', allowDeny: ALLOW_DENY.ALLOW },

  // service / private endpoints
  { permissionId: 'control-plane:service:view-private-endpoints', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage-private-endpoints', allowDeny: ALLOW_DENY.ALLOW },

  // service / scaling
  { permissionId: 'control-plane:service:view-scaling-config', allowDeny: ALLOW_DENY.ALLOW },
  { permissionId: 'control-plane:service:manage-scaling-config', allowDeny: ALLOW_DENY.ALLOW },

  // support
  { permissionId: 'control-plane:support:manage', allowDeny: ALLOW_DENY.ALLOW }
];

export const SERVICE_READ_ONLY_POLICIES: PredefinedPolicy[] = [
  // service
  { permissionId: 'control-plane:service:view', allowDeny: ALLOW_DENY.ALLOW },

  // service / backups
  { permissionId: 'control-plane:service:view-backups', allowDeny: ALLOW_DENY.ALLOW },

  // service / scaling
  { permissionId: 'control-plane:service:view-scaling-config', allowDeny: ALLOW_DENY.ALLOW },

  // support
  { permissionId: 'control-plane:support:manage', allowDeny: ALLOW_DENY.ALLOW }
];

export const PREDEFINED_ROLES = {
  [ROLE_TO_ID_MAP['ADMIN']]: {
    id: ROLE_TO_ID_MAP['ADMIN'],
    name: 'ADMIN',
    context: 'organizationUser',
    policies: ADMIN_POLICIES
  },
  [ROLE_TO_ID_MAP['DEVELOPER']]: {
    id: ROLE_TO_ID_MAP['DEVELOPER'],
    name: 'DEVELOPER',
    context: 'organizationUser',
    policies: DEVELOPER_POLICIES
  },
  [ROLE_TO_ID_MAP['ORG_MEMBER']]: {
    id: ROLE_TO_ID_MAP['ORG_MEMBER'],
    name: 'ORG_MEMBER',
    context: 'organizationUser',
    policies: ORG_MEMBER_POLICIES
  },
  [ROLE_TO_ID_MAP['BILLING']]: {
    id: ROLE_TO_ID_MAP['BILLING'],
    name: 'BILLING',
    context: 'organizationUser',
    policies: BILLING_POLICIES
  },
  [ROLE_TO_ID_MAP['SERVICE_ADMIN']]: {
    id: ROLE_TO_ID_MAP['SERVICE_ADMIN'],
    name: 'SERVICE_ADMIN',
    context: 'serviceUser',
    policies: SERVICE_ADMIN_POLICIES
  },
  [ROLE_TO_ID_MAP['SERVICE_READ_ONLY']]: {
    id: ROLE_TO_ID_MAP['SERVICE_READ_ONLY'],
    name: 'SERVICE_READ_ONLY',
    context: 'serviceUser',
    policies: SERVICE_READ_ONLY_POLICIES
  }
};
