import {
  Container,
  SidebarNavigationTitle,
  Spacer
} from '@clickhouse/click-ui';
import {
  Organization,
  OrganizationInvitationDetails
} from '@cp/common/protocol/Organization';
import { isSamlUserId } from '@cp/common/utils/AuthUtils';
import { HTMLAttributes, ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import {
  MainSidebarContainer,
  SidebarElementsContainer
} from 'src/layout/GlobalSidebarStyles';
import { SidebarFooter } from 'src/layout/OrgSidebar/SidebarFooter';
import { SidebarHeader } from 'src/layout/OrgSidebar/SidebarHeader';
import InvitationsModal from 'src/layout/ServiceSidebar/InvitationsModal';
import { AlertsSection } from 'src/layout/ServiceSidebar/SidebarAlertsSection';
import { SidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';
import { SidebarOrgSelector } from 'src/layout/ServiceSidebar/SidebarOrgSelector';
import {
  MainSidebarBody,
  useMainSidebar
} from 'src/layout/SidebarWrapper/SidebarWrapper';
import { useOrgFeature, useUserFeature } from 'src/lib/features';
import { routes } from 'src/lib/routes';
import { useCurrentUserWithRoleInCurrentOrg } from 'src/lib/user/useCurrentUserWithRoleInCurrentOrg';
import {
  useCurrentOrganizationOrThrow,
  useOrganizationStateManager
} from 'src/organization/organizationState';
import { useInvitationController } from 'src/state/notification/invitationController';
import styled from 'styled-components';

const OrgSidebarContainer = styled(SidebarElementsContainer)`
  padding-inline: 1rem;
`;

interface OrgSidebarProps {
  container: HTMLDivElement | null;
}

export interface OrgSidebarContentProps {
  currentOrg: Organization;
  organizations: Record<string, Organization>;
  hasManageOrganizationPermission: boolean;
  hasManageBillingPermission: boolean;
  isSAMLUser: boolean;
  invitations: OrganizationInvitationDetails[];
  container: HTMLDivElement | null;
  onCloseSidebar: () => void;
  onInvitationAlertClick: (b: boolean) => void;
}

export const OrgSidebarContent = ({
  currentOrg,
  organizations,
  hasManageOrganizationPermission,
  hasManageBillingPermission,
  isSAMLUser,
  invitations,
  onCloseSidebar,
  onInvitationAlertClick
}: OrgSidebarContentProps): ReactElement => {
  const showNotifications = useOrgFeature('FT_ORG_NOTIFICATIONS');

  return (
    <>
      <OrgSidebarContainer
        orientation="vertical"
        fillHeight
        isResponsive={false}
        padding="xs"
      >
        <SidebarNavigationTitle label="Account" />
        <Spacer size="sm" />

        <SidebarItem
          type="main"
          icon="user"
          label="Profile"
          path="/profile"
          data-testid="profileSidebarButton"
          onClick={(): void => {
            navigateTo('/profile');
            onCloseSidebar();
          }}
        />

        {/* email and social users should be able to set up MFA */}
        {!isSAMLUser && (
          <SidebarItem
            type="main"
            icon="secure"
            label="Security"
            path="/security"
            data-testid="securitySidebarButton"
            onClick={(): void => {
              navigateTo('/security');
              onCloseSidebar();
            }}
          />
        )}
        {showNotifications && (
          <SidebarItem
            type="main"
            icon="bell"
            label="Notifications"
            path="/organizations/:organizationId/notifications"
            data-testid="notificationsSidebarButton"
            onClick={(): void => {
              navigateTo(routes.notificationsPage({ orgId: currentOrg.id }));
              onCloseSidebar();
            }}
          />
        )}
        <Spacer size="md" />
        <SidebarNavigationTitle label="Organization" />
        <Spacer size="sm" />
        {hasManageBillingPermission && (
          <SidebarItem
            type="main"
            icon="cards"
            label="Billing"
            path="/organizations/:organizationId/billing"
            data-testid="billingSidebarButton"
            onClick={(): void => {
              navigateTo(routes.billingHome({ orgId: currentOrg.id }));
              onCloseSidebar();
            }}
          />
        )}

        {hasManageBillingPermission && (
          <SidebarItem
            type="main"
            icon="metrics-alt"
            label="Usage breakdown"
            path="/organizations/:organizationId/usage"
            data-testid="usageSidebarButton"
            onClick={(): void => {
              navigateTo(routes.billingUsage({ orgId: currentOrg.id }));
              onCloseSidebar();
            }}
          />
        )}
        <SidebarItem
          type="main"
          icon="users"
          label="Users and roles"
          path="/organizations/:organizationId/members"
          data-testid="usersSidebarButton"
          onClick={(): void => {
            navigateTo(routes.membersPage({ orgId: currentOrg.id }));
            onCloseSidebar();
          }}
        />

        <SidebarItem
          type="main"
          icon="arrow-directions"
          label="Private endpoints"
          path="/organizations/:organizationId/private-endpoints"
          data-testid="privateEndpointsSidebarButton"
          onClick={(): void => {
            navigateTo(routes.privateEndpointsPage({ orgId: currentOrg.id }));
            onCloseSidebar();
          }}
        />

        <SidebarItem
          type="main"
          icon="keys"
          label="API keys"
          path="/organizations/:organizationId/keys"
          data-testid="apiKeysSidebarButton"
          onClick={(): void => {
            navigateTo(routes.apiKeysPage({ orgId: currentOrg.id }));
            onCloseSidebar();
          }}
        />

        {hasManageOrganizationPermission && (
          <SidebarItem
            type="main"
            icon="activity"
            label="Audit"
            path="/organizations/:organizationId/audit"
            data-testid="auditSidebarButton"
            onClick={(): void => {
              navigateTo(routes.auditPage({ orgId: currentOrg.id }));
              onCloseSidebar();
            }}
          />
        )}

        <SidebarItem
          type="main"
          icon="building"
          label="Details"
          path="/organizations/:organizationId"
          data-testid="detailsSidebarButton"
          onClick={(): void => {
            navigateTo(routes.orgDetails({ orgId: currentOrg.id }));
            onCloseSidebar();
          }}
        />
      </OrgSidebarContainer>
      <AlertsSection
        currentOrg={currentOrg}
        hasInvitations={invitations.length > 0}
        setInvitationsModalOpen={onInvitationAlertClick}
      />
      <SidebarOrgSelector
        currentOrg={currentOrg}
        orgs={Object.values(organizations)}
      />
    </>
  );
};

const OrgSidebar = ({
  container,
  ...props
}: HTMLAttributes<HTMLDivElement> & OrgSidebarProps): ReactElement | null => {
  const currentUser = useCurrentUserWithRoleInCurrentOrg();
  const currentOrg = useCurrentOrganizationOrThrow();
  const [invitationsModalOpen, setInvitationsModalOpen] = useState(false);
  const { acceptInvitation, invitations } = useInvitationController();
  const { organizations } = useOrganizationStateManager();
  const { closeSidebar } = useMainSidebar();
  const isSAMLUser = isSamlUserId(currentUser.id);
  const hasManageOrganizationPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage',
      currentUser.role === 'ADMIN'
    );
  const hasManageBillingPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage-billing',
      currentUser.role === 'ADMIN'
    );

  return (
    <MainSidebarBody header={<SidebarHeader />} container={container}>
      <Container alignItems="start" fillHeight wrap="nowrap" overflow="auto">
        <MainSidebarContainer
          orientation="vertical"
          isResponsive={false}
          fillHeight
          {...props}
        >
          <InvitationsModal
            acceptInvitation={acceptInvitation}
            invitations={invitations}
            invitationsModalOpen={invitationsModalOpen}
            onClose={(): void => setInvitationsModalOpen(false)}
          />
          <OrgSidebarContent
            currentOrg={currentOrg}
            organizations={organizations}
            hasManageOrganizationPermission={hasManageOrganizationPermission}
            hasManageBillingPermission={hasManageBillingPermission}
            isSAMLUser={isSAMLUser}
            invitations={invitations}
            container={container}
            onCloseSidebar={closeSidebar}
            onInvitationAlertClick={setInvitationsModalOpen}
            {...props}
          />
          <SidebarFooter />
        </MainSidebarContainer>
      </Container>
    </MainSidebarBody>
  );
};

export default OrgSidebar;
