import { Container, Spacer, Text, Title } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { NewKeyInfo } from 'src/apiKey/apiKeyController';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import CredentialsInfo from 'src/components/ServiceConnect/CredentialsInfo';
import { SectionSeparator } from 'src/lib/pageElements';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

interface NewKeyInfoProps {
  newKeyInfo: NewKeyInfo;
}

export default function NewKeyInfo({
  newKeyInfo
}: NewKeyInfoProps): ReactElement {
  const hasManageOrganizationPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  return (
    <>
      <Container
        orientation="vertical"
        alignItems="start"
        fillWidth
        maxWidth="max(50%, 640px)"
      >
        <Title type="h4">New API key generated!</Title>
        <Spacer size="sm" />
        <Text color="muted">
          Make sure to copy your key and secret, we'll only show it to you once.
        </Text>
        <Spacer size="md" />
        <CredentialsInfo
          hasManagePermission={hasManageOrganizationPermission}
          username={newKeyInfo.key}
          usernameLabel="Key ID"
          password={newKeyInfo.secret}
          passwordLabel="Key Secret"
          credentialsFileName={`clickhouse-api-key-${newKeyInfo.name}.txt`}
          showDownload
        />
      </Container>
      <SectionSeparator />
    </>
  );
}
