import { assertTruthy } from '@cp/common/utils/Assert';
import { getStorageIdFromAttachment } from '@cp/common/utils/SupportUtils';
import { ReactElement, ReactNode, useState } from 'react';
import { formatMoment } from 'src/lib/formatters/dateTimeFormatter';
import {
  Button,
  Container,
  Link,
  Panel,
  Spacer,
  Text,
  Title,
  VerticalStepper
} from '@clickhouse/click-ui';
import { SupportCaseEvent } from '@cp/common/protocol/Support';
import ReadMore from 'src/components/ReadMore/ReadMore';
import useSupportAttachment from 'src/support/controller/useSupportAttachment';
import styled from 'styled-components';
import ReplyModal from 'src/components/SupportCase/ReplyModal';

const CustomPanel = styled(Panel)`
  margin-top: -2.5rem;
  justify-content: space-between;
`;

const NoWrapText = styled(Text)`
  white-space: nowrap;
`;

function StepperLabel({
  fullName,
  isClickHouse,
  email,
  reply,
  type,
  status,
  attachment,
  timestamp
}: SupportCaseEvent): ReactNode | null {
  const { downloadAttachment } = useSupportAttachment();

  const handleDownloadLink = (): void => {
    assertTruthy(attachment, 'Invalid attachment');
    void downloadAttachment({
      storageId: getStorageIdFromAttachment(attachment)
    });
  };

  if (type === 'status') {
    if (['WAITING_ON_CUSTOMER', 'WAITING_ON_CLICKHOUSE'].includes(status)) {
      return null;
    }
    return (
      <CustomPanel hasBorder>
        <Text>Case is {status === 'NEW' ? 'opened' : 'closed'}</Text>
        <NoWrapText component="div" color="muted">
          {formatMoment(timestamp)}
        </NoWrapText>
      </CustomPanel>
    );
  }

  if (type === 'attachment') {
    return (
      <CustomPanel hasBorder>
        <Text color="muted" data-testid="attachment">
          {email} uploaded
          {attachment && (
            <>
              {' '}
              {attachment.fileName}{' '}
              <Link onClick={handleDownloadLink}>download</Link>
            </>
          )}
        </Text>
        <NoWrapText component="div" color="muted">
          {formatMoment(timestamp)}
        </NoWrapText>
      </CustomPanel>
    );
  }
  return (
    <CustomPanel hasBorder fillWidth>
      <Container
        fillWidth
        justifyContent="space-between"
        padding="none"
        gap="md"
      >
        <Container
          alignItems="start"
          justifyContent="start"
          orientation="vertical"
          padding="none"
        >
          <Text align="left" component="div" weight="bold">
            {fullName ?? email}
          </Text>
          {isClickHouse && (
            <Text component="div" color="muted">
              ClickHouse
            </Text>
          )}
        </Container>
        <NoWrapText align="left" fillWidth component="div" color="muted">
          {formatMoment(timestamp)}
        </NoWrapText>
      </Container>
      <ReadMore maxLength={256}>{reply}</ReadMore>
    </CustomPanel>
  );
}

function CaseTimeline({
  events = [],
  caseId
}: {
  events: Array<SupportCaseEvent>;
  caseId: string;
}): ReactElement {
  const [openReplyModal, toggleReplyModal] = useState(false);
  return (
    <Container
      orientation="vertical"
      padding="none"
      gap="xl"
      alignItems="start"
      maxWidth="72%"
    >
      <Container fillWidth justifyContent="space-between" isResponsive={false}>
        <Title type="h3">Case timeline</Title>
        <Button type="secondary" onClick={(): void => toggleReplyModal(true)}>
          Reply
        </Button>
      </Container>
      <VerticalStepper type="bulleted">
        {events.map((event, index) => {
          if (
            event.type === 'status' &&
            ['WAITING_ON_CUSTOMER', 'WAITING_ON_CLICKHOUSE'].includes(
              event.status
            )
          ) {
            return null;
          }
          return (
            <>
              <VerticalStepper.Step
                key={`event-${index}`}
                collapsed={false}
                status="incomplete"
                data-testid="case-timeline"
              >
                <StepperLabel {...event} />
              </VerticalStepper.Step>
              <Spacer size="sm" />
            </>
          );
        })}
      </VerticalStepper>

      {openReplyModal && (
        <ReplyModal
          onCancel={(): void => toggleReplyModal(false)}
          caseId={caseId}
        />
      )}
    </Container>
  );
}

export default CaseTimeline;
