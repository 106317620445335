import { ReactElement } from 'react';

import { Toolbar as PrimitiveToolbar } from 'primitives';

import DatabaseSelector from 'src/components/DatabaseSelector';

import { useEditTable } from 'src/components/CreateTableView/context';
import { Text } from '@clickhouse/click-ui';
import { flex } from 'src/lib/utility-styles';
import { paddingStyle } from 'src/components/global-styles';

interface NameProps {
  name: string;
}

function Name({ name }: NameProps): ReactElement {
  return (
    <div css={[flex, paddingStyle({ left: 12 })]}>
      <Text size="md" color="muted" weight="medium">
        Create table
      </Text>
      {name && (
        <Text size="md" weight="medium">
          {' '}
          - {name}
        </Text>
      )}
    </div>
  );
}

export function Toolbar(): ReactElement {
  const { tableState } = useEditTable();
  return (
    <div>
      <PrimitiveToolbar nameElement={<Name name={tableState.name} />}>
        <DatabaseSelector width={168} data-testid="database-selector" />
      </PrimitiveToolbar>
    </div>
  );
}
