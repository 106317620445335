import { Select, SelectProps, Tooltip } from '@clickhouse/click-ui';
import { css, SerializedStyles } from '@emotion/react';
import { ReactElement } from 'react';
import {
  useDatabases,
  useMetadataError,
  useMetadataLoading
} from 'src/metadata/metadataState';
import { useSelectedDatabase } from 'src/metadata/selectedDatabase';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useSelectedTab, useUpdateTab } from 'src/state/tabs';
import styled from 'styled-components';

const databaseSelectorStyle = (width?: number | string): SerializedStyles =>
  css({
    width,
    overflow: 'hidden'
  });

interface DatabaseSelectorProps extends Omit<SelectProps, 'children'> {
  width?: number | string;
}

const widthToString = (width: number | string): string =>
  typeof width === 'number' ? `${width}px` : width;

const CustomTrigger = styled(Tooltip.Trigger)<{ width?: number | string }>`
  width: 100%;
  max-width: ${({ width }): string => (width ? widthToString(width) : 'auto')};
`;

export default function DatabaseSelector({
  onSelect: onSelectProp,
  width,
  ...props
}: DatabaseSelectorProps): ReactElement {
  const databases = useDatabases();
  const metadataError = useMetadataError();
  const metadataLoading = useMetadataLoading();
  const { selectedDatabase: database, setSelectedDatabase } =
    useSelectedDatabase();
  const serviceId = useCurrentInstanceId();
  const selectedTab = useSelectedTab();
  const updateTab = useUpdateTab();

  const onChange = (newDb: string): void => {
    if (serviceId) {
      setSelectedDatabase(serviceId, newDb);
      if (selectedTab && 'database' in selectedTab) {
        updateTab(selectedTab.id, {
          ...selectedTab,
          database: newDb
        });
      }
    }
    onSelectProp && onSelectProp(newDb);
  };

  const component = (
    <Select
      value={database}
      disabled={metadataLoading}
      onSelect={onChange}
      error={!!metadataError}
      css={databaseSelectorStyle(width)}
      {...props}
      className="fs-exclude"
      useFullWidthItems
    >
      {databases.map(({ name }) => (
        <Select.Item
          value={name}
          key={name}
          icon="database"
          data-testid={`select-item-${encodeURIComponent(name)}`}
          className="fs-exclude"
        >
          {name}
        </Select.Item>
      ))}
    </Select>
  );

  return metadataError ? (
    <Tooltip>
      <CustomTrigger width={width}>{component}</CustomTrigger>
      <Tooltip.Content>{metadataError}</Tooltip.Content>
    </Tooltip>
  ) : (
    component
  );
}
