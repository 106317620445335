import { Icon } from '@clickhouse/click-ui';
import styled from 'styled-components';

const HighlightedIcon = styled(Icon)`
  svg {
    ${({ theme }) =>
      theme.name === 'dark'
        ? `
    color: ${theme.global.color.accent.default};
  `
        : `
    color: ${theme.global.color.text.default};
  `}
  }
`;

export default HighlightedIcon;
