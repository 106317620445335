import { ReactElement, useState } from 'react';
import {
  Button,
  Container,
  Dialog,
  Separator,
  Text,
  TextField
} from '@clickhouse/click-ui';
import { useCreateDashboard } from 'src/dashboard/dashboardController';
import { editingDashboardFamily } from 'src/state/dashboard';
import { Dashboard } from 'shared/src/types/dashboard';
import { useNavigate } from 'react-router-dom';

interface CreateDashboardDialogProps {
  onClose: () => void;
}

type ValidationResult = {
  isValid: boolean;
  message: string;
};

const validateDashboardName = (dashboardName: string): ValidationResult => {
  if (!dashboardName) {
    return {
      isValid: false,
      message: 'Please enter a dashboard name'
    };
  }

  return {
    isValid: true,
    message: ''
  };
};

export const CreateDashboardDialog = ({
  onClose
}: CreateDashboardDialogProps): ReactElement => {
  const [dashboardName, setDashboardName] = useState<string>('');
  const [creationError, setCreationError] = useState<string>('');
  const createDashboard = useCreateDashboard();
  const navigate = useNavigate();

  const handleNameChange = (newName: string): void => {
    const validationResult = validateDashboardName(newName);
    setCreationError(validationResult.message);
    setDashboardName(newName);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === 'Enter') {
      handleSubmit();
    }

    if (evt.key === 'Escape') {
      onClose();
    }
  };

  const handleSubmit = (): void => {
    const validationResult = validateDashboardName(dashboardName);

    if (!validationResult.isValid) {
      setCreationError(validationResult.message);
      return;
    }

    createDashboard(dashboardName)
      .then((dashboard: Dashboard) => {
        editingDashboardFamily({ id: dashboard.id, init: true }); // initialize editing atom to true
        navigate(dashboard.id);
        onClose();
      })
      .catch((error) => {
        console.log(error);

        if (error instanceof Error) {
          setCreationError(error.message);
        }
      });
  };

  return (
    <Dialog open={true}>
      <Dialog.Content
        onClose={onClose}
        onKeyDown={handleKeyDown}
        showClose={true}
        title="Create new dashboard"
      >
        <Container fillWidth={true} gap="md" orientation="vertical">
          <Text color="muted">
            Plot your data into visually rich dashboards.
          </Text>
          <TextField
            autoFocus={true}
            error={creationError}
            label="Dashboard name"
            onChange={handleNameChange}
            placeholder="Name your dashboard"
            type="text"
            value={dashboardName}
          />
          <Separator size="sm" />
          <Container gap="md" justifyContent="end" orientation="horizontal">
            <Dialog.Close label="Cancel" onClick={onClose} />
            <Button onClick={handleSubmit}>Create dashboard</Button>
          </Container>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
