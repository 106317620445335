import React, { useEffect, useRef } from 'react';
import * as monaco from 'monaco-editor';

interface DiffEditorProps {
  originalText: string;
  modifiedText: string;
  sideBySide: boolean;
}

/**
 * A React component that displays a diff editor for comparing two blocks of text.
 * @param originalText The original block of text to compare
 * @param modifiedText The modified block of text to compare
 */
const DiffEditor: React.FC<DiffEditorProps> = ({
  originalText,
  modifiedText,
  sideBySide
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const diffEditor = useRef<monaco.editor.IDiffEditor | null>(null);

  useEffect(() => {
    // Create the diff editor
    // FIXME: Disabling the eslint here as this will be out of scope of this PR, but this needs to be addressed asap as this can be a root cause of crashes
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    diffEditor.current = monaco.editor.createDiffEditor(containerRef.current!, {
      readOnly: true,
      fontFamily: 'Inconsolata',
      fontWeight: '500',
      fontSize: 16,
      automaticLayout: true,
      renderSideBySide: sideBySide
    });
    return () => {
      // Dispose of the diff editor when the component unmounts
      if (diffEditor.current) {
        diffEditor.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (!diffEditor.current) {
      return;
    }

    // Create the original and modified models
    const originalModel = monaco.editor.createModel(originalText, 'chdb');
    const modifiedModel = monaco.editor.createModel(modifiedText, 'chdb');

    // Set the original and modified models in the diff editor
    diffEditor.current.setModel({
      original: originalModel,
      modified: modifiedModel
    });
  }, [originalText, modifiedText, diffEditor.current]);

  useEffect(() => {
    // Update the side-by-side setting when the prop changes
    if (!diffEditor.current) {
      return;
    }
    diffEditor.current.updateOptions({
      renderSideBySide: sideBySide
    });
  }, [sideBySide]);

  return <div ref={containerRef} style={{ height: '100%' }} />;
};

export default DiffEditor;
