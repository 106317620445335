// @ts-nocheck
import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import { css, useTheme } from '@emotion/react';

import {
  Container,
  SearchField,
  Select,
  Separator,
  Text
} from '@clickhouse/click-ui';

import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { ChartData } from 'src/lib/chart';
import { chartMap } from 'src/lib/chart/config';
import { ChartConfig } from 'src/lib/chart/types';
import { alignCenter, flex, justifyCenter } from 'src/lib/utility-styles';
import { paddingStyle } from 'global-styles';
import { logEvent } from 'src/components/ChartConfigSidebar/analytics';
import ColumnList from 'src/components/ChartConfigSidebar/ColumnList';
import DataDrop from 'src/components/ChartConfigSidebar/DataDrop';
import DragLayer from 'src/components/ChartConfigSidebar/DragLayer';

import {
  axisColumnContainer,
  chartSelectStyle,
  columnListDroppableStyle,
  columnListSectionStyle,
  formElementStyle
} from 'src/components/ChartConfigSidebar/styles';
import { ResultError } from 'src/lib/clickhouse/query';

type DragDropItem = {
  column: string;
  dataType: string;
  type: 'column' | 'columnArray';
  axis: string;
};

interface GeneralChartConfigProps {
  chartConfig: ChartConfig;
  data: ChartData | ResultError | null;
  loading: boolean;
  portalId: string;
  updateChart: (config: ChartConfig) => void;
  type: string;
}

export default function GeneralChartConfig({
  chartConfig,
  data,
  loading,
  portalId,
  type,
  updateChart
}: GeneralChartConfigProps): ReactElement {
  const [columnSearch, setColumnSearch] = useState('');
  const [hoveringData, setHoveringData] = useState(false);

  const theme = useTheme();
  const colors = theme.colors.chart.values;

  const chartDataConfig = useMemo(
    () => (chartMap[type] ? chartMap[type].data : undefined),
    [type]
  );

  const onDragEnd = (item: DragDropItem, dropResult, source) => {
    if (!chartDataConfig) {
      return;
    }

    const destination = dropResult.dropZone;
    if (destination === 'columns' && item.axis) {
      const column =
        typeof item.column === 'object'
          ? get(item, 'column.column')
          : item.column;
      return removeColumn({ column, source: item.axis });
    }

    let column = item.column;
    if (column && typeof column === 'object') {
      column = column.column;
    }

    const config = { ...chartConfig };

    if (source === destination) {
      return;
    }

    if (chartDataConfig[destination]) {
      if (chartDataConfig[destination].dataType !== 'any') {
        if (chartDataConfig[destination].dataType !== item.dataType) {
          return;
        }
      }
    }

    if (chartDataConfig[source]) {
      if (chartDataConfig[source].type === 'column') {
        config[source] = null;
      }

      if (chartDataConfig[source].type === 'columnArray') {
        config[source] = config[source].filter(
          ({ column: columnName }) => columnName !== column
        );
      }
    }

    if (chartDataConfig[destination]) {
      if (chartDataConfig[destination].type === 'column') {
        config[destination] = column;
      }

      if (chartDataConfig[destination].type === 'columnArray') {
        if (!config[destination]) {
          config[destination] = [];
        }

        config[destination] = config[destination].concat([
          {
            column,
            color: colors[config[destination].length % colors.length]
          }
        ]);
      }
    }

    updateChart(config);
  };

  const editColumn = ({ column, source, config }) => {
    const newChartConfig = cloneDeep(chartConfig);
    if (chartDataConfig[source]) {
      let axis = newChartConfig[source];
      if (chartDataConfig[source].type === 'column') {
        if (axis && typeof axis === 'object' && axis.column === column) {
          axis = {
            ...axis,
            ...config
          };
        }
      }

      if (chartDataConfig[source].type === 'columnArray') {
        const index = findIndex(axis, (col) => col.column === column);
        if (index > -1) {
          axis[index] = {
            ...axis[index],
            ...config
          };
        }
      }

      newChartConfig[source] = axis;

      updateChart(newChartConfig);
    }
  };

  const removeColumn = ({ column, source }) => {
    const config = { ...chartConfig };

    if (chartDataConfig[source].type === 'column') {
      config[source] = null;
    }

    if (chartDataConfig[source].type === 'columnArray') {
      config[source] = config[source].filter(
        ({ column: columnName }) => columnName !== column
      );
    }

    updateChart(config);
  };

  const renderAxisConfig = useCallback(
    (config, axis, index) => {
      return (
        <DataDrop
          axis={axis}
          chartConfig={config}
          data={data}
          dataConfig={chartDataConfig[axis]}
          editColumn={editColumn}
          key={index}
          onDragEnd={onDragEnd}
          removeColumn={removeColumn}
          setHoveringData={setHoveringData}
        />
      );
    },
    [data, chartDataConfig, editColumn, onDragEnd, removeColumn]
  );

  return (
    <Container grow="1" orientation="vertical">
      <div css={css(formElementStyle, chartSelectStyle)}>
        {/* Chart select */}
        <Select
          label="Select chart type"
          onSelect={(value: string): void => {
            if (value === chartConfig.chartType) {
              return;
            }

            const previousChartDataConfig =
              get(chartMap, `${chartConfig.chartType}.data`) ||
              get(chartMap, 'bar.data');
            const newChart = {
              ...chartConfig,
              chartType: value
            };
            const chartDataConfig = get(chartMap, `${value}.data`);
            if (
              !isEqual(previousChartDataConfig, chartDataConfig) &&
              chartConfig
            ) {
              Object.keys(chartDataConfig).forEach((key) => {
                newChart[key] = undefined;
              });
            }

            if (
              chartMap[value].appearance &&
              get(chartMap[value], 'defaults.appearance')
            ) {
              Object.entries(
                get(chartMap[value], 'defaults.appearance')
              ).forEach(([key, value]) => {
                if (newChart[key] === undefined) {
                  newChart[key] = value;
                }
              });
              updateChart(newChart);
            }

            updateChart(newChart);
            logEvent('dashboardChartSelectorSelect', 'click');
          }}
          value={type}
          onClick={(): void => logEvent('chartSelectorFocus', 'trigger')}
          onBlur={(): void => logEvent('chartSelectorBlur', 'trigger')}
        >
          <Select.Item value="area">Area</Select.Item>
          <Select.Item value="bar">Bar</Select.Item>
          <Select.Item value="hbar">Horizontal bar</Select.Item>
          <Select.Item value="sbar">Stacked bar</Select.Item>
          <Select.Item value="shbar">Stacked horizontal bar</Select.Item>
          <Select.Item value="line">Line</Select.Item>
          <Select.Item value="scatter">Scatter</Select.Item>
          <Select.Item value="pie">Pie</Select.Item>
          <Select.Item value="doughnut">Doughnut</Select.Item>
          <Select.Item value="heatMap">Heat map</Select.Item>
        </Select>
      </div>
      <Separator size="md" />
      {/* Data columns */}
      {data && data.columns && data.columns.length > 0 && (
        <>
          <div css={columnListSectionStyle} className="column-list">
            <SearchField
              label="Specify columns for the chart"
              onChange={(value: string): void => {
                setColumnSearch(value);
              }}
              placeholder="Search available columns"
              value={columnSearch}
              onFocus={(): void => logEvent('columnSearchFocus', 'trigger')}
              onBlur={(): void => logEvent('columnSearchBlur', 'trigger')}
            />
            <div>
              <ColumnList
                chartConfig={chartConfig}
                chartDataConfig={chartDataConfig}
                columnSearch={columnSearch}
                data={data}
                onDragEnd={onDragEnd}
              />
            </div>
          </div>
          <Separator size="md" />
        </>
      )}
      {/* Chart columns */}
      {data && data.columns && data.columns.length > 0 && (
        <div
          css={css(columnListDroppableStyle, formElementStyle)}
          className="column-list-droppable"
        >
          {chartDataConfig && !loading && (
            <div
              css={axisColumnContainer}
              onMouseEnter={(): void => setHoveringData(true)}
              onMouseLeave={(): void => setHoveringData(false)}
            >
              {Object.keys(chartDataConfig).map((key, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <Separator size="md" />}
                  <div
                    css={css`
                      padding: 10px;
                    `}
                  >
                    {renderAxisConfig(chartConfig, key, index)}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
          {chartDataConfig && !loading && (
            <DragLayer
              hoveringData={hoveringData}
              portalId={portalId}
              width={189}
            />
          )}
          {loading && (
            <Text
              css={[flex, alignCenter, justifyCenter, paddingStyle('1rem')]}
            >
              Loading...
            </Text>
          )}
        </div>
      )}
      {data && data.error && (
        <Container>
          <pre>{data.error}</pre>
        </Container>
      )}
    </Container>
  );
}
