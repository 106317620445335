import { Container } from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import { Dashboard } from 'shared/src/types/dashboard';
import { DashboardFlyout } from 'src/components/Dashboards/DashboardView/DashboardFlyout';
import DashboardComponent from 'src/components/Dashboards/DashboardView/Dashboard';
import { useUpdateDashboardConfig } from 'src/dashboard/dashboardController';
import useElementSize from 'src/lib/hooks';
import {
  useCloseDashboardFlyout,
  useDashboardFlyoutOpen
} from 'src/state/dashboard';
import {
  useUpdateDashboardFilterOutput,
  useUpdateDashboardFilterState
} from 'src/state/dashboard/dashboardState';

interface DashboardViewProps {
  dashboard: Dashboard;
  editable: boolean;
}

export default function DashboardView({
  dashboard,
  editable
}: DashboardViewProps): ReactElement {
  const updateDashboardConfig = useUpdateDashboardConfig();
  const [containerRef, { width }] = useElementSize();
  const closeDashboardFlyout = useCloseDashboardFlyout();
  const isFlyoutOpen = useDashboardFlyoutOpen();

  const updateState = useUpdateDashboardFilterState({
    dashboardId: dashboard.id
  });
  const updateOutput = useUpdateDashboardFilterOutput({
    dashboardId: dashboard.id
  });

  const initDashboardState = () => {
    Object.entries(dashboard.config.dashboardFilters.config).forEach(
      ([field, filterConfig]) => {
        if (filterConfig.defaultValue !== undefined) {
          updateState(field, {
            value: filterConfig.defaultValue
          });
          updateOutput(field, {
            value: filterConfig.defaultValue
          });
        }
      }
    );
  };

  useEffect(() => {
    initDashboardState();
  }, []);

  return (
    <Container
      id="dashboard-main-view"
      orientation="horizontal"
      ref={containerRef}
      minHeight="100%"
      minWidth="100%"
      data-testid="dashboard"
      onClick={closeDashboardFlyout}
      alignItems="start"
    >
      <DashboardComponent
        dashboard={dashboard}
        editable={editable}
        updateDashboardConfig={updateDashboardConfig}
        width={width}
      />
      {isFlyoutOpen && dashboard && (
        <DashboardFlyout
          config={dashboard.config}
          dashboardId={dashboard.id}
          editable={editable}
        />
      )}
    </Container>
  );
}
