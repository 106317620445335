import { ConfirmUpdatedPaymentMethodResponse } from '@cp/common/protocol/Billing';
import { useCallback, useState } from 'react';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';

type ConfirmUpdatedPaymentMethodFunction = (
  organizationId: string,
  paymentMethodUpdateId: string
) => Promise<ConfirmUpdatedPaymentMethodResponse>;

export interface ConfirmUpdatedPaymentMethod {
  confirmUpdatedPaymentMethod: ConfirmUpdatedPaymentMethodFunction;
  isLoading: boolean;
}
export const useConfirmUpdatedPaymentMethod = (): ConfirmUpdatedPaymentMethod => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);
  const confirmUpdatedPaymentMethod = useCallback<ConfirmUpdatedPaymentMethodFunction>(
    async (organizationId: string, paymentMethodUpdateId: string): Promise<ConfirmUpdatedPaymentMethodResponse> => {
      try {
        setIsLoading(true);
        return await apiClient.billing.confirmUpdatedPaymentMethod(organizationId, paymentMethodUpdateId);
      } catch (e) {
        // TODO: I'm not sure we need to keep this toast, considering we'll probably show the error in the modal
        createToast(
          'There was a problem updating your billing details, please try again later',
          'alert',
          errorMessage(e)
        );
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [apiClient.billing]
  );
  return { confirmUpdatedPaymentMethod, isLoading };
};
