import { DataImport } from '@prisma/client';
import { FileUploadImport, FileUrlImport, ImportStatus, SampleDataImport } from 'shared/src/dataLoading';
import { formatTimestampWithSeconds } from 'src/lib/formatters/dateTimeFormatter';
import { ChunkSummary, DataImportWithChunks } from 'types/protocol/getDataImportProtocol';

export interface DataImportModel extends DataImport {
  isQueued: () => boolean;
  isStopped: () => boolean;
  isFailed: () => boolean;
  isImporting: () => boolean;
  isRetryable: () => boolean;
  isSuccessful: () => boolean;
  getImportModel: () => FileUrlImport | SampleDataImport | FileUploadImport;
  getLastAttempt: () => string;
  getLastAttemptFormatted: () => string;
  unwrap: () => DataImport;
}

export interface DataImportWithChunksModel extends Omit<DataImportModel, 'unwrap'> {
  unwrap: () => DataImportWithChunks;
  chunks: () => Array<ChunkSummaryModel>;
}

export type ChunkSummaryModel = ChunkSummary & {
  isRetryable: () => boolean;
  unwrap: () => ChunkSummary;
  getLastAttemptFormatted: () => string;
};

export function isRetryable(status: ImportStatus): boolean {
  return ['failed', 'stopped', 'canceled', 'failed_splitting'].includes(status as string);
}

export function isImporting(status: ImportStatus): boolean {
  return ['importing', 'splitting', 'queued_for_importing'].includes(status as string);
}

export function isFailed(status: ImportStatus): boolean {
  return ['failed', 'failed_splitting'].includes(status as string);
}

export function isStopped(status: ImportStatus): boolean {
  return ['stopped', 'canceled'].includes(status as string);
}

export function isQueued(status: ImportStatus): boolean {
  return ['queued'].includes(status as string);
}

export function isSuccessful(status: ImportStatus): boolean {
  return ['success'].includes(status as string);
}

const extractModelFromDataImport = (dataImport: DataImport): FileUrlImport | SampleDataImport | FileUploadImport => {
  return dataImport.data as unknown as FileUrlImport | SampleDataImport | FileUploadImport;
};

export function getLastAttempt(dataImport: DataImportWithChunks | DataImport): string {
  const chunks = 'chunks' in dataImport ? dataImport.chunks : [];
  const lastChunkAttempt = chunks
    .filter((chunk) => !!chunk.lastAttempt)
    .sort(
      (a: ChunkSummary, b: ChunkSummary) =>
        new Date(a.lastAttempt as unknown as string).getTime() - new Date(b.lastAttempt as unknown as string).getTime()
    );
  if (lastChunkAttempt.length === 0) {
    return dataImport.createdAt as unknown as string;
  } else {
    return lastChunkAttempt[lastChunkAttempt.length - 1].lastAttempt as unknown as string;
  }
}

export function getLastAttemptFormatted(timestamp: string | Date | undefined): string {
  if (!timestamp) {
    return '';
  }

  return formatTimestampWithSeconds(timestamp);
}

export function buildDataImportModel(dataImport: DataImport): DataImportModel {
  const proxy = Object.create(dataImport) as DataImportModel;
  const status = dataImport.status as ImportStatus;
  proxy.isQueued = (): boolean => isQueued(status);
  proxy.isStopped = (): boolean => isStopped(status);
  proxy.isFailed = (): boolean => isFailed(status);
  proxy.isImporting = (): boolean => isImporting(status);
  proxy.isRetryable = (): boolean => isRetryable(status);
  proxy.isSuccessful = (): boolean => isSuccessful(status);
  proxy.getImportModel = (): FileUrlImport | SampleDataImport | FileUploadImport =>
    extractModelFromDataImport(dataImport);
  proxy.unwrap = (): DataImport => dataImport;
  proxy.getLastAttempt = (): string => getLastAttempt(dataImport);
  proxy.getLastAttemptFormatted = (): string => getLastAttemptFormatted(getLastAttempt(dataImport));
  return proxy;
}

export function buildChunkSummaryModel(chunk: ChunkSummary): ChunkSummaryModel {
  const proxy = Object.create(chunk) as ChunkSummaryModel;
  proxy.isRetryable = (): boolean => ['failed_importing', 'failed_splitting'].includes(chunk.status);
  proxy.unwrap = (): ChunkSummary => chunk;
  proxy.getLastAttemptFormatted = (): string => getLastAttemptFormatted(chunk.lastAttempt as unknown as string);
  return proxy;
}

export function buildDataImportWithChunksModel(
  dataImport: DataImportWithChunks | DataImport
): DataImportWithChunksModel {
  const proxy = buildDataImportModel(dataImport) as DataImportWithChunksModel;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  proxy.unwrap = (): DataImportWithChunks => dataImport as DataImportWithChunks;
  proxy.chunks = (): Array<ChunkSummaryModel> =>
    'chunks' in dataImport ? dataImport.chunks.map((chunk) => buildChunkSummaryModel(chunk)) : [];
  proxy.isRetryable = (): boolean => {
    const onRetryableStatus = isRetryable(dataImport.status as ImportStatus);
    const anyRetryableChunk = proxy.chunks().some((chunk) => chunk.isRetryable());
    return onRetryableStatus || anyRetryableChunk;
  };
  return proxy;
}
