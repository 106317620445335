import { TableState, TableMapping } from 'shared/src/dataLoading';

function validateNewTable(config: TableState, tableNames: ReadonlyArray<string>): ReadonlyArray<string> {
  const errors = [];

  const tableName = config.name;
  if (!tableName) {
    errors.push('Table name is required.');
  }
  if (tableNames.includes(tableName)) {
    errors.push(`Table with name ${tableName} already exists`);
  }

  const columns = Object.values(config.columns).filter((column) => column.removed !== true);
  if (columns.length === 0) {
    errors.push('Please specify at least 1 column');
  }
  if (columns.some((column) => !column.type)) {
    errors.push('Please specify a type for each column');
  }
  if (columns.some((column) => !column.name)) {
    errors.push('Please specify a name for each column');
  }

  if (
    config.engine.type === 'ReplacingMergeTree' &&
    (!config.engine.versionColumnId || !config.engine.versionColumnId.length)
  ) {
    errors.push('Please specify a version column for ReplacingMergeTree tables');
  }

  if (config.engine.type === 'ReplacingMergeTree' && !(config.orderBy && config.orderBy.length > 0)) {
    errors.push('Please specify at least one sorting key for ReplacingMergeTree tables');
  }

  return errors;
}

function validateExistingTable(config: TableMapping): ReadonlyArray<string> {
  const errors = [];
  if (!(config.name && config.name.length)) {
    errors.push('Please select the target table');
  }

  if (!config.columnsMapping.length || !config.columnsMapping.filter((mapping) => mapping.target).length) {
    errors.push('Please specify at least 1 mapping');
  }

  if (config.columnsMapping.some((mapping) => !mapping.target && !mapping.removed)) {
    errors.push('Please specify a target column for each active mapping');
  }

  const columnNames = config.columnsMapping
    .filter((mapping) => !mapping.removed)
    .map((mapping) => mapping.target?.name);
  if (new Set(columnNames).size !== columnNames.length) {
    errors.push('Destination column can be mapped only once');
  }

  return errors;
}

export function validate(
  tableType: 'existing' | 'newTable',
  config: TableState | TableMapping,
  tableNames: ReadonlyArray<string>
): ReadonlyArray<string> {
  if (tableType === 'existing') {
    return validateExistingTable(config as TableMapping);
  } else {
    return validateNewTable(config as TableState, tableNames);
  }
}
