import React, { MouseEvent, ReactNode, useState } from 'react';

import {
  ClickLocation,
  getContainingCellPosition
} from 'src/components/primitives/lib/Spreadsheet/clickLocation';
import {
  cellRectSelected,
  cellSelected,
  rectangleSelection
} from 'src/components/primitives/lib/Spreadsheet/selectionActions';
import {
  OnSelectionContextMenuType,
  Option,
  Rectangle,
  SelectionPos
} from 'src/components/primitives/lib/Spreadsheet/types';
import { SelectedRegion } from 'src/components/primitives/lib/Spreadsheet/types';
import { ContextMenu, Text } from '@clickhouse/click-ui';

interface Props {
  children: ReactNode;
  getMenuOptions: (selection: SelectedRegion) => Option[];
  selection: SelectedRegion;
  bounds: Rectangle;
  containerRef: React.RefObject<HTMLElement>;
  onSelectionContextMenu: OnSelectionContextMenuType;
  onSelectCell: (pos: SelectionPos) => void;
}

function clickedOnSelection(
  location: ClickLocation,
  selection: SelectedRegion,
  bounds: Rectangle
): boolean | undefined {
  if (location.type === 'cell') {
    return cellSelected(selection, location.column, location.row);
  } else if (location.type === 'columnHeader') {
    return cellRectSelected(
      selection,
      location.column,
      bounds.top,
      location.column,
      bounds.bottom
    );
  } else if (location.type === 'rowHeader') {
    return cellRectSelected(
      selection,
      bounds.left,
      location.row,
      bounds.right,
      location.row
    );
  }
}

export default function DataContextMenu({
  getMenuOptions,
  children,
  selection,
  bounds,
  onSelectionContextMenu,
  onSelectCell,
  containerRef
}: Props): JSX.Element {
  const [_open, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const openMenuForSelection = (selection: SelectedRegion): void => {
    const options = getMenuOptions(selection);
    const modifiedOptions = options.map((opt) => {
      return {
        ...opt,
        onClick: (): void => {
          opt.onClick?.();
          setOpen(false);
        }
      };
    });
    setOptions(modifiedOptions);
    setOpen(true);
  };

  return (
    <ContextMenu onOpenChange={onSelectionContextMenu}>
      <ContextMenu.Trigger
        onContextMenu={(e: MouseEvent<HTMLElement>): void => {
          if (e.target instanceof HTMLElement && containerRef.current) {
            const location = getContainingCellPosition(
              e.target,
              containerRef.current
            );
            if (location) {
              if (clickedOnSelection(location, selection, bounds)) {
                openMenuForSelection(selection);
              } else if (location.type === 'cell') {
                // get options for single cell outside selection
                onSelectCell({ column: location.column, row: location.row });
                openMenuForSelection(
                  rectangleSelection(
                    location.column,
                    location.row,
                    location.column,
                    location.row
                  )
                );
              }
            }
          }
        }}
        onClick={(): void => {
          setOpen(false);
        }}
      >
        {children}
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        {options.map((option) => (
          <ContextMenu.Item onClick={option.onClick} key={option.label}>
            <Text>{option.label}</Text>
            <Text color="muted">{option.hotKey}</Text>
          </ContextMenu.Item>
        ))}
      </ContextMenu.Content>
    </ContextMenu>
  );
}
