import {
  Button,
  Container,
  InfoAlert,
  WarningAlert,
  Link,
  Text
} from '@clickhouse/click-ui';
import omit from 'lodash/omit';
import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
  ReactEventHandler
} from 'react';

import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  Size
} from 'react-virtualized';
import { logger } from 'src/lib/logger';
import { Table } from 'shared/src/clickhouse/types';
import {
  useIsMetadataLoaderVisible,
  useMaterializedViews,
  useMetadataError,
  useTables,
  useViews,
  useMetadataLoading
} from 'src/metadata/metadataState';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import {
  useCreateTable,
  useSelectedTab,
  useSelectedTable,
  useTabActions
} from 'src/state/tabs';
import { TableRightBarOption, TableTab } from 'src/state/tabs/types';
import ExpandableSection from 'src/components/TableView/TableList/ExpandableSection';
import {
  fittedHeights,
  sectionHeight
} from 'src/components/TableView/TableList/fittedHeights';

import styles from 'src/components/TableView/TableList/styles';
import TableListSearch from 'src/components/TableView/TableList/TableListSearch';
import TableLoader from 'src/components/TableView/TableList/TableLoader';
import { loadDatabaseMetadata } from 'src/components/MetadataInitializer/metadataEmitter';
import { useCurrentInstance } from 'src/instance/instanceController';
import { useQueryRunner } from 'src/lib/clickhouse/query';
import { useWakeService } from 'src/state/service/wakeService';

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
interface TableListProps {
  selectedTable?: Table | null;
}

interface TableListWithTabInfoProps extends TableListProps {
  tableRightBarType: TableRightBarOption[] | undefined;
  tabTableName: string | undefined;
}

function logEvent(name: string, type: 'Focus' | 'Blur'): void {
  logger.track({
    view: 'sidebar',
    component: 'tableList',
    event: `${name}${type}`,
    interaction: 'trigger'
  });
}

function searchCollection<T extends { tableName: string }>(
  search: string,
  collection: T[] | undefined
): T[] {
  if (!collection) {
    return [];
  } else {
    return collection.filter((item) =>
      item.tableName.toLowerCase().includes(search.toLowerCase())
    );
  }
}
type SectionType = 'tables' | 'views' | 'materializedViews';

type ListOfTablesProps = {
  search: string;
  tableRightBarType?: TableRightBarOption[];
  selectedTable?: Table | null;
  tabTableName?: string;
};

function ListOfTables({
  search,
  tableRightBarType,
  selectedTable,
  tabTableName
}: ListOfTablesProps): ReactElement | null {
  const tables = useTables();
  const views = useViews();
  const materializedViews = useMaterializedViews();
  const { addOrUpdateTable } = useTabActions();
  const currentInstance = useCurrentInstance();
  const isIdle = currentInstance?.state === 'idle';
  const isIdleAndNoTables = isIdle && tables.length === 0;
  const isLoaderVisible = useIsMetadataLoaderVisible();
  const isMetadataLoading = useMetadataLoading();
  const metadataError = useMetadataError();
  const { wakeupService } = useWakeService();
  const awakeServiceRunner = useQueryRunner({
    sql: 'SELECT 1',
    options: { wakeService: true }
  });

  const [expandedItems, setExpandedItems] = useState<
    Record<SectionType, Record<string, boolean>>
  >({
    tables: {},
    views: {},
    materializedViews: {}
  });
  const [expandedSections, setExpandedSections] = useState<
    Record<SectionType, boolean>
  >({
    tables: true,
    views: false,
    materializedViews: false
  });

  const filteredTables = useMemo(() => {
    return searchCollection(search, tables);
  }, [tables, search]);

  const filteredViews = useMemo(() => {
    return searchCollection(search, views);
  }, [views, search]);

  const filteredMatViews = useMemo(() => {
    return searchCollection(search, materializedViews);
  }, [materializedViews, search]);

  const removeTableSchema = (
    rightBarType: TableRightBarOption[] = []
  ): TableRightBarOption[] => {
    return rightBarType.filter((option) => option !== 'tableSchema');
  };

  const getHeightsPerSection = useCallback(
    (height: number) => {
      const sections = [
        {
          name: 'tables',
          expanded: expandedSections['tables'],
          height: sectionHeight(
            filteredTables.map((table) => ({
              ...table,
              detailsAreOpen: expandedItems.tables[table.tableName]
            })),
            expandedSections['tables']
          )
        },
        {
          name: 'views',
          expanded: expandedSections['views'],
          height: sectionHeight(
            filteredViews.map((view) => ({
              ...view,
              detailsAreOpen: expandedItems.views[view.tableName]
            })),
            expandedSections['views']
          )
        },
        {
          name: 'materializedViews',
          expanded: expandedSections['materializedViews'],
          height: sectionHeight(
            filteredMatViews.map((view) => ({
              ...view,
              detailsAreOpen: expandedItems.materializedViews[view.tableName]
            })),
            expandedSections['materializedViews']
          )
        }
      ];

      const elementsHeight =
        (filteredTables.length > 0 ? 20 : 0) +
        (filteredViews.length > 0 ? 20 : 0) +
        (filteredMatViews.length > 0 ? 20 : 0);

      return fittedHeights(
        sections,
        height - elementsHeight - 8 * (elementsHeight % 20)
      );
    },
    [
      expandedSections,
      expandedItems,
      filteredTables,
      filteredViews,
      filteredMatViews
    ]
  );

  const onTableChange = useCallback(
    (table: Table, rightBarType?: 'tableSchema') => {
      const newTab: Partial<TableTab> = {};
      const rightBar = tableRightBarType ?? [];
      if (rightBarType) {
        if (rightBar.includes(rightBarType)) {
          newTab.rightBarType = removeTableSchema(rightBar);
        } else {
          newTab.rightBarType = rightBar;
        }
        newTab.rightBarType.push(rightBarType);
      } else if (rightBar.includes('tableSchema')) {
        newTab.rightBarType = removeTableSchema(rightBar);
      }
      addOrUpdateTable(table, newTab);
    },
    [addOrUpdateTable, tableRightBarType]
  );

  const onToggleItem =
    (type: SectionType) =>
    (tableName: string): void => {
      setExpandedItems((state) => {
        if (state[type][tableName]) {
          state[type] = omit(state[type], tableName);
          return { ...state };
        } else {
          return { ...state, [type]: { ...state[type], [tableName]: true } };
        }
      });
    };

  const awakeServiceCallback: ReactEventHandler = (event): void => {
    event.preventDefault();

    awakeServiceRunner().then(wakeupService).catch(console.error);

    logger.track({
      view: 'app',
      component: 'tableList',
      event: 'awakeServiceButtonClick',
      interaction: 'click'
    });
  };

  if (!currentInstance) {
    return null;
  }

  if (isLoaderVisible) {
    return <TableLoader />;
  }

  if (isIdleAndNoTables || (isIdle && metadataError)) {
    return (
      <InfoAlert
        text={
          <Text>
            {
              'The list of tables could not be fetched because the service is idle. '
            }
            <Link size="sm" onClick={awakeServiceCallback}>
              Wake service
            </Link>
          </Text>
        }
      />
    );
  }

  if (metadataError) {
    return <WarningAlert text={metadataError} />;
  }

  return (
    <AutoSizer>
      {({ height, width }: Size): ReactNode => {
        const sectionsHeight = getHeightsPerSection(height);
        return (
          <Container
            orientation="vertical"
            isResponsive={false}
            alignItems="start"
            justifyContent="start"
            gap="xs"
            data-testid={
              isMetadataLoading ? 'table-list-loading' : 'table-list-loaded'
            }
          >
            {!isLoaderVisible && (
              <ExpandableSection
                selected={selectedTable}
                tableRightBarType={tableRightBarType}
                collection={filteredTables}
                label={`Tables (${filteredTables.length})`}
                type="table"
                tableName={tabTableName}
                onTableChange={onTableChange}
                width={width}
                height={sectionsHeight.tables}
                open={expandedSections.tables}
                onOpenChange={(open: boolean): void =>
                  setExpandedSections((state) => {
                    return {
                      ...state,
                      tables: open
                    };
                  })
                }
                onToggleItem={onToggleItem('tables')}
                expandedCollections={expandedItems.tables}
              />
            )}
            {filteredViews.length > 0 && (
              <ExpandableSection
                selected={selectedTable}
                tableRightBarType={tableRightBarType}
                collection={filteredViews}
                label={`Views (${filteredViews.length})`}
                type="view"
                tableName={tabTableName}
                onTableChange={onTableChange}
                width={width}
                height={sectionsHeight.views}
                open={expandedSections.views}
                onOpenChange={(open: boolean): void =>
                  setExpandedSections((state) => {
                    return {
                      ...state,
                      views: open
                    };
                  })
                }
                onToggleItem={onToggleItem('views')}
                expandedCollections={expandedItems.views}
              />
            )}
            {filteredMatViews.length > 0 && (
              <ExpandableSection
                selected={selectedTable}
                tableRightBarType={tableRightBarType}
                collection={filteredMatViews}
                label={`Materialized Views (${filteredMatViews.length})`}
                type="materializedView"
                tableName={tabTableName}
                onTableChange={onTableChange}
                width={width}
                height={sectionsHeight.materializedViews}
                open={expandedSections.materializedViews}
                onOpenChange={(open: boolean): void =>
                  setExpandedSections((state) => {
                    return {
                      ...state,
                      materializedViews: open
                    };
                  })
                }
                onToggleItem={onToggleItem('materializedViews')}
                expandedCollections={expandedItems.materializedViews}
              />
            )}
          </Container>
        );
      }}
    </AutoSizer>
  );
}

const TableListWithTabInfo = React.memo(function TableListWithTabInfo({
  selectedTable,
  tableRightBarType,
  tabTableName
}: TableListWithTabInfoProps) {
  const { addCreateTableTab } = useTabActions();
  const database = useSelectedDatabaseValue();

  const currentInstance = useCurrentInstance();

  const tab = useCreateTable();
  const [search, setSearch] = useState('');

  const refreshBtnClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    e.preventDefault();

    if (!currentInstance) {
      console.error('no current service');
      return;
    }

    loadDatabaseMetadata({
      cached: false,
      database,
      serviceId: currentInstance.id
    });

    void logger.track({
      view: 'sidebar',
      component: 'tableList',
      event: 'refreshButtonClick',
      interaction: 'click'
    });
  };

  const onSearchChange = (inputValue: string): void => {
    setSearch(inputValue);
    void logger.track({
      view: 'sidebar',
      component: 'tableList',
      event: 'searchInput',
      interaction: 'click'
    });
  };

  return (
    <div css={styles.container(true)} data-testid="tables-sidebar">
      <div css={styles.newTableRow}>
        <Button
          iconLeft="table"
          fillWidth
          disabled={tab !== undefined}
          onClick={(): void => addCreateTableTab()}
          data-testid="sidebarNewTableBtn"
        >
          New table
        </Button>
      </div>
      <TableListSearch
        placeholder="Search resources"
        onBlur={(): void => logEvent('search', 'Blur')}
        onFocus={(): void => logEvent('search', 'Focus')}
        enableUnifiedConsole
        refreshBtnClick={refreshBtnClick}
        value={search}
        onChange={onSearchChange}
      />
      <div css={styles.tableListContainer}>
        <ListOfTables
          search={search}
          tableRightBarType={tableRightBarType}
          selectedTable={selectedTable}
          tabTableName={tabTableName}
        />
      </div>
    </div>
  );
});

export default function TableList(): ReactElement {
  const tab = useSelectedTab();
  const selectedTable = useSelectedTable();
  const tableRightBarType: TableRightBarOption[] | undefined =
    (tab && tab.type === 'table' && tab?.rightBarType) || undefined;
  const tabTableName =
    tab && tab.type === 'table' ? tab.table.tableName : undefined;
  return (
    <TableListWithTabInfo
      selectedTable={selectedTable}
      tableRightBarType={tableRightBarType}
      tabTableName={tabTableName}
    />
  );
}
