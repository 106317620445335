import { ReactElement } from 'react';
import { Button, Tooltip, createToast } from '@clickhouse/click-ui';

type CopyResultsToClipboardButtonProps = {
  text: string;
};

/**
 * A button component that copies the provided text to the clipboard when clicked.
 * @param {string} props.text - The text to copy to the clipboard when the button is clicked.
 */
const CopyResultsToClipboardButton = ({
  text
}: CopyResultsToClipboardButtonProps): ReactElement | null => {
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Button
          onClick={(): void => {
            navigator.clipboard
              .writeText(text)
              .then(() => {
                createToast({
                  title: 'Copied',
                  description: 'Copied to clipboard'
                });
              })
              .catch(console.error);
          }}
          iconLeft={'copy'}
          disabled={false}
          data-testid="copy-results-to-clipboard-button"
        >
          Copy to Clipboard
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        Copy the query results text to clipboard
      </Tooltip.Content>
    </Tooltip>
  );
};

export default CopyResultsToClipboardButton;
