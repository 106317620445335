import { CsvFormatType, FileType, JSONFormatType, TsvFormatType } from 'shared/src/dataLoading';

interface JsonOption {
  label: JSONFormatType;
  value: JSONFormatType;
}

const jsonOptions: JsonOption[] = [
  {
    label: 'JSONEachRow',
    value: 'JSONEachRow'
  }
];

interface CsvOption {
  label: CsvFormatType;
  value: CsvFormatType;
}

const csvOptions: CsvOption[] = [
  {
    label: 'CSV',
    value: 'CSV'
  },
  {
    label: 'CSVWithNamesAndTypes',
    value: 'CSVWithNamesAndTypes'
  },
  {
    label: 'CSVWithNames',
    value: 'CSVWithNames'
  }
];

interface TsvOption {
  label: TsvFormatType;
  value: TsvFormatType;
}

const tsvOptions: TsvOption[] = [
  {
    label: 'TabSeparated',
    value: 'TabSeparated'
  },
  {
    label: 'TabSeparatedWithNames',
    value: 'TabSeparatedWithNames'
  },
  {
    label: 'TabSeparatedWithNamesAndTypes',
    value: 'TabSeparatedWithNamesAndTypes'
  }
];

type FileTypeOptions = Array<TsvOption | JsonOption | CsvOption>;

export function inferFileType(urlOrPath: string): FileType {
  if (urlOrPath.endsWith('csv')) {
    return 'text/csv';
  } else if (urlOrPath.endsWith('tsv')) {
    return 'text/tab-separated-values';
  } else if (urlOrPath.endsWith('json')) {
    return 'application/json';
  } else {
    throw new Error('unsupported file type');
  }
}

export function getFileFormatOptions(fileType: FileType | null | undefined): FileTypeOptions {
  switch (fileType) {
    case 'application/json':
      return jsonOptions;
    case 'text/csv':
      return csvOptions;
    case 'text/tab-separated-values':
      return tsvOptions;
    default:
      return [...csvOptions, ...jsonOptions, ...tsvOptions];
  }
}
