import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useIntegrationSummary } from 'src/integrations/controller/useIntegrationSummary';
import { IntegrationSummary } from 'src/components/IntegrationSummary/IntegrationSummary';
import { IntegrationLayout } from 'src/layout/IntegrationLayout';
import Helmet from 'src/components/Helmet/Helmet';

export const IntegrationSummaryPage = (): ReactElement => {
  const { integrationId } = useParams();
  const {
    data: integration,
    isLoading,
    error
  } = useIntegrationSummary({
    integrationSlug: integrationId ?? ''
  });

  return (
    <IntegrationLayout>
      <Helmet>
        <title>
          Integrations{integration?.name ? ` - ${integration.name}` : ''}
        </title>
      </Helmet>
      <IntegrationSummary
        integration={integration}
        isLoading={isLoading}
        error={error}
      />
    </IntegrationLayout>
  );
};
