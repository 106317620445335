import { Button, Container, Title } from '@clickhouse/click-ui';
import { Galaxy } from 'galaxy';
import { ReactElement } from 'react';
import { downloadText } from 'src/lib/utils/download';
import { ServiceConnect } from 'src/components/ServiceConnect/ServiceConnect';
import { useInstanceOrThrow } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { CreateServiceType } from 'src/components/CreateNewService//useCreateService';

const description =
  "These credentials will help you securely log into your ClickHouse database. Make sure to copy or download your password - We'll only show it to you during this session!";
const credentialsFileName = 'clickhouse_credentials.txt';

interface ServiceDetailsProps {
  serviceId: string;
  type?: CreateServiceType;
  onSubmit: () => void;
}

export const ServiceDetails = ({
  serviceId,
  type = 'default',
  onSubmit: onSubmitProp
}: ServiceDetailsProps): ReactElement => {
  const instance = useInstanceOrThrow(serviceId);
  const { instanceState } = useInstanceStateManager();
  const { instancePasswords } = instanceState;
  const instancePassword = instancePasswords[serviceId] ?? '';
  const isOnboarding = type === 'onboarding';

  const onDownload = (): void => {
    const text = `Username: ${instance.dbUsername}\nPassword: ${instancePassword}`;
    downloadText(text, credentialsFileName);
    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.serviceDetails.continueButtonClick`,
      {
        interaction: 'click'
      }
    );
  };

  const onSubmit = (): void => {
    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.serviceDetails.continueButtonClick`,
      {
        interaction: 'click'
      }
    );
    onSubmitProp();
  };

  return (
    <Container
      orientation="vertical"
      isResponsive={false}
      alignItems="start"
      justifyContent="start"
      gap="md"
    >
      <Title type="h2">Database Credentials</Title>
      <ServiceConnect
        isModal={false}
        description={description}
        instance={instance}
        showDownload={false}
        showContent={false}
      />
      <Container
        orientation={isOnboarding ? 'vertical' : 'horizontal'}
        isResponsive={false}
        justifyContent="start"
        gap="md"
      >
        <Button
          fillWidth={isOnboarding}
          type="secondary"
          iconLeft="download"
          onClick={onDownload}
          data-testid="download-credentials-button"
        >
          Download credentials
        </Button>
        <Button
          fillWidth={isOnboarding}
          type="primary"
          onClick={onSubmit}
          data-testid="continue-button"
        >
          Continue
        </Button>
      </Container>
    </Container>
  );
};
