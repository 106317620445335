import {
  HTMLAttributes,
  ReactElement,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import { Select } from '@clickhouse/click-ui';
import { css } from '@emotion/react';
import {
  useDatabases,
  useMetadataError,
  useMetadataLoading
} from 'src/metadata/metadataState';
import { useSelectedDatabase } from 'src/metadata/selectedDatabase';

import { useImportModel } from 'src/state/dataLoading';
import { useCurrentInstanceId } from 'src/instance/instanceController';

export const tableParamsStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: inherit;
  align-items: flex-start;
`;

export const SYSTEM_DATABASES = new Set([
  'information_schema',
  'system',
  'INFORMATION_SCHEMA'
]);

export function isUserDatabase(name: string): boolean {
  return SYSTEM_DATABASES.has(name) === false;
}

const defaultDb = 'default';

function DestinationTableSettings({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  const databases = useDatabases();
  const isMetadataLoading = useMetadataLoading();
  const hasMetadataError = Boolean(useMetadataError());
  const { importModel, setDatabase } = useImportModel();
  const { setSelectedDatabase } = useSelectedDatabase();
  const serviceId = useCurrentInstanceId();

  const selectedDatabase =
    'data' in importModel
      ? importModel.data.tableConfig?.config?.schema || defaultDb
      : defaultDb;

  const selectDatabase = useCallback(
    (database: string): void => {
      if (database !== selectedDatabase && serviceId) {
        setDatabase(database);
        setSelectedDatabase(serviceId, database);
      }
    },
    [selectedDatabase, serviceId, setDatabase, setSelectedDatabase]
  );

  const dbOptions = useMemo(() => {
    if (isMetadataLoading || hasMetadataError) {
      return [];
    } else {
      const options =
        databases.map((db) => ({
          label: db.name,
          value: db.name,
          className: 'fs-exclude'
        })) ?? [];

      return options.filter((option) => isUserDatabase(option.value));
    }
  }, [databases, isMetadataLoading, hasMetadataError]);

  useEffect(() => {
    if (!isUserDatabase(selectedDatabase)) {
      selectDatabase(defaultDb);
    }
  }, [selectDatabase, selectedDatabase]);

  return (
    <div css={tableParamsStyle} {...props}>
      <Select
        disabled={hasMetadataError || isMetadataLoading}
        value={selectedDatabase}
        onSelect={selectDatabase}
        options={dbOptions}
        label="Database"
        data-testid="database-input"
        placeholder="Select a database"
        className="fs-exclude"
      />
      {children}
    </div>
  );
}

export default DestinationTableSettings;
