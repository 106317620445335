import { createToast } from '@clickhouse/click-ui';
import { getCloudProviderFromRegionId } from '@cp/common/protocol/Instance';
import { OrganizationRole, OrganizationUpdatePayload } from '@cp/common/protocol/Organization';
import { RegionId } from '@cp/common/protocol/Region';
import { SetStateAction } from 'jotai';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useCurrentInstance } from 'src/instance/instanceController';
import { useCurrentUser } from 'src/lib/auth/AuthenticationClientHooks';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { routes } from 'src/lib/routes';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import { RenameOrganizationInput, UpdatePrivateEndpointsInput } from 'src/organization/organizationApiClient';
import { useCurrentOrganization, useOrganizationStateManager } from 'src/organization/organizationState';
import { extractOrganizationIdFromPathname } from 'src/state/organization';

export interface OrganizationService {
  leave: (leaveOrganizationInput: LeaveOrganizationProps) => Promise<void>;
  rename: (renameOrganizationInput: RenameOrganizationInput) => Promise<void>;
  updatePrivateEndpoints: (endpoints: UpdatePrivateEndpointsInput) => Promise<boolean>;
}
export interface LeaveOrganizationProps {
  organizationId: string;
  name: string;
}
export const useOrganizationController = (): OrganizationService => {
  const apiClient = useApiClient();

  const leave = useCallback(
    async ({ organizationId, name }: LeaveOrganizationProps): Promise<void> => {
      try {
        await apiClient.organization.leave({
          organizationId
        });
        createToast({
          title: 'Left organization',
          type: 'success',
          description: `You have left ${name}`
        });
        navigateTo(routes.root());
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: errorMessage(e) ?? 'Error while leaving the organization'
        });
      }
    },
    [apiClient.organization]
  );

  const rename = useCallback(
    async ({ name, organizationId }: RenameOrganizationInput): Promise<void> => {
      try {
        await apiClient.organization.rename({
          name,
          organizationId
        });
        createToast({
          title: 'Organization name updated'
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: errorMessage(e) ?? 'Error while renaming the organization'
        });
      }
    },
    [apiClient.organization]
  );

  const updatePrivateEndpoints = useCallback(
    async (request: UpdatePrivateEndpointsInput) => {
      try {
        await apiClient.organization.updatePrivateEndpoints(request);
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: errorMessage(e) ?? 'Error while updating organization private endpoints'
        });
        return false;
      }
      return true;
    },
    [apiClient.organization]
  );

  return {
    leave,
    rename,
    updatePrivateEndpoints
  };
};

export const useAssignCurrentOrgOnRouteChange = (): void => {
  const { currentOrganizationId, switchCurrentOrganization, assignCurrentOrganization } = useOrganizationStateManager();
  const location = useLocation();
  const currentOrganization = useCurrentOrganization();

  useEffect(() => {
    const orgIdInUrl = extractOrganizationIdFromPathname(location.pathname) ?? undefined;
    if (orgIdInUrl && orgIdInUrl !== currentOrganizationId) {
      switchCurrentOrganization(orgIdInUrl);
    }

    if (!currentOrganization) {
      // we store the current organization id in the local storage.
      // upon loading the route, if the current org id saved in local storage
      // doesn't exist anymore get a new current org
      assignCurrentOrganization();
    }
  }, [
    currentOrganizationId,
    location.pathname,
    switchCurrentOrganization,
    assignCurrentOrganization,
    currentOrganization
  ]);
};

export const useInitOrganizationState = (): void => {
  const currentInstance = useCurrentInstance();
  const {
    currentOrganizationId,
    setOrganization,
    setOrganizations,
    deleteOrganization,
    assignCurrentOrganization,
    switchCurrentOrganization
  } = useOrganizationStateManager();
  const currentOrganizationIdRef = useRef(currentOrganizationId);
  const currentUser = useCurrentUser();

  useEffect(() => {
    currentOrganizationIdRef.current = currentOrganizationId;
  }, [currentOrganizationId]);

  useEffect(() => {
    if (currentInstance?.id && currentInstance.id !== currentOrganizationId) {
      switchCurrentOrganization(currentInstance.organizationId);
    }
  }, [currentInstance?.id, currentInstance?.organizationId, switchCurrentOrganization, currentOrganizationId]);

  useWebSocketsNotification<OrganizationUpdatePayload>({
    subscriptionDetails: {
      type: 'ORG_UPDATE',
      objId: currentUser?.id || ''
    },
    handler: ({ payload }) => {
      if (payload.updateType === 'UNLINK') {
        deleteOrganization(payload.organizationId);

        if (payload.organizationId === currentOrganizationIdRef.current) {
          assignCurrentOrganization();
        }
      }

      if (payload.updateType === 'COMPLETE') {
        const organizations = payload.organizations;
        setOrganizations(organizations);
        assignCurrentOrganization();
      }
      if (payload.updateType === 'PARTIAL' || payload.updateType === undefined) {
        // Partial update.
        for (const organization of payload.organizations) {
          setOrganization(organization);
          /** If this is the first sign in, the first partial update is when we get the first org. */
          if (!currentOrganizationIdRef.current) {
            assignCurrentOrganization();
          }
        }
      }
    }
  });
};

export type ChangeOrgResponse = {
  assignCurrentOrganization: () => void;
  switchCurrentOrganization: (orgId: string) => void;
};

export type DeleteInvitationFunction = (email: string, organizationId: string) => Promise<void>;
export type DeleteInvitationResponse = {
  loading: boolean;
  error: string | undefined;
  deleteInvitation: DeleteInvitationFunction;
};

export const useDeleteInvitation = (): DeleteInvitationResponse => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const deleteInvitation = async (email: string, organizationId: string): Promise<void> => {
    setLoading(true);
    setError(undefined);
    try {
      await api.organization.deleteInvitation(email, organizationId);
      createToast({ title: 'Success', type: 'success', description: 'Invitation deleted successfully' });
    } catch (error) {
      setError(errorMessage(error));
      console.error(error);
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'Unable to delete Invitation'
      });
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, deleteInvitation };
};

type CreateInvitationResponse = {
  loading: boolean;
  error: string | undefined;
  createInvitation: (
    emails: Array<string>,
    roles: Array<OrganizationRole>,
    onSuccess?: () => void,
    onError?: () => void
  ) => Promise<void>;
  setError: Dispatch<SetStateAction<string | undefined>>;
};

export const useInviteUser = (): CreateInvitationResponse => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { currentOrganizationId } = useOrganizationStateManager();

  const createInvitation = async (
    emails: Array<string>,
    roles: Array<OrganizationRole>,
    onSuccess?: () => void,
    onError?: () => void
  ): Promise<void> => {
    setLoading(true);
    setError(undefined);
    try {
      await api.organization.inviteToOrganization(emails, roles, currentOrganizationId);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {
      const invitationErrorMessage = errorMessage(error);
      setError(invitationErrorMessage);
      if (typeof onError === 'function') {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, createInvitation, setError };
};

export type ResendInvitationFunction = (email: string, role: OrganizationRole) => Promise<void>;
export type ResendInvitationResponse = {
  loading: boolean;
  error: string | undefined;
  resendInvitation: ResendInvitationFunction;
};

export const useResendInvitation = (): ResendInvitationResponse => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { currentOrganizationId } = useOrganizationStateManager();
  const resendInvitation = async (email: string, role: OrganizationRole): Promise<void> => {
    setLoading(true);
    setError(undefined);
    try {
      await api.organization.resendInvitation(email, role, currentOrganizationId);
      createToast({
        title: 'Invitation resend',
        type: 'success',
        description: 'The invitation has been successfully resent'
      });
    } catch (error) {
      setError(errorMessage(error));
      console.error(error);
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'Unable to resend the invitation'
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, resendInvitation };
};

export type RemoveUserFunction = (userId: string, organizationId: string) => Promise<void>;
export type RemoveUserResponse = {
  loading: boolean;
  error: string | undefined;
  removeUser: RemoveUserFunction;
};

export const useRemoveUser = (): RemoveUserResponse => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const removeUser = async (userId: string, organizationId: string): Promise<void> => {
    setLoading(true);
    setError(undefined);
    try {
      await api.organization.removeUser(userId, organizationId);
      createToast({
        title: 'User removed',
        type: 'success',
        description: 'User removed successfully'
      });
    } catch (error) {
      setError(errorMessage(error));
      console.error(error);
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'Unable to remove user'
      });
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, removeUser };
};

export type ChangeRoleFunction = (userId: string, organizationId: string, role: OrganizationRole) => Promise<void>;
export type ChangeRoleResponse = {
  loading: boolean;
  error: string | undefined;
  changeRole: ChangeRoleFunction;
};

export const useChangeRole = (): ChangeRoleResponse => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const changeRole = async (userId: string, organizationId: string, role: OrganizationRole): Promise<void> => {
    setLoading(true);
    setError(undefined);
    try {
      await api.organization.changeRole(userId, organizationId, role);
      createToast({
        title: 'Role Changed',
        type: 'success',
        description: `Changed role to ${role.toLowerCase()}`
      });
    } catch (error) {
      setError(errorMessage(error));
      console.error(error);
      createToast({
        title: 'Error',
        type: 'danger',
        description: `Failed to change role to ${role.toLowerCase()}`
      });
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, changeRole };
};

export type OnboardByocFunction = (organizationId: string, accountId: string, regionId: RegionId) => Promise<void>;

export const useOnboardByoc = (): OnboardByocFunction => {
  const api = useApiClient();

  const onboardByoc = useCallback(
    async (organizationId: string, accountId: string, regionId: RegionId): Promise<void> => {
      const cloudProvider = getCloudProviderFromRegionId(regionId);
      try {
        await api.organization.createByocAccount(organizationId, cloudProvider, accountId);
        await api.organization.createByocAccountInfrastructure(organizationId, accountId, regionId);
      } catch (error) {
        console.error(error);
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Unable to onboard user into BYOC private preview'
        });
      }
    },
    [api.organization]
  );

  return onboardByoc;
};
