import { Container, Panel, Title, WarningAlert } from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import {
  hydrateConfig,
  useDebouncedRunQuery
} from 'src/components/Dashboards/DashboardView/DashboardElement/utils';
import { DashboardBigStatConfig } from 'shared/src/types/dashboard';
import {
  useDashboardObjectState,
  useDashboardOutputs,
  useUpdateDashboardObjectOutput,
  useUpdateDashboardObjectState
} from 'src/state/dashboard/dashboardState';
import { useCredentials } from 'src/state/connection';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { QueryResult } from 'src/lib/clickhouse/query';

interface BigStatConfig {
  parameters: Record<string, string>;
  queryId: string;
  title: string;
  subtitle: string;
  type: 'table';
}

interface BigStatElementProps {
  config: DashboardBigStatConfig;
  dashboardId: string;
  objectId: string;
}

export default function BigStatElement({
  config,
  dashboardId,
  objectId
}: BigStatElementProps): ReactElement {
  const outputs = useDashboardOutputs({
    dashboardId
  });
  const hydratedConfig = hydrateConfig(config, outputs) as BigStatConfig;

  const { parameters, queryId, title, subtitle } = hydratedConfig;

  const credentials = useCredentials();
  const query = useSavedQuery(queryId);
  const state = useDashboardObjectState({
    dashboardId,
    objectId
  });
  const updateState = useUpdateDashboardObjectState({
    dashboardId,
    objectId
  });
  const updateOutput = useUpdateDashboardObjectOutput({
    dashboardId,
    objectId
  });

  const data = state.data as QueryResult | undefined;
  const value = state.value as string | undefined;

  const debouncedRunQuery = useDebouncedRunQuery();

  useEffect(() => {
    updateState({
      data: null
    });
    if (query) {
      updateState({
        loading: true
      });
      debouncedRunQuery(
        query.query,
        {
          ...credentials,
          database: query.database
        },
        {
          wakeService: true,
          variables: parameters
        }
      )
        .then((results) => {
          let value;
          if (results && !('error' in results)) {
            value = results.rows[0]?.[0];
          }
          updateState({
            data: results,
            loading: false,
            value
          });
          updateOutput({
            value
          });
        })
        .catch(console.error);
    }
  }, [query?.query, JSON.stringify(parameters)]);

  return (
    <Panel
      hasBorder
      orientation="vertical"
      padding="xl"
      alignItems="center"
      fillHeight
    >
      <Title type="h2">{title}</Title>
      <Container
        fillHeight
        alignItems="center"
        justifyContent="center"
        minWidth="100%"
      >
        {!query ? (
          'Please select a query'
        ) : state.loading || !data ? (
          'Loading...'
        ) : 'error' in data ? (
          <Container padding="sm">
            <WarningAlert text={data.error} data-testid="chart-error" />
          </Container>
        ) : (
          <h1>{value || 'No data'}</h1>
        )}
      </Container>
      <Title type="h3">{subtitle}</Title>
    </Panel>
  );
}
