import { ClickUIProvider, ThemeName } from '@clickhouse/click-ui';
import { ChartsThemeProvider } from '@clickhouse/viz-house';
import { ReactElement, ReactNode, useMemo } from 'react';
import ThemeProvider from 'src/components/ThemeProvider';
import { useIsUnifiedConsole } from 'src/lib/features';
import { useUserStateManager } from 'src/user/userState';

const clickUIProviderConfig = {
  tooltip: {
    delayDuration: 0
  }
};

export function ClickUIWrapper({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const { user } = useUserStateManager();
  const isUnifiedConsole = useIsUnifiedConsole();
  const theme = user.preferences?.theme;
  const realSelectedTheme: ThemeName = useMemo(() => {
    if (isUnifiedConsole) {
      return theme ?? 'light';
    } else {
      return 'classic';
    }
  }, [isUnifiedConsole, theme]);

  return (
    <ClickUIProvider theme={realSelectedTheme} config={clickUIProviderConfig}>
      <ThemeProvider>
        <ChartsThemeProvider>{children}</ChartsThemeProvider>
      </ThemeProvider>
    </ClickUIProvider>
  );
}
