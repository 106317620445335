import { Table } from 'shared/src/clickhouse';
import { v4 as uuid } from 'uuid';

import {
  Column,
  EditColumn,
  EditColumnId,
  FileFormatType,
  FileType,
  FileUrlAccessKeyIdCredentials,
  PreviewDataRequest,
  RowPreview,
  TableConfig,
  TableMapping,
  TableState,
  TableType
} from 'shared/src/dataLoading';

import { ChDescriber } from 'src/lib/dataLoading/describe';
import { parseData } from 'src/lib/dataLoading/parseData';
import { ApiClient } from 'src/lib/controlPlane/client';
import { generateColumnMappings } from 'src/components/ImportView/ImportForm';

export type DataPreview = {
  rows: RowPreview[][];
  tableConfig: TableConfig;
  formatType: FileFormatType;
  fileType: FileType;
  sizeBytes: number;
};

export class DataPreviewer {
  serviceId: string;
  tables: Table[];
  database: string;
  api: ApiClient;

  constructor(api: ApiClient, serviceId: string, tables: Table[], database: string) {
    this.api = api;
    this.serviceId = serviceId;
    this.tables = tables;
    this.database = database;
  }

  generateTableState(tableName: string, cols: Column[]): TableState {
    const columnsWithIds = cols.map((col) => ({
      ...col,
      originalName: col.name,
      id: uuid(),
      defaultExpression: ''
    }));

    const columnsIndexedById: Record<EditColumnId, EditColumn> = columnsWithIds.reduce(
      (memo, columnWithId) => {
        memo[columnWithId.id] = columnWithId;
        return memo;
      },
      {} as Record<EditColumnId, EditColumn>
    );

    const columnIds = columnsWithIds.map((col) => col.id);

    return {
      schema: this.database,
      name: tableName,
      columnIds: columnIds,
      columns: columnsIndexedById,
      engine: {
        type: 'MergeTree'
      },
      orderBy: [],
      partitionBy: '',
      sampleBy: '',
      primaryKey: '',
      indexGranularity: ''
    };
  }

  generateTableMapping(tableName: string, cols: Column[]): TableMapping {
    const table = this.tables.find((table) => table.tableName === tableName);

    const columnsMapping = generateColumnMappings(cols, table?.columns || []);

    return {
      schema: this.database,
      name: tableName,
      sourceColumns: cols,
      columnsMapping: columnsMapping
    };
  }

  generateTableConfig(tableType: TableType, tableName: string, cols: Column[]): TableConfig {
    if (tableType === 'newTable') {
      const tableState = this.generateTableState(tableName, cols);
      return {
        tableType,
        config: tableState
      };
    } else {
      const tableMapping = this.generateTableMapping(tableName, cols);
      return {
        tableType,
        config: tableMapping
      };
    }
  }

  getPreviewDataFromUrl = async (
    url: string,
    tableName: string,
    tableType: TableType,
    hints: {
      numLines?: number;
      fileType?: FileType;
      formatType?: FileFormatType;
      separator?: string;
    } = {},
    credentials?: FileUrlAccessKeyIdCredentials
  ): Promise<DataPreview> => {
    const defaultHints = { numLines: 20 };

    const options: PreviewDataRequest = {
      url: url,
      serviceId: this.serviceId,
      hints: { ...defaultHints, ...hints }
    };

    if (credentials) {
      options.credentials = credentials;
    }

    const urlDataPreview = await this.api.dataloading.previewDataForUrl(options);

    const {
      data: { columns, formatType, rows, sizeBytes }
    } = urlDataPreview;

    if (!rows || rows.length === 0) {
      throw new Error('Could not read any data from the URL');
    }

    const tableConfig = this.generateTableConfig(tableType, tableName, columns);
    const rowsAsRowPreview = rows as RowPreview[][];
    return {
      sizeBytes,
      rows: rowsAsRowPreview,
      tableConfig,
      formatType,
      fileType: urlDataPreview.data.fileType
    };
  };

  async getPreviewDataFromData(
    dataRaw: string,
    tableType: TableType,
    tableName: string,
    chDescriber: ChDescriber,
    fileType: FileType,
    formatType: FileFormatType
  ): Promise<Omit<DataPreview, 'sizeBytes'>> {
    const { columns, rows } = await parseData(dataRaw, formatType, fileType, chDescriber);

    const tableConfig = this.generateTableConfig(tableType, tableName, columns);
    return {
      rows: rows,
      tableConfig,
      formatType: formatType,
      fileType
    };
  }
}
