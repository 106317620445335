import React, { useEffect, useRef, useState } from 'react';
import { TextField, Panel, Button } from '@clickhouse/click-ui';

interface Props {
  onSubmit: (value: string) => void;
  icon: React.ReactNode;
}

const GptConstructionTextField: React.FC<Props> = ({ onSubmit, icon }) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.focus();
  }, []);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      onSubmit(value);
    }
  };

  return (
    <Panel orientation="horizontal" padding="md">
      {icon}
      <Panel width="510px" padding="none">
        <TextField
          ref={inputRef}
          value={value}
          onChange={(inputValue: string): void => setValue(inputValue)}
          onKeyDown={handleKeyDown}
          data-testid="GptConstructionPopover"
          className="fs-exclude"
          placeholder="Ask our SQL assistant to build a query"
        />
      </Panel>
      <Button
        onClick={(): void => onSubmit(value)}
        data-testid="GptConstructionPopoverButton"
        iconLeft="enter"
      >
        Enter
      </Button>
    </Panel>
  );
};

export default GptConstructionTextField;
