import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  SuccessAlert,
  Text
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import ConnectWithContent from 'src/components/ServiceConnect/ConnectWithContent';
import CredentialsInfo from 'src/components/ServiceConnect/CredentialsInfo';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import useInstanceMySqlSettingsController from 'src/instance/instanceMySqlSettingsController';
import useInstanceMySqlSettingsStateManager from 'src/instance/InstanceMySqlSettingsState';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

export interface ResetPasswordModalProps {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  open: boolean;
  password?: string;
  resetSuccess: boolean;
  username: string;
}

export const ResetPasswordModal = ({
  onClose,
  onConfirm,
  open,
  password,
  resetSuccess,
  username
}: ResetPasswordModalProps): ReactElement => {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const [showPassword, setShowPassword] = useState(false);
  const instance = useCurrentInstanceOrThrow();
  const { updateMysqlSettings } = useInstanceMySqlSettingsController();
  const { instanceState } = useInstanceStateManager();
  const { mySqlPasswords } = instanceState;
  const mySqlPassword = mySqlPasswords[instance.id] ?? '';
  const { mySqlSettingsLoading, mysqlSettings } =
    useInstanceMySqlSettingsStateManager();
  const nativesecure = instance?.endpoints['nativesecure'] ?? {
    hostname: '',
    port: 0
  };
  const nativesecureParams = {
    hostname: nativesecure.hostname,
    password: password ?? '',
    port: nativesecure.port.toString()
  };

  const https = instance?.endpoints['https'] ?? {
    hostname: '',
    port: 0
  };

  const httpsParams = {
    hostname: https.hostname,
    password: password ?? '',
    port: https.port.toString()
  };

  const mysqlParams = {
    canEnable: !!mysqlSettings?.passwordSet,
    featureFlagEnabled: instance?.cloudProvider !== 'azure',
    mySqlUsername: mysqlSettings?.username ?? '',
    mySqlPassword,
    isLoading: mySqlSettingsLoading,
    enabled: mysqlSettings?.enabled ?? false,
    onEnabledChange: (val: boolean): void => {
      void updateMysqlSettings({
        instanceId: instance.id,
        organizationId: instance.organizationId,
        enabled: val
      });
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Content
        title="Reset service user password"
        onClose={onClose}
        showClose
      >
        <Text size="md">
          You're resetting the password for the default user. Make sure to
          update the settings of any clients that use these credentials.
        </Text>
        <Spacer />
        {resetSuccess && (
          <>
            <SuccessAlert text="Password reset" />
            <Spacer />
          </>
        )}
        <CredentialsInfo
          hasManagePermission={hasManageServicePermission}
          username={username}
          password={password}
          onTogglePassword={setShowPassword}
          dataWarehouseId={instance.dataWarehouseId}
          showDownload
        />
        {resetSuccess && (
          <>
            <Spacer />
            <ConnectWithContent
              showPassword={showPassword}
              https={httpsParams}
              mysqlProps={mysqlParams}
              username={username}
              nativesecure={nativesecureParams}
            />
          </>
        )}
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close
            label={resetSuccess ? 'Close' : 'Cancel'}
            onClick={onClose}
          />
          {!resetSuccess && (
            <Button onClick={(): void => void onConfirm().catch(console.error)}>
              Reset password
            </Button>
          )}
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
