import React, { Fragment, ReactElement } from 'react';

import { RowElement } from 'src/components/primitives/lib/Spreadsheet/types';

interface DataCellProps {
  rowIndex: number;
  dataColumnIndex: number;
  getRow: (idx: number) => RowElement[] | null;
  isFocused: boolean;
  selectionBorderLeft: boolean;
  selectionBorderTop: boolean;
  isSelected: boolean;
  isLastColumn: boolean;
  isLastRow: boolean;
  selectedRow: boolean;
}

function DataCell({
  rowIndex,
  dataColumnIndex,
  getRow,
  isFocused,
  selectionBorderLeft,
  selectionBorderTop,
  isSelected,
  isLastColumn,
  isLastRow,
  selectedRow
}: DataCellProps): ReactElement {
  const row = getRow(rowIndex);
  const value = row?.[dataColumnIndex] ?? null;

  let outerClass = 'dataCellOuter';
  if (selectionBorderLeft) {
    outerClass += ' dataCellBorderLeft';
  }
  if (selectionBorderTop) {
    outerClass += ' dataCellBorderTop';
  }

  let innerClass = 'sp-content dataCellInner';

  if (typeof row?.[dataColumnIndex] === 'number') {
    innerClass += ' dataCellIsNumber';
  }

  if (value === null) {
    innerClass += ' dataCellIsEmpty';
  }

  const valueArray = value ? value.split('\n') : [];

  return (
    <div
      className={outerClass}
      data-location="cell"
      data-selected={isSelected}
      data-isfocused={isFocused}
      data-column={dataColumnIndex}
      data-row={rowIndex}
      data-is-last-column={isLastColumn}
      data-is-last-row={isLastRow}
      data-testid={`cell-${dataColumnIndex}-${rowIndex}`}
    >
      <div
        tabIndex={-1}
        data-location="cell"
        data-row={rowIndex}
        data-column={dataColumnIndex}
        className={innerClass}
        data-selected={isSelected}
        data-isfocused={isFocused}
        data-selected-row={selectedRow}
        data-copy="true"
      >
        <p>
          {[0, 1].includes(valueArray.length)
            ? value
            : valueArray
                .map<React.ReactNode>((element) => (
                  <Fragment key={element}>
                    {element}
                    <span className="enter-key">⏎</span>
                  </Fragment>
                ))
                .reduce((prev, curr) => [prev, '\n', curr])}
        </p>
      </div>
    </div>
  );
}

export default React.memo(DataCell);
