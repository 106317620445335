import {
  Container,
  Flyout,
  Icon,
  Separator,
  Title,
  Tooltip
} from '@clickhouse/click-ui';
import { UIUserNotification } from '@cp/common/protocol/Notifications';
import { getNotificationType } from '@cp/common/utils/NotificationsUtils';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { NotificationItem } from 'src/components/Notifications/NotificationItem';
import { WrappedLink } from 'src/components/WrappedLink/WrappedLink';
import { routes } from 'src/lib/routes';
import { NotificationTabs } from 'src/pages/NotificationsPage/tabs';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const AnimatedContainer = styled.div<{ $duration: string }>`
  animation: ${fadeInAnimation} ${({ $duration }): string => $duration};
  width: 100%;
`;

const CustomFlyoutFooter = styled(Flyout.Footer)`
  padding: 0.5rem 1.5rem;
`;

const CustomFlyoutHeader = styled(Flyout.Header)`
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  height: 2.5rem;
`;
const NoColorLink = styled(Link)`
  color: inherit;
`;

type NotificationsFlyoutProps = {
  isOpen: boolean;
  onToggleOpen: () => void;
  notifications: UIUserNotification[] | undefined;
  notificationContainer: HTMLElement | null;
  organizationId: string;
  triggerButton: React.ReactNode;
};

export const NotificationsFlyout = memo(
  ({
    isOpen,
    onToggleOpen,
    notifications,
    notificationContainer,
    organizationId,
    triggerButton
  }: NotificationsFlyoutProps) => {
    return (
      <Flyout onOpenChange={onToggleOpen} open={isOpen}>
        <Flyout.Trigger>{triggerButton}</Flyout.Trigger>
        <Flyout.Content
          closeOnInteractOutside={true}
          align="start"
          container={notificationContainer}
        >
          <CustomFlyoutHeader showClose={false}>
            <Container justifyContent="space-between">
              <Title size="lg" type="h2">
                Notifications
              </Title>
              <Tooltip>
                <Tooltip.Trigger>
                  <NoColorLink
                    to={routes.notificationsPage(
                      { orgId: organizationId },
                      NotificationTabs.SETTINGS
                    )}
                  >
                    <Icon cursor="pointer" size="sm" name="settings" />
                  </NoColorLink>
                </Tooltip.Trigger>
                <Tooltip.Content>Notification settings</Tooltip.Content>
              </Tooltip>
            </Container>
          </CustomFlyoutHeader>
          <Flyout.Body align="top">
            {notifications?.map((n, i) => {
              if (!getNotificationType(n.name)) {
                return null;
              }

              return (
                <AnimatedContainer $duration="0.4s" key={n.id}>
                  <NotificationItem notification={n} />
                  {i !== notifications.length - 1 && <Separator size="xs" />}
                </AnimatedContainer>
              );
            })}
          </Flyout.Body>
          <CustomFlyoutFooter>
            <Container justifyContent="center">
              <WrappedLink
                to={routes.notificationsPage({ orgId: organizationId })}
                size="sm"
                color="primary"
              >
                View all
              </WrappedLink>
            </Container>
          </CustomFlyoutFooter>
        </Flyout.Content>
      </Flyout>
    );
  }
);
