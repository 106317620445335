import React, { useEffect, useRef } from 'react';

import get from 'lodash/get';
import { useDragLayer } from 'react-dnd-cjs';
import ReactDOM from 'react-dom';

import { columnNameStyle, columnStyle, dataTypeStyle } from './styles';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'default',
  zIndex: 999999
};

function getItemStyles(currentOffset, hoveringData, width) {
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    position: 'fixed',
    cursor: 'default',
    transform,
    transition: 'width 0.2s ease',
    WebkitTransform: transform,
    // width: hoveringData ? width - 20 : width
    width
  };
}

export default function DragLayer({ hoveringData, portalId, width }) {
  const el = useRef(document.createElement('div'));
  const { currentOffset, isDragging, item } = useDragLayer((monitor) => {
    return {
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem(),
      isDragging: monitor.isDragging()
    };
  });

  useEffect(() => {
    const portalRoot = document.getElementById(portalId);
    portalRoot.appendChild(el.current);
    return () => {
      if (
        el.current &&
        portalRoot &&
        portalRoot.children &&
        portalRoot.children.length
      ) {
        portalRoot.removeChild(el.current);
      }
    };
  }, []);

  if (!isDragging || item.type !== 'column') {
    return null;
  }

  const column =
    typeof item.column === 'object' ? get(item, 'column.column') : item.column;
  return ReactDOM.createPortal(
    <div style={layerStyles}>
      <div
        css={columnStyle}
        style={getItemStyles(currentOffset, hoveringData, width)}
      >
        {item && <div css={columnNameStyle}>{column}</div>}
        {item && (
          <div css={dataTypeStyle} style={{ marginLeft: 10 }}>
            {item.dataType}
          </div>
        )}
      </div>
    </div>,
    el.current
  );
}
