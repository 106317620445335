import React, { FC, ReactElement, useMemo } from 'react';

import { List as _List, ListProps, ListRowRenderer } from 'react-virtualized';
import { QueryType } from 'shared/src/types/queryFolders';
import { useLinearizeNodes } from 'src/components/QueryView/QueryList/Folders/utils';

import ContentNode from 'src/components/QueryView/QueryList/Folders/Tree/ContentNode';
import FolderNode from 'src/components/QueryView/QueryList/Folders/Tree/FolderNode';
import { listWrapperStyle } from 'src/components/QueryView/QueryList/Folders/Tree/styles';
import { TreeNodesProps } from 'src/components/QueryView/QueryList/Folders/Tree/types';
import { paddingStyle, virtualizedListStyle } from 'global-styles';
import { v4 as uuid } from 'uuid';

const List = _List as unknown as FC<ListProps>;

function TreeNodes(props: TreeNodesProps): ReactElement | null {
  const {
    nodes,
    search,
    onClick,
    onCreateFolder,
    onDelete,
    onDrop,
    onExpand,
    onTitleChange,
    expandedPaths,
    expandData,
    isDragging,
    type: contentType,
    mainRef,
    currentTabId,
    scrollRef,
    size,
    onContentRename,
    selectedFolder
  } = props;

  const linearizeNodes = useLinearizeNodes({
    currentTabId,
    expandedPaths,
    expandData,
    isDragging,
    search,
    selectedFolder
  });

  // if the reference for nodes changes, we update the key to rerender the list
  const { nodeList, nodeListKey } = useMemo(() => {
    const nodeList = linearizeNodes(nodes);
    const nodeListKey = uuid();

    return { nodeList, nodeListKey };
  }, [linearizeNodes, nodes]);

  const rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    const { type, node, ...props } = nodeList[index];
    if (type === 'content') {
      return (
        <div
          style={style}
          key={`${key}-${node.title}`}
          css={paddingStyle({ left: '1rem' })}
        >
          <ContentNode
            {...(node as QueryType)}
            {...props}
            noAccess={false}
            key={props.path}
            mainRef={mainRef}
            onClick={onClick}
            onCreateFolder={onCreateFolder}
            onDelete={onDelete}
            onDrop={onDrop}
            type={contentType}
            currentTabId={currentTabId}
            onExpand={onExpand}
            onContentRename={onContentRename}
          />
        </div>
      );
    } else if (type === 'folder') {
      return (
        <div style={style} key={key} css={paddingStyle({ left: '1rem' })}>
          <FolderNode
            {...node}
            {...props}
            key={props.path}
            onCreateFolder={onCreateFolder}
            onDelete={onDelete}
            onDrop={onDrop}
            onExpand={onExpand}
            onTitleChange={onTitleChange}
            mainRef={mainRef}
            expanded={props.expanded || false}
            hasChildren={props.hasChildren || false}
          />
        </div>
      );
    }
  };

  if (typeof size.width !== 'number' || typeof size.height !== 'number') {
    return null;
  }

  return (
    <div css={listWrapperStyle(size.height, nodeList.length * 32)}>
      {
        <List
          height={size.height}
          ref={scrollRef}
          overscanRowCount={40}
          key={nodeListKey}
          rowCount={nodeList.length}
          rowHeight={32}
          rowRenderer={rowRenderer}
          width={size.width}
          style={{ ...virtualizedListStyle, maxHeight: 'inherit' }}
        />
      }
    </div>
  );
}

export default React.memo(TreeNodes);
