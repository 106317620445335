import { useMemo } from 'react';

import { QueryStateContextActions, useQueryStateContext } from 'src/lib/query/QueryState/';

export function useQueryActions(): QueryStateContextActions {
  const { createQueryInPlace, createQuery, cancelQuery, deleteQuery, runQuery, queryEachRow, resetQueryStatus } =
    useQueryStateContext();

  return useMemo(() => {
    return {
      createQuery,
      createQueryInPlace,
      cancelQuery,
      deleteQuery,
      runQuery,
      queryEachRow,
      resetQueryStatus
    };
  }, [createQuery, createQueryInPlace, cancelQuery, deleteQuery, runQuery, queryEachRow, resetQueryStatus]);
}
