import { useMemo } from 'react';

type ChartRowElement = string | number | null;

import { isNumericType, isString } from 'shared/src/clickhouse/dataTypes';
import clickhouseTypes from 'shared/src/clickhouse/typeOptions';

import { getCustomColumnType } from 'shared/src/clickhouse/columns';
import { Row } from 'shared/src/clickhouse/types';
import { useResultsView } from 'src/lib/query/QueryState';
import { RunningQueryColumn } from 'src/lib/query/runningQueryTypes';
import { QueryResult, ResultError } from 'src/lib/clickhouse/query';

const columnTypes = [
  {
    value: 'boolean',
    type: 'boolean'
  },
  {
    value: 'date',
    type: 'date'
  },
  {
    value: 'number',
    type: 'number'
  },
  {
    value: 'string',
    type: 'string'
  },
  ...clickhouseTypes
];

export interface ColumnInfo {
  name: string;
  type: string;
}

export interface ResultInfo {
  columns?: ColumnInfo[];
  rows: Row[];
}

export interface ColumnType {
  value: string;
  label?: string;
  type?: string;
}

export const getColumnType = (data: undefined | null | ChartData, column: string): string | undefined => {
  const columnInfo = data && data.columns && data.columns.find((item) => item.name === column);
  if (!columnInfo) {
    return '?';
  }

  const type: string | undefined = getCustomColumnType(columnInfo.type);

  let typeDef: undefined | ColumnType;
  if (type) {
    const lowercaseType = type.toLowerCase();
    typeDef = columnTypes.find((item) => item.value.toLowerCase() === lowercaseType);
  }

  if (typeDef) {
    return typeDef.type;
  } else if (data.rows?.[0] && data.rows[0][column] != null) {
    return typeof data.rows[0][column];
  } else if (isNumericType(type)) {
    return 'number';
  } else if (isString(type)) {
    return 'string';
  } else {
    return '?';
  }
};

export type RowObject = Record<string, ChartRowElement>;

export type ChartData = {
  columns: RunningQueryColumn[];
  rows: RowObject[];
  loading: boolean;
};

function createRowObject(row: Row, columns: RunningQueryColumn[]): RowObject {
  const entries: [string, ChartRowElement][] = columns.map((col, i) => {
    let value: ChartRowElement = row[i];
    if (isNumericType(col.type)) {
      value = Number(value);
    }
    return [col.name, value];
  });

  const rowObj: { [p: string]: ChartRowElement } = Object.fromEntries(entries);
  return rowObj;
}

function dedupeColumns(columns: RunningQueryColumn[]): RunningQueryColumn[] {
  return columns.map((col, index, arr) => {
    let num;
    let name;
    if (col.name) {
      while (!name) {
        const newName = num ? `${col.name}_${num}` : col.name;
        const conflict = arr.some((col, colIndex) => {
          if (index !== colIndex) {
            return col.name === newName;
          }
          return false;
        });
        if (!conflict) {
          name = newName;
          arr[index].name = newName;
        } else {
          num = num ? num + 1 : 1;
        }
      }
    } else {
      name = col.name;
    }

    return {
      ...col,
      name
    };
  });
}

interface UseChartDataArgs {
  runId: string | undefined;
  returnData?: boolean;
}

const maxChartRows = 10000;

export function useChartData({ runId, returnData = true }: UseChartDataArgs): ChartData {
  const {
    getRow,
    numRows,
    columns: columnsFromQuery = [],
    status
  } = useResultsView({
    queryId: runId ?? null,
    startRow: 0,
    endRow: returnData ? maxChartRows : 0,
    name: 'chart'
  });

  return useMemo<ChartData>(() => {
    const isRunning = status === 'running';
    const noColumns = columnsFromQuery.length === 0;

    if (!returnData || isRunning || noColumns) {
      return {
        columns: [],
        rows: [],
        loading: isRunning // if query is running, rows have not finished loading
      };
    }

    const columns = dedupeColumns(columnsFromQuery);

    let allLoaded = true;
    const rowsEnd = Math.min(numRows, maxChartRows);
    const rows: RowObject[] = [];

    for (let i = 0; i < rowsEnd; ++i) {
      const row = getRow(i);
      if (!row) {
        allLoaded = false;
        break;
      }
      if (row.length !== columns.length) {
        throw new Error('Columns and row length mismatch');
      }

      const rowObj = createRowObject(row, columns);

      rows.push(rowObj);
    }

    if (!allLoaded) {
      return { columns: [], rows: [], loading: true };
    } else {
      return { rows, columns, loading: false };
    }
  }, [columnsFromQuery, getRow, numRows, returnData, status]);
}

export function convertQueryResultToChartData(data?: QueryResult): ChartData | ResultError {
  if (!data) {
    return {
      columns: [],
      rows: [],
      loading: true
    };
  }

  if ('error' in data) {
    return data;
  }

  const columns = dedupeColumns(data.columns);

  const rows = data.rows.map((row) => {
    return createRowObject(row, columns);
  });

  return {
    columns,
    rows,
    loading: false
  };
}
