import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  BorderBottomContainer,
  BorderedResizable
} from 'src/components/Dashboards/DashboardView/styles';
import { useDashboards } from 'src/state/dashboard';
import {
  Button,
  ConfirmationDialog,
  Container,
  createToast,
  Dropdown,
  Icon,
  IconButton,
  SearchField,
  SidebarNavigationItem,
  Spacer,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import { useAtom } from 'jotai';
import { mainSidebarOpen } from 'src/state/sidebar';
import { btnStyle } from 'src/layout/SqlConsoleLayout/ConsoleLayout/styles';
import {
  useDeleteDashboard,
  useFetchDashboards
} from 'src/dashboard/dashboardController';
import styled from 'styled-components';
import { Dashboard } from 'shared/src/types/dashboard';
import { Link } from 'react-router-dom';
import { Loading } from 'src/components/Dashboards/Loading';

const HoverContainer = styled(Container)`
  [aria-label='dots-horizontal'] {
    visibility: hidden;
  }

  &:hover [aria-label='dots-horizontal'] {
    visibility: visible;
  }
`;

const FullWidthLink = styled(Link)`
  color: ${({ theme }): string => theme.global.color.text.muted};
  width: 100%;
`;

const MutedBorderedResizable = styled(BorderedResizable)`
  background-color: ${({ theme }): string =>
    theme.global.color.background.muted};
`;

interface DashboardListProps {
  dashboards: Record<string, Dashboard>;
  isLoading?: boolean;
  showConfirmDeleteDialog: (dashboard: Dashboard) => void;
}

const DashboardList = ({
  dashboards,
  isLoading,
  showConfirmDeleteDialog
}: DashboardListProps): ReactElement => {
  if (isLoading) {
    return <Loading />;
  }

  const dashboardsList = Object.values(dashboards);

  if (!dashboardsList.length) {
    return (
      <Container gap="md" orientation="vertical" padding="sm">
        <Text>No dashboards</Text>
      </Container>
    );
  }
  return (
    <Container gap="xs" orientation="vertical">
      <Spacer size="sm" />
      {dashboardsList.map((dashboard) => {
        return (
          <HoverContainer key={dashboard.id}>
            <FullWidthLink to={dashboard.id}>
              <SidebarNavigationItem label={dashboard.name} />
            </FullWidthLink>
            <Dropdown modal={false}>
              <Dropdown.Trigger>
                <Icon cursor="pointer" name="dots-horizontal" />
              </Dropdown.Trigger>
              <Dropdown.Content showArrow={true}>
                <Dropdown.Item
                  icon="trash"
                  onClick={() => {
                    showConfirmDeleteDialog(dashboard);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </HoverContainer>
        );
      })}
    </Container>
  );
};

interface DashboardSidebarProps {
  showCreateDialog: () => void;
}

const DashboardSidebar = React.memo(function Sidebar({
  showCreateDialog
}: DashboardSidebarProps): ReactElement {
  const [openSidebar, setOpenSidebar] = useAtom(mainSidebarOpen);
  const [dashboardToDelete, setDashboardToDelete] = useState<Dashboard>();
  const fetchDashboards = useFetchDashboards();

  const deleteDashboard = useDeleteDashboard();
  const dashboards = useDashboards();

  const [deleteConfirmationIsVisible, setDeleteConfirmationIsVisible] =
    useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');
  const [filteredDashboards, setFilteredDashboards] =
    useState<Record<string, Dashboard>>(dashboards);
  const [isLoading, setIsLoading] = useState<boolean>();

  const getDashboards = useCallback((): void => {
    setIsLoading(true);
    fetchDashboards()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }, [fetchDashboards]);

  useEffect(() => {
    getDashboards();
  }, [getDashboards]);

  useEffect(() => {
    if (filterText) {
      filterDashboardList(filterText);
    } else {
      setFilteredDashboards(dashboards);
    }
  }, [dashboards]);

  const showConfirmDeleteDialog = (dashboard: Dashboard): void => {
    setDashboardToDelete(dashboard);
    setDeleteConfirmationIsVisible(true);
  };

  const hideConfirmDeleteDialog = (): void => {
    setDeleteConfirmationIsVisible(false);
  };

  const filterDashboardList = (text: string): void => {
    setFilterText(text);

    const newFilteredDashboards: Record<string, Dashboard> = {};

    for (const [dashboardId, dashboard] of Object.entries(dashboards)) {
      if (dashboard.name.toLowerCase().includes(text.toLowerCase())) {
        newFilteredDashboards[dashboardId] = dashboard;
      }
    }

    setFilteredDashboards(newFilteredDashboards);
  };

  const handleDashboardDelete = (dashboard: Dashboard): void => {
    deleteDashboard(dashboard.id)
      .then((success) => {
        if (success) {
          createToast({
            type: 'success',
            title: 'Deleted',
            description: `${dashboard.name} has been deleted`,
            duration: 5_000
          });
          void fetchDashboards();
        } else {
          createToast({
            type: 'warning',
            title: 'Error',
            description: `There was a problem deleting ${dashboard.name}. Please try again.`,
            duration: 5_000
          });
        }
      })
      .catch(console.error);
  };

  return (
    <MutedBorderedResizable
      defaultSize={{ width: 285, height: '100%' }}
      minWidth={250}
      maxWidth={600}
    >
      <Container orientation="vertical">
        <BorderBottomContainer
          gap="sm"
          justifyContent="space-evenly"
          orientation="horizontal"
          padding="sm"
        >
          <Tooltip>
            <Tooltip.Trigger>
              <IconButton
                icon="slide-out"
                type="ghost"
                css={btnStyle(openSidebar)}
                onClick={(): void => {
                  setOpenSidebar((openSidebar) => !openSidebar);
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Content>Toggle Sidebar</Tooltip.Content>
          </Tooltip>
          <Button onClick={showCreateDialog} iconLeft="plus">
            New Dashboard
          </Button>
          <Spacer size="sm" />
          <Icon cursor="pointer" name="refresh" onClick={getDashboards} />
        </BorderBottomContainer>
        <BorderBottomContainer orientation="horizontal" padding="sm">
          <SearchField
            clear={true}
            onChange={filterDashboardList}
            placeholder="Search dashboards"
            value={filterText}
          />
        </BorderBottomContainer>
        <DashboardList
          dashboards={filteredDashboards}
          isLoading={isLoading}
          showConfirmDeleteDialog={showConfirmDeleteDialog}
        />
      </Container>
      {deleteConfirmationIsVisible && dashboardToDelete && (
        <ConfirmationDialog
          onCancel={hideConfirmDeleteDialog}
          onConfirm={() => {
            handleDashboardDelete(dashboardToDelete);
          }}
          open={true}
          primaryActionLabel="Delete"
          secondaryActionLabel="Cancel"
          showClose={true}
          title={`Delete ${dashboardToDelete.name}?`}
        >
          Deleting this dashboard will permanently remove it and all of the
          elements on it.
        </ConfirmationDialog>
      )}
    </MutedBorderedResizable>
  );
});

export default DashboardSidebar;
