import { Container, Icon } from '@clickhouse/click-ui';
import { memo } from 'react';
import styled from 'styled-components';

const CustomIconWithDor = styled(Icon)<{ $showRedDot?: boolean }>`
  color: ${({ theme }): string => theme.click.global.color.text.muted};
  cursor: pointer;
  ${({ $showRedDot, theme }): string =>
    $showRedDot
      ? `
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: calc(100% - 4px);
        left: calc(100% - 4px);
        background: ${theme.click.global.color.text.danger};
        border-radius: 50%;
        display: flex;
        place-items: center;
        width: 8px;
        height: 8px;
        aspect-ratio: 1 / 1;
      }
  `
      : ''}
`;

type NotificationBellViewProps = {
  hasUnread: boolean;
  isFlyoutOpen: boolean;
  isLoading: boolean;
};

export const NotificationBellView = memo(
  ({ hasUnread, isFlyoutOpen, isLoading }: NotificationBellViewProps) => {
    const showRedDot = hasUnread && !isLoading && !isFlyoutOpen;
    return (
      <Container justifyContent="end">
        <CustomIconWithDor
          name="bell"
          data-testid={showRedDot ? 'bell-button-unread' : 'bell-button'}
          size="sm"
          cursor="pointer"
          $showRedDot={showRedDot}
        />
      </Container>
    );
  }
);
