import { getBrowserPlatform } from 'src/lib/platform';

export const PASSWORD_MASK = '•••••••••••••';

export type Protocol = 'https' | 'native' | 'mysql';

export interface ProtocolProps {
  hostname: string;
  password: string;
  port: string;
}

export interface ServiceModalMySqlProtocolProps {
  canEnable: boolean;
  mySqlUsername: string;
  mySqlPassword: string;
  isLoading: boolean;
  enabled: boolean;
  onEnabledChange: (enabled: boolean) => void;
}

export const getHttpsConnectionString = (
  hostname: string,
  username: string,
  password: string,
  port: string
): string => {
  return `curl --user '${username}:${password || '<password>'}' \\
  --data-binary 'SELECT 1' \\
  https://${hostname}:${port}`;
};

export const getNativeConnectionString = (hostname: string, username: string, password: string): string => {
  const clickhouseCommand = getBrowserPlatform() === 'linux' ? 'clickhouse' : './clickhouse';

  return (
    `${clickhouseCommand} client --host ${hostname} --secure ` +
    (username === 'default' ? '' : `--user '${username}' `) +
    '--password' +
    // Concatenate the password to the native connection endpoint. If the password doesn't exist in memory keep it
    // blank so the user will be prompt to insert it when they copy this endpoint to their terminal.
    (password ? ` '${password}'` : '')
  );
};

export const getMysqlConnectionString = (hostname: string, username: string): string => {
  return `mysql -h ${hostname} -u ${username} -P 3306 --password`;
};

export const getPasswordSubstitution = (password: string): string => {
  return password || '<password>';
};
