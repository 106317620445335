import {
  OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS,
  OpenapiEntityKeysUpdatePayload,
  OpenapiKey,
  OpenapiKeyActorWithRole,
  OpenapiKeyRole,
  checkCanDeleteOpenapiKey,
  checkCanUpdateOpenapiKey,
  isExpiredOpenapiKey
} from '@cp/common/protocol/OpenapiKey';
import { Dispatch, SetStateAction, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import { useOrganizationRole } from 'src/organization/organizationState';
import { MILLIS_PER_DAY, MILLIS_PER_WEEK } from '@cp/common/utils/DateTimeUtils';
import { User } from 'src/lib/auth/types';
import { createToast } from '@clickhouse/click-ui';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import { UpdateOpenapiKeyOptions } from 'src/apiKey/apiKeyApiClient';
import { OrganizationRole } from '@cp/common/protocol/Organization';

export function canDelete(key: OpenapiKey, user: User | null, role: OpenapiKeyRole): boolean {
  if (!user) {
    return false;
  }

  const actor: OpenapiKeyActorWithRole = {
    actorType: 'user',
    actorId: user.id,
    role
  };
  return checkCanDeleteOpenapiKey(actor, key);
}

export function canEdit(key: OpenapiKey, user: User | null, role: OpenapiKeyRole): boolean {
  if (!user) {
    return false;
  }

  const actor: OpenapiKeyActorWithRole = {
    actorType: 'user',
    actorId: user.id,
    role
  };
  return checkCanUpdateOpenapiKey(actor, key);
}

export function canToggle(key: OpenapiKey, user: User | null, role: OpenapiKeyRole): boolean {
  return !isExpiredOpenapiKey(key) && canEdit(key, user, role);
}

export interface NewKeyInfo {
  key: string;
  secret: string;
  name: string;
}

interface CreateOrEditKeyArgs {
  customExpiry: string;
  expiry: string;
  name: string;
  role: OpenapiKeyRole;
}

interface UseApiKeys {
  closeCreateKeyModal: () => void;
  createKeyModalOpen: boolean;
  createOrEditKey: (args: CreateOrEditKeyArgs) => Promise<void>;
  deleteKey: () => Promise<void>;
  deleteKeyModalOpen: boolean;
  deletingKey: OpenapiKey | null;
  editingKey: OpenapiKey | null;
  keys: OpenapiKey[];
  loading: boolean;
  newKeyInfo: NewKeyInfo | null;
  clearNewKeyInfo: () => void;
  openCreateKeyModal: () => void;
  openDeleteKeyModal: (key: OpenapiKey) => void;
  openEditKeyModal: (key: OpenapiKey) => void;
  role: OrganizationRole | null;
  setDeleteKeyModalOpen: Dispatch<SetStateAction<boolean>>;
  toggleEnabled: (args: { i: number; id: string; state: 'enabled' | 'disabled' }) => void;
}

/** Ordered list of options as shown in the select. */
const API_KEY_EXPIRATION_OPTIONS: Record<string, number> = {
  never: -1, // Custom handling.
  '1 week': MILLIS_PER_WEEK,
  '2 weeks': 2 * MILLIS_PER_WEEK,
  '1 month': 30 * MILLIS_PER_DAY,
  '6 months': 6 * 30 * MILLIS_PER_DAY,
  '1 year': 356 * MILLIS_PER_DAY,
  custom: -1 // Custom handling.
};

function getExpirationDateFromExpirationPeriod(expiry: string, customExpiry: string): number {
  if (expiry === 'never') return OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS;
  if (expiry !== 'custom') {
    // Round up to minutes.
    const date = new Date(Date.now() + API_KEY_EXPIRATION_OPTIONS[expiry]);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }
  // Support only 2 types of inputs: 1) YYYY-MM-DD 2) YYYY-MM-DD HH:mm.
  // Both values are in UTC.
  // Convert date to ISO format before parsing (example: '2023-02-25T20:06:08.096Z').
  // Any failure with parse will result to NaN.
  if (customExpiry.includes(' ')) return Date.parse(customExpiry.replace(' ', 'T') + ':00.000Z');
  return Date.parse(customExpiry + 'T00:00.000Z');
}

type UseApiKeysArgs = {
  organizationId: string;
};
export function useApiKeys({ organizationId }: UseApiKeysArgs): UseApiKeys {
  const apiClient = useApiClient();
  const [createKeyModalOpen, setCreateKeyModalOpen] = useState(false);
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const role = useOrganizationRole(organizationId);

  const [deletingKey, setDeletingKey] = useState<OpenapiKey | null>(null);
  const [editingKey, setEditingKey] = useState<OpenapiKey | null>(null);
  const [keys, setKeys] = useState<Array<OpenapiKey>>([]);
  const [loading, setLoading] = useState(true);
  const [newKeyInfo, setNewKeyInfo] = useState<NewKeyInfo | null>(null);

  useWebSocketsNotification<OpenapiEntityKeysUpdatePayload>({
    subscriptionDetails: {
      type: 'ORG_OPENAPI_ORG_KEY_UPDATE',
      objId: organizationId
    },
    handler: (message) => {
      setKeys(message.payload.keys);
      setLoading(false);
    },
    dependencies: [organizationId]
  });

  const closeCreateKeyModal = (): void => {
    setCreateKeyModalOpen(false);
    setEditingKey(null);
  };

  const createOrEditKey = async ({ customExpiry, expiry, name, role }: CreateOrEditKeyArgs): Promise<void> => {
    try {
      const expirationDate = getExpirationDateFromExpirationPeriod(expiry, customExpiry);
      if (editingKey) {
        // keep
        const includeExpirationKey = Date.now() < new Date(editingKey.expirationDate).getTime();
        const newExpirationDate = includeExpirationKey ? expirationDate : undefined;
        const updateOptions: UpdateOpenapiKeyOptions = {
          keyId: editingKey.id,
          name,
          expireAt: newExpirationDate,
          roles: [role]
        };
        await apiClient.apiKey.updateKey(updateOptions);
        setKeys((currentKeys): OpenapiKey[] => {
          const newKeyIndex = currentKeys.findIndex((key) => key.id === editingKey.id);
          let newKey = { ...currentKeys[newKeyIndex] };
          const newKeys = [...currentKeys];

          newKey = {
            ...newKey,
            name,
            expirationDate: newExpirationDate || newKey.expirationDate,
            roles: [role]
          };

          newKeys[newKeyIndex] = newKey;

          return newKeys;
        });
      } else {
        const createOptions = {
          entityId: organizationId,
          entityType: 'organization',
          expirationDate,
          name,
          roles: [role]
        };
        const res = await apiClient.apiKey.createKey(createOptions);

        setNewKeyInfo({
          key: res.key,
          secret: res.secret,
          name
        });
        setKeys(res.keys);
      }

      createToast({
        title: 'Success',
        description: `Key ${editingKey ? `${editingKey.name} edited` : 'created'} successfully`,
        type: 'success'
      });
      setCreateKeyModalOpen(false);
      setEditingKey(null);
    } catch (e) {
      console.error(e);
      createToast({
        title: 'Error',
        description: getErrorMessageFromError(e),
        type: 'danger'
      });
      throw e;
    }
  };

  const deleteKey = async (): Promise<void> => {
    if (!deletingKey) {
      return;
    }

    try {
      await apiClient.apiKey.deleteKey(deletingKey.id);
      setKeys((currentKeys) => {
        const index = currentKeys.findIndex((key) => key.id === deletingKey.id);
        if (index === -1) {
          return currentKeys;
        }

        const newKeys = [...currentKeys];
        newKeys.splice(index, 1);
        return newKeys;
      });

      createToast({
        title: 'Success',
        description: `Key ${deletingKey.name} deleted successfully`,
        type: 'success'
      });
      setDeleteKeyModalOpen(false);
      setDeletingKey(null);
    } catch (e) {
      console.error(e);
      createToast({
        title: 'Error',
        description: getErrorMessageFromError(e),
        type: 'danger'
      });
    }
  };

  const openCreateKeyModal = (): void => {
    setEditingKey(null);
    setCreateKeyModalOpen(true);
  };

  const openDeleteKeyModal = (key: OpenapiKey): void => {
    setDeletingKey(key);
    // hack to avoid pointer-events: none; bug with dropdown closing and dialog opening at the same time
    setTimeout(() => {
      setDeleteKeyModalOpen(true);
    });
  };

  const openEditKeyModal = (key: OpenapiKey): void => {
    setEditingKey(key);
    // hack to avoid pointer-events: none; bug with dropdown closing and dialog opening at the same time
    setTimeout(() => {
      setCreateKeyModalOpen(true);
    });
  };

  const toggleEnabled = ({ i, id, state }: { i: number; id: string; state: 'enabled' | 'disabled' }): void => {
    const newState = state === 'enabled' ? 'disabled' : 'enabled';
    apiClient.apiKey
      .updateKey({
        keyId: id,
        state: newState
      })
      .then(() => {
        setKeys((currentKeys) => {
          const newKeys = [...currentKeys];
          const newKey = { ...newKeys[i] };
          newKey.state = newState;

          newKeys[i] = newKey;

          return newKeys;
        });
        createToast({
          title: 'Success',
          description: `Key ${newState} successfully`,
          type: 'success'
        });
      })
      .catch((e: Error) => {
        createToast({
          title: 'Error',
          description: getErrorMessageFromError(e),
          type: 'danger'
        });
      });
  };

  const clearNewKeyInfo = (): void => {
    setNewKeyInfo(null);
  };

  return {
    closeCreateKeyModal,
    createKeyModalOpen,
    createOrEditKey,
    deleteKey,
    deleteKeyModalOpen,
    deletingKey,
    editingKey,
    keys,
    loading,
    newKeyInfo,
    clearNewKeyInfo,
    openCreateKeyModal,
    openDeleteKeyModal,
    openEditKeyModal,
    role,
    setDeleteKeyModalOpen,
    toggleEnabled
  };
}
