import { atom, useAtomValue, useSetAtom } from 'jotai';

const lastVisitedSvcUrlAtom = atom<null | string>(null);
export const useLastVisitedSvcUrl = (): string | null => {
  return useAtomValue(lastVisitedSvcUrlAtom);
};

export const useSetLastVisitedSvcUrl = (): ((url: string | null) => void) => {
  return useSetAtom(lastVisitedSvcUrlAtom);
};
