// serverless websocket inaccuracy.
// See https://github.com/dherault/serverless-offline/issues/1290 for more details.
import { SysadminApp } from '@cp/common/protocol/Sysadmin';
import { MILLIS_PER_MINUTE } from '@cp/common/utils/DateTimeUtils';
import { RpcRequest } from '@cp/common/utils/ProtocolUtils';
export const MIN_WEBSOCKET_CONNECTION_DURATION_IN_MILLIS = MILLIS_PER_MINUTE * 15;

type CpWebSocketMessageType =
  | 'USER_DETAILS_UPDATED'

  /**
   * Sent when instance is created, deleted and on every top-level instance property change
   * stored in CP-API: name, state.
   * This subscription is made on the 'organization' level: objId is organizationId.
   */
  | 'ORG_INSTANCE_UPDATE'

  /**
   * Sent when data warehouse is created or updated
   * This subscription is made on the 'organization' level: objId is organizationId.
   */
  | 'ORG_DATA_WAREHOUSE_UPDATE'

  /**
   * Sent when instance password is changed. Password is not stored in DB or in Instance model,
   * so this event is independent of the 'ORG_INSTANCE_UPDATE'.
   */
  | 'ORG_INSTANCE_PASSWORD_CHANGED'
  | 'ORG_USER_NAME_CHANGED'

  /**
   * Sent when backup is created, deleted and on every 'InstanceBackup' property change stored in CP-API, like 'status'.
   * This subscription is made on the 'organization' level: objId is organizationId.
   */
  | 'ORG_INSTANCE_BACKUP_UPDATE'

  /**
   * Sent when organization is created, deleted or when any field of the Organization model is changed.
   * Includes 'users' and 'invitations' collections.
   * This subscription is made on the 'user' level: objId is userId.
   */
  | 'ORG_UPDATE'

  /**
   * A one time response with all the instances of the all the organizations the user belongs to.
   * This subscription is made on the 'user' level: objId is userId.
   */
  | 'ONE_TIME_ALL_USER_INSTANCES_UPDATE'

  /**
   * Sent when a support case is updated or created.
   * The subscription is made on the organization level.
   */
  | 'CASE_DETAILS_UPDATE'

  /** Sent any of organization-level Openapi key is added/changed/removed. */
  | 'ORG_OPENAPI_ORG_KEY_UPDATE'

  /** Sent if current user receives a new invitation. */
  | 'INVITE_UPDATE'

  /** Send whenever a cp user gets deleted. */
  | 'USER_DELETED'
  /** UI notifications for a cp user.
   * Uses concatenated user id and notification id as objId
   * @see getObjIdForWebsocketUserNotificationSubscription
   */
  | 'USER_UI_NOTIFICATIONS';

type SysadminWebSocketMessageType =
  /**
   * Sent when instance is created, deleted and on every top-level instance property change
   * stored in CP-API: name, state.
   * This subscription is made on the 'organization' level: objId is organizationId.
   */
  | 'SYSADMIN_ORG_INSTANCE_UPDATE'

  /**
   * Sent when an instance is created, deleted and on every top-level instance property change
   * stored in CP-API: name, state.
   * This subscription is made on the instance level: objId is an instance id.
   */
  | 'SYSADMIN_INSTANCE_UPDATE'

  /**
   * Sent when organization is created, deleted or when any field of the Organization model is changed.
   * Includes 'users' and 'invitations' collections.
   * This subscription is made on the 'organization' level: objId is orgId.
   */
  | 'SYSADMIN_ORG_UPDATE'

  /**
   * Sent when a sysadmin user is updated.
   * This subscription is made on the sysadmin user level: objId is userId.
   */
  | 'SYSADMIN_SA_USER_UPDATE'

  /**
   * Sent when a sysadmin group is updated.
   * This subscription is made on the sysadmin group level: objId is the group id.
   */
  | 'SYSADMIN_PERMISSIONS_GROUP_UPDATE'

  /**
   * Sent when we want to send an update regarding the entire set of sysadmin groups.
   * This subscription is made on the sysadmin application level: objId is the application, in this case 'sysadmin'.
   */
  | 'SYSADMIN_PERMISSIONS_GROUPS_UPDATE'

  /**
   * Sent when backup is created, deleted and on every 'InstanceBackup' property change stored in CP-API, like 'status'.
   * This subscription is made on the 'instance' level: objId is instanceId.
   */
  | 'SYSADMIN_INSTANCE_BACKUP_UPDATE';

export type WebSocketsMessageType = CpWebSocketMessageType | SysadminWebSocketMessageType;

/** Set of all RPC actions for websocket handler. */
export type WebSocketsRpcAction = 'subscribe' | 'unsubscribe' | 'updateAccessToken' | 'acknowledgeMessage';

/**
 * Type of the websocket update:
 * - 'COMPLETE' - a complete data set: all instances, all backups, etc...
 * - 'PARTIAL' - a single entity update: single instance state change, single backup removal.
 */
export type UpdateType = 'COMPLETE' | 'PARTIAL';

/**
 * Each subscription has a type such as INSTANCE_CREATED, ORGANIZATION_DELETED, etc...
 * The objId is for identifying the parent of what's changed. For example:
 * ORG_INSTANCE_CREATED - The id is for the organization
 * USER_ORGANIZATION_DELETED - The id is for the user id.
 * ORG_INSTANCE_STATE_CHANGE - Here the id can be both for the instance or the organization - we decided to use the
 *      organization's id.
 * ORG_OPENAPI_ORG_KEY_UPDATE - the id is a user ID in the organization.
 * Note: The type prefix indicates the id object type (USER_ORGANIZATION_DELETED - The id is the user id)
 */
export interface SubscriptionDetails {
  type: WebSocketsMessageType;
  objId: string;
}

/**
 * All functions ending with 'Request' are websockets request coming from the client
 */
export interface WebSocketsSubscribeRequest {
  subscriptions: Array<SubscriptionDetails>;
  clientId: string;
  accessToken: string;
  application?: WebSocketApplicationType;
}

export interface UpdateAccessTokenRequest {
  clientId: string;
  accessToken: string;
  application?: WebSocketApplicationType;
}

export interface WebSocketsUnsubscribeRequest {
  subscriptions: Array<SubscriptionDetails>;
  clientId: string;
}

export interface WebSocketsAcknowledgeMessageRequest {
  messageId: string;
  clientId: string;
}

export type WebSocketRequestType =
  | WebSocketsSubscribeRequest
  | WebSocketsUnsubscribeRequest
  | UpdateAccessTokenRequest
  | WebSocketsUnsubscribeRequest;

export interface WebSocketsClientMessage<T = unknown> {
  rpcAction: WebSocketsRpcAction;
  request: T;
}

export interface WebSocketsRequest<T = unknown> extends RpcRequest<WebSocketsRpcAction> {
  /** See $default websocket handler in serverless.yml. */
  action: 'websocketMessage';
  request: T;
}

/** Returns true if 'value' is a WebSocketsRequest. */
export function isWebSocketsRequest(value: unknown): value is WebSocketsRequest {
  if (typeof value !== 'object' || value === null) {
    return false;
  }
  const request = value as WebSocketsRequest;
  return request.request !== undefined && request.action === 'websocketMessage';
}
export interface WebSocketsMessage {
  subscription: SubscriptionDetails;
  messageId: string;
  payload?: any;
  part?: number;
  totalParts?: number;
}

export interface WebSocketAccountStateInterface {
  getIsAuthenticated: () => boolean;
  isAuthenticated: () => Promise<boolean>;
}

export type WebSocketApplicationType = SysadminApp | 'control-plane';

export interface WebsocketError {
  $response: {
    statusCode: number;
  };
}
