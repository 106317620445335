import {
  Badge,
  Container,
  EllipsisContent,
  Icon,
  IconButton,
  Panel,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
  Text,
  Title,
  Tooltip
} from '@clickhouse/click-ui';
import {
  InstanceBackup,
  InstanceBackupStatus
} from '@cp/common/protocol/Backup';
import { REGION_BY_ID } from '@cp/common/protocol/Region';
import {
  MILLIS_PER_SECOND,
  secondsToString
} from '@cp/common/utils/DateTimeUtils';
import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { RestoreInstanceInput } from 'src/backups/backupsController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import {
  formatMoment,
  formatUtcTimestamp
} from 'src/lib/formatters/dateTimeFormatter';
import { formatBytesToSize } from 'src/lib/SizeUtils';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

import BackupRestoreModal from 'src/pages/BackupsPage/BackupRestoreModal';

export const NoBackupsView = ({
  loading
}: {
  loading: boolean;
}): ReactElement => {
  return (
    <Container orientation="vertical" alignItems="center" gap="md">
      <Spacer size="xxl" />
      <Container>
        <Panel
          orientation="vertical"
          color="default"
          alignItems="center"
          fillWidth
        >
          <Icon
            name={loading ? 'horizontal-loading' : 'backups'}
            height="80px"
            width="80px"
          />
          <Title type="h1" size="md">
            {loading ? 'Loading backup information...' : 'No backups'}
          </Title>
          {!loading && (
            <Text align="center">
              There are currently no backups for this service.
              <br />
              The first backup will be taken shortly.
            </Text>
          )}
        </Panel>
      </Container>
    </Container>
  );
};

const getBadge = (status: InstanceBackupStatus): ReactElement => {
  switch (status) {
    case 'error':
      return <Badge text="Failed" size="md" state="danger" />;
    case 'in_progress':
      return (
        <Badge
          text="In progress"
          icon="loading-animated"
          size="md"
          state="neutral"
          iconDir="start"
        />
      );
    case 'done':
      return <Badge text="Successful" size="md" state="success" />;
  }
};
export const BackupListView = ({
  backups,
  restoreInstance
}: BackupListProps): ReactElement => {
  const [backupToRestore, setBackupToRestore] = useState<InstanceBackup | null>(
    null
  );
  const [backupRestoreModalOpen, setBackupRestoreModalOpen] = useState(false);
  const { instances } = useInstanceStateManager();
  const instance = backupToRestore
    ? instances[backupToRestore.instanceId]
    : null;
  const region =
    backupToRestore && instance ? REGION_BY_ID[instance.regionId] : null;
  const hasManageBackupsPermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage-backups',
    useCurrentOrgUserRole() === 'ADMIN'
  );

  const backupRows: Array<TableRowType> = backups.map(
    (backup: InstanceBackup) => {
      const items = [
        {
          label: (
            <Tooltip>
              <Tooltip.Trigger>
                <EllipsisContent className="fs-exclude">
                  {backup.id}
                </EllipsisContent>
              </Tooltip.Trigger>
              <Tooltip.Content side="bottom" className="fx-exclude">
                {backup.id}
              </Tooltip.Content>
            </Tooltip>
          )
        },
        {
          label: getBadge(backup.status)
        },
        {
          label: (
            <Tooltip>
              <Tooltip.Trigger>
                {formatMoment(backup.effectiveStartTime ?? backup.createdAt)}
              </Tooltip.Trigger>
              <Tooltip.Content side="bottom" className="fx-exclude">
                {formatUtcTimestamp(
                  backup.effectiveStartTime ?? backup.createdAt,
                  'YYYY-MM-DD HH:mm:ss (UTC)'
                )}
              </Tooltip.Content>
            </Tooltip>
          )
        },
        {
          label: backup.finished ? (
            <Tooltip>
              <Tooltip.Trigger>{formatMoment(backup.finished)}</Tooltip.Trigger>
              <Tooltip.Content side="bottom" className="fx-exclude">
                {formatUtcTimestamp(
                  backup.finished,
                  'YYYY-MM-DD HH:mm:ss (UTC)'
                )}
              </Tooltip.Content>
            </Tooltip>
          ) : (
            <Text color="muted">N/A</Text>
          )
        },
        {
          label: backup.finished
            ? secondsToString(
                (backup.finished -
                  (backup.effectiveStartTime ?? backup.createdAt)) /
                  MILLIS_PER_SECOND,
                ','
              )
            : secondsToString(
                (Date.now() - (backup.effectiveStartTime ?? backup.createdAt)) /
                  MILLIS_PER_SECOND,
                ','
              )
        },
        {
          label: formatBytesToSize(backup.size)
        },
        {
          label: backup.isFullBackup ? 'Full' : 'Incremental'
        }
      ];

      if (hasManageBackupsPermission) {
        items.push({
          label: (
            <IconButton
              icon="dots-vertical"
              onClick={() => {
                setBackupToRestore(backup);
                setBackupRestoreModalOpen(true);
              }}
              size="default"
              disabled={backup.status === 'done' ? false : true}
              data-testid="backups-actions-button"
            />
          )
        });
      }
      return {
        id: backup.id,
        items,
        'data-testid': 'backup-row'
      };
    }
  );

  const backupHeaders: Array<TableHeaderType> = [
    { label: 'Backup ID' },
    { label: 'Status', width: '15%' },
    { label: 'Started', width: '15%' },
    { label: 'Finished', width: '15%' },
    { label: 'Duration', width: '15%' },
    { label: 'Size', width: '10%' },
    { label: 'Type', width: '10%' }
  ];

  if (hasManageBackupsPermission) {
    backupHeaders.push({ label: 'Actions', width: '6%' });
  }

  return (
    <Container
      orientation="vertical"
      alignItems="start"
      gap="md"
      maxWidth="1152px"
    >
      <Title type="h1" size="md">
        Backup history
      </Title>

      <Table
        headers={backupHeaders}
        rows={backupRows}
        data-testid="backup-history-table"
      />

      <BackupRestoreModal
        backupToRestore={backupToRestore}
        instance={instance}
        onClose={() => {
          setBackupRestoreModalOpen(false);
          setBackupToRestore(null);
        }}
        onSubmit={({ name }): Promise<void> => {
          if (!backupToRestore) {
            throw new Error('No backup to restore');
          }

          if (!instance) {
            throw new Error('No associated instance to this backup');
          }

          return restoreInstance({
            backupId: backupToRestore.id,
            instanceId: backupToRestore.instanceId,
            name,
            organizationId: instance.organizationId
          });
        }}
        region={region}
        open={backupRestoreModalOpen}
      />
    </Container>
  );
};

export const MainSection = ({
  backups,
  loading,
  restoreInstance
}: MainSectionProps): ReactElement => {
  if (loading || backups.length === 0) {
    return <NoBackupsView loading={loading} />;
  } else {
    return (
      <BackupListView backups={backups} restoreInstance={restoreInstance} />
    );
  }
};

export interface MainSectionProps {
  backups: Array<InstanceBackup>;
  loading: boolean;
  restoreInstance: (props: RestoreInstanceInput) => Promise<void>;
}

export interface BackupListProps {
  backups: Array<InstanceBackup>;
  restoreInstance: (props: RestoreInstanceInput) => Promise<void>;
}
