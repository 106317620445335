import {
  Container,
  Logo,
  Panel,
  Separator,
  Text as CUIText,
  Title,
  Tooltip,
  GridContainer,
  Button,
  Icon,
  Accordion,
  CardHorizontal,
  Link
} from '@clickhouse/click-ui';
import { REGION_BY_ID, RegionId } from '@cp/common/protocol/Region';
import { ReactElement, ReactNode } from 'react';
import { DataWarehouseActionMenu } from 'src/components/DataWarehouseList/DataWarehouseActionMenu';
import styled from 'styled-components';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { formatBytesToSize } from 'src/lib/SizeUtils';
import { ReleaseChannel } from '@cp/common/protocol/Instance';
import { useDataWarehouseSize } from 'src/dataWarehouses/controller/useDataWarehouseSize';

const CardContainer = styled(Panel)`
  transition: background-color 0.2s;
  gap: 0;
  border-style: dashed;
  border-color: ${({ theme }): string => theme.click.global.color.text.muted};
`;

const ServiceTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled(Container)<{ $allowSpan: boolean }>`
  ${({ $allowSpan }): string => ($allowSpan ? 'grid-column: 1 / span 2;' : '')};
`;

const CustomCardHorizontal = styled(CardHorizontal)`
  align-self: stretch;
  div {
    gap: 0.5rem;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }): string =>
      theme.click.global.color.stroke.intense};
    background: none;
  }
`;

export interface DataWarehouseCardProps {
  id: string;
  organizationId: string;
  children: ReactNode;
  title: string;
  version: string;
  regionId: RegionId;
  provider: 'aws' | 'gcp' | 'azure';
  openRenameWarehouse: () => void;
  openDeleteWarehouse: () => void;
  onFastUpgrade: () => void;
  instancesCount: number;
  isFirst: boolean;
  releaseChannel?: ReleaseChannel;
  openResetDB: () => void;
  hasManageServicePermission: boolean;
}

export const DataWarehouseCard = ({
  id,
  organizationId,
  title,
  provider,
  version,
  regionId,
  children,
  openRenameWarehouse,
  openDeleteWarehouse,
  onFastUpgrade,
  instancesCount,
  releaseChannel,
  isFirst,
  openResetDB,
  hasManageServicePermission
}: DataWarehouseCardProps): ReactElement => {
  const { data: warehouseSize, isLoading } = useDataWarehouseSize({
    dataWarehouseId: id,
    organizationId
  });

  const onCreateService = (): void => {
    navigateTo(routes.createNewService());
  };

  return (
    <CardContainer
      hasBorder
      width="100%"
      padding="none"
      orientation="vertical"
      alignItems="start"
      data-testid="warehouse-card"
    >
      <Panel
        width="100%"
        color="muted"
        radii="none"
        padding="none"
        orientation="horizontal"
      >
        <Container isResponsive={false} justifyContent="space-between">
          <Container
            orientation="horizontal"
            alignItems="start"
            padding="sm"
            gap="xs"
            justifyContent="start"
            isResponsive={false}
            fillWidth={false}
          >
            <Container
              orientation="horizontal"
              gap="md"
              isResponsive={false}
              overflow="hidden"
              fillHeight
            >
              <Container
                orientation="horizontal"
                gap="xs"
                isResponsive={false}
                overflow="hidden"
                fillHeight
                fillWidth={false}
              >
                <Icon name="database" />
                <Tooltip>
                  <Tooltip.Trigger>
                    <ServiceTitle
                      type="h3"
                      size="sm"
                      color="default"
                      data-testid="instance-title"
                    >
                      {title}
                    </ServiceTitle>
                  </Tooltip.Trigger>
                  <Tooltip.Content data-testid="tooltip-content">
                    {title}
                  </Tooltip.Content>
                </Tooltip>
              </Container>
              <CUIText color="muted">|</CUIText>
              {isLoading && <Icon name="horizontal-loading" />}
              {warehouseSize && (
                <Title
                  type="h4"
                  size="sm"
                  color="muted"
                  data-testid="instance-version"
                >
                  {formatBytesToSize(warehouseSize)}
                </Title>
              )}
            </Container>
          </Container>
          <Container
            orientation="horizontal"
            alignItems="start"
            padding="sm"
            justifyContent="end"
            isResponsive={false}
            fillWidth={false}
            gap="sm"
          >
            <Container
              orientation="horizontal"
              justifyContent="start"
              gap="sm"
              isResponsive={false}
            >
              <Logo name={provider} width="20px" height="20px" />
              <CUIText data-testid="warehouse-region">{`${REGION_BY_ID[regionId]?.name} (${REGION_BY_ID[regionId]?.displayId})`}</CUIText>
            </Container>
            {hasManageServicePermission && (
              <DataWarehouseActionMenu
                openRenameWarehouse={openRenameWarehouse}
                openDeleteWarehouse={openDeleteWarehouse}
                openResetDB={openResetDB}
                version={version}
                onFastUpgrade={onFastUpgrade}
                releaseChannel={releaseChannel}
              />
            )}
          </Container>
        </Container>
      </Panel>
      <Separator orientation="horizontal" size="xs" />
      <Container isResponsive={false} padding="md">
        <Accordion
          title={`${instancesCount} service${instancesCount === 1 ? '' : 's'}`}
          fillWidth
        >
          <GridContainer
            gridTemplateColumns="1fr 1fr"
            gap="md"
            alignItems="start"
            isResponsive={false}
          >
            {children}
            <ButtonContainer
              fillHeight
              fillWidth
              $allowSpan={!isFirst && instancesCount % 2 === 0}
            >
              {isFirst && instancesCount === 1 ? (
                <CustomCardHorizontal
                  title="Add a new compute service to your warehouse"
                  data-testid="create-new-service-panel"
                >
                  Great for better management of different business units,
                  isolation of read and write operations, and customization of
                  number of nodes for different tasks.{' '}
                  <Link href="#">Learn more</Link>
                  <Button
                    onClick={onCreateService}
                    data-testid="create-new-service-panel-button"
                  >
                    Create Service
                  </Button>
                </CustomCardHorizontal>
              ) : (
                <Button
                  type="secondary"
                  fillWidth
                  onClick={onCreateService}
                  data-testid="create-new-service-button"
                >
                  Create new service
                </Button>
              )}
            </ButtonContainer>
          </GridContainer>
        </Accordion>
      </Container>
    </CardContainer>
  );
};
