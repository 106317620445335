import { ReactElement } from 'react';

export const OccasionallySvg = (): ReactElement => {
  return (
    <svg
      width="66"
      height="41"
      viewBox="0 0 66 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 40H10.1429L12 24L15.5 20L19 24L21 20L24.5 40H46L49 22L53 25L56.5 40L65 40"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
