import { Button, Container } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import useNetworkSettings from 'src/instance/controller/useNetworkSettings';
import { NetworkingSectionView } from 'src/pages/SettingsPage/NetworkingSectionView';
import { Galaxy } from 'galaxy';
import { CreateServiceType } from 'src/components/CreateNewService//useCreateService';

export const NetworkSettings = ({
  onSubmit: onComplete,
  serviceId,
  type = 'default'
}: {
  onSubmit: () => void;
  serviceId: string | null;
  type?: CreateServiceType;
}): ReactElement => {
  const {
    networkType,
    onSubmit: onSubmitProp,
    onChange,
    ipList
  } = useNetworkSettings(serviceId);

  const onSubmit = (): void => {
    onSubmitProp();
    onComplete();
    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.serviceDetails.continueButtonClick`,
      {
        interaction: 'click'
      }
    );
  };

  return (
    <Container orientation="vertical" alignItems="center" gap="md" fillWidth>
      <NetworkingSectionView
        title="Where do you want to connect from?"
        ipsAccessList={ipList}
        onSubmit={onChange}
        selectedIpAccessOption={networkType}
      />
      <Button
        fillWidth
        onClick={onSubmit}
        data-testid="network-settings-button"
      >
        Continue
      </Button>
    </Container>
  );
};
