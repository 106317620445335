import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { css, Theme } from '@emotion/react';
import debounce from 'lodash/debounce';

import LoadingIcon from 'src/components/icons/LoadingIcon';
import PaginationArrowIcon from 'src/components/icons/PaginationArrowIcon';
import { InteractionType } from 'src/lib/logger';

import { PaginationState } from 'src/components/primitives/lib/Spreadsheet/types';

const leftPaginationStyle = { transform: 'rotate(180deg)' };
const classes = {
  root: (theme: Theme) => css`
    background: transparent;
    border-top: 1px solid ${theme.global.color.stroke.default};
    color: ${theme.global.color.text.default};
    cursor: default !important;
    user-select: none;

    min-height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: relative;
    font-family: 'Inter';
    font-size: 14px;
  `,

  paginationControls: css`
    position: absolute;
    left: 0px;
    right: 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    user-select: none;
  `,

  paginationSelector: css`
    position: absolute;
    right: 12px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    user-select: none;
  `,

  currentPageInput: (disabled: boolean) => (theme: Theme) => css`
    height: 32px;
    width: 36px;
    background: transparent;
    border: 1px solid ${theme.global.color.stroke.default};
    border-radius: 4px;
    text-align: center;
    user-select: inherit;
    color: ${theme.global.color.text.default};

    display: flex;
    align-items: center;

    user-select: none;
    outline: none !important;

    &::selection {
      background: transparent;
      color: ${theme.global.color.text.muted};
    }

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      user-select: none;
    }

    &:focus {
      &::selection {
        background: ${theme.global.color.accent.default};
        color: ${theme.global.color.background.default};
      }
      border: 1px solid ${theme.global.color.accent.default};
      user-select: text;
    }

    ${disabled &&
    `
  cursor: not-allowed;
  user-select: none;
      `}
  `,

  paginationButton: (theme: Theme) => css`
    line-height: 10px;
    margin: 0px 8px;
    outline: none;
    background: none;
    border: none;
    user-select: none;
    color: ${theme.global.color.text.default};
    transition: all 150ms ease 0ms;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: ${theme.global.color.accent.default};
      transition: unset;
    }
    &:disabled {
      cursor: not-allowed;
      color: ${theme.global.color.stroke.default} !important;
    }
  `,

  rowCountStyle: (theme: Theme) => css`
    position: absolute;
    left: 12px;
    user-select: none;
    color: ${theme.global.color.text.muted};
  `,

  pageNumStyle: (theme: Theme) => css`
    user-select: none;
    color: ${theme.global.color.text.muted};
    margin-left: 8px;
  `
};

export interface OnLoadPageProps {
  currentPage: number;
  pageSize: number;
  offset: number;
}

export interface GridFooterProps {
  paginationState?: PaginationState | undefined;
  onLoadPage: (props: OnLoadPageProps) => void;
  pageSize: number;
  hideRowCount: boolean;
  insertRow?: boolean;
  rowsLoading?: boolean;
  rowsLoadingText?: string;
  rowsIsEstimate?: boolean;
  filterApplied?: boolean;
  unknownTotalPages?: boolean;
  numRows: number;
  paginationSelector?: ReactElement;
  log: (
    eventName: string,
    eventTrigger: InteractionType,
    from?: string
  ) => void;
}

export default React.memo(function GridFooter(props: GridFooterProps) {
  const {
    onLoadPage,
    pageSize,
    hideRowCount,
    insertRow,
    rowsLoading,
    rowsLoadingText,
    rowsIsEstimate,
    filterApplied,
    numRows,
    paginationState,
    paginationSelector,
    log,
    unknownTotalPages
  } = props;
  const currentPageProp = paginationState?.currentPage;

  const [currentPage, setCurrentPage] = useState(currentPageProp);

  const totalPages =
    paginationState?.totalPages ||
    (paginationState?.totalRows && pageSize
      ? Math.ceil(paginationState.totalRows / pageSize)
      : paginationState?.totalRows);

  const loadPage = useMemo(
    () =>
      debounce(
        (currentPage, pageSize) => {
          if (typeof onLoadPage === 'function') {
            onLoadPage({
              currentPage,
              pageSize,
              offset: (currentPage - 1) * pageSize
            });
          }
        },
        250,
        {
          leading: false,
          trailing: true
        }
      ),
    [onLoadPage]
  );

  const onNext = () => {
    if (typeof onLoadPage !== 'function') {
      return;
    }

    setCurrentPage((state) => {
      state = state || 1;
      if (totalPages === undefined || totalPages === null) {
        state = state + 1;
      } else if (totalPages !== 0 && currentPage !== totalPages) {
        state = state + 1;
      }
      loadPage(state, pageSize);
      return state;
    });
    log('paginationForwardButtonClick', 'click', 'footer');
  };

  const onPrevious = () => {
    if (typeof onLoadPage !== 'function') {
      return;
    }

    setCurrentPage((state) => {
      state = state || 1;
      if (currentPage && currentPage > 1) {
        state = state - 1;
      }
      if (insertRow && currentPage === totalPages) {
        loadPage(state, pageSize - 1);
      } else {
        loadPage(state, pageSize);
      }
      return state;
    });
    log('paginationBackwardButtonClick', 'click', 'footer');
  };

  const onPageChange = (value: number) => {
    if (typeof onLoadPage !== 'function') {
      return;
    }

    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(paginationState?.currentPage);
  }, [paginationState?.currentPage]);

  const displayedRows = paginationState ? paginationState.totalRows : numRows;
  const showOfPagesSpan =
    !unknownTotalPages && totalPages != null && !filterApplied;

  return (
    <div css={classes.root}>
      {!hideRowCount &&
        displayedRows !== undefined &&
        displayedRows !== null &&
        !filterApplied &&
        !unknownTotalPages && (
          <div css={classes.rowCountStyle} data-testid="rowCount">
            {rowsLoading && !rowsLoadingText && (
              <LoadingIcon width={18} height={18} />
            )}
            {rowsLoading && rowsLoadingText && (
              <React.Fragment>{rowsLoadingText}</React.Fragment>
            )}
            {!rowsLoading && (
              <React.Fragment>
                {rowsIsEstimate ? '~' : ''}
                {displayedRows.toLocaleString()} rows
              </React.Fragment>
            )}
          </div>
        )}
      {paginationState && (
        <div css={classes.paginationControls}>
          <button
            css={classes.paginationButton}
            onClick={onPrevious}
            disabled={!currentPage || currentPage <= 1}
          >
            <PaginationArrowIcon
              color="currentColor"
              style={leftPaginationStyle}
            />
          </button>
          <input
            type="number"
            css={classes.currentPageInput(totalPages === 1)}
            disabled={totalPages === 1}
            max={
              totalPages !== undefined && totalPages !== null
                ? totalPages
                : undefined
            }
            step={1}
            onFocus={(e) => {
              e.target.select();
              log('paginationFocus', 'trigger', 'footer');
            }}
            onChange={(e) => {
              const value = e.target.value;
              setCurrentPage(parseInt(value));
            }}
            onBlur={() => {
              const currentPageNumber = Number(currentPage);
              const isInteger = Number.isInteger(Number(currentPageNumber));
              if (totalPages == null) {
                if (isInteger && currentPageNumber > 0) {
                  onPageChange(currentPageNumber);
                  loadPage(currentPageNumber, pageSize);
                }
              } else if (
                isInteger &&
                currentPageNumber > 0 &&
                currentPageNumber <= totalPages
              ) {
                onPageChange(currentPageNumber);
                loadPage(currentPageNumber, pageSize);
              } else if (isInteger && currentPageNumber <= 0) {
                onPageChange(1);
                setCurrentPage(1);
                loadPage(1, pageSize);
              } else if (isInteger && currentPageNumber > totalPages) {
                onPageChange(totalPages);
                setCurrentPage(totalPages);
                loadPage(totalPages, pageSize);
              } else {
                setCurrentPage(currentPageProp);
                loadPage(currentPageProp, pageSize);
              }
              log('paginationBlur', 'trigger', 'footer');
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const key = e.key.toLowerCase();
              if (['escape', 'enter'].includes(key)) {
                e.preventDefault();
                e.currentTarget.blur();
              } else if (
                !(e.metaKey || e.ctrlKey) &&
                !key.match(/[0-9]/g) &&
                !['backspace', 'delete'].includes(key)
              ) {
                e.preventDefault();
              }
            }}
            value={currentPage}
          />
          {showOfPagesSpan && (
            <span css={classes.pageNumStyle}>
              of {(totalPages || 1).toLocaleString()}
            </span>
          )}
          <button
            css={classes.paginationButton}
            onClick={onNext}
            disabled={
              typeof totalPages === 'number' &&
              ((!totalPages &&
                !(filterApplied || unknownTotalPages || rowsIsEstimate)) ||
                totalPages === 0 ||
                currentPage === totalPages ||
                numRows < pageSize)
            }
          >
            <PaginationArrowIcon color="currentColor" />
          </button>
        </div>
      )}
      {paginationSelector && (
        <div css={classes.paginationSelector}>{paginationSelector}</div>
      )}
    </div>
  );
});
