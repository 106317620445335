import { useCallback } from 'react';
import { PrimitiveAtom, useAtom } from 'jotai';
import { Credential } from 'src/state/connection';

type GptRequestParams = {
  credential: Credential;
  serviceId: string;
};

export type GptRequestHookResult<T extends GptRequestParams, ResponseType> = {
  /**
   * Sends a request to OpenAI GPT
   * @param params - Request parameters, including service ID. Exact parameters depend on T
   * @returns promise resolving to the SQL query text
   * @throws Error if request fails or service ID is missing
   **/
  sendRequest: (params: T) => Promise<ResponseType | undefined>;

  /**
   * Cancel the request
   */
  cancelRequest: () => void;

  // Is request in progress
  isRequestRunning: boolean;
};

type AbortControllerAtomType = PrimitiveAtom<AbortController | null>;

/**
 * A hook for making requests to OpenAI GPT queries.
 * @param requestFunction - Function that sends the request to OpenAI GPT
 * @param abortControllerAtom - Atom that stores the AbortController instance.
 * This atom is passed to this hook because we want different hooks cancel their requests separately.
 * @returns An object with functions and properties for making and managing requests
 */
export function useGptRequest<T extends GptRequestParams, ResponseType>(
  requestFunction: (request: T, signal: AbortSignal) => Promise<ResponseType>,
  abortControllerAtom: AbortControllerAtomType
): GptRequestHookResult<T, ResponseType> {
  const [abortController, setAbortController] = useAtom(abortControllerAtom);

  const sendRequest = useCallback(
    async (requestParams: T): Promise<ResponseType | undefined> => {
      const controller = new AbortController();
      // Abort the previous request if it exists
      abortController?.abort();
      // Set the new one
      setAbortController(controller);
      try {
        return await requestFunction(requestParams, controller.signal);
      } catch (e) {
        if (e instanceof DOMException && e.name === 'AbortError') {
          // Request was aborted
          return;
        }
        // re-throw the error if it's not an abort error
        // so UI will show the error message
        throw e;
      } finally {
        setAbortController(null);
      }
    },
    [abortController, requestFunction, setAbortController]
  );

  const cancelRequest = useCallback((): void => {
    abortController?.abort();
    setAbortController(null);
  }, [abortController, setAbortController]);

  return {
    sendRequest,
    cancelRequest,
    isRequestRunning: !!abortController
  };
}
