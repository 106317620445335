import styled from 'styled-components';
import React, { ReactElement, useState } from 'react';
import { Dropdown, IconButton } from '@clickhouse/click-ui';
import { SerializedStyles, css, Theme } from '@emotion/react';

import { ClickPipeStatus } from 'types/protocol';
import ClickPipeDeleteDialog from 'src/components/ClickPipesDeleteDialog/ClickPipeDeleteDialog';
import ClickPipeActionDialog from 'src/components/ClickPipeActionDialog/ClickPipeActionDialog';

type Props = {
  pipeType: string;
  pipeId: string;
  pipeName: string;
  status?: ClickPipeStatus;
  onDelete: () => Promise<boolean>;
  onShowDetails: () => void;
  onPause: () => Promise<boolean>;
  onResume: () => Promise<boolean>;
};

function removeStyle(theme: Theme): SerializedStyles {
  return css({
    color: theme.colors.alert.text.danger
  });
}

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Actions({
  pipeType,
  pipeId,
  pipeName,
  status,
  onDelete,
  onShowDetails,
  onPause,
  onResume
}: Props): ReactElement {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false);
  const [retryDialogOpen, setRetryDialogOpen] = useState(false);

  const disableAction = pipeType === 'postgres';

  return (
    <CenteredContainer>
      <Dropdown>
        <Dropdown.Trigger>
          <IconButton icon="dots-vertical" size="sm" />
        </Dropdown.Trigger>
        <Dropdown.Content align="end" side="bottom">
          <Dropdown.Item disabled={disableAction} onClick={onShowDetails}>
            Details
          </Dropdown.Item>
          {status === 'Stopped' && (
            <Dropdown.Item
              disabled={disableAction}
              onClick={() => setResumeDialogOpen(true)}
            >
              Resume
            </Dropdown.Item>
          )}
          {status === 'Failed' && (
            <Dropdown.Item
              disabled={disableAction}
              onClick={() => setResumeDialogOpen(true)}
            >
              Retry
            </Dropdown.Item>
          )}
          {status === 'Running' && (
            <Dropdown.Item
              disabled={disableAction}
              onClick={() => setPauseDialogOpen(true)}
            >
              Pause
            </Dropdown.Item>
          )}
          <Dropdown.Item
            disabled={disableAction}
            onClick={() => setDeleteDialogOpen(true)}
            css={removeStyle}
          >
            Remove
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown>

      <ClickPipeDeleteDialog
        open={deleteDialogOpen}
        pipeId={pipeId}
        name={pipeName}
        onDelete={onDelete}
        onCancel={(): void => {
          setDeleteDialogOpen(false);
        }}
        data-testid="delete-clickpipe-modal"
      />

      <ClickPipeActionDialog
        type={'pause'}
        open={pauseDialogOpen}
        pipeId={pipeId}
        name={pipeName}
        onAction={onPause}
        onCancel={(): void => {
          setPauseDialogOpen(false);
        }}
        data-testid="pause-clickpipe-modal"
      />

      <ClickPipeActionDialog
        type={'resume'}
        open={resumeDialogOpen}
        pipeId={pipeId}
        name={pipeName}
        onAction={onResume}
        onCancel={(): void => {
          setResumeDialogOpen(false);
        }}
        data-testid="resume-clickpipe-modal"
      />

      <ClickPipeActionDialog
        type={'retry'}
        open={retryDialogOpen}
        pipeId={pipeId}
        name={pipeName}
        onAction={onResume}
        onCancel={(): void => {
          setRetryDialogOpen(false);
        }}
        data-testid="retry-clickpipe-modal"
      />
    </CenteredContainer>
  );
}

export default React.memo(Actions);
