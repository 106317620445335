import { css, SerializedStyles, Theme } from '@emotion/react';
import { transparentBtn } from 'global-styles';

export default {
  tabBarContainer: css({
    width: '100%',
    maxWidth: '100%',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden'
  }),
  container: css`
    height: 100%;
    display: grid;
    grid-template-columns: 40px auto 36px;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    max-width: calc(100% - 140px);
  `,

  newTab: (theme: Theme): SerializedStyles => css`
    ${transparentBtn}
    cursor: pointer;
    height: 40px;
    width: 36px;
    display: grid;
    place-items: center;
    color: ${theme.global.color.text.muted};

    &:hover svg {
      color: ${theme.global.color.text.default};
    }
  `,
  tabButton: (theme: Theme) => css`
    ${transparentBtn};
    cursor: pointer;
    padding-left: 8px;
    padding-right: 12px;
    font-weight: 500;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 100%;
    background: ${theme.global.color.background.muted};
    border: none;
    border-radius: inherit;
    color: ${theme.global.color.text.muted};
    .tab-func {
      display: flex;
      height: 100%;
      gap: 10px;
      width: 10px;
      .content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 12px;
        * {
          display: grid;
          place-items: center;
          height: 100%;
        }
        .close-btn {
          display: none;
        }
      }
    }
    .tab-name {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      text-align: left;
    }
    .preview-tab {
      font-style: italic;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      right: -1px;
      height: 2px;
      background: transparent;
    }
    .status {
      position: relative;
      width: 8px;
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      &[data-status='unsaved']::after {
        background: ${theme.global.color.accent.default};
      }
      &[data-status='finished']::after {
        background: ${theme.colors.alert.bg.success};
      }
      &[data-status='error']::after {
        background: ${theme.colors.alert.bg.danger};
      }
    }
    &:hover {
      color: ${theme.global.color.background.default};
      .tab-func .content > *:not(.close-btn) {
        display: none;
      }
      .tab-func .content .close-btn {
        display: grid;
        place-items: center;
        opacity: 1;
      }
      &:not([data-selected='true']) {
        background: ${theme.global.color.background.default};
        color: ${theme.global.color.text.muted};
      }
    }
    &[data-selected='true'] {
      color: ${theme.global.color.text.default};
      background: ${theme.global.color.background.default};
      &::after {
        background: ${theme.global.color.accent.default};
      }
    }
  `,
  tabsContainer: css`
    flex-shrink: 0;
    max-width: 100%;
    width: fit-content;

    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  `,
  tabContainer: css`
    ${transparentBtn};
    width: 100%;
    max-width: 200px;
    height: 100%;
    position: relative;
    border-radius: inherit;
  `,
  homeContainer: (theme: Theme): SerializedStyles => css`
    ${transparentBtn};
    height: 40px;
    position: relative;
    cursor: pointer;
    width: auto;
    padding: 13px;
    display: grid;
    place-items: center;
    background: ${theme.global.color.background.muted};
    color: ${theme.global.color.text.muted};
    border: 1px solid ${theme.global.color.stroke.default};
    border-bottom: none;
    border-left: none;
    border-radius: 0px;
    &:not([data-has-tabs='true']) {
      border-top-right-radius: 4px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      height: 2px;
      width: calc(100% + 2px);
    }
    &:hover,
    &[data-active='true'] {
      background: ${theme.global.color.background.default};
      color: ${theme.global.color.text.default};
    }
    &[data-active='true']::after {
      background: ${theme.global.color.background.default};
    }
    &[data-active='false']:hover {
      background: ${theme.global.color.background.default};
    }
  `,
  scrollToContainer:
    (width: number) =>
    (theme: Theme): SerializedStyles =>
      css({
        height: 40,
        border: '1px solid',
        borderColor: theme.global.color.stroke.default,
        borderBottom: 'none',
        width: `clamp(75px, ${width}px, 200px)`,
        "&:not([data-dragging='true'])": {
          borderLeftColor: 'transparent'
        },

        '.tab-func:not(&:hover)': {
          width: width < 100 ? 0 : 10,
          '.status': {
            display: width < 100 ? 'none' : 'grid'
          }
        },
        borderRadius: 0,
        '&:last-of-type': {
          borderTopRightRadius: 4
        },
        '& > div': {
          height: '100%',
          width: '100%',
          borderRadius: 'inherit'
        }
      }),
  contextContentStyle: css({
    width: 'var(--radix-dropdown-menu-trigger-width)'
  })
};
