import {
  Button,
  Container,
  EllipsisContent,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
  Text
} from '@clickhouse/click-ui';
import {
  Organization,
  OrganizationUser
} from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import Helmet from 'src/components/Helmet/Helmet';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { formatDateShort } from 'src/lib/formatters/dateTimeFormatter';
import ActionsLayout from 'src/pages/MembersPage/ActionsLayout';
import { generateNameAvatar, getAuthIcons } from 'src/pages/MembersPage/utils';

interface MembersPageProps {
  currentUser: OrganizationUser;
  currentOrganization: Organization;
  onInviteMembers: () => void;
}

export const MembersPageView = ({
  currentUser,
  currentOrganization,
  onInviteMembers
}: MembersPageProps): ReactElement => {
  const hasManageOrganizationPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage',
      currentUser.role === 'ADMIN'
    ) && currentOrganization.restrictions.canInviteMembers;

  const orgUsers = currentOrganization.users;
  const orgInvites = currentOrganization.invitations;
  const memberHeaders: Array<TableHeaderType> = [
    { label: 'User', width: '45%' },
    { label: 'Role', width: '14%' },
    { label: 'Provider', width: '10%' },
    { label: 'MFA status', width: '10%' },
    { label: 'Member since', width: '13%' }
  ];
  if (hasManageOrganizationPermission) {
    memberHeaders.push({ label: 'Actions', width: '8%' });
  }

  const memberRows: Array<TableRowType> = [];
  for (const idx in orgUsers) {
    const orgUser = orgUsers[idx];
    const userName = orgUser.name || orgUser.email;
    const items = [
      {
        label: (
          <Container gap="sm" isResponsive={false} fillWidth>
            {generateNameAvatar(userName)}
            <EllipsisContent>
              <Text color="muted" data-testid="email-cell">
                {orgUser.email}
              </Text>
            </EllipsisContent>
          </Container>
        ),
        'data-testid-user': userName
      },
      {
        label: [
          orgUser.role.slice(0, 1),
          orgUser.role.slice(1).toLowerCase()
        ].join(''),
        'data-testid': 'role-cell'
      },
      {
        label: orgUser.identityProviders
          ? getAuthIcons(orgUser.identityProviders)
          : 'N/A'
      },
      {
        label:
          orgUser.mfaPreferredMethod && orgUser.mfaPreferredMethod !== 'NOMFA'
            ? 'Enabled'
            : 'Not set'
      },
      {
        label: formatDateShort(orgUser.joinedAt),
        'data-testid': 'member-since'
      }
    ];
    if (hasManageOrganizationPermission) {
      items.push({
        label:
          orgUser.userId !== currentUser.userId ? (
            <Container data-testid={`user-actions-container-${orgUser.email}`}>
              <ActionsLayout
                user={orgUser.userId}
                userRole={orgUser.role}
                isInvite={false}
                organizationId={currentOrganization.id}
              />
            </Container>
          ) : (
            ''
          )
      });
    }
    const orgUserRecord: TableRowType = {
      id: `member-${orgUser.userId}`,
      items
    };
    memberRows.push(orgUserRecord);
  }
  for (const idx in orgInvites) {
    const orgInvite = orgInvites[idx];
    const inviteExpired = orgInvite.expirationDate < Date.now();
    const items = [
      {
        label: (
          <Container gap="sm" isResponsive={false} fillWidth>
            {generateNameAvatar(orgInvite.email)}
            <EllipsisContent>
              <Text color="muted" data-testid="email-cell">
                {orgInvite.email}
              </Text>
            </EllipsisContent>
          </Container>
        ),
        'data-testid-user': orgInvite.email
      },
      {
        label: [
          orgInvite.role.slice(0, 1),
          orgInvite.role.slice(1).toLowerCase()
        ].join(''),
        'data-testid': 'role-cell'
      },
      { label: '' },
      { label: '' },
      {
        label: (
          <EllipsisContent component={Text} color="muted">
            {inviteExpired ? 'Invitation expired' : 'Invitation pending'}
          </EllipsisContent>
        ),
        'data-testid': 'comment-cell'
      }
    ];

    if (hasManageOrganizationPermission) {
      items.push({
        label: (
          <Container data-testid={`user-actions-container-${orgInvite.email}`}>
            <ActionsLayout
              user={orgInvite.email}
              userRole={orgInvite.role}
              isInvite={true}
              inviteExpired={inviteExpired}
              organizationId={currentOrganization.id}
            />
          </Container>
        ),
        'data-testid': ''
      });
    }
    const orgInviteRecord: TableRowType = {
      id: `invite-${idx}`,
      items
    };
    memberRows.push(orgInviteRecord);
  }

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar
        label={`Users and roles - ${currentOrganization.name}`}
      >
        {hasManageOrganizationPermission && (
          <Button
            label="Invite members"
            iconLeft="plus"
            onClick={(): void => {
              console.log('vvvvvvv');
              onInviteMembers();
            }}
            data-testid="invite-members-button"
          />
        )}
      </MainPaneLayout.Topbar>
      <MainPaneLayout.Content>
        <Helmet>
          <title>Users and roles - {currentOrganization.name}</title>
        </Helmet>
        <MaxWidthContainer
          orientation="vertical"
          padding="lg"
          alignItems="start"
          data-testid="members-page"
        >
          <Text color="default">
            Users that have access to the ClickHouse Cloud console.
          </Text>
          <Spacer />
          <Table size="md" headers={memberHeaders} rows={memberRows} />
        </MaxWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};
