import { createToast } from 'primitives';
import config from 'src/lib/config';
import { ReactElement, ReactFragment, useCallback } from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

declare global {
  interface Window {
    recaptchaOptions: {
      useRecaptchaNet: boolean;
      enterprise: boolean;
    };
  }
}

window.recaptchaOptions = {
  useRecaptchaNet: true,
  enterprise: true
};

type Props = {
  children: ReactElement | ReactFragment;
};

export function useRecaptcha(): (action: string) => Promise<string | null> {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return useCallback(
    async (action: string): Promise<string | null> => {
      if (!executeRecaptcha) {
        console.warn('Recaptcha not loaded');
        return null;
      }

      return executeRecaptcha(action);
    },
    [executeRecaptcha]
  );
}

export const RecaptchaProvider = ({ children }: Props): ReactElement => {
  if (!config.recaptcha.siteKey) {
    return <>{children}</>;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.recaptcha.siteKey}
      useRecaptchaNet={true}
      useEnterprise={true}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
      container={{
        // optional to render inside custom element
        element: 'recaptcha-container',
        parameters: {
          errorCallback: (): void => {
            createToast(
              'Recaptcha error',
              'error',
              'Could not load recaptcha. Please refresh your page'
            );
          }
        }
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};
