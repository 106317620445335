import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';
import { Galaxy } from 'galaxy';
import { ClickhouseLoading } from 'primitives';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import EntryQuestionnaire from 'src/components/EntryQuestionnaire/EntryQuestionnaire';
import { useInitGalaxy } from 'src/galaxy/useInitGalaxy';
import { useInitInstanceState } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import {
  setIsUnifiedConsoleInLocalStorage,
  useIsUnifiedConsole
} from 'src/lib/features';
import { setGrowthBookOrgId } from 'src/lib/growthbook/growthbook';
import { useGetPendingUserActions } from 'src/lib/pendingUserActions/useGetPendingUserActions';
import { routes } from 'src/lib/routes';
import { useInitOnboardState } from 'src/onboard/controller/useInitOnboardState';
import { useOnboardStateManager } from 'src/onboard/onboardState';
import {
  useAssignCurrentOrgOnRouteChange,
  useInitOrganizationState
} from 'src/organization/organizationController';
import {
  useCurrentOrganization,
  useOrganizationStateManager
} from 'src/organization/organizationState';
import {
  useInitInvitationState,
  useInvitationController
} from 'src/state/notification/invitationController';

import { useInitUserState } from 'src/user/userController';
import { useUserStateManager } from 'src/user/userState';
import { FCChild } from 'types/commonTypes';
import { useInitDataWarehouseState } from 'src/dataWarehouses/controller/useInitDataWarehouseState';

const useInitIsUnifiedConsoleInLocalStorage = (): void => {
  const isUnified = useIsUnifiedConsole();

  useEffect(() => {
    setIsUnifiedConsoleInLocalStorage(isUnified);
  }, [isUnified]);
};

const AppWithOrgAndInstanceStateInitialized = ({
  children
}: {
  children: ReactNode;
}): ReactElement => {
  useInitGalaxy();
  useInitInvitationState();
  useInitIsUnifiedConsoleInLocalStorage();

  useEffect(() => {
    Galaxy.galaxy().track('auth.provider.signin', {
      interaction: 'trigger'
    });
  }, []);
  return <>{children}</>;
};

const OutletWrapper = ({
  pathname,
  url
}: {
  pathname: string;
  url: string;
}): ReactElement => {
  if (pathname === url) {
    return (
      <AppWithOrgAndInstanceStateInitialized>
        <Outlet />
      </AppWithOrgAndInstanceStateInitialized>
    );
  }

  return <Navigate replace to={url} />;
};

const Initializer: FCChild = ({ children }) => {
  useInitUserState();
  useInitOrganizationState();
  useInitDataWarehouseState();
  useInitInstanceState();
  useInitOnboardState();
  useAssignCurrentOrgOnRouteChange();

  const { invitations } = useInvitationController();
  const { pathname } = useLocation();
  const { user } = useUserStateManager();
  const { isInstanceStateInitialized } = useInstanceStateManager();
  const { isOnboardingInProgress } = useOnboardStateManager();

  const pendingUserActions = useGetPendingUserActions();

  let hasMarketplaceOnboarding = false,
    hasEntryQuestionnaire = false;

  pendingUserActions.forEach((action) => {
    if (action.type === 'tackle-subscription') {
      hasMarketplaceOnboarding = true;
    }
    if (action.type === 'entry-questionnaire') {
      hasEntryQuestionnaire = true;
    }
  });

  const {
    currentOrganizationId,
    isOrganizationStateInitialized,
    organizations
  } = useOrganizationStateManager();

  const organizationIds = Object.keys(organizations);

  let hasByocOnboarding = pathname === '/byocOnboarding';

  if (organizationIds.length === 1) {
    // If we have only one org, and it's an unconfigured BYOC org, we should redirect to byoc
    // onboard page because before that is done there's nothing the user can do in the app.
    const onlyOrg = organizations[organizationIds[0]];
    if (isOrgByoc(onlyOrg) && Object.keys(onlyOrg.byocConfig).length === 0) {
      hasByocOnboarding = true;
    }
  }

  const hasOrganizations = Object.values(organizations).length > 0;
  const currentOrganization = useCurrentOrganization();

  useEffect(() => {
    if (currentOrganizationId) {
      setGrowthBookOrgId(currentOrganizationId);
    }
  }, [currentOrganizationId]);

  if (!isOrganizationStateInitialized) {
    return <ClickhouseLoading isFullScreen />;
  }

  if (!user.acceptedTOSContractId) {
    return <OutletWrapper url="/termsOfService" pathname={pathname} />;
  }

  if (
    isOrganizationStateInitialized &&
    isInstanceStateInitialized &&
    !currentOrganization &&
    !hasOrganizations &&
    invitations?.length > 0
  ) {
    return <OutletWrapper url="/approveOrgInvitations" pathname={pathname} />;
  }

  if (hasMarketplaceOnboarding) {
    return <OutletWrapper url="/marketplaceOnboarding" pathname={pathname} />;
  }

  if (hasByocOnboarding) {
    return <OutletWrapper url="/byocOnboarding" pathname={pathname} />;
  }

  if (isOnboardingInProgress) {
    return <OutletWrapper url="/onboard" pathname={pathname} />;
  }

  if (!currentOrganization || !isInstanceStateInitialized) {
    return <ClickhouseLoading isFullScreen />;
  }

  // These routes should not be accessible directly
  if (
    ['/approveOrgInvitations', '/marketplaceOnboarding', '/onboard'].includes(
      pathname
    )
  ) {
    return <Navigate to={routes.services()} />;
  }

  return (
    <AppWithOrgAndInstanceStateInitialized>
      {hasEntryQuestionnaire && <EntryQuestionnaire />}
      {children}
    </AppWithOrgAndInstanceStateInitialized>
  );
};

export default Initializer;
