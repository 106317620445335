import {
  CellProps,
  Container,
  Grid,
  Panel,
  Title,
  WarningAlert
} from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import {
  hydrateConfig,
  useDebouncedRunQuery
} from 'src/components/Dashboards/DashboardView/DashboardElement/utils';
import { DashboardTableConfig } from 'shared/src/types/dashboard';
import {
  useDashboardObjectState,
  useDashboardOutputs,
  useUpdateDashboardObjectOutput,
  useUpdateDashboardObjectState
} from 'src/state/dashboard/dashboardState';
import { useCredentials } from 'src/state/connection';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { QueryResult } from 'src/lib/clickhouse/query';
import { EllipsisContainer } from 'src/layout/GlobalStyles';

interface TableConfig {
  parameters: Record<string, string>;
  queryId: string;
  title: string;
  type: 'table';
}

interface TableElementProps {
  config: DashboardTableConfig;
  dashboardId: string;
  objectId: string;
}

export default function TableElement({
  config,
  dashboardId,
  objectId
}: TableElementProps): ReactElement {
  const outputs = useDashboardOutputs({
    dashboardId
  });
  const hydratedConfig = hydrateConfig(config, outputs) as TableConfig;

  const { parameters, queryId, title } = hydratedConfig;

  const credentials = useCredentials();
  const query = useSavedQuery(queryId);
  const state = useDashboardObjectState({
    dashboardId,
    objectId
  });
  const updateState = useUpdateDashboardObjectState({
    dashboardId,
    objectId
  });
  const updateOutput = useUpdateDashboardObjectOutput({
    dashboardId,
    objectId
  });

  const data = state.data as QueryResult | undefined;
  const selectedColumnIndex = (state.selectedColumnIndex as number) || 0;
  const selectedRowIndex = (state.selectedRowIndex as number) || 0;

  const debouncedRunQuery = useDebouncedRunQuery();

  useEffect(() => {
    updateState({
      data: null
    });
    if (query) {
      updateState({
        loading: true
      });
      debouncedRunQuery(
        query.query,
        {
          ...credentials,
          database: query.database
        },
        {
          wakeService: true,
          variables: parameters
        }
      )
        .then((results) => {
          let selectedCell;
          let selectedRow;
          if (results && !('error' in results)) {
            selectedCell =
              results.rows[selectedRowIndex]?.[selectedColumnIndex];
            selectedRow = results.rows[selectedRowIndex];
          }
          updateState({
            data: results,
            loading: false,
            selectedCell,
            selectedRow
          });
        })
        .catch(console.error);
    }
  }, [query?.query, JSON.stringify(parameters)]);

  const Cell: CellProps = ({ columnIndex, rowIndex, type, ...props }) => {
    if (!data || 'error' in data) {
      return null;
    }

    if (type === 'header-cell') {
      return (
        <Container color="muted">
          <EllipsisContainer {...props}>
            {data.columns[columnIndex].name}
          </EllipsisContainer>
        </Container>
      );
    }

    return <span {...props}>{data.rows[rowIndex]?.[columnIndex]}</span>;
  };

  const onFocusChange = (rowIndex: number, columnIndex: number): void => {
    if (!data || 'error' in data) {
      return;
    }

    updateState({
      selectedColumnIndex: columnIndex,
      selectedRowIndex: rowIndex
    });
    updateOutput({
      selectedCell: data.rows[rowIndex]?.[columnIndex],
      selectedRow: data.rows[rowIndex]
    });
  };

  return (
    <Panel hasBorder orientation="vertical" alignItems="start" fillHeight>
      <Title type="h2">{title}</Title>
      {!query ? (
        <Container>Please select a query</Container>
      ) : state.loading || !data ? (
        <Container>Loading...</Container>
      ) : 'error' in data ? (
        <Container padding="sm">
          <WarningAlert text={data.error} data-testid="chart-error" />
        </Container>
      ) : (
        <Grid
          columnCount={data.columns.length ?? 0}
          rowCount={data.rows.length}
          cell={Cell}
          focus={{
            row: selectedRowIndex,
            column: selectedColumnIndex
          }}
          onFocusChange={onFocusChange}
          showToast={true}
        />
      )}
    </Panel>
  );
}
