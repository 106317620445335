import { Container, Label, Select, TextField } from '@clickhouse/click-ui';
import { ReactElement, useEffect, useState } from 'react';
import {
  DashboardConfigField,
  DashboardFilterConfig,
  DashboardFilterType
} from 'shared/src/types/dashboard';
import { OnFilterConfirm } from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/ConfigSchema';
import {
  FilterBooleanSwitch,
  FilterBooleanValue
} from 'src/components/Dashboards/DashboardView/components/FilterBooleanSwitch';

interface FilterConfigControlsProps<T> {
  defaultValue: string;
  filterConfig?: DashboardFilterConfig;
  onConfirm: OnFilterConfirm;
  value?: DashboardConfigField<T>;
}

export default function FilterConfigControls<T>({
  defaultValue,
  filterConfig,
  onConfirm,
  value
}: FilterConfigControlsProps<T>): ReactElement | null {
  const [defaultFilterValue, setDefaultFilterValue] = useState(
    (value?.type === 'filter' && filterConfig?.defaultValue) || defaultValue
  );
  useEffect(() => {
    setDefaultFilterValue(filterConfig?.defaultValue || defaultValue);
  }, [defaultValue, filterConfig]);

  if (value?.type !== 'filter' || !filterConfig) {
    return null;
  }

  const onTypeSelect = (filterType: string): void => {
    onConfirm({
      defaultValue: defaultFilterValue,
      filterType: filterType as DashboardFilterType
    });
  };

  const onDefaultBlur = (): void => {
    onConfirm({
      defaultValue: defaultFilterValue,
      filterType: filterConfig.filterType || 'string'
    });
  };

  return (
    <Container gap="md" orientation="horizontal" minWidth="160px">
      <Container gap="sm" orientation="vertical">
        <Label>Filter type</Label>
        <Select
          onSelect={onTypeSelect}
          placeholder="Filter type"
          value={filterConfig.filterType}
        >
          <Select.Item value="string">String</Select.Item>
          <Select.Item value="date">Date</Select.Item>
          <Select.Item value="boolean">Boolean</Select.Item>
        </Select>
      </Container>
      <Container gap="sm" orientation="vertical">
        <Label>Default value</Label>
        {filterConfig.filterType === 'boolean' ? (
          <FilterBooleanSwitch
            onChange={(newValue: FilterBooleanValue) => {
              setDefaultFilterValue(newValue);
              onConfirm({
                defaultValue: newValue,
                filterType: 'boolean'
              });
            }}
            value={defaultFilterValue as FilterBooleanValue}
          />
        ) : (
          <TextField
            onBlur={onDefaultBlur}
            onChange={(val: string): void => setDefaultFilterValue(val)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            value={defaultFilterValue}
          />
        )}
      </Container>
    </Container>
  );
}
