import { Container, Text } from '@clickhouse/click-ui';

import { instanceStateLabel } from 'src/instance/instance';
import { ReactElement } from 'react';
import { InstanceState as InstanceStateType } from '@cp/common/protocol/Instance';
import { StatusIcon } from 'src/components/InstanceStatusIcon';

type Props = { instanceState: InstanceStateType };

export function InstanceState({ instanceState }: Props): ReactElement {
  return (
    <Container gap="xs">
      <StatusIcon status={instanceState} />
      <Text data-testid="instance-state">
        {instanceStateLabel(instanceState)}
      </Text>
    </Container>
  );
}
