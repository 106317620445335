import { ReactElement, useMemo } from 'react';
import { Container, FileTabElement, FileTabs } from '@clickhouse/click-ui';
import {
  useSelectedTabIndex,
  useSetTabs,
  useTabActions,
  useTabs,
  useIsRestoringTabs
} from 'src/state/tabs';
import { InteractionType, logger } from 'src/lib/logger';
import Tab from 'src/components/ConsoleTopBar/Tab';
import { Tab as TabType } from 'src/state/tabs/types';
import { useRemoveTabs } from 'src/components/ConsoleTopBar/useRemoveTabs';
import RemoveTabModal from 'src/components/App/MainView/TabBar/RemoveTabModal';

const log = (
  type: TabType['type'] | 'home',
  event: string,
  interaction?: InteractionType
): void => {
  logger.track({
    view: 'tabbar',
    component: `${type}Tab`,
    event,
    interaction: interaction || 'click'
  });
};

function Tabs(): ReactElement {
  const tabs = useTabs();
  const { addNewQueryTab, setSelectedTabIndex } = useTabActions();
  const selectedTabIndex = useSelectedTabIndex();
  const {
    closeModalProps,
    getStatus,
    duplicateTab,
    closeCurrentTab,
    closeAllTabs,
    closeAllTabsToRight,
    closeAllOtherTabs
  } = useRemoveTabs(tabs);
  const setTabs = useSetTabs();
  const isRestoringTabs = useIsRestoringTabs();

  const disambiguatedNames = useMemo(() => {
    const nameOccurrences = new Map<string | undefined, number>();

    tabs.forEach((tab) => {
      const title = tab.title;
      const count = nameOccurrences.get(title) ?? 0;
      nameOccurrences.set(title, count + 1);
    });

    const names = new Map<string, string | undefined>();

    tabs.forEach((tab) => {
      if (tab.type === 'table') {
        const duplicates = nameOccurrences.get(tab.title) ?? 0;
        if (duplicates > 1 && tab.table.schema) {
          names.set(tab.id, `${tab.table.schema}.${tab.table.tableName}`);
        } else {
          names.set(tab.id, tab.title);
        }
      } else {
        names.set(tab.id, tab.title);
      }
    });

    return names;
  }, [tabs]);

  const onHomeClick = (): void => {
    log('home', 'focus', 'trigger');
    setSelectedTabIndex(-1);
  };

  const onNewTabClick = (): void => {
    addNewQueryTab({
      title: 'Untitled query',
      type: 'query',
      saved: false
    });
    logger.track({
      view: 'tabbar',
      component: 'queryTab',
      event: 'newTabOpen',
      interaction: 'click'
    });
  };
  const onReorderTab = (
    sourcePosition: number,
    destinationPosition: number
  ): void => {
    const reorderedTabs = [...tabs];
    const [removed] = reorderedTabs.splice(sourcePosition, 1);
    reorderedTabs.splice(destinationPosition, 0, removed);
    setTabs(reorderedTabs);
    setSelectedTabIndex(destinationPosition);
  };

  return (
    <Container fillHeight isResponsive={false}>
      <FileTabElement
        icon="home"
        onClick={onHomeClick}
        active={selectedTabIndex === -1}
      />
      {!isRestoringTabs && (
        <>
          <FileTabs
            onReorderTab={onReorderTab}
            onClose={closeCurrentTab}
            onSelect={setSelectedTabIndex}
            selectedIndex={selectedTabIndex}
          >
            {tabs.map((tab, index) => (
              <Tab
                {...tab}
                selected={index === selectedTabIndex}
                key={tab.id}
                log={log}
                index={index}
                text={disambiguatedNames.get(tab.id) || 'Untitled Tab'}
                duplicateTab={duplicateTab}
                closeCurrentTab={closeCurrentTab}
                closeAllOtherTabs={closeAllOtherTabs}
                closeAllTabs={closeAllTabs}
                closeAllTabsToRight={closeAllTabsToRight}
                status={getStatus(tab)}
              />
            ))}
          </FileTabs>

          <FileTabElement
            icon="plus"
            onClick={onNewTabClick}
            data-testid="newTabButton"
          />
        </>
      )}

      <RemoveTabModal {...closeModalProps} />
    </Container>
  );
}

export default Tabs;
