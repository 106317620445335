import styled from 'styled-components';

export const AggregationPeriodValue = styled.p`
  white-space: nowrap;
  margin: 0;
`;

export const TabsTrigger = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabsContent = styled.div`
  width: 100%;
  &[data-state='active'] {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    outline: none;
    margin-top: 2rem;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
