import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import {
  CaseDetailsRequest,
  CaseDetailsResponse,
  CreateSupportCaseRequest,
  CreateSupportCaseResponse,
  DownloadSupportAttachmentRequest,
  ListCasesRequest,
  ListCasesResponse,
  PrepareSupportAttachmentUploadRequest,
  ReportSupportCaseEventRequest,
  SupportAttachmentDetails,
  SupportCaseDetails,
  SupportCaseEventType,
  SupportCasePriority,
  SupportCaseStatus
} from '@cp/common/protocol/Support';
import { SECONDS_PER_YEAR } from '@cp/common/utils/DateTimeUtils';
import { ErrorResponseMixin } from '@cp/common/utils/ValidationUtils';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const supportApiUrl = `${config.controlPlane.apiHost}/api/support`;

export class SupportApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async rpcRequest<RequestType, ResponseType>(request: RequestType, token: string): Promise<ResponseType> {
    const response = await this.httpClient.post(
      `${supportApiUrl}/${token}`,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );

    if (response.ok) {
      return (await response.json()) as ResponseType;
    } else {
      try {
        const error = (await response.json()) as Partial<ErrorResponseMixin>;
        throw new Error(`Failed to fetch support ${token}: ${error?.message ?? ''} ${error?.details ?? ''}`);
      } catch (e) {
        console.log(`Failed to fetch support ${token}:`, e);
        throw e;
      }
    }
  }

  /** Returns list of support cases sorted by creation date. */
  async listCases(organizationId: string): Promise<Array<SupportCaseDetails>> {
    const request: ListCasesRequest = { organizationId };
    const response = await this.rpcRequest<ListCasesRequest, ListCasesResponse>(request, 'listCases');
    return (response.cases || []) as Array<SupportCaseDetails>;
  }

  async getCaseDetails(organizationId: string, caseId: string): Promise<SupportCaseDetails> {
    const request: CaseDetailsRequest = { organizationId, caseId };
    const response = await this.rpcRequest<CaseDetailsRequest, CaseDetailsResponse>(request, 'getCaseDetails');
    return response.details;
  }

  async prepareSupportAttachmentUpload(
    organizationId: string,
    fileName: string,
    mimeType?: string,
    caseId?: string
  ): Promise<UploadSignatureDetails> {
    const request: PrepareSupportAttachmentUploadRequest = {
      organizationId,
      fileName,
      mimeType,
      secondsToExpire: SECONDS_PER_YEAR,
      caseId
    };
    return await this.rpcRequest<PrepareSupportAttachmentUploadRequest, UploadSignatureDetails>(
      request,
      'prepareAttachmentUpload'
    );
  }

  async createCase(
    organizationId: string,
    priority: SupportCasePriority,
    subject: string,
    description: string,
    category: string,
    additionalEmails: string[],
    recaptchaToken: string,
    serviceId?: string,
    serviceName?: string,
    attachmentStorageIds?: string[]
  ): Promise<CreateSupportCaseResponse> {
    const request: CreateSupportCaseRequest = {
      organizationId,
      priority,
      subject,
      description,
      category,
      additionalEmails,
      serviceId,
      serviceName,
      attachmentStorageIds,
      recaptchaToken
    };
    return await this.rpcRequest<CreateSupportCaseRequest, CreateSupportCaseResponse>(request, 'createCase');
  }

  async reportCaseEvent(
    organizationId: string,
    caseId: string,
    type: SupportCaseEventType,
    reply?: string,
    status?: SupportCaseStatus,
    attachmentStorageIds?: string[]
  ): Promise<void> {
    const request: ReportSupportCaseEventRequest = {
      organizationId,
      caseId,
      type,
      reply,
      status,
      attachmentStorageIds
    };
    const response = await this.httpClient.post(
      `${supportApiUrl}/reportCaseEvent`,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      try {
        const error = (await response.json()) as Partial<ErrorResponseMixin>;
        throw new Error(`Failed to fetch support reportCaseEvent: ${error?.message ?? ''} ${error?.details ?? ''}`);
      } catch (e) {
        console.log('Failed to fetch support:', e);
        throw e;
      }
    }
  }

  async downloadSupportAttachment(storageId: string, organizationId: string): Promise<string> {
    const request: DownloadSupportAttachmentRequest = {
      organizationId,
      storageId
    };
    const response = await this.rpcRequest<DownloadSupportAttachmentRequest, SupportAttachmentDetails>(
      request,
      'downloadAttachmentSafe'
    );

    return response.location;
  }
}
