import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useState
} from 'react';

import { transparentBtn } from 'global-styles';

import DownArrowIcon from 'src/components/icons/DownArrowIcon';
import { getCustomColumnType } from 'shared/src/clickhouse/columns';

import {
  cellHeaderStyle,
  headerClickContainer
} from 'src/components/primitives/lib/Spreadsheet/styles';

import ResizeHandler from 'src/components/primitives/lib/Spreadsheet/HeaderCell/ResizeHandler';
import {
  columnNameStyle,
  sortIcon,
  tableHeaderStyle
} from 'src/components/primitives/lib/Spreadsheet/HeaderCell/styles';
import { HeaderMenuProps } from 'src/components/primitives/lib/Spreadsheet/HeaderCell/types';
import { Tooltip } from '@clickhouse/click-ui';

function HeaderCell(props: HeaderMenuProps): ReactElement {
  const {
    column,
    columnWidth,
    columnIndex,
    getOnResize,
    getOnFinishResize,
    getOnAutoResize,
    onClick,
    onRightClick,
    isSelected,
    isResizing
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onResize = getOnResize(columnIndex);
  const onFinishResize = getOnFinishResize(columnIndex);
  const onAutoResize = getOnAutoResize(columnIndex);

  const [open, setOpen] = useState(false);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const contextMenu: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (typeof onRightClick === 'function') {
        e.preventDefault();
        onRightClick({ column });
      } else if (!isSelected) {
        e.preventDefault();
        setOpen(true);
      }
    },
    [onRightClick, isSelected, column]
  );

  const click = (event: React.MouseEvent) => {
    event.preventDefault();
    onClick(columnIndex, event);
  };

  const onSort = (e: React.MouseEvent) => {
    e.preventDefault();
    if (typeof column.onSort === 'function') {
      column.onSort({ column });
    }
    onClick(columnIndex, e);
  };

  return (
    <div
      css={cellHeaderStyle}
      data-selected={isSelected}
      data-location="column-header"
      data-column={columnIndex}
      data-copy="false"
      onBlur={() => {
        setOpenContextMenu(false);
      }}
    >
      <Tooltip
        open={!(open || isResizing || openContextMenu) && tooltipOpen}
        onOpenChange={setTooltipOpen}
      >
        <Tooltip.Trigger className="header-tooltip">
          <div css={headerClickContainer}>
            <div css={tableHeaderStyle}>
              <button
                css={[tableHeaderStyle, transparentBtn]}
                onClick={click}
                onContextMenu={contextMenu}
              >
                <span css={columnNameStyle}>
                  {column.name}
                  {column.sort && (
                    <button
                      css={(theme) => [sortIcon(theme), transparentBtn]}
                      data-asc={column.sortAsc}
                      onClick={onSort}
                    >
                      <DownArrowIcon />
                    </button>
                  )}
                </span>
              </button>
            </div>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content side="top" sideOffset={10}>
          {column.name} - {getCustomColumnType(column.type)}
        </Tooltip.Content>
      </Tooltip>
      <ResizeHandler
        size={columnWidth}
        onResize={onResize}
        onFinishResize={onFinishResize}
        onAutoResize={onAutoResize}
      />
    </div>
  );
}
export default React.memo(HeaderCell);
