import {
  Button,
  Container,
  Link,
  Spacer,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import {
  IpAccessListEntry,
  MAX_IP_ACCESS_LIST_ADDRESSES_TO_SHOW_IN_UI
} from '@cp/common/protocol/Instance';
import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { IpAccessOption } from 'src/instance/controller/useNetworkSettings';
import {
  Section,
  SectionTitle,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import NetworkFlyout from 'src/pages/SettingsPage/NetworkFlyout';

export interface NetworkingSectionViewProps {
  title: string;
  ipsAccessList: Array<IpAccessListEntry>;
  selectedIpAccessOption: IpAccessOption;
  onSubmit: (ipType: IpAccessOption, ipList: Array<IpAccessListEntry>) => void;
}

export const NetworkingSectionView = ({
  title,
  ipsAccessList,
  selectedIpAccessOption,
  onSubmit: onSubmitProp
}: NetworkingSectionViewProps): ReactElement | null => {
  const hasManageIpAccessListPermission =
    useUserAndOrgRolesHasPermissionForInstance(
      'control-plane:service:manage-ip-access-list',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const [ipType, setIpType] = useState<IpAccessOption>(
    selectedIpAccessOption || 'anywhere'
  );
  const [open, onOpenChange] = useState(false);
  const openFlyoutWithType = (incommingType: IpAccessOption) => () => {
    setIpType(incommingType);
    onOpenChange(true);
  };

  const onSubmit = (
    ipType: IpAccessOption,
    ipList: Array<IpAccessListEntry>
  ): void => {
    onOpenChange(false);
    setIpType(ipType);
    onSubmitProp(ipType, ipList);
  };
  const isAnywhere = selectedIpAccessOption === 'anywhere';

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <Spacer />
      <Subsection justifyContent="space-between">
        <SubsectionDescription>
          <SubsectionTitle>IP access list</SubsectionTitle>
          <Spacer />
          <Text color="muted">
            To ensure an extra layer of security for your service, we recommend
            only allowing traffic from IP addresses that you know and trust.
          </Text>
        </SubsectionDescription>
        <SubsectionControls data-testid="ip-addresses-list-container">
          <Text
            size="sm"
            weight="medium"
            component={Container}
            alignItems="center"
            gap="xxs"
            justifyContent="start"
            isResponsive={false}
          >
            You can connect to this service from
            {isAnywhere ? (
              <Link
                component="span"
                onClick={openFlyoutWithType('anywhere')}
                data-testid="anywhere-warning-message"
                size="sm"
                weight="medium"
              >
                anywhere
              </Link>
            ) : selectedIpAccessOption === 'nowhere' ? (
              <Link
                size="sm"
                weight="medium"
                component="span"
                onClick={openFlyoutWithType('nowhere')}
                data-testid="nowhere-warning-message"
              >
                nowhere
              </Link>
            ) : (
              <Tooltip
                disabled={
                  ipsAccessList.length <
                  MAX_IP_ACCESS_LIST_ADDRESSES_TO_SHOW_IN_UI
                }
              >
                <Tooltip.Trigger>
                  <Link
                    size="sm"
                    weight="medium"
                    component="span"
                    onClick={openFlyoutWithType('specificLocations')}
                  >
                    {ipsAccessList.length} specific location
                    {ipsAccessList.length > 1 ? 's' : ''}
                  </Link>
                </Tooltip.Trigger>
                <Tooltip.Content maxWidth="320px">
                  The number of IP addresses shown in the Cloud UI is limited to{' '}
                  {MAX_IP_ACCESS_LIST_ADDRESSES_TO_SHOW_IN_UI}, viewing a list
                  of all IPs is supported via our API.
                </Tooltip.Content>
              </Tooltip>
            )}
          </Text>
          {selectedIpAccessOption !== 'specificLocations' &&
            hasManageIpAccessListPermission && (
              <Container orientation="vertical">
                <Spacer size="sm" />
                <Button
                  type="secondary"
                  onClick={openFlyoutWithType('specificLocations')}
                  fillWidth={false}
                  data-testid="add-ips-button"
                >
                  Add IPs
                </Button>
              </Container>
            )}
          <NetworkFlyout
            open={open}
            onChange={onSubmit}
            onOpenChange={onOpenChange}
            type={ipType}
            ipsAccessList={ipsAccessList}
          />
        </SubsectionControls>
      </Subsection>
    </Section>
  );
};
