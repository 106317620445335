import { ReactEventHandler } from 'react';
import { useIsCurrentInstanceAwakeStatus } from 'src/instance/instanceController';
import { useQueryRunner } from 'src/lib/clickhouse/query';
import { logger } from 'src/lib/logger';
import { useWakeService } from 'src/state/service/wakeService';

type ServiceAlertStateManager = {
  isAwake: boolean;
  isAsleep: boolean;
  isAwaking: boolean;
  isStopped: boolean;
  isStarting: boolean;
  isProvisioning: boolean;
  awakeServiceCallback: ReactEventHandler;
};

export const useServiceAlertStateManager = (): ServiceAlertStateManager => {
  const { isAwake, isAsleep, isAwaking, isStopped, isStarting, isProvisioning } = useIsCurrentInstanceAwakeStatus();
  const { wakeupService } = useWakeService();

  const awakeServiceRunner = useQueryRunner({
    sql: 'SELECT 1',
    options: { wakeService: true }
  });

  const awakeServiceCallback: ReactEventHandler = (event): void => {
    event.preventDefault();

    void awakeServiceRunner().then(async () => {
      await wakeupService();
    });

    logger.track({
      view: 'app',
      component: 'alertBanner',
      event: 'awakeServiceButtonClick',
      interaction: 'click'
    });
  };

  return {
    isAwake,
    isAsleep,
    isAwaking,
    isStopped,
    isStarting,
    isProvisioning,
    awakeServiceCallback
  };
};
