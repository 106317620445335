import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import useSWR, { SWRResponse } from 'swr';
import { CaseDetailsUpdatePayload, SupportCaseDetails } from '@cp/common/protocol/Support';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useApiClient } from 'src/lib/controlPlane/client';

/**
 * Sanitizes the support case data by ensuring that required properties are not undefined.
 */
function sanitizeSupportCaseData(details: SupportCaseDetails): SupportCaseDetails {
  return {
    ...details,
    subject: details.subject ?? '',
    description: details.description ?? ''
  };
}
export interface SupportStateProps extends SWRResponse<SupportCaseDetails, Error> {
  organizationId: string;
}

export const useSupportCase = (caseId: string): SupportStateProps => {
  const api = useApiClient();

  const organizationId = useCurrentOrganizationOrThrow().id;

  const supportData = useSWR<SupportCaseDetails, Error>(`fetchSupportCase${caseId}`, async () => {
    const details = await api.support.getCaseDetails(organizationId, caseId);
    return sanitizeSupportCaseData(details);
  });
  useWebSocketsNotification<CaseDetailsUpdatePayload>({
    subscriptionDetails: {
      type: 'CASE_DETAILS_UPDATE',
      objId: organizationId
    },
    handler: ({ payload }) => {
      if (payload.details) {
        const details = sanitizeSupportCaseData(payload.details);
        void supportData.mutate(details);
      }
    }
  });

  return { ...supportData, organizationId };
};
