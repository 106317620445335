import { CUIThemeType, useCUITheme } from '@clickhouse/click-ui';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FC, useEffect, useRef, useState } from 'react';

import {
  DEFAULT_COLOR_FOR_METRICS,
  generateLinearGradientStops,
  getXAxisDateTimeOptions,
  tooltipPointFormatter
} from 'src/components/primitives/lib/Charts/Highcharts/Formatters';

interface MetricChartSeriesOptions {
  type?: 'area' | 'line';
  name: string;
  color?: string;
}

export interface MetricChartInput {
  title: string;
  helpTooltipText: string;
  tooltipDataSizePointFormat?: string;
  tooltipValueSuffix?: string;
  yAxisValueFormat?: string;
  yAxisValueFormatter?: (value: number | string) => string;
  query: any;
  seriesOptions?: Array<Partial<MetricChartSeriesOptions>>;
}

export interface MetricChartProps {
  metric: MetricChartInput;
  seriesData: Highcharts.SeriesOptionsType[];
}

function isChartOptionsEmpty(options: Highcharts.Options): boolean {
  return !options.series || options.series.length === 0;
}

function tooltipDateFormat(metric: MetricChartInput): string {
  // See https://api.highcharts.com/classreference/Highcharts.Time#dateFormat.
  const timePeriod = (metric?.query?.timePeriod ?? '') as string;
  switch (timePeriod) {
    case 'Last hour':
    case 'Last 24 hours':
      return '%B %e, %l:%M %p'; // Up to minutes: April 24, 4:20 PM.
    case 'Last week':
    case 'Last month':
      return '%B %e, %l %p'; // Up to hours: April 24, 4 PM.
    case 'Last year':
      return '%B %e, %Y'; // Up to days: April 24, 2022.
  }
  return '%Y-%m-%d';
}

const CHART_FONT_CONFIG =
  '400 0.875rem/1.5 "Inter",\'"SF Pro Display"\',-apple-system,BlinkMacSystemFont,\'"Segoe UI"\',Roboto,Oxygen,Ubuntu,Cantarell,\'"Open Sans"\',\'"Helvetica Neue"\',sans-serif';

export function getHighchartOptions(
  metric: MetricChartInput,
  seriesData: Highcharts.SeriesOptionsType[],
  cuiTheme: CUIThemeType
): Highcharts.Options {
  const options: Highcharts.Options = {
    title: {
      text: metric.title,
      style: {
        color: cuiTheme.global.color.text.muted,
        font: CHART_FONT_CONFIG
      }
    },
    chart: {
      renderTo: 'chartRef',
      margin: [42, 60, 50, 70],
      animation: false,
      backgroundColor: cuiTheme.global.color.background.default,
      spacingRight: 0,
      marginRight: 4,
      spacingLeft: 0,
      marginLeft: 70
    },
    time: {
      useUTC: false // Use local time by default.
    },
    yAxis: {
      title: { text: null },
      gridLineColor: cuiTheme.global.color.stroke.muted,
      lineColor: cuiTheme.global.color.stroke.default,
      labels: {
        style: {
          color: cuiTheme.global.color.text.muted,
          fontSize: cuiTheme.sizes[4]
        },
        format: metric.yAxisValueFormat,
        formatter: !metric.yAxisValueFormatter
          ? undefined
          : function (): string {
              return metric.yAxisValueFormatter
                ? metric.yAxisValueFormatter(this.value)
                : '';
            }
      }
    },
    xAxis: {
      gridLineColor: cuiTheme.global.color.stroke.muted,
      lineColor: cuiTheme.global.color.stroke.default,
      tickColor: cuiTheme.global.color.stroke.default,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        style: {
          color: cuiTheme.global.color.text.muted,
          fontSize: cuiTheme.sizes[4]
        }
      },
      ...getXAxisDateTimeOptions()
    },
    plotOptions: {
      series: {
        animation: false
      },
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: generateLinearGradientStops(DEFAULT_COLOR_FOR_METRICS)
        }
      }
    },
    tooltip: {
      pointFormatter: function () {
        return tooltipPointFormatter({
          seriesName: this.series.name,
          tooltipDataSizePointFormat: metric.tooltipDataSizePointFormat,
          tooltipValueSuffix: metric.tooltipValueSuffix,
          yValue: this.y ?? 0
        });
      },
      xDateFormat: tooltipDateFormat(metric),
      shared: true,
      useHTML: true,
      backgroundColor: '#322F39',
      borderColor: '#322F39',
      borderRadius: 6,
      hideDelay: 0,
      style: {
        color: 'white',
        fontWeight: '600',
        opacity: 0.7
      }
    },

    credits: { enabled: false },
    legend: { enabled: false },
    series: seriesData
  };
  return options;
}

const MetricChart: FC<MetricChartProps> = ({
  metric,
  seriesData
}: MetricChartProps) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const cuiTheme = useCUITheme();

  useEffect(() => {
    setOptions(() => {
      const newOptions = getHighchartOptions(metric, seriesData, cuiTheme);
      if (chartRef.current && chartRef.current.chart) {
        chartRef.current.chart.reflow();
      }
      return newOptions;
    });
  }, [metric, seriesData, cuiTheme]);

  if (!options || isChartOptionsEmpty(options)) {
    return null;
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
      containerProps={{ style: { width: '100%', height: '100%' } }}
    />
  );
};

export default MetricChart;
