import {
  Container,
  Text as CUIText,
  Logo,
  Select,
  Separator
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useSelectService } from 'src/state/service/selectService';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { formatStateForDisplay } from 'src/instance/instance';
import { routes } from 'src/lib/routes';
import { Instance } from '@cp/common/protocol/Instance';
import styled from 'styled-components';
import { useCurrentOrganizationDataWarehousesWithInstances } from 'src/dataWarehouses/controller/dataWarehouseWithInstances';

const StatusText = styled(CUIText)`
  &::first-letter {
    text-transform: capitalize;
  }
`;
const SelectGroup = styled(Select.Group)`
  & > div:first-child {
    border: none;
    padding: 0.5rem 0.75rem;
  }
`;

export const goToService = (serviceId: string, isDesktop: boolean): void => {
  if (serviceId === 'all-warehouses') {
    navigateTo(routes.warehouses());
  } else if (isDesktop) {
    navigateTo(routes.service({ serviceId }));
  } else {
    navigateTo(routes.serviceSettings({ serviceId }));
  }
};
interface WarehouseSelectorProps {
  isDesktop: boolean;
  currentService?: Instance;
}

export const WarehouseSelector = ({
  isDesktop,
  currentService
}: WarehouseSelectorProps): ReactElement => {
  const { warehouses } = useCurrentOrganizationDataWarehousesWithInstances();
  const selectService = useSelectService();
  return (
    <Select
      onSelect={(serviceId) => {
        goToService(serviceId, isDesktop);
        selectService({ serviceId });
      }}
      value={currentService?.id}
      placeholder="Select a service"
      data-testid="serviceSelector"
      useFullWidthItems
    >
      {warehouses.map((warehouse) => (
        <SelectGroup
          heading={
            <Container isResponsive={false} justifyContent="space-between">
              <CUIText component="span" color="muted" weight="medium" size="sm">
                {warehouse.name}
              </CUIText>
              <Logo name={warehouse.cloudProvider} width="20px" height="20px" />
            </Container>
          }
        >
          {warehouse.instances.map((service) => (
            <Select.Item value={service.id} key={service.id}>
              {service.name}
              <StatusText
                color="muted"
                data-hide-in-trigger
                size="sm"
                data-testid={`serviceSelector-${service.id}`}
              >
                {formatStateForDisplay(service.state)}
              </StatusText>
            </Select.Item>
          ))}
          <Separator size="sm" />
        </SelectGroup>
      ))}
      <Select.Item value="all-warehouses">All warehouses</Select.Item>
    </Select>
  );
};
