import { useAuth0 } from '@auth0/auth0-react';
import { User as Auth0User } from '@auth0/auth0-spa-js';
import {
  OrganizationFeatureId,
  UserFeatureId
} from '@cp/common/protocol/features';
import { convertAuth0SubToCpUserId } from '@cp/common/utils/AuthUtils';
import { omit } from 'lodash';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAuthorizationStateManager } from 'src/authorization/authorizationState';
import { AuthContext, LoginOptions } from 'src/components/auth';
import { AuthenticationDefaultProps } from 'src/components/auth/AuthProvider';
import { User } from 'src/lib/auth/types';
import config from 'src/lib/config';
import { ControlPlaneApiClient } from 'src/lib/controlPlane/client';
import { isUnifiedConsoleInLocalStorage } from 'src/lib/features';
import { SecureHttpClient } from 'src/lib/http';
import { parseTackleSubscriptionTokenFromUrl } from 'src/lib/tackle/tackle';
import { useInvitationsState } from 'src/state/notification/invitation';
import { useUserStateManager } from 'src/user/userState';

export default function Auth0AuthProvider({
  children,
  ...initProps
}: PropsWithChildren<AuthenticationDefaultProps>): JSX.Element {
  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    logout
  } = useAuth0();
  const [params] = useSearchParams();
  const [currentUser, setCurrentUser] = useState<User | null>(
    initProps.currentUser ?? null
  );
  const isUCInLocalStorage = isUnifiedConsoleInLocalStorage();
  const userState = useUserStateManager();
  const authorizationState = useAuthorizationStateManager();
  const location = useLocation();
  const [, setInvitations] = useInvitationsState();

  const initialized = useRef(false);
  // this will disable the logout behaviour on http client. not needed for auth0
  window.skipLogoutOnUnauthorized = true;

  const redirectToAuth = async ({
    returnTo,
    screen_hint,
    connection
  }: {
    returnTo: string;
    screen_hint?: string;
    connection?: string;
  }): Promise<void> => {
    const tackleToken = parseTackleSubscriptionTokenFromUrl();
    return loginWithRedirect({
      appState: {
        returnTo,
        // Used to pass the Tackle token via SSO flow.
        // Received & handled by Auth0AuthProvider.
        tackleToken
      },
      authorizationParams: {
        screen_hint: screen_hint,
        display: 'page',
        // Auth0 always process all values in authorizationParams as strings.
        originatedFromUnifiedConsole: 'true',
        // Used to pass the Tackle token via e-mail signup flow.
        // Received & handled by PostLogin Auth0 handler.
        tackleTokenJson: tackleToken && JSON.stringify(tackleToken),
        connection
      }
    });
  };

  useEffect(() => {
    async function initialize(user: Auth0User): Promise<void> {
      let userFeatures: Array<UserFeatureId> = [];
      const orgFeatures: Record<string, Array<OrganizationFeatureId>> = {};

      if (!initialized.current) {
        const token = await getAccessTokenSilently();
        const http = new SecureHttpClient(
          () => Promise.resolve(token),
          () => logout()
        );
        const api = new ControlPlaneApiClient(http);
        const userSession = await api.initializeUserSession();
        const userDetails = omit(userSession, ['organizations', 'invitations']);
        setInvitations(userSession.invitations);
        userState.setUser(userDetails);
        const { features, organizations } = userSession;
        userFeatures = features;
        authorizationState.setPolicies(userSession.policies ?? []);
        authorizationState.setRoleMappings(userSession.roleMappings ?? []);
        organizations.forEach((org) => {
          orgFeatures[org.id] = org.features;
        });

        initialized.current = true;
      }

      const updatedUser = {
        id: user.sub ? convertAuth0SubToCpUserId(user.sub) : '',
        email: user.email ?? '',
        userName: user.preferred_username ?? '',
        givenName: user.given_name ?? '',
        features: {
          orgs: orgFeatures,
          user: userFeatures
        }
      };

      setCurrentUser((currentUser) => {
        if (!currentUser) {
          return updatedUser;
        }

        return {
          ...currentUser,
          ...updatedUser
        };
      });
    }

    const goToAuth = ({ connection }: LoginOptions): void => {
      if (location.pathname === '/signUp') {
        redirectToAuth({
          returnTo: window.location.pathname,
          screen_hint: 'signup'
        }).catch(console.error);
      } else {
        redirectToAuth({
          returnTo: window.location.pathname,
          connection
        }).catch(console.error);
      }
    };

    const tackleToken = parseTackleSubscriptionTokenFromUrl();
    if (!isLoading && isAuthenticated && tackleToken) {
      // If the user is already authenticated but has a Tackle token,
      // we still need to redirect to authentication
      const returnTo = `${window.location.origin}${window.location.search}`;
      logout({ logoutParams: { returnTo } }).catch(console.error);
    } else if (!isLoading && isAuthenticated && user) {
      void initialize(user);
    } else if (!isLoading && !isAuthenticated) {
      const connection = params.get('connection') || undefined;
      goToAuth({ connection });
    }

    return () => {
      initialized.current = false;
    };
  }, [
    isAuthenticated,
    loginWithRedirect,
    user,
    isLoading,
    getAccessTokenSilently,
    logout
  ]);

  const logOut = useCallback(async (): Promise<void> => {
    await logout({
      logoutParams: {
        returnTo: isUCInLocalStorage ? config.webUrl : config.controlPlane.host
      }
    });
    return Promise.resolve();
  }, [logout, isUCInLocalStorage]);

  return (
    <AuthContext.Provider
      value={{
        loading: isLoading,
        isAuthenticated,
        currentUser,
        loginWithRedirect: ({
          url,
          connection
        }: LoginOptions): Promise<void> => {
          return redirectToAuth({
            returnTo: url ?? window.location.href,
            connection
          });
        },
        signupWithRedirect: (url?: string): Promise<void> => {
          return redirectToAuth({
            returnTo: url ?? window.location.href,
            screen_hint: 'signup'
          });
        },
        logOut,
        getAccessToken: getAccessTokenSilently,
        idp: 'AUTH0'
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
