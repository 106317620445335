import { TableRowType, Text } from '@clickhouse/click-ui';
import { ReactElement, useMemo } from 'react';
import { NotificationItemBody } from 'src/components/Notifications/NotificationItemBody';
import { formatDateLongSmart } from 'src/lib/formatters/dateTimeFormatter';
import { AllNotificationsTabView } from 'src/pages/NotificationsPage/AllNotifications/AllNotificationsTabView';
import { useAllNotifications } from 'src/pages/NotificationsPage/AllNotifications/useAllNotifications';
import { NotificationCategory } from 'src/pages/NotificationsPage/NotificationCategory';
import { NotificationSeverityBadge } from 'src/pages/NotificationsPage/NotificationSeverityBadge';
import styled from 'styled-components';

const WrapWhiteSpaceText = styled(Text)`
  white-space: pre-wrap;
  max-width: 36rem;
`;

export const AllNotificationsTab = (): ReactElement => {
  const {
    notifications,
    onSort,
    page,
    totalRows,
    totalPages,
    limit,
    search,
    severity,
    category,
    time,
    isLoading,
    onPageChange,
    onPageSizeChange,
    onSearch,
    onSelectSeverity,
    onSelectCategory,
    onSelectTime
  } = useAllNotifications();

  const rows: TableRowType[] = useMemo(() => {
    return notifications.map((notification) => {
      return {
        id: notification.id,
        items: [
          {
            label: formatDateLongSmart(new Date(notification.createdAt), true)
          },
          {
            label: (
              <NotificationSeverityBadge notificationName={notification.name} />
            )
          },
          {
            label: (
              <WrapWhiteSpaceText>
                <NotificationItemBody notification={notification} />
              </WrapWhiteSpaceText>
            )
          },
          {
            label: <NotificationCategory notificationName={notification.name} />
          }
        ]
      };
    });
  }, [notifications]);

  return (
    <AllNotificationsTabView
      search={search}
      onSearch={onSearch}
      severity={severity}
      onSelectSeverity={onSelectSeverity}
      category={category}
      onSelectCategory={onSelectCategory}
      time={time}
      onSelectTime={onSelectTime}
      page={page}
      onPageChange={onPageChange}
      limit={limit}
      onPageSizeChange={onPageSizeChange}
      onSort={onSort}
      rows={rows}
      totalPages={totalPages}
      totalRows={totalRows}
      isLoading={isLoading}
    />
  );
};
