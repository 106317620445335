import { isInternalUserEmail } from '@cp/common/utils/TestUsers';
import { useEffect } from 'react';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { useOnboardStateManager } from 'src/onboard/onboardState';
import { useCurrentOrganization } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';

export const useInitOnboardState = (): void => {
  const { setIsOnboardingInProgress } = useOnboardStateManager();
  const { instances, isInstanceStateInitialized } = useInstanceStateManager();
  const { user } = useUserStateManager();
  const hasInstances = Object.values(instances).length > 0;
  const hasOnboardingPendingAction = !!user.pendingActions?.some(({ type }) => type === 'onboarding');
  const currentOrganization = useCurrentOrganization();
  const currentUserRole = Object.values(currentOrganization?.users || []).find((u) => u.userId === user.userId)?.role;

  useEffect(() => {
    if (!currentOrganization) {
      return;
    }

    setIsOnboardingInProgress({
      hasCurrentOrganization: !!currentOrganization,
      canOrgCreateService: currentOrganization.restrictions.canCreateInstances,
      isInstanceStateInitialized,
      hasInstances,
      hasOnboardingPendingAction,
      isDeveloper: currentUserRole === 'DEVELOPER',
      isInternalUser: isInternalUserEmail(user.email)
    });
  }, [
    currentOrganization,
    isInstanceStateInitialized,
    hasInstances,
    hasOnboardingPendingAction,
    currentUserRole,
    setIsOnboardingInProgress
  ]);
};
