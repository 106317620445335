import { ReactElement, Suspense } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { useImportModel } from 'src/state/dataLoading';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';

export const CheckImportGuard = (): ReactElement => {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const { importId, serviceId } = useParams();
  const { importModel } = useImportModel();
  if (hasManageServicePermission) {
    if (importModel.type && !importId) {
      return <Navigate to={`./${importModel.id}`} />;
    }

    return (
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    );
  }

  if (serviceId) {
    return <Navigate to={`/services/${serviceId}`} />;
  }

  return <NotFoundPage />;
};
