import { useMemo } from 'react';
import { ResultColumn, SqlQueryFunction, useSqlQueryFunction } from 'src/lib/clickhouse/query';
import { Row } from 'shared/src/clickhouse/types';
import { escapeString } from 'shared/src/tableSchema/escape';
import config from 'src/lib/config';
import { internalQuerySetting, internalQueryLogComment } from 'shared/src/clickhouse/internalQuerySettings';
import { clusterIfNecessary } from 'shared/src/sql/query';
import { internalDatabaseUsers } from 'shared/src/clickhouse';

const extraFilters: Array<string> = [];

if (config.querying.ignoreAnalyticsQueryInLog) {
  extraFilters.push("sql not like 'INSERT INTO analytics%'", "sql not like 'INSERT INTO forensics_test_v2%'");
}
const extraFilterSql = extraFilters.length === 0 ? '' : 'and ' + extraFilters.join(' and ');

const runningQueriesSql = `
SELECT
    query_id as "id",
    'running' as "status",
    multiIf(user like 'sql-console:%', extract(user,'sql-console:(.*)'), user like 'clickpipe:%', 'ClickPipes', user) as "runBy",
    concat(toString(round(elapsed)),' seconds') as "elapsed",
    concat('Read: ', formatReadableQuantity(read_rows), ' rows (', formatReadableSize(read_bytes), ')') as "readInfo",
    concat('Written: ', formatReadableQuantity(written_rows), ' rows (', formatReadableSize(written_bytes), ')') as "writeInfo",
    formatReadableSize(memory_usage) as "memUsage",
    formatReadableSize(peak_memory_usage) as "memPeak",
    query_kind as "queryKind",
    trimLeft(trim(BOTH '\n' FROM query)) as "sql",
    read_rows as "readRows",
    written_rows as "writtenRows",
    total_rows_approx as "totalRows",
    0 as "errorCode",
    'N/A' as "errorMessage"
from
    ${clusterIfNecessary('system.processes', config.querying.useClustering)}
where
    user not in (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    and is_initial_query = 1
    and Settings['log_comment'] != ${escapeString(internalQueryLogComment)}
    and sql not like 'KILL QUERY WHERE query_id = %'
    ${extraFilterSql}
order by
    elapsed desc
`;

const completedQueriesSql = `
select
    query_id as "id",
    multiIf(exception_code=394,'cancelled',type='QueryFinish','completed','failed') as "status",
    multiIf(user like 'sql-console:%', extract(user,'sql-console:(.*)'), user like 'clickpipe:%', 'ClickPipes', user) as "runBy",
    if(query_duration_ms < 1000, concat(toString(query_duration_ms), ' milliseconds'), concat(toString(query_duration_ms / 1000),' seconds')) as "elapsed",
    concat('Read: ', formatReadableQuantity(read_rows), ' rows (', formatReadableSize(read_bytes), ')') as "readInfo",
    concat('Written: ', formatReadableQuantity(written_rows), ' rows (', formatReadableSize(written_bytes), ')') as "writeInfo",
    formatReadableSize(memory_usage) as "memUsage",
    formatReadableSize(memory_usage) as "memPeak",
    query_kind as "queryKind",
    trimLeft(trim(BOTH '\n' FROM query)) as "sql",
    read_rows as "readRows",
    written_rows as "writtenRows",
    result_rows as "totalRows",
    exception_code as "errorCode",
    exception as "errorMessage"
from
    ${clusterIfNecessary('system.query_log', config.querying.useClustering)}
where
    event_date >= toStartOfDay(now() - 900)
    and event_time >= now() - 900
    and user not in (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    and is_initial_query = 1
    and log_comment != ${escapeString(internalQueryLogComment)}
    and query_kind != 'KillQuery'
    ${extraFilterSql}
    and type != 'QueryStart'
order by
    event_time_microseconds desc
limit 5
`;

const loadFullListSql = `SELECT * FROM (${runningQueriesSql} UNION ALL ${completedQueriesSql}) limit 5 settings skip_unavailable_shards=1, ${internalQuerySetting}`;

const loadActiveQueryCountSql = `
select
    count()
from
    ${clusterIfNecessary('system.processes', config.querying.useClustering)}
where
    user not in (${internalDatabaseUsers.map((dbUser: string) => `'${dbUser}'`).join(',')})
    and is_initial_query = 1
    and Settings['log_comment'] != 'sql console internal query'
    and Settings['log_comment'] != 'clickpipes%'
    and query not like 'KILL QUERY WHERE query_id = %'
settings
    skip_unavailable_shards=1,
    ${internalQuerySetting}
`;

export type QueryItem = {
  id: string;
  queryKind: string;
  sql: string;
  memUsage: string;

  readInfo: string;
  writeInfo: string;

  memPeak: string;
  elapsed: string;
  status: 'completed' | 'running' | 'cancelled' | 'failed';
  runBy: string;
  readRows: string;
  writtenRows: string;
  totalRows: string;
  errorCode: string;
  errorMessage: string;
};

const rowItemToQueryItem = (row: Row, columns: Array<ResultColumn>): QueryItem => {
  return row.reduce(
    (obj, value, i) => ({
      ...obj,
      [columns[i].name]: value
    }),
    {}
  ) as QueryItem;
};

export class InsufficientPermissionsError extends Error {}

function isInsufficientPrivilegesError(error: string): boolean {
  return error.match(/^Code: 497. DB::Exception:.*Not enough privileges/) !== null;
}

function handleDbError(error: string): never {
  if (isInsufficientPrivilegesError(error)) {
    throw new InsufficientPermissionsError(error);
  } else {
    throw new Error(error);
  }
}

export class LiveQueriesModel {
  constructor(private runner: SqlQueryFunction) {}

  async loadFullList(): Promise<Array<QueryItem>> {
    const results = await this.runner(loadFullListSql);
    if ('error' in results) {
      handleDbError(results.error);
    }

    const runId = results.runId;
    const runningQueriesColumns = results.columns;
    return results.rows.map((row) => rowItemToQueryItem(row, runningQueriesColumns)).filter((row) => row.id !== runId);
  }

  async loadRunningCount(): Promise<number> {
    const results = await this.runner(loadActiveQueryCountSql);
    if ('error' in results) {
      handleDbError(results.error);
    }
    if (results.rows.length !== 1 || results.columns.length !== 1) {
      throw new Error('Running query count query should return single number');
    }
    const countRaw = results.rows[0][0];
    if (typeof countRaw !== 'string') {
      throw new Error('Running query count query should return single number');
    }
    return parseInt(countRaw);
  }
}

export function useLiveQueriesModel(): LiveQueriesModel {
  const [runQuery] = useSqlQueryFunction();

  return useMemo(() => {
    return new LiveQueriesModel(runQuery);
  }, [runQuery]);
}
