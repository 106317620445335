import { Button } from '@clickhouse/click-ui';
import { Instance } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import Helmet from 'src/components/Helmet/Helmet';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { DataWarehouseList } from 'src/components/DataWarehouseList';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useIsRegionBlocked } from 'src/lib/blockedRegion';
import { useCurrentUserWithRoleInCurrentOrg } from 'src/lib/user/useCurrentUserWithRoleInCurrentOrg';

interface DataWarehouseListPageProps {
  instances: Array<Instance>;
  currentOrganizationName: string;
  organizationId: string;
}
export const DataWarehouseListPage = ({
  instances,
  currentOrganizationName,
  organizationId
}: DataWarehouseListPageProps): ReactElement | null => {
  const headerText = `All warehouses - ${currentOrganizationName}`;
  const currentUser = useCurrentUserWithRoleInCurrentOrg();
  const isBlocked = useIsRegionBlocked();
  const { createNewServiceOrShowTrialLimitModal } =
    useServiceLimitReachedModal();

  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    currentUser.role === 'ADMIN'
  );

  if (!currentUser) {
    return null;
  }
  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label={headerText} data-testid="title">
        {instances.length !== 0 && hasManageServicePermission && !isBlocked && (
          <Button
            label="Create new warehouse"
            iconLeft="plus"
            onClick={createNewServiceOrShowTrialLimitModal}
            data-testid="create-warehouse-button-top"
          />
        )}
      </MainPaneLayout.Topbar>

      <MainPaneLayout.Content>
        <Helmet>
          <title>ClickHouse Cloud</title>
        </Helmet>
        <MaxWidthContainer
          orientation="vertical"
          padding="lg"
          data-testid="warehouse-list-page"
          maxWidth="1740px"
        >
          <DataWarehouseList
            hasManageServicePermission={hasManageServicePermission}
            organizationId={organizationId}
          />
        </MaxWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};
