import { ReactElement } from 'react';
import { Container, Spacer } from '@clickhouse/click-ui';
import SupportCaseEmpty from 'src/components/SupportList/SupportCaseEmpty';
import SupportCaseListContainer from 'src/components/SupportList/SupportCaseListContainer';
import SupportLayout from 'src/layout/SupportLayout';
import { useSupportCases } from 'src/support/controller/useSupportCases';

function SupportListPage(): ReactElement {
  const { data = [], isLoading, error } = useSupportCases();
  return (
    <SupportLayout supportCases={data} isLoading={isLoading} error={error}>
      <Container orientation="vertical">
        {data.length === 0 && <Spacer size="xxl" />}
        {data.length > 0 ? (
          <SupportCaseListContainer supportCases={data} />
        ) : (
          <SupportCaseEmpty />
        )}
      </Container>
    </SupportLayout>
  );
}

export default SupportListPage;
