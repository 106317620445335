import { useEffect } from 'react';

import { useDrop } from 'react-dnd-cjs';

import { dropStyle } from 'src/components/QueryView/QueryList/Folders/Tree/styles';
import {
  CollectedProps,
  ItemType,
  OnDropType,
  PositionType,
  SizeType
} from 'src/components/QueryView/QueryList/Folders/Tree/types';

interface DropProps {
  onDrop: OnDropType;
  parentId: string;
  path: string | null;
  level?: number;
  active?: boolean;
  direction: PositionType;
  position?: 'between' | PositionType;
  onDrag?: (isOver: boolean) => void;
  size?: SizeType;
  isDragging?: boolean;
}
const Drop = ({
  onDrop,
  level,
  active,
  parentId,
  path,
  direction,
  position,
  onDrag,
  size,
  isDragging
}: DropProps) => {
  const [{ isOver }, drop] = useDrop<
    ItemType,
    unknown,
    Partial<CollectedProps>
  >({
    accept: 'node',
    drop: (item) => {
      onDrop(item.id, parentId, direction, item);
    },
    canDrop: (item) => {
      return item.path !== path;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver() && monitor.getItem().path !== path
    })
  });

  useEffect(() => onDrag && onDrag(isOver || false), [isOver]);

  return (
    <div
      ref={drop}
      css={dropStyle({
        width: size?.width || '100%',
        height: size?.height || '100%'
      })}
      data-direction={position || direction}
      data-level={level}
      data-active={isOver || active}
      data-dragging={isDragging}
    />
  );
};

export default Drop;
