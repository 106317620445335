import { Container, Icon, useCUITheme } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import {
  InstanceStateWithUpgrade,
  instanceStateColors,
  instanceStateIcon
} from 'src/instance/instance';
import styled from 'styled-components';
const IconContainer = styled(Container)<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 50%;
  height: 20px;
  width: 20px;
`;
export const StatusIcon = ({
  status
}: {
  status: InstanceStateWithUpgrade;
}): ReactElement => {
  const theme = useCUITheme();
  const colors = instanceStateColors(theme, status);
  return (
    <IconContainer
      $backgroundColor={colors.backgroundColor}
      orientation="horizontal"
      alignItems="center"
      justifyContent="center"
      padding="xxs"
      fillWidth={false}
    >
      <Icon
        name={instanceStateIcon(status)}
        color={colors.iconColor}
        height="12px"
        width="12px"
      />
    </IconContainer>
  );
};
