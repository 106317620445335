/** Lazy assertion errors provider function. */
export type AssertErrorProvider = () => Error | string;

/**
 * Asserts that the 'value' is truthy (non-nullable) or throws Error.
 *
 * This method is designed as an alternative for the NodeJS assert() and works both on the client and server sides.
 * The name 'assertTruthy' is selected to avoid accidental confusion with NodeJS 'assert'.
 */
export function assertTruthy(value: unknown, error: string | Error | AssertErrorProvider): asserts value {
  if (!value) {
    fail(error);
  }
}

export function fail(error: string | Error | AssertErrorProvider): never {
  const messageOrObject = typeof error === 'function' ? error() : error;
  throw typeof messageOrObject === 'object' ? messageOrObject : new Error(messageOrObject ?? 'Assertion error');
}

/**
 * Casts the 'value' to a non-nullable type or throws an error.
 * Calls 'assertTruthy' to make the check.
 */
export function truthy<T>(value: T, error: string | Error | AssertErrorProvider): NonNullable<T> {
  assertTruthy(value, error);
  return value as NonNullable<T>;
}

/**
 * Asserts that 2 values are equal using referential (===) equality.
 * Includes both values into the error message when failed, so be careful with sensitive fields (use assertTruthy).
 */
export function assertEquals<T>(actual: T, expected: T, error?: string | Error | AssertErrorProvider): void {
  if (actual === expected) {
    return;
  }
  const messageOrObject = typeof error === 'function' ? error() : error;
  throw typeof messageOrObject === 'object'
    ? messageOrObject
    : new Error(messageOrObject ?? `Assertion, expected '${actual}' to be equal to '${expected}'`);
}

/**
 * Asserts that a value is of type "never".
 *
 * This is useful for raising a compile time error that a branch should never be
 * taken (i.e. when exhaustively testing for all members of a type union or enum
 * in a switch statement).
 */
export function assertNever(val: never, error: string | Error | AssertErrorProvider): never {
  void val;
  fail(error);
}
