import { FocusEventHandler, ReactElement, useRef, useState } from 'react';
import { Checkbox, Container, TextField } from '@clickhouse/click-ui';
import styled from 'styled-components';

const CustomTextFieldContainer = styled(Container)<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
`;
interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  onErrorChange: (checked: boolean) => void;
}

function QuestionnaireAnswerWithInput({
  label,
  value,
  onChange,
  checked,
  onCheckedChange: onCheckedChangeProp,
  onErrorChange
}: Props): ReactElement {
  const [visited, setVisited] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const handleTextChange = (input: string): void => {
    onChange(input);
    onErrorChange(checked && input.length === 0);
  };

  const onCheckedChange = (checked: boolean | 'indeterminate'): void => {
    const newChecked = checked === true;
    onCheckedChangeProp(newChecked);
    onErrorChange(newChecked && value.length === 0);
    if (newChecked) {
      setTimeout(() => ref.current?.focus(), 0); //inorder to focus the input correctly
    } else {
      setVisited(false);
    }
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setVisited(true);
    onErrorChange(e.target.value.length === 0 && checked);
  };

  return (
    <>
      <Container orientation="horizontal" gap="xs">
        <Checkbox
          label={label}
          checked={checked}
          onCheckedChange={onCheckedChange}
        />
        <CustomTextFieldContainer $show={checked}>
          <TextField
            ref={ref}
            value={value}
            onChange={handleTextChange}
            error={visited && value.length === 0 && checked}
            onBlur={onBlur}
            disabled={!checked}
            data-testid={`${label.toLowerCase().replaceAll(' ', '-')}-input`}
          />
        </CustomTextFieldContainer>
      </Container>
    </>
  );
}

export default QuestionnaireAnswerWithInput;
