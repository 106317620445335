import { produce, Draft } from 'immer';
import { Atom, atom, useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { v4 as uuid } from 'uuid';

import { DataPreview } from 'src/lib/dataLoading/DataPreviewer';
import {
  EditColumn,
  EditColumnId,
  FileBasedImport,
  FileFormatType,
  FileType,
  FileUrlImport,
  ImportModel,
  NewFileUploadImport,
  NewFileUrlImport,
  NewImportModel,
  RowPreview,
  SampleDataImport,
  TableConfig,
  KafkaImport,
  KafkaImportData,
  ObjectStorageImportData,
  S3Import,
  FileUrlCredentials
} from 'shared/src/dataLoading';
import { SchemaRegistryResponse } from 'types/protocol';

const storage = createJSONStorage<ImportModel>(() => sessionStorage);

const initialImport: NewImportModel = {
  id: uuid(),
  name: 'New Import',
  type: null,
  status: 'new'
};

export const getNewUrlImportModel = (id: string): NewFileUrlImport => {
  return {
    id,
    name: 'New Url Import',
    status: 'new',
    type: 'file_url',
    data: {
      requiresCredentials: false,
      sizeBytes: 0,
      url: '',
      fileType: null,
      formatType: null
    }
  };
};

export const getNewFileUploadModel = (id: string): NewFileUploadImport => {
  return {
    id: id,
    name: 'File Upload',
    status: 'new',
    type: 'file_upload',
    data: {
      file: null,
      rowsPreview: [],
      sizeBytes: 0,
      fileSampleRaw: ''
    }
  };
};

export const getClickPipeImportModel = (id: string): KafkaImport => {
  return {
    id: id,
    name: '',
    status: 'new' as const,
    type: 'kafka' as const,
    // the minimal set of overlapping properties
    data: {
      format: 'JSONEachRow' as const,
      step: 0
      // FIXME should be refactored during https://github.com/ClickHouse/control-plane/issues/6510
    } as KafkaImportData
  };
};

export const getObjectStorageImportModel = (id: string): S3Import => {
  return {
    id: id,
    name: '',
    status: 'new' as const,
    type: 's3' as const,
    // the minimal set of overlapping properties
    data: {
      format: 'json' as const,
      step: 0
      // FIXME should be refactored during https://github.com/ClickHouse/control-plane/issues/6510
    } as ObjectStorageImportData
  };
};

const topicsStorage = createJSONStorage<Array<string>>(() => sessionStorage);
export const kafkaTopicsAtom = atomWithStorage<Array<string>>('kafkaTopics', [], topicsStorage);

const streamsStorage = createJSONStorage<Array<string>>(() => sessionStorage);
export const kinesisStreamsAtom = atomWithStorage<Array<string>>('kinesisStreams', [], streamsStorage);

const schemaStorage = createJSONStorage<SchemaRegistryResponse>(() => sessionStorage);
export const schemaRegistryAtom = atomWithStorage<SchemaRegistryResponse>(
  'schemaRegistry',
  { schema: '', schemaType: '' },
  schemaStorage
);
const importModelAtom = atomWithStorage<ImportModel>('currentDataImport', initialImport, storage);

const progressAtom = atomWithStorage('currentImportProgress', 0);
const uploadXhrRequestAtom = atom<XMLHttpRequest | null>(null);

const isDataSampleSelected = atom((get) => {
  const model = get(importModelAtom);
  return model.type === 'sample_data' && !!model.data?.dataset;
});

export function useIsDataSampleSelected(): boolean {
  const [value] = useAtom(isDataSampleSelected);
  return value;
}

const rowsPreview = atom((get) => {
  const model = get(importModelAtom);
  if (model.type === null) {
    return [];
  }

  return model?.data?.rowsPreview || [];
});

const columnsAtom: Atom<EditColumn[]> = atom((get) => {
  const model = get(importModelAtom);
  if (!model.type || !model.data.tableConfig) {
    return [];
  }

  if (model.data?.tableConfig?.tableType === 'newTable') {
    const tableStateColIds = model.data?.tableConfig?.config.columnIds ?? [];
    const tableStateColsById: Record<EditColumnId, EditColumn> = model.data?.tableConfig?.config?.columns ?? {};

    const columns = tableStateColIds.map((colId: EditColumnId) => tableStateColsById[colId]);
    return columns ?? [];
  } else {
    return model.data.tableConfig.config.sourceColumns.map((col) => ({
      ...col,
      originalName: col.name,
      defaultExpression: ''
    }));
  }
});

export function useRowsPreview(): RowPreview[][] {
  const [value] = useAtom(rowsPreview);
  return value;
}

export function useColumns(): EditColumn[] {
  const [value] = useAtom(columnsAtom);
  return value.filter((column) => !column.removed);
}

export function useAllColumns(): EditColumn[] {
  const [value] = useAtom(columnsAtom);
  return value;
}

export const useCancelableFileUpload = () => {
  const [xhr, setUploadXhr] = useAtom(uploadXhrRequestAtom);

  return {
    cancelUpload: () => {
      xhr?.abort();
      setUploadXhr(null);
    },
    setUploadXhr
  };
};

export const useImportModel = () => {
  const [importModel, setImportModel] = useAtom(importModelAtom);
  const [progress, setProgress] = useAtom(progressAtom);
  const resetImport = () => setImportModel(initialImport);

  const updateImportModel = <T extends ImportModel>(updater: (draft: Draft<T>) => void): void => {
    setImportModel((previous) => {
      const newModel = produce(previous as T, (draft) => updater(draft));
      return newModel;
    });
  };

  const setName = (name: string) =>
    updateImportModel((draft: ImportModel) => {
      draft.name = name;
    });

  const setUrl = (url: string) =>
    updateImportModel((draft: FileUrlImport | NewFileUrlImport) => {
      draft.data.url = url;
    });

  const setFile = (file: File, rawData: string) =>
    updateImportModel(async (draft: NewFileUploadImport) => {
      draft.data.file = file;
      draft.data.fileName = file.name;
      draft.data.fileSampleRaw = rawData;
      draft.data.sizeBytes = file.size;
    });

  const setFileType = (fileType: FileType | null) =>
    updateImportModel((draft: FileBasedImport) => {
      draft.data.fileType = fileType;
    });

  const setFormatType = (formatType: FileFormatType) =>
    updateImportModel((draft: FileBasedImport) => {
      draft.data.formatType = formatType;
      switch (formatType) {
        case 'CSV':
        case 'CSVWithNames':
        case 'CSVWithNamesAndTypes':
          draft.data.fileType = 'text/csv';
          break;
        case 'TabSeparated':
        case 'TabSeparatedWithNames':
        case 'TabSeparatedWithNamesAndTypes':
          draft.data.fileType = 'text/tab-separated-values';
          break;
        case 'JSONEachRow':
          draft.data.fileType = 'application/json';
          break;
        default:
          draft.data.fileType = null;
      }
    });

  const setTableConfig = (tableState: TableConfig) =>
    updateImportModel((draft: FileBasedImport | SampleDataImport) => {
      draft.data.tableConfig = tableState;
    });

  const setRowsPreview = (rows: RowPreview[][]) =>
    updateImportModel((draft: FileBasedImport | SampleDataImport) => {
      draft.data.rowsPreview = rows;
    });

  const setFileSampleRaw = (rawContent: string) =>
    updateImportModel((draft: NewFileUploadImport) => {
      draft.data.fileSampleRaw = rawContent;
    });

  const setDataPreview = (dataPreview: DataPreview | null) =>
    updateImportModel((draft: FileBasedImport | SampleDataImport) => {
      if (!dataPreview) {
        draft.data.rowsPreview = [];
        draft.data.tableConfig = undefined;
        draft.data.formatType = null;
        draft.data.sizeBytes = 0;
      } else {
        const { rows, tableConfig, formatType, sizeBytes } = dataPreview;
        draft.data.rowsPreview = rows;
        draft.data.tableConfig = tableConfig;
        draft.data.formatType = formatType;
        draft.data.sizeBytes = sizeBytes;
      }
    });

  const setSeparator = (separator: string) =>
    updateImportModel((draft: FileBasedImport) => {
      draft.data.separator = separator;
    });

  const setRequiresCredentials = (requiresCredentials: boolean) =>
    updateImportModel((draft: FileUrlImport | NewFileUrlImport) => {
      draft.data.requiresCredentials = requiresCredentials;
    });

  const requiresCredentials = importModel.type === 'file_url' && !!importModel.data.requiresCredentials;
  const hasValidPassword =
    importModel.type === 'file_url' &&
    requiresCredentials &&
    !!importModel.data.credentials &&
    !!importModel.data.rowsPreview;

  const setDatabase = (newDb: string) =>
    updateImportModel((draft: Draft<FileBasedImport>) => {
      if (!draft.data.tableConfig) {
        return;
      }

      draft.data.tableConfig.config.schema = newDb;
    });

  const setCredentials = (credentials: FileUrlCredentials | undefined): void =>
    updateImportModel((draft: Draft<FileUrlImport | NewFileUrlImport>) => {
      draft.data.credentials = credentials;
    });

  const setHasValidCredentials = (validCredentials: boolean): void =>
    updateImportModel((draft: FileUrlImport | NewFileUrlImport) => {
      draft.data.validCredentials = validCredentials;
    });

  const hasValidCredentials = importModel.type === 'file_url' && !!importModel.data.validCredentials;
  const fileType =
    importModel.type === 'file_upload' || importModel.type === 'file_url' ? importModel.data.fileType : null;

  return {
    importModel,
    setImportModel,
    updateImportModel,
    resetImportModel: resetImport,
    setProgress,
    progress,
    setRequiresCredentials,
    requiresCredentials,
    hasValidPassword,
    setCredentials,
    hasValidCredentials,
    setHasValidCredentials,
    setName,
    setUrl,
    setFile,
    setFileType,
    setFormatType,
    setTableConfig,
    setRowsPreview,
    setFileSampleRaw,
    setDataPreview,
    setSeparator,
    setDatabase,
    fileType
  };
};
