import React, { useMemo, useRef } from 'react';

import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import {
  Area,
  AreaChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { v4 as uuid } from 'uuid';

import AxisTick from './ChartComponents/AxisTick';
import CartesianGrid from './ChartComponents/CartesianGrid';
import ChartLegend from './ChartComponents/Legend';
import { useAxisConfig } from './ChartUtils/axis';
import {
  useDataLimit,
  useResizeListener,
  useVerticalInterval,
  useXAxisHeight,
  useYAxisWidth
} from './ChartUtils/hooks';
import defaultClasses from './styles';

const PrimitiveLineChart = React.forwardRef((props, ref) => {
  const {
    animate,
    footer,
    layout,
    data: dataProp,
    editable,
    showLegend,
    subtitle,
    title,
    xAxis,
    xAxisType,
    xAxisLabel,
    xAxisTickVertical,
    yAxis,
    yAxisType,
    yAxisLabel,
    xAxisRange: xAxisRangeProp,
    yAxisRange: yAxisRangeProp,
    allowEscapeViewBox
  } = props;
  const theme = useTheme();

  const data = useDataLimit(dataProp);
  const id = useRef(`area-chart-${uuid()}`);
  const [height, rootRef, headerRef, footerRef, onResize, width] =
    useResizeListener([title, subtitle, footer]);
  const interval = useVerticalInterval(data, width);
  const charLimit = useMemo(
    () => Math.max(Math.round((height / 100) * 6), 10),
    [height]
  );
  const yAxisWidth = useYAxisWidth(id.current, yAxisLabel, yAxis, data);
  const [xAxisHeight, yOffset] = useXAxisHeight(
    id.current,
    xAxisLabel,
    xAxis,
    xAxisTickVertical,
    data,
    charLimit
  );
  const colors = theme.colors.chart.values;

  const { xAxesConfigs, yAxesConfigs, dataAxisConfig, chartKey } =
    useAxisConfig(
      layout,
      data,
      xAxis,
      xAxisRangeProp,
      yAxis,
      yAxisType,
      yAxisRangeProp
    );

  const [areas, gradients] = useMemo(() => {
    const axis = layout === 'vertical' ? yAxis : xAxis;
    let areas = [];
    let gradients;
    if (typeof axis === 'string') {
      gradients = (
        <defs>
          <linearGradient id="area-color-0" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.global.color.stroke.default}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={theme.global.color.stroke.default}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
      );
      areas = dataAxisConfig.map(({ color, ...props }, key) => [
        <Area
          key={key}
          {...props}
          isAnimationActive={animate}
          type="linear"
          strokeWidth={2}
          stroke={theme.global.color.stroke.default}
          fill="url(#area-color-0)"
          // activeDot={{ r: 5 }}
        />
      ]);
    } else if (Array.isArray(axis)) {
      gradients = (
        <defs>
          {axis.map((area, key) => (
            <linearGradient
              key={key}
              id={`area-color-${key}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={area.color || colors[key % colors.length]}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={area.color || colors[key % colors.length]}
                stopOpacity={0}
              />
            </linearGradient>
          ))}
        </defs>
      );
      areas = dataAxisConfig.map(({ color, ...props }, key) => {
        return (
          <Area
            isAnimationActive={animate}
            key={key}
            {...props}
            type="linear"
            strokeWidth={2}
            stroke={color}
            fill={`url(#area-color-${key})`}
            // activeDot={{ r: 5 }}
          />
        );
      });
    }

    return [areas, gradients];
  }, [
    layout,
    JSON.stringify(xAxis),
    JSON.stringify(yAxis),
    animate,
    dataAxisConfig
  ]);

  return (
    <div id={id.current} ref={rootRef} css={defaultClasses.root}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      <div ref={headerRef} css={defaultClasses.headerContainer}>
        <div css={defaultClasses.header}>
          {title || (editable ? 'Enter title here' : '')}
        </div>
        {subtitle && (
          <div css={defaultClasses.subtitle}>
            {subtitle || (editable ? 'Enter subtitle here' : '')}
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          key={`${xAxisLabel}${yAxisLabel}-${chartKey}`}
          data={Array.isArray(data) ? data : null}
          layout={layout === 'vertical' ? 'horizontal' : 'vertical'}
          css={defaultClasses.chartStyle}
        >
          {gradients && gradients}
          <CartesianGrid parentRef={rootRef} />
          {xAxisTickVertical &&
            xAxesConfigs.map((props, i) => (
              <XAxis
                key={i}
                {...props}
                allowDataOverflow
                xAxisId={i}
                interval={interval}
                height={xAxisHeight}
                type={xAxisType || 'number'}
                tick={<AxisTick charLimit={charLimit} />}
                tickMargin={6.5}
              >
                <Label
                  value={
                    xAxisLabel || (editable ? 'Enter x-axis label here' : '')
                  }
                  position="insideBottom"
                  offset={-3}
                />
              </XAxis>
            ))}
          {!xAxisTickVertical &&
            xAxesConfigs.map((props, i) => (
              <XAxis
                key={i}
                {...props}
                allowDataOverflow
                xAxisId={i}
                interval="preserveStart"
                height={xAxisHeight}
                type={xAxisType || 'number'}
                tickMargin={4}
              >
                <Label
                  value={
                    xAxisLabel || (editable ? 'Enter x-axis label here' : '')
                  }
                  position="insideBottom"
                  offset={-3}
                />
              </XAxis>
            ))}
          {yAxesConfigs.map((props, i) => (
            <YAxis
              key={i}
              {...props}
              allowDataOverflow
              yAxisId={i}
              width={
                yAxisWidth + yOffset + (i < yAxesConfigs.length - 1 ? 20 : 0)
              }
              type={yAxisType}
            >
              <Label
                offset={0}
                value={
                  yAxisLabel || (editable ? 'Enter y-axis label here' : '')
                }
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
          ))}
          <Tooltip
            cursor={{
              fill: 'rgba(240, 240, 240, 0.5)',
              stroke: 'rgba(240, 240, 240, 0.7)'
            }}
            allowEscapeViewBox={{
              x: allowEscapeViewBox,
              y: allowEscapeViewBox
            }}
          />
          {areas.length > 1 && showLegend && <Legend content={ChartLegend} />}
          {areas}
        </AreaChart>
      </ResponsiveContainer>
      <div ref={footerRef} css={defaultClasses.footer}>
        {footer || (editable ? 'Enter footer here' : '')}
      </div>
    </div>
  );
});

PrimitiveLineChart.displayName = 'AreaChart';

PrimitiveLineChart.propTypes = {
  animate: PropTypes.bool,
  footer: PropTypes.string,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  data: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  showLegend: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  xAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  xAxisType: PropTypes.oneOf(['category', 'number']),
  xAxisLabel: PropTypes.string,
  xAxisTickVertical: PropTypes.bool,
  allowEscapeViewBox: PropTypes.bool,
  yAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  yAxisType: PropTypes.oneOf(['category', 'number']),
  yAxisLabel: PropTypes.string
};

PrimitiveLineChart.defaultProps = {
  animate: true,
  data: [],
  editable: false,
  layout: 'vertical',
  showLegend: true,
  xAxisTickVertical: false,
  allowEscapeViewBox: false
};

export default PrimitiveLineChart;
