import { createToast } from '@clickhouse/click-ui';
import {
  ChangeIpAccessListRequest,
  DUPLICATE_INSTANCE_NAME,
  IDLING_TIMEOUT_MINUTES_DEFAULT,
  Instance,
  InstanceAutoscalingParams,
  InstanceCustomerManagedEncryptionConfig,
  InstanceDatabaseAccessMapping,
  InstanceTier,
  InstanceUpdatePayload,
  IpAccessListEntry,
  OneTimeAllInstancesPayload,
  UpdateInstancePrivateEndpointsRequest
} from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import { useCallback } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import {
  countSecondaryInstances,
  generateClickHouseDbPassword,
  makeDoubleSha1HashBrowser,
  makeSha256Base64Browser
} from 'src/instance/instance';
import { BackupConfiguration, InstanceActionInput, StartFullMaintenanceInput } from 'src/instance/instanceApiClient';
import {
  isInstanceAsleep,
  isInstanceAwake,
  isInstanceAwaking,
  isInstanceDeleted,
  isInstanceProvisioning,
  isInstanceStarting,
  isInstanceStopped,
  useInstanceStateManager
} from 'src/instance/instanceState';
import config from 'src/lib/config';
import { useApiClient } from 'src/lib/controlPlane/client';
import { routes } from 'src/lib/routes';
import { useParams } from 'src/lib/routes/useParams';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import { useCurrentOrganization } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';
import useSWR, { SWRResponse } from 'swr';

const warnAboutUsingHookOutsideOfServiceRouteContext = (): void => {
  if (config.env === 'local') {
    const error = new Error('using hook outside of Service route context');
    console.warn('using hook outside of Service route context', error.stack);
  }
};

export type InstanceStatuses = {
  isUnknown: boolean;
  isAwake: boolean;
  isAsleep: boolean;
  isDeleted: boolean;
  isAwaking: boolean;
  isStopped: boolean;
  isStarting: boolean;
  isProvisioning: boolean;
};

export const DEFAULT_INSTANCE_STATUSES: InstanceStatuses = {
  isUnknown: true,
  isAwake: false,
  isAsleep: false,
  isDeleted: false,
  isAwaking: false,
  isStopped: false,
  isStarting: false,
  isProvisioning: false
};

export function useIsCurrentInstanceAwakeStatus(): InstanceStatuses {
  const instance = useCurrentInstance();

  if (!instance) {
    // warnAboutUsingHookOutsideOfServiceRouteContext();
    //these will be the defaults we are using whenever we can't find any current instance
    return DEFAULT_INSTANCE_STATUSES;
  }

  return getInstanceAwakeStatus(instance);
}

export function getInstanceAwakeStatus(instance: Instance): InstanceStatuses {
  const isAwake = isInstanceAwake(instance);
  const isAsleep = isInstanceAsleep(instance);
  const isDeleted = isInstanceDeleted(instance);
  const isAwaking = isInstanceAwaking(instance);
  const isStopped = isInstanceStopped(instance);
  const isStarting = isInstanceStarting(instance);
  const isProvisioning = isInstanceProvisioning(instance);

  return {
    isUnknown: false,
    isAwake,
    isAsleep,
    isDeleted,
    isAwaking,
    isStopped,
    isStarting,
    isProvisioning
  };
}

interface ServiceIamPrincipalKey {
  url: '/api/getInstanceIamPrincipal';
  serviceId: string;
  organizationId: string;
}

function getServiceIamPrincipalKey(instance?: Instance): ServiceIamPrincipalKey | null {
  if (!instance) {
    return null;
  }

  return {
    url: '/api/getInstanceIamPrincipal',
    organizationId: instance.organizationId,
    serviceId: instance.id
  };
}

export function useInstanceIamRole(): SWRResponse<string, Error> {
  const apiClient = useApiClient();
  const instance = useCurrentInstance();

  const key = getServiceIamPrincipalKey(instance);

  return useSWR(key, async ({ organizationId, serviceId }) => {
    return apiClient.instance.getIamPrincipal({
      organizationId,
      instanceId: serviceId
    });
  });
}

export function useCurrentInstanceId(): string | null {
  const { serviceId } = useParams();
  return serviceId;
}

export function useCurrentInstance(): Instance | undefined {
  const { instances } = useInstanceStateManager();
  const instanceId = useCurrentInstanceId();

  if (!instanceId) {
    return undefined;
  }

  return instances[instanceId];
}

export function useSecondaryInstanceCount(): number {
  const { instances } = useInstanceStateManager();
  const instanceId = useCurrentInstanceId();
  return countSecondaryInstances(instanceId, instances);
}

export function useSecondaryInstanceCountForInstance(instanceId: string): number {
  const { instances } = useInstanceStateManager();
  return countSecondaryInstances(instanceId, instances);
}

export function useInstanceOrThrow(instanceId: string | null): Instance {
  const { instances } = useInstanceStateManager();
  const instance = instances[instanceId ?? ''];
  if (!instance || !instanceId) {
    throw new Error('No service found');
  }

  return instance;
}
export function useIsInstanceTerminating(): boolean {
  const instance = useCurrentInstance();
  return instance?.state === 'terminating' || instance?.state === 'terminated';
}

export function useCurrentInstanceOrThrow(): Instance {
  const { instances } = useInstanceStateManager();
  const instanceId = useCurrentInstanceId();

  if (!instanceId) {
    throw new Error('No current service found');
  }

  return instances[instanceId];
}

export function useCurrentOrganizationInstances(): Array<Instance> {
  const currentOrg = useCurrentOrganization();
  const { instances } = useInstanceStateManager();

  return Object.values(instances).filter((instance) => instance.organizationId === currentOrg?.id);
}

export function useInitInstanceState(): void {
  const organization = useCurrentOrganization();
  const { setInstances, updateInstances } = useInstanceStateManager();
  const { user } = useUserStateManager();

  // If user id is undefined no subscription will be made
  useWebSocketsNotification<OneTimeAllInstancesPayload>({
    subscriptionDetails: {
      type: 'ONE_TIME_ALL_USER_INSTANCES_UPDATE',
      objId: user.userId || ''
    },
    handler: ({ payload }) => {
      setInstances(payload.instances);
    }
  });

  // if the organization id is empty the client will not be subscribed
  // as soon as the current organization id is defined
  // a legit subscription will be established
  useWebSocketsNotification<InstanceUpdatePayload>({
    subscriptionDetails: {
      type: 'ORG_INSTANCE_UPDATE',
      objId: organization?.id || ''
    },
    handler: ({ payload }) => {
      updateInstances(payload.instances);
    }
  });
}

interface CreateInstanceInput {
  name: string;
  organizationId: string;
  regionId: RegionId;
  ipAccessList: Array<IpAccessListEntry>;
  instanceTier: InstanceTier;
  autoscalingParams: Partial<InstanceAutoscalingParams>;
  customerManagedEncryption?: InstanceCustomerManagedEncryptionConfig;
  privatePreviewTermsChecked?: boolean;
  byocId?: string;
  onCreateService: (serviceId: string) => void;
}

interface UpdateInstanceDbRoleMapping {
  instanceId: string;
  organizationId: string;
  mappings: Array<InstanceDatabaseAccessMapping>;
}

export interface InstanceService {
  createInstance: (createInstanceInput: CreateInstanceInput) => Promise<void>;
  stopInstance: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
  startInstance: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
  upgradeInstance: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
  deleteInstance: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
  resetInstancePassword: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
  startFullMaintenance: ({
    instanceId,
    organizationId,
    runAfterMaintenance
  }: StartFullMaintenanceInput) => Promise<void>;
  updateIpAccessList: ({
    instanceId,
    ipAccessList,
    organizationId
  }: Omit<ChangeIpAccessListRequest, 'rpcAction'>) => Promise<void>;
  listInstances: ({ organizationId }: { organizationId: string }) => Promise<Array<Instance>>;
  renameInstance: ({ instanceId, organizationId, name }: RenameInstanceInput) => Promise<void>;
  updateInstanceDbRoleMapping: (props: UpdateInstanceDbRoleMapping) => Promise<void>;
  updatePrivateEndpointIds: (props: Omit<UpdateInstancePrivateEndpointsRequest, 'rpcAction'>) => Promise<boolean>;
  getBackupConfiguration: (
    organizationId: string,
    instanceId: string,
    onSuccess: (backupConfiguration: BackupConfiguration) => void
  ) => Promise<void>;
  updateBackupConfiguration: (
    organizationId: string,
    instanceId: string,
    backupConfiguration: Omit<BackupConfiguration, 'defaultBackupPeriod' | 'defaultBackupRetentionPeriod'>
  ) => Promise<void>;
  refreshUserDataFlag: (serviceId: string, organizationId: string) => Promise<void>;
}

interface RenameInstanceInput {
  instanceId: string;
  organizationId: string;
  name: string;
}
export function useInstanceController(): InstanceService {
  const apiClient = useApiClient();
  const { setIsCreatingInstance, setInstancePassword } = useInstanceStateManager();

  // When creating a custom hook, it's important to memoize the return value so that the hook does not cause unnecessary re-renders.
  // https://reactjs.org/docs/hooks-custom.html#useyourimagination
  // On this case we would always return a new object defined whenever this hook is called and whenever apiClient changes.
  // By wrapping the return value in useMemo, we ensure that the object is only created once and only when apiClient changes for all clients of this hook.
  const createInstance = useCallback(
    async ({
      name,
      organizationId,
      regionId,
      ipAccessList,
      instanceTier,
      autoscalingParams = {},
      customerManagedEncryption,
      privatePreviewTermsChecked,
      byocId,
      onCreateService
    }: CreateInstanceInput): Promise<void> => {
      try {
        const adjustedAutoscalingParams: Partial<InstanceAutoscalingParams> =
          autoscalingParams.enableIdleScaling === false
            ? { ...autoscalingParams, idleTimeoutMinutes: IDLING_TIMEOUT_MINUTES_DEFAULT }
            : autoscalingParams;
        const password = generateClickHouseDbPassword();
        const passwordHash = await makeSha256Base64Browser(password);
        const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
        setIsCreatingInstance(true);
        const serviceId = await apiClient.instance.create({
          name,
          organizationId,
          passwordHash,
          doubleSha1Password,
          regionId,
          ipAccessList,
          instanceTier,
          autoscalingParams: adjustedAutoscalingParams,
          customerManagedEncryption,
          privatePreviewTermsChecked,
          byocId
        });
        setInstancePassword(serviceId, password);
        onCreateService(serviceId);
      } catch (e) {
        let errorDescription = 'Error while creating the service';
        if (getErrorMessageFromError(e) === DUPLICATE_INSTANCE_NAME) {
          errorDescription = 'A service with the same name already exists';
        }
        createToast({
          title: 'Error',
          type: 'danger',
          description: errorDescription
        });
      } finally {
        setIsCreatingInstance(false);
      }
    },
    [apiClient.instance, setIsCreatingInstance]
  );

  const stopInstance = useCallback(
    async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      try {
        await apiClient.instance.stop({
          instanceId,
          organizationId
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while stopping the service'
        });
      }
    },
    [apiClient.instance]
  );

  const startFullMaintenance = useCallback(
    async ({ instanceId, organizationId, runAfterMaintenance }: StartFullMaintenanceInput): Promise<void> => {
      try {
        await apiClient.instance.startFullMaintenance({
          instanceId,
          organizationId,
          runAfterMaintenance
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while setting the maintenance to start'
        });
        console.error(e);
      }
    },
    [apiClient.instance]
  );

  const startInstance = useCallback(
    async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      try {
        await apiClient.instance.start({
          instanceId,
          organizationId
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while starting the service'
        });
      }
    },
    [apiClient.instance]
  );

  const upgradeInstance = useCallback(
    async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      try {
        await apiClient.instance.upgrade({
          instanceId,
          organizationId
        });
        createToast({
          title: 'Upgrade started',
          type: 'success',
          description:
            'The upgrade has started successfully. You will see the new service in the services page as soon as the upgrade has completed.'
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while upgrading the service'
        });
      }
    },
    [apiClient.instance]
  );

  const deleteInstance = useCallback(
    async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      try {
        await apiClient.instance.delete({
          instanceId,
          organizationId
        });
        navigateTo(routes.services());
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while deleting the service'
        });
      }
    },
    [apiClient.instance]
  );

  const updateIpAccessList = useCallback(
    async ({
      instanceId,
      ipAccessList,
      organizationId
    }: Omit<ChangeIpAccessListRequest, 'rpcAction'>): Promise<void> => {
      try {
        await apiClient.instance.updateIpAccessList({
          instanceId,
          ipAccessList,
          organizationId
        });
        createToast({
          title: 'Success',
          type: 'success',
          description: 'IP preferences saved successfully'
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while updating the IP access list'
        });
      }
    },
    [apiClient.instance]
  );

  const resetInstancePassword = useCallback(
    async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      try {
        const password = generateClickHouseDbPassword();
        const passwordHash = await makeSha256Base64Browser(password);
        const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
        await apiClient.instance.resetInstancePassword({
          doubleSha1Password,
          passwordHash,
          instanceId,
          organizationId
        });
        setInstancePassword(instanceId, password);
        createToast({
          title: 'Success',
          type: 'success',
          description: 'Password reset successfully'
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while resetting the service password'
        });
      }
    },
    [apiClient.instance, setInstancePassword]
  );

  const renameInstance = useCallback(
    async ({
      instanceId,
      organizationId,
      name
    }: {
      instanceId: string;
      organizationId: string;
      name: string;
    }): Promise<void> => {
      try {
        await apiClient.instance.rename({
          instanceId,
          organizationId,
          name
        });
        createToast({
          title: 'Success',
          type: 'success',
          description: 'Service name updated successfully '
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while updating the service name'
        });
        throw e;
      }
    },
    [apiClient.instance]
  );

  const updateInstanceDbRoleMapping = useCallback(
    async ({
      instanceId,
      organizationId,
      mappings
    }: {
      instanceId: string;
      organizationId: string;
      mappings: Array<InstanceDatabaseAccessMapping>;
    }): Promise<void> => {
      apiClient.instance
        .updateInstanceDbRoleMapping({
          instanceId,
          organizationId,
          defaultDatabaseRoleMappings: mappings
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [apiClient.instance]
  );

  const listInstances = useCallback(
    ({ organizationId }: { organizationId: string }): Promise<Array<Instance>> => {
      return apiClient.instance.list({ organizationId });
    },
    [apiClient.instance]
  );

  const updatePrivateEndpointIds = useCallback(
    async ({
      organizationId,
      instanceId,
      privateEndpointIds
    }: Omit<UpdateInstancePrivateEndpointsRequest, 'rpcAction'>): Promise<boolean> => {
      try {
        await apiClient.instance.updatePrivateEndpointIds({
          organizationId,
          instanceId,
          privateEndpointIds
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while updating private endpoint list'
        });
        return false;
      }
      return true;
    },
    [apiClient.instance]
  );

  const getBackupConfiguration = useCallback(
    async (
      organizationId: string,
      instanceId: string,
      onSuccess: (backupConfiguration: BackupConfiguration) => void
    ): Promise<void> => {
      try {
        const backupConfiguration = await apiClient.instance.getBackupConfiguration(organizationId, instanceId);
        onSuccess(backupConfiguration);
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while fetching backup configuration'
        });
      }
    },
    [apiClient.instance]
  );

  const updateBackupConfiguration = useCallback(
    async (
      organizationId: string,
      instanceId: string,
      backupConfiguration: Omit<BackupConfiguration, 'defaultBackupPeriod' | 'defaultBackupRetentionPeriod'>
    ): Promise<void> => {
      try {
        await apiClient.instance.updateBackupConfiguration(organizationId, instanceId, backupConfiguration);
        createToast({
          title: 'Success',
          type: 'success',
          description: 'Backup configuration saved successfully'
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while updating backup configuration'
        });
      }
    },
    [apiClient.instance]
  );

  const refreshUserDataFlag = useCallback(
    async (instanceId: string, organizationId: string) => {
      await apiClient.instance.rpcRequest({ rpcAction: 'refreshUserDataFlag', organizationId, instanceId });
    },
    [apiClient.instance]
  );
  return {
    createInstance,
    stopInstance,
    startInstance,
    upgradeInstance,
    listInstances,
    deleteInstance,
    startFullMaintenance,
    updateIpAccessList,
    resetInstancePassword,
    renameInstance,
    updateInstanceDbRoleMapping,
    updatePrivateEndpointIds,
    getBackupConfiguration,
    updateBackupConfiguration,
    refreshUserDataFlag
  };
}
