import { Container, Panel, Title, Text, Button } from '@clickhouse/click-ui';
import { Galaxy } from 'galaxy';
import React, { ReactElement, useState } from 'react';
import FeedbackDialog from 'src/components/ServiceMetrics/FeedbackDialog';

type Props = {
  host: string;
  port: number;
};

const FeedbackPanel = React.memo(({ host, port }: Props): ReactElement => {
  const dashboardUrl = `https://${host}:${port}/dashboard`;
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDashboardUrl = (): void => {
    Galaxy.galaxy().track('serviceHealth.advancedDashboard.opened', {
      interaction: 'click'
    });
    window.open(dashboardUrl, '_blank');
  };

  return (
    <>
      <Panel hasBorder>
        <Container
          orientation="vertical"
          justifyContent="center"
          alignItems="center"
          gap="xl"
        >
          <Title type="h2">Can't find what you are looking for?</Title>
          <Text color="muted" align="center">
            Check out our advanced dashboard to view CPU Usage, queries per
            second, running queries, reads from disk and many others.
          </Text>
          <Container justifyContent="center" gap="xs">
            <Button type="primary" onClick={openDashboardUrl}>
              Advanced Dashboard
            </Button>
            <Button
              data-testid={'request-new-metric-button'}
              type="secondary"
              onClick={(): void => {
                setDialogOpen(true);
                Galaxy.galaxy().track('serviceHealth.requestMetric.opened', {
                  interaction: 'click'
                });
              }}
            >
              Request new metric
            </Button>
          </Container>
        </Container>
      </Panel>
      {dialogOpen && (
        <FeedbackDialog
          open={dialogOpen}
          onClose={(): void => setDialogOpen(false)}
        />
      )}
    </>
  );
});

export default FeedbackPanel;
