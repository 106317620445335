import {
  Link,
  Table,
  TableHeaderType,
  TableRowType
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import DownloadCSVLink from 'src/components/BillingInfo/DownloadCSVLink';
import { parseInvoiceDateRange } from 'src/components/BillingInfo/invoiceDateRangeFormatter';
import { formatCurrency } from 'src/lib/formatters/currencyFormatter';

export type Invoice = {
  /** Unix timestamp in milliseconds. */
  periodStartDate?: number;
  /** Unix timestamp in milliseconds. */
  periodEndDate?: number;
  /** Invoice ID. */
  invoiceId: string;
  /** Amount due on the invoice */
  amount: number;
  /** Currency of the amount due on the invoice */
  currency: string;
  /** BillId used to link invoices with bill periods and allow downloading CSV statement. */
  billId?: string;
  /** Status of the invoice */
  status: string;
  /** Direct download link for the invoice PDF. */
  invoicePdfDownloadLink: string;
  /** Direct link of the hosted invoice page */
  invoicePaymentLink?: string;
  /** Organization ID from instance belongs. */
  organizationId: string;
};

export type InvoicesTableProps = { invoices: Invoice[] };

const InvoicesTable = ({ invoices }: InvoicesTableProps): ReactElement => {
  const headers: Array<TableHeaderType> = [
    { label: 'Time period' },
    { label: 'Invoice no.' },
    { label: 'Amount' },
    { label: 'Usage statement' },
    { label: 'Status' },
    { label: '' }
  ];

  const rows: Array<TableRowType> = invoices.map((i) => {
    return {
      id: i.invoiceId,
      items: [
        {
          label: parseInvoiceDateRange(i.periodStartDate, i.periodEndDate)
        },
        {
          label: i.invoiceId,
          className: 'fs-exclude'
        },
        {
          label: formatCurrency(i.amount, i.currency),
          className: 'fs-exclude'
        },
        {
          label: (
            <DownloadCSVLink
              organizationId={i.organizationId}
              billId={i.billId}
            />
          )
        },
        {
          label: i.status
        },
        {
          label: (
            <Link
              target="_blank"
              href={
                i.invoicePaymentLink
                  ? i.invoicePaymentLink
                  : i.invoicePdfDownloadLink
              }
            >
              {i.status === 'Unpaid' && i.invoicePaymentLink
                ? 'Pay invoice'
                : 'View invoice'}
            </Link>
          )
        }
      ]
    };
  });

  return <Table headers={headers} rows={rows} />;
};

export default InvoicesTable;
