import { css, SerializedStyles, Theme } from '@emotion/react';

export const modal =
  (width: number | string | undefined) =>
  (theme: Theme): SerializedStyles => css`
    background: ${theme.global.color.background.default};
    border: 1px solid ${theme.global.color.stroke.default};
    border-radius: 4px;
    width: ${typeof width === 'number' ? `${width}px` : width};
    min-height: 50px;
    height: auto;
    max-height: 450px;
    box-shadow: ${theme.shadows.modal};
  `;

export const modalHeader = (theme: Theme): SerializedStyles => css`
  border-bottom: 1px solid ${theme.global.color.stroke.default};
  color: ${theme.global.color.text.default};
  padding: 12px;
  font-weight: 500;
  user-select: none;
  cursor: default;
  display: flex;
  .modal-variant-wide > & {
    align-items: center;
    justify-content: center;
  }
`;

export const modalBody = (theme: Theme): SerializedStyles => css`
  color: $ ${theme.global.color.text.muted};
  padding: 12px;
  overflow: overlay;
  @supports not (overflow: overlay) {
    overflow: auto;
  }
  max-height: 365px;
  b,
  strong {
    color: ${theme.global.color.text.default};
  }
  line-height: 18px;
  .dark {
    color: ${theme.global.color.text.default};
  }
`;

export const modalFooter =
  (isLeftAligned: boolean) =>
  (theme: Theme): SerializedStyles => css`
    display: flex;
    flex-direction: ${isLeftAligned ? 'row' : 'row-reverse'};
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${theme.global.color.stroke.default};
    padding: 12px 12px;
    color: ${theme.global.color.stroke.default};
    > :not(.btn-contained) {
      padding: 0;
    }
  `;

export const closeBtn: SerializedStyles = css`
  position: absolute;
  top: 15px;
  right: 15px;
  height: 14px;
  display: flex;
  align-items: center;
`;

export const modalFooterBtn = (theme: Theme): SerializedStyles => css`
  &[data-color='transparent'] {
    background: transparent;
    border: none;
    min-height: initial;
    color: ${theme.global.color.text.muted};
    &:enabled {
      &:hover,
      &[data-active='true'] {
        color: ${theme.global.color.text.default};
      }
    }
    &[data-type='primary'] {
      color: ${theme.global.color.text.default};
      &:enabled {
        &:hover,
        &[data-active='true'] {
          color: ${theme.global.color.accent.default};
          text-decoration: underline;
        }
      }
    }
    &[data-type='dark'] {
      color: ${theme.global.color.text.default} !important;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`;
