import { css } from '@emotion/react';
import ClickPipeActionDialog from 'src/components/ClickPipeActionDialog/ClickPipeActionDialog';
import styled from 'styled-components';
import React, { useState, useEffect, ReactElement } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Container,
  ContainerProps,
  DialogProps,
  EllipsisContent,
  Flyout,
  Link,
  Select,
  Separator,
  Tabs,
  Text,
  Tooltip
} from '@clickhouse/click-ui';

import { routes } from 'src/lib/routes';
import { Row } from 'shared/src/dataLoading';
import {
  ClickPipeStatus,
  KafkaSource,
  ObjectStorageSource,
  KinesisSource,
  PostgresSource
} from 'types/protocol';
import { useOrgFeature } from 'src/lib/features';
import { formatDataSize } from 'src/metrics/dataSize';
import DangerIcon from 'src/components/icons/DangerIcon';
import LoadingIcon from 'src/components/icons/LoadingIcon';
import { useApiClient } from 'src/lib/controlPlane/client';
import { escapeString } from 'shared/src/tableSchema/escape';
import {
  getCurrentServiceIdOrFail,
  useServiceFeatureFlag
} from 'src/state/service';
import { formatNumber } from 'src/lib/formatters/numberFormatter';
import { ResultData, useSqlQuery } from 'src/lib/clickhouse/query';
import StatusBadge from 'src/components/ClickPipesList/StatusBadge';
import FieldColumn from 'src/components/ImportRightBar/FieldColumn';
import { DbMetrics } from 'src/components/ClickPipesList/DbMetrics';
import { PipeDetailsOption } from 'src/components/ImportRightBar/types';
import { LineContainer } from 'src/components/ImportRightBar/LineContainer';
import { truncateMiddle } from 'src/components/ImportRightBar/truncateMiddle';
import { getClickPipeStatus } from 'src/lib/dataLoading/clickpipes/getStatus';
import { prettifyTimeAgo } from 'src/components/ImportRightBar/prettifyTimeAgo';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import MetricChartWithSummary from 'src/components/Metrics/MetricChartWithSummary';
import { getClickPipeTypeName } from 'src/components/ClickPipesList/ClickPipeType';
import {
  formatTimeShort,
  formatUtcTimestamp
} from 'src/lib/formatters/dateTimeFormatter';
import ClickPipeDeleteDialog from 'src/components/ClickPipesDeleteDialog/ClickPipeDeleteDialog';
import {
  alignCenter,
  flex,
  spaceBetween,
  w100,
  gapSm
} from 'src/lib/utility-styles';
import {
  getAggregationPeriod,
  getDefaultMetric,
  MetricSummaryChartProps,
  parseMetric
} from 'src/components/Metrics/MetricUtils';
import {
  TabsContainer,
  AggregationPeriodValue,
  TabsContent,
  TabsTrigger
} from 'src/components/ImportRightBar/styles';
import {
  ClickPipeMetric,
  IdleClickPipeMetric
} from 'src/components/ClickPipesList/ClickPipeMetric';

type Props = DialogProps &
  Omit<PipeDetailsOption, 'type' | 'id'> & {
    dbMetrics: DbMetrics;
    isInstanceAwake: boolean;
  };

type FormattedResultsValueProps = {
  error?: string;
  loading: boolean;
  data?: ResultData;
  valueExtractor: (rows: Row[]) => number;
  formatter: (value: number) => string;
};

function FormattedResultsValue({
  error,
  data,
  loading,
  valueExtractor,
  formatter
}: FormattedResultsValueProps): JSX.Element | null {
  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <DangerIcon />
        </Tooltip.Trigger>
        <Tooltip.Content>{error}</Tooltip.Content>
      </Tooltip>
    );
  }

  if (!data) {
    return null;
  }

  const value = Number(valueExtractor(data.rows)) || 0;
  return <div>{formatter(value)}</div>;
}

function getActionsText(
  status?: ClickPipeStatus
): 'Pause' | 'Resume' | 'Retry' {
  if (status === 'Stopped' || status === 'Stopping') {
    return 'Resume';
  }

  if (status === 'Failed') {
    return 'Retry';
  }

  return 'Pause';
}

const errorsStyle = css({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
});

const CustomMetricChartWithSummary = styled(MetricChartWithSummary)`
  max-width: calc(var(--flyout-width) - 3rem);
`;

const pipeActionsWrapperStyles = css`
  padding: 1.5em 1.5em;
`;

function isActionsDisabled(status?: ClickPipeStatus): boolean {
  return (
    status === 'Stopping' || status === 'Provisioning' || status === 'Completed'
  );
}

function isKinesisSource(
  source: KafkaSource | ObjectStorageSource | KinesisSource | PostgresSource
): source is KinesisSource {
  return source.type === 'kinesis';
}

function isKafkaSource(
  source: KafkaSource | ObjectStorageSource | KinesisSource | PostgresSource
): source is KafkaSource {
  return (
    source.type === 'kafka' ||
    source.type === 'confluent' ||
    source.type === 'azureeventhub' ||
    source.type === 'msk' ||
    source.type === 'redpanda' ||
    source.type === 'upstash' ||
    source.type === 'warpstream'
  );
}

function isObjectStorageSource(
  source: KafkaSource | ObjectStorageSource | KinesisSource | PostgresSource
): source is ObjectStorageSource {
  return source.type === 's3' || source.type === 'gcs';
}

const ElementContainer = ({ gap, ...props }: ContainerProps): ReactElement => {
  return (
    <Container
      orientation="vertical"
      alignItems="start"
      gap={gap ?? 'sm'}
      fillWidth
      minWidth="fit-content"
      {...props}
    />
  );
};

function formatToHumanReadable(value: string): string {
  if (value === 'avro_confluent') return 'AvroConfluent';
  return value;
}

function PipeDetailsRightBar({
  isInstanceAwake,
  onOpenChange,
  data,
  dbMetrics,
  onClickViewData,
  onClickAction,
  onClickRemove,
  onClickScale
}: Props): ReactElement {
  const api = useApiClient();
  const serviceId = getCurrentServiceIdOrFail();
  const {
    destination: { database, table }
  } = data;
  const errorTable = data.errorHandler.tableName;
  const errorDatabase = data.errorHandler.tableDatabase || database;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false);
  const [retryDialogOpen, setRetryDialogOpen] = useState(false);

  const batchTable = `s3_clickpipe_${data.id}`;
  const s3FileStatsSql = `select sum(length(file_paths)) from \`${database}\`.\`${batchTable}\``;
  const [reload, S3FileStatsResult] = useSqlQuery(
    s3FileStatsSql,
    undefined,
    isInstanceAwake
  );

  useEffect(() => {
    // reload batch table counts every ${reloadInterval} seconds
    const reloadInterval = 10_000;
    const interval = setInterval(() => {
      if (isInstanceAwake) {
        reload().catch((e) => {
          console.warn('clickpipe batch table object count failed to query', e);
        });
      }
    }, reloadInterval);

    return () => clearInterval(interval);
  }, [reload, isInstanceAwake]);

  const errorTableStatsSql = `select total_rows, total_bytes from system.tables where database = ${escapeString(
    errorDatabase
  )} and name = ${escapeString(errorTable)};`;
  const [, errorTableStatsResult] = useSqlQuery(
    errorTableStatsSql,
    undefined,
    isInstanceAwake
  );

  const resolveActionsDialog = (status?: ClickPipeStatus) => {
    if (status === 'Stopped' || status === 'Stopping') {
      setResumeDialogOpen(true);
      return;
    }

    if (status === 'Failed') {
      setRetryDialogOpen(true);
      return;
    }

    setPauseDialogOpen(true);
  };

  const [period, setPeriod] = useState<string>('Last 24 hours');

  function changePeriod(value: string): void {
    setPeriod(value);
    void fetchMetric(value);
  }

  const [clickpipes_errors_metric, setClickpipes_errors_metric] =
    useState<MetricSummaryChartProps>(getDefaultMetric);
  const [clickpipes_fetched_events, setClickpipes_fetched_events] =
    useState<MetricSummaryChartProps>(getDefaultMetric);
  const [
    clickpipes_sent_bytes_compressed,
    setClickpipes_sent_bytes_compressed
  ] = useState<MetricSummaryChartProps>(getDefaultMetric);
  const [clickpipes_latency, setClickpipes_latency] =
    useState<MetricSummaryChartProps>(getDefaultMetric);
  const fetchMetric = async (period: string): Promise<void> => {
    try {
      const response = await api.getClickPipeMetrics(
        { period, clickpipeID: data.id },
        serviceId
      );

      // Split out the response and format it for the chart
      const clickpipes_errors_metric = parseMetric(
        response,
        'clickpipes_errors',
        period
      );

      const clickpipes_fetched_events = parseMetric(
        response,
        'clickpipes_fetched_events',
        period
      );

      const clickpipes_sent_bytes_compressed = parseMetric(
        response,
        'clickpipes_sent_bytes_compressed',
        period
      );

      const clickpipes_latency = parseMetric(
        response,
        'clickpipes_latency',
        period
      );

      setClickpipes_errors_metric(clickpipes_errors_metric);
      setClickpipes_fetched_events(clickpipes_fetched_events);
      setClickpipes_sent_bytes_compressed(clickpipes_sent_bytes_compressed);
      setClickpipes_latency(clickpipes_latency);
    } catch (error) {
      console.error(error);
    }
  };

  // Update the metric data every 60 seconds
  useEffect(() => {
    void fetchMetric(period);
    const intervalId = setInterval(() => {
      void fetchMetric(period);
    }, 60_000);
    return () => clearInterval(intervalId);
  }, [period]);

  const goToErrorsTable = (): void => {
    navigateTo(
      routes.tables({
        serviceId,
        databaseName: encodeURIComponent(errorDatabase),
        tableName: encodeURIComponent(errorTable)
      })
    );
  };

  const goToErrorsTableIfFailedStatus = (): void => {
    if (data.status === 'Failed' && table) {
      goToErrorsTable();
    }
  };

  const isLatencyChartEnabled = useOrgFeature(
    'FT_ORG_CLICKPIPES_LATENCY_CHART'
  );

  const isManualScalingEnabled = useServiceFeatureFlag(
    'FT_INSTANCE_CLICKPIPES_MANUAL_REPLICAS_SCALING'
  );
  const isManualS3ScalingEnabled = useServiceFeatureFlag(
    'FT_INSTANCE_CLICKPIPES_MANUAL_S3_CONCURRENCY_SCALING'
  );

  const [originalReplicas, setOriginalReplicas] = useState<number>(
    Number(data.replicas)
  );
  const [replicas, setReplicas] = useState<number>(originalReplicas);
  const [isReplicasLoading, setReplicasLoading] = useState(false);
  const replicaOptions: ButtonGroupProps['options'] = [
    ...Array<number>(10)
  ].map((_, i) => {
    return {
      label: `${i + 1}`,
      value: `${i + 1}`
    };
  });
  const concurrencyOptions: ButtonGroupProps['options'] = [
    {
      label: 'Auto',
      value: '0'
    },
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    },
    {
      label: '3',
      value: '3'
    },
    {
      label: '5',
      value: '5'
    },
    {
      label: '8',
      value: '8'
    },
    {
      label: '13',
      value: '13'
    },
    {
      label: '21',
      value: '21'
    },
    {
      label: '34',
      value: '34'
    }
  ];

  const scaleReplicas = async () => {
    if (replicas) {
      setReplicasLoading(true);
      try {
        await onClickScale(replicas);
        setOriginalReplicas(replicas);
      } catch (e) {
        console.log('Error scaling replicas', e);
      }
      setReplicasLoading(false);
    }
  };

  const listKafkaBrokers = (brokerString: string) => {
    const brokers = brokerString.split(',');
    const isMultiBroker = brokers.length > 1;

    return brokers.map((broker, index) => (
      <LineContainer key={index}>
        <div>Broker {isMultiBroker && `(${index + 1})`}</div>
        <Tooltip>
          <Text component={Tooltip.Trigger} className="fs-exclude">
            {truncateMiddle(broker, 55)}
          </Text>
          <Tooltip.Content side="bottom" className="fs-exclude">
            {broker}
          </Tooltip.Content>
        </Tooltip>
      </LineContainer>
    ));
  };

  return (
    <Flyout open onOpenChange={onOpenChange}>
      <Flyout.Content
        className="view-pipe-details"
        data-testid="pipe-details-rightbar"
        strategy="fixed"
        size="wide"
      >
        <Flyout.Header>
          <h2>{data.name}</h2>
          <Text color="muted">{data.description}</Text>
        </Flyout.Header>
        <Flyout.Body align="top">
          <Container style={{ padding: '0 1.5em' }}>
            <ElementContainer gap="md">
              <LineContainer wrap="wrap" gap="lg" alignItems="center">
                <Text weight="semibold" align="center">
                  Aggregation period
                </Text>
                <AggregationPeriodValue
                  as={Text}
                  weight="medium"
                  data-testid="aggregation-period-text"
                >
                  {getAggregationPeriod(period)}
                </AggregationPeriodValue>
                <Text weight="semibold" align="center">
                  Time period
                </Text>
                <Container maxWidth="30%">
                  <Select
                    data-testid="topic-select"
                    value={period}
                    onSelect={changePeriod}
                  >
                    <Select.Item value="Last hour">Last hour</Select.Item>
                    <Select.Item value="Last 24 hours">
                      Last 24 hours
                    </Select.Item>
                    <Select.Item value="Last week">Last week</Select.Item>
                    <Select.Item value="Last month">Last month</Select.Item>
                    <Select.Item value="Last year">Last year</Select.Item>
                  </Select>
                </Container>
              </LineContainer>
              <ElementContainer>
                <LineContainer>
                  <CustomMetricChartWithSummary
                    metric={clickpipes_sent_bytes_compressed.metricChartInput}
                    summaryInput={clickpipes_sent_bytes_compressed.summaryInput}
                  />
                </LineContainer>
              </ElementContainer>
            </ElementContainer>
          </Container>

          <Container>
            <div
              css={[
                flex,
                w100,
                alignCenter,
                spaceBetween,
                gapSm,
                pipeActionsWrapperStyles
              ]}
            >
              <Button fillWidth onClick={onClickViewData} iconRight="share">
                View Data
              </Button>
              <Button
                fillWidth
                disabled={isActionsDisabled(data.status)}
                onClick={() => resolveActionsDialog(data.status)}
              >
                {getActionsText(data.status)}
              </Button>
              <Button
                fillWidth
                type="danger"
                onClick={() => setDeleteDialogOpen(true)}
              >
                Remove
              </Button>
            </div>
          </Container>

          {isManualScalingEnabled && isKafkaSource(data.source) && (
            <Container style={{ padding: '0 1.5em 1.5em' }}>
              <ElementContainer data-testid="clickpipes-manual-scaling">
                <Text weight="bold">Replicas</Text>
                <ButtonGroup
                  fillWidth
                  onClick={function (value) {
                    setReplicas(parseInt(value));
                  }}
                  options={replicaOptions}
                  selected={replicas.toString()}
                  type="default"
                />
                {originalReplicas != replicas && (
                  <LineContainer>
                    <Text color="muted">
                      Changing the number of replicas may take a few minutes to
                      show effect.
                    </Text>
                    <Button
                      onClick={scaleReplicas}
                      label="Apply changes"
                      loading={isReplicasLoading}
                      showLabelWithLoading={true}
                    />
                  </LineContainer>
                )}
              </ElementContainer>
            </Container>
          )}

          {isManualS3ScalingEnabled && isObjectStorageSource(data.source) && (
            <Container style={{ padding: '0 1.5em 1.5em' }}>
              <ElementContainer data-testid="clickpipes-manual-scaling">
                <Text weight="bold">Concurrency</Text>
                <ButtonGroup
                  fillWidth
                  onClick={function (value) {
                    setReplicas(parseInt(value));
                  }}
                  options={concurrencyOptions}
                  selected={replicas.toString()}
                  type="default"
                />
                {originalReplicas != replicas && (
                  <LineContainer>
                    <Text color="muted">
                      Changing concurrency may take a few minutes to show
                      effect.
                    </Text>
                    <Button
                      onClick={scaleReplicas}
                      label="Apply changes"
                      loading={isReplicasLoading}
                      showLabelWithLoading={true}
                    />
                  </LineContainer>
                )}
              </ElementContainer>
            </Container>
          )}

          <TabsContainer as={Tabs} defaultValue="tab1">
            <Tabs.TriggersList aria-label="Manage your account">
              {/* TODO: check these errors later. It seems that TS is not liking the styled-components polymorphic key "as" */}
              {/* @ts-ignore */}
              <TabsTrigger
                as={Tabs.Trigger}
                value="tab1"
                grow="1"
                data-testid="clickpipes-detail-overview-tab"
              >
                Overview
              </TabsTrigger>
              <TabsTrigger
                as={Tabs.Trigger}
                value="tab2"
                data-testid="clickpipes-detail-configuration-tab"
              >
                Configuration
              </TabsTrigger>
              <TabsTrigger
                as={Tabs.Trigger}
                value="tab3"
                data-testid="clickpipes-detail-metrics-tab"
              >
                Metrics
              </TabsTrigger>
            </Tabs.TriggersList>
            <Flyout.Element grow="1" fillWidth overflow="auto">
              {/* TODO check these errors later. It seems that TS is not liking the styled-components polymorphic key "as" */}
              {/* @ts-ignore */}
              <TabsContent
                as={Tabs.Content}
                value="tab1"
                overflow="auto"
                data-testid="clickpipes-detail-overview-content"
              >
                <ElementContainer>
                  <LineContainer>
                    <div>ID</div>
                    <div>{data.id}</div>
                  </LineContainer>
                  <ElementContainer>
                    <LineContainer>
                      <div>Status</div>
                      <div onClick={goToErrorsTableIfFailedStatus}>
                        <StatusBadge
                          type={getClickPipeStatus(data, () =>
                            dbMetrics.getRecordCount(data)
                          )}
                        />
                      </div>
                    </LineContainer>

                    <LineContainer>
                      <div>Records</div>
                      <div>
                        <ClickPipeMetric
                          isInstanceAwake={isInstanceAwake}
                          value={dbMetrics.getRecordCount(data)}
                          displayFunc={formatNumber}
                        />
                      </div>
                    </LineContainer>

                    <LineContainer>
                      <div>Size of Data</div>
                      <div>
                        <ClickPipeMetric
                          isInstanceAwake={isInstanceAwake}
                          value={dbMetrics.getTableSize(data)}
                          displayFunc={formatDataSize}
                        />
                      </div>
                    </LineContainer>

                    <LineContainer>
                      <div>Error Count</div>
                      <div
                        onClick={goToErrorsTable}
                        css={errorsStyle}
                        role="button"
                        tabIndex={-1}
                      >
                        {isInstanceAwake ? (
                          <FormattedResultsValue
                            {...errorTableStatsResult}
                            valueExtractor={(rows): number => {
                              const value = rows?.[0]?.[0] ?? 0;
                              return Number(value);
                            }}
                            formatter={(value: number): string =>
                              formatNumber(value)
                            }
                          />
                        ) : (
                          <IdleClickPipeMetric />
                        )}
                      </div>
                    </LineContainer>

                    <Separator size={'sm'} />

                    <LineContainer>
                      <div>Last Updated</div>
                      <div>
                        <Tooltip>
                          <Tooltip.Trigger>
                            {prettifyTimeAgo(data.updatedAt)}
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {formatUtcTimestamp(
                              data.updatedAt,
                              'YYYY-MM-DD HH:mm:ss (UTC)'
                            )}
                          </Tooltip.Content>
                        </Tooltip>
                      </div>
                    </LineContainer>

                    <LineContainer>
                      <div>Created on</div>
                      <div>
                        <Tooltip>
                          <Tooltip.Trigger>
                            {formatTimeShort(data.createdAt)}
                          </Tooltip.Trigger>
                          <Tooltip.Content>
                            {formatUtcTimestamp(
                              data.createdAt,
                              'YYYY-MM-DD HH:mm:ss (UTC)'
                            )}
                          </Tooltip.Content>
                        </Tooltip>
                      </div>
                    </LineContainer>

                    {data.destination.username && (
                      // `username` should always exist - but because we do a split operation, we need to check
                      // This will display as `default` in local env. See https://github.com/ClickHouse/control-plane/issues/6514
                      <LineContainer>
                        <div>Created by</div>
                        <div>{data.destination.username.split(':').at(-1)}</div>
                      </LineContainer>
                    )}

                    {isInstanceAwake &&
                      isObjectStorageSource(data.source) &&
                      data.status === 'Running' && (
                        <LineContainer hasBorder>
                          <div>Objects identified</div>
                          <div>
                            {isInstanceAwake ? (
                              <FormattedResultsValue
                                {...S3FileStatsResult}
                                valueExtractor={(rows): number => {
                                  const value = rows?.[0]?.[0] ?? 0;
                                  return Number(value);
                                }}
                                formatter={(value: number): string =>
                                  formatNumber(value)
                                }
                              />
                            ) : (
                              <IdleClickPipeMetric />
                            )}
                          </div>
                        </LineContainer>
                      )}
                  </ElementContainer>
                </ElementContainer>
              </TabsContent>
              <TabsContent
                as={Tabs.Content}
                value="tab2"
                data-testid="clickpipes-detail-configuration-content"
              >
                <ElementContainer gap="md">
                  <ElementContainer>
                    <Text weight="bold">Source</Text>
                    {isKafkaSource(data.source) && (
                      <>
                        {listKafkaBrokers(data.source.metadata.brokers)}
                        <LineContainer>
                          <div>Group</div>
                          <div className="fs-exclude">
                            {data.source.metadata.group}
                          </div>
                        </LineContainer>

                        <LineContainer>
                          <div>Secure</div>
                          <div>{data.source.metadata.secure}</div>
                        </LineContainer>

                        <LineContainer>
                          <div>Topics</div>
                          <div className="fs-exclude">
                            {data.source.metadata.topics}
                          </div>
                        </LineContainer>
                      </>
                    )}

                    {isKinesisSource(data.source) && (
                      <>
                        <LineContainer>
                          <div>Region</div>
                          <div>{data.source.metadata.region}</div>
                        </LineContainer>
                        <LineContainer>
                          <div>Stream Name</div>
                          <div>{data.source.metadata.streamName}</div>
                        </LineContainer>
                      </>
                    )}

                    {isObjectStorageSource(data.source) && (
                      <>
                        <LineContainer>
                          <div>URL</div>
                          <div>
                            <Tooltip>
                              <EllipsisContent component={Tooltip.Trigger}>
                                {truncateMiddle(data.source.metadata.url, 55)}
                              </EllipsisContent>
                              <Tooltip.Content side="bottom">
                                {data.source.metadata.url}
                              </Tooltip.Content>
                            </Tooltip>
                          </div>
                        </LineContainer>
                        <LineContainer>
                          <div>Continuous Ingestion</div>
                          <div>
                            {(() => {
                              const continuousIngestion =
                                data.source.metadata.continuousIngestion;
                              if (continuousIngestion === 'true') {
                                return 'On';
                              }
                              return 'Off';
                            })()}
                          </div>
                        </LineContainer>
                      </>
                    )}

                    <LineContainer>
                      <div>Type</div>
                      <div>{getClickPipeTypeName(data.source.type).name}</div>
                    </LineContainer>
                    <LineContainer>
                      <div>Format</div>
                      <div>
                        {formatToHumanReadable(data.sourceSchema.format)}
                      </div>
                    </LineContainer>
                  </ElementContainer>

                  <ElementContainer>
                    <Text weight="bold">Destination</Text>
                    <LineContainer>
                      <div>Address</div>
                      <div>{data.destination.addr}</div>
                    </LineContainer>
                    <LineContainer>
                      <div>Database</div>
                      <div className="fs-exclude">
                        {data.destination.database}
                      </div>
                    </LineContainer>

                    <LineContainer>
                      <div>Table</div>
                      <div className="fs-exclude">{data.destination.table}</div>
                    </LineContainer>

                    <LineContainer>
                      <div>Table Engine</div>
                      <div>{data.destination.tableEngine}</div>
                    </LineContainer>
                    {Boolean(data.destination.tablePartitionKey) && (
                      <LineContainer>
                        <div>Partition Key</div>
                        <div className="fs-exclude">
                          {data.destination.tablePartitionKey}
                        </div>
                      </LineContainer>
                    )}
                    {Boolean(data.destination.tableStoringKey) && (
                      <LineContainer>
                        <div>Storing Key</div>
                        <div className="fs-exclude">
                          {data.destination.tableStoringKey}
                        </div>
                      </LineContainer>
                    )}
                  </ElementContainer>

                  <ElementContainer>
                    <Text weight="bold">Fields</Text>
                    <FieldColumn
                      sourceFields={data.sourceSchema.fields}
                      destinationFields={data.destinationSchema.fields}
                      fieldMapping={data.fieldMapping}
                    />
                  </ElementContainer>
                  <ElementContainer>
                    <Text weight="bold">Settings</Text>
                    <LineContainer>
                      <div>Permissions</div>
                      <div className="fs-exclude">
                        {
                          // '-' for already running clickpipes.
                          data.ingestRoleId ? data.ingestRoleId.join(', ') : '-'
                        }
                      </div>
                    </LineContainer>
                  </ElementContainer>
                </ElementContainer>
              </TabsContent>
              <Tabs.Content
                value="tab3"
                data-testid="clickpipes-detail-metrics-content"
              >
                <ElementContainer gap="md">
                  <Container />
                  <ElementContainer>
                    <LineContainer>
                      <CustomMetricChartWithSummary
                        metric={clickpipes_fetched_events.metricChartInput}
                        summaryInput={clickpipes_fetched_events.summaryInput}
                      />
                    </LineContainer>
                  </ElementContainer>
                  <ElementContainer>
                    {/* Using customized container to make bottom text closer to the chart */}
                    <Container gap="none" orientation="vertical">
                      <CustomMetricChartWithSummary
                        metric={clickpipes_errors_metric.metricChartInput}
                        summaryInput={clickpipes_errors_metric?.summaryInput}
                      />
                      <Text color="muted">
                        Errors can be related to connectivity issues or
                        malformed data.{' '}
                        <Link onClick={goToErrorsTable}>See errors table.</Link>
                      </Text>
                    </Container>
                  </ElementContainer>
                  {isLatencyChartEnabled &&
                    (isKafkaSource(data.source) ||
                      isKinesisSource(data.source)) && (
                      <ElementContainer>
                        <LineContainer>
                          <CustomMetricChartWithSummary
                            metric={clickpipes_latency.metricChartInput}
                            summaryInput={clickpipes_latency?.summaryInput}
                          />
                        </LineContainer>
                      </ElementContainer>
                    )}
                </ElementContainer>
              </Tabs.Content>
            </Flyout.Element>
          </TabsContainer>
        </Flyout.Body>
      </Flyout.Content>

      <ClickPipeDeleteDialog
        open={deleteDialogOpen}
        pipeId={data.id}
        name={data.name}
        onDelete={onClickRemove}
        onCancel={(): void => {
          setDeleteDialogOpen(false);
        }}
        data-testid="delete-clickpipe-rightbar-modal"
      />

      <ClickPipeActionDialog
        type={'pause'}
        open={pauseDialogOpen}
        pipeId={data.id}
        name={data.name}
        onAction={onClickAction}
        onCancel={(): void => {
          setPauseDialogOpen(false);
        }}
        data-testid="pause-clickpipe-modal"
      />

      <ClickPipeActionDialog
        type={'resume'}
        open={resumeDialogOpen}
        pipeId={data.id}
        name={data.name}
        onAction={onClickAction}
        onCancel={(): void => {
          setResumeDialogOpen(false);
        }}
        data-testid="resume-clickpipe-modal"
      />

      <ClickPipeActionDialog
        type={'retry'}
        open={retryDialogOpen}
        pipeId={data.id}
        name={data.name}
        onAction={onClickAction}
        onCancel={(): void => {
          setRetryDialogOpen(false);
        }}
        data-testid="retry-clickpipe-modal"
      />
    </Flyout>
  );
}

export default PipeDetailsRightBar;
