import {
  Button,
  Container,
  Icon,
  Spacer,
  Text as CUIText,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';

export const EmptyDataWarehouse = ({
  showCreateButton
}: {
  showCreateButton: boolean;
}): ReactElement => {
  const onCreateService = (): void => {
    navigateTo(routes.createNewService());
  };

  return (
    <Container
      orientation="vertical"
      alignItems="center"
      gap="xs"
      padding="xl"
      data-testid="no-warehouse-message"
    >
      <Spacer size="xxl" />
      <Container
        orientation="vertical"
        padding="lg"
        alignItems="center"
        maxWidth="415px"
        gap="xs"
      >
        <Icon name="clickhouse" height="80px" width="80px" />
        <Title type="h1" size="md">
          No ClickHouse warehouses
        </Title>
        <Spacer size="sm" />
        <CUIText align="center">
          Get started by creating a high-powered serverless ClickHouse database
          in minutes.
        </CUIText>
        <Spacer size="sm" />
        {showCreateButton && (
          <Button
            label="Create new service"
            iconLeft="plus"
            onClick={onCreateService}
            data-testid="create-service-button"
          />
        )}
      </Container>
    </Container>
  );
};
