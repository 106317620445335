import { ReactElement, useState } from 'react';
import {
  Button,
  ConfirmationDialog,
  Container,
  createToast,
  Text
} from '@clickhouse/click-ui';
import { DashboardObjectSchema } from 'shared/src/dashboard';
import {
  DashboardConfig,
  DashboardConfigField,
  DashboardFilterConfig,
  DashboardFilterType,
  DashboardObjectConfig
} from 'shared/src/types/dashboard';
import QueryIdField from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/QueryIdField';
import StringField from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/StringField';
import GeneralChartConfig from 'src/components/ChartConfigSidebar/GeneralChartConfig';
import {
  useDashboardObjectState,
  useUpdateDashboardFilterOutput,
  useUpdateDashboardFilterState
} from 'src/state/dashboard/dashboardState';
import { convertQueryResultToChartData } from 'src/lib/chart';
import { QueryResult } from 'src/lib/clickhouse/query';
import ElementIdField from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/ElementIdField';
import {
  buildFilterConfigUpdater,
  buildObjectConfigUpdater,
  UpdateDashboardFilterConfigFunc,
  useComposeUpdateDashboardConfig
} from 'src/dashboard/dashboardController';
import {
  useCancel,
  useInlineQueryEdit,
  useQuerySelect,
  useStringConfirm
} from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/hooks';

type OnConfirmArgs = {
  defaultValue: string;
  filterType: DashboardFilterType;
};
export type OnFilterConfirm = (args: OnConfirmArgs) => void;

interface ConfigSchemaProps {
  dashboardConfig: DashboardConfig;
  dashboardId: string;
  dashboardObjectConfig: DashboardObjectConfig;
  deleteDashboardObject: () => void;
  editingObjectId: string;
  schema: DashboardObjectSchema;
  updateDashboardConfig: (
    config: DashboardObjectConfig
  ) => Promise<DashboardObjectConfig | null>;
}

export default function ConfigSchema({
  dashboardConfig,
  dashboardId,
  dashboardObjectConfig,
  deleteDashboardObject,
  editingObjectId,
  schema,
  updateDashboardConfig
}: ConfigSchemaProps): ReactElement {
  const [tentativeChanges, setTentativeChanges] = useState(
    dashboardObjectConfig
  );

  const composeUpdateDashboardConfig = useComposeUpdateDashboardConfig();
  const updateState = useUpdateDashboardFilterState({
    dashboardId
  });
  const updateOutput = useUpdateDashboardFilterOutput({
    dashboardId
  });

  const [deleteConfirmationIsVisible, setDeleteConfirmationIsVisible] =
    useState<boolean>(false);

  const state = useDashboardObjectState({
    dashboardId,
    objectId: editingObjectId
  });

  const onFieldChange = <T,>(field: string, value: T): void => {
    setTentativeChanges((currentChanges) => ({
      ...currentChanges,
      [field]: value
    }));
  };

  const onUpdateConfig = async (
    changes: DashboardObjectConfig
  ): Promise<void> => {
    try {
      const newConfig = await updateDashboardConfig(changes);
      if (newConfig) {
        setTentativeChanges(newConfig);
      }
    } catch (e) {
      console.error(e);

      if (e instanceof Error) {
        createToast({
          title: 'Error updating config',
          type: 'danger',
          description: e.message
        });
      }
    }
  };

  const onParameterChange = (
    parameter: string,
    value: DashboardConfigField<string>
  ): void => {
    const newParameters: Record<string, DashboardConfigField<string>> = {
      ...tentativeChanges.parameters,
      [parameter]: value
    };

    onFieldChange('parameters', newParameters);
  };

  const onParameterConfirm = async (
    parameter: string,
    changes?: DashboardConfigField<string>
  ): Promise<void> => {
    if (changes) {
      const newParameters: Record<string, DashboardConfigField<string>> = {
        ...tentativeChanges.parameters,
        [parameter]: changes
      };

      onFieldChange('parameters', newParameters);
      return onUpdateConfig({
        ...tentativeChanges,
        parameters: newParameters
      });
    } else {
      return onUpdateConfig(tentativeChanges);
    }
  };

  const onCancel = useCancel({
    dashboardObjectConfig,
    onFieldChange,
    onUpdateConfig,
    tentativeChanges
  });

  const onInlineQueryEdit = useInlineQueryEdit({
    dashboardConfig,
    dashboardId,
    editingObjectId,
    onFieldChange,
    tentativeChanges
  });

  const onQuerySelect = useQuerySelect({
    dashboardConfig,
    dashboardId,
    editingObjectId,
    onFieldChange,
    tentativeChanges
  });

  const onStringConfirm = useStringConfirm({
    onUpdateConfig,
    tentativeChanges
  });

  const onUpdateFilterConfig: UpdateDashboardFilterConfigFunc = async (
    filterKey: string,
    config: DashboardFilterConfig
  ): Promise<void> => {
    try {
      updateState(filterKey, {
        value: config.defaultValue
      });
      updateOutput(filterKey, {
        value: config.defaultValue
      });

      const newParameters: Record<string, DashboardConfigField<string>> = {
        ...tentativeChanges.parameters,
        [filterKey]: { type: 'filter' }
      };

      onFieldChange('parameters', newParameters);

      await composeUpdateDashboardConfig(dashboardConfig, [
        buildFilterConfigUpdater({
          [filterKey]: config
        }),
        buildObjectConfigUpdater(editingObjectId, {
          ...tentativeChanges,
          parameters: {
            ...tentativeChanges.parameters,
            [filterKey]: { type: 'filter' }
          }
        })
      ]);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        createToast({
          title: 'Error updating filter config',
          type: 'danger',
          description: e.message
        });
      }
      return;
    }
  };

  const showConfirmDeleteDialog = (): void => {
    setDeleteConfirmationIsVisible(true);
  };

  const hideConfirmDeleteDialog = (): void => {
    setDeleteConfirmationIsVisible(false);
  };

  const schemaFields = Object.keys(schema).map((field) => {
    const fieldSchema = schema[field];

    switch (fieldSchema.type) {
      case 'chartConfig': {
        if (dashboardObjectConfig.type !== 'chart') {
          return <Container>Invalid chart config schema</Container>;
        }

        const chartData = convertQueryResultToChartData(
          state.data as QueryResult
        );

        return (
          <GeneralChartConfig
            chartConfig={dashboardObjectConfig.chartConfig}
            data={chartData}
            loading={!!state.loading}
            portalId="dashboard-main-view"
            type={
              typeof dashboardObjectConfig.chartConfig.chartType === 'string'
                ? dashboardObjectConfig.chartConfig['chartType']
                : 'bar'
            }
            updateChart={(chartConfig) => {
              void onUpdateConfig({
                ...tentativeChanges,
                [field]: chartConfig
              });
            }}
          />
        );
      }
      case 'id': {
        return (
          <ElementIdField
            onChange={(value) => {
              onFieldChange(field, value);
            }}
            onConfirm={() => {
              void onUpdateConfig(tentativeChanges);
            }}
            value={tentativeChanges.elementId}
          />
        );
      }
      case 'queryId': {
        if (
          tentativeChanges.type === 'bigStat' ||
          tentativeChanges.type === 'chart' ||
          tentativeChanges.type === 'table'
        ) {
          return (
            <QueryIdField
              config={dashboardConfig}
              dashboardId={dashboardId}
              editingObjectId={editingObjectId}
              label={fieldSchema.label}
              key={field}
              onInlineQueryEdit={onInlineQueryEdit}
              onParameterCancel={() => {
                onCancel('parameters');
              }}
              onParameterChange={onParameterChange}
              onParameterConfirm={onParameterConfirm}
              onParameterFilterConfirm={onUpdateFilterConfig}
              onSelect={(value) => onQuerySelect(field, value)}
              parameters={tentativeChanges.parameters}
              value={
                tentativeChanges[
                  field as keyof DashboardObjectConfig
                ] as unknown as string
              }
            />
          );
        } else {
          return <Text>Invalid Config</Text>;
        }
      }
      case 'string':
      default: {
        const value = tentativeChanges[
          field as keyof DashboardObjectConfig
        ] as unknown as DashboardConfigField<string>;
        const linkable =
          fieldSchema.type === 'string' ? fieldSchema.linkable : false;

        return (
          <StringField
            config={dashboardConfig}
            dashboardId={dashboardId}
            editingObjectId={editingObjectId}
            field={field}
            filterable={false}
            label={fieldSchema.label}
            linkable={!!linkable}
            key={field}
            onCancel={() => onCancel(field)}
            onChange={(val) => {
              onFieldChange(field, val);
            }}
            onConfirm={(changes) => onStringConfirm(field, changes)}
            value={value}
          />
        );
      }
    }
  });

  return (
    <Container orientation="vertical" gap="md" minHeight="100%">
      {schemaFields}
      <Button fillWidth={true} onClick={showConfirmDeleteDialog} type="danger">
        Delete
      </Button>
      <ConfirmationDialog
        onCancel={hideConfirmDeleteDialog}
        onConfirm={deleteDashboardObject}
        open={deleteConfirmationIsVisible}
        primaryActionLabel="Delete"
        secondaryActionLabel="Cancel"
        showClose={true}
        title={`Delete ${dashboardObjectConfig.elementId}?`}
      >
        Deleting this dashboard element will permanently remove all its data.
      </ConfirmationDialog>
    </Container>
  );
}
