import { ReactElement } from 'react';
import Helmet from 'src/components/Helmet/Helmet';
import HomeView from 'src/components/HomeView';

const HomePage = (): ReactElement => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <HomeView />
    </>
  );
};

export default HomePage;
