import { MutableRefObject, useCallback, useLayoutEffect, useEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

type CallbackFunction<Args extends any[], Result> = (...args: Args) => Result;

// Similar to useCallback. Has no dependencies and returns a function that never
// changes, but always results on the latest function passed into the hook being
// called. Should be used in callbacks and _not_ in rendering since updates to
// the function will never trigger a rerender
export function useRefCallback<Args extends any[], Result>(
  callback: CallbackFunction<Args, Result>
): CallbackFunction<Args, Result> {
  const ref = useRef(callback);
  useEffect(() => {
    ref.current = callback;
  }, [callback]);
  return useCallback((...args: Args) => {
    return ref.current(...args);
  }, []);
}

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;
type SomePromiseFunction<T> = (...args: any[]) => Promise<T>;

interface Flushable {
  flush: () => void;
}

/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */
export function useDebounceFunction<Func extends SomeFunction>(func: Func, delay = 1000): Func & Flushable {
  const timer = useRef<Timer>();
  const latestCallRef = useRef<() => void>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction: Func = ((...args): void => {
    latestCallRef.current = () => {
      func(...args);
    };
    const newTimer = setTimeout(latestCallRef.current, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as Func;

  return Object.assign(debouncedFunction, {
    flush: () => {
      clearTimeout(timer.current);
      latestCallRef.current?.();
      latestCallRef.current = undefined;
    }
  });
}

/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */

export function useDebouncePromise<T, Func extends SomePromiseFunction<T>>(func: Func, delay = 1000): Func {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction: Func = ((...args): Promise<T> => {
    return new Promise((resolve, reject) => {
      const newTimer = setTimeout(() => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, delay);
      clearTimeout(timer.current);
      timer.current = newTimer;
    });
  }) as Func;

  return debouncedFunction;
}

interface Size {
  width: number;
  height: number;
}

export default function useElementSize<T extends HTMLElement = HTMLDivElement>(): [MutableRefObject<T | null>, Size] {
  const target = useRef<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    target.current && setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry: ResizeObserverEntry) => {
    setSize(entry.contentRect);
  });

  return [target, size];
}

export function useVisited(): [visited: boolean, visit: () => void] {
  const [visited, setVisited] = useState(false);

  const visit = useCallback(() => {
    setVisited(true);
  }, []);

  return [visited, visit];
}
