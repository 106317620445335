import { Container, Spacer } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { MediumWidthContainer } from 'src/components/MediumWidthContainer';

import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import SplitLayout from 'src/layout/SplitLayout';
import { SectionSeparator } from 'src/lib/pageElements';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { ActionsSection } from 'src/pages/SettingsPage/ActionsSection';
import { BackupsConfigurationSection } from 'src/pages/SettingsPage/Backups/BackupsConfigurationSection';
import { HeaderSection } from 'src/pages/SettingsPage/HeaderSection';
import { NetworkingSection } from 'src/pages/SettingsPage/NetworkingSection';
import { ReleasesSection } from 'src/pages/SettingsPage/ReleaseSection';
import { ScalingControlsSection } from 'src/pages/SettingsPage/ScalingControlsSection';

const SettingsPage = (): ReactElement => {
  const currentUserRole = useCurrentOrgUserRole();
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    currentUserRole === 'ADMIN'
  );
  const instance = useCurrentInstanceOrThrow();

  const showReleaseSection =
    hasManageServicePermission && instance.instanceTier === 'Production';

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Service Settings" />
      <MainPaneLayout.Content>
        <SplitLayout>
          <Container orientation="vertical" padding="lg">
            <HeaderSection />
            <Spacer size="xl" />
            <MediumWidthContainer
              orientation="vertical"
              padding="none"
              data-testid="settings-page"
              grow="1"
            >
              <ActionsSection />
              <SectionSeparator />
              <ScalingControlsSection />
              <SectionSeparator />
              {instance.isPrimary && (
                <>
                  <BackupsConfigurationSection />
                  <SectionSeparator />
                </>
              )}
              {showReleaseSection && (
                <>
                  <ReleasesSection />
                  <SectionSeparator />
                </>
              )}
              <NetworkingSection title="Security" />
              <Spacer size="xxl" />
            </MediumWidthContainer>
          </Container>
        </SplitLayout>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default SettingsPage;
