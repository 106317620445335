import { css, SerializedStyles, Theme } from '@emotion/react';

export default {
  container: css`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 56px auto auto 1fr;
    grid-template-columns: 100%;
  `,
  databaseSelectorRow: (theme: Theme): SerializedStyles => css`
    border-bottom: 1px solid ${theme.global.color.stroke.default};
    display: grid;
    place-items: center;
    padding: 0 1rem;
  `
};
