import { AxisColumn, ChartConfig } from 'src/lib/chart/types';

import { ChartData, getColumnType } from 'src/lib/chart/';

function axisColumnName(axisColumn: string | Record<string, string>) {
  if (typeof axisColumn === 'string') {
    return axisColumn;
  } else if (typeof axisColumn?.column === 'string') {
    return axisColumn.column;
  } else {
    throw new Error('unexpected axis value');
  }
}

function axisColumnNames(axis: AxisColumn | AxisColumn[] | undefined | null) {
  if (axis === undefined || axis === null) {
    return [];
  } else if (Array.isArray(axis)) {
    return axis.map(axisColumnName);
  } else {
    return [axisColumnName(axis)];
  }
}

function generateAxisRangeConfig(config: ChartConfig, axis: AxisColumn | AxisColumn[], name: string, label: string) {
  const axisNames = axisColumnNames(axis);
  if (axisNames.length > 0) {
    config[name] = {
      label: `${label} range`,
      type: 'rangeList',
      event: 'axisRange',
      columns: axisNames
    };
  }
}

export const appearanceConfig = (state: ChartConfig): Record<string, unknown> => {
  const config: ChartConfig = {};

  if (!state) {
    return config;
  }

  if (state.category === 'chart' && ['line', 'scatter', 'area', 'bar', 'hbar'].includes(state.chartType as string)) {
    generateAxisRangeConfig(config, state.xAxis as AxisColumn | AxisColumn[], 'xAxisRange', 'x-axis');
    generateAxisRangeConfig(config, state.yAxis as AxisColumn | AxisColumn[], 'yAxisRange', 'y-axis');
    generateAxisRangeConfig(config, state.zAxis as AxisColumn | AxisColumn[], 'zAxisRange', 'z-axis');
  }

  let type;

  if (state.data) {
    if (state.yAxis) {
      type = getColumnType(state.data as unknown as ChartData, state.yAxis as string);
    } else if (state.xAxis) {
      type = getColumnType(state.data as unknown as ChartData, state.xAxis as string);
    } else if (state.zAxis) {
      type = getColumnType(state.data as unknown as ChartData, state.zAxis as string);
    } else if (state.categories) {
      type = getColumnType(state.data as unknown as ChartData, state.categories as string);
    } else if (state.dataKey) {
      type = getColumnType(state.data as unknown as ChartData, state.dataKey as string);
    }
  }

  if (type === 'date') {
    config.dateFormat = {
      label: 'Date Format',
      type: 'string',
      linkable: true
    };
  }

  let showLegendInput = false;

  if (Array.isArray(state.xAxis) && state.xAxis.length > 1) {
    showLegendInput = true;
  } else if (Array.isArray(state.yAxis) && state.yAxis.length > 1) {
    showLegendInput = true;
  } else if (Array.isArray(state.zAxis) && state.zAxis.length > 1) {
    showLegendInput = true;
  } else if (Array.isArray(state.categories) && state.categories.length > 1) {
    showLegendInput = true;
  }

  if (showLegendInput) {
    config.showLegend = {
      label: 'Show legend',
      type: 'boolean'
    };
  }

  return config;
};

export const fontOptions = [
  {
    label: 'Inter',
    value: 'Inter'
  },
  {
    label: 'cursive',
    value: 'cursive'
  },
  {
    label: 'fantasy',
    value: 'fantasy'
  },
  {
    label: 'monospace',
    value: 'monospace'
  },
  {
    label: 'sans-serif',
    value: 'sans-serif'
  }
];

export type ChartSchemaAxisConfig = {
  dataType: 'any' | 'number' | 'string' | 'date';
  label: string;
  required?: boolean;
  type: 'column' | 'columnArray';
};

type ChartSchemaField = {
  label: string;
  type: 'boolean' | 'number' | 'select' | 'string';
  event?: string;
  options?: {
    label: string;
    value: string;
  }[];
};

export interface ChartSchemaData {
  categories?: ChartSchemaAxisConfig;
  dataKey?: ChartSchemaAxisConfig;
  values?: ChartSchemaAxisConfig;
  xAxis?: ChartSchemaAxisConfig;
  yAxis?: ChartSchemaAxisConfig;
  yValue?: ChartSchemaAxisConfig;
  zAxis?: ChartSchemaAxisConfig;
}

interface ChartSchema {
  label: string;
  orientation?: 'flipped';
  appearance: {
    font?: ChartSchemaField;
    showDataLabel?: ChartSchemaField;
    showLegend?: ChartSchemaField;
    size?: ChartSchemaField;
    weight?: ChartSchemaField;
    xAxisLabel?: ChartSchemaField;
    xAxisTickVertical?: ChartSchemaField;
    yAxisLabel?: ChartSchemaField;
    yValueLabel?: ChartSchemaField;
  };
  data: ChartSchemaData;
  defaults: {
    appearance: {
      dateFormat?: string;
      font?: string;
      showDataLabel?: boolean;
      showLegend?: boolean;
      size?: number;
      weight?: string;
      xAxisLabel?: string;
      xAxisTickVertical?: boolean;
      yAxisLabel?: string;
      yAxisTickVertical?: boolean;
    };
  };
}

export type ChartMap = Record<string, ChartSchema>;

export const chartMap: ChartMap = {
  bar: {
    label: 'Bar',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'any',
        label: 'x-axis',
        type: 'column'
      },
      yAxis: {
        dataType: 'number',
        label: 'y-axis',
        type: 'columnArray',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  hbar: {
    label: 'Horizontal bar',
    orientation: 'flipped',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'number',
        label: 'x-axis',
        type: 'columnArray',
        required: true
      },
      yAxis: {
        dataType: 'any',
        label: 'y-axis',
        type: 'column'
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  sbar: {
    label: 'Stacked bar',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'any',
        label: 'x-axis',
        type: 'column'
      },
      yAxis: {
        dataType: 'number',
        label: 'y-axis',
        type: 'columnArray',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  shbar: {
    label: 'Stacked horizontal bar',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'number',
        label: 'x-axis',
        type: 'columnArray',
        required: true
      },
      yAxis: {
        dataType: 'any',
        label: 'y-axis',
        type: 'column'
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  line: {
    label: 'Line',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'any',
        label: 'x-axis',
        type: 'column'
      },
      yAxis: {
        dataType: 'number',
        label: 'y-axis',
        type: 'columnArray',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  area: {
    label: 'Area',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'any',
        label: 'x-axis',
        type: 'column'
      },
      yAxis: {
        dataType: 'number',
        label: 'y-axis',
        type: 'columnArray',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  scatter: {
    label: 'Scatter',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'any',
        label: 'x-axis',
        type: 'column'
      },
      yAxis: {
        dataType: 'number',
        label: 'y-axis',
        type: 'columnArray',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  pie: {
    label: 'Pie',
    appearance: {
      showLegend: {
        label: 'Show legend',
        type: 'boolean'
      }
    },
    data: {
      categories: {
        dataType: 'any',
        label: 'category',
        type: 'column'
      },
      values: {
        dataType: 'number',
        label: 'values',
        type: 'column',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  doughnut: {
    label: 'Doughnut',
    appearance: {
      showLegend: {
        label: 'Show legend',
        type: 'boolean'
      }
    },
    data: {
      categories: {
        dataType: 'any',
        label: 'category',
        type: 'column'
      },
      values: {
        dataType: 'number',
        label: 'values',
        type: 'column',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY',
        showLegend: true
      }
    }
  },
  heatMap: {
    label: 'Heat map',
    appearance: {
      xAxisLabel: {
        label: 'x-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      xAxisTickVertical: {
        label: 'Vertical x-axis Labels',
        event: 'verticalLabel',
        type: 'boolean'
      },
      yAxisLabel: {
        label: 'y-axis title',
        event: 'axisTitle',
        type: 'string'
      },
      yValueLabel: {
        label: 'y-value title',
        type: 'string'
      },
      showDataLabel: {
        label: 'Show data labels',
        event: 'dataLabel',
        type: 'boolean'
      }
    },
    data: {
      xAxis: {
        dataType: 'any',
        label: 'x-axis',
        type: 'column'
      },
      yAxis: {
        dataType: 'any',
        label: 'y-axis',
        type: 'column',
        required: true
      },
      zAxis: {
        dataType: 'number',
        label: 'heat',
        type: 'column',
        required: true
      }
    },
    defaults: {
      appearance: {
        dateFormat: 'MMMM D, YYYY'
      }
    }
  }
};
