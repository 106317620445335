import { assertTruthy } from '@cp/common/utils/Assert';
import { QueryEndpoint } from '@prisma/client';
import { createToast } from 'primitives';
import { useCallback, useEffect, useState } from 'react';
import { useCurrentInstance } from 'src/instance/instanceController';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useDebounceFunction } from 'src/lib/hooks';
import { QueryEndpointValidationError } from 'src/queryEndpoints/queryEndpointApiClient';

export type UseShareModalQueryEndpointsResult = {
  validationError: QueryEndpointValidationError | null;
  queryEndpoint: QueryEndpoint | null;
  initialLoading: boolean;
  loading: boolean;
  createEndpoint: (
    keyId: string,
    openApiKeys: Array<string>,
    roles: Array<string>,
    allowedOrigins: string
  ) => Promise<void>;
  updateEndpoint: (keyIds: Array<string>, roles: Array<string>, allowedOrigins: string) => Promise<void>;
  disableEndpoint: () => Promise<void>;
  addKey: (keyId: string) => Promise<void>;
  removeKey: (keyId: string) => Promise<void>;
  roles: Array<string>;
  setRoles: (roles: Array<string>) => Promise<void>;
  allowedOrigins: string;
  setAllowedOrigins: (allowedOrigins: string) => Promise<void>;
};

export function useShareModalQueryEndpoints(queryId: string): UseShareModalQueryEndpointsResult {
  const apiClient = useApiClient();
  const currentInstance = useCurrentInstance();
  const [validationError, setValidationError] = useState<QueryEndpointValidationError | null>(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [queryEndpoint, setQueryEndpoint] = useState<QueryEndpoint | null>(null);

  const [roles, setRoles] = useState<string[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [allowedOrigins, setAllowedOrigins] = useState<string>('');

  useEffect(() => {
    const loadQueryEndpoint = async (serviceId: string, newQueryId: string): Promise<void> => {
      const queryEndpoints = await apiClient.queryEndpoints.listQueryEndpoints({
        serviceId,
        queryId: newQueryId,
        status: 'active'
      });

      if (queryEndpoints.length > 0) {
        setQueryEndpoint(queryEndpoints[0]);
        setKeys(queryEndpoints[0].openApiKeys);
        setRoles(queryEndpoints[0].roles);
        setAllowedOrigins(queryEndpoints[0].allowedOrigins ?? '');
      }
      setInitialLoading(false);
    };

    if (currentInstance?.id) {
      loadQueryEndpoint(currentInstance.id, queryId).catch(console.error);
    }
  }, [currentInstance?.id, queryId, apiClient.queryEndpoints]);

  const createEndpoint = useCallback(
    async (
      newQueryId: string,
      newOpenApiKeys: Array<string>,
      newRoles: Array<string>,
      newAllowedOrigins: string
    ): Promise<void> => {
      setLoading(true);
      setValidationError(null);

      try {
        const [successful, queryEndpointOrValidationError] = await apiClient.queryEndpoints.createQueryEndpoint({
          queryId: newQueryId,
          openApiKeys: newOpenApiKeys,
          roles: newRoles,
          allowedOrigins: newAllowedOrigins
        });

        if (successful) {
          setQueryEndpoint(queryEndpointOrValidationError);
        } else {
          setValidationError(queryEndpointOrValidationError);
        }
      } catch (e) {
        setQueryEndpoint(null);
        createToast('Error creating query endpoint', 'danger', e instanceof Error ? e.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    },
    [apiClient.queryEndpoints]
  );

  const debouncedUpdateEndpoint = useDebounceFunction(
    async (newKeys: Array<string>, newRoles: Array<string>, newAllowedOrigins: string): Promise<void> => {
      setLoading(true);
      setValidationError(null);
      assertTruthy(queryEndpoint, 'Query endpoint not found');

      try {
        const [successful, queryEndpointOrValidationError] = await apiClient.queryEndpoints.updateQueryEndpoint({
          endpointId: queryEndpoint.id,
          openApiKeys: newKeys,
          roles: newRoles,
          allowedOrigins: newAllowedOrigins
        });

        if (successful) {
          setQueryEndpoint(queryEndpointOrValidationError);
        } else {
          setValidationError(queryEndpointOrValidationError);
        }
      } catch (e) {
        createToast('Error updating query endpoint', 'danger', e instanceof Error ? e.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    },
    250
  );

  const updateEndpoint = useCallback(debouncedUpdateEndpoint, [apiClient.queryEndpoints, queryEndpoint]);

  const addKey = useCallback(
    async (keyId: string): Promise<void> => {
      const newKeys = [...keys, keyId];
      setKeys(newKeys);

      if (queryEndpoint) {
        try {
          await updateEndpoint(newKeys, roles, allowedOrigins);
        } catch (error) {
          // we restore the previous state
          setKeys(keys.filter((k) => k !== keyId));
          throw error;
        }
      } else {
        try {
          await createEndpoint(queryId, newKeys, roles, allowedOrigins);
        } catch (error) {
          // we restore the previous state
          setKeys(keys.filter((k) => k !== keyId));
          throw error;
        }
      }
    },
    [keys, queryEndpoint, updateEndpoint, roles, allowedOrigins, createEndpoint, queryId]
  );

  const setOrigins = useCallback(
    async (newAllowedOrigins: string): Promise<void> => {
      setAllowedOrigins(newAllowedOrigins);

      if (queryEndpoint) {
        try {
          await updateEndpoint(keys, roles, newAllowedOrigins);
        } catch (error) {
          // we restore the previous state
          setAllowedOrigins(allowedOrigins ?? '');
          throw error;
        }
      } else {
        try {
          await createEndpoint(queryId, keys, roles, newAllowedOrigins);
        } catch (error) {
          // we restore the previous state
          setAllowedOrigins(allowedOrigins ?? '');
          throw error;
        }
      }
    },
    [keys, queryEndpoint, updateEndpoint, roles, allowedOrigins, createEndpoint, queryId]
  );

  const removeKey = useCallback(
    async (keyId: string): Promise<void> => {
      const newKeys = keys.filter((k) => k !== keyId);
      setKeys(newKeys);

      if (queryEndpoint) {
        try {
          await updateEndpoint(newKeys, roles, allowedOrigins);
        } catch (error) {
          // we restore the previous state
          setKeys(keys);
          throw error;
        }
      }
    },
    [keys, queryEndpoint, updateEndpoint, roles, allowedOrigins]
  );

  const setEndpointRoles = useCallback(
    async (newRoles: Array<string>): Promise<void> => {
      setRoles(newRoles);

      if (queryEndpoint) {
        try {
          await updateEndpoint(keys, newRoles, allowedOrigins);
        } catch (error) {
          // we restore the previous state
          setRoles(roles);
          throw error;
        }
      } else {
        try {
          await createEndpoint(queryId, keys, newRoles, allowedOrigins);
        } catch (error) {
          // we restore the previous state
          setRoles(roles);
          throw error;
        }
      }
    },
    [queryEndpoint, setRoles, roles, keys, queryId, allowedOrigins, createEndpoint, updateEndpoint]
  );

  const disableEndpoint = useCallback(async (): Promise<void> => {
    setLoading(true);
    assertTruthy(queryEndpoint, 'Query endpoint not found');

    try {
      await apiClient.queryEndpoints.disableQueryEndpoint(queryEndpoint.id);
      setQueryEndpoint(null);
      setRoles([]);
      setKeys([]);
      setValidationError(null);
    } catch (e) {
      createToast('Error disabling query endpoint', 'danger', e instanceof Error ? e.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [apiClient.queryEndpoints, queryEndpoint]);

  return {
    initialLoading,
    validationError,
    queryEndpoint,
    loading,
    createEndpoint,
    updateEndpoint,
    disableEndpoint,
    addKey,
    removeKey,
    setRoles: setEndpointRoles,
    roles,
    allowedOrigins: allowedOrigins ?? '',
    setAllowedOrigins: setOrigins
  };
}
