import { truthy } from '@cp/common/utils/Assert';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { getCurrentServiceId, useOrgIdFromServiceIdOrFail } from 'src/state/service/index';

import { ResolveReject } from 'src/state/types';

// if the value is a string, the text in the modal is updated with the string value
const modalOpen = atom<boolean>(false);
const modalMessage = atom<string>('');
const setRestartServiceModalOpenWriteAtom = atom(
  null,
  (get, set, open: boolean, message?: string, promise?: ResolveReject<void>) => {
    set(modalOpen, open);

    if (!open) {
      set(modalMessage, '');
    } else if (message && !get(modalMessage)) {
      // only update if modalMessage isn't already set to prevent weird text flashes
      set(modalMessage, message);
    }

    if (promise) {
      set(restartServiceClosePromisesAtom, [...get(restartServiceClosePromisesAtom), promise]);
    }
  }
);

const restartServiceClosePromisesAtom = atom<ResolveReject<void>[]>([]);

export function useSetRestartServiceModalOpen(): (
  open: boolean,
  message?: string,
  promise?: ResolveReject<void>
) => void {
  return useSetAtom(setRestartServiceModalOpenWriteAtom);
}

interface UseRestartService {
  cancelRestartServiceModal: () => void;
  confirmRestartServiceModal: () => void;
  restartServiceModalOpen: boolean;
  restartServiceModalMessage: string;
  restartService: () => Promise<void>;
  setRestartServiceModalOpen: (open: boolean, message?: string, promise?: ResolveReject<void>) => void;
}

export function useRestartService(): UseRestartService {
  const restartServiceModalOpen = useAtomValue(modalOpen);
  const restartServiceModalMessage = useAtomValue(modalMessage);
  const [restartServiceClosePromises, setRestartServiceClosePromises] = useAtom(restartServiceClosePromisesAtom);
  const setRestartServiceModalOpen = useSetRestartServiceModalOpen();
  const apiClient = useApiClient();
  const instanceId = truthy(getCurrentServiceId(), 'Invalid service id');
  const organizationId = useOrgIdFromServiceIdOrFail(instanceId);

  const cancelRestartServiceModal = useCallback((): void => {
    setRestartServiceModalOpen(false);
    restartServiceClosePromises.forEach((promise) => {
      promise.reject(new Error('Service restarting cancelled'));
    });
    setRestartServiceClosePromises([]);
  }, [restartServiceClosePromises, setRestartServiceClosePromises, setRestartServiceModalOpen]);

  const restartService = useCallback(async (): Promise<void> => {
    return await apiClient.instance.start({ organizationId, instanceId }).then(
      () => {
        // Do nothing
      },
      (reason) => {
        console.error(`Failed to set awaking state for the service ${instanceId}`, reason);
      }
    ); // Error!});
  }, [apiClient.instance, instanceId, organizationId]);

  const confirmRestartServiceModal = useCallback((): void => {
    restartServiceClosePromises.forEach((promise) => {
      promise.resolve();
    });
    setRestartServiceModalOpen(false);
    setRestartServiceClosePromises([]);
    restartService().catch(console.error);
  }, [
    restartServiceClosePromises,
    setRestartServiceClosePromises,
    setRestartServiceModalOpen,
    apiClient,
    restartService
  ]);

  return {
    cancelRestartServiceModal,
    confirmRestartServiceModal,
    restartServiceModalOpen,
    setRestartServiceModalOpen,
    restartServiceModalMessage,
    restartService: restartService
  };
}
