import { appStore } from 'src/lib/state/store';

export function dumpLocalStorage(): void {
  try {
    for (let i = 0, len = window.localStorage.length; i < len; i++) {
      const key = window.localStorage.key(i);
      if (key !== null) {
        const value = window.localStorage[key] as string;
        console.log(key + ' => ' + value);
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function dumpJotaiStore(): void {
  try {
    console.log('Jotai store:');
    const mountedAtoms = appStore.dev_get_mounted_atoms ? appStore.dev_get_mounted_atoms() : [];
    for (const atom of mountedAtoms) {
      console.log(atom.debugLabel ? atom.debugLabel : atom.toString(), JSON.stringify(appStore.get(atom), null, 2));
    }
  } catch (e) {
    console.error(e);
  }
}

export function dumpClientState(): void {
  dumpLocalStorage();
  dumpJotaiStore();
}
