import { Button, Container, Dialog, Separator } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { InvitationRecord } from 'src/layout/InviteMembersModal/InviteMembersModalUtils';
import { InviteMembersForm } from 'src/components/InviteMembersForm/InviteMembersForm';
import { useInviteMembers } from 'src/inviteMembers/controller/useInviteMembers';

type InviteMembersModalProps = {
  open: boolean;
  onClose: () => void;
  pendingInvitationEmails: Array<string>;
  organizationEmails: Array<string>;
  canInviteMembers: boolean;
};

const initialRecords: InvitationRecord[] = [
  { email: '', role: null },
  { email: '', role: null },
  { email: '', role: null }
];

export const InviteMembersModal = ({
  open,
  onClose: onCloseProp,
  pendingInvitationEmails,
  organizationEmails,
  canInviteMembers
}: InviteMembersModalProps): ReactElement => {
  const {
    members,
    onChange,
    onSubmit,
    loading,
    error,
    onEmailBlur,
    onEmailFocus,
    disableSubmit,
    onCancel
  } = useInviteMembers({
    records: initialRecords,
    pendingInvitationEmails,
    organizationEmails,
    onSuccess: onCloseProp
  });

  const onClose = (): void => {
    onCancel();
    onCloseProp();
  };

  return (
    <Dialog open={open}>
      <Dialog.Content
        title={'Invite members'}
        data-testid="invite-members-modal"
        showClose
        onClose={onClose}
      >
        <InviteMembersForm
          error={error}
          canInviteMembers={canInviteMembers}
          description="Members have access to the ClickHouse Cloud console where they can view or manage services, members, billing, usage, and API keys."
          members={members}
          onChange={onChange}
          onEmailBlur={onEmailBlur}
          onEmailFocus={onEmailFocus}
        />
        <Separator size="xl" />
        <Container
          justifyContent="end"
          gap="md"
          padding="none"
          fillWidth={false}
          isResponsive={false}
        >
          <Button label="Cancel" onClick={onClose} type="secondary" />
          <Button
            disabled={disableSubmit || loading || !canInviteMembers}
            onClick={(): void => {
              onSubmit().catch(console.error);
            }}
            data-testid="invite-members-submit-button"
            iconLeft={loading ? 'loading-animated' : undefined}
          >
            Send invites
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
