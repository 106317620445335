export function humanFileSize(size: number): string {
  if (size === 0) {
    return '0 MB';
  }

  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1000));
  const x: number = (size / Math.pow(1000, i)) * 1;

  let convertedSize: string = x.toFixed(2);

  const [integer, decimal] = convertedSize.split('.');
  convertedSize = decimal === '00' ? integer : convertedSize;

  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];
  return [convertedSize, unit].join(' ');
}
