import {
  ToastProps,
  createToast as createCUIToast
} from '@clickhouse/click-ui';
import React, { ReactNode } from 'react';

import { toast, ToastContentProps, ToastOptions } from 'react-toastify';
import { humanizeMessage } from 'src/lib/errors/humanizer';
import { isUnifiedConsoleInLocalStorage } from 'src/lib/features';
import ErrorModal from 'src/components/primitives/lib/Toast/ErrorModal';

type ToastType = ToastProps['type'];

interface ToastActionsProps {
  component: JSX.Element;
  options?: ToastOptions;
}

function toastActions({
  component,
  options = {}
}: ToastActionsProps): [() => void, () => void] {
  const newToast = ({ closeToast }: ToastContentProps<unknown>): ReactNode =>
    React.cloneElement(component, { onClose: closeToast });

  const notify = (): void => {
    toast(newToast, options);
  };

  const dismissAll = (): void => toast.dismiss();

  return [notify, dismissAll];
}

function convertToastType(type: string): ToastType {
  switch (type) {
    case 'error':
      return 'danger';
    case 'success':
      return 'success';
    case 'alert':
      return 'warning';
    default:
      return 'default';
  }
}

function makeCUIToast(
  title = 'Default Title',
  type: string,
  message: string | null
): void {
  const humanizedMessage: ReactNode = humanizeMessage(message);

  const toastType: ToastType = convertToastType(type);

  if (!toastType) {
    throw new Error(`Unknown toast type: ${type}`);
  }

  const toastProps: ToastProps = {
    title,
    type: toastType,
    description: humanizedMessage
  };

  // TODO understand this unsafe assignment
  createCUIToast(toastProps);
}

function makeClassicToast(
  title = 'Default Title',
  type: string,
  message: string | null,
  children?: ReactNode
): void {
  const humanizedMessage = humanizeMessage(message);
  const props = {
    component: (
      <ErrorModal title={title} type={type}>
        {humanizedMessage}
        {children}
      </ErrorModal>
    ),
    options: {
      toastId: Date.now(),
      pauseOnHover: type === 'alert'
    }
  };

  const [notify] = toastActions(props);
  notify();
}

export function createToast(
  title = 'Default Title',
  type: string,
  message: string | null,
  children?: ReactNode
): void {
  if (isUnifiedConsoleInLocalStorage()) {
    makeCUIToast(title, type, message);
  } else {
    makeClassicToast(title, type, message, children);
  }
}
