import { Config } from 'src/lib/config/';
import environment from 'src/lib/config/environment';

export default (config: Config): Config => {
  config.env = 'development';
  // This is the clientId from control plane app id
  // config.controlPlane.clientId = 'cqqoouicdrl3j1foq3mk6oi1h'
  config.controlPlane.clientId = '17pm32oa9n8g6dmk3keiok4b7l';
  config.controlPlane.host = 'https://control-plane.clickhouse-dev.com';
  config.controlPlane.apiHost = 'https://control-plane.clickhouse-dev.com';

  if (environment.CLIENT_REMOTE_DEBUG === 'true') {
    config.controlPlane.redirectUri = 'http://localhost:8910/callback';
    config.features.passwordlessAuth = false;
  } else {
    config.controlPlane.redirectUri = 'https://console.clickhouse-dev.com/callback';
    config.features.passwordlessAuth = true;
  }

  config.features.dataLoading = true;

  config.auth0Web = {
    domain: 'auth.control-plane.clickhouse-dev.com',
    clientId: 'lkZkzWIh1lRuv4v6pwbPj9XvwFOsBR6L',
    audience: 'control-plane-web',
    callbackPath: '/',
    auth0domain: 'ch-qa.us.auth0.com',
    databaseConnectionName: 'ControlPlaneUserDb',
    cookieDomain: '.clickhouse-dev.com'
  };

  config.websockets = {
    endpoint: 'wss://o8hydlur88.execute-api.us-east-2.amazonaws.com/dev'
  };

  config.datadog = {
    rum: {
      applicationId: 'efb90891-235f-4961-9c9d-d4fd748dccf0',
      clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
      site: 'us3.datadoghq.com',
      service: 'console',
      env: 'development',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: window._env.APP_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      startSessionReplayRecording: false
    },
    logs: {
      version: window._env.APP_VERSION,
      clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
      site: 'us3.datadoghq.com',
      service: 'console',
      env: 'development',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['error']
    }
  };
  config.fullStory = {
    orgId: 'o-1GR1DR-na1'
  };

  config.growthBook = {
    clientKey: 'sdk-Hqo30WMgC2bVNpcn'
  };

  config.tackle = {
    awsMarketplaceProdPageUrl: 'https://aws.amazon.com/marketplace/pp/prodview-gsdi4fxwso4i2',
    gcpMarketplaceProdPageUrl: 'https://console.cloud.google.com/marketplace/orders',
    azureMarketplaceProdPageUrl: 'https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2'
  };
  config.stripe = {
    publishableKey:
      'pk_test_51LN1y0IAAcceGFTiOfiqg5WI730XSyPnbyrEsg61MBGOa6ppYGMv78BGU8s2B8PNzRVUOYaXoLQ8isKFkWT8o4s800F2dGAF1q'
  };

  config.recaptcha = {
    siteKey: '6Lf3eY4hAAAAADHuCPaw1RzIqbyTLm_b5fqWna3B'
  };
  return config;
};
