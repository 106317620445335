import { NewNotificationPayload, UIUserNotification } from '@cp/common/protocol/Notifications';
import { getObjIdForWebsocketUserNotificationSubscription } from '@cp/common/utils/NotificationsUtils';
import { useCallback, useState } from 'react';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

type useUINotificationsReturnType = {
  notifications: UIUserNotification[];
  markAsRead: () => Promise<void>;
  fetchUINotifications: () => Promise<void>;
  isLoading: boolean;
  unreadCount: number;
};

/**
 * Add new notifications to the list
 * Drop duplicates by id
 * Duplication can happen when re-sub to the same Websocket connection after reconnection
 */
export const addNewNotifications = (
  newNotifications: UIUserNotification[],
  prevNotifications: UIUserNotification[]
): UIUserNotification[] => {
  const update = [];
  for (const notification of newNotifications) {
    let found = false;
    for (const prevNotification of prevNotifications) {
      if (prevNotification.id === notification.id) {
        found = true;
        break;
      }
    }
    if (!found) {
      update.push(notification);
    }
  }
  return [...update, ...prevNotifications];
};

/**
 * Hook for user specific UI notifications
 */
export const useUINotifications = (): useUINotificationsReturnType => {
  const client = useApiClient();
  const user = useCurrentUserOrThrow();
  const organization = useCurrentOrganizationOrThrow();

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<UIUserNotification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useWebSocketsNotification<NewNotificationPayload>({
    subscriptionDetails: {
      type: 'USER_UI_NOTIFICATIONS',
      objId: getObjIdForWebsocketUserNotificationSubscription(user.id, organization?.id || '')
    },
    handler: ({ payload }) => {
      const newNotifications = payload.notifications;
      setNotifications((prev) => {
        return addNewNotifications(newNotifications, prev);
      });

      // update unread count
      if (payload.unreadCount !== undefined) {
        setUnreadCount(payload.unreadCount);
      } else {
        setUnreadCount((prev) => prev + newNotifications.filter((n) => !n.isRead).length);
      }
    }
  });

  const markAsRead = useCallback(async () => {
    if (notifications.length === 0) {
      return;
    }
    const lastId = notifications[0]?.id;
    if (!lastId) {
      return;
    }
    await client.notification.updateMarkAsRead({ lastNotificationId: lastId, organizationId: organization.id });
  }, [client.notification, notifications, organization.id]);

  const fetchUINotifications = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await client.notification.getLastUINotifications({ organizationId: organization.id });
      setNotifications(response.notifications);
      setUnreadCount(response.unreadCount);
    } catch (e) {
      console.error('Error while fetching notifications', e);
    } finally {
      setIsLoading(false);
    }
  }, [client.notification, organization.id]);

  return { notifications, markAsRead, fetchUINotifications, isLoading, unreadCount };
};
