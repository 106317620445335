import {
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import { v4 as uuid } from 'uuid';

import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import { createToast } from 'src/components/primitives';
import { errorMessage } from 'src/lib/errors/errorMessage';

import { ConfirmNodeDelete } from 'src/components/QueryView/QueryList/Folders/ConfirmNodeDelete';
import Tree from 'src/components/QueryView/QueryList/Folders/Tree';
import { rootStyle } from 'src/components/QueryView/QueryList/Folders/Tree/styles';
import { FoldersProps } from 'src/components/QueryView/QueryList/Folders/types';
import {
  getIds,
  removeNull,
  walkAllContentNodes
} from 'src/components/QueryView/QueryList/Folders/utils';

import {
  NodeChangeType,
  NodeType,
  QueryCategory,
  QueryType
} from 'shared/src/types/queryFolders';
import { getNode, removeNodeFromTree } from 'shared/src/utils/queryFolders';
import { useCurrentUser } from 'src/lib/auth/AuthenticationClientHooks';

const categoryTitleMap: Record<QueryCategory, string> = {
  unowned: 'Archived Queries'
};

/**
 * Refreshes the tree data (query and folder nodes) based on the provided content (query) nodes.
 *
 * This function clones the original tree data and updates it based on the content nodes.
 * If a content node matches an existing node in the tree, the tree node is updated.
 * If a content node doesn't exist in the tree, it's added at the beginning.
 *
 * @param {QueryType[]} contentNodes - The content (query) nodes to refresh the tree with.
 * @param {NodeType[]} treeData - The original tree data.
 * @returns {NodeType[]} The refreshed tree data, or an empty array if no content nodes are provided.
 */
const getRefreshedTree = (
  contentNodes: Record<string, QueryType>,
  treeData: NodeType[]
): Array<NodeType> => {
  if (contentNodes) {
    const newTreeData = cloneDeep(treeData);
    const existingIds: string[] = [];

    const groupedNodes = groupBy(Object.values(contentNodes), 'category');

    walkAllContentNodes(newTreeData, (node: NodeType, path: string) => {
      existingIds.push(node.id);
      const hasCategory = !groupedNodes.undefined?.find(
        (item) => item.id === node.id
      );
      const item = contentNodes[node.id];
      const sourcePath = '[' + path.split('-').join('].children[') + ']';

      if (hasCategory) {
        set(newTreeData, sourcePath, null);
      } else {
        if (item) {
          set(newTreeData, sourcePath, item);
        } else {
          set(newTreeData, sourcePath, null);
        }
      }
    });

    Object.values(contentNodes).forEach((item) => {
      if (!existingIds.includes(item.id) && !item.category) {
        newTreeData.unshift(item);
      }
    });

    Object.entries(groupedNodes).forEach(
      ([category, nodes]: [unknown, QueryType[]]) => {
        if (category === 'undefined') {
          return;
        }

        const cat = category as QueryCategory;

        newTreeData.unshift({
          title: categoryTitleMap[cat],
          category: cat,
          children: nodes,
          id: uuid()
        });
      }
    );

    return removeNull(newTreeData);
  } else {
    return [];
  }
};

function parsePath(path: string): Array<number> {
  return path.split('-').map((part) => parseInt(part));
}

interface NodeToDelete {
  id: string;
  title: string;
  type: 'query' | 'folder';
}

const Folders = (props: FoldersProps): ReactElement => {
  const {
    contentNodes,
    folders,
    noDataMessage,
    onChange: onChangeProp,
    onClick,
    onCreateFolder,
    onCreateOption,
    onDelete,
    innerRef,
    search,
    type,
    currentTabId,
    scrollRef,
    onContentRename
  } = props;

  // Holds the hierarchical structure of the folders and content nodes in the tree.
  const [treeData, setTreeData] = useState(() => {
    if (folders) {
      return getRefreshedTree(contentNodes, folders);
    } else {
      return [];
    }
  });
  const [nodeToDelete, setNodeToDelete] = useState<NodeToDelete | null>(null);
  const folderToHighlightRef = useRef<string | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const currentUser = useCurrentUser();

  const refreshTree = useCallback(
    (contentNodes: Record<string, QueryType>, newTreeData: Array<NodeType>) => {
      if (contentNodes) {
        newTreeData = getRefreshedTree(contentNodes, newTreeData);
        if (
          Object.keys(contentNodes).length === 0 ||
          !isEqual(newTreeData, treeData)
        ) {
          setTreeData(newTreeData);
        }
      }
    },
    [treeData]
  );

  const debouncedRefreshTree = useRef(
    debounce(refreshTree, 250, {
      leading: true,
      trailing: true
    })
  );

  const getNodeFromPath = useCallback(
    (indices: number[]): NodeType | undefined => {
      const allButLast = indices.slice(0, indices.length - 1);
      const lastIndex = indices[indices.length - 1];
      let children = treeData;

      for (const index of allButLast) {
        const nextChildren = children[index].children;
        if (!nextChildren) {
          throw new Error('Invalid path');
        }
        children = nextChildren;
      }

      return children[lastIndex];
    },
    [treeData]
  );

  const removeNode = useCallback(
    (nodeId: string) => {
      setTreeData((oldTreeData: Array<NodeType>) => {
        const newData = cloneDeep(oldTreeData);
        const nodeResult = getNode(newData, nodeId);

        const path = removeNodeFromTree(newData, nodeResult);
        if (path != null) {
          void onChangeProp(newData, path, 'delete');
        }

        return newData;
      });
    },
    [onChangeProp]
  );

  const onDeleteFolder = useCallback(async () => {
    if (!nodeToDelete) {
      return;
    }

    setNodeToDelete(null);
    const nodeResult = getNode(treeData, nodeToDelete.id);
    if (!nodeResult) {
      return;
    }

    const { node } = nodeResult;

    const ids = getIds(node.children ?? []);
    try {
      await Promise.all(ids.map((id) => onDelete(id)));
      removeNode(nodeToDelete.id);
    } catch (e) {
      createToast('Error deleting folder', 'alert', errorMessage(e));
    }
  }, [onDelete, nodeToDelete, removeNode, treeData]);

  const onDeleteQuery = useCallback(async () => {
    if (!nodeToDelete) {
      return;
    }

    setNodeToDelete(null);
    await onDelete(nodeToDelete.id);
  }, [onDelete, nodeToDelete]);

  const deleteNode = useCallback(
    (path: string): void => {
      const node = getNodeFromPath(parsePath(path));
      if (node) {
        if (node.type === 'content') {
          setNodeToDelete({
            id: node.id,
            title: node.title,
            type: 'query'
          });
        } else {
          if (node.creatorId !== currentUser?.id) {
            createToast(
              'Error deleting folder',
              'alert',
              "You don't have permissions to to delete this folder"
            );
            return;
          }

          if (node.children && node.children.length === 0) {
            removeNode(node.id);
          } else if (node.children) {
            setNodeToDelete({
              id: node.id,
              title: node.title,
              type: 'folder'
            });
          }
        }
      }
    },
    [getNodeFromPath, onDelete, removeNode]
  );

  useEffect(() => {
    debouncedRefreshTree.current(contentNodes, treeData);
  }, [JSON.stringify(contentNodes)]);

  // This useEffect hook is triggered only when 'folders' or 'contentNodes'
  // component properties change.
  // As a side-effect, it updates the 'treeData' state.
  useEffect(() => {
    if (!folders || !contentNodes) {
      return;
    }
    const newTreeData = getRefreshedTree(contentNodes, folders);
    // Update 'treeData' state.
    setTreeData((oldTreeData) => {
      // If 'contentNodes' is empty or 'newTreeData' is different from 'oldTreeData',
      // update the 'treeData' state with 'newTreeData'.
      if (
        Object.keys(contentNodes).length === 0 ||
        !isEqual(newTreeData, oldTreeData)
      ) {
        // set newTreeData as the treeData
        return newTreeData;
      }
      // Otherwise, keep the 'treeData' state unchanged.
      return oldTreeData;
    });
  }, [JSON.stringify(folders), JSON.stringify(contentNodes)]);

  useImperativeHandle(innerRef, () => ({
    setHighlightedFolder: (id: string): void => {
      folderToHighlightRef.current = id;
    },
    setSelectedFolder,
    shouldHighlightFolder: (id: string): boolean => {
      if (id === folderToHighlightRef.current) {
        folderToHighlightRef.current = null;
        return true;
      } else {
        return false;
      }
    }
  }));

  const onChange = useCallback(
    (
      data: Array<NodeType>,
      path?: string,
      type?: NodeChangeType,
      value?: NodeType | string
    ) => {
      setTreeData(data);
      onChangeProp(data, path, type, value);
    },
    [onChangeProp, setTreeData]
  );

  return (
    <div css={rootStyle} data-testid="queryListFolders">
      <Tree
        noDataMessage={noDataMessage}
        onChange={onChange}
        onContentRename={onContentRename}
        onClick={onClick}
        onCreateFolder={onCreateFolder}
        onCreateOption={onCreateOption}
        onDelete={deleteNode}
        search={search}
        treeData={treeData}
        type={type}
        mainRef={innerRef}
        currentTabId={currentTabId}
        scrollRef={scrollRef}
        selectedFolder={selectedFolder}
      />
      <ConfirmNodeDelete
        open={nodeToDelete !== null}
        onCancel={(): void => {
          setNodeToDelete(null);
        }}
        onDeleteFolder={onDeleteFolder}
        onDeleteQuery={onDeleteQuery}
        title={nodeToDelete?.title}
        type={nodeToDelete?.type}
      />
    </div>
  );
};

export default Folders;
