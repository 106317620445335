import { ReactElement } from 'react';

import { IconProps } from 'src/components/icons/iconInterface';

const LockIcon = ({
  color = 'currentColor',
  ...props
}: IconProps): ReactElement => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 6.25V4.375V4.375C5.5 2.99437 6.61937 1.875 8 1.875V1.875C9.38063 1.875 10.5 2.99437 10.5 4.375V4.375V6.25"
        stroke={color}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.75V10.625"
        stroke={color}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.125 13.125H4.875C4.18437 13.125 3.625 12.5656 3.625 11.875V7.5C3.625 6.80937 4.18437 6.25 4.875 6.25H11.125C11.8156 6.25 12.375 6.80937 12.375 7.5V11.875C12.375 12.5656 11.8156 13.125 11.125 13.125Z"
        stroke={color}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockIcon;
