import { Config } from 'src/lib/config/';

export default (config: Config): Config => {
  config.env = 'production';
  config.controlPlane.clientId = '67jh110eic4htl54tftqpc23sf';
  config.controlPlane.host = 'https://clickhouse.cloud';
  config.controlPlane.apiHost = 'https://clickhouse.cloud';
  config.controlPlane.redirectUri = 'https://console.clickhouse.cloud/callback';
  config.features.passwordlessAuth = true;
  config.features.dataLoading = false;
  config.auth0Web = {
    domain: 'auth.clickhouse.cloud',
    clientId: 'IPpH4RND0qNXHVayepffgsGpbXQmFikr',
    audience: 'control-plane-web',
    callbackPath: '/',
    auth0domain: 'ch-production.us.auth0.com',
    databaseConnectionName: 'ControlPlaneUserDb',
    cookieDomain: '.clickhouse.cloud'
  };

  config.websockets = {
    endpoint: 'wss://q5wbg5hbt9.execute-api.us-east-2.amazonaws.com/production'
  };

  config.datadog = {
    rum: {
      applicationId: 'efb90891-235f-4961-9c9d-d4fd748dccf0',
      clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
      site: 'us3.datadoghq.com',
      service: 'console',
      env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: window._env.APP_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      startSessionReplayRecording: true
    },
    logs: {
      version: window._env.APP_VERSION,
      clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
      site: 'us3.datadoghq.com',
      service: 'console',
      env: 'production',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['error']
    }
  };
  config.fullStory = {
    orgId: 'o-1GR1DR-na1'
  };

  config.growthBook = {
    clientKey: 'sdk-Y4XqZEwK3Wz208Cp'
  };

  config.tackle = {
    awsMarketplaceProdPageUrl: 'https://aws.amazon.com/marketplace/pp/prodview-jettukeanwrfc',
    gcpMarketplaceProdPageUrl: 'https://console.cloud.google.com/marketplace/orders',
    azureMarketplaceProdPageUrl: 'https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2'
  };

  config.stripe = {
    publishableKey:
      'pk_live_51LBihwC9bitRmbOwFDQjAa2pNEbki8jSrvriGCOlhKYCCc18S2p5yILMZ1x2nVi0p0RyDIJz2WCHv7o6iarwR1AD00UNNKvPPt'
  };

  config.recaptcha = {
    siteKey: '6LdKg44hAAAAAC52M_CMVqZ16kjiS26r1BmkYk2P'
  };
  return config;
};
