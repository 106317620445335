import { Icon } from '@clickhouse/click-ui';
import React from 'react';
import styles from 'src/components/App/MainView/TabBar/styles';

interface HomeTabProps {
  active: boolean;
  hasTabs: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export function HomeTab({ active, onClick, hasTabs = false }: HomeTabProps) {
  return (
    <div
      css={styles.homeContainer}
      role="button"
      tabIndex={0}
      className="home"
      data-active={active}
      onClick={onClick}
      data-has-tabs={hasTabs}
    >
      <Icon name="home" size="sm" />
    </div>
  );
}
