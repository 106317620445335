import { ReactElement } from 'react';
import { Theme, useTheme } from '@emotion/react';
import { ProgressBar } from 'src/components/primitives';
import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { QueryProgress } from 'src/lib/query/streamingQuery';

type RunningQueryProgressBarProps = {
  status: RunningQueryStatusName;
  progress?: QueryProgress;
};

const defaultProgress: QueryProgress = {
  read_rows: 0,
  read_bytes: 0,
  written_rows: 0,
  written_bytes: 0,
  total_rows_to_read: 0,
  elapsed_ns: 0,
  peak_memory_usage: 0,
  result_rows: 0,
  result_bytes: 0
};

type ProgressBarProps = {
  barColor: string;
  bgColor: string;
  percent: number;
};

/**
 * Determines the color and percent progress of the progress bar based on the status and progress of the query.
 * @param {RunningQueryStatusName} status - The status of the running query.
 * @param {QueryProgress} progress - The progress of the running query.
 * @param {Theme} theme - The theme object from Emotion.
 * @returns {ProgressBarProps} The color and percent progress of the progress bar.
 */
const getColorAndPercent = (
  status: RunningQueryStatusName,
  progress: QueryProgress,
  theme: Theme
): ProgressBarProps => {
  let barColor: string;
  let percent: number;
  const bgColor = theme.global.color.stroke.muted;

  switch (status) {
    case 'running':
      barColor = theme.colors.alert.text.info;
      percent = Math.round(
        Math.min(
          1,
          Math.max(0, progress.read_rows / progress.total_rows_to_read)
        ) * 100
      );
      break;
    case 'error':
    case 'cancelled':
      barColor = theme.colors.alert.text.danger;
      percent = 100;
      break;
    case 'finished':
      barColor = theme.colors.alert.text.success;
      percent = 100;
      break;
    case 'new':
    default:
      barColor = theme.colors.alert.text.info;
      percent = 0;
      break;
  }

  return { barColor, bgColor, percent };
};

const RunningQueryProgressBar = ({
  status,
  progress = defaultProgress
}: RunningQueryProgressBarProps): ReactElement => {
  const theme = useTheme();
  const { barColor, bgColor, percent } = getColorAndPercent(
    status,
    progress,
    theme
  );

  return (
    <ProgressBar
      progress={percent || 0.01} // Show a small amount of progress to prevent the bar from disappearing
      barHeight="2px"
      barWidth="100%"
      barColor={barColor}
      bgColor={bgColor}
      borderColor="none"
    />
  );
};

// To avoid `react-refresh/only-export-components` warning
RunningQueryProgressBar.getColorAndPercent = getColorAndPercent;

export default RunningQueryProgressBar;
