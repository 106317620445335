import { tokenize } from 'shared/src/sql/parse/tokenize';

function tokensEquivalent(a, b) {
  if (a.length !== b.length) {
    return false;
  } else {
    for (let i = 0; i < a.length; ++i) {
      if (a[i].type !== b[i].type || a[i].text !== b[i].text) {
        return false;
      }
    }
    return true;
  }
}

// Return the new cursor offset (characters from the beginning of the document).
// We tokenize the before & after documents, and if the tokens are the same we try
// to put the cursor in a similar place wrt to the tokens that it was before
export default function updatedCursorPosition(
  oldText,
  oldCursorOffset,
  newText,
  sqlVariant = 'sql'
) {
  const oldTokens = tokenize(oldText, { variant: sqlVariant });
  const newTokens = tokenize(newText, { variant: sqlVariant });
  if (!tokensEquivalent(oldTokens, newTokens)) {
    return newText.length;
  } else if (oldTokens.length === 0) {
    return newText.length;
  } else {
    for (let i = 0; i < oldTokens.length; ++i) {
      const oldToken = oldTokens[i];
      const newToken = newTokens[i];
      if (oldToken.startOffset > oldCursorOffset) {
        // cursor was between tokens, place it at the beginning of next token
        return newToken.startOffset;
      } else if (
        oldToken.startOffset <= oldCursorOffset &&
        oldToken.endOffset >= oldCursorOffset
      ) {
        // we're in the middle of this token
        return newToken.startOffset + (oldCursorOffset - oldToken.startOffset);
      }
    }
    // If we got here, we must be after the final token, just put us after the final token
    return oldTokens[oldTokens.length - 1].endOffset;
  }
}
