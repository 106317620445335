import { ReactElement } from 'react';
import { Container, EllipsisContent, Tooltip } from '@clickhouse/click-ui';
import LoadingIcon from 'src/components/icons/LoadingIcon';

interface ClickPipeMetricProps {
  isInstanceAwake: boolean;
  value: string | null;
  displayFunc: (n: number) => string;
}

export function IdleClickPipeMetric(): ReactElement {
  return (
    <Container alignItems="center" gap="sm">
      <Tooltip>
        <EllipsisContent component={Tooltip.Trigger}>N/A</EllipsisContent>
        <Tooltip.Content side="bottom" className="fs-exclude">
          Instance is currently idle
        </Tooltip.Content>
      </Tooltip>
    </Container>
  );
}

export function ClickPipeMetric({
  isInstanceAwake,
  value,
  displayFunc
}: ClickPipeMetricProps): ReactElement {
  if (!isInstanceAwake) {
    return <IdleClickPipeMetric />;
  }

  const label =
    value === null ? (
      <LoadingIcon height={'20px'} width={'20px'} data-testid="loading-icon" />
    ) : (
      displayFunc(Number(value))
    );

  return (
    <Container alignItems="center">
      <Tooltip>
        <EllipsisContent component={Tooltip.Trigger}>{label}</EllipsisContent>

        {value !== null && (
          <Tooltip.Content side="bottom" className="fs-exclude">
            {label}
          </Tooltip.Content>
        )}
      </Tooltip>
    </Container>
  );
}
