import { MAX_INSTANCE_NAME_LENGTH, MIN_INSTANCE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';

export const validateServiceName = ({
  serviceName,
  existingInstanceNames
}: {
  serviceName: string;
  existingInstanceNames: Array<string>;
}): { error: string } => {
  if (!serviceName) {
    return { error: 'A service name is required' };
  }

  if (existingInstanceNames.includes(serviceName)) {
    return { error: 'This service name is already in use' };
  }

  if (serviceName.length < MIN_INSTANCE_NAME_LENGTH) {
    return { error: 'That service name is too short' };
  }

  if (serviceName.length > MAX_INSTANCE_NAME_LENGTH) {
    return { error: 'That service name is too long' };
  }

  return { error: '' };
};
