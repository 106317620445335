import React, { useMemo, useRef } from 'react';

import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { v4 as uuid } from 'uuid';

import AxisTick from './ChartComponents/AxisTick';
import ChartLegend from './ChartComponents/Legend';
import { useAxisConfig } from './ChartUtils/axis';
import {
  useDataLimit,
  useHorizontalInterval,
  useResizeListener,
  useVerticalInterval,
  useXAxisHeight,
  useYAxisWidth
} from './ChartUtils/hooks';
import defaultClasses from './styles';

const DataLabelVertical = (stacked, interval) => (props) => {
  const { index, value, x, y } = props;

  if (interval && index !== 0 && index % interval !== 0) {
    return null;
  }

  if (stacked) {
    return (
      <React.Fragment>
        <text
          filter="url(#text-background)"
          x={x}
          dx={7}
          y={y}
          dy={stacked ? 10 : -4}
          fontSize={10}
          textAnchor="middle"
        >
          {' '}
          {value}{' '}
        </text>
        <text
          x={x}
          dx={7}
          y={y}
          dy={stacked ? 10 : -4}
          fontSize={10}
          textAnchor="middle"
        >
          {value}
        </text>
      </React.Fragment>
    );
  }

  return (
    <text
      x={x}
      dx={7}
      y={y}
      dy={stacked ? 10 : -4}
      fontSize={10}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

const DataLabelHorizontal = (stacked, interval) => (props) => {
  const { index, value, width, x, y } = props;
  if (stacked && value === 0) {
    return null;
  }

  if (interval && index !== 0 && index % interval !== 0) {
    return null;
  }

  return (
    <text
      x={x + width}
      dx={stacked ? -10 : 4}
      y={y}
      fontSize={10}
      alignmentBaseline="middle"
      textAnchor={stacked ? 'end' : 'start'}
    >
      {value}
    </text>
  );
};

const PrimitiveBarChart = React.forwardRef((props, ref) => {
  const {
    animate,
    footer,
    layout,
    data: dataProp,
    editable,
    showLegend,
    subtitle,
    stacked,
    title,
    xAxis,
    xAxisLabel,
    xAxisTickVertical,
    yAxis,
    yAxisType,
    yAxisLabel,
    showDataLabel,
    xAxisRange: xAxisRangeProp,
    yAxisRange: yAxisRangeProp,
    allowEscapeViewBox
  } = props;
  const theme = useTheme();

  const data = useDataLimit(dataProp);
  const id = useRef(`bar-chart-${uuid()}`);
  const [height, rootRef, headerRef, footerRef, onResize, width] =
    useResizeListener([title, subtitle, footer]);
  const charLimit = useMemo(
    () => Math.max(Math.round((height / 100) * 6), 10),
    [height]
  );
  const interval = useVerticalInterval(data, width);
  const horizontalInterval = useHorizontalInterval(data, height);
  const yAxisWidth = useYAxisWidth(id.current, yAxisLabel, yAxis, data);

  const [xAxisHeight, yOffset] = useXAxisHeight(
    id.current,
    xAxisLabel,
    xAxis,
    xAxisTickVertical,
    data,
    charLimit
  );
  const colors = theme.colors.chart.values;

  const { xAxesConfigs, yAxesConfigs, dataAxisConfig, chartKey } =
    useAxisConfig(
      layout,
      data,
      xAxis,
      xAxisRangeProp,
      yAxis,
      yAxisType,
      yAxisRangeProp
    );

  const bar = useMemo(() => {
    const axis = layout === 'vertical' ? yAxis : xAxis;
    const radius = layout === 'vertical' ? [3, 3, 0, 0] : [0, 3, 3, 0];
    if (typeof axis === 'string') {
      return dataAxisConfig.map(({ color, ...props }, key) => [
        <Bar
          isAnimationActive={animate}
          key={key}
          {...props}
          fill={colors[0]}
          barSize={15}
          radius={radius}
          label={
            !showDataLabel
              ? null
              : layout === 'vertical'
              ? DataLabelVertical(false, interval)
              : DataLabelHorizontal(false, horizontalInterval)
          }
        />
      ]);
    } else if (Array.isArray(axis)) {
      return dataAxisConfig.map(({ color, ...props }, key) => (
        <Bar
          isAnimationActive={animate}
          key={key}
          {...props}
          stackId={stacked ? 'a' : null}
          fill={color || colors[key % colors.length]}
          barSize={15}
          radius={key !== axis.length - 1 && stacked ? [0, 0, 0, 0] : radius}
          label={
            !showDataLabel
              ? null
              : layout === 'vertical'
              ? DataLabelVertical(key !== axis.length - 1 && stacked, interval)
              : DataLabelHorizontal(
                  key !== axis.length - 1 && stacked,
                  horizontalInterval
                )
          }
        />
      ));
    }

    return [];
  }, [
    layout,
    JSON.stringify(xAxis),
    JSON.stringify(yAxis),
    interval,
    horizontalInterval,
    animate,
    showDataLabel,
    stacked,
    dataAxisConfig
  ]);

  return (
    <div id={id.current} ref={rootRef} css={defaultClasses.root}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      <div ref={headerRef} css={defaultClasses.headerContainer}>
        {(editable || title) && (
          <div css={defaultClasses.header}>
            {title || (editable ? 'Enter title here' : '')}
          </div>
        )}
        {subtitle && (
          <div css={defaultClasses.subtitle}>
            {subtitle || (editable ? 'Enter subtitle here' : '')}
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          key={`${xAxisLabel}${yAxisLabel}-${chartKey}`}
          data={Array.isArray(data) ? data : null}
          layout={layout === 'vertical' ? 'horizontal' : 'vertical'}
          css={defaultClasses.chartStyle}
        >
          <defs>
            <filter x="0" y="0" width="1" height="1" id="text-background">
              <feFlood floodColor="white" floodOpacity="0.5" />
              <feComposite in="SourceGraphic" operator="xor" />
            </filter>
          </defs>
          <CartesianGrid parentRef={rootRef} />
          {xAxisTickVertical &&
            xAxesConfigs.map((props, i) => (
              <XAxis
                key={i}
                {...props}
                allowDataOverflow
                xAxisId={i}
                interval={interval}
                height={xAxisHeight + (i < xAxesConfigs.length - 1 ? 20 : 0)}
                type={layout === 'vertical' ? 'category' : 'number'}
                tick={<AxisTick charLimit={charLimit} />}
                tickMargin={6.5}
                padding={{ right: 20 }}
              >
                <Label
                  value={
                    xAxisLabel || (editable ? 'Enter x-axis label here' : '')
                  }
                  position="insideBottom"
                  offset={-3}
                />
              </XAxis>
            ))}
          {!xAxisTickVertical &&
            xAxesConfigs.map((props, i) => (
              <XAxis
                key={i}
                {...props}
                allowDataOverflow
                xAxisId={i}
                interval="preserveStart"
                height={xAxisHeight + (i < xAxesConfigs.length - 1 ? 20 : 0)}
                type={layout === 'vertical' ? 'category' : 'number'}
                tickMargin={4}
              >
                <Label
                  value={
                    xAxisLabel || (editable ? 'Enter x-axis label here' : '')
                  }
                  position="insideBottom"
                  offset={-3}
                />
              </XAxis>
            ))}
          {yAxesConfigs.map((props, i) => (
            <YAxis
              key={i}
              {...props}
              yAxisId={i}
              allowDataOverflow
              dx={0}
              width={
                yAxisWidth + yOffset + (i < yAxesConfigs.length - 1 ? 20 : 0)
              }
              type={layout === 'vertical' ? 'number' : 'category'}
              padding={xAxisTickVertical ? { top: 20 } : {}}
            >
              <Label
                offset={0}
                value={
                  yAxisLabel || (editable ? 'Enter y-axis label here' : '')
                }
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
          ))}
          <Tooltip
            cursor={{
              fill: 'rgba(240, 240, 240, 0.5)',
              stroke: 'rgba(240, 240, 240, 0.7)'
            }}
            allowEscapeViewBox={{
              x: allowEscapeViewBox,
              y: allowEscapeViewBox
            }}
          />
          {bar.length > 1 && showLegend && <Legend content={ChartLegend} />}
          {bar}
        </BarChart>
      </ResponsiveContainer>
      <div ref={footerRef} css={defaultClasses.footer}>
        {footer || (editable ? 'Enter footer here' : '')}
      </div>
    </div>
  );
});

PrimitiveBarChart.displayName = 'BarChart';

PrimitiveBarChart.propTypes = {
  animate: PropTypes.bool,
  footer: PropTypes.string,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  data: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  showLegend: PropTypes.bool,
  subtitle: PropTypes.string,
  stacked: PropTypes.bool,
  title: PropTypes.string,
  xAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  xAxisType: PropTypes.oneOf(['category', 'number']),
  xAxisLabel: PropTypes.string,
  xAxisTickVertical: PropTypes.bool,
  allowEscapeViewBox: PropTypes.bool,
  yAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  yAxisType: PropTypes.oneOf(['category', 'number']),
  yAxisLabel: PropTypes.string,
  showDataLabel: PropTypes.bool
};

PrimitiveBarChart.defaultProps = {
  animate: true,
  data: [],
  editable: false,
  layout: 'vertical',
  showLegend: true,
  stacked: false,
  xAxisTickVertical: false,
  allowEscapeViewBox: false
};

export default PrimitiveBarChart;
