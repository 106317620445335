import { ConfirmationDialog } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

type DisableEndpointConfirmationModalProps = {
  disableEndpoint: () => Promise<void>;
  onCancel: () => void;
};

export default function DisableEndpointConfirmationModal({
  disableEndpoint,
  onCancel
}: DisableEndpointConfirmationModalProps): ReactElement {
  return (
    <ConfirmationDialog
      title="Are you sure you want to disable the endpoint?"
      message={
        'This action is not reversible. You will no longer be able to query the endpoint.'
      }
      open
      onCancel={onCancel}
      onConfirm={disableEndpoint}
    />
  );
}
