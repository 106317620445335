import {
  BillPeriodDates,
  OrganizationUsagePeriodChart,
  OrganizationUsageReport,
  UsageMetric
} from '@cp/common/protocol/Billing';
import { useEffect, useState } from 'react';
import { BillingApiClient } from 'src/billing/billingApiClient';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';

/**
 * Billing Usage Page logic:
 *
 *
 * We effectively use the `getUsageReport` for three things:
 * 1. Grab a list of valid billDates for the specified org
 * 2. Get usage data/charts for the current period (latest bill, probably not closed)
 * 3. Get usage data/charts for a specific period
 *
 * Use cases #1 and #2 do not require a `billDate` param.  The endpoint returns the
 * latest bill if no `billDate` is specified
 *
 * Use case #3 requires a `billDate` param.
 *
 * Another note: `getUsageReport` returns a `callAgainSoon` boolean that indicates
 * whether or not we need to schedule another request (after ~10s) once a statement has
 * been calculated for the bill.
 *
 * This controller is structured similar to Fabio's `useMetrics()` function —
 * `getUsageDetails()` function will orchestrate calls to `fetchUsage()` and
 * set our controller variables accordingly.
 *
 * As a result, the intended flow is as follows:
 * 1. Fire off first `getUsageDetails()` call --> populate the bill period selector,
 *    usage table and chart for latest period
 * 2. If `getUsageDetails()` returns true for `callAgainSoon`, continue calling it until `callAgainSoon`
 *    is false
 * 3. Whenever the user selects a different chart type or bill period, repeat step #1 but with user-specified
 *    values for `billDate` and `chartType`
 */
export interface AdminUsageReport {
  isChartLoading: boolean;
  isReportLoading: boolean;
  usageReport: OrganizationUsageReport;
  usageChart: OrganizationUsagePeriodChart;
  billDates: Array<BillPeriodDates>;
  error: string | undefined;
  callAgain: boolean;
}
type UsageReportError = { error: string };
type UsageReportSuccess = {
  report: OrganizationUsageReport;
  chart: OrganizationUsagePeriodChart | null;
  billDates: Array<BillPeriodDates>;
  callAgainSoon: boolean;
};

const fetchUsage = async (
  organizationId: string,
  billDate: string | undefined,
  chartType: UsageMetric,
  api: BillingApiClient
): Promise<UsageReportSuccess | UsageReportError> => {
  try {
    const response = await api.getAdminUsageReport(organizationId, billDate, chartType);
    return {
      report: response.report,
      chart: response.chart,
      billDates: response.billDates,
      callAgainSoon: response.callAgainSoon
    };
  } catch (e) {
    console.error(e);
    return { error: errorMessage(e) };
  }
};

export function useAdminUsageReport(
  organizationId: string,
  billDate: string | undefined,
  metric: UsageMetric
): AdminUsageReport {
  const [error, setError] = useState<string | undefined>(undefined);
  const [usageReport, setUsageReport] = useState<OrganizationUsageReport>({} as OrganizationUsageReport);
  const [billDates, setBillDates] = useState<Array<BillPeriodDates>>([] as Array<BillPeriodDates>);
  const [usageChart, setUsageChart] = useState<OrganizationUsagePeriodChart>({} as OrganizationUsagePeriodChart);
  const [callAgain, setCallAgain] = useState<boolean>(false);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(true);

  const [previousOrganizationId, setPreviousOrganizationId] = useState<string | null>(null);
  const [previousBillDate, setPreviousBillDate] = useState<string | undefined | null>(null);
  const [previousMetric, setPreviousMetric] = useState<UsageMetric | null>(null);

  const api = useApiClient();
  useEffect(() => {
    async function getUsageDetails(): Promise<void> {
      if (organizationId !== previousOrganizationId || billDate !== previousBillDate || metric !== previousMetric) {
        setIsChartLoading(true);
      }
      if (organizationId !== previousOrganizationId || billDate !== previousBillDate) {
        setIsReportLoading(true);
      }
      const result = await fetchUsage(organizationId, billDate, metric, api.billing);
      setPreviousOrganizationId(organizationId);
      setPreviousBillDate(billDate);
      setPreviousMetric(metric);

      if ('error' in result) {
        setError(result.error);
      }
      if ('report' in result) {
        setUsageReport(result.report);
      }
      if ('chart' in result) {
        if (result.chart) {
          setUsageChart(result.chart);
          setIsChartLoading(false);
        } else {
          // chart is null for now -- will call again soon
          setIsChartLoading(true);
        }
      }
      if ('billDates' in result) {
        setBillDates(result.billDates);
      }
      if ('callAgainSoon' in result) {
        setCallAgain(result.callAgainSoon);
      }
      setIsReportLoading(false);
    }

    const intervalId = setInterval(() => {
      if (!callAgain) {
        clearInterval(intervalId);
      } else {
        getUsageDetails().catch(console.error);
      }
    }, 10000);
    getUsageDetails().catch(console.error);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [organizationId, billDate, metric, callAgain, api, previousOrganizationId, previousBillDate, previousMetric]);
  return {
    isChartLoading,
    isReportLoading,
    error,
    usageReport,
    billDates,
    usageChart,
    callAgain
  };
}
