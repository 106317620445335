import React, { ReactElement } from 'react';
import { ImportStatus } from 'shared/src/dataLoading';
import { Dropdown, IconButton } from '@clickhouse/click-ui';
import { isStopped, isSuccessful } from 'src/dataloading/models';
type Props = {
  importId: string;
  status: ImportStatus;
  serviceId: string;
  onRetry: (importId: string) => void;
  onCancel: (dataImportId: string) => void;
  onOpenDetails: (importId: string) => void;
};

function Actions({
  importId,
  status,
  onCancel,
  onRetry,
  onOpenDetails
}: Props): ReactElement {
  return (
    <Dropdown>
      <Dropdown.Trigger>
        <IconButton
          icon="dots-vertical"
          disabled={status === 'canceled'}
          size="sm"
        />
      </Dropdown.Trigger>
      <Dropdown.Content side="bottom" align="end">
        {status !== 'queued' && status !== 'canceled' && (
          <Dropdown.Item onClick={() => onOpenDetails(importId)}>
            Details
          </Dropdown.Item>
        )}
        {!isStopped(status) && !isSuccessful(status) && (
          <Dropdown.Item onClick={() => onCancel(importId)}>
            Cancel
          </Dropdown.Item>
        )}
        {status === 'failed' && (
          <Dropdown.Item onClick={() => onRetry(importId)}>Retry</Dropdown.Item>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
}

const MemoizedActions = React.memo(Actions);
MemoizedActions.displayName = 'Actions';
export default MemoizedActions;
