import { ContextMenu } from '@clickhouse/click-ui';

import { css, SerializedStyles } from '@emotion/react';
import type { editor, ISelection } from 'monaco-editor';
import { MutableRefObject, useMemo, useRef, useState } from 'react';

import type { AutocompleteProviderOptions } from 'src/lib/autocomplete/types';
import defaultTemplateOptions from 'src/lib/autocomplete/utils/templateOptions';
import { LogFn } from 'src/lib/logger';
import SQLFormator from 'src/lib/sql/format';
import {
  parseQueryVariables,
  QueryVariable
} from 'shared/src/sql/parse/parseQueryVariables';
import { StatementRange } from 'src/lib/sql/parse/statementRanges';
import { useGptPopups } from 'src/lib/gpt/GptPopupsHook';

import MonacoSQLEditor, {
  EditorInstance
} from 'src/components/primitives/lib/MonacoSQLEditor';

interface EditorState {
  model: editor.ITextModel | undefined;
  selection: ISelection | undefined;
}

type QueryVariables = QueryVariable[];

interface OnChangeEvent {
  sql: string;
  queryVariables: QueryVariables;
}

interface TemplateOptions {
  keyword: string;
  text: string;
  type: string;
  description: string;
  tokens: string[];
}

interface Props {
  autocompleteOptions: AutocompleteProviderOptions;
  editorRef?: MutableRefObject<EditorInstance | null>;
  readOnly?: boolean;
  preloadedEditorState?: EditorState;
  preloadedQuery?: string;
  indentWidth?: number;
  onChange?: (event: OnChangeEvent) => void;
  onSave?: (value: string | undefined) => Promise<void>;
  onSelectionChange?: (selectedText: string | undefined) => void;
  runSQL?: (statement: string | undefined) => void;
  runQuery?: (query?: string) => void;
  scrollPosition?: number;
  height?: string | number;
  templateOptions?: TemplateOptions;
  css?: SerializedStyles;
  logEvent: LogFn;
  errorRegion?: StatementRange;
}

const contextMenuContainer = (height: number | string = '100%') =>
  css({
    width: '100%',
    height
  });

function SqlEditorWithAutoComplete({
  editorRef,
  readOnly,
  preloadedEditorState,
  preloadedQuery,
  onChange,
  onSave,
  autocompleteOptions,
  indentWidth = 4,
  onSelectionChange,
  runSQL,
  runQuery,
  scrollPosition,
  height,
  templateOptions,
  logEvent,
  errorRegion,
  ...props
}: Props) {
  const sqlEditorRef = useRef<EditorInstance | null>(null);
  const [editorState, setEditorState] = useState<EditorState | undefined>(
    preloadedEditorState
  );
  const { showGptConstructionPopup } = useGptPopups();

  const extractQueryVariables = (sql: string) => {
    return parseQueryVariables(sql);
  };

  const formatQuery = () => {
    if (sqlEditorRef.current) {
      const query = sqlEditorRef.current.getText() || '';
      const newQuery = SQLFormator(query.trim(), 'clickhouse', indentWidth);
      sqlEditorRef.current.replaceEditorContent(newQuery);
      if (onChange) {
        onChange({
          sql: newQuery,
          queryVariables: extractQueryVariables(newQuery)
        });
      }
    }
  };

  const contextMenuOptions = useMemo(() => {
    const hasText = (sqlEditorRef.current?.getText() || '')?.trim().length > 0;
    const options = [
      {
        label: 'Format query',
        onClick: (): void => {
          logEvent && logEvent('contextMenuQueryFormat');
          formatQuery();
        }
      },
      {
        label: 'Paste',
        onClick: async (): Promise<void> => {
          logEvent && logEvent('contextMenuPaste');
          sqlEditorRef.current?.paste();
        }
      }
    ];
    if (hasText) {
      options.push({
        label: 'Run all',
        onClick: () => {
          logEvent && logEvent('contextMenuAllCommandsRun');
          runQuery && runQuery();
        }
      });
    }
    if (sqlEditorRef.current) {
      if ((sqlEditorRef.current.getSelectedText() || '')?.trim().length > 0) {
        options.push({
          label: 'Copy',
          onClick: () => {
            logEvent && logEvent('contextMenuPaste');
            sqlEditorRef.current?.copy();
          }
        });
        options.push({
          label: 'Run selected',
          onClick: () => {
            logEvent && logEvent('contextMenuSelectedRun');

            sqlEditorRef.current?.runSelectedText();
          }
        });
      } else if (hasText) {
        options.push({
          label: 'Run at cursor',
          onClick: () => {
            logEvent && logEvent('contextMenuAtCursorRun');
            sqlEditorRef.current?.runCurrentStatement();
          }
        });
      }
    }
    return options;
  }, [
    sqlEditorRef.current?.getSelectedText()?.trim()?.length,
    sqlEditorRef.current?.getText()?.trim()?.length
  ]);

  return (
    <ContextMenu
      onOpenChange={(open: boolean): void => {
        logEvent &&
          logEvent(
            open ? 'contextMenuOpen' : 'contextMenuBlur',
            open ? 'click' : 'trigger'
          );
      }}
      {...props}
    >
      <ContextMenu.Trigger
        css={contextMenuContainer(height)}
        className="fs-exclude"
      >
        <MonacoSQLEditor
          readOnly={readOnly}
          ref={(val) => {
            sqlEditorRef.current = val;
            if (editorRef) {
              editorRef.current = val;
            }
          }}
          autocompleteOptions={autocompleteOptions}
          autofocus
          editorState={editorState}
          formatQuery={formatQuery}
          onChange={(value) => {
            const queryVariables = extractQueryVariables(value);
            const sql = value;

            if (onChange) {
              onChange({ queryVariables, sql });
            }
          }}
          onSave={
            onSave ? () => onSave(sqlEditorRef?.current?.getText()) : undefined
          }
          onStateChange={(newState: unknown) => {
            const editorState = newState as EditorState;
            setEditorState(editorState);

            if (newState && sqlEditorRef.current) {
              const selectedText = sqlEditorRef.current
                .getSelectedText()
                ?.trim();
              if (onSelectionChange) {
                onSelectionChange(selectedText);
              }
            }
          }}
          runQuery={runQuery}
          runStatementAt={(statement) => {
            if (runSQL) {
              runSQL(statement);
            }
          }}
          generateSql={(): void => {
            logEvent && logEvent('generateSql', 'click');
            showGptConstructionPopup();
          }}
          scrollPosition={scrollPosition}
          style={{
            height: '100%',
            width: '100%'
          }}
          templates={templateOptions || defaultTemplateOptions}
          value={preloadedQuery}
          sqlVariant="chdb"
          indentWidth={indentWidth}
          logEvent={logEvent}
          errorRegion={errorRegion}
        />
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        {contextMenuOptions.map(({ label, ...optionProps }) => (
          <ContextMenu.Item key="" {...optionProps}>
            {label}
          </ContextMenu.Item>
        ))}
      </ContextMenu.Content>
    </ContextMenu>
  );
}

export default SqlEditorWithAutoComplete;
