import { OrganizationRole } from '@cp/common/protocol/Organization';
import { isEmail } from '@cp/common/utils/ValidationUtils';

import uniqBy from 'lodash/uniqBy';
import xorBy from 'lodash/xorBy';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useOrganizationStateManager } from 'src/organization/organizationState';

export type InvitationRecord = {
  email: string;
  role: OrganizationRole | null;
  error?: string;
};

export interface OnChangeProp extends Partial<InvitationRecord> {
  index: number;
}

interface UseInviteMembersProps {
  records?: Array<InvitationRecord>;
  onSuccess?: () => void;
  onError?: () => void;
  pendingInvitationEmails: Array<string>;
  organizationEmails: Array<string>;
}

export interface onEmailBlurProp {
  email: string;
  index: number;
}

interface UseInviteMembersReturnProps {
  members: Array<InvitationRecord>;
  onChange: (props: OnChangeProp) => void;
  onSubmit: () => Promise<Array<string>>;
  addNewEntry: () => void;
  loading: boolean;
  error?: string;
  onEmailBlur: (props: onEmailBlurProp) => void;
  onEmailFocus: (props: { index: number }) => void;
  onDelete: (props: { index: number }) => void;
  disableSubmit: boolean;
  onCancel: () => void;
}

const getDuplicateEmails = (ipsAccessList: Array<InvitationRecord>): Array<string> => {
  if (ipsAccessList.length > 0) {
    const duplicateIpList = xorBy(ipsAccessList, uniqBy(ipsAccessList, 'email'));
    return map(duplicateIpList, 'email');
  }
  return [];
};

const updateDuplicateError = (members: Array<InvitationRecord>): void => {
  const duplicateList = getDuplicateEmails(members);
  members.forEach((member) => {
    if (duplicateList.includes(member.email) && member.email.length > 0) {
      member.error = 'Duplicate email';
    } else if (member.error === 'Duplicate email') {
      member.error = undefined;
    }
  });
};

const validateDisableButton = (members: Array<InvitationRecord>): boolean => {
  return members.some((member) => {
    if (member.error === undefined && member.email.length === 0 && typeof member.role !== 'string') {
      return false;
    }
    return typeof member.error !== 'undefined' || typeof member.role !== 'string';
  });
};

export const useInviteMembers = ({
  records = [],
  onSuccess,
  onError,
  pendingInvitationEmails,
  organizationEmails
}: UseInviteMembersProps): UseInviteMembersReturnProps => {
  const api = useApiClient();
  const [members, setMembers] = useState<Array<InvitationRecord>>(records ?? []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { currentOrganizationId } = useOrganizationStateManager();

  const onCancel = (): void => {
    setMembers(records);
    setDisableSubmit(true);
    setError(undefined);
  };

  const onEmailBlur = ({ index, email }: onEmailBlurProp): void => {
    setMembers((members) => {
      const newMembers = cloneDeep(members);
      newMembers[index].email = email;
      newMembers[index].error = undefined;

      if (email.length > 0) {
        if (!isEmail(email)) {
          newMembers[index].error = 'Invalid email';
        } else if (pendingInvitationEmails.includes(email)) {
          newMembers[index].error = "There's already pending invitation with that email";
        } else if (organizationEmails.includes(email)) {
          newMembers[index].error = "There's already an existing user with that email";
        }
      }

      updateDuplicateError(newMembers);
      if (newMembers[index].error || newMembers.every((member) => member.email.length === 0)) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(validateDisableButton(newMembers));
      }
      return newMembers;
    });
  };

  const onEmailFocus = ({ index }: { index: number }): void => {
    setMembers((members) => {
      const newMembers = cloneDeep(members);
      newMembers[index].error = undefined;
      return members;
    });
  };

  const onChange = ({ index, role, email }: OnChangeProp): void => {
    setMembers((members) => {
      const newMembers = cloneDeep(members);
      const member = newMembers[index];
      if (role) {
        newMembers[index].role = role;
        setDisableSubmit(validateDisableButton(newMembers));
      }
      newMembers[index].email = email ?? member.email;
      return newMembers;
    });
  };

  const addNewEntry = (): void => {
    setMembers((members) => {
      members.push({
        email: '',
        role: null
      });
      return cloneDeep(members);
    });
  };

  const onDelete = ({ index }: { index: number }): void => {
    setMembers((members) => {
      members.splice(index, 1);
      return cloneDeep(members);
    });
  };

  useEffect(() => {
    setMembers(records);
  }, [records]);

  const onSubmit = async (): Promise<Array<string>> => {
    setLoading(true);
    setError(undefined);
    try {
      const emails: Array<string> = [];
      const roles: Array<OrganizationRole> = [];
      members.forEach((record) => {
        if (record.email.length === 0) {
          return;
        }
        if (record.role === null) {
          throw new Error('Please choose a valid role');
        } else {
          roles.push(record.role);
        }
        if (record.email.length > 0) {
          emails.push(record.email);
        }
      });

      await api.organization.inviteToOrganization(emails, roles, currentOrganizationId);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      onCancel();
      return emails;
    } catch (error) {
      const invitationErrorMessage = errorMessage(error);
      setError(invitationErrorMessage);
      if (typeof onError === 'function') {
        setMembers(records);
        onError();
      }
      setDisableSubmit(true);
      throw invitationErrorMessage;
    } finally {
      setLoading(false);
    }
  };

  return {
    members,
    onChange,
    onSubmit,
    addNewEntry,
    loading,
    error,
    onEmailBlur,
    onEmailFocus,
    disableSubmit,
    onCancel,
    onDelete
  };
};
