import { useState } from 'react';
import { Draft } from 'immer';
import {
  Alert,
  Button,
  Container,
  Icon,
  Link,
  NumberField,
  PasswordField,
  RadioGroup,
  Spacer,
  Text,
  TextField
} from '@clickhouse/click-ui';

import {
  cdcReplicationMode,
  ClickPipePostgresImport,
  PostgresConnection,
  PostgresImport
} from 'shared/src/dataLoading/types';
import { useApiClient } from 'src/lib/controlPlane/client';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { getPostgresConfigWithCredentials } from 'src/lib/dataLoading/clickpipes/postgres';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';

type ConnectionStatus = 'unknown' | 'error' | 'success';
type FlattenedFieldKey = 'integrationName' | 'description';

type FieldKey =
  | FlattenedFieldKey
  | keyof PostgresConnection
  | 'replicationMode';

const isPresent = (value: string | undefined): boolean =>
  typeof value === 'string' && value.length > 0;

const defaultErrorState: Record<string, string | undefined> = {};

function validateConnectionObject(
  importModel: ClickPipePostgresImport
): Map<FieldKey, string> {
  const { auth } = importModel.data;
  const errors: Map<FieldKey, string> = new Map();
  if (!isPresent(auth.host)) {
    errors.set('host', 'Field is required');
  }
  if (!isPresent(auth.port?.toString())) {
    errors.set('port', 'Field is required');
  }
  if (!isPresent(auth.username)) {
    errors.set('username', 'Field is required');
  }
  if (!isPresent(auth.password)) {
    errors.set('password', 'Field is required');
  }
  if (!isPresent(auth.database)) {
    errors.set('database', 'Field is required');
  }

  return errors;
}

const docsLink =
  'https://clickhouse.com/docs/en/integrations/clickpipes/postgres';

export function SetupPostgresConnection(): JSX.Element | null {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const serviceId = getCurrentServiceIdOrFail();
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>('unknown');
  const [connectionError, setConnectionError] = useState<string | undefined>(
    undefined
  );
  const [errors, setErrors] = useState(defaultErrorState);
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipePostgresImport>();

  const data = importModel.data;

  const onFieldChangeHandler =
    (field: FlattenedFieldKey, trim: boolean = false) =>
    (value: string) => {
      setConnectionStatus('unknown');
      setError(field, undefined);
      updateImportModel<Draft<PostgresImport>>((model) => {
        const newValue = trim ? value.trim() : value;
        model.data[field] = newValue;
      });
    };

  const setIntegrationName = onFieldChangeHandler('integrationName');
  const setDescription = onFieldChangeHandler('description');

  const setReplicationMode = (value: string): void => {
    setConnectionStatus('unknown');
    updateImportModel<Draft<PostgresImport>>((model) => {
      model.data.cdcSettings.replicationMode = value as cdcReplicationMode;
    });
  };

  const setAuthSetting = (field: FieldKey, value: string | number): void => {
    setConnectionStatus('unknown');
    setError(field, undefined);
    updateImportModel<Draft<PostgresImport>>((model) => {
      const auth = model.data.auth as PostgresConnection;
      model.data.auth = { ...auth, [field]: value };
    });
  };

  const setError = (key: FieldKey, error: string | undefined): void => {
    setErrors((oldErrors) => {
      const newErrors = { ...oldErrors };
      error === undefined ? delete newErrors[key] : (newErrors[key] = error);
      return newErrors;
    });
  };

  const validateModel = (): boolean => {
    let valid = true;

    if (!isPresent(importModel.data.integrationName)) {
      valid = false;
      setError('integrationName', 'Integration name is required');
    }

    if (!isPresent(importModel.data.cdcSettings.replicationMode)) {
      valid = false;
      setError('replicationMode', 'Replication mode is required');
    }

    const validationErrors = validateConnectionObject(importModel);
    for (const [field, message] of validationErrors.entries()) {
      setError(field, message);
    }

    return valid && validationErrors.size === 0;
  };

  const validateConnection = async (): Promise<boolean> => {
    try {
      const valid = validateModel();
      if (valid) {
        const request = getPostgresConfigWithCredentials(importModel);
        const valid = await api.checkPostgresConnection(request, serviceId);
        if ('error' in valid) {
          setConnectionError("Can't connect to the data source.");
          setConnectionStatus('error');
          return false;
        }
        setConnectionError(undefined);
        setConnectionStatus('success');
        return true;
      }
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message ?? "Can't connect to the data source.";
        setConnectionError(message);
        setConnectionStatus('error');
      } else {
        setConnectionError("Can't connect to the data source.");
        setConnectionStatus('error');
      }
    }

    return false;
  };

  const nextStep = async (): Promise<void> => {
    setLoading(true);
    const valid = await validateConnection();
    if (!valid) {
      setLoading(false);
      return;
    }
    updateImportModel<Draft<PostgresImport>>((model) => {
      model.data.step = 2;
    });
    setLoading(false);
  };

  const back = (): void => {
    updateImportModel((draft: Draft<ClickPipePostgresImport>) => {
      draft.data.step = 0;
    });
  };

  const isNextStepDisabled = loading || connectionStatus === 'error';
  return (
    <Container orientation="vertical" gap="md">
      <Text>
        If you need any help to connect your Postgres database,{' '}
        <Link href={docsLink} target="_blank" rel="noreferrer">
          you can check out our documentation.
        </Link>
      </Text>
      <Container orientation="vertical" gap="md" maxWidth="800px">
        <TwoColsContainer>
          <TextField
            label="Integration name"
            error={errors['integrationName']}
            name="integrationName"
            value={data.integrationName ?? ''}
            onChange={setIntegrationName}
            placeholder="My new Postgres pipe"
            data-testid="integration-name"
            autoComplete="off"
            className="fs-exclude"
          />
          <TextField
            label="Description"
            error={errors['description']}
            name="description"
            value={data.description ?? ''}
            onChange={setDescription}
            placeholder="Custom Postgres ingestion"
            data-testid="description"
            autoComplete="off"
            className="fs-exclude"
          />
        </TwoColsContainer>
        <TextField
          label="Host"
          error={errors['host']}
          name="host"
          value={data.auth?.host}
          onChange={(value) => setAuthSetting('host', value)}
          data-testid="clickpipes-postgres-host"
          autoComplete="off"
          placeholder="Your Postgres host. ex:clickpipes.us-east-1.rds.amazonaws.com"
          className="fs-exclude"
        />
        <NumberField
          loading={false}
          label="Port"
          error={errors['port']}
          name="port"
          value={data.auth?.port}
          onChange={(value) => setAuthSetting('port', value)}
          data-testid="clickpipes-postgres-port"
          autoComplete="off"
          className="fs-exclude"
        />
        <Container orientation="vertical" gap="xs">
          <TextField
            label="User"
            error={errors['user']}
            name="user"
            value={data.auth?.username}
            onChange={(value) => setAuthSetting('username', value)}
            data-testid="clickpipes-postgres-username"
            autoComplete="off"
            placeholder="Input your username"
            className="fs-exclude"
          />
          <Text size="sm" color="default">
            This user should have enough permissions to do the replication job
            and list publications
          </Text>
        </Container>
        <PasswordField
          label="Password"
          error={errors['password']}
          name="password"
          value={data.auth?.password ?? ''}
          onChange={(value) => setAuthSetting('password', value)}
          data-testid="clickpipes-postgres-password"
          autoComplete="off"
          placeholder="Input your password"
          className="fs-exclude"
        />
        <TextField
          label="Database"
          error={errors['database']}
          name="database"
          value={data.auth?.database ?? ''}
          onChange={(value) => setAuthSetting('database', value)}
          data-testid="clickpipes-postgres-database"
          autoComplete="off"
          placeholder="Input your database. ex:postgres"
          className="fs-exclude"
        />
        <Text size="sm" weight="medium" style={{ color: '#696e79' }}>
          Replication method
        </Text>
        <RadioGroup
          onValueChange={setReplicationMode}
          value={data.cdcSettings.replicationMode}
        >
          <RadioGroup.Item
            value="snapshot"
            label="Initial load only"
            data-testid="clickpipes-postgres-snapshot-option"
          />
          <RadioGroup.Item
            value="cdc"
            label="Initial load + CDC"
            data-testid="clickpipes-postgres-cdc-option"
          />
        </RadioGroup>
        {!loading && connectionStatus === 'error' && connectionError && (
          <Alert
            text={connectionError}
            state="danger"
            size="medium"
            showIcon={false}
          />
        )}
      </Container>
      <Spacer size="xs" />
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          <Icon name="arrow-left" /> Previous
        </Button>
        <Button
          onClick={(): void => void nextStep()}
          loading={loading}
          disabled={isNextStepDisabled}
          data-testid="setup-conn-next-step"
        >
          Next <Icon name="arrow-right" />
        </Button>
      </Container>
      <Spacer size="xs" />
    </Container>
  );
}
