import { ReactElement } from 'react';
import GridLayout from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { NUM_COLUMNS, ROW_HEIGHT } from 'shared/src/dashboard';
import {
  Dashboard,
  DashboardConfig,
  DashboardObjectPosition
} from 'shared/src/types/dashboard';
import DashboardElement from 'src/components/Dashboards/DashboardView/DashboardElement';
import {
  DashboardElementContainer,
  DashboardElementId,
  DragHandleIcon
} from 'src/components/Dashboards/DashboardView/styles';
import { useOpenEditDashboardObjectFlyout } from 'src/dashboard/dashboardController';
import { useEditingDashboardObject } from 'src/state/dashboard';

interface DashboardProps {
  dashboard: Dashboard;
  editable: boolean;
  updateDashboardConfig: (
    dashboardId: string,
    config: DashboardConfig
  ) => Promise<DashboardConfig>;
  width: number;
}

export default function Dashboard({
  dashboard,
  editable,
  updateDashboardConfig,
  width
}: DashboardProps): ReactElement {
  const setEditDashboardObject = useOpenEditDashboardObjectFlyout();
  const editingObject = useEditingDashboardObject();
  const dashboardItems = Object.entries(dashboard.config.dashboardObjects).map(
    ([objectId, object]) => {
      return (
        <DashboardElementContainer
          key={objectId}
          onClick={(e) => {
            if (!editable) {
              return;
            }

            e.stopPropagation();
            setEditDashboardObject(objectId);
          }}
          selected={objectId === editingObject?.id}
        >
          {editable && (
            <DragHandleIcon
              className="dragHandle"
              name="dots-vertical-double"
            />
          )}
          {editable && (
            <DashboardElementId>{object.config.elementId}</DashboardElementId>
          )}
          <DashboardElement
            id={object.id}
            config={object.config}
            dashboardId={dashboard.id}
          />
        </DashboardElementContainer>
      );
    }
  );

  const dashboardLayout = Object.entries(
    dashboard.config.dashboardObjectPositions
  ).map(([objectId, { x, y, w, h }]) => {
    return {
      i: objectId,
      x,
      y,
      w,
      h
    };
  });

  const onLayoutChange = async (layout: GridLayout.Layout[]) => {
    if (!editable) {
      return;
    }

    const newDashboardObjectPositions = layout.reduce(
      (objectPositions, { i, x, y, w, h }) => {
        objectPositions[i] = {
          x,
          y,
          w,
          h
        };

        return objectPositions;
      },
      {} as Record<string, DashboardObjectPosition>
    );

    try {
      await updateDashboardConfig(dashboard.id, {
        ...dashboard.config,
        dashboardObjectPositions: newDashboardObjectPositions
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GridLayout
      className="layout"
      cols={NUM_COLUMNS}
      isDraggable={editable}
      isDroppable={editable}
      isResizable={editable}
      draggableHandle=".dragHandle"
      layout={dashboardLayout}
      margin={[20, 20]}
      onLayoutChange={onLayoutChange}
      rowHeight={ROW_HEIGHT}
      width={width}
    >
      {dashboardItems}
    </GridLayout>
  );
}
