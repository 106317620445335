import {
  ButtonGroup,
  ButtonGroupProps,
  Container,
  Separator,
  Table,
  TableHeaderType,
  Title
} from '@clickhouse/click-ui';
import { ReactElement, useMemo, useState } from 'react';
import SupportCaseEmpty from 'src/components/SupportList/SupportCaseEmpty';
import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import {
  ButtonGroupSelectType,
  SortType,
  TableRowsType,
  filterRows
} from 'src/components/SupportList/SupportCaseListContainerRows';

type ButtonGroupElementProps = ButtonGroupProps['options'];

const supportDefaultHeaders: Array<TableHeaderType> = [
  {
    id: 'caseNumber',
    label: 'Case Number',
    isSortable: true,
    sortPosition: 'start',
    width: '130px'
  },

  {
    id: 'subject',
    label: 'Subject',
    isSortable: true,
    sortPosition: 'start'
  },
  {
    id: 'priority',
    label: 'Severity',
    isSortable: true,
    sortPosition: 'start',
    width: '100px'
  },
  {
    id: 'status',
    label: 'Status',
    isSortable: true,
    sortPosition: 'start',
    width: '100px'
  },
  {
    id: 'primaryClickHouseContactName',
    label: 'ClickHouse Contact',
    isSortable: true,
    sortPosition: 'start',
    width: '180px'
  },
  {
    id: 'lastModifiedDate',
    label: 'Last Updated',
    isSortable: true,
    sortDir: 'asc',
    sortPosition: 'start',
    width: '150px'
  }
];

interface Props {
  supportCases: Array<SupportCaseDetails>;
}
function SupportCaseListContainer({ supportCases }: Props): ReactElement {
  const [supportHeaders, setSupportHeaders] = useState<Array<TableHeaderType>>(
    supportDefaultHeaders
  );
  const [buttonGroupSelected, setButtonGroupSelected] =
    useState<ButtonGroupSelectType>('open');
  const [sort, setSort] = useState<SortType>({
    elementIndex: 4,
    direction: 'desc'
  });
  const options: ButtonGroupElementProps = useMemo(() => {
    const closedLength = supportCases.filter(
      (row) => row.status === 'CLOSED'
    ).length;
    return [
      {
        value: 'view_all',
        label: `View all ${supportCases.length}`
      },
      {
        value: 'open',
        label: `Open ${supportCases.length - closedLength}`
      },
      {
        value: 'closed',
        label: `Closed ${closedLength}`
      }
    ];
  }, [supportCases]);

  const filteredRows: TableRowsType = useMemo(() => {
    return filterRows(supportCases, buttonGroupSelected, sort);
  }, [buttonGroupSelected, supportCases, sort]);

  const onSort = (
    sortDir: TableHeaderType['sortDir'],
    _: TableHeaderType,
    index: number
  ): void => {
    setSupportHeaders((supportHeaders) => {
      return supportHeaders.map((header, headerIndex) => {
        header.sortDir = headerIndex === index ? sortDir : undefined;
        return header;
      });
    });

    setSort({
      elementIndex: index,
      direction: sortDir ?? 'asc'
    });
  };

  return (
    <Container orientation="vertical">
      <Container justifyContent="space-between" gap="xs">
        <MaxWidthContainer justifyContent="space-between" padding="lg" gap="md">
          <Title type="h2" size="md">
            Support cases
          </Title>
          <ButtonGroup
            options={options}
            selected={buttonGroupSelected}
            onClick={(value: string) => {
              setButtonGroupSelected(value as ButtonGroupSelectType);
            }}
          />
        </MaxWidthContainer>
      </Container>
      <Separator size="xs" />
      <MaxWidthContainer justifyContent="space-between" gap="xs" padding="lg">
        {filteredRows.length > 0 ? (
          <Table headers={supportHeaders} rows={filteredRows} onSort={onSort} />
        ) : (
          <SupportCaseEmpty />
        )}
      </MaxWidthContainer>
    </Container>
  );
}

export default SupportCaseListContainer;
