import React from 'react';

import { css } from '@emotion/react';

const legendStyle = css`
  margin-top: 15px;
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  user-select: inherit;
`;

const entryStyle = (theme) => css`
  margin-right: 31px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  text-align: right;
  color: ${theme.colors.c4};
  fill: ${theme.colors.c4};
  user-select: inherit;
`;

const entryColorStyle = (color) => css`
  margin-right: 10px;

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${color};
  user-select: inherit;
`;

export default function PrimitiveLegend(props) {
  const { payload } = props;

  return (
    <div css={legendStyle}>
      {payload.map((entry, index) => (
        <div css={entryStyle} key={index}>
          <div css={entryColorStyle(entry.color)} />
          <div>{entry.value}</div>
        </div>
      ))}
    </div>
  );
}
