import { SupportCasePriority } from '@cp/common/protocol/Support';
import isEmpty from 'lodash/isEmpty';
import { controlPlaneHost } from 'src/lib/controlPlane/apiUrls';
import { placehoderParamsToUrlParams } from 'src/lib/routes/usePlaceholderParams';
import { NotificationTabs } from 'src/pages/NotificationsPage/tabs';

export interface SupportParams {
  subject?: string;
  severity?: SupportCasePriority;
}

export type ApiKeysRouteParams = {
  orgId: string;
};

export type ControlPlaneSettingsRouteParams = {
  serviceId: string;
};

export type DashboardsRouteParams = {
  serviceId: string;
};

export type ServiceScopeRouteParams = {
  serviceId: string;
};
export type ServiceScopeOptionalRouteParams = {
  serviceId?: string;
};

export type OrgScopeRouteParams = {
  orgId: string;
};

export type TablesRouteParams = ServiceScopeRouteParams & {
  databaseName: string;
  tableName: string;
};

export type QueryRouteParams = ServiceScopeRouteParams & {
  queryId: string;
  placeholderParams?: Record<string, string>;
};

export type ImportRouteParams = ServiceScopeRouteParams & {
  importId: string;
};
export type SupportCaseScopeRouteParams = ServiceScopeRouteParams & {
  caseId: string;
};

const getServiceConsoleUrl = (serviceId: string): string => {
  const isUnified = localStorage.getItem('isUnified');
  return `/services/${serviceId}/${isUnified === 'true' ? 'console/' : ''}`;
};
export const routes = {
  apiKeysPage({ orgId }: ApiKeysRouteParams): string {
    return `/organizations/${orgId}/keys`;
  },
  controlPlaneSettings({ serviceId }: ControlPlaneSettingsRouteParams): string {
    return controlPlaneHost(`/service/${serviceId}/settings`);
  },
  dashboards({ serviceId }: DashboardsRouteParams): string {
    return `/services/${serviceId}/dashboards`;
  },
  tables({ serviceId, databaseName, tableName }: TablesRouteParams): string {
    return `${getServiceConsoleUrl(serviceId)}database/${databaseName}/table/${tableName}`;
  },
  query({ serviceId, queryId, placeholderParams }: QueryRouteParams): string {
    const queryVariables = placeholderParams ? `?${placehoderParamsToUrlParams(placeholderParams)}` : '';
    return `${getServiceConsoleUrl(serviceId)}query/${queryId}${queryVariables}`;
  },
  createTable({ serviceId }: ServiceScopeRouteParams): string {
    return `${getServiceConsoleUrl(serviceId)}createTable`;
  },
  import({ serviceId, importId }: ImportRouteParams): string {
    return `/services/${serviceId}/imports/${importId}`;
  },
  imports({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/imports`;
  },
  home({ serviceId }: ServiceScopeRouteParams): string {
    return getServiceConsoleUrl(serviceId);
  },
  console({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/console`;
  },
  backups({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/backups`;
  },
  serviceSettings({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/settings`;
  },
  health({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/health`;
  },
  queryInsights({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/queryInsights`;
  },
  login(): string {
    return '/signIn';
  },
  services(): string {
    return '/services';
  },
  createNewService(): string {
    return '/createNewService';
  },
  learn({ serviceId }: { serviceId?: string | null }): string {
    if (typeof serviceId === 'string') {
      return `/services/${serviceId}/learn`;
    }
    return '/learn';
  },
  membersPage({ orgId }: OrgScopeRouteParams): string {
    return `/organizations/${orgId}/members`;
  },
  privateEndpointsPage({ orgId }: OrgScopeRouteParams): string {
    return `/organizations/${orgId}/private-endpoints`;
  },
  billingHome({ orgId }: OrgScopeRouteParams): string {
    return `/organizations/${orgId}/billing`;
  },
  billingUsage({ orgId }: OrgScopeRouteParams): string {
    return `/organizations/${orgId}/usage`;
  },
  auditPage({ orgId }: OrgScopeRouteParams): string {
    return `/organizations/${orgId}/audit`;
  },
  orgDetails({ orgId }: OrgScopeRouteParams): string {
    return `/organizations/${orgId}`;
  },
  support({ serviceId }: { serviceId?: string | null }): string {
    if (typeof serviceId === 'string') {
      return `/services/${serviceId}/support`;
    }
    return '/support';
  },
  supportCase({ serviceId, caseId }: { serviceId?: string | null; caseId: string }): string {
    if (typeof serviceId === 'string') {
      return `/services/${serviceId}/support/${caseId}`;
    }
    return `/support/${caseId}`;
  },
  newSupportCase({ serviceId, params = {} }: { serviceId?: string | null; params?: SupportParams }): string {
    let url = '';
    if (typeof serviceId === 'string') {
      url = `/services/${serviceId}/support/newCase`;
    } else {
      url = '/support/newCase';
    }

    if (!isEmpty(params ?? {})) {
      const urlQueryParams = new URLSearchParams({});
      Object.entries(params ?? {}).forEach(([key, value]) => {
        urlQueryParams.append(key, value as string);
      });
      url += `?${urlQueryParams.toString()}`;
    }
    return url;
  },
  notificationsPage({ orgId }: OrgScopeRouteParams, tab?: NotificationTabs): string {
    const searchParam = tab ? `?tab=${tab}` : '';
    return `/organizations/${orgId}/notifications${searchParam}`;
  },
  serviceList(): string {
    return '/services';
  },
  warehouses(): string {
    return '/warehouses';
  },
  service({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}`;
  },
  root(params?: URLSearchParams | Record<string, string>): string {
    const urlParams = params instanceof URLSearchParams ? params : new URLSearchParams(params ?? {});
    return `/${urlParams.toString()}`;
  },
  integrations({ serviceId }: { serviceId?: string | null }): string {
    if (typeof serviceId === 'string') {
      return `/services/${serviceId}/integrations`;
    }
    return '/integrations';
  },
  integration({ serviceId, integrationId }: { serviceId?: string | null; integrationId: string }): string {
    if (typeof serviceId === 'string') {
      return `/services/${serviceId}/integrations/${integrationId}`;
    }
    return `/integrations/${integrationId}`;
  },
  gettingStarted({ serviceId }: ServiceScopeRouteParams): string {
    return `/services/${serviceId}/gettingStarted`;
  }
};
