import { atom, useAtomValue, useSetAtom } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import objectSet from 'lodash/set';
import objectGet from 'lodash/get';

const EMPTY_DASHBOARD_STATE = {
  states: {},
  outputs: {}
};

export type DashboardState = {
  states: Record<string, Record<string, unknown>>;
  outputs: Record<string, Record<string, unknown>>;
};

export const dashboardStateAtom = atom<Record<string, DashboardState>>({});
const updateDashboardStateAtom = atom(
  null,
  (get, set, dashboardId: string, objectId: string, newState: Record<string, unknown>) => {
    const dashboardState = get(dashboardStateAtom);

    const newDashboardState = cloneDeep(dashboardState[dashboardId] || EMPTY_DASHBOARD_STATE);
    const currentObjectState = objectGet(newDashboardState, `states.${objectId}`);
    const mergedObjectState = {
      ...currentObjectState,
      ...newState
    };
    objectSet(newDashboardState, `states.${objectId}`, mergedObjectState);

    set(dashboardStateAtom, {
      ...dashboardState,
      [dashboardId]: newDashboardState
    });
  }
);
const updateDashboardOutputAtom = atom(
  null,
  (get, set, dashboardId: string, key: string, newOutput: Record<string, unknown>) => {
    const dashboardState = get(dashboardStateAtom);

    const newDashboardOutput = cloneDeep(dashboardState[dashboardId] || EMPTY_DASHBOARD_STATE);
    const currentObjectOutput = objectGet(newDashboardOutput, `outputs.${key}`);
    const mergedObjectOutput = {
      ...currentObjectOutput,
      ...newOutput
    };
    objectSet(newDashboardOutput, `outputs.${key}`, mergedObjectOutput);

    set(dashboardStateAtom, {
      ...dashboardState,
      [dashboardId]: newDashboardOutput
    });
  }
);

type UseDashboardObjectState = {
  dashboardId: string;
  objectId: string;
};

export function useDashboardObjectState({ dashboardId, objectId }: UseDashboardObjectState): Record<string, unknown> {
  const state = useAtomValue(dashboardStateAtom);
  const dashboardState = state[dashboardId] || {
    states: {}
  };
  return dashboardState.states[objectId] || {};
}

type UseDashboardState = {
  dashboardId: string;
};

export function useDashboardState({ dashboardId }: UseDashboardState): Record<string, Record<string, unknown>> {
  const state = useAtomValue(dashboardStateAtom);
  const dashboardState = state[dashboardId] || {
    states: {},
    outputs: {}
  };
  return dashboardState.states;
}

type UseDashboardOutputs = {
  dashboardId: string;
};

export function useDashboardOutputs({ dashboardId }: UseDashboardOutputs): Record<string, Record<string, unknown>> {
  const state = useAtomValue(dashboardStateAtom);
  const dashboardState = state[dashboardId] || {
    states: {},
    outputs: {}
  };
  return dashboardState.outputs;
}

interface UseUpdateDashboardObjectState {
  dashboardId: string;
  objectId: string;
}

export function useUpdateDashboardObjectState({
  dashboardId,
  objectId
}: UseUpdateDashboardObjectState): (newState: Record<string, unknown>) => void {
  const updateState = useSetAtom(updateDashboardStateAtom);
  return (newState: Record<string, unknown>) => {
    updateState(dashboardId, objectId, newState);
  };
}

interface UseUpdateDashboardFilterState {
  dashboardId: string;
}

export function useUpdateDashboardFilterState({
  dashboardId
}: UseUpdateDashboardFilterState): (field: string, newState: Record<string, unknown>) => void {
  const updateState = useSetAtom(updateDashboardStateAtom);
  return (field: string, newState: Record<string, unknown>) => {
    updateState(dashboardId, field, newState);
  };
}

interface UseUpdateDashboardObjectOutput {
  dashboardId: string;
  objectId: string;
}

export function useUpdateDashboardObjectOutput({
  dashboardId,
  objectId
}: UseUpdateDashboardObjectOutput): (newOutput: Record<string, unknown>) => void {
  const updateOutput = useSetAtom(updateDashboardOutputAtom);
  return (newOutput: Record<string, unknown>) => {
    updateOutput(dashboardId, objectId, newOutput);
  };
}

interface UseUpdateDashboardFilterOutput {
  dashboardId: string;
}

export function useUpdateDashboardFilterOutput({
  dashboardId
}: UseUpdateDashboardFilterOutput): (field: string, newOutput: Record<string, unknown>) => void {
  const updateOutput = useSetAtom(updateDashboardOutputAtom);
  return (field: string, newOutput: Record<string, unknown>) => {
    updateOutput(dashboardId, field, newOutput);
  };
}
