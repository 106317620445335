import { IconButton } from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';

const BaseButton = styled.button<{
  $copied: boolean;
  $error: boolean;
}>`
  ${({ $copied, $error, theme }): string =>
    $copied || $error
      ? `
   color: ${
     $copied
       ? theme.click.alert.color.text.success
       : theme.click.alert.color.text.danger
   };
   `
      : ''};
`;

type CopyButtonProps = {
  text: string;
};

/**
 * A copy icon button that copies the provided text to the clipboard.
 */
const CopyButton = ({ text }: CopyButtonProps): ReactElement => {
  const [copied, setCopied] = useState(false);
  const [errorCopy, setErrorCopy] = useState(false);

  const copyCodeToClipboard = async (): Promise<void> => {
    if (!text) return;
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setErrorCopy(true);
      setTimeout(() => setErrorCopy(false), 2000);
    }
  };

  return (
    <BaseButton
      as={IconButton}
      $copied={copied}
      $error={errorCopy}
      type="ghost"
      icon={copied ? 'check' : errorCopy ? 'warning' : 'copy'}
      onClick={(): void => {
        void copyCodeToClipboard();
      }}
      data-testid="copy-button"
    />
  );
};

export default CopyButton;
