import { css, SerializedStyles, Theme } from '@emotion/react';

export const container = (theme: Theme): SerializedStyles => css`
  background: ${theme.global.color.background.muted};
  border: 1px solid ${theme.global.color.stroke.default};
  color: ${theme.global.color.text.default};

  box-shadow: ${theme.shadows.popover};
  outline: none;
  min-height: 50px;
  border-radius: 4px;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;

  max-height: none;
  width: 350px;
`;

export const headerContainer = (theme: Theme): SerializedStyles => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid ${theme.global.color.stroke.default};
  color: ${theme.global.color.text.default};
`;
export const headerText = css`
  width: 100%;
  display: grid;
  grid-template-columns: 16px 1fr;
  font-weight: 500;
  gap: 10px;
  align-items: center;
`;

export const contentStyle = css`
  padding: 12px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: auto;
  .content-info {
    width: fit-content;
  }
`;
