import { useState } from 'react';
import { Draft } from 'immer';
import { createToast } from 'primitives';
import HighlightedIcon from 'src/components/ImportView/ClickPipesImportForm/DetailsAndSettings/components/HighlightedIcon';
import PostgresAlertConfiguration from 'src/components/ImportView/ClickPipesImportForm/DetailsAndSettings/PostgresAlertConfiguration';
import { routes } from 'src/lib/routes';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import {
  ClickPipeImport,
  isClickPipesImport,
  isClickPipesPostgresImport
} from 'shared/src/dataLoading';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { ClickPipeModelWrapper } from 'types/protocol';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { RoleSelector } from 'src/components/RoleSelector';
import {
  Text,
  Button,
  Link,
  Panel,
  Container,
  Title
} from '@clickhouse/click-ui';
import { css } from '@emotion/react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { ROLE_CLICKPIPES_DESTINATION_ONLY } from 'shared/src/clickpipes';
import { defaultAdminRole } from 'shared/src/roles';

const panelStyle = css({
  flexWrap: 'nowrap',
  alignItems: 'start'
});

export default function DetailsAndSettings(): JSX.Element | null {
  const [loading, setLoading] = useState(false);
  const { importModel, updateImportModel, resetImportModel } =
    useClickPipesImport<ClickPipeImport>();

  const serviceId = getCurrentServiceIdOrFail();

  const defaultRoles = [
    {
      value: defaultAdminRole,
      label: 'Full access'
    }
  ];

  const api = useApiClient();
  if (!isClickPipesImport(importModel)) {
    return null;
  }

  function setRole(value: string[]): void {
    updateImportModel((draft: Draft<ClickPipeImport>) => {
      draft.data.ingestRoleId = value;
    });
  }

  const previousStep = (): void => {
    updateImportModel((draft: Draft<ClickPipeImport>) => {
      draft.data.step = 3;
    });
  };

  const createClickPipe = async (): Promise<void> => {
    try {
      setLoading(true);
      const modelToSend = { ...importModel, data: { ...importModel.data } };
      modelToSend.data.rowsPreview = [];

      await api.createClickPipe({
        serviceId,
        model: modelToSend as ClickPipeModelWrapper['model']
      });

      createToast('Success', 'success', 'Successfully created ClickPipe');
      resetImportModel();
      navigateTo(routes.imports({ serviceId }));
    } catch (error) {
      createToast('Error', 'alert', errorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      orientation="vertical"
      gap="md"
      maxWidth="800px"
      data-testid={'clickpipes-details'}
    >
      <Panel fillWidth hasBorder orientation="horizontal" css={panelStyle}>
        <HighlightedIcon
          name="lock"
          width="auto"
          height="3em"
          data-testid="lock-icon"
        />

        <Container orientation="vertical" fillWidth={true} gap="md">
          <Title type="h2">Permissions</Title>

          <Text color="muted">
            ClickPipes will set up a dedicated database user for data writing.
            You can select a role for this user. For materialized views or
            dictionary access from the destination table, opt for full access or
            a role with the necessary permissions.
          </Text>

          <Container orientation="vertical">
            <RoleSelector
              onSelect={setRole}
              extraRoles={[
                ...defaultRoles,
                ...(!isClickPipesPostgresImport(importModel)
                  ? [
                      {
                        value: ROLE_CLICKPIPES_DESTINATION_ONLY, // doesn't apply to multiple destination tables
                        label: 'Only destination'
                      }
                    ]
                  : [])
              ]}
            />
          </Container>
        </Container>
      </Panel>

      {isClickPipesPostgresImport(importModel) && (
        <PostgresAlertConfiguration />
      )}

      <Container gap="md">
        <Text>
          If you need any help to connect your source,{' '}
          <Link
            href="https://clickhouse.com/docs/en/integrations/clickpipes"
            target="_blank"
            rel="noreferrer"
          >
            access the documentation
          </Link>
          for more details.
        </Text>
      </Container>

      <Container orientation="horizontal" gap="md">
        <Button type="secondary" onClick={previousStep}>
          Back
        </Button>
        <Button
          disabled={!importModel?.data?.ingestRoleId?.length}
          onClick={(): void => {
            createClickPipe().catch(console.error);
          }}
          loading={loading}
          data-testid="clickpipes-complete-setup"
        >
          Complete Setup
        </Button>
      </Container>
    </Container>
  );
}
