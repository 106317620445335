import { LogsInitConfiguration } from '@datadog/browser-logs/cjs/domain/configuration';
import apiUrl from 'src/lib/config/apiUrl';
import ciOverridesFn from 'src/lib/config/ci';
import developmentOverridesFn from 'src/lib/config/development';
import environment from 'src/lib/config/environment';
import localOverridesFn from 'src/lib/config/local';
import productionOverridesFn from 'src/lib/config/production';
import stagingOverridesFn from 'src/lib/config/staging';
import testOverridesFn from 'src/lib/config/test';
import webUrl from 'src/lib/config/webUrl';

export interface Config {
  tackle: {
    awsMarketplaceProdPageUrl: string;
    gcpMarketplaceProdPageUrl: string;
    azureMarketplaceProdPageUrl: string;
  };
  env: 'production' | 'staging' | 'development' | 'local' | 'test';
  superUsers: string[];
  apiUrl: string;
  webUrl: string;
  controlPlane: {
    mocked: boolean;
    clientId: string;
    host: string;
    apiHost: string;
    redirectUri: string;
    cognitoPoolId: string;
  };
  auth0Web: {
    clientId: string;
    domain: string;
    audience: string;
    callbackPath: string;
    auth0domain: string;
    databaseConnectionName: string;
    cookieDomain?: string;
  };
  features: {
    passwordlessAuth: boolean;
    dataLoading: boolean;
    clickPipesPeerDBTile: boolean;
    clickPipesEnableAvroFlatten?: boolean;
  };
  datadog?: {
    rum?: {
      applicationId: string;
      clientToken: string;
      site: string;
      service: string;
      env: string;
      // Specify a version number to identify the deployed version of your application in Datadog
      version: string;
      sampleRate: number;
      sessionReplaySampleRate: number;
      trackInteractions: boolean;
      trackResources: boolean;
      trackLongTasks: boolean;
      defaultPrivacyLevel: 'mask-user-input';
      startSessionReplayRecording: boolean;
    };
    logs?: LogsInitConfiguration;
  };
  growthBook?: {
    clientKey: string;
  };
  querying: {
    useClustering: boolean;
    ignoreAnalyticsQueryInLog: boolean;
  };
  websockets: {
    endpoint: string;
  };
  fullStory: {
    orgId: string;
  };
  recaptcha: {
    siteKey: string | undefined;
  };
  stripe: {
    publishableKey: string;
  };
}

export type OverriderFunction = (config: Config) => Config;
declare global {
  interface Window {
    consoleConfig: any;
    version?: string;
    _env: { [key: string]: string };
  }
}
window._env ||= {};

const envOverridesMapping: Record<string, OverriderFunction> = {
  ci: ciOverridesFn,
  local: localOverridesFn,
  development: developmentOverridesFn,
  staging: stagingOverridesFn,
  test: testOverridesFn,
  production: productionOverridesFn
};

const deployEnv = (window._env.APP_ENV || 'local') as keyof typeof apiUrl;

const defaultConfig: Config = {
  env: 'production',
  superUsers: [
    'fabio.neves@clickhouse.com',
    'kevin.zhang@clickhouse.com',
    'zach.naimon@clickhouse.com',
    'justin.guzman@clickhouse.com',
    'vineeth.asokkumar@clickhouse.com',
    'matt.woenker@clickhouse.com'
  ],
  apiUrl: environment.CLIENT_API_URL || apiUrl[deployEnv],
  webUrl: environment.CLIENT_WEB_URL || webUrl[deployEnv],
  controlPlane: {
    mocked: false,
    clientId: window._env.RW_CONTROL_PLANE_CLIENT_ID || '',
    apiHost: window._env.CONTROL_PLANE_API_HOST || '',
    host: window._env.RW_CONTROL_PLANE_HOST || '',
    redirectUri: window._env.RW_CONTROL_PLANE_REDIRECT_URI || '',
    cognitoPoolId: window._env.RW_CONTROL_PLAN_COGNITO_POOL_ID || ''
  },
  features: {
    passwordlessAuth: false,
    dataLoading: window._env.DATA_LOADING === 'true' || false,
    clickPipesPeerDBTile: window._env.CLICKPIPES_KINESIS === 'true' || false,
    clickPipesEnableAvroFlatten: window._env.CLICKPIPES_AVRO_FLATTEN === 'true' || false
  },
  auth0Web: {
    clientId: window._env.AUTH0_CLIENT_ID || '',
    domain: window._env.AUTH0_DOMAIN || '',
    audience: window._env.AUTH0_AUDIENCE || '',
    callbackPath: window._env.AUTH0_CALLBACK_PATH || '',
    auth0domain: window._env.AUTH0_DOMAIN || '',
    databaseConnectionName: 'ControlPlaneUserDb'
  },
  querying: {
    useClustering: true,
    ignoreAnalyticsQueryInLog: false
  },
  websockets: {
    endpoint: 'wss://q5wbg5hbt9.execute-api.us-east-2.amazonaws.com/production'
  },
  fullStory: {
    orgId: ''
  },
  recaptcha: {
    siteKey: window._env.RECAPTCHA_SITE_KEY ?? '6Lf3eY4hAAAAADHuCPaw1RzIqbyTLm_b5fqWna3B'
  },
  tackle: {
    awsMarketplaceProdPageUrl: 'https://aws.amazon.com/marketplace/pp/prodview-',
    gcpMarketplaceProdPageUrl: 'https://console.cloud.google.com/marketplace/orders',
    azureMarketplaceProdPageUrl: 'https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2'
  },
  stripe: {
    publishableKey:
      'pk_test_51LJ0trEqDIZfA797YyebhaMBghaLRMv4uhRhh1dLJYOFcthvbW7vPt8q7f3EeVehgNwkC5wvz2TAlSJp12kg0K9400zSHN7rLT'
  }
};

const configOverrider = envOverridesMapping[deployEnv];

const config = configOverrider(defaultConfig);

window.consoleConfig = config;
window.version = window._env.APP_VERSION;

export default config;
