import { forwardRef, ReactElement } from 'react';

import PropTypes from 'prop-types';

import HorizontalLoadingIcon from 'src/components/icons/HorizontalLoadingIcon';

import { BtnProps } from 'src/components/primitives/lib/Button/interface';
import { loadingContainer } from 'src/components/primitives/lib/Button/styles';

const Button = forwardRef<HTMLButtonElement, BtnProps>(
  (
    {
      children,
      color = 'white',
      disabled,
      fillWidth = false,
      loading,
      onClick,
      active,
      hasBorder = true,
      ...otherProps
    },
    ref
  ): ReactElement => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        data-active={active}
        data-color={color}
        data-border={hasBorder}
        data-fill-width={fillWidth}
        {...otherProps}
      >
        {loading ? (
          <div css={loadingContainer}>
            <HorizontalLoadingIcon height="8" />
          </div>
        ) : (
          children
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';
Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  color: PropTypes.oneOf([
    'yellow',
    'yellow-extended',
    'white',
    'white-extended',
    'error',
    'transparent'
  ]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  active: PropTypes.bool
};

Button.defaultProps = {
  color: 'white',
  disabled: false,
  loading: false,
  active: false
};

export default Button;
