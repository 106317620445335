import { ReactElement } from 'react';
import { useServiceAlertStateManager } from 'src/alert/serviceAlertState';
import ServiceAlertBanner from 'src/components/AlertBanner/ServiceAlertBanner';

const ServiceAlertBannerContainer = (): ReactElement | null => {
  const {
    isAwake,
    isAsleep,
    isAwaking,
    isStopped,
    isStarting,
    isProvisioning,
    awakeServiceCallback
  } = useServiceAlertStateManager();
  return (
    <ServiceAlertBanner
      isAsleep={isAsleep}
      isAwaking={isAwaking}
      isAwake={isAwake}
      isStopped={isStopped}
      isStarting={isStarting}
      isProvisioning={isProvisioning}
      awakeServiceCallback={awakeServiceCallback}
    />
  );
};

export default ServiceAlertBannerContainer;
