import { Galaxy, GalaxyOptions } from 'galaxy';
import { useEffect, useRef } from 'react';
import { useAuth } from 'src/components/auth';
import { useCurrentInstance } from 'src/instance/instanceController';
import config from 'src/lib/config';
import { useHttpClient } from 'src/lib/http';
import { logger } from 'src/lib/logger';
import { useCurrentOrganization } from 'src/organization/organizationState';
import { getCurrentServiceId } from 'src/state/service';

const logEvent = (event: string): void => {
  logger.track({
    view: 'app',
    component: 'window',
    event,
    interaction: 'trigger'
  });
};

export const useInitGalaxy = (): void => {
  const http = useHttpClient();
  const { isAuthenticated, currentUser } = useAuth();
  const galaxyInitialized = useRef(false);
  const serviceId = useCurrentInstance()?.id;
  const initialized = galaxyInitialized.current;
  const organization = useCurrentOrganization();

  useEffect(() => {
    const galaxyOptions: GalaxyOptions = {
      httpClient: {
        post: async (url: string, requestBody: Record<string, unknown>): Promise<Response> => {
          return http.post(
            url,
            {
              body: JSON.stringify(requestBody)
            },
            { includeAuthProviderHeader: false }
          );
        }
      },
      errorHandler: {
        captureException(exception: unknown): void {
          console.error(exception);
        }
      },
      replaceConsoleLog: false,
      application: 'SQL_CONSOLE',
      tags: ['WEB'],
      apiHost: config.controlPlane.apiHost,
      getUserId: () => currentUser?.id ?? null,
      getContext() {
        const serviceId = getCurrentServiceId();

        const context: Record<string, unknown> = {
          service: serviceId ? { id: serviceId } : undefined
        };

        context['orgId'] = organization?.id || undefined;

        return context;
      }
    };

    const [, stopGalaxy] = Galaxy.init(galaxyOptions);
    galaxyInitialized.current = true;
    return () => {
      galaxyInitialized.current = false;
      void stopGalaxy();
    };
  }, [isAuthenticated, serviceId, currentUser?.id, organization?.id]);

  useEffect(() => {
    if (initialized) {
      window.addEventListener('focus', () => logEvent('focus'));
      window.addEventListener('blur', () => logEvent('blur'));

      logEvent('load'); // here we track page loads

      return () => {
        window.removeEventListener('focus', () => logEvent('focus'));
        window.removeEventListener('blur', () => logEvent('blur'));
      };
    }
  }, [initialized]);
};
