import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AlertBanner from 'src/components/AlertBanner';
import { Container } from '@clickhouse/click-ui';
import OrgSidebar from 'src/layout/OrgSidebar';
import { ResponsiveWrapper } from 'src/layout/SidebarWrapper/SidebarWrapper';

function OrgLayout(): ReactElement {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  return (
    <Container
      orientation="vertical"
      isResponsive={false}
      overflow="hidden"
      fillHeight
    >
      <AlertBanner />
      <ResponsiveWrapper ref={setRef}>
        <Container
          overflow="hidden"
          grow="1"
          fillHeight
          isResponsive={false}
          alignItems="start"
        >
          <Outlet />
        </Container>
        <OrgSidebar container={ref} />
      </ResponsiveWrapper>
    </Container>
  );
}

export default OrgLayout;
