import { ReactElement, useState } from 'react';
import LoadingPanel from 'src/components//LoadingPanel/LoadingPanel';
import { Container, Text as CUIText, Link } from '@clickhouse/click-ui';
import { DataWarehouseServiceCard } from 'src/components/DataWarehouseList/DataWarehouseServiceCard';
import { DataWarehouseCard } from 'src/components/DataWarehouseList/DataWarehouseCard';
import { EmptyDataWarehouse } from 'src/components/DataWarehouseList/EmptyDataWarehouse';
import { RenameDataWarehouseDialog } from 'src/components/DataWarehouseList/RenameDataWarehouseDialog';
import { DeleteDataWarehouseDialog } from 'src/components/DataWarehouseList/DeleteDataWarehouseDialog';
import { useMainSidebar } from 'src/layout/SidebarWrapper/SidebarWrapper';
import { ResetDataWarehousePasswordModal } from 'src/pages/SettingsPage/ResetDataWarehousePasswordModal';
import { useCurrentOrganizationDataWarehousesWithInstances } from 'src/dataWarehouses/controller/dataWarehouseWithInstances';
import { useByocOnboardingStatus } from 'src/lib/byoc/byocOnboardingStatus';
import { ByocProvisioning } from 'src/components/DataWarehouseList/ByocProvisioning';
import { FastUpgradeDialog } from 'src/components/DataWarehouseList/FastUpgradeDialog';

export type DataWarehouseListProps = {
  hasManageServicePermission: boolean;
  isLoading?: boolean;
  organizationId: string;
};

interface FastUpgradeDialogValuesProps {
  dataWarehouseId: string;
}

interface RenameDialogValuesProps {
  dataWarehouseId: string;
  name: string;
}
interface DeleteDialogValuesProps {
  dataWarehouseId: string;
  name: string;
}

export const DataWarehouseList = ({
  hasManageServicePermission
}: DataWarehouseListProps): ReactElement => {
  const [deleteDialogValues, setDeleteDialogValues] = useState<
    undefined | DeleteDialogValuesProps
  >();
  const { isDesktop } = useMainSidebar();
  const [renameDialogValues, setRenameDialogValues] = useState<
    undefined | RenameDialogValuesProps
  >();
  const [fastUpdgradeDialogValues, setFastUpgradeDialogValues] = useState<
    undefined | FastUpgradeDialogValuesProps
  >();
  const byocOnboardingStatus = useByocOnboardingStatus();
  const [resetPasswordWarehouseId, setResetPasswordWarehouseId] = useState<
    undefined | string
  >();
  const {
    isLoading,
    warehouses,
    deleteWarehouse,
    renameWarehouse,
    onFastUpgrade
  } = useCurrentOrganizationDataWarehousesWithInstances();

  if (isLoading) {
    return <LoadingPanel fillWidth text="Loading warehouses" />;
  }

  if (byocOnboardingStatus === 'pending') {
    return <ByocProvisioning />;
  }

  if (warehouses.length === 0) {
    return <EmptyDataWarehouse showCreateButton={hasManageServicePermission} />;
  }

  return (
    <Container
      isResponsive={false}
      orientation="vertical"
      gap="lg"
      maxWidth="800px"
    >
      <CUIText>
        Host your data in a warehouse, use one or more compute services to
        access it. <Link href="#">Learn more</Link>
      </CUIText>
      {warehouses.map((warehouse, index) => (
        <DataWarehouseCard
          id={warehouse.id}
          organizationId={warehouse.organizationId}
          provider={warehouse.cloudProvider}
          regionId={warehouse.regionId}
          title={warehouse.name}
          version={`v${warehouse.clickhouseVersion}`}
          key={warehouse.id}
          openDeleteWarehouse={() => {
            setDeleteDialogValues({
              dataWarehouseId: warehouse.id,
              name: warehouse.name
            });
          }}
          openRenameWarehouse={() => {
            setRenameDialogValues({
              dataWarehouseId: warehouse.id,
              name: warehouse.name
            });
          }}
          onFastUpgrade={() => {
            setFastUpgradeDialogValues({
              dataWarehouseId: warehouse.id
            });
          }}
          instancesCount={warehouse.instances.length}
          isFirst={index === 0}
          releaseChannel={warehouse.releaseChannel}
          openResetDB={() => setResetPasswordWarehouseId(warehouse.id)}
          hasManageServicePermission={hasManageServicePermission}
        >
          {warehouse.instances.map((instance) => (
            <DataWarehouseServiceCard
              title={instance.name}
              instanceTier={instance.instanceTier}
              state={instance.state}
              serviceId={instance.id}
              isPrimary={instance.isPrimary}
              isEncrypted={!!instance.customerManagedEncryption?.keyArn}
              key={instance.id}
              isDesktop={isDesktop}
              onClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              maintenanceStatus={undefined}
              minAutoscalingTotalMemory={
                instance.minAutoScalingTotalMemory ?? 0
              }
              maxAutoscalingTotalMemory={
                instance.maxAutoScalingTotalMemory ?? 0
              }
              numReplicas={instance.minReplicas ?? 0}
              showDelete={!instance.isPrimary || warehouse.instances.length > 1}
              isReadOnly={instance.isReadonly}
            />
          ))}
        </DataWarehouseCard>
      ))}
      <RenameDataWarehouseDialog
        open={typeof renameDialogValues !== 'undefined'}
        onCancel={() => setRenameDialogValues(undefined)}
        onConfirm={async (warehouseName: string): Promise<void> => {
          if (renameDialogValues?.dataWarehouseId) {
            await renameWarehouse({
              dataWarehouseId: renameDialogValues?.dataWarehouseId,
              name: warehouseName
            });
          } else {
            throw new Error('Invalid dataWarehouseId');
          }
        }}
      />
      <DeleteDataWarehouseDialog
        open={typeof deleteDialogValues !== 'undefined'}
        onCancel={() => setDeleteDialogValues(undefined)}
        warehouseName={deleteDialogValues?.name}
        onConfirm={async (): Promise<void> => {
          if (deleteDialogValues?.dataWarehouseId) {
            await deleteWarehouse({
              dataWarehouseId: deleteDialogValues?.dataWarehouseId
            });
          } else {
            throw new Error('Invalid dataWarehouseId');
          }
        }}
      />
      <FastUpgradeDialog
        dataWarehouseId={fastUpdgradeDialogValues?.dataWarehouseId}
        onActivateClick={onFastUpgrade}
        isDialogOpen={fastUpdgradeDialogValues !== undefined}
        onClose={() => setFastUpgradeDialogValues(undefined)}
      />
      <ResetDataWarehousePasswordModal
        dataWarehouseId={resetPasswordWarehouseId ?? ''}
        onClose={() => setResetPasswordWarehouseId(undefined)}
        open={typeof resetPasswordWarehouseId !== 'undefined'}
      />
    </Container>
  );
};
