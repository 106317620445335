import { useMemo, useState } from 'react';

import {
  RunningQuery,
  RunningQueryArgs,
  RunningQueryColumn,
  RunningQueryResult,
  RunningQueryStatusName
} from 'src/lib/query/runningQueryTypes';
import { QueryMetrics, QueryProgress } from 'src/lib/query/streamingQuery';

// This basically replaces the runningQueries redux part of the old app, and
// can be replaced by a drop-in redux-backed implementation eventually

export interface RunningQueryState {
  queries: Record<string, RunningQuery>;
  actions: {
    create: (runId: string, args: RunningQueryArgs) => void;
    delete: (runId: string) => void;
    setStatus: (runId: string, status: RunningQueryStatusName) => void;
    setArgs: (runId: string, args: RunningQueryArgs) => void;
    updateQuery: (runId: string, update: Partial<RunningQuery>) => void;
    setColumns: (runId: string, columns: RunningQueryColumn[]) => void;
    setResult: (runId: string, result: RunningQueryResult) => void;
    setMessage: (runId: string, message: string) => void;
    appendRows: (runId: string, nRows: number) => void;
    appendRawData: (runId: string, data: string) => void;
    setMetrics: (runId: string, metrics: QueryMetrics) => void;
    updateProgress: (runId: string, progress: QueryProgress) => void;
  };
}

export function useRunningQueryState(): RunningQueryState {
  const [runningQueriesById, setRunningQueriesById] = useState<Record<string, RunningQuery>>({});

  const actions = useMemo(() => {
    const create = (runId: string, args: RunningQueryArgs): void => {
      setRunningQueriesById((queries) => ({
        ...queries,
        [runId]: {
          status: 'new',
          startTime: new Date(),
          elapsedMs: 0,
          result: {
            numRows: 0,
            cleared: false
          },
          args
        }
      }));
    };

    const deleteQuery = (runId: string): void => {
      setRunningQueriesById((queries) => {
        const newQueries = { ...queries };
        delete newQueries[runId];
        return newQueries;
      });
    };

    const updateQuery = (runId: string, update: Partial<RunningQuery>): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        } else {
          return {
            ...queries,
            [runId]: {
              ...queries[runId],
              ...update
            }
          };
        }
      });
    };

    const updateQueryResult = (runId: string, update: Partial<RunningQueryResult>): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        } else {
          return {
            ...queries,
            [runId]: {
              ...queries[runId],
              result: {
                ...queries[runId].result,
                ...update
              }
            }
          };
        }
      });
    };

    const setResult = (runId: string, result: RunningQueryResult): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        } else {
          return {
            ...queries,
            [runId]: {
              ...queries[runId],
              result
            }
          };
        }
      });
    };

    const setMessage = (runId: string, message: string): void => {
      updateQueryResult(runId, { message });
    };

    const setColumns = (runId: string, columns: RunningQueryColumn[]): void => {
      updateQueryResult(runId, {
        columns,
        numRows: 0
      });
    };

    const setStatus = (runId: string, status: RunningQueryStatusName): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        } else {
          return {
            ...queries,
            [runId]: {
              ...queries[runId],
              status
            }
          };
        }
      });
    };

    const setArgs = (runId: string, args: RunningQueryArgs): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        } else {
          return {
            ...queries,
            [runId]: {
              ...queries[runId],
              args
            }
          };
        }
      });
    };

    const appendRows = (runId: string, nRows: number): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        } else {
          const query = queries[runId];
          const result = { ...query.result };
          delete result.error;
          result.numRows = (result.numRows ?? 0) + nRows;
          return {
            ...queries,
            [runId]: {
              ...queries[runId],
              result
            }
          };
        }
      });
    };

    const setMetrics = (runId: string, metrics: QueryMetrics): void => {
      updateQueryResult(runId, { metrics });
    };

    const appendRawData = (runId: string, data: string): void => {
      setRunningQueriesById((queries) => {
        if (!(runId in queries)) {
          return queries;
        }
        const query = queries[runId];
        const result = { ...query.result };
        if (!result.rawData) {
          result.rawData = [];
        }
        result.rawData.push(data);
        return {
          ...queries,
          [runId]: {
            ...queries[runId],
            result
          }
        };
      });
    };

    const updateProgress = (runId: string, progress: QueryProgress): void => {
      updateQueryResult(runId, { progress });
    };

    return {
      create,
      delete: deleteQuery,
      setStatus,
      setArgs,
      updateQuery,
      setColumns,
      setResult,
      setMessage,
      appendRows,
      setMetrics,
      appendRawData,
      updateProgress
    };
  }, []);

  return {
    queries: runningQueriesById,
    actions
  };
}
