import {
  Button,
  Dialog,
  DialogProps,
  NumberField,
  PasswordField,
  Separator,
  Text,
  TextField
} from '@clickhouse/click-ui';

import { createToast } from 'primitives';
import React, { KeyboardEvent, ReactElement, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from 'src/components/auth';
import {
  useCurrentInstance,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import { useQueryRunner } from 'src/lib/clickhouse/query';
import { PasswordCredential, useCredentials } from 'src/state/connection';
import styles from 'src/components/ConsoleCredentialsModal/styles';

interface Props extends Omit<DialogProps, 'children'> {
  open?: boolean;
  isAuthorized?: boolean;
  onSuccess?: (
    credentials: Pick<
      PasswordCredential,
      'host' | 'port' | 'username' | 'password'
    >
  ) => void;
  onClose?: () => void;
  container?: HTMLElement | null;
  loading?: boolean;
}

const testConnectionQuery = 'SELECT 1;';

function ConsoleCredentialsModal({
  open: openProp,
  onSuccess,
  onClose,
  isAuthorized = false,
  container,
  loading,
  ...props
}: Props): ReactElement {
  const currentUser = useAuth().currentUser;
  const [open, setOpen] = useState(false);
  const [params] = useSearchParams();
  const connectionDetails = useCredentials();
  const currentService = useCurrentInstance();

  const { isAsleep, isAwaking, isProvisioning } =
    useIsCurrentInstanceAwakeStatus();
  const [testing, setTesting] = useState(false);
  const [host, setHost] = useState<string>(
    currentService?.endpoints.https.hostname || ''
  );
  const [port, setPort] = useState<string>(
    String(currentService?.endpoints.https.port || '')
  );
  const [username, setUsername] = useState<string>(
    currentService?.dbUsername || ''
  );
  const [password, setPassword] = useState<string>('');

  const validCredentials = useMemo(() => {
    return host && port && username;
  }, [host, port, username]);

  const connectionCredentials: PasswordCredential = {
    connected: false,
    host,
    port: port || '8443',
    username: username || 'default',
    database: connectionDetails.database || 'default',
    password,
    type: 'password'
  };

  const queryRunner = useQueryRunner({
    sql: testConnectionQuery,
    connectionCredentials,
    skipConnectionInit: true
  });

  const testConnection = async (): Promise<boolean> => {
    if (!validCredentials) {
      createToast('Error', 'alert', 'Please fill in all fields');
      return false;
    }

    if (isAsleep || isAwaking) {
      createToast(
        'Service is asleep',
        'alert',
        'Waking up your service. This may take a minute...'
      );
    }

    if (isProvisioning) {
      createToast(
        'Service is starting',
        'alert',
        'Provisioning your service. This may take a minute...'
      );
    }

    setTesting(true);

    return queryRunner()
      .then((data) => {
        setTesting(false);
        if ('error' in data) {
          createToast(
            'Error',
            'alert',
            `Unable to establish connection.  Please check your database credentials - ${data.error}`
          );
          return false;
        } else {
          createToast(
            'Success',
            'success',
            'Connection established successfully!'
          );
          return true;
        }
      })
      .catch((error) => {
        setTesting(false);
        let message = '';
        if (error instanceof Error) {
          message = error.message;
        }
        createToast(
          'Error',
          'alert',
          `Unable to establish connection.  Please check your database credentials - ${message}`
        );
        return false;
      });
  };

  const saveConnection = (): void => {
    if (!testing) {
      void testConnection().then((success) => {
        if (success) {
          setOpen(false);

          if (onSuccess) {
            setTimeout(() => {
              onSuccess({
                host: host || '',
                port: port || '8443',
                username: username || 'default',
                password: password || ''
              });
            }, 50);
          }
        }
      });
    }
  };

  const pasteHost = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    const pastedText = e.clipboardData.getData('text');
    const simpleSuffixMatch = pastedText.match(/^(.*):(\d+)$/);

    const input = e.currentTarget;

    const fullySelected =
      input.selectionStart === 0 && input.selectionEnd === input.value.length;

    if (!fullySelected) {
      return;
    }

    if (simpleSuffixMatch) {
      const host = simpleSuffixMatch[1];
      const port = simpleSuffixMatch[2];
      setHost(host);
      setPort(port);
      e.preventDefault();
    } else if (pastedText.startsWith('https://')) {
      try {
        const url = new URL(pastedText);
        setHost(url.hostname);
        setPort(url.port || '443');
        if (url.username) {
          setUsername(url.username);
        }
        if (url.password) {
          setPassword(url.password);
        }
        e.preventDefault();
      } catch (e) {
        // not valid URL, ignore
      }
    }
  };

  const currentUserEmail = currentUser?.email;
  const showHostAndPort =
    params.get('show_host') === '1' &&
    currentUserEmail &&
    (currentUserEmail.endsWith('@clickhouse.com') ||
      currentUserEmail.endsWith('@clickhouse-local.com'));

  const serviceId = currentService?.id || '';
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      saveConnection();
    }
  };

  return (
    <Dialog {...props} modal={false} open={openProp ?? open}>
      <Dialog.Content
        title={
          isAuthorized
            ? 'Enter DB Credentials'
            : `Enter DB Credentials ${
                currentService?.name ? `for ${currentService.name}` : ''
              }`
        }
        showClose
        container={container}
        css={styles.modalStyle}
        data-testid="credentials-dialog"
        onClose={onClose}
      >
        <div css={styles.rowStyle}>
          <Text color="muted" size="md">
            Your administrator has not enabled passwordless authentication for
            this role.
          </Text>
          {showHostAndPort && (
            <div css={styles.hostDetails}>
              <div css={styles.inputLabelStyle}>
                <TextField
                  label="Host"
                  autoComplete="off"
                  onPaste={pasteHost}
                  value={host}
                  id={`${serviceId}_hostname`}
                  name={`${serviceId}[hostname]`}
                  placeholder="Enter Hostname"
                  onChange={setHost}
                  onKeyDown={onKeyDown}
                  orientation="horizontal"
                />
              </div>
              <NumberField
                label="Port"
                autoComplete="off"
                type="number"
                placeholder="8443"
                id={`${serviceId}_port`}
                name={`${serviceId}[port]`}
                value={port}
                onChange={setPort}
                onKeyDown={onKeyDown}
                loading={false}
                orientation="horizontal"
              />
            </div>
          )}

          <div css={styles.inputLabelStyle}>
            <TextField
              label="User"
              autoComplete="off"
              placeholder="default"
              value={username}
              id={`${serviceId}_username`}
              name={`${serviceId}[username]`}
              onChange={setUsername}
              onKeyDown={onKeyDown}
              orientation="horizontal"
            />
          </div>
          <div css={styles.inputLabelStyle}>
            <PasswordField
              label="Password"
              autoComplete="off"
              placeholder="Enter Password"
              id={`${serviceId}_password`}
              name={`${serviceId}[password]`}
              value={password}
              onChange={setPassword}
              onKeyDown={onKeyDown}
              orientation="horizontal"
            />
          </div>
        </div>
        <Separator size="lg" />
        <div css={styles.footerStyle}>
          <Dialog.Close label="Cancel" onClick={onClose} />
          <Button
            loading={testing || loading}
            disabled={!validCredentials || testing || loading}
            onClick={(): void => saveConnection()}
            data-testid="credentials-connect-button"
          >
            {testing ? 'Connecting' : 'Connect'}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

export default React.memo(ConsoleCredentialsModal);
