import React from 'react';
import { Switch } from '@clickhouse/click-ui';

/**
 * A boolean filter value.
 * Since ClickHouse uses UInt8 for boolean values, and the Dashboard filter value is string,
 * we use '0' and '1' as false and true.
 */
export type FilterBooleanValue = '0' | '1';

interface FilterBooleanSwitchProps {
  value: FilterBooleanValue;
  onChange: (newValue: FilterBooleanValue) => void;
}

/**
 * A switch component for boolean filter values.
 * @param {FilterBooleanValue} value - The current value of the switch. The switch is on when the value is '1' and off otherwise.
 * @param {(newValue: string) => void} onChange - Function to call when the switch value changes.
 * @returns {React.ReactElement} - The rendered switch component.
 */
export const FilterBooleanSwitch: React.FC<FilterBooleanSwitchProps> = ({
  value,
  onChange
}) => {
  const handleCheckedChange = (): void => {
    const newValue = value === '1' ? '0' : '1';
    onChange(newValue);
  };

  return (
    <Switch onCheckedChange={handleCheckedChange} checked={value === '1'} />
  );
};
