import { FC, ReactElement, useCallback, useRef } from 'react';
import { SidebarCollapsibleTitle } from '@clickhouse/click-ui';
import { Index, List as _List, ListRowProps } from 'react-virtualized';
import { paddingStyle, virtualizedListStyle } from 'global-styles';
import { TableListing } from 'src/components/TableView/TableList/Table';
import { Table } from 'shared/src/clickhouse/types';
import { TableRightBarOption } from 'src/state/tabs/types';
import { tableHeight } from 'src/components/TableView/TableList/fittedHeights';
import { ListProps } from 'react-virtualized';

const List = _List as unknown as FC<ListProps>;
interface Props {
  label: string;
  selected?: Table | null;
  collection: Array<Table>;
  type: 'table' | 'view' | 'materializedView';
  tableRightBarType?: TableRightBarOption[];
  tableName?: string;
  onTableChange: (table: Table, rightBarType?: 'tableSchema') => void;
  width: number;
  height: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  expandedCollections: Record<string, boolean>;
  onToggleItem: (tableName: string) => void;
}

const ExpandableSection = ({
  label,
  type,
  collection,
  selected,
  tableRightBarType,
  tableName,
  onTableChange,
  width,
  height = 0,
  open,
  onOpenChange,
  expandedCollections,
  onToggleItem
}: Props): ReactElement => {
  const ref = useRef<_List>(null);

  const toggleItem = useCallback(
    (tableName: string) => {
      onToggleItem(tableName);
      ref.current?.recomputeRowHeights();
    },
    [onToggleItem]
  );

  const getRowHeight = useCallback(
    ({ index }: Index) => {
      const table = collection[index];
      return tableHeight(table, expandedCollections[table.tableName] === true);
    },
    [expandedCollections, collection]
  );

  const rowRenderer = useCallback(
    ({ style, key, index }: ListRowProps) => {
      const table = collection[index];
      const isSelected =
        selected?.tableName === table.tableName && selected.type === type;

      return (
        <div style={style} key={key} css={paddingStyle({ left: '1rem' })}>
          <TableListing
            table={table}
            onToggleTable={toggleItem}
            selected={isSelected}
            onTableChange={onTableChange}
            expanded={expandedCollections[table.tableName] === true}
            tableRightBarType={tableRightBarType}
            tableName={tableName}
          />
        </div>
      );
    },
    [
      collection,
      selected?.tableName,
      selected?.type,
      type,
      toggleItem,
      onTableChange,
      expandedCollections,
      tableRightBarType,
      tableName
    ]
  );
  return (
    <SidebarCollapsibleTitle
      type="sqlSidebar"
      label={label}
      open={open}
      onOpenChange={onOpenChange}
      style={{
        width: width,
        padding: '0 1rem'
      }}
    >
      <List
        ref={ref}
        height={height}
        width={width}
        rowCount={collection.length}
        rowHeight={getRowHeight}
        rowRenderer={rowRenderer}
        style={virtualizedListStyle}
      />
    </SidebarCollapsibleTitle>
  );
};

export default ExpandableSection;
