import { SavedQueryDTO } from 'shared';
import {
  DashboardConfig,
  DashboardConfigField,
  DashboardFilterConfig,
  DashboardObjectConfig
} from 'shared/src/types/dashboard';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import {
  buildFilterConfigUpdater,
  buildObjectConfigUpdater,
  useComposeUpdateDashboardConfig
} from 'src/dashboard/dashboardController';
import { hydrateParameterConfig } from 'src/lib/dashboard/utils';
import {
  useUpdateDashboardFilterOutput,
  useUpdateDashboardFilterState
} from 'src/state/dashboard/dashboardState';

interface UseInlineQueryEditArgs {
  dashboardConfig: DashboardConfig;
  dashboardId: string;
  editingObjectId: string;
  onFieldChange: <T>(field: string, value: T) => void;
  tentativeChanges: DashboardObjectConfig;
}

export function useInlineQueryEdit({
  dashboardConfig,
  dashboardId,
  editingObjectId,
  onFieldChange,
  tentativeChanges
}: UseInlineQueryEditArgs) {
  const composeUpdateDashboardConfig = useComposeUpdateDashboardConfig();
  const updateState = useUpdateDashboardFilterState({
    dashboardId
  });
  const updateOutput = useUpdateDashboardFilterOutput({
    dashboardId
  });

  return (queryId: string, newQuery: SavedQueryDTO): void => {
    let newFilterConfigs: Record<string, DashboardFilterConfig> | undefined;
    const updates = Object.entries(dashboardConfig.dashboardObjects)
      .filter(([, objectConfig]) => objectConfig.config.queryId === queryId)
      .map(([objectId]) => {
        const { newFilters, newParameters } = hydrateParameterConfig(
          dashboardConfig,
          objectId,
          newQuery
        );

        if (!newFilterConfigs) {
          newFilterConfigs = newFilters;
        }

        if (objectId === editingObjectId) {
          onFieldChange('parameters', newParameters);
        }

        return buildObjectConfigUpdater(objectId, {
          ...tentativeChanges,
          parameters: newParameters
        });
      });

    if (newFilterConfigs) {
      updates.push(buildFilterConfigUpdater(newFilterConfigs));
      Object.entries(newFilterConfigs).forEach(([parameter, filterConfig]) => {
        updateState(parameter, {
          value: filterConfig.defaultValue
        });
        updateOutput(parameter, {
          value: filterConfig.defaultValue
        });
      });
    }

    composeUpdateDashboardConfig(dashboardConfig, updates).catch(console.error);
  };
}

interface UseQuerySelectArgs {
  dashboardConfig: DashboardConfig;
  dashboardId: string;
  editingObjectId: string;
  onFieldChange: <T>(field: string, value: T) => void;
  tentativeChanges: DashboardObjectConfig;
}

export function useQuerySelect({
  dashboardConfig,
  dashboardId,
  editingObjectId,
  onFieldChange,
  tentativeChanges
}: UseQuerySelectArgs) {
  const composeUpdateDashboardConfig = useComposeUpdateDashboardConfig();
  const { savedQueries } = useSavedQueries();
  const updateState = useUpdateDashboardFilterState({
    dashboardId
  });
  const updateOutput = useUpdateDashboardFilterOutput({
    dashboardId
  });

  return (field: string, value: string): void => {
    const savedQuery = savedQueries.find((query) => query.id === value);

    const savedQueryParameters: Record<
      string,
      DashboardConfigField<string>
    > = {};
    const updates = [];

    Object.entries(savedQuery?.parameters || {}).forEach(
      ([parameter, paramValue]) => {
        const currentFilterValue =
          dashboardConfig.dashboardFilters.config[parameter];

        savedQueryParameters[parameter] = {
          type: 'filter'
        };

        if (!currentFilterValue) {
          updates.push(
            buildFilterConfigUpdater({
              [parameter]: {
                defaultValue: paramValue,
                filterType: 'string'
              }
            })
          );
          updateState(parameter, {
            value: paramValue
          });
          updateOutput(parameter, {
            value: paramValue
          });
        }
      }
    );

    onFieldChange(field, value);
    onFieldChange('parameters', savedQueryParameters);

    updates.push(
      buildObjectConfigUpdater(editingObjectId, {
        ...tentativeChanges,
        [field]: value,
        parameters: savedQueryParameters
      })
    );

    composeUpdateDashboardConfig(dashboardConfig, updates).catch(console.error);
  };
}

interface UseCancelArgs {
  dashboardObjectConfig: DashboardObjectConfig;
  onFieldChange: <T>(field: string, value: T) => void;
  onUpdateConfig: (changes: DashboardObjectConfig) => Promise<void>;
  tentativeChanges: DashboardObjectConfig;
}

export function useCancel({
  dashboardObjectConfig,
  onFieldChange,
  onUpdateConfig,
  tentativeChanges
}: UseCancelArgs) {
  return (field: string): void => {
    const previousValue =
      dashboardObjectConfig[field as keyof DashboardObjectConfig];

    onFieldChange(field, previousValue);
    void onUpdateConfig({
      ...tentativeChanges,
      [field]: previousValue
    });
  };
}

interface UseStringConfirmArgs {
  onUpdateConfig: (changes: DashboardObjectConfig) => Promise<void>;
  tentativeChanges: DashboardObjectConfig;
}

export function useStringConfirm({
  onUpdateConfig,
  tentativeChanges
}: UseStringConfirmArgs) {
  return (field: string, changes?: DashboardConfigField<string>) => {
    if (changes) {
      void onUpdateConfig({
        ...tentativeChanges,
        [field]: changes
      });
    } else {
      void onUpdateConfig(tentativeChanges);
    }
  };
}
