import { FileUploadImport, NewFileUploadImport } from 'shared/src/dataLoading';

import FileDropPanel from 'src/components/ImportView/ImportForm/SourceInput/FileSourceInput/FileDropPanel';
import FileUploadDetails from 'src/components/ImportView/ImportForm/SourceInput/FileSourceInput/FileUploadDetails';
import { FormContainer, ProgressBar } from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  importModel: FileUploadImport | NewFileUploadImport;
  progress: number;
  onCancelUpload: () => void;
  children: ReactNode;
};

const StyledProgressBar = styled(ProgressBar)`
  ${({ theme }): string =>
    theme.name === 'light'
      ? `
    color: white;
  `
      : ''}
`;
function FileSourceInput({
  importModel,
  progress,
  onCancelUpload,
  children
}: Props): JSX.Element {
  const { status, data } = importModel;

  if (status !== 'new') {
    return (
      <FileUploadDetails fileName={data.fileName} sizeBytes={data.sizeBytes} />
    );
  }

  if (!data.file) {
    return <FileDropPanel />;
  }

  const fileName = data.fileName || '';

  return (
    <>
      <FileUploadDetails fileName={fileName} sizeBytes={data.sizeBytes} />
      {children}
      <FormContainer
        label="Upload progress"
        htmlFor="file-progress"
        data-progress={parseInt(`${progress}`)}
      >
        <StyledProgressBar
          progress={Math.floor(progress)}
          id="file-progress"
          dismissable
          onCancel={onCancelUpload}
        />
      </FormContainer>
    </>
  );
}

export default FileSourceInput;
