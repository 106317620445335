import { ReactElement, memo, useEffect, useState } from 'react';
import { Button, Flyout } from '@clickhouse/click-ui';

import { useImportModel } from 'src/lib/dataLoading';
import { isFileBasedImport } from 'shared/src/dataLoading';
import { generateImportStatement } from 'shared/src/dataLoading/generateImportStatement';
import { isCloudEnv } from 'src/lib/config/isCloudEnv';

function Preview(): ReactElement | null {
  const { importModel } = useImportModel();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  if (
    !importModel ||
    !isFileBasedImport(importModel) ||
    !importModel.data.tableConfig
  ) {
    return null;
  }

  // TODO: Find why this is complaining https://github.com/ClickHouse/control-plane/issues/6983
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const credentials =
    importModel.type === 'file_url' &&
    importModel.data.credentials?.type === 'accessKeyId'
      ? importModel.data.credentials
      : undefined;

  const statement = generateImportStatement({
    importModel,
    skipCreateTable: false,
    credentials,
    credentialsMasked: true,
    isCloudEnv: isCloudEnv()
  });

  return (
    <Flyout modal={false} onOpenChange={setOpen} open={open}>
      <Flyout.Trigger>
        <Button iconLeft="eye" type="secondary">
          Query preview
        </Button>
      </Flyout.Trigger>
      <Flyout.Content data-testid="query-preview" strategy="fixed" size="wide">
        <Flyout.Header
          title="Query preview"
          description="The following queries will be executed against your service to complete this import."
        />
        <Flyout.Body>
          <div className="fs-exclude">
            <Flyout.CodeBlock wrapLines fillHeight statement={statement} />
          </div>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
}

export default memo(Preview);
