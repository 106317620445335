import { InstanceTier } from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { isCustomerManagedEncryptionAvailable } from '@cp/common/utils/InstanceUtils';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';
import { useState, useCallback } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useCurrentOrganization } from 'src/organization/organizationState';

export type CustomerKeyValidationState = 'valid' | 'invalid' | 'checking' | 'blank';

interface NewInstanceCustomerKeyConfig {
  keyId: string;
  roleId: string;
  setKeyId: (id: string) => void;
  setRoleId: (id: string) => void;
  validate: () => void;
  validationStatus: CustomerKeyValidationState;
}

export function useNewInstanceCustomerKeyConfig(
  regionId: RegionId,
  instanceTier: InstanceTier
): NewInstanceCustomerKeyConfig {
  const [keyIdInput, setKeyIdInput] = useState('');
  const [roleIdInput, setRoleIdInput] = useState('');
  const [validationStatus, setValidationStatus] = useState<CustomerKeyValidationState>('blank');

  const currentOrganization = useCurrentOrganization();
  const apiClient = useApiClient();

  const customerManagedEncryptionAvailable = isCustomerManagedEncryptionAvailable(
    regionId,
    instanceTier,
    currentOrganization?.features ?? [],
    !!currentOrganization && isOrgByoc(currentOrganization)
  );

  const keyIdTrimmed = keyIdInput.trim();
  const roleIdTrimmed = roleIdInput.trim();

  const validate = useCallback(() => {
    const inputsBlank = !keyIdTrimmed && !roleIdTrimmed;

    if (currentOrganization?.id && !inputsBlank) {
      setValidationStatus('checking');
      apiClient.instance
        .verifyCustomerKeyConfig({
          organizationId: currentOrganization?.id,
          customerManagedEncryption: {
            keyArn: keyIdTrimmed,
            assumedRoleArn: roleIdTrimmed
          }
        })
        .then((result) => {
          setValidationStatus(result.valid ? 'valid' : 'invalid');
        })
        .catch((e) => {
          console.error(e);
          setValidationStatus('invalid');
        });
    }
  }, [currentOrganization?.id, keyIdTrimmed, roleIdTrimmed, apiClient]);

  return {
    keyId: customerManagedEncryptionAvailable ? keyIdInput : '',
    setKeyId: setKeyIdInput,
    roleId: customerManagedEncryptionAvailable ? roleIdInput : '',
    setRoleId: setRoleIdInput,
    validate,
    validationStatus: customerManagedEncryptionAvailable ? validationStatus : 'valid'
  };
}
