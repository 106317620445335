import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  &,
  &:hover {
    text-decoration: none;
  }
`;

const NoDecorationLink = forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    return <StyledLink ref={ref} {...props} />;
  }
);

export default NoDecorationLink;
