import React, { PropsWithChildren, ReactElement } from 'react';
import { css } from '@emotion/react';
import { alignCenter, flex, gapSm, mlAuto, w100 } from '@utility-styles';
import { produce } from 'immer';
import { Column, ColumnMapping, TableMapping } from 'shared/src/dataLoading';
import {
  Accordion,
  EllipsisContent,
  Icon,
  Select,
  SelectOptionListItem,
  Table,
  TableHeaderType,
  TableRowType,
  Text
} from '@clickhouse/click-ui';
import TableSelect from 'src/components/ImportView/ImportForm/TableSelect';
import DestinationTableSettings from 'src/components/ImportView/ImportForm/DestinationTableSettings';
import { Table as ChTableType } from 'shared/src/clickhouse/types';
import { isEnumEqual, parseEnumType } from 'shared/src/sql/parse/parseEnum';
// td one is a temp fix that needs to be fixed in click-ui
const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'inherit',
  'td > div': {
    height: 'auto'
  }
});

export type TableMappingFormTableType = Pick<
  ChTableType,
  'schema' | 'tableName'
> & {
  columns: Column[];
};

type Props = {
  mapping: TableMapping;
  onTableChange: (table: string) => void;
  onMappingChange: (mapping: Array<ColumnMapping>) => void;
  tables: TableMappingFormTableType[];
  collapsibleColumns?: boolean;
};

const columnMappingHeaderCollapsible: Array<TableHeaderType> = [
  {
    label: 'Source Field'
  },
  {
    label: 'To',
    width: '75px'
  },
  {
    label: 'Column in Table'
  }
];
const columnMappingHeaderNonCollapsible: Array<TableHeaderType> = [
  {
    label: 'Field in file'
  },
  {
    label: 'To',
    width: '75px'
  },
  {
    label: 'Column in Table'
  }
];

const columnStyle = css({
  width: 'auto',
  maxWidth: '50%'
});

type ColumnLabelProps = { column: Column };
function ColumnLabel({
  column,
  children
}: PropsWithChildren<ColumnLabelProps>): ReactElement {
  let typeLabel = column.type;

  if (typeLabel.startsWith('Enum')) {
    const enumValue = parseEnumType(typeLabel);
    // we basically sort the enum so it is displayed in a consistent way
    typeLabel = `${enumValue.enumTypeValue}(${enumValue.enumValues.join(
      ', '
    )})`;
  }

  return (
    <div css={[flex, alignCenter, w100, gapSm]}>
      <EllipsisContent
        component={Text}
        css={columnStyle}
        className="fs-exclude"
      >
        {column.name}
      </EllipsisContent>
      <EllipsisContent
        component={Text}
        color="muted"
        css={[columnStyle, mlAuto]}
      >
        {typeLabel}
      </EllipsisContent>
      {children && children}
    </div>
  );
}

const isHighlighted = (source: Column, target: Column | null): boolean => {
  if (!target) {
    return false;
  }

  if (target.type.startsWith('Enum') && source.type.startsWith('Enum')) {
    const sourceEnum = parseEnumType(source.type);
    const targetEnum = parseEnumType(target.type);
    return !isEnumEqual(sourceEnum, targetEnum);
  } else {
    return target.type !== source.type;
  }
};

function TableMappingForm({
  mapping,
  onTableChange,
  onMappingChange,
  tables,
  collapsibleColumns = true
}: Props): JSX.Element | null {
  if (!mapping) {
    return null;
  }

  const tableName = mapping.name;
  const table = tables.find((table) => table.tableName === tableName);

  const tableColumns = table?.columns || [];

  const toggleRemoved = (item: TableRowType, idx: number): void => {
    const newColumnsMapping = produce(columnsMapping, (draft) => {
      const mapping = draft[idx];
      mapping.removed = item.isDeleted;
    });
    onMappingChange(newColumnsMapping);
  };

  const targetOptions: Array<SelectOptionListItem> = tableColumns
    .map((col) => ({
      label: <ColumnLabel column={col} />,
      value: col.name
    }))
    .sort((a, b) => (a.value < b.value ? -1 : 1));

  const columnsMapping = mapping.columnsMapping || [];

  const rows: Array<TableRowType> = columnsMapping.map(
    ({ source, target, removed }, idx) => {
      const highlighted = isHighlighted(source, target);

      const onChangeTarget = (idx: number) => (newValue: string) => {
        const newColumnsMapping = produce(columnsMapping, (draft) => {
          const mapping = draft[idx];
          mapping.target =
            tableColumns.find((column) => column.name === newValue) ?? null;
        });
        onMappingChange(newColumnsMapping);
      };

      return {
        id: `column-${idx}`,
        items: [
          {
            label: <ColumnLabel column={source} />
          },
          {
            label: <Icon size="md" name="arrow-right" />
          },
          {
            label: (
              <Select
                disabled={removed}
                value={target?.name}
                options={targetOptions}
                error={highlighted}
                onSelect={onChangeTarget(idx)}
                className="fs-exclude"
              />
            )
          }
        ],
        isDeleted: removed
      };
    }
  );

  return (
    <div css={containerStyle}>
      <DestinationTableSettings>
        <TableSelect
          tables={tables}
          value={tableName}
          onSelect={onTableChange}
        />
      </DestinationTableSettings>

      {table && collapsibleColumns && (
        <Accordion title="Column Mapping" defaultValue="item">
          <Table
            headers={columnMappingHeaderCollapsible}
            rows={rows}
            onDelete={toggleRemoved}
          />
        </Accordion>
      )}
      {table && !collapsibleColumns && (
        <Table
          headers={columnMappingHeaderNonCollapsible}
          rows={rows}
          onDelete={toggleRemoved}
        />
      )}
    </div>
  );
}

export default React.memo(TableMappingForm);
