/*** Date/time related utilities and constants. */
import { assertTruthy } from '@cp/common/utils/Assert';
import { findNextBillDateUsingDayJs } from '@cp/common/utils/UtilsWithDayJsReference';

export const MILLIS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const HOURS_PER_AVERAGE_MONTH = 730;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_YEAR = 365;

export const MILLIS_PER_MINUTE = SECONDS_PER_MINUTE * MILLIS_PER_SECOND;
export const MILLIS_PER_HOUR = MINUTES_PER_HOUR * MILLIS_PER_MINUTE;
export const MILLIS_PER_DAY = HOURS_PER_DAY * MILLIS_PER_HOUR;
export const MILLIS_PER_WEEK = DAYS_PER_WEEK * MILLIS_PER_DAY;

export const SECONDS_PER_HOUR = MINUTES_PER_HOUR * SECONDS_PER_MINUTE;
export const SECONDS_PER_DAY = HOURS_PER_DAY * SECONDS_PER_HOUR;
export const SECONDS_PER_WEEK = DAYS_PER_WEEK * SECONDS_PER_DAY;
// do not create SECONDS_PER_MONTH, because a month varies from 28 days to 31 days
export const SECONDS_PER_YEAR = DAYS_PER_YEAR * SECONDS_PER_DAY;

/** Average amount of hours per month. Used for pricing and billing purposes. */
export const HOURS_PER_MONTH = (DAYS_PER_YEAR * HOURS_PER_DAY) / 12;
export const HOURS_PER_WEEK = SECONDS_PER_WEEK / SECONDS_PER_HOUR;

/**
 * Used to define a period that has a start and end time.
 * Can be unbounded on one (since ever OR until forever), both (all time since ever until forever) or neither side (from time A to B)
 */
export interface TimePeriod {
  startTime?: Date;
  endTime?: Date;
}

/**
 * Returns true if referencePoint is between periodStartTime and periodEndTime (inclusive).
 * periodStartTime and periodEndTime behave like doTimePeriodsOverlap's second time range parameters where null or undefined values denote infinity.
 */
export function doesTimeRangeIncludePoint(
  referencePoint: Date | string | number,
  periodStartTime: Date | string | number | undefined | null,
  periodEndTime: Date | string | number | undefined | null
): boolean {
  return doTimePeriodsOverlap(referencePoint, referencePoint, periodStartTime, periodEndTime, true);
}

/**
 * Compares two time periods and returns true if they overlap.
 * If a period is invalid (endTime before startTime) this method returns false.
 * The accepted formats for the timestamps are the same as for Date constructor:
 * - String should be full ISO date.
 * - Number should be unix timestamp in milliseconds
 *
 * @param rawStartTime1 Start time of the first period.
 * @param rawEndTime1 End time of the first period.
 * @param rawStartTime2 Start time of the second period. Null or undefined values denote infinity (period started at big bang).
 * @param rawEndTime2 End time of the second period. Null or undefined values denote infinity (period will end at universe heat death).
 * @param isInclusiveComparison if true, a single overlap point (for example endTime1 === startTime2) is enough to count as overlapping.
 */
export function doTimePeriodsOverlap(
  rawStartTime1: Date | string | number,
  rawEndTime1: Date | string | number,
  rawStartTime2: Date | string | number | undefined | null,
  rawEndTime2: Date | string | number | undefined | null,
  isInclusiveComparison = true
): boolean {
  const startTime1 = new Date(rawStartTime1).getTime();
  const endTime1 = new Date(rawEndTime1).getTime();

  const startTime2 = rawStartTime2 ? new Date(rawStartTime2).getTime() : undefined;
  const endTime2 = rawEndTime2 ? new Date(rawEndTime2).getTime() : undefined;

  if (startTime2 === undefined && endTime2 === undefined) {
    // period2 is unbounded on both ends so it includes all time ever. There's definite overlap.
    return true;
  }

  if (startTime1 > endTime1) {
    // period1 is invalid (starts after it ends).
    return false;
  }

  if (startTime2 === undefined && endTime2 !== undefined) {
    return isInclusiveComparison ? startTime1 <= endTime2 : startTime1 < endTime2;
  }

  if (startTime2 !== undefined && endTime2 === undefined) {
    return isInclusiveComparison ? endTime1 >= startTime2 : endTime1 > startTime2;
  }

  if (startTime2 && endTime2) {
    if (startTime2 > endTime2) {
      // period2 is invalid (starts after it ends).
      return false;
    }
    return isInclusiveComparison
      ? startTime1 <= endTime2 && endTime1 >= startTime2
      : startTime1 < endTime2 && endTime1 > startTime2;
  }

  return false;
}

/**
 * Returns the beginning of the day in UTC (midnight UTC).
 * Defaults to today if no date provided.
 */
export function getBeginningOfDayUtc(date = new Date()): Date {
  const modifiableDate = new Date(date.getTime());
  modifiableDate.setUTCHours(0, 0, 0, 0);
  return modifiableDate;
}

/** Converts full ISO date string (with time) to date only string: '2022-09-09T16:13:47.079Z'->'2022-09-09'. */
export function toISODateOnlyString(isoDateTimeStringOrDate: string | Date | number): string {
  const isoDateTimeString =
    typeof isoDateTimeStringOrDate === 'number'
      ? new Date(isoDateTimeStringOrDate).toISOString()
      : typeof isoDateTimeStringOrDate === 'object'
      ? isoDateTimeStringOrDate.toISOString()
      : isoDateTimeStringOrDate;
  return isoDateTimeString.substring(0, 10);
}

/** Converts date object to timeonly only string by timezone: '2022-09-09T16:13:47.079Z'->'2022-09-09'. */
export function toTimeByTimezone(
  dateTime: Date,
  timeZone?: string,
  timeZoneName:
    | 'short'
    | 'long'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric'
    | undefined = 'longGeneric'
): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };
  if (timeZone) {
    options.timeZone = timeZone;
  } else {
    options.timeZoneName = timeZoneName;
  }
  //Escaped character NARROW NO-BREAK SPACE can cause regex to fail when string is returned to cognito
  return dateTime.toLocaleTimeString('en-US', options).replace('\u202f', ' ');
}

export function toLocaleDate(milliseconds: string | number): string {
  return new Date(milliseconds).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

/** Formats date as 'YYYY-MM-DD HH:mm:ss'. */
export function formatDateInGalaxyStyle(timestamp: number | Date): string {
  const isoDate = (typeof timestamp === 'object' ? timestamp : new Date(timestamp)).toISOString();
  return isoDate.replace('T', ' ').substring(0, isoDate.length - 5);
}

/** Converts date object to detailed locale date string: '2023-02-21T16:13:47.079Z'->'Tuesday, February 21, 2023'. */
export function toDetailedLocaleDate(dateTime: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  return dateTime.toLocaleDateString('en-US', options);
}

/** Formats date range as 'Jul 10 to Jul 21, 2023' + suffix (optional). Limit to 30 chars. */
export function toDateRangeString(billStartDate: Date, billEndDate: Date, suffix: string = ''): string {
  if (billEndDate < billStartDate) {
    return 'invalid date range';
  }

  const billStartNoYear = billStartDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  return billStartNoYear + ' to ' + toLocaleDate(billEndDate.getTime()) + suffix;
}

export type TemporalUnit = 'day' | 'week' | 'month';

export interface RetentionPeriod {
  amount: number;
  unit: TemporalUnit;
}

/**
 * Returns a human-readable period of the given number of hours.
 * Example: 2 week
 */
export function parseHumanReadablePeriod(periodInHours: number): RetentionPeriod {
  const hoursPerWeek = 7 * HOURS_PER_DAY; // 168 hours

  // Avoid using months because not all months have 30 days.
  if (periodInHours === 30 * HOURS_PER_DAY) {
    return { amount: 30, unit: 'day' };
  }

  if (periodInHours >= hoursPerWeek) {
    return {
      amount: Math.floor(periodInHours / hoursPerWeek),
      unit: 'week'
    };
  } else {
    return {
      amount: Math.floor(periodInHours / HOURS_PER_DAY),
      unit: 'day'
    };
  }
}

/** Result type for functions that build aligned periods. */
export interface AlignedTimePeriod {
  alignedStartTime: number;
  alignedEndTime: number;
}

/** The only period supported today by 'buildAlignedTimePeriods'. */
export type TimeAlignPeriod = '1 hour' | '15 minutes';

/** Returns start date of the aligned period. */
export function getAlignedTimePeriodStartDate(dateInsidePeriod: number, periodType: TimeAlignPeriod): number {
  switch (periodType) {
    case '1 hour': {
      const startDate = new Date(dateInsidePeriod);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      return startDate.getTime();
    }

    case '15 minutes': {
      const startDate = new Date(dateInsidePeriod);
      const interval = Math.floor(startDate.getMinutes() / 15) % 4;
      const startMinute = interval * 15;
      startDate.setMinutes(startMinute);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      return startDate.getTime();
    }
  }
}

/** Returns end start date of the aligned period. */
export function getAlignedTimePeriodEndDate(dateInsidePeriod: number, periodType: TimeAlignPeriod): number {
  switch (periodType) {
    case '1 hour': {
      const startDate = getAlignedTimePeriodStartDate(dateInsidePeriod, periodType);
      return startDate + MILLIS_PER_HOUR;
    }

    case '15 minutes': {
      const startDate = getAlignedTimePeriodStartDate(dateInsidePeriod, periodType);
      return startDate + 15 * MILLIS_PER_MINUTE;
    }
  }
}

/**
 * Returns a list of continuous time periods
 * that cover the `minDate`/`maxDate` range with a `periodType` granularity.
 *
 * The first period in the result starts with the first possible aligned date before or equal to `minDate`.
 * The last period in the result ends with an aligned date greater or equal than `maxDate`.
 *
 * Note: that maxDate is considered as exclusive: so it may be the same as 'alignedEndTime' of the last interval.
 */
export function buildAlignedTimePeriods(
  minDate: number,
  maxDate: number,
  periodType: TimeAlignPeriod
): Array<AlignedTimePeriod> {
  assertTruthy(minDate <= maxDate, () => `Wrong min/max dates: ${minDate} - ${maxDate}`);
  const result: Array<AlignedTimePeriod> = [];
  let alignedStartTime = getAlignedTimePeriodStartDate(minDate, periodType);
  let alignedEndTime = getAlignedTimePeriodEndDate(minDate, periodType);
  const alignedMaxEndTime = getAlignedTimePeriodEndDate(Math.max(minDate, maxDate - 1), periodType); // Max date is exclusive.
  while (alignedEndTime <= alignedMaxEndTime) {
    result.push({ alignedStartTime, alignedEndTime });
    alignedStartTime = alignedEndTime;
    alignedEndTime = getAlignedTimePeriodEndDate(alignedStartTime, periodType);
  }
  return result;
}

/** Formats time interval in seconds to human readable format. For example 3668 => 1 hour, 1 minute and 8 seconds. */
export function secondsToString(seconds: number, lastSeparator: string = ' and'): string {
  if (seconds <= 0) {
    return 'N/A';
  }
  let remaining = seconds;
  const timeParts: string[] = [];
  if (remaining >= SECONDS_PER_YEAR) {
    const numYears = Math.floor(seconds / SECONDS_PER_YEAR);
    timeParts.push(`${numYears} ${numYears > 1 ? 'years' : 'year'}`);
    remaining = remaining % SECONDS_PER_YEAR;
  }
  if (remaining >= SECONDS_PER_WEEK) {
    const numWeeks = Math.floor(remaining / SECONDS_PER_WEEK);
    timeParts.push(`${numWeeks} ${numWeeks > 1 ? 'weeks' : 'week'}`);
    remaining = remaining % SECONDS_PER_WEEK;
  }
  if (remaining >= SECONDS_PER_DAY) {
    const numDays = Math.floor(remaining / SECONDS_PER_DAY);
    timeParts.push(`${numDays} ${numDays > 1 ? 'days' : 'day'}`);
    remaining = remaining % SECONDS_PER_DAY;
  }
  if (remaining >= SECONDS_PER_HOUR) {
    const numHours = Math.floor(remaining / SECONDS_PER_HOUR);
    timeParts.push(`${numHours} ${numHours > 1 ? 'hours' : 'hour'}`);
    remaining = remaining % SECONDS_PER_HOUR;
  }
  if (remaining >= SECONDS_PER_MINUTE) {
    const numMinutes = Math.floor(remaining / SECONDS_PER_MINUTE);
    timeParts.push(`${numMinutes} ${numMinutes > 1 ? 'minutes' : 'minute'}`);
  }
  const numSeconds = Math.floor(remaining % SECONDS_PER_MINUTE);
  if (numSeconds > 0) {
    timeParts.push(`${numSeconds} ${numSeconds === 1 ? 'second' : 'seconds'}`);
  }
  return timeParts.join(', ').replace(/,([^,]*)$/, lastSeparator + '$1');
}

/** return the next billDate for a bill that starts today. */
export function findNextBillDate(billStartDate: Date): Date {
  return findNextBillDateUsingDayJs(billStartDate);
}
