import { v4 as uuid } from 'uuid';

import { SqlQueryFunction } from 'src/lib/clickhouse/query';
import { JSONFormatType, TsvFormatType, EditColumn } from 'shared/src/dataLoading';

interface InferredSettings {
  separator: string;
  format: string;
}

export interface CHStructureDescriber {
  describeCsvFromData(data: string, settings: InferredSettings): Promise<EditColumn[]>;
  describeTsvFromData(data: string, formatType: TsvFormatType): Promise<EditColumn[]>;
  describeJsonFromData(data: string, formatType: JSONFormatType): Promise<EditColumn[]>;
}

function transformToNonNullable(input: string): string | null {
  const match = input.match(/Nullable\((.+)\)$/);
  if (match) {
    const content = match[1].trim();
    return content;
  } else {
    return null;
  }
}

export class ChDescriber implements CHStructureDescriber {
  readonly runQuery: SqlQueryFunction;
  constructor(runQuery: SqlQueryFunction) {
    this.runQuery = runQuery;
  }

  async executeDescribe(query: string): Promise<EditColumn[]> {
    const result = await this.runQuery(query);

    if ('error' in result) {
      throw new Error(result.error);
    }

    const describedColumns = result.rows.map((row, idx) => {
      const [name, type] = row;
      return {
        id: uuid(),
        originalName: name || `c${idx + 1}`,
        name: name || `c${idx + 1}`,
        type: type || 'Nullable(String)',
        defaultExpression: ''
      };
    });

    if (describedColumns.length === 0) {
      return describedColumns;
    }
    const allNullable =
      describedColumns.filter((col) => col.type.startsWith('Nullable')).length === describedColumns.length;

    if (allNullable) {
      const firstCol = describedColumns[0];
      firstCol.type = transformToNonNullable(firstCol.type) ?? firstCol.type;
    }

    return describedColumns;
  }

  async describeCsvFromData(data: string, settings: InferredSettings): Promise<EditColumn[]> {
    const { format, separator } = settings;
    const query = `
      DESC format('${format}', '${this.sanitizeQuotes(
        data
      )}') SETTINGS schema_inference_make_columns_nullable=0, format_csv_delimiter='${separator}';
    `;

    return this.executeDescribe(query);
  }

  describeTsvFromData(data: string, formatType: TsvFormatType): Promise<EditColumn[]> {
    const query = `DESC format('${formatType}', '${this.sanitizeQuotes(
      data
    )}') SETTINGS schema_inference_make_columns_nullable=0;`;

    return this.executeDescribe(query);
  }

  describeJsonFromData(data: string, formatType: JSONFormatType): Promise<EditColumn[]> {
    const query = `DESC format('${formatType}', '${this.sanitizeQuotes(
      data
    )}') SETTINGS schema_inference_make_columns_nullable=0;`;

    return this.executeDescribe(query);
  }

  async describeCsvUrl(fileUrl: string, settings: InferredSettings): Promise<EditColumn[]> {
    const { format, separator } = settings;

    const query = `
      DESCRIBE
        url('${fileUrl}', '${format}')
      SETTINGS format_csv_delimiter = '${separator}', schema_inference_make_columns_nullable=0;
    `;

    return this.executeDescribe(query);
  }

  private sanitizeQuotes(data: string) {
    return data.replaceAll("'", "\\'").replaceAll('\\"', '\\\\"');
  }
}
