import { ReactNode } from 'react';
import { FCChild } from 'types/commonTypes';
import { Helmet as _Helmet, HelmetProps } from 'react-helmet';

const ReactHelmet = _Helmet as unknown as FCChild<HelmetProps>;

const Helmet = (props: HelmetProps & { children: ReactNode }) => {
  return <ReactHelmet {...props} />;
};

export default Helmet;
