import { v4 as uuid } from 'uuid';

import { ApiClient, useApiClient } from 'src/lib/controlPlane/client';
import { detectFileMimeTypeFromFilename } from 'src/lib/dataLoading/parseData/fileUtils';

export type FileDownloadUrl = string;
export type StorageId = string;

export type UploadCancelStorage = (xhr: XMLHttpRequest) => void;

export async function uploadFileToS3(
  api: ApiClient,
  file: File,
  organizationId: string,
  instanceId: string,
  progressHandler?: (percentage: number) => void,
  cancelUploadStorage?: UploadCancelStorage
): Promise<[FileDownloadUrl, StorageId]> {
  const randomId = uuid();
  const ext = file.name.split('.')[1];
  const key = `${randomId}.${ext}`;
  const fileType = detectFileMimeTypeFromFilename(file.name);
  const signatureDetails = await api.dataloading.prepareUploadSignatureDetails(
    organizationId,
    key,
    fileType
  );

  const uploadPromise = new Promise(function (resolve, reject) {
    const formData = new FormData();
    const fields = signatureDetails.fields;
    formData.append('key', fields.key);
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', fields['X-Amz-Date']);
    formData.append('Policy', fields['Policy']);
    formData.append('X-Amz-Signature', fields['X-Amz-Signature']);

    if (fields['X-Amz-Security-Token']) {
      formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token']);
    }

    formData.append('file', file);

    const xhr = new XMLHttpRequest();

    xhr.open('POST', signatureDetails.url, true);

    if (cancelUploadStorage) {
      cancelUploadStorage(xhr);
    }

    xhr.onload = function (): void {
      if (this.status >= 200 && this.status < 300) {
        resolve({ response: xhr.response, cancelUpload: () => xhr.abort() });
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };

    xhr.onerror = function (): void {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };

    if (progressHandler) {
      xhr.upload.addEventListener('progress', (event) => {
        const percent = (event.loaded / event.total) * 100;
        progressHandler(percent);
      });
    }

    xhr.send(formData);
  });

  await uploadPromise;

  const response = await api.dataloading.getImportFileDownloadUrl(
    organizationId,
    instanceId,
    signatureDetails.id
  );

  return [response.downloadUrl, signatureDetails.id];
}

export type UploadFileToS3Fn = (
  file: File,
  organizationId: string,
  instanceId: string,
  progressHandler?: (percentage: number) => void,
  cancelUploadStorage?: UploadCancelStorage
) => Promise<[FileDownloadUrl, StorageId]>;

export function useUploadToS3(): UploadFileToS3Fn {
  const api = useApiClient();

  return async function uploadToS3(
    file: File,
    organizationId: string,
    instanceId: string,
    progressHandler?: (percentage: number) => void,
    cancelUploadStorage?: UploadCancelStorage
  ): Promise<[FileDownloadUrl, StorageId]> {
    return uploadFileToS3(
      api,
      file,
      organizationId,
      instanceId,
      progressHandler,
      cancelUploadStorage
    );
  };
}
