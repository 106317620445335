import { css } from '@emotion/react';

export default {
  columns: css`
    margin: 0.5rem 0;
  `,
  column: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    height: 20px;
  `
};
