import { css, SerializedStyles } from '@emotion/react';

export const summaryContainer = (): SerializedStyles => css`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  .summary_title {
    color: #6d7386;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .summary_value {
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
  }

  .summary_na_value {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .summary_loading_text {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #6d7386;
  }

  .summary_separator_line {
    margin-top: 12px;
    margin-bottom: 15px;
    height: 1px;
    width: 100%;
    background-color: rgba(109, 115, 134, 0.1);
  }

  .summary_details {
    color: #6d7386;
  }

  .summary_successful_queries .summary_value_and_delta {
    margin-bottom: 20px;
  }

  .summary_sql_statements_per_type {
  }

  .summary_spacer_20 {
    height: 20px;
  }

  .summary_alerted_text {
    color: #c70f0f;
  }

  .summary_dot {
    margin-left: 0.1em;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }

  .summary_dot.summary_success_dot {
    background-color: #cbeabc;
  }

  .summary_dot.summary_failed_dot {
    background-color: #c70f0f;
  }

  .summary_dot.summary_inserts_dot {
    background-color: #3b73de;
  }

  .summary_dot.summary_selects_dot {
    background-color: #407b24;
  }
`;

export const summaryContainerWithMetric = (): SerializedStyles => css`
  border: 1px solid rgba(109, 115, 134, 0.1);
  display: flex;
  flex-wrap: nowrap;
  gap: 0;
  border-radius: 8px;

  .metrics_summary_with_info_block {
    box-sizing: border-box;
    width: 190px;
    min-width: 190px;
    padding: 24px;
    border-radius: 0 0 0 0;
    overflow: hidden;
    position: relative;

    .info_block_corner {
      position: absolute;
      cursor: help;
      margin-top: 8px;
      margin-left: -56px;
      background-color: rgba(109, 115, 134, 0.1);
      width: 48px;
      height: 48px;
      left: 32px;
      bottom: -24px;
      transform: rotate(45deg);

      .info_block_corner_icon {
        transform: rotate(-45deg);
        left: 0.70rem;
        bottom: 0.70rem;
        position: relative;
      }
    }
  }

  .metrics_chart {
    flex: 1 1 auto;
    box-sizing: border-box;
    overflow: hidden; // required for auto-sizing.
    border-radius: 0 8px 8px 0;
  }

  .error_block {
    padding: 30px;
    height: 160px;
    text-align: center;
    background-color: white;
    width: 100%;

    .retry_button {
      .retry_icon {
        margin-right: 1px;
        transform: scale(0.70);
      }
    }

    .error_title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #2F2C3A;
      margin-top: 10px;
      margin-bottom: 24px;
    }

    .error_text {
      color: #6D7386;
      width: 400px;
      display: inline-block;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
}
`;

export const chartTooltipSeries = css`
  line-height: 18px;
  opacity: 0.9;

  &:first-of-type {
    margin-top: 4px;
  }

  .chart_tooltip_value {
    font-weight: 700;
  }
`;
