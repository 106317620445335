import { css, SerializedStyles, Theme } from '@emotion/react';

export default {
  chWrapper: css`
    background-image: linear-gradient(
      170deg,
      black 0%,
      black calc(45% - 20.8px),
      #fcff74 calc(45% - 20px),
      #fcff74 45%,
      white 45%,
      white
    );
    background-size: max(100%, 1100px) max(100%, 1024px);
    background-repeat: no-repeat;
    padding: clamp(32px, 20vh, 128px) 0 0;
  `,
  innerContainer: (theme: Theme): SerializedStyles => css`
    background: ${theme.global.color.background.default};
    max-width: 496px;
    width: 100%;
    box-shadow: 0 10px 40px 0 ${theme.colors.c4_10};
    border-radius: 8px;
    padding: 40px 48px;
    border: 1px solid ${theme.colors.c4_10};
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
      padding: 40px 24px;
    }
  `,
  icon: css`
    margin: 40px 0;
  `
};
