import {
  Button,
  ButtonType,
  Container,
  Dropdown,
  Icon,
  IconName,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import {
  addObjectToDashboard,
  dashboardObjectConfigSchema
} from 'shared/src/dashboard';
import { Dashboard, DashboardObjectType } from 'shared/src/types/dashboard';
import { AddableObject } from 'src/components/Dashboards/DashboardView/styles';
import { useUpdateDashboardConfig } from 'src/dashboard/dashboardController';

interface NewVisualizationButtonProps {
  dashboard: Dashboard;
  onClick?: () => void;
  type?: ButtonType;
}

export const NewVisualizationButton = ({
  dashboard,
  onClick,
  type = 'secondary'
}: NewVisualizationButtonProps): ReactNode => {
  const updateDashboardConfig = useUpdateDashboardConfig();

  const addObject = async (objectType: DashboardObjectType): Promise<void> => {
    try {
      const newConfig = addObjectToDashboard({
        config: dashboard.config,
        objectType
      });

      await updateDashboardConfig(dashboard.id, newConfig);
    } catch (e) {
      console.error(e);
    }
  };

  const addableObjects = Object.values(dashboardObjectConfigSchema).map(
    (schema) => {
      return (
        <AddableObject
          gap="xs"
          key={schema.type}
          onClick={() => {
            void addObject(schema.type);
            if (onClick) {
              onClick();
            }
          }}
          orientation="horizontal"
          padding="sm"
        >
          <Icon name={schema.iconName as IconName} size="md" />
          <Container alignItems="start" orientation="vertical" padding="xs">
            <Title type="h3">{schema.title}</Title>
            <Text color="muted">{schema.description}</Text>
          </Container>
        </AddableObject>
      );
    }
  );

  return (
    <Dropdown>
      <Dropdown.Trigger>
        <Button disabled={!dashboard} iconLeft="plus" type={type}>
          New visualization
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>{addableObjects}</Dropdown.Content>
    </Dropdown>
  );
};
