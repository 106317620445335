import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';

import { css, Theme, useTheme } from '@emotion/react';
import {
  editor,
  IDisposable,
  ISelection,
  KeyCode,
  KeyMod,
  languages,
  Position,
  Range
} from 'monaco-editor';
import PropTypes from 'prop-types';

import { getWorkerProvider } from 'src/lib/autocomplete/index';
import { AutocompleteProviderOptions } from 'src/lib/autocomplete/types';
import { LogFn } from 'src/lib/logger';
import * as chLanguage from 'src/lib/monaco-ch-language';
import { parseQueryVariables } from 'shared/src/sql/parse/parseQueryVariables';
import {
  statementForCursor,
  StatementRange
} from 'src/lib/sql/parse/statementRanges';

import { terminateWorkerProvider } from 'src/lib/autocomplete/index';
import theme from 'src/components/primitives/lib/theme';

import { useErrorDecoration } from 'src/components/primitives/lib/MonacoSQLEditor/errorDecorations';
import {
  flashTimeoutSeconds,
  useRunStatementDecoration
} from 'src/components/primitives/lib/MonacoSQLEditor/runStatementDecoration';
import updatedCursorPosition from 'src/components/primitives/lib/MonacoSQLEditor/updatedCursorPosition';
import { ColorTheme } from 'src/components/primitives/lib/theme/types';
import { useCUITheme } from '@clickhouse/click-ui';

function openBrowserWindow(url: string, target: string | null): void {
  window.open(url, target || undefined);
}

chLanguage.init();

export type MonacoEditorVariant = 'chdb' | 'json' | 'string';
type Language = 'json' | 'string' | 'chdb';

const variantLanguages: Record<MonacoEditorVariant, Language> = {
  chdb: 'chdb',
  json: 'json',
  string: 'string'
};

function shouldBeImpossible(val: never): void {
  console.error('value should not occur', val);
}

function isSQLLanguage(variant: Language): boolean {
  switch (variant) {
    case 'chdb':
      return true;
    case 'json':
      return false;
    case 'string':
      return false;
    default:
      shouldBeImpossible(variant);
      return false;
  }
}

export interface MonacoProps {
  autocompleteOptions?: AutocompleteProviderOptions | undefined;
  autofocus?: boolean;
  editorState?: {
    model: editor.ITextModel | undefined;
    selection: ISelection | undefined;
  };
  readOnly?: boolean;
  scrollPosition?: number;
  value?: string;
  sqlVariant?: MonacoEditorVariant;
  indentWidth?: number;
  showLines?: boolean;
  formatQuery?: () => void;
  onChange?: (value: string) => void;
  onSave?: () => void;
  onStateChange?: (newState: unknown) => void;
  runQuery?: (query?: string) => void;
  runStatementAt?: (statement: string) => void;
  generateSql?: () => void;
  style?: Record<string, unknown>;
  templates?: unknown;
  wordWrap?: boolean;
  showDarkTheme?: boolean;
  logEvent?: LogFn;
  className?: string;
  errorRegion?: StatementRange;
}

export interface EditorInstance {
  replaceEditorContent(content: string): void;
  getEditorState(): unknown;
  getText(): string | undefined;
  runCurrentStatement(): void;
  runSelectedText(): void;
  getScrollPosition(): number;
  getSelectedText(): string | undefined;
  hasFocus(): boolean;
  focusEditor(): void;
  copy(): void;
  paste(value?: string): void;
}

const copySelection = (selection: ISelection): ISelection => ({
  positionColumn: selection.positionColumn,
  positionLineNumber: selection.positionLineNumber,
  selectionStartColumn: selection.selectionStartColumn,
  selectionStartLineNumber: selection.selectionStartLineNumber
});

const splatSelection = (selection: ISelection | undefined) => [
  selection?.positionColumn,
  selection?.positionLineNumber,
  selection?.selectionStartColumn,
  selection?.selectionStartLineNumber
];

function defineMonacoTheme(
  name: string,
  base: editor.BuiltinTheme,
  theme: ColorTheme
) {
  editor.defineTheme(name, {
    base,
    inherit: false,
    rules: [
      { token: '', foreground: theme.sqlEditor.default },
      { token: 'comment', foreground: theme.sqlEditor.comment },
      { token: 'string', foreground: theme.sqlEditor.string },
      {
        token: 'string.double',
        foreground: theme.sqlEditor.default
      },
      { token: 'keyword', foreground: theme.sqlEditor.keyword },
      { token: 'boolean', foreground: theme.sqlEditor.boolean },
      { token: 'number', foreground: theme.sqlEditor.number },
      { token: 'operator', foreground: theme.sqlEditor.operator },
      { token: 'delimiter', foreground: theme.sqlEditor.delimiter },
      { token: 'predefined', foreground: theme.sqlEditor.predefined }
    ],
    colors: {
      'editor.background': theme.sqlEditor.background,
      'editorSuggestWidget.background': theme.sqlEditor.background,
      'editorSuggestWidget.border': theme.sqlEditor.border,
      'editorSuggestWidget.foreground': theme.sqlEditor.foreground,
      'editorSuggestWidget.errorBackground':
        theme.sqlEditor.removedLineBackground,
      'editorSuggestWidget.highlightForeground':
        theme.sqlEditor.highlightForeground,
      'editorSuggestWidget.selectedBackground':
        theme.sqlEditor.selectedBackground,
      'diffEditor.removedLineBackground': theme.sqlEditor.removedLineBackground
    }
  });
}

defineMonacoTheme('clickhouse', 'vs-dark', theme.darkTheme);
defineMonacoTheme('clickhouse-light', 'vs', theme.lightTheme);

const relativeContainer = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

const containerStyle = (theme: Theme) => css`
  height: 100%;
  width: 100%;
  inset: 0;
  position: absolute;

  & .query-variable {
    background-color: ${theme.global.color.feedback.neutral.background};
    color: ${theme.global.color.feedback.neutral.foreground};
    border-radius: 1px;
  }

  & .run-statement {
    animation-duration: ${flashTimeoutSeconds}s;
    animation-name: run-statement-flash;
  }

  @keyframes run-statement-flash {
    from {
      background-color: ${theme.colors.y2};
    }
    to {
      background-color: transparent;
    }
  }
  & .json-key {
    color: ${theme.colors.y2};
  }

  & .error-statement {
    background: ${theme.global.color.feedback.warning.background};
  }

  & .json-bracket,
  & .json-boolean {
    color: ${theme.global.color.text.default};
  }

  & .vs-dark .monaco-scrollable-element > .scrollbar {
    opacity: 1;
    background: ${theme.colors.c4};

    & > .slider {
      opacity: 1;
      background: ${theme.colors.c2};
    }
  }

  & .invisible {
    visibility: hidden;
  }
  .scrollbar {
    &.vertical {
      margin-right: 5px;
    }
    &.horizontal {
      margin-bottom: 5px;
    }
  }

  & .monaco-editor .scroll-decoration {
    display: none;
  }

  & .monaco-editor .scrollbar {
    background: transparent !important;
    & > div {
      background: #555 !important;
      border-radius: 20px;
    }
  }

  & .decorationsOverviewRuler {
    display: none !important;
  }

  & .find-widget :focus {
    outline: none;
  }

  & .monaco-editor .margin-view-overlays .line-numbers {
    margin-top: 0;
  }

  & .cursor.monaco-mouse-cursor-text {
    height: 16px !important;
    margin-top: 3px;
  }

  .monaco-editor .suggest-widget {
    box-shadow:
      0px 1px 10px rgba(0, 0, 0, 0.12),
      0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 2px 4px -1px rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
  }

  .monaco-list .monaco-list-row {
    &.focused,
    &:hover {
      background-color: ${theme.global.color.background.muted} !important;
    }
  }

  .monaco-editor .suggest-widget .focused .monaco-highlighted-label {
    color: ${theme.global.color.text.default};
  }

  .monaco-editor .suggest-widget .focused .suggest-icon,
  .monaco-editor .suggest-widget .suggest-icon {
    color: ${theme.global.color.text.default};
  }

  .monaco-editor
    .suggest-widget
    .monaco-list
    .monaco-list-row.focused
    .codicon {
    color: ${theme.global.color.text.default};
  }

  .monaco-editor
    .suggest-widget
    .monaco-list
    .monaco-list-row
    > .contents
    > .main
    > .right
    > .details-label {
    display: block;
  }

  .monaco-editor .suggest-widget .focused .details-label {
    color: ${theme.colors.c4};
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .monaco-editor .line-numbers,
  .monaco-editor .line-numbers.active-line-number {
    color: ${theme.sqlEditor.lineNumber};
    font-weight: 400;
  }
`;

const JSON_REGEX_COMBO = [
  {
    regex: /(("|').*("|'))[:]\s/g,
    className: 'json-key'
  },
  {
    regex: /(\[|{|}|\])/g,
    className: 'json-bracket'
  },
  {
    regex: /[:]\s(true|false)/g,
    className: 'json-boolean'
  }
];

const moveCursorToEnd = (editor: editor.IStandaloneCodeEditor) => {
  const model = editor.getModel();
  if (model) {
    const endPosition = model.getFullModelRange().getEndPosition();
    editor.setSelection(Range.fromPositions(endPosition, endPosition));
  }
};

interface UseCompletionProviderProps {
  autocompleteOptions: AutocompleteProviderOptions | undefined;
}

// Maintain a completion provider that only provides completions for the specified
// editor, and using the specified language
const useCompletionProvider = ({
  autocompleteOptions
}: UseCompletionProviderProps) => {
  const providerRef = useRef<IDisposable>();

  const getCompletionItemProvider = useCallback(getWorkerProvider, [
    autocompleteOptions,
    'chdb'
  ]);

  useEffect(() => {
    if (autocompleteOptions) {
      const autocompleteProvider =
        getCompletionItemProvider(autocompleteOptions);
      providerRef.current = languages.registerCompletionItemProvider(
        'chdb',
        autocompleteProvider
      );
    }

    return (): void => {
      if (providerRef.current) {
        providerRef.current.dispose();
        providerRef.current = undefined;
      }
    };
  }, [autocompleteOptions, getCompletionItemProvider]);

  useEffect(() => {
    return (): void => {
      terminateWorkerProvider();
    };
  }, []);
};

const MonacoSQLEditor = forwardRef<EditorInstance, MonacoProps>(
  (props, ref) => {
    const {
      autocompleteOptions,
      autofocus,
      editorState: editorStateProp,
      readOnly,
      scrollPosition: scrollPositionProp,
      value: valueProp,
      sqlVariant,
      indentWidth,
      showLines,
      wordWrap,
      showDarkTheme,
      logEvent,
      className,
      errorRegion
    } = props;

    const theme = useTheme();
    const { name: cuiThemeName } = useCUITheme();
    const value = valueProp || '';

    const editorLanguage =
      sqlVariant && sqlVariant in variantLanguages
        ? variantLanguages[sqlVariant]
        : 'chdb';

    const containerRef = useRef<HTMLDivElement>(null);
    const firstLoadRef = useRef(false);
    const [model, setModel] = useState(editorStateProp?.model);
    const [selection, setSelection] = useState(editorStateProp?.selection);
    const [queryVarDecorations, setQueryVarDecorations] = useState<
      editor.IModelDeltaDecoration[]
    >([]);
    const [jsonDecorations, setJsonDecorations] = useState<
      editor.IModelDeltaDecoration[]
    >([]);
    const decorationsRef = useRef<string[]>([]);
    const {
      decorations: runStatementDecorations,
      // TODO: find out why these are unbound
      // eslint-disable-next-line @typescript-eslint/unbound-method
      flashStatement,
      // TODO: find out why these are unbound
      // eslint-disable-next-line @typescript-eslint/unbound-method
      cancelFlash
    } = useRunStatementDecoration(model);

    const editorRef = useRef<editor.IStandaloneCodeEditor>();
    const latestProps = useRef<MonacoProps>(props);

    latestProps.current = props;
    useCompletionProvider({ autocompleteOptions });
    const errorDecorations = useErrorDecoration(model, errorRegion);

    const decorations = useMemo(() => {
      return [
        ...queryVarDecorations,
        ...runStatementDecorations,
        ...jsonDecorations,
        ...errorDecorations
      ];
    }, [
      queryVarDecorations,
      runStatementDecorations,
      jsonDecorations,
      errorDecorations
    ]);

    useEffect(() => {
      const model = editorRef.current?.getModel();
      if (model) {
        decorationsRef.current = model.deltaDecorations(
          decorationsRef.current,
          decorations
        );
      }
    }, [decorations]);

    const redecorateQueryVars = useCallback(function redecorateSQL() {
      const editor = editorRef.current;
      const value = editor?.getValue();
      const model = editor?.getModel();
      if (!editor || !model || value === undefined) {
        return;
      }

      const queryVarDecorations = parseQueryVariables(value).map((queryVar) => {
        return {
          range: Range.fromPositions(
            model.getPositionAt(queryVar.textStartPos),
            model.getPositionAt(queryVar.textEndPos)
          ),
          options: { inlineClassName: 'query-variable' }
        };
      });

      setQueryVarDecorations(queryVarDecorations);
    }, []);

    const getSelectedText = () => {
      const editor = editorRef.current;
      const selection = editor?.getSelection();
      return (
        (editor &&
          selection &&
          editor.getModel()?.getValueInRange(selection)) ??
        undefined
      );
    };

    const runCurrentStatement = useCallback(
      function runCurrentStatement() {
        const editor = editorRef.current;
        if (editor && latestProps.current) {
          const selection = editor.getSelection();
          const model = editor.getModel();
          editor.focus();
          if (selection && model) {
            const pos = model.getOffsetAt(selection.getPosition());
            const statement = statementForCursor(
              latestProps.current.value || '',
              {},
              pos
            );
            if (statement && statement.text.trim().length > 0) {
              // eslint-disable-next-line no-unused-expressions
              latestProps.current.runStatementAt?.(statement.text);
              flashStatement(statement);
              redecorateQueryVars();
            }
          }
        }
      },
      [flashStatement, redecorateQueryVars]
    );

    const runSelectedText = useCallback(function runSelectedText() {
      const editor = editorRef.current;

      if (editor && latestProps.current) {
        const query = getSelectedText() ?? '';
        if (query.length > 0) {
          latestProps.current.runStatementAt?.(query);
        } else {
          latestProps.current.runQuery?.();
        }
      }
    }, []);

    const decorateJSON = useCallback(() => {
      const editor = editorRef.current;
      const model = editor?.getModel();
      if (!editor || !model) {
        return;
      }
      const value = editor.getValue();
      const newDecorations = JSON_REGEX_COMBO.flatMap((regexVar) => {
        return [...value.matchAll(regexVar.regex)].map((match) => {
          const start = match.index ?? 0;
          const end = start + match[0].length;
          const startPosition = model.getPositionAt(start);
          const endPosition = model.getPositionAt(end);
          const range = Range.fromPositions(startPosition, endPosition);
          return {
            range,
            options: { inlineClassName: regexVar.className }
          };
        });
      });
      setJsonDecorations(newDecorations);
    }, []);

    useEffect(() => {
      let tabModel = model;
      const modelAlreadyCreated = Boolean(editorStateProp?.model);

      if (!model) {
        tabModel = editor.createModel(value || '', editorLanguage);
        setModel(tabModel);
      }

      const container = containerRef.current;
      if (!container) {
        console.error('Editor container not found!');
        return;
      }

      const monacoEditor = (editorRef.current = editor.create(container, {
        value: value || '',
        contextmenu: false,
        wordWrap: wordWrap ? 'on' : 'off',
        theme:
          theme.type === 'light' && !showDarkTheme && cuiThemeName !== 'dark'
            ? 'clickhouse-light'
            : 'clickhouse',
        readOnly: Boolean(readOnly),
        model: tabModel,
        fontFamily: 'Inconsolata',
        fontWeight: '500',
        fontSize: 16,
        automaticLayout: true,
        padding: {
          top: 8,
          bottom: 8
        },
        minimap: {
          enabled: false
        },
        suggestOnTriggerCharacters: true,
        renderLineHighlight: 'none',
        scrollBeyondLastLine: false,
        scrollbar: {
          verticalScrollbarSize: 8,
          horizontalScrollbarSize: 8
        },
        find: {
          addExtraSpaceOnTop: false,
          cursorMoveOnType: false
        },
        fixedOverflowWidgets: true,
        lineNumbers: showLines ? 'on' : 'off',
        lineNumbersMinChars: 3,
        lineDecorationsWidth: 0,
        unicodeHighlight: {
          ambiguousCharacters: false
        }
      }));

      // hack to change opener service
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const linkDetector = monacoEditor.getContribution('editor.linkDetector');

      // TODO: find out why openerService does not exist on linkDetector
      // @ts-ignore
      if (linkDetector?.openerService?.open) {
        // @ts-ignore
        linkDetector.openerService.open = (
          target: string,
          options: string | null
        ) => {
          openBrowserWindow(target, options);
        };
      }

      const editorModel = monacoEditor.getModel();
      if (!editorModel) {
        console.error("Editor model doesn't exist!");
        return;
      }
      editorModel.updateOptions({ insertSpaces: true, tabSize: indentWidth });

      if (selection) {
        monacoEditor.setSelection(selection);
      }

      monacoEditor.onDidChangeModelContent(() => {
        const { onChange } = latestProps.current;
        cancelFlash();
        if (editorLanguage === 'json') {
          decorateJSON();
        } else if (isSQLLanguage(editorLanguage)) {
          redecorateQueryVars();
        }

        const value = monacoEditor.getValue();
        typeof onChange === 'function' && onChange(value);
      });

      monacoEditor.onDidChangeCursorSelection(({ selection }) => {
        setSelection(copySelection(selection));
      });

      monacoEditor.onDidFocusEditorWidget(() => {
        logEvent && logEvent('focus', 'trigger');
      });

      monacoEditor.onDidBlurEditorWidget(() => {
        logEvent && logEvent('blur', 'trigger');
      });

      if (autofocus) {
        if (!modelAlreadyCreated) {
          moveCursorToEnd(monacoEditor);
        }
        monacoEditor.focus();
      }

      const hasTextValue = () => {
        return editorRef.current && editorRef.current.getValue().length > 0;
      };
      monacoEditor.addAction({
        id: 'runQuery',
        label: 'Run Query',
        keybindings: [KeyMod.CtrlCmd | KeyCode.Enter],
        run: () => {
          // eslint-disable-next-line no-unused-expressions
          if (hasTextValue()) {
            logEvent && logEvent('shortcutAllCommandsRun', 'shortcut');
            runSelectedText();
          }
        }
      });

      monacoEditor.addAction({
        id: 'runStatement',
        label: 'Run Statement',
        keybindings: [KeyMod.CtrlCmd | KeyMod.Shift | KeyCode.Enter],
        run: () => {
          if (hasTextValue()) {
            logEvent && logEvent('shortcutAtCursorRun', 'shortcut');
            runCurrentStatement();
          }
        }
      });

      monacoEditor.addAction({
        id: 'formatQuery',
        label: 'Format Query',
        keybindings: [KeyMod.CtrlCmd | KeyMod.Alt | KeyCode.KeyF],
        run: () => {
          // eslint-disable-next-line no-unused-expressions
          logEvent && logEvent('shortcutQueryFormat', 'shortcut');
          latestProps.current.formatQuery?.();
        }
      });

      monacoEditor.addAction({
        id: 'saveQuery',
        label: 'Save Query',
        keybindings: [KeyMod.CtrlCmd | KeyCode.KeyS],
        run: () => {
          const { onSave } = latestProps.current;
          onSave && onSave();
        }
      });

      monacoEditor.addAction({
        id: 'generateSql',
        label: 'Generate SQL',
        keybindings: [KeyMod.CtrlCmd | KeyCode.KeyK],
        run: () => {
          const { generateSql } = latestProps.current;
          generateSql && generateSql();
        }
      });

      setTimeout(() => {
        if (editorLanguage === 'json') {
          decorateJSON();
        } else if (isSQLLanguage(editorLanguage)) {
          redecorateQueryVars();
        }
        if (!readOnly) {
          monacoEditor.focus();
        }
      }, 0);

      if (scrollPositionProp) {
        monacoEditor.setScrollTop(scrollPositionProp);
      }

      document.fonts?.ready.then(editor.remeasureFonts).catch(console.error);
      return () => {
        // monacoEditor.getModel()?.dispose()
        monacoEditor.dispose();
      };
    }, []);

    useEffect(() => {
      editor.setTheme(
        theme.type === 'light' && !showDarkTheme && cuiThemeName !== 'dark'
          ? 'clickhouse-light'
          : 'clickhouse'
      );
    }, [theme.type, showDarkTheme, cuiThemeName]);

    useEffect(() => {
      if (editorRef.current) {
        editorRef.current.updateOptions({
          readOnly
        });
      }
    }, [readOnly]);

    const editorState = useMemo(
      () => ({
        model,
        selection
      }),
      [...splatSelection(selection), model]
    );

    // onStateChange when editor state changes internally
    useEffect(() => {
      const { onStateChange } = latestProps.current;
      onStateChange && onStateChange(editorState);
    }, [editorState]);

    useEffect(() => {
      const model = editorRef.current && editorRef.current.getModel();
      const oldLanguage = model && model.getLanguageId();
      if (editorRef.current && editorLanguage !== oldLanguage) {
        const newModel = editor.createModel(
          editorRef.current.getValue() || '',
          editorLanguage
        );
        editorRef.current.setModel(newModel);
        setModel(newModel);
      }
    }, [editorLanguage]);

    useEffect(() => {
      if (editorRef.current) {
        const model = editorRef.current.getModel();
        if (model) {
          model.updateOptions({ tabSize: indentWidth });
        }
      }
    }, [indentWidth]);

    const replaceEditorContent = useCallback(
      (newValue: string) => {
        // replace content w/o nuking the undo stack like we would get with editor.setValue()
        const editor = editorRef.current;
        const model = editorRef.current?.getModel();
        if (!editor || !model) {
          return;
        }

        editor.updateOptions({
          readOnly: false
        });

        const oldValue = editor.getValue();
        const oldPosition =
          editor.getSelection()?.getPosition() ?? new Position(0, 0);
        const oldOffset = model.getOffsetAt(oldPosition);
        if (newValue !== oldValue) {
          const range = model.getFullModelRange();
          editor.executeEdits(null, [
            {
              text: newValue,
              range
            }
          ]);
          const { sqlVariant } = latestProps.current;
          const newCursorOffset = updatedCursorPosition(
            oldValue,
            oldOffset,
            newValue,
            sqlVariant
          );
          const position = model.getPositionAt(newCursorOffset);
          editor.setSelection(Range.fromPositions(position, position));

          editor.updateOptions({
            readOnly
          });
        }
      },
      [editorRef]
    );

    useEffect(() => {
      if (firstLoadRef.current) {
        replaceEditorContent(value);
      } else {
        firstLoadRef.current = true;
      }
    }, [value]);

    useImperativeHandle(ref, () => ({
      // replaceEditorState: (state) => onChange(state),
      replaceEditorContent,
      getEditorState() {
        return editorState;
      },
      getText() {
        return editorRef.current && editorRef.current.getValue();
      },
      getScrollPosition() {
        return editorRef.current ? editorRef.current.getScrollTop() : 0;
      },
      getSelectedText,
      hasFocus: () => {
        return Boolean(editorRef.current && editorRef.current.hasTextFocus());
      },
      focusEditor() {
        editorRef.current && editorRef.current.focus();
      },
      copy() {
        if (editorRef.current) {
          const editor = editorRef.current;
          const model = editor.getModel();
          const selection = editor.getSelection();
          const selectionEmpty = selection && selection.isEmpty();

          if (selectionEmpty) {
            model && editor.setSelection(model.getFullModelRange());
          }

          editorRef.current.focus();
          document.execCommand('copy');

          if (selectionEmpty) {
            editor.setSelection(selection);
          }
        }
      },
      layout() {
        editorRef.current && editorRef.current.focus();
      },
      async paste(value: string | undefined) {
        if (editorRef.current) {
          const text = value || (await navigator.clipboard.readText());
          const selection = editorRef.current.getSelection();
          if (selection) {
            editorRef.current.executeEdits(null, [
              {
                text,
                range: selection
              }
            ]);
          }
          // what does this code do? Aren't we just setting the position to what it already is?
          const position = editorRef.current.getPosition();
          if (position) {
            editorRef.current.setPosition(position);
            editorRef.current.focus();
          }
        }
      },
      innerRef: {
        blur() {
          if (
            editorRef.current &&
            editorRef.current.hasTextFocus() &&
            document.activeElement instanceof window.HTMLElement
          ) {
            document.activeElement.blur();
          }
        },
        contains(element: Node) {
          return containerRef.current && containerRef.current.contains(element);
        }
      },
      runCurrentStatement,
      runSelectedText
    }));

    return (
      <div css={relativeContainer} className={className}>
        <div ref={containerRef} css={containerStyle} />
      </div>
    );
  }
);

/* eslint-disable react/no-unused-prop-types */
MonacoSQLEditor.propTypes = {
  /**
   * Options for the auto complete dropdown
   * keyword - autual string to match
   * prefix - prefix required to precede the suggestion before it is suggested
   * description - description of suggestion
   * type - type of keyword (keyword, column, function, etc.)
   */
  autocompleteOptions: PropTypes.any,

  /**
   * Generate initial editor state with selection and focus
   */
  autofocus: PropTypes.bool,
  /**
   * You can pass the an editor state to the component to persist the editor state outside the component
   */
  editorState: PropTypes.any,
  /**
   * Pass a function to be called on format key shortcut
   */
  formatQuery: PropTypes.func,
  /**
   * Returns the data from editor as plain text
   */
  onChange: PropTypes.func,
  /**
   * Function to execute on cmd + s
   */
  onSave: PropTypes.func,
  /**
   * Returns the editor state.
   * Use this function to save editor changes to external state
   */
  onStateChange: PropTypes.func,
  /**
   * Set true to make the editor read only
   */
  readOnly: PropTypes.bool,
  /**
   * Function to execute on [ctrl+enter | command+enter] key press
   * The query string will be passed to the function's first argument
   */
  runQuery: PropTypes.func,
  /**
   * Scroll position for the editor
   */
  scrollPosition: PropTypes.number,
  /**
   * Vale as plain text
   */
  value: PropTypes.string,
  indentWidth: PropTypes.number,
  showLines: PropTypes.bool,
  showDarkTheme: PropTypes.bool
};
/* eslint-enable react/no-unused-prop-types */

MonacoSQLEditor.defaultProps = {
  autofocus: false,
  onChange: () => null,
  readOnly: false,
  scrollPosition: 0,
  value: '',
  indentWidth: 4,
  showLines: true,
  showDarkTheme: false,
  wordWrap: false
};

MonacoSQLEditor.displayName = 'MonacoSQLEditor';

export default MonacoSQLEditor;
