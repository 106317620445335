import React, { useMemo } from 'react';

import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip
} from 'recharts';

import PrimitiveLegend from './ChartComponents/Legend';
import PrimitiveTooltip from './ChartComponents/Tooltip';
import { useDataLimit, useResizeListener } from './ChartUtils/hooks';
import defaultClasses from './styles';

const makeActiveShapeRenderer = (theme) => (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 22) * cos;
  const my = cy + (outerRadius + 22) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g key={value}>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        strokeWidth="1.5"
        fill="none"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey + 4}
        textAnchor={textAnchor}
        fill={theme.colors.c4}
        style={{
          fontWeight: 500,
          fontSize: '12px',
          letterSpacing: '0.5px'
        }}
      >
        {value}
      </text>
    </g>
  );
};

const PrimitivePieChart = React.forwardRef((props, ref) => {
  const {
    animate,
    footer,
    data: dataProp,
    editable,
    values,
    categories,
    showLegend,
    subtitle,
    title,
    type
  } = props;
  const theme = useTheme();

  const activeShapeRenderer = useMemo(() => {
    return makeActiveShapeRenderer(theme);
  }, [theme]);

  const data = useDataLimit(dataProp);
  const [height, rootRef, headerRef, footerRef, onResize] = useResizeListener([
    title,
    subtitle,
    footer
  ]);
  const colors = theme.colors.chart.values;

  const normalizedData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    } else {
      const type = data.reduce((type, row) => {
        if (row[values] == null) {
          return type;
        } else if (typeof row[values] === 'number') {
          return 'number';
        } else if (!isNaN(row[values])) {
          return 'string';
        } else {
          return 'invalid';
        }
      }, 'number');
      if (type === 'number') {
        return data;
      } else if (type === 'string') {
        return data.map((value) => {
          return {
            ...value,
            [values]: value[values] == null ? 0 : parseFloat(value[values])
          };
        });
      } else {
        return [];
      }
    }
  }, [data, values]);

  return (
    <div ref={rootRef} css={defaultClasses.root}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      <div ref={headerRef} css={defaultClasses.headerContainer}>
        <div css={defaultClasses.header}>
          {title || (editable ? 'Enter title here' : '')}
        </div>
        {subtitle && (
          <div css={defaultClasses.subtitle}>
            {subtitle || (editable ? 'Enter subtitle here' : '')}
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <PieChart css={defaultClasses.chartStyle}>
          {categories &&
            normalizedData[0] &&
            normalizedData[0][categories] &&
            showLegend && (
              <Legend
                payload={data.map((item, index) => ({
                  id: `${item[categories]}-${index}`,
                  color: colors[index % colors.length],
                  value: item[categories]
                }))}
                content={PrimitiveLegend}
              />
            )}
          <Pie
            isAnimationActive={animate}
            activeIndex={
              Array.isArray(data) ? data.map((entry, index) => index) : []
            }
            activeShape={activeShapeRenderer}
            dataKey={values}
            data={normalizedData}
            innerRadius={type === 'doughnut' ? '50%' : '0'}
            outerRadius="70%"
          >
            {Array.isArray(data) &&
              data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
          </Pie>
          <Tooltip
            content={<PrimitiveTooltip categories={categories} />}
            // formatter={(value, name, props) => {
            //   return [value, props.payload[categories]]
            // }}
          />
        </PieChart>
      </ResponsiveContainer>
      <div ref={footerRef} css={defaultClasses.footer}>
        {footer || (editable ? 'Enter footer here' : '')}
      </div>
    </div>
  );
});

PrimitivePieChart.displayName = 'PieChart';

PrimitivePieChart.propTypes = {
  animate: PropTypes.bool,
  footer: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  values: PropTypes.string,
  categories: PropTypes.string,
  showLegend: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['pie', 'doughnut'])
};

PrimitivePieChart.defaultProps = {
  animate: true,
  data: [],
  editable: false,
  showLegend: true,
  type: 'pie'
};

export default PrimitivePieChart;
