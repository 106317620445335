import { createToast } from 'primitives';
import { useCallback, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';

export interface UsageStatementCSV {
  getUsageStatementCSV: (organizationId: string, billId: string) => Promise<string>;
  isLoading: boolean;
}

export const useUsageStatementCSV = (): UsageStatementCSV => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);

  const getUsageStatementCSV = useCallback(
    async (organizationId: string, billId: string): Promise<string> => {
      try {
        setIsLoading(true);
        const response = await apiClient.billing.getUsageStatement(organizationId, billId);
        return response.report;
      } catch (e) {
        createToast('Unable to get Usage Statement data', 'alert', errorMessage(e));
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [apiClient.billing]
  );

  return { getUsageStatementCSV, isLoading };
};
