import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { routes } from 'src/lib/routes';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

const trialServiceLimitReachedModalOpen = atom<boolean>(false);
const nonTrialServiceLimitReachedModalOpen = atom<boolean>(false);
// Return type of the useTrialServiceLimitReachedModal hook.
type UseTrialServiceLimitReachedResult = {
  isTrialModalOpen: boolean;
  isNonTrialModalOpen: boolean;
  setIsTrialModalOpen: (open: boolean) => void;
  setIsNonTrialModalOpen: (open: boolean) => void;
  /**
   * takes the user to the create new service page unless the user is in trial and has reached the service limit
   * @returns void
   */
  createNewServiceOrShowTrialLimitModal: () => void;
};

/**
 * This hook handles visibility state of the Trial Service Limit Reached modal
 * @returns UseTrialServiceLimitReachedResult
 */
export const useServiceLimitReachedModal =
  (): UseTrialServiceLimitReachedResult => {
    const [
      trialServiceLimitReachedModalIsOpen,
      setTrialServiceLimitReachedModalIsOpen
    ] = useAtom(trialServiceLimitReachedModalOpen);
    const [
      nonTrialServiceLimitReachedModalIsOpen,
      setNonTrialServiceLimitReachedModalIsOpen
    ] = useAtom(nonTrialServiceLimitReachedModalOpen);
    const currentOrganization = useCurrentOrganizationOrThrow();
    const { isInstanceStateLoading } = useInstanceStateManager();
    const currentOrgInstances = useCurrentOrganizationInstances();

    const hasReachedLimit =
      currentOrgInstances.length >=
      currentOrganization.restrictions.maxInstanceCount;
    const isTrial = [
      'IN_TRIAL',
      'IN_TRIAL_GRACE_PERIOD',
      'DECOMMISSIONED'
    ].includes(currentOrganization.billingStatus);

    const createNewService = useCallback((): void => {
      if (isInstanceStateLoading) {
        return;
      }

      if (
        hasReachedLimit ||
        !currentOrganization.restrictions.canCreateInstances
      ) {
        if (isTrial) {
          setTrialServiceLimitReachedModalIsOpen(true);
        } else {
          setNonTrialServiceLimitReachedModalIsOpen(true);
        }
      } else {
        navigateTo(routes.createNewService());
      }
    }, [
      currentOrganization.restrictions,
      hasReachedLimit,
      isTrial,
      isInstanceStateLoading,
      setTrialServiceLimitReachedModalIsOpen
    ]);

    return {
      isTrialModalOpen: trialServiceLimitReachedModalIsOpen,
      isNonTrialModalOpen: nonTrialServiceLimitReachedModalIsOpen,
      setIsTrialModalOpen: setTrialServiceLimitReachedModalIsOpen,
      setIsNonTrialModalOpen: setNonTrialServiceLimitReachedModalIsOpen,
      createNewServiceOrShowTrialLimitModal: createNewService
    };
  };
