import { ConfirmationDialog, TextField } from '@clickhouse/click-ui';
import { KeyboardEvent, ReactElement, useState } from 'react';

interface SaveQueryModalProps {
  open: boolean;
  queryName: string;
  onCancel: () => void;
  onSave: (name: string) => Promise<void>;
}

export default function SaveQueryModal({
  open,
  queryName,
  onCancel,
  onSave
}: SaveQueryModalProps): ReactElement | null {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(queryName);

  if (open !== true) {
    return null;
  }

  const saveAction = (): void => {
    setSaving(true);
    onSave(name)
      .then(() => setSaving(false))
      .catch(() => setSaving(false));
  };

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onConfirm={saveAction}
      title="Save query as..."
      primaryActionLabel="Save Query"
      secondaryActionLabel="Cancel"
      loading={saving}
      data-testid="saveQueryModal"
    >
      <TextField
        disabled={saving}
        label="Query Name:"
        autoComplete="off"
        type="text"
        value={name}
        id="save-query-modal-input"
        name="save-query-modal-input"
        data-testid="saveQueryModalInput"
        placeholder="Untitled query [n]"
        onChange={(value: string): void => {
          setName(value);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void => {
          if (e.key === 'Enter') {
            saveAction();
          }
        }}
        className="fs-exclude"
      />
    </ConfirmationDialog>
  );
}
