import { ConfirmationDialog, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { routes } from 'src/lib/routes';

function NonTrialServiceLimitReachedModal(): ReactElement | null {
  const { isNonTrialModalOpen, setIsNonTrialModalOpen } =
    useServiceLimitReachedModal();

  if (!isNonTrialModalOpen) {
    return null;
  }

  const navigateToSupport = (): void => {
    navigateTo(routes.support({ serviceId: undefined }));
  };

  return (
    <ConfirmationDialog
      title="Service limit reached"
      open={isNonTrialModalOpen}
      onCancel={() => setIsNonTrialModalOpen(false)}
      onConfirm={navigateToSupport}
      primaryActionLabel="Contact support"
      secondaryActionLabel="Close"
      showClose
      data-testis="non-trial-service-limit-dialog"
    >
      <Text>
        You have already reached the maximum number of services allowed for this
        organization. Please contact support to increase this limit.
      </Text>
    </ConfirmationDialog>
  );
}

export default NonTrialServiceLimitReachedModal;
