import { Icon, useCUITheme } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { IntegrationSummaryType } from 'src/integrations/controller/useIntegrationSummary';
import { mapIntegrationToClickUiLogo } from 'src/util/strapi';
import styled from 'styled-components';
import { PropsOf } from 'types/commonTypes';

type IconSize = NonNullable<PropsOf<typeof Icon>['size']>;

interface IntegrationLogoProps {
  integration: IntegrationSummaryType;
  size: IconSize;
}

const CustomIconImage = styled.img<{ $size: IconSize }>`
  ${({ theme, $size }): string => `
    height: ${theme.click.image[$size].size.height};
    width: ${theme.click.image.lg.size.width};
  `};
`;

export function IntegrationLogo({
  integration,
  size
}: IntegrationLogoProps): ReactElement {
  const { name } = useCUITheme();
  const maybeClickUiLogo = mapIntegrationToClickUiLogo(integration.name);
  if (maybeClickUiLogo) {
    return <Icon name={maybeClickUiLogo} size={size} />;
  }

  if (integration.logo) {
    return (
      <CustomIconImage
        src={integration[name === 'dark' ? 'logo_dark' : 'logo'].fullUrl}
        alt="card icon"
        aria-hidden
        $size={size}
      />
    );
  }

  return <Icon name="clickhouse" size={size} />;
}
