import {
  Avatar,
  Badge,
  Container,
  Dropdown,
  EllipsisContent,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import AuthMethodIndicatorDot from 'src/components/AuthMethodIndicatorDot';
import ClickHouseFullIcon from 'src/layout/ServiceSidebar/ClickHouseFullIcon';
import { UserState } from 'src/user/userState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useOrgFeature, useUserFeature } from 'src/lib/features';
import { NotificationBell } from 'src/components/Notifications/NotificationBell';
import styled from 'styled-components';

const FullWidthContainer = styled(Container)`
  width: 100%;
  padding: 0 1rem;
`;

const CustomDropdownContent = styled(Dropdown.Content)`
  width: 100%;
  max-width: 220px;
`;

const LogoLink = styled(Link)`
  height: 25px;
`;

export const SidebarHeader = ({
  currentUser,
  logOut,
  numInvitations,
  setInvitationsModalOpen,
  notificationContainer
}: SidebarHeaderProps): ReactElement => {
  const userName = currentUser.name || currentUser.email;
  const initials = currentUser.initials || userName || '';

  const showNotifications = useOrgFeature('FT_ORG_NOTIFICATIONS');

  return (
    <Container
      alignItems="center"
      padding="none"
      justifyContent="space-between"
      isResponsive={false}
    >
      <Container orientation="horizontal" alignItems="center">
        <LogoLink aria-label="ClickHouse Logo" to="/">
          <ClickHouseFullIcon />
        </LogoLink>
      </Container>
      <Container
        orientation="horizontal"
        alignItems="center"
        justifyContent="right"
        gap="xs"
      >
        {showNotifications && (
          <NotificationBell notificationContainer={notificationContainer} />
        )}
        <Dropdown>
          <Dropdown.Trigger data-testid="sidebar-header-dropdown-trigger">
            <Avatar
              text={initials}
              textSize="md"
              data-testid="sidebar-header-avatar"
            />
          </Dropdown.Trigger>
          <CustomDropdownContent side="bottom" align="start" sideOffset={6}>
            <FullWidthContainer orientation="vertical">
              <Spacer size="sm" />
              <Container orientation="horizontal">
                <Text color="muted" size="sm">
                  Signed in as
                </Text>
                <AuthMethodIndicatorDot />
              </Container>
              <EllipsisContent component={Text} size="lg">
                {userName}
              </EllipsisContent>
            </FullWidthContainer>
            <Separator size="sm" />
            <Dropdown.Item
              data-testid="profile-menu-item"
              onClick={(): void => navigateTo('/profile')}
            >
              Profile
            </Dropdown.Item>
            {numInvitations > 0 && (
              <Dropdown.Item
                data-testid="sidebar-header-invitations"
                onClick={(): void => {
                  // hack to avoid dropdown closing and modal opening at the same time
                  setTimeout(() => {
                    setInvitationsModalOpen(true);
                  });
                }}
              >
                <Container
                  justifyContent="space-between"
                  alignItems="center"
                  fillWidth={false}
                >
                  <Text>Invitations</Text>
                  <Badge state="danger" text={numInvitations} />
                </Container>
              </Dropdown.Item>
            )}
            <Dropdown.Item
              icon="share"
              iconDir="end"
              data-testid="docs-menu-item"
              onClick={(): void => {
                window.open('https://clickhouse.com/docs', '_blank');
              }}
            >
              Docs
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(): void => void logOut()}
              data-testid="sign-out-menu-item"
            >
              Sign out
            </Dropdown.Item>
          </CustomDropdownContent>
        </Dropdown>
      </Container>
    </Container>
  );
};

interface SidebarHeaderProps {
  currentUser: UserState;
  logOut: (url?: string) => Promise<void>;
  numInvitations: number;
  setInvitationsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notificationContainer: HTMLDivElement | null;
}
