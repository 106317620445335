import { Button, Container, IconButton, Separator } from '@clickhouse/click-ui';
import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import { Toolbar as PrimtiveToolbar } from 'primitives';
import { ReactElement, useState } from 'react';
import { SchemaColumn } from 'shared/src/clickhouse/types';
import { RefreshDataArgs } from 'src/components/TableView';
import { SetFilterConfigFunction } from 'src/components/TableView/types';
import { InteractionType } from 'src/lib/logger';
import { useTabActions } from 'src/state/tabs';
import {
  Filter as FilterType,
  FilterConfig,
  Sort as SortType
} from 'src/state/tabs/types';
import Filter from 'src/components/TableView/DataView/Toolbar/Filter';
import Sort from 'src/components/TableView/DataView/Toolbar/Sort';

interface Props {
  downloadTable: () => void;
  getSelectQuery: () => string;
  openInsertRow: () => void;
  refreshData: (args?: RefreshDataArgs) => void;
  selectedTableName: string;
  logEvent: (
    component: string,
    event: string,
    interaction?: InteractionType
  ) => void;
  columns: Array<SchemaColumn>;
  filterConfig: FilterConfig;
  setFilterConfig: SetFilterConfigFunction;
  tabId: string;
}

export default function Toolbar({
  downloadTable,
  getSelectQuery,
  openInsertRow,
  refreshData,
  selectedTableName,
  logEvent,
  filterConfig,
  setFilterConfig,
  columns
}: Props): ReactElement {
  const { addNewQueryTab } = useTabActions();
  const [downloading, setDownloading] = useState(false);

  return (
    <PrimtiveToolbar
      name={selectedTableName}
      allowEditing={false}
      buttonGap="10px 32px"
    >
      <Container gap="sm" isResponsive={false}>
        <Button
          type="secondary"
          iconLeft="query"
          onClick={(): void => {
            logEvent('toolBar', 'createQueryButtonClick');
            addNewQueryTab({
              query: getSelectQuery(),
              type: 'query',
              saved: false,
              title: 'Untitled query'
            });
          }}
          data-testid="toolbarCreateQueryBtn"
        >
          Create query
        </Button>
        <Separator size="xs" orientation="vertical" />
        <Button
          iconLeft="insert-row"
          type="secondary"
          onClick={openInsertRow}
          data-testid="toolbarInsertRowBtn"
        >
          Insert row
        </Button>
        <Filter
          currentFilters={filterConfig.filters}
          columns={columns}
          logEvent={logEvent}
          onFilterApply={(filters: Array<FilterType>): void => {
            if (!isEqual(filterConfig.filters, filters)) {
              setFilterConfig(
                produce(filterConfig, (draft) => {
                  draft.filters = filters;
                })
              );
            }
          }}
        />
        <Sort
          currentSorts={filterConfig.sorts}
          logEvent={logEvent}
          onSortApply={(sorts: Array<SortType>): void => {
            if (!isEqual(filterConfig.sorts, sorts)) {
              setFilterConfig(
                produce(filterConfig, (draft) => {
                  draft.sorts = sorts;
                })
              );
            }
          }}
          columns={columns}
        />
        <Separator size="xs" orientation="vertical" />
        <IconButton
          icon="download"
          type="ghost"
          disabled={downloading}
          onClick={(): void => {
            try {
              setDownloading(true);
              logEvent('toolBar', 'exportButtonClick');
              downloadTable();
            } finally {
              setDownloading(false);
            }
          }}
          data-testid="toolbarExportBtn"
        />
        <IconButton
          icon="refresh"
          type="ghost"
          onClick={(): void => {
            logEvent('toolBar', 'refreshButtonClick');
            refreshData();
          }}
        />
      </Container>
    </PrimtiveToolbar>
  );
}
