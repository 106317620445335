import { Separator, useCUITheme } from '@clickhouse/click-ui';
import { css } from '@emotion/react';
import { Fragment } from 'react';

import ContentLoader from 'react-content-loader';

const queryLoadingStyle = css({ padding: 0 });

function QueryLoading(): JSX.Element {
  const theme = useCUITheme();
  return (
    <div css={queryLoadingStyle}>
      <ContentLoader
        width={290}
        height={129}
        foregroundColor={theme.global.color.stroke.intense}
        backgroundColor={theme.global.color.stroke.muted}
        speed={2}
      >
        <rect x="12" y="18" rx="0" ry="0" width="90" height="12" />
        <rect x="125" y="18" rx="0" ry="0" width="151" height="12" />
        <rect x="12" y="58" rx="0" ry="0" width="150" height="12" />
        <rect x="12" y="83" rx="0" ry="0" width="160" height="12" />
        <rect x="12" y="108" rx="0" ry="0" width="100" height="12" />
      </ContentLoader>
    </div>
  );
}

const QueryListLoading = (): JSX.Element => {
  const rows = 5;

  return (
    <Fragment>
      {new Array(rows).fill(0).map((_, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && <Separator size="xs" />}
            <QueryLoading />
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default QueryListLoading;
