import React, { useEffect } from 'react';

import get from 'lodash/get';
import { useDrag } from 'react-dnd-cjs';
import { getEmptyImage } from 'react-dnd-html5-backend-cjs';
import { logEvent } from './analytics';
import {
  columnActionsContainer,
  columnIconStyle,
  columnNameStyle,
  columnStyle,
  dataTypeStyle
} from './styles';
import { Container, Icon, IconButton, Tooltip } from '@clickhouse/click-ui';

const Column = ({
  axis,
  column,
  onDragEnd,
  removeColumn,
  source,
  type,
  testId
}) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { column, dataType: type, type: 'column', axis },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    },
    begin: () => {
      logEvent('columnDrag');
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        logEvent('columnDrop');
        onDragEnd(item, monitor.getDropResult(), source);
      }
    }
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const columnName =
    typeof column === 'string' ? column : get(column, 'column');
  return (
    <div
      ref={drag}
      css={columnStyle}
      data-dragging={isDragging}
      data-testid={testId}
    >
      <Container gap="xs" isResponsive={false}>
        <Icon name="dots-vertical-double" size="sm" />
        <span css={columnNameStyle} className="fs-exclude">
          {columnName}
        </span>
      </Container>
      <div css={columnActionsContainer}>
        <div css={dataTypeStyle}>{type}</div>
        {source !== 'columns' && (
          <Tooltip>
            <Tooltip.Trigger
              css={columnIconStyle}
              onClick={() => {
                if (removeColumn) {
                  removeColumn({ column: columnName, source, type });
                }
              }}
            >
              <IconButton icon="cross" type="ghost" />
            </Tooltip.Trigger>
            <Tooltip.Content>Remove column</Tooltip.Content>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Column;
