import { Container, SuccessAlert, Text } from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import {
  AccordionWrapper,
  CommonAccordionProps,
  GettingStartedValueType
} from 'src/components/GettingStarted/AccordionWrapper';
import { ClickHouseVerticalLoading } from 'src/components/ClickHouseVerticalLoading/ClickHouseVerticalLoading';

const CreateServiceAccordion = ({
  moveToNextStep,
  ...props
}: CommonAccordionProps & {
  moveToNextStep: (type: GettingStartedValueType) => void;
}): ReactElement => {
  useEffect(() => {
    if (props.isCompleted) {
      moveToNextStep('createService');
    }
  }, [props.isCompleted, moveToNextStep]);

  return (
    <AccordionWrapper title="Create service" value="createService" {...props}>
      {!props.isCompleted && (
        <Container
          orientation="vertical"
          alignItems="center"
          isResponsive={false}
          gap="sm"
        >
          <ClickHouseVerticalLoading />
          <Container
            orientation="vertical"
            alignItems="center"
            isResponsive={false}
            gap="md"
            maxWidth="400px"
          >
            <Text weight="bold" size="lg">
              Creating your service
            </Text>
            <Text color="muted" align="center">
              We’re almost ready to roll! Give us just a few minutes while we
              finish deploying your service.
            </Text>
          </Container>
        </Container>
      )}
      {props.isCompleted && (
        <SuccessAlert text="Service created successfully" size="medium" />
      )}
    </AccordionWrapper>
  );
};

export default CreateServiceAccordion;
