import { useEffect } from 'react';

import { produce } from 'immer';

import { PIPES_SUFFIX } from 'shared/src/clickpipes';
import {
  ColumnMapping,
  ExistingTableConfig,
  FileBasedImport,
  KafkaImport
} from 'shared/src/dataLoading';
import { Table } from 'shared/src/clickhouse/types';
import { useTables } from 'src/metadata/metadataState';
import { useImportModel } from 'src/state/dataLoading';

import { generateColumnMappings } from 'src/components/ImportView/ImportForm';
import TableMappingForm from 'src/components/ImportView/ImportForm/TableMappingForm';

type TableMappingImportModel = FileBasedImport | KafkaImport;

type Props = {
  collapsibleColumns?: boolean;
};

export const genTableConfigFor = (
  tables: Table[],
  database: string,
  tableName: string,
  tableConfig: ExistingTableConfig
): ExistingTableConfig => {
  const table = tables.find((table) => table.tableName === tableName);
  const tableColumns = table?.columns || [];

  const columnsMapping = generateColumnMappings(
    tableConfig.config.sourceColumns,
    tableColumns
  );

  return {
    tableType: tableConfig.tableType,
    config: {
      ...tableConfig.config,
      schema: database,
      name: tableName,
      columnsMapping
    }
  };
};

export default function ExistingTableForm({
  collapsibleColumns = true
}: Props): JSX.Element | null {
  const { importModel, setTableConfig } = useImportModel();
  const tables = useTables().filter(
    (table) => !table.tableName.endsWith(PIPES_SUFFIX)
  );

  const model = importModel as TableMappingImportModel;

  useEffect(() => {
    const tableConfig = model.data.tableConfig;
    if (tableConfig) {
      setTableConfig(
        genTableConfigFor(
          tables,
          tableConfig.config.schema,
          tableConfig.config.name,
          tableConfig as ExistingTableConfig
        )
      );
    }
  }, [model.data.tableConfig?.config?.name]);

  const tableConfig = model.data.tableConfig;

  if (!(tableConfig?.tableType === 'existing')) {
    return null;
  }

  const onTableChange = (newTable: string): void => {
    if (newTable === tableConfig.config.name) {
      return;
    }

    setTableConfig(
      genTableConfigFor(
        tables,
        tableConfig.config.schema,
        newTable,
        tableConfig
      )
    );
  };

  const onTableMappingChange = (mapping: Array<ColumnMapping>): void => {
    const newTableConfig = produce(tableConfig, (draft) => {
      draft.config.columnsMapping = mapping;
    });

    setTableConfig(newTableConfig);
  };

  return (
    <TableMappingForm
      mapping={tableConfig.config}
      onTableChange={onTableChange}
      onMappingChange={onTableMappingChange}
      tables={tables}
      collapsibleColumns={collapsibleColumns}
    />
  );
}
