import { OrganizationFeatureId, SystemFeatureId, UserFeatureId } from '@cp/common/protocol/features';
import { useCurrentOrganization, useOrganizationStateManager } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';

export function useUserFeature(feature: UserFeatureId): boolean {
  const { user } = useUserStateManager();
  if (!user.features || user.features.length === 0) {
    return false;
  }

  return user.features.includes(feature);
}

export function useSystemFeature(feature: SystemFeatureId): boolean {
  const systemFeatures = window?._env?.features ?? [];
  return Boolean(systemFeatures.includes(feature));
}

export function useOrgFeature(feature: OrganizationFeatureId): boolean {
  const currentOrganization = useCurrentOrganization();

  if (!currentOrganization) {
    return false;
  }

  const orgFeatures = currentOrganization.features;
  return orgFeatures.includes(feature);
}

export function useHasSomeOrgFeature(feature: OrganizationFeatureId): boolean {
  const { organizations } = useOrganizationStateManager();

  return Object.values(organizations).some((org) => org.features.includes(feature));
}

export function useIsUnifiedConsole(): boolean {
  const userFeature = useUserFeature('FT_USER_UNIFIED_CONSOLE');
  const hasSomeOrgUnifiedConsoleFf = useHasSomeOrgFeature('FT_ORG_UNIFIED_CONSOLE');

  return userFeature || hasSomeOrgUnifiedConsoleFf;
}

export function setIsUnifiedConsoleInLocalStorage(value: boolean): void {
  return localStorage.setItem('isUnified', value.toString());
}
export function isUnifiedConsoleInLocalStorage(): boolean {
  return localStorage.getItem('isUnified') === 'true';
}
