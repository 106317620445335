import {
  Checkbox,
  Container,
  Table,
  TableHeaderType,
  TableRowType
} from '@clickhouse/click-ui';
import {
  NOTIFICATION_CHANNEL_TYPES,
  NotificationName
} from '@cp/common/protocol/NotificationInventory';
import { NotificationChannelSetting } from '@cp/common/protocol/Notifications';

import { ReactElement } from 'react';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import { getHumanReadableChannelType } from 'src/pages/NotificationsPage/helpers';
type Props = {
  setting: NotificationChannelSetting;
  notificationName: NotificationName;
  onChange: (
    notificationName: NotificationName,
    setting: NotificationChannelSetting
  ) => void;
};

const ChannelSettingServiceTable = ({
  setting,
  notificationName,
  onChange
}: Props): ReactElement => {
  const services = useCurrentOrganizationInstances();
  const headers: TableHeaderType[] = [
    { label: 'Service name' },
    { label: 'Notify via' }
  ];
  if ('settingsByService' in setting) {
    const rows: TableRowType[] = Object.keys(setting.settingsByService).map(
      (serviceId) => {
        const settings = setting.settingsByService[serviceId];
        const checkboxes = NOTIFICATION_CHANNEL_TYPES.map((channel) => {
          const checked = settings[channel];
          return (
            <Checkbox
              key={channel}
              checked={checked}
              onClick={() => {
                const newSetting: NotificationChannelSetting = {
                  type: setting.type,
                  settingsByService: {
                    ...setting.settingsByService,
                    [serviceId]: {
                      ...settings,
                      [channel]: !checked
                    }
                  }
                };
                onChange(notificationName, newSetting);
              }}
              label={getHumanReadableChannelType(channel)}
            />
          );
        });

        return {
          id: serviceId,
          items: [
            {
              label:
                services.find((service) => service.id === serviceId)?.name ??
                serviceId
            },
            { label: <Container gap="lg">{checkboxes}</Container> }
          ]
        };
      }
    );

    return <Table headers={headers} rows={rows} />;
  }
  return <>Unknown setting type, contact support.</>;
};

const ChannelSettingChannels = ({
  setting,
  onChange,
  notificationName
}: Props): ReactElement => {
  return (
    <Container gap="lg">
      {NOTIFICATION_CHANNEL_TYPES.map((channel) => {
        if ('settings' in setting) {
          const checked = setting.settings[channel];
          return (
            <Checkbox
              key={channel}
              checked={checked}
              onClick={() => {
                const newSetting: NotificationChannelSetting = {
                  type: setting.type,
                  settings: {
                    ...setting.settings,
                    [channel]: !checked
                  }
                };
                onChange(notificationName, newSetting);
              }}
              label={getHumanReadableChannelType(channel)}
            />
          );
        }
      })}
    </Container>
  );
};

export const ChannelSetting = ({
  setting,
  onChange,
  notificationName
}: Props): ReactElement => {
  switch (setting.type) {
    case 'service':
      return (
        <ChannelSettingServiceTable
          setting={setting}
          notificationName={notificationName}
          onChange={onChange}
        />
      );
    case 'billing':
      return (
        <ChannelSettingChannels
          setting={setting}
          notificationName={notificationName}
          onChange={onChange}
        />
      );
    default:
      return <>Unknown setting type, contact support.</>;
  }
};
