import { ReactElement } from 'react';
import LoadingIcon from 'src/components/icons/LoadingIcon';

const RunningQueryIndicator = (): ReactElement => {
  return (
    <div className="running-indicator">
      <LoadingIcon className="loading" width={16} height={16} />
      Running Query
    </div>
  );
};

export default RunningQueryIndicator;
