import {
  ConfirmationDialog,
  TextAreaField,
  Container
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import FileUploadElement from 'src/components/FileUploadElement/FileUploadElement';
import useCreateReplyEvent from 'src/support/controller/useCreateReplyEvent';

interface Props {
  onCancel: () => void;
  caseId: string;
}

function ReplyModal({ onCancel, caseId }: Props): ReactElement {
  const {
    reply,
    setReply,
    progress,
    status,
    fileList,
    onAttachmentChange,
    sendReplyEvent,
    isLoading
  } = useCreateReplyEvent(caseId);
  const onConfirm = async (): Promise<void> => {
    await sendReplyEvent();
  };

  return (
    <ConfirmationDialog
      open
      title="Add Reply"
      onCancel={onCancel}
      primaryActionLabel="Reply"
      showClose
      onConfirm={onConfirm}
      loading={isLoading}
      disabled={reply.length === 0}
    >
      <Container gap="xs" orientation="vertical">
        <TextAreaField
          label="Reply"
          onChange={setReply}
          value={reply}
          maxLength={32000}
          data-testid="case-reply"
        />
        <FileUploadElement
          onChange={onAttachmentChange}
          status={status}
          progress={progress}
          fileList={fileList}
        />
      </Container>
    </ConfirmationDialog>
  );
}

export default ReplyModal;
