import { platformOptions } from 'src/components/primitives/lib/ClickhouseLoading';

export const suggestions = [
  {
    windows: (
      <>
        Use <span>Ctrl + ⏎</span> to run your query
      </>
    ),
    mac: (
      <>
        Use <span>⌘ + ⏎</span> to run your query
      </>
    ),
    linux: (
      <>
        Use <span>Ctrl + ⏎</span> to run your query
      </>
    )
  },
  {
    windows: (
      <>
        Select code on multiple lines by enabling multi-cursors{' '}
        <span>Ctrl + Alt + ↑/↓</span>
      </>
    ),
    mac: (
      <>
        Select code on multiple lines by enabling multi-cursors{' '}
        <span>⌘ + Alt + ↑/↓</span>
      </>
    ),
    linux: (
      <>
        Select code on multiple lines by enabling multi-cursors{' '}
        <span>Ctrl + Alt + ↑/↓</span>
      </>
    )
  },
  {
    windows: (
      <>
        Auto-format your query using <span>Ctrl + Alt + F</span>
      </>
    ),
    mac: (
      <>
        Auto-format your query using <span>⌘ + Alt + F</span>
      </>
    ),
    linux: (
      <>
        Auto-format your query using <span>Ctrl + Alt + F</span>
      </>
    )
  },
  {
    windows: (
      <>
        Use <span>Ctrl + /</span> to comment out your current selection
      </>
    ),
    mac: (
      <>
        Use <span>⌘ + /</span> to comment out your current selection
      </>
    ),
    linux: (
      <>
        Use <span>Ctrl + /</span> to comment out your current selection
      </>
    )
  },
  {
    windows: (
      <>
        Rick click a table and select <span>View create table SQL</span> to see
        the table definition
      </>
    ),
    mac: (
      <>
        Rick click a table and select <span>View create table SQL</span> to see
        the table definition
      </>
    ),
    linux: (
      <>
        Rick click a table and select <span>View create table SQL</span> to see
        the table definition
      </>
    )
  }
];

export const randomSuggestion = (osType: platformOptions) => {
  const suggestion = suggestions[Math.floor(Math.random() * 5)];
  return suggestion[osType];
};
