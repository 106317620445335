import { ClientError } from 'api/src/lib/errorHandling';
import { SendRequestIntegration } from 'shared';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

export class IntegrationApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async sendRequestIntegration(text: string, recaptchaToken: string): Promise<void> {
    const request: SendRequestIntegration = { text, recaptchaToken };
    const response = await this.httpClient.post(
      `${config.controlPlane.apiHost}/api/account/sendRequestIntegration`,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (response.ok) {
      return;
    } else {
      console.error(response.text());
      throw new ClientError(response.status, 'Invalid response from server');
    }
  }
}
