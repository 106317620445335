import config from 'src/lib/config';

export function apiUrl(path: string): string {
  return `${config.apiUrl}${path}`;
}

export function controlPlaneUrl(path: string): string {
  return `${config.controlPlane.apiHost}${path}`;
}

export function controlPlaneHost(path: string): string {
  return `${config.controlPlane.host}${path}`;
}
