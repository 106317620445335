import {
  Dialog,
  Button,
  TextField,
  Separator,
  Container
} from '@clickhouse/click-ui';
import { MAX_INSTANCE_NAME_LENGTH } from '@cp/common/utils/ValidationUtils';
import { ReactElement, useEffect, useState } from 'react';

interface EditNameProps {
  open: boolean;
  name: string;
  onConfirm: (name: string) => Promise<void>;
  onCancel: () => void;
}

export const EditNameModal = ({
  open,
  name,
  onConfirm: onConfirmProp,
  onCancel
}: EditNameProps): ReactElement => {
  const [value, setValue] = useState(name);
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    if (open) {
      setValue(name);
      setNameError('');
    }
  }, [open, name]);

  const onValueChange = (value: string) => {
    if (value.length > MAX_INSTANCE_NAME_LENGTH) {
      setNameError('The name of the service is too long');
    } else {
      setNameError('');
    }
    setValue(value);
  };

  const onConfirm = async (): Promise<void> => {
    await onConfirmProp(value);
    onCancel();
  };

  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
        }
      }}
    >
      <Dialog.Content title="Service name" showClose onClose={onCancel}>
        <TextField
          label="Name"
          value={value}
          onChange={onValueChange}
          error={nameError}
        />
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Cancel" onClick={onCancel} />
          <Button
            onClick={onConfirm}
            disabled={value.length === 0 || nameError.length !== 0}
          >
            Update name
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
