import {
  Alert,
  Button,
  Container,
  Icon,
  Label,
  Spacer,
  useCUITheme
} from '@clickhouse/click-ui';
import { GetClientSecretResponse } from '@cp/common/protocol/Billing';
import { truthy } from '@cp/common/utils/Assert';
import { getServerErrorMessage } from '@cp/common/utils/MiscUtils';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElementStyle
} from '@stripe/stripe-js';
import { ReactElement, useReducer, useState } from 'react';
import { useConfirmUpdatedPaymentMethod } from 'src/billing/controllers/useConfirmUpdatedPaymentMethod';
import { BillingStageProps } from 'src/components/BillingCardModal/BillingInfoWrapper';
import config from 'src/lib/config';
import { routes } from 'src/lib/routes';

const GENERIC_ERROR = 'Error occurred when saving the payment information.';
type BillingFormProps = BillingStageProps & {
  clientSecret: GetClientSecretResponse;
  getClientSecret: () => Promise<GetClientSecretResponse>;
};

interface EventObject {
  isHovered: boolean;
  isFocused: boolean;
}

type FormFieldType = 'cardNumber' | 'expiryDate' | 'cvc';

const defaultEventObjectState = {
  isHovered: false,
  isFocused: false
};

interface AlertErrorProps {
  text: string;
  title?: string;
}

const AlertError = ({ text, title }: AlertErrorProps): ReactElement => {
  return (
    <Alert
      showIcon
      size="small"
      state="danger"
      text={text}
      title={title}
      data-testid="system-message-text"
      type="default"
    />
  );
};

export const BillingInfoForm = ({
  clientSecret,
  getClientSecret,
  organizationId,
  canUpdatePaymentMethod,
  billingAddress,
  onAdvance,
  onBack
}: BillingFormProps): ReactElement => {
  const cuiTheme = useCUITheme();
  const stripe = useStripe();
  const elements = useElements();
  const { confirmUpdatedPaymentMethod } = useConfirmUpdatedPaymentMethod();
  const [cardNumberState, setCardNumberState] = useReducer(
    (state: EventObject, updates: Partial<EventObject>) => ({
      ...state,
      ...updates
    }),
    defaultEventObjectState
  );
  const [expiryDateState, setExpiryDateState] = useReducer(
    (state: EventObject, updates: Partial<EventObject>) => ({
      ...state,
      ...updates
    }),
    defaultEventObjectState
  );
  const [cvcState, setCvcState] = useReducer(
    (state: EventObject, updates: Partial<EventObject>) => ({
      ...state,
      ...updates
    }),
    defaultEventObjectState
  );
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  // isStripeComplete is used to determine if all three element inputs are happy (valid data, etc.)
  const [isStripeCardNumberComplete, setIsStripeCardNumberComplete] =
    useState(false);
  const [stripeCardNumberErrorMessage, setStripeCardNumberErrorMessage] =
    useState<string | undefined>(undefined);
  const [isStripeCardExpiryComplete, setIsStripeCardExpiryComplete] =
    useState(false);
  const [stripeCardExpiryErrorMessage, setStripeCardExpiryErrorMessage] =
    useState<string | undefined>(undefined);
  const [isStripeCardCvcComplete, setIsStripeCardCvcComplete] = useState(false);
  const [stripeCardCvcErrorMessage, setStripeCardCvcErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const isStripeComplete =
    isStripeCardNumberComplete &&
    isStripeCardExpiryComplete &&
    isStripeCardCvcComplete &&
    !errorMessage;

  if (!stripe || !elements) {
    return (
      <Container
        fillWidth
        alignItems="center"
        justifyContent="center"
        orientation="vertical"
        padding="lg"
      >
        <Icon name="horizontal-loading" height="60px" width="60px" />
      </Container>
    );
  }
  const onCardNumberChange = (
    event: StripeCardNumberElementChangeEvent
  ): void => {
    // Set this complete flag and if other complete flags are on, switch on the big isStripeComplete flag
    setIsStripeCardNumberComplete(event.complete);
    setStripeCardNumberErrorMessage(event.error?.message);
    setErrorMessage(undefined);
  };

  const onCardExpiryChange = (
    event: StripeCardExpiryElementChangeEvent
  ): void => {
    // Set this complete flag and if other complete flags are on, switch on the big isStripeComplete flag
    setIsStripeCardExpiryComplete(event.complete);
    setStripeCardExpiryErrorMessage(event.error?.message);
    setErrorMessage(undefined);
  };

  const onCardCvcChange = (event: StripeCardCvcElementChangeEvent): void => {
    // Set this complete flag and if other complete flags are on, switch on the big isStripeComplete flag
    setIsStripeCardCvcComplete(event.complete);
    setStripeCardCvcErrorMessage(event.error?.message);
    setErrorMessage(undefined);
  };

  const onSubmit = async (): Promise<void> => {
    setButtonDisabled(true);
    try {
      if (!canUpdatePaymentMethod) {
        setErrorMessage(
          'Error occurred when saving the payment information. Please try again later.'
        );
        return;
      }

      // Reformat the object. Stripe will throw an error if we send 'postalCode' (they're really strict about inputs).
      const { postalCode, ...rest } = truthy(
        billingAddress,
        'Billing address is missing'
      );
      const address = { ...rest, postal_code: postalCode };

      // Send the credit card info to stripe directly.
      // It is crucial for PCI compliance that our API never get the card details, they're sent to stripe directly from the user's browser.
      const stripeResponse = await stripe.confirmCardSetup(
        truthy(clientSecret.clientSecret, 'Invalid client secret'),
        {
          payment_method: {
            card: truthy(
              elements.getElement('cardNumber'),
              'Invalid card number element'
            ),
            billing_details: {
              address
            },
            metadata: {
              setupIntentId: clientSecret.setupIntentId
            }
          },
          return_url: `${config.controlPlane.host}${routes.billingHome({
            orgId: organizationId
          })}`
        }
      );

      if (stripeResponse.error) {
        // Display the error and don't close the modal.
        setErrorMessage(
          stripeResponse.error?.type === 'card_error'
            ? stripeResponse.error.message
            : GENERIC_ERROR
        );
        return;
      }

      try {
        await confirmUpdatedPaymentMethod(
          organizationId,
          stripeResponse.setupIntent.id
        );
      } catch (e) {
        setErrorMessage(getServerErrorMessage(e) ?? GENERIC_ERROR);
        return;
      }
      await onAdvance();
    } catch (e) {
      //TODO(1786) Collect forensics log for billing
      console.error(e);
      setErrorMessage(GENERIC_ERROR);
    } finally {
      setButtonDisabled(false);
      if (errorMessage) {
        await getClientSecret();
      }
    }
  };
  const stripeElementWrapperStyle = (
    hovered: boolean,
    focused: boolean,
    type: FormFieldType
  ): React.CSSProperties => {
    let widthVal;
    switch (type) {
      case 'cardNumber':
        widthVal = '100%';
        break;
      case 'expiryDate':
        widthVal = '100px';
        break;
      case 'cvc':
        widthVal = '75px';
        break;
    }
    return {
      border: `1px solid ${
        focused
          ? cuiTheme.global.color.accent.default
          : hovered
          ? cuiTheme.global.color.stroke.default
          : cuiTheme.global.color.stroke.muted
      }`,
      background: `${
        focused || hovered
          ? cuiTheme.global.color.background.muted
          : cuiTheme.global.color.background.default
      }`,
      padding: '0.75rem 0.75rem',
      borderRadius: '0.25rem',
      width: widthVal
    };
  };

  const stripeElementStyle: StripeElementStyle = {
    base: {
      color: cuiTheme.global.color.text.default,
      fontSize: '16px',
      '::placeholder': {
        color: cuiTheme.global.color.text.muted
      }
    },
    invalid: {
      iconColor: cuiTheme.global.color.feedback.danger.foreground,
      color: cuiTheme.global.color.feedback.danger.foreground
    }
  };

  return (
    <Container orientation="vertical" gap="lg" alignItems="start">
      <Spacer size="md" />
      <Container
        orientation="horizontal"
        gap="lg"
        alignItems="start"
        data-testid="billing-card-modal-billing-info-form"
      >
        <Container orientation="vertical" gap="sm" alignItems="start" fillWidth>
          <Label>Credit or debit card</Label>
          <div
            style={stripeElementWrapperStyle(
              cardNumberState.isHovered,
              cardNumberState.isFocused,
              'cardNumber'
            )}
            onMouseEnter={() => {
              setCardNumberState({ isHovered: true });
            }}
            onMouseLeave={() => {
              setCardNumberState({ isHovered: false });
            }}
          >
            <CardNumberElement
              options={{ showIcon: true, style: stripeElementStyle }}
              onChange={onCardNumberChange}
              onFocus={() => {
                setCardNumberState({ isFocused: true });
              }}
              onBlur={() => {
                setCardNumberState({ isFocused: false });
              }}
            />
          </div>
        </Container>
        <Container
          orientation="horizontal"
          gap="lg"
          padding="none"
          alignItems="start"
          fillWidth={false}
        >
          <Container
            orientation="vertical"
            gap="sm"
            alignItems="start"
            minWidth="100px"
          >
            <Label>Expiration date</Label>
            <div
              style={stripeElementWrapperStyle(
                expiryDateState.isHovered,
                expiryDateState.isFocused,
                'expiryDate'
              )}
              onMouseEnter={() => {
                setExpiryDateState({ isHovered: true });
              }}
              onMouseLeave={() => {
                setExpiryDateState({ isHovered: false });
              }}
            >
              <CardExpiryElement
                onChange={onCardExpiryChange}
                options={{ style: stripeElementStyle }}
                onFocus={() => {
                  setExpiryDateState({ isFocused: true });
                }}
                onBlur={() => {
                  setExpiryDateState({ isFocused: false });
                }}
              />
            </div>
          </Container>

          <Container
            orientation="vertical"
            gap="sm"
            alignItems="start"
            minWidth="85px"
          >
            <Label>Security code</Label>
            <div
              style={stripeElementWrapperStyle(
                cvcState.isHovered,
                cvcState.isFocused,
                'cvc'
              )}
              onMouseEnter={() => {
                setCvcState({ isHovered: true });
              }}
              onMouseLeave={() => {
                setCvcState({ isHovered: false });
              }}
            >
              <CardCvcElement
                onChange={onCardCvcChange}
                options={{ style: stripeElementStyle }}
                onFocus={() => {
                  setCvcState({ isFocused: true });
                }}
                onBlur={() => {
                  setCvcState({ isFocused: false });
                }}
              />
            </div>
          </Container>
        </Container>
      </Container>
      <Container orientation="vertical" gap="md" fillWidth>
        {stripeCardNumberErrorMessage && (
          <AlertError text={stripeCardNumberErrorMessage} />
        )}
        {stripeCardExpiryErrorMessage && (
          <AlertError text={stripeCardExpiryErrorMessage} />
        )}
        {stripeCardCvcErrorMessage && (
          <AlertError text={stripeCardCvcErrorMessage} />
        )}
        {errorMessage && (
          <AlertError text={errorMessage} title="Could not save card details" />
        )}
      </Container>
      <Container justifyContent="end" gap="md" isResponsive={false}>
        <Button
          onClick={onBack}
          type="secondary"
          label="Back"
          data-testid="save-billing-back-button"
        />
        <Button
          align="center"
          loading={isButtonDisabled}
          disabled={!isStripeComplete || isButtonDisabled}
          iconRight="arrow-right"
          label="Save billing details"
          type="primary"
          data-testid="save-billing-button"
          onClick={() => {
            onSubmit().catch((error) =>
              console.error('Error submitting billing info', error)
            );
          }}
        />
      </Container>
    </Container>
  );
};
