import { Container, Spacer, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import CopyButton from 'src/components/CopyButton';
import {
  Section,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';

export const OrganizationIdSection = (props: {
  orgId: string;
}): ReactElement => {
  return (
    <Section padding="lg">
      <Subsection>
        <SubsectionDescription>
          <SubsectionTitle>Organization ID</SubsectionTitle>
          <Spacer />
        </SubsectionDescription>
      </Subsection>
      <Subsection>
        <SubsectionDescription>
          <Text color="muted">
            The unique identification number assigned to your organization
            during creation.
          </Text>
        </SubsectionDescription>
        <SubsectionControls>
          <Container padding="none" gap="sm" isResponsive={false}>
            <Text data-testid="org-id" weight="medium">
              {props.orgId}
            </Text>
            <CopyButton text={props.orgId} />
          </Container>
        </SubsectionControls>
      </Subsection>
    </Section>
  );
};
