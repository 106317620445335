import { sortBy } from 'lodash';
import { ReactNode } from 'react';
import { Integration } from 'shared';
import { findAll } from 'src/util/strapi';
import useSWR from 'swr';

interface BlogPost {
  id: number;
  category: string;
  title: string;
  shortDescription?: string;
  shortDescriptionElement: ReactNode;
  content: string;
  publishedAt: string;
  slug?: string;
  date?: string;
  keywords?: string;
  url?: never;
}

interface VideoRecording {
  title: string;
  shortDescription?: string;
  url: string;
  date?: string;
  duration?: string;
  keywords?: string;
}
interface UseIntegrationSummaryProps {
  integrationSlug: string;
}

export type IntegrationEventType = BlogPost | VideoRecording;

export interface IntegrationSummaryType extends Integration {
  list: Array<IntegrationTabsList>;
  events: Array<IntegrationEventType>;
}

interface UseIntegrationSummaryReturnProps {
  data?: IntegrationSummaryType;
  isLoading?: boolean;
  error?: Error;
}

interface IntegrationTabsList {
  label: string;
  value: string;
  content?: string;
  link?: string;
}

const integrationFields: Array<string> = [
  'shortDescription',
  'name',
  'category',
  'supportLevel',
  'docsLink',
  'slug',
  'aboutv2',
  'changelogv2',
  'version',
  'license',
  'readiness',
  'website',
  'summaryv2'
];

const fetchIntegration = (integrationSlug: string) => async (): Promise<IntegrationSummaryType> => {
  const { data }: { data: Array<Integration> } = await findAll('integrations', {
    populate: ['logo'],
    filters: { slug: { $eq: integrationSlug } },
    fields: integrationFields,
    sort: ['category:ASC'],
    pagination: { limit: 1 }
  });

  const { data: videoRecordings } = await findAll('video-recordings', {
    filters: {
      keywords: {
        $contains: integrationSlug
      }
    },
    pagination: { limit: 5 },
    fields: ['title', 'shortDescription', 'date', 'keywords', 'url', 'duration']
  });

  const { data: blogs }: { data: Array<BlogPost> } = await findAll('blog-posts', {
    filters: {
      keywords: {
        $contains: integrationSlug
      }
    },
    pagination: { limit: 5 },
    fields: ['title', 'shortDescription', 'date', 'keywords', 'slug']
  });

  const integration = data[0];
  if (integration) {
    const list: Array<IntegrationTabsList> = [];
    if (integration.summaryv2) {
      list.push({
        label: 'How To Connect',
        value: 'how-to-connect',
        content: integration.summaryv2
      });
    }

    if (integration.aboutv2) {
      list.push({
        label: 'About',
        value: 'about',
        content: integration.aboutv2
      });
    }
    if (integration.changelogv2) {
      list.push({
        label: 'Change log',
        value: 'changeLog',
        content: integration.changelogv2
      });
    }

    if (integration.docsLink) {
      list.push({
        label: 'Full documentation',
        value: 'docsLink',
        link: integration.docsLink
      });
    }

    const events = sortBy([...blogs, ...videoRecordings], 'date').slice(0, 5) as Array<IntegrationEventType>;

    return {
      ...integration,
      list,
      events
    };
  }
  throw new Error(`No Integration found for ${integrationSlug}`);
};

export const useIntegrationSummary = ({
  integrationSlug
}: UseIntegrationSummaryProps): UseIntegrationSummaryReturnProps => {
  const { data, isLoading, error } = useSWR(`fetch-${integrationSlug}`, fetchIntegration(integrationSlug));
  return {
    data,
    isLoading,
    error
  };
};
