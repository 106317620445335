import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  Container,
  Text,
  TextAreaField,
  TextField
} from '@clickhouse/click-ui';
import { POCRequest } from '@cp/common/protocol/Account';
import { ReactElement, useEffect, useState } from 'react';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';

interface PocDialogProps
  extends Omit<ConfirmationDialogProps, 'title' | 'showClose' | 'onConfirm'> {
  onConfirm: (
    props: Omit<POCRequest, 'recaptchaToken' | 'rpcAction'>
  ) => Promise<void>;
}

const PocDialog = ({
  open,
  disabled,
  onConfirm: onConfirmProp,
  loading,
  ...props
}: PocDialogProps): ReactElement => {
  const user = useCurrentUserOrThrow();
  const [name, setName] = useState('');
  const [nameVisited, setNameVisited] = useState(false);
  const [description, setDescription] = useState('');
  const [descVisited, setDescVisited] = useState(false);

  useEffect(() => {
    if (open) {
      setName('');
      setNameVisited(false);
      setDescription('');
      setDescVisited(false);
    }
  }, [open]);

  const onConfirm = (): void => {
    void onConfirmProp({ name, email: user.email, description });
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Proof of concept"
      showClose
      disabled={
        disabled ||
        !nameVisited ||
        !descVisited ||
        (nameVisited && name.length === 0) ||
        (descVisited && description.length === 0)
      }
      onConfirm={onConfirm}
      loading={loading}
      data-testid="poc-dialog"
      {...props}
    >
      <Container orientation="vertical" gap="md">
        <Text component="div" data-testid="poc-dialog-text">
          When starting a proof of concept, we recommend creating a{' '}
          <Text weight="bold" component="span">
            Production
          </Text>{' '}
          service. This will give you access to unlimited storage and full
          autoscaling controls.
          <br />
          Our sales team would love to hear from you and assist you with any
          questions that you may have while you evaluate our offering.
        </Text>
        <Container isResponsive={false} gap="md" alignItems="start">
          <TextField
            label="Name"
            value={name}
            onChange={setName}
            error={
              nameVisited && name.length === 0 ? 'Please enter name' : false
            }
            onBlur={() => setNameVisited(true)}
            data-testid="poc-name"
          />
          <TextField
            label="Email"
            value={user.email}
            onChange={() => {}}
            readOnly
            disabled
          />
        </Container>
        <TextAreaField
          label="Description"
          placeholder="Include any details that might help our team with recommendations"
          value={description}
          onChange={setDescription}
          error={
            descVisited && description.length === 0
              ? 'Please enter description'
              : false
          }
          onBlur={() => setDescVisited(true)}
          data-testid="poc-description"
        />
      </Container>
    </ConfirmationDialog>
  );
};

export default PocDialog;
