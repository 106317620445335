import { EventEmitter } from 'src/lib/EventEmitter';
import { LoadMetadataOptions } from 'src/metadata/types';

export type LoadMetadataEvent = Omit<LoadMetadataOptions, 'cached'> & {
  cached?: boolean;
};

export const metadataEventEmitter = new EventEmitter<LoadMetadataEvent>();

export const loadDatabaseMetadata = (options: LoadMetadataEvent): void => {
  metadataEventEmitter.emit(options);
};
