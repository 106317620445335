import { ReactElement, ReactNode } from 'react';
import { MetadataInitializer } from 'src/components/MetadataInitializer';
import { SocketIoProvider } from 'src/lib/socketio';
import { Outlet } from 'react-router-dom';
import { useIsUnifiedConsole } from 'src/lib/features';
import ServiceLayout from 'src/layout/ServiceLayout';
import CurrentConsoleLayout from 'src/layout/SqlConsoleLayout/CurrentConsoleLayout';
import SQLConsoleInitializerProvider from 'src/lib/SQLConsoleInitializerProvider';
import { useOrgIdFromServiceId } from 'src/state/service';
import { QueryStateProvider } from 'src/lib/query/QueryState';
import { useCurrentInstanceId } from 'src/instance/instanceController';

function DataSourcesLayoutWithMetadata({
  children
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <SQLConsoleInitializerProvider>
      <SocketIoProvider>
        <MetadataInitializer>{children}</MetadataInitializer>
      </SocketIoProvider>
    </SQLConsoleInitializerProvider>
  );
}

function DataSourcesLayoutWithoutMetadata({
  children
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <SQLConsoleInitializerProvider>
      <SocketIoProvider>
        <QueryStateProvider>{children}</QueryStateProvider>
      </SocketIoProvider>
    </SQLConsoleInitializerProvider>
  );
}

export default function DataSourcesLayout({
  requiresMetadata,
  children
}: {
  requiresMetadata: boolean;
  children?: ReactNode;
}): ReactElement | null {
  const isUnifiedConsole = useIsUnifiedConsole();
  const serviceId = useCurrentInstanceId();
  const orgId = useOrgIdFromServiceId(serviceId);

  const renderChildren = (): ReactElement | null => {
    if (!orgId) {
      return null;
    }

    if (children) {
      return <>{children}</>;
    } else {
      return <Outlet />;
    }
  };

  if (isUnifiedConsole && requiresMetadata) {
    return (
      <ServiceLayout>
        <DataSourcesLayoutWithMetadata>
          {renderChildren()}
        </DataSourcesLayoutWithMetadata>
      </ServiceLayout>
    );
  }

  if (isUnifiedConsole) {
    return (
      <ServiceLayout>
        <DataSourcesLayoutWithoutMetadata>
          {renderChildren()}
        </DataSourcesLayoutWithoutMetadata>
      </ServiceLayout>
    );
  }

  if (requiresMetadata) {
    return (
      <DataSourcesLayoutWithMetadata>
        <CurrentConsoleLayout>{renderChildren()}</CurrentConsoleLayout>
      </DataSourcesLayoutWithMetadata>
    );
  }

  return (
    <DataSourcesLayoutWithoutMetadata>
      <CurrentConsoleLayout>{renderChildren()}</CurrentConsoleLayout>
    </DataSourcesLayoutWithoutMetadata>
  );
}
