import {
  NOTIFICATION_INVENTORY,
  NotificationCategory,
  NotificationName,
  NotificationSeverity,
  NotificationType
} from '@cp/common/protocol/NotificationInventory';

export const getNotificationByCategory = (category: NotificationCategory): NotificationName[] => {
  return Object.keys(NOTIFICATION_INVENTORY).reduce((acc, name) => {
    const notification = NOTIFICATION_INVENTORY[name as NotificationName];
    if (notification.category === category) {
      acc.push(name as NotificationName);
    }
    return acc;
  }, [] as NotificationName[]);
};

export const getNotificationBySeverity = (severity: NotificationSeverity): NotificationName[] => {
  return Object.keys(NOTIFICATION_INVENTORY).reduce((acc, name) => {
    const notification = NOTIFICATION_INVENTORY[name as NotificationName];
    if (notification.severity === severity) {
      acc.push(name as NotificationName);
    }
    return acc;
  }, [] as NotificationName[]);
};

export const getNotificationType = (name: NotificationName): NotificationType | undefined => {
  return NOTIFICATION_INVENTORY[name];
};

/**
 * User subscribe for his notifications for the organization.
 * Every user can have different notification settings for different organizations.
 * That's why we need to have a unique objId for each user in the organization.
 * We create ojbId by concatenating organizationId and userId
 */
export const getObjIdForWebsocketUserNotificationSubscription = (userId: string, organizationId: string): string => {
  return `${organizationId}:${userId}`;
};

/**
 * Extract userId and organizationId from the objId
 * @see getObjIdForWebsocketUserNotificationSubscription
 */
export const getUserIdOrganizationIdFromObjId = (objId: string): { userId: string; organizationId: string } => {
  const [organizationId, userId] = objId.split(':');
  return { organizationId, userId };
};
