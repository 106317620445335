import { createContext, useContext } from 'react';

import {
  AssignSavedQueryInput,
  CreateSavedQueryInput,
  SavedQueryDTO,
  SavedQueryPermissionsLevel,
  UpdateSavedQueryInput
} from 'shared/src/types/savedQuery';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

export type SavedQueryResult = SavedQueryDTO;

export type LoadingState = 'initial' | 'loading' | 'done';
export type UseSavedQueriesResult = {
  assignQuery: (args: AssignSavedQueryInput) => Promise<SavedQueryDTO | null>;
  savedQueries: SavedQueryResult[];
  loadingState: LoadingState;
  loadError: string;
  refetch: () => Promise<void>;
  getQueryById: (id: string) => SavedQueryResult | undefined;
  updateQuery: (
    query: UpdateSavedQueryInput
  ) => Promise<SavedQueryResult | null>;
  createQuery: (
    query: CreateSavedQueryInput
  ) => Promise<SavedQueryResult | null>;
  deleteQuery: (id: string) => Promise<void>;
};

export const SavedQueriesContext = createContext<
  UseSavedQueriesResult | undefined
>(undefined);

/**
 * A custom hook that provides access to the saved queries list and functions.
 * @returns An object with the saved queries state and functions.
 */
export const useSavedQueries = (): UseSavedQueriesResult => {
  const context = useContext(SavedQueriesContext);
  if (context === undefined) {
    throw new Error(
      'useSavedQueries must be used within a SavedQueriesProvider'
    );
  }
  return context;
};

interface SavedQueryWithUserPermissions extends SavedQueryDTO {
  queryUnowned: boolean;
  userPermissions: SavedQueryPermissionsLevel | 'owner';
}

const permissionsOrder = ['none', 'read', 'write', 'owner'];
/**
 * A custom hook that takes the saved query from the list and attaches user permissions.
 * @returns An object with the saved queries state and functions.
 */
export const useSavedQuery = (
  queryId: string
): SavedQueryWithUserPermissions | null => {
  const currentOrg = useCurrentOrganizationOrThrow();
  const currentUser = useCurrentUserOrThrow();
  const savedQueries = useSavedQueries();

  const savedQuery = savedQueries.getQueryById(queryId);

  if (!savedQuery) {
    return null;
  }

  let permissions: SavedQueryPermissionsLevel | 'owner';
  let queryUnowned = false;

  if (savedQuery.authorId === currentUser.id) {
    permissions = 'owner';
  } else {
    const globalPermissions =
      savedQuery.queryPermissions.find((p) => p.type === 'global')
        ?.permissions || 'none';
    const userPermissions =
      savedQuery.queryPermissions.find(
        (p) => p.type === 'user' && p.userId === currentUser.id
      )?.permissions || 'none';

    permissions =
      permissionsOrder.indexOf(globalPermissions) >
      permissionsOrder.indexOf(userPermissions)
        ? globalPermissions
        : userPermissions;

    if (!currentOrg.users[savedQuery.authorId]) {
      queryUnowned = true;
    }
  }

  return {
    ...savedQuery,
    queryUnowned,
    userPermissions: permissions
  };
};
