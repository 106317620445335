import {
  Alert,
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  Link,
  Text
} from '@clickhouse/click-ui';
import { ReactElement, memo, useCallback, useEffect, useState } from 'react';

import {
  SectionTitle,
  Section,
  Subsection,
  SubsectionDescription,
  SubsectionTitle,
  SubsectionControls
} from 'src/lib/pageElements';

type ModalDialogProps = {
  isSaving: boolean;
  isDialogOpen: boolean;
  onClose: () => void;
  onActivateClick: () => Promise<void>;
};

const ModalDialog = memo(
  ({ isSaving, isDialogOpen, onClose, onActivateClick }: ModalDialogProps) => {
    return (
      <Dialog open={isDialogOpen} modal>
        <Dialog.Content
          title="Enroll in fast releases"
          onClose={onClose}
          showClose={true}
        >
          <Text color="muted">
            While we conduct extensive testing on all of our releases, we don’t
            recommend enrolling in the fast release channel when working with
            production environments.{' '}
            <Link
              href="https://clickhouse.com/docs/en/manage/updates#release-channels-upgrade-schedule"
              target="_blank"
            >
              Learn more
            </Link>
          </Text>
          <Spacer />
          <Container orientation="vertical" gap="xs">
            <Alert
              state="info"
              size="medium"
              text={
                <>
                  Once subscribed to the fast release you cannot change to the
                  regular release schedule.
                </>
              }
            />
            <Alert
              state="info"
              size="medium"
              text={<>This change might trigger a service restart.</>}
            />
          </Container>
          <Separator size="lg" />
          <Container justifyContent="end" gap="sm">
            <Dialog.Close label="Cancel" onClick={onClose} />
            <Button
              loading={isSaving}
              disabled={isSaving}
              onClick={(): void => {
                onActivateClick().catch(console.error);
              }}
              data-testid="activate-fast-channel-button"
            >
              Activate fast release channel
            </Button>
          </Container>
        </Dialog.Content>
      </Dialog>
    );
  }
);

export type ReleasesSectionViewProps = {
  showEnrollButton: boolean;
  onActivateClick: () => Promise<void>;
  isSaving: boolean;
};
export const ReleasesSectionView = memo(
  ({
    showEnrollButton,
    onActivateClick,
    isSaving
  }: ReleasesSectionViewProps): ReactElement | null => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const onClick = useCallback(() => {
      setIsDialogOpen(true);
    }, []);

    const onModalDialogClose = useCallback(() => {
      setIsDialogOpen(false);
    }, []);

    useEffect(() => {
      if (!showEnrollButton) {
        setIsDialogOpen(false);
      }
    }, [showEnrollButton]);

    return (
      <>
        <ModalDialog
          onActivateClick={onActivateClick}
          isSaving={isSaving}
          isDialogOpen={isDialogOpen}
          onClose={onModalDialogClose}
        />
        <Section data-testid="release-settings-section">
          <Spacer />
          <SectionTitle>Releases</SectionTitle>
          <Spacer />
          <Subsection justifyContent="space-between">
            <SubsectionDescription>
              <SubsectionTitle>Fast channel</SubsectionTitle>
              <Spacer />

              <Text color="muted">
                Get early access to the latest features, improvements, and bug
                fixes.
                <Spacer />
                While we conduct extensive testing on all of our releases, we
                don’t recommend enrolling in the fast release channel when
                working with production environments.{' '}
                <Link
                  href="https://clickhouse.com/docs/en/manage/updates#release-channels-upgrade-schedule"
                  target="_blank"
                >
                  Learn more
                </Link>
              </Text>
            </SubsectionDescription>
            <SubsectionControls>
              {showEnrollButton ? (
                <Button
                  data-testid="enroll-fast-channel-button"
                  type="secondary"
                  onClick={onClick}
                  fillWidth={false}
                >
                  Enroll in fast releases
                </Button>
              ) : (
                <Text>Your service is part of the fast channel releases.</Text>
              )}
            </SubsectionControls>
          </Subsection>
        </Section>
        <Spacer size="lg" />
      </>
    );
  }
);
