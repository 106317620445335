import { Alert } from '@clickhouse/click-ui';
import { assertNever } from '@cp/common/utils/Assert';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useByocOnboardingStatus } from 'src/lib/byoc/byocOnboardingStatus';

type OrganizationMessageIcon = 'check' | 'dots-horizontal';
interface OrganizationMessage {
  text: string;
  icon: OrganizationMessageIcon;
  dismissable: boolean;
}

const PENDING_MESSAGE: OrganizationMessage = {
  text: 'Finishing your AWS ClickHouse infrastructure set up. This process might take up to 40 minutes.',
  icon: 'dots-horizontal',
  dismissable: false
};

const FINISHED_MESSAGE: OrganizationMessage = {
  text: 'Our system has finished the set up between ClickHouse and AWS. You can start creating services now.',
  icon: 'check',
  dismissable: true
};

const OrganizationAlertBanner = (): ReactElement | null => {
  const byocOnboardingStatus = useByocOnboardingStatus();
  const [message, setMessage] = useState<OrganizationMessage>();
  const previousByocOnboardingStatusRef = useRef(byocOnboardingStatus);

  useEffect(() => {
    if (byocOnboardingStatus === 'no_byoc') {
      setMessage(undefined);
    } else if (byocOnboardingStatus == 'pending') {
      setMessage(PENDING_MESSAGE);
    } else if (byocOnboardingStatus === 'completed') {
      // Only show completed message if we were previously pending
      setMessage(
        previousByocOnboardingStatusRef.current === 'pending'
          ? FINISHED_MESSAGE
          : undefined
      );
    } else {
      assertNever(
        byocOnboardingStatus,
        `Invalid byocOnboardingStatus ${byocOnboardingStatus}`
      );
    }
    previousByocOnboardingStatusRef.current = byocOnboardingStatus;
  }, [byocOnboardingStatus]);

  if (!message) {
    return null;
  }

  return (
    <Alert
      text={message.text}
      showIcon
      state="info"
      type="banner"
      size="small"
      customIcon={message.icon}
      dismissible={message.dismissable}
      data-testid="organization-alert-banner"
    ></Alert>
  );
};

export default OrganizationAlertBanner;
