import { createToast } from '@clickhouse/click-ui';
import { useApiClient } from 'src/lib/controlPlane/client';

interface ReturnProps {
  createDefaultOrgForUser: () => Promise<void>;
}
const useCreateDefaultOrganization = (): ReturnProps => {
  const apiClient = useApiClient();
  const createDefaultOrgForUser = async (): Promise<void> => {
    try {
      console.debug('User is creating default organization');

      await apiClient.organization.createDefaultOrgForUser();
      console.debug('User successfully created default organization, moving to onboard');
    } catch (e) {
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'Unable to create default organization, please refresh page and try again.'
      });
    }
  };
  return { createDefaultOrgForUser };
};

export default useCreateDefaultOrganization;
