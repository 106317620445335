// See https://stripe.com/docs/api/events/types for full list of stripe event types.

import { PartialBy } from '@cp/common/protocol/Common';
import type { InstanceCloudProvider, InstanceTier } from '@cp/common/protocol/Instance';
import type { ChartDataSeries } from '@cp/common/protocol/Metrics';
import type { OrganizationFirmographics } from '@cp/common/protocol/Organization';
import { RegionId } from '@cp/common/protocol/Region';
import type { TaxExemptType, TaxIdDatumType, TaxStatusType } from '@cp/common/protocol/Stripe';
import type { RpcRequest, WithOrganizationId } from '@cp/common/utils/ProtocolUtils';

/** URL path for billing handler: /api/billing. */
export const BILLING_API_PATH = 'billing';

/** Set of all RPC actions for 'billing' handler. */
export const BILLING_RPC_ACTIONS = [
  'getClientSecret',
  'getUsageReport',
  'getUsageStatement',
  'updateOrganizationBillingDetails',
  'getOrganizationBillingDetails',
  'updateOrganizationBillingContact',
  'handleTackleSubscription',
  'confirmUpdatedPaymentMethod',
  'requestCredits',
  'getPricingForAllRegions'
] as const;

export type BillingRpcAction = (typeof BILLING_RPC_ACTIONS)[number];
export type BillingRpcRequest<T extends BillingRpcAction> = RpcRequest<T>;

export type StripeCustomerEventType =
  /**
   * data.object is a customer
   * Occurs whenever a new customer is created.
   */
  | 'customer.created'

  /**
   * data.object is a customer
   * Occurs whenever a customer is deleted.
   */
  | 'customer.deleted'

  /**
   * data.object is a customer
   * Occurs whenever any property of a customer changes.
   */
  | 'customer.updated';

export type StripeDisputeEventType =
  /**
   * data.object is a dispute
   * Occurs when a dispute is closed and the dispute status changes to lost, warning_closed, or won.
   */
  | 'charge.dispute.closed'

  /**
   * data.object is a dispute
   * Occurs whenever a customer disputes a charge with their bank.
   */
  | 'charge.dispute.created'

  /**
   * data.object is a dispute
   * Occurs when funds are reinstated to your account after a dispute is closed. This includes partially refunded payments.
   */
  | 'charge.dispute.funds_reinstated'

  /**
   * data.object is a dispute
   * Occurs when funds are removed from your account due to a dispute.
   */
  | 'charge.dispute.funds_withdrawn'

  /**
   * data.object is a dispute
   * Occurs when the dispute is updated (usually with evidence).
   */
  | 'charge.dispute.updated';

export type StripePaymentMethodEventType =
  /**
   * data.object is a payment method
   * Occurs whenever a new payment method is attached to a customer.
   */
  | 'payment_method.attached'

  /**
   * data.object is a payment method
   * Occurs whenever a payment method’s details are automatically updated by the network.
   */
  | 'payment_method.automatically_updated'

  /**
   * data.object is a payment method
   * Occurs whenever a payment method is detached from a customer.
   */
  | 'payment_method.detached'

  /**
   * data.object is a payment method
   * Occurs whenever a payment method is updated via the PaymentMethod update API.
   */
  | 'payment_method.updated';

export type StripeInvoicePaymentEventType =
  /**
   * data.object is an invoice
   * Occurs whenever an invoice payment attempt fails, due either to a declined payment or to the lack of a stored payment method.
   */
  | 'invoice.payment_failed'

  /**
   * data.object is an invoice
   * Occurs whenever an invoice payment attempt succeeds.
   */
  | 'invoice.payment_succeeded';

export type StripeMandateEventType =
  /**
   * data.object is an mandate
   * A Mandate is a record of the permission a customer has given you to debit their payment method.
   * For more information, see https://stripe.com/docs/invoicing/india-emandate-guide
   * Occurs whenever a Mandate is updated.
   */
  'mandate.updated';

export type StripeSetupIntentEventType =
  /**
   * data.object is an setup intent
   * Occurs when setup intent fails for a variety of possible reasons.
   */
  'setup_intent.setup_failed';

/**
 * Currencies supported by CP.
 * In the future we'll expand support for additional currencies.
 * Note: Must be compatible with 'currencyCode' in https://angular.io/api/common/CurrencyPipe.
 */
export type Currency = 'USD';
export const DEFAULT_CURRENCY: Currency = 'USD';

export interface GetClientSecretRequest extends BillingRpcRequest<'getClientSecret'> {
  organizationId: string;
}

export interface GetClientSecretResponse {
  /** Stripe Elements uses this to identify the Stripe customer when attaching a payment method. */
  clientSecret: string;
  /** The id of the setupIntent used to later create the payment method. */
  setupIntentId: string;
}

/**
 * The values used by Stripe to distinguish credit card brands.
 * These can be used to pick the correct brand icon when displaying saved credit cards.
 * Original list available: https://stripe.com/docs/api/payment_methods/object#payment_method_object-card-brand
 */
export type CreditCardBrand = 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown';

/**
 * The values relevant to us from Stripe.PaymentMethod.type.
 * If we support other methods of payment, we'll have to include them here to serve them back to the client.
 * See here: https://stripe.com/docs/api/payment_methods/object (look for the type attribute)
 */
export const PAYMENT_METHOD_TYPE_ARRAY = [
  /** Credit card. */
  'card',
  /** ACH Direct Debit is used to debit US bank accounts through the Automated Clearing House (ACH) payments system.  */
  'us_bank_account'
] as const;

export type PaymentMethodType = (typeof PAYMENT_METHOD_TYPE_ARRAY)[number];

export interface PaymentMethod {
  id: string;
  type: PaymentMethodType;
  currency: Currency;
  /** Last 4 digits bank account or credit card number. Some bank accounts might not list last 4 digits. */
  last4: string | null;
}

/** Representing relevant parts of Stripe's Card object: https://stripe.com/docs/api/payment_methods/object#payment_method_object-card */
export interface CardPaymentMethod extends PaymentMethod {
  type: 'card';
  brand: CreditCardBrand;
  country: string | null;
  expMonth: number;
  expYear: number;
  funding: string;
}

/** Representing relevant parts of Stripe's US Bank account object: https://stripe.com/docs/api/payment_methods/object#payment_method_object-us_bank_account */
export interface UsAchPaymentMethod extends PaymentMethod {
  type: 'us_bank_account';
  accountHolderType: 'individual' | 'company' | null;
  accountType: 'checking' | 'savings' | null;
  bankName: string | null;
  /** Routing number of the bank account. */
  routingNumber: string | null;
}

export interface GetPaymentMethodsRequest {
  organizationId: string;
}

export interface GetPaymentMethodsResponse {
  paymentMethods: Array<PaymentMethod>;
}

/**
 * Type of the usage metric shown to user. Included into the usage report, CSV usage statement, etc..
 * Used instead of M3terMetric in client code.
 */
export const USAGE_METRIC_ARRAY = [
  /**
   * How much EC2 resources CH server instance used during the reported period. Source: 'computeUnitMinutes' m3ter measure.
   * Includes scaling of the instance, so even for a 1-minute period the usage may be greater than 1.
   */
  'COMPUTE_UNIT_MINUTES',

  /**
   * Storage usage by the CH server. Source 'storageGBMinutesTables' m3ter measure.
   * Is equal to sum(gigabytes used by a server during a minute).
   * Example:
   * 15:00 -> 5Gb
   * 15:01 -> 7Gb
   * 15:02 -> 0Gb
   * ===> 12Gb in the period since 15:00 to 15:02.
   */
  'STORAGE_GB_MINUTES_TABLES',

  /**
   * Storage usage by the CH service's backups. Source 'storageGBMinutesPaidBackups' m3ter measure.
   * Is equal to sum(gigabytes of visible backups of service).
   */
  'STORAGE_GB_MINUTES_PAID_BACKUPS'
] as const;

export type UsageMetric = (typeof USAGE_METRIC_ARRAY)[number];

export interface UsageReportRequest extends WithOrganizationId, BillingRpcRequest<'getUsageReport'> {
  /** Bill date: YYYY-MM-DD. If not provided, the current (unfinished) billing period is used. */
  billDate?: string;

  /**
   * Indicates what chart data should be included into the response.
   * The chart will include data for the requested period (see billDate).
   */
  chartMetric: UsageMetric;
}

export interface UsageReportChartRequest extends WithOrganizationId {
  /** Bill date: YYYY-MM-DD. */
  billDate: string;

  /**
   * Indicates what chart data should be included into the response.
   * The chart will include data for the requested period (see billDate).
   */
  chartMetric: UsageMetric;
}

export interface UsageListRecord {
  /* YYYY-MM-DD. */
  date: string;
  instanceId: string;
  instanceName: string;
  cloudProvider?: InstanceCloudProvider;
  /* RegionId without the cloud provider prefix */
  region?: string;
  instanceTier?: InstanceTier;
  /**
   *  Metric (computeUnitMinutes, storageGBMinutesTables, storageGBMinutesPaidBackups)
   *  and value for this instanceId on this date.
   */
  metricValues: Partial<Record<UsageMetric, number>>;
}

/**
 * See M3terBill but note that the endDate behaves differently here.
 * Used for display purposes.
 * (start|end|bill)Date. Format: YYYY-MM-DD.
 */
export interface BillPeriodDates {
  /** startDate is inclusive. */
  startDate: string;
  //TODO: Refactor usages of M3terBill end and start dates #3784
  // Remove this in the next step of this task as it should no longer be used anywhere
  /** endDate is inconsistent. It's mostly used as exclusive, except for a few cases where it's inclusive. Use the new explicit fields instead. */
  endDate: string;
  /** Inclusive endDate. If the billDate is 2024-03-15 (billDate is exclusive), then this would be 2024-03-14. */
  endDateInclusive: string;
  /** endDate is exclusive. It's the same as the billDate. */
  endDateExclusive: string;
  /** This is the day after the endDate. Equal to m3ter's original billDate/endDate. */
  billDate: string;
}

export interface UsageReportResponse {
  /** Detailed usage report. */
  report: OrganizationUsageReport;

  /** Chart data requested via 'chartType' in the request per instance id. */
  chart: OrganizationUsagePeriodChart | null;

  /** Sorted (the latest is first) available bill dates for the last 12 periods (including current non finished period). */
  billDates: Array<BillPeriodDates>;

  /**
   * If true, the server is currently generating a detailed usage report (including cost) so the web client should
   * try this endpoint again in 10 seconds to hopefully get that added detail.
   */
  callAgainSoon: boolean;
}

export interface UsageReportChartResponse {
  chart: OrganizationUsagePeriodChart | null;
}

export interface OrganizationUsageReport extends WithOrganizationId, BillPeriodDates {
  /** Total amount based on usage for the reported period. */
  grossBillableAmount: number;

  /** Billed amount for the reported period after deducting any applicable credits. */
  netBillableAmount: number;

  /** Currency used in the report. */
  currency: Currency;

  /** Per instance reports. */
  instanceReports: Array<InstanceUsageReport>;

  /** Totals for the whole organization: sum of instances. */
  totalUsageReport: AdminTotalUsageReport;
}

/**
 * Chart data for admin usage report per organization.
 * Keeps per instance chart data + total data.
 */
export interface OrganizationUsagePeriodChart extends WithOrganizationId, BillPeriodDates {
  type: UsageMetric;
  instanceNameById: Record<string, string>;
  perInstanceData: Record<string, ChartDataSeries>;
  /** values converted into units that conform to the website's pricing page. */
  websitePerInstanceData: Record<string, ChartDataSeries>;
}

export interface UsageMetricValueBreakdown {
  metricValue: number;
  pricePerUnit?: number;
  cost?: number;
  /** values converted into units that conform to the website's pricing page. */
  websiteUnitMetricValue: number;
  /** prices converted into so they reflect the website's pricing page. */
  websiteUnitPricePerUnit?: number;
}

export type UsageMetricValues = Record<UsageMetric, UsageMetricValueBreakdown>;

/** Per instance usage report provided by M3ter. */
export interface InstanceUsageReport extends UsageMetricValues {
  instanceId: string;
  instanceName: string;
  instanceTier: InstanceTier;
  instanceCloudProvider: InstanceCloudProvider;
  instanceRegion: RegionId;
  customPricing?: string;
  deleted: boolean;
}

/** Sum of all instance reports. */
export type AdminTotalUsageReport = UsageMetricValues;

export interface OrganizationDetails extends Partial<OrganizationFirmographics> {
  bothAddressesSame?: boolean;
  billingAddress: Address;
  organizationIsABusiness?: boolean;
  shippingAddress?: Address;
  companyName?: string;
  taxId?: string;
  taxIdType?: TaxIdDatumType;
  taxStatus?: TaxStatusType;
  taxExempt?: TaxExemptType;
  businessType?: BusinessType;
}

export type OrganizationDetailsToUpdate = PartialBy<OrganizationDetails, 'billingAddress'>;

export interface UpdateOrganizationBillingDetailsRequest
  extends OrganizationDetailsToUpdate,
    WithOrganizationId,
    BillingRpcRequest<'updateOrganizationBillingDetails'> {}

export interface UpdateOrganizationBillingContact
  extends WithOrganizationId,
    BillingRpcRequest<'updateOrganizationBillingContact'> {
  billingContact: string;
}

export interface GetOrganizationBillingDetailsRequest extends BillingRpcRequest<'getOrganizationBillingDetails'> {
  organizationId: string;
}

export interface GetOrganizationBillingDetailsResponse {
  shippingAddress?: Partial<Address>;
  bothAddressesSame?: boolean;
  organizationIsABusiness?: boolean;
  billingAddress?: Partial<Address>;
  companyName?: string;
  taxId?: string;
  taxIdType?: TaxIdDatumType;
  taxStatus?: TaxStatusType;
  taxExempt?: TaxExemptType;
  paymentMethod?: PaymentMethod;
  invoices: Array<InvoiceSummary>;
  billUsageStatements: Array<BillUsageSummary>;
  creditBalances: Array<CreditBalanceSummary>;
  /** Unix timestamp in milliseconds. */
  nextInvoiceDate?: number;
  billingContact: string;
  canUpdatePaymentMethod: boolean;
  businessType?: BusinessType;
}

/**
 * See https://stripe.com/docs/api/invoices/object#invoice_object-status
 */
export const INVOICE_STATUS_RAW = {
  draft: 'Draft',
  open: 'Unpaid',
  void: 'Void',
  paid: 'Paid',
  uncollectible: 'Unpaid' // uncollectible is an internal stripe designation that impacts how we record the liability. The customer can (and should!) still pay it.
} as const;
export type InvoiceStatus = keyof typeof INVOICE_STATUS_RAW;
export const INVALID_INVOICE_STATUS = ['draft'] as const;

export interface InvoiceSummary extends Partial<HasPeriodDates> {
  /** Stripe Invoice ID. */
  invoiceId: string;
  /** Amount due on the invoice, this could have already been paid, partially paid or still outstanding. */
  amount: number;
  currency: Currency;
  /** Direct download link for the invoice PDF. */
  invoicePdfDownloadLink: string;
  /** M3ter billId used to link invoices with bill periods and allow downloading CSV statement. */
  billId?: string;
  /** Direct link to the hosted invoice page (https://stripe.com/docs/invoicing/hosted-invoice-page). */
  invoicePaymentLink?: string;
  /** Status of the invoice */
  status: InvoiceStatus;
}

export interface BillUsageSummary extends HasPeriodDates {
  /** M3ter bill id, used to link invoices with bill periods and allow downloading CSV statement. */
  billId: string;
  /** Amount of CHC spent in this bill (before credits applied). */
  billGrossTotal: number;
  /** Amount of CHC spent in this bill (after credits applied). */
  billNetTotal: number;
  /** Amount of CHC credit applied to this bill. */
  creditApplied: number;
  /** This bill is locked and can no longer change. */
  locked: boolean;
}

export interface CreditBalanceSummary extends HasPeriodDates {
  /** CHC amount allocated in this credit balance. */
  amountTotal: number;
  /** CHC amount still remaining right now, this is not an historic value. */
  amountRemaining: number;
  /** CHC amount spent until right now, this is not an historic value. */
  amountSpent: number;
}

export interface HasPeriodDates {
  /** Unix timestamp in milliseconds. */
  periodStartDate: number;
  /** Unix timestamp in milliseconds. */
  periodEndDate: number;
}

/** Generic address object used to update organization address. */
export interface Address {
  line1: string;
  line2?: string;
  city: string;
  /** State, province, oblast, canton, etc. */
  state?: string;
  /** 2 letter country code, upper case (see: ISO 3166-1 alpha-2) */
  country: string;
  /** ZIP or postal code */
  postalCode: string;
}

export const BUSINESS_TYPE_ARRAY = [
  'Sole Proprietorship',
  'Partnerships',
  'Limited liability company (LLC)',
  'Corporation - C corp',
  'Corporation - S corp',
  'Corporation - Non profit',
  'Other'
] as const;

export type BusinessType = (typeof BUSINESS_TYPE_ARRAY)[number];

// TODO: Cleanup commitment-named fields that were left for backwards compatibility #3845
export const GRACE_PERIOD_COMMITMENT_TYPE_ARRAY = [
  /** This commitment represents an org's trial grace period (no credits). */
  'TRIAL_GRACE_PERIOD',
  /** This commitment represents an org's marketplace grace period (no credits). */
  'MP_GRACE_PERIOD'
] as const;

// TODO: Cleanup commitment-named fields that were left for backwards compatibility #3845
export type GracePeriodCommitmentType = (typeof GRACE_PERIOD_COMMITMENT_TYPE_ARRAY)[number];

// TODO: Cleanup commitment-named fields that were left for backwards compatibility #3845
export const REGULAR_COMMITMENT_TYPE_ARRAY = [
  /** This commitment represents an org's trial credits and period. */
  'TRIAL',
  /** This commitment represents an org's prepaid credits and their time period. */
  'PREPAID',
  /** This commitment represents an org's promotional credit granted during the 2022 Beta. */
  'BETAPROMO',
  /** This commitment represents credits that were refunded to the org. */
  'REFUND',
  /** These are credits that were refunded to the org for an overcharge. They are ignored by DWH for MRR purposes. */
  'OVERCHARGE_REFUND'
] as const;

export type CommitmentType = GracePeriodCommitmentType | (typeof REGULAR_COMMITMENT_TYPE_ARRAY)[number];

export const ALL_M3TER_COMMITMENT_TYPE_SET: ReadonlySet<CommitmentType> = new Set<CommitmentType>([
  ...GRACE_PERIOD_COMMITMENT_TYPE_ARRAY,
  ...REGULAR_COMMITMENT_TYPE_ARRAY
]);

/** Requests for the per-org, per-bill CSV statement report. */
export interface GetUsageStatementRequest extends BillingRpcRequest<'getUsageStatement'> {
  organizationId: string;
  /** M3ter bill id. */
  billId: string;
}

export interface GetUsageStatementResponse {
  /** CSV report. */
  report: string;
}

export interface ConfirmUpdatedPaymentMethodRequest
  extends BillingRpcRequest<'confirmUpdatedPaymentMethod'>,
    WithOrganizationId {
  paymentMethodUpdateId: string;
}

export interface ConfirmUpdatedPaymentMethodResponse {
  paymentMethod?: PaymentMethod;
  canUpdatePaymentMethod: boolean;
}

export interface GetPricingForAllRegionsRequest
  extends BillingRpcRequest<'getPricingForAllRegions'>,
    WithOrganizationId {}

export interface RegionPricingDetails {
  computeProduction: string;
  storageProduction: string;
  computeDevelopment: string;
  storageDevelopment: string;
}
export interface GetPricingForAllRegionsResponse {
  prices: Record<RegionId, RegionPricingDetails>;
}
/**
 * Usage statement report for the organization.
 * Used internally in CP API and is converted to different formats (like CSV) when needed.
 */
export interface OrganizationUsageStatementReport extends BillPeriodDates {
  organizationId: string;
  instanceReports: Record<string, InstanceUsageStatementReport>;
}

/** Usage statement report per instance. */
export interface InstanceUsageStatementReport extends BillPeriodDates {
  instanceId: string;
  instanceName: string;
  cloudProvider: InstanceCloudProvider;
  region: RegionId;
  instanceTier: InstanceTier;
  customPricing?: string;
  usage: Record<UsageMetric, Array<DateAndMetric>>;
}

/** Date (YYYY-MM-DD) and metric value pair. */
export type DateAndMetric = [string, number];

/** Type of the usage metric view shown to user. */
export const USAGE_METRIC_VIEW_TYPE_ARRAY = [
  /** Show the metric in the context of how it's priced, so it's easy to multiply this amount by the published price per unit. */
  'WEBSITE',
  /** Show the metric in the context of how it's metered, in a human-readable way. */
  'BILLING',
  /** Show the metric in the same context as WEBSITE, but with some copy differences. Used with COST. */
  'UNITS',
  /** Show the metric's cost. */
  'COST',
  /* Show the metric in terms of CHCs */
  'CHC'
] as const;

export type UsageMetricViewType = (typeof USAGE_METRIC_VIEW_TYPE_ARRAY)[number];

export interface AdditionalCreditsRequest extends BillingRpcRequest<'requestCredits'> {
  creditsText: string;
}

export const USAGE_REPORT_STATE_ARRAY = [
  /** The report needs to be processed -- make the request to metrics cluster next. */
  'PENDING',
  /** Metrics have been collected from metrics cluster, needs to be pushed to m3ter. */
  'COLLECTED',
  /** Successfully reported the metrics for this record to m3ter. */
  'COMPLETED',
  /** Something went wrong when collecting metrics from metrics cluster, try again. */
  'FAILED_COLLECTING',
  /** Metrics have been collected but failed to report to m3ter, try again. */
  'FAILED_REPORTING',
  /** Fundamental failure occurred which can't be fixed by retrying. */
  'UNRECOVERABLE_FAIL'
] as const;

export type UsageMetricsReportStateType = (typeof USAGE_REPORT_STATE_ARRAY)[number];

export interface TackleSubscriptionTransferInputs {
  // organization we take the MP subscription from
  sourceOrganizationId: string;
  // organization we transfer the MP subscription to
  targetOrganizationId: string;
  // an admin on the target organization whose email and name we'll use to fill in the Tackle profile
  userId: string;
}

export interface TackleSubscriptionTransferValidationMessages {
  /**  Indicates problems that MUST be addressed before the subscription transfer can proceed */
  errorMessages: string[];
  /** Indicates non-blocking issues related to the subscription transfer to be aware of */
  warnMessages: string[];
}

export type TackleSubscriptionTransferStatus =
  /** Validation checks failed -- transfer was not initiated */
  | 'NOT_INITIATED'
  /** Transfer succeeded */
  | 'SUCCESS'
  /** Error while updating target org in DB or on M3ter */
  | 'FAILED_IN_TARGET_ORG'
  /** Error while updating source org in DB or on M3ter */
  | 'FAILED_IN_SOURCE_ORG'
  /** Error while trying to register order on Tackle */
  | 'FAILED_IN_TACKLE_API';

export const USAGE_INTERVAl_STATUS_ARRAY = [
  /** The interval needs to be processed */
  'PENDING',
  /** Successfully created the Usage metrics reports (in PENDING status) docs for this interval. */
  'REPORTS_CREATED',
  /** Successfully collected metrics for the usage metrics reports docs of this interval. */
  'COLLECTED',
  /** Something went wrong when collecting clickpipes details from metrics cluster, try again. */
  'FAILED_COLLECTING',
  /** Something went wrong when creating usage_metrics_reports, try again. */
  'FAILED_CREATING'
] as const;

export type UsageIntervalStatusType = (typeof USAGE_INTERVAl_STATUS_ARRAY)[number];

/** Default number of places after the decimal to display for values associated with usage cost */
export const DEFAULT_COST_DISPLAY_PRECISION = 3;
