import { Dashboard } from 'shared/src/types/dashboard';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';
import {
  CreateDashboardResponse,
  GetDashboardResponse,
  ListDashboardsResponse,
  UpdateDashboardResponse
} from 'types/protocol/dashboards';

type CreateDashboardArgs = {
  name: string;
  serviceId: string;
};

type ListDashboardsArgs = {
  serviceId: string;
};

type GetDashboardArgs = {
  dashboardId: string;
  serviceId: string;
};

type UpdateDashboardArgs = {
  dashboard: Partial<Dashboard>;
  dashboardId: string;
  serviceId: string;
};

type DeleteDashboardArgs = {
  dashboardId: string;
  serviceId: string;
};

export class DashboardApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async createDashboard({ name, serviceId }: CreateDashboardArgs): Promise<Dashboard> {
    const res = await this.httpClient.post(
      `${config.apiUrl}/services/${serviceId}/dashboards`,
      {
        body: JSON.stringify({
          name
        })
      },
      {
        includeAuthProviderHeader: true
      }
    );

    if (!res.ok) {
      throw new Error('Failed to create dashboard');
    }

    const { data } = (await res.json()) as CreateDashboardResponse;
    return data;
  }

  async listDashboards({ serviceId }: ListDashboardsArgs): Promise<Record<string, Dashboard>> {
    const res = await this.httpClient.get(
      `${config.apiUrl}/services/${serviceId}/dashboards`,
      {},
      {
        includeAuthProviderHeader: true
      }
    );

    if (!res.ok) {
      throw new Error('Failed to list dashboards');
    }

    const { data } = (await res.json()) as ListDashboardsResponse;
    return data;
  }

  async getDashboard({ dashboardId, serviceId }: GetDashboardArgs): Promise<Dashboard> {
    const res = await this.httpClient.get(
      `${config.apiUrl}/services/${serviceId}/dashboards/${dashboardId}`,
      {},
      {
        includeAuthProviderHeader: true
      }
    );

    if (!res.ok) {
      throw new Error('Failed to fetch dashboard');
    }

    const { data } = (await res.json()) as GetDashboardResponse;
    return data;
  }

  async updateDashboard({ dashboard, dashboardId, serviceId }: UpdateDashboardArgs): Promise<Dashboard> {
    const res = await this.httpClient.patch(
      `${config.apiUrl}/services/${serviceId}/dashboards/${dashboardId}`,
      {
        body: JSON.stringify({
          dashboard
        })
      },
      {
        includeAuthProviderHeader: true
      }
    );

    if (!res.ok) {
      throw new Error('Failed to update dashboard');
    }

    const { data } = (await res.json()) as UpdateDashboardResponse;
    return data;
  }

  async deleteDashboard({ dashboardId, serviceId }: DeleteDashboardArgs): Promise<boolean> {
    const res = await this.httpClient.destroy(
      `${config.apiUrl}/services/${serviceId}/dashboards/${dashboardId}`,
      {},
      {
        includeAuthProviderHeader: true
      }
    );

    if (!res.ok) {
      throw new Error('Failed to delete dashboard');
    }

    if (res.status !== 204) {
      return false;
    }

    return true;
  }
}
