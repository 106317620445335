import { ClickPipeKinesisImport } from 'shared/src/dataLoading';

export function getKinesisConfigWithCredentials<T extends ClickPipeKinesisImport>(model: T) {
  return {
    type: model.type,
    region: model.data.region ?? '',
    auth: model.data.auth,
    streamName: model.data.streamName
  };
}
