import { useCallback, useState } from 'react';
import { useRecaptcha } from 'src/components/RecaptchaProvider';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';

interface ReturnProps {
  loading: boolean;
  sendRequestIntegration: (value: string) => () => Promise<void>;
}
const useRequestIntegration = (onSubmitSuccess: () => void): ReturnProps => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const getRecaptchaToken = useRecaptcha();
  const sendRequestIntegration = useCallback(
    (value: string) => async (): Promise<void> => {
      try {
        setLoading(true);
        const recaptchaToken = await getRecaptchaToken('request_integration');
        if (!recaptchaToken) {
          createToast('Error', 'error', 'Invalid Captcha');
          return;
        }

        await api.integration.sendRequestIntegration(value, recaptchaToken);
        onSubmitSuccess();
        createToast(
          'Thanks so much for your request!',
          'success',
          'Our team will review your request for a new integration and will reach out if we need more information.'
        );
      } catch (e) {
        createToast(
          'Unable to send request',
          'error',
          'Due to some issues we were unable to send the request. Kindly try again later'
        );
      } finally {
        setLoading(false);
      }
    },
    [api.integration, getRecaptchaToken, onSubmitSuccess]
  );

  return {
    loading,
    sendRequestIntegration
  };
};

export default useRequestIntegration;
