import { Instance } from '@cp/common/protocol/Instance';
import { Container, Text } from '@clickhouse/click-ui';
import { instanceRegionName } from 'src/instance/instance';
import { ReactElement } from 'react';
import { formatDate } from 'src/lib/formatters/dateTimeFormatter';

function SummaryEntry({
  label,
  value
}: {
  label: string;
  value: string;
}): ReactElement {
  return (
    <Container orientation="vertical" maxWidth="25%" gap="xs">
      <Text color="muted">{label}</Text>
      <Text>{value}</Text>
    </Container>
  );
}

type Props = { instance: Instance };

export function InstanceSummary({ instance }: Props): ReactElement {
  return (
    <Container padding="none" gap="md">
      <SummaryEntry
        label="Location"
        value={instanceRegionName(instance.regionId)}
      />
      <SummaryEntry label="Version" value={instance.clickhouseVersion} />
      <SummaryEntry
        label="Last successful backup"
        value={formatDate(instance.lastBackupStarted)}
      />
      <SummaryEntry label="Created" value={formatDate(instance.creationDate)} />
    </Container>
  );
}
