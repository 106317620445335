import {
  Dialog,
  Button,
  TextField,
  Separator,
  Text,
  Spacer,
  Container
} from '@clickhouse/click-ui';
import { QuestionAnswerType } from '@cp/common/protocol/Account';
import { assertTruthy } from '@cp/common/utils/Assert';
import { ReactElement, useCallback, useState } from 'react';
import { ExistQuestionnaire } from 'src/components/ExitQuestionnaire/ExitQuestionnaire';
import { useExitQuestionnaire } from 'src/exitQuestionnaire/useExitQuestionnaire';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

interface DeleteServiceModalProps {
  open: boolean;
  name: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteServiceModal = ({
  open,
  name,
  onDelete,
  onCancel
}: DeleteServiceModalProps): ReactElement => {
  const currentOrganization = useCurrentOrganizationOrThrow();
  const currentInstances = useCurrentOrganizationInstances();
  const showExitQuestionnaire = currentInstances.length === 1;
  const { addUserExitQuestionnaire } = useExitQuestionnaire();
  const [inputValue, setInputValue] = useState('');
  const [questionnaireAnswers, setQuestionnaireAnswers] = useState<
    Array<QuestionAnswerType>
  >([]);
  const [isValidExitQuestionnaire, setIsValidExitQuestionnaire] =
    useState(false);
  const onSubmit = useCallback(() => {
    if (showExitQuestionnaire) {
      assertTruthy(
        isValidExitQuestionnaire,
        'Exit questionnaire form must be filled'
      );
      void addUserExitQuestionnaire(
        questionnaireAnswers,
        currentOrganization.id
      );
    }
    onDelete();
  }, [
    onDelete,
    addUserExitQuestionnaire,
    showExitQuestionnaire,
    questionnaireAnswers
  ]);
  const valid =
    inputValue === name && (!showExitQuestionnaire || isValidExitQuestionnaire);
  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
        }
      }}
    >
      <Dialog.Content title="Delete service" showClose onClose={onCancel}>
        <Text size="md">
          Terminating <b>{name}</b> will permanently delete all settings, data,
          and backups associated with this service. This action cannot be
          undone. To continue, please enter the name of the service.
        </Text>
        <Spacer />
        <TextField
          label="Service name"
          value={inputValue}
          onChange={setInputValue}
          data-testid="delete-service-modal-input-field"
        />
        {showExitQuestionnaire && (
          <>
            <Spacer />
            <ExistQuestionnaire
              onValidQuestionnaireChange={setIsValidExitQuestionnaire}
              onReasonListChange={setQuestionnaireAnswers}
            ></ExistQuestionnaire>
          </>
        )}
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Cancel" onClick={onCancel} />
          <Button
            type="danger"
            disabled={!valid}
            onClick={onSubmit}
            data-testid="delete-service-modal-delete-button"
          >
            Delete service
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
