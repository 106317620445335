import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Button,
  CardPrimary,
  Container,
  Icon,
  Link,
  Panel,
  SuccessAlert,
  Text as CUIText
} from '@clickhouse/click-ui';
import { ContinuouslySvg } from 'src/components/NetworkIdlingPanel/NetworkSvgs/ContinuouslySvg';
import { OccasionallySvg } from 'src/components/NetworkIdlingPanel/NetworkSvgs/OccasionallySvg';
import { useInstanceAutoScalingStateManager } from 'src/instance/instanceAutoScalingState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import styled from 'styled-components';
import { useInstanceAutoScalingController } from 'src/instance/instanceAutoScalingController';
import { Instance } from '@cp/common/protocol/Instance';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { useServiceFeatureFlag } from 'src/state/service';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';

const CustomCardPrimary = styled(CardPrimary)`
  border-radius: ${({ theme }): string => theme.border.radii[2]};
  max-width: 120px;
`;

const LinearGradientDiv = styled(Container)`
  background: ${({ theme }): string =>
    theme.name === 'dark'
      ? 'linear-gradient(180deg, rgba(226, 230, 19, 0.5) 0%, rgba(200, 230, 19, 0.10) 5%, rgba(213, 230, 19, 0.00) 23.96%, rgba(212, 230, 19, 0.00) 50%, rgba(212, 230, 19, 0.00) 75%, rgba(212, 230, 19, 0.10) 95%, rgba(212, 230, 19, 0.54) 100%);'
      : 'linear-gradient(180deg, rgba(19, 91, 230, 0.5) 0%, rgba(19, 91, 230, 0.10) 5%, rgba(19, 91, 230, 0.00) 25%, rgba(19, 91, 230, 0.00) 50%, rgba(19, 91, 230, 0.00) 75%, rgba(19, 91, 230, 0.10) 95%, rgba(19, 91, 230, 0.5) 100%);'};
  color: ${({ theme }): string => theme.global.color.outline.default};
  height: 2.5rem;
`;

const IdleServiceCard = ({
  isSelected,
  children,
  text,
  onClick
}: {
  isSelected: boolean;
  children: ReactNode;
  text: string;
  onClick: () => void;
}): ReactElement => (
  <Container isResponsive={false} maxWidth="7.5rem">
    <CustomCardPrimary
      alignContent="start"
      isSelected={isSelected}
      onClick={onClick}
      data-testid="network-card"
    >
      <LinearGradientDiv isResponsive={false} justifyContent="center">
        {children}
      </LinearGradientDiv>
      <CUIText size="sm">{text}</CUIText>
    </CustomCardPrimary>
  </Container>
);

export interface NetworkIdlingPanelProps {
  instance: Instance;
  onSubmitClick: (type: 'idlingOption' | 'accessList') => void;
}

export const NetworkIdlingPanel = ({
  instance,
  onSubmitClick
}: NetworkIdlingPanelProps): ReactNode => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const org = useCurrentOrganizationOrThrow();
  const isPayingOrg = isPayingStatus(org?.billingStatus);
  const isCustomValuesFeatureEnabled = useServiceFeatureFlag(
    'FT_CUSTOM_AUTOSCALE_VALUES'
  );
  const isDisableAutoscalingFeatureEnabled = useServiceFeatureFlag(
    'FT_DISABLE_AUTOSCALING'
  );

  const activeMaintenanceKind = instance?.activeMaintenanceKind;
  const { fetchAutoScalingSettings } = useInstanceAutoScalingController();

  const { autoScalingState, updateInstanceIdleTimeout } =
    useInstanceAutoScalingStateManager();
  const { saveIdleTimeoutAutoScalingSettings } =
    useInstanceAutoScalingController();
  const idleTimeout = autoScalingState[instance.id]?.enableIdleScaling
    ? autoScalingState[instance.id].idleTimeoutMinutes
    : 0;

  const handleIdleTimeoutChange = useCallback(
    (value: number) => (): void => {
      updateInstanceIdleTimeout({
        idleTimeoutMinutes: value,
        instanceId: instance.id
      });
      setIsChanged(true);
    },
    [instance.id, updateInstanceIdleTimeout]
  );

  useEffect(() => {
    if (!instance || !org) {
      return;
    }

    void fetchAutoScalingSettings(
      instance.id,
      instance.instanceTier,
      instance.customAutoscaleValues,
      instance.regionId,
      isCustomValuesFeatureEnabled,
      isPayingOrg,
      isOrgByoc(org),
      isDisableAutoscalingFeatureEnabled,
      activeMaintenanceKind
    );
  }, [
    fetchAutoScalingSettings,
    instance,
    org,
    isCustomValuesFeatureEnabled,
    isPayingOrg,
    isDisableAutoscalingFeatureEnabled,
    activeMaintenanceKind
  ]);
  const onSubmit = (): void => {
    void saveIdleTimeoutAutoScalingSettings(instance.id).then(() => {
      onSubmitClick('idlingOption');
      setIsSubmitted(true);
      setIsChanged(false);
    });
  };

  if (isSubmitted) {
    return (
      <SuccessAlert
        size="medium"
        title="Idling period "
        text={
          <Container isResponsive={false} wrap="wrap" gap="xxs">
            {idleTimeout === 0
              ? 'Your service idling is disabled'
              : `
            Your service will start idling after ${idleTimeout} minutes of inactivity.
            `}
            <span>
              <Link size="sm" onClick={() => setIsSubmitted(false)}>
                Edit answer
              </Link>
              |
            </span>
            <Link
              size="sm"
              onClick={() =>
                navigateTo(routes.serviceSettings({ serviceId: instance.id }))
              }
            >
              Go to idling settings
            </Link>
          </Container>
        }
      />
    );
  }

  return (
    <Panel color="muted" fillWidth alignItems="center" gap="lg">
      <Container
        isResponsive={false}
        justifyContent="center"
        alignItems="center"
        orientation="vertical"
      >
        <CUIText align="center" weight="bold">
          How often do you plan to use your service?
        </CUIText>
        <CUIText color="muted" align="center" weight="normal">
          Helps us determine when to idle your service
        </CUIText>
      </Container>
      <Container
        justifyContent="center"
        isResponsive={false}
        gap="lg"
        alignItems="stretch"
      >
        <IdleServiceCard
          isSelected={idleTimeout === 0}
          onClick={handleIdleTimeoutChange(0)}
          text="Continuously"
        >
          <ContinuouslySvg />
        </IdleServiceCard>
        <IdleServiceCard
          isSelected={idleTimeout === 60}
          onClick={handleIdleTimeoutChange(60)}
          text="Occasionally"
        >
          <OccasionallySvg />
        </IdleServiceCard>
        <IdleServiceCard
          isSelected={idleTimeout === 15}
          onClick={handleIdleTimeoutChange(15)}
          text="I don’t know"
        >
          <Icon name="question" />
        </IdleServiceCard>
      </Container>
      {isChanged && (
        <Container fillWidth justifyContent="center" isResponsive={false}>
          <Button type="primary" fillWidth={false} onClick={onSubmit}>
            Submit
          </Button>
        </Container>
      )}
    </Panel>
  );
};

export default NetworkIdlingPanel;
