import { css, Theme } from '@emotion/react';

export const versionMismatchBar = (theme: Theme) =>
  css({
    height: '40px',
    padding: '4px 20px 4px 12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    background: theme.global.color.background.muted,
    borderBottom: `1px solid ${theme.global.color.stroke.default}`,
    display: 'flex',
    alignItems: 'center'
  });

export const versionMismatchIcon = css({
  paddingRight: '12px',
  flex: '0 0 auto'
});

export const versionMismatchMessage = css({
  flex: '1 1 auto',
  display: 'flex'
});

export const versionMismatchButtons = css({
  flex: '0 0 auto',
  display: 'flex',
  columnGap: '12px'
});
