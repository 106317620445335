import { ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useIsUnifiedConsole } from 'src/lib/features';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';

export const UnifiedConsoleFeatureGuard = (): ReactElement => {
  const enableUnifiedConsole = useIsUnifiedConsole();
  if (!enableUnifiedConsole) {
    return <NotFoundPage />;
  }
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};
