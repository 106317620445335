import {
  Alert,
  Button,
  ButtonProps,
  Container,
  GridContainer,
  Panel,
  Text as CuiText,
  Tooltip
} from '@clickhouse/click-ui';
import { Galaxy } from 'galaxy';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import transform from 'lodash/transform';
import { ReactElement, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Integration, IntegrationCategory } from 'shared';
import {
  AccordionWrapper,
  CommonAccordionProps
} from 'src/components/GettingStarted/AccordionWrapper';
import { IntegrationLogo } from 'src/components/IntegrationLogo/IntegrationLogo';
import LoadingPanel from 'src/components/LoadingPanel/LoadingPanel';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { createCategoryLabel } from 'src/integrations/common/integrationCategories';
import { IntegrationsByCategoryType } from 'src/integrations/controller/useIntegrations';
import { IntegrationSummaryType } from 'src/integrations/controller/useIntegrationSummary';
import { routes } from 'src/lib/routes';
import { findAll } from 'src/util/strapi';
import styled from 'styled-components';
import useSWR from 'swr';

const IntegrationIconButton = styled(Button)`
  padding: 1rem;
`;

interface ChildrenProp {
  children: ReactNode;
}

const IntegrationCategoryContainer = ({
  children
}: ChildrenProp): ReactElement => (
  <GridContainer
    gridTemplateColumns="repeat(2, 64px)"
    isResponsive={false}
    gap="md"
  >
    {children}
  </GridContainer>
);

type IntegrationByCategory = Record<
  IntegrationCategory,
  IntegrationsByCategoryType
>;
const fetchIntegrations = async (): Promise<
  Array<IntegrationsByCategoryType>
> => {
  const { data } = await findAll<Integration>('integrations', {
    populate: ['logo', 'logo_dark'],
    filters: {
      category: {
        $in: [
          'DATA_INGESTION',
          'DATA_VISUALIZATION',
          'SQL_CLIENT',
          'LANGUAGE_CLIENT'
        ]
      }
    },
    fields: [
      'name',
      'category',
      'docsLink',
      'slug',
      'website',
      'openInNewWindow'
    ]
  });

  if (!data?.[0]) {
    throw new Error('No Integrations found');
  }
  const integrationsObject = transform(
    groupBy(orderBy(data, 'name'), 'category'),
    (result, value: Array<Integration>, key) => {
      const category = key as IntegrationCategory;
      const integrations = value.slice(0, 6);
      result[category] = {
        label: createCategoryLabel(category),
        category,
        integrations
      };
    },
    {} as IntegrationByCategory
  );

  return Object.values(integrationsObject);
};

const IntegrationWrapper = ({
  children,
  title
}: ChildrenProp & { title: string }): ReactElement => (
  <Container
    orientation="vertical"
    gap="lg"
    justifyContent="start"
    alignItems="start"
    padding="md"
    maxWidth="10.75rem"
    isResponsive={false}
  >
    <CuiText weight="medium" size="md">
      {title}
    </CuiText>
    <IntegrationCategoryContainer>{children}</IntegrationCategoryContainer>
  </Container>
);

interface IntegrationButtonProps extends ButtonProps {
  integration: Integration;
}

const IntegrationButton = ({
  integration,
  ...props
}: IntegrationButtonProps): ReactElement => (
  <IntegrationIconButton type="secondary" {...props}>
    <IntegrationLogo
      integration={integration as IntegrationSummaryType}
      size="lg"
    />
  </IntegrationIconButton>
);

interface IntegrationLinkProps extends IntegrationButtonProps {
  href: string;
}

const IntegrationLink = ({
  href,
  integration,
  ...props
}: IntegrationLinkProps): ReactElement => (
  <Link to={href} target="_blank">
    <IntegrationIconButton type="secondary" {...props}>
      <IntegrationLogo
        integration={integration as IntegrationSummaryType}
        size="lg"
      />
    </IntegrationIconButton>
  </Link>
);

const ExploreIntegrationsAccordion = ({
  serviceId,
  markAsCompleted,
  showProgress,
  ...props
}: CommonAccordionProps & { serviceId?: string | null }): ReactElement => {
  const {
    data: integrationByCategories,
    isLoading,
    error
  } = useSWR<Array<IntegrationsByCategoryType>>(
    'fetch-integrations-accordion',
    fetchIntegrations
  );
  const onClickNavigateTo = useCallback(
    (integrationId: string) => (): void => {
      Galaxy.galaxy().track(
        'gettingStartedPage.integrationsAccordion.integrationButtonClick',
        {
          interaction: 'click',
          from: showProgress ? 'main' : 'help'
        }
      );
      navigateTo(routes.integration({ serviceId, integrationId }));
    },
    [showProgress, serviceId]
  );

  const onIntegrationLinkClick = useCallback((): void => {
    Galaxy.galaxy().track(
      'gettingStartedPage.integrationsAccordion.integrationLinkClick',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
  }, [showProgress]);

  const onViewIntegrationsClick = useCallback(() => {
    Galaxy.galaxy().track(
      'gettingStartedPage.integrationsAccordion.viewIntegrationsButtonClick',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    navigateTo(routes.integrations({ serviceId }));
  }, [showProgress, serviceId]);

  return (
    <AccordionWrapper
      title="Explore integrations"
      value="visitedIntegration"
      {...props}
    >
      <Container orientation="vertical" gap="lg" fillWidth isResponsive={false}>
        <CuiText color="muted">
          Use our table builder to create your first table, or type in your
          query manually in the SQL console
        </CuiText>
        <Container isResponsive={false} alignItems="start" gap="md" wrap="wrap">
          {error && <Alert state="danger" text={error} />}
          {isLoading && <LoadingPanel />}
          {integrationByCategories?.map((integrationsByCategory) => (
            <IntegrationWrapper
              key={integrationsByCategory.label}
              title={integrationsByCategory.label}
            >
              {integrationsByCategory.integrations.map((integration) => (
                <Tooltip key={integration.name}>
                  <Tooltip.Trigger>
                    {integration.openInNewWindow && (
                      <IntegrationLink
                        href={integration.docsLink}
                        integration={integration}
                        onClick={onIntegrationLinkClick}
                      />
                    )}
                    {!integration.openInNewWindow && (
                      <IntegrationButton
                        onClick={onClickNavigateTo(integration.slug)}
                        integration={integration}
                      />
                    )}
                  </Tooltip.Trigger>
                  <Tooltip.Content>{integration.name}</Tooltip.Content>
                </Tooltip>
              ))}
            </IntegrationWrapper>
          ))}
        </Container>
        <Container isResponsive={false} gap="md">
          <Button onClick={onViewIntegrationsClick}>
            View all integrations
          </Button>
          {showProgress && !props.isCompleted && (
            <Button iconLeft="check" type="secondary" onClick={markAsCompleted}>
              Mark as completed
            </Button>
          )}
        </Container>
      </Container>
    </AccordionWrapper>
  );
};

export default ExploreIntegrationsAccordion;
