import { Container } from '@clickhouse/click-ui';
import { Organization } from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import { MediumWidthContainer } from 'src/components/MediumWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { SectionSeparator } from 'src/lib/pageElements';
import { canLeaveOrganization } from 'src/organization/organization';
import { useOrganizationController } from 'src/organization/organizationController';
import { LeaveOrganizationSection } from 'src/pages/OrganizationDetailsPage/LeaveOrganizationSection';
import { OrganizationIdSection } from 'src/pages/OrganizationDetailsPage/OrganizationIdSection';
import { OrganizationNameSection } from 'src/pages/OrganizationDetailsPage/OrganizationNameSection';

type OrganizationDetailsPageProps = {
  organization: Organization;
  userId: string;
};

const OrganizationDetails = ({
  organization,
  userId
}: OrganizationDetailsPageProps): ReactElement | null => {
  const organizationController = useOrganizationController();

  const currentOrganizationId = organization.id;

  const userCanLeaveOrganization = canLeaveOrganization(userId, organization);

  const onLeave = (): void => {
    void organizationController.leave({
      organizationId: organization.id,
      name: organization.name
    });
  };

  const onRename = (name: string): void => {
    void organizationController.rename({
      name,
      organizationId: organization.id
    });
  };

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Organization details" />
      <MainPaneLayout.Content>
        <MediumWidthContainer
          orientation="vertical"
          padding="none"
          alignItems="start"
          data-testid="organization-details-page"
        >
          <OrganizationNameSection
            orgName={organization.name}
            onRename={onRename}
          />
          <OrganizationIdSection orgId={currentOrganizationId} />
          {userCanLeaveOrganization && (
            <Container orientation="vertical">
              <SectionSeparator />
              <LeaveOrganizationSection
                onLeave={onLeave}
                orgName={organization.name}
              />
            </Container>
          )}
        </MediumWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default OrganizationDetails;
