import { useCallback, useEffect } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useSocketIo } from 'src/lib/socketio';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { useSetLoadingQueryApiEndpoints, useSetQueryApiEndpoints } from 'src/queryEndpoints/stateManager';

export function useInitQueryApiEndpointState(serviceId: string): void {
  const setLoading = useSetLoadingQueryApiEndpoints();
  const setQueryEndpoints = useSetQueryApiEndpoints();
  const apiClient = useApiClient();
  const { client: socketIo } = useSocketIo();
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );

  const role = useCurrentOrgUserRole();
  const loadQueryEndpoints = useCallback(
    async function (): Promise<void> {
      setLoading(true);
      setQueryEndpoints([]);
      try {
        const endpoints = await apiClient.queryEndpoints.listQueryEndpoints({
          serviceId,
          status: 'active'
        });
        setQueryEndpoints(endpoints);
      } catch (e) {
        console.error(e);
        createToast('Error', 'danger', errorMessage(e));
      } finally {
        setLoading(false);
      }
    },
    [serviceId, setQueryEndpoints, setLoading, apiClient.queryEndpoints, hasManageServicePermission]
  );

  useEffect(() => {
    if (hasManageServicePermission && role === 'ADMIN') {
      loadQueryEndpoints().catch(console.error);
    }
  }, [loadQueryEndpoints, hasManageServicePermission, role]);

  useEffect(() => {
    const listener = (): void => {
      loadQueryEndpoints().catch(console.error);
    };

    if (socketIo) {
      socketIo.on('apiEndpointCreated', listener);
      socketIo.on('apiEndpointUpdated', listener);
      socketIo.on('apiEndpointDisabled', listener);
    }

    return () => {
      socketIo?.removeListener('apiEndpointUpdated', listener);
      socketIo?.removeListener('apiEndpointCreated', listener);
      socketIo?.removeListener('apiEndpointDisabled', listener);
    };
  }, [socketIo, serviceId, loadQueryEndpoints]);
}
