import { IconProps } from 'src/components/icons/iconInterface';

function InfoRoundedIcon(props: IconProps & { backgroundColor?: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        y="0.5"
        width="16"
        height="16"
        rx="8"
        fill={props.backgroundColor || '#E6F1FA'}
      />
      <path
        d="M7.22816 12.5V5.95455H8.77077V12.5H7.22816ZM8.00373 5.02557C7.75941 5.02557 7.54918 4.9446 7.37305 4.78267C7.19691 4.6179 7.10884 4.42045 7.10884 4.19034C7.10884 3.95739 7.19691 3.75994 7.37305 3.59801C7.54918 3.43324 7.75941 3.35085 8.00373 3.35085C8.25089 3.35085 8.46112 3.43324 8.63441 3.59801C8.81055 3.75994 8.89862 3.95739 8.89862 4.19034C8.89862 4.42045 8.81055 4.6179 8.63441 4.78267C8.46112 4.9446 8.25089 5.02557 8.00373 5.02557Z"
        fill={props.color || '#3B73DE'}
      />
    </svg>
  );
}

export default InfoRoundedIcon;
