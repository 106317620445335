import { SelectOptionListItem } from '@clickhouse/click-ui';
import { GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import {
  ActiveMaintenanceKind,
  DEFAULT_AUTOSCALING_BYOC_MEMORY,
  GetInstanceAutoScalingResponse,
  IDLING_TIMEOUT_MINUTES_DEFAULT,
  INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED,
  INSTANCE_TIER_TO_REPLICA_COUNT_MAP,
  InstanceTier,
  MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG,
  MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG,
  MIN_AUTOSCALING_PRODUCTION_MEMORY,
  MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY
} from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { getInstanceMemorySizeOptions } from '@cp/common/utils/InstanceUtils';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { produce } from 'immer';
import { formatMemoryAllocation } from 'src/instance/instance';
import { clamp } from '@cp/common/utils/MathUtils';

export interface InstanceAutoScalingSettings {
  originMinAutoScalingTotalMemory?: number;
  originMaxAutoScalingTotalMemory?: number;
  minAutoScalingTotalMemory?: number;
  maxAutoScalingTotalMemory?: number;
  memoryAutoScalingOptions?: Array<SelectOptionListItem>;
  memoryNeedsSaving?: boolean;
  /** If true, idle timeout is disabled. This value is mutable (the user can change the `idle_timeout_minute` value). */
  enableIdleScaling?: boolean;
  idleTimeoutMinutes?: number;
  idleTimeoutNeedsSaving?: boolean;
  originEnableIdleScaling?: boolean;
  originIdleTimeoutMinutes?: number;
  originMinReplicas?: number;
  originMaxReplicas?: number;
  numReplicasNeedsSaving?: boolean;
  minReplicas?: number;
  maxReplicas?: number;
  isLoading?: boolean;
  canBeScaled: boolean;
  /** If true, the idle timeout control is disabled and the user won't be able to modify the `idle_timeout_minute` value. */
  isIdleControlDisabled: boolean;
}

export type InstanceAutoScalingState = Record<string, InstanceAutoScalingSettings>;
const autoScalingStateAtom = atom<InstanceAutoScalingState>({});

interface UpdateMinMemoryInStateInput {
  minAutoScalingTotalMemory: number;
  instanceId: string;
}

/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {minAutoScalingTotalMemory, instanceId}
 *
 * Updates the autoscaling minimum memory for a given instance id and returns the updated state.
 */
export const updateMinMemoryInState = (
  state: InstanceAutoScalingState,
  { minAutoScalingTotalMemory, instanceId }: UpdateMinMemoryInStateInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    if (Number(newState.maxAutoScalingTotalMemory) < minAutoScalingTotalMemory) {
      newState.maxAutoScalingTotalMemory = minAutoScalingTotalMemory;
    }

    newState.minAutoScalingTotalMemory = minAutoScalingTotalMemory;

    newState.memoryNeedsSaving =
      newState.maxAutoScalingTotalMemory !== newState.originMaxAutoScalingTotalMemory ||
      newState.minAutoScalingTotalMemory !== newState.originMinAutoScalingTotalMemory;
  });

interface UpdateMaxMemoryInStateInput {
  maxAutoScalingTotalMemory: number;
  instanceId: string;
}

/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {maxAutoScalingTotalMemory, instanceId}
 *
 * Updates the autoscaling maximum memory for a given instance id and returns the updated state.
 */
export const updateMaxMemoryInState = (
  state: InstanceAutoScalingState,
  { maxAutoScalingTotalMemory, instanceId }: UpdateMaxMemoryInStateInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    if (Number(state[instanceId].minAutoScalingTotalMemory) > maxAutoScalingTotalMemory) {
      newState.minAutoScalingTotalMemory = maxAutoScalingTotalMemory;
    }

    newState.maxAutoScalingTotalMemory = maxAutoScalingTotalMemory;

    newState.memoryNeedsSaving =
      newState.maxAutoScalingTotalMemory !== newState.originMaxAutoScalingTotalMemory ||
      newState.minAutoScalingTotalMemory !== newState.originMinAutoScalingTotalMemory;
  });

interface UpdateNumReplicasInStateInput {
  minReplicas: number;
  maxReplicas: number;
  instanceId: string;
}

export const updateNumReplicasInState = (
  state: InstanceAutoScalingState,
  { minReplicas, maxReplicas, instanceId }: UpdateNumReplicasInStateInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    newState.minReplicas = minReplicas;
    newState.maxReplicas = maxReplicas;
    newState.numReplicasNeedsSaving =
      newState.minReplicas !== newState.originMinReplicas || newState.maxReplicas !== newState.originMaxReplicas;
  });

interface UpdateIdleTimeoutInStateInput {
  idleTimeoutMinutes: number;
  instanceId: string;
}

/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {idleTimeoutMinutes, instanceId}
 *
 * Updates the auto scaling idle timeout for a given instance id and returns the updated state.
 */
export const updateInstanceIdleTimeoutInState = (
  state: InstanceAutoScalingState,
  { idleTimeoutMinutes, instanceId }: UpdateIdleTimeoutInStateInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    if (idleTimeoutMinutes === 0) {
      newState.idleTimeoutMinutes = idleTimeoutMinutes;
      newState.enableIdleScaling = false;
      newState.idleTimeoutNeedsSaving = true;
      return;
    }

    newState.idleTimeoutMinutes = idleTimeoutMinutes;
    newState.enableIdleScaling = true;
    newState.idleTimeoutNeedsSaving = true;
  });

interface SetInstanceMemoryAutoScalingSavedSuccessfullyInput {
  instanceId: string;
  savingStatusMessage?: string;
}
/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {savingStatusMessage, instanceId}
 *
 * Set the current autoscaling memory settings with the one saved and returns the updated state.
 */
export const setInstanceMemoryAutoScalingSavedSuccessfullyInState = (
  state: InstanceAutoScalingState,
  { instanceId }: SetInstanceMemoryAutoScalingSavedSuccessfullyInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    newState.originMinAutoScalingTotalMemory = newState.minAutoScalingTotalMemory;

    newState.originMaxAutoScalingTotalMemory = newState.maxAutoScalingTotalMemory;
    newState.originMinReplicas = newState.minReplicas;
    newState.originMaxReplicas = newState.maxReplicas;
    newState.numReplicasNeedsSaving = false;
    newState.memoryNeedsSaving = false;
  });

interface SetInstanceIdleTimeoutAutoScalingSavedSuccessfullyInput {
  instanceId: string;
  savingStatusMessage?: string;
}
/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {savingStatusMessage, instanceId}
 *
 * Set the current autoscaling idle timeout settings with the one saved and returns the updated state.
 */
export const setInstanceIdleTimeoutAutoScalingSavedSuccessfullyInState = (
  state: InstanceAutoScalingState,
  { instanceId }: SetInstanceIdleTimeoutAutoScalingSavedSuccessfullyInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    newState.originEnableIdleScaling = newState.enableIdleScaling;
    newState.originIdleTimeoutMinutes = newState.idleTimeoutMinutes;

    newState.idleTimeoutNeedsSaving = false;
  });
interface SetInstanceAutoScalingIsLoadingInput {
  instanceId: string;
  isLoading?: boolean;
}
/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {isLoading, instanceId}
 *
 * Updates the loading state for a given instance auto scaling operation and returns the updated state.
 */
export const setInstanceAutoScalingIsLoadingInState = (
  state: InstanceAutoScalingState,
  { instanceId, isLoading }: SetInstanceAutoScalingIsLoadingInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    newState.isLoading = isLoading;
  });

export interface ComputeInstanceAutoScalingStateInput {
  instanceId: string;
  instanceTier: InstanceTier;
  instanceCustomAutoScaleValues: Array<number>;
  regionId: RegionId;
  isCustomValuesFeatureEnabled: boolean;
  isPayingOrg: boolean;
  isByocOrg: boolean;
  limits: GetAutoScalingLimitsResponse;
  autoScalingSettings?: GetInstanceAutoScalingResponse;
  enableIdleScaling?: boolean;
  idleTimeoutMinutes?: number;
  isDisableAutoscalingFeatureEnabled: boolean;
  activeMaintenanceKind: ActiveMaintenanceKind | undefined;
}

/**
 *
 * @param state: The auto scaling application state. The state includes auto scaling information for each instance if fetched.
 * @param {ComputeInstanceAutoScalingStateInput, instanceId}
 *
 * Computes the auto scaling settings for a given instance and returns the updated state
 */
export const computeInstanceAutoScalingInState = (
  state: InstanceAutoScalingState,
  {
    instanceId,
    instanceTier,
    instanceCustomAutoScaleValues = [],
    regionId,
    isCustomValuesFeatureEnabled,
    isPayingOrg,
    isByocOrg,
    limits,
    autoScalingSettings: autoScalingSettingsProp,
    isDisableAutoscalingFeatureEnabled,
    activeMaintenanceKind
  }: ComputeInstanceAutoScalingStateInput
): InstanceAutoScalingState =>
  produce(state, (draft): void => {
    draft[instanceId] = draft[instanceId] || {};

    const newState = draft[instanceId];
    const defaultReplicas = INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instanceTier];

    const autoScalingSettings = autoScalingSettingsProp ?? {
      minMemoryGb: isByocOrg
        ? DEFAULT_AUTOSCALING_BYOC_MEMORY
        : instanceTier === 'Production'
        ? MIN_AUTOSCALING_PRODUCTION_MEMORY
        : MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY,
      maxMemoryGb: isByocOrg
        ? DEFAULT_AUTOSCALING_BYOC_MEMORY
        : instanceTier === 'Production'
        ? isPayingOrg
          ? MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG
          : MAX_AUTOSCALING_PRODUCTION_MEMORY_NON_PAID_ORG
        : MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY,
      enableHorizontalScaling: undefined,
      enableIdleScaling: undefined,
      idleTimeoutMinutes: IDLING_TIMEOUT_MINUTES_DEFAULT,
      minReplicas: defaultReplicas,
      maxReplicas: defaultReplicas
    };

    const { sizes: sizesOptions, selectableDomain } = getInstanceMemorySizeOptions(
      regionId,
      instanceTier,
      isCustomValuesFeatureEnabled,
      isPayingOrg,
      isByocOrg,
      instanceCustomAutoScaleValues,
      limits
    );

    let sizes = sizesOptions;

    if (instanceTier === 'Development') {
      sizes = [MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY];
    }

    const memorySizes = sizes.filter((_, idx) => idx >= selectableDomain.min && idx <= selectableDomain.max);

    const memoryAutoScalingOptions = memorySizes.map((size) => {
      return {
        label: formatMemoryAllocation(size, instanceTier),
        value: size.toString(),
        'data-testid': 'memory-size-option',
        'data-value': size.toString()
      };
    });

    const canBeScaled =
      INSTANCE_TIERS_THAT_CAN_BE_AUTO_SCALED.has(instanceTier) &&
      !isDisableAutoscalingFeatureEnabled &&
      !activeMaintenanceKind;

    if (instanceTier === 'Development') {
      newState.maxAutoScalingTotalMemory = MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY;
      newState.minAutoScalingTotalMemory = MIN_MAX_AUTOSCALING_DEV_TOTAL_MEMORY;
    } else {
      const minSelectable = Math.min(...memorySizes);
      const maxSelectable = Math.max(...memorySizes);
      newState.maxAutoScalingTotalMemory = clamp(
        minSelectable,
        newState.maxAutoScalingTotalMemory ?? autoScalingSettings.maxMemoryGb,
        maxSelectable
      );
      newState.minAutoScalingTotalMemory = clamp(
        minSelectable,
        newState.minAutoScalingTotalMemory ?? autoScalingSettings.minMemoryGb,
        maxSelectable
      );
    }

    newState.canBeScaled = canBeScaled;
    newState.originMaxAutoScalingTotalMemory = autoScalingSettings.maxMemoryGb;
    newState.originMinAutoScalingTotalMemory = autoScalingSettings.minMemoryGb;
    newState.memoryAutoScalingOptions = memoryAutoScalingOptions;
    newState.enableIdleScaling = newState.enableIdleScaling ?? autoScalingSettings.enableIdleScaling;
    newState.isIdleControlDisabled = !!activeMaintenanceKind || isByocOrg;
    newState.idleTimeoutMinutes = newState.idleTimeoutMinutes ?? autoScalingSettings.idleTimeoutMinutes;
    newState.originEnableIdleScaling = autoScalingSettings.enableIdleScaling;
    newState.originIdleTimeoutMinutes = autoScalingSettings.idleTimeoutMinutes;
    newState.originMinReplicas = autoScalingSettings.minReplicas || defaultReplicas;
    newState.originMaxReplicas = autoScalingSettings.maxReplicas || defaultReplicas;
    newState.minReplicas = autoScalingSettings.minReplicas || defaultReplicas;
    newState.maxReplicas = autoScalingSettings.maxReplicas || defaultReplicas;
  });

interface CancelMemoryChangesInput {
  instanceId: string;
}
/**
 *
 * @param state: The auto scaling application state.
 * @param {instanceId}
 *
 * Restore the autoscaling settings for a given instance id and returns the updated state.
 */
export const cancelMemoryChangesInState = (
  state: InstanceAutoScalingState,
  { instanceId }: CancelMemoryChangesInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    newState.maxAutoScalingTotalMemory = newState.originMaxAutoScalingTotalMemory;
    newState.minAutoScalingTotalMemory = newState.originMinAutoScalingTotalMemory;
    newState.maxReplicas = newState.originMaxReplicas;
    newState.minReplicas = newState.originMinReplicas;
    newState.memoryNeedsSaving = false;
    newState.numReplicasNeedsSaving = false;
  });

interface CancelIdleTimeoutChangesInput {
  instanceId: string;
}
/**
 *
 * @param state: The auto scaling application state.
 * @param {instanceId}
 *
 * Restore the auto scaling idle settings for a given instance id and returns the updated state.
 */
export const cancelIdleTimeoutChangesInState = (
  state: InstanceAutoScalingState,
  { instanceId }: CancelIdleTimeoutChangesInput
): InstanceAutoScalingState =>
  produce(state, (draft) => {
    const newState = draft[instanceId];

    newState.enableIdleScaling = newState.originEnableIdleScaling;
    newState.idleTimeoutMinutes = newState.originIdleTimeoutMinutes;
    newState.idleTimeoutNeedsSaving = false;
  });

interface AutoScalingStateManager {
  autoScalingState: InstanceAutoScalingState;

  computeInstanceAutoScaling: (autoScaling: ComputeInstanceAutoScalingStateInput) => void;

  setInstanceMemoryAutoScalingSavedSuccessfully: ({
    instanceId
  }: SetInstanceMemoryAutoScalingSavedSuccessfullyInput) => void;

  setInstanceIdleTimeoutAutoScalingSavedSuccessfully: ({
    instanceId
  }: SetInstanceMemoryAutoScalingSavedSuccessfullyInput) => void;

  updateInstanceMinMemoryAutoScaling: ({ minAutoScalingTotalMemory, instanceId }: UpdateMinMemoryInStateInput) => void;

  updateInstanceMaxMemoryAutoScaling: ({ maxAutoScalingTotalMemory, instanceId }: UpdateMaxMemoryInStateInput) => void;

  updateInstanceNumReplicas: ({ minReplicas, maxReplicas, instanceId }: UpdateNumReplicasInStateInput) => void;

  updateInstanceIdleTimeout: ({ idleTimeoutMinutes, instanceId }: UpdateIdleTimeoutInStateInput) => void;

  cancelMemoryInstanceAutoScalingChanges: ({ instanceId }: CancelMemoryChangesInput) => void;

  cancelIdleTimeoutInstanceAutoScalingChanges: ({ instanceId }: CancelIdleTimeoutChangesInput) => void;

  setInstanceAutoScalingIsLoading: ({ instanceId, isLoading }: SetInstanceAutoScalingIsLoadingInput) => void;
}

export const useInstanceAutoScalingStateManager = (): AutoScalingStateManager => {
  const [autoScalingState, setAutoScalingAtom] = useAtom(autoScalingStateAtom);

  const computeInstanceAutoScaling = useCallback(
    (input: ComputeInstanceAutoScalingStateInput): void => {
      setAutoScalingAtom((state) => computeInstanceAutoScalingInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const setInstanceMemoryAutoScalingSavedSuccessfully = useCallback(
    (input: SetInstanceMemoryAutoScalingSavedSuccessfullyInput): void => {
      setAutoScalingAtom((state) => setInstanceMemoryAutoScalingSavedSuccessfullyInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const setInstanceIdleTimeoutAutoScalingSavedSuccessfully = useCallback(
    (input: SetInstanceMemoryAutoScalingSavedSuccessfullyInput): void => {
      setAutoScalingAtom((state) => setInstanceIdleTimeoutAutoScalingSavedSuccessfullyInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const updateInstanceMinMemoryAutoScaling = useCallback(
    (input: UpdateMinMemoryInStateInput): void => {
      setAutoScalingAtom((state) => updateMinMemoryInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const updateInstanceMaxMemoryAutoScaling = useCallback(
    (input: UpdateMaxMemoryInStateInput): void => {
      setAutoScalingAtom((state) => updateMaxMemoryInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const updateInstanceNumReplicas = useCallback(
    (input: UpdateNumReplicasInStateInput): void => {
      setAutoScalingAtom((state) => updateNumReplicasInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const updateInstanceIdleTimeout = useCallback(
    (input: UpdateIdleTimeoutInStateInput): void => {
      setAutoScalingAtom((state) => updateInstanceIdleTimeoutInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const cancelMemoryInstanceAutoScalingChanges = useCallback(
    (input: CancelMemoryChangesInput) => {
      setAutoScalingAtom((state) => cancelMemoryChangesInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const cancelIdleTimeoutInstanceAutoScalingChanges = useCallback(
    (input: CancelIdleTimeoutChangesInput) => {
      setAutoScalingAtom((state) => cancelIdleTimeoutChangesInState(state, input));
    },
    [setAutoScalingAtom]
  );

  const setInstanceAutoScalingIsLoading = useCallback(
    (input: SetInstanceAutoScalingIsLoadingInput) => {
      setAutoScalingAtom((state) => setInstanceAutoScalingIsLoadingInState(state, input));
    },
    [setAutoScalingAtom]
  );

  return {
    autoScalingState,
    computeInstanceAutoScaling,
    setInstanceMemoryAutoScalingSavedSuccessfully,
    setInstanceIdleTimeoutAutoScalingSavedSuccessfully,
    updateInstanceMinMemoryAutoScaling,
    updateInstanceMaxMemoryAutoScaling,
    updateInstanceNumReplicas,
    updateInstanceIdleTimeout,
    cancelMemoryInstanceAutoScalingChanges,
    cancelIdleTimeoutInstanceAutoScalingChanges,
    setInstanceAutoScalingIsLoading
  };
};
