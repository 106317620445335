import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import HttpConnectionTab from 'src/components/ServiceConnect/Tabs/HttpConnectionTab';
import NativeConnectionTab from 'src/components/ServiceConnect/Tabs/NativeConnectionTab';
import GoConnectionTab from 'src/components/ServiceConnect/Tabs/GoConnectionTab';
import JavaConnectionTab from 'src/components/ServiceConnect/Tabs/JavaConnectionTab';
import NodejsConnectionTab from 'src/components/ServiceConnect/Tabs/NodejsConnectionTab';
import PythonConnectionTab from 'src/components/ServiceConnect/Tabs/PythonConnectionTab';
import JdbcConnectionTab from 'src/components/ServiceConnect/Tabs/JdbcConnectionTab';
import MySqlConnectionTab from 'src/components/ServiceConnect/Tabs/MySqlConnectionTab';
import { Container, Select, Text } from '@clickhouse/click-ui';
import {
  ProtocolProps,
  ServiceModalMySqlProtocolProps
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';

// This component is needed to enable pointer events for the child component.
// Without it the select items are not clickable, because they inherit the pointer-events: none from body.
const SelectItems = styled.div`
  pointer-events: all;
`;

const CustomSelect = styled(Select)`
  width: 230px;
`;

interface ConnectWithContentProps {
  showPassword: boolean;
  https: ProtocolProps;
  mysqlProps: ServiceModalMySqlProtocolProps;
  username: string;
  nativesecure: ProtocolProps;
}

function ConnectWithContent({
  mysqlProps,
  https,
  showPassword,
  username,
  nativesecure
}: ConnectWithContentProps): ReactElement {
  const [connectionType, setConnectionType] = useState<string>('https');
  return (
    <>
      <Container gap="xs" isResponsive={false}>
        <Text>Connect with:</Text>
        <CustomSelect
          value={connectionType}
          onSelect={setConnectionType}
          data-testid="service-connect-select-connection-type"
        >
          <SelectItems>
            <Select.Group heading="Interfaces" key="interfaces">
              <Select.Item
                value="https"
                key="https"
                icon="http"
                data-testid="service-connect-select-connection-type-https"
              >
                HTTPS
              </Select.Item>
              <Select.Item
                value="native"
                key="native"
                icon="clickhouse"
                data-testid="service-connect-select-connection-type-native"
              >
                Native
              </Select.Item>
              <Select.Item
                value="mysql"
                key="mysql"
                icon="mysql"
                data-testid="service-connect-select-connection-type-mysql"
              >
                MySQL
              </Select.Item>
              <Select.Item
                value="jdbc"
                key="jdbc"
                icon="jdbc"
                data-testid="service-connect-select-connection-type-jdbc"
              >
                JDBC
              </Select.Item>
            </Select.Group>
            <Select.Group heading="Language clients" key="languageClients">
              <Select.Item
                value="python"
                key="python"
                icon="python"
                data-testid="service-connect-select-connection-type-python"
              >
                Python
              </Select.Item>
              <Select.Item
                value="nodejs"
                key="nodejs"
                icon="nodejs"
                data-testid="service-connect-select-connection-type-nodejs"
              >
                Node.js
              </Select.Item>
              <Select.Item
                value="java"
                key="java"
                icon="jdbc"
                data-testid="service-connect-select-connection-type-java"
              >
                Java
              </Select.Item>
              <Select.Item
                value="go"
                key="go"
                icon="golang"
                data-testid="service-connect-select-connection-type-go"
              >
                Go
              </Select.Item>
            </Select.Group>
          </SelectItems>
        </CustomSelect>
      </Container>
      {connectionType === 'https' && (
        <HttpConnectionTab
          username={username}
          credentials={https}
          showPassword={showPassword}
        />
      )}
      {connectionType === 'native' && (
        <NativeConnectionTab
          username={username}
          credentials={nativesecure}
          showPassword={showPassword}
        />
      )}
      {connectionType === 'mysql' && (
        <MySqlConnectionTab
          hostname={nativesecure.hostname}
          credentials={mysqlProps}
        />
      )}
      {connectionType === 'jdbc' && (
        <JdbcConnectionTab
          hostname={https.hostname}
          username={username}
          password={https.password}
          port={https.port}
          showPassword={showPassword}
        />
      )}
      {connectionType === 'python' && (
        <PythonConnectionTab
          hostname={https.hostname}
          username={username}
          password={https.password}
          showPassword={showPassword}
        />
      )}
      {connectionType === 'nodejs' && (
        <NodejsConnectionTab
          hostname={https.hostname}
          username={username}
          password={https.password}
          port={https.port}
          showPassword={showPassword}
        />
      )}
      {connectionType === 'java' && (
        <JavaConnectionTab
          hostname={https.hostname}
          username={username}
          password={https.password}
          port={https.port}
          showPassword={showPassword}
        />
      )}
      {connectionType === 'go' && (
        <GoConnectionTab
          hostname={nativesecure.hostname}
          username={username}
          password={nativesecure.password}
          port={nativesecure.port}
          showPassword={showPassword}
        />
      )}
    </>
  );
}

export default ConnectWithContent;
