import 'src/components/primitives/lib/theme/normalize.css';
import 'src/components/primitives/lib/theme/globalStyles.css';
import breakpoints from 'src/components/primitives/lib/theme/breakpoints';
import { ColorTheme } from 'src/components/primitives/lib/theme/types';
import { CUIThemeType } from '@clickhouse/click-ui';

const lightTheme: ColorTheme = {
  type: 'light',
  colors: {
    y2: '#FFC029',
    g0: '#F9FBFF',
    g1: '#F8F8F8',
    g2: '#F6F7FA',
    g3: '#E6E6E6',
    g4: '#E7E5F5',
    g5: '#A3A3A3',
    g6: '#343333',
    b1: '#F2F7FF',
    b2: '#339DFF',
    white: '#FFFFFF',
    black: '#000000',
    oval: {
      rgb: '255, 244, 219',
      hex: '#FFF4DB'
    },

    c2: '#F6F7FA',
    c3: '#443F51',
    c4: '#6D7386',
    c4_10: 'rgba(16, 24, 40, 0.10)',
    c4_20: 'rgba(16, 24, 40, 0.20)',
    c5: '#2F2C3A',
    chart: {
      values: ['#FFB200', '#3B73DE', '#FD6FBA', '#8E62FF', '#FF9666', '#5DA4FF', '#70D69B', '#FFBD60']
    },
    sidebar_bg: '#373343',
    sidebar_tooltip: '#444444',
    alert: {
      bg: {
        info: '#E6F1FA',
        warning: '#FFEFCA',
        success: '#CBEABC',
        danger: '#FFCACA'
      },
      text: {
        info: '#3B73DE',
        warning: '#C78F0F',
        success: '#407B24',
        danger: '#C70F0F'
      }
    }
  },
  shadows: {
    modal:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.01), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    popover: '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.01)',
    card: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    tooltip: '0px 2px 4px -1px rgba(0, 0, 0, 0.01)'
  },
  sqlEditor: {
    background: '#FFFFFF',
    border: '#F6F7FA',
    foreground: '#6D7386',
    highlightForeground: '#443F51',
    selectedBackground: '#6D7386',
    removedLineBackground: '#FFCACA',
    default: '#3A3739',
    keyword: '#8B4DFF',
    delimiter: '#1C7E92',
    predefined: '#1C7E92',
    number: '#E05600',
    boolean: '#E05600',
    comment: '#696769',
    operator: '#3A3739',
    string: '#0F8A15',
    lineNumber: '#b2b2b2'
  }
};

const darkTheme: ColorTheme = {
  ...lightTheme,
  type: 'dark',
  sqlEditor: {
    background: '#1F1F1C',
    border: '#282828',
    foreground: '#b3b6bd',
    highlightForeground: '#FFFFFF',
    selectedBackground: '#b3b6bd',
    removedLineBackground: '#88AECE',
    default: '#FFFFFF',
    keyword: '#88AECE',
    delimiter: '#f08d49',
    predefined: '#f08d49',
    number: '#f08d49',
    boolean: '#f08d49',
    comment: '#999999',
    operator: '#FFFFFF',
    string: '#b5bd68',
    lineNumber: '#C0C0C0'
  }
};

declare module '@emotion/react' {
  export interface Theme extends ColorTheme, CUIThemeType {}
}

export default {
  breakpoints: breakpoints(),
  lightTheme,
  darkTheme
};
