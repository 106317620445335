import { Row } from 'shared/src/clickhouse';
import { ClickPipe } from 'types/protocol';

export class DbMetrics {
  readonly cache: Map<string, string | null> = new Map();

  constructor(readonly rows: Row[]) {
    for (const [database, table, totalRows, totalBytes] of rows) {
      this.cache.set(`${database}:${table}:records`, totalRows);
      this.cache.set(`${database}:${table}:size`, totalBytes);
    }
  }

  getRecordCount(pipe: ClickPipe): string | null {
    return this.cache.get(`${pipe.destination.database}:${pipe.destination.table}:records`) ?? null;
  }

  getTableSize(pipe: ClickPipe): string | null {
    return this.cache.get(`${pipe.destination.database}:${pipe.destination.table}:size`) ?? null;
  }
}
