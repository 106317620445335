import { Container, Icon } from '@clickhouse/click-ui';
import { Resizable } from 're-resizable';
import styled from 'styled-components';

export const AddableObject = styled(Container)`
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    background: ${({ theme }): string => theme.global.color.background.muted};
  }
`;

export const BorderBottomContainer = styled(Container)`
  border-bottom: 1px solid ${({ theme }): string => theme.global.color.stroke.default};
`;

export const BorderedResizable = styled(Resizable)`
  border-right: 1px solid ${({ theme }): string => theme.global.color.stroke.default};
`;

export const DashboardElementId = styled.div`
  position: absolute;
  top: -18px;
  left: 0px;
  padding-bottom: 4px;

  color: ${({ theme }): string => theme.global.color.accent.default};
`;

export const DashboardElementContainer = styled.div<{ selected: boolean }>`
  position: relative;

  border-radius: ${({ theme }): string => theme.border.radii[1]};
  & > .dragHandle,
  & > ${DashboardElementId} {
    display: none;
  }

  ${({ selected, theme }): string =>
    selected
      ? `
      border: 1px solid ${theme.global.color.accent.default};

      & > .dragHandle,
      & > ${DashboardElementId} {
        display: block;
      }
  `
      : ''}

  &:hover {
    & > .dragHandle,
    & > ${DashboardElementId} {
      display: block;
    }
  }
`;

export const DragHandleIcon = styled(Icon)`
  position: absolute;
  top: 0px;
  left: -18px;

  cursor: grab;

  & > svg > circle {
    fill: ${({ theme }) => theme.global.color.accent.default} !important;
    stroke: ${({ theme }) => theme.global.color.accent.default} !important;
  }

  &:active {
    cursor: grabbing;
  }
`;
