import {
  Button,
  Container,
  ConfirmationDialog,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import {
  Section,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';

export const LeaveOrganizationSection = (props: {
  orgName: string;
  onLeave: () => void;
}): ReactElement => {
  const LeaveOrganizationModal = ConfirmationDialog;
  const [leaveOrganizationDialogOpen, setLeaveOrganizationDialogOpen] =
    useState(false);

  return (
    <Section padding="lg" data-testid="leave-org-section">
      <Subsection>
        <SubsectionDescription>
          <SubsectionTitle>Leave organization</SubsectionTitle>
          <Spacer />
        </SubsectionDescription>
      </Subsection>
      <Subsection>
        <SubsectionDescription>
          <Text color="muted">
            If you leave this organization you will have to contact your
            administrator to regain access.
          </Text>
        </SubsectionDescription>
        <SubsectionControls>
          <Container alignItems="start">
            <Button
              label="Leave this organization"
              type="danger"
              data-testid="leave-organization-button"
              onClick={(): void => setLeaveOrganizationDialogOpen(true)}
            />
          </Container>
        </SubsectionControls>
      </Subsection>

      <LeaveOrganizationModal
        open={leaveOrganizationDialogOpen}
        title="Leave organization"
        message={`Are you sure you want to leave '${props.orgName}'?`}
        primaryActionLabel="Leave organization"
        primaryActionType="danger"
        onConfirm={props.onLeave}
        onCancel={(): void => setLeaveOrganizationDialogOpen(false)}
        data-testid="leave-org-modal"
      />
    </Section>
  );
};
