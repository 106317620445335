import { Config } from 'src/lib/config';
import environment from 'src/lib/config/environment';

export default (config: Config): Config => {
  config.env = 'local';

  if (environment.CLIENT_MOCKED) {
    config.controlPlane.mocked = true;
  } else {
    config.controlPlane.clientId = 'dejwvmtw9u65l6b4hvlmpy8kf';
    config.controlPlane.host = 'http://localhost:4200';
    config.controlPlane.apiHost = 'http://localhost:3000';
    config.controlPlane.redirectUri = 'http://localhost:8910/callback';
  }

  config.superUsers.push('test-user@clickhouse-local.com');

  config.features.passwordlessAuth = true;
  config.features.dataLoading = true;
  config.features.clickPipesPeerDBTile = true;
  config.features.clickPipesEnableAvroFlatten = true;

  config.querying = {
    ignoreAnalyticsQueryInLog: true,
    useClustering: false
  };

  config.auth0Web = {
    domain: 'auth.control-plane-local.clickhouse-dev.com',
    clientId: 'TUBu1o7uzBmaQKgSLuxyRoRhxppX2GKX',
    audience: 'control-plane-web',
    callbackPath: '/',
    auth0domain: 'ch-local-dev.us.auth0.com',
    databaseConnectionName: 'ControlPlaneUserDb'
  };

  config.websockets = {
    endpoint: 'ws://localhost:3001'
  };

  config.datadog = undefined;
  config.growthBook = {
    clientKey: 'sdk-Y4XqZEwK3Wz208Cp'
  };

  // uncomment to enable local datadog
  // config.datadog = {
  //   rum: {
  //     applicationId: 'efb90891-235f-4961-9c9d-d4fd748dccf0',
  //     clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
  //     site: 'us3.datadoghq.com',
  //     service: 'console',
  //     env: 'local',
  //     version: window._env.version,
  //     sampleRate: 100,
  //     sessionReplaySampleRate: 0,
  //     trackInteractions: true,
  //     trackResources: true,
  //     trackLongTasks: true,
  //     defaultPrivacyLevel: 'mask-user-input',
  //     startSessionReplayRecording: true,
  //   },
  //   logs: {
  //     version: window._env.version,
  //     clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
  //     site: 'us3.datadoghq.com',
  //     service: 'console',
  //     env: 'local',
  //     forwardErrorsToLogs: true,
  //     sessionSampleRate: 100,
  //     telemetrySampleRate: 0,
  //     forwardConsoleLogs: ['error'],
  //   }
  // };
  config.fullStory = {
    orgId: 'o-1GR1DR-na1'
  };

  config.stripe = {
    publishableKey:
      'pk_test_51LJ0trEqDIZfA797YyebhaMBghaLRMv4uhRhh1dLJYOFcthvbW7vPt8q7f3EeVehgNwkC5wvz2TAlSJp12kg0K9400zSHN7rLT'
  };

  config.recaptcha = {
    siteKey: '6Lf3eY4hAAAAADHuCPaw1RzIqbyTLm_b5fqWna3B'
  };
  return config;
};
