import { Organization, OrganizationUser } from '@cp/common/protocol/Organization';
import { v4 as uuidv4 } from 'uuid';

export const createTestOrganizationUser = ({
  joinedAt = new Date().getTime(),
  userId = 'user-id',
  name = 'TestU',
  role = 'ADMIN',
  email = 'test@example.com',
  identityProviders,
  roleIds = []
}: Partial<OrganizationUser> = {}): OrganizationUser => ({
  joinedAt,
  userId,
  name,
  role,
  email,
  identityProviders,
  roleIds
});

export const createTestOrganizationObject = ({
  name = 'testOrganization',
  id = uuidv4(),
  createdAt = Date.now(),
  updatedAt = Date.now(),
  users = {},
  invitations = {},
  billingStatus = 'IN_TRIAL',
  restrictions = {
    maxInstanceCount: 1,
    canCreateInstances: true,
    canStartInstances: true,
    canInviteMembers: true,
    canStartTrial: true,
    maxCasePriority: 'SEV_1'
  },
  paymentDetails = {
    currency: 'USD',
    addressCaptured: true,
    paymentMethodCaptured: true
  },
  paymentState = 'STRIPE',
  firmographics = {},
  cachedCommitmentState = {},
  features = [],
  regionsWhiteList = '',
  tackleState = undefined,
  privateEndpoints = [],
  supportOnly = false
}: Partial<Organization> = {}): Organization => ({
  name,
  id,
  createdAt,
  updatedAt,
  users,
  invitations,
  billingStatus,
  restrictions,
  paymentDetails,
  paymentState,
  firmographics,
  cachedCommitmentState,
  features,
  regionsWhiteList,
  tackleState,
  privateEndpoints,
  supportOnly
});

export const getOrganizationAdmins = (organization: Organization): Array<OrganizationUser> => {
  return filterOrganizationAdmins(organization.users);
};

const filterOrganizationAdmins = (organizationUsers: Record<string, OrganizationUser>): Array<OrganizationUser> => {
  return Object.values(organizationUsers).filter((u) => u.role === 'ADMIN');
};

// todo address in the future. Why only non-admins can leave the orgs? BE allows developers to leave the orgs as well.
export const canLeaveOrganization = (userId: string, organization: Organization): boolean => {
  const admins = getOrganizationAdmins(organization);
  return admins.some((u) => u.userId !== userId);
};
