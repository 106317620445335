import React, {
  Dispatch,
  forwardRef,
  ReactNode,
  SetStateAction,
  useMemo,
  useRef
} from 'react';

import { createToast } from 'src/components/primitives';
import { mergeRef } from 'src/components/primitives/lib/common';
import { errorMessage } from 'src/lib/errors/errorMessage';

import {
  contentInputStyle,
  contentTextStyle
} from 'src/components/QueryView/QueryList/Folders/Tree/styles';

interface Props {
  children?: ReactNode;
  onRename: (title: string) => void;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  currentTitle: string;
}

const NodeName = forwardRef<HTMLInputElement, Props>(
  (
    {
      children,
      onRename,
      editable,
      setEditable,
      title,
      setTitle,
      currentTitle
    },
    ref
  ) => {
    const titleRef = useRef<HTMLInputElement>(null);
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && titleRef?.current) {
        e.preventDefault();
        e.stopPropagation();
        titleRef.current.blur();
      }
    };

    const updateTitle = async () => {
      if (editable) {
        setEditable(false);
        if (title && title.trim().length > 0) {
          try {
            await onRename(title);
          } catch (e) {
            createToast('Error', 'alert', errorMessage(e));
          }
        } else {
          setTitle(currentTitle);
        }
      }
    };

    const mergedReference = useMemo(
      () => mergeRef([ref, titleRef]),
      [titleRef, ref]
    );
    return (
      <div css={contentTextStyle}>
        <input
          aria-label={`savedQuery:${title}`}
          css={contentInputStyle}
          readOnly={!editable}
          ref={mergedReference}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={updateTitle}
          onKeyDown={onKeyDown}
          spellCheck={false}
          type="text"
          value={title}
          className="fs-exclude"
        />
        {children}
      </div>
    );
  }
);

export default NodeName;
