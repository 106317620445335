import { ReactElement } from 'react';
import { DashboardObject } from 'shared/src/types/dashboard';
import BigStatElement from 'src/components/Dashboards/DashboardView/DashboardElement/BigStatElement';
import ChartElement from 'src/components/Dashboards/DashboardView/DashboardElement/ChartElement';
import TableElement from 'src/components/Dashboards/DashboardView/DashboardElement/TableElement';

interface DashboardElementProps extends DashboardObject {
  dashboardId: string;
}

export default function DashboardElement({
  config,
  dashboardId,
  id
}: DashboardElementProps): ReactElement {
  let element = null;
  switch (config.type) {
    case 'bigStat': {
      element = (
        <BigStatElement
          config={config}
          dashboardId={dashboardId}
          objectId={id}
        />
      );
      break;
    }
    case 'chart': {
      element = (
        <ChartElement config={config} dashboardId={dashboardId} objectId={id} />
      );
      break;
    }
    case 'table': {
      element = (
        <TableElement config={config} dashboardId={dashboardId} objectId={id} />
      );
      break;
    }
  }

  return element;
}
