import { Container, Icon, Spacer, Text, Title } from '@clickhouse/click-ui';
import { HTMLAttributes, ReactElement } from 'react';
import ChatWithUsButton from 'src/components/SupportList/ChatWithUsButton';
import NewCaseButton from 'src/components/SupportList/NewCaseButton';
import styled from 'styled-components';

const SupportCaseEmptyWrapper = styled(Container)`
  margin: auto;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoint.sizes.md}) {
      max-width: 84%;
    }
  `}
`;
function SupportCaseEmpty(props: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <>
      <Spacer size="xxl" />
      <SupportCaseEmptyWrapper
        padding="none"
        alignItems="center"
        justifyContent="center"
        orientation="vertical"
        grow="1"
        gap="xs"
        maxWidth="40%"
        {...props}
      >
        <Icon name="line-in-circle" size="xxl" />
        <Title type="h2" size="lg">
          No support cases
        </Title>
        <Text align="center">
          If you are experiencing issues and were unable to find answers in our
          documentation, open a case and our support staff will be happy to
          help.
        </Text>

        <Container
          padding="xs"
          justifyContent="center"
          alignItems="center"
          gap="md"
        >
          <ChatWithUsButton />
          <NewCaseButton />
        </Container>
      </SupportCaseEmptyWrapper>
    </>
  );
}

export default SupportCaseEmpty;
