import { Badge, BadgeState } from '@clickhouse/click-ui';
import { NotificationName } from '@cp/common/protocol/NotificationInventory';
import { getNotificationType } from '@cp/common/utils/NotificationsUtils';
import { memo } from 'react';

export const NotificationSeverityBadge = memo(
  ({ notificationName }: { notificationName: NotificationName }) => {
    const { severity: notificationSeverity } = getNotificationType(
      notificationName
    ) || {
      severity: 'Unknown'
    };

    let state: BadgeState = 'default';
    let severityText;
    if (notificationSeverity === 'warning') {
      state = 'warning';
      severityText = 'Warning';
    }
    if (notificationSeverity === 'critical') {
      state = 'danger';
      severityText = 'Critical';
    }
    if (notificationSeverity === 'info') {
      state = 'info';
      severityText = 'Info';
    }

    if (!severityText) {
      console.error('Unknown notification category or severity');
      severityText = 'Unknown';
    }

    return <Badge size="md" state={state} text={severityText} />;
  }
);
