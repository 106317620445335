import { OrganizationDetailsToUpdate } from '@cp/common/protocol/Billing';
import { assertTruthy } from '@cp/common/utils/Assert';
import { useCallback } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useGetPendingUserActions } from 'src/lib/pendingUserActions/useGetPendingUserActions';
import { isTackleSubscriptionToken } from 'src/lib/tackle/tackle';

export interface HandleTackleSubscription {
  handleTackleSubscription: (organizationDetails: OrganizationDetailsToUpdate) => Promise<string | undefined>;
}

export const useHandleTackleSubscription = (): HandleTackleSubscription => {
  const apiClient = useApiClient();
  const pendingUserActions = useGetPendingUserActions();
  const action = pendingUserActions.find((action) => action.type === 'tackle-subscription');

  /**
   * Send `handleTackleSubscription` RPC request to the /billing endpoint with the given
   * organization details and the tackle token from the pending action.
   * @returns the organization id linked to this subscription, if the tackle subscription was successful, otherwise undefined
   */
  const handleTackleSubscription = useCallback(
    async (organizationDetails: OrganizationDetailsToUpdate): Promise<string | undefined> => {
      assertTruthy(
        action && action.data,
        "Can't handle tackle subscription - Tackle pending action must exist and have data field"
      );
      const tackleToken = JSON.parse(action.data) as unknown;
      if (!isTackleSubscriptionToken(tackleToken)) {
        return undefined;
      }
      const organization = await apiClient.billing.handleTackleSubscription(tackleToken, organizationDetails);
      return organization?.id;
    },
    [action, apiClient.billing]
  );

  return { handleTackleSubscription };
};
