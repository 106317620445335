import { Link, Spacer, Text } from '@clickhouse/click-ui';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';
import {
  PASSWORD_MASK,
  getPasswordSubstitution
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import { ReactElement } from 'react';
import LanguageClientExtras from 'src/components/ServiceConnect/Tabs/LanguageClientExtras';

const getJavaClientCode = (
  hostname: string,
  username: string,
  password: string,
  port: string
): string => {
  return `package org.example;

import com.clickhouse.client.*;
import java.util.HashMap;

public class Main {
    public static void main(String[] args) throws Exception {
        HashMap<String, String> options = new HashMap<>();
        options.put("user", "${username}");
        options.put("password", "${getPasswordSubstitution(password)}");

        String url = "https://${hostname}:${port}";
        ClickHouseNode server = ClickHouseNode.of(url, options);
        ClickHouseClient client = ClickHouseClient.newInstance(ClickHouseProtocol.HTTP);
        ClickHouseRequest<?> request = client.read(server);
        ClickHouseResponse response = request.query("SELECT 1").execute().get();
        System.out.printf("Result: %d", response.firstRecord().getValue(0).asInteger());
    }
}`;
};

interface JavaConnectionTabProps {
  hostname: string;
  username: string;
  password: string;
  port: string;
  showPassword?: boolean;
}

const JavaConnectionTab = ({
  hostname,
  username,
  password,
  port,
  showPassword
}: JavaConnectionTabProps): ReactElement => {
  const unmasked = getJavaClientCode(hostname, username, password, port);
  const masked = password
    ? getJavaClientCode(hostname, username, PASSWORD_MASK, port)
    : undefined;
  return (
    <>
      <h3>Installation</h3>
      <Text>
        Visit the{' '}
        <Link
          href="https://mvnrepository.com/artifact/com.clickhouse/clickhouse-jdbc/latest"
          target="_blank"
          icon="share-arrow"
        >
          Maven repository page
        </Link>
        for all available installation options.
      </Text>
      <h3>Usage</h3>
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        data-testid="service-connect-code-block-java-usage"
        language="java"
        showPassword={showPassword}
      />
      <Spacer />
      <LanguageClientExtras
        title="Java Client"
        docsLink="https://clickhouse.com/docs/en/integrations/java"
        examplesLink="https://github.com/ClickHouse/clickhouse-java/tree/main/examples"
      />
    </>
  );
};

export default JavaConnectionTab;
