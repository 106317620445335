import { useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { getCurrentServiceId } from 'src/state/service';
import { useApiClient } from 'src/lib/controlPlane/client';
import { createToast } from '@clickhouse/click-ui';
import { errorMessage } from 'api/src/lib/errorHandling';

type LoadState = 'loading' | 'loaded' | 'error';

export type GptConsentResult = {
  hasConsent: boolean;
  acceptConsent: (accepted: boolean) => Promise<void>;
  loadState: LoadState;
};

export const hasConsentAtom = atom<boolean>(false);
export const loadStateAtom = atom<LoadState>('loading');

export const useGptConsent = (): GptConsentResult => {
  const api = useApiClient();
  const serviceId = getCurrentServiceId() || '';
  const [hasConsent, setHasConsent] = useAtom(hasConsentAtom);
  const [loadState, setLoadState] = useAtom(loadStateAtom);

  useEffect(() => {
    const updateConsent = async (): Promise<void> => {
      setHasConsent(false);
      setLoadState('loading');

      try {
        const consent = await api.getGptUsageConsent(serviceId);
        setHasConsent(consent);
        setLoadState('loaded');
      } catch (e) {
        setLoadState('error');
      }
    };
    updateConsent().catch(console.error);
  }, []);

  async function acceptConsent(accepted: boolean): Promise<void> {
    try {
      await api.setGptUsageConsent(serviceId, accepted);
      setHasConsent(accepted);
    } catch (e) {
      createToast({
        description: errorMessage(e),
        title: 'Cannot change GPT consent status',
        type: 'danger'
      });
      console.error(e);
    }
  }

  return { hasConsent, acceptConsent, loadState };
};
