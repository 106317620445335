import { ReactElement } from 'react';
import {
  Alert,
  Container,
  ContainerProps,
  Link,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { InviteMemberElement } from 'src/components/InviteMembersForm/InviteMemberElement';
import {
  InvitationRecord,
  onEmailBlurProp
} from 'src/inviteMembers/controller/useInviteMembers';

type OnChangeProps = Partial<InvitationRecord> & { index: number };
export interface InviteMembersFormProps extends ContainerProps {
  members: Array<InvitationRecord>;
  description: string;
  canInviteMembers: boolean;
  error?: string;
  onChange: (props: OnChangeProps) => void;
  onEmailBlur: (props: onEmailBlurProp) => void;
  onEmailFocus: (props: { index: number }) => void;
  onDelete?: (props: { index: number }) => void;
}

export const InviteMembersForm = ({
  members = [],
  description,
  canInviteMembers,
  error,
  onChange: onChangeProp,
  onEmailFocus,
  onEmailBlur,
  onDelete,
  ...props
}: InviteMembersFormProps): ReactElement => {
  const onChange = ({ email, role, index }: OnChangeProps): void => {
    onChangeProp({
      email,
      role,
      index
    });
  };

  return (
    <>
      <Container orientation="vertical" gap="md" fillWidth={false} {...props}>
        <Text color="muted">{description}</Text>
        <Spacer size="xs" />
        {members.map((member, idx) => {
          return (
            <InviteMemberElement
              key={`invite-members-${idx}`}
              email={member.email}
              role={member.role}
              index={idx}
              onChange={onChange}
              error={member?.error}
              onEmailBlur={onEmailBlur}
              onEmailFocus={onEmailFocus}
              onDelete={members.length === 1 ? undefined : onDelete}
            />
          );
        })}
      </Container>
      <Spacer size="lg" />
      <Alert
        type="default"
        state="info"
        size="medium"
        text={
          <>
            Admins and developers are different than database users. To create
            database users and roles, please use the SQL console. To learn more,
            visit our docs on{' '}
            <Link
              href="https://clickhouse.com/docs/en/cloud/users-and-roles"
              target="_blank"
              size="sm"
            >
              Users and Roles
            </Link>
            .
          </>
        }
      />
      {error && (
        <>
          <Spacer />
          <Alert type="default" state="danger" text={error} />
        </>
      )}

      {!canInviteMembers && (
        <>
          <Spacer />
          <Alert
            type="default"
            state="warning"
            text="Organization can't accept new members"
          />
        </>
      )}
    </>
  );
};
