import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSqlQuery } from 'src/lib/clickhouse/query';
import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { QueryMetrics } from 'src/lib/query/streamingQuery';

const kilobyte = 1000;
const megabyte = kilobyte * kilobyte;
const gigabyte = kilobyte * kilobyte * kilobyte;

const millisecond = 1000;

const round = (n: number, roundOfTo = 2) => {
  return n.toFixed(roundOfTo);
};

export function formatMetrics(metrics: QueryMetrics | undefined) {
  if (!metrics) {
    return '';
  }

  let size: string;
  if (metrics.bytes > gigabyte) {
    size = `${round(metrics.bytes / gigabyte)}GB`;
  } else if (metrics.bytes > megabyte) {
    size = `${round(metrics.bytes / megabyte)}MB`;
  } else {
    size = `${round(metrics.bytes / kilobyte)}KB`;
  }

  return `${round(metrics.durationMS)}ms, read ${metrics.rows} rows, ${size}`;
}

export function formatMsToSec(durarionMs: number) {
  if (durarionMs < 1) {
    return round(durarionMs / millisecond, 4);
  }

  return round(durarionMs / millisecond, 3);
}

export function formatBytes(bytes?: number) {
  if (typeof bytes !== 'number') {
    return '';
  }
  if (bytes > gigabyte) {
    return `${round(bytes / gigabyte)} GB`;
  }

  if (bytes > megabyte) {
    return `${round(bytes / megabyte)} MB`;
  }

  return `${round(bytes / kilobyte)} KB`;
}

const maxTriesAfterFinish = 10;
const retryIntervalMS = 1000;

export function useMetricsQuery(runId: string | undefined, status: RunningQueryStatusName): QueryMetrics | null {
  const [reload, { data }] = useSqlQuery(`
    SELECT result_rows, result_bytes, query_duration_ms
    FROM clusterAllReplicas('default', 'system.query_log')
    WHERE query_id = '${runId}'
      AND type = 'QueryFinish'
`);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const retriesAfterFinishRef = useRef(0);

  const retry = useCallback(async () => {
    await reload();
    setTimeoutId(undefined);
  }, [reload, runId]);

  useEffect(() => {
    if (data && data.rows.length < 1 && timeoutId === undefined) {
      // data loaded and query didn't return anything, schedule a retry
      if (status !== 'finished') {
        setTimeoutId(setTimeout(retry, retryIntervalMS));
      } else if (retriesAfterFinishRef.current < maxTriesAfterFinish) {
        ++retriesAfterFinishRef.current;
        setTimeoutId(setTimeout(retry, retryIntervalMS));
      }
    }
  }, [data, retry, status, timeoutId]);

  useEffect(() => {
    retriesAfterFinishRef.current = 0;
  }, [runId]);

  return useMemo(() => {
    if (!data || data.rows.length === 0) {
      return null;
    } else {
      const row = data.rows[0];
      return {
        rows: Number(row[0] ?? '0'),
        bytes: Number(row[1] ?? '0'),
        durationMS: Number(row[2] ?? '0')
      };
    }
  }, [data]);
}
