import { Container, Label, Select } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { dashboardObjectConfigSchema } from 'shared/src/dashboard';
import {
  DashboardConfig,
  DashboardConfigField
} from 'shared/src/types/dashboard';

interface ObjectLinkControlsProps<T> {
  config: DashboardConfig;
  editingObjectId: string;
  onConfirm: (changes?: DashboardConfigField<T>) => void;
  value?: DashboardConfigField<T>;
}

export default function ObjectLinkControls<T>({
  config,
  editingObjectId,
  onConfirm,
  value
}: ObjectLinkControlsProps<T>): ReactElement {
  const referencedElement =
    value?.type === 'reference'
      ? config.dashboardObjects[value.objectId || '']
      : null;
  const linkableFields = Object.keys(
    referencedElement
      ? dashboardObjectConfigSchema[referencedElement.config.type]
          .linkableFields
      : {}
  );

  const validObjects = Object.values(config.dashboardObjects).filter(
    ({ id }) => id !== editingObjectId
  );

  const onObjectIdSelect = (objectId: string): void => {
    onConfirm({
      type: 'reference',
      objectId,
      field: ''
    });
  };

  const onFieldSelect = (field: string): void => {
    onConfirm({
      type: 'reference',
      objectId: value?.type === 'reference' ? value.objectId : '',
      field
    });
  };

  return (
    <Container gap="md" orientation="horizontal" minWidth="160px">
      <Container gap="sm" orientation="vertical">
        <Label>Object ID</Label>
        <Select
          onSelect={onObjectIdSelect}
          placeholder="Object id"
          value={value?.type === 'reference' ? value.objectId : ''}
        >
          {validObjects.map((objectConfig) => (
            <Select.Item key={objectConfig.id} value={objectConfig.id}>
              {objectConfig.config.elementId}
            </Select.Item>
          ))}
        </Select>
      </Container>
      <Container gap="sm" orientation="vertical">
        <Label>Field to Link</Label>
        <Select
          disabled={!referencedElement}
          onSelect={onFieldSelect}
          placeholder="Field to link"
          value={value?.type === 'reference' ? value.field : ''}
        >
          {linkableFields.map((field) => (
            <Select.Item key={field} value={field}>
              {field}
            </Select.Item>
          ))}
        </Select>
      </Container>
    </Container>
  );
}
