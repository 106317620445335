import {
  Container,
  Dialog,
  Link,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';

interface TrialExpiredModalProps {
  hasManageBillingPermission: boolean;
  open: boolean;
  onCancel: () => void;
  onEnterPaymentDetails: () => void;
}

function TrialExpiredModal({
  hasManageBillingPermission,
  open,
  onCancel,
  onEnterPaymentDetails
}: TrialExpiredModalProps): ReactElement | null {
  if (open !== true) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(open: boolean): void => {
        !open && onCancel && onCancel();
      }}
    >
      <Dialog.Content
        data-testid="trial-expired-dialog"
        title="Your trial has expired"
        showClose={true}
      >
        <Text data-testid="trial-expired-dialog-p1">
          We hope you enjoyed your free trial.
          {hasManageBillingPermission
            ? ' Enter your payment details '
            : ' Contact your system admin '}
          to continue using ClickHouse Cloud.
        </Text>
        <Spacer size="md" />
        <Text color="muted" data-testid="trial-expired-dialog-p2">
          If you need more time to complete your evaluation, or would like to
          discuss any other matter, please contact our{' '}
          <Link href="mailto:sales@clickhouse.com">sales team</Link>.
        </Text>

        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close
            label={'Close'}
            type={hasManageBillingPermission ? undefined : 'primary'}
            data-testid="close-trial-expired-button"
          />
          {hasManageBillingPermission && (
            <Dialog.Close
              type="primary"
              label="Enter payment details"
              onClick={onEnterPaymentDetails}
              data-testid="enter-payment-button"
            />
          )}
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

export default TrialExpiredModal;
