import { PropsWithChildren, ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import SQLConsoleInitializerProvider from 'src/lib/SQLConsoleInitializerProvider';

export const QueryInsightsLayout = ({
  children
}: PropsWithChildren): ReactElement => {
  const OutletOrChildren = (): ReactElement => {
    if (children) {
      return <>{children}</>;
    }

    return <Outlet />;
  };

  return (
    <SQLConsoleInitializerProvider>
      <OutletOrChildren />
    </SQLConsoleInitializerProvider>
  );
};
