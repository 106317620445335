import { QuestionAnswerType } from '@cp/common/protocol/Account';

export interface ReasonType {
  label: string;
  checked: boolean;
  /** If true, should show an input field when this option is selected. */
  hasInput?: boolean;
  /** The value of the input field. */
  value?: string;
}

export type ReasonListType = Array<ReasonType>;

export type OtherReasonType = { value: string; checked: boolean };

/** Converts a list of ReasonType to a semicolon separated string - a concatenation of all the checked labels. */
export const checkedReasonsToString = (list: ReasonListType): string => {
  return list
    .flatMap((reason) => {
      if (reason.checked) {
        return reason.value ? `${reason.label} - ${reason.value}` : reason.label;
      }
      return [];
    })
    .join('; ');
};

export const wrapQuestionAnswers = (
  question: string,
  checkedReasonList: ReasonListType,
  other: OtherReasonType
): QuestionAnswerType => {
  let reasonListAnswers = checkedReasonsToString(checkedReasonList);
  if (other.value) {
    reasonListAnswers = reasonListAnswers ? `${reasonListAnswers}; Other - ${other.value}` : `Other - ${other.value}`;
  }
  return {
    question,
    answer: reasonListAnswers
  };
};
