import { Button, Container, Flyout, Spacer, Title } from '@clickhouse/click-ui';
import {
  NotificationName,
  NotificationType
} from '@cp/common/protocol/NotificationInventory';
import { NotificationChannelSetting } from '@cp/common/protocol/Notifications';
import { memo, ReactElement } from 'react';
import { NotificationSeverityBadge } from 'src/pages/NotificationsPage/NotificationSeverityBadge';
import { ChannelSetting } from 'src/pages/NotificationsPage/Settings/ChannelSetting';

type SettingsFlyoutProps = {
  isFlyoutOpen: boolean;
  selectedNotificationType?: NotificationType;
  onClose: () => void;
  selectedNotificationName?: NotificationName;
  setting?: NotificationChannelSetting;
  onSaveSettings: (notificationName: NotificationName) => Promise<void>;
  onSettingChange: (
    notificationName: NotificationName,
    setting: NotificationChannelSetting
  ) => void;
  isLoading: boolean;
};

export const SettingsFlyout = memo(
  ({
    isFlyoutOpen,
    onClose,
    setting,
    selectedNotificationType,
    selectedNotificationName,
    onSaveSettings,
    onSettingChange,
    isLoading
  }: SettingsFlyoutProps): ReactElement => {
    const settingTitle =
      selectedNotificationType?.category === 'service'
        ? 'Notification channels per service'
        : 'Notification channels';
    return (
      <Flyout modal={false} open={isFlyoutOpen} onOpenChange={onClose}>
        <Flyout.Content strategy="fixed" size="wide" align="end">
          {selectedNotificationType && selectedNotificationName && (
            <>
              <Flyout.Header
                showClose
                description={selectedNotificationType.description}
                title={selectedNotificationType.title}
              />
              <Flyout.Body>
                <Spacer size="md" />
                <Flyout.Element>
                  <Container gap="sm" orientation="vertical">
                    <Title type="h3">Severity</Title>
                    <Container>
                      {selectedNotificationName && (
                        <NotificationSeverityBadge
                          notificationName={selectedNotificationName}
                        />
                      )}
                    </Container>
                  </Container>
                  <Spacer size="sm" />

                  <Container gap="sm" orientation="vertical">
                    <Title type="h3">{settingTitle}</Title>
                    <Container>
                      {setting && (
                        <ChannelSetting
                          onChange={onSettingChange}
                          notificationName={selectedNotificationName}
                          setting={setting}
                        />
                      )}
                    </Container>
                  </Container>
                </Flyout.Element>
              </Flyout.Body>
              <Flyout.Footer>
                <Container justifyContent="end" gap="sm">
                  <Button type="secondary" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    loading={isLoading}
                    onClick={() => {
                      void onSaveSettings(selectedNotificationName);
                    }}
                    type="primary"
                  >
                    Save
                  </Button>
                </Container>
              </Flyout.Footer>
            </>
          )}
        </Flyout.Content>
      </Flyout>
    );
  }
);
