import React, { ReactElement } from 'react';
import { ResultsDisplayType } from 'src/state/tabs/types';
import { ButtonGroup, ButtonGroupProps } from '@clickhouse/click-ui';

interface DisplaySelectorProps {
  resultsDisplayType: ResultsDisplayType;
  setDisplayType: React.Dispatch<ResultsDisplayType>;
  log: (type: string) => void;
  isContentEmpty: boolean;
  hasRawData: boolean;
}

const rawDataOption = {
  label: 'Raw',
  value: 'raw',
  'data-testid': 'queryViewRawBtn'
};

const apiOption = {
  label: 'API',
  value: 'api',
  'data-testid': 'queryViewApiBtn'
};

const tableOption = {
  label: 'Table',
  value: 'table',
  'data-testid': 'queryViewTableBtn'
};

const chartOption = (disabled: boolean) => ({
  label: 'Chart',
  value: 'chart',
  'data-testid': 'queryViewChartBtn',
  disabled
});

const DisplaySelector = ({
  resultsDisplayType,
  setDisplayType,
  log,
  isContentEmpty,
  hasRawData
}: DisplaySelectorProps): ReactElement => {
  // Displayed different tabs based on whether the query results have been returned in raw format.
  let options: ButtonGroupProps['options'];

  if (hasRawData) {
    options = [rawDataOption];
  } else {
    options = [
      tableOption,
      chartOption(resultsDisplayType === 'table' && isContentEmpty)
    ];
  }
  const onChange = (value: string): void => {
    if (value && resultsDisplayType !== value) {
      setDisplayType(value as ResultsDisplayType);
      log(`${value[0].toUpperCase()}${value.slice(1)}`);
    }
  };
  return (
    <ButtonGroup
      options={options}
      onClick={onChange}
      selected={resultsDisplayType as string}
    />
  );
};

const MemoizedDisplaySelector = React.memo(DisplaySelector);
MemoizedDisplaySelector.displayName = 'DisplaySelector';
export default MemoizedDisplaySelector;
