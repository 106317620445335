import { Galaxy } from 'galaxy';

import config from 'src/lib/config';

export type InteractionType = 'trigger' | 'click' | 'rightclick' | 'doubleclick' | 'shortcut' | 'keypress';

type OptionsType = {
  view: 'app' | 'navbar' | 'sidebar' | 'tabbar' | 'tableView' | 'queryView';
  component: string;
  event: string;
  interaction: InteractionType;
  previousId?: string;
};

export type LogFn = (event: string, interaction?: InteractionType) => void;

export const logger = {
  track: (props: OptionsType): void => {
    if (process.env.NODE_ENV === 'test' || config.env === 'test') {
      return;
    }

    Galaxy.galaxy().track(`${props.view}.${props.component}.${props.event}`, {
      interaction: props.interaction
    });
  }
};
