import { Draft } from 'immer';
import { css } from '@emotion/react';
import {
  ClickPipeImport,
  PostgresAlert,
  PostgresImport,
  PostgresImportData
} from 'shared/src/dataLoading';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import {
  Container,
  Link,
  NumberField,
  Panel,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import HighlightedIcon from 'src/components/ImportView/ClickPipesImportForm/DetailsAndSettings/components/HighlightedIcon';

export default function PostgresAlertConfiguration(): JSX.Element | null {
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipeImport>();

  const data = importModel.data as PostgresImportData;

  const panelStyle = css({
    flexWrap: 'nowrap',
    alignItems: 'start'
  });

  type FieldKey = keyof PostgresAlert;

  const setAlertSetting = (field: FieldKey, value: number): void => {
    updateImportModel<Draft<PostgresImport>>((model) => {
      const auth = model.data.alert as PostgresAlert;
      model.data.alert = { ...auth, [field]: value };
    });
  };

  const pgDocsLink =
    'https://clickhouse.com/docs/en/integrations/clickpipes/postgres';

  return (
    <Panel fillWidth hasBorder orientation="horizontal" css={panelStyle}>
      <HighlightedIcon
        name="bell"
        width="auto"
        height="3em"
        data-testid="lock-icon"
      />

      <Container orientation="vertical" fillWidth={true} gap="md">
        <Title type="h2">Configure Alerts</Title>

        <Text color="muted">
          ClickPipes can be configured to automatically send alerts via email
          for issues such as Slot Lag growth and Open Connections.{' '}
          <Link href={pgDocsLink} target="_blank" rel="noreferrer">
            Learn More
          </Link>
        </Text>

        <Container orientation="vertical">
          <NumberField
            loading={false}
            label="Slot lag alert threshold (in GB)"
            name="clickpipes-postgres-alert-slot-growth"
            value={data.alert?.slotLagGrowth ?? 10}
            onChange={(value) =>
              setAlertSetting('slotLagGrowth', Number(value))
            }
            data-testid="clickpipes-postgres-alert-slot-growth"
            autoComplete="off"
          />

          <Spacer />

          <NumberField
            loading={false}
            label="Open connections alert threshold"
            name="clickpipes-postgres-alert-open-connections"
            value={data.alert?.openConnections ?? 8}
            onChange={(value) =>
              setAlertSetting('openConnections', Number(value))
            }
            data-testid="clickpipes-postgres-alert-open-connections"
            autoComplete="off"
          />
        </Container>
      </Container>
    </Panel>
  );
}
