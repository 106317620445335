import { useCurrentInstanceId } from 'src/instance/instanceController';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { loadDatabaseMetadata } from 'src/components/MetadataInitializer/metadataEmitter';
import { useCallback } from 'react';

export type DatabaseName = string;

/**
 * Generates a localstorage key for the selected database of a service.
 * @param {string | null} serviceId - The ID of the service. Defaults to the current service ID.
 * @returns {string} The cache key.
 */
export function getSelectedDatabaseCacheKey(serviceId: string): string {
  return `${serviceId}:selectedDatabase`;
}

/**
 * Retrieves the cached value of a database from local storage.
 * @param {string} key - The key of the cached database value.
 * @returns {DatabaseName} The cached database value, or 'default' if no value is found.
 */
export function getCachedDatabaseValue(key: string): DatabaseName {
  const result = window.localStorage.getItem(key);
  if (!result || result === 'undefined') {
    return 'default';
  }
  return result;
}

type SelectedDatabases = Record<string, DatabaseName>;

export const selectedDatabasesAtom = atom<SelectedDatabases>({});

/**
 * Retrieves the selected database for the current service.
 * @param {Record<string, DatabaseName>} selectedDatabases - A record of selected databases per service id.
 * @returns {DatabaseName} The selected database for the current service, or 'default' if no database is selected.
 */
export const getSelectedDatabaseForService = (
  selectedDatabases: Record<string, DatabaseName>,
  serviceId: string
): string => {
  return selectedDatabases[serviceId] || 'default';
};

/**
 * Hook to get the selected database name for the current service.
 * @returns {DatabaseName} The selected database name.
 */
export const useSelectedDatabaseValue = (): DatabaseName => {
  const selectedDatabases = useAtomValue(selectedDatabasesAtom);
  const serviceId = useCurrentInstanceId() ?? '';
  return getSelectedDatabaseForService(selectedDatabases, serviceId);
};

type SelectedDatabaseSetter = (
  serviceId: string,
  newSelectedDatabase: DatabaseName,
  forceReloadMetadata?: boolean
) => void;

/**
 * Hook to get the selected database and a setter function to update it.
 * @returns {[string, (newSelectedDatabase: string, forceReloadMetadata?: boolean) => void]} The selected database and setter function.
 */
export const useSelectedDatabase = (): {
  selectedDatabase: DatabaseName;
  setSelectedDatabase: SelectedDatabaseSetter;
} => {
  const setDatabases = useSetAtom(selectedDatabasesAtom);
  const selectedDatabase = useSelectedDatabaseValue();

  const setSelectedDatabase = useCallback(
    (serviceId: string, newSelectedDatabase: DatabaseName): void => {
      setDatabases((oldDatabases: SelectedDatabases) => {
        const oldDatabase = oldDatabases[serviceId];

        if (newSelectedDatabase !== oldDatabase) {
          const newDatabases = { ...oldDatabases, [serviceId]: newSelectedDatabase };
          // Save the selected database in the local storage.
          window.localStorage.setItem(getSelectedDatabaseCacheKey(serviceId), newSelectedDatabase);
          // Load the metadata for the new selected database.
          loadDatabaseMetadata({ database: newSelectedDatabase, serviceId });
          return newDatabases;
        }

        return oldDatabases;
      });
    },
    [setDatabases]
  );
  return {
    selectedDatabase,
    setSelectedDatabase
  };
};
