import { createToast } from 'primitives';
import { PaginationState } from 'primitives/lib/Spreadsheet/types';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Table, TableDetails } from 'shared/src/clickhouse/types';

import { QueryResult, useSqlQueryFunction } from 'src/lib/clickhouse/query';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { usePlaceholderParams } from 'src/lib/routes/usePlaceholderParams';
import {
  useDatabasesNames,
  useMetadataLoading
} from 'src/metadata/metadataState';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import {
  useCloseRightBar,
  useUpdateRightBarTypes,
  useUpdateTab
} from 'src/state/tabs';
import {
  FilterConfig,
  TableRightBarOption,
  TableTab,
  ViewParameterValues
} from 'src/state/tabs/types';

import { tableViewPageSize } from 'src/components/TableView/constants';
import DataView from 'src/components/TableView/DataView';
import styles from 'src/components/TableView/styles';
import {
  SetFilterConfigFunction,
  SetFilterConfigParamType
} from 'src/components/TableView/types';
import { generateTableViewQuery } from 'src/components/TableView/utils';
import { OnPaginationStateChangeProps } from 'primitives/lib/Spreadsheet/usePaginationState';
import { useDebounceFunction } from 'src/lib/hooks';
import { ConfirmationDialog, WarningAlert } from '@clickhouse/click-ui';
import { assertTruthy } from '@cp/common/utils/Assert';
import { internalQuerySetting } from 'shared/src/clickhouse';

function shouldCancelQueries(filterConfig: FilterConfig): boolean {
  return filterConfig.filters.length > 0 || filterConfig.sorts.length > 0;
}

interface FetchData {
  filterConfig: FilterConfig;
  pageNumber: number;
  pageSize: number;
  table: Table;
  parameters: ViewParameterValues;
  forceWakeService?: boolean;
}

interface Props {
  tab: TableTab;
  selectedTableDetails: TableDetails;
}

export interface RefreshDataArgs {
  forceWakeService?: boolean;
  parameters?: ViewParameterValues;
}

export default function TableView({
  tab,
  selectedTableDetails
}: Props): ReactElement {
  const loadingMetadata = useMetadataLoading();
  const selectedDatabase = useSelectedDatabaseValue();
  const databasesNames = useDatabasesNames();
  const mountedRef = useRef(false);
  const displayDatabaseDeletedAlert =
    !loadingMetadata &&
    !databasesNames.has(selectedDatabase) &&
    mountedRef.current;

  const [paginationState, setPaginationState] = useState<PaginationState>({
    currentPage: tab.currentPage ?? 1,
    totalRows: tab.totalRows ?? 0,
    totalPages: tab.totalPages ?? 0
  });

  const [originalRowCount, setOriginalRowCount] = useState<number | undefined>(
    0
  );

  const shouldCancelRef = useRef(shouldCancelQueries(tab.filterConfig));

  const [runFetchQuery, { data, error, loading }, cancelFetchQueryRef] =
    useSqlQueryFunction();

  const [runQuery, {}, cancelRunQueryRef] = useSqlQueryFunction();

  assertTruthy(selectedTableDetails, 'No table present in the tab');
  const isAggregatingMergeTree = selectedTableDetails.engine?.includes(
    'AggregatingMergeTree'
  );
  const [canLoadData, setCanLoadData] = useState(!isAggregatingMergeTree);
  const [
    confirmFetchAggregatingMergeTreeDialogOpen,
    setConfirmFetchAggregatingMergeTreeDialogOpen
  ] = useState(isAggregatingMergeTree && !canLoadData);

  const updateRightBarTypes = useUpdateRightBarTypes();
  const updateTab = useUpdateTab();

  const [viewParameterValues, setPlaceholderParams] = usePlaceholderParams();

  const hasViewParams = Boolean(
    selectedTableDetails.parameters &&
      selectedTableDetails.parameters.length > 0
  );

  const fetchRowCount = useCallback(
    async (forceWakeService?: boolean) => {
      const rowCountResult = await runQuery(
        `SELECT total_rows FROM system.tables WHERE database = {database:String} AND name = {tableName:String} SETTINGS ${internalQuerySetting};`,
        {
          wakeService: forceWakeService,
          variables: {
            database: selectedDatabase,
            tableName: selectedTableDetails.tableName
          }
        }
      );

      if ('error' in rowCountResult) {
        if (rowCountResult.type !== 'aborted') {
          createToast('Error', 'alert', rowCountResult.error);
        }
        return null;
      } else {
        const resultCount = rowCountResult?.rows?.[0]?.[0];
        const totalRows =
          typeof resultCount === 'string' ? parseInt(resultCount) : undefined;
        if (typeof totalRows === 'number') {
          setPaginationState({
            ...paginationState,
            totalRows: totalRows
          });
        }

        setOriginalRowCount(totalRows);

        return totalRows;
      }
    },
    [
      selectedDatabase,
      paginationState,
      runQuery,
      selectedTableDetails.tableName
    ]
  );

  const isWeirdCHTable = originalRowCount === undefined;

  const fetchData = useCallback(
    async ({
      filterConfig,
      pageNumber,
      pageSize,
      table,
      parameters,
      forceWakeService
    }: FetchData): Promise<QueryResult> => {
      if (hasViewParams && Object.keys(parameters).length === 0) {
        return { runId: '', rows: [], columns: [] };
      }
      cancelFetchQueryRef.current();
      cancelRunQueryRef.current();
      return runFetchQuery(
        generateTableViewQuery({
          filterConfig,
          pageNumber,
          pageSize,
          table: selectedTableDetails,
          parameters,
          tableView: true
        }),
        {
          wakeService: forceWakeService
        }
      );
    },
    [cancelFetchQueryRef, cancelRunQueryRef, hasViewParams, runFetchQuery]
  );

  const onMount = async (): Promise<void> => {
    await fetchRowCount();
    const fetchDataResult = await fetchData({
      filterConfig: tab.filterConfig,
      pageNumber: paginationState.currentPage ?? 1,
      pageSize: tableViewPageSize,
      table: selectedTableDetails,
      parameters: viewParameterValues
    });

    if ('error' in fetchDataResult && fetchDataResult.type !== 'aborted') {
      createToast('Error', 'alert', errorMessage(fetchDataResult.error));
    }

    mountedRef.current = true;
  };

  const onUnmount = (): void => {
    if (shouldCancelRef.current) {
      cancelFetchQueryRef.current();
      cancelRunQueryRef.current();
      mountedRef.current = false;
    }
  };

  useEffect(() => {
    if (loadingMetadata || !databasesNames.has(selectedDatabase)) {
      return;
    }

    if (!mountedRef.current && canLoadData) {
      onMount().catch(console.error);
      return (): void => onUnmount();
    }
  }, [
    displayDatabaseDeletedAlert,
    loadingMetadata,
    databasesNames,
    selectedDatabase,
    canLoadData
  ]);

  const refreshData = useCallback(
    async ({ forceWakeService, parameters }: RefreshDataArgs = {}) => {
      if (
        isAggregatingMergeTree &&
        !canLoadData &&
        !confirmFetchAggregatingMergeTreeDialogOpen
      ) {
        setConfirmFetchAggregatingMergeTreeDialogOpen(true);
        return;
      }

      if (selectedTableDetails) {
        await fetchData({
          filterConfig: tab.filterConfig,
          pageNumber: paginationState.currentPage || 1,
          pageSize: tableViewPageSize,
          table: selectedTableDetails,
          parameters: parameters ?? viewParameterValues,
          forceWakeService
        });

        await fetchRowCount(forceWakeService);
      }
    },
    [
      fetchData,
      fetchRowCount,
      paginationState.currentPage,
      selectedTableDetails,
      tab.filterConfig,
      viewParameterValues,
      confirmFetchAggregatingMergeTreeDialogOpen,
      setConfirmFetchAggregatingMergeTreeDialogOpen,
      isAggregatingMergeTree,
      canLoadData
    ]
  );

  const updateFilterConfig: SetFilterConfigFunction = (
    newFilterConfig: SetFilterConfigParamType,
    update = true
  ) => {
    const oldConfig = tab.filterConfig;

    const newConfig =
      typeof newFilterConfig === 'function'
        ? newFilterConfig(oldConfig)
        : newFilterConfig;

    shouldCancelRef.current = shouldCancelQueries(newConfig);

    // calculate new filters immediately if we're passed a function
    // so that we can run the new query immediately
    if (selectedTableDetails && update) {
      setPaginationState((state) => ({
        ...state,
        currentPage: 1
      }));

      void fetchData({
        filterConfig: newConfig,
        pageNumber: 1,
        pageSize: tableViewPageSize,
        table: selectedTableDetails,
        parameters: viewParameterValues
      });
    }

    updateTab(tab.id, {
      filterConfig: newConfig
    });
  };

  const updateRightBar = useCallback(
    (type: TableRightBarOption, value?: string) => {
      updateRightBarTypes(tab.id, tab.rightBarType, type, value);
    },
    [tab.id, tab.rightBarType, updateRightBarTypes]
  );

  const filtersApplied = (tab.filterConfig?.filters?.length || 0) > 0;
  // This will handle the pagination of the weird CH tables, where the row_count on system tables can return 0,
  // even though there can be millions of records returned. Example: system.zeros_mt
  // Our strategy here is to re-calculate the number of totalPages and totalRows on each page. We always fetch a pageSize + 1 number of rows per page
  // In case we receive pageSize + 1 rows, it means it has one more page than the current one, and we extend the totalRows + totalPages
  useEffect(() => {
    if (!loading) {
      const rowsLength = (data?.rows || []).length;
      if (isWeirdCHTable || filtersApplied) {
        const currentPage = paginationState.currentPage || 1;
        const currentTotalRows = rowsLength;

        const totalRows = isWeirdCHTable
          ? (currentPage - 1) * tableViewPageSize + currentTotalRows
          : currentTotalRows;

        const totalPages =
          rowsLength > tableViewPageSize ? currentPage + 1 : currentPage;

        setPaginationState({
          ...paginationState,
          currentPage: currentPage,
          totalPages,
          totalRows
        });
      } else if (!filtersApplied) {
        const count = originalRowCount ?? 0;
        setPaginationState({
          ...paginationState,
          totalPages: Math.ceil(count / tableViewPageSize),
          totalRows: count
        });
      }
    }
    // Don't include the pagination state on this useEffect.
    // It is intentional to only run this after the data has arrived and paginationState was already set
  }, [loading, data, isWeirdCHTable, filtersApplied]);

  const closeRightBar = useCloseRightBar();

  // Open view parameter sidebar if needed
  useEffect(() => {
    if (hasViewParams) {
      updateRightBar('viewParameters');
    } else {
      closeRightBar('viewParameters');
    }
  }, [hasViewParams, closeRightBar, updateRightBar]);

  const updateViewParamValues = useCallback(
    (values: ViewParameterValues) => {
      updateTab(tab.id, {
        parameters: values
      });

      void refreshData({ parameters: values }).catch(console.error);

      // Update url to reflect new parameters
      setPlaceholderParams(values);
    },
    [refreshData, tab.id, setPlaceholderParams, updateTab]
  );

  const debouncedFetchData = useDebounceFunction((args: FetchData) => {
    fetchData(args).catch(console.error);
  }, 250);

  const setPaginationStateCallback = useCallback(
    (newState: OnPaginationStateChangeProps): void => {
      updateTab(tab.id, {
        currentPage: newState.currentPage || 1
      });
      setPaginationState((state) => ({
        ...state,
        ...newState
      }));
      if (selectedTableDetails) {
        void debouncedFetchData({
          filterConfig: tab.filterConfig,
          table: selectedTableDetails,
          pageNumber: newState.currentPage || 1,
          pageSize: tableViewPageSize,
          parameters: viewParameterValues
        });
      }
    },
    [
      fetchData,
      selectedTableDetails,
      tab.filterConfig,
      tab.id,
      viewParameterValues
    ]
  );

  if (displayDatabaseDeletedAlert) {
    return (
      <WarningAlert
        text={`The database '${selectedDatabase}' has been deleted`}
      />
    );
  }

  return (
    <div css={styles.container}>
      {confirmFetchAggregatingMergeTreeDialogOpen && (
        <ConfirmationDialog
          data-testid="confirmFetchAggregatingMergeTreeDialog"
          message={
            'Fetching data for an AggregatingMergeTree table can be expensive. Are you sure you want to continue?'
          }
          onCancel={() => {
            setConfirmFetchAggregatingMergeTreeDialogOpen(false);
          }}
          onConfirm={() => {
            setCanLoadData(true);
            setConfirmFetchAggregatingMergeTreeDialogOpen(false);
          }}
          open={confirmFetchAggregatingMergeTreeDialogOpen}
          primaryActionLabel="Confirm"
          secondaryActionLabel="Cancel"
          title={`Please confirm you want to fetch data for ${selectedTableDetails.tableName}`}
        />
      )}
      <DataView
        error={error}
        filterConfig={tab.filterConfig}
        loading={loading}
        paginationState={paginationState}
        refreshData={refreshData}
        results={data}
        runQuery={runQuery}
        selectedTable={selectedTableDetails}
        tabId={tab.id}
        setFilterConfig={updateFilterConfig}
        unknownTotalPages={isWeirdCHTable}
        setPaginationState={setPaginationStateCallback}
        updateRightBar={updateRightBar}
        setViewParameterValues={updateViewParamValues}
        selectedRow={tab.selectedRow ?? 0}
        selectedColumn={tab.selectedColumn ?? 0}
        viewParameterValues={viewParameterValues}
        viewHasViewParams={hasViewParams}
      />
    </div>
  );
}
