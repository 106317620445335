import { ReactElement } from 'react';
import { Container, Text, TextField } from '@clickhouse/click-ui';
import { DashboardConfigField } from 'shared/src/types/dashboard';

interface ElementIdFieldProps {
  onChange: (value: string) => void;
  onConfirm: (changes?: DashboardConfigField<string>) => void;
  value?: string;
}

export default function ElementIdField({
  onChange,
  onConfirm,
  value
}: ElementIdFieldProps): ReactElement {
  return (
    <Container orientation="vertical" gap="sm" maxWidth="90%">
      <Text weight="bold">Element ID</Text>
      <Container gap="sm">
        <TextField
          onBlur={() => onConfirm()}
          onChange={(value) => {
            onChange(value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur();
            }
          }}
          value={value}
        />
      </Container>
    </Container>
  );
}
