import { Dropdown, Icon, Text } from '@clickhouse/click-ui';
import {
  InstanceState,
  STATES_VALID_TO_START,
  STATES_VALID_TO_STOP,
  STATES_VALID_TO_TERMINATE
} from '@cp/common/protocol/Instance';
import { getMaintenanceWindows } from '@cp/common/utils/MaintenanceWindowUtils';
import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { useInstanceController } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { routes } from 'src/lib/routes';
import { useCurrentUserWithRoleInCurrentOrg } from 'src/lib/user/useCurrentUserWithRoleInCurrentOrg';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { DeleteServiceModal } from 'src/pages/SettingsPage/DeleteServiceModal';
import { StopServiceModal } from 'src/pages/SettingsPage/StopServiceModal';
import styled from 'styled-components';

type MenuOption = Array<{
  action: () => void;
  label: string;
  dataTestid: string;
  isDisabled?: boolean;
}>;

export const getMenuOptions = ({
  state,
  hasManageServicePermission,
  onStart,
  onStop,
  onDelete,
  onStartFullMaintenance,
  isMaintenanceScheduledWhenStopped
}: {
  state: InstanceState;
  hasManageServicePermission: boolean;
  onStart: () => void;
  onStop: () => void;
  onDelete: () => void;
  onStartFullMaintenance?: () => void;
  isMaintenanceScheduledWhenStopped: boolean | undefined;
}): MenuOption => {
  const menuOptions: MenuOption = [];
  const canStopService =
    STATES_VALID_TO_STOP.includes(state) && state !== 'stopped';
  const canStartFullMaintenance = [
    ...STATES_VALID_TO_STOP,
    'stopping'
  ].includes(state);

  if (hasManageServicePermission) {
    if (STATES_VALID_TO_START.includes(state)) {
      menuOptions.push({
        action: onStart,
        label: 'Start',
        dataTestid: 'menu-item-start'
      });
    }

    if (canStopService) {
      menuOptions.push({
        action: onStop,
        label: 'Stop',
        dataTestid: 'menu-item-stop'
      });
    }

    if (
      isMaintenanceScheduledWhenStopped &&
      typeof onStartFullMaintenance === 'function'
    ) {
      menuOptions.push({
        action: onStartFullMaintenance,
        label: 'Perform maintenance',
        dataTestid: 'menu-item-start-maintenance',
        isDisabled: !canStartFullMaintenance
      });
    }

    if (STATES_VALID_TO_TERMINATE.includes(state)) {
      menuOptions.push({
        action: onDelete,
        label: 'Delete',
        dataTestid: 'menu-item-delete'
      });
    }
  }
  return menuOptions;
};

const MenuText = styled(Text)`
  color: ${(props): string => (props.$danger ? 'red' : 'revert')};
`;

export interface ServiceActionsMenuProps {
  serviceId: string;
  isDisabled: boolean;
  isInMaintenance: boolean;
  onStartMigrationMenuClick: () => void;
  handleStartMigration?: (runAfterMaintenance: boolean) => void;
  showDelete?: boolean;
}

const ServiceActionsMenu = ({
  serviceId,
  isDisabled,
  isInMaintenance,
  onStartMigrationMenuClick,
  handleStartMigration,
  showDelete = true
}: ServiceActionsMenuProps): ReactElement => {
  const { role: userRole } = useCurrentUserWithRoleInCurrentOrg();
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    userRole === 'ADMIN'
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [stopDialogOpen, setStopDialogOpen] = useState(false);
  const { setIsTrialModalOpen: setServiceLimitDialogOpen } =
    useServiceLimitReachedModal();

  const { instances } = useInstanceStateManager();
  const { stopInstance, deleteInstance, startInstance } =
    useInstanceController();
  const instance = instances[serviceId];

  const windows = getMaintenanceWindows([instance]);
  const isMaintenanceScheduledWhenStopped =
    windows.length > 0 && windows[0].initiateWhenStopped;

  const currentOrganization = useCurrentOrganizationOrThrow();
  const currentOrganizationId = currentOrganization.id;

  const handleStart = (): void => {
    if (!currentOrganization.restrictions.canStartInstances) {
      setServiceLimitDialogOpen(true);
      return;
    }

    void startInstance({
      instanceId: serviceId,
      organizationId: currentOrganizationId
    });
  };

  const handleStop = (): void => {
    void stopInstance({
      instanceId: serviceId,
      organizationId: currentOrganizationId
    });
    setStopDialogOpen(false);
  };

  const handleDelete = (): void => {
    void deleteInstance({
      instanceId: serviceId,
      organizationId: currentOrganizationId
    });
    setDeleteDialogOpen(false);
  };

  const handleViewSettings = (): void => {
    navigateTo(routes.serviceSettings({ serviceId }));
  };

  const menuOptions = getMenuOptions({
    hasManageServicePermission,
    state: instance.state,
    onStop: () => setStopDialogOpen(true),
    onStart: handleStart,
    onDelete: () => setDeleteDialogOpen(true),
    onStartFullMaintenance: onStartMigrationMenuClick,
    isMaintenanceScheduledWhenStopped
  });

  return (
    <>
      <DeleteServiceModal
        open={deleteDialogOpen}
        name={instance.name}
        onDelete={handleDelete}
        onCancel={(): void => {
          setDeleteDialogOpen(false);
        }}
        data-testid="delete-service-modal"
      />
      <StopServiceModal
        open={stopDialogOpen}
        instanceName={instance.name}
        onStop={handleStop}
        onCancel={(): void => setStopDialogOpen(false)}
        onStartMaintenance={(): void => {
          if (typeof handleStartMigration === 'function') {
            handleStartMigration(false);
            setStopDialogOpen(false);
          }
        }}
        maintenanceWindow={windows[0]}
        data-testid="stop-modal"
      />

      <Dropdown>
        <Dropdown.Trigger disabled={isDisabled}>
          <Icon name="dots-vertical" data-testid="service-menu-button" />
        </Dropdown.Trigger>
        <Dropdown.Content>
          <Dropdown.Group>
            <Dropdown.Item onClick={handleViewSettings}>
              View settings
            </Dropdown.Item>
          </Dropdown.Group>
          <Dropdown.Group>
            {Object.values(menuOptions).map(
              ({ action, label, dataTestid, isDisabled }) => (
                <Dropdown.Item
                  key={label}
                  onClick={action}
                  disabled={isInMaintenance || isDisabled}
                  data-testid={dataTestid}
                >
                  {label === 'Delete' && !isInMaintenance && showDelete ? (
                    <MenuText $danger={true}>{label}</MenuText>
                  ) : (
                    <MenuText $danger={false}>{label}</MenuText>
                  )}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Group>
        </Dropdown.Content>
      </Dropdown>
    </>
  );
};

export default ServiceActionsMenu;
