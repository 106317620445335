import { v4 as uuid } from 'uuid';

import { jsonIsArray, jsonIsObject, JSONValue, parseJSON } from 'shared/src/json';

import { getCurrentServiceId } from 'src/state/service';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';

export interface HistoryItem {
  id: string;
  when: Date;
  sql: string;
  serviceId: string;
}

// Local storage key for history
const storageKey = 'queryHistory';
// Maximum number of stored history items
const maxHistoryItems = 500;
// Maximum number of characters in serialized stored history
const maxHistoryChars = 1024 * 1024;

/**
 * Trims the history item to fit maxHistoryItems and maxHistoryChars limits
 * @param items An array of history items.
 * @returns A trimmed array of history items.
 */
function trimHistory(items: HistoryItem[]): HistoryItem[] {
  // Trim number of items to maxHistoryItems
  items = items.slice(0, maxHistoryItems);

  // Make sure the serialized items would fit in maxHistoryChars
  const serializable = items.map((item) => ({
    ...item,
    when: item.when.toISOString()
  }));

  // Repeatedly remove oldest item until short enough to fit in maxHistoryChars
  let serialized: string;
  for (;;) {
    serialized = JSON.stringify(serializable);
    if (serialized.length < maxHistoryChars) {
      break;
    } else {
      serializable.pop();
      items.pop();
    }
  }
  return items;
}

type History = {
  addHistoryItem: (sql: string, serviceId: string) => void;
  removeHistoryItem: (id: string) => void;
  setHistory: (items: HistoryItem[]) => void;
};

/**
 * An `atom` that stores the history of SQL queries.
 * The `atom` uses `localStorage` to persist the history across sessions.
 */
const historyAtom = atomWithStorage<HistoryItem[]>(storageKey, [], {
  getItem(key: string, initialValue: HistoryItem[]): HistoryItem[] {
    const storedValue = localStorage.getItem(key);
    try {
      return parseHistory(storedValue);
    } catch {
      return initialValue;
    }
  },
  setItem(key: string, value: HistoryItem[]): void {
    localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key: string): void {
    localStorage.removeItem(key);
  },
  subscribe(key: string, callback: (value: HistoryItem[]) => void, initialValue: HistoryItem[]): () => void {
    const listener = (e: StorageEvent): void => {
      const newHistoryValue = handleStorageEvent(e, initialValue);
      callback(newHistoryValue);
    };
    window.addEventListener('storage', listener);
    return (): void => {
      window.removeEventListener('storage', listener);
    };
  }
});

/**
 * A derived atom that returns the history items for the current service, sorted by timestamp.
 */
export const currentServiceHistoryAtom = atom<HistoryItem[]>((get) => {
  const historyValue = get(historyAtom);
  const serviceId = getCurrentServiceId();
  const currentServiceHistory = historyValue.filter((item) => item.serviceId === serviceId);
  currentServiceHistory.sort((a, b) => {
    return b.when.getTime() - a.when.getTime();
  });
  return currentServiceHistory;
});

export const useHistory = (): History => {
  const [historyValue, setHistoryValue] = useAtom(historyAtom);

  // Trim history to fit maxHistoryItems and maxHistoryChars limits
  // and save to local storage
  const setHistory = useCallback(
    (items: HistoryItem[]): void => {
      const trimmed = trimHistory(items);
      setHistoryValue(trimmed);
    },
    [setHistoryValue]
  );

  const addHistoryItem = useCallback(
    function addHistoryItem(sql: string, serviceId: string): void {
      const newItem: HistoryItem = {
        sql,
        id: uuid(),
        when: new Date(),
        serviceId
      };

      const oldHistory = historyValue;
      const newHistory: HistoryItem[] = [newItem, ...oldHistory];
      setHistory(newHistory);
    },
    [historyValue, setHistory]
  );

  function removeHistoryItem(id: string): void {
    setHistory(historyValue.filter((item) => item.id !== id));
  }

  return {
    addHistoryItem,
    removeHistoryItem,
    setHistory
  };
};

/**
 * Parses a single history item from a JSON value.
 * @param item The JSON value to parse.
 * @returns A `HistoryItem` object if the JSON value is valid, or `null` otherwise.
 */
function parseHistoryItem(item: JSONValue): HistoryItem | null {
  if (
    !jsonIsObject(item) ||
    !(typeof item.id === 'string' && typeof item.when === 'string' && typeof item.sql === 'string')
  ) {
    console.error('Invalid history item');
    return null;
  }

  if (typeof item.serviceId !== 'string') {
    return null;
  }

  return {
    id: item.id,
    when: new Date(item.when),
    sql: item.sql,
    serviceId: item.serviceId
  };
}

/**
 * Parses a string of JSON data into an array of `HistoryItem` objects.
 * @param dataStr The JSON data to parse.
 * @returns An array of `HistoryItem` objects.
 */
function parseHistory(dataStr: string | null): HistoryItem[] {
  if (dataStr === null) {
    return [];
  } else {
    let data: JSONValue;
    try {
      data = parseJSON(dataStr);
    } catch (e) {
      console.error('Error parsing history JSON (invalid JSON)');
      return [];
    }

    if (!jsonIsArray(data)) {
      console.error('Error parsing history JSON (not an array)');
      return [];
    }

    const items: HistoryItem[] = [];

    for (const item of data) {
      const parsed = parseHistoryItem(item);
      if (parsed !== null) {
        items.push(parsed);
      }
    }

    return items;
  }
}

/**
 * Handles a storage event and returns the updated history.
 * @param e The storage event to handle.
 * @param ourHistory The current history.
 * @returns The updated history.
 */
function handleStorageEvent(e: StorageEvent, ourHistory: HistoryItem[]): HistoryItem[] {
  if (e.key === storageKey) {
    let theirOldHistory: HistoryItem[];
    let theirHistory: HistoryItem[];
    try {
      theirOldHistory = parseHistory(e.oldValue);
      theirHistory = parseHistory(e.newValue);
    } catch (e) {
      console.error('Error parsing history', e);
      theirOldHistory = theirHistory = [];
    }

    // Create a dictionary of history items by ID
    const mergedById: Record<string, HistoryItem> = {};

    // Get the IDs of the old and new history items
    const oldIds = theirOldHistory.map((item) => item.id);
    const newIds = new Set(theirHistory.map((item) => item.id));

    // Get the IDs of the deleted history items
    const deletedIds = oldIds.filter((id) => !newIds.has(id));

    // Add our history items to the dictionary
    ourHistory.forEach((item) => {
      mergedById[item.id] = item;
    });

    // Add their history items to the dictionary
    theirHistory.forEach((item) => {
      mergedById[item.id] = item;
    });

    // Remove the deleted history items from the dictionary
    deletedIds.forEach((id) => {
      delete mergedById[id];
    });

    // Convert the dictionary to an array and sort it by timestamp
    const merged = Object.values(mergedById);
    merged.sort((a, b) => {
      return b.when.getTime() - a.when.getTime();
    });
    return merged;
  }
  return ourHistory;
}
