import { Tooltip, Text, Icon } from '@clickhouse/click-ui';
import { summaryContainerWithMetric } from 'src/components/Metrics/styles';

import MetricChart, {
  MetricChartProps
} from 'src/components/Metrics/MetricChart';
import MetricSummary, {
  MetricSummaryProps
} from 'src/components/Metrics/MetricSummary';
import { FC, HTMLAttributes } from 'react';

interface MetricChartWithSummaryProps extends HTMLAttributes<HTMLDivElement> {
  metric: MetricChartProps;
  summaryInput: MetricSummaryProps;
}
const MetricChartWithSummary: FC<MetricChartWithSummaryProps> = ({
  metric,
  summaryInput,
  ...props
}) => {
  if (
    !metric ||
    !metric.metric ||
    !metric.seriesData ||
    metric.seriesData.length === 0
  ) {
    return <div> No data </div>;
  }
  return (
    <div
      css={summaryContainerWithMetric}
      className="metric_chart_with_summary"
      {...props}
    >
      <div className="metrics_summary_with_info_block">
        <MetricSummary summaryInput={summaryInput.summaryInput} />
        <Tooltip>
          <div className="info info_block_corner">
            <Tooltip.Trigger>
              <Text color="muted" component="div">
                <Icon name="information" className="info_block_corner_icon" />
              </Text>
            </Tooltip.Trigger>
          </div>
          <Tooltip.Content data-testid="tooltip-content">
            {summaryInput.summaryInput.helpTooltipText}
          </Tooltip.Content>
        </Tooltip>
      </div>
      <div className="metrics_chart">
        <MetricChart metric={metric.metric} seriesData={metric.seriesData} />
      </div>
    </div>
  );
};
export default MetricChartWithSummary;
