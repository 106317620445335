import React from 'react';
import styled from 'styled-components';
import { FullWidthCodeBlock } from 'src/components/ServiceConnect/Tabs/FullWidthCodeBlock';

interface CodeBlockWithPasswordProps {
  // The string containing the unmasked password
  unmaskedString: string;
  // The string containing the masked password.
  // If undefined, the password empty and masking is not needed.
  maskedString?: string;
  // The language of the code block
  language?: string;
  showPassword?: boolean;
}

// CodeBlockContainer and EyeButtonContainer are needed to position the eye icon inside the code block
const CodeBlockContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CodeBlockWithPassword: React.FC<CodeBlockWithPasswordProps> = ({
  maskedString,
  unmaskedString,
  language,
  showPassword = false,
  ...props
}) => {
  const copy = async (): Promise<void> => {
    // Ignore what the component has copied and copy the unmasked string instead
    await navigator.clipboard.writeText(unmaskedString);
  };

  return (
    <CodeBlockContainer {...props} data-a={showPassword} data-b={maskedString}>
      <FullWidthCodeBlock
        language={language || 'bash'}
        showLineNumbers
        wrapLines
        onCopy={copy}
      >
        {!showPassword && maskedString ? maskedString : unmaskedString}
      </FullWidthCodeBlock>
    </CodeBlockContainer>
  );
};

export default CodeBlockWithPassword;
