import { Link, Text } from '@clickhouse/click-ui';
import { InstanceCloudProvider } from '@cp/common/protocol/Instance';
import { ReactNode } from 'react';

interface PrivateEndpointDocsLinkProps {
  cloudProvider: InstanceCloudProvider;
}

interface LinkDetails {
  title: string;
  href: string;
}

const cloudProviderLinkDetails: Partial<
  Record<InstanceCloudProvider, LinkDetails>
> = {
  aws: {
    title: 'AWS PrivateLink setup',
    href: 'https://clickhouse.com/docs/en/manage/security/aws-privatelink'
  },
  gcp: {
    title: 'GCP Private Service Connect',
    href: 'https://clickhouse.com/docs/en/manage/security/gcp-private-service-connect'
  },
  azure: {
    title: 'Azure Private Link setup',
    href: 'https://clickhouse.com/docs/en/cloud/security/azure-privatelink'
  }
};

export function PrivateEndpointDocsLink({
  cloudProvider
}: PrivateEndpointDocsLinkProps): ReactNode | null {
  const details = cloudProviderLinkDetails[cloudProvider];

  if (!details) {
    return null;
  }

  const { href, title } = details;

  return (
    <Text>
      <Link target="_blank" href={href} icon="share">
        {title}
      </Link>
    </Text>
  );
}
