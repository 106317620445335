import {
  Button,
  Container,
  Flyout,
  GridContainer,
  Label,
  RadioGroup,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { IpAccessListEntry } from '@cp/common/protocol/Instance';
import { ReactElement, useRef } from 'react';
import { IpAccessOption } from 'src/instance/controller/useNetworkSettings';
import { NetworkInput } from 'src/pages/SettingsPage/NetworkInput';
import styled from 'styled-components';
import { useNetworkAccessList } from 'src/instance/controller/useNetworkAccessList';

export interface NetworkFlyoutProps {
  type: IpAccessOption;
  onChange: (ipType: IpAccessOption, ipList: Array<IpAccessListEntry>) => void;
  open?: boolean;
  onOpenChange: (open: boolean) => void;
  ipsAccessList?: Array<IpAccessListEntry>;
}

const RadioGroupItem = styled(RadioGroup.Item)`
  label {
    width: 100%;
    & > [data-label] {
      white-space: nowrap;
    }
  }
`;

const NetworkFlyout = ({
  type = 'anywhere',
  onChange,
  open,
  onOpenChange,
  ipsAccessList = []
}: NetworkFlyoutProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    hasUpdateIpAddressListPermission,
    duplicateList,
    invalidIpList,
    IP_ADDRESS_INPUT_COLUMN,
    addItem,
    onValueChange,
    removeItem,
    onSubmit,
    onRadioGroupChange,
    addCurrentIp,
    onBlur,
    disabledSaveBtn,
    ipOption,
    ipList
  } = useNetworkAccessList({ ipsAccessList, open, type, onSubmit: onChange });
  const ipListLength = ipList.length;

  return (
    <Flyout open={open} onOpenChange={onOpenChange}>
      <Flyout.Content
        strategy="fixed"
        data-testid="add-ip-access-list-entry-dialog"
        size="wide"
      >
        <Flyout.Header
          title="IP Access List"
          description="To ensure an extra layer of security for your service, we recommend only allowing traffic from IP addresses that you know and trust."
          maxWidth="var(--flyout-width, 100%)"
        />
        <Flyout.Body align="top" maxWidth="var(--flyout-width, 100%)">
          <Spacer size="md" />
          <Flyout.Element>
            <RadioGroup
              label="Allow access to this service from"
              orientation="vertical"
              value={ipOption}
              onValueChange={onRadioGroupChange}
              inline={false}
              data-testid="specific-location-block"
              disabled={!hasUpdateIpAddressListPermission}
            >
              <RadioGroup.Item value="anywhere" label="Anywhere" />
              <RadioGroup.Item value="nowhere" label="Nowhere" />
              <RadioGroupItem
                value="specificLocations"
                label="Specific locations"
              />
            </RadioGroup>
            {ipOption === 'specificLocations' && (
              <Container orientation="vertical" gap="xs" ref={ref}>
                <Container
                  fillWidth
                  alignItems="center"
                  justifyContent="space-between"
                  isResponsive={false}
                >
                  <Text weight="semibold" fillWidth size="lg">
                    Access list
                  </Text>
                  {hasUpdateIpAddressListPermission && (
                    <Container
                      gap="xs"
                      justifyContent="end"
                      fillWidth
                      isResponsive={false}
                    >
                      <Button
                        iconLeft="plus"
                        onClick={addItem}
                        type="secondary"
                      >
                        Add new IP
                      </Button>
                      <Button
                        iconLeft="plus"
                        onClick={addCurrentIp}
                        type="secondary"
                      >
                        Use current IP
                      </Button>
                    </Container>
                  )}
                </Container>
                <GridContainer
                  gridTemplateColumns={IP_ADDRESS_INPUT_COLUMN}
                  alignItems="start"
                >
                  <Label>IP address or CIDR</Label>
                  <Label>Description</Label>
                </GridContainer>
                {ipList.map((ip, index) => (
                  <NetworkInput
                    key={`ip-${index}`}
                    gridTemplateColumns={IP_ADDRESS_INPUT_COLUMN}
                    onChange={onValueChange(index)}
                    disabled={!hasUpdateIpAddressListPermission}
                    onRemove={removeItem(index)}
                    source={ip.source}
                    description={ip.description}
                    showIpDeleteButton={ipListLength > 1}
                    onBlur={onBlur}
                    duplicateList={duplicateList}
                    invalidIpList={invalidIpList}
                  />
                ))}
              </Container>
            )}
          </Flyout.Element>
        </Flyout.Body>
        {hasUpdateIpAddressListPermission && (
          <Flyout.Footer data-testid="network-flyout-footer">
            <Flyout.Close label="Cancel" />
            <Button
              onClick={onSubmit}
              disabled={ipOption === 'specificLocations' && disabledSaveBtn}
              data-a={disabledSaveBtn}
            >
              Save
            </Button>
          </Flyout.Footer>
        )}
      </Flyout.Content>
    </Flyout>
  );
};

export default NetworkFlyout;
