import {
  Accordion,
  Button,
  Checkbox,
  Container,
  Link,
  Spacer,
  Text,
  createToast
} from '@clickhouse/click-ui';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import TierSelection from 'src/components/CreateNewService/TierSelection';
import NewServiceScalingControlSection from 'src/components/CreateNewService/NewServiceScalingControlSection';
import {
  DEFAULT_AUTOSCALING_BYOC_MEMORY,
  InstanceCustomerManagedEncryptionConfig,
  InstanceTier,
  getAllowAnywhereIpAccessList
} from '@cp/common/protocol/Instance';
import { useInstanceAutoScalingStateManager } from 'src/instance/instanceAutoScalingState';
import {
  useCurrentOrganizationInstances,
  useInstanceController
} from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { NOT_FOUND } from '@cp/common/utils/HttpError';
import { truthy } from '@cp/common/utils/Assert';
import { Region, RegionId } from '@cp/common/protocol/Region';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { NewServiceCustomerKeySection } from 'src/components/CreateNewService/NewServiceCustomerKeysSection';
import { useNewInstanceCustomerKeyConfig } from 'src/instance/controller/useNewInstanceCustomerKeyConfig';
import { useCurrentOrganization } from 'src/organization/organizationState';
import { isCustomerManagedEncryptionAvailable } from '@cp/common/utils/InstanceUtils';
import { validateServiceName } from 'src/components/CreateNewService/service-validatation';
import PocDialog from 'src/components/CreateNewService/PocDialog';
import useRequestPOC from 'src/account/controller/useRequestPOC';
import { RegionSelector } from 'src/components/RegionSelector';
import { Galaxy } from 'galaxy';
import styled from 'styled-components';
import { getByocIdForAccountAndRegion } from 'src/lib/byoc/getByocIdForAccountAndRegion';
import { CloudProviderAccountSelection } from 'src/components/CloudProviderAccountSelection';
import { ByocCloudProviderConfig } from '@cp/common/protocol/Organization';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';
import { useRegionsPrices } from 'src/billing/controllers/useRegionsPrices';

const ActionsWrapper = Container;

const CustomAccordion = styled(Accordion)`
  container-type: inline-size;
`;

export interface ServiceFormProps {
  regions: Array<Region>;
  byocAccounts: ByocCloudProviderConfig | null;
  selectedRegion: RegionId;
  setSelectedRegion: Dispatch<SetStateAction<RegionId>>;
  selectedByocAccount: string | undefined;
  setSelectedByocAccount: Dispatch<SetStateAction<string | undefined>>;
  serviceTier: InstanceTier;
  onServiceTierChange: (tier: InstanceTier) => void;
  onCreateService: (serviceId: string) => void;
  currentOrganizationId: string;
  serviceName: string;
  shouldShowAzureToc?: boolean;
  disableTierSelection?: boolean;
  type?: 'default' | 'onboarding';
  handleNameError: (error: string) => void;
}

const ServiceForm = ({
  regions,
  byocAccounts,
  selectedRegion,
  setSelectedRegion,
  selectedByocAccount,
  setSelectedByocAccount,
  serviceTier,
  onServiceTierChange,
  currentOrganizationId,
  onCreateService,
  shouldShowAzureToc = true,
  disableTierSelection = false,
  serviceName,
  type = 'default',
  handleNameError
}: ServiceFormProps): ReactElement => {
  const { createInstance } = useInstanceController();
  const {
    instanceState: { isCreatingInstance }
  } = useInstanceStateManager();
  const currentOrganizationInstances = useCurrentOrganizationInstances();

  const { loading, openPOCDialog, setOpenPOCDialog, sendRequestPOC } =
    useRequestPOC();

  const [privatePreviewTermsChecked, setPrivatePreviewTermsChecked] =
    useState(false);

  const { prices } = useRegionsPrices();

  const {
    keyId,
    roleId,
    setKeyId,
    setRoleId,
    validate: onCustomerKeyValidate,
    validationStatus: customerKeyValidationStatus
  } = useNewInstanceCustomerKeyConfig(selectedRegion, serviceTier);

  const currentOrganization = useCurrentOrganization();
  const orgFeatures = currentOrganization?.features ?? [];
  const cmekAvailable = isCustomerManagedEncryptionAvailable(
    selectedRegion,
    serviceTier,
    orgFeatures,
    !!currentOrganization && isOrgByoc(currentOrganization)
  );

  const isAutoScalingEnabled = serviceTier !== 'Development';

  const existingInstanceNames = Object.values(currentOrganizationInstances).map(
    (value) => value.name
  );

  const handleRegionChange = (regionId: string): void => {
    const id = truthy(
      regions.find(({ id }) => id === regionId)?.id,
      `${NOT_FOUND}: region ${regionId}`
    );
    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.createNewService.onRegionSelection`,
      {
        interaction: 'trigger',
        regionId
      }
    );
    setSelectedRegion(id);
  };

  const temporaryInstanceId =
    serviceTier === 'Production' ? 'newInstanceProd' : 'newInstanceDev';

  const { autoScalingState } = useInstanceAutoScalingStateManager();

  const handleServiceCreation = (): void => {
    const { error } = validateServiceName({
      serviceName,
      existingInstanceNames
    });

    if (error) {
      handleNameError(error);
      return;
    }

    if (shouldShowAzureToc && !privatePreviewTermsChecked) {
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'You need to accept the terms and conditions'
      });
      return;
    }

    let customerManagedEncryption:
      | InstanceCustomerManagedEncryptionConfig
      | undefined;

    if (keyId.trim()) {
      customerManagedEncryption = {
        keyArn: keyId.trim(),
        assumedRoleArn: roleId.trim()
      };
    }

    const ipAccessList = getAllowAnywhereIpAccessList();
    const autoscalingParams = byocAccounts
      ? {
          maxAutoScalingTotalMemory:
            autoScalingState[temporaryInstanceId]?.maxAutoScalingTotalMemory ??
            DEFAULT_AUTOSCALING_BYOC_MEMORY,
          minAutoScalingTotalMemory:
            autoScalingState[temporaryInstanceId]?.minAutoScalingTotalMemory ??
            DEFAULT_AUTOSCALING_BYOC_MEMORY,
          enableIdleScaling: false,
          idleTimeoutMinutes:
            autoScalingState[temporaryInstanceId]?.idleTimeoutMinutes
        }
      : serviceTier === 'Production'
      ? {
          maxAutoScalingTotalMemory:
            autoScalingState[temporaryInstanceId]?.maxAutoScalingTotalMemory,
          minAutoScalingTotalMemory:
            autoScalingState[temporaryInstanceId]?.minAutoScalingTotalMemory,
          enableIdleScaling:
            autoScalingState[temporaryInstanceId]?.enableIdleScaling,
          idleTimeoutMinutes:
            autoScalingState[temporaryInstanceId]?.idleTimeoutMinutes
        }
      : {
          enableIdleScaling:
            autoScalingState[temporaryInstanceId]?.enableIdleScaling,
          idleTimeoutMinutes:
            autoScalingState[temporaryInstanceId]?.idleTimeoutMinutes
        };

    let byocId: string | undefined;
    if (byocAccounts) {
      if (!selectedByocAccount) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'You need to select a cloud provider account'
        });
        return;
      }

      byocId = getByocIdForAccountAndRegion(
        byocAccounts,
        selectedByocAccount,
        selectedRegion
      );
    }

    const instancePayload = {
      name: serviceName,
      instanceTier: serviceTier,
      organizationId: currentOrganizationId,
      regionId: selectedRegion,
      autoscalingParams,
      customerManagedEncryption,
      ipAccessList,
      privatePreviewTermsChecked,
      byocId
    };

    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.createNewService.submitButtonClick`,
      {
        interaction: 'click',
        payload: instancePayload
      }
    );

    void createInstance({
      ...instancePayload,
      onCreateService
    });
  };

  const onKeyIdChange = (newKeyId: string): void => {
    setKeyId(newKeyId);
  };

  const onRoleIdChange = (newRoleId: string): void => {
    setRoleId(newRoleId);
  };

  const onTierSelection = (tier: InstanceTier): void => {
    if (tier === 'Development') {
      setKeyId('');
      setRoleId('');
    }
    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.createNewService.onTierClick`,
      {
        interaction: 'click',
        tier
      }
    );
    onServiceTierChange(tier);
  };

  const onClickContactUs = (): void => {
    Galaxy.galaxy().track('onboardingPage.createNewService.openPOCModal', {
      interaction: 'click'
    });
    setOpenPOCDialog(true);
  };

  const onPOCCancel = (): void => {
    Galaxy.galaxy().track('onboardingPage.createNewService.cancelPOCClick', {
      interaction: 'click'
    });
    setOpenPOCDialog(false);
  };

  return (
    <Container
      data-testid="service-form"
      orientation="vertical"
      gap="lg"
      alignItems="stretch"
    >
      {byocAccounts !== null && (
        <CloudProviderAccountSelection
          cloudProviderConfig={byocAccounts}
          selectedAccountId={selectedByocAccount}
          onAccountChange={setSelectedByocAccount}
        />
      )}
      <RegionSelector
        regions={regions}
        selectedRegionId={selectedRegion}
        onRegionChange={handleRegionChange}
      />
      <TierSelection
        selectedTier={serviceTier}
        onTierSelection={onTierSelection}
        isDevTierDisabled={disableTierSelection}
        isOnboarding={type === 'onboarding'}
        devStoragePrice={prices[selectedRegion]?.storageDevelopment}
        devComputePrice={prices[selectedRegion]?.computeDevelopment}
        prodStoragePrice={prices[selectedRegion]?.storageProduction}
        prodComputePrice={prices[selectedRegion]?.computeProduction}
      />
      {isAutoScalingEnabled && (
        <CustomAccordion
          title="Scaling and idling"
          size="md"
          data-testid="autoscaling-section"
          onValueChange={() => {
            Galaxy.galaxy().track(
              `${
                type === 'default' ? 'service' : 'onboarding'
              }Page.createNewService.scalingAccordionClick`,
              {
                interaction: 'click'
              }
            );
          }}
        >
          <NewServiceScalingControlSection
            selectedRegion={selectedRegion}
            selectedInstanceTier={serviceTier}
          />
        </CustomAccordion>
      )}
      {cmekAvailable && (
        <Accordion title="Customer managed encryption" size="lg" fillWidth>
          <NewServiceCustomerKeySection
            keyId={keyId}
            roleId={roleId}
            onKeyIdChange={onKeyIdChange}
            onRoleIdChange={onRoleIdChange}
            onValidate={onCustomerKeyValidate}
            validationStatus={customerKeyValidationStatus}
          />
        </Accordion>
      )}
      {shouldShowAzureToc && (
        <Checkbox
          data-testid="azure-toc-checkbox"
          label={
            <Text>
              I accept the Azure Private Preview{' '}
              <a
                href="https://clickhouse.com/legal/agreements/private-preview-terms-of-service"
                target="_blank"
              >
                Terms and Conditions
              </a>
            </Text>
          }
          checked={privatePreviewTermsChecked}
          onCheckedChange={() => setPrivatePreviewTermsChecked((prev) => !prev)}
        />
      )}
      <Container orientation="vertical" isResponsive={false} alignItems="end">
        <ActionsWrapper gap="sm" isResponsive={false}>
          {type === 'default' && (
            <Button
              type="secondary"
              disabled={isCreatingInstance}
              label="Cancel"
              onClick={() => {
                Galaxy.galaxy().track(
                  'servicePage.createNewService.onServiceCancelClick',
                  {
                    interaction: 'click'
                  }
                );
                navigateTo(routes.services());
              }}
              data-testid="cancel-create-service-button"
            />
          )}
          <Button
            loading={isCreatingInstance}
            label="Create service"
            fillWidth={type === 'onboarding'}
            onClick={handleServiceCreation}
            autoFocus={serviceName.length > 0}
            data-testid="create-service-button"
          />
        </ActionsWrapper>
        {type === 'onboarding' && (
          <>
            <Spacer size="sm" />
            <Text component="div" data-testid="poc-link">
              Starting a proof of concept?{' '}
              <Link onClick={onClickContactUs} data-testid="poc-contact-us">
                Contact us
              </Link>
            </Text>
          </>
        )}
      </Container>
      <PocDialog
        open={openPOCDialog}
        onCancel={onPOCCancel}
        onConfirm={sendRequestPOC}
        loading={loading}
      />
    </Container>
  );
};

export default ServiceForm;
