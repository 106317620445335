import React, { KeyboardEvent, ReactElement, useMemo, useState } from 'react';
import { createToast } from 'primitives';
import { useAuth } from 'src/components/auth';
import { useSearchParams } from 'react-router-dom';
import {
  useCurrentInstance,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import { useQueryRunner } from 'src/lib/clickhouse/query';
import { PasswordCredential, useCredentials } from 'src/state/connection';
import styles from 'src/components/CredentialsModal/styles';
import {
  Button,
  Container,
  Dialog,
  NumberField,
  PasswordField,
  Separator,
  TextField
} from '@clickhouse/click-ui';

interface Props {
  open?: boolean;
  onClose: () => void;
  onSuccess: (
    credentials: Pick<
      PasswordCredential,
      'host' | 'port' | 'username' | 'password'
    >
  ) => void;
}

const testConnectionQuery = 'SELECT 1;';

function CredentialsModalComponent({
  open,
  onSuccess,
  onClose
}: Props): ReactElement {
  const currentUser = useAuth().currentUser;
  const [params] = useSearchParams();
  const { isAsleep, isAwaking, isProvisioning } =
    useIsCurrentInstanceAwakeStatus();
  const connectionDetails = useCredentials();

  const [testing, setTesting] = useState(false);
  const currentService = useCurrentInstance();

  const [host, setHost] = useState<string>(
    currentService?.endpoints.https.hostname || ''
  );
  const [port, setPort] = useState<string>(
    String(currentService?.endpoints.https.port || '')
  );
  const [username, setUsername] = useState<string>(
    currentService?.dbUsername || ''
  );
  const [password, setPassword] = useState<string>('');

  const validCredentials = useMemo(() => {
    return host && port && username;
  }, [host, port, username, password]);

  const closeModal = (): void => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const connectionCredentials: PasswordCredential = {
    connected: false,
    host,
    port: port || '8443',
    username: username || 'default',
    database: connectionDetails.database || 'default',
    password,
    type: 'password'
  };

  const queryRunner = useQueryRunner({
    sql: testConnectionQuery,
    connectionCredentials,
    skipConnectionInit: true
  });

  const testConnection = async (): Promise<boolean> => {
    if (!validCredentials) {
      createToast('Error', 'alert', 'Please fill in all fields');
      return false;
    }

    if (isAsleep || isAwaking || isProvisioning) {
      createToast(
        'Service is asleep',
        'alert',
        'Waking up your service. This may take a minute...'
      );
    }

    setTesting(true);

    return queryRunner()
      .then((data) => {
        setTesting(false);
        if ('error' in data) {
          createToast(
            'Error',
            'alert',
            `Unable to establish connection.  Please check your database credentials - ${data.error}`
          );
          return false;
        } else {
          createToast(
            'Success',
            'success',
            'Connection established successfully!'
          );
          return true;
        }
      })
      .catch((error) => {
        setTesting(false);
        let message = '';
        if (error instanceof Error) {
          message = error.message;
        }
        createToast(
          'Error',
          'alert',
          `Unable to establish connection.  Please check your database credentials - ${message}`
        );
        return false;
      });
  };

  const saveConnection = (): void => {
    if (!testing) {
      void testConnection().then((success) => {
        if (success) {
          onSuccess({
            host: host || '',
            port: port || '8443',
            username: username || 'default',
            password: password || ''
          });
        }
      });
    }
  };

  const pasteHost = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    const pastedText = e.clipboardData.getData('text');
    const simpleSuffixMatch = pastedText.match(/^(.*):(\d+)$/);

    const input = e.currentTarget;

    const fullySelected =
      input.selectionStart === 0 && input.selectionEnd === input.value.length;

    if (!fullySelected) {
      return;
    }

    if (simpleSuffixMatch) {
      const host = simpleSuffixMatch[1];
      const port = simpleSuffixMatch[2];
      setHost(host);
      setPort(port);
      e.preventDefault();
    } else if (pastedText.startsWith('https://')) {
      try {
        const url = new URL(pastedText);
        setHost(url.hostname);
        setPort(url.port || '443');
        if (url.username) {
          setUsername(url.username);
        }
        if (url.password) {
          setPassword(url.password);
        }
        e.preventDefault();
      } catch (e) {
        // not valid URL, ignore
      }
    }
  };

  const currentUserEmail = currentUser?.email;
  const showHostAndPort =
    params.get('show_host') === '1' &&
    currentUserEmail &&
    (currentUserEmail.endsWith('@clickhouse.com') ||
      currentUserEmail.endsWith('@clickhouse-local.com'));

  const serviceId = currentService?.id || '';
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      saveConnection();
    }
  };

  return (
    <Dialog open={open}>
      <Dialog.Content
        title={`Enter DB Credentials for ${currentService?.name ?? ''}`}
        showClose={connectionDetails.connected && typeof onClose === 'function'}
        onClose={closeModal}
      >
        <Container orientation="vertical" gap="xs">
          {showHostAndPort && (
            <div css={styles.hostDetails}>
              <div css={styles.inputLabelStyle}>
                <TextField
                  label="Host"
                  autoComplete="off"
                  onPaste={pasteHost}
                  value={host}
                  id={`${serviceId}_hostname`}
                  name={`${serviceId}[hostname]`}
                  placeholder="Enter Hostname"
                  onChange={setHost}
                  onKeyDown={onKeyDown}
                  orientation="horizontal"
                />
              </div>
              <NumberField
                label="Port"
                autoComplete="off"
                type="number"
                placeholder="8443"
                id={`${serviceId}_port`}
                name={`${serviceId}[port]`}
                value={port}
                onChange={setPort}
                onKeyDown={onKeyDown}
                loading={false}
                orientation="horizontal"
              />
            </div>
          )}

          <div css={styles.inputLabelStyle}>
            <TextField
              label="User"
              autoComplete="off"
              placeholder="default"
              value={username}
              id={`${serviceId}_username`}
              name={`${serviceId}[username]`}
              onChange={setUsername}
              onKeyDown={onKeyDown}
              orientation="horizontal"
            />
          </div>
          <div css={styles.inputLabelStyle}>
            <PasswordField
              label="Password"
              autoComplete="off"
              placeholder="Enter Password"
              id={`${serviceId}_password`}
              name={`${serviceId}[password]`}
              value={password}
              onChange={setPassword}
              onKeyDown={onKeyDown}
              orientation="horizontal"
            />
          </div>
        </Container>

        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Button
            iconLeft={testing ? 'loading' : 'connect'}
            disabled={!validCredentials || testing}
            onClick={(): void => saveConnection()}
            data-testid="credentials-connect-button"
          >
            {testing ? 'Connecting' : 'Connect'}
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

const CredentialsModal = React.memo(CredentialsModalComponent);

export default CredentialsModal;
