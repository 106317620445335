import React, { useRef, useState } from 'react';

import {
  resizerHandler,
  resizerHandlerDragArea
} from 'src/components/primitives/lib/Spreadsheet/HeaderCell/styles';
import { ResizeProps } from 'src/components/primitives/lib/Spreadsheet/HeaderCell/types';
type DragStateType = {
  dragging: boolean;
  startX: number;
  originalSize: number;
  lastX?: number;
};
export default function ResizeHandler({
  size,
  onResize,
  onFinishResize,
  onAutoResize
}: ResizeProps) {
  const [isDragging, setIsDragging] = useState(false);
  const dragState = useRef<DragStateType>({
    dragging: false,
    startX: 0,
    originalSize: 0
  });

  const mouseMove = (e: MouseEvent) => {
    if (dragState.current.dragging) {
      const diff = e.pageX - dragState.current.startX;
      onResize(dragState.current.originalSize + diff);
      dragState.current.lastX = e.pageX;
    }
  };

  const mouseUp = (e: MouseEvent) => {
    const diff = e.pageX - dragState.current.startX;
    size = dragState.current.originalSize + diff;
    typeof onFinishResize === 'function' && onFinishResize(size);
    setIsDragging(false);
    dragState.current.dragging = false;
    document.removeEventListener('mousemove', mouseMove, { capture: true });
    document.removeEventListener('mouseup', mouseUp, { capture: true });
  };

  const mouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    document.addEventListener('mousemove', mouseMove, { capture: true });
    document.addEventListener('mouseup', mouseUp, { capture: true });
    setIsDragging(true);
    dragState.current.dragging = true;
    dragState.current.startX = e.pageX;
    dragState.current.originalSize = size;
  };

  const doubleClick = () => {
    typeof onAutoResize === 'function' && onAutoResize();
  };

  return (
    <div
      css={resizerHandlerDragArea(isDragging)}
      tabIndex={0}
      role="button"
      onMouseDown={mouseDown}
      onDoubleClick={doubleClick}
    >
      <div css={resizerHandler} />
    </div>
  );
}
