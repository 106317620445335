import { Container } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import HorizontalLoadingIcon from 'src/components/icons/HorizontalLoadingIcon';

export const Loading = (): ReactElement => {
  return (
    <Container
      justifyContent="center"
      fillHeight={true}
      fillWidth={true}
      orientation="horizontal"
    >
      <Container
        fillWidth={false}
        justifyContent="center"
        orientation="vertical"
      >
        <HorizontalLoadingIcon height="8" />
      </Container>
    </Container>
  );
};
