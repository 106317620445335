import { ClickPipePostgresImport } from 'shared/src/dataLoading';
import { PostgresSourceRequest } from 'types/protocol';

export function getPostgresConfigWithCredentials<T extends ClickPipePostgresImport>(model: T): PostgresSourceRequest {
  return {
    id: model.id,
    type: model.type,
    config: {
      type: model.data.auth.type,
      host: model.data.auth.host,
      port: model.data.auth.port,
      database: model.data.auth.database,
      username: model.data.auth.username,
      password: model.data.auth.password
    },
    validateCDC: model.data.cdcSettings.replicationMode === 'cdc'
  };
}
