import { useCallback } from 'react';

import { v4 as uuid } from 'uuid';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

import { SavedQueryResult } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import type { TableDrop } from 'shared/src/types/query';
import { Table } from 'shared/src/clickhouse/types';
import { usePlaceholderParams } from 'src/lib/routes/usePlaceholderParams';
import { useParams } from 'src/lib/routes/useParams';
import { emptyTableState } from 'shared/src/tableSchema';
import { getCurrentServiceId } from 'src/state/service';

import {
  getCachedDatabaseValue,
  getSelectedDatabaseCacheKey,
  useSelectedDatabase
} from 'src/metadata/selectedDatabase';
import { useSetDatabases } from 'src/metadata/metadataState';

import { QueryTab, RightBarOption, Tab, TableRightBarOption, TableTab } from 'src/state/tabs/types';
import { Params, useLocation } from 'react-router-dom';
import { routes } from 'src/lib/routes';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { atomWithScopedStorage, getInitialValue, getScopedStorageKey } from 'src/state/persister';
import { useLoadDatabases } from 'src/components/MetadataInitializer/useLoadDatabases';
import { loadDatabaseMetadata } from 'src/components/MetadataInitializer/metadataEmitter';

export const SELECTED_TAB_ID_KEY = 'selectedTabId';
export const TABS_KEY = 'tabs';

const [lastSelectedTabIdAtom, lastSelectedTabServiceIdAtom] = atomWithScopedStorage<string | null>(
  SELECTED_TAB_ID_KEY,
  null,
  getCurrentServiceId() as string
);
const [tabsAtom, tabsServiceIdAtom] = atomWithScopedStorage<Tab[]>(TABS_KEY, [], getCurrentServiceId() as string);

const getTabDatabase = (tab: Tab): string | undefined => {
  if (tab.type === 'table') {
    return tab.table.schema;
  }
  if (tab.type === 'query') {
    return tab.database;
  }
  return undefined;
};

// This atom is used to determine if the tabs are being restored from the cache in local storage.
// Since the application is only fully functional after the tabs are restored, we use this atom to determine if the application is fully loaded.
const isRestoringTabsAtom = atom(true);

export const useIsRestoringTabs = (): boolean => {
  return useAtomValue(isRestoringTabsAtom);
};

export function useReloadPersistedTabs(): () => Promise<void> {
  const { setSelectedTab } = useTabActions();
  const setTabs = useSetAtom(tabsAtom);
  const setSelectedTabId = useSetAtom(lastSelectedTabIdAtom);
  const { selectedDatabase, setSelectedDatabase } = useSelectedDatabase();
  const getDatabases = useLoadDatabases();
  const setDatabases = useSetDatabases();
  const { databaseName: databaseFromUrl, tableName: tableNameFromUrl } = useParams();
  const setTabsServiceId = useSetAtom(tabsServiceIdAtom);
  const setLastSelectedTabServiceId = useSetAtom(lastSelectedTabServiceIdAtom);
  const [_, setIsRestoringTabs] = useAtom(isRestoringTabsAtom);

  return async (): Promise<void> => {
    const currentServiceId = getCurrentServiceId();
    if (!currentServiceId) {
      return;
    }

    setIsRestoringTabs(true);
    try {
      const lastSelectedTabIdValue = getInitialValue(getScopedStorageKey(SELECTED_TAB_ID_KEY, currentServiceId), null);
      const tabsValue = getInitialValue<Array<Tab>>(getScopedStorageKey(TABS_KEY, currentServiceId), []);
      setTabsServiceId(currentServiceId);
      setLastSelectedTabServiceId(currentServiceId);
      setSelectedTabId(lastSelectedTabIdValue);
      setTabs(tabsValue);

      // Use database from the URL if available.
      // If not, use the last selected database from cache.
      let newDatabase = databaseFromUrl ?? getCachedDatabaseValue(getSelectedDatabaseCacheKey(currentServiceId));
      const tab = tabsValue.find((tab) => tab.id === lastSelectedTabIdValue);

      if (tab && !tableNameFromUrl) {
        // the set select tab will set the database based on the tab type
        setSelectedTab(tab);
        newDatabase = getTabDatabase(tab) ?? newDatabase;
      }

      try {
        const databases = await getDatabases();
        setDatabases(databases.map((dbName) => ({ name: dbName })));
        // If the database is not available, we set it to the first available database
        const assignableDatabase = databases.includes(newDatabase) ? newDatabase : databases[0];
        setSelectedDatabase(currentServiceId, assignableDatabase);

        // this is the edge case that will force the database to be reloaded in case we are switching between orgs that have the same database
        if (selectedDatabase === newDatabase && newDatabase === assignableDatabase) {
          loadDatabaseMetadata({
            database: assignableDatabase,
            resetCurrentMetadata: true,
            serviceId: currentServiceId
          });
        }
      } catch (error) {
        console.error('Error getting databases', error);
      }
    } finally {
      setIsRestoringTabs(false);
    }
  };
}

export function defaultQueryTab(selectedDatabase: string): QueryTab {
  return {
    id: uuid(),
    saved: false,
    queryId: uuid(),
    preview: true,
    updatedAt: new Date().toISOString(),
    rightBarType: [],
    query: '',
    chartConfig: {},
    resultsDisplayType: 'table',
    type: 'query',
    database: selectedDatabase,
    search: '',
    title: 'Untitled query'
  };
}

export type LastSelectedTab = {
  tab: Tab;
  idx: number;
};

export function createTableTab(table: Table, tab: Partial<TableTab> = {}): TableTab {
  return {
    type: 'table',
    preview: false,
    id: uuid(),
    title: table.tableName,
    rightBarType: [],
    filterConfig: {
      filters: [],
      sorts: []
    },
    table,
    ...tab
  };
}

export function useTabsState(): [Tab[], (tabs: Tab[]) => void] {
  return useAtom(tabsAtom);
}

export type AddQueryTabOptions = {
  apiInsightsOpen?: boolean;
  endpointId?: string;
};

export type TabActions = {
  addTab: (tab: Tab) => void;
  selectTable: (table: Table) => void;
  useCloseTab: () => (index: number) => void;
  addNewImportTab: () => void;
  addTableTab: (table: Table, tab?: Partial<TableTab>) => void;
  addNewQueryTab: (tab?: Partial<QueryTab>) => void;
  closeTabs: () => void;
  addQueryTab: (query: SavedQueryResult, options?: AddQueryTabOptions) => void;
  setSelectedTabIndex: (index: number) => void;
  addCreateTableTab: () => void;
  setSelectedTab: (tab: Tab | null) => void;
  useReplaceCurrentTab: () => (tab: Tab) => void;
  addOrUpdateTable: (table: Table, tab?: Partial<TableTab>) => void;
  selectQuery: (query: SavedQueryResult) => void;
  selectQueryApiEndpoint: (query: SavedQueryResult, endpointId: string) => void;
  updateTableTabs: (tables: Table[]) => void;
};

export function useTabActions(): TabActions {
  const { selectedDatabase, setSelectedDatabase } = useSelectedDatabase();
  const setSelectedTabId = useSetAtom(lastSelectedTabIdAtom);
  const [tabs, setTabs] = useAtom(tabsAtom);
  const updateTab = useUpdateTab();
  const [placeholderParams] = usePlaceholderParams();

  /**
   * Updates the table tabs based on the provided array of tables.
   * This function should be called when the table metadata is updated.
   * @param {Table[]} tables - An array of tables to update the tabs with.
   */
  function updateTableTabs(tables: Table[]): void {
    tabs.forEach((tab) => {
      if (tab.type !== 'table') {
        return;
      }
      const table = tables.find((table) => {
        if (table.id && tab.table.id) {
          return table.id === tab.table.id;
        }
        return table.tableName === tab.table.tableName;
      });
      if (!table || table === tab.table) {
        return;
      }
      updateTab(tab.id, {
        title: table.tableName, // Update tab title
        table
      });
    });
  }

  function setSelectedTab(tab: Tab | null): void {
    const serviceId = getCurrentServiceId() as string;

    setSelectedTabId(tab?.id ?? null);

    if (tab === null && serviceId) {
      navigateTo(routes.home({ serviceId }));
    } else if (tab === null) {
      navigateTo(routes.root(placeholderParams));
    } else if (tab.type === 'query') {
      if (tab.database) {
        setSelectedDatabase(serviceId, tab.database);
      }
      navigateTo(
        routes.query({
          serviceId,
          queryId: tab.queryId,
          placeholderParams
        })
      );
    } else if (tab.type === 'table') {
      if (tab.table.schema) {
        setSelectedDatabase(serviceId, tab.table.schema);
      }
      const url = routes.tables({
        serviceId,
        databaseName: tab.table.schema ?? '',
        tableName: tab.table.tableName
      });
      navigateTo(url);
    } else if (tab.type === 'createTable') {
      navigateTo(routes.createTable({ serviceId }));
    } else if (tab.type === 'importTab') {
      navigateTo(routes.import({ serviceId, importId: tab.state.id }));
    }
  }

  function setSelectedTabIndex(index: number): void {
    if (index < 0) {
      setSelectedTab(null);
    } else {
      const tab = tabs[index];
      setSelectedTab(tab ?? null);
    }
  }

  function addTab(tab: Tab): void {
    const newTabs = [...tabs];

    const previewTabIndex = tabs.findIndex((tab) => tab.preview);

    if (previewTabIndex > -1) {
      newTabs[previewTabIndex] = tab;
    } else {
      newTabs.push(tab);
    }

    setTabs(newTabs);
    setSelectedTab(tab);
  }

  function addNewQueryTab(tab?: Partial<QueryTab>): void {
    addTab({
      ...defaultQueryTab(selectedDatabase),
      updatedAt: new Date().toISOString(),
      ...tab
    });
  }

  function addNewImportTab(): void {
    addTab({
      id: uuid(),
      type: 'importTab',
      preview: false,
      title: 'New Import',
      state: {
        id: uuid(),
        name: 'New Import',
        type: null,
        status: 'new'
      }
    });
  }

  function addQueryTab(query: SavedQueryResult, options?: AddQueryTabOptions): void {
    addTab({
      ...defaultQueryTab(selectedDatabase),
      type: 'query',
      queryId: query.id,
      title: query.name,
      query: query.query,
      updatedAt: query.updatedAt,
      saved: true,
      queryVariables: query.parameters,
      chartConfig: query.chartConfig ?? {},
      apiInsightsOpen: options?.apiInsightsOpen,
      queryEndpointId: options?.endpointId
    });
  }

  function addTableTab(table: Table, tab: Partial<TableTab> = {}): void {
    addTab(createTableTab(table, tab));
  }

  function addCreateTableTab(): void {
    addTab({
      type: 'createTable',
      id: uuid(),
      preview: false,
      title: 'Create table',
      state: {
        tableState: emptyTableState('default'),
        previewOpen: false,
        queryError: undefined
      }
    });
  }

  function useCloseTab(): (index: number) => void {
    const currentIndex = useSelectedTabIndex();
    const tabsFromState = useTabs();

    return useCallback(
      (index: number) => {
        const newTabs = [...tabsFromState];

        newTabs.splice(index, 1);

        let newIndex = currentIndex;
        if (newTabs.length === 0) {
          newIndex = -1;
        } else if (index <= currentIndex) {
          newIndex = Math.max(0, currentIndex - 1);
        }

        setTabs(newTabs);
        setTimeout(() => {
          setSelectedTab(newTabs[newIndex] ?? null);
        });
      },
      [currentIndex, tabsFromState]
    );
  }

  function useReplaceCurrentTab(): (tab: Tab) => void {
    const selectedTabIndex = useSelectedTabIndex();

    return (tab: Tab) => {
      const newTabs = [...tabs];
      newTabs[selectedTabIndex] = tab;
      setTabs(newTabs);
      setSelectedTabIndex(selectedTabIndex);
    };
  }

  function selectQuery(query: SavedQueryResult): void {
    const queryIndex = tabs.findIndex((tab) => tab.type === 'query' && tab.queryId === query.id);
    if (queryIndex > -1) {
      return setSelectedTabIndex(queryIndex);
    }

    addQueryTab(query);
  }

  function selectTable(table: Table): void {
    const tableIndex = tabs.findIndex((tab) => tab.type === 'table' && tab.table?.tableName === table.tableName);
    if (tableIndex > -1) {
      return setSelectedTabIndex(tableIndex);
    }

    addTableTab(table);
  }

  function selectQueryApiEndpoint(query: SavedQueryResult, endpointId: string): void {
    const queryId = query.id;
    const queryIndex = tabs.findIndex((tab) => tab.type === 'query' && tab.queryId === queryId);

    if (queryIndex > -1) {
      const tab = tabs[queryIndex] as QueryTab;
      updateTab(tab.id, {
        apiInsightsOpen: true,
        queryEndpointId: endpointId
      });
      setSelectedTabIndex(queryIndex);
    } else {
      addQueryTab(query, { apiInsightsOpen: true, endpointId });
    }
  }

  function addOrUpdateTable(table: Table, tab: Partial<TableTab> = {}): void {
    const currentTab = tabs.find(
      (tab) => tab.type === 'table' && tab.table?.tableName === table.tableName && tab.table?.schema === table.schema
    );
    if (typeof currentTab === 'undefined') {
      addTableTab(table, tab);
      return;
    }
    if (Object.keys(tab).length > 0) {
      updateTab(currentTab.id, tab);
    }
    setSelectedTabIndex(tabs.findIndex((tab) => tab.id === currentTab.id));
  }

  function closeTabs(): void {
    setTabs([]);
    setTimeout(() => {
      setSelectedTab(null);
    });
  }

  return {
    setSelectedTab,
    setSelectedTabIndex,
    addTab,
    addNewQueryTab,
    addNewImportTab,
    addQueryTab,
    addTableTab,
    addCreateTableTab,
    useCloseTab,
    useReplaceCurrentTab,
    selectTable,
    addOrUpdateTable,
    closeTabs,
    selectQuery,
    updateTableTabs,
    selectQueryApiEndpoint
  };
}

export const useCreateTable = (): Tab | undefined => {
  const tabs = useTabs();
  return tabs.find((tab) => tab.type === 'createTable');
};

export function useSetTabs(): (newTabs: Tab[]) => void {
  return useSetAtom(tabsAtom);
}

const updateTabWriteAtom = atom(null, (get, set, id: string, tab: Partial<Tab>) => {
  const tabs = get(tabsAtom);
  const newTabs = [...tabs];
  const index = newTabs.findIndex((tab) => tab.id === id);
  if (index > -1) {
    const newTab = {
      ...newTabs[index],
      ...tab
    } as Tab;

    newTabs[index] = newTab;
    set(tabsAtom, newTabs);
    return newTab;
  }
});

export type UpdateTabFunc = (id: string, tab: Partial<Tab>) => Tab | undefined;

export function useUpdateTab(): UpdateTabFunc {
  return useSetAtom(updateTabWriteAtom);
}

export function useLastSelectedTab(): LastSelectedTab | undefined {
  const lastTabId = useAtomValue(lastSelectedTabIdAtom);
  const tabs = useTabs();
  const lastIdx = tabs.findIndex((tab) => tab.id === (lastTabId ?? ''));

  const tab = tabs[lastIdx];

  if (!(lastIdx && tab)) {
    return undefined;
  }

  return {
    tab,
    idx: lastIdx
  };
}

export function useSelectedTab(): Tab | undefined {
  const selectedTabIndex = useSelectedTabIndex();
  const tabs = useTabs();

  return tabs[selectedTabIndex];
}

export function useSelectedTable(): Table | null {
  const selectedTab = useSelectedTab();
  let selectedTable = null;
  if (selectedTab?.type === 'table') {
    selectedTable = selectedTab.table;
  }
  return selectedTable;
}

export function getDecodedTableAndDatabaseFromParams(
  params: Record<string, string> | Params<string>
): [string, string] {
  const SPLAT_SUB_ROUTE_REGEX = /(.+)\/table\/(.+)/;

  if (params.databaseName && params.tableName) {
    const decodedDatabaseName = decodeURIComponent(params.databaseName);
    const decodedTableName = decodeURIComponent(params.tableName);
    return [decodedDatabaseName, decodedTableName];
  }

  if (!params['*']) {
    throw new Error('Unexpected route');
  }

  const match = params['*'].match(SPLAT_SUB_ROUTE_REGEX);
  if (!match || match.length !== 3) {
    throw new Error('Unexpected route');
  }

  const decodedDatabaseName = match[1];
  const decodedTableName = match[2];
  return [decodedDatabaseName, decodedTableName];
}

export function useSelectedTabIndex(): number {
  const lastSelectedTabId = useAtomValue(lastSelectedTabIdAtom);
  const tabs = useAtomValue(tabsAtom);
  const params = useParams();
  const location = useLocation();

  if (params.queryId !== undefined) {
    return tabs.findIndex((tab) => tab.type === 'query' && tab.queryId === params.queryId);
  } else if (params.tableName !== undefined && params.databaseName !== undefined) {
    /*
      This is a hack to be able to support table names with crazy characters.
      React router does not know how to handle them on path variables. Mainly due to the @ and ? characters.
      Since we don't control how the React router regex, we added one special catchall route that allows us to capture all chars
     */
    const [decodedDatabaseName, decodedTableName] = getDecodedTableAndDatabaseFromParams(params);

    return tabs.findIndex(
      (tab) =>
        tab.type === 'table' && tab.table.tableName === decodedTableName && tab.table.schema === decodedDatabaseName
    );
  } else if (location.pathname.endsWith('/createTable')) {
    return tabs.findIndex((tab) => tab.type === 'createTable');
  } else if (params.importId) {
    return tabs.findIndex((tab) => tab.type === 'importTab' && tab.state.id === params.importId);
  } else {
    const lastTabId = lastSelectedTabId ?? '';
    const lastIdx = tabs.findIndex((tab) => tab.id === lastTabId);
    return lastIdx ?? -1;
  }
}

export function useTabs(): Tab[] {
  return useAtomValue(tabsAtom);
}

export function useTabQueries(): string[] {
  const tabs = useTabs();
  const queryIds: string[] = [];
  for (const tab of tabs) {
    if (tab.type === 'query' && tab.queryRunId) {
      queryIds.push(tab.queryRunId);
    }
  }
  return queryIds;
}

const removeTableTabsWriteAtom = atom(null, (get, set, tableDrops: TableDrop[]) => {
  const tabs = get(tabsAtom);
  const allValidTabs = tabs.filter((tab) => {
    if (tab.type !== 'table') {
      return true;
    }

    return !tableDrops.find((drop) => {
      return (
        drop.tableName === tab.table.tableName && drop.database === tab.table.schema && drop.type === tab.table.type
      );
    });
  });
  set(tabsAtom, allValidTabs);
});

export function useRemoveTableTabs(): (tableDrops: TableDrop[]) => void {
  return useSetAtom(removeTableTabsWriteAtom);
}

const updateRightBarTypesWriteAtom = atom(
  null,
  (
    _,
    set,
    tabId: string,
    tabRightBarType: TableRightBarOption[] | RightBarOption[] | undefined,
    type: TableRightBarOption | RightBarOption,
    value?: string
  ) => {
    if (!tabId) {
      return;
    }

    const tabValues: Partial<TableTab> = {};
    tabValues.rightBarType = tabRightBarType ?? [];
    if (tabValues.rightBarType[0] !== type) {
      if (tabValues.rightBarType.includes(type)) {
        tabValues.rightBarType = tabValues.rightBarType.filter((option) => option !== type);
      }
      tabValues.rightBarType.push(type);
    }
    if (value && type === 'viewCell') {
      tabValues.cellValue = value;
    }
    set(updateTabWriteAtom, tabId, tabValues);
  }
);

export function useUpdateRightBarTypes(): (
  tabId: string,
  tabRightBarType: TableRightBarOption[] | RightBarOption[] | undefined,
  type: TableRightBarOption | RightBarOption,
  value?: string
) => void {
  return useSetAtom(updateRightBarTypesWriteAtom);
}

export function useCloseRightBar(): (state?: TableRightBarOption) => void {
  const updateTab = useUpdateTab();
  const selectedTab = useSelectedTab();
  const optionsIfDefined =
    selectedTab?.type === 'table' && selectedTab.rightBarType ? selectedTab.rightBarType : undefined;

  return useCallback(
    (state?: TableRightBarOption) => {
      if (selectedTab?.id) {
        let options = optionsIfDefined ?? [];
        if (state === undefined || options[options.length - 1] === state) {
          options = options.slice(0, options.length - 1);
          updateTab(selectedTab.id, {
            rightBarType: options
          });
        }
      }
    },
    [optionsIfDefined, selectedTab?.id, updateTab]
  );
}
