import { OrganizationBillingStatus, OrganizationCommitment } from '@cp/common/protocol/Organization';
import { MILLIS_PER_DAY } from '@cp/common/utils/DateTimeUtils';

export type BillingAlertType =
  | 'CREDIT_NEAR_DEPLETION'
  | 'CREDIT_NEAR_EXPIRATION'
  | 'CREDIT_DEPLETED'
  | 'CREDIT_EXPIRED';

export interface BillingAlert {
  alertType: 'BILLING';
  subAlertType: BillingAlertType;
  alertText: string;
}

export interface BillingAlertParams {
  billingStatus: OrganizationBillingStatus;
  trialCommitment: OrganizationCommitment | null;
}

// Determine whether a BillingAlert needs to be created based on billing status and trial commitment
export const getBillingAlert = (billingAlertParams: BillingAlertParams): BillingAlert | null => {
  if (!billingAlertParams.trialCommitment) {
    return null;
  }

  const billingAlertType = getBillingAlertType(billingAlertParams.billingStatus, billingAlertParams.trialCommitment);
  if (!billingAlertType) {
    return null;
  }

  const billingAlertText = getBillingAlertText(billingAlertType, billingAlertParams.trialCommitment);
  if (!billingAlertText) {
    return null;
  }

  return {
    alertType: 'BILLING',
    subAlertType: billingAlertType,
    alertText: billingAlertText
  };
};

export const getBillingAlertType = (
  billingStatus: OrganizationBillingStatus,
  trialCommitment: OrganizationCommitment
): BillingAlertType | null => {
  const creditDepletionPercentThreshold = 50;
  const creditExpirationDaysLeftThreshold = 1;
  const billingStatusesToShow: Set<OrganizationBillingStatus> = new Set([
    'IN_TRIAL',
    'IN_TRIAL_GRACE_PERIOD',
    'DECOMMISSIONED'
  ]);
  if (!billingStatusesToShow.has(billingStatus)) {
    return null;
  }

  const { usagePercent, expirationDate } = trialCommitment;

  if (usagePercent >= 100) {
    return 'CREDIT_DEPLETED';
  }

  if (expirationDate < Date.now()) {
    return 'CREDIT_EXPIRED';
  }

  if (usagePercent >= creditDepletionPercentThreshold) {
    return 'CREDIT_NEAR_DEPLETION';
  }

  if (expirationDate < Date.now() + creditExpirationDaysLeftThreshold * MILLIS_PER_DAY) {
    return 'CREDIT_NEAR_EXPIRATION';
  }

  return null;
};

export const getBillingAlertText = (
  alertType: BillingAlertType,
  trialCommitment: OrganizationCommitment
): string | null => {
  if (!alertType) {
    return null;
  }
  const { usagePercent, totalDurationInDays, timeRemainingInDays } = trialCommitment;
  const roundedUsagePercent = +usagePercent.toFixed(2);
  if (alertType.startsWith('CREDIT')) {
    let creditAlertText = 'Credit alert - ';

    switch (alertType) {
      case 'CREDIT_DEPLETED':
      case 'CREDIT_NEAR_DEPLETION':
        creditAlertText += `${roundedUsagePercent}% of your trial credits have been used.`;
        break;

      case 'CREDIT_EXPIRED':
        creditAlertText += `Your ${totalDurationInDays} day free trial has now expired.`;
        break;
      case 'CREDIT_NEAR_EXPIRATION': {
        const daysRemainingMessage =
          timeRemainingInDays === 0
            ? 'There is less than one day left'
            : timeRemainingInDays === 1
            ? 'There is one day left'
            : `There are ${timeRemainingInDays} days left`;
        const totalTrialDaysMessage =
          totalDurationInDays === 0 ? 'in your free trial.' : `in your ${totalDurationInDays} day free trial.`;
        creditAlertText += `${daysRemainingMessage} ${totalTrialDaysMessage}`;
        break;
      }
    }
    return creditAlertText;
  }
  return null;
};
