import {
  BigStat,
  CodeBlock,
  Container,
  GridContainer,
  Panel,
  Title,
  WarningAlert,
  useCUITheme
} from '@clickhouse/click-ui';
import {
  TimeSeriesChart,
  TimeSeriesSeriesDescriptor
} from '@clickhouse/viz-house';
import { assertTruthy } from '@cp/common/utils/Assert';
import { ReactElement, useState } from 'react';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import useQueryApiEndpointStats from 'src/queryEndpoints/queryEndpointsController/useQueryApiEndpointStats';
import {
  useApiEndpointByQueryId,
  useIsLoadingQueryApiEndpoints
} from 'src/queryEndpoints/stateManager';
import { useSelectedTab } from 'src/state/tabs';
import { QueryEndpointDetail } from 'types/protocol/queryEndpoints';
import { formatMoment } from 'src/lib/formatters/dateTimeFormatter';
import { GetQueryEndpointsStatsResult } from 'shared/src/queryEndpoints/protocol/index';
import {
  SelectableTimePeriod,
  TimeSelect
} from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { getCurlCommand } from 'shared/src/queryEndpoints';
import config from 'src/lib/config';
import HorizontalLoadingIcon from 'src/components/icons/HorizontalLoadingIcon';
import { useApiKeys } from 'src/apiKey/apiKeyController';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

type EndpointInsightsProps = {
  endpoint: QueryEndpointDetail;
};

function NoEndpointFound(): ReactElement {
  return (
    <MaxWidthContainer>
      <Container orientation="vertical">
        <WarningAlert
          title="Endpoint not found"
          text="Could not find any endpoint associated with this query"
        />
      </Container>
    </MaxWidthContainer>
  );
}

function EndpointsStatsLoading(): ReactElement {
  return <HorizontalLoadingIcon />;
}

type EndpointStatsProps = {
  stats: GetQueryEndpointsStatsResult;
  period: SelectableTimePeriod;
  onPeriodChange: (period: SelectableTimePeriod) => void;
};

function EndpointStats({
  stats,
  period,
  onPeriodChange
}: EndpointStatsProps): ReactElement {
  const theme = useCUITheme();

  const chartSortedColors = [
    theme.global.color.chart.bars.blue,
    theme.global.color.chart.bars.orange,
    theme.global.color.chart.bars.green,
    theme.global.color.chart.bars.teal,
    theme.global.color.chart.bars.fuchsia,
    theme.global.color.chart.bars.violet
  ];

  const currentOrg = useCurrentOrganizationOrThrow();
  const { keys } = useApiKeys({ organizationId: currentOrg.id });
  const keysNameById: Record<string, string> = {};
  keys.forEach((key) => {
    keysNameById[key.id] = key.name;
  });

  const vizHouseSeries: Array<TimeSeriesSeriesDescriptor> = Object.keys(
    stats.series
  ).map((key, idx) => {
    const keyName = keysNameById[key] || key;
    return {
      type: 'line',
      name: keyName,
      values: stats.series[key].map((point) => ({
        x: new Date(point[0]).getTime(),
        y: point[1]
      })),
      color: chartSortedColors[idx]
    };
  });
  const chart = (
    <TimeSeriesChart
      period={period as string}
      series={vizHouseSeries}
      yAxis={{
        title: 'Requests'
      }}
    />
  );

  return (
    <Container orientation="vertical" gap="md">
      <Panel title="Requests over time" fillWidth hasBorder>
        <Container justifyContent="space-between" gap="xs">
          <Title type="h3">Requests over time</Title>
          <TimeSelect onSelect={onPeriodChange} selectedPeriod={period} />
        </Container>
        {chart}
      </Panel>
      <GridContainer gridTemplateColumns="1fr 1fr" gap="md">
        <BigStat
          title={stats.totalRequests}
          label="Total requests"
          size="sm"
          spacing="lg"
        />
        <BigStat
          title={stats.totalRequests ? formatMoment(stats.lastRequest) : '-'}
          size="sm"
          spacing="lg"
          label="Last request"
        />
      </GridContainer>
    </Container>
  );
}

function EndpointInsights({ endpoint }: EndpointInsightsProps): ReactElement {
  const [period, setPeriod] = useState<SelectableTimePeriod>('LAST_DAY');
  const { stats, error, loading } = useQueryApiEndpointStats(
    endpoint.id,
    period
  );

  const query = useSavedQuery(endpoint.queryId);
  const curlCommand = getCurlCommand({
    parameters: query?.parameters || ({} as Record<string, string>),
    queryEndpointHost: config.apiUrl.replace('/.api', ''),
    queryEndpointId: endpoint.id,
    format: 'JSONEachRow',
    version: endpoint.version
  });

  return (
    <MaxWidthContainer>
      <Container orientation="vertical" padding="xs" gap="md">
        <Panel hasBorder>
          <Title type="h3">API Endpoint</Title>
          <CodeBlock language="bash" wrapLines>
            {curlCommand}
          </CodeBlock>
        </Panel>
        {loading && <EndpointsStatsLoading />}
        {error && <WarningAlert title="Failed to fetch stats" text={error} />}
        {!loading && !error && stats && (
          <EndpointStats
            stats={stats}
            period={period}
            onPeriodChange={setPeriod}
          />
        )}
      </Container>
    </MaxWidthContainer>
  );
}

export default function ApiEndpointInsights(): ReactElement | null {
  const selectedTab = useSelectedTab();
  assertTruthy(selectedTab && selectedTab.type === 'query', 'Invalid tab type');
  const endpoint = useApiEndpointByQueryId(selectedTab.queryId);
  const loadingEndpoints = useIsLoadingQueryApiEndpoints();

  if (loadingEndpoints) {
    return null;
  }

  if (!endpoint) {
    return <NoEndpointFound />;
  }

  return <EndpointInsights endpoint={endpoint} />;
}
