import React, { HTMLAttributes } from 'react';

import { Theme, css } from '@emotion/react';

type Props = {
  progress: number;
  barHeight: string;
  barWidth: string;
  barColor: string;
  bgColor: string;
  borderColor: string;
} & HTMLAttributes<HTMLDivElement>;

const progressBarWrapperStyle =
  (barHeight: string, barWidth: string, bgColor: string, borderColor: string) =>
  (theme: Theme) =>
    css({
      width: barWidth,
      minWidth: barWidth,
      height: barHeight,
      minHeight: barHeight,
      boxSizing: 'border-box',
      background: bgColor,
      border: `solid 1px ${borderColor}`,
      borderRadius: '4px',
      position: 'relative'
    });

const barStyle =
  (width: number, height: string, color: string) => (theme: Theme) =>
    css({
      position: 'absolute',
      width: `${width}%`,
      top: 0,
      left: 0,
      height: height,
      transition: 'width 200ms ease-in-out',
      background: color,
      borderRadius: '4px'
    });

function progressBar({
  progress,
  barHeight,
  barWidth,
  barColor,
  bgColor,
  borderColor,
  ...otherDivProps
}: Props): JSX.Element | null {
  if (!progress) {
    return null;
  }

  let progressString = progress.toFixed(2);

  if (progressString.endsWith('.00')) {
    progressString = progressString.replace('.00', '');
  }

  return (
    <div
      css={progressBarWrapperStyle(barHeight, barWidth, bgColor, borderColor)}
      {...otherDivProps}
    >
      <div css={barStyle(progress, barHeight, barColor)}></div>
    </div>
  );
}

export default React.memo(progressBar);
