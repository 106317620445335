import {
  CardPrimary,
  Container,
  Icon,
  IconName,
  Label,
  Panel,
  Spacer
} from '@clickhouse/click-ui';
import { CloudProvider } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import styled from 'styled-components';

const CustomCardWrapper = styled(Container)`
  & > * {
    width: auto;
  }
  ${({ theme }): string => `
    @media (max-width: ${theme.breakpoint.sizes.md}) {
      gap: 0.25rem;
    }
  `}
`;

const CustomCardPrimary = styled(CardPrimary)`
  padding: 0.25rem 2rem;
  width: fit-content;
  ${({ theme }): string => `
    @media (max-width: ${theme.breakpoint.sizes.sm}) {
      padding-inline: 1rem;
    }
  `}
`;
export interface CloudProviderSelectionProps {
  selectedProvider: CloudProvider;
  onProviderChange: (p: CloudProvider) => void;
  availableProviders: Array<CloudProvider>;
}

const CloudProviderSelection = ({
  selectedProvider,
  onProviderChange,
  availableProviders
}: CloudProviderSelectionProps): ReactElement => {
  return (
    <Container
      orientation="vertical"
      padding="none"
      alignItems="start"
      fillWidth
    >
      <Label>Cloud provider</Label>
      <Spacer size="sm" />
      <Panel padding="none" fillWidth>
        <CustomCardWrapper
          padding="none"
          gap="lg"
          fillWidth
          data-testid="providers-cards"
          justifyContent="start"
          isResponsive={false}
        >
          {availableProviders.map((provider) => (
            <CustomCardPrimary
              key={provider}
              size="md"
              isSelected={selectedProvider === provider}
              onClick={(): void => {
                onProviderChange(provider);
              }}
              data-testid={`${provider.toLowerCase()}-card`}
              data-selected={
                selectedProvider === provider ? 'selected' : 'unselected'
              }
              alignContent="start"
            >
              <Icon
                name={`${provider.toLocaleLowerCase()}` as IconName}
                size="xl"
              />
            </CustomCardPrimary>
          ))}
        </CustomCardWrapper>
      </Panel>
    </Container>
  );
};

export default CloudProviderSelection;
