import React, { ReactElement, useState } from 'react';
import {
  Dialog,
  Button,
  TextField,
  Separator,
  Text,
  Spacer,
  Container
} from '@clickhouse/click-ui';

type DialogType = 'pause' | 'resume' | 'retry';

interface Props {
  type: DialogType;
  open: boolean;
  name: string;
  pipeId: string;
  onAction: () => Promise<boolean>;
  onCancel: () => void;
}

/**
 * ClickPipes can be paused / resumed / retried by the user via the user interface
 * This component is a common dialog across those various states. The onAction
 * property is the function that makes the request to the clickpipes api
 *
 * @param type
 * @param open
 * @param pipeId
 * @param name
 * @param onAction
 * @param onCancel
 * @constructor
 */
const ClickPipeActionDialog = ({
  type,
  open,
  pipeId,
  name,
  onAction,
  onCancel
}: Props): ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const getTitle = (): string => {
    if (type === 'pause') {
      return 'Pause ClickPipe';
    }
    if (type === 'resume') {
      return 'Resume ClickPipe';
    }
    if (type === 'retry') {
      return 'Retry ClickPipe';
    }

    throw Error('Unexpected type', type);
  };

  const getDescription = (): ReactElement => {
    if (type === 'pause') {
      return (
        <Text>
          Pausing <b>{name}</b> ({pipeId}) will stop data ingestion from this
          ClickPipe.
        </Text>
      );
    }
    if (type === 'resume') {
      return (
        <Text>
          Resuming <b>{name}</b> ({pipeId}) will start data ingestion from this
          ClickPipe.
        </Text>
      );
    }
    if (type === 'retry') {
      return (
        <Text>
          Retrying <b>{name}</b> ({pipeId}) will start data ingestion from this
          ClickPipe.
        </Text>
      );
    }

    throw Error('Unexpected type', type);
  };

  const executeAction = async (): Promise<void> => {
    setLoading(true);
    const isSuccess = await onAction();
    setLoading(false);
    setInputValue('');

    // A toast will appear if the action is unsuccessful

    if (isSuccess) {
      onCancel();
    }
  };

  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
          setInputValue('');
        }
      }}
    >
      <Dialog.Content title={getTitle()} showClose onClose={onCancel}>
        <Text size="md">
          {getDescription()}
          <Spacer size={'sm'} />
          To continue, please enter the name of your ClickPipe.
        </Text>
        <Spacer />
        <TextField
          value={inputValue}
          onChange={setInputValue}
          data-testid={`${type}-clickpipe-modal-input-field`}
        />
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Cancel" onClick={onCancel} />
          <Button
            type="primary"
            disabled={!(inputValue.trimEnd() === name)}
            loading={loading}
            onClick={executeAction}
            data-testid={`clickpipe-modal-${type}-button`}
          >
            {getTitle()}
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};

export default React.memo(ClickPipeActionDialog);
