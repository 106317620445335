import React, { useCallback } from 'react';

import { useDrop } from 'react-dnd-cjs';

import { getColumnType } from 'src/lib/chart';

import Column from './Column';
import { columnListStyle } from './styles';

export default function ColumnList({
  chartConfig,
  chartDataConfig,
  columnSearch,
  data,
  onDragEnd
}) {
  const [_, drop] = useDrop({
    accept: 'column',
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
    },
    drop: (item, monitor) => {
      return {
        dropZone: 'columns'
      };
    }
  });

  const filterColumns = useCallback(
    (config, column, search) => {
      // if (column.name === dragging) {
      //   return false
      // }

      if (
        search &&
        !column.name.toLowerCase().startsWith(search.toLowerCase())
      ) {
        return false;
      }

      return !Object.entries(chartDataConfig).reduce(
        (contains, [key, value]) => {
          if (value.type === 'column') {
            return contains || config[key] === column.name;
          } else if (value.type === 'columnArray') {
            return (
              contains ||
              (Array.isArray(config[key]) &&
                config[key].filter(
                  ({ column: columnName }) => columnName === column.name
                ).length > 0)
            );
          }

          return contains;
        },
        false
      );
    },
    [chartDataConfig]
  );

  const renderColumnItems = useCallback(
    (column, i, isData) => {
      return (
        <Column
          key={i}
          column={column.name}
          onDragEnd={onDragEnd}
          source="columns"
          type={getColumnType(data, column.name)}
          testId="column-list-item"
        />
      );
    },
    [data, onDragEnd]
  );

  if (!data) {
    return null;
  }

  return (
    <div css={columnListStyle} ref={drop}>
      {data &&
        data.columns &&
        data.columns
          .filter((column) => {
            return filterColumns(chartConfig, column, columnSearch);
          })
          .map((column, i) => renderColumnItems(column, i, false))}
    </div>
  );
}
