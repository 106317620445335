import { noop } from 'lodash';
import { KeyedMutator } from 'swr';
import styled from 'styled-components';
import { flex } from '@utility-styles';
import { createToast } from 'primitives';
import { MutatorOptions } from 'swr/_internal';
import React, { useEffect, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  Accordion,
  EllipsisContent,
  Link,
  Table,
  TableHeaderType,
  TableRowType,
  Tooltip,
  Text
} from '@clickhouse/click-ui';

import { routes } from 'src/lib/routes';
import { formatDataSize } from 'src/metrics/dataSize';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import ImportRightBar from 'src/components/ImportRightBar';
import Actions from 'src/components/ClickPipesList/Actions';
import { formatNumber } from 'src/lib/formatters/numberFormatter';
import StatusBadge from 'src/components/ClickPipesList/StatusBadge';
import { DbMetrics } from 'src/components/ClickPipesList/DbMetrics';
import { generateTableViewQuery } from 'src/components/TableView/utils';
import ClickPipeType from 'src/components/ClickPipesList/ClickPipeType';
import { getClickPipeStatus } from 'src/lib/dataLoading/clickpipes/getStatus';
import { ImportRightbarOption } from 'src/components/ImportRightBar/types';
import { ClickPipe, ClickPipeStatus, ClickPipesS3Status } from 'types/protocol';
import { useSqlQuery, useSqlQueryFunction } from 'src/lib/clickhouse/query';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useIsCurrentInstanceAwakeStatus } from 'src/instance/instanceController';
import { ClickPipeMetric } from 'src/components/ClickPipesList/ClickPipeMetric';
import {
  formatDateHuman,
  formatUtcTimestamp
} from 'src/lib/formatters/dateTimeFormatter';

const TableAccordion = styled(Accordion)`
  td > div {
    height: auto;
  }
`;

const AlignActionsHeader = styled.div`
  th:last-child div {
    justify-content: center;
  }

  thead > td > div {
    max-height: 24px;
  }
`;

const viewDataStyle = (status: ClickPipesS3Status): SerializedStyles =>
  css(`
    ${
      ['provisioning', 'success', 'running', 'stopping'].includes(
        status.toLocaleLowerCase()
      )
        ? 'cursor: not-allowed;'
        : 'cursor: pointer;'
    }
`);

function generateWhereClause(pipes: ClickPipe[]): string {
  return pipes
    ?.map(
      (pipe) =>
        `((database = '${pipe.destination.database}') AND (name = '${pipe.destination.table}'))`
    )
    .join(' OR ');
}

function sortPipes(
  pipes: ClickPipe[],
  sortDirection: 'asc' | 'desc'
): ReadonlyArray<ClickPipe> {
  if (sortDirection === 'asc') {
    return pipes.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }

  return pipes.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
}

type ClickPipeListProps = {
  pipes: ClickPipe[];
  serviceId: string;
  mutate: KeyedMutator<ClickPipe[]>;
};

function ClickPipesList({
  pipes,
  serviceId,
  mutate
}: ClickPipeListProps): JSX.Element | null {
  const api = useApiClient();
  const [rightBar, setRightBar] = useState<ImportRightbarOption | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [runQuery] = useSqlQueryFunction();
  const { isAwake } = useIsCurrentInstanceAwakeStatus();
  const [activePipe, setActivePipe] = useState<string | null>(null);

  const headers: Array<TableHeaderType> = [
    { label: 'Name', width: '15%' },
    { label: 'Type', width: '15%' },
    { label: 'Status', width: '10%' },
    { label: 'Data Size', width: '10%' },
    { label: 'Table data', width: '18%' },
    { label: 'Records', width: '12%' },
    {
      label: 'Created on',
      width: '12%',
      isSortable: true,
      sortPosition: 'start',
      sortDir: sortDirection
    },
    { label: 'Actions', width: '8%' }
  ];

  const whereClause = generateWhereClause(pipes);
  const sql = `
  SELECT
    database,
    name,
    total_rows,
    total_bytes
  FROM system.tables
  WHERE (${whereClause})
  ORDER BY (total_bytes,total_rows) DESC`;

  // only run the query immediately if the instance `isAwake`
  const [reload, { data }] = useSqlQuery(sql, undefined, isAwake);

  const processedMetrics = new DbMetrics(data?.rows ?? []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAwake) {
        reload().catch((e) => {
          console.error('clickpipe destination table metrics failed', e);
        });
      }
    }, 20_000);

    return () => clearInterval(interval);
  }, [reload, sql, isAwake]);

  useEffect(() => {
    if (isAwake) {
      reload().catch((e) => {
        console.error('clickpipe destination table metrics failed', e);
      });
    }
  }, [reload, sql, isAwake]);

  const openTableView = (pipe: ClickPipe) => () => {
    const serviceId = getCurrentServiceIdOrFail();
    const databaseName = encodeURIComponent(pipe.destination.database);
    const tableName = encodeURIComponent(pipe.destination.table);
    navigateTo(routes.tables({ serviceId, databaseName, tableName }));
  };

  const openClickPipeDestination = (pipe: ClickPipe) => async () => {
    const data = await runQuery(
      generateTableViewQuery({
        filterConfig: { filters: [], sorts: [] },
        pageNumber: 1,
        pageSize: 3,
        table: {
          tableName: pipe.destination.table,
          createTableQuery: '',
          engine: '',
          sortingKey: '',
          primaryKey: '',
          stats: {
            totalBytes: 0,
            totalBytesUncompressed: 0,
            totalRows: 0
          },
          columns: [],
          constraints: {
            checks: [],
            foreignKeys: [],
            primaryKeys: [],
            uniques: [],
            exclusions: []
          },
          schema: pipe.destination.database,
          type: 'table',
          indexes: {}
        }
      })
    );
    if ('error' in data) {
      createToast('Error', 'alert', data.error);
      return;
    }
    setRightBar({
      type: 'kafkaDestination',
      data
    });
  };

  const removePipe = (pipe: ClickPipe) => async () => {
    try {
      await api.deletePipe(serviceId, pipe.id, pipe.source.type);
      void mutate();
      if (rightBar?.type === pipe.id) {
        setRightBar(null);
      }
      return true;
    } catch (e) {
      createToast('Unable to delete Pipe', 'alert', errorMessage(e));
      return false;
    }
  };

  const updateStatus = (
    status: ClickPipeStatus,
    pipeId: string
  ): Array<ClickPipe> => {
    const newPipes = pipes;
    const index = pipes.findIndex((pipe) => pipe.id === pipeId);
    newPipes[index].status = status;
    return newPipes;
  };

  const updatePipeDetails = (
    details: Partial<ClickPipe>,
    pipeId: string
  ): Array<ClickPipe> => {
    const newPipes = pipes;
    const index = pipes.findIndex((pipe) => pipe.id === pipeId);
    newPipes[index] = { ...newPipes[index], ...details };
    return newPipes;
  };

  const onSuccess = (data?: Array<ClickPipe>): Array<ClickPipe> => {
    if (data && data.length > 0 && rightBar?.type === 'pipeDetails') {
      const pipe = data.find((pipe) => pipe.id === rightBar.data.id);
      if (pipe) {
        void openDetail(pipe)();
      }
    }
    return data ?? [];
  };

  const mutationOptions: MutatorOptions<ClickPipe[]> = {
    optimisticData: onSuccess
  };

  const pausePipe = (pipeId: string) => async (): Promise<boolean> => {
    try {
      await api.pausePipe(serviceId, pipeId);
      const data = updateStatus('Provisioning', pipeId);
      void mutate(data, {
        ...mutationOptions,
        revalidate: false
      });
      setTimeout(() => {
        void mutate(data, mutationOptions);
      }, 1000);
      return true;
    } catch (e: any) {
      createToast('Error', 'alert', e.message);
      return false;
    }
  };

  const resumePipe = (pipeId: string) => async (): Promise<boolean> => {
    try {
      await api.resumePipe(serviceId, pipeId);
      const data = updateStatus('Provisioning', pipeId);
      void mutate(data, {
        ...mutationOptions,
        revalidate: false
      });
      setTimeout(() => {
        void mutate(data, mutationOptions);
      }, 1000);
      return true;
    } catch (e: any) {
      createToast('Error', 'alert', e.message);
      return false;
    }
  };

  const scalePipe = (pipeId: string, replicas: number) => async () => {
    try {
      await api.scalePipe(serviceId, pipeId, replicas);
      const data = updatePipeDetails({ replicas: replicas }, pipeId);
      void mutate(data, {
        ...mutationOptions,
        revalidate: false
      });
      setTimeout(() => {
        void mutate(data, mutationOptions);
      }, 1000);
    } catch (e: any) {
      createToast('Error', 'alert', e.message);
    }
  };

  const openDetail = (pipe: ClickPipe) => async () => {
    setActivePipe(pipe.id);
    setRightBar({
      type: 'pipeDetails',
      data: pipe,
      onClickViewData: () => {
        if (pipe) {
          openTableView(pipe)();
        }
      },
      onClickAction: async () => {
        if (pipe?.status === 'Stopped') {
          return await resumePipe(pipe.id)();
        } else {
          return await pausePipe(pipe.id)();
        }
      },
      onClickRemove: removePipe(pipe),
      onClickScale: async (replicas: number) =>
        await scalePipe(pipe.id, replicas)()
    });
  };

  const rows: Array<TableRowType> = sortPipes(pipes, sortDirection).map(
    (pipe, idx) => {
      return {
        id: pipe.id,
        isActive: activePipe === pipe.id && rightBar !== null,
        items: [
          {
            label: (
              <Tooltip>
                <EllipsisContent
                  component={Tooltip.Trigger}
                  className="fs-exclude"
                >
                  {pipe.name}
                </EllipsisContent>
                <Tooltip.Content side="bottom" className="fs-exclude">
                  {pipe.name}
                </Tooltip.Content>
              </Tooltip>
            ),
            onClick: openDetail(pipe)
          },
          {
            label: <ClickPipeType type={pipe.source.type} />
          },
          {
            label: (
              <Tooltip>
                <EllipsisContent
                  component={Tooltip.Trigger}
                  className="fs-exclude"
                >
                  <StatusBadge
                    key={`status-${pipe.id}`}
                    type={getClickPipeStatus(pipe, () =>
                      processedMetrics.getRecordCount(pipe)
                    )}
                  />
                </EllipsisContent>
                <Tooltip.Content side="bottom" className="fs-exclude">
                  {getClickPipeStatus(pipe, () =>
                    processedMetrics.getRecordCount(pipe)
                  )}
                </Tooltip.Content>
              </Tooltip>
            )
          },
          {
            label: (
              <ClickPipeMetric
                isInstanceAwake={isAwake}
                value={processedMetrics.getTableSize(pipe)}
                displayFunc={formatDataSize}
              />
            )
          },
          {
            label: (
              <>
                {processedMetrics.getTableSize(pipe) !== '0' &&
                  pipe.source.type !== 'postgres' && (
                    <div>
                      <Link onClick={openClickPipeDestination(pipe)}>
                        Preview
                      </Link>
                      <span>|</span>{' '}
                      <Link
                        component={Link}
                        key={`view-data-${idx}`}
                        css={viewDataStyle(
                          getClickPipeStatus(pipe, () =>
                            processedMetrics.getRecordCount(pipe)
                          )
                        )}
                        role="button"
                        onClick={openTableView(pipe)}
                      >
                        View table
                      </Link>
                    </div>
                  )}

                {processedMetrics.getTableSize(pipe) === '0' &&
                  pipe.source.type !== 'postgres' && (
                    <div css={flex}>
                      <Text>No data</Text>
                    </div>
                  )}

                {pipe.source.type === 'postgres' && (
                  <div>
                    <Link
                      component={Link}
                      key={`view-data-${idx}`}
                      role="button"
                      disabled={true} // true for now
                      onClick={() => noop()}
                    >
                      View tables
                    </Link>
                  </div>
                )}
              </>
            )
          },
          {
            label: (
              <ClickPipeMetric
                isInstanceAwake={isAwake}
                value={processedMetrics.getRecordCount(pipe)}
                displayFunc={formatNumber}
              />
            )
          },
          {
            label: (
              <Tooltip>
                <EllipsisContent component={Tooltip.Trigger}>
                  {formatDateHuman(pipe.createdAt)}
                </EllipsisContent>
                <Tooltip.Content side="bottom">
                  {formatUtcTimestamp(
                    pipe.createdAt,
                    'YYYY-MM-DD HH:mm:ss (UTC)'
                  )}
                </Tooltip.Content>
              </Tooltip>
            ),
            onClick: openDetail(pipe)
          },
          {
            label: (
              <Actions
                pipeType={pipe.source.type}
                key={`actions-${pipe.id}`}
                pipeId={pipe.id}
                pipeName={pipe.name}
                status={pipe.status}
                onDelete={removePipe(pipe)}
                onShowDetails={openDetail(pipe)}
                onPause={pausePipe(pipe.id)}
                onResume={resumePipe(pipe.id)}
              />
            )
          }
        ]
      };
    }
  );

  if (!(rows && rows.length > 0)) {
    return null;
  }

  return (
    <TableAccordion title="ClickPipes" defaultValue="item">
      <AlignActionsHeader>
        <Table
          headers={headers}
          onSort={() =>
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
          }
          rows={rows}
          size="md"
          data-testid="clickpipes-list"
        />
      </AlignActionsHeader>
      <ImportRightBar
        isInstanceAwake={isAwake}
        rightBarModel={rightBar}
        updateRightBar={setRightBar}
        dbMetrics={processedMetrics}
      />
    </TableAccordion>
  );
}

export default React.memo(ClickPipesList);
