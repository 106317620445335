import {
  ConfirmationDialog,
  Container,
  GridContainer,
  Icon,
  Link,
  MultiAccordion,
  Text as CUIText
} from '@clickhouse/click-ui';
import { GettingStartedSteps } from '@cp/common/protocol/Instance';
import { Galaxy } from 'galaxy';
import { ReactElement, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { GettingStartedValueType } from 'src/components/GettingStarted/AccordionWrapper';
import CreateServiceAccordion from 'src/components/GettingStarted/CreateServiceAccordion';
import ExploreIntegrationsAccordion from 'src/components/GettingStarted/ExploreIntegrationsAccordion';
import ImportAccordion from 'src/components/GettingStarted/ImportAccordion';
import InviteMembersAccordion from 'src/components/GettingStarted/InviteMembersAccordion';
import NetworkAccordion from 'src/components/GettingStarted/NetworkAccordion';
import { useGettingStarted } from 'src/instance/controller/useGettingStarted';
import { useGettingStartedFeature } from 'src/instance/controller/useGettingStartedFeature';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useUserFeature } from 'src/lib/features';
import { useParams } from 'src/lib/routes/useParams';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { GettingStartedSidebar } from 'src/pages/GettingStartedPage/GettingStartedSidebar';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';

const gettingStartedAdminOptions: Array<GettingStartedValueType> = [
  'createService',
  'loadData',
  'idlingOptions',
  'inviteMembers',
  'visitedIntegration'
];
const gettingStartedDeveloperOptions: Array<GettingStartedValueType> = [
  'createService',
  'loadData',
  'visitedIntegration'
];

const GettingStartedPage = (): ReactElement => {
  const { serviceId } = useParams();
  const showNewOnboarding = useUserFeature('FT_USER_SHOW_NEW_ONBOARDING');
  const [openSkipOnboarding, setOpenSkipOnboarding] = useState(false);
  const { hasGettingStarted, removeGettingStarted } =
    useGettingStartedFeature();
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const gettingStartedOptions = hasManageServicePermission
    ? gettingStartedAdminOptions
    : gettingStartedDeveloperOptions;
  const { updateGettingStarted, ...options } = useGettingStarted();
  const {
    createService,
    loadData,
    idlingOptions,
    inviteMembers,
    visitedIntegration
  } = options;

  const gettingStartedSteps = useRef<GettingStartedSteps>({
    createService,
    loadData,
    idlingOptions,
    inviteMembers,
    visitedIntegration
  });

  const [currentAccordionType, setCurrentAccordionType] = useState<
    GettingStartedValueType | undefined
  >(
    gettingStartedOptions.find((option) => options[option] === false) ??
      'createService'
  );
  const prevAccordionType = useRef<GettingStartedValueType>(
    currentAccordionType ?? 'createService'
  );
  if (!showNewOnboarding) {
    return <NotFoundPage />;
  }

  const moveToNextStep = (type: GettingStartedValueType): void => {
    if (
      currentAccordionType !== type ||
      gettingStartedSteps.current[type] === true
    ) {
      return;
    }

    const index = gettingStartedOptions.findIndex((step) => type === step);
    if (index > -1 && index < gettingStartedOptions.length - 1) {
      if (['createService', 'loadData'].includes(type)) {
        Galaxy.galaxy().track(
          type === 'loadData'
            ? 'gettingStarted.importAccordion.loadDataCompleted'
            : 'gettingStarted.createServiceAccordion.createServiceCompleted',
          {
            interaction: 'trigger'
          }
        );
      }
      const nextccordionType = gettingStartedOptions[index + 1];
      prevAccordionType.current = nextccordionType;
      setCurrentAccordionType(nextccordionType);
      if (gettingStartedSteps.current) {
        gettingStartedSteps.current[type] = true;
      }
    }
  };

  const onMemberInvited = (): void => {
    const index = gettingStartedOptions.findIndex(
      (step) => 'inviteMembers' === step
    );
    if (index > -1 && index < gettingStartedOptions.length - 1) {
      const nextccordionType = gettingStartedOptions[index + 1];
      prevAccordionType.current = nextccordionType;
      setCurrentAccordionType(nextccordionType);
    }
  };

  const markAsCompleted = (type: GettingStartedValueType) => (): void => {
    updateGettingStarted({
      [type]: true
    });

    Galaxy.galaxy().track('gettingStartedPage.main.markAsCompletedClicked', {
      interaction: 'click'
    });
    moveToNextStep(type);
  };
  const onConfirmDialog = async (): Promise<void> => {
    await removeGettingStarted();
    setOpenSkipOnboarding(false);
  };

  const onValueChange = (value: string | undefined): void => {
    if (value) {
      Galaxy.galaxy().track('gettingStarted.accordion.accordionClick', {
        interaction: 'click',
        value,
        from: hasGettingStarted ? 'main' : 'help'
      });
      const nextccordionType = value as GettingStartedValueType;
      prevAccordionType.current = nextccordionType;
      setCurrentAccordionType(nextccordionType);
    } else {
      setCurrentAccordionType(undefined);
    }
  };

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Getting started" />
      <MainPaneLayout.Content>
        <Helmet>
          <title>Getting started</title>
        </Helmet>
        <GridContainer gridTemplateColumns="3fr 1fr" maxWidth="1400px">
          <Container
            isResponsive={false}
            alignItems="start"
            overflow="auto"
            orientation="vertical"
            fillWidth
            padding="lg"
            grow="1"
            gap="md"
          >
            <MultiAccordion
              type="single"
              collapsible
              value={currentAccordionType}
              showBorder
              showCheck={hasGettingStarted}
              fillWidth
              onValueChange={onValueChange}
              markAsCompleted={(value: string) => {
                const accordionType = value as GettingStartedValueType;
                if (!['createService', 'loadData'].includes(accordionType)) {
                  markAsCompleted(accordionType)();
                }
              }}
            >
              <CreateServiceAccordion
                isCompleted={createService}
                moveToNextStep={moveToNextStep}
                showProgress={hasGettingStarted}
              />
              <ImportAccordion
                isCompleted={loadData}
                moveToNextStep={moveToNextStep}
                showProgress={hasGettingStarted}
              />
              {hasManageServicePermission && (
                <>
                  <NetworkAccordion
                    serviceId={serviceId}
                    isCompleted={idlingOptions}
                    markAsCompleted={markAsCompleted('idlingOptions')}
                    showProgress={hasGettingStarted}
                  />
                  <InviteMembersAccordion
                    isCompleted={inviteMembers}
                    markAsCompleted={markAsCompleted('inviteMembers')}
                    showProgress={hasGettingStarted}
                    onMemberInvited={onMemberInvited}
                  />
                </>
              )}
              <ExploreIntegrationsAccordion
                serviceId={serviceId}
                isCompleted={visitedIntegration}
                markAsCompleted={markAsCompleted('visitedIntegration')}
                showProgress={hasGettingStarted}
              />
            </MultiAccordion>
            {hasGettingStarted && (
              <Container justifyContent="end">
                <Link
                  onClick={() => {
                    setOpenSkipOnboarding(true);
                  }}
                >
                  <Icon name="double-check" />
                  Skip Onboarding
                </Link>
              </Container>
            )}
          </Container>
          <GettingStartedSidebar
            type={prevAccordionType.current}
            serviceId={serviceId}
            isServiceCreated={createService}
          />
          <ConfirmationDialog
            open={openSkipOnboarding}
            title="Getting started has been removed"
            onConfirm={onConfirmDialog}
            onCancel={() => {
              setOpenSkipOnboarding(false);
            }}
            primaryActionLabel="Ok"
            secondaryActionLabel="Undo"
          >
            <CUIText size="md">
              If you still need help setting up your service, you can find it
              under Help -&gt;{' '}
              <Link size="md" onClick={onConfirmDialog}>
                Getting started{' '}
              </Link>
            </CUIText>
          </ConfirmationDialog>
        </GridContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default GettingStartedPage;
