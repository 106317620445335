import { tokenizer, TokenizerOptions } from 'shared/src/sql/parse/tokenize';

export interface StatementRange {
  start: number;
  end: number;
}

export interface StatementInfo extends StatementRange {
  text: string;
}

// Return an array of records describing the beginning and end offsets of each
// statement within an SQL text. For our purposes, the ranges for statements are
// layed out like this: The first statement starts at the beginning of the sql
// text, and extends through the first encontered statement delimiter, and
// includes all non-token chars (whitespace) and comments after that
// delimiter. Then the next statement begins immediately after on the first
// token character, and extends through the next delimiter and trailing
// whitespace/comments, and so on.
export function statementRanges(sql: string, options: TokenizerOptions): StatementRange[] {
  const tokenStream = tokenizer(sql, options);
  const firstToken = tokenStream.nextToken();

  if (!firstToken) {
    return [
      {
        start: 0,
        end: sql.length
      }
    ];
  }

  const statements: StatementRange[] = [];
  let statementStart = 0;
  let statementFinished = false;

  for (;;) {
    const token = tokenStream.nextToken();
    if (!token) {
      break;
    } else if (token.type === 'statementDelimiter') {
      statementFinished = true;
    } else if (token.type !== 'comment') {
      if (statementFinished) {
        statements.push({
          start: statementStart,
          end: token.startOffset
        });
        statementStart = token.startOffset;
        statementFinished = false;
      }
    }
  }

  statements.push({
    start: statementStart,
    end: sql.length
  });

  return statements;
}

export function statementForCursor(sql: string, options: TokenizerOptions, cursorPos: number): StatementInfo | null {
  const ranges = statementRanges(sql, options);

  for (const { start, end } of ranges) {
    if (cursorPos >= start && cursorPos < end) {
      return {
        start,
        end,
        text: sql.slice(start, end)
      };
    }
  }

  // special case if cursor is just past the final statement.
  if (ranges.length > 0) {
    const lastRange = ranges[ranges.length - 1];
    if (lastRange.end === cursorPos) {
      return {
        ...lastRange,
        text: sql.slice(lastRange.start, lastRange.end)
      };
    }
  }

  return null;
}
