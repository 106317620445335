import { internalQuerySetting, TableDetails } from 'shared/src/clickhouse';
import { Table } from 'shared/src/clickhouse/types';
import { escapeString, standardQuoteIdentifier } from 'shared/src/tableSchema/escape';
import { Filter, FilterConfig, Sort } from 'src/state/tabs/types';

interface GenerateInsertRowQueryProps {
  fields: { [key: string]: string };
  table: {
    tableName: string;
  };
}

const TRUNCATABLE_FIELD_TYPES = [
  'String',
  'FixedString',
  'Nullable(String)',
  'LowCardinality(String)',
  'Nullable(FixedString)'
];

export function generateInsertRowQuery({ fields, table }: GenerateInsertRowQueryProps) {
  const escapedColumns = Object.keys(fields).map(standardQuoteIdentifier);
  const escapedValues = Object.values(fields).map(escapeString);
  if (escapedColumns.length === 0) {
    return null;
  }
  return `INSERT INTO ${table.tableName} (${escapedColumns.join(', ')}) VALUES (${escapedValues.join(', ')})`;
}

interface GenerateTableViewQueryProps {
  filterConfig: FilterConfig;
  pageNumber: number;
  table: TableDetails;
  pageSize: number;
  parameters?: Record<string, string>;
  withDb?: boolean;
  tableView?: boolean;
}

function selectValidFilters(filters: Filter[]): Filter[] {
  return filters.filter(
    (filter) =>
      filter.column && filter.operator && (filter.value != null || ['isNull', 'isNotNull'].includes(filter.operator))
  );
}

function selectValidSorts(sorts: Sort[]): Sort[] {
  return sorts.filter((sort) => sort.column && sort.direction);
}
function escapeIdentifier(identifier: string | undefined): string | undefined {
  return JSON.stringify(identifier).slice(1, -1);
}
function genSQLFilter(filter: Filter): string | null {
  const column = filter.column ?? undefined;
  const escapedColumn = `"${escapeIdentifier(column)}"`;
  if (!column) {
    throw new Error(`Could not find a table column with name ${filter.column}`);
  }

  const value = filter.value;

  switch (filter.operator) {
    case 'contains': {
      return `${escapedColumn} ILIKE '%${value}%'`;
    }
    case 'startsWith': {
      return `${escapedColumn} ILIKE '${value}%'`;
    }
    case 'endsWith': {
      return `${escapedColumn} ILIKE '%${value}'`;
    }
    case 'isNull':
      return `${escapedColumn} IS NULL`;
    case 'isNotNull':
      return `${escapedColumn} IS NOT NULL`;
    case 'in': {
      if (value === '' || typeof value !== 'string') {
        return null;
      }

      let options = value.split(',');

      const isNum = options.every((option: string) => !isNaN(Number(option.trim())));
      if (isNum) {
        return `${escapedColumn} IN (${value})`;
      }

      options = options.map((option: string) => `'${option.trim()}'`);
      return `${escapedColumn} IN (${options.join(', ')})`;
    }
    case 'notIn': {
      if (value === '' || typeof value !== 'string') {
        return null;
      }

      let options = value.split(',');
      const isNum = options.every((option: string) => !isNaN(Number(option.trim())));
      if (isNum) {
        return `${escapedColumn} NOT IN (${value})`;
      }

      options = options.map((option: string) => `'${option.trim()}'`);
      return `${escapedColumn} NOT IN (${options.join(', ')})`;
    }
    default: {
      const isNum = !isNaN(Number((filter.value || '').trim()));
      const value = isNum ? filter.value : `'${filter.value}'`;
      return `${escapedColumn} ${filter.operator} ${value}`;
    }
  }
}

function genSQLSort(sort: Sort): string {
  const escapedColumn = escapeIdentifier(sort.column);
  return `"${escapedColumn}" ${sort.direction}`;
}

export function tableName(table: Table, withDb = true) {
  const tableName = standardQuoteIdentifier(table.tableName);
  if (table.schema && withDb) {
    const schemaName = standardQuoteIdentifier(table.schema);
    return `${schemaName}.${tableName}`;
  } else {
    return tableName;
  }
}

function formatViewParameters(parameters?: Record<string, string>): string {
  if (!parameters || Object.keys(parameters).length === 0) {
    return '';
  } else {
    const assignments = Object.entries(parameters).map(([name, value]) => {
      return `${standardQuoteIdentifier(name)}=${escapeString(value)}`;
    });
    return `(${assignments.join(',')})`;
  }
}

export function generateTableViewQuery({
  filterConfig,
  pageNumber,
  pageSize,
  table,
  parameters,
  withDb = true,
  tableView = false
}: GenerateTableViewQueryProps) {
  let whereClause = '';
  const validFilters = selectValidFilters(filterConfig.filters);
  const adjustedColumns = tableView
    ? table.columns
        .map((col) => {
          const colName = standardQuoteIdentifier(col.name);
          if (TRUNCATABLE_FIELD_TYPES.includes(col.baseType)) {
            return `substring(${colName}, 1, 100000) as ${colName}`;
          }
          return colName;
        })
        .join(', ')
    : '*';

  if (validFilters.length) {
    const checks = validFilters
      .map(genSQLFilter)
      .filter((statement) => !!statement)
      .join(' AND ');
    whereClause = ` WHERE ${checks}`;
  }

  let orderByClause = '';
  const validSorts = selectValidSorts(filterConfig.sorts);
  if (validSorts.length) {
    const orders = validSorts.map(genSQLSort).join(', ');
    orderByClause = ` ORDER BY ${orders}`;
  }

  const limitClause = ` LIMIT ${pageSize + 1} OFFSET ${(pageNumber - 1) * pageSize}`;

  const viewParams = formatViewParameters(parameters);
  const internalSettingIfNecessary = tableView ? ` SETTINGS ${internalQuerySetting}` : '';
  return `SELECT ${adjustedColumns} FROM ${tableName(
    table,
    withDb
  )}${viewParams}${whereClause}${orderByClause}${limitClause}${internalSettingIfNecessary};`;
}
