import useSWR from 'swr';
import { Integration, IntegrationCategory } from 'shared/src/types/integration';
import { findAll } from 'src/util/strapi';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
export interface IntegrationsByCategoryType {
  label: string;
  integrations: Array<Integration>;
  category: IntegrationCategory;
}
export const integrationListAtom = atom<Array<Integration>>([]);
interface UseIntegrationsResponse {
  integrations: Array<Integration>;
  isLoading?: boolean;
  error?: Error;
}

const integrationFields: ReadonlyArray<string> = [
  'shortDescription',
  'name',
  'category',
  'supportLevel',
  'docsLink',
  'slug',
  'about',
  'changelog',
  'version',
  'license',
  'readiness',
  'website',
  'openInNewWindow'
];

const fetchIntegrations = async (): Promise<Array<Integration>> => {
  const { data } = await findAll<Integration>('integrations', {
    populate: ['logo', 'logo_dark'],
    filters: {},
    fields: integrationFields
  });

  if (!data?.[0]) {
    throw new Error('No Integrations found');
  }

  return data;
};

const useIntegrations = (): UseIntegrationsResponse => {
  const [integrationList, setIntegrationList] = useAtom(integrationListAtom);
  const { data, isLoading, error } = useSWR<Array<Integration>>('fetch-integrations', fetchIntegrations);

  useEffect(() => {
    if (data) {
      setIntegrationList(data);
    }
  }, [data, setIntegrationList]);

  return {
    integrations: integrationList,
    isLoading,
    error
  };
};

export default useIntegrations;
