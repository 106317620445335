import {
  GetImportFileDownloadUrlRequest,
  GetImportFileDownloadUrlResponse,
  PrepareUploadSignatureDetailsRequest
} from '@cp/common/protocol/Instance';
import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import { ImportModel, PreviewDataRequest, PreviewDataResponse } from 'shared/src/dataLoading';
import {
  buildDataImportModel,
  buildDataImportWithChunksModel,
  DataImportModel,
  DataImportWithChunksModel
} from 'src/dataloading/models';

import { apiUrl, controlPlaneUrl } from 'src/lib/controlPlane/apiUrls';
import { HttpClient } from 'src/lib/http';
import { DataImport } from 'types/prisma';
import { CancelDataImportRequest } from 'types/protocol/cancelDataImportProtocol';
import { DataImportWithChunks } from 'types/protocol/getDataImportProtocol';

export interface ListImportsProps {
  serviceId: string;
}

export type CreateDataImportProps = {
  serviceId: string;
  organizationId: string;
  importModel: ImportModel;
};

export type SuccessfulDataImportCreate = { ok: true; value: DataImport };
export type BadDataImportCreate = { ok: false; errors: Array<string> };
export type CreateDataImportResponse = SuccessfulDataImportCreate | BadDataImportCreate;

export interface GetImportProps {
  serviceId: string;
  importId: string;
}
type ResponseErrors = { errors: Array<string> } | { error: string };

function parseImportErrors(responseErrors: ResponseErrors): Array<string> {
  if ('errors' in responseErrors) {
    return responseErrors.errors;
  } else {
    return [responseErrors.error];
  }
}

export class DataLoadingApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async createDataImport(props: CreateDataImportProps): Promise<CreateDataImportResponse> {
    const response = await this.httpClient.post(apiUrl('/saveDataImport'), {
      body: JSON.stringify(props)
    });

    if (response.ok) {
      const data = (await response.json()) as DataImport;
      return { ok: true, value: data };
    } else {
      const errors = parseImportErrors((await response.json()) as ResponseErrors);
      return { ok: false, errors };
    }
  }

  async getImport(props: GetImportProps): Promise<DataImportWithChunksModel> {
    const queryParams = new URLSearchParams({ serviceId: props.serviceId, dataImportId: props.importId });
    const response = await this.httpClient.get(apiUrl(`/getDataImport?${queryParams.toString()}`));

    if (response.ok) {
      const result = (await response.json()) as { data: DataImportWithChunks };
      return buildDataImportWithChunksModel(result.data);
    } else {
      const errors = parseImportErrors((await response.json()) as ResponseErrors);
      throw new Error(errors.join('\n'));
    }
  }

  async getImportFileDownloadUrl(
    organizationId: string,
    instanceId: string,
    storageId: string
  ): Promise<GetImportFileDownloadUrlResponse> {
    const request: GetImportFileDownloadUrlRequest = {
      rpcAction: 'getImportFileDownloadUrl',
      organizationId,
      instanceId,
      storageId
    };

    const response = await this.httpClient.post(
      controlPlaneUrl('/api/instance'),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );

    if (response.ok) {
      return (await response.json()) as GetImportFileDownloadUrlResponse;
    } else {
      throw new Error('Failed to get import file download url');
    }
  }

  async listImports({ serviceId }: ListImportsProps): Promise<DataImportModel[]> {
    const response = await this.httpClient.get(
      apiUrl(`/getDataImports?serviceId=${encodeURIComponent(serviceId)}`),
      {}
    );
    if (response.ok) {
      const resultArray = (await response.json()) as Array<DataImport>;
      return resultArray.map((dataImport) => buildDataImportModel(dataImport));
    } else {
      throw new Error('Failed to list imports');
    }
  }

  async previewDataForUrl(request: PreviewDataRequest): Promise<PreviewDataResponse> {
    const response = await this.httpClient.post(apiUrl('/previewDataForUrl'), {
      body: JSON.stringify(request)
    });

    if (response.ok) {
      return (await response.json()) as PreviewDataResponse;
    } else {
      const errors = parseImportErrors((await response.json()) as ResponseErrors);
      throw new Error(errors[0]);
    }
  }

  async retryDataImportJob(id: string): Promise<boolean> {
    const response = await this.httpClient.post(apiUrl('/retryDataImport'), {
      body: JSON.stringify({ importId: id })
    });

    return response.ok;
  }

  async prepareUploadSignatureDetails(
    organizationId: string,
    fileName: string,
    mimeType?: string
  ): Promise<UploadSignatureDetails> {
    const request: PrepareUploadSignatureDetailsRequest = {
      rpcAction: 'prepareUploadSignatureDetails',
      organizationId,
      fileName,
      mimeType
    };

    const response = await this.httpClient.post(
      controlPlaneUrl('/api/instance'),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );

    if (response.ok) {
      return (await response.json()) as UploadSignatureDetails;
    } else {
      throw new Error('Failed to get upload signature details');
    }
  }

  async cancelDataImport(dataImportId: string, serviceId: string): Promise<boolean> {
    const request: CancelDataImportRequest = {
      serviceId,
      dataImportId
    };

    const response = await this.httpClient.post(apiUrl('/cancelDataImport'), {
      body: JSON.stringify(request)
    });

    return response.ok;
  }
}
