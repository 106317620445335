import { Container, Label, Switch, TextField } from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import { DashboardFilterConfig } from 'shared/src/types/dashboard';
import { useDebounceFunction } from 'src/lib/hooks';
import {
  useDashboardState,
  useUpdateDashboardFilterOutput,
  useUpdateDashboardFilterState
} from 'src/state/dashboard/dashboardState';
import {
  FilterBooleanSwitch,
  FilterBooleanValue
} from 'src/components/Dashboards/DashboardView/components/FilterBooleanSwitch';

interface FilterProps {
  config?: DashboardFilterConfig;
  dashboardId: string;
  field: string;
}

export default function Filter({
  config,
  dashboardId,
  field
}: FilterProps): ReactElement {
  const dashboardState = useDashboardState({ dashboardId });
  const updateState = useUpdateDashboardFilterState({
    dashboardId
  });
  const updateOutput = useUpdateDashboardFilterOutput({
    dashboardId
  });
  const debouncedUpdateOutput = useDebounceFunction(updateOutput, 250);

  let filter;
  switch (config?.filterType) {
    case 'date': {
      filter = (
        <input
          onBlur={() => debouncedUpdateOutput.flush()}
          onChange={(e) => {
            updateState(field, {
              value: e.target.value
            });
            debouncedUpdateOutput(field, {
              value: e.target.value
            });
          }}
          type="date"
          value={(dashboardState[field]?.value as string) || ''}
        />
      );
      break;
    }
    case 'string': {
      filter = (
        <TextField
          onBlur={() => {
            debouncedUpdateOutput.flush();
          }}
          onChange={(newValue: string) => {
            updateState(field, {
              value: newValue
            });
            debouncedUpdateOutput(field, {
              value: newValue
            });
          }}
          value={(dashboardState[field]?.value as string) || ''}
        />
      );
      break;
    }
    case 'boolean': {
      filter = (
        <FilterBooleanSwitch
          onChange={(newValue: FilterBooleanValue) => {
            updateState(field, { value: newValue });
            debouncedUpdateOutput(field, { value: newValue });
          }}
          value={dashboardState[field]?.value as FilterBooleanValue}
        />
      );
      break;
    }
    default: {
      filter = (
        <Container>
          Filter improperly configured. Please check the configuration for the
          queries that use this filter.
        </Container>
      );
    }
  }

  return (
    <Container gap="sm" padding="sm" orientation="vertical">
      <Label>{field}</Label>
      {filter}
    </Container>
  );
}
