import {
  Button,
  Container,
  RadioGroup,
  Title,
  VerticalStepper
} from '@clickhouse/click-ui';

import { mlAuto } from '@utility-styles';
import { produce } from 'immer';
import { createToast } from 'primitives';
import { ReactElement, useState } from 'react';
import { marginStyle } from 'src/components/global-styles';

import BottomBar from 'src/components/ImportView/ImportForm/BottomBar';
import UrlSourceInput from 'src/components/ImportView/ImportForm/SourceInput/UrlSourceInput';
import { useApiClient } from 'src/lib/controlPlane/client';

import {
  getNewFileUploadModel,
  getNewUrlImportModel,
  useCancelableFileUpload,
  useColumns,
  useImportModel
} from 'src/lib/dataLoading';
import { DataPreview, DataPreviewer } from 'src/lib/dataLoading/DataPreviewer';
import { ChDescriber } from 'src/lib/dataLoading/describe';
import { errorMessage } from 'src/lib/errors/errorMessage';

import { routes } from 'src/lib/routes';
import { getFileNameWithoutExtension, underscoreName } from 'src/lib/uri-utils';
import {
  Column,
  ColumnMapping,
  EditColumn,
  ExistingTableConfig,
  FileBasedImport,
  FileFormatType,
  FileUrlAccessKeyIdCredentials,
  isFileBasedImport,
  NewFileUploadImport,
  RowPreview,
  TableMapping,
  TableType
} from 'shared/src/dataLoading';
import { generateImportStatement } from 'shared/src/dataLoading/generateImportStatement';
import { useTables } from 'src/metadata/metadataState';
import { useConnectionCredentials } from 'src/state/connection';
import {
  getCurrentServiceIdOrFail,
  useOrgIdFromServiceIdOrFail
} from 'src/state/service';
import { useTabActions } from 'src/state/tabs';
import { useSqlQueryFunction } from 'src/lib/clickhouse/query';
import { useAllColumns } from 'src/state/dataLoading';

import FileFormatInput from 'src/components/ImportView/ImportForm/FileFormatInput';
import PreviewBox from 'src/components/ImportView/ImportForm/PreviewBox';
import FileSourceInput from 'src/components/ImportView/ImportForm/SourceInput/FileSourceInput';
import TableMappingForm from 'src/components/ImportView/ImportForm/TableMappingForm';
import TableSettingsForm from 'src/components/ImportView/ImportForm/TableSettingsForm';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { isCloudEnv } from 'src/lib/config/isCloudEnv';
import { Galaxy } from 'galaxy';

export const generateColumnMappings = (
  source: Column[],
  target: Column[]
): { source: Column; target: Column | null }[] => {
  const findMatchingColumns = (sourceCol: Column): Column | null => {
    return (
      target.find(
        (targetCol) =>
          targetCol.name.toLowerCase() === sourceCol.name.toLowerCase()
      ) ?? null
    );
  };

  return source.map((sourceCol: Column) => {
    const target = findMatchingColumns(sourceCol);
    return {
      source: sourceCol,
      target
    };
  });
};

/**
 * Adjusts the data for preview by removing columns that are marked for removal.
 * @param {RowPreview[][] | undefined} rowsPreview - The preview of rows to be adjusted.
 * @param {EditColumn[]} allColumns - The list of columns with their removal status.
 * @returns {RowPreview[][]} - The adjusted rows preview with columns removed as necessary.
 */
export function getCompactedRows(
  rowsPreview: RowPreview[][] | undefined,
  allColumns: EditColumn[]
): RowPreview[][] {
  if (!rowsPreview) {
    return [];
  }
  return rowsPreview.map((row) =>
    // Returns only the columns that are not marked for removal and the cell values are not null.
    row.filter((_, idx) => !allColumns[idx].removed && row[idx] !== null)
  );
}

function ImportForm(): ReactElement | null {
  const {
    importModel,
    updateImportModel,
    resetImportModel,
    setUrl,
    setFileType,
    setDataPreview,
    progress,
    setProgress,
    setFormatType,
    setSeparator,
    setImportModel,
    setTableConfig,
    setName,
    requiresCredentials,
    hasValidCredentials
  } = useImportModel();
  const { addNewQueryTab } = useTabActions();
  const api = useApiClient();
  const { selectedDatabase } = useConnectionCredentials();
  const [submitting, setSubmitting] = useState(false);
  const [processingSource, setProcessingSource] = useState(false);
  const [sourceError, setSourceError] = useState<string | null>(null);

  const serviceId = getCurrentServiceIdOrFail();
  const organizationId = useOrgIdFromServiceIdOrFail(serviceId);
  const [runQuery] = useSqlQueryFunction();
  const describer = new ChDescriber(runQuery);
  const { cancelUpload } = useCancelableFileUpload();
  const importType = importModel.type;
  const columns = useColumns();
  const allColumns = useAllColumns();
  const metadataTables = useTables();

  if (!isFileBasedImport(importModel)) {
    return null;
  }
  const tables = metadataTables.map((metaTable) => ({
    ...metaTable,
    schema: metaTable.schema || selectedDatabase
  }));
  const dataPreviewer = new DataPreviewer(
    api,
    serviceId,
    tables,
    selectedDatabase
  );
  const hasRows = (importModel.data.rowsPreview?.length ?? 0) > 0;
  const tableType = importModel.data.tableConfig?.tableType ?? 'newTable';

  const rows = getCompactedRows(importModel.data.rowsPreview, allColumns);

  const setTableType = (newType: TableType): void => {
    if (tableType === newType) {
      return;
    }

    let tableName: string;
    if (newType === 'existing') {
      tableName = tables.length > 0 ? tables[0].tableName : '';
    } else {
      const filename =
        importType === 'file_upload'
          ? importModel.data.fileName
          : importModel.data.url;
      tableName = underscoreName(getFileNameWithoutExtension(filename || ''));
    }

    const tableConfig = dataPreviewer.generateTableConfig(
      newType,
      tableName,
      columns
    );

    Galaxy.galaxy().track('dataLoading.importForm.selectTableType', {
      interaction: 'click',
      type: newType
    });
    setTableConfig(tableConfig);
  };

  const genTableConfigFor = (
    database: string,
    tableName: string,
    tableConfig: ExistingTableConfig
  ): ExistingTableConfig => {
    const table = tables.find((table) => table.tableName === tableName);
    const tableColumns = table?.columns || [];

    const columnsMapping = generateColumnMappings(
      tableConfig.config.sourceColumns,
      tableColumns
    );

    return {
      tableType: tableConfig.tableType,
      config: {
        ...tableConfig.config,
        schema: database,
        name: tableName,
        columnsMapping
      }
    };
  };

  function openDataLoadAsQuery(importModel: FileBasedImport): void {
    const credentials =
      importModel.type === 'file_url' &&
      importModel.data.credentials?.type === 'accessKeyId'
        ? importModel.data.credentials
        : undefined;

    const sql = generateImportStatement({
      importModel: importModel,
      skipCreateTable: false,
      credentials,
      credentialsMasked: true,
      isCloudEnv: isCloudEnv()
    });

    Galaxy.galaxy().track('dataLoading.importForm.openAsQuery', {
      interaction: 'click'
    });

    addNewQueryTab({
      query: sql,
      type: 'query',
      title: 'Untitled query',
      saved: false
    });
  }

  const onTableChange = (newTable: string): void => {
    const tableConfig = importModel.data.tableConfig as ExistingTableConfig;

    if (newTable === tableConfig.config.name) {
      return;
    }

    setTableConfig(
      genTableConfigFor(tableConfig.config.schema, newTable, tableConfig)
    );
  };

  const onTableMappingChange = (mapping: Array<ColumnMapping>): void => {
    const tableConfig = importModel.data.tableConfig as ExistingTableConfig;
    const newTableConfig = produce(tableConfig, (draft) => {
      draft.config.columnsMapping = mapping;
    });

    Galaxy.galaxy().track('dataLoading.importForm.mappingChangeSelect', {
      interaction: 'click'
    });
    setTableConfig(newTableConfig);
  };

  const createDataImport = async (): Promise<void> => {
    setSubmitting(true);
    try {
      Galaxy.galaxy().track('dataLoading.importForm.createImportSelect', {
        interaction: 'click'
      });
      const result = await api.dataloading.createDataImport({
        serviceId,
        organizationId,
        importModel
      });

      if (result.ok) {
        resetImportModel();
        navigateTo(routes.imports({ serviceId }));
        createToast(
          'Success',
          'success',
          'Data import has been successfully created'
        );
      } else {
        createToast('Error', 'alert', result.errors.join('\n'));
      }
    } catch (error) {
      createToast('Error', 'alert', errorMessage(error));
    } finally {
      setSubmitting(false);
    }
  };

  const toolbarLabel =
    importType === 'file_url' ? 'Configure Url' : 'Configure file';

  const resetCurrentImport = (): void => {
    const newModel =
      importType === 'file_url'
        ? getNewUrlImportModel(importModel.id)
        : getNewFileUploadModel(importModel.id);

    setProgress(0);
    setImportModel(newModel);
    setSourceError(null);
  };

  const resetDataPreview = (): void => {
    updateImportModel((draft: NewFileUploadImport) => {
      draft.data.rowsPreview = [];
      draft.data.tableConfig = undefined;
    });
  };

  const updateDataPreview = async (
    newFormatType: FileFormatType | null,
    separator: string | null | undefined
  ): Promise<void> => {
    try {
      setProcessingSource(true);
      setSourceError(null);
      resetDataPreview();

      if (!newFormatType) {
        return;
      }

      if (importType === 'file_url') {
        const url = importModel.data.url;
        const tableName = underscoreName(getFileNameWithoutExtension(url));
        try {
          Galaxy.galaxy().track('dataLoading.importForm.getUrlDataPreview', {
            interaction: 'trigger'
          });
          const dataPreview = await dataPreviewer.getPreviewDataFromUrl(
            url,
            tableName,
            tableType,
            {
              formatType: newFormatType ?? undefined,
              separator: separator ?? undefined
            },
            importModel.data.requiresCredentials &&
              importModel.data.credentials?.type === 'accessKeyId'
              ? importModel.data.credentials
              : undefined
          );
          setDataPreview(dataPreview);
        } catch (error) {
          resetDataPreview();
          createToast(
            'error',
            'alert',
            'Could not get data preview. Wrong file format?'
          );
        }
      } else if (importType === 'file_upload') {
        const { fileSampleRaw, fileType } = importModel.data;
        const tableName = underscoreName(
          getFileNameWithoutExtension(importModel.name)
        );

        if (!fileType) {
          throw new Error('fileType is undefined');
        }

        try {
          Galaxy.galaxy().track(
            'dataLoading.importForm.getDataPreviewFromRawSample',
            { interaction: 'trigger' }
          );
          const dataPreview = await dataPreviewer.getPreviewDataFromData(
            fileSampleRaw,
            tableType,
            tableName,
            describer,
            fileType,
            newFormatType
          );

          setDataPreview({
            ...dataPreview,
            sizeBytes: importModel.data.sizeBytes
          });
        } catch (error) {
          resetDataPreview();
          createToast(
            'error',
            'alert',
            'Could not get data preview. Wrong file format?'
          );
        }
      } else {
        throw new Error('Unexpected state');
      }
    } finally {
      setProcessingSource(false);
    }
  };

  const hasUrlSource = importType === 'file_url' && importModel.data.url;
  const hasUploadSource =
    importType === 'file_upload' &&
    importModel.status === 'new' &&
    importModel.data.file &&
    progress === 100;

  const hasSource = hasUploadSource || hasUrlSource;
  const hasCancelUploadButton =
    importType === 'file_upload' &&
    importModel.status === 'new' &&
    importModel.data.file &&
    progress < 100;

  const showFileTypeInput =
    !processingSource &&
    hasSource &&
    (!requiresCredentials || hasValidCredentials);

  const stopUpload = (): void => {
    Galaxy.galaxy().track('dataLoading.importForm.cancelUploadSelect', {
      interaction: 'click'
    });
    cancelUpload();

    resetCurrentImport();
  };

  const onCancelUpload = (): void => {
    if (hasCancelUploadButton) {
      cancelUpload();
    }
    resetCurrentImport();
  };

  const getDataPreviewForUrl = (
    url: string,
    credentials?: FileUrlAccessKeyIdCredentials
  ): Promise<DataPreview> => {
    const tableName = underscoreName(getFileNameWithoutExtension(url));
    const hints = undefined;
    return dataPreviewer.getPreviewDataFromUrl(
      url,
      tableName,
      tableType,
      hints,
      credentials
    );
  };

  const updateDataPreviewHandler = (
    formatType: FileFormatType | null,
    separator: string | null | undefined
  ): void => {
    updateDataPreview(formatType, separator).catch(console.error);
  };

  return (
    <VerticalStepper>
      <VerticalStepper.Step
        label={toolbarLabel}
        status={hasRows ? 'complete' : 'active'}
        collapsed={false}
      >
        <Container gap="xs" justifyContent="end" alignItems="start">
          {importType === 'file_url' && (
            <>
              <UrlSourceInput
                setName={setName}
                url={importModel.data.url}
                setUrl={setUrl}
                sizeBytes={importModel.data.sizeBytes}
                setFileType={setFileType}
                setPreviewData={setDataPreview}
                loading={processingSource}
                setLoading={setProcessingSource}
                error={sourceError}
                setError={setSourceError}
                getDataPreview={getDataPreviewForUrl}
              />
            </>
          )}

          {importType === 'file_upload' && (
            <FileSourceInput
              importModel={importModel}
              progress={progress}
              onCancelUpload={onCancelUpload}
            >
              <FileFormatInput
                css={mlAuto}
                formatType={importModel.data.formatType}
                setFormatType={setFormatType}
                setSeparator={setSeparator}
                separator={importModel.data.separator}
                updateDataPreview={updateDataPreviewHandler}
              />
            </FileSourceInput>
          )}

          {importType !== 'file_upload' && (
            <>
              {showFileTypeInput && (
                <FileFormatInput
                  css={mlAuto}
                  formatType={importModel.data.formatType}
                  setFormatType={setFormatType}
                  setSeparator={setSeparator}
                  separator={importModel.data.separator}
                  updateDataPreview={updateDataPreviewHandler}
                />
              )}

              {hasCancelUploadButton && (
                <Button
                  type="secondary"
                  css={marginStyle({ left: 12, top: 26 })}
                  onClick={stopUpload}
                >
                  Cancel
                </Button>
              )}
              {!processingSource && hasSource && (
                <Button
                  type="secondary"
                  css={marginStyle({ left: 12, top: 26 })}
                  onClick={() => {
                    resetCurrentImport();
                    Galaxy.galaxy().track(
                      'dataLoading.importForm.removeCurrentImport',
                      { interaction: 'click' }
                    );
                  }}
                >
                  Remove
                </Button>
              )}
            </>
          )}
        </Container>
      </VerticalStepper.Step>
      <VerticalStepper.Step
        label="Configure table"
        status={hasRows ? 'active' : 'incomplete'}
      >
        <Container gap="xl" orientation="vertical">
          <PreviewBox loading={false} columns={columns} rows={rows} />

          <Container orientation="vertical" gap="md">
            <Title type="h3">Destination table</Title>
            <RadioGroup
              label="Upload data to"
              onValueChange={setTableType}
              value={tableType}
            >
              <RadioGroup.Item value="newTable" label="New table" />
              <RadioGroup.Item value="existing" label="Existing table" />
            </RadioGroup>
            {tableType === 'newTable' && <TableSettingsForm />}

            {tableType === 'existing' &&
              importModel.data?.tableConfig?.config && (
                <TableMappingForm
                  mapping={importModel.data.tableConfig.config as TableMapping}
                  onTableChange={onTableChange}
                  onMappingChange={onTableMappingChange}
                  tables={tables}
                />
              )}
          </Container>

          <BottomBar
            onOpenQueryClick={(): void => openDataLoadAsQuery(importModel)}
            onCreateImportClick={createDataImport}
            loading={submitting}
          />
        </Container>
      </VerticalStepper.Step>
    </VerticalStepper>
  );
}

export default ImportForm;
