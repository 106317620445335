import { ReactElement, ReactNode } from 'react';
import SQLConsoleProvider from 'src/components/SQLConsoleProvider';
import ConsoleLayout from 'src/layout/SqlConsoleLayout/ConsoleLayout';
import { useIsUnifiedConsole } from 'src/lib/features';
import CurrentConsoleLayout from 'src/layout/SqlConsoleLayout/CurrentConsoleLayout';
import ServiceLayout from 'src/layout/ServiceLayout';
import { Outlet } from 'react-router-dom';
import { useCurrentInstance } from 'src/instance/instanceController';

export default function SqlConsoleLayout({
  children
}: {
  children?: ReactNode;
}): ReactElement {
  const isUnifiedConsole = useIsUnifiedConsole();
  const currentInstance = useCurrentInstance();

  if (isUnifiedConsole) {
    return (
      <ServiceLayout>
        <SQLConsoleProvider>
          <ConsoleLayout>
            {currentInstance && !children && <Outlet />}
            {currentInstance && children && <>{children}</>}
          </ConsoleLayout>
        </SQLConsoleProvider>
      </ServiceLayout>
    );
  }

  // Legacy console
  return (
    <SQLConsoleProvider>
      <CurrentConsoleLayout>
        {currentInstance && !children && <Outlet />}
        {currentInstance && children && <>{children}</>}
      </CurrentConsoleLayout>
    </SQLConsoleProvider>
  );
}
