import { useApiClient } from 'src/lib/controlPlane/client';
import useSWR, { SWRResponse } from 'swr';

export const useDataWarehouseSize = ({
  dataWarehouseId,
  organizationId
}: {
  organizationId: string;
  dataWarehouseId: string;
}): SWRResponse<number> => {
  const apiClient = useApiClient();
  const size = useSWR<number>(`fetch-size-${dataWarehouseId}`, async (): Promise<number> => {
    return await apiClient.warehouse.getWarehouseSize({
      organizationId,
      dataWarehouseId
    });
  });

  return size;
};
