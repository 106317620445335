import { databasesSql } from 'shared/src/clickhouse';
import { useCurrentInstance } from 'src/instance/instanceController';
import { useQueryRunner } from 'src/lib/clickhouse/query';

type DatabaseLoader = () => Promise<Array<string>>;
export const useLoadDatabases = (): DatabaseLoader => {
  const currentInstance = useCurrentInstance();

  const loadDatabasesRunner = useQueryRunner({
    sql: databasesSql
  });

  const loadDatabases = async (): Promise<Array<string>> => {
    if (!currentInstance) {
      console.error('no current service');
      return [];
    }

    const result = await loadDatabasesRunner({
      credentialsOverride: { database: 'system', serviceId: currentInstance.id }
    });
    if ('error' in result) {
      throw new Error(result.error);
    }

    return result.rows.map((row) => (typeof row[0] === 'string' ? row[0] : JSON.stringify(row[0])));
  };

  return loadDatabases;
};
