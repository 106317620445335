import { Select } from '@clickhouse/click-ui';
import { Draft } from 'immer';
import { useAtom } from 'jotai';

import { ClickPipeKafkaImport } from 'shared/src/dataLoading';
import { kafkaTopicsAtom } from 'src/state/dataLoading';

import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';

export default function TopicSelector(): JSX.Element {
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipeKafkaImport>();
  const [topics] = useAtom(kafkaTopicsAtom);

  const selectedTopic = importModel.data.topic;

  const onSelect = (value: string): void =>
    updateImportModel((draft: Draft<ClickPipeKafkaImport>) => {
      draft.data.topic = value;
    });

  return (
    <Select
      label="Topic"
      data-testid="topic-select"
      showSearch={topics.length > 10}
      value={selectedTopic}
      onSelect={onSelect}
      className="fs-exclude"
    >
      {topics.map((topic) => (
        <Select.Item key={topic} value={topic} className="fs-exclude">
          {topic}
        </Select.Item>
      ))}
    </Select>
  );
}
