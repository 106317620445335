import { ReactElement } from 'react';
import { Button, Container, Separator } from '@clickhouse/click-ui';
import { AddressInfoContainer } from 'src/components/BillingCardModal/AddressInfoContainer';
import { useIsAddressFormValid } from 'src/components/BillingCardModal/FormState';

type IndividualTabParams = {
  onCompleteSignUp: () => void;
  isLoading: boolean;
};
const IndividualTab = ({
  onCompleteSignUp,
  isLoading
}: IndividualTabParams): ReactElement => {
  const canBeSubmitted = useIsAddressFormValid();
  return (
    <Container orientation="vertical">
      <>
        <AddressInfoContainer />
        <Separator size="md" />
        <Container justifyContent="end" gap="md" fillWidth={true}>
          <Button
            onClick={(): void => {
              onCompleteSignUp();
            }}
            iconRight="arrow-right"
            disabled={!canBeSubmitted}
            loading={isLoading}
          >
            Complete sign up
          </Button>
        </Container>
      </>
    </Container>
  );
};

export default IndividualTab;
