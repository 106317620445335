import { ReactElement } from 'react';
import { createToast } from 'primitives';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useQuery } from 'src/lib/query/QueryState';
import { RunningQuery } from 'src/lib/query/runningQueryTypes';
import { useGptConsent } from 'src/lib/gpt/GptConsentHook';
import { useGptCorrection } from 'src/lib/gpt/GptCorrectionHook';
import { Button, Tooltip } from '@clickhouse/click-ui';
import { useGptConsentModal } from 'src/lib/gpt/GptConsentModalHook';

export interface FixQueryButtonProps {
  runId: string | undefined;
}

/**
 * A button to fix a SQL query.
 * @param runId The ID of the query run to fix
 */
const FixQueryButton = ({
  runId
}: FixQueryButtonProps): ReactElement | null => {
  const {
    requestGptCorrection,
    cancelRequest,
    isRequestRunning,
    getCorrectedQueryText
  } = useGptCorrection();
  const { hasConsent } = useGptConsent();
  const { showGptConsentModal } = useGptConsentModal();
  const query: RunningQuery | null = useQuery(runId);
  const error = query?.result?.error?.message;
  if (!query || !runId || !error) {
    // Do not show the button if there was no running query for this ID
    // or if the user has not given consent
    // or if there was no error with the query
    return null;
  }
  const correctedQuery = getCorrectedQueryText(runId);

  const correctQuery = (): void => {
    requestGptCorrection(runId, query).catch((error) => {
      createToast('Error', 'alert', errorMessage(error));
    });
  };

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Button
          onClick={(): void => {
            if (isRequestRunning) {
              cancelRequest();
            } else if (hasConsent) {
              correctQuery();
            } else {
              void showGptConsentModal().then((accepted) => {
                if (accepted) {
                  correctQuery();
                }
              });
            }
          }}
          iconLeft={isRequestRunning ? 'loading-animated' : 'sparkle'}
          disabled={!!correctedQuery}
          data-testid="gptFixQueryBtn"
        >
          {isRequestRunning ? 'Cancel' : 'Fix Query'}
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>Fix Query with GPT</Tooltip.Content>
    </Tooltip>
  );
};

export default FixQueryButton;
