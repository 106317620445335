import { useAtomValue, useSetAtom } from 'jotai';
import { atom } from 'jotai/index';
import { useMemo } from 'react';
import { defaultCapabilities } from 'shared/src/clickhouse';
import { DatabaseResult } from 'shared/src/clickhouse/databases';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { SchemaFunction, Table, DatabaseCapabilities } from 'shared/src/clickhouse/types';
import { DatabaseName, useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';

export interface DatabaseMetadata {
  version: string | undefined;
  tables: Table[];
  views: Table[];
  materializedViews: Table[];
  functions: SchemaFunction[];
  capabilities: DatabaseCapabilities;
}

export type DatabaseMetadataMap = Record<string, DatabaseMetadata>;

export function getCachedDatabaseValue(key: string): DatabaseName {
  const result = window.localStorage.getItem(key);
  if (!result || result === 'undefined') {
    return 'default';
  }

  return result;
}

const getDatabaseMetadataMapKey = (serviceId: string, databaseName: DatabaseName): string => {
  return `${serviceId}:${databaseName}`;
};

export const databaseMetadataMapAtom = atom<DatabaseMetadataMap>({});

export const databaseListAtom = atom<DatabaseResult[]>([]);

export const databaseMetadataLoadingAtom = atom(false);
export const databaseMetadataErrorAtom = atom<string | null>(null);

export type Setter<T> = (value: T) => void;
export type SetterPerDatabase<T> = (serviceId: string, databaseName: DatabaseName, value: T) => void;

/**
 * Hook to set the database metadata for the given service id and database name.
 * @returns {SetterPerDatabase<DatabaseMetadata>} The setter function for database metadata.
 */
export const useSetDatabaseMetadata = (): SetterPerDatabase<DatabaseMetadata> => {
  const setMetadata = useSetAtom(databaseMetadataMapAtom);
  return (serviceId: string, databaseName: string, value: DatabaseMetadata) => {
    setMetadata((oldMetadataMap) => {
      const key = getDatabaseMetadataMapKey(serviceId, databaseName);
      const newMetadataMap = {
        ...oldMetadataMap,
        [key]: value
      };
      return newMetadataMap;
    });
  };
};

/**
 * Hook to reset the database metadata for the given service id and database name.
 * @returns {(serviceId: string, databaseName: DatabaseName) => void} The function to reset database metadata.
 */
export const useResetDatabaseMetadata = (): ((serviceId: string, databaseName: DatabaseName) => void) => {
  const setMetadata = useSetAtom(databaseMetadataMapAtom);
  return (serviceId: string, databaseName: DatabaseName): void => {
    setMetadata((oldMetadataMap) => {
      const key = getDatabaseMetadataMapKey(serviceId, databaseName);
      const oldMetadata = oldMetadataMap[key];
      const newMedatada = {
        version: oldMetadata?.version,
        tables: [],
        views: [],
        materializedViews: [],
        functions: [],
        capabilities: defaultCapabilities,
        databaseName,
        serviceId
      };
      const newMetadataMap = {
        ...oldMetadataMap,
        [key]: newMedatada
      };
      return newMetadataMap;
    });
  };
};

export const useMetadataLoading = (): boolean => {
  return useAtomValue(databaseMetadataLoadingAtom);
};

const metadataLoaderVisibleAtom = atom(true);

export const useSetMetadataLoaderVisible = (): Setter<boolean> => useSetAtom(metadataLoaderVisibleAtom);
export const useIsMetadataLoaderVisible = (): boolean => useAtomValue(metadataLoaderVisibleAtom);

export const useMetadataError = (): string | null => {
  return useAtomValue(databaseMetadataErrorAtom);
};

/**
 * Hook to get the metadata for the currently selected database and service.
 * @returns {DatabaseMetadata | undefined} The metadata for the selected database, or undefined if not found.
 */
const useMetadata = (): DatabaseMetadata | undefined => {
  const databaseName = useSelectedDatabaseValue();
  const serviceId = useCurrentInstanceId() ?? '';
  const metadataMap = useAtomValue(databaseMetadataMapAtom);
  const key = getDatabaseMetadataMapKey(serviceId, databaseName);
  return metadataMap[key];
};

/**
 * Hook to get the tables in the current service/database.
 * @returns {Table[]} The tables in the current database.
 */
export const useTables = (): Table[] => {
  const metadata = useMetadata();
  const tables = metadata?.tables ?? [];
  // `s3_clickpipe` is a staging table used by the object storage pipes
  // `kinesis_clickpipe` is a staging table used by the kinesis pipes
  return tables.filter(
    (table) =>
      !table.tableName.startsWith('.inner_id.') &&
      !table.tableName.startsWith('s3_clickpipe') &&
      !table.tableName.startsWith('kinesis_clickpipe')
  );
};

export const useTableNames = (): string[] => {
  const tables = useTables();
  return tables.map((table) => table.tableName);
};

export const useViews = (): Table[] => {
  const metadata = useMetadata();
  return metadata?.views ?? [];
};

export const useMaterializedViews = (): Table[] => {
  const metadata = useMetadata();
  const materializedViews = metadata?.materializedViews ?? [];
  return useMemo(() => {
    // `s3_clickpipe` is a staging view used by the object storage pipes
    return materializedViews.filter((materializedView) => !materializedView.tableName.startsWith('s3_clickpipe'));
  }, [materializedViews]);
};

export const useFunctions = (): SchemaFunction[] => {
  const metadata = useMetadata();
  return metadata?.functions ?? [];
};

export const useDatabases = (): DatabaseResult[] => {
  return useAtomValue(databaseListAtom);
};

export const useSetDatabases = (): Setter<DatabaseResult[]> => {
  return useSetAtom(databaseListAtom);
};

export const useDatabasesNames = (): Set<string> => {
  const databases = useDatabases();
  return useMemo(() => {
    return databases.reduce((result, db) => {
      result.add(db.name);
      return result;
    }, new Set<string>());
  }, [databases]);
};

export const useDatabaseCapabilities = (): DatabaseCapabilities => {
  const metadata = useMetadata();
  return metadata?.capabilities ?? defaultCapabilities;
};

export const useSetMetadataLoading = (): Setter<boolean> => {
  const setMetadataLoading = useSetAtom(databaseMetadataLoadingAtom);
  const setError = useSetAtom(databaseMetadataErrorAtom);

  return (loading: boolean): void => {
    setMetadataLoading(loading);
    if (loading) {
      setError(null);
    }
  };
};

export const useSetMetadataError = (): Setter<string | null> => {
  return useSetAtom(databaseMetadataErrorAtom);
};
