import { TextField } from '@clickhouse/click-ui';

import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { memo, ReactElement } from 'react';

import {
  containerStyle,
  hintStyle,
  sectionHeaderStyle
} from 'src/components/QueryView/QueryVariables/styles';

export interface QueryVariablesProps {
  queryVariables: Record<string, string>;
  oldQueryVariables?: Record<string, string>;
  editable: boolean;
  query: string;
  setQueryVariables: (value: Record<string, string>) => void;
}

function QueryVariables({
  editable,
  query,
  queryVariables,
  setQueryVariables
}: QueryVariablesProps): ReactElement {
  if (!queryVariables || !Object.keys(queryVariables).length) {
    return (
      <div css={containerStyle}>
        <div css={sectionHeaderStyle}>Query Variables</div>
        <div css={hintStyle}>
          Type {'{{variable_name}}'} into the editor to use query variables
        </div>
      </div>
    );
  }

  const queryVariableKeys = sortBy(Object.keys(queryVariables), [
    (o): number => {
      return query.indexOf(`{{${o}}}`);
    }
  ]);
  const disabled = !editable;
  return (
    <div css={containerStyle}>
      <div css={sectionHeaderStyle}>Query Variables</div>
      {queryVariableKeys.map((key) => {
        const change = (newValue: string): void => {
          if (editable) {
            setQueryVariables({
              ...queryVariables,
              [key]: newValue
            });
            // oldQueryVariables: {
            //   ...oldQueryVariables,
            //   [key]: newValue,
            // },
          }
        };

        return (
          <TextField
            key={key}
            label={key}
            disabled={disabled}
            onChange={(value: string): void => {
              change(value);
            }}
            placeholder="Enter value"
            value={queryVariables[key]}
            className="fs-exclude"
            data-testid={`query-variable-${key}`}
          />
        );
      })}
    </div>
  );
}

const areEqual = (
  prevProps: QueryVariablesProps,
  nextProps: QueryVariablesProps
) =>
  isEqual(prevProps.queryVariables, nextProps.queryVariables) &&
  isEqual(prevProps.setQueryVariables, nextProps.setQueryVariables);

export default memo(QueryVariables, areEqual);
