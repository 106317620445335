import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd-cjs';
import { getEmptyImage } from 'react-dnd-html5-backend-cjs';

import {
  logCreateFolder,
  sidebarLogEvent,
  onContextMenuToggle
} from 'src/components/QueryView/analytics';

import NodeName from 'src/components/QueryView/QueryList/Folders/Tree/NodeName';
import {
  contentRootStyle,
  sidebarCloseBtn
} from 'src/components/QueryView/QueryList/Folders/Tree/styles';
import {
  FolderNodeProps,
  CollectedProps,
  ItemType
} from 'src/components/QueryView/QueryList/Folders/Tree/types';
import {
  Tooltip,
  IconButton,
  SidebarNavigationItem,
  ContextMenu
} from '@clickhouse/click-ui';

const logName = 'queryContextMenu';
const FolderNode = ({
  category,
  expanded,
  id,
  level,
  parentId,
  path,
  onDelete,
  onDrop,
  onExpand,
  onTitleChange,
  onCreateFolder,
  title: titleProp,
  mainRef,
  selected
}: FolderNodeProps): ReactElement => {
  const ref = useRef(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState(titleProp);
  const [editable, setEditable] = useState(false);
  const [{ isDragging }, drag, preview] = useDrag({
    canDrag: !category,
    item: {
      id,
      type: 'node',
      isFolder: true,
      parentId,
      path,
      title
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop<
    ItemType,
    unknown,
    Partial<CollectedProps>
  >({
    accept: 'node',
    canDrop: () => !category,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      if (item.parentId !== id) {
        onDrop(item.id, id, 'over', item);
      }
    },
    collect: (monitor) => {
      const isOver = !!monitor.isOver({ shallow: true });

      if (isOver) {
        mainRef.current?.setSelectedFolder(parentId);
      } else {
        mainRef.current?.setSelectedFolder(null);
      }

      return {
        isOver
      };
    }
  });

  drag(drop(ref));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    setTitle(titleProp);
  }, [titleProp]);

  const renameFolder = (): void => {
    setEditable(true);
    setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.select();
      }
    });
  };

  const onRename = (title: string): void => {
    onTitleChange(id, path, title);
  };

  useEffect(() => {
    if (mainRef.current && mainRef.current.shouldHighlightFolder(id)) {
      renameFolder();
    }
  }, [mainRef.current]);

  return (
    <>
      <ContextMenu onOpenChange={onContextMenuToggle}>
        <ContextMenu.Trigger>
          <SidebarNavigationItem
            aria-label={`folderNode:${title}`}
            type="sqlSidebar"
            icon={expanded ? 'folder-open' : 'folder-closed'}
            level={level}
            ref={ref}
            role="listitem"
            css={contentRootStyle(level)}
            selected={!category && (isOver || selected)}
            className="folder"
            data-dragging={isDragging}
            data-testid="folderNode"
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              if (!editable) {
                onExpand(path, id);
                sidebarLogEvent('folder', 'Open');
              }
            }}
            label={
              <>
                <NodeName
                  onRename={onRename}
                  editable={editable}
                  setEditable={setEditable}
                  title={title}
                  setTitle={setTitle}
                  currentTitle={titleProp}
                  ref={titleRef}
                />
                {!isDragging && !category && (
                  <Tooltip>
                    <Tooltip.Trigger
                      className="delete-icon"
                      css={sidebarCloseBtn}
                    >
                      <IconButton
                        icon="cross"
                        type="ghost"
                        size="xs"
                        onClick={(e): void => {
                          e.stopPropagation();
                          onDelete(path);
                        }}
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>Delete Folder</Tooltip.Content>
                  </Tooltip>
                )}
              </>
            }
          />
        </ContextMenu.Trigger>
        <ContextMenu.Content side="bottom" align="start" loop>
          <ContextMenu.Item
            onClick={(e: MouseEvent): void => {
              e.stopPropagation();
              renameFolder();
              sidebarLogEvent(logName, 'FolderRename');
            }}
          >
            Rename folder
          </ContextMenu.Item>
          <ContextMenu.Item
            onClick={(e: MouseEvent): void => {
              e.stopPropagation();
              void onCreateFolder(id);
              if (!expanded) {
                onExpand(path, id);
              }
              logCreateFolder();
            }}
          >
            Create folder
          </ContextMenu.Item>
          <ContextMenu.Item
            onClick={(e: MouseEvent): void => {
              e.stopPropagation();
              onDelete(path);
              sidebarLogEvent(logName, 'FolderDelete');
            }}
          >
            Delete folder
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu>
    </>
  );
};

export default FolderNode;
