import { TableHeaderType, TableRowType } from '@clickhouse/click-ui';
import { OrganizationActivity } from '@cp/common/protocol/Organization';
import { unparse } from 'papaparse';
import { useCallback, useMemo, useState } from 'react';
import downloadjs from 'downloadjs';
import { orderBy } from 'lodash';
import { formatKeySuffix, getDisplayActivityName } from '@cp/common/utils/OrganizationUtils';

interface Props {
  search: string;
  data?: Array<OrganizationActivity>;
}

export type SortFn = (sortDir: 'asc' | 'desc', header: TableHeaderType, index: number) => void;
interface FilterOrgActivities {
  filteredRows: Array<TableRowType>;
  headers: Array<TableHeaderType>;
  onSort: SortFn;
  exportCSV: () => void;
}

function useFilterOrgActivities({ search, data }: Props): FilterOrgActivities {
  const [sort, setSort] = useState<[number, 'asc' | 'desc']>([3, 'asc']);
  const [headers, setHeaders] = useState<Array<TableHeaderType>>([
    { label: 'Activity', sortPosition: 'start', isSortable: true },
    { label: 'User', sortPosition: 'start', isSortable: true },
    { label: 'IP address', sortPosition: 'start', isSortable: true },
    { label: 'Time', sortPosition: 'start', isSortable: true, sortDir: 'asc' }
  ]);

  const filteredRows = useMemo((): Array<TableRowType> => {
    if (!data) {
      return [];
    }

    const [sortIndex, sortDir] = sort;
    const unsortedRows = data.flatMap((row) => {
      const loweredSearch = search.toLowerCase().trim();
      const openapiKeyInfo = row.openapiKeySuffix ? formatKeySuffix(row.openapiKeySuffix) : undefined;
      const convertedRow = {
        id: row.id,
        items: [
          {
            label: getDisplayActivityName(row)
          },
          {
            label: openapiKeyInfo || row.email || row.fullName || '',
            className: 'fs-exclude'
          },
          {
            label: row.ipAddress || '-',
            className: 'fs-exclude'
          },
          {
            label: `${new Date(row.timestamp).toUTCString().split('GMT')[0].trim()}(UTC)`
          }
        ]
      };
      if (loweredSearch.length === 0) {
        return convertedRow;
      }
      const hasSearchValue =
        loweredSearch
          .split(' ')
          .every(
            (keyword) =>
              convertedRow.items[0].label.toLowerCase().trim().includes(keyword) ||
              convertedRow.items[1].label.toLowerCase().trim().includes(keyword) ||
              convertedRow.items[2].label?.toLowerCase().trim().includes(keyword)
          ) || convertedRow.items[3].label.toLowerCase().includes(loweredSearch);
      if (hasSearchValue) {
        return convertedRow;
      }
      return [];
    });

    return orderBy(
      unsortedRows,
      (obj) => {
        if (sortIndex == 3) {
          return new Date(obj.items[sortIndex].label).getTime();
        }
        return obj.items[sortIndex].label;
      },
      sortDir
    );
  }, [search, data, sort]);

  const exportCSV = useCallback((): void => {
    const rowData = filteredRows.map((row) => {
      const rowObj = row.items.reduce(
        (obj, value, i) => ({
          ...obj,
          [headers[i].label as string]: value.label
        }),
        {}
      );
      return {
        Id: row.id,
        ...rowObj
      };
    });

    downloadjs(unparse(rowData, { quotes: true }), `audit_${new Date().toDateString()}.csv`.toLowerCase(), 'text/csv');
  }, [filteredRows, headers]);

  const onSort: SortFn = useCallback((sortDir, _, index) => {
    setHeaders((headers) => {
      return headers.map((header, headerIndex) => {
        header.sortDir = headerIndex === index ? sortDir : undefined;
        return header;
      });
    });
    setSort([index, sortDir]);
  }, []);

  return { filteredRows, exportCSV, headers, onSort };
}

export default useFilterOrgActivities;
