import { ReactElement } from 'react';
import {
  Container,
  Icon,
  Panel,
  Separator,
  Spacer,
  Switch,
  Text,
  createToast
} from '@clickhouse/click-ui';
import CopyButton from 'src/components/CopyButton';
import PasswordResetInfo from 'src/components/ServiceConnect/PasswordResetInfo';
import {
  ServiceModalMySqlProtocolProps,
  getMysqlConnectionString
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import { FullWidthCodeBlock } from 'src/components/ServiceConnect/Tabs/FullWidthCodeBlock';

type MySqlProtocolProps = {
  hostname: string;
  credentials: ServiceModalMySqlProtocolProps;
};

/**
 * The section that displays the MySQL protocol information.
 * You can enable or disable the protocol, `and copy the connection string, username and password.
 * If the password is not provided, it displays a link to reset the password.
 */
const MySqlProtocol = ({
  credentials,
  hostname
}: MySqlProtocolProps): ReactElement => {
  const connectionString = getMysqlConnectionString(
    hostname,
    credentials.mySqlUsername
  );
  const {
    canEnable,
    mySqlUsername,
    mySqlPassword,
    isLoading,
    enabled,
    onEnabledChange
  } = credentials;
  return (
    <>
      <Spacer />
      <Panel
        orientation="vertical"
        color="muted"
        alignItems="start"
        data-testid="mysql-protocol-information-panel"
      >
        <Switch
          checked={enabled}
          label="Enable the MySQL protocol"
          dir="end"
          orientation="horizontal"
          onClick={(): void => {
            if (!canEnable) {
              createToast({
                description:
                  'Please reset your service password to enable mysql',
                title: 'Password Reset Required',
                type: 'danger'
              });
            } else {
              onEnabledChange(!enabled);
            }
          }}
          disabled={isLoading}
        />
        <Text color="muted">
          By enabling the ClickHouse MySQL interface, your service will expose
          the network port 3306 for the MySQL wire protocol.
        </Text>

        {enabled ? (
          <>
            <Text color="muted">
              To connect to MySQL interfaces, we provide a separate user. Save
              these credentials.
            </Text>

            <Container gap="xl">
              <Text>MySQL Username</Text>
              <Text>{mySqlUsername}</Text>
              <CopyButton text={mySqlUsername} />
            </Container>
            <Container gap="xl">
              <Text>MySQL Password</Text>
              {mySqlPassword ? (
                <>
                  <Text>{mySqlPassword}</Text>
                  <CopyButton text={mySqlPassword} />
                </>
              ) : (
                <PasswordResetInfo />
              )}
            </Container>
            <Separator orientation="horizontal" size="xs" />
            <FullWidthCodeBlock language="bash" showLineNumbers wrapLines>
              {connectionString}
            </FullWidthCodeBlock>
          </>
        ) : (
          <>
            {isLoading && (
              <Container gap="xs">
                <Icon name="loading-animated" />
                <Text>Loading MySQL settings</Text>
              </Container>
            )}
          </>
        )}
      </Panel>
    </>
  );
};

export default MySqlProtocol;
