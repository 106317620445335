import { useCallback } from 'react';
import { ExecuteQueryResult } from 'shared/src/queryEndpoints/protocol';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useApiClient } from 'src/lib/controlPlane/client';

export const useExecuteQuery = (): ((
  sql: string,
  variables: Record<string, unknown>
) => Promise<ExecuteQueryResult>) => {
  const apiClient = useApiClient();
  const serviceId = useCurrentInstanceId() || '';

  return useCallback(
    (sql: string, variables: Record<string, unknown> = {}): Promise<ExecuteQueryResult> => {
      try {
        return apiClient.queryEndpoints.executeQuery({ serviceId, sql, variables });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    [apiClient.queryEndpoints, serviceId]
  );
};
