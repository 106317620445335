import { openDB, IDBPDatabase } from 'idb';

import { Page, RowData } from 'src/lib/query/RowCache/types';

const DatabaseIsClosingError = "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.";

export class RowDB {
  db: IDBPDatabase<RowData>;
  onIndexedDBClosingError?: () => void;
  cachedWrittenQueryIds = new Set<string>();

  constructor(db: IDBPDatabase<RowData>) {
    this.db = db;
  }

  async catchErrors<T>(promise: Promise<T>): Promise<T> {
    try {
      return await promise;
    } catch (e) {
      if (e instanceof Error && e.message === DatabaseIsClosingError) {
        this.onIndexedDBClosingError?.();
      }
      throw e;
    }
  }

  async getQueryIds(): Promise<Set<string>> {
    const queries = await this.catchErrors(this.db.getAll('queries'));
    return new Set(queries.map((item) => item.queryId));
  }

  async readPage(queryId: string, pageIndex: number): Promise<Page | null> {
    const row = await this.catchErrors(this.db.get('rows', [queryId, pageIndex]));
    return row ? row.data : null;
  }

  async writePage(queryId: string, pageIndex: number, page: Page): Promise<boolean> {
    const transaction = this.db.transaction(['rows', 'queries'], 'readwrite');
    const rowStore = transaction.objectStore('rows');
    const queryStore = transaction.objectStore('queries');

    const promises: Promise<unknown>[] = [];

    if (!this.cachedWrittenQueryIds.has(queryId)) {
      promises.push(queryStore.add({ queryId }));
      this.cachedWrittenQueryIds.add(queryId);
    }

    promises.push(rowStore.add({ queryId, index: pageIndex, data: page }));

    try {
      await Promise.all([...promises, transaction.done].map((p) => this.catchErrors(p)));
      return true;
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        return false;
      }
      throw e;
    }
  }

  async deleteQueryPages(queryId: string): Promise<void> {
    const transaction = this.db.transaction(['rows', 'queries'], 'readwrite');
    const rowObjectStore = transaction.objectStore('rows');
    const queryObjectStore = transaction.objectStore('queries');
    await Promise.all([
      this.catchErrors(
        rowObjectStore.delete(window.IDBKeyRange.bound([queryId, 0], [queryId, Number.MAX_SAFE_INTEGER]))
      ),
      this.catchErrors(queryObjectStore.delete(queryId)),
      this.catchErrors(transaction.done)
    ]);
  }

  close() {
    this.db.close();
  }
}

const initialVersion = 1;
const versionWithQueryKey = 2;
const versionWithQueryList = 3;
const latestVersion = versionWithQueryList;

export async function openRowDB(onVersionIncompatible?: () => void): Promise<RowDB> {
  const db = await openDB<RowData>('rowData', latestVersion, {
    upgrade(db, oldVersion, newVersion, transaction) {
      if (initialVersion > oldVersion) {
        db.createObjectStore('rows', { keyPath: ['queryId', 'index'] });
      }

      if (versionWithQueryKey > oldVersion) {
        const rowStore = transaction.objectStore('rows');

        rowStore.createIndex('queryId', 'queryId', { unique: false });
      }

      if (versionWithQueryList > oldVersion) {
        db.deleteObjectStore('rows');
        db.createObjectStore('rows', { keyPath: ['queryId', 'index'] });

        db.createObjectStore('queries', {
          keyPath: 'queryId'
        });
      }
    },
    blocking() {
      onVersionIncompatible?.();
      if (db) {
        db.close();
      }
    }
  });

  return new RowDB(db);
}
