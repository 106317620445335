import {
  CardHorizontal,
  CardHorizontalProps,
  CardPrimary,
  CardPrimaryProps,
  Container,
  GridContainer,
  GridContainerProps,
  Link,
  Panel,
  Separator,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { toTimeByTimezone } from '@cp/common/utils/DateTimeUtils';
import { ReactElement } from 'react';
import NoDecorationLink from 'src/components/NoDecorationLink/NoDecorationLink';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import SplitLayout from 'src/layout/SplitLayout';
import { findAll } from 'src/util/strapi';
import styled from 'styled-components';
import useSWR from 'swr';
import EventsCard from 'src/pages/LearnPage/EventsCard';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';

interface CardProps extends CardHorizontalProps {
  url: string;
}
const learn: Array<CardProps> = [
  {
    icon: 'brackets',
    title: 'Getting started',
    description: 'Everything you need for a successful start.',
    url: 'https://clickhouse.com/docs/en/cloud-quick-start'
  },
  {
    icon: 'data',
    title: 'Ingesting data',
    description: 'Loading data into your Cloud service.',
    url: 'https://clickhouse.com/docs/en/sql-reference/statements/insert-into'
  },
  {
    icon: 'integrations',
    title: 'Integrating with ClickHouse',
    description: 'Connecting to third party apps and services.',
    url: 'https://clickhouse.com/docs/en/integrations/'
  },
  {
    icon: 'activity',
    title: 'Visualizing data',
    description: 'Connect to your favorite BI tools.',
    url: 'https://clickhouse.com/docs/en/integrations/data-visualization/'
  },
  {
    icon: 'arrow-directions',
    title: 'Connecting to ClickHouse',
    description: 'Sending queries to your Cloud service.',
    url: 'https://clickhouse.com/docs/en/interfaces/cli'
  },
  {
    icon: 'secure',
    title: 'Securing your services',
    description: 'Passwords, access lists, VPCs, and more.',
    url: 'https://clickhouse.com/docs/en/manage/security/'
  }
];
const featured: Array<CardProps> = [
  {
    icon: 'gift',
    title: 'Cloud Changelog',
    description: 'See what’s new in the latest Cloud release.',
    url: 'https://clickhouse.com/docs/en/whats-new/cloud/'
  },
  {
    icon: 'kafka',
    title: 'Integrating Kafka',
    description: 'Inserting Kafka based data into ClickHouse.',
    url: 'https://clickhouse.com/docs/en/integrations/kafka/'
  },
  {
    icon: 'brackets',
    title: 'Working with JSON',
    description: 'Exploring options with JSON and ClickHouse.',
    url: 'https://clickhouse.com/docs/en/guides/developer/working-with-json/'
  },
  {
    icon: 'metrics',
    title: 'Connecting Grafana to ClickHouse',
    description: 'Beautiful visualizations in Grafana.',
    url: 'https://clickhouse.com/docs/en/connect-a-ui/grafana-and-clickhouse/'
  }
];
interface AcademyLink {
  title: string;
  imgUrl: string;
  time: string;
  lessonCount: number;
  url: string;
}

const academyLinks: Array<AcademyLink> = [
  {
    title: 'ClickHouse Developer',
    imgUrl: '/images/learn/clickhouse-developer.png',
    time: '12 hrs',
    lessonCount: 12,
    url: 'https://learn.clickhouse.com/visitor_catalog_class/show/1328973/'
  },
  {
    title: 'Getting Started',
    imgUrl: '/images/learn/getting-started.png',
    time: '1 hr',
    lessonCount: 1,
    url: 'https://learn.clickhouse.com/visitor_catalog_class/show/1328489/'
  },
  {
    title: 'Inserting Data',
    imgUrl: '/images/learn/inserting-data.png',
    time: '1 hr',
    lessonCount: 1,
    url: 'https://learn.clickhouse.com/visitor_catalog_class/show/1328910/'
  }
];

const getTimeByTimezone = (dateTime: Date, timeZone?: string): string => {
  return toTimeByTimezone(dateTime, timeZone);
};

type EventType = {
  id: number;
  title: string;
  slug: string;
  shortDescription: string;
  description: string;
  richDescription: string;
  localDatetime: string;
  datetimeAndTimezoneString: string;
  viewMoreDetailsText: string;
  category: string;
  thumbnailPng: unknown;
  hostedBy: unknown;
  agenda?: unknown;
  location: unknown;
  darkFeatureImagePng: unknown;
  lightFeatureImagePng: unknown;
  eventEnded: boolean;
  eventVideoUrl: string;
  form: unknown;
  featured: boolean;
  recordedVimeoUrl?: string;
  keywords?: string;
  duration?: string;
};

const EventDescription = ({
  localDatetime,
  duration
}: {
  localDatetime: string;
  duration?: string;
}): ReactElement => {
  const date = new Date(localDatetime);
  return (
    <Container orientation="vertical" gap="xl">
      <Text color="muted">{[duration, date.toDateString()].join(' • ')}</Text>
      <Container orientation="vertical">
        <Text color="muted">
          {getTimeByTimezone(date, 'US/Pacific')} PST (San Francisco)
        </Text>
        <Text color="muted">
          {getTimeByTimezone(date, 'Europe/Amsterdam')} CET (Amsterdam)
        </Text>
        <Text color="muted">{getTimeByTimezone(date)}</Text>
      </Container>
    </Container>
  );
};

const fetchWorkshops = async (): Promise<Array<CardPrimaryProps>> => {
  const { data }: { data: Array<EventType> } = await findAll('events', {
    filters: {
      localDatetime: {
        $gt: new Date().toISOString()
      },
      category: {
        $eqi: 'Free Training'
      }
    },
    sort: ['localDatetime:ASC'],
    populate: [],
    pagination: { limit: 3 }
  });

  const workshops: Array<CardPrimaryProps> = data.map((element) => ({
    title: element.title,
    children: (
      <EventDescription
        localDatetime={element.localDatetime}
        duration={element.duration}
      />
    ),
    infoText: 'Register Now',
    alignContent: 'start',
    size: 'sm',
    infoUrl: `https://clickhouse.com/events/${element.slug}`
  }));

  return workshops;
};

const Video = styled.iframe`
  aspect-ratio: 16/9;
  border-radius: 8px;
  width: 100%;
  max-width: 40%;
  height: fit-content;
  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

interface CustomGridContainerProps
  extends Omit<
    GridContainerProps,
    | 'gridTemplateColumns'
    | 'isResponsive'
    | 'rowGap'
    | 'columnGap'
    | 'alignItems'
  > {
  column: number;
}

const CustomGridContainer = ({
  column,
  ...props
}: CustomGridContainerProps): ReactElement => {
  return (
    <GridContainer
      gridTemplateColumns={`repeat(${column}, 1fr)`}
      isResponsive
      rowGap="lg"
      columnGap="lg"
      alignItems="center"
      {...props}
    />
  );
};

function LearnPage(): ReactElement {
  const { data, isLoading } = useSWR('fetch', fetchWorkshops);
  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Learn" />
      <MainPaneLayout.Content>
        <SplitLayout>
          <MaxWidthContainer orientation="vertical" padding="lg">
            <Panel hasBorder fillWidth>
              <Container gap="xl">
                <Container orientation="vertical" gap="xs">
                  <Title type="h2" size="lg">
                    Watch our video introduction to getting up and running with
                    ClickHouse Cloud.
                  </Title>
                  <Text size="md" color="muted">
                    This five minute video shows how to load data into your
                    service, run your first queries, and understand your metrics
                  </Text>
                </Container>
                <Video
                  src="https://player.vimeo.com/video/751409158?h=54cfbce3b9"
                  width="360"
                  height="202"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                />
              </Container>
            </Panel>
            <Spacer size="lg" />
            <Container orientation="vertical" gap="xs">
              <Title type="h2" size="lg">
                ClickHouse 101
              </Title>
              <Spacer size="xs" />
              <CustomGridContainer column={2}>
                {learn.map(({ url, ...linkProps }) => (
                  <NoDecorationLink
                    key={linkProps.title}
                    to={url}
                    target="_blank"
                  >
                    <CardHorizontal {...linkProps} />
                  </NoDecorationLink>
                ))}
              </CustomGridContainer>
            </Container>
            <Separator size="xl" />
            <Container orientation="vertical" gap="xs">
              <Container justifyContent="space-between">
                <Title type="h2" size="lg">
                  ClickHouse Academy{' '}
                </Title>
                <Link
                  href="https://learn.clickhouse.com/visitor_class_catalog"
                  target="_blank"
                  icon="arrow-right"
                >
                  View all
                </Link>
              </Container>
              <Spacer size="xs" />
              <CustomGridContainer column={3}>
                {academyLinks.map((academy, index) => (
                  <EventsCard key={`academy-${index}`} {...academy} />
                ))}
              </CustomGridContainer>
            </Container>
            <Separator size="xl" />
            {!isLoading && data && (
              <>
                <Container orientation="vertical" gap="xs">
                  <Container justifyContent="space-between">
                    <Title type="h2" size="lg">
                      Upcoming workshops{' '}
                    </Title>
                    <Link
                      href="https://clickhouse.com/company/news-events"
                      target="_blank"
                      icon="arrow-right"
                    >
                      View all
                    </Link>
                  </Container>
                  <Spacer size="xs" />
                  <CustomGridContainer column={3}>
                    {data.map((event, index) => (
                      <NoDecorationLink
                        key={`event-${index}`}
                        to={event.infoUrl ?? '#'}
                        target="_blank"
                      >
                        <CardPrimary {...event} />
                      </NoDecorationLink>
                    ))}
                  </CustomGridContainer>
                </Container>
                <Separator size="xl" />
              </>
            )}
            <Container orientation="vertical" gap="xs">
              <Title type="h2" size="lg">
                Featured documents
              </Title>
              <Spacer size="xs" />
              <CustomGridContainer column={2}>
                {featured.map(({ url, ...linkProps }) => (
                  <NoDecorationLink
                    key={linkProps.title}
                    to={url}
                    target="_blank"
                  >
                    <CardHorizontal {...linkProps} />
                  </NoDecorationLink>
                ))}
              </CustomGridContainer>
            </Container>
          </MaxWidthContainer>
        </SplitLayout>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
}

export default LearnPage;
