import { ReactElement, useCallback } from 'react';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';

import {
  DEFAULT_RELEASE_CHANNEL,
  FAST_RELEASE_CHANNEL,
  ReleaseChannel
} from '@cp/common/protocol/Instance';
import { useUpdateReleaseChannel } from 'src/instance/controller/useUpdateReleaseChannel';
import { ReleasesSectionView } from 'src/pages/SettingsPage/ReleaseSectionView';
import { createToast } from '@clickhouse/click-ui';

export const ReleasesSection = (): ReactElement => {
  const { organizationId, id, releaseChannel } = useCurrentInstanceOrThrow();
  const { updateReleaseChannel, isSaving } = useUpdateReleaseChannel();

  const updateChannel = FAST_RELEASE_CHANNEL as ReleaseChannel;

  const onActivateClick = useCallback(async () => {
    await updateReleaseChannel(
      organizationId,
      id,
      updateChannel,
      () => {
        createToast({
          title: 'Success',
          type: 'success',
          description: 'Successfully enrolled into fast channel releases'
        });
      },
      () => {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while updating release channel'
        });
      }
    );
  }, [updateReleaseChannel, organizationId, id, updateChannel]);

  const showEnrollButton =
    releaseChannel === undefined || releaseChannel === DEFAULT_RELEASE_CHANNEL;

  return (
    <ReleasesSectionView
      onActivateClick={onActivateClick}
      showEnrollButton={showEnrollButton}
      isSaving={isSaving}
    />
  );
};
