import { assertTruthy } from '@cp/common/utils/Assert';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { QueryEndpointsCorsForm } from 'src/components/QueryEndpoints/QueryEndpointsCorsForm';
import { QueryEndpointsKeysForm } from 'src/components/QueryEndpoints/QueryEndpointsKeysForm';
import { QueryEndpointsRolesForm } from 'src/components/QueryEndpoints/QueryEndpointsRolesForm';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import config from 'src/lib/config';
import { useUserFeature } from 'src/lib/features';
import { useDebounceFunction } from 'src/lib/hooks';
import {
  Subsection,
  SubsectionDescription,
  SubsectionTitle,
  SubsectionControls
} from 'src/lib/pageElements';
import {
  Spacer,
  Text,
  Container,
  Switch,
  createToast,
  CodeBlock,
  Title
} from '@clickhouse/click-ui';
import { useServiceQueryApiEndpoint } from 'src/queryEndpoints/queryEndpointsController/useServiceQueryApiEndpoint';
import { ServiceQueryAPIEndpoint } from '@prisma/client';
import { UpsertServiceQueryApiEndpointInput } from 'src/queryEndpoints/queryEndpointApiClient';

type PureQueryAPIEndpointsSectionProps = {
  hasServiceQueryApiEndpoint: boolean;
  serviceQueryApiEndpoint: ServiceQueryAPIEndpoint | null;
  isLoading: boolean;
  upsertServiceQueryApiEndpoint: (input: {
    openApiKeys: Array<string>;
    roles: Array<string>;
    allowedOrigins: string;
  }) => void;
  disableServiceQueryApiEndpoint: () => void;
};

function SampleRequest() {
  const currentServiceId = useCurrentInstanceId();

  return (
    <Container orientation="vertical" gap="sm">
      <Title type="h6" color="muted" size="xs">
        Example
      </Title>
      <Container orientation="horizontal" gap="sm">
        <CodeBlock language="bash" wrapLines>
          {`curl -X POST -s --user '<key_id>:<key_secret>' \\
  '${config.apiUrl}/services/${currentServiceId}/query' \\
  -H 'Content-Type: application/json' \\
  -d '{ "sql": "SELECT * FROM table" , "format": "JSONEachRow" }'`}
        </CodeBlock>
      </Container>
    </Container>
  );
}

export function PureQueryAPIEndpointsSection({
  hasServiceQueryApiEndpoint,
  serviceQueryApiEndpoint,
  upsertServiceQueryApiEndpoint,
  disableServiceQueryApiEndpoint
}: PureQueryAPIEndpointsSectionProps): ReactElement | null {
  const hasCorsEnabled = useUserFeature('FT_USER_QUERY_ENDPOINTS_CORS');
  const isInitialized = useRef(false);

  const [allowedOriginsModel, setAllowedOriginsModel] = useState<string>(
    serviceQueryApiEndpoint?.allowedOrigins ?? ''
  );

  useEffect(() => {
    if (serviceQueryApiEndpoint && !isInitialized.current) {
      isInitialized.current = true;
      setAllowedOriginsModel(serviceQueryApiEndpoint.allowedOrigins ?? '');
    }
  }, [serviceQueryApiEndpoint, setAllowedOriginsModel]);

  const addKey = (key: string): void => {
    assertTruthy(
      serviceQueryApiEndpoint,
      'Expected serviceQueryApiEndpoint to be defined'
    );
    upsertServiceQueryApiEndpoint({
      roles: serviceQueryApiEndpoint.roles,
      allowedOrigins: serviceQueryApiEndpoint.allowedOrigins ?? '',
      openApiKeys: serviceQueryApiEndpoint.openApiKeys.concat(key)
    });
  };

  const removeKey = (key: string): void => {
    assertTruthy(
      serviceQueryApiEndpoint,
      'Expected serviceQueryApiEndpoint to be defined'
    );
    upsertServiceQueryApiEndpoint({
      roles: serviceQueryApiEndpoint.roles,
      allowedOrigins: serviceQueryApiEndpoint.allowedOrigins ?? '',
      openApiKeys: serviceQueryApiEndpoint.openApiKeys.filter((k) => k !== key)
    });
  };

  const setRoles = (roles: Array<string>): void => {
    assertTruthy(
      serviceQueryApiEndpoint,
      'Expected serviceQueryApiEndpoint to be defined'
    );
    upsertServiceQueryApiEndpoint({
      roles,
      allowedOrigins: serviceQueryApiEndpoint.allowedOrigins ?? '',
      openApiKeys: serviceQueryApiEndpoint.openApiKeys
    });
  };

  const updateAllowedOrigins = useDebounceFunction(
    (allowedOrigins: string): void => {
      assertTruthy(
        serviceQueryApiEndpoint,
        'Expected serviceQueryApiEndpoint to be defined'
      );

      upsertServiceQueryApiEndpoint({
        roles: serviceQueryApiEndpoint.roles,
        allowedOrigins,
        openApiKeys: serviceQueryApiEndpoint.openApiKeys
      });
    },
    250
  );

  const setAllowedOrigins = (allowedOrigins: string): void => {
    setAllowedOriginsModel(allowedOrigins);
    updateAllowedOrigins(allowedOrigins);
  };

  return (
    <Subsection
      justifyContent="space-between"
      data-testid="query-endpoints-access-section"
    >
      <SubsectionDescription>
        <SubsectionTitle>Query API Endpoints</SubsectionTitle>
        <Spacer size="sm" />
        <Text color="muted">
          Enable a Generic Query API endpoint for your service.
        </Text>
      </SubsectionDescription>

      <SubsectionControls>
        <Spacer size="md" />
        <Container gap="md" orientation="vertical"></Container>
        <Switch
          checked={hasServiceQueryApiEndpoint}
          dir="end"
          label="Enable Service Query API Endpoint"
          orientation="horizontal"
          onCheckedChange={(value: boolean): void => {
            if (value) {
              upsertServiceQueryApiEndpoint({
                openApiKeys: [],
                roles: [],
                allowedOrigins: ''
              });
            } else {
              disableServiceQueryApiEndpoint();
            }
          }}
        />
        {serviceQueryApiEndpoint && (
          <Container orientation="vertical" gap="lg">
            <Spacer />
            <QueryEndpointsKeysForm
              endpointKeys={serviceQueryApiEndpoint.openApiKeys}
              onAddKey={addKey}
              onRemoveKey={removeKey}
            />
            <QueryEndpointsRolesForm
              roles={serviceQueryApiEndpoint.roles}
              setRoles={setRoles}
            />

            {hasCorsEnabled && (
              <QueryEndpointsCorsForm
                allowedOrigins={allowedOriginsModel}
                setAllowedOrigins={setAllowedOrigins}
              />
            )}
            <SampleRequest />
          </Container>
        )}
      </SubsectionControls>
    </Subsection>
  );
}

type QueryAPIEndpointsSectionProps = {
  serviceId: string;
};

export function QueryAPIEndpointsSection({
  serviceId
}: QueryAPIEndpointsSectionProps): ReactElement {
  const {
    hasServiceQueryApiEndpoint,
    serviceQueryApiEndpoint,
    isLoading,
    error,
    upsertServiceQueryApiEndpoint,
    disableServiceQueryApiEndpoint
  } = useServiceQueryApiEndpoint(serviceId);

  useEffect(() => {
    if (error) {
      createToast({
        type: 'danger',
        title: 'Error',
        description: error.message
      });
    }
  }, [error]);

  return (
    <PureQueryAPIEndpointsSection
      hasServiceQueryApiEndpoint={hasServiceQueryApiEndpoint}
      serviceQueryApiEndpoint={serviceQueryApiEndpoint}
      isLoading={isLoading}
      upsertServiceQueryApiEndpoint={(
        input: UpsertServiceQueryApiEndpointInput
      ) => {
        void upsertServiceQueryApiEndpoint(input);
      }}
      disableServiceQueryApiEndpoint={() => {
        void disableServiceQueryApiEndpoint();
      }}
    />
  );
}
