export const errorMessage = (error: any): string => {
  if (error instanceof Error) {
    return error.message;
  } else if (error?.error && typeof error.error === 'string') {
    return error.error;
  } else if (
    error?.error &&
    typeof error.error === 'object' &&
    error.error.message &&
    typeof error.error.message === 'string'
  ) {
    return error.error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return String(error);
  }
};
