import {
  isClickPipesKafkaImport,
  isClickPipesObjectStorageImport,
  isClickPipesKinesisImport
} from 'shared/src/dataLoading/utils';
import { isClickPipesPostgresImport } from 'shared/src/dataLoading/utils';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { SetupKafkaConnection } from 'src/components/ImportView/ClickPipesImportForm/SetupConnection/KafkaBasedConnection';
import { SetupObjectStorageConnection } from 'src/components/ImportView/ClickPipesImportForm/SetupConnection/ObjectStorageConnection';
import { SetupKinesisConnection } from 'src/components/ImportView/ClickPipesImportForm/SetupConnection/KinesisBasedConnection';
import { SetupPostgresConnection } from 'src/components/ImportView/ClickPipesImportForm/SetupConnection/PostgresBasedConnection';

export default function SetupConnection(): JSX.Element | null {
  const { importModel } = useClickPipesImport();

  if (isClickPipesKafkaImport(importModel)) {
    return <SetupKafkaConnection />;
  } else if (isClickPipesObjectStorageImport(importModel)) {
    return <SetupObjectStorageConnection />;
  } else if (isClickPipesKinesisImport(importModel)) {
    return <SetupKinesisConnection />;
  } else if (isClickPipesPostgresImport(importModel)) {
    return <SetupPostgresConnection />;
  } else {
    console.error('Unknown ClickPipe type');

    return null;
  }
}
