import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import Helmet from 'src/components/Helmet/Helmet';
import ImportView from 'src/components/ImportView';
import DataSourcesTopBar from 'src/components/ImportView/DataSourcesTopBar';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { routes } from 'src/lib/routes';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { getCurrentServiceIdOrFail } from 'src/state/service';

const ImportPage = (): ReactElement => {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );

  if (!hasManageServicePermission) {
    const serviceId = getCurrentServiceIdOrFail();
    navigateTo(routes.home({ serviceId }));
  }

  return (
    <>
      <MainPaneLayout>
        <MainPaneLayout.Topbar>
          <DataSourcesTopBar />
        </MainPaneLayout.Topbar>
        <MainPaneLayout.Content>
          <Helmet>
            <title>Data sources</title>
          </Helmet>
          <MaxWidthContainer>
            <ImportView />
          </MaxWidthContainer>
        </MainPaneLayout.Content>
      </MainPaneLayout>
    </>
  );
};

export default ImportPage;
