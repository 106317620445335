import { ReleaseChannel } from '@cp/common/protocol/Instance';
import { useCallback, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';

type ReturnType = {
  updateReleaseChannel: (
    organizationId: string,
    instanceId: string,
    releaseChannel: ReleaseChannel,
    onSuccess?: () => void,
    onError?: (error: any) => void
  ) => Promise<void>;
  isSaving: boolean;
};

export const useUpdateReleaseChannel = (): ReturnType => {
  const apiClient = useApiClient();
  const [isSaving, setIsSaving] = useState(false);

  const updateReleaseChannel = useCallback(
    async (
      organizationId: string,
      instanceId: string,
      releaseChannel: ReleaseChannel,
      onSuccess?: () => void,
      onError?: (e: Error) => void
    ): Promise<void> => {
      setIsSaving(true);
      try {
        await apiClient.instance.updateReleaseChannel(organizationId, instanceId, releaseChannel);
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e as Error);
      }
      setIsSaving(false);
    },
    [apiClient.instance]
  );

  return { updateReleaseChannel, isSaving };
};
