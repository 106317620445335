import { css, SerializedStyles, Theme } from '@emotion/react';

export const minWidthStyle = (width: number) =>
  css({
    minWidth: width
  });

export const saveBtnStyle = (theme: Theme): SerializedStyles =>
  css({
    transition: 'box-shadow 0.1s ease-in-out',
    "&[data-saved='false']:not(:disabled)": {
      boxShadow: `0 0 0 2px ${theme.colors.y2}`
    }
  });
