import {
  Button,
  Container,
  Link,
  Panel,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';

import downloadjs from 'downloadjs';
import { unparse } from 'papaparse';
import { OnPaginationStateChangeProps } from 'primitives/lib/Spreadsheet/usePaginationState';
import { ReactElement, useRef } from 'react';
import { TableDetails } from 'shared/src/clickhouse/types';

import { createToast } from 'src/components/primitives';
import {
  PaginationState,
  SelectionPos
} from 'src/components/primitives/lib/Spreadsheet/types';
import { RefreshDataArgs } from 'src/components/TableView';
import { ConsoleResultsTable } from 'src/components/TableView/DataView/ConsoleResultsTable';
import { useDataViewController } from 'src/components/TableView/DataView/dataViewController';
import {
  useCurrentInstanceOrThrow,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { QueryResult, ResultData } from 'src/lib/clickhouse/query';
import { formatDate } from 'src/lib/formatters/dateTimeFormatter';
import { useIsUnifiedConsole } from 'src/lib/features';
import { InteractionType, logger } from 'src/lib/logger';
import { routes } from 'src/lib/routes';
import { useUpdateTab } from 'src/state/tabs';
import { FilterConfig, ViewParameterValues } from 'src/state/tabs/types';
import styled from 'styled-components';

import { tableViewPageSize } from 'src/components/TableView/constants';
import {
  SetFilterConfigFunction,
  UpdateRightBarFn
} from 'src/components/TableView/types';
import {
  generateTableViewQuery,
  tableName
} from 'src/components/TableView/utils';

import RightBar from 'src/components/TableView/DataView/RightBar';
import Toolbar from 'src/components/TableView/DataView/Toolbar';

const RelativeContainer = styled(Container)`
  position: relative;
`;

interface DataViewProps {
  error: string | undefined;
  paginationState: PaginationState;
  filterConfig: FilterConfig;
  loading: boolean;
  setPaginationState: (partialState: OnPaginationStateChangeProps) => void;
  refreshData: (args?: RefreshDataArgs) => void;
  results: ResultData | undefined;
  runQuery: (
    sql: string,
    variables?: Record<string, string>
  ) => Promise<QueryResult>;
  selectedTable: TableDetails | null;
  setFilterConfig: SetFilterConfigFunction;
  tabId: string;
  updateRightBar: UpdateRightBarFn;
  unknownTotalPages?: boolean;
  selectedRow: number;
  selectedColumn: number;
  viewParameterValues?: ViewParameterValues;
  setViewParameterValues: (values: ViewParameterValues) => void;
  viewHasViewParams: boolean;
}
const Wrapper = styled(Container)`
  position: relative;
`;

interface WakeServicePanelProps {
  instanceId: string;
  refreshData: (args?: RefreshDataArgs) => void;
}

export function StoppedServicePanel({
  instanceId,
  refreshData
}: WakeServicePanelProps): ReactElement {
  const { isStarting } = useIsCurrentInstanceAwakeStatus();
  const role = useCurrentOrgUserRole();
  const { confirmInstanceStatusChange } = useDataViewController(
    instanceId,
    refreshData
  );

  return (
    <Container justifyContent="center" fillWidth>
      <Panel
        orientation="vertical"
        padding="none"
        alignItems="center"
        width="440px"
        height="130px"
      >
        <Title type="h1" size="xl" data-testid="service-stopped-panel-title">
          {isStarting ? 'Service is restarting...' : 'Service is stopped'}
        </Title>
        <Text align={'center'} data-testid="service-stopped-panel-description">
          {isStarting
            ? 'This may take a few moments to complete.'
            : `Restart your service to view table data and run queries. This may take
          a few moments to complete.`}
        </Text>
        <Spacer />
        <Button
          onClick={(): void => {
            confirmInstanceStatusChange('starting').catch(console.error);
          }}
          disabled={isStarting || role === 'DEVELOPER'}
          data-testid="restart-service-button"
          iconLeft={isStarting ? 'loading-animated' : undefined}
        >
          {isStarting ? 'Restarting service' : 'Restart service'}
        </Button>
      </Panel>
    </Container>
  );
}

export function WakeServicePanel({
  instanceId,
  refreshData
}: WakeServicePanelProps): ReactElement {
  const { isAwaking } = useIsCurrentInstanceAwakeStatus();

  const isUnifiedConsole = useIsUnifiedConsole();

  const { confirmInstanceStatusChange } = useDataViewController(
    instanceId,
    refreshData
  );

  return (
    <Container justifyContent="center" fillWidth>
      <Panel
        orientation="vertical"
        padding="none"
        alignItems="center"
        width="440px"
        height="130px"
      >
        <Title type="h1" size="xl" data-testid="service-asleep-panel-title">
          Service is idle
        </Title>
        <Text align={'center'} data-testid="service-asleep-panel-description">
          Wake your service to view table data and run queries. This may take a
          few moments to complete.{' '}
        </Text>
        <Spacer />
        <Text align={'center'}>
          You can adjust your idling period on the{' '}
          <Link
            data-testid="service-asleep-panel-link"
            href={
              isUnifiedConsole
                ? routes.serviceSettings({ serviceId: instanceId })
                : routes.controlPlaneSettings({ serviceId: instanceId })
            }
          >
            settings screen.
          </Link>
        </Text>
        <Spacer />
        <Button
          loading={isAwaking}
          onClick={() => {
            confirmInstanceStatusChange('awaking').catch(console.error);
          }}
          data-testid="service-asleep-panel-button"
        >
          Wake service
        </Button>
      </Panel>
    </Container>
  );
}

interface ResultsProps
  extends Omit<WakeServicePanelProps, 'instanceId'>,
    Pick<
      DataViewProps,
      | 'error'
      | 'loading'
      | 'results'
      | 'paginationState'
      | 'setPaginationState'
      | 'updateRightBar'
      | 'unknownTotalPages'
      | 'filterConfig'
      | 'tabId'
      | 'selectedRow'
      | 'selectedColumn'
      | 'viewHasViewParams'
    > {}

function Results({
  refreshData,
  error,
  loading,
  results,
  paginationState,
  setPaginationState,
  updateRightBar,
  unknownTotalPages,
  filterConfig,
  tabId,
  selectedRow,
  selectedColumn,
  viewHasViewParams
}: ResultsProps): ReactElement {
  const currentService = useCurrentInstanceOrThrow();
  const updateTab = useUpdateTab();
  const { isAsleep, isStopped, isStarting } = useIsCurrentInstanceAwakeStatus();
  if (isAsleep) {
    return (
      <>
        <Spacer size="xxl" />
        <WakeServicePanel
          instanceId={currentService.id}
          refreshData={refreshData}
        />
      </>
    );
  }
  if (isStopped || isStarting) {
    return (
      <>
        <Spacer size="xxl" />
        <StoppedServicePanel
          instanceId={currentService.id}
          refreshData={refreshData}
        />
      </>
    );
  }

  return (
    <RelativeContainer
      fillHeight
      wrap="nowrap"
      grow="1"
      overflow="hidden"
      className="fs-exclude"
      alignItems="start"
      data-testid="tableViewResults"
    >
      <ConsoleResultsTable
        loading={loading}
        error={error}
        results={results}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        updateRightBar={updateRightBar}
        unknownTotalPages={unknownTotalPages}
        filterApplied={filterConfig?.filters?.length > 0}
        onChangeFocus={(selectionPos: SelectionPos): void => {
          updateTab(tabId, {
            selectedRow: selectionPos.row,
            selectedColumn: selectionPos.column
          });
        }}
        selectedRow={selectedRow}
        selectedColumn={selectedColumn}
        hasViewParams={viewHasViewParams}
      />
    </RelativeContainer>
  );
}

export default function DataView({
  error,
  filterConfig,
  loading,
  paginationState,
  refreshData,
  results,
  runQuery,
  selectedTable,
  setFilterConfig,
  setPaginationState,
  tabId,
  updateRightBar,
  unknownTotalPages,
  selectedRow,
  selectedColumn,
  viewParameterValues,
  setViewParameterValues,
  viewHasViewParams
}: DataViewProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isAwake } = useIsCurrentInstanceAwakeStatus();

  if (!selectedTable) {
    return (
      <Container fillWidth justifyContent="center">
        Click on a table on the left to start exploring
      </Container>
    );
  }

  const logEvent = (
    component: string,
    event: string,
    interaction?: InteractionType
  ): void => {
    logger.track({
      view: 'tableView',
      component,
      event,
      interaction: interaction || 'click'
    });
  };
  const downloadTable = async (): Promise<void> => {
    const results = await runQuery(
      `SELECT * FROM ${tableName(selectedTable)} LIMIT 1000000`
    );

    if ('error' in results) {
      createToast('Error', 'alert', results.error);
      return;
    }

    const rows = results?.rows;
    const columns = results?.columns;

    if (rows && columns) {
      const rowData = rows.map((row) => {
        return row.reduce(
          (obj, value, i) => ({
            ...obj,
            [columns[i].name]: value
          }),
          {}
        );
      });

      downloadjs(
        unparse(rowData, { quotes: true }),
        `${selectedTable.tableName}_${formatDate(
          new Date()
        )}.csv`.toLowerCase(),
        'text/csv'
      );
    }
  };

  const getSelectQuery = (): string => {
    return generateTableViewQuery({
      filterConfig,
      pageNumber: paginationState.currentPage || 1,
      pageSize: tableViewPageSize,
      table: selectedTable,
      withDb: false
    });
  };

  const openInsertRow = (): void => {
    logEvent('toolBar', 'insertRowButtonClick');
    updateRightBar('insertRow');
  };
  const wrapperPadding = isAwake ? 'none' : 'lg';

  return (
    <Container wrap="nowrap" orientation="vertical" fillWidth>
      <Toolbar
        filterConfig={filterConfig}
        downloadTable={() => {
          downloadTable().catch(console.error);
        }}
        getSelectQuery={getSelectQuery}
        openInsertRow={openInsertRow}
        refreshData={refreshData}
        selectedTableName={selectedTable?.tableName}
        columns={selectedTable?.columns}
        logEvent={logEvent}
        tabId={tabId}
        setFilterConfig={setFilterConfig}
      />
      <Wrapper
        orientation="horizontal"
        padding={wrapperPadding}
        fillWidth
        fillHeight
        ref={containerRef}
        overflow="hidden"
      >
        <Results
          refreshData={refreshData}
          error={error}
          loading={loading}
          results={results}
          paginationState={paginationState}
          setPaginationState={setPaginationState}
          updateRightBar={updateRightBar}
          unknownTotalPages={unknownTotalPages}
          filterConfig={filterConfig}
          tabId={tabId}
          selectedRow={selectedRow}
          selectedColumn={selectedColumn}
          viewHasViewParams={viewHasViewParams}
        />
        <RightBar
          table={selectedTable}
          runQuery={runQuery}
          refreshData={refreshData}
          setViewParameterValues={setViewParameterValues}
          viewParameterValues={viewParameterValues}
          container={containerRef.current}
        />
      </Wrapper>
    </Container>
  );
}
