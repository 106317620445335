import { ReactElement } from 'react';
import {
  useChangeRole,
  useDeleteInvitation,
  useRemoveUser,
  useResendInvitation
} from 'src/organization/organizationController';
import Actions from 'src/pages/MembersPage/Actions';

interface ActionsLayoutProps {
  user: string;
  userRole: 'ADMIN' | 'DEVELOPER';
  isInvite: boolean;
  organizationId: string;
  inviteExpired?: boolean;
}

function ActionsLayout(props: ActionsLayoutProps): ReactElement {
  const { deleteInvitation } = useDeleteInvitation();
  const { removeUser } = useRemoveUser();
  const { changeRole } = useChangeRole();
  const { resendInvitation, loading: resendLoading } = useResendInvitation();
  const changeToRole = props.userRole === 'ADMIN' ? 'DEVELOPER' : 'ADMIN';

  return (
    <Actions
      {...props}
      changeRole={changeRole}
      removeUser={removeUser}
      deleteInvitation={deleteInvitation}
      resendInvitation={resendInvitation}
      resendLoading={resendLoading}
      changeToRole={changeToRole}
    />
  );
}

export default ActionsLayout;
