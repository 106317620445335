import { createToast } from 'primitives';

import { emptyAsyncIterable } from 'src/lib/iterator';
import { v4 as uuid } from 'uuid';

import { QueryRowCache } from 'src/lib/query/RowCache/QueryRowCache';
import { openRowDB, RowDB } from 'src/lib/query/RowCache/RowDB';
import { Range, ViewRangeParams } from 'src/lib/query/RowCache/RowIndexes';
import { Row } from 'src/lib/query/RowCache/types';

function onMemoryFallbackOverflow() {
  createToast('Error', 'alert', 'Not all results are shown because space in IndexedDB is not available');
}

function onQuotaExhausted() {
  //createToast('Error', 'alert', 'IndexedDB quota exhausted.')
}

export class RowStore {
  db: RowDB | null;
  queries: Map<string, QueryRowCache>;

  constructor(db: RowDB | null) {
    this.db = db;
    this.queries = new Map();
  }

  private getQueryRowCache(queryId: string) {
    if (!this.queries.has(queryId)) {
      return null;
    } else {
      return this.queries.get(queryId);
    }
  }

  createQuery(): string {
    const queryId = uuid();
    this.queries.set(
      queryId,
      new QueryRowCache({
        queryId,
        numRows: 0,
        db: this.db,
        onMemoryFallbackOverflow,
        onQuotaExhausted
      })
    );
    return queryId;
  }

  createQueryInPlace(queryId: string, numRows: number): void {
    if (this.queries.has(queryId)) {
      return;
    }
    const queryRowCache = new QueryRowCache({
      queryId,
      numRows,
      db: this.db,
      onMemoryFallbackOverflow,
      onQuotaExhausted
    });
    queryRowCache.finish();
    this.queries.set(queryId, queryRowCache);
  }

  async deleteQuery(queryId: string): Promise<void> {
    if (!this.db) {
      return;
    }

    const query = this.queries.get(queryId);
    if (query) {
      this.queries.delete(queryId);
      await this.db.deleteQueryPages(queryId);
    }
  }

  async addRows(queryId: string, rows: Row[]): Promise<boolean> {
    if (rows.length === 0) {
      return true;
    }

    const query = this.getQueryRowCache(queryId);
    if (!query) {
      return false;
    }

    return query.addRows(rows);
  }

  queryNumRows(queryId: string): number {
    const query = this.queries.get(queryId);
    return query?.numRows ?? 0;
  }

  subscribeToRange(queryId: string, startRow: number, endRow: number, viewParams?: ViewRangeParams): Range | null {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (queryRowCache) {
      return queryRowCache.addRetainedRange(startRow, endRow, viewParams);
    }
    return null;
  }

  updateSubscribedRange(
    queryId: string,
    range: Range,
    startRow: number,
    endRow: number,
    viewParams?: ViewRangeParams
  ): Range | null {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (queryRowCache) {
      return queryRowCache.updateRetainedRange(range, startRow, endRow, viewParams);
    }
    return null;
  }

  unsubscribeToRange(queryId: string, range: Range): void {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (queryRowCache) {
      return queryRowCache.removeRetainedRange(range);
    }
  }

  async removeUnneededQueryIds(referencedIds: string[]): Promise<void> {
    if (!this.db) {
      return;
    }
    const db = this.db;
    const storedQueryIds = await db.getQueryIds();
    const idsToDelete = new Set(storedQueryIds);
    for (const referencedId of referencedIds) {
      idsToDelete.delete(referencedId);
    }

    await Promise.all([...idsToDelete].map((id) => db.deleteQueryPages(id)));
  }

  getRow(queryId: string, index: number): Row | null {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (!queryRowCache) {
      throw new Error('invalid query!');
    } else {
      return queryRowCache.getRow(index);
    }
  }

  finishQuery(queryId: string): void {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (queryRowCache) {
      queryRowCache.finish();
    }
  }

  async eachRow(queryId: string, rowCallback: (row: Row, i: number) => void): Promise<void> {
    return this.getQueryRowCache(queryId)?.eachRow(rowCallback);
  }

  rowIterator(queryId: string, range: Range | null, start?: number, end?: number): AsyncIterable<Row> {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (queryRowCache) {
      return queryRowCache.range(start, end, range);
    } else {
      return emptyAsyncIterable();
    }
  }

  rowsByIndex(queryId: string, indexes: Iterable<number>, range: Range | null): AsyncIterable<Row> {
    const queryRowCache = this.getQueryRowCache(queryId);
    if (queryRowCache) {
      return queryRowCache.rowsByIndex(indexes, range);
    } else {
      return emptyAsyncIterable();
    }
  }

  close(): void {
    this.db && this.db.close();
  }
}

export async function openRowStore(): Promise<RowStore> {
  const onVersionIncompatible = () => {
    createToast('Error', 'alert', 'Newer version of this application is running; please reload');
  };
  try {
    const db = await openRowDB(onVersionIncompatible);
    const rowStore = new RowStore(db);
    db.onIndexedDBClosingError = () => {
      createToast('Error', 'alert', 'Could not get access to IndexedDB. Please refresh and try again.');
      rowStore.db = null;
    };
    return rowStore;
  } catch (e) {
    return new RowStore(null);
  }
}
