import { QueryRunnerFn, useRawSqlQueryFunction } from 'src/lib/clickhouse/query';
import { useDebouncePromise } from 'src/lib/hooks';

/**
 * @param config arbitrary json value, to be deep recursed through
 * @param outputs object containing the outputs of elements, to be used for hydrating the references
 * @returns an object that has all the internal config objects replaced with the values they resolve to.
 * For example, if the config looks like this:
 * ```
 * {
 *   value: {
 *     type: 'constant',
 *     value: 'string value'
 *   }
 * }
 * ```
 * it will resolve to this:
 * {
 *   value: 'string value'
 * }
 * ```
 *
 * hydrateConfig also handles references:
 * ```
 * hydrateConfig({
 *   value: {
 *     type: 'reference',
 *     objectId: 'object1',
 *     field: 'referencedField'
 *   }
 * }, {
 *   object1: {
 *     referencedField: 'string value'
 *   }
 * })
 *
 * {
 *   value: 'string value'
 * }
 * ```
 */
export function hydrateConfig(
  config: unknown,
  outputs: Record<string, Record<string, unknown>>,
  key?: string
): unknown {
  if (typeof config === 'object') {
    if (!config) {
      return config;
    } else if (Array.isArray(config)) {
      return config.map((obj) => hydrateConfig(obj, outputs, key));
    } else {
      if ('type' in config) {
        if (config.type === 'constant' && 'value' in config) {
          return config.value;
        } else if (config.type === 'reference' && 'objectId' in config && 'field' in config) {
          return outputs[config.objectId as string]?.[config.field as string];
        } else if (config.type === 'filter' && key) {
          return outputs[key]?.value || '';
        }
      }

      return Object.entries(config).reduce(
        (hydratedConfig, [key, value]) => ({
          ...hydratedConfig,
          [key]: hydrateConfig(value, outputs, key)
        }),
        {}
      );
    }
  }

  return config;
}

export function useDebouncedRunQuery(): QueryRunnerFn {
  const [runQuery] = useRawSqlQueryFunction();
  const debouncedRunQuery = useDebouncePromise(runQuery, 350);

  return debouncedRunQuery;
}
