import { ReactElement } from 'react';
import { Button, ButtonProps } from '@clickhouse/click-ui';
import { useDrift } from 'src/drift/useDrift';

function ChatWithUsButton(props: ButtonProps): ReactElement {
  const { openChat } = useDrift();
  return (
    <Button type="secondary" iconLeft="chat" onClick={openChat} {...props}>
      Chat with support
    </Button>
  );
}

export default ChatWithUsButton;
