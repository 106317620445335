import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { QueryEndpointDetail } from 'types/protocol/queryEndpoints';

const loadingAtom = atom(true);
const serviceApiEndpointsAtom = atom<QueryEndpointDetail[]>([]);
const numServiceApiEndpointsAtom = atom((get) => get(serviceApiEndpointsAtom).length);

export const useNumApiEndpoints = (): number => {
  return useAtomValue(numServiceApiEndpointsAtom);
};

export type SetApiEndpointsFn = (queryEndpoints: QueryEndpointDetail[]) => void;

export const useSetQueryApiEndpoints = (): SetApiEndpointsFn => {
  const setServiceApiEndpoints = useSetAtom(serviceApiEndpointsAtom);

  return useCallback(
    (queryEndpoints: QueryEndpointDetail[]) => {
      setServiceApiEndpoints(queryEndpoints);
    },
    [setServiceApiEndpoints]
  );
};

export const useQueryApiEndpoints = (): QueryEndpointDetail[] => {
  return useAtomValue(serviceApiEndpointsAtom);
};

export const useIsLoadingQueryApiEndpoints = (): boolean => {
  return useAtomValue(loadingAtom);
};

export type SetLoadingFn = (loading: boolean) => void;
export const useSetLoadingQueryApiEndpoints = (): SetLoadingFn => {
  return useSetAtom(loadingAtom);
};

export const useApiEndpoint = (endpointId: string): QueryEndpointDetail | undefined => {
  const queryEndpoints = useQueryApiEndpoints();
  return queryEndpoints.find((queryEndpoint) => queryEndpoint.id === endpointId);
};

export const useApiEndpointByQueryId = (queryId: string): QueryEndpointDetail | undefined => {
  const queryEndpoints = useQueryApiEndpoints();
  return queryEndpoints.find((queryEndpoint) => queryEndpoint.queryId === queryId);
};
