import { ReactElement, useState } from 'react';
import {
  Container,
  Link,
  Text,
  ConfirmationDialog
} from '@clickhouse/click-ui';
import { useOrgFeature } from 'src/lib/features';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useDataWarehousePassword } from 'src/dataWarehouses/controller/useDataWarehousePassword';

interface PasswordResetInfoProps {
  onPasswordReset?: () => Promise<void>;
  dataWarehouseId?: string;
}

/**
 * The component that displays a link to reset the password.
 */
const PasswordResetInfo = ({
  onPasswordReset,
  dataWarehouseId = ''
}: PasswordResetInfoProps): ReactElement => {
  const showHydra = useOrgFeature('FT_ORG_HYDRA_UI');
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const currentOrg = useCurrentOrganizationOrThrow();

  const { resetDataWarehousePassword, warehouse, loading } =
    useDataWarehousePassword({
      dataWarehouseId
    });

  const onConfirm = async (): Promise<void> => {
    if (showHydra) {
      await resetDataWarehousePassword();
    } else if (typeof onPasswordReset === 'function') {
      await onPasswordReset();
    }
  };

  return (
    <Container gap="xs" isResponsive={false}>
      <Text color="muted">
        {showHydra
          ? 'Cannot be displayed after initial warehouse creation.'
          : 'Cannot be displayed after initial creation'}
        {typeof onPasswordReset !== 'function' && showHydra && (
          <>
            {' '}
            Contact your{' '}
            <Link
              onClick={(): void => {
                navigateTo(routes.membersPage({ orgId: currentOrg?.id ?? '' }));
              }}
              data-testid="reset-password-link"
            >
              warehouse admin
            </Link>
            if you no longer have it.
          </>
        )}
      </Text>
      {typeof onPasswordReset === 'function' && (
        <Link
          onClick={(): void => {
            setConfirmationVisible(true);
          }}
          data-testid="reset-password-link"
        >
          Reset password
        </Link>
      )}
      <ConfirmationDialog
        title="Reset service user password"
        onCancel={(): void => {
          setConfirmationVisible(false);
        }}
        onConfirm={onConfirm}
        open={confirmationVisible}
        primaryActionLabel="Reset password"
        secondaryActionLabel="Cancel"
        loading={loading}
      >
        {showHydra ? (
          <>
            <Text component="span">
              All services within your warehouse share the same DB credentials.
              If you reset the password, you will also reset the password for
              the following services:
            </Text>
            <ul>
              {warehouse?.instances.map((instance) => (
                <Text component="li" key={instance.name}>
                  {instance.name}
                </Text>
              ))}
            </ul>
            <Text component="span">This action cannot be undone.</Text>
          </>
        ) : (
          "You're resetting the password for the default user. Make sure to update the settings of any clients that use these credentials."
        )}
      </ConfirmationDialog>
    </Container>
  );
};

export default PasswordResetInfo;
