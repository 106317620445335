import { ReactElement } from 'react';
import { Button, Container, Dialog, Text } from '@clickhouse/click-ui';
import { OpenapiKey } from '@cp/common/protocol/OpenapiKey';

interface DeleteKeyModalProps {
  deletingKey: OpenapiKey | null;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  open: boolean;
}

export default function DeleteKeyModal({
  deletingKey,
  onClose,
  onSubmit,
  open
}: DeleteKeyModalProps): ReactElement {
  const onOpenChange = (open: boolean): void => {
    if (!open) {
      onClose();
    }
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        onClose={onClose}
        showClose
        title={`Delete API key "${deletingKey?.name || ''}"`}
      >
        <Container gap="md" orientation="vertical">
          <Container>
            <Text color="muted">
              If you delete this API key, all systems that use this API key will
              lose access to ClickHouse Cloud and will be unable to perform
              their usual operations.
            </Text>
          </Container>
          <Container gap="xs" justifyContent="end" isResponsive={false}>
            <Button onClick={onClose} type="secondary">
              Cancel
            </Button>
            <Button
              onClick={(): void => void onSubmit().catch(console.error)}
              type="danger"
            >
              Delete key
            </Button>
          </Container>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}
