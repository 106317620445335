import { Container, createToast } from '@clickhouse/click-ui';
import { Instance } from '@cp/common/protocol/Instance';
import { filterBackupsInRetentionPeriod } from '@cp/common/utils/InstanceUtils';
import { ReactElement } from 'react';
import { useBackups } from 'src/backups/backupsController';
import Helmet from 'src/components/Helmet/Helmet';
import { useCurrentInstance } from 'src/instance/instanceController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import SplitLayout from 'src/layout/SplitLayout';
import { HeaderSection } from 'src/pages/BackupsPage/HeaderSection';
import { MainSection } from 'src/pages/BackupsPage/MainSection';

type Props = {
  instance: Instance;
};

export function Backups({ instance }: Props): ReactElement {
  const { backups, loading, error, restoreInstance } = useBackups();
  const lastSuccessfulBackup = backups.find(
    (e) => e.finished && e.status === 'done'
  );
  const lastBackupTime = lastSuccessfulBackup?.createdAt ?? 0;
  const filteredBackups = filterBackupsInRetentionPeriod(
    backups,
    instance.backupConfiguration.customBackupRetentionPeriod
  );

  if (error) {
    createToast({
      type: 'danger',
      title: 'Could not fetch backups',
      description: error
    });
  }
  return (
    <>
      <HeaderSection
        lastBackupTime={lastBackupTime}
        backupConfiguration={instance.backupConfiguration}
        loading={loading}
      />
      <Container overflow="auto">
        <MainSection
          backups={filteredBackups}
          loading={loading}
          restoreInstance={restoreInstance}
        />
      </Container>
    </>
  );
}

const BackupsPage = (): ReactElement | null => {
  const instance = useCurrentInstance();
  if (!instance) {
    return null;
  }

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Backups" />
      <MainPaneLayout.Content>
        <Helmet>
          <title>{instance.name} - Backups</title>
        </Helmet>
        <SplitLayout>
          <Container
            orientation="vertical"
            padding="lg"
            data-testid="backups-page"
            gap="xxl"
            overflow="hidden"
          >
            <Backups instance={instance} />
          </Container>
        </SplitLayout>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default BackupsPage;
