import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { BillingAlert, BillingAlertParams, BillingAlertType, getBillingAlert } from 'src/billing/billingAlert';

export type AlertType = 'BILLING' | 'SERVICE ';

export interface GlobalAlert {
  alertType: AlertType;
  subAlertType?: BillingAlertType; // TODO (#6532): Add ServiceAlertType if subtypes are needed
  alertText: string;
}

export interface GlobalAlertState {
  billingAlert?: BillingAlert;
  // serviceAlert?: ServiceAlert;  // TODO (#6532)
}

const alertStateAtom = atom<GlobalAlertState>({});

export interface AlertStateManager {
  alertState: GlobalAlertState;
  updateAlerts: (alert: GlobalAlert) => void;
  setBillingAlert: (billingAlertParams: BillingAlertParams) => void;
}

export function useAlertStateManager(): AlertStateManager {
  const [alertState, setAlertStateAtom] = useAtom(alertStateAtom);

  // Update global alertState with a BillingAlert
  const setBillingAlert = useCallback(
    (billingAlertParams: BillingAlertParams): void => {
      const billingAlert = getBillingAlert(billingAlertParams);
      if (billingAlert) {
        setAlertStateAtom((state) => setAlertsInState(state, billingAlert));
      } else {
        setAlertStateAtom({});
      }
    },
    [setAlertStateAtom]
  );

  // Generic function to update global alertState with a new GlobalAlert
  const updateAlerts = useCallback(
    (alert: GlobalAlert): void => {
      setAlertStateAtom((state) => setAlertsInState(state, alert));
    },
    [setAlertStateAtom]
  );

  return { alertState, setBillingAlert, updateAlerts };
}

export const setAlertsInState = (
  currentAlertState: GlobalAlertState,
  newAlert: GlobalAlert | null
): GlobalAlertState => {
  return produce(currentAlertState, (draftAlertState) => {
    if (!newAlert) return;

    if (newAlert.alertType === 'BILLING') {
      draftAlertState.billingAlert = newAlert as BillingAlert;
    }
  });
};
