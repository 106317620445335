import type * as monaco from 'monaco-editor';

import { Constraint } from 'src/lib/autocomplete/models/DatabaseInfo';

export type ITextModel = monaco.editor.ITextModel;

export enum Kind {
  Keyword = 'KEYWORD',
  Variable = 'VARIABLE',
  Function = 'FUNCTION'
}

export interface IAutoCompleteItem {
  prefix: string | null;
  keyword: string;
  type: Kind;
  sortPriority: number;
}

export type AutocompleteSuggestion = {
  label: string;
  kind: Kind;
  insertText: string;
  range?: monaco.IRange;
  filterText?: string;
  detail?: string;
};

export interface ICompletionProvider {
  calcSuggestions: (model: ITextModel, position: monaco.IPosition) => AutocompleteSuggestion[];
}

export type IAsyncCompletionProviderResponse = {
  suggestions: AutocompleteSuggestion[];
};
export interface IAsyncCompletionProvider {
  calcSuggestions: (model: ITextModel, position: monaco.IPosition) => Promise<IAsyncCompletionProviderResponse>;
}

export interface IAutocompleteProvider {
  calcSuggestions: (
    selectedResourceGroup: string | undefined,
    statement: string,
    offset: number
  ) => AutocompleteSuggestion[];
}

export interface SuggesterIntegration {
  id: string;
  resources: unknown[];
}
export interface Column {
  name: string;
  type: string;
  default?: string;
}

export interface Resource {
  id: string;
  name: string;
  properties: {
    schema?: string;
    columns?: Column[] | undefined;
    type?: string;
    constraints?: Record<string, Constraint[]>;
  };
}

export interface AutocompleteProviderOptions {
  id: string;
  type: string;
  resources?: Resource[] | null;
  functions?: Resource[] | null;
  schema?: string;
}
export interface AutocompleteWorkerMessage {
  type: string;
  options: AutocompleteProviderOptions;
  statement: string;
  offset: number;
}
