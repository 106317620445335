import { Draft } from 'immer';
import { VerticalStepper } from '@clickhouse/click-ui';

import {
  ClickPipesStep,
  isClickPipesImport,
  ClickPipeImport
} from 'shared/src/dataLoading';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import SetupConnection from 'src/components/ImportView/ClickPipesImportForm/SetupConnection';
import DetailsAndSettings from 'src/components/ImportView/ClickPipesImportForm/DetailsAndSettings';
import { SetupClickPipeType } from 'src/components/ImportView/ClickPipesImportForm/SetupClickPipeType';
import { ClickhouseConfigurationSteps } from 'src/components/ImportView/ClickPipesImportForm/ConfigurationSteps';

export default function ClickPipesImportForm(): JSX.Element | null {
  const { importModel, updateImportModel } = useClickPipesImport();

  if (!isClickPipesImport(importModel)) {
    return null;
  }

  const step = importModel.data.step ?? 1;
  const type = importModel.type;

  const gotoPage = (index: number) => () => {
    if (index >= 0 && index < 5) {
      updateImportModel((draft: Draft<ClickPipeImport>) => {
        draft.data.step = index as ClickPipesStep;
      });
    }
  };

  return (
    <VerticalStepper>
      <VerticalStepper.Step
        label="Select the data source"
        status={step === 0 ? 'active' : 'complete'}
        onClick={gotoPage(0)}
      >
        <SetupClickPipeType />
      </VerticalStepper.Step>
      <VerticalStepper.Step
        label="Setup your ClickPipe Connection"
        status={step === 1 ? 'active' : step > 1 ? 'complete' : 'incomplete'}
        onClick={gotoPage(1)}
      >
        <SetupConnection />
      </VerticalStepper.Step>

      <ClickhouseConfigurationSteps
        step={step}
        gotoPage={gotoPage}
        clickpipeType={type}
      />

      <VerticalStepper.Step
        label="Details and Settings"
        status={step === 4 ? 'active' : step > 4 ? 'complete' : 'incomplete'}
        onClick={gotoPage(4)}
      >
        <DetailsAndSettings />
      </VerticalStepper.Step>
    </VerticalStepper>
  );
}
