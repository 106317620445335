import { Button, SearchField, Container } from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useOrganizationActivities } from 'src/organizationActivities/organizationActivitiesController';
import OrgAudit from 'src/pages/OrgAuditPage/OrgAudit';
import useFilterOrgActivities from 'src/pages/OrgAuditPage/useFilterOrgActivities';

function OrgAuditPage(): ReactElement {
  const [search, setSearch] = useState('');
  const { activities, pagination, isLoading } = useOrganizationActivities();
  const { filteredRows, headers, exportCSV, onSort } = useFilterOrgActivities({
    search,
    data: activities
  });

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Audit">
        <Container gap="lg" justifyContent="end">
          <Container fillWidth maxWidth="200px">
            <SearchField
              value={search}
              onChange={setSearch}
              placeholder="Search activities"
            />
          </Container>
          <Button type="secondary" iconLeft="share-arrow" onClick={exportCSV}>
            Export To CSV
          </Button>
        </Container>
      </MainPaneLayout.Topbar>
      <MainPaneLayout.Content>
        <OrgAudit
          isLoading={isLoading}
          headers={headers}
          filteredRows={filteredRows}
          onSort={onSort}
          pagination={pagination}
          rowCount={pagination?.rowCount || activities?.length || 0}
        />
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
}

export default OrgAuditPage;
