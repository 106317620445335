import { createToast } from '@clickhouse/click-ui';
import { useCallback, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { Instance } from '@cp/common/protocol/Instance';
import { generateClickHouseDbPassword } from 'src/instance/instance';
import { DataWarehouseWithInstances } from 'src/dataWarehouses/dataWarehousesState';
import { useDataWarehousePrimaryInstance } from 'src/dataWarehouses/controller/useDataWarehousePrimaryService';

interface UseDataWarehousePasswordProps {
  loading: boolean;
  resetDataWarehousePassword: () => Promise<void>;
  warehouse?: DataWarehouseWithInstances;
  password?: string;
  mySqlPassword?: string;
  primaryInstance?: Instance;
}
export const useDataWarehousePassword = ({
  dataWarehouseId
}: {
  dataWarehouseId: string;
}): UseDataWarehousePasswordProps => {
  const apiClient = useApiClient();
  const [loading, setLoading] = useState(false);
  const {
    setInstancePassword,
    instanceState: { instancePasswords, mySqlPasswords }
  } = useInstanceStateManager();

  const primaryInstance = useDataWarehousePrimaryInstance(dataWarehouseId);
  const password = instancePasswords?.[primaryInstance?.id ?? ''];
  const mySqlPassword = mySqlPasswords?.[primaryInstance?.id ?? ''];

  const resetDataWarehousePassword = useCallback(async () => {
    try {
      setLoading(true);
      const dbPassword = generateClickHouseDbPassword();
      await apiClient.warehouse.resetPassword({
        dataWarehouseId,
        password: dbPassword,
        organizationId: primaryInstance?.organizationId ?? ''
      });
      setInstancePassword(primaryInstance?.id ?? '', dbPassword);
      createToast({
        title: 'Password reseted successfully',
        description: '',
        type: 'success'
      });
    } catch (e) {
      createToast({
        title: 'Failed to reset the password',
        description: '',
        type: 'danger'
      });
    } finally {
      setLoading(false);
    }
  }, [apiClient.warehouse, dataWarehouseId, primaryInstance?.id, primaryInstance?.organizationId, setInstancePassword]);

  return { resetDataWarehousePassword, loading, password, mySqlPassword, primaryInstance };
};
