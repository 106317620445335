import InfoRoundedIcon from 'src/components/icons/InfoRoundedIcon';
import SuccessRoundedIcon from 'src/components/icons/SuccessRoundedIcon';
import WarningRoundedIcon from 'src/components/icons/WarningRoundedIcon';
import CloseButton from 'src/components/primitives/lib/CloseButton';

import {
  container,
  contentStyle,
  headerContainer,
  headerText
} from 'src/components/primitives/lib/Toast/styles';

interface ErrorModalProps {
  children: any;
  type: string;
  title: string;
  onClose?: (e: any) => void;
}

const ErrorModal = ({ children, onClose, type, title }: ErrorModalProps) => {
  let Icon;
  switch (type) {
    case 'alert':
      Icon = WarningRoundedIcon;
      break;
    case 'success':
      Icon = SuccessRoundedIcon;
      break;
    default:
      Icon = InfoRoundedIcon;
      break;
  }

  return (
    <div css={container}>
      <div css={headerContainer}>
        <div css={headerText}>
          <Icon color="currentColor" width="16" height="16" />
          <span>{title}</span>
        </div>
        {typeof onClose === 'function' && (
          <CloseButton height={8} onClick={onClose} />
        )}
      </div>
      <div css={contentStyle}>
        <div
          tabIndex={-1}
          role="contentinfo"
          className="content-info"
          onMouseDown={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
