import { ReactElement } from 'react';
import { createTableStatement } from 'shared/src/tableSchema/generateSql';
import ResizableFlyout from 'src/components/RightBar/ResizableFlyout';

import { useEditTable } from 'src/components/CreateTableView/context';

export function Preview({
  container
}: {
  container: HTMLElement | null;
}): ReactElement {
  const { tableState, previewOpen, setPreviewOpen } = useEditTable();

  const onOpenChange = (open: boolean): void => {
    setPreviewOpen(open);
  };

  const statement = createTableStatement(tableState);
  return (
    <ResizableFlyout
      open={previewOpen}
      header="SQL Preview"
      onOpenChange={onOpenChange}
      container={container}
      size="wide"
    >
      <ResizableFlyout.Code
        className="view-sql-preview"
        data-testid="view-sql-preview"
        successHeader="Query copied"
        successMessage="Create table query copied successfully"
      >
        {statement}
      </ResizableFlyout.Code>
    </ResizableFlyout>
  );
}
