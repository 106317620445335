import { PropsWithChildren } from 'react';
import Auth0AuthProvider from 'src/components/auth/Auth0AuthProvider';
import { User } from 'src/lib/auth/types';

export type AuthenticationDefaultProps = {
  isAuthenticated?: boolean;
  currentUser?: User | null;
  loading?: boolean;
};

export default function AuthProvider({
  children,
  ...initProps
}: PropsWithChildren<AuthenticationDefaultProps>): JSX.Element {
  return Auth0AuthProvider({ children, ...initProps });
}
