import { datadogRum } from '@datadog/browser-rum';
import config from 'src/lib/config';
import { datadogLogs } from '@datadog/browser-logs';

if (config.datadog) {
  if (config.datadog.rum) {
    datadogRum.init(config.datadog.rum);

    if (config.datadog.rum.startSessionReplayRecording) {
      datadogRum.startSessionReplayRecording();
    }
  }

  if (config.datadog.logs) {
    console.log('starting datadog logs');
    datadogLogs.init(config.datadog.logs);
  }
}
