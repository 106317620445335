import {
  InstanceMetricRequest,
  InstanceMetricResponse,
  MetricArgs,
  MetricReport,
  SendMetricsFeedbackRequest
} from '@cp/common/protocol/Metrics';

import { ErrorResponseMixin } from '@cp/common/utils/ValidationUtils';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

type MetricsRequest = InstanceMetricRequest | SendMetricsFeedbackRequest;

export const queryMetricsUrl = `${config.controlPlane.apiHost}/api/metrics/queryMetrics`;
export const sendFeedbackUrl = `${config.controlPlane.apiHost}/api/metrics/sendMetricsFeedback`;

export class MetricsApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: MetricsRequest, url: string): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async getMetrics(
    instanceId: string,
    organizationId: string,
    query: MetricArgs
  ): Promise<Array<MetricReport | string>> {
    const request: InstanceMetricRequest = {
      organizationId,
      instanceId,
      batch: [query]
    };

    const response = await this.rpcRequest(request, queryMetricsUrl);
    if (response.ok) {
      const responseBody = (await response.json()) as unknown as InstanceMetricResponse;
      return responseBody.batch;
    } else {
      try {
        const error = (await response.json()) as Partial<ErrorResponseMixin>;
        throw new Error(`Failed to fetch metrics: ${error?.message ?? ''} ${error?.details ?? ''}`);
      } catch (e) {
        console.log('Failed to fetch metrics:', e);
        throw e;
      }
    }
  }

  async sendFeedback(text: string, recaptchaToken: string | null): Promise<void> {
    const request: SendMetricsFeedbackRequest = {
      text,
      recaptchaToken: recaptchaToken ?? ''
    };
    const response = await this.rpcRequest(request, sendFeedbackUrl);
    if (!response.ok) {
      throw new Error('Failed to send feedback');
    }
  }
}
