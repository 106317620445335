import { ReactElement } from 'react';

import CellInspectorModal from 'src/components/RightBar/CellInspectorModal';
import { useSelectedTab, useUpdateTab } from 'src/state/tabs';
import { QueryTab } from 'src/state/tabs/types';

function RightBar({
  container
}: {
  container: HTMLElement | null;
}): ReactElement | null {
  const selectedTab = useSelectedTab() as QueryTab;
  const updateTab = useUpdateTab();

  const options = selectedTab?.rightBarType ?? [];
  if (options.length === 0) {
    return null;
  }

  const lastOptionType = options[options.length - 1];

  const onOpenChange = (open: boolean): void => {
    if (!open) {
      options.pop();
      updateTab(selectedTab.id, {
        rightBarType: options
      });
    }
  };

  return (
    <CellInspectorModal
      open={lastOptionType === 'viewCell'}
      value={selectedTab.cellValue ?? ''}
      onOpenChange={onOpenChange}
      container={container}
    />
  );
}

export default RightBar;
