import { ReactElement } from 'react';
import SecurityPageView from 'src/pages/SecurityPage/SecurityPageView';
import { useMfaController } from 'src/user/userMfaController';

const SecurityPage = (): ReactElement | null => {
  const { isTotpEnabled, enableTotp, disableTotp, initiateEnrollment } =
    useMfaController();

  return (
    <SecurityPageView
      isMfaEnabled={isTotpEnabled}
      onEnable={enableTotp}
      onDisable={disableTotp}
      onSetup={initiateEnrollment}
    />
  );
};

export default SecurityPage;
