import {
  Button,
  Container,
  Dialog,
  Label,
  RadioGroup,
  Select,
  Separator,
  Text,
  TextField
} from '@clickhouse/click-ui';
import {
  OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS,
  OpenapiKey,
  OpenapiKeyRole
} from '@cp/common/protocol/OpenapiKey';
import { ReactElement, useEffect, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import {
  formatTimestamp,
  validateDateTimeFormat
} from 'src/lib/formatters/dateTimeFormatter';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import styled from 'styled-components';

const ErrorText = styled(Text)`
  color: ${({ theme }): string =>
    theme.global.color.feedback.danger.foreground};
`;

interface CreateKeyModalProps {
  editingKey: OpenapiKey | null;
  onClose: () => void;
  onSubmit: (options: {
    customExpiry: string;
    expiry: string;
    name: string;
    role: OpenapiKeyRole;
  }) => Promise<void>;
  open: boolean;
}

export default function CreateKeyModal({
  editingKey,
  onClose: onCloseProp,
  onSubmit,
  open
}: CreateKeyModalProps): ReactElement {
  const hasManageOrganizationPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  const [customExpiry, setCustomExpiry] = useState('');
  const [error, setError] = useState('');
  const [customExpiryError, setCustomExpiryError] = useState('');
  const [expiry, setExpiry] = useState('1 month');
  const [name, setName] = useState('');
  const [role, setRole] = useState<OpenapiKeyRole>('DEVELOPER');
  const [loading, setLoading] = useState(false);

  const revertData = (): void => {
    setName('');
    setRole('DEVELOPER');
    setExpiry('never');
    setCustomExpiry('');
  };

  const onClose = (): void => {
    setError('');
    setCustomExpiryError('');
    revertData();
    onCloseProp();
  };

  useEffect(() => {
    if (editingKey) {
      setName(editingKey.name);
      setRole(editingKey.roles[0]);
      setExpiry(
        editingKey.expirationDate === OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS
          ? 'never'
          : 'custom'
      );
      setCustomExpiry(formatTimestamp(editingKey.expirationDate));
    } else {
      revertData();
    }
  }, [editingKey]);

  const checkCustomError = (): boolean => {
    const isValid = validateDateTimeFormat(customExpiry);
    setCustomExpiryError(
      isValid ? '' : 'The value should be of type YYYY-MM-DD HH:mm'
    );
    return isValid;
  };

  const onConfirm = (): void => {
    if (expiry === 'custom') {
      const isValid = checkCustomError();
      if (!isValid) {
        return;
      }
    }
    setError('');
    setCustomExpiryError('');
    setLoading(true);
    void onSubmit({
      customExpiry,
      expiry,
      name,
      role
    })
      .then(() => {
        setName('');
        setLoading(false);
      })
      .catch((e): void => {
        if (e instanceof Error) {
          setError(e.message);
          setLoading(false);
        }
      });
  };

  return (
    <Dialog open={open}>
      <Dialog.Content
        onClose={onClose}
        showClose
        title={`${editingKey ? 'Edit' : 'Create'} API key`}
      >
        <Container gap="md" orientation="vertical" fillWidth={false}>
          <Text color="muted">
            Members have access to the ClickHouse Cloud console where they can
            view or manage services, members, billing, usage, and API keys.
          </Text>
          <TextField
            label="API key name"
            onChange={(value): void => setName(value)}
            value={name}
          />
          <Container
            gap="xs"
            orientation="vertical"
            fillWidth
            alignItems="start"
          >
            <Label>Organization Permissions</Label>
            <RadioGroup orientation="vertical" value={role}>
              <RadioGroup.Item
                label="Query Endpoints"
                onClick={(): void => setRole('QUERY_ENDPOINTS')}
                value="QUERY_ENDPOINTS"
              />
              <RadioGroup.Item
                label="Developer"
                onClick={(): void => setRole('DEVELOPER')}
                value="DEVELOPER"
              />
              {hasManageOrganizationPermission && (
                <RadioGroup.Item
                  label="Admin"
                  onClick={(): void => setRole('ADMIN')}
                  value="ADMIN"
                />
              )}
            </RadioGroup>
          </Container>

          <Container
            orientation="vertical"
            alignItems="start"
            gap="xs"
            fillWidth
          >
            <Label>Expires</Label>

            <Select onSelect={(value): void => setExpiry(value)} value={expiry}>
              <Select.Item value="never">Never</Select.Item>
              <Select.Item value="1 week">1 week</Select.Item>
              <Select.Item value="2 weeks">2 weeks</Select.Item>
              <Select.Item value="1 month">1 month</Select.Item>
              <Select.Item value="6 months">6 months</Select.Item>
              <Select.Item value="1 year">1 year</Select.Item>
              <Select.Item value="custom">Custom</Select.Item>
            </Select>
          </Container>
          {expiry === 'custom' && (
            <TextField
              onChange={(value): void => {
                setCustomExpiry(value);
                if (customExpiryError.length > 0) {
                  setCustomExpiryError('');
                }
              }}
              placeholder="YYYY-MM-DD HH:mm"
              error={customExpiryError}
              value={customExpiry}
              onBlur={() => checkCustomError()}
            />
          )}

          <Separator size="sm" />
          {error && <ErrorText>{error}</ErrorText>}
          <Container
            fillWidth
            gap="md"
            justifyContent="end"
            isResponsive={false}
          >
            <Button onClick={onClose} type="secondary">
              Cancel
            </Button>
            <Button
              disabled={!name || customExpiryError.length > 0}
              loading={loading}
              onClick={onConfirm}
            >
              {editingKey ? 'Update key' : 'Generate API key'}
            </Button>
          </Container>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}
