import { css, SerializedStyles, Theme } from '@emotion/react';

export const toolbarContainer = (theme: Theme): SerializedStyles =>
  css({
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '4px',
    paddingRight: '12px',
    borderBottom: `1px solid ${theme.global.color.stroke.default}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  });

export const gapContainer = (gap: number | string): SerializedStyles =>
  css({
    display: 'flex',
    gap,
    flexFlow: 'row wrap'
  });

export const nameContainer = css({
  width: 0,
  minWidth: 150,
  flex: '1 1 auto',
  position: 'relative'
});

export const nameStyle = (theme: Theme): SerializedStyles =>
  css({
    display: 'flex',
    maxWidth: '100%',
    width: 'fit-content',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    height: 34,
    fontWeight: 500,
    border: '1px solid',
    borderRadius: 4,
    borderColor: 'transparent',
    color: theme.global.color.text.default,
    cursor: 'text',
    pre: {
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      color: 'inherit',
      margin: 0,
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      overflow: 'hidden',
      whiteSpace: 'pre',
      cursor: 'text'
    }
  });

export const nameInputContainer = (isReadOnly: boolean): SerializedStyles =>
  css({
    position: 'absolute',
    content: "''",
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '&  div': isReadOnly
      ? {
          background: 'transparent',
          '&:not(:hover)': {
            borderColor: 'transparent'
          }
        }
      : undefined
  });

export const nameTextStyle = css({
  textOverflow: 'ellipsis',
  cursor: 'text'
});

export const nameContentStyling = (allowEditing: boolean): SerializedStyles =>
  css({
    opacity: allowEditing ? 0 : 1,
    width: 'calc(100% + 2ch)',
    padding: '0 calc(0.75rem + 1ch) 0 0.75rem',
    maxWidth: '100%'
  });
