import keyBy from 'lodash/keyBy';
import { ReactElement } from 'react';
import { Icon } from '@clickhouse/click-ui';

import { Field, FieldMapping } from 'types/protocol';
import { LineContainer } from 'src/components/ImportRightBar/LineContainer';

interface Props {
  sourceFields: ReadonlyArray<Field>;
  destinationFields: ReadonlyArray<Field>;
  fieldMapping: ReadonlyArray<FieldMapping>;
}

function FieldColumn({
  sourceFields,
  destinationFields,
  fieldMapping
}: Props): ReactElement {
  if (fieldMapping.length === 0) {
    return (
      <>
        {destinationFields.map((field, index) => (
          <LineContainer
            key={`field-${index}`}
            justifyContent="space-between"
            alignItems="start"
            gap="sm"
            fillWidth
            minWidth="fit-content"
          >
            <div>{field.name}</div>
            <div>{field.type}</div>
          </LineContainer>
        ))}
      </>
    );
  }

  const sourceFieldObject = keyBy(sourceFields, (field) => field.name);

  const destinationFieldObject = keyBy(
    destinationFields,
    (field) => field.name
  );

  return (
    <>
      {fieldMapping.map((field, index) => (
        <LineContainer
          key={`field-${index}`}
          justifyContent="space-between"
          alignItems="start"
          gap="sm"
          fillWidth
          minWidth="fit-content"
        >
          <div className="fs-exclude">
            {field.sourceField.length > 0
              ? `${field.sourceField} (${
                  sourceFieldObject[field.sourceField].type
                })`
              : '-'}
          </div>
          <Icon name="arrow-right" size="md" />
          <div className="fs-exclude">
            {field.destinationField.length > 0
              ? `${field.destinationField} (${
                  destinationFieldObject[field.destinationField].type
                })`
              : '-'}
          </div>
        </LineContainer>
      ))}
    </>
  );
}

export default FieldColumn;
