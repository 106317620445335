import { SearchField, SplitButton, Tooltip } from '@clickhouse/click-ui';

import { leftBarControlsStyle } from 'global-styles';
import React, { ReactElement, useMemo, useState } from 'react';

import { marginStyle } from 'src/components/global-styles';
import { sidebarLogEvent } from 'src/components/QueryView/analytics';
import { SavedQueryResult } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { getCurrentServiceId } from 'src/state/service';
import { useSelectedTab, useTabActions } from 'src/state/tabs';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';

import Folders from 'src/components/QueryView/QueryList/Folders';
import {
  useFolders,
  useSavedQueryFolders
} from 'src/components/QueryView/QueryList/Folders/hooks';
import { NodeType, NodeChangeType } from 'shared/src/types/queryFolders';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';

function onToggleOptionsButton(open: boolean): void {
  if (!open) {
    sidebarLogEvent('newQueryMenu', 'Blur', 'trigger');
  }
}

interface QueryListWithQueryIdProps {
  queryFilter: string;
  selectedTabQueryId: string | null;
}

const QueriesWithTabId = React.memo(function QueriesWithTabId({
  queryFilter,
  selectedTabQueryId
}: QueryListWithQueryIdProps) {
  const currentServiceId = getCurrentServiceId();
  const currentUser = useCurrentUserOrThrow();
  const { savedQueries } = useSavedQueries();
  const [, setOpen] = useState(false);
  const { addNewQueryTab, selectQuery } = useTabActions();

  const {
    folders,
    loading: foldersLoading,
    persistFolders
  } = useSavedQueryFolders();

  if (!currentServiceId) {
    throw new Error('Expected service id to be defined');
  }

  const [search, setSearch] = useState<string>('');
  const openDummyQuery = (): void => {
    addNewQueryTab({
      query: '',
      title: 'Untitled query',
      type: 'query',
      saved: false
    });
  };

  const filteredQueries: SavedQueryResult[] = useMemo(() => {
    if (!savedQueries) {
      return [];
    } else {
      return savedQueries.filter((query) => {
        if (queryFilter === 'myQueries') {
          if (query.authorId !== currentUser.id) {
            return false;
          }
        } else if (queryFilter === 'sharedQueries') {
          if (query.authorId === currentUser.id || query.isUnowned) {
            return false;
          }
        } else if (queryFilter === 'unownedQueries') {
          if (!query.isUnowned) {
            return false;
          }
        }

        const words = search.toLowerCase().split(/\s+/);
        const name = query.name.toLowerCase();
        return words.every((part) => name.includes(part));
      });
    }
  }, [currentUser.id, queryFilter, savedQueries, search]);

  const {
    createNewFolder,
    folderRef,
    onCreateOption,
    onDelete,
    onRename,
    queryNodes,
    scrollRef
  } = useFolders({
    folders,
    onChange: persistFolders,
    queries: filteredQueries
  });

  const options = [
    {
      label: 'New query',
      onClick: (): void => {
        openDummyQuery();
        sidebarLogEvent('newQueryMenu', 'QueryCreate');
      }
    },
    {
      label: 'New folder',
      onClick: async (): Promise<void> => {
        await createNewFolder(currentUser.id);
        sidebarLogEvent('newQueryMenu', 'FolderCreate');
      }
    }
  ];

  return (
    <>
      <div css={leftBarControlsStyle}>
        <Tooltip>
          <Tooltip.Trigger>
            <SplitButton
              menu={options}
              onClick={(): void => {
                sidebarLogEvent('newQuery', 'MainButtonClick');
                openDummyQuery();
              }}
              onOpenChange={(newOpen): void => {
                if (newOpen === true) {
                  sidebarLogEvent('newQuery', 'SecondaryButtonClick');
                }
                setOpen(newOpen);
                onToggleOptionsButton(newOpen);
              }}
              fillWidth
              icon="plus"
            >
              New query
            </SplitButton>
          </Tooltip.Trigger>
          <Tooltip.Content side="bottom">Create new query</Tooltip.Content>
        </Tooltip>
      </div>
      <div css={marginStyle('0 1rem')}>
        <SearchField
          onChange={(value: string): void => {
            sidebarLogEvent('searchQueries', 'Input');
            setSearch(value);
          }}
          placeholder="Search queries"
          value={search}
          onFocus={(): void =>
            sidebarLogEvent('searchQueries', 'Focus', 'trigger')
          }
          onBlur={(): void =>
            sidebarLogEvent('searchQueries', 'Blur', 'trigger')
          }
          className="fs-exclude"
        />
      </div>

      {!foldersLoading && (
        <Folders
          innerRef={folderRef}
          scrollRef={scrollRef}
          contentNodes={queryNodes}
          folders={folders}
          noDataMessage="No queries."
          onChange={(
            tree: NodeType[],
            path?: string,
            type?: NodeChangeType,
            value?: NodeType | string
          ): void => {
            void persistFolders(tree, path, type, value);
          }}
          onClick={(node: { id: string }): void => {
            const query = filteredQueries.find((query) => query.id === node.id);
            if (query) {
              selectQuery(query);
            }
          }}
          onCreateOption={onCreateOption}
          onCreateFolder={(containingFolderId?: string | null) => {
            return createNewFolder(currentUser.id, containingFolderId);
          }}
          onContentRename={onRename}
          onDelete={onDelete}
          search={search}
          type="Query"
          currentTabId={selectedTabQueryId}
        />
      )}
    </>
  );
});

interface QueriesProps {
  queryFilter: string;
}

export default function Queries({ queryFilter }: QueriesProps): ReactElement {
  const selectedTab = useSelectedTab();
  const selectedTabQueryId =
    selectedTab?.type === 'query' ? selectedTab?.queryId : null;
  return (
    <QueriesWithTabId
      queryFilter={queryFilter}
      selectedTabQueryId={selectedTabQueryId}
    />
  );
}
