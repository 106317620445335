import { standardQuoteIdentifier } from 'shared';
import { ColumnProps } from 'src/components/TableView/DataView/RightBar/TableInspector/types';
import { useSqlQuery } from 'src/lib/clickhouse/query';

export type UseColumnValuesDistribution = {
  loading: boolean;
  values: Array<{ min: number; max: number; count: number }>;
  error: string | undefined;
};
export function useColumnValuesDistribution({
  name,
  database,
  tableName
}: Omit<ColumnProps, 'dataUncompressedBytes'>): UseColumnValuesDistribution {
  const fullTableName = `${standardQuoteIdentifier(database)}.${standardQuoteIdentifier(tableName)}`;

  const sql = `
  with arrayJoin(histogram(10)(${standardQuoteIdentifier(name)})) as buckets
  select buckets.1 as min, buckets.2 as max, buckets.3 as size from ${fullTableName};
  `;

  const [, { loading, data, error }] = useSqlQuery(sql);

  const values =
    data?.rows && data.rows.length > 0 && data.rows[0].length > 1
      ? data.rows.map((row) => {
          const [min, max, count] = row;
          return {
            min: Number(min),
            max: Number(max),
            count: Number(count)
          };
        })
      : [];

  return {
    loading,
    values,
    error
  };
}
