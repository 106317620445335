import { ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import TabBar from 'src/components/App/MainView/TabBar';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { logger } from 'src/lib/logger';
import { useTabActions, useSelectedTabIndex, useTabs } from 'src/state/tabs';

function TopBar(): ReactElement | null {
  const { addNewQueryTab, setSelectedTabIndex } = useTabActions();
  const selectedTabIndex = useSelectedTabIndex();
  const tabs = useTabs();
  const { pathname } = useLocation();

  if (!pathname.includes('/imports')) {
    return (
      <MainPaneLayout.Topbar padding="none" showMenu={false}>
        <TabBar
          onNewTabClick={(): void => {
            addNewQueryTab({
              title: 'Untitled query',
              type: 'query',
              saved: false
            });
            logger.track({
              view: 'tabbar',
              component: 'queryTab',
              event: 'newTabOpen',
              interaction: 'click'
            });
          }}
          onTabClick={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
          tabs={tabs}
        />
      </MainPaneLayout.Topbar>
    );
  }
  return null;
}

export default TopBar;
