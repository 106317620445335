import { useCallback, useRef, useState } from 'react';
import useSupportAttachment, { SupportAttachmentReturnType } from 'src/support/controller/useSupportAttachment';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useRecaptcha } from 'src/components/RecaptchaProvider';
import { CreateSupportCaseResponse, SupportCasePriority } from '@cp/common/protocol/Support';
import { Instance } from '@cp/common/protocol/Instance';
import { createToast } from 'src/components/primitives';

interface ReplyResponseType extends Omit<SupportAttachmentReturnType, 'uploadFiles' | 'downloadAttachment'> {
  isLoading: boolean;
  createCase: (
    organizationId: string,
    priority: SupportCasePriority,
    subject: string,
    description: string,
    category: string,
    additionalEmails: Array<string>,
    instance: Instance
  ) => Promise<null | CreateSupportCaseResponse>;
  fileList: FileList | null;
  onAttachmentChange: (files: FileList) => Promise<void>;
}

function useCreateCaseEvent(): ReplyResponseType {
  const getRecaptchaToken = useRecaptcha();
  const [isLoading, setLoading] = useState(false);
  const storageIds = useRef<Array<string>>([]);
  const api = useApiClient();
  const [fileList, setFileList] = useState<FileList | null>(null);
  const { progress, status, uploadFiles } = useSupportAttachment();
  const createCase = useCallback(
    async (
      organizationId: string,
      priority: SupportCasePriority,
      subject: string,
      description: string,
      category: string,
      additionalEmails: Array<string>,
      instance?: Instance
    ): Promise<CreateSupportCaseResponse | null> => {
      setLoading(true);
      const recaptchaToken = await getRecaptchaToken('support_create_case');
      let response = null;
      if (!recaptchaToken) {
        createToast('Error', 'error', 'Invalid Captcha');
        return response;
      }

      try {
        response = await api.support.createCase(
          organizationId,
          priority,
          subject,
          description,
          category,
          additionalEmails,
          recaptchaToken,
          instance?.id,
          instance?.name,
          storageIds.current
        );
      } catch (e) {
        createToast('Case creation failed', 'error', 'Failed to create case');
        console.error(e);
      } finally {
        setLoading(false);
      }
      return response;
    },
    [api.support, getRecaptchaToken]
  );

  const onAttachmentChange = useCallback(
    async (files: FileList) => {
      setLoading(true);
      const fileItems = new DataTransfer();
      setFileList(files);
      const [uploadFileList, ids] = await uploadFiles(files);
      uploadFileList.forEach((file) => fileItems.items.add(file));
      storageIds.current = ids;
      setFileList(fileItems.files);

      setLoading(false);
    },
    [uploadFiles]
  );

  return {
    isLoading,
    createCase,
    fileList,
    progress,
    status,
    onAttachmentChange
  };
}
export default useCreateCaseEvent;
