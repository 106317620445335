import { css, SerializedStyles, Theme } from '@emotion/react';

export default {
  container: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  contentContainer: css`
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: row nowrap;
    position: relative;
    overflow: hidden;
  `,
  editor: css`
    width: 0;
    flex: 1 1 auto;
  `,
  hiddenEditor: css`
    display: none;
  `,
  editorContainer:
    (editorHeight: string) =>
    (theme: Theme): SerializedStyles => css`
      width: 100%;
      border-bottom: 1px solid ${theme.global.color.stroke.default};

      display: flex;
      flex-flow: row nowrap;
      flex-basis: ${editorHeight};
    `,
  mainContainer: css`
    flex: 1;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  `,
  resultContainer: css`
    flex: 1;
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  `
};
