import { Button, Icon, IconButton, Tooltip } from '@clickhouse/click-ui';
import { css } from '@emotion/react';

import { Toolbar as PrimitiveToolbar } from 'primitives';
import { ReactElement, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { SavedQueryPermissions } from 'shared/src/types/savedQuery';

import DatabaseSelector from 'src/components/DatabaseSelector';
import { logEvent } from 'src/components/QueryView/analytics';
import GptCompleteButton from 'src/components/QueryView/Toolbar/GptCompleteButton';
import QueryApiEndpointsFlyout from 'src/components/QueryView/Toolbar/QueryEndpointsInsightsFlyout';
import RunButtonMenu from 'src/components/QueryView/Toolbar/RunButton';
import SaveQueryModal from 'src/components/QueryView/Toolbar/SaveQueryModal';
import ShareQueryFlyout from 'src/components/QueryView/Toolbar/ShareQueryFlyout';
import { saveBtnStyle } from 'src/components/QueryView/Toolbar/style';
import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { useApiEndpointByQueryId } from 'src/queryEndpoints/stateManager';
import {
  DeleteSavedQueryUserAccessArgs,
  UpdateSavedQueryGlobalAccessArgs,
  UpdateSavedQueryUserAccessArgs
} from 'src/savedQuery/savedQueryAccessApiClient';
import { useSelectedTab, useUpdateTab } from 'src/state/tabs';

interface ToolbarProps {
  assignQuery: () => Promise<void>;
  authorId?: string;
  deleteQuery: () => Promise<void>;
  deleteSavedQueryUserAccess: (
    args: DeleteSavedQueryUserAccessArgs
  ) => Promise<void>;
  name: string;
  status: RunningQueryStatusName;
  isSaveDisabled: boolean;
  isSavedQuery: boolean;
  onNameChange: (name: string) => void;
  runQuery: (runAll?: boolean) => void;
  runCurrentStatement: () => void;
  cancelQuery: () => void;
  saveQuery: () => Promise<void>;
  disableRun: boolean;
  constructQuery: (value: string) => Promise<void>;
  cancelConstructionRequest: () => void;
  completionIsRunning: boolean;
  isCompletionButtonVisible: boolean;
  queryId: string;
  queryUnowned: boolean;
  serviceId: string;
  runId: string;
  queryPermissions?: SavedQueryPermissions[];
  updateSavedQueryGlobalAccess: (
    args: UpdateSavedQueryGlobalAccessArgs
  ) => Promise<void>;
  updateSavedQueryUserAccess: (
    args: UpdateSavedQueryUserAccessArgs
  ) => Promise<void>;
}

export default function Toolbar({
  assignQuery,
  authorId,
  deleteQuery,
  deleteSavedQueryUserAccess,
  name,
  onNameChange,
  runQuery,
  runCurrentStatement,
  cancelQuery,
  saveQuery,
  status,
  isSaveDisabled,
  disableRun,
  isSavedQuery,
  constructQuery,
  cancelConstructionRequest,
  completionIsRunning,
  isCompletionButtonVisible,
  queryId,
  runId,
  serviceId,
  queryPermissions,
  queryUnowned,
  updateSavedQueryGlobalAccess,
  updateSavedQueryUserAccess
}: ToolbarProps): ReactElement {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [onSaveHandler, setOnSaveHandler] = useState(() => () => {});
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const selectedTab = useSelectedTab();
  const updateTab = useUpdateTab();

  const queryApiEndpointsOpen =
    !!selectedTab &&
    selectedTab.type === 'query' &&
    !!selectedTab.apiInsightsOpen;

  const openQueryApiInsightsFlyout = (): void => {
    if (selectedTab) {
      updateTab(selectedTab.id, { apiInsightsOpen: true });
    }
  };

  const closeQueryEndpointFlyout = (): void => {
    if (selectedTab) {
      updateTab(selectedTab.id, { apiInsightsOpen: false });
    }
  };

  const hasEndpoint = !!useApiEndpointByQueryId(queryId);

  const onSave = async (): Promise<void> => {
    logEvent('toolBar', 'saveButtonClick', 'click');
    if (!isSavedQuery) {
      setSaveModalOpen(true);
    } else {
      await saveQuery();
      onSaveHandler();
      setOnSaveHandler(() => () => {});
    }
  };

  useHotkeys(
    'meta+s, ctrl+s',
    () => {
      logEvent('toolBar', 'shortcutSaveQuery', 'shortcut');
      if (!isSavedQuery) {
        setSaveModalOpen(true);
      } else {
        saveQuery().catch(console.error);
      }
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      enabled: (e, handler) => {
        if (!isSaveDisabled) {
          return (
            ((handler.meta || handler.ctrl) && e.key.toLowerCase() === 's') ??
            false
          );
        }
        return false;
      },
      preventDefault: true
    },
    [saveQuery, isSaveDisabled, isSavedQuery]
  );

  return (
    <PrimitiveToolbar
      name={name}
      onNameChange={onNameChange}
      allowEditing={!queryUnowned}
      css={css({ paddingLeft: '4px', paddingRight: '12px', fontWeight: 500 })}
      className="queryViewToolBar"
      log={(event, interaction): void =>
        logEvent('toolBar', `query${event}`, interaction)
      }
    >
      <DatabaseSelector
        disabled={queryUnowned}
        onSelect={(): void => {
          logEvent('toolBar', 'switcherSelect', 'click');
        }}
        onClick={(): void => logEvent('toolBar', 'switcherFocus', 'trigger')}
        onBlur={(): void => logEvent('toolBar', 'switcherBlur', 'trigger')}
        width={168}
        data-testid="database-selector"
      />
      <RunButtonMenu
        runQuery={runQuery}
        runCurrentStatement={runCurrentStatement}
        cancelQuery={cancelQuery}
        status={status}
        log={(event, interaction): void =>
          logEvent('toolBar', event, interaction)
        }
        disabled={disableRun}
      />
      {isCompletionButtonVisible && (
        <GptCompleteButton
          constructQuery={constructQuery}
          cancelConstructionRequest={cancelConstructionRequest}
          isRunning={completionIsRunning}
          log={(event, interaction): void =>
            logEvent('toolBar', event, interaction)
          }
          runId={runId}
        />
      )}
      {queryUnowned ? (
        <>
          <Button
            onClick={() => {
              assignQuery().catch(console.error);
            }}
            type="secondary"
          >
            Assign to me
          </Button>
          <Button
            onClick={() => {
              deleteQuery().catch(console.error);
            }}
            type="secondary"
          >
            Delete
          </Button>
        </>
      ) : (
        <>
          <Tooltip
            open={!isSaveDisabled && tooltipOpen}
            onOpenChange={setTooltipOpen}
          >
            <Tooltip.Trigger>
              <Button
                data-testid="queryViewSaveQueryBtn"
                type="secondary"
                onClick={onSave}
                disabled={isSaveDisabled}
                data-saved={isSavedQuery}
                css={saveBtnStyle}
                iconLeft="disk"
              >
                Save
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>Save query</Tooltip.Content>
          </Tooltip>
          <Button
            data-testid="queryViewShareQueryBtn"
            onClick={() => {
              if (!isSavedQuery) {
                setOnSaveHandler(() => () => setShareModalOpen(true));
                setSaveModalOpen(true);
              } else {
                setShareModalOpen(true);
              }
            }}
            type="secondary"
          >
            <Icon name="share" /> Share
          </Button>

          {hasEndpoint && (
            <Tooltip>
              <Tooltip.Trigger>
                <IconButton
                  icon="http-monitoring"
                  type="primary"
                  onClick={openQueryApiInsightsFlyout}
                />
              </Tooltip.Trigger>
              <Tooltip.Content>API endpoint insights</Tooltip.Content>
            </Tooltip>
          )}

          {saveModalOpen && (
            <SaveQueryModal
              onCancel={() => {
                setSaveModalOpen(false);
              }}
              onSave={async (name) => {
                setSaveModalOpen(false);
                onNameChange(name);
                onSaveHandler();
              }}
              open={saveModalOpen}
              queryName={name}
            />
          )}
          {shareModalOpen && (
            <ShareQueryFlyout
              queryId={queryId}
              authorId={authorId}
              deleteUserAccess={(userId: string) => {
                deleteSavedQueryUserAccess({
                  queryId,
                  serviceId,
                  userId
                }).catch(console.error);
              }}
              onClose={() => setShareModalOpen(false)}
              updateGlobalAccess={(permissions: string) => {
                updateSavedQueryGlobalAccess({
                  permissions,
                  serviceId,
                  queryId
                }).catch(console.error);
              }}
              updateUserAccess={(userId: string, permissions: string) => {
                updateSavedQueryUserAccess({
                  permissions,
                  serviceId,
                  queryId,
                  userId
                }).catch(console.error);
              }}
              open={shareModalOpen}
              queryPermissions={queryPermissions}
            />
          )}
          {queryApiEndpointsOpen && (
            <QueryApiEndpointsFlyout onClose={closeQueryEndpointFlyout} />
          )}
        </>
      )}
    </PrimitiveToolbar>
  );
}
