import { GetClientSecretResponse } from '@cp/common/protocol/Billing';
import { createToast } from 'primitives';
import { useCallback, useEffect, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

export interface ClientSecret {
  clientSecret: GetClientSecretResponse;
  isLoading: boolean;
  getClientSecret: () => Promise<GetClientSecretResponse>;
}

export const useClientSecret = (): ClientSecret => {
  const apiClient = useApiClient();
  const organizationId = useCurrentOrganizationOrThrow().id;
  const [clientSecret, setClientSecret] = useState({ clientSecret: '', setupIntentId: '' });
  const [isLoading, setIsLoading] = useState(false);

  const getClientSecret = useCallback(async (): Promise<GetClientSecretResponse> => {
    try {
      setIsLoading(true);
      const clientSecret = await apiClient.billing.getClientSecret(organizationId);
      setClientSecret(clientSecret);
      return clientSecret;
    } catch (e) {
      createToast('Unable to retrieve credit card information', 'alert', errorMessage(e));
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, [apiClient.billing, organizationId, setClientSecret]);

  useEffect(() => {
    void getClientSecret().catch(console.error);
  }, [organizationId]);

  return {
    clientSecret,
    isLoading,
    getClientSecret
  };
};
