import { FilterQuery } from 'src/lib/query/RowCache/FilterQuery';
import { ROWS_PER_PAGE, rowToPage, rowToPageAndOffset } from 'src/lib/query/RowCache/pagination';
import { RangeIterable, RowCursor, RowIndexIterable } from 'src/lib/query/RowCache/RowCursor';
import type { RowDB } from 'src/lib/query/RowCache/RowDB';
import { type Range, RangeList, type ViewRangeParams } from 'src/lib/query/RowCache/RowIndexes';
import type { Page, Row } from 'src/lib/query/RowCache/types';

interface QueryRowCacheConstructor {
  queryId: string;
  numRows: number;
  db: RowDB | null;
  onMemoryFallbackOverflow?: () => void;
  onQuotaExhausted?: () => void;
}

export const MAX_FALLBACK_MEMORY_ROWS = 50000;

export class QueryRowCache {
  queryId: string;
  numRows: number;
  retainedRanges: RangeList;
  loadingPageIndexes = new Set<number>();
  inMemoryPages = new Map<number, Page>();
  finished: boolean;
  filterQueries: Map<Range, FilterQuery>;
  db: RowDB | null;
  partialPageIndex = 0;
  partialPage: Page = [];
  onMemoryFallbackOverflow?: () => void;
  onQuotaExhausted?: () => void;

  constructor({ queryId, numRows, db, onMemoryFallbackOverflow, onQuotaExhausted }: QueryRowCacheConstructor) {
    this.numRows = numRows;
    this.retainedRanges = new RangeList();
    this.filterQueries = new Map();
    this.finished = numRows !== 0; // don't support adding more rows if created w/ rows already in
    this.db = db;
    this.queryId = queryId;
    this.inMemoryPages.set(this.partialPageIndex, this.partialPage);
    this.onMemoryFallbackOverflow = onMemoryFallbackOverflow;
    this.onQuotaExhausted = onQuotaExhausted;
  }

  addRetainedRange(start: number, end: number, viewParams?: ViewRangeParams): Range {
    const range = this.retainedRanges.add(start, end, viewParams);
    this.checkMissing()
      .then(() => {
        if (viewParams?.filter) {
          const filterQuery = new FilterQuery(range, this);
          this.filterQueries.set(range, filterQuery);
          filterQuery
            .run()
            .then(() => {
              this.rangeChange(range);
            })
            .catch(console.error);
        } else {
          this.rangeChange(range);
        }
      })
      .catch(console.error);
    return range;
  }

  removeRetainedRange(range: Range): void {
    this.retainedRanges.remove(range);
    const query = this.filterQueries.get(range);
    if (query) {
      query.cancel();
      this.filterQueries.delete(range);
    }
    this.evictUnneededRows();
    this.checkMissing().catch(console.error);
  }

  updateRetainedRange(range: Range, start: number, end: number, viewParams?: ViewRangeParams): Range {
    const originalFilter = range.viewRangeData?.filter;
    const newRange = this.retainedRanges.update(range, start, end, viewParams);
    const oldQuery = this.filterQueries.get(range);
    if (viewParams?.filter !== originalFilter) {
      // filter has changed, cancel old query
      if (oldQuery) {
        oldQuery.cancel();
        this.filterQueries.delete(range);
      }
      // create filter query if necessary
      if (viewParams?.filter) {
        const filterQuery = new FilterQuery(newRange, this);
        this.filterQueries.set(newRange, filterQuery);
        filterQuery.run().catch(console.error);
      }
    } else if (oldQuery) {
      // update query for new version of range
      this.filterQueries.delete(range);
      this.filterQueries.set(newRange, oldQuery);
      oldQuery.range = newRange;
    }
    this.rangeChange(newRange);
    this.evictUnneededRows();
    this.checkMissing().catch(console.error);
    return newRange;
  }

  private async flushPage() {
    const pageIndex = this.partialPageIndex;
    const page = this.partialPage;
    ++this.partialPageIndex;
    this.partialPage = [];
    this.inMemoryPages.set(pageIndex, page);
    if (this.db) {
      const success = await this.db.writePage(this.queryId, pageIndex, page);
      if (!success) {
        this.db = null;
        this.numRows = Math.min(MAX_FALLBACK_MEMORY_ROWS, this.numRows);
        this.changeAllRanges();
      }
      return success;
    } else {
      return this.numRows < MAX_FALLBACK_MEMORY_ROWS;
    }
  }

  private changeAllRanges() {
    for (const range of this.retainedRanges.ranges) {
      this.rangeChange(range);
    }
  }

  private incrementNumRows(nAdded: number) {
    this.numRows += nAdded;
    if (!this.db) {
      this.numRows = Math.min(MAX_FALLBACK_MEMORY_ROWS, this.numRows);
    }
  }

  async addRows(rows: Row[]): Promise<boolean> {
    if (this.finished) {
      throw new Error("Can't add rows to finished query");
    }

    const writes: Promise<boolean>[] = [];

    for (const row of rows) {
      this.partialPage.push(row);
      if (this.partialPage.length > ROWS_PER_PAGE) {
        throw new Error('page too large!');
      } else if (this.partialPage.length === ROWS_PER_PAGE) {
        writes.push(this.flushPage());
      }
    }

    this.incrementNumRows(rows.length);

    let success: boolean;
    try {
      success = (await Promise.all(writes)).every((result) => result);
    } catch (e) {
      console.error('Error writing rows');
      return false;
    }

    // update all ranges, not just ones looking at these rows because we need
    // to inform all watchers of new numRows
    this.changeAllRanges();
    this.evictUnneededRows();

    if (!success) {
      if (this.numRows >= MAX_FALLBACK_MEMORY_ROWS && !this.db) {
        this.onMemoryFallbackOverflow?.();
        this.finish();
        return false;
      } else {
        this.onQuotaExhausted?.();
        return true;
      }
    } else {
      return true;
    }
  }

  retainedPageIndexes(): Set<number> {
    const pageIndexes = new Set<number>();

    if (this.db) {
      for (const range of this.retainedRanges.ranges) {
        const startPageIndex = rowToPage(Math.max(0, range.start));
        const endPageIndex = Math.min(this.partialPageIndex, rowToPage(range.end));
        for (let pageIndex = startPageIndex; pageIndex <= endPageIndex; ++pageIndex) {
          pageIndexes.add(pageIndex);
        }
      }
      return pageIndexes;
    } else {
      // Hit db quota, or other such problem. Hold first
      // MAX_FALLBACK_MEMORY_ROWS rows in memory
      const maxRowIndex = Math.min(MAX_FALLBACK_MEMORY_ROWS, this.numRows) - 1;
      const maxPageIndex = rowToPage(maxRowIndex);
      for (let pageIndex = 0; pageIndex <= maxPageIndex; ++pageIndex) {
        pageIndexes.add(pageIndex);
      }
      return pageIndexes;
    }
  }

  evictUnneededRows(): void {
    const retainedPages = this.retainedPageIndexes();
    for (const pageIndex of this.inMemoryPages.keys()) {
      if (!retainedPages.has(pageIndex)) {
        this.inMemoryPages.delete(pageIndex);
      }
    }
  }

  rangeChange(range: Range): void {
    let numRows = this.numRows;
    const filterQuery = this.filterQueries.get(range);
    const isFiltering = range.viewRangeData !== undefined && range.viewRangeData.filter !== '';
    const filterFinished = isFiltering && (filterQuery?.finished ?? false);
    if (range.viewRangeData) {
      const data = new Map<number, Row>();
      if (range.viewRangeData.filter !== '') {
        const filteredRows = range.viewRangeData.filteredRows || [];
        numRows = filteredRows.length;
        for (let index = range.start; index <= range.end; ++index) {
          if (index < filteredRows.length) {
            data.set(index, filteredRows[index]);
          }
        }
      } else {
        for (let index = range.start; index <= range.end; ++index) {
          const row = this.getRow(index);
          if (row) {
            data.set(index, row);
          }
        }
      }
      range.viewRangeData.onChange({ rows: data, numRows, filterFinished });
    }
  }

  missingPages(): Set<number> {
    // set of rows that are retained, not loading or already loaded
    const retainedPages = this.retainedPageIndexes();
    for (const pageIndex of this.inMemoryPages.keys()) {
      retainedPages.delete(pageIndex);
    }
    for (const pageIndex of this.loadingPageIndexes) {
      retainedPages.delete(pageIndex);
    }
    retainedPages.delete(this.partialPageIndex);
    return retainedPages;
  }

  async checkMissing(): Promise<void> {
    const missing = [...this.missingPages()];
    await Promise.all(missing.map((pageIndex) => this.loadPage(pageIndex)));
  }

  async loadPage(pageIndex: number) {
    if (this.db && !this.loadingPageIndexes.has(pageIndex)) {
      this.loadingPageIndexes.add(pageIndex);
      try {
        const page = await this.db.readPage(this.queryId, pageIndex);
        if (!page) {
          throw new Error(`Can't load row page: ${pageIndex}`);
        }
        this.inMemoryPages.set(pageIndex, page);
      } finally {
        this.loadingPageIndexes.delete(pageIndex);
      }

      // notify listeners
      const begin = pageIndex * ROWS_PER_PAGE;
      const end = (pageIndex + 1) * ROWS_PER_PAGE - 1;
      for (const range of this.retainedRanges.ranges) {
        if (!(range.end < begin || range.start > end)) {
          this.rangeChange(range);
        }
      }
    }
  }

  getRow(index: number): Row | null {
    const { page, offset } = rowToPageAndOffset(index);

    const pageRows = this.inMemoryPages.get(page);

    if (pageRows && offset in pageRows) {
      return pageRows[offset];
    } else {
      return null;
    }
  }

  finish(): void {
    this.finished = true;
    if (this.partialPage.length > 0) {
      this.flushPage().catch((e) => console.error('Failed to write page', e));
    }
  }

  async waitForRange(start: number, end: number): Promise<Row[]> {
    let range: Range | null = null;
    const rows = await new Promise<Row[]>((resolve) => {
      range = this.addRetainedRange(start, end, {
        filter: '',
        onChange: ({ rows }): void => {
          // if rows are complete, resolve the promise & unretain range
          const result = new Array<Row>(end - start + 1);
          for (let i = start; i < end; ++i) {
            const row = rows.get(i);
            if (!row) {
              return;
            } else {
              result[i - start] = row;
            }
          }
          resolve(result);
        }
      });
    });
    if (range) {
      this.removeRetainedRange(range);
    }
    return rows;
  }

  range(start?: number, end?: number, range?: Range | null): AsyncIterable<Row> {
    if (range && range.viewRangeData?.filter) {
      const filteredRows = range.viewRangeData?.filteredRows?.slice(start ?? 0, end);
      const rangeIterable = {
        [Symbol.asyncIterator](): AsyncIterator<Row> {
          let i = start ?? 0;

          return {
            next: async (): Promise<IteratorResult<Row>> => {
              if (end !== undefined && i >= end) {
                return { value: undefined, done: true };
              }

              const row = filteredRows?.[i++];

              if (!row) {
                return { value: undefined, done: true };
              } else {
                return { value: row, done: false };
              }
            }
          };
        }
      };
      return rangeIterable;
    }
    const cursor = new RowCursor(this);
    return new RangeIterable(cursor, start ?? 0, end);
  }

  rowsByIndex(indexes: Iterable<number>, range?: Range | null): AsyncIterable<Row> {
    if (range && range.viewRangeData?.filter) {
      const currentIndexes = indexes[Symbol.iterator]();
      const rowByIndexIterable = {
        [Symbol.asyncIterator](): AsyncIterator<Row> {
          return {
            next: async (): Promise<IteratorResult<Row>> => {
              const { done, value: rowIndex } = currentIndexes.next();

              if (done) {
                return { value: undefined, done: true };
              }

              const row = range?.viewRangeData?.filteredRows?.[rowIndex];

              if (!row) {
                return { value: undefined, done: true };
              } else {
                return { value: row, done: false };
              }
            }
          };
        }
      };

      return rowByIndexIterable;
    }

    const cursor = new RowCursor(this);
    return new RowIndexIterable(cursor, indexes);
  }

  async eachRow(rowCallback: (row: Row, i: number) => void): Promise<void> {
    let i = 0;
    for await (const row of this.range()) {
      rowCallback(row, i++);
    }
  }

  // Used to test cache behavior
  numRowsInMemory(): number {
    let rowCount = 0;
    for (const page of this.inMemoryPages.values()) {
      rowCount += page.length;
    }
    return rowCount;
  }
}
