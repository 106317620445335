import { css, SerializedStyles, Theme } from '@emotion/react';

export default {
  sidebarContainer: css({
    display: 'flex',
    flexFlow: 'row nowrap'
  }),
  container: (theme: Theme) =>
    css({
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      borderRight: '1px solid',
      borderRightColor: theme.global.color.stroke.default
    }),
  sidebar: (theme: Theme) =>
    css({
      width: 64,
      paddingTop: 16,
      paddingBottom: 12,
      position: 'relative',
      backgroundColor: theme.global.color.background.sidebar,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      '.Tooltip': {
        filter: 'drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))',
        '&::after': {
          content: "''",
          position: 'absolute',
          top: '50%',
          right: '100%',
          marginTop: -5,
          borderWidth: 5,
          borderLeftWidth: 0,
          borderStyle: 'solid',
          borderColor: `transparent ${theme.colors.sidebar_tooltip} transparent transparent`
        }
      }
    }),
  sidebarLogoContainer: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
    cursor: 'pointer'
  }),
  sidebarLink: (theme: Theme) =>
    css({
      display: 'grid',
      placeItems: 'center',
      height: 48,
      color: theme.global.color.text.default,
      cursor: 'pointer',
      position: 'relative',
      marginBottom: 8,
      '&.connection': {
        marginBottom: 0
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        height: 0,
        width: 5,
        backgroundColor: theme.global.color.accent.default,
        transition: 'height 0.5s'
      },
      "&:hover, &[data-selected='true']": {
        backgroundColor: theme.global.color.background.sidebar
      },
      "&[data-selected='true']::after": {
        height: '100%'
      },
      '&:hover': {
        ".sidebar-icon-container:not([data-selected='true'])": {
          transition: 'none',
          background: theme.colors.sidebar_bg
        }
      }
    }),
  sidebarIcon: (theme: Theme): SerializedStyles =>
    css({
      width: 36,
      height: 36,
      display: 'grid',
      padding: 0,
      placeItems: 'center',
      borderRadius: '50%',
      transition: 'all 0.5s',
      background: theme.global.color.background.sidebar,
      color: theme.global.color.background.default,
      '&[data-selected="true"]': {
        background: theme.global.color.accent.default,
        color: theme.global.color.background.sidebar
      }
    })
};
