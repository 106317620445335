import { useAtomValue } from 'jotai';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import transform from 'lodash/transform';
import { useCallback, useMemo, useState } from 'react';
import { Integration, IntegrationCategory } from 'shared/src/types/integration';
import { createCategoryLabel } from 'src/integrations/common/integrationCategories';
import { integrationListAtom } from 'src/integrations/controller/useIntegrations';

export interface IntegrationsByCategoryType {
  label: string;
  integrations: Array<Integration>;
  category: IntegrationCategory;
}

type IntegrationCategoryOption = IntegrationCategory | 'VIEW_ALL';

interface UseIntegrationsResponse {
  integrationsByCategories?: Array<IntegrationsByCategoryType>;
  selectedCategory: IntegrationCategoryOption;
  onCategoryChange: (value: string) => void;
}

const useIntegrationsList = (): UseIntegrationsResponse => {
  const [selectedCategory, setSelectedCategory] = useState<IntegrationCategoryOption>('VIEW_ALL');
  const integrations = useAtomValue(integrationListAtom);

  const integrationsByCategories: Array<IntegrationsByCategoryType> = useMemo(() => {
    const integrationsObject = transform(
      groupBy(orderBy(integrations, 'name'), 'category'),
      (result, value: Array<Integration>, key) => {
        const category = key as IntegrationCategory;
        result[category] = {
          label: createCategoryLabel(category),
          category,
          integrations: value
        };
      },
      {} as Record<IntegrationCategory, IntegrationsByCategoryType>
    );

    const integrationList =
      selectedCategory === 'VIEW_ALL'
        ? integrationsObject
        : { [selectedCategory]: integrationsObject[selectedCategory] };
    return Object.entries(integrationList).flatMap(
      ([key, integrationByCategory]: [string, IntegrationsByCategoryType]) => {
        const integrations = integrationByCategory.integrations;

        if (integrations.length === 0) {
          return [];
        }
        return {
          category: key as IntegrationCategory,
          label: integrationByCategory.label,
          integrations
        };
      }
    );
  }, [integrations, selectedCategory]);

  const onCategoryChange = useCallback((value: string): void => {
    setSelectedCategory(value as IntegrationCategoryOption);
  }, []);

  return {
    integrationsByCategories,
    selectedCategory,
    onCategoryChange
  };
};

export default useIntegrationsList;
