import {
  Button,
  Container,
  Link,
  Panel,
  Separator,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';

type BlockedRegionPanelProps = {
  showContinueButton: boolean;
  onContinueButtonClick?: () => void;
};
const BlockedRegionPanel = ({
  showContinueButton,
  onContinueButtonClick
}: BlockedRegionPanelProps): ReactElement => {
  return (
    <Panel
      alignItems="start"
      hasBorder={true}
      orientation="vertical"
      data-testid="region-block-message"
    >
      <Title type="h1">ClickHouse Cloud is not available in your country</Title>
      <Spacer size="sm" />
      <Text>
        Service creation is not available in your country, which means that you
        cannot use ClickHouse as a hosted database service.
      </Text>
      <Text>
        You can still use your account to gain access to training or to paid
        support for your self managed ClickHouse.
      </Text>
      <Separator orientation="horizontal" size="sm" />
      <Container
        orientation="horizontal"
        gap="md"
        justifyContent="end"
        fillWidth
      >
        <Link
          href="https://clickhouse.com/clickhouse"
          target="_blank"
          icon="popout"
        >
          Try open-source ClickHouse
        </Link>
        {showContinueButton && (
          <Button
            type="primary"
            onClick={onContinueButtonClick}
            iconRight="arrow-right"
            data-testid="region-block-continue"
          >
            Continue anyway
          </Button>
        )}
      </Container>
    </Panel>
  );
};

export default BlockedRegionPanel;
