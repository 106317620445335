import React, { useEffect, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

import { useDataLimit, useResizeListener } from './ChartUtils/hooks';
import defaultClasses from './styles';

const HeatMap = React.forwardRef((props, ref) => {
  const {
    footer,
    data: dataProp,
    editable,
    showDataLabel,
    subtitle,
    title,
    xAxis,
    xAxisLabel,
    xAxisTickVertical,
    yAxis,
    yValue,
    yAxisLabel,
    yValueLabel,
    zAxis
  } = props;
  const theme = useTheme();

  const data = useDataLimit(dataProp);
  const [margin, setMargin] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    left2: 0
  });
  const d3Container = useRef(null);
  const [height, rootRef, headerRef, footerRef, onResize, width] =
    useResizeListener([title, subtitle, footer]);

  useEffect(() => {
    const charLimit = Math.max(Math.round((height / 100) * 6), 10);
    const d3Width = isNaN(width)
      ? undefined
      : width - margin.left - margin.left2 - margin.right;
    const d3Height = isNaN(height)
      ? undefined
      : height -
        Math.max(margin.top, 20) -
        (xAxisLabel || editable ? margin.bottom : 0);
    if (data && d3Container.current && d3Width && d3Height) {
      // Clear all child elements
      d3.select(d3Container.current).selectAll('*').remove();
      const svg = d3
        .select(d3Container.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height);
      // Get axis labels
      const xVars = d3.map(data, (item) => item[xAxis]);
      const yVars = d3.map(data, (item) => item[yAxis]);
      // Build X scales and axis:
      const x = d3.scaleBand().range([0, d3Width]).domain(xVars);

      const xAxisGroup = svg
        .append('g')
        .attr('class', 'd3-axis-tick d3-x-axis-tick')
        .attr('transform', `translate(${margin.left + margin.left2}, 10)`)
        .style('text-anchor', 'middle')
        .call(d3.axisTop(x).tickSize(0));

      if (xAxisTickVertical) {
        xAxisGroup
          .style('text-anchor', 'start')
          .attr('transform-origin', 'bottom left')
          .attr(
            'transform',
            `translate(${margin.left + margin.left2}, ${margin.top})`
          );
        xAxisGroup
          .selectAll('text')
          .attr('transform', 'rotate(-50)')
          .text(function (text) {
            if (typeof text !== 'string') {
              text = `${text}`;
            }

            return text.length > charLimit
              ? text.substring(0, charLimit - 4) + '...'
              : text;
          });
      } else {
        xAxisGroup.selectAll('text').text(function (text) {
          if (x.bandwidth() < this.getBBox().width) {
            if (typeof text !== 'string') {
              text = `${text}`;
            }

            return (
              text.substring(0, Math.max(Math.round(x.bandwidth() / 8), 1)) +
              '...'
            );
          }

          return text;
        });
      }

      xAxisGroup.select('.domain').remove();
      // text label for the x axis
      svg
        .append('text')
        .attr('class', 'd3-axis-label d3-x-axis-label')
        .attr(
          'transform',
          `translate(${d3Width / (2 + margin.left + margin.left2)}, ${
            height - 10
          })`
        )
        .style('text-anchor', 'middle')
        .text(xAxisLabel || (editable ? 'Enter x-axis label here' : ''));
      // Build Y scale and axis:
      const y = d3.scaleBand().range([0, d3Height]).domain(yVars);
      svg
        .append('g')
        .attr('class', 'd3-axis-tick d3-y-axis-tick')
        .attr(
          'transform',
          `translate(${margin.left}, ${Math.max(margin.top, 20)})`
        )
        .call(d3.axisLeft(y).tickSize(0))
        .select('.domain')
        .remove();
      // text label for the y axis
      svg
        .append('text')
        .attr('class', 'd3-axis-label d3-y-axis-label')
        .attr(
          'transform',
          `translate(${margin.left}, ${Math.max(margin.top, 20)})`
        )
        .attr('y', 0)
        .attr('x', 0)
        .attr('dy', '-12px')
        .attr('dx', yValue ? '0px' : '-5px')
        .style('text-anchor', 'end')
        .text(yAxisLabel || (editable ? 'Enter y-axis label here' : ''));
      // Build color scale
      const colorFn = d3
        .scaleLinear()
        .domain([0, d3.max(data.map((item) => item[zAxis]))])
        .range([
          theme.global.color.background.default,
          theme.global.color.accent.default
        ]);
      const textColorFn = d3
        .scaleLinear()
        .domain([0, d3.max(data.map((item) => item[zAxis]))])
        .range([theme.colors.c4, theme.colors.c3]);
      // create a tooltip
      const tooltip = d3
        .select(d3Container.current)
        .append('div')
        .attr('class', 'd3-tooltip');
      // Three function that change the tooltip when user hover / move / leave a cell
      const mouseover = function (event, d) {
        tooltip.style('opacity', 1);
      };
      const mousemove = function (event, d) {
        tooltip
          .html(
            `
              <p>${d[xAxis]}, ${d[yAxis]}</p>
              <p class='d3-tooltip-value'>value: ${d[zAxis]}</p>
            `
          )
          .style('left', d3.pointer(event, d3Container.current)[0] + 30 + 'px')
          .style('top', d3.pointer(event, d3Container.current)[1] + 'px');
      };
      const mouseleave = function (event, d) {
        tooltip.style('opacity', 0);
      };
      // add the squares
      const boxGroups = svg
        .selectAll()
        .data(data, function (d) {
          return d[xAxis] + ':' + d[yAxis];
        })
        .enter()
        .append('svg')
        .attr('class', (d) => {
          if (d[zAxis] === 0) {
            return 'd3-data-label-display-hover';
          }
        })
        .attr('x', function (d) {
          return x(d[xAxis]) + (margin.left + margin.left2);
        })
        .attr('y', function (d) {
          return y(d[yAxis]) + Math.max(margin.top, 20);
        })
        .attr('width', x.bandwidth())
        .attr('height', y.bandwidth())
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave);

      boxGroups
        .append('rect')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('x', 0)
        .attr('y', 0)
        .style('fill', function (d) {
          return colorFn(d[zAxis]);
        });

      if (showDataLabel) {
        boxGroups
          .append('text')
          .attr('x', '50%')
          .attr('y', '50%')
          .attr('class', 'd3-axis-tick d3-x-axis-tick d3-data-labels')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('transform-origin', '50%')
          .style('fill', function (d) {
            return textColorFn(d[zAxis]);
          })
          .text(function (d) {
            if (d[zAxis] !== undefined) {
              return d[zAxis];
            }
          });

        svg.selectAll('.d3-data-labels').each(function (d) {
          const scale = x.bandwidth() / this.getBBox().width;
          if (scale < 1) {
            d3.select(this).attr('transform', `scale(${scale.toFixed(3)})`);
          }
        });
      }
    }
  }, [
    d3Container.current,
    data,
    xAxisTickVertical,
    xAxis,
    xAxisLabel,
    yAxis,
    yAxis,
    zAxis,
    height,
    width,
    editable,
    margin,
    showDataLabel
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof document !== 'undefined' && rootRef.current) {
        let left = 0;
        let left2 = 0;
        let bottom = 0;
        let top = 0;
        if (data && yValue) {
          const group = rootRef.current.querySelector('.d3-y-value-tick');
          const yLabel = rootRef.current.querySelector('.d3-y-value-label');
          if (group) {
            const width = group.getBBox().width;
            const lableWidth = yLabel.getBBox().width;
            if (Math.max(width, lableWidth) > left2) {
              left2 = Math.max(width, lableWidth) + 10;
            }
          }
        }

        if (data && yAxis) {
          const group = rootRef.current.querySelector('.d3-y-axis-tick');
          const yLabel = rootRef.current.querySelector('.d3-y-axis-label');
          if (group) {
            const width = group.getBBox().width;
            const lableWidth = yLabel.getBBox().width;
            if (Math.max(width, lableWidth) > left) {
              left = Math.max(width, lableWidth);
            }
          }
        }

        if (data && xAxis && xAxisTickVertical) {
          const group = rootRef.current.querySelector('.d3-x-axis-tick');
          if (group) {
            const height = group.getBBox().height;
            if (height > 20) {
              top = height;
            }
          }
        }

        if (xAxisLabel) {
          bottom = bottom + 20;
        }

        setMargin({
          top,
          right: 0,
          bottom,
          left,
          left2
        });
      }
    });
  }, [
    data,
    rootRef,
    width,
    xAxis,
    xAxisLabel,
    xAxisTickVertical,
    yAxis,
    yValue,
    yAxisLabel,
    yValueLabel
  ]);

  return (
    <div ref={rootRef} css={defaultClasses.root}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      <div ref={headerRef} css={defaultClasses.headerContainer}>
        <div css={defaultClasses.header}>
          {title || (editable ? 'Enter title here' : '')}
        </div>
        {subtitle && (
          <div css={defaultClasses.subtitle}>
            {subtitle || (editable ? 'Enter subtitle here' : '')}
          </div>
        )}
      </div>
      <div
        style={{
          width,
          height,
          position: 'relative'
        }}
        ref={d3Container}
        css={defaultClasses.chartStyle}
      />
      <div ref={footerRef} css={defaultClasses.footer}>
        {footer || (editable ? 'Enter footer here' : '')}
      </div>
    </div>
  );
});

HeatMap.displayName = 'BarChart';

HeatMap.propTypes = {
  animate: PropTypes.bool,
  footer: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  xAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  xAxisLabel: PropTypes.string,
  xAxisTickVertical: PropTypes.bool,
  yAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  yValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  yAxisLabel: PropTypes.string,
  yValueLabel: PropTypes.string,
  zAxis: PropTypes.string,
  showDataLabel: PropTypes.bool
};

HeatMap.defaultProps = {
  animate: true,
  data: [],
  editable: false,
  xAxisTickVertical: false
};

export default HeatMap;
