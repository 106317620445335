import { NotificationName } from '@cp/common/protocol/NotificationInventory';
import { NotificationChannelSetting, GetNotificationChannelSettingsResponse } from '@cp/common/protocol/Notifications';
import { useCallback, useEffect, useState } from 'react';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';

type NotificationsChannelSettingsReturnType = {
  /** Settings from server */
  settings?: GetNotificationChannelSettingsResponse['settings'];
  /** Mutable settings, can be changed by user before save */
  currentSettings?: GetNotificationChannelSettingsResponse['settings'];
  isLoading: boolean;
  onSubmit: (notificationName: NotificationName) => Promise<void>;
  onSettingChange: (notificationName: NotificationName, setting: NotificationChannelSetting) => void;
};

export const useNotificationsChannelSettings = (organizationId: string): NotificationsChannelSettingsReturnType => {
  const client = useApiClient();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSettings, setCurrentSettings] = useState<
    GetNotificationChannelSettingsResponse['settings'] | undefined
  >(undefined);
  const [settings, setSettings] = useState<GetNotificationChannelSettingsResponse['settings'] | undefined>(undefined);

  const fetchSettings = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await client.notification.channelSettings({ organizationId });
      setSettings(response.settings);
      setCurrentSettings(response.settings);
    } catch (e) {
      console.error('Error while fetching settings', e);
      createToast('Error', 'danger', 'Error while fetching settings');
    } finally {
      setIsLoading(false);
    }
  }, [client.notification, organizationId]);

  // fetch settings on mount
  useEffect(() => {
    void fetchSettings();
  }, [client.notification, fetchSettings, organizationId]);

  const onSubmit = useCallback(
    async (notificationName: NotificationName): Promise<void> => {
      if (!currentSettings) {
        return;
      }
      const setting = currentSettings[notificationName];
      setIsLoading(true);
      try {
        await client.notification.updateChannelSetting({ organizationId, notificationName, setting });
        await fetchSettings();
        createToast('Success', 'success', 'Settings saved');
      } catch (e) {
        console.error('Error while saving settings', e);
        createToast('Error', 'danger', 'Error while saving settings');
      } finally {
        setIsLoading(false);
      }
    },
    [client.notification, currentSettings, fetchSettings, organizationId]
  );

  const onSettingChange = useCallback(
    (notificationName: NotificationName, setting: NotificationChannelSetting): void => {
      setCurrentSettings((prev) => {
        if (!prev) {
          return;
        }
        return {
          ...prev,
          [notificationName]: setting
        };
      });
    },
    []
  );

  return { currentSettings, settings, isLoading, onSubmit, onSettingChange };
};
