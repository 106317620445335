import {
  Badge,
  Link as CUILink,
  TableRowType,
  Tooltip
} from '@clickhouse/click-ui';
import {
  SupportCaseDetails,
  SupportCaseStatus
} from '@cp/common/protocol/Support';
import { formatMoment } from 'src/lib/formatters/dateTimeFormatter';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { capitalize } from 'lodash';
import {
  supportCasePriorityColor,
  supportCaseStatusColor
} from 'shared/src/types/support';

export type ButtonGroupSelectType = 'view_all' | 'open' | 'closed';

export type SortType = {
  elementIndex: number;
  direction: 'asc' | 'desc';
};

export type TableRowsType = Array<TableRowType>;

const supportCaseStatusDisplay: Record<SupportCaseStatus, string> = {
  NEW: 'New',
  CLOSED: 'Closed',
  WAITING_ON_CLICKHOUSE: 'Waiting on ClickHouse',
  WAITING_ON_CUSTOMER: 'ClickHouse responded'
};

/**
 * Filters and sorts the support cases based on the selected button group and sort type.
 * @param {SupportCaseDetails[]} supportCases - The support cases to filter and sort.
 * @param {string} buttonGroupSelected - The selected button group. Can be 'view_all', 'open', or 'closed'.
 * @param {SortType} sort - The sort type. Contains the index of the element to sort by and the sort direction.
 * @returns {TableRowType[]} The filtered and sorted table rows.
 */
export const filterRows = (
  supportCases: SupportCaseDetails[],
  buttonGroupSelected: ButtonGroupSelectType,
  sort: SortType
): TableRowsType => {
  const rows = supportCases.flatMap((supportCase, index) => {
    const supportTableData = [
      supportCase.caseNumber,
      supportCase.subject,
      capitalize(supportCase.priority).replace('_', ' '),
      supportCaseStatusDisplay[supportCase.status],
      supportCase.primaryClickHouseContactName,
      formatMoment(supportCase.lastModifiedDate)
    ];
    const items = [
      {
        id: `caseNumber-${index}`,
        label: supportTableData[0],
        'data-content': supportTableData[0]
      },
      {
        id: `subject-${index}`,
        label: supportCase.events?.[0]?.reply ? (
          <Tooltip>
            <Tooltip.Trigger>
              <CUILink to={supportCase.id} component={Link}>
                {supportTableData[1]}
              </CUILink>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="bottom">
              {supportCase.events[0].reply}
            </Tooltip.Content>
          </Tooltip>
        ) : (
          <CUILink to={supportCase.id} component={Link}>
            {supportTableData[1]}
          </CUILink>
        ),
        'data-content': supportTableData[1]
      },
      {
        id: `priority-${index}`,
        label: (
          <Badge
            size="sm"
            text={supportTableData[2]}
            state={supportCasePriorityColor[supportCase.priority]}
          />
        ),
        'data-content': supportTableData[2]
      },
      {
        id: `status-${index}`,
        label: (
          <Badge
            size="sm"
            text={supportTableData[3]}
            state={supportCaseStatusColor[supportCase.status]}
          />
        ),
        'data-content': supportTableData[3]
      },
      {
        id: `primaryClickHouseContactName-${index}`,
        label: supportTableData[4],
        'data-content': supportTableData[4]
      },
      {
        id: `lastModified-${index}`,
        label: supportTableData[5],
        'data-content': supportTableData[5]
      }
    ];
    const row = {
      id: supportCase.id,
      items
    };
    const supportCaseStatus = supportCase.status.toLowerCase();
    if (
      buttonGroupSelected === 'view_all' ||
      (supportCaseStatus === 'closed' && buttonGroupSelected === 'closed') ||
      (supportCaseStatus !== 'closed' && buttonGroupSelected === 'open')
    ) {
      return row;
    }
    return [];
  });

  return orderBy(
    rows,
    (row) => {
      if (sort.elementIndex === 4) {
        // sort by date
        const supportCase = supportCases.find(
          (caseItem) => caseItem.id === row.id
        );
        return supportCase?.lastModifiedDate;
      }
      return row.items[sort.elementIndex]['data-content'];
    },
    sort.direction
  );
};
