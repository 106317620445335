import { OrganizationFeatureId } from '@cp/common/protocol/features';
import { CloudProvider } from '@cp/common/protocol/Instance';
import { OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS } from '@cp/common/protocol/OpenapiKey';
import {
  ByocConfig,
  OrganizationActivity,
  OrganizationBillingStatus,
  OrganizationTackleSubscription,
  OrganizationUserNameChangedPayload
} from '@cp/common/protocol/Organization';
import { assertNever, fail } from '@cp/common/utils/Assert';
import { secondsToString } from '@cp/common/utils/DateTimeUtils';

/** Returns list of Cloud Providers the organization can use to create instances. */
export function getOrganizationCloudProviders(org: {
  features?: Array<OrganizationFeatureId>;
  tackleState?: OrganizationTackleSubscription;
  byocConfig?: ByocConfig;
}): Array<CloudProvider> {
  if (isOrgByoc(org)) {
    return ['AWS'];
  }
  if (org.tackleState) {
    switch (org.tackleState.marketplace) {
      case 'aws':
        return ['AWS'];
      case 'gcp':
        return ['GCP'];
      case 'azure':
        return ['AZURE'];
      default:
        fail(`Unsupported Tackle marketplace: ${org.tackleState.marketplace}`);
    }
  }

  if (org?.features?.includes('FT_ORG_AZURE_PRIVATE_PREVIEW')) {
    return ['AZURE'];
  }

  if (org?.features?.includes('FT_ORG_AZURE')) {
    return ['AWS', 'GCP', 'AZURE'];
  }

  return ['AWS', 'GCP'];
}

export function isInternalOrganization(billingStatus: OrganizationBillingStatus): boolean {
  return billingStatus === 'EMPLOYEE';
}

export const IS_INTERNAL_ORGANIZATION_FILTER = { billingStatus: 'EMPLOYEE' };

export function formatKeySuffix(openapiKeySuffix: string): string {
  return `****${openapiKeySuffix}`;
}

export function getDisplayActivityName(activity: OrganizationActivity): string {
  const { type, actorDetails } = activity;
  const instanceNameOrId = activity.instanceName || activity.instanceId || '-';
  switch (type) {
    case 'OPENAPI_KEY_UPDATE': {
      const { type, value = '' } = activity.payload;
      const keysSuffix = formatKeySuffix(activity.entityDetails || 'unknown');
      switch (type) {
        case 'created':
          return `API key created: ${keysSuffix}`;
        case 'deleted':
          return `API key deleted: ${keysSuffix}`;
        case 'name-changed':
          return `API key renamed: ${keysSuffix}`;
        case 'role-changed':
          return `API key ${keysSuffix} role changed to: ${value}`;
        case 'state-changed':
          return `API key ${keysSuffix} state changed to: ${value}`;
        case 'date-changed': {
          const date = new Date(value || 'must always be set');
          const displayValue =
            date.toString() === 'Invalid Date' || date.getTime() >= OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS
              ? 'Never'
              : date.toISOString().substring(0, 16).replace('T', ' '); // Trim up to minutes.
          return `API key ${keysSuffix} expiration set to: ${displayValue}`;
        }
      }
      break;
    }
    case 'CREATE_INSTANCE':
      return `Service created - ${instanceNameOrId}`;
    case 'TERMINATING_INSTANCE':
      return `Service terminated - ${instanceNameOrId}`;
    case 'STOP_INSTANCE':
      return `Service stopped - ${instanceNameOrId}`;
    case 'START_INSTANCE':
      return `Service started - ${instanceNameOrId}`;
    case 'AWAKING_INSTANCE':
      return `Service awakened - ${instanceNameOrId}`;
    case 'PARTIALLY_RUNNING_INSTANCE':
      return `Service partially ran - ${instanceNameOrId}`;
    case 'CHANGE_INSTANCE_NAME': {
      const pl = activity.payload;
      return `Service name changed - ${pl.oldName} -> ${pl.newName}`;
    }
    case 'CHANGE_INSTANCE_GPT_USAGE_CONSENT': {
      const pl = activity.payload;
      return `GPT usage consent changed to ${pl.gptUsageConsent.toString()} - ${instanceNameOrId}`;
    }
    case 'CHANGE_INSTANCE_IP_ACCESS_LIST':
      return `Service access list changed - ${instanceNameOrId}`;
    case 'CHANGE_INSTANCE_AUTOSCALING_MEMORY': {
      const pl = activity.payload;
      return `Service scaling settings adjusted - ${pl.minAutoScalingTotalMemory ?? '-'}GB (min) to ${
        pl.maxAutoScalingTotalMemory ?? '-'
      }GB (max) - ${instanceNameOrId}`;
    }
    case 'CHANGE_INSTANCE_SERVICE_IDLING': {
      const pl = activity.payload;
      const enabledIdleScalingString = `enabled, minimum idle time: ${secondsToString(pl.idleTimeoutMinutes * 60)}`;
      return `Service idling ${pl.enableIdleScaling ? enabledIdleScalingString : 'disabled'} - ${instanceNameOrId}`;
    }
    case 'CREATE_ORGANIZATION':
      return 'Organization created';
    case 'DELETE_ORGANIZATION':
      return 'Organization deleted';
    case 'CHANGE_ORGANIZATION_NAME':
      return `Organization name changed - ${(activity.payload as OrganizationUserNameChangedPayload).newName}`;
    case 'CHANGE_USER_ROLE': {
      const pl = activity.payload;
      return `User role changed - ${pl.changedUserEmail} -> ${pl.newRole}`;
    }
    case 'REMOVE_USER_FROM_ORGANIZATION':
      return `User removed from organization - ${activity.payload.removedUserFullName}`;
    case 'INVITE_USERS_TO_ORGANIZATION': {
      const pl = activity.payload;
      const emails = pl.invitedEmails.join(', ');
      return `Invited users to organization - ${emails}`;
    }
    case 'JOIN_ORGANIZATION':
      return `User joined organization - ${actorDetails}`;
    case 'ADD_ORGANIZATION_MEMBER':
      return `User joined organization - ${actorDetails}`;
    case 'DELETE_ORGANIZATION_INVITATION': {
      const pl = activity.payload;
      return `Organization invitation deleted - ${pl.invitedEmail}`;
    }
    case 'LEAVE_ORGANIZATION':
      return `User left organization - ${actorDetails}`;
    case 'INSTANCE_PASSWORD_RESET':
      return `Service password was reset - ${actorDetails} -> ${instanceNameOrId}`;
    case 'DELETE_INSTANCE_BACKUP': {
      const { backupId } = activity.payload;
      return `Delete backup (${backupId}) of service ${instanceNameOrId}`;
    }
    case 'INSTANCE_CMEK_KEY_CHECK_FAILED':
      return 'CMEK key check failed';
    case 'INSTANCE_CMEK_KEY_ROTATION_FAILED':
      return 'CMEK key rotation failed';
    case 'INSTANCE_CMEK_KEY_ROTATION_SUCCESS':
      return 'CMEK key rotated';
    case 'INSTANCE_CMEK_POLLER_STOP_INSTANCE':
      return 'Instance stopped by CMEK poller';
    case 'RESURRECT_INSTANCE':
      return `Instance resurrected - ${instanceNameOrId}`;
    case 'ASSIGN_TACKLE_SUBSCRIPTION': {
      const { token } = activity.payload;
      return `Assigned marketplace subscription: ${token.marketplace}/${token.customerId}`;
    }
    case 'IMPORT_INSTANCE': {
      const { sourceOrganizationId } = activity.payload;
      return `Imported service - ${instanceNameOrId} from organization - ${sourceOrganizationId}`;
    }
    case 'EXPORT_INSTANCE': {
      const { targetOrganizationId } = activity.payload;
      return `exported service - ${instanceNameOrId} from organization - ${targetOrganizationId}`;
    }
    case 'CHANGE_INSTANCE_AUTOSCALING_REPLICAS': {
      const { minReplicas, maxReplicas } = activity.payload;
      const minReplicasUpdate = minReplicas ? `min replicas: ${minReplicas}` : '';
      const maxReplicasUpdate = maxReplicas ? `max replicas: ${maxReplicas}` : '';
      const update = [minReplicasUpdate, maxReplicasUpdate].join(', ');
      return `Change instance - ${instanceNameOrId} autoscaling configuration - ${update}`;
    }
    case 'CHANGE_INSTANCE_MAX_ALLOWABLE_REPLICAS': {
      const { maxAllowableReplicas } = activity.payload;
      return `Change instance - ${instanceNameOrId} max allowable autoscaling replicas to ${maxAllowableReplicas}`;
    }
    case 'CHANGE_NAME': {
      const { previousName, newName } = activity.payload;
      return `Change user name from ${previousName} to ${newName}`;
    }
    case 'TRANSFER_SERVICE_IN': {
      const { sourceOrganizationId } = activity.payload;
      return `Transfer service - ${instanceNameOrId} from source organization - ${sourceOrganizationId}`;
    }
    case 'TRANSFER_SERVICE_OUT': {
      const { targetOrganizationId } = activity.payload;
      return `Transfer service - ${instanceNameOrId} to target organization - ${targetOrganizationId}`;
    }
    case 'TRANSFER_TACKLE_TOKEN_IN': {
      const { sourceOrganizationId } = activity.payload;
      return `Transfer marketplace subscription from source organization - ${sourceOrganizationId}`;
    }
    case 'TRANSFER_TACKLE_TOKEN_OUT': {
      const { targetOrganizationId } = activity.payload;
      return `Transfer marketplace subscription to target organization (${targetOrganizationId})`;
    }
    case 'CHANGE_ORGANIZATION_BILLING_STATUS': {
      const { previousBillingStatus, newBillingStatus } = activity.payload;
      return `Change organization billing status from ${previousBillingStatus} to ${newBillingStatus}`;
    }

    case 'CHANGE_MFA_METHOD': {
      const { newMfaMethod } = activity.payload;
      const mfaEnabled = newMfaMethod === 'SOFTWARE_TOKEN_MFA' ? 'enabled' : 'disabled';
      return `${actorDetails} changed MFA method - ${mfaEnabled}`;
    }
    case 'UPDATE_INSTANCE_RELEASE_CHANNEL': {
      const { releaseChannel } = activity.payload;
      return `Update instance - ${instanceNameOrId} release channel - ${releaseChannel}`;
    }
    case 'RESTORE_BACKUP': {
      const { backupId } = activity.payload;
      return `Restore backup - ${backupId} of instance - ${instanceNameOrId}`;
    }

    case 'SAVE_PAYMENT_METHOD':
    case 'CHANGE_INSTANCE_BACKUP_CONFIGURATION':
    case 'CHANGE_ORGANIZATION_BYOC_CONFIG':
    case 'CHANGE_ORGANIZATION_RESTRICTIONS':
    case 'CHANGE_INSTANCE_PRIVATE_ENDPOINTS':
    case 'CHANGE_ORGANIZATION_PRIVATE_ENDPOINTS':
    case 'CHANGE_USER_RESTRICTIONS':
    case 'DELETE_USER':
    case 'SIGN_UP':
    case 'TAG_ORGANIZATION_AS_HOT_CUSTOMER':
    case 'SYSADMIN_ADD_PERMISSION':
    case 'SYSADMIN_REMOVE_PERMISSION':
    case 'SYSADMIN_ADD_USER_TO_GROUP':
    case 'SYSADMIN_REMOVE_USER_FROM_GROUP':
    case 'UPDATE_FEATURES':
    case 'UPGRADE_INSTANCE': {
      const name = (type as string).replace(/_/g, ' ').toLowerCase().trim();
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
    case 'RENAME_DATA_WAREHOUSE': {
      const pl = activity.payload;
      return `Warehouse name changed to ${pl.newName}`;
    }

    default: {
      assertNever(type, `Activity type is not found: ${type}`);
    }
  }
}

export function isOrgByoc<T extends { byocConfig?: ByocConfig }>(
  organization: T
): organization is T & { byocConfig: ByocConfig } {
  return !!organization.byocConfig;
}
