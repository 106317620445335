import { Button, Container, Separator } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useIsAddressFormValid } from 'src/components/BillingCardModal/FormState';
import { AddressInfoContainer } from 'src/components/BillingCardModal/AddressInfoContainer';

type AddressStageProps = {
  onClose: () => void;
  onAdvance: () => Promise<void>;
  isLoading: boolean;
};

export const AddressInfoForm = ({
  onClose,
  onAdvance,
  isLoading
}: AddressStageProps): ReactElement => {
  const canBeSubmitted = useIsAddressFormValid();

  return (
    <>
      <AddressInfoContainer />
      <Separator size="lg" orientation="horizontal" />
      <Container justifyContent="end" gap="md" isResponsive={false}>
        <Button
          onClick={onClose}
          type="secondary"
          label="Cancel"
          data-testid="company-or-payment-back-button"
        />
        <Button
          align="center"
          loading={isLoading}
          disabled={isLoading || !canBeSubmitted}
          iconRight="arrow-right"
          label="Account details"
          data-testid="company-or-payment-update-button"
          type="primary"
          onClick={onAdvance}
        />
      </Container>
    </>
  );
};
