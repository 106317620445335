import { GridContainer, IconButton, TextField } from '@clickhouse/click-ui';
import { FocusEventHandler, ReactElement, useState } from 'react';
import { useVisited } from 'src/lib/hooks';
import styled from 'styled-components';

const CustomIconButton = styled(IconButton)<{ $show: boolean }>`
  opacity: ${({ $show }): number => ($show ? 1 : 0)};
  visibility: ${({ $show }): string => ($show ? 'visible' : 'hidden')};
`;

type InputChange = 'source' | 'description';

export interface NetworkInputProps {
  gridTemplateColumns: string;
  onChange: (type: InputChange, value: string) => void;
  onBlur: () => void;
  onRemove: () => void;
  disabled: boolean;
  duplicateList: Array<string>;
  source: string;
  description: string;
  showIpDeleteButton: boolean;
  invalidIpList: Array<string>;
}

export const NetworkInput = ({
  gridTemplateColumns,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  onRemove,
  disabled,
  showIpDeleteButton,
  source,
  description,
  duplicateList,
  invalidIpList
}: NetworkInputProps): ReactElement => {
  const [visited, setVisited] = useVisited();
  const [focus, setFocus] = useState(false);

  const sourceError =
    !focus &&
    ((visited && source.length === 0 && 'IP is required') ||
      (invalidIpList.includes(source) && 'IP is invalid') ||
      (duplicateList.includes(source) && 'IP is duplicated'));

  const onChange =
    (type: InputChange) =>
    (value: string): void => {
      onChangeProp(type, value);
    };

  const onFocus = (): void => {
    setFocus(true);
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = () => {
    setVisited();
    onBlurProp();
    setTimeout(() => {
      setFocus(false);
    }, 10);
  };

  return (
    <GridContainer
      gridTemplateColumns={gridTemplateColumns}
      isResponsive={false}
      alignItems="start"
      className="fs-exclude"
      data-testid="ip-rows"
    >
      <TextField
        value={source}
        onFocus={onFocus}
        onChange={onChange('source')}
        onBlur={onBlur}
        error={sourceError}
        autoFocus
        data-testid="access-list-ip"
        data-ip-source
        disabled={disabled}
      />
      <TextField
        value={description}
        onChange={onChange('description')}
        data-testid="access-list-description"
        disabled={disabled}
      />
      {!disabled && (
        <CustomIconButton
          icon="cross"
          onClick={onRemove}
          $show={showIpDeleteButton}
          data-testid="delete-ip-btn"
        />
      )}
    </GridContainer>
  );
};
