import React from 'react';

import styles from 'src/components/App/MainView/TabBar/styles';
import PlusIcon from 'src/components/icons/PlusIcon';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function AddTabButton({ onClick }: Props) {
  return (
    <button css={styles.newTab} data-testid="newTabButton" onClick={onClick}>
      <PlusIcon />
    </button>
  );
}
