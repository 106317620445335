import { Container, Select, Text } from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import StringField from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/StringField';
import styled from 'styled-components';
import {
  DashboardConfig,
  DashboardConfigField
} from 'shared/src/types/dashboard';
import { UpdateDashboardFilterConfigFunc } from 'src/dashboard/dashboardController';
import InlineQueryEditor from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/InlineQueryEditor';
import { SavedQueryDTO } from 'shared';

const ParametersContainer = styled(Container)`
  margin-left: 10px;
`;

interface QueryIdFieldProps {
  config: DashboardConfig;
  dashboardId: string;
  editingObjectId: string;
  label: string;
  onInlineQueryEdit: (queryId: string, newQuery: SavedQueryDTO) => void;
  onParameterCancel: () => void;
  onParameterChange: (
    parameter: string,
    value: DashboardConfigField<string>
  ) => void;
  onParameterConfirm: (
    parameter: string,
    changes?: DashboardConfigField<string>
  ) => Promise<void>;
  onParameterFilterConfirm: UpdateDashboardFilterConfigFunc;
  onSelect: (value: string, newQuery?: SavedQueryDTO) => void;
  parameters: Record<string, DashboardConfigField<string>>;
  value: string;
}

export default function QueryIdField({
  config,
  dashboardId,
  editingObjectId,
  label,
  onInlineQueryEdit,
  onParameterCancel,
  onParameterChange,
  onParameterConfirm,
  onParameterFilterConfirm,
  onSelect,
  parameters,
  value
}: QueryIdFieldProps): ReactElement {
  const { savedQueries } = useSavedQueries();

  const savedQuery = savedQueries.find((q) => q.id === value);

  return (
    <Container orientation="vertical" gap="sm">
      <Text weight="bold">{label}</Text>
      <Container gap="sm" orientation="horizontal">
        <Select onSelect={(newValue) => onSelect(newValue)} value={value}>
          {savedQueries.map((query) => {
            return (
              <Select.Item key={query.id} label={query.name} value={query.id} />
            );
          })}
        </Select>
        <InlineQueryEditor
          key={value}
          onSaveQuery={(newQuery) => onInlineQueryEdit(value, newQuery)}
          queryId={value}
        />
      </Container>
      {!!savedQuery && (
        <ParametersContainer gap="sm" orientation="vertical" padding="sm">
          {Object.keys(savedQuery.parameters).map((parameter) => {
            const parameterConfig = parameters[parameter];
            return (
              <StringField
                config={config}
                dashboardId={dashboardId}
                defaultValue={savedQuery.parameters[parameter]}
                editingObjectId={editingObjectId}
                field={parameter}
                filterable
                label={parameter}
                key={parameter}
                linkable
                onCancel={onParameterCancel}
                onConfirm={(value) => {
                  onParameterConfirm(parameter, value).catch(console.error);
                }}
                onChange={(value) => {
                  onParameterChange(parameter, value);
                }}
                onFilterConfirm={onParameterFilterConfirm}
                value={parameterConfig}
              />
            );
          })}
        </ParametersContainer>
      )}
    </Container>
  );
}
