import { OrganizationRole } from '@cp/common/protocol/Organization';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useCurrentOrganizationOrThrow, useOrganizationRole } from 'src/organization/organizationState';

type UserWithRoleInCurrentOrg = {
  id: string;
  email: string;
  name: string;
  role: OrganizationRole;
};

export function useCurrentUserWithRoleInCurrentOrg(): UserWithRoleInCurrentOrg {
  const currentUser = useCurrentUserOrThrow();
  const organization = useCurrentOrganizationOrThrow();
  const role = useOrganizationRole(organization.id);

  if (!role) {
    throw new Error(`No role found for current user in organization with id ${organization.id}`);
  }

  return {
    id: currentUser.id,
    email: currentUser.email,
    name: [currentUser.givenName, currentUser.familyName].filter((name) => !!name).join(' '),
    role: role
  };
}
