import { Text as CUIText, Select, Separator } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useSelectService } from 'src/state/service/selectService';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { formatStateForDisplay } from 'src/instance/instance';
import { routes } from 'src/lib/routes';
import { Instance } from '@cp/common/protocol/Instance';
import styled from 'styled-components';

const StatusText = styled(CUIText)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const goToService = (serviceId: string, isDesktop: boolean): void => {
  if (serviceId === 'all-services') {
    navigateTo(routes.serviceList());
  } else if (isDesktop) {
    navigateTo(routes.service({ serviceId }));
  } else {
    navigateTo(routes.serviceSettings({ serviceId }));
  }
};
interface ServiceSelectorProps {
  isDesktop: boolean;
  services: Array<Instance>;
  currentService?: Instance;
}

export const ServiceSelector = ({
  isDesktop,
  services,
  currentService
}: ServiceSelectorProps): ReactElement => {
  const selectService = useSelectService();
  return (
    <Select
      onSelect={(serviceId) => {
        goToService(serviceId, isDesktop);
        selectService({ serviceId });
      }}
      value={currentService?.id}
      placeholder="Select a service"
      data-testid="serviceSelector"
      useFullWidthItems
    >
      {services
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((service) => (
          <Select.Item
            value={service.id}
            key={service.id}
            icon={service.cloudProvider}
          >
            {service.name}
            <StatusText
              color="muted"
              data-hide-in-trigger
              size="sm"
              data-testid={`serviceSelector-${service.id}`}
            >
              {formatStateForDisplay(service.state)}
            </StatusText>
          </Select.Item>
        ))}
      <Separator size="sm" />
      <Select.Item value="all-services" icon="services">
        All services
      </Select.Item>
    </Select>
  );
};
