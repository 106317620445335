import { RowPreview, TsvFormatType } from 'shared/src/dataLoading';
export async function parseTsvRowsFromData(data: string, formatType: TsvFormatType): Promise<[RowPreview[][], string]> {
  const rows: RowPreview[][] = data.split('\n').map((row) => row.split('\t'));
  switch (formatType) {
    case 'TabSeparated':
      return [rows, data];
    case 'TabSeparatedWithNames':
      return [rows.slice(1, rows.length), data];
    case 'TabSeparatedWithNamesAndTypes': {
      const [_headers, _types, ...rest] = rows;
      return [rest, data];
    }
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unsupported format type ${formatType}`);
  }
}
