import { ReactElement } from 'react';
import { useAuth } from 'src/components/auth';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useCurrentOrganization } from 'src/organization/organizationState';
import UsagePage from 'src/pages/OrganizationUsagePage/UsagePage';
import Helmet from 'src/components/Helmet/Helmet';

const OrganizationUsagePage = (): ReactElement | null => {
  const { currentUser } = useAuth();
  const currentOrg = useCurrentOrganization();
  if (!currentUser || !currentOrg) {
    return null;
  }

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Usage breakdown" />
      <MainPaneLayout.Content>
        <Helmet>
          <title>ClickHouse Cloud - Usage breakdown</title>
        </Helmet>

        <MaxWidthContainer
          orientation="vertical"
          data-testid="usage-page"
          fillHeight
        >
          <UsagePage organization={currentOrg} />
        </MaxWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default OrganizationUsagePage;
