import { Draft } from 'immer';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useDebounce } from 'src/lib/hooks';
import styled from 'styled-components';
import { Container, Select } from '@clickhouse/click-ui';

import { ClickPipeKafkaImport } from 'shared/src/dataLoading';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';

const DateTimeSelectorStyles = styled.div`
  ${({ theme }) => `
    input {
      display: flex;
      padding: 0.13rem 0.75rem;
      margin-top: 1.6rem;
      border: 1px solid ${theme.global.color.stroke.default};
      border-radius: 4px;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;
      gap: 0.5rem;
      font-size: 14px;
      line-height: 23px;
      background: ${theme.global.color.background.muted};
      color: ${theme.global.color.text.default};
    }

    input:focus {
      border-color: ${theme.global.color.accent.default};
    }
  `}
`;

const DateTimeSelectorTheme = styled.div`
  ${({ theme }) =>
    theme.name === 'dark'
      ? `
        color-scheme: dark;
  `
      : `
        color-scheme: light;
  `}
`;

type Props = {
  topicDefined: boolean;
  updateSampleFunc: (value: string | undefined) => Promise<void>;
};

export default function OffsetSelector({
  topicDefined,
  updateSampleFunc
}: Props): JSX.Element {
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipeKafkaImport>();
  const { offsetStrategy, offsetValue } = importModel.data;

  const onSelect = (value: string): void =>
    updateImportModel((draft: Draft<ClickPipeKafkaImport>) => {
      if (
        value === 'from_beginning' ||
        value === 'from_latest' ||
        value === 'from_timestamp'
      ) {
        draft.data.offsetStrategy = value;

        if (value === 'from_beginning') {
          draft.data.offsetValue = '0';
        } else if (value === 'from_latest') {
          draft.data.offsetValue = 'latest';
        } else {
          const now = new Date();
          const utcDate = new Date(now.toISOString());
          draft.data.offsetValue = utcDate.toISOString().substring(0, 16);
        }
      } else {
        throw Error('Invalid offset strategy');
      }
    });

  const updateOffsetValue = (value: string): void => {
    updateImportModel((draft: Draft<ClickPipeKafkaImport>) => {
      draft.data.offsetValue = value;
    });

    debouncedSampleChange(value);
  };

  const updateSample = (value: string): void => {
    updateSampleFunc(value).catch((err) =>
      console.error(`could not update kafka sample: ${err}`)
    );
  };

  const handleChange = (v: string) => {
    updateOffsetValue(v);
  };

  // debounce to reduce load on the discovery service this can happen if the user is typing slowly
  const debouncedSampleChange = useCallback(
    debounce((value: string) => {
      updateSample(value);
    }, 1250),
    []
  );

  const CondContainer =
    offsetStrategy === 'from_timestamp' ? TwoColsContainer : Container;

  return (
    <Container orientation="vertical" gap="md">
      <CondContainer>
        <Select
          data-testid="offset-select"
          value={offsetStrategy}
          onSelect={onSelect}
          label="Offset selection (Beta)"
          className="fs-exclude"
          disabled={!topicDefined}
        >
          <Select.Item value="from_beginning" className="fs-exclude">
            From beginning
          </Select.Item>
          <Select.Item value="from_latest" className="fs-exclude">
            From latest
          </Select.Item>
          <Select.Item value="from_timestamp" className="fs-exclude">
            From timestamp
          </Select.Item>
        </Select>

        {offsetStrategy === 'from_timestamp' && (
          <TwoColsContainer style={{ display: 'block' }}>
            <DateTimeSelectorTheme>
              <DateTimeSelectorStyles>
                <input
                  type="datetime-local"
                  className="input-field fs-exclude"
                  id="kafka_offset_from_timestamp"
                  value={offsetValue}
                  onChange={(v) => handleChange(v.target.value)}
                  // disable the future
                  max={new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .substring(0, 16)}
                />
              </DateTimeSelectorStyles>
            </DateTimeSelectorTheme>
          </TwoColsContainer>
        )}
      </CondContainer>
    </Container>
  );
}
