import { useCallback } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useUserStateManager } from 'src/user/userState';

type UserPreferencesControllerResult = {
  isGptInlineCodeCompletionEnabled: boolean;
  toggleGptInlineCodeCompletion: (value: boolean) => Promise<void>;
};

/**
 * Hook to manage user preferences.
 */
export function useUserPreferencesController(): UserPreferencesControllerResult {
  const apiClient = useApiClient();
  const { user, updateUser } = useUserStateManager();
  const preferences = user?.preferences;
  const isGptInlineCodeCompletionEnabled = preferences?.isGptInlineCodeCompletionEnabled ?? false;

  /**
   * Enable/disable the GPT inline code completion.
   * @param {boolean} value The new value for the GPT inline code completion setting.
   * @returns {Promise<void>}
   */
  const toggleGptInlineCodeCompletion = useCallback(
    async (value: boolean): Promise<void> => {
      await apiClient.updateUserPreferences({
        isGptInlineCodeCompletionEnabled: value
      });
      updateUser({ preferences: { ...preferences, isGptInlineCodeCompletionEnabled: value } });
    },
    [apiClient, updateUser, preferences]
  );

  return {
    isGptInlineCodeCompletionEnabled,
    toggleGptInlineCodeCompletion
  };
}
