import { Fragment } from 'react';

import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { spreadsheetLoaderStyle } from 'src/components/primitives/lib/Spreadsheet/styles';
import { useCUITheme } from '@clickhouse/click-ui';

const rowHeight = 30;
const cellWidth = 75;
const cellMargin = 25;

interface Props extends IContentLoaderProps {
  columns?: number;
  rows?: number;
}

const SpreadsheetLoader = (props: Props) => {
  const { columns = 5, rows = 10 } = props;
  const width = 32 + columns * (cellWidth + cellMargin);
  const height = rowHeight * rows;
  const theme = useCUITheme();
  return (
    <div css={spreadsheetLoaderStyle}>
      <ContentLoader
        viewBox={`0 0 ${width} ${height}`}
        {...props}
        style={{
          width,
          height,
          ...(props.style || {})
        }}
        foregroundColor={theme.global.color.stroke.intense}
        backgroundColor={theme.global.color.stroke.muted}
        speed={2}
      >
        {new Array(rows).fill('').map((_, index) => {
          const contentVerticalPosition = (contentHeight: number) =>
            rows > 1 ? contentHeight + rowHeight * index : contentHeight;
          return (
            <Fragment key={index}>
              <rect
                x="6"
                y={contentVerticalPosition(rowHeight / 3)}
                rx="4"
                ry="4"
                width="20"
                height={rowHeight / 3}
                vectorEffect="non-scaling-stroke"
              />
              {new Array(columns).fill('').map((_, j) => (
                <rect
                  key={j}
                  x={32 + j * (cellWidth + cellMargin)}
                  y={contentVerticalPosition(rowHeight / 3)}
                  rx="10"
                  ry="4"
                  width={cellWidth}
                  height={rowHeight / 3}
                  vectorEffect="non-scaling-stroke"
                />
              ))}
              <rect
                y={contentVerticalPosition(rowHeight - 1)}
                x="10"
                ry="10"
                width="1500"
                height="1"
                vectorEffect="non-scaling-stroke"
              />
            </Fragment>
          );
        })}
      </ContentLoader>
    </div>
  );
};

export default SpreadsheetLoader;
