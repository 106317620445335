import { useCallback, useState } from 'react';

export function useVisited(): [visited: boolean, visit: () => void] {
  const [visited, setVisited] = useState(false);

  const visit = useCallback(() => {
    setVisited(true);
  }, []);

  return [visited, visit];
}
