import { ReactElement, useEffect, useState, useCallback } from 'react';
import { createToast } from 'primitives';
import { routes } from 'src/lib/routes';
import {
  useColumns,
  useImportModel,
  useIsDataSampleSelected,
  useRowsPreview as useRowsPreview
} from 'src/lib/dataLoading';
import { DataPreviewer } from 'src/lib/dataLoading/DataPreviewer';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { S3_SAMPLES } from 'shared/src/dataLoading/samples';
import { useTables } from 'src/metadata/metadataState';
import { useConnectionCredentials } from 'src/state/connection';
import {
  getCurrentServiceIdOrFail,
  useOrgIdFromServiceIdOrFail
} from 'src/state/service';
import { useApiClient } from 'src/lib/controlPlane/client';
import PreviewBox from 'src/components/ImportView/ImportForm/PreviewBox';
import SampleDataSelector from 'src/components/ImportView/SampleDataSelector';
import {
  Button,
  Container,
  Spacer,
  VerticalStepper
} from '@clickhouse/click-ui';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';

function SampleDataForm(): ReactElement | null {
  const api = useApiClient();
  const { importModel, resetImportModel, setDataPreview, setName } =
    useImportModel();

  const { selectedDatabase } = useConnectionCredentials();
  const isDataSampleSelected = useIsDataSampleSelected();
  const rowsPreview = useRowsPreview();
  const columns = useColumns();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const serviceId = getCurrentServiceIdOrFail();
  const organizationId: string = useOrgIdFromServiceIdOrFail(serviceId);
  const tables = useTables();
  const dataPreviewer = new DataPreviewer(
    api,
    serviceId,
    tables,
    selectedDatabase
  );
  const dataset =
    importModel.type === 'sample_data' && importModel.data?.dataset;

  const fetchPreviewData = useCallback(async (dataset: string) => {
    setLoading(true);

    try {
      const sample = S3_SAMPLES.find((sample) => sample.name === dataset);

      if (!sample) {
        console.warn(`Could not find sample identified by dataset ${dataset}`);
        return;
      }

      const dataPreview = await dataPreviewer.getPreviewDataFromUrl(
        sample.link,
        sample.tableName,
        'newTable',
        {
          numLines: 20,
          fileType: sample.fileType,
          formatType: sample.fileFormat
        }
      );

      setDataPreview(dataPreview);
      setName(sample.name);
    } catch (error) {
      console.warn(error);
      createToast('error', 'alert', errorMessage(error));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (dataset) {
      void fetchPreviewData(dataset);
    }
  }, [dataset]); //Do not include importModel. We only want this to run, whenever the dataset changes

  const createImport = async (): Promise<void> => {
    if (loading || submitting) {
      return;
    }

    setSubmitting(true);
    try {
      const result = await api.dataloading.createDataImport({
        serviceId,
        organizationId,
        importModel
      });

      if (result.ok) {
        resetImportModel();
        navigateTo(routes.imports({ serviceId }));
        createToast(
          'Success',
          'success',
          'Data import has been successfully created'
        );
      } else {
        createToast('Error', 'alert', result.errors.join('\n'));
      }
    } catch (error) {
      createToast('Error', 'alert', errorMessage(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <VerticalStepper>
      <VerticalStepper.Step
        label="Select Dataset"
        collapsed={false}
        status={isDataSampleSelected ? 'complete' : 'active'}
      >
        <SampleDataSelector />
      </VerticalStepper.Step>
      <VerticalStepper.Step
        label="Import Options"
        status={isDataSampleSelected ? 'active' : 'incomplete'}
      >
        <PreviewBox loading={loading} columns={columns} rows={rowsPreview} />
        <Spacer size="md" />
        <Container>
          <Button
            type="primary"
            onClick={(): void => {
              createImport().catch(console.error);
            }}
            iconLeft="download"
            loading={loading || submitting}
          >
            Import dataset
          </Button>
        </Container>
      </VerticalStepper.Step>
    </VerticalStepper>
  );
}

SampleDataForm.displayName = 'SampleDataForm';
export default SampleDataForm;
