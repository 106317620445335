import { Link } from '@clickhouse/click-ui';
import { NotificationChannelServiceSetting } from '@cp/common/protocol/Notifications';
import { memo } from 'react';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';

type Props = {
  setting?: NotificationChannelServiceSetting;
  openFlyoutForNotification?: () => void;
};

const getServiceIdsWithEnabledChannels = (
  setting: NotificationChannelServiceSetting
): string[] => {
  return Object.keys(setting.settingsByService).filter((serviceId) => {
    const service = setting.settingsByService[serviceId];
    return Object.values(service).some((enabled) => enabled);
  });
};

export const OnServiceCell = memo(
  ({ setting, openFlyoutForNotification }: Props) => {
    const services = useCurrentOrganizationInstances();
    if (!setting) {
      // TODO add skeleton if we have it
      return <>Loading...</>;
    }

    const serviceIdsWithEnabledChannels =
      getServiceIdsWithEnabledChannels(setting);

    if (
      serviceIdsWithEnabledChannels.length ===
        Object.keys(setting.settingsByService).length &&
      serviceIdsWithEnabledChannels.length > 2
    ) {
      return <>All services</>;
    }

    const serviceNames = serviceIdsWithEnabledChannels.map((serviceId) => {
      return (
        services.find((service) => service.id === serviceId)?.name ?? serviceId
      );
    });

    if (serviceNames.length === 0) {
      return <>-</>;
    }

    // sort names
    serviceNames.sort((a, b) => a.localeCompare(b));

    if (serviceNames.length > 2) {
      return (
        <>
          {serviceNames.slice(0, 2).join(', ')}, and{' '}
          <Link onClick={openFlyoutForNotification} data-testid="n-more-link">
            {serviceNames.length - 2} more
          </Link>
        </>
      );
    }

    return <>{serviceNames.join(', ')}</>;
  }
);
