import { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  BigStat,
  Button,
  ButtonGroup,
  CodeBlock,
  Container,
  Dialog,
  Select,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import {
  getTimePeriodLabel,
  SelectableTimePeriod,
  TimePeriodLabel,
  TimeSelect
} from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { QueryInsightsChart } from 'src/components/QueryInsights/QueryInsightsChart';
import { QueryInsightsTable } from 'src/components/QueryInsights/QueryInsightsTable';
import {
  QueryType,
  useGetQueryLatencyCount,
  useGetQueryTypeForTimePeriod
} from 'src/components/QueryInsights/queries';
import { QueryResult, isResultError } from 'src/lib/clickhouse/query';
import { Row } from 'shared/src/clickhouse';
import { formatNumber } from 'src/lib/formatters/numberFormatter';
import { Galaxy } from 'galaxy';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useCurrentInstanceId } from 'src/instance/instanceController';

export type SelectableLatency = 'p50' | 'p90' | 'p99';

const queryTypeLabelMap: { [key: string]: string } = {
  allQueries: 'Query volume',
  slowQueries: 'Latency',
  errors: 'Errors'
};

interface QueryPermsCheckerProps {
  results: Array<QueryResult>;
}
interface QueryPermsCheckerResult {
  hasError: boolean;
  necessaryGrants: Array<string>;
}
//TODO: move checkPermissionsError to a util function, extend it for more holistic coverage
//Potentially regex is a better method of string matching/parsing here.
// Defining these constants outside so that they are easily modifiable
const grantSubstringStartText =
  "To execute this query, it's necessary to have the grant ";
const grantSubstringEndText = '. (ACCESS_DENIED)';
const selectSubstringStartText = 'SELECT(';
const selectSubstringEndText = ') ON system.query_log';
const getGrantText = (errorMsg: string): string => {
  return errorMsg.substring(
    errorMsg.indexOf(grantSubstringStartText) + grantSubstringStartText.length,
    errorMsg.indexOf(grantSubstringEndText)
  );
};
const getQueryLogColumnNames = (grantMsg: string): Array<string> => {
  const rawColumnNames = grantMsg.substring(
    grantMsg.indexOf(selectSubstringStartText) +
      selectSubstringStartText.length,
    grantMsg.indexOf(selectSubstringEndText)
  );
  return rawColumnNames.split(', ');
};
const generateQueryLogGrantCommand = (
  queryLogColumns: Array<string>
): string | undefined => {
  const deduplicatedQueryLogColumns = [...new Set(queryLogColumns)].join(', ');
  if (deduplicatedQueryLogColumns.length > 0) {
    return 'SELECT(' + deduplicatedQueryLogColumns + ') ON system.query_log';
  }
};

const collateGrantCommands = (grantMsgs: Array<string>): Array<string> => {
  const queryLogColumns: Array<string> = [];
  const necessaryGrants: Array<string> = [];
  grantMsgs.forEach((grant) => {
    if (
      grant.startsWith(selectSubstringStartText) &&
      grant.endsWith(selectSubstringEndText)
    ) {
      queryLogColumns.push(...getQueryLogColumnNames(grant));
    } else {
      necessaryGrants.push(grant);
    }
  });
  const queryLogGrant = generateQueryLogGrantCommand(queryLogColumns);
  if (queryLogGrant) {
    necessaryGrants.push(queryLogGrant);
  }
  return necessaryGrants;
};
const checkPermissionsError = ({
  results
}: QueryPermsCheckerProps): QueryPermsCheckerResult => {
  const grantMsgs: Array<string> = [];

  results.forEach((result) => {
    if (!isResultError(result)) {
      return;
    }
    const errorMsg = JSON.parse(result.error).exception;
    if (errorMsg.startsWith('Code: 497.')) {
      grantMsgs.push(getGrantText(errorMsg));
    }
  });
  const necessaryGrants = collateGrantCommands(grantMsgs);

  return {
    hasError: necessaryGrants.length > 0,
    necessaryGrants: necessaryGrants
  };
};

export const QueryInsights = (): ReactElement => {
  const currentInstanceId = useCurrentInstanceId();
  const [selectedQueryType, setSelectedQueryType] =
    useState<QueryType>('allQueries');
  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<SelectableTimePeriod>('LAST_HOUR');
  const [selectedLatency, setSelectedLatency] =
    useState<SelectableLatency>('p50');
  const [chartQueries, setChartQueries] = useState<QueryResult>();
  const [necessaryGrants, setNecessaryGrants] = useState<Array<string>>([]);
  const [hasChartError, setHasChartError] = useState<boolean>(false);

  const [allQueriesCount, setAllQueriesCount] = useState<string>('-');
  const [slowQueriesCount, setSlowQueriesCount] = useState<string>('-');
  const [errorsCount, setErrorsCount] = useState<string>('-');
  const timePeriodLabel = getTimePeriodLabel(selectedTimePeriod);

  const handleTimeChange = (timePeriod: SelectableTimePeriod): void => {
    if (timePeriod !== selectedTimePeriod) {
      Galaxy.galaxy().track('queryInsights.timePeriod.changed', {
        interaction: 'click',
        timePeriod
      });
      setSelectedTimePeriod(timePeriod);
      setChartQueries(undefined);
      setAllQueriesCount('-');
      setSlowQueriesCount('-');
      setErrorsCount('-');
    }
  };

  const handleSelectQueryType = (queryType: string): void => {
    if (queryType !== selectedQueryType) {
      Galaxy.galaxy().track('queryInsights.queryType.changed', {
        interaction: 'click',
        queryType
      });
      setSelectedQueryType(queryType as QueryType);
      setChartQueries(undefined);
    }
  };

  const handleSetSelectedLatency = (latency: string): void => {
    setSelectedLatency(latency as SelectableLatency);
  };

  const getAllQueriesForTimePeriod = useGetQueryTypeForTimePeriod(
    selectedTimePeriod,
    'allQueries'
  );

  const getSlowQueriesForTimePeriod = useGetQueryTypeForTimePeriod(
    selectedTimePeriod,
    'slowQueries'
  );

  const getQueryErrorsForTimePeriod = useGetQueryTypeForTimePeriod(
    selectedTimePeriod,
    'errors'
  );

  const getLatencyCount = useGetQueryLatencyCount(selectedTimePeriod);

  useEffect(() => {
    const runAllQueriesForTimePeriod = async (): Promise<void> => {
      try {
        const [
          allQueries,
          slowQueries,
          latencyCount,
          queryErrors
        ]: Array<QueryResult> = await Promise.all([
          getAllQueriesForTimePeriod(),
          getSlowQueriesForTimePeriod(),
          getLatencyCount(),
          getQueryErrorsForTimePeriod()
        ]);
        const permissionCheck = checkPermissionsError({
          results: [allQueries, slowQueries, latencyCount, queryErrors]
        });

        if (permissionCheck.hasError) {
          setNecessaryGrants(permissionCheck.necessaryGrants);
          return;
        }

        const hasResultError =
          isResultError(allQueries) ||
          isResultError(slowQueries) ||
          isResultError(latencyCount) ||
          isResultError(queryErrors);

        if (hasResultError) {
          setHasChartError(true);
          return;
        }

        setHasChartError(false);

        setAllQueriesCount(
          String(
            allQueries.rows.reduce(
              (total: number, [, , queryCount]: Row): number => {
                return parseInt(queryCount as string, 10) + total;
              },
              0
            )
          )
        );

        const latencyIndex = getSelectedLatencyIndex(selectedLatency);
        setSlowQueriesCount(
          `${String(
            latencyCount.rows.reduce(
              (total: number, [queryCount]: Row): number => {
                const count = JSON.parse(queryCount as string) as Array<string>;
                const median =
                  count.length > 0 ? parseFloat(count[latencyIndex]) : 0;
                return median + total;
              },
              0
            )
          )} ms`
        );

        setErrorsCount(
          String(
            queryErrors.rows.reduce(
              (total: number, [, queryCount]: Row): number => {
                return parseInt(queryCount as string, 10) + total;
              },
              0
            )
          )
        );

        switch (selectedQueryType) {
          case 'allQueries': {
            setChartQueries(allQueries);
            break;
          }
          case 'slowQueries': {
            setChartQueries(slowQueries);
            break;
          }
          case 'errors': {
            setChartQueries(queryErrors);
            break;
          }
        }
      } catch (error) {
        setHasChartError(true);
      }
    };

    void runAllQueriesForTimePeriod();
  }, [selectedTimePeriod, selectedQueryType, selectedLatency]);
  const buttonGroupOptions = [
    {
      label: <div data-testid="all-queries-button">Query volume</div>,
      value: 'allQueries',
      disabled: false
    },
    {
      label: <div data-testid="slow-queries-button">Latency</div>,
      value: 'slowQueries',
      disabled: false
    },
    {
      label: <div data-testid="errors-queries-button">Errors</div>,
      value: 'errors',
      disabled: errorsCount === '0'
    }
  ];

  return (
    <>
      <Dialog open={necessaryGrants.length > 0}>
        <Dialog.Content title="Insufficient Grants">
          <Container orientation="vertical" gap="md">
            <Text>
              You do not have the minimum required GRANTs necessary to use this
              feature. Please contact your system administrator and ask for the
              following GRANTs:
            </Text>
            {necessaryGrants.map((grant, idx) => {
              return (
                <CodeBlock
                  key={`grantBlock${idx}`}
                  language="sql"
                  wrapLines={true}
                  showLineNumbers={false}
                >
                  {grant}
                </CodeBlock>
              );
            })}
          </Container>
          <Spacer />
          <Separator size="xs" />
          <Spacer />
          <Container justifyContent="end" gap="md">
            <Button
              label="Contact support"
              onClick={() => {
                navigateTo(routes.support({ serviceId: currentInstanceId }));
              }}
              type="secondary"
            />
            {currentInstanceId && (
              <Button
                label="Go to SQL Console"
                onClick={() => {
                  navigateTo(routes.console({ serviceId: currentInstanceId }));
                }}
                data-testid="maintenance-window-info-close-button"
                type="primary"
              />
            )}
          </Container>
        </Dialog.Content>
      </Dialog>
      <Container orientation="vertical" gap="xs" padding="lg">
        <Container justifyContent="space-evenly" gap="lg">
          <BigStat
            fillWidth={true}
            label={`Query volume (${timePeriodLabel})`}
            size="sm"
            spacing="lg"
            title={
              <QueryCount
                allQueriesCount={allQueriesCount}
                queryType="allQueries"
                statCount={allQueriesCount}
              />
            }
          />

          <BigStat
            fillWidth={true}
            label={`${selectedLatency} latency (${timePeriodLabel})`}
            size="sm"
            spacing="lg"
            title={slowQueriesCount}
          />

          <BigStat
            fillWidth={true}
            label={`Errors (${timePeriodLabel})`}
            size="sm"
            spacing="lg"
            title={
              <QueryCount
                allQueriesCount={allQueriesCount}
                queryType="errors"
                statCount={errorsCount}
              />
            }
          />
        </Container>
        <Spacer size="lg" />
        <Container
          orientation="horizontal"
          fillWidth={true}
          justifyContent="space-between"
        >
          <ButtonGroup
            selected={selectedQueryType}
            onClick={handleSelectQueryType}
            options={buttonGroupOptions}
          />
          <Container
            orientation="horizontal"
            justifyContent="end"
            maxWidth="65%"
          >
            {selectedQueryType === 'slowQueries' && (
              <Container maxWidth="175px">
                <Select
                  onSelect={handleSetSelectedLatency}
                  value={selectedLatency}
                >
                  <Select.Item value="p50">p50</Select.Item>
                  <Select.Item value="p90">p90</Select.Item>
                  <Select.Item value="p99">p99</Select.Item>
                </Select>
              </Container>
            )}
            <TimeSelect
              onSelect={handleTimeChange}
              selectedPeriod={selectedTimePeriod}
            />
          </Container>
        </Container>
        <QueryInsightsChart
          chartDeployment="main"
          isLoading={chartQueries === undefined}
          hasError={hasChartError}
          queries={chartQueries}
          queryType={selectedQueryType}
          timePeriodLabel={timePeriodLabel as TimePeriodLabel}
          selectedLatency={selectedLatency}
        />
        <QueryInsightsTable timePeriod={selectedTimePeriod} />
      </Container>
    </>
  );
};

export const getSelectedLatencyIndex = (
  selectedLatency?: SelectableLatency
): number => {
  switch (selectedLatency) {
    case 'p50': {
      return 0;
    }
    case 'p90': {
      return 1;
    }
    case 'p99': {
      return 2;
    }
    default: {
      return 0;
    }
  }
};

export const queryCountSum = (total: number, [, , queryCount]: Row): number => {
  return parseInt(queryCount as string, 10) + total;
};

interface QueryCountProps {
  allQueriesCount: string;
  queryType: QueryType;
  statCount: string;
}

export const QueryCount = ({
  allQueriesCount,
  queryType,
  statCount
}: QueryCountProps): ReactNode => {
  if (statCount === '-') {
    return '-';
  }

  if (queryType === 'allQueries') {
    return formatNumber(parseInt(statCount, 10));
  }

  const queriesCount = parseInt(allQueriesCount, 10);
  return (
    <>
      {formatNumber(parseInt(statCount, 10))}
      {queriesCount !== 0 && (
        <>
          {' '}
          (
          {formatNumber(parseInt(statCount, 10) / queriesCount, {
            style: 'percent',
            maximumFractionDigits: 2
          })}
          )
        </>
      )}
    </>
  );
};
