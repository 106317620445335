export class LineReader {
  reader: ReadableStreamReader<Uint8Array>;
  decoder = new TextDecoder();
  bufferedLines: string[] = [];
  startTime = Date.now();

  constructor(reader: ReadableStreamReader<Uint8Array>) {
    this.reader = reader;
  }

  private async loadChunk(): Promise<boolean> {
    const readResult = await (this.reader as ReadableStreamDefaultReader<Uint8Array>).read();
    if (readResult.done) {
      return false;
    } else {
      const readStr = this.decoder.decode(readResult.value);
      const newLines = readStr.split(/\r\n|\r|\n/);
      if (this.bufferedLines.length === 0) {
        this.bufferedLines = newLines;
      } else {
        this.bufferedLines[this.bufferedLines.length - 1] += newLines[0];
        for (let i = 1; i < newLines.length; ++i) {
          this.bufferedLines.push(newLines[i]);
        }
      }
    }
    return true;
  }

  async getLine(): Promise<string | undefined> {
    while (this.bufferedLines.length < 2) {
      const moreLines = await this.loadChunk();

      if (!moreLines) {
        if (this.bufferedLines.length === 1) {
          break;
        } else {
          return undefined;
        }
      }
    }

    const line = this.bufferedLines[0];
    this.bufferedLines.shift();
    return line;
  }
}
