export type PrettyPrintJsonResult = [string, 'json' | 'plaintext'];

/**
 * Format a string for the cell inspector output.
 * 1. If value is a valid JSON object, return a formatted JSON string with 2 spaces indentation, marked as 'json'.
 * 2. If value is a primitive value (e.g., numeric (including BigInt), boolean, string literals),
 *    return the original string marked as 'plaintext'.
 * 3. If value is not a valid JSON, return the original string marked as 'plaintext'.
 *
 * Examples:
 *  prettyPrintJson('{"key":"value"}') => [`{\n  "key": "value"\n}`, 'json']
 *  prettyPrintJson('120208881066470507') => ['120208881066470507', 'plaintext']
 *  prettyPrintJson('{"key": 123}') => [`{\n  "key": 123\n}`, 'json']
 *  prettyPrintJson('{key: 123}') => ['{key: 123}', 'plaintext']
 *  prettyPrintJson('Hello, world!') => ['Hello, world!', 'plaintext']
 *  prettyPrintJson('[1,2,3,4]') => `[\n  1,\n  2,\n  3,\n  4\n]`, 'json']
 *  prettyPrintJson('[]') => ['[]', 'json']
 *
 * @param {string} value - The input string to be pretty printed as JSON.
 * @returns {PrettyPrintJsonResult} A tuple containing the pretty printed string or the original input,
 * and a string indicating the type of the output ('json' or 'plaintext').
 */
export const prettyPrintJson = (value: string): PrettyPrintJsonResult => {
  try {
    // Try to parse the input as JSON
    const parsedValue: unknown = JSON.parse(value);

    // Check if the parsed value is a valid JSON
    if (typeof parsedValue === 'object' && parsedValue !== null) {
      // Format the JSON string with 2 spaces indentation
      return [JSON.stringify(parsedValue, null, 2), 'json'];
    } else {
      // It's a primitive type like number or string, return the original string as a plaintext
      return [value, 'plaintext'];
    }
  } catch (e) {
    // Not a valid JSON, return the original string as a plaintext
    return [value, 'plaintext'];
  }
};
