import { InstanceBackup } from '@cp/common/protocol/Backup';
import { useCallback, useEffect, useState } from 'react';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'api/src/lib/errorHandling';
import { BackupActionInput } from 'src/backups/backupsApiClient';
import { useBackupsStateManager } from 'src/backups/backupsState';
import {
  generateClickHouseDbPassword,
  makeDoubleSha1HashBrowser,
  makeSha256Base64Browser
} from 'src/instance/instance';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import { createToast } from '@clickhouse/click-ui';

export interface InstanceBackupService {
  error: string | null;
  loading: boolean;
  backups: Array<InstanceBackup>;
  listBackups: ({ organizationId, instanceId }: BackupActionInput) => Promise<Array<InstanceBackup>>;
  restoreInstance: ({ name, backupId, instanceId, organizationId }: RestoreInstanceInput) => Promise<void>;
  isRestoreLoading: boolean;
}

export interface RestoreInstanceInput {
  name: string;
  backupId: string;
  instanceId: string;
  organizationId: string;
}

export const useBackups = (): InstanceBackupService => {
  const { backups, loading, setBackups, setLoading, backupsError, setBackupsError } = useBackupsStateManager();
  const currentInstance = useCurrentInstanceOrThrow();
  const [isRestoreLoading, setIsRestoreLoading] = useState(false);
  const apiClient = useApiClient();
  const { setInstancePassword } = useInstanceStateManager();

  const restoreInstance = useCallback(
    async ({ name, organizationId, instanceId, backupId }: RestoreInstanceInput): Promise<void> => {
      try {
        setIsRestoreLoading(true);
        const password = generateClickHouseDbPassword();
        const passwordHash = await makeSha256Base64Browser(password);
        const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
        const { instanceId: newInstanceId } = await apiClient.backups.restore({
          backupId,
          doubleSha1Password,
          instanceId,
          instanceName: name,
          organizationId,
          passwordHash
        });
        setInstancePassword(newInstanceId, password);
      } catch (e) {
        let errorDescription = 'Error while restoring backup';
        const errorMsg = getErrorMessageFromError(e);
        console.log(errorMsg);
        if (errorMsg === 'DUPLICATE_INSTANCE_NAME') {
          errorDescription = 'A service with this name already exists, please choose another';
        }
        if (errorMsg === 'INSTANCE_LIMIT_REACHED') {
          errorDescription = "You've reached the maximum amount of services allowed per organization";
        }
        createToast({
          title: 'Error restoring backup',
          type: 'danger',
          description: errorDescription
        });
        throw e;
      } finally {
        setIsRestoreLoading(false);
      }
    },
    [apiClient.backups, setInstancePassword]
  );

  const listBackups = useCallback(
    async ({ organizationId, instanceId }: BackupActionInput): Promise<Array<InstanceBackup>> => {
      setLoading(true);
      setBackupsError(null);
      try {
        const backups = await apiClient.backups.list({
          organizationId,
          instanceId
        });

        setBackups(backups);
        return backups;
      } catch (error) {
        console.error(error);
        setBackupsError(errorMessage(error));
        return [];
      } finally {
        setLoading(false);
      }
    },
    [apiClient.backups, setBackups, setLoading, setBackupsError]
  );

  useEffect(() => {
    void listBackups({
      organizationId: currentInstance.organizationId,
      instanceId: currentInstance.id
    });
  }, [listBackups, currentInstance]);

  return {
    backups,
    loading,
    listBackups,
    restoreInstance,
    error: backupsError,
    isRestoreLoading
  };
};
