import { ClickPipeKafkaImport } from 'shared/src/dataLoading';

export function getKafkaConfigWithCredentials<T extends ClickPipeKafkaImport>(model: T) {
  return {
    type: model.type,
    brokers: model.data.brokers ?? '',
    group: model.data.group ?? '',
    credentials: model.data.credentials ?? {
      username: '',
      password: ''
    },
    sslCertificate: model.data.sslCertificate ?? '',
    authentication: model.data.authentication ?? 'basic',
    schemaRegistry: getSchemaRegistryWithCredentials(model)
  };
}

export function getSchemaRegistryWithCredentials(model: ClickPipeKafkaImport) {
  return {
    enabled: model.data.schemaRegistryEnabled ?? false,
    url: model.data.registryUrl,
    credentials: {
      username: model.data.registryKey,
      password: model.data.registrySecret
    }
  };
}
