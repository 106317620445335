import { Button, Container, Panel } from '@clickhouse/click-ui';
import { ReactElement, useCallback, useRef } from 'react';
import {
  AccordionWrapper,
  CommonAccordionProps
} from 'src/components/GettingStarted/AccordionWrapper';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import NetworkIdlingPanel from 'src/components/NetworkIdlingPanel/NetworkIdlingPanel';
import { NetworkAccessListPanel } from 'src/components/NetworkAccessListPanel/NetworkAccessListPanel';
import { Galaxy } from 'galaxy';

const NetworkAccordion = ({
  markAsCompleted,
  showProgress,
  serviceId,
  ...props
}: CommonAccordionProps & { serviceId: string | null }): ReactElement => {
  const isCompleted = useRef({
    idlingOption: false,
    accessList: false
  });

  const currentInstance = useCurrentInstanceOrThrow();
  const onSubmitClick = useCallback(
    (type: 'idlingOption' | 'accessList') => {
      Galaxy.galaxy().track(
        `gettingStarted.networkAccordion.${type}SubmitButtonClicked`,
        {
          interaction: 'click',
          from: showProgress ? 'main' : 'help'
        }
      );
      isCompleted.current[type] = true;
      if (
        isCompleted.current.accessList === true &&
        isCompleted.current.idlingOption === true &&
        typeof markAsCompleted === 'function'
      ) {
        markAsCompleted();
      }
    },
    [markAsCompleted, showProgress]
  );

  const viewSettings = (): void => {
    Galaxy.galaxy().track(
      'gettingStarted.networkAccordion.viewServiceClicked',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    if (serviceId) {
      navigateTo(routes.serviceSettings({ serviceId }));
    }
  };

  return (
    <AccordionWrapper
      title="Configure idling and IP filtering"
      value="idlingOptions"
      {...props}
    >
      <Panel padding="sm">
        <Container gap="lg" orientation="vertical" isResponsive={false}>
          <NetworkIdlingPanel
            instance={currentInstance}
            onSubmitClick={onSubmitClick}
          />
          <NetworkAccessListPanel
            instanceId={currentInstance.id}
            onSubmitClick={onSubmitClick}
          />
        </Container>
        <Container isResponsive={false} gap="md">
          <Button onClick={viewSettings}>View all settings</Button>
          {showProgress && !props.isCompleted && (
            <Button iconLeft="check" type="secondary" onClick={markAsCompleted}>
              Mark as completed
            </Button>
          )}
        </Container>
      </Panel>
    </AccordionWrapper>
  );
};

export default NetworkAccordion;
