import {
  MILLIS_PER_SECOND,
  SECONDS_PER_MINUTE
} from '@cp/common/utils/DateTimeUtils';
import { ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { ClickhouseLoading } from 'src/components/primitives';
import {
  useCurrentInstance,
  useInstanceController
} from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { routes } from 'src/lib/routes';

const MILLIS_PER_MINUTE = SECONDS_PER_MINUTE * MILLIS_PER_SECOND;
const RequiresCurrentServiceGuard = (): ReactElement | null => {
  const currentInstance = useCurrentInstance();
  const { refreshUserDataFlag } = useInstanceController();
  const { isInstanceStateInitialized } = useInstanceStateManager();

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentInstance?.id) {
        if (currentInstance?.hasUserData) {
          clearInterval(interval);
        } else {
          void refreshUserDataFlag(
            currentInstance.id,
            currentInstance.organizationId
          );
        }
      }
    }, MILLIS_PER_MINUTE);

    return (): void => {
      clearInterval(interval);
    };
  }, [
    currentInstance?.hasUserData,
    currentInstance?.id,
    currentInstance?.organizationId,
    refreshUserDataFlag
  ]);

  if (!currentInstance?.id && isInstanceStateInitialized) {
    navigateTo(routes.services());
    return null;
  }

  if (!currentInstance?.id) {
    return <ClickhouseLoading isFullScreen />;
  }

  return <Outlet />;
};

export default RequiresCurrentServiceGuard;
