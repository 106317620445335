import { Fragment, ReactElement } from 'react';
import { Checkbox, Container, Select } from '@clickhouse/click-ui';
import styled from 'styled-components';
import { ReasonListType } from 'src/lib/questionnaire';

const SuboptionsContainer = styled(Container)``;
interface Props {
  options: ReasonListType;
  onCheckedChange: (label: string, checked: boolean, value?: string) => void;
}

export function QuestionnaireAnswerWithSuboptions({
  options,
  onCheckedChange: onCheckedChangeProp
}: Props): ReactElement {
  return (
    <>
      {options.map((o) => {
        const isSuboption = o.label.split(':').length > 1;
        if (isSuboption) {
          return null;
        }
        const suboptions = options.filter((so) =>
          so.label.startsWith(`${o.label}:`)
        );
        const selectedSuboption = suboptions.find((so) => so.checked);

        return (
          <Fragment key={o.label}>
            <SuboptionsContainer gap="md" orientation="vertical">
              <Checkbox
                label={o.label}
                checked={o.checked}
                onCheckedChange={(checked) =>
                  onCheckedChangeProp(o.label, checked === true)
                }
              />
              {suboptions.length > 0 && o.checked && (
                <Container maxWidth="50%" minWidth="300px">
                  <Select
                    value={selectedSuboption?.label}
                    onSelect={(value) => onCheckedChangeProp(value, true)}
                  >
                    {suboptions.map((so) => (
                      <Select.Item
                        key={so.label}
                        value={so.label}
                        label={so.label.split(':')[1]}
                      />
                    ))}
                  </Select>
                </Container>
              )}
            </SuboptionsContainer>
          </Fragment>
        );
      })}
    </>
  );
}
