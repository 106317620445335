import { useEffect, useRef } from 'react';
import CredentialsModalLayout from 'src/layout/CredentialsModalLayout';

import ServiceInitializer from 'src/lib/ServiceInitializer';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useCredentials } from 'src/state/connection';
import { useReloadPersistedTabs } from 'src/state/tabs';
import {
  useOrgIdFromServiceId,
  useSetOrgsWithServices
} from 'src/state/service';
import { FCChild } from 'types/commonTypes';
import { useParams } from 'src/lib/routes/useParams';
import { useLocation } from 'react-router-dom';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useSelectService } from 'src/state/service/selectService';

const ServiceInitializerProvider: FCChild = ({ children }) => {
  const api = useApiClient();
  const { serviceId } = useParams();
  const orgId = useOrgIdFromServiceId(serviceId);
  const { pathname } = useLocation();
  const hasReloadedTabsRef = useRef(false);
  const selectService = useSelectService();

  const currentInstanceId = useCurrentInstanceId();

  const setOrgsWithServices = useSetOrgsWithServices();
  const reloadPersistedTabs = useReloadPersistedTabs();
  const credentials = useCredentials();

  useEffect(() => {
    hasReloadedTabsRef.current = false;
  }, [currentInstanceId]);

  useEffect(() => {
    const serviceInitializer = new ServiceInitializer(api, setOrgsWithServices);

    serviceInitializer.initialize().catch(console.error);
  }, [currentInstanceId, api, setOrgsWithServices]);

  useEffect(() => {
    if (currentInstanceId) {
      return (): void => {
        hasReloadedTabsRef.current = false;
      };
    }
  }, [currentInstanceId]);

  useEffect(() => {
    if (
      credentials.connected &&
      currentInstanceId &&
      !pathname.includes('/imports') &&
      !pathname.includes('/queryInsights') &&
      !pathname.includes('/health') &&
      !hasReloadedTabsRef.current
    ) {
      selectService({ serviceId });
      hasReloadedTabsRef.current = true;
      reloadPersistedTabs().catch(console.error);
    }
  }, [currentInstanceId, pathname, credentials.connected]);

  if (!orgId) {
    return null;
  }

  return <CredentialsModalLayout>{children}</CredentialsModalLayout>;
};

export default ServiceInitializerProvider;
