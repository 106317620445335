import {
  Button,
  Container,
  Icon,
  SearchField,
  Spacer,
  Text,
  Alert
} from '@clickhouse/click-ui';
import { useEffect, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import ConfigureDatabases from 'src/components/ImportView/ClickPipesImportForm/ConfigureTables/configureDatabase';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import {
  ClickPipePostgresImport,
  PostgresImport
} from 'shared/src/dataLoading';
import SchemaBox from 'src/components/ImportView/ClickPipesImportForm/ConfigureTables/schemaBox';
import { getPostgresConfigWithCredentials } from 'src/lib/dataLoading/clickpipes/postgres';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import LoadingContainer from 'src/components/LoadingContainer/LoadingContainer';
import { Draft } from 'immer';

const ConfigureTables = () => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [schemasLoading, setSchemasLoading] = useState(false);
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipePostgresImport>();
  const serviceId = getCurrentServiceIdOrFail();
  const [schemas, setSchemas] = useState<string[]>([]);
  const [tableSearchQuery, setTableSearchQuery] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');

  const fetchSchemas = async () => {
    setSchemasLoading(true);
    const request = getPostgresConfigWithCredentials(importModel);
    const response = await api.listPostgresSchemas(request, serviceId);
    setSchemas(response.data.schemas);
    setSchemasLoading(false);
  };

  const validateTableSelectionsAndDatabase = async (): Promise<boolean> => {
    const data = importModel.data;
    if (!data.cdcSettings.targetClickhouseDatabase) {
      setValidationError('Please select or type in a ClickHouse database');
      return false;
    }
    if (
      data.cdcSettings.tableSelections &&
      data.cdcSettings.tableSelections.length > 0
    ) {
      // all tables should have a target table
      const tablesWithoutTarget = data.cdcSettings.tableSelections.filter(
        (table) => table.targetTable === ''
      );
      if (tablesWithoutTarget.length > 0) {
        setValidationError('Please select a target table for all tables');
        return false;
      }

      const request = getPostgresConfigWithCredentials(importModel);
      try {
        const selectedTablesValidity = await api.validatePostgresTables(
          {
            ...request,
            selectedTables: data.cdcSettings.tableSelections.map(
              (tableSelection) => {
                return {
                  schemaName: tableSelection.sourceSchemaName,
                  tableName: tableSelection.sourceTable,
                  selectedColumns: tableSelection.selectedColumns
                };
              }
            ),
            checkCreatePublication: data.cdcSettings.publicationName === ''
          },
          serviceId
        );
        if ('error' in selectedTablesValidity) {
          setValidationError(selectedTablesValidity.error);
          return false;
        }
      } catch (error) {
        if (error instanceof Error) setValidationError(error.message);
        else setValidationError('Something went wrong while validating tables');
        return false;
      }

      setValidationError('');
      return true;
    }
    setValidationError('Please select at least one table');
    return false;
  };

  const nextStep = async (): Promise<void> => {
    setLoading(true);
    const valid = await validateTableSelectionsAndDatabase();
    if (!valid) {
      setLoading(false);
      return;
    }
    updateImportModel<Draft<PostgresImport>>((model) => {
      model.data.step = 4;
    });
    setLoading(false);
  };

  const back = (): void => {
    updateImportModel((draft: Draft<ClickPipePostgresImport>) => {
      draft.data.step = 2;
    });
  };

  const refresh = async (): Promise<void> => {
    updateImportModel((draft: Draft<ClickPipePostgresImport>) => {
      draft.data.cdcSettings.tableSelections = [];
    });
    setSchemasLoading(true);
    await fetchSchemas();
    setSchemasLoading(false);
  };

  useEffect(() => {
    void fetchSchemas();
  }, [importModel.id, importModel.type, importModel.data.auth]);

  if (schemasLoading) {
    return (
      <LoadingContainer data-testid="postgres-clickpipes-loading-schemas" />
    );
  }
  return (
    <Container orientation="vertical" gap="md" maxWidth="100%">
      <ConfigureDatabases />

      <Spacer size="xs" />

      <Text color="default">
        Select and configure the Postgres tables that you want to replicate in
        ClickHouse. Tables are grouped by schema.
      </Text>

      <SearchField
        onChange={setTableSearchQuery}
        placeholder="Search tables"
        value={tableSearchQuery}
        data-testid="postgres-clickpipe-table-search"
      />

      <Container orientation="vertical" gap="lg">
        {schemas.map((schemaName, index) => (
          <SchemaBox
            key={`postgres-clickpipe-schema-${index}`}
            schemaName={schemaName}
            searchedTable={tableSearchQuery}
          />
        ))}
      </Container>
      <Spacer size="xs" />
      {validationError && (
        <Alert
          text={validationError}
          state="danger"
          size="medium"
          showIcon={false}
        />
      )}
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          <Icon name="arrow-left" /> Previous
        </Button>
        <Button
          type="secondary"
          onClick={refresh}
          loading={loading || schemasLoading}
          disabled={loading || schemasLoading}
          data-testid="cdc-settings-refresh"
        >
          <Icon name="refresh" /> Refresh
        </Button>
        <Button
          onClick={nextStep}
          loading={loading || schemasLoading}
          disabled={loading || schemasLoading}
          data-testid="postgres-clickpipe-cdc-table-picker-next-step"
        >
          Next <Icon name="arrow-right" />
        </Button>
      </Container>
    </Container>
  );
};
export default ConfigureTables;
