import { Badge, Container } from '@clickhouse/click-ui';
import { Instance } from '@cp/common/protocol/Instance';
import { ReactElement, useEffect, useMemo } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { SidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';
import { routes } from 'src/lib/routes';

interface GettingStartedSidebarItemProps {
  service: Instance;
  showBadge?: boolean;
  hasManagePermission: boolean;
  closeSidebar: () => void;
  removeGettingStarted: () => Promise<void>;
}
export const GettingStartedSidebarItem = ({
  service,
  closeSidebar,
  showBadge = false,
  removeGettingStarted,
  hasManagePermission
}: GettingStartedSidebarItemProps): ReactElement => {
  const totalCount = hasManagePermission ? 5 : 3;
  const completed: number = useMemo(() => {
    if (typeof service.gettingStarted === 'undefined') {
      return 0;
    }

    return Object.entries(service.gettingStarted).reduce(
      (total: number, [key, item]) => {
        if (typeof item === 'boolean') {
          if (hasManagePermission) {
            total = total + (item ? 1 : 0);
          } else if (!['idlingOptions', 'inviteMembers'].includes(key)) {
            total = total + (item ? 1 : 0);
          }
        }
        return total;
      },
      0
    );
  }, [hasManagePermission, service.gettingStarted]);

  useEffect(() => {
    if (showBadge && completed === totalCount) {
      void removeGettingStarted();
    }
  }, [completed, removeGettingStarted, showBadge, totalCount]);

  return (
    <SidebarItem
      type="main"
      icon={showBadge ? 'rocket' : undefined}
      label={
        <Container justifyContent="space-between" isResponsive={false}>
          <div>Getting started</div>
          {showBadge && <Badge text={`${completed}/${totalCount}`} size="sm" />}
        </Container>
      }
      path="/services/:serviceId/gettingStarted"
      data-testid="gettingStartedSidebarButton"
      onClick={() => {
        navigateTo(routes.gettingStarted({ serviceId: service.id }));
        closeSidebar();
      }}
    />
  );
};
