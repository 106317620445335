import { GetOrganizationBillingDetailsResponse } from '@cp/common/protocol/Billing';
import { createToast } from 'primitives';
import { useCallback, useEffect, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useCurrentOrganizationOrThrow, useCurrentOrgPaymentMethodCaptured } from 'src/organization/organizationState';

export interface OrganizationBillingDetails {
  getOrganizationBillingDetails: () => Promise<GetOrganizationBillingDetailsResponse>;
  organizationBillingDetails: GetOrganizationBillingDetailsResponse;
  isLoading: boolean;
}

export const useOrganizationBillingDetails = (): OrganizationBillingDetails => {
  const apiClient = useApiClient();
  const organizationId = useCurrentOrganizationOrThrow().id;
  const initialBillingDetails: GetOrganizationBillingDetailsResponse = {
    invoices: [],
    billUsageStatements: [],
    creditBalances: [],
    billingContact: '',
    canUpdatePaymentMethod: false
  };
  const [organizationBillingDetails, setOrganizationBillingDetails] = useState(initialBillingDetails);
  const [isLoading, setIsLoading] = useState(true);
  const paymentMethodCaptured = useCurrentOrgPaymentMethodCaptured();

  const getOrganizationBillingDetails = useCallback(async (): Promise<GetOrganizationBillingDetailsResponse> => {
    try {
      setIsLoading(true);
      const billingDetails = await apiClient.billing.getOrganizationBillingDetails(organizationId);
      setOrganizationBillingDetails(billingDetails);
      return billingDetails;
    } catch (e) {
      createToast('Unable to get billing details', 'alert', errorMessage(e));
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, [apiClient.billing, organizationId, setOrganizationBillingDetails]);

  useEffect(() => {
    void getOrganizationBillingDetails().catch(console.error);
  }, [organizationId, paymentMethodCaptured, getOrganizationBillingDetails]);

  return {
    getOrganizationBillingDetails,
    organizationBillingDetails,
    isLoading
  };
};
