import {
  Button,
  Container,
  Flyout,
  Label,
  RadioGroup,
  Separator,
  Spacer,
  Text,
  TextAreaField,
  TextField,
  Title
} from '@clickhouse/click-ui';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { PrivateEndpointDocsLink } from 'src/pages/SettingsPage/PrivateEndpointDocsLink';
import {
  InstanceCloudProvider,
  cloudProvidersAllowingPrivateEndpoints,
  getRegionsForCloudProvider
} from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { RegionId } from '@cp/common/protocol/Region';
import config from 'src/lib/config';
import { RegionSelector } from 'src/components/RegionSelector';
import { EndpointIdLink } from 'src/components/PrivateEndpointIdLink';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import { useInstancePrivateEndpointConfig } from 'src/instance/useInstancePrivateEndpointConfig';
import { OrganizationPrivateEndpointSupportingInformation } from 'src/components/PrivateEndpointSupportingInfo';

export interface OrganizationPrivateEndpointFlyoutProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onCreateEndpoint: (
    cloudProvider: InstanceCloudProvider,
    region: RegionId,
    id: string,
    description: string
  ) => void;
}

export function OrganizationPrivateEndpointFlyout({
  open,
  onOpenChange,
  onCreateEndpoint
}: OrganizationPrivateEndpointFlyoutProps): ReactElement {
  const [cloudProvider, setCloudProvider] =
    useState<InstanceCloudProvider>('aws');
  const regions = useMemo(
    () => getRegionsForCloudProvider(config.env, cloudProvider),
    [cloudProvider]
  );

  const [newEndpointId, setNewEndpointId] = useState('');
  const [newEndpointDescription, setNewEndpointDescription] = useState('');
  const [regionId, setRegionId] = useState(() => regions[0].id);

  useEffect(() => {
    if (open) {
      // clear form when it is opened
      setNewEndpointId('');
      setNewEndpointDescription('');
    }
  }, [open]);

  const createEndpoint = (): void => {
    onCreateEndpoint(
      cloudProvider,
      regionId,
      newEndpointId,
      newEndpointDescription
    );
  };

  // If selected region is not in available regions, choose a new one that is
  useEffect(() => {
    const selectedRegion = regions.find((region) => region.id === regionId);
    if (!selectedRegion) {
      setRegionId(regions[0].id);
    }
  }, [setRegionId, regions, regionId]);

  const instances = useCurrentOrganizationInstances();
  const regionInstance = instances.find(
    (member) => member.regionId === regionId
  );
  const endpointConfig = useInstancePrivateEndpointConfig(regionInstance);

  return (
    <Flyout open={open} onOpenChange={onOpenChange}>
      <Flyout.Content
        strategy="fixed"
        size="default"
        data-testid="organizationPrivateEndpointFlyout"
      >
        <Flyout.Header>
          <Container padding="none" gap="md" orientation="vertical">
            <Title type="h1" size="xl">
              Set up private endpoint
            </Title>
          </Container>
        </Flyout.Header>
        <Flyout.Body align="default">
          <Flyout.Element>
            <Text color="muted">
              {
                'For step-by-step directions on how to set up a private endpoint, see our docs. '
              }
            </Text>
            <RadioGroup
              value={cloudProvider}
              onValueChange={(newValue) => {
                const newCloudProvider =
                  cloudProvidersAllowingPrivateEndpoints.find(
                    (member) => member === newValue
                  );
                assertTruthy(newCloudProvider, 'Invalid cloud provider');
                setCloudProvider(newCloudProvider);
              }}
            >
              <RadioGroup.Item label="AWS" value="aws" />
              <RadioGroup.Item label="GCP" value="gcp" />
              <RadioGroup.Item label="Azure" value="azure" />
            </RadioGroup>
            <PrivateEndpointDocsLink cloudProvider={cloudProvider} />
            <RegionSelector
              regions={regions}
              selectedRegionId={regionId}
              onRegionChange={setRegionId}
            />
            <OrganizationPrivateEndpointSupportingInformation
              endpointServiceId={endpointConfig?.endpointServiceId}
            />
            <Separator size="sm" />
            <Container fillWidth orientation="vertical">
              <Label htmlFor="endpointId">
                <Container
                  fillWidth
                  justifyContent="space-between"
                  padding="none"
                >
                  <span>
                    {'Endpoint ID '}
                    <EndpointIdLink cloudProvider={cloudProvider} />
                  </span>
                </Container>
              </Label>
              <Spacer size="sm" />
              <TextField
                width="100%"
                id="endpointId"
                placeholder="Endpoint ID"
                value={newEndpointId}
                onChange={setNewEndpointId}
                data-testid="endpoint-id-input"
              />
            </Container>
            <TextAreaField
              label="Endpoint description"
              value={newEndpointDescription}
              onChange={setNewEndpointDescription}
              placeholder="Add a note to help you identify this endpoint."
              data-testid="endpoint-description-input"
              rows={3}
            />
          </Flyout.Element>
        </Flyout.Body>
        <Flyout.Footer>
          <Flyout.Close label="Cancel" />
          <Button disabled={!newEndpointId} onClick={createEndpoint}>
            Create endpoint
          </Button>
        </Flyout.Footer>
      </Flyout.Content>
    </Flyout>
  );
}
