import {
  Accordion,
  EllipsisContent,
  Icon,
  Panel,
  Text,
  Container
} from '@clickhouse/click-ui';
import {
  CloudProvider,
  InstanceCloudProvider
} from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import CopyButton from 'src/components/CopyButton';
import { useInstanceIamRole } from 'src/instance/instanceController';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { SubsectionTitle } from 'src/lib/pageElements';
import styled from 'styled-components';

interface InfoRowProps {
  title: string;
  value?: string;
  error?: Error;
  loading: boolean;
  dataTestId: string;
}

const Information = styled.div`
  border-radius: 4px;
  border: 1px solid
    ${({ theme }): string => theme.click.global.color.stroke.default};
  width: 100%;
`;

const InformationItem = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  padding: 7.5px 12px;
  border-bottom: 1px solid
    ${({ theme }): string => theme.click.global.color.stroke.default};
  &:last-of-type {
    border-bottom: none;
  }
`;

const InformationTitle = styled.div`
  flex: 0 0 180px;
`;

const InformationValue = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
`;

const CopyIcon = styled.div`
  flex: 0 0 32px;
`;

function InfoRow({
  title,
  value,
  loading,
  error,
  dataTestId
}: InfoRowProps): ReactElement {
  // prettier-ignore
  const displayValue = loading
    ? 'Loading...'
    : error
      ? errorMessage(error)
      : value ?? '';
  return (
    <InformationItem data-testid={dataTestId}>
      <InformationTitle data-testid="networkInformationItemName">
        <Text weight="semibold" size="md">
          {title}
        </Text>
      </InformationTitle>
      <InformationValue data-testid="networkInformationItemValue">
        <EllipsisContent>
          <Text size="md" className="fs-exclude">
            {displayValue}{' '}
          </Text>
        </EllipsisContent>
      </InformationValue>
      <CopyIcon data-testid="networkInformationItemCopy">
        {!error && !loading && <CopyButton text={displayValue} />}
      </CopyIcon>
    </InformationItem>
  );
}

export function NetworkSecurityInformationSection({
  cloudProvider
}: {
  cloudProvider: InstanceCloudProvider;
}): ReactElement | null {
  const { data: iamRole, isLoading, error } = useInstanceIamRole();

  const showIamRole = cloudProvider === 'aws';

  if (!showIamRole) {
    return null;
  }

  return (
    <Panel color="muted" width="100%" padding="lg">
      <Container gap="xs">
        <Icon name="secure" size="lg" />
        <SubsectionTitle>Network security information</SubsectionTitle>
      </Container>
      <Container orientation="vertical">
        <Accordion title="View service details" color="link">
          <Information>
            <InfoRow
              title="Service role ID (IAM)"
              value={iamRole}
              loading={isLoading}
              error={error}
              dataTestId="network-information-service-role"
            />
          </Information>
        </Accordion>
      </Container>
    </Panel>
  );
}
