import { GetAutoScalingLimitsRequest, GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import {
  BackfillMysqlPasswordRequest,
  ChangeInstanceNameRequest,
  ChangeIpAccessListRequest,
  CreateInstanceRequest,
  DeleteInstanceRequest,
  GetInstanceAutoScalingRequest,
  GetInstanceAutoScalingResponse,
  GetInstanceBackupConfigurationRequest,
  GetInstanceIamPrincipalRequest,
  GetInstanceIamPrincipalResponse,
  GetMysqlSettingsRequest,
  GetPrivateEndpointConfigRequest,
  GetPrivateEndpointConfigResponse,
  Instance,
  InstanceAutoScalingRequest,
  InstanceMysqlSettings,
  UpdateInstanceReleaseChannelRequest,
  ListInstancesRequest,
  ListInstancesResponse,
  ReleaseChannel,
  ResetInstancePasswordRequest,
  StartInstanceRequest,
  StopInstanceRequest,
  UpdateInstanceBackupConfigurationRequest,
  UpdateInstanceDbRoleMappingRequest,
  UpdateInstancePrivateEndpointsRequest,
  UpdateInstanceStateRequest,
  UpdateInstanceStateSimulatorRequest,
  UpdateMysqlSettingsRequest,
  UpgradeInstanceRequest,
  VerifyCustomerKeyConfigRequest,
  VerifyCustomerKeyConfigResponse,
  StartFullMaintenanceRequest,
  UpdateGettingStartedRequest,
  RefreshUserDataFlagRequest
} from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import { WithInstanceId } from '@cp/common/utils/ProtocolUtils';
import { ErrorResponseMixin } from '@cp/common/utils/ValidationUtils';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const instanceApiUrl = `${config.controlPlane.apiHost}/api/instance`;
const autoscalingApiUrl = `${config.controlPlane.apiHost}/api/autoScaling`;

export interface InstanceActionInput {
  instanceId: string;
  organizationId: string;
}

export interface StartFullMaintenanceInput extends InstanceActionInput {
  runAfterMaintenance: boolean;
}

type InstanceRequest =
  | CreateInstanceRequest
  | ChangeIpAccessListRequest
  | StopInstanceRequest
  | StartInstanceRequest
  | DeleteInstanceRequest
  | GetAutoScalingLimitsRequest
  | GetInstanceAutoScalingRequest
  | InstanceAutoScalingRequest
  | ListInstancesRequest
  | ResetInstancePasswordRequest
  | UpdateMysqlSettingsRequest
  | BackfillMysqlPasswordRequest
  | GetMysqlSettingsRequest
  | GetInstanceIamPrincipalRequest
  | ChangeInstanceNameRequest
  | UpgradeInstanceRequest
  | UpdateInstanceDbRoleMappingRequest
  | UpdateInstanceStateRequest
  | UpdateInstanceStateSimulatorRequest
  | UpdateInstancePrivateEndpointsRequest
  | UpdateGettingStartedRequest
  | VerifyCustomerKeyConfigRequest
  | GetInstanceBackupConfigurationRequest
  | UpdateInstanceBackupConfigurationRequest
  | GetPrivateEndpointConfigRequest
  | UpdateInstanceReleaseChannelRequest
  | RefreshUserDataFlagRequest
  | StartFullMaintenanceRequest;

export interface GetAutoScalingLimitsInput {
  regionId: RegionId;
}

export interface UpdateAutoScalingInput {
  organizationId: string;
  instanceId: string;
  enableIdleScaling?: boolean;
  idleTimeoutMinutes: number;
  minAutoScalingTotalMemory?: number;
  maxAutoScalingTotalMemory?: number;
  minReplicas?: number;
  maxReplicas?: number;
}

export interface BackupConfiguration {
  defaultBackupPeriod: number;
  defaultBackupRetentionPeriod: number;
  customBackupPeriod?: number;
  customBackupStartTime?: string;
  customBackupRetentionPeriod?: number;
}

export type CreateInstanceInput = Omit<CreateInstanceRequest, 'rpcAction'>;

export type InstancePrivateEndpointConfig = GetPrivateEndpointConfigResponse;

export class InstanceApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: InstanceRequest, url = instanceApiUrl): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async create({
    name,
    organizationId,
    regionId,
    ipAccessList,
    instanceTier,
    /** Hashed password. Uses makeSha256HexString(). Must be compatible with DP. */
    passwordHash,
    /** SHA1 hashed password. Uses makeDoubleSha1(). Must be compatible with DP. */
    doubleSha1Password,
    autoscalingParams,
    customerManagedEncryption,
    privatePreviewTermsChecked,
    byocId
  }: CreateInstanceInput): Promise<string> {
    const request: CreateInstanceRequest = {
      rpcAction: 'create',
      organizationId,
      name,
      regionId,
      ipAccessList,
      instanceTier,
      passwordHash,
      doubleSha1Password,
      autoscalingParams,
      customerManagedEncryption,
      privatePreviewTermsChecked,
      byocId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }

    return ((await response.json()) as unknown as WithInstanceId).instanceId;
  }

  async stop({ organizationId, instanceId }: InstanceActionInput): Promise<void> {
    const request: StopInstanceRequest = {
      rpcAction: 'stop',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while stopping the service');
    }
  }

  async startFullMaintenance({
    organizationId,
    instanceId,
    runAfterMaintenance
  }: StartFullMaintenanceInput): Promise<void> {
    const request: StartFullMaintenanceRequest = {
      rpcAction: 'startFullMaintenance',
      organizationId,
      instanceId,
      runAfterMaintenance
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while starting full maintenance');
    }
  }

  async start({ organizationId, instanceId }: InstanceActionInput): Promise<void> {
    const request: StartInstanceRequest = {
      rpcAction: 'start',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while starting the service');
    }
  }

  async delete({ organizationId, instanceId }: InstanceActionInput): Promise<void> {
    const request: DeleteInstanceRequest = {
      rpcAction: 'delete',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while deleting the service');
    }
  }

  async upgrade({ organizationId, instanceId }: InstanceActionInput): Promise<void> {
    const request: UpgradeInstanceRequest = {
      rpcAction: 'upgrade',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }
  }

  async list({ organizationId }: { organizationId: string }): Promise<Array<Instance>> {
    const request: ListInstancesRequest = {
      rpcAction: 'list',
      organizationId
    };

    const response = await this.rpcRequest(request);

    if (response.ok) {
      const instancesResponse = (await response.json()) as unknown as ListInstancesResponse;
      return instancesResponse.instances;
    } else {
      throw new Error('Could not fetch instances');
    }
  }

  async saveAutoScalingSettings({
    organizationId,
    instanceId,
    enableIdleScaling,
    idleTimeoutMinutes,
    minAutoScalingTotalMemory,
    maxAutoScalingTotalMemory,
    minReplicas,
    maxReplicas
  }: UpdateAutoScalingInput): Promise<void> {
    const request: InstanceAutoScalingRequest = {
      rpcAction: 'updateAutoScaling',
      organizationId,
      instanceId,
      enableIdleScaling,
      idleTimeoutMinutes,
      minAutoScalingTotalMemory,
      maxAutoScalingTotalMemory,
      minReplicas,
      maxReplicas
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while saving the auto scaling settings');
    }
  }

  async getAutoScalingSettings({ instanceId }: { instanceId: string }): Promise<GetInstanceAutoScalingResponse> {
    const request: GetInstanceAutoScalingRequest = {
      rpcAction: 'getAutoScaling',
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (response.ok) {
      return (await response.json()) as unknown as GetInstanceAutoScalingResponse;
    } else {
      throw new Error('Could not fetch instances');
    }
  }

  async getIamPrincipal({ organizationId, instanceId }: InstanceActionInput): Promise<string> {
    const request: GetInstanceIamPrincipalRequest = {
      rpcAction: 'getInstanceIamPrincipal',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while retrieving Iam principal for the service');
    }

    const data = (await response.json()) as GetInstanceIamPrincipalResponse;
    return data.iamPrincipal;
  }

  async getRegionAutoScalingLimits({ regionId }: { regionId: RegionId }): Promise<GetAutoScalingLimitsResponse> {
    const request: GetAutoScalingLimitsRequest = {
      rpcAction: 'getLimits',
      regionId
    };

    const response = await this.rpcRequest(request, autoscalingApiUrl);

    if (response.ok) {
      const autoScalingLimitsResponse = (await response.json()) as unknown as GetAutoScalingLimitsResponse;
      return autoScalingLimitsResponse;
    } else {
      throw new Error('Could not fetch instances');
    }
  }

  async updateIpAccessList({
    ipAccessList,
    organizationId,
    instanceId
  }: Omit<ChangeIpAccessListRequest, 'rpcAction'>): Promise<void> {
    const request: ChangeIpAccessListRequest = {
      rpcAction: 'updateIpAccessList',
      ipAccessList,
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while updating the IP access list');
    }
  }

  async resetInstancePassword({
    passwordHash,
    doubleSha1Password,
    instanceId,
    organizationId
  }: Omit<ResetInstancePasswordRequest, 'rpcAction'>): Promise<void> {
    const request: ResetInstancePasswordRequest = {
      rpcAction: 'resetPassword',
      passwordHash,
      doubleSha1Password,
      instanceId,
      organizationId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while resetting the service password');
    }
  }

  async rename({
    instanceId,
    organizationId,
    name
  }: {
    instanceId: string;
    organizationId: string;
    name: string;
  }): Promise<void> {
    const request: ChangeInstanceNameRequest = {
      rpcAction: 'rename',
      instanceId,
      organizationId,
      name
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }
  }

  async updateMysqlSettings({
    enabled,
    instanceId,
    organizationId
  }: Omit<UpdateMysqlSettingsRequest, 'rpcAction'>): Promise<void> {
    const request: UpdateMysqlSettingsRequest = {
      rpcAction: 'updateMysqlSettings',
      organizationId,
      instanceId,
      enabled
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      throw new Error('Could not update MySQL settings');
    }
  }

  async backfillMysqlPassword({
    doubleSha1Password,
    sha256Password,
    instanceId,
    organizationId
  }: Omit<BackfillMysqlPasswordRequest, 'rpcAction'>): Promise<void> {
    const request: BackfillMysqlPasswordRequest = {
      rpcAction: 'backfillMysqlPassword',
      organizationId,
      instanceId,
      doubleSha1Password,
      sha256Password
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      throw new Error('Could not update MySQL password');
    }
  }

  async getMysqlSettings({
    instanceId,
    organizationId
  }: Omit<GetMysqlSettingsRequest, 'rpcAction'>): Promise<InstanceMysqlSettings> {
    const request: GetMysqlSettingsRequest = {
      rpcAction: 'getMysqlSettings',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (response.ok) {
      const instancesResponse = (await response.json()) as unknown as InstanceMysqlSettings;
      return instancesResponse;
    } else {
      throw new Error('Could not fetch MySQL settings');
    }
  }

  async updateInstanceDbRoleMapping(
    requestProps: Omit<UpdateInstanceDbRoleMappingRequest, 'rpcAction'>
  ): Promise<void> {
    const request: UpdateInstanceDbRoleMappingRequest = {
      rpcAction: 'updateInstanceDbRoleMapping',
      ...requestProps
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      throw new Error('Could not update database role mapping');
    }
  }

  async updateServiceState({ instanceId, state }: Omit<UpdateInstanceStateRequest, 'rpcAction'>): Promise<void> {
    const request: UpdateInstanceStateRequest = {
      instanceId,
      state,
      rpcAction: 'updateInstanceState'
    };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error(`Could not update instance ${instanceId} state to ${state}`);
    }
  }

  async updateServiceStateSimulator({
    instanceId,
    state
  }: Omit<UpdateInstanceStateSimulatorRequest, 'rpcAction'>): Promise<void> {
    const request: UpdateInstanceStateSimulatorRequest = {
      instanceId,
      state,
      rpcAction: 'updateInstanceStateSimulator'
    };
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error(`Could not update instance ${instanceId} state to ${state}`);
    }
  }

  async updatePrivateEndpointIds({
    privateEndpointIds,
    organizationId,
    instanceId
  }: Omit<UpdateInstancePrivateEndpointsRequest, 'rpcAction'>): Promise<void> {
    const request: UpdateInstancePrivateEndpointsRequest = {
      rpcAction: 'updatePrivateEndpoints',
      privateEndpointIds,
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Could not update instance private endpoints');
    }
  }

  async updateGettingStarted({
    gettingStarted,
    instanceId
  }: Omit<UpdateGettingStartedRequest, 'rpcAction'>): Promise<void> {
    const request: UpdateGettingStartedRequest = {
      rpcAction: 'updateGettingStarted',
      gettingStarted,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Could not update getting started steps');
    }
  }

  async verifyCustomerKeyConfig({
    organizationId,
    customerManagedEncryption
  }: Omit<VerifyCustomerKeyConfigRequest, 'rpcAction'>): Promise<VerifyCustomerKeyConfigResponse> {
    const request: VerifyCustomerKeyConfigRequest = {
      rpcAction: 'verifyCustomerKeyConfig',
      organizationId,
      customerManagedEncryption
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }

    return (await response.json()) as VerifyCustomerKeyConfigResponse;
  }

  async getBackupConfiguration(organizationId: string, instanceId: string): Promise<BackupConfiguration> {
    const request: GetInstanceBackupConfigurationRequest = {
      rpcAction: 'getInstanceBackupConfiguration',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (response.ok) {
      return (await response.json()) as BackupConfiguration;
    } else {
      const error = (await response.json()) as Partial<ErrorResponseMixin>;
      const message = `Failed to fetch backup configuration for instance ${instanceId}: ${error?.message ?? ''} ${
        error?.details ?? ''
      }`;
      throw new Error(message);
    }
  }

  async updateBackupConfiguration(
    organizationId: string,
    instanceId: string,
    backupConfiguration: Omit<BackupConfiguration, 'defaultBackupPeriod' | 'defaultBackupRetentionPeriod'>
  ): Promise<void> {
    const request: UpdateInstanceBackupConfigurationRequest = {
      rpcAction: 'updateInstanceBackupConfiguration',
      organizationId,
      instanceId,
      customBackupPeriod: backupConfiguration.customBackupPeriod,
      customBackupStartTime: backupConfiguration.customBackupStartTime,
      customBackupRetentionPeriod: backupConfiguration.customBackupRetentionPeriod
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as Partial<ErrorResponseMixin>;
      const message = `Failed to update backup configuration for instance ${instanceId}: ${error?.message ?? ''} ${
        error?.details ?? ''
      }`;
      throw new Error(message);
    }
  }

  async getPrivateEndpointConfig(organizationId: string, instanceId: string): Promise<InstancePrivateEndpointConfig> {
    const request: GetPrivateEndpointConfigRequest = {
      rpcAction: 'getPrivateEndpointConfig',
      organizationId,
      instanceId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error) ?? 'Unable to get private endpoint config');
    }

    return (await response.json()) as InstancePrivateEndpointConfig;
  }

  async updateReleaseChannel(
    organizationId: string,
    instanceId: string,
    releaseChannel: ReleaseChannel
  ): Promise<void> {
    const request: UpdateInstanceReleaseChannelRequest = {
      rpcAction: 'updateReleaseChannel',
      organizationId,
      instanceId,
      releaseChannel
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error) ?? `Unable to set ${releaseChannel} release channel`);
    }

    return;
  }
}
