import {
  Container,
  Icon,
  Link,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement, useEffect, useState } from 'react';
import DashboardView from 'src/components/Dashboards/DashboardView';
import {
  useFetchDashboard,
  useUpdateDashboardName
} from 'src/dashboard/dashboardController';
import { DashboardToolbar } from 'src/components/Dashboards/DashboadToolbar';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  useEditingDashboard,
  useSelectedDashboard,
  useSetEditingDashboard,
  useSetSelectedDashboard,
  useUpdateDashboard
} from 'src/state/dashboard';
import styled from 'styled-components';
import { DashboardOutletContext } from 'src/components/Dashboards/DashboardLayout';
import { Loading } from 'src/components/Dashboards/Loading';
import { Dashboard } from 'shared/src/types/dashboard';
import { NewVisualizationButton } from 'src/components/Dashboards/NewVisualizationButton';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { fillMissingParameterConfigs } from 'src/lib/dashboard/utils';

const DashboardPageContainer = styled(Container)<{ $editable: boolean }>`
  position: relative;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  & > .editableBadge {
    display: none;

    position: absolute;
    top: 0px;
    // center using translate + left: https://stackoverflow.com/questions/8508275/how-to-center-a-position-absolute-element
    left: 50%;
    transform: translateX(-50%);
    padding: 0 0.5rem;

    background: ${({ theme }): string => theme.global.color.accent.default};
    color: ${({ theme }): string => theme.global.color.background.default};
    font-size: 0.75rem;
  }

  ${({ $editable, theme }): string =>
    $editable
      ? `
      border-color: ${theme.global.color.accent.default};

      & > .editableBadge {
        display: block;
      }
  `
      : ''}
`;

interface DashboardPageContentProps {
  editable: boolean;
  errorMessage: string;
  dashboard: Dashboard | null;
  isLoading: boolean;
  setEditable: (editing: boolean) => void;
}

const DashboardPageContent = ({
  editable,
  errorMessage,
  dashboard,
  isLoading,
  setEditable
}: DashboardPageContentProps): ReactElement => {
  const [migrated, setMigrated] = useState(false);
  const { loadingState, savedQueries } = useSavedQueries();
  const updateDashboard = useUpdateDashboard();

  // fill missing query configs and in the future handle migrations here
  useEffect(() => {
    if (!isLoading && dashboard && loadingState === 'done' && !migrated) {
      const newConfig = fillMissingParameterConfigs(
        dashboard.config,
        savedQueries
      );

      updateDashboard(dashboard.id, {
        config: newConfig
      });
      setMigrated(true);
    }
  }, [dashboard, isLoading, loadingState, migrated]);

  if (isLoading || !migrated) {
    return <Loading />;
  }

  if (errorMessage) {
    return (
      <DashboardPageContainer
        $editable={false}
        data-testid="dashboard-page"
        grow="1"
        maxHeight="45%"
        minWidth="100%"
        padding="lg"
        orientation="vertical"
      >
        <Container
          justifyContent="center"
          minHeight="100%"
          orientation="horizontal"
        >
          <Container
            fillHeight={false}
            fillWidth={false}
            gap="md"
            orientation="vertical"
          >
            {errorMessage}
          </Container>
        </Container>
      </DashboardPageContainer>
    );
  }

  if (!dashboard) {
    return (
      <DashboardPageContainer
        $editable={false}
        data-testid="dashboard-page"
        grow="1"
        maxHeight="45%"
        minWidth="100%"
        padding="lg"
        orientation="vertical"
      >
        <Container
          justifyContent="center"
          minHeight="100%"
          orientation="horizontal"
        >
          <Container
            fillHeight={false}
            fillWidth={false}
            gap="md"
            orientation="vertical"
          >
            We couldn't find that dashboard
          </Container>
        </Container>
      </DashboardPageContainer>
    );
  }

  if (!Object.entries(dashboard.config.dashboardObjects).length) {
    const handleAddNewVizualization = (): void => {
      setEditable(true);
    };

    return (
      <DashboardPageContainer
        $editable={editable}
        data-testid="dashboard-page"
        grow="1"
        minWidth="100%"
        padding="lg"
        orientation="vertical"
      >
        <div className="editableBadge">Editing</div>
        <Container
          justifyContent="center"
          minHeight="70%"
          orientation="horizontal"
        >
          <Container
            fillHeight={false}
            fillWidth={false}
            gap="md"
            orientation="vertical"
          >
            <Container justifyContent="center" orientation="horizontal">
              <Icon name="bar-chart" size="xxl" />
            </Container>
            <Container justifyContent="center" orientation="horizontal">
              <Title type="h3" size="xl">
                Create your first vizualization
              </Title>
            </Container>
            <Container justifyContent="center" orientation="horizontal">
              <Container maxWidth="60%">
                <Text align="center">
                  Visualize your data using dashboards. Add charts, text, and
                  tables to help identify patterns and display results.{' '}
                  <Link href="" target="_blank" rel="noopener noreferrer">
                    Read more.
                  </Link>
                </Text>
              </Container>
            </Container>
            <Spacer size="xs" />
            <Container orientation="horizontal" justifyContent="center">
              <NewVisualizationButton
                dashboard={dashboard}
                onClick={handleAddNewVizualization}
                type="primary"
              />
            </Container>
          </Container>
        </Container>
      </DashboardPageContainer>
    );
  }

  return (
    <DashboardPageContainer
      $editable={editable}
      padding="lg"
      orientation="vertical"
      data-testid="dashboard-page"
      minWidth="100%"
      grow="1"
    >
      <div className="editableBadge">Editing</div>
      <DashboardView
        dashboard={dashboard}
        editable={editable}
        key={dashboard.id}
      />
    </DashboardPageContainer>
  );
};

export const DashboardPage = (): ReactElement => {
  const { dashboardId } = useParams();
  const fetchDashboard = useFetchDashboard();
  const setSelectedDashboard = useSetSelectedDashboard();
  const updateDashboardName = useUpdateDashboardName();

  const dashboard = useSelectedDashboard();
  const editable = useEditingDashboard(dashboard?.id ?? '');
  const setEditable = useSetEditingDashboard(dashboard?.id ?? '');
  const { serviceId } = useOutletContext<DashboardOutletContext>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dashboardId && !dashboard) {
      setIsLoading(true);
      fetchDashboard(dashboardId)
        .then((currentDashboard) => {
          setIsLoading(false);
          setSelectedDashboard(currentDashboard.id);
          setErrorMessage('');
        })
        .catch((error) => {
          setIsLoading(false);
          if (error instanceof Error) {
            setErrorMessage(error.message);
          }
          if (typeof error === 'string') {
            setErrorMessage(error);
          }
        });
    } else {
      setErrorMessage('');
    }
  }, [dashboard, dashboardId, fetchDashboard, setSelectedDashboard]);

  useEffect(() => {
    setSelectedDashboard(dashboardId as string);
  }, [dashboardId]);

  return (
    <>
      <DashboardToolbar
        dashboard={dashboard}
        editable={editable}
        serviceId={serviceId}
        setEditable={setEditable}
        updateDashboardName={updateDashboardName}
      />
      <DashboardPageContent
        editable={editable}
        errorMessage={errorMessage}
        dashboard={dashboard}
        isLoading={isLoading}
        setEditable={setEditable}
      />
    </>
  );
};
