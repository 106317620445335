// TODO:@pjhampton ~ refactor this function to drop String.substr
export function truncateMiddle(input: string, maxLength: number): string {
  if (input.length <= maxLength) {
    return input;
  }

  const separator = '...';
  const separatorLength = separator.length;
  const charactersToShow = maxLength - separatorLength;
  const frontCharacters = Math.ceil(charactersToShow / 2);
  const backCharacters = Math.floor(charactersToShow / 2);

  return input.substring(0, frontCharacters) + separator + input.substring(input.length - backCharacters);
}
