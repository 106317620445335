import { Container, Panel, Title, WarningAlert } from '@clickhouse/click-ui';
import { ReactElement, useEffect } from 'react';
import { DashboardChartConfig } from 'shared/src/types/dashboard';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { QueryResult } from 'src/lib/clickhouse/query';
import { useCredentials } from 'src/state/connection';
import {
  useDashboardObjectState,
  useDashboardOutputs,
  useUpdateDashboardObjectState
} from 'src/state/dashboard/dashboardState';
import {
  hydrateConfig,
  useDebouncedRunQuery
} from 'src/components/Dashboards/DashboardView/DashboardElement/utils';
import { Chart } from 'src/components/primitives';
import { VizhouseChart } from 'src/components/Dashboards/DashboardView/DashboardElement/VizhouseChart';

interface ChartConfig {
  chartConfig: Record<string, string>;
  parameters: Record<string, string>;
  queryId: string;
  title: string;
  subtitle: string;
  type: 'chart';
}

interface ChartElementProps {
  config: DashboardChartConfig;
  dashboardId: string;
  objectId: string;
}

export default function ChartElement({
  config,
  dashboardId,
  objectId
}: ChartElementProps): ReactElement {
  const outputs = useDashboardOutputs({
    dashboardId
  });
  const hydratedConfig = hydrateConfig(config, outputs) as ChartConfig;

  const { parameters, queryId, subtitle, title } = hydratedConfig;

  const credentials = useCredentials();
  const query = useSavedQuery(queryId);
  const state = useDashboardObjectState({
    dashboardId,
    objectId
  });
  const updateState = useUpdateDashboardObjectState({
    dashboardId,
    objectId
  });

  const data = state.data as QueryResult | undefined;

  const debouncedRunQuery = useDebouncedRunQuery();

  useEffect(() => {
    updateState({
      data: null
    });
    if (query) {
      updateState({
        loading: true
      });
      debouncedRunQuery(
        query.query,
        {
          ...credentials,
          database: query.database
        },
        {
          wakeService: true,
          variables: parameters
        }
      )
        .then((results) => {
          updateState({
            data: results,
            loading: false
          });
        })
        .catch(console.error);
    }
  }, [query?.query, JSON.stringify(parameters)]);

  return (
    <Panel
      hasBorder
      gap="none"
      alignItems="start"
      color="default"
      orientation="vertical"
      padding="md"
      radii="sm"
      height="100%"
    >
      <Title type="h2">{title}</Title>
      <Title type="h4">{subtitle}</Title>
      {!query ? (
        <Container>Please select a query</Container>
      ) : state.loading || !data ? (
        <Container fillHeight alignItems="center" justifyContent="center">
          Loading...
        </Container>
      ) : 'error' in data ? (
        <Container padding="sm">
          <WarningAlert text={data.error} data-testid="chart-error" />
        </Container>
      ) : (
        <>
          <Chart
            {...config.chartConfig}
            customMessage="Please configure the chart."
            data={data}
            dataHash={data}
            showNoResults={!state.loading}
            type={config.chartConfig.chartType || 'bar'}
          />
          <VizhouseChart chartConfig={config.chartConfig} sql={query?.query} />
        </>
      )}
    </Panel>
  );
}
