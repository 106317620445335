import { Chart, usePaginationState } from 'primitives';
import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react';

import { EditorInstance } from 'src/components/primitives/lib/MonacoSQLEditor';
import { useChartData } from 'src/lib/chart';
import { ChartConfig } from 'src/lib/chart/types';
import {
  useQuery,
  useQueryError,
  useQueryMessage,
  useQueryMetrics,
  useResultsView
} from 'src/lib/query/QueryState';
import { useSelectedTab, useUpdateTab } from 'src/state/tabs';
import { ResultsDisplayType, RightBarOption } from 'src/state/tabs/types';
import ResultToolbar from 'src/components/QueryView/Results/ResultToolbar';
import { Container, SuccessAlert, WarningAlert } from '@clickhouse/click-ui';
import QueryResults from 'src/components/QueryView/Results/QueryResults/QueryResults';
import RawQueryResults from 'src/components/QueryView/Results/RawQueryResults';
import {
  useQueryProgress,
  useQueryRawData
} from 'src/lib/query/QueryState/queryDetails';
import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { PaginationPropsType } from 'src/components/primitives/lib/Spreadsheet/usePaginationState';

export interface ResultsProps {
  chartConfig: ChartConfig;
  columnWidths: Record<string, number>;
  resultsDisplayType: ResultsDisplayType;
  name: string;
  runId: string | undefined;
  setDisplayType: React.Dispatch<ResultsDisplayType>;
  updateRightBar: (type: RightBarOption, value: string) => void;
  onNumRowsChange?: (numRows: number) => void;
  selectedColumn: number;
  selectedRow: number;
  tabId: string;
  paginationSelectorValue: number;
  currentPage?: number;
  editorRef?: MutableRefObject<EditorInstance | null>;
  status: RunningQueryStatusName;
}

export type VisibleStateType = { start: number; end: number };

//TODO: Export this from click-ui and clean them up
type SelectionPos = {
  column: number;
  row: number;
};

type ResultsLayoutProps = {
  numRows: number;
  children: React.ReactNode;
  resultsDisplayType: ResultsDisplayType;
  downloadResults: () => Promise<void>;
  metrics: ReturnType<typeof useQueryMetrics>;
  progress: ReturnType<typeof useQueryProgress>;
  search: string;
  setDisplayType: React.Dispatch<ResultsDisplayType>;
  setSearch: (search: string) => void;
  showFixQuery: boolean;
  rawData: string | undefined;
  status: RunningQueryStatusName;
  runId: string | undefined;
  paginationProps: PaginationPropsType;
};

function ResultsLayout({
  numRows,
  resultsDisplayType,
  downloadResults,
  metrics,
  progress,
  search,
  setDisplayType,
  setSearch,
  children,
  showFixQuery,
  rawData,
  status,
  runId,
  paginationProps
}: ResultsLayoutProps): ReactElement {
  const setSearchDispatcher = (value: string): void => {
    setSearch(value);
    paginationProps.onPaginationStateChange({ currentPage: 1 });
  };

  return (
    <Container
      orientation="vertical"
      overflow="auto"
      grow="1"
      wrap="nowrap"
      data-numrows={numRows}
    >
      <ResultToolbar
        resultsDisplayType={resultsDisplayType}
        downloadResults={downloadResults}
        metrics={metrics}
        progress={progress}
        search={search}
        setDisplayType={setDisplayType}
        setSearch={setSearchDispatcher}
        status={status}
        hasData={numRows > 0}
        runId={runId}
        showFixQuery={showFixQuery}
        rawData={rawData}
      />
      {children}
    </Container>
  );
}

const Results = React.memo(function Results(props: ResultsProps) {
  const {
    chartConfig,
    columnWidths,
    resultsDisplayType,
    name,
    runId,
    setDisplayType,
    updateRightBar,
    onNumRowsChange,
    selectedRow,
    selectedColumn,
    tabId,
    paginationSelectorValue,
    currentPage
  } = props;

  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 200 });
  const currentTab = useSelectedTab();
  const updateTab = useUpdateTab();

  if (!(currentTab?.type === 'query')) {
    throw new Error('currentTab is not a query tab');
  }
  const setSearch = useCallback(
    (search: string): void => {
      updateTab(currentTab.id, {
        search
      });
    },
    [currentTab.id, updateTab]
  );
  const search = currentTab.search;

  const {
    downloadResults,
    columns,
    getRow,
    numRows,
    status,
    filterFinished,
    rowIterator,
    totals
  } = useResultsView({
    queryId: runId ?? null,
    name,
    filter: search?.trim(),
    startRow: visibleRange.start,
    endRow: visibleRange.end
  });

  const chartData = useChartData({
    runId,
    returnData: resultsDisplayType === 'chart'
  });

  const error = useQueryError(runId);
  const message = useQueryMessage(runId);
  const metrics = useQueryMetrics(runId);
  const progress = useQueryProgress(runId);
  const rawData = useQueryRawData(runId);
  const hasRawData = !!rawData;

  const [pageSize, setPageSize] = useState(paginationSelectorValue);
  const { paginationProps } = usePaginationState(
    numRows,
    pageSize,
    currentPage
  );
  useQuery(runId);

  const loading =
    status === 'new' ||
    (status === 'running' &&
      (!numRows ||
        !metrics?.durationMS ||
        (metrics.durationMS && metrics.durationMS < 200 && numRows === 0))) ||
    (resultsDisplayType === 'chart' && chartData.loading);

  // not including numRows on the dependency so that it only runs once
  useEffect(() => {
    if (!loading && onNumRowsChange) {
      onNumRowsChange(numRows);
    }
  }, [loading, onNumRowsChange]);

  useEffect(() => {
    // When switching between table and raw data format, set appropriate display type
    if (hasRawData) {
      // If the query results contains raw data, set the display type to raw
      setDisplayType('raw');
    } else if (resultsDisplayType === 'raw') {
      // If the query results does not contain raw data, but we display raw data tab,
      // set the display type to table.
      // This check would prevent from jumping between table and chart display type.
      setDisplayType('table');
    }
  }, [hasRawData, setDisplayType, resultsDisplayType]);

  const filterLoading =
    ['running', 'finished'].includes(status) &&
    !!search &&
    search.trim() !== '' &&
    !filterFinished;
  const loadingOrFilterLoading = loading || filterLoading;

  useEffect(() => {
    console.debug(
      'QueryResults status:',
      status,
      loading,
      filterLoading,
      filterFinished
    );
  }, [status, loading, filterLoading, filterFinished]);

  const onPaginationChange = useCallback(
    ({
      currentPage,
      pageSize
    }: {
      currentPage?: number;
      pageSize?: number;
    }): void => {
      updateTab(tabId, {
        currentPage,
        selectedColumn: 0,
        selectedRow: 0
      });
      paginationProps.onPaginationStateChange({
        currentPage:
          currentPage ?? paginationProps?.paginationState?.currentPage,
        pageSize: pageSize ?? paginationProps?.paginationState?.pageSize
      });
    },
    [paginationProps, tabId, updateTab]
  );
  const onPaginationSizeChange = useCallback(
    (size: number): void => {
      if (paginationSelectorValue !== size) {
        setPageSize(size);
        updateTab(tabId, {
          paginationSelector: size,
          selectedRow: 0,
          selectedColumn: 0
        });
      }
    },
    [paginationSelectorValue, tabId, updateTab]
  );
  const onColumnResize = (columnWidths: Record<string, number>): void => {
    updateTab(tabId, {
      columnWidths
    });
  };

  const onChangeFocus = useCallback(
    (selectionPos: SelectionPos): void => {
      updateTab(tabId, {
        selectedRow: selectionPos.row,
        selectedColumn: selectionPos.column
      });
    },
    [tabId, updateTab]
  );

  const visibleRowsChanged = useCallback((start: number, end: number) => {
    // Always keep at least 200 rows after start row. Kind of a hack to stop
    // rows from being unloaded before the spreadsheet knows it has them. Maybe
    // not them most elegant solution but should work unless the user has a
    // screen taller than 200 rows
    const realEnd = end - start > 200 ? end : start + 200;
    setVisibleRange({ start, end: realEnd });
  }, []);

  const showFixQuery = !loadingOrFilterLoading && typeof error !== 'undefined';

  const renderResult = (): ReactElement => {
    if (!loading && error) {
      return <WarningAlert text={error.message} data-testid="errorBox" />;
    }

    if (!loading && message && !rawData) {
      return <SuccessAlert text={message} />;
    }

    if (status === 'cancelled' && numRows === 0) {
      return (
        <WarningAlert text="Query was cancelled before any results were returned" />
      );
    }

    if (!loadingOrFilterLoading && resultsDisplayType === 'chart') {
      return (
        <Chart
          {...chartConfig}
          customMessage="Please configure the chart."
          data={chartData}
          dataHash={chartData}
          showNoResults={!chartData.loading}
          title={name || 'Untitled query'}
          type={chartConfig.chartType || 'bar'}
        />
      );
    }

    if (!loadingOrFilterLoading && rawData && resultsDisplayType !== 'table') {
      return <RawQueryResults text={rawData || ''} />;
    }

    return (
      <QueryResults
        filterLoading={filterLoading}
        paginationProps={paginationProps}
        search={search}
        totals={totals}
        selectedRow={selectedRow}
        selectedColumn={selectedColumn}
        tabId={tabId}
        columnWidths={columnWidths}
        updateRightBar={updateRightBar}
        columns={columns}
        getRow={getRow}
        rowIterator={rowIterator}
        visibleRowsChanged={visibleRowsChanged}
        numRows={numRows}
        pageSize={pageSize}
        loading={loading}
        onPaginationChange={onPaginationChange}
        onChangeFocus={onChangeFocus}
        onColumnResize={onColumnResize}
        onPaginationSizeChange={onPaginationSizeChange}
      />
    );
  };

  return (
    <ResultsLayout
      showFixQuery={showFixQuery}
      numRows={numRows}
      downloadResults={downloadResults}
      search={search}
      setDisplayType={setDisplayType}
      setSearch={setSearch}
      metrics={metrics}
      progress={progress}
      rawData={rawData}
      status={status}
      resultsDisplayType={resultsDisplayType}
      runId={runId}
      paginationProps={paginationProps}
    >
      {renderResult()}
    </ResultsLayout>
  );
});

export default Results;
