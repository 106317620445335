import { CUIThemeType, Spacer, Title, Container } from '@clickhouse/click-ui';
import React, { ReactElement } from 'react';
import { ChunkStatus } from 'shared/src/dataLoading';
import { ChunkSummaryModel } from 'src/dataloading/models';
import styled from 'styled-components';

const ChunkSignal = styled.div<{ status: ChunkStatus; selected: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${({
    status,
    theme
  }: {
    status: ChunkStatus;
    theme: CUIThemeType;
  }): string => {
    switch (status) {
      case 'done':
        return theme.global.color.feedback.success.background;
      case 'queued':
        return theme.global.color.feedback.neutral.background;
      case 'failed_importing':
      case 'failed_moving_partitions':
      case 'canceled':
        return theme.global.color.feedback.danger.background;
      case 'importing':
      case 'moving_partitions':
        return theme.global.color.feedback.info.background;
      default:
        return theme.global.color.feedback.neutral.background;
    }
  }};
  border: 1px solid
    ${({ selected, status, theme }): string => {
      switch (status) {
        case 'done':
          return selected
            ? theme.global.color.feedback.success.foreground
            : theme.global.color.feedback.success.background;
        case 'queued':
          return selected
            ? theme.global.color.feedback.neutral.foreground
            : theme.global.color.feedback.neutral.background;
        case 'failed_importing':
        case 'failed_moving_partitions':
        case 'canceled':
          return selected
            ? theme.global.color.feedback.danger.foreground
            : theme.global.color.feedback.danger.background;
        case 'importing':
        case 'moving_partitions':
          return selected
            ? theme.global.color.feedback.info.foreground
            : theme.global.color.feedback.info.background;
        default:
          return selected
            ? theme.global.color.feedback.neutral.foreground
            : theme.global.color.feedback.neutral.background;
      }
    }};

  &:hover {
    border: 1px solid
      ${({ status, theme }): string => {
        switch (status) {
          case 'done':
            return theme.global.color.feedback.success.foreground;
          case 'queued':
            return theme.global.color.feedback.neutral.foreground;
          case 'failed_importing':
          case 'failed_moving_partitions':
          case 'canceled':
            return theme.global.color.feedback.danger.foreground;
          case 'importing':
          case 'moving_partitions':
            return theme.global.color.feedback.info.foreground;
          default:
            return theme.global.color.feedback.neutral.foreground;
        }
      }};
  }
`;

type ChunkProps = {
  chunk: ChunkSummaryModel;
  onClick: (chunk: ChunkSummaryModel) => void;
  selected: boolean;
};

const Chunk = ({ chunk, onClick, selected }: ChunkProps): ReactElement => {
  return (
    <ChunkSignal
      status={chunk.status}
      selected={selected}
      onClick={(): void => {
        onClick(chunk);
      }}
    />
  );
};

type ChunkStatusTabProps = {
  chunks: Array<ChunkSummaryModel>;
  onSelectChunk: (chunk: ChunkSummaryModel) => void;
  selectedChunk: ChunkSummaryModel | null;
};

const ChunkStatuses = ({
  chunks,
  onSelectChunk,
  selectedChunk
}: ChunkStatusTabProps): ReactElement => {
  const successfulChunks = chunks.filter(
    (chunk) => chunk.status === 'done'
  ).length;

  const isSelected = (chunk: ChunkSummaryModel): boolean =>
    selectedChunk ? selectedChunk.chunkNumber === chunk.chunkNumber : false;

  return (
    <Container
      orientation="vertical"
      overflow="auto"
      gap="xs"
      grow="1"
      alignItems="start"
    >
      <Spacer size="sm" />
      <Title size="sm" type="h2">
        Import status ({successfulChunks}/{chunks.length})
      </Title>
      <Spacer size="sm" />
      <Container gap="xs" wrap="wrap">
        {chunks.map((chunk, idx) => {
          return (
            <Chunk
              chunk={chunk}
              key={`chunk-${idx}`}
              selected={isSelected(chunk)}
              onClick={(chunk: ChunkSummaryModel): void => {
                onSelectChunk(chunk);
              }}
            />
          );
        })}
      </Container>
    </Container>
  );
};
const MemoizedChunkStatuses = React.memo(ChunkStatuses);
export default MemoizedChunkStatuses;
