import React from 'react';
import styled from 'styled-components';
import { Container, Icon, Link, Text } from '@clickhouse/click-ui';

const PeerDBInfoBoxWrapper = styled.div`
  padding: 16px;
  display: flex;
  border-radius: 4px;
  ${({ theme }) =>
    theme.name === 'dark'
      ? `
    background-color: #09255b;
  `
      : `
    background-color:  #b5cdf9;
  `}

  a,
  a:visited {
    ${({ theme }) =>
      theme.name === 'dark'
        ? `
    color: #b5cdf9;
  `
        : `
    color: #09255b;
  `}
    text-decoration: underline;
  }

  p {
    color: #b5cdf9;
    font-family: Arial, sans-serif;
    font-size: 14px;
    ${({ theme }) =>
      theme.name === 'dark'
        ? `
    color: #b5cdf9;
  `
        : `
    color: #09255b;
  `}
  }

  svg {
    display: flex;
    ${({ theme }) =>
      theme.name === 'dark'
        ? `
    color: #b5cdf9;
  `
        : `
    color: #09255b;
  `}
  }
`;

const PeerDBInfoBox = (): JSX.Element => {
  return (
    <PeerDBInfoBoxWrapper data-testid="peerdb-info-box">
      <Icon name="information" size={'lg'} />

      <Container orientation="vertical" style={{ paddingLeft: '1em' }}>
        <Text>
          PeerDB has joined forces with ClickHouse to offer the fastest Postgres
          CDC available! To get started, create an account on{' '}
          <Link target={'_blank'} href={'https://peerdb.io'} rel="noreferrer">
            PeerDB Cloud
          </Link>
          and refer to the{' '}
          <Link
            target={'_blank'}
            href="https://docs.peerdb.io/connect/clickhouse/clickhouse-cloud"
            rel="noreferrer"
          >
            documentation
          </Link>
          for setup instructions.
        </Text>

        <br />

        <Text>
          In the coming months, we'll be working on making PostgreSQL CDC a
          fully integrated solution in ClickPipes.
        </Text>
      </Container>
    </PeerDBInfoBoxWrapper>
  );
};

export default React.memo(PeerDBInfoBox);
