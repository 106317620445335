import { routes } from 'src/lib/routes';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { InstanceStateWithUpgrade } from 'src/instance/instance';
import { createToast } from '@clickhouse/click-ui';

export const navigateToService = ({
  serviceId,
  state,
  isDesktop
}: {
  serviceId: string;
  state: InstanceStateWithUpgrade;
  isDesktop: boolean;
}): void => {
  if (!isDesktop || ['stopped', 'stopping', 'starting', 'provisioning'].includes(state)) {
    navigateTo(routes.serviceSettings({ serviceId }));
  } else if (['running', 'awaking', 'upgrading', 'partially_running'].includes(state)) {
    navigateTo(routes.console({ serviceId }));
  } else if (['idle', 'degraded'].includes(state)) {
    navigateTo(routes.health({ serviceId }));
  } else {
    createToast({
      title: 'Error',
      type: 'danger',
      description: 'Cannot open service'
    });
  }
};
