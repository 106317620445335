import { useCallback, useState } from 'react';
import { createToast } from 'primitives';
import { useApiClient } from 'src/lib/controlPlane/client';

export const useJoinWaitList = () => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);

  const joinAzureWaitlist = useCallback(async () => {
    try {
      setIsLoading(true);
      createToast('Success', 'success', 'You joined the azure waitlist');
    } catch (e) {
      createToast('Error', 'error', 'Sorry. There was some issue registering you to the waitlist');
      throw e;
    } finally {
      setIsLoading(false);
    }
    await apiClient.account.addUserToCloudWaitlist({ waitlistName: 'azure' });
  }, [apiClient.account]);

  return {
    isLoading,
    joinAzureWaitlist
  };
};
