import { ConfirmationDialog, createToast, Text } from '@clickhouse/click-ui';
import React, { ReactElement } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useApiClient } from 'src/lib/controlPlane/client';
import { routes } from 'src/lib/routes';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

interface StoppedServiceModalProps {
  serviceId: string;
}

function StoppedServiceModal({
  serviceId
}: StoppedServiceModalProps): ReactElement | null {
  const apiClient = useApiClient();
  const onAllServicesClick = (): void => {
    navigateTo(routes.serviceList());
  };

  const canRestartService = useCurrentOrgUserRole() === 'ADMIN';

  const onStartServiceClick = async (): Promise<void> => {
    if (!canRestartService) {
      return;
    }

    try {
      await apiClient.instance.updateServiceState({
        instanceId: serviceId,
        state: 'starting'
      });
      navigateTo(routes.service({ serviceId }));
    } catch (e) {
      console.error(`Failed to start service ${serviceId}`, e);
      createToast({
        title: 'Error',
        description: 'Failed to start service',
        type: 'danger'
      });
    }
  };

  return (
    <ConfirmationDialog
      open
      onCancel={onAllServicesClick}
      disabled={!canRestartService}
      onConfirm={onStartServiceClick}
      title="Do you want to start this service?"
      primaryActionLabel="Start service"
      secondaryActionLabel="All services"
      data-testid="stopped-service-confirmation-dialog"
    >
      <Text>
        {
          'This service is currently in a stopped state. Do you want to start it?'
        }
      </Text>
    </ConfirmationDialog>
  );
}

const MemoizedStoppedServiceModal = React.memo(StoppedServiceModal);
MemoizedStoppedServiceModal.displayName = 'StoppedServiceModal';

export default MemoizedStoppedServiceModal;
