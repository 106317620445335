import type { QueryRowCache } from 'src/lib/query/RowCache/QueryRowCache';
import { Range } from 'src/lib/query/RowCache/RowIndexes';
import { Row } from 'src/lib/query/RowCache/types';

function rowMatchesFilter(row: Row, filter: string): boolean {
  const valueMatches = (value: unknown) => {
    const strValue = value !== null && typeof value === 'object' ? JSON.stringify(value) : String(value);
    return strValue.toLowerCase().includes(filter.toLowerCase());
  };

  if (typeof row === 'object' && row !== null) {
    return Object.values(row).some(valueMatches);
    // } else if (Array.isArray(row)) {
    //   return row.some(valueMatches)
  }
  return false;
}

export class FilterQuery {
  range: Range;
  queryRowCache: QueryRowCache;
  cancelled: boolean;
  finished = false;

  constructor(range: Range, queryRowCache: QueryRowCache) {
    this.range = range;
    this.queryRowCache = queryRowCache;
    this.cancelled = false;
  }

  async run(): Promise<void> {
    const filter = this.range.viewRangeData?.filter;

    if (this.range.viewRangeData && !!filter && filter !== '') {
      this.range.viewRangeData.filteredRows = [];
      let lastUpdate = new Date().getTime();

      for await (const row of this.queryRowCache.range()) {
        if (this.cancelled) {
          break;
        }

        if (rowMatchesFilter(row, filter)) {
          this.range.viewRangeData.filteredRows.push(row);
          const now = new Date().getTime();
          if (now - lastUpdate > 1000) {
            this.queryRowCache.rangeChange(this.range);
            lastUpdate = new Date().getTime();
          }
        }
      }
      if (!this.cancelled) {
        this.finished = true;
        this.queryRowCache.rangeChange(this.range);
      }
    }
  }

  cancel(): void {
    this.cancelled = true;
  }
}
