import { Instance } from '@cp/common/protocol/Instance';
import { convertArrayToRecord } from '@cp/common/utils/MiscUtils';
import { castDraft, produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { InstancePrivateEndpointConfig } from 'src/instance/instanceApiClient';

export interface InstanceState {
  instances: Record<string, Instance>;
  // ClickHouse instance password for `default` user. Key is the instance ID.
  instancePasswords: Record<string, string>;
  // ClickHouse passwords for MySQL protocol. Key is the instance ID.
  mySqlPasswords: Record<string, string>;
  // Instances iam roles. Key is the instance Id
  iamPrincipals: Record<string, string>;
  privateEndpointConfigs: Record<string, InstancePrivateEndpointConfig>;
  isCreatingInstance?: boolean;
  isInstanceStateInitialized: boolean;
  isInstanceStateLoading: boolean;
}
export const instanceStateAtom = atom<InstanceState>({
  instances: {},
  instancePasswords: {},
  mySqlPasswords: {},
  iamPrincipals: {},
  privateEndpointConfigs: {},
  isInstanceStateLoading: true,
  isInstanceStateInitialized: false
});

interface InstanceStateManager {
  instances: Record<string, Instance>;
  instanceState: InstanceState;
  isInstanceStateInitialized: boolean;
  isInstanceStateLoading: boolean;
  setInstance: (i: Instance) => void;
  setInstances: (instances: Array<Instance>) => void;
  updateInstances: (instances: Array<Instance>) => void;
  setIsCreatingInstance: (b: boolean) => void;
  setIsInstanceStateLoading: (b: boolean) => void;
  setInstancePassword: (instanceId: string, password: string) => void;
  setMySqlPassword: (instanceId: string, password: string) => void;
  setPrivateEndpointConfig: (instanceId: string, config: InstancePrivateEndpointConfig) => void;
  setIamPrincipal: (instanceId: string, iamPrincipal: string) => void;
}

const useInstanceStateManager = (): InstanceStateManager => {
  const [instanceState, setInstancesAtom] = useAtom(instanceStateAtom);
  // Whenever instances changes, it will trigger a re-render of a component that uses this hook.
  // This would re-create the functions below, which would cause the useEffect in the component
  // to re-run, which would cause unnecessary renders or even an infinite loop if there was a hook that had any of these functions as a dependency.
  // these function should only change if the setInstancesAtom function changes, which is why we use useCallback.
  const setInstance = useCallback(
    (instance: Instance): void => {
      setInstancesAtom((state) => setInstanceInState(state, instance));
    },
    [setInstancesAtom]
  );

  /** Overwrite the entire state. */
  const setInstances = useCallback(
    (instancesToStore: Array<Instance>): void => {
      setInstancesAtom((state) => setInstancesInState(state, instancesToStore));
    },
    [setInstancesAtom]
  );

  /** Update or set a part of the state. */
  const updateInstances = useCallback(
    (instancesToStore: Array<Instance>): void => {
      setInstancesAtom((state) => updateInstancesInState(state, instancesToStore));
    },
    [setInstancesAtom]
  );

  const setIsCreatingInstance = useCallback(
    (value: boolean): void => {
      setInstancesAtom((state) => setIsCreatingInstanceInState(state, value));
    },
    [setInstancesAtom]
  );

  const setIsInstanceStateLoading = useCallback(
    (value: boolean): void => {
      setInstancesAtom((state) => setIsInstanceStateLoadingInState(state, value));
    },
    [setInstancesAtom]
  );

  const setIamPrincipal = useCallback(
    (instanceId: string, iamPrincipal: string): void => {
      setInstancesAtom((state) => setIamPrincipalInState(state, instanceId, iamPrincipal));
    },
    [setInstancesAtom]
  );
  /**
   * Set a password for a specific instance.
   * @param instanceId - The ID of the instance.
   * @param password - The password to set.
   */
  const setInstancePassword = useCallback(
    (instanceId: string, password: string): void => {
      setInstancesAtom((state) => setPasswordInState(state, 'instancePasswords', instanceId, password));
    },
    [setInstancesAtom]
  );

  /**
   * Set a MySQL password for a specific instance.
   * @param instanceId - The ID of the instance.
   * @param password - The password to set.
   */
  const setMySqlPassword = useCallback(
    (instanceId: string, password: string): void => {
      setInstancesAtom((state) => setPasswordInState(state, 'mySqlPasswords', instanceId, password));
    },
    [setInstancesAtom]
  );

  const setPrivateEndpointConfig = useCallback(
    (instanceId: string, privateEndpointConfig: InstancePrivateEndpointConfig): void => {
      setInstancesAtom((state) => setPrivateEndpointConfigInState(state, instanceId, privateEndpointConfig));
    },
    [setInstancesAtom]
  );
  return {
    instances: instanceState.instances,
    isInstanceStateInitialized: instanceState.isInstanceStateInitialized,
    isInstanceStateLoading: instanceState.isInstanceStateLoading,
    instanceState,
    setInstance,
    setInstances,
    updateInstances,
    setIsCreatingInstance,
    setIsInstanceStateLoading,
    setInstancePassword,
    setMySqlPassword,
    setPrivateEndpointConfig,
    setIamPrincipal
  };
};

export const setInstancesInState = (state: InstanceState, instances: Array<Instance>): InstanceState =>
  produce(state, (draft) => {
    draft.instances = castDraft(convertArrayToRecord(instances));
    draft.isInstanceStateLoading = false;
    draft.isInstanceStateInitialized = true;
  });

export const setInstanceInState = (state: InstanceState, instance: Instance): InstanceState =>
  produce(state, (draft) => {
    draft.instances[instance.id] = castDraft(instance);
  });

export const updateInstancesInState = (state: InstanceState, instances: Array<Instance>): InstanceState =>
  produce(state, (draft) => {
    instances.forEach((instance) => {
      if (instance.state === 'terminated') {
        delete draft.instances[instance.id];
      }
    });

    const newInstances = instances.filter((instance) => instance.state !== 'terminated');

    draft.instances = castDraft({
      ...draft.instances,
      ...convertArrayToRecord(newInstances)
    });
    draft.isInstanceStateLoading = false;
  });

export const setIsCreatingInstanceInState = (state: InstanceState, value: boolean): InstanceState =>
  produce(state, (draft) => {
    draft.isCreatingInstance = value;
  });

export const setIsInstanceStateLoadingInState = (state: InstanceState, value: boolean): InstanceState =>
  produce(state, (draft) => {
    draft.isInstanceStateLoading = value;
  });

/**
 * A pure function to set a password in the state.
 * @param state - The current state.
 * @param section - The section to set the password in, either `instancePasswords` or `mySqlPasswords`.
 * @param instanceId - The ID of the instance.
 * @param password - The password to set.
 * @returns The new state.
 */
export const setPasswordInState = (
  state: InstanceState,
  section: 'instancePasswords' | 'mySqlPasswords',
  instanceId: string,
  password: string
): InstanceState =>
  produce(state, (draft) => {
    draft[section][instanceId] = password;
  });

export const setPrivateEndpointConfigInState = (
  state: InstanceState,
  instanceId: string,
  privateEndpointConfig: InstancePrivateEndpointConfig
): InstanceState =>
  produce(state, (draft) => {
    draft.privateEndpointConfigs[instanceId] = privateEndpointConfig;
  });

export const setIamPrincipalInState = (state: InstanceState, instanceId: string, iamPrincipal: string): InstanceState =>
  produce(state, (draft) => {
    draft.iamPrincipals[instanceId] = iamPrincipal;
  });

export const AWAKE_STATUS_LIST: Instance['state'][] = ['running', 'degraded', 'partially_running'];
export const DELETED_STATUS_LIST: Instance['state'][] = ['terminated', 'terminating'];
export const STOPPED_STATUS_LIST: Instance['state'][] = ['stopped', 'stopping', 'failed'];
export const ASLEEP_STATUS_LIST: Instance['state'][] = ['idle'];
export const AWAKING_STATUS_LIST: Instance['state'][] = ['awaking'];
export const PROVISIONING_STATUS_LIST: Instance['state'][] = ['provisioning'];
export const STARTING_STATUS_LIST: Instance['state'][] = ['starting'];
export const isInstanceAwake = (instance: Instance): boolean => AWAKE_STATUS_LIST.includes(instance.state);
export const isInstanceAsleep = (instance: Instance): boolean => ASLEEP_STATUS_LIST.includes(instance.state);
export const isInstanceDeleted = (instance: Instance): boolean => DELETED_STATUS_LIST.includes(instance.state);
export const isInstanceAwaking = (instance: Instance): boolean => AWAKING_STATUS_LIST.includes(instance.state);
export const isInstanceProvisioning = (instance: Instance): boolean =>
  PROVISIONING_STATUS_LIST.includes(instance.state);
export const isInstanceStopped = (instance: Instance): boolean => STOPPED_STATUS_LIST.includes(instance.state);
export const isInstanceStarting = (instance: Instance): boolean => STARTING_STATUS_LIST.includes(instance.state);

export { useInstanceStateManager };
