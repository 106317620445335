import { useCallback, useState } from 'react';
import { createToast } from '@clickhouse/click-ui';
import { useApiClient } from 'src/lib/controlPlane/client';

export interface UpdateBillingContact {
  isLoading: boolean;
  updateBillingContact: (organizationId: string, billingContact: string) => Promise<void>;
}

export const useUpdateBillingContact = (): UpdateBillingContact => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateBillingContact = useCallback(
    async (organizationId: string, billingContact: string) => {
      try {
        setIsLoading(true);
        await apiClient.billing.updateOrganizationBillingContact(organizationId, billingContact);
        createToast({
          description: `Billing contact has been updated to ${billingContact}.`,
          title: 'Success',
          type: 'success'
        });
      } catch {
        createToast({
          description: 'Please try again later',
          title: 'There was a problem updating your billing contact',
          type: 'danger'
        });
      } finally {
        setIsLoading(false);
      }
    },
    [apiClient.billing]
  );

  return { isLoading, updateBillingContact };
};
