import styled from 'styled-components';
import { ReactElement } from 'react';
import {
  useCloudStatus,
  STATUS_PAGE_BASE_URL
} from 'src/components/CloudStatusEmbed/useCloudStatus';
import { CustomSidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';

type StatusType = 'danger' | 'warning' | 'success';
interface StyledSidebarProps {
  $statusType: StatusType;
}

const StyledSidebarNavigationItem = styled(
  CustomSidebarItem
)<StyledSidebarProps>`
  ${({ theme, $statusType }): string => `
  ${
    $statusType !== 'success' &&
    `background-color: ${theme.global.color.feedback[$statusType].background};`
  };
  & svg {
    color: ${theme.global.color.feedback[$statusType].foreground};
  }
`}
  text-transform: lowercase;

  ::first-letter {
    text-transform: capitalize;
  }
`;

export const CloudStatusEmbed = (): ReactElement | null => {
  const { statusIndicator, statusText, incidentImpact, incidentName } =
    useCloudStatus();

  if (!statusIndicator) return null;
  let statusType: StatusType =
    statusIndicator === 'none' ? 'success' : 'danger';

  if (incidentImpact == 'minor' || statusIndicator == 'minor') {
    statusType = 'warning';
  }

  return (
    <StyledSidebarNavigationItem
      $collapsible={false}
      type="main"
      icon="dot"
      $statusType={statusType}
      label={
        <a
          href={`${STATUS_PAGE_BASE_URL}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {incidentName ? incidentName : statusText}
        </a>
      }
    />
  );
};
