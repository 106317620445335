import { css, SerializedStyles, Theme } from '@emotion/react';

export const rootStyle: SerializedStyles = css`
  position: relative;
  flex-grow: 1;

  display: flex;
  flex-flow: column;
`;

export const contentRootStyle = (level: number): SerializedStyles => css`
  margin-left: ${level * 6}px;
  &.folder input {
    font-weight: 500;
  }

  & > .content-icon {
    flex-shrink: 0;
  }

  &[data-dragging='true'] {
    opacity: 0.6;
  }
  span {
    flex: 1;
  }
  &:hover .delete-icon {
    opacity: 1;
  }
`;

export const contentTextStyle: SerializedStyles = css`
  flex: 1;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  display: flex;
  justify-content: space-between;
`;

export const contentInputStyle: SerializedStyles = css`
  color: inherit;
  width: fill-available;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: inherit;

  background: transparent;
  padding: 0px;
  margin: 0px;
  border: 0px;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
  &:read-only,
  &:disabled {
    cursor: pointer;
    user-select: none;
  }
`;

export const listContainerStyle = (active: boolean, level: number): SerializedStyles => css`
  padding-left: 10px;
  position: relative;

  ${active &&
  `
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 4px;
        width: 100%;

        margin-left: ${level * 8}px;
      }
    `}
`;

export const listContainerDropStyle = css`
  position: absolute;
  height: 100%;
  top: 0px;
  left: 10px;
  width: 20px;
`;

export const listWrapperStyle = (containerHeight: number, listHeight: number): SerializedStyles => css`
  max-height: ${containerHeight > listHeight ? listHeight : containerHeight - 10}px;
`;

export const sidebarCloseBtn = css`
  opacity: 0;
  cursor: pointer;
  display: flex;
`;

export const treeContainer = (height: number): SerializedStyles => css`
  display: grid;
  height: ${height}px;
  grid-template-rows: 10px auto 1fr;
`;

export const dropStyle =
  (size: { width: number | string; height: number | string }) =>
  (theme: Theme): SerializedStyles => css`
    position: absolute;
    bottom: 0px;
    left: 0;
    width: ${typeof size.width === 'number' ? `${size.width}px` : size.width};
    height: 100%;

    &[data-direction='between'] {
      top: 0;
      height: 4px;
      &[data-level='1'] {
        margin-left: 8px;
      }
      &[data-active='true'] {
        background: ${theme.global.color.accent.default};
      }
    }
    &[data-dragging='true'] {
      height: ${typeof size.height === 'number' ? `calc(100vh - ${size.height}px)` : size.height};
    }
  `;
