import { ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export const UsagePageFeatureGuard = (): ReactElement => {
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};
