import { Container, Tabs } from '@clickhouse/click-ui';

import { ReactElement, useCallback } from 'react';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useOrgFeature } from 'src/lib/features';
import { useSearchParam } from 'src/lib/routes/useSearchParam';
import { AllNotificationsTab } from 'src/pages/NotificationsPage/AllNotifications/AllNotificationsTab';
import { SettingsTab } from 'src/pages/NotificationsPage/Settings/SettingsTab';
import { NotificationTabs } from 'src/pages/NotificationsPage/tabs';

function NotificationsPage(): ReactElement {
  const showNotifications = useOrgFeature('FT_ORG_NOTIFICATIONS');
  const [tab, setTab] = useSearchParam('tab', NotificationTabs.NOTIFICATIONS, {
    type: 'string'
  });

  const setTabValue = useCallback(
    (value: string) => {
      if (value !== tab) {
        setTab(value, true);
      }
    },
    [tab, setTab]
  );

  if (!showNotifications) {
    return <></>;
  }

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Notifications">
        <Container gap="lg" justifyContent="end">
          <Container fillWidth maxWidth="200px"></Container>
        </Container>
      </MainPaneLayout.Topbar>
      <MainPaneLayout.Content>
        <MaxWidthContainer
          gap="md"
          padding="lg"
          orientation="vertical"
          alignItems="start"
          grow="1"
        >
          <Tabs value={tab} onValueChange={setTabValue}>
            <Tabs.TriggersList aria-label="Notification list and settings">
              <Tabs.Trigger value={NotificationTabs.NOTIFICATIONS}>
                All notifications
              </Tabs.Trigger>
              <Tabs.Trigger value={NotificationTabs.SETTINGS}>
                Notification settings
              </Tabs.Trigger>
            </Tabs.TriggersList>
            <Tabs.Content value={NotificationTabs.NOTIFICATIONS}>
              <AllNotificationsTab />
            </Tabs.Content>
            <Tabs.Content value={NotificationTabs.SETTINGS}>
              <SettingsTab />
            </Tabs.Content>
          </Tabs>
        </MaxWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
}

export default NotificationsPage;
