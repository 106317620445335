import { ReactElement, useState } from 'react';

import { logEvent } from 'src/components/QueryView/analytics';
import { ViewParameter } from 'shared/src/clickhouse/types';
import { InteractionType } from 'src/lib/logger';
import { ViewParameterValues } from 'src/state/tabs/types';

import { Field } from 'src/components/TableView/DataView/RightBar/RightBarField';
import { Button, FlyoutProps } from '@clickhouse/click-ui';
import ResizableFlyout from 'src/components/RightBar/ResizableFlyout';

interface ViewParametersProps extends FlyoutProps {
  parameters?: ViewParameter[];
  values?: ViewParameterValues;
  update: (props: Record<string, string>) => void;
  container?: HTMLElement | null;
}

export default function ViewParameters({
  update,
  values,
  parameters = [],
  ...props
}: ViewParametersProps): ReactElement {
  const [fields, setFields] = useState<ViewParameterValues>(values ?? {});
  const [loading, setLoading] = useState(false);

  const someFieldsBlank = parameters.some((param) => {
    return !fields[param.name];
  });

  const noFieldsChanged =
    values &&
    parameters.every((param) => {
      return values[param.name] === fields[param.name];
    });

  const disabled = someFieldsBlank || noFieldsChanged;

  const onSubmit = (): void => {
    logEvent('viewParameters', 'updateParametersButtonClick');
    setLoading(true);
    update(fields);
    setLoading(false);
  };
  const log = (event: string, interaction?: InteractionType): void =>
    logEvent('viewParameters', event, interaction);

  return (
    <ResizableFlyout header="View Parameters" logEvent={log} {...props}>
      <ResizableFlyout.Body data-testid="rightBarInsertRowContent">
        {parameters.map((column, i) => (
          <Field
            key={i}
            name={column.name}
            onChange={(value): void => {
              setFields((fields) => {
                const newFields: Record<string, string> = { ...fields };
                newFields[column.name] = value;
                return newFields;
              });
            }}
            type={column.type}
            value={fields[column.name]}
            logEvent={log}
          />
        ))}
      </ResizableFlyout.Body>
      <ResizableFlyout.Footer>
        <ResizableFlyout.Close label="Cancel" />
        <Button
          loading={loading}
          disabled={disabled || loading}
          onClick={onSubmit}
        >
          Update View
        </Button>
      </ResizableFlyout.Footer>
    </ResizableFlyout>
  );
}
