import { useCallback } from 'react';

import { impossibleValue } from 'shared/src/impossibleValue';
import { emptyIterable, rangeIterator } from 'src/lib/iterator';

import {
  SelectionAction,
  LogFunction,
  SelectedRegion,
  SelectionPos,
  EventType,
  RowsSelection,
  RectangleSelection,
  Rectangle,
  ColumnsSelection
} from 'src/components/primitives/lib/Spreadsheet/types';

interface Props {
  log: LogFunction;
  focus: SelectionPos;
  numColumns: number;
  numRows: number;
  selection: SelectedRegion;
}

export interface SelectionActions {
  select: (action: SelectionAction, event: EventType) => SelectedRegion;
  clearSelection: (force: boolean) => SelectedRegion;
  moveSelection: (
    columnDiff: number,
    rowDiff: number,
    moveAnchor: boolean,
    action: EventType
  ) => SelectionAction | null;
}

export function cellSelected(
  selection: SelectedRegion,
  column: number,
  row: number
): boolean {
  return cellRectSelected(selection, column, row, column, row);
}

export function singleCellSelected(
  selection: SelectedRegion
): SelectionPos | null {
  if (
    selection.type === 'rectangle' &&
    selection.bounds.left === selection.bounds.right &&
    selection.bounds.top === selection.bounds.bottom
  ) {
    return {
      column: selection.bounds.left,
      row: selection.bounds.top
    };
  } else {
    return null;
  }
}

function orderedRect(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): Rectangle {
  const left = Math.min(x1, x2);
  const right = Math.max(x1, x2);
  const top = Math.min(y1, y2);
  const bottom = Math.max(y1, y2);
  return { left, right, top, bottom };
}

export function cellRectSelected(
  selection: SelectedRegion,
  x1: number,
  y1: number,
  x2: number,
  y2: number
): boolean {
  const { left, right, top, bottom } = orderedRect(x1, y1, x2, y2);

  switch (selection.type) {
    case 'columns':
      for (let i = left; i <= right; ++i) {
        if (!selection.columns.has(i)) {
          return false;
        }
      }
      return true;
    case 'rows':
      for (let i = top; i <= bottom; ++i) {
        if (!selection.rows.has(i)) {
          return false;
        }
      }
      return true;
    case 'rectangle':
      return (
        selection.bounds.left <= left &&
        selection.bounds.right >= right &&
        selection.bounds.top <= top &&
        selection.bounds.bottom >= bottom
      );
    default:
      return false;
  }
}

function rowSelection(
  rows: Iterable<number>,
  anchorRow: number
): RowsSelection {
  return {
    type: 'rows',
    rows: new Set(rows),
    anchorRow
  };
}

function columnSelection(
  columns: Iterable<number>,
  anchorColumn: number
): ColumnsSelection {
  return {
    type: 'columns',
    columns: new Set(columns),
    anchorColumn
  };
}

export function rectangleSelection(
  anchorColumn: number,
  anchorRow: number,
  focusColumn: number,
  focusRow: number
): RectangleSelection {
  return {
    type: 'rectangle',
    bounds: orderedRect(anchorColumn, anchorRow, focusColumn, focusRow),
    anchor: { column: anchorColumn, row: anchorRow }
  };
}

export function rowsInSelection(
  selection: SelectedRegion,
  bounds: Rectangle
): Iterable<number> {
  switch (selection.type) {
    case 'rectangle':
      return rangeIterator(selection.bounds.top, selection.bounds.bottom + 1);
    case 'columns':
      return rangeIterator(bounds.top, bounds.bottom + 1);
    case 'rows':
      return [...selection.rows].sort();
    case 'empty':
      return emptyIterable();
    default:
      return impossibleValue(selection);
  }
}

export function emptySelection(): SelectedRegion {
  return { type: 'empty' };
}

export function columnIsSelected(
  selection: SelectedRegion,
  column: number,
  bounds: Rectangle
): boolean {
  return cellRectSelected(selection, column, bounds.top, column, bounds.bottom);
}

export function rowIsSelected(
  selection: SelectedRegion,
  row: number,
  bounds: Rectangle
): boolean {
  return cellRectSelected(selection, bounds.left, row, bounds.right, row);
}

export function rowAnySelected(
  selection: SelectedRegion,
  row: number
): boolean {
  return (
    selection.type === 'columns' ||
    (selection.type === 'rows' && selection.rows.has(row)) ||
    (selection.type === 'rectangle' &&
      selection.bounds.top <= row &&
      selection.bounds.bottom >= row)
  );
}

export function selectCell(col: number, row: number): SelectedRegion {
  return rectangleSelection(col, row, col, row);
}

export function rangeIndices(a: number, b: number): number[] {
  const low = Math.min(a, b);
  const high = Math.max(a, b);
  const indices: number[] = [];
  for (let i = low; i <= high; ++i) {
    indices.push(i);
  }
  return indices;
}

export function useSelectionActions({
  log,
  focus,
  numColumns,
  numRows,
  selection
}: Props): SelectionActions {
  const clearSelection = useCallback(
    (force: boolean): SelectedRegion => {
      if (selection.type === 'columns' && !force) {
        return selection;
      } else {
        return emptySelection();
      }
    },
    [selection]
  );

  const cellSelect = useCallback(
    (rowIndex: number, columnIndex: number, event: EventType) => {
      log('cellSelect', event);
      return selectCell(columnIndex, rowIndex);
    },
    [log]
  );

  const shiftSelect = useCallback(
    (rowIndex: number, columnIndex: number, event: EventType) => {
      log('cellRangeSelect', event);
      const newSelection = rectangleSelection(
        columnIndex,
        rowIndex,
        focus.column,
        focus.row
      );
      return newSelection;
    },
    [focus.column, focus.row, log]
  );

  const rowSelect = useCallback(
    (rowIndex: number, event: EventType) => {
      log('rowSelect', event);
      const newSelection = rowSelection([rowIndex], rowIndex);
      return newSelection;
    },
    [log]
  );

  const shiftRowSelect = useCallback(
    (rowIndex: number, event: EventType) => {
      const newSelection = rowSelection(
        rangeIndices(focus.row, rowIndex),
        rowIndex
      );
      log('rowSelect', event);

      return newSelection;
    },
    [focus.row, log]
  );

  const ctrlRowSelect = useCallback(
    (rowIndex: number, event: EventType) => {
      let rows: Set<number>;

      if (selection.type === 'rows') {
        rows = new Set(selection.rows);
      } else {
        rows = new Set();
      }
      rows.add(rowIndex);

      const newSelection = rowSelection(rows, rowIndex);
      log('rowSelect', event);

      return newSelection;
    },
    [log, selection]
  );

  const columnSelect = useCallback(
    (columnIndex: number, event: EventType) => {
      log('columnSelect', event);
      const newSelection = columnSelection([columnIndex], columnIndex);
      return newSelection;
    },
    [log]
  );

  const shiftColumnSelect = useCallback(
    (columnIndex: number, event: EventType) => {
      const newSelection = columnSelection(
        rangeIndices(focus.column, columnIndex),
        columnIndex
      );

      log('columnSelect', event);

      return newSelection;
    },
    [focus.column, log]
  );

  const ctrlColumnSelect = useCallback(
    (columnIndex: number, event: EventType) => {
      let columns: Set<number>;

      if (selection.type === 'columns') {
        columns = new Set(selection.columns);
      } else {
        columns = new Set();
      }
      columns.add(columnIndex);

      const newSelection = columnSelection(columns, columnIndex);
      log('rowSelect', event);

      return newSelection;
    },
    [log, selection]
  );

  const select = useCallback(
    (action: SelectionAction, event: EventType): SelectedRegion => {
      switch (action.type) {
        case 'normal':
          return cellSelect(action.row, action.column, event);
        case 'shiftSelection':
          return shiftSelect(action.row, action.column, event);
        case 'columnSelection':
          return columnSelect(action.column, event);
        case 'shiftColumnSelection':
          return shiftColumnSelect(action.column, event);
        case 'ctrlColumnSelection':
          return ctrlColumnSelect(action.column, event);
        case 'rowSelection':
          return rowSelect(action.row, event);
        case 'shiftRowSelection':
          return shiftRowSelect(action.row, event);
        case 'ctrlRowSelection':
          return ctrlRowSelect(action.row, event);
        default:
          impossibleValue(action);
      }
    },
    [
      cellSelect,
      shiftSelect,
      columnSelect,
      rowSelect,
      shiftColumnSelect,
      shiftRowSelect,
      ctrlRowSelect,
      ctrlColumnSelect
    ]
  );

  const moveSelection = useCallback(
    (
      columnDiff: number,
      rowDiff: number,
      moveAnchor: boolean,
      event: EventType
    ): SelectionAction | null => {
      const clamp = (n: number, low: number, high: number) => {
        return Math.max(low, Math.min(high, n));
      };

      let action: SelectionAction | null = null;

      if (moveAnchor) {
        if (selection.type === 'rows') {
          const row = clamp(selection.anchorRow + rowDiff, 0, numRows);
          action = { type: 'shiftRowSelection', row };
        } else if (selection.type === 'columns') {
          const column = clamp(
            selection.anchorColumn + columnDiff,
            0,
            numColumns
          );
          action = { type: 'shiftColumnSelection', column };
        } else if (selection.type === 'rectangle') {
          const row = clamp(selection.anchor.row + rowDiff, 0, numRows);
          const column = clamp(
            selection.anchor.column + columnDiff,
            0,
            numColumns
          );
          action = { type: 'shiftSelection', row, column };
        }
      } else {
        const row = clamp(focus.row + rowDiff, 0, numRows);
        const column = clamp(focus.column + columnDiff, 0, numColumns);
        action = { type: 'normal', row, column };
      }

      if (action) {
        select(action, event);
      }

      return action;
    },
    [select, numColumns, numRows, selection, focus]
  );

  return {
    select,
    clearSelection,
    moveSelection
  };
}
