import { ReactElement } from 'react';
import {
  Icon,
  Panel,
  Text,
  IconName,
  useCUITheme,
  Spacer
} from '@clickhouse/click-ui';

import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { QueryProgress } from 'src/lib/query/streamingQuery';
import {
  formatBytes,
  formatMsToSec
} from 'src/components/QueryView/Results/metrics';
import LoadingIcon from 'src/components/icons/LoadingIcon';

type ProgressToolbarStatusProps = {
  status: RunningQueryStatusName;
  progress?: QueryProgress;
  showIcons: boolean;
};

const StatusTitle = (props: {
  title: string;
  icon: IconName;
  showIcon: boolean;
  iconTestId: string;
}): ReactElement => {
  const cuiTheme = useCUITheme();
  return props.showIcon ? (
    <Icon
      color={cuiTheme.global.color.text.muted}
      name={props.icon}
      size="sm"
      data-testid={props.iconTestId}
    />
  ) : (
    <Text color="muted">{props.title}: </Text>
  );
};

const ProgressToolbarStatus = (
  props: ProgressToolbarStatusProps
): ReactElement => {
  const progress = props.progress ?? {
    read_rows: 0,
    read_bytes: 0,
    written_rows: 0,
    written_bytes: 0,
    total_rows_to_read: 0,
    elapsed_ns: 0,
    peak_memory_usage: 0,
    result_rows: 0,
    result_bytes: 0
  };
  const { showIcons } = props;

  return (
    <Panel alignItems="start" orientation="horizontal" padding="none" gap="md">
      <Spacer size="md" />
      <Panel orientation="horizontal" padding="none">
        <LoadingIcon className="loading" width={16} height={16} />
        <Text color="muted">Running Query</Text>
      </Panel>

      {Boolean(progress?.peak_memory_usage) && (
        <Panel orientation="horizontal" padding="none">
          <StatusTitle
            title="Memory Usage"
            icon="activity"
            showIcon={showIcons}
            iconTestId="icon-memory"
          />
          <Text color="muted">{formatBytes(progress?.peak_memory_usage)}</Text>
        </Panel>
      )}
      {Boolean(progress?.read_rows) && (
        <Panel orientation="horizontal" padding="none">
          <StatusTitle
            title="Read"
            icon="eye"
            showIcon={showIcons}
            iconTestId="icon-read"
          />
          <Text color="muted">
            {progress?.read_rows.toLocaleString()} rows
            {progress?.read_bytes && ` (${formatBytes(progress?.read_bytes)})`}
          </Text>
        </Panel>
      )}
      {Boolean(progress?.written_rows) && (
        <Panel orientation="horizontal" padding="none">
          <StatusTitle
            title="Written"
            icon="pencil"
            showIcon={showIcons}
            iconTestId="icon-written"
          />
          <Text color="muted">
            {progress?.written_rows.toLocaleString()} rows
            {progress?.written_bytes &&
              ` (${formatBytes(progress?.written_bytes)})`}
          </Text>
        </Panel>
      )}
    </Panel>
  );
};

export default ProgressToolbarStatus;
