import {
  CardHorizontal,
  Container,
  Icon,
  Label,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { InstanceTier } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
const PriceBlock = ({
  storagePrice,
  computePrice
}: {
  storagePrice: string;
  computePrice: string;
}): ReactElement => {
  return (
    <Container isResponsive={false}>
      <Container orientation="vertical">
        {storagePrice ? (
          <>
            <Text>${storagePrice}</Text>
            <Text color="muted"> Per 1 TB storage / month</Text>
          </>
        ) : (
          <Icon name="horizontal-loading" />
        )}
      </Container>

      <Container orientation="vertical">
        {computePrice ? (
          <>
            <Text>${computePrice}</Text>
            <Text color="muted"> Per compute unit / hour </Text>
          </>
        ) : (
          <Icon name="horizontal-loading" />
        )}
      </Container>
    </Container>
  );
};

const CardContent = ({
  description,
  storagePrice,
  computePrice,
  isOnboarding
}: {
  description: string;
  storagePrice: string;
  computePrice: string;
  isOnboarding: boolean;
}): ReactElement => {
  const isPricingFfOn = useFeatureIsOn('onboarding-service-pricing');
  const showPricingInfo = isOnboarding && isPricingFfOn;

  return (
    <Container orientation="vertical">
      <Spacer size="sm" />
      <Text color="muted">{description}</Text>
      {showPricingInfo && (
        <>
          <Separator size="md" />
          <PriceBlock
            storagePrice={storagePrice}
            computePrice={computePrice}
          ></PriceBlock>
        </>
      )}
    </Container>
  );
};

const TierSelection = ({
  selectedTier,
  onTierSelection,
  isDevTierDisabled,
  isOnboarding,
  devStoragePrice,
  devComputePrice,
  prodStoragePrice,
  prodComputePrice
}: {
  selectedTier: InstanceTier;
  onTierSelection: (tier: InstanceTier) => void;
  isDevTierDisabled: boolean;
  isOnboarding: boolean;
  devStoragePrice: string;
  devComputePrice: string;
  prodStoragePrice: string;
  prodComputePrice: string;
}): ReactElement => (
  <Container orientation="vertical" padding="none" alignItems="start">
    <Label>Purpose</Label>
    <Spacer size="sm" />
    <Container
      gap="lg"
      padding="none"
      alignItems="stretch"
      orientation="vertical"
    >
      <CardHorizontal
        title="Production (recommended)"
        isSelected={selectedTier === 'Production'}
        onClick={(): void => onTierSelection('Production')}
        data-testid="purpose-card-Production"
      >
        <CardContent
          description="Use this option when working with production environments, data at
            scale or professional use cases"
          storagePrice={prodStoragePrice}
          computePrice={prodComputePrice}
          isOnboarding={isOnboarding}
        />
      </CardHorizontal>
      {!isDevTierDisabled && (
        <CardHorizontal
          title="Development"
          isSelected={selectedTier === 'Development'}
          onClick={(): void => onTierSelection('Development')}
          data-testid="purpose-card-Development"
        >
          <CardContent
            description="Use this option when testing out new ideas or starter projects. Limited storage and memory."
            storagePrice={devStoragePrice}
            computePrice={devComputePrice}
            isOnboarding={isOnboarding}
          />
        </CardHorizontal>
      )}
    </Container>
  </Container>
);

export default TierSelection;
