import { ReactNode } from 'react';

export const noSpaceHint =
  'There is no space left on device. Please free up some space.';

const regexes: Record<string, string | JSX.Element> = {
  'ENOSPC: no space left on device.*': noSpaceHint
};

const humanizedMessages: Record<string, string | JSX.Element> = {
  'input of anonymous composite types is not implemented':
    'Editing cells of composite types is not supported.'
};

export function humanizeMessage(message: string | null): ReactNode {
  if (!message) {
    return null;
  }

  let humanizedMessage = humanizedMessages[message];

  if (!humanizedMessage) {
    const regex =
      Object.keys(regexes).find((regex) => new RegExp(regex).test(message)) ||
      '';
    humanizedMessage = regexes[regex];
  }

  if (!humanizedMessage) {
    humanizedMessage = message;
  }

  return humanizedMessage;
}
