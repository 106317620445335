import { css } from '@emotion/react';
import { ReactElement, ReactNode } from 'react';
import TopBar from 'src/components/App/MainView/TopBar';
import Sidebar from 'src/components/App/Sidebar';
import MainPaneLayout from 'src/layout/MainPaneLayout';

const container = css`
  position: relative;
  height: 100vh;
  width: 100vw;

  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
`;

export default function CurrentConsoleLayout({
  children
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div css={container}>
      <Sidebar />
      <MainPaneLayout>
        <TopBar />
        <MainPaneLayout.Content>{children}</MainPaneLayout.Content>
      </MainPaneLayout>
    </div>
  );
}
