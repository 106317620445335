import { pageAndOffsetToRow, ROWS_PER_PAGE, rowToPageAndOffset } from 'src/lib/query/RowCache/pagination';
import type { QueryRowCache } from 'src/lib/query/RowCache/QueryRowCache';
import { Row } from 'src/lib/query/RowCache/types';

interface LoadedChunk {
  page: number;
  rows: Row[];
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export class RowCursor {
  currentChunk: LoadedChunk | null = null;
  source: QueryRowCache;

  constructor(source: QueryRowCache) {
    this.source = source;
  }

  async getRow(rowIndex: number): Promise<Row | null> {
    const { page, offset } = rowToPageAndOffset(rowIndex);
    const chunkStart = pageAndOffsetToRow({ page, offset: 0 });

    if (!this.currentChunk || this.currentChunk.page !== page || offset >= this.currentChunk.rows.length) {
      while (!this.source.finished && rowIndex >= this.source.numRows) {
        await wait(1);
      }

      if (rowIndex >= this.source.numRows) {
        // query is finished and we don't have enough rows
        return null;
      }

      const chunkEnd = Math.min(this.source.numRows, pageAndOffsetToRow({ page, offset: ROWS_PER_PAGE }));

      this.currentChunk = {
        page,
        rows: await this.source.waitForRange(chunkStart, chunkEnd)
      };
    }

    return this.currentChunk.rows[offset] ?? null;
  }
}

export class RangeIterable implements AsyncIterable<Row> {
  private cursor: RowCursor;
  private startIndex: number;
  private endIndex?: number;

  constructor(cursor: RowCursor, startIndex: number, endIndex?: number) {
    this.cursor = cursor;
    this.startIndex = startIndex;
    this.endIndex = endIndex;
  }

  [Symbol.asyncIterator](): AsyncIterator<Row> {
    let i = this.startIndex ?? 0;

    return {
      next: async (): Promise<IteratorResult<Row>> => {
        if (this.endIndex !== undefined && i >= this.endIndex) {
          return { value: undefined, done: true };
        }

        const row = await this.cursor.getRow(i++);

        if (!row) {
          return { value: undefined, done: true };
        } else {
          return { value: row, done: false };
        }
      }
    };
  }
}

export class RowIndexIterable implements AsyncIterable<Row> {
  private cursor: RowCursor;
  private indexes: Iterator<number>;

  constructor(cursor: RowCursor, indexesIterable: Iterable<number>) {
    this.cursor = cursor;
    this.indexes = indexesIterable[Symbol.iterator]();
  }

  [Symbol.asyncIterator](): AsyncIterator<Row> {
    return {
      next: async (): Promise<IteratorResult<Row>> => {
        const { done, value: rowIndex } = this.indexes.next();

        if (done) {
          return { value: undefined, done: true };
        }

        const row = await this.cursor.getRow(rowIndex);

        if (!row) {
          return { value: undefined, done: true };
        } else {
          return { value: row, done: false };
        }
      }
    };
  }
}
