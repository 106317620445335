import { useMemo, forwardRef } from 'react';
import {
  AxisLabelsFormatterContext,
  TooltipFormmatterArgs,
  XYChart,
  XYSeriesDescriptor,
  xAxis,
  yAxis
} from '@clickhouse/viz-house';
import {
  AxisColumns,
  MaybeRange,
  useAxisConfig
} from 'src/components/primitives/lib/Charts/ChartUtils/axis';
import { useDataLimit } from 'src/components/primitives/lib/Charts/ChartUtils/hooks';
import ChartWrapper from 'src/components/primitives/lib/Charts/ChartWrapper';
import { useSeriesColors } from 'src/components/primitives/lib/Charts/ChartUtils/seriesColorsHook';

interface AxisConfig {
  column: string;
  color?: string;
}

interface VizHouseChartProps {
  chartType: 'bar' | 'area' | 'line' | 'scatter';
  footer?: string;
  layout?: 'vertical' | 'horizontal';
  data: object[];
  editable?: boolean;
  showLegend?: boolean;
  subtitle?: string;
  title?: string;
  xAxis?: AxisConfig[] | string;
  xAxisType?: 'category' | 'number';
  xAxisLabel?: string;
  xAxisTickVertical?: boolean;
  yAxis?: AxisColumns;
  yAxisType?: 'category' | 'number';
  yAxisLabel?: string;
  showDataLabel?: boolean;
  xAxisRange?: MaybeRange[];
  yAxisRange?: MaybeRange[];
  stacked?: boolean;
}

const VizHouseChart = forwardRef<HTMLDivElement, VizHouseChartProps>(
  (props, ref) => {
    const {
      chartType,
      footer,
      layout = 'vertical',
      data: dataProp = [],
      editable = false,
      showLegend = true,
      subtitle,
      title,
      xAxis,
      xAxisType = 'number',
      xAxisLabel,
      xAxisTickVertical = false,
      yAxis,
      yAxisType,
      yAxisLabel,
      xAxisRange: xAxisRangeProp,
      yAxisRange: yAxisRangeProp,
      stacked = false,
      showDataLabel
    } = props;

    const data = useDataLimit(dataProp);
    const getSeriesColors = useSeriesColors();

    const { xAxesConfigs, dataAxisConfig } = useAxisConfig(
      layout,
      data,
      xAxis ?? [],
      xAxisRangeProp,
      yAxis ?? [],
      yAxisType ?? '',
      yAxisRangeProp
    );

    const series: XYSeriesDescriptor[] = useMemo(() => {
      return dataAxisConfig.map((config, index) => ({
        name: config.dataKey,
        type:
          chartType === 'area'
            ? 'area'
            : chartType === 'line'
            ? 'line'
            : layout === 'horizontal'
            ? 'bar'
            : chartType === 'scatter'
            ? 'scatter'
            : 'column',
        values: data.map((item: any, idx: number) => ({
          x: idx,
          y: item[config.dataKey]
        })),
        ...getSeriesColors(config, index)
      }));
    }, [dataAxisConfig, chartType, layout, data, getSeriesColors]);

    const getXValue = (index: number): string => {
      return data[index]?.[xAxesConfigs[0]?.dataKey || ''];
    };

    const isHorizontalBar = chartType === 'bar' && layout === 'horizontal';
    const yTitle = yAxisLabel || (editable ? 'Enter y-axis label here' : '');
    const xTitle = xAxisLabel || (editable ? 'Enter x-axis label here' : '');
    const xMinRange = xAxisRangeProp?.[0]?.min;
    const xMaxRange = xAxisRangeProp?.[0]?.max;
    const yMinRange = yAxisRangeProp?.[0]?.min;
    const yMaxRange = yAxisRangeProp?.[0]?.max;

    // In case of horizontal bar chart, x-axis is vertical and y-axis is horizontal.
    // Also stacked horizontal bar chart doesn't support x-axis or y-axis range.
    const xAxisProps: xAxis = isHorizontalBar
      ? {
          title: yTitle,
          min: stacked ? undefined : yMinRange,
          max: stacked ? undefined : yMaxRange
        }
      : {
          title: xTitle,
          type: xAxisType === 'category' ? 'category' : 'linear',
          verticalLabels: xAxisTickVertical,
          labelsFormatter: (ctx: AxisLabelsFormatterContext): string => {
            const index = Number(ctx.value);
            return getXValue(index);
          },
          min: xMinRange,
          max: xMaxRange
        };

    const yAxisProps: yAxis = isHorizontalBar
      ? {
          title: xTitle,
          verticalLabels: xAxisTickVertical,
          min: stacked ? undefined : xMinRange,
          max: stacked ? undefined : xMaxRange
        }
      : {
          title: yTitle,
          min: yMinRange,
          max: yMaxRange
        };

    return (
      <ChartWrapper
        title={title}
        subtitle={subtitle}
        footer={footer}
        editable={editable}
      >
        <XYChart
          series={series}
          xAxis={xAxisProps}
          yAxis={yAxisProps}
          stacked={stacked}
          legend={series.length > 1 && showLegend} // Only show legend if there are multiple series
          tooltipFormatter={function (args: TooltipFormmatterArgs) {
            const index = Number(args.xValue);
            return `${getXValue(index)}<br/>${args.seriesName}: ${args.yValue}`;
          }}
          hasDataLabels={showDataLabel}
        />
      </ChartWrapper>
    );
  }
);

VizHouseChart.displayName = 'VizHouseChart';

export default VizHouseChart;
