import { MetricArgs, MetricReport, MetricType, TimePeriod } from '@cp/common/protocol/Metrics';
import { MILLIS_PER_MINUTE, MILLIS_PER_SECOND } from '@cp/common/utils/DateTimeUtils';
import { useEffect, useState } from 'react';
import config from 'src/lib/config';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { MetricsApiClient } from 'src/metrics/metricsApiClient';
import { MetricChartInput } from 'src/metrics/metricChart';
import { useUserFeature } from 'src/lib/features';
import { produce } from 'immer';
import { getMetricsChartInput } from 'src/metrics/metricChartInputs';
import { useMemo } from 'react';

const METRICS_REFRESH_INTERVAL_MILLIS = 2 * MILLIS_PER_MINUTE;
/** This value is only used when the code is run as a part of the Playwright tests: tests do not need to wait a long period. */
const METRICS_REFRESH_INTERVAL_IN_TESTS_MILLIS = 5 * MILLIS_PER_SECOND;

type FetchMetricsArgs = {
  api: MetricsApiClient;
  instanceId: string;
  organizationId: string;
  metricArgs: MetricArgs;
};

type FetchMetricsError = { error: string };
type FetchMetricsSuccess = { reports: Array<MetricReport> };
type FetchMetricsResult<T> = { type: MetricType } & T;

const fetchMetrics = async (
  args: FetchMetricsArgs
): Promise<FetchMetricsResult<FetchMetricsError> | FetchMetricsResult<FetchMetricsSuccess>> => {
  const { api, instanceId, organizationId, metricArgs } = args;
  try {
    const report = await api.getMetrics(instanceId, organizationId, metricArgs);
    switch (typeof report[0]) {
      case 'string':
        return { type: metricArgs.type, error: report[0] };
      default:
        return {
          type: metricArgs.type,
          reports: report as Array<MetricReport>
        };
    }
  } catch (e) {
    console.error(e);
    return { type: metricArgs.type, error: errorMessage(e) };
  }
};

type UseMetricsResult = {
  errors: Record<MetricType, string | undefined>;
  metrics: Record<MetricType, Array<MetricReport>>;
  loading: boolean;
  reload: (metricType: MetricType) => Promise<void>;
  metricChartInputs: Array<MetricChartInput>;
};

export const POLLING_INTERVAL_IN_MILLIS =
  config.env === 'test' ? METRICS_REFRESH_INTERVAL_IN_TESTS_MILLIS : METRICS_REFRESH_INTERVAL_MILLIS;

export function useMetrics(instanceId: string, organizationId: string, period: TimePeriod): UseMetricsResult {
  const [errors, setErrors] = useState<Record<MetricType, string | undefined>>({} as Record<MetricType, string>);
  const [metrics, setMetrics] = useState<Record<MetricType, Array<MetricReport>>>(
    {} as Record<MetricType, Array<MetricReport>>
  );
  const showMemoryResident = useUserFeature('SHOW_RESIDENT_MEMORY_USAGE');
  const api = useApiClient();

  const metricChartInputs: Array<MetricChartInput> = useMemo(() => {
    return getMetricsChartInput(period, showMemoryResident);
  }, [period, showMemoryResident]);

  const loading = Object.keys(metrics).length === 0 && Object.keys(errors).length === 0;

  function updateMetrics(
    result: FetchMetricsResult<FetchMetricsError> | FetchMetricsResult<FetchMetricsSuccess>
  ): void {
    if ('error' in result) {
      setErrors((prevErrors) => {
        return produce(prevErrors, (draft) => {
          draft[result.type] = result.error;
        });
      });
    } else if ('reports' in result) {
      setMetrics((prevMetrics) => {
        return produce(prevMetrics, (draft) => {
          draft[result.type] = result.reports;
        });
      });
      setErrors((prevErrors) => {
        return produce(prevErrors, (draft) => {
          draft[result.type] = undefined;
        });
      });
    }
  }

  useEffect(() => {
    async function loadAllMetrics(): Promise<void> {
      setErrors({} as Record<MetricType, string>);
      setMetrics({} as Record<MetricType, Array<MetricReport>>);
      const promises = metricChartInputs.map((metricChartInput) => {
        return fetchMetrics({
          api: api.metrics,
          instanceId,
          organizationId,
          metricArgs: metricChartInput.query
        });
      });

      const results = await Promise.all(promises);

      const errors = results.filter((result) => 'error' in result);
      const success = results.filter((result) => 'reports' in result);

      setErrors((prevErrors) => {
        return produce(prevErrors, (draft) => {
          for (const error of errors) {
            draft[error.type] = error.error;
          }
        });
      });

      setMetrics((prevMetrics) => {
        return produce(prevMetrics, (draft) => {
          for (const successResult of success) {
            draft[successResult.type] = successResult.reports;
          }
        });
      });
    }

    const interval = setInterval(() => {
      loadAllMetrics().catch(console.error);
    }, POLLING_INTERVAL_IN_MILLIS);

    loadAllMetrics().catch(console.error);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [api.metrics, instanceId, organizationId, metricChartInputs]);

  const reload = async (metricType: MetricType): Promise<void> => {
    const metricChartInput = metricChartInputs.find((metricChartInput) => metricChartInput.query.type === metricType);
    if (!metricChartInput) {
      console.error(`Failed to reload metrics: unknown metric type ${metricType}`);
      return;
    }

    const result = await fetchMetrics({
      api: api.metrics,
      instanceId,
      organizationId,
      metricArgs: metricChartInput.query
    });
    updateMetrics(result);
  };

  return {
    loading,
    errors,
    metrics,
    reload,
    metricChartInputs
  };
}

export type MetricsFeedback = {
  loading: boolean;
  result: MetricsFeedbackResult | undefined;
  sendMetricsFeedback: (feedbackText: string, recaptchaToken: string) => Promise<void>;
};

type MetricsFeedbackResult = {
  successful: boolean;
  message: string;
};

export const useMetricsFeedback = (): MetricsFeedback => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<MetricsFeedbackResult | undefined>(undefined);

  const feedbackErrorMessage = (e: unknown): string => {
    const errorCode = errorMessage(e);

    switch (errorCode) {
      case 'FAILED_RECAPTCHA':
        return 'Submitting your request failed the recaptcha challenge, please try again later';
      default:
        return 'There was a problem submitting your request, please try again later';
    }
  };

  const sendMetricsFeedback = async (feedbackText: string, recaptchaToken: string): Promise<void> => {
    setLoading(true);
    try {
      await api.metrics.sendFeedback(feedbackText, recaptchaToken);
      setResult({
        successful: true,
        message: 'Thank you for your feedback!'
      });
    } catch (e) {
      console.error(e);
      setResult({
        successful: false,
        message: feedbackErrorMessage(e)
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    result,
    sendMetricsFeedback
  };
};
