import {
  Badge,
  Container,
  Dropdown,
  EllipsisContent,
  IconButton,
  Table,
  Text
} from '@clickhouse/click-ui';
import {
  OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS,
  OpenapiKey,
  OpenapiKeyRole,
  isExpiredOpenapiKey
} from '@cp/common/protocol/OpenapiKey';
import capitalize from 'lodash/capitalize';
import { ReactElement } from 'react';
import { canDelete, canEdit, canToggle } from 'src/apiKey/apiKeyController';
import { User } from 'src/lib/auth/types';
import { formatDateHuman } from 'src/lib/formatters/dateTimeFormatter';

const devTableHeaders = [
  {
    label: 'API key'
  },
  { label: 'Role', width: '10%' },
  { label: 'Status', width: '10%' },
  { label: 'Expires', width: '14%' },
  { label: 'Last used', width: '14%' },
  { label: 'Created on' }
];

const adminTableHeaders = [
  ...devTableHeaders,
  { label: 'Actions', width: '8%' }
];

interface KeysListProps {
  currentUser: User | null;
  keys: OpenapiKey[];
  openDeleteKeyModal: (key: OpenapiKey) => void;
  openEditKeyModal: (key: OpenapiKey) => void;
  role: OpenapiKeyRole;
  toggleEnabled: (args: {
    i: number;
    id: string;
    state: 'enabled' | 'disabled';
  }) => void;
}

export default function KeysList({
  currentUser,
  openDeleteKeyModal,
  openEditKeyModal,
  keys,
  role,
  toggleEnabled
}: KeysListProps): ReactElement {
  return (
    <Table
      headers={adminTableHeaders}
      rows={keys.map((key, i) => {
        const {
          creationDate,
          createdByDetails,
          expirationDate,
          id,
          keySuffix,
          name,
          roles,
          state,
          useDate
        } = key;
        const formattedRole = capitalize(roles.toString());
        const isExpired = isExpiredOpenapiKey(key);
        const items = [
          {
            label: (
              <Container
                alignItems="start"
                orientation="vertical"
                fillWidth={false}
              >
                <Text>{name}</Text>
                <Text color="muted">*********{keySuffix}</Text>
              </Container>
            )
          },
          {
            label: formattedRole
          },
          {
            label: (
              <Badge
                text={isExpired ? 'Expired' : capitalize(state)}
                state={
                  //prettier-ignore
                  isExpired
                    ? 'danger'
                    : state === 'enabled'
                      ? 'success'
                      : 'warning'
                }
              />
            )
          },
          {
            label: (
              <Text color={isExpired ? 'danger' : 'default'}>
                {expirationDate === OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS
                  ? 'Never'
                  : formatDateHuman(expirationDate)}
              </Text>
            )
          },
          {
            label: useDate ? formatDateHuman(useDate) : 'Never'
          },
          {
            label: (
              <Container
                alignItems="start"
                orientation="vertical"
                fillWidth={false}
              >
                <Text>{formatDateHuman(creationDate)}</Text>
                <EllipsisContent>
                  <Text color="muted" className="fs-exclude">
                    {createdByDetails}
                  </Text>
                </EllipsisContent>
              </Container>
            )
          }
        ];

        const userCanDelete = canDelete(key, currentUser, role);
        const userCanEdit = canEdit(key, currentUser, role);
        const userCanToggle = canToggle(key, currentUser, role);

        if (userCanEdit || userCanDelete) {
          items.push({
            label: (
              <Dropdown>
                <Dropdown.Trigger>
                  <IconButton
                    data-testid={`api-key-actions-${i}`}
                    icon="dots-vertical"
                  />
                </Dropdown.Trigger>
                <Dropdown.Content showArrow>
                  <Dropdown.Item
                    data-testid={`api-key-actions-${i}-edit`}
                    disabled={!userCanEdit}
                    onClick={(): void => {
                      openEditKeyModal(key);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-testid={`api-key-actions-${i}-toggle`}
                    disabled={!userCanToggle}
                    onClick={(): void => {
                      toggleEnabled({ i, id, state });
                    }}
                  >
                    {state === 'enabled' ? 'Disable' : 'Enable'} Key
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-testid={`api-key-actions-${i}-delete`}
                    disabled={!userCanDelete}
                    onClick={(): void => {
                      openDeleteKeyModal(key);
                    }}
                  >
                    Delete Key
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown>
            )
          });
        }

        return {
          id,
          items
        };
      })}
    />
  );
}
