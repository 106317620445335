import React from 'react';

import { css } from '@emotion/react';

const containerStyle = (theme) => css`
  padding: 5px;

  background-color: ${theme.global.color.background.default} !important;
  border: 1px solid ${theme.global.color.stroke.default} !important;
  box-shadow: 0px 24px 34px ${theme.global.color.shadow.default} !important;
  border-radius: 4px;
`;

const entryStyle = (color) => css`
  color: ${color};
`;

export default function Tooltip({ active, categories, payload, label }) {
  return (
    <div css={containerStyle}>
      {payload.map((entry, index) => (
        <div css={entryStyle(entry.payload.fill)} key={index}>
          <div>
            {entry.payload[categories]}: {entry.value}
          </div>
        </div>
      ))}
    </div>
  );
}
