import { BrowserRouter, useSearchParams } from 'react-router-dom';
import { init as initFullStory } from '@fullstory/browser';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
import App from 'src/App';
import config from 'src/lib/config';
import 'src/lib/datadog';
import 'src/index.css';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useTackleTokenFromAppState } from 'src/lib/tackle/useTackleTokenFromAppState';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { getGrowthBook } from 'src/lib/growthbook/growthbook';

const root = createRoot(document.getElementById('root') as HTMLElement);

if (config.env === 'production') {
  initFullStory({ orgId: config.fullStory.orgId });
}

function AppWithAuth0Provider(): JSX.Element {
  const addTackleSubscriptionFromAppState = useTackleTokenFromAppState();
  const [params] = useSearchParams();
  const enableDevMode = params.has('gbdev');
  const growthbook = getGrowthBook(enableDevMode);

  const withParam = params.get('with');
  let connection = undefined;
  if (withParam === 'email') {
    connection = config.auth0Web.databaseConnectionName;
  }

  return (
    <Auth0Provider
      cookieDomain={config.auth0Web.cookieDomain}
      domain={config.auth0Web.domain}
      cacheLocation="localstorage"
      clientId={config.auth0Web.clientId}
      onRedirectCallback={(appState?: AppState): void => {
        addTackleSubscriptionFromAppState(appState);
        if (appState?.returnTo) {
          navigateTo(appState.returnTo);
        }
      }}
      authorizationParams={{
        audience: config.auth0Web.audience,
        redirect_uri: `${window.location.origin}${config.auth0Web.callbackPath}`,
        connection
      }}
    >
      <GrowthBookProvider growthbook={growthbook}>
        <App />
      </GrowthBookProvider>
    </Auth0Provider>
  );
}

root.render(
  <BrowserRouter>
    <AppWithAuth0Provider />
  </BrowserRouter>
);
