import { useTheme } from '@emotion/react';
import { SpreadsheetLoader } from 'primitives';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';

import { Row } from 'shared/src/clickhouse/types';
import { useRefCallback } from 'src/lib/hooks';
import { v4 as uuid } from 'uuid';

import { useClasses } from 'src/components/primitives/lib/utils';

import { useBounds } from 'src/components/primitives/lib/Spreadsheet/bounds';
import { copyRowsAndNotify } from 'src/components/primitives/lib/Spreadsheet/copyTable';
import GridFooter from 'src/components/primitives/lib/Spreadsheet/GridFooter';
import GridMain from 'src/components/primitives/lib/Spreadsheet/GridMain';
import {
  cellSelected,
  emptySelection,
  rowsInSelection,
  singleCellSelected
} from 'src/components/primitives/lib/Spreadsheet/selectionActions';
import { noRow, root } from 'src/components/primitives/lib/Spreadsheet/styles';
import type {
  CellProps,
  HotkeysHandlers,
  MaybeRow,
  MenuOption,
  Option,
  SelectedRegion,
  SelectionPos,
  SpreadsheetProps
} from 'src/components/primitives/lib/Spreadsheet/types';

export const Spreadsheet = function Spreadsheet({
  classes: externalClasses = {},
  contextMenuOptions = [],
  columns,
  numRows,
  getRow,
  onColumnResize,
  hideRowCount,
  loading,
  onVisibleRowsChanged,
  onHeaderClick,
  onHeaderRightClick,
  onPaginationStateChange,
  pagination,
  showFooter,
  paginationState,
  pageSize,
  showRowNumber,
  filterApplied,
  hideBorder,
  monospace,
  totalRows,
  selectedRow,
  onChangeFocus: onChangeFocusProp,
  selectedColumn,
  paginationSelector,
  unknownTotalPages,
  log: logProp,
  getRowsByIndex: getRowsByIndexProp,
  onEditCell,
  totals,
  ...otherProps
}: SpreadsheetProps): JSX.Element {
  const theme = useTheme();
  if (totalRows === undefined) {
    totalRows = numRows;
  }
  const [focus, setFocus] = useState<SelectionPos>({
    row: selectedRow || 0,
    column: selectedColumn || 0
  });

  const [selection, setSelection] = useState<SelectedRegion>(emptySelection());

  const log = useRefCallback(logProp);

  const selectionRef = useRef<SelectedRegion>(selection);
  selectionRef.current = selection;

  const id = useRef(uuid());

  const propClasses = useClasses(theme, externalClasses);

  const pageStart = pagination
    ? (pageSize || 0) * ((paginationState?.currentPage || 1) - 1)
    : 0;

  const pageEnd = totalRows
    ? pagination
      ? Math.min(pageStart + (pageSize || 0), totalRows)
      : totalRows
    : pageStart + (pageSize || 0);

  const rowsPerPage = pageSize || numRows;

  const bounds = useBounds(columns.length, pageStart, rowsPerPage);

  const keepInBounds = useCallback(
    ({ row, column }: { row: number; column: number }) => {
      return {
        row: Math.max(pageStart, Math.min(pageEnd - 1, row)),
        column: Math.max(0, Math.min(columns.length - 1, column))
      };
    },
    [pageStart, pageEnd, columns]
  );

  const getRowsByIndex = useCallback(
    async (rowIndexes: Iterable<number>): Promise<MaybeRow[]> => {
      if (getRowsByIndexProp) {
        return getRowsByIndexProp(rowIndexes);
      } else {
        return [...rowIndexes].map((idx) => getRow(idx));
      }
    },
    [getRowsByIndexProp, getRow]
  );

  const onChangeFocus = useCallback(
    (newFocus: SelectionPos) => {
      const corrected = keepInBounds(newFocus);
      setFocus(corrected);
      if (onChangeFocusProp) {
        onChangeFocusProp(corrected);
      }
      return corrected;
    },
    [keepInBounds]
  );

  const singleCellIsFocused = useMemo(() => {
    const row = getRow(focus.row);
    return Boolean(
      row &&
        (selection.type === 'empty' ||
          (selection.type === 'rectangle' &&
            selection.bounds.left === selection.bounds.right &&
            selection.bounds.top === selection.bounds.bottom))
    );
  }, [selection, focus, getRow]);

  const copySelection = useCallback(async () => {
    const rowIndexes = rowsInSelection(selectionRef.current, bounds);

    const rows = await getRowsByIndex(rowIndexes);
    const selectedCells: Row[] = [];
    let i = 0;

    for (const rowIndex of rowIndexes) {
      const row = rows[i++];
      if (row) {
        const selected = row.filter((_, colIndex) => {
          return cellSelected(selectionRef.current, colIndex, rowIndex);
        });
        selectedCells.push(selected);
      }
    }

    copyRowsAndNotify(selectedCells);
  }, [getRowsByIndex, selection, bounds]);

  const hotKeysHandlers = useMemo((): HotkeysHandlers => {
    return {
      'ctrl+c': () => copySelection(),
      'meta+c': () => copySelection()
    };
  }, [copySelection]);

  const getMenuOptions = useRefCallback((cells: SelectedRegion) => {
    let singleFocusedCell: CellProps | undefined;

    const singleCellPosition = singleCellSelected(cells);
    if (singleCellPosition) {
      const row = getRow(singleCellPosition.row);
      if (row) {
        singleFocusedCell = {
          columnIndex: singleCellPosition.column,
          rowIndex: singleCellPosition.row,
          rowData: row,
          value: row[singleCellPosition.column]
        };
      }
    }

    const menuOptions: Option[] = [
      {
        label: 'Copy',
        onClick: () => {
          copySelection()
            .then(() => {
              log(
                singleFocusedCell
                  ? 'cellContextMenuCopy'
                  : 'cellRangeContextMenuCopy',
                'click'
              );
            })
            .catch(console.error);
        }
      }
    ];

    const visibleEntries = contextMenuOptions.filter(
      (entry) => !entry.hide || !entry.hide({ focusedCell: singleFocusedCell })
    );

    const customEntries: Option[] = visibleEntries.map((entry: MenuOption) => {
      const onClick = () => {
        entry.callback({ focusedCell: singleFocusedCell });

        if (entry.label && typeof entry.label === 'string') {
          const eventName =
            typeof entry.label === 'string'
              ? entry.label.replace(/ /g, '')
              : 'Option';
          log(
            singleFocusedCell
              ? `cellContextMenu${eventName}`
              : `cellRangeContextMenu${eventName}`,
            'click'
          );
        }
      };

      return {
        label: entry.label,
        hotKey: entry.hotKey,
        onClick
      };
    });

    return [...menuOptions, ...customEntries];
  });

  const onSelectionContextMenu = useRefCallback((open: boolean) => {
    const eventName = !singleCellIsFocused
      ? 'cellRangeContextMenu'
      : 'cellContextMenu';
    log(open ? `${eventName}Open` : `${eventName}Blur`, 'trigger');
  });

  const gridMain = useMemo(() => {
    return (
      <GridMain
        getMenuOptions={getMenuOptions}
        hotKeysHandlers={hotKeysHandlers}
        currentPage={paginationState?.currentPage || 1}
        rowsPerPage={rowsPerPage}
        pagination={!!pagination}
        numRows={numRows}
        getRow={getRow}
        columns={columns}
        onColumnResize={onColumnResize}
        focus={focus}
        selection={selection}
        onChangeFocus={onChangeFocus}
        onVisibleRowsChanged={onVisibleRowsChanged}
        filterApplied={!!filterApplied}
        showRowNumber={showRowNumber}
        hideBorder={!!hideBorder}
        onHeaderClick={onHeaderClick}
        onHeaderRightClick={onHeaderRightClick}
        setSelection={(newSelection) => {
          setSelection(newSelection);
          if (typeof newSelection !== 'function') {
            selectionRef.current = newSelection;
          } else {
            selectionRef.current = newSelection(selection);
          }
        }}
        onSelectionContextMenu={onSelectionContextMenu}
        log={log}
        onEditCell={onEditCell}
        totals={totals}
      />
    );
  }, [
    pagination,
    numRows,
    paginationState?.currentPage,
    columns,
    getRow,
    selection,
    onChangeFocus,
    focus,
    setSelection,
    getMenuOptions,
    filterApplied,
    hideBorder,
    onColumnResize,
    onHeaderClick,
    onHeaderRightClick,
    onVisibleRowsChanged,
    showRowNumber,
    onSelectionContextMenu,
    log,
    hotKeysHandlers,
    rowsPerPage,
    onEditCell
  ]);

  interface LoadPageProps {
    currentPage: number;
    pageSize: number;
    offset: number;
  }
  const onLoadPage = useCallback(
    ({ currentPage, pageSize }: LoadPageProps) => {
      if (onPaginationStateChange) {
        onPaginationStateChange({
          currentPage,
          pageSize
        });
      }
    },
    [onPaginationStateChange]
  );

  return (
    <div
      id={`sp-${id.current}`}
      css={[root, propClasses.root]}
      data-monospace={monospace}
      data-testid="spreadsheet"
      {...otherProps}
    >
      {!loading && numRows === 0 ? (
        <div
          css={noRow}
          style={pagination ? { height: 'calc(100% - 30px)' } : {}}
        >
          {filterApplied
            ? 'No records found matching specified filters'
            : 'No records found in this table'}
          .
        </div>
      ) : loading ? (
        <SpreadsheetLoader columns={columns.length > 0 ? columns.length : 5} />
      ) : (
        gridMain
      )}
      {(pagination || showFooter) && (
        <GridFooter
          paginationState={paginationState}
          onLoadPage={onLoadPage}
          hideRowCount={!!hideRowCount}
          pageSize={pagination ? pageSize || 0 : 0}
          filterApplied={!!filterApplied}
          unknownTotalPages={unknownTotalPages}
          numRows={numRows}
          paginationSelector={paginationSelector}
          log={log}
        />
      )}
    </div>
  );
};

Spreadsheet.displayName = 'Spreadsheet';

const Wrapped: FC<SpreadsheetProps> = memo(Spreadsheet);
export default Wrapped;
export { default as usePaginationState } from 'src/components/primitives/lib/Spreadsheet/usePaginationState';
