import { isSamlUserId } from '@cp/common/utils/AuthUtils';
import { ReactElement, useState } from 'react';
import {
  Badge,
  Button,
  ConfirmationDialog,
  Container,
  Icon,
  IconButton,
  Panel,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { Navigate } from 'react-router-dom';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';

import {
  Section,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import { MediumWidthContainer } from 'src/components/MediumWidthContainer';

type SecurityPageProps = {
  // Flag indicating if MFA is enabled.
  isMfaEnabled: boolean;
  // Callback to load the QR code.
  onSetup: () => Promise<void>;
  // Callback to enable MFA.
  onEnable: () => Promise<void>;
  // Callback to disable MFA.
  onDisable: () => Promise<void>;
};

const SecurityPage = ({
  isMfaEnabled,
  onSetup,
  onEnable,
  onDisable
}: SecurityPageProps): ReactElement | null => {
  const currentUser = useCurrentUserOrThrow();
  const isSAMLUser = isSamlUserId(currentUser.id);
  const [removeConfirmationDialogOpen, setRemoveConfirmationDialogOpen] =
    useState(false);

  if (isSAMLUser) {
    return <Navigate to="/" replace />;
  }

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Security" />
      <MainPaneLayout.Content>
        <MediumWidthContainer
          orientation="vertical"
          padding="lg"
          alignItems="start"
          data-testid="security-page"
        >
          <Section>
            <Subsection>
              <SubsectionDescription>
                <SubsectionTitle>Multi-factor authentication</SubsectionTitle>
                <Spacer />

                <Text color="muted">
                  Add an extra layer of security to your account. To sign in,
                  you'll need to provide a code along with your email address
                  and password.
                </Text>
              </SubsectionDescription>

              <SubsectionControls>
                <Panel
                  alignItems="start"
                  color="default"
                  hasBorder
                  orientation="horizontal"
                >
                  <Container grow="0" fillWidth={false}>
                    <Icon name="auth-app" size="xxl" />
                  </Container>
                  <Container
                    orientation="vertical"
                    grow="2"
                    maxWidth="65%"
                    alignItems="start"
                    fillWidth={false}
                  >
                    <Container
                      padding="none"
                      fillWidth
                      justifyContent="space-between"
                    >
                      <Title size="xs" type="h3">
                        Authenticator app
                      </Title>
                      {isMfaEnabled && (
                        <IconButton
                          icon="trash"
                          size="sm"
                          data-testid="remove-mfa-icon"
                          onClick={(): void => {
                            setRemoveConfirmationDialogOpen(true);
                          }}
                        />
                      )}
                    </Container>

                    <Spacer size="sm" />
                    <Text color="muted" size="md">
                      Generate one time security codes from an app like Authy,
                      1Password or Google Authenticator{' '}
                    </Text>
                    <Spacer size="md" />
                    {isMfaEnabled ? (
                      <Badge state="success" text="Configured" icon="check" />
                    ) : (
                      <Button
                        type="secondary"
                        data-testid="setup-mfa-button"
                        onClick={(): void => {
                          onSetup().then(onEnable).catch(console.error);
                        }}
                      >
                        Set up
                      </Button>
                    )}
                  </Container>
                </Panel>
              </SubsectionControls>
            </Subsection>
          </Section>
        </MediumWidthContainer>
      </MainPaneLayout.Content>
      <ConfirmationDialog
        title="Remove authenticator app?"
        open={removeConfirmationDialogOpen}
        onCancel={(): void => {
          setRemoveConfirmationDialogOpen(false);
        }}
        onConfirm={(): void => {
          setRemoveConfirmationDialogOpen(false);
          void onDisable();
        }}
        primaryActionLabel="Remove authenticator app"
        secondaryActionLabel="Cancel"
      >
        After removing the authenticator app, your account will not have
        multi-factor authentication enabled.
      </ConfirmationDialog>
    </MainPaneLayout>
  );
};

export default SecurityPage;
