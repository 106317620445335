import {
  CellProps,
  Container,
  Grid,
  GridContextMenuItemProps,
  Pagination,
  SelectionAction,
  SelectedRegion,
  SelectionFocus,
  Separator,
  Text,
  Tooltip,
  createToast
} from '@clickhouse/click-ui';
import {
  FocusEventHandler,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useRef,
  useState
} from 'react';
import { RightBarOption } from 'src/state/tabs/types';
import { RunningQueryColumn } from 'src/lib/query/runningQueryTypes';
import { Row } from 'shared/src/clickhouse';
import { PaginationState } from 'src/components/primitives/lib/Spreadsheet/types';
import { logEvent } from 'src/components/QueryView/analytics';
import { SpreadsheetLoader } from 'src/components/primitives';
import { EllipsisContainer } from 'src/layout/GlobalStyles';
import copyGridElements from 'src/components/QueryView/Results/QueryResults/copyGridElements';
import { RowIteratorFunction } from 'src/lib/query/QueryState/useResultsView';
import { getValueOfCell } from 'src/lib/grid/getValueOfCell';

type SelectionPos = {
  row: number;
  column: number;
};

interface GridOnItemsRenderedProps {
  overscanColumnStartIndex: number;
  overscanColumnStopIndex: number;
  overscanRowStartIndex: number;
  overscanRowStopIndex: number;
  visibleColumnStartIndex: number;
  visibleColumnStopIndex: number;
  visibleRowStartIndex: number;
  visibleRowStopIndex: number;
}

const maxRowsPerPageList = [250, 100, 50];

const onPrevPageClick: MouseEventHandler<HTMLButtonElement> = () => {
  logEvent('spreadsheetFooter', 'paginationBackwardButtonClick', 'click');
};

const onNextPageClick: MouseEventHandler<HTMLButtonElement> = () => {
  logEvent('spreadsheetFooter', 'paginationForwardButtonClick', 'click');
};

const onPageNumberFocus: FocusEventHandler<HTMLInputElement> = () => {
  logEvent('spreadsheetFooter', 'paginationFocus', 'trigger');
};

const onPageNumberBlur: FocusEventHandler<HTMLInputElement> = () => {
  logEvent('spreadsheetFooter', 'paginationBlur', 'trigger');
};

const onSelect = (action: SelectionAction): void => {
  logEvent('resultSpreadsheet', action.type, action.event ?? 'click');
};
interface ConsoleQueryResultsTableProps {
  paginationState?: PaginationState;
  onPaginationChange: (props: {
    currentPage?: number;
    pagSize?: number;
  }) => void;
  onPaginationSizeChange: (size: number) => void;
  updateRightBar: (type: RightBarOption, value: string) => void;
  columns?: RunningQueryColumn[];
  getRow: (index: number) => null | Row;
  selectedRow: number;
  selectedColumn: number;
  filterApplied: boolean;
  numRows: number;
  totals?: Row;
  onChangeFocus: (selectionPos: SelectionPos) => void;
  columnWidths: Record<string, number>;
  onColumnResize: (widths: Record<string, number>) => void;
  loading: boolean;
  pageSize: number;
  visibleRowsChanged: (start: number, end: number) => void;
  rowIterator: RowIteratorFunction;
}

const ConsoleQueryResultsTable = ({
  paginationState,
  onPaginationChange,
  onPaginationSizeChange,
  columns,
  getRow: getRowProp,
  updateRightBar,
  selectedRow,
  selectedColumn,
  filterApplied,
  numRows,
  totals,
  onChangeFocus,
  columnWidths,
  onColumnResize: onColumnResizeProp,
  loading,
  pageSize: pageSizeProp,
  visibleRowsChanged,
  rowIterator
}: ConsoleQueryResultsTableProps): ReactElement => {
  const outerRef = useRef<HTMLDivElement>(null);
  const currentPage = paginationState?.currentPage ?? 1;
  const paginationPageSize = pageSizeProp < 1 ? undefined : pageSizeProp;
  const pageSize = paginationPageSize ?? totals?.length ?? numRows;
  const page = paginationState?.currentPage ?? 1;
  const rowStart = pageSize ? pageSize * (page - 1) + 1 : 1;
  const rowsLength =
    pageSize < numRows
      ? numRows - rowStart + 1
      : numRows ?? totals?.length ?? 0;
  const [selection, setSelection] = useState<SelectedRegion>({
    type: 'empty'
  });

  const getRowsByIndex = useCallback(
    async (rowIndexes: Iterable<number>) => {
      const rows: Row[] = [];
      for await (const row of await rowIterator(rowIndexes)) {
        rows.push(row);
      }
      return rows;
    },
    [rowIterator]
  );

  const getRow = useCallback(
    (i: number) => {
      if (i < numRows) {
        return getRowProp(i);
      } else if (i === numRows && totals) {
        return totals;
      } else {
        return null;
      }
    },
    [getRowProp, totals, numRows]
  );

  const cellValue = useCallback(
    (rowIndex: number, columnIndex: number): null | string => {
      const row = getRow(rowIndex - 1);
      return row?.[columnIndex] ?? null;
    },
    [getRow]
  );

  const columnWidth = useCallback(
    (columnIndex: number): number => {
      const columnName = columns?.[columnIndex]?.name;
      if (!columnName) {
        return 100;
      }
      return columnWidths[columnName] ?? 100;
    },
    [columnWidths, columns]
  );

  const onColumnResize = useCallback(
    (columnIndex: number, newWidth: number) => {
      const column = columns?.[columnIndex];
      if (!column) {
        return;
      }

      columnWidths[column.name] = newWidth;
      onColumnResizeProp(columnWidths);
    },
    [columnWidths, columns, onColumnResizeProp]
  );

  const onCopy = useCallback(
    async (selection: SelectedRegion, focus: SelectionFocus): Promise<void> => {
      try {
        await copyGridElements({
          selection,
          rowCount: numRows,
          columnCount: columns?.length ?? 0,
          getRowsByIndex,
          focus,
          outerRef
        });
        createToast({
          title: 'Copied successfully',
          description: 'Now you can paste the content',
          type: 'success'
        });
      } catch (error) {
        console.log(error);
        createToast({
          title: 'Failed to copy',
          description:
            'Encountered an error while copying. Try again after sometime',
          type: 'danger'
        });
      }

      logEvent(
        'spreadsheetFooter',
        selection.type === 'empty'
          ? 'cellContextMenuCopy'
          : 'cellRangeContextMenuCopy',
        'click'
      );
    },
    [columns?.length, numRows, getRowsByIndex]
  );

  const onEditCell = useCallback(
    (rowIndex: number, columnIndex: number) => {
      const value = cellValue(rowIndex, columnIndex);
      const isFilled = typeof value === 'string' && value.length > 0;

      if (isFilled) {
        updateRightBar('viewCell', String(value));
      }
    },
    [updateRightBar, cellValue]
  );

  const onFocusChange = useCallback(
    (row: number, column: number) => {
      onChangeFocus({ row, column });
    },
    [onChangeFocus]
  );

  const onGridSelect = useCallback(
    (action: SelectionAction, selectedRegion: SelectedRegion): void => {
      onSelect(action);
      setSelection(selectedRegion);
    },
    []
  );

  const getMenuOptions = useCallback(
    (
      selection: SelectedRegion,
      focus: SelectionFocus
    ): Array<GridContextMenuItemProps> => {
      if (selection.type !== 'empty') {
        return [];
      }

      const value = cellValue(focus.row, focus.column);
      const items = [];
      if (typeof value !== 'string') {
        return [];
      }

      items.push({
        label: 'Inspect Cell',
        onSelect: (): void => {
          updateRightBar('viewCell', value);
          logEvent('spreadsheetFooter', 'cellContextMenuInspectCell', 'click');
        }
      });

      return items;
    },
    [cellValue, updateRightBar]
  );

  const onCellClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      if (e.detail > 1) {
        const cell = (e.target as HTMLElement).closest<HTMLElement>(
          '[data-grid-row][data-grid-column]'
        );
        if (cell && cell.dataset.gridRow && cell.dataset.gridColumn) {
          onEditCell(
            Number(cell.dataset.gridRow),
            Number(cell.dataset.gridColumn)
          );
        }
      }
    },
    [onEditCell]
  );

  const onContextMenu: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const eventName =
        selection.type === 'empty' ? 'cellContextMenu' : 'cellRangeContextMenu';
      logEvent('spreadsheetFooter', `${eventName}Open`, 'trigger');
      onCellClick(e);
    },
    [onCellClick, selection.type]
  );

  const Cell: CellProps = ({
    rowIndex,
    columnIndex,
    type,
    isScrolling,
    width,
    ...props
  }) => {
    if (!columns) {
      return null;
    }
    if (type === 'header-cell') {
      const name = columns[columnIndex].name;
      const type = columns[columnIndex].type;
      return (
        <Tooltip>
          <Tooltip.Trigger data-scrolling={isScrolling} {...props}>
            <EllipsisContainer>{name}</EllipsisContainer>
          </Tooltip.Trigger>
          <Tooltip.Content side="bottom">
            {name} - {type}
          </Tooltip.Content>
        </Tooltip>
      );
    }

    const isFocused =
      rowIndex === selectedRow && columnIndex === selectedColumn;

    return (
      <span
        data-scrolling={isScrolling}
        {...props}
        data-testid={`cell-${columnIndex}-${rowIndex}`}
      >
        {getValueOfCell(cellValue(rowIndex, columnIndex), isFocused, width)}
      </span>
    );
  };
  const onPaginationNumberChange = (pageNumber: number): void => {
    if (
      pageNumber < 1 ||
      !paginationState?.totalPages ||
      pageNumber > paginationState?.totalPages
    ) {
      return;
    }
    onPaginationChange({ currentPage: pageNumber });
  };

  const onItemsRendered = ({
    overscanRowStartIndex,
    overscanRowStopIndex
  }: GridOnItemsRenderedProps): void => {
    visibleRowsChanged(overscanRowStartIndex, overscanRowStopIndex);
  };

  const lastRow = getRow(rowsLength < pageSize ? rowsLength - 1 : pageSize + 1);

  if (rowsLength === 0) {
    return (
      <Container
        fillHeight
        orientation="vertical"
        grow="1"
        fillWidth
        justifyContent="center"
        data-testid="queryResultsTable"
        className="fs-exclude"
      >
        <Container
          grow="1"
          fillWidth
          justifyContent={loading ? 'start' : 'center'}
          alignItems="start"
          isResponsive={false}
        >
          {loading ? (
            <Container
              gap="xs"
              fillWidth={false}
              alignItems="start"
              justifyContent="start"
              isResponsive={false}
            >
              <SpreadsheetLoader columns={columns?.length ?? 5} />
            </Container>
          ) : (
            <Text>
              {filterApplied
                ? 'No records found matching specified filters'
                : 'No records found'}
            </Text>
          )}
        </Container>

        {currentPage !== 1 && (
          <>
            <Separator size="xs" />
            <Pagination
              currentPage={currentPage}
              pageSize={paginationPageSize}
              onChange={onPaginationNumberChange}
              padding="md"
              onPageNumberBlur={onPageNumberBlur}
              onPageNumberFocus={onPageNumberFocus}
              onPrevPageClick={onPrevPageClick}
              onNextPageClick={onNextPageClick}
              totalPages={paginationState?.totalPages}
              maxRowsPerPageList={maxRowsPerPageList}
              onPageSizeChange={onPaginationSizeChange}
              data-testid="pagination-container"
              disableNextButton
            />
          </>
        )}
      </Container>
    );
  }

  return (
    <Container
      orientation="vertical"
      grow="1"
      fillHeight
      data-testid="queryResultsTable"
      className="fs-exclude"
    >
      <Container
        orientation="vertical"
        alignItems="start"
        grow="1"
        data-testid="spreadsheet"
      >
        <Grid
          rowStart={rowStart}
          columnCount={columns?.length ?? 0}
          rowCount={rowsLength < pageSize ? rowsLength : pageSize}
          cell={Cell}
          getMenuOptions={getMenuOptions}
          focus={{
            row: selectedRow > 0 ? selectedRow : rowStart,
            column: selectedColumn
          }}
          onFocusChange={onFocusChange}
          onMouseDown={onCellClick}
          onContextMenu={onContextMenu}
          onSelect={onGridSelect}
          onCopy={onCopy}
          columnWidths={columnWidth}
          onColumnResize={onColumnResize}
          onItemsRendered={onItemsRendered}
          showToast={true}
        />
      </Container>
      <Separator size="xs" />
      <Pagination
        totalPages={paginationState?.totalPages}
        currentPage={currentPage}
        pageSize={paginationPageSize}
        rowCount={paginationState?.totalRows ?? numRows}
        onChange={onPaginationNumberChange}
        onPageSizeChange={onPaginationSizeChange}
        padding="md"
        onPageNumberBlur={onPageNumberBlur}
        onPageNumberFocus={onPageNumberFocus}
        onPrevPageClick={onPrevPageClick}
        onNextPageClick={onNextPageClick}
        maxRowsPerPageList={maxRowsPerPageList}
        data-testid="pagination-container"
        disableNextButton={lastRow === undefined}
      />
      <Container ref={outerRef} />
    </Container>
  );
};

export default ConsoleQueryResultsTable;
