import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { createToast } from 'primitives';
import SqlEditorWithAutocomplete from 'primitives/lib/SqlEditorWithAutoComplete';
import { Resizable } from 're-resizable';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { DatabaseResult } from 'shared/src/clickhouse';

import { JSONObjectValue } from 'shared/src/json';
import { parseQueryVariables } from 'shared/src/sql/parse/parseQueryVariables';
import ChartConfigSidebar from 'src/components/ChartConfigSidebar';

import type { EditorInstance } from 'src/components/primitives/lib/MonacoSQLEditor';
import { logEvent } from 'src/components/QueryView/analytics';
import { GptCorrectionPreview } from 'src/components/QueryView/GptCorrectionPreview';
import {
  useAutocompleteOptions,
  useDebouncedQueryVariablesUpdate,
  useResizableEditor
} from 'src/components/QueryView/hooks';
import QueryVariables from 'src/components/QueryView/QueryVariables';
import Results from 'src/components/QueryView/Results';
import RightBar from 'src/components/QueryView/RightBar';
import {
  useSavedQueries,
  useSavedQuery
} from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import styles from 'src/components/QueryView/styles';
import Toolbar from 'src/components/QueryView/Toolbar';
import {
  useVersionMismatch,
  VersionConflictBar
} from 'src/components/QueryView/VersionConflictBar';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { ChartConfig } from 'src/lib/chart/types';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useGptConstruction } from 'src/lib/gpt/GptConstructionHook';
import { useGptCorrection } from 'src/lib/gpt/GptCorrectionHook';
import { useGptInlineCodeCompletion } from 'src/lib/gpt/GptInlineCodeCompletionHook';
import { useQueryActions, useQueryStatus } from 'src/lib/query/QueryState';
import { useQueryErrorRegion } from 'src/lib/query/QueryState/useQueryErrorRegion';
import { usePlaceholderParams } from 'src/lib/routes/usePlaceholderParams';
import { useDatabases, useMetadataError } from 'src/metadata/metadataState';
import { useSavedQueryAccessController } from 'src/savedQuery/savedQueryAccessController';
import { useConnectionCredentials } from 'src/state/connection';
import { useHistory } from 'src/state/history';
import { useUpdateRightBarTypes, useUpdateTab } from 'src/state/tabs';
import {
  QueryTab,
  ResultsDisplayType,
  RightBarOption
} from 'src/state/tabs/types';

interface Props {
  tab: QueryTab;
}

function dbExists(databases: DatabaseResult[], selectedDb: string): boolean {
  const dbNames = databases.map((db) => db.name);
  return dbNames.includes(selectedDb);
}

function queryVarsAreEmpty(vars: undefined | Record<string, string>): boolean {
  return !vars || isEqual(vars, {}) || Object.values(vars).every((val) => !val);
}

function queryVarsAreEqual(
  a: undefined | Record<string, string>,
  b: undefined | Record<string, string>
): boolean {
  return isEqual(a, b) || (queryVarsAreEmpty(a) && queryVarsAreEmpty(b));
}

function chartConfigIsEmpty(
  config: JSONObjectValue | null | undefined
): boolean {
  return !config || Object.keys(config).length === 0;
}

function chartConfigsAreEqual(
  config1: JSONObjectValue | null | undefined,
  config2: JSONObjectValue | null | undefined
): boolean {
  return (
    isEqual(config1, config2) ||
    (chartConfigIsEmpty(config1) && chartConfigIsEmpty(config2))
  );
}

type QueryTabPropsAffectingDirtyState = Pick<
  QueryTab,
  'database' | 'query' | 'queryVariables' | 'chartConfig'
>;

const QueryView = React.memo(function QueryView({ tab }: Props) {
  const chartConfig = tab.chartConfig;
  const containerRef = useRef<HTMLDivElement>(null);
  const currentUser = useCurrentUserOrThrow();

  const resultsDisplayType = tab.resultsDisplayType;

  const savedQueries = useSavedQueries();

  const savedQueryId = tab.queryId;
  const savedQuery = useSavedQuery(savedQueryId);
  const savedQueryName = savedQuery?.name;
  const savedQueryDatabase = savedQuery?.database || tab.database || 'default';

  const {
    deleteSavedQueryUserAccess,
    updateSavedQueryGlobalAccess,
    updateSavedQueryUserAccess
  } = useSavedQueryAccessController();

  const versionMismatch = useVersionMismatch(tab);
  const databases = useDatabases();
  const { selectedDatabase, setSelectedDatabase } = useConnectionCredentials();
  const metadataError = useMetadataError();

  const updateTab = useUpdateTab();
  const updateRightBarTypes = useUpdateRightBarTypes();

  const name = tab.title ?? '';
  const query = tab.query || '';
  const queryEmpty = query.trim().length === 0;

  const setName = useCallback(
    (name: string) => {
      updateTab(tab.id, { title: name });
    },
    [tab.id, updateTab]
  );

  const isDirty = useCallback(
    (tabState: QueryTabPropsAffectingDirtyState) => {
      return (
        savedQueryDatabase !== tabState.database ||
        (savedQuery?.query ?? '') !== (tabState.query ?? '') ||
        !queryVarsAreEqual(savedQuery?.parameters, tabState.queryVariables) ||
        !chartConfigsAreEqual(savedQuery?.chartConfig, tabState.chartConfig)
      );
    },
    [
      savedQuery?.chartConfig,
      savedQuery?.query,
      savedQuery?.parameters,
      savedQueryDatabase
    ]
  );

  const editTabState = useCallback(
    (modifications: Partial<QueryTab>) => {
      const isDirtyWithEdits = isDirty({
        database: modifications.database ?? tab.database,
        query: modifications.query ?? tab.query,
        queryVariables: modifications.queryVariables ?? tab.queryVariables,
        chartConfig: modifications.chartConfig ?? tab.chartConfig
      });

      updateTab(tab.id, {
        ...modifications,
        editedAt: isDirtyWithEdits ? new Date().toISOString() : tab.lastRunAt
      });
    },
    [
      tab.id,
      tab.lastRunAt,
      tab.database,
      tab.query,
      tab.queryVariables,
      tab.chartConfig,
      isDirty,
      updateTab
    ]
  );

  const setQuery = useCallback(
    (query: string) => editTabState({ query }),
    [editTabState]
  );

  const [placeholderParams, setPlaceholderParams] = usePlaceholderParams();

  const [queryVariables, setQueryVariables] = useState<Record<string, string>>(
    () => {
      let params: Record<string, string>;
      if (tab.queryVariables) {
        params = tab.queryVariables;
      } else {
        const queryVariableDescriptions = parseQueryVariables(tab.query);

        const variables = queryVariableDescriptions.map(
          (variable) => variable.name
        );

        params = Object.fromEntries(variables.map((name) => [name, '']));
      }

      // Merge the URL parameters into the saved query parameters.
      // URL parameters owerwrite saved query parameters.
      for (const [key, val] of Object.entries(placeholderParams)) {
        if (params[key] !== undefined) {
          // If the parameter is already defined in the saved query parameters,
          // update it with the URL parameter value.
          params[key] = val;
        }
      }

      return params;
    }
  );

  const actions = useQueryActions();

  const [runId, setRunId] = useState<string>(tab.queryRunId || '');
  const editorRef = useRef<EditorInstance | null>(null);

  const status = useQueryStatus(runId);
  const serviceId = useCurrentInstanceId() ?? '';

  const errorRegion = useQueryErrorRegion(runId, query);

  const [isQuerySaving, setIsQuerySaving] = useState(false);

  // Query construction with GPT
  const {
    constructQuery,
    cancelConstructionRequest,
    isRequestRunning: isRunning
  } = useGptConstruction(editorRef);

  // Query correction with GPT
  const { getCorrectedQueryText } = useGptCorrection();
  const correctedQuery = getCorrectedQueryText(runId);
  useGptInlineCodeCompletion({
    serviceId
  });

  const { addHistoryItem } = useHistory();

  // Synchronize URL with query variables
  const syncQueryParamsToUrl = useCallback(
    (queryVariables: Record<string, string>) => {
      if (savedQueryId && serviceId) {
        // Update the URL with the new query parameters.
        setPlaceholderParams(queryVariables);
      }
    },
    [savedQueryId, serviceId, setPlaceholderParams]
  );

  const updateChartConfig = useCallback(
    (updates: Partial<ChartConfig>) => {
      editTabState({
        chartConfig: {
          ...tab.chartConfig,
          ...updates
        }
      });
    },
    [tab.chartConfig, editTabState]
  );

  // When we load a query, set its saved parameters in the url params
  const lastSavedQueryIdRef = useRef<string>('');
  useEffect(() => {
    if (lastSavedQueryIdRef.current !== savedQueryId) {
      lastSavedQueryIdRef.current = savedQueryId;
      syncQueryParamsToUrl(queryVariables);
    }
  }, [savedQueryId, queryVariables, syncQueryParamsToUrl]);

  useEffect(() => {
    if (['finished', 'error'].includes(status)) {
      const date = new Date().toISOString();
      updateTab(tab.id, {
        lastRunAt: date,
        editedAt: date
      });
    }
  }, [tab.id, status, updateTab]);

  const runQuery = useCallback(
    async (query: string): Promise<void> => {
      updateTab(tab.id, {
        preview: false,
        search: ''
      });

      syncQueryParamsToUrl(queryVariables);

      if (query.trim().length === 0) {
        return;
      }

      if (dbExists(databases, selectedDatabase)) {
        if (serviceId) {
          addHistoryItem(query, serviceId);
        }
        const runId = await actions.createQuery({ query, queryVariables });
        setRunId(runId);
        updateTab(tab.id, {
          queryRunId: runId,
          selectedColumn: 0,
          selectedRow: 0,
          currentPage: 1
        });
        await actions.runQuery({ runId, query, queryVariables });
      } else {
        const message = metadataError
          ? 'We had an error loading the database schema. Please refresh the database schema and try again'
          : "We could not load the database '" +
            selectedDatabase +
            "'. Please reload the database schema and try again";

        createToast('Error', 'alert', message);
      }
    },
    [
      tab.id,
      actions,
      queryVariables,
      selectedDatabase,
      databases,
      serviceId,
      syncQueryParamsToUrl,
      addHistoryItem,
      metadataError,
      updateTab
    ]
  );

  const runCurrentStatement = useCallback(() => {
    if (dbExists(databases, selectedDatabase)) {
      editorRef.current && editorRef.current.runCurrentStatement();
      updateTab(tab.id, {
        preview: false,
        search: ''
      });
    } else {
      const message = metadataError
        ? 'We had an error loading the database schema. Please refresh the database schema and try again'
        : "We could not load the database '" +
          selectedDatabase +
          "'. Please reload the database schema and try again";
      createToast('Error', 'alert', message);
    }
  }, [selectedDatabase, databases, metadataError, updateTab, tab.id]);

  const runSelectedText = useCallback(() => {
    if (dbExists(databases, selectedDatabase)) {
      updateTab(tab.id, {
        preview: false,
        search: ''
      });
      editorRef.current && editorRef.current.runSelectedText();
    } else {
      const message = metadataError
        ? 'We had an error loading the database schema. Please refresh the database schema and try again'
        : "We could not load the database '" +
          selectedDatabase +
          "'. Please reload the database schema and try again";
      createToast('Error', 'alert', message);
    }
  }, [databases, selectedDatabase, metadataError, updateTab, tab.id]);

  const assignQuery = useCallback(async () => {
    updateTab(tab.id, {
      preview: false
    });

    if (!serviceId) {
      throw new Error('Service Id is not defined');
    }

    try {
      const result = await savedQueries.assignQuery({
        authorId: currentUser.id,
        id: savedQueryId
      });
      if (result) {
        createToast('Query assigned', 'success', 'Query assigned successfully');
        updateTab(tab.id, {
          updatedAt: result.updatedAt ?? undefined
        });
      }
    } catch (error) {
      createToast('Error', 'alert', errorMessage(error));
    }
  }, [
    currentUser.id,
    savedQueries,
    savedQueryId,
    serviceId,
    tab.id,
    updateTab
  ]);

  const deleteQuery = useCallback(async () => {
    try {
      await savedQueries.deleteQuery(savedQueryId);
      createToast('Query deleted', 'success', 'Query deleted successfully');
    } catch (error) {
      createToast('Error', 'alert', errorMessage(error));
    }
  }, [savedQueries, savedQueryId]);

  const saveQuery = useCallback(
    async (name: string, query: string) => {
      updateTab(tab.id, {
        preview: false
      });

      syncQueryParamsToUrl(queryVariables);
      setIsQuerySaving(true);

      if (!serviceId) {
        throw new Error('Service Id is not defined');
      }

      if (tab.saved) {
        try {
          const updatedQuery = await savedQueries.updateQuery({
            id: savedQueryId,
            name,
            query,
            database: selectedDatabase,
            updatedAt: new Date().toISOString(),
            parameters: queryVariables,
            chartConfig
          });

          if (updatedQuery) {
            // Success (new version didn't conflict w/ modified / deleted
            // version on server
            updateTab(tab.id, {
              title: updatedQuery?.name,
              updatedAt: updatedQuery?.updatedAt ?? undefined,
              editedAt: undefined,
              lastRunAt: undefined,
              query
            });
            createToast(
              'Query updated',
              'success',
              'Query updated successfully'
            );
          }
        } catch (error) {
          if (savedQueryName) {
            setName(savedQueryName);
          }

          createToast('Error', 'alert', errorMessage(error));
        } finally {
          setIsQuerySaving(false);
        }
      } else {
        try {
          const result = await savedQueries.createQuery({
            id: savedQueryId,
            name,
            query,
            database: selectedDatabase,
            parameters: queryVariables,
            chartConfig
          });

          if (result) {
            setName(result.name);
            updateTab(tab.id, {
              queryId: result.id,
              title: result.name,
              saved: true,
              editedAt: undefined,
              lastRunAt: undefined,
              updatedAt: result.updatedAt,
              query,
              chartConfig
            });

            createToast('Query saved', 'success', 'Query saved successfully');
          } else {
            createToast('Error', 'alert', 'Error creating query');
          }
        } catch (error) {
          createToast('Error', 'alert', 'Error creating query');
        } finally {
          setIsQuerySaving(false);
        }
      }
    },
    [
      queryVariables,
      savedQueryName,
      serviceId,
      setName,
      savedQueries,
      savedQueryId,
      selectedDatabase,
      tab.saved,
      tab.id,
      syncQueryParamsToUrl,
      chartConfig,
      updateTab
    ]
  );

  const changeName = useCallback(
    (name: string) => {
      updateTab(tab.id, {
        preview: false
      });
      setName(name);
      saveQuery(name, query).catch((e) => {
        console.error('Error saving query', e);
      });
    },
    [tab.id, saveQuery, query, setName, updateTab]
  );

  useEffect(() => {
    if (savedQueryName !== undefined) {
      setName(savedQueryName);
    } else if (savedQueries.loadingState !== 'loading') {
      setName('Untitled query');
    }
  }, [setName, savedQueryName, savedQueries.loadingState]); // should savedQueryName be in this?

  const setDisplayType = useCallback(
    (resultsDisplayType: ResultsDisplayType) => {
      updateTab(tab.id, { resultsDisplayType });
      const withoutResults = status === 'new';

      if (withoutResults && ['chart', 'table'].includes(resultsDisplayType)) {
        runQuery(query).catch((e) => console.error('error running query', e));
      }
    },
    [tab.id, query, runQuery, status, updateTab]
  );

  useEffect(() => {
    if (savedQueryDatabase) {
      setSelectedDatabase(serviceId, savedQueryDatabase);
    }
  }, [savedQueryDatabase, setSelectedDatabase, serviceId]);

  const updateQueryVariables = (
    value:
      | Record<string, string>
      | ((props: Record<string, string>) => Record<string, string>)
  ): void => {
    let newValue;
    if (typeof value === 'function') {
      newValue = value(queryVariables);
    } else {
      newValue = value;
    }
    setQueryVariables(newValue);
    editTabState({ queryVariables: newValue });
  };

  const debouncedHookUpdates = useDebouncedQueryVariablesUpdate({
    tabId: tab.id,
    updateQueryVariables,
    updateTab
  });

  const loading = status === 'running';

  const autocompleteOptions = useAutocompleteOptions();

  useHotkeys(
    'meta+enter, ctrl+enter',
    () => {
      logEvent('editor', 'shortcutAllCommandsRun', 'shortcut');
      runSelectedText();
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      enabled: (e, handler) => {
        if (!loading && !queryEmpty) {
          return (
            ((handler.meta || handler.ctrl) &&
              e.key.toLowerCase() === 'enter') ??
            false
          );
        }
        return false;
      },
      preventDefault: true
    },
    [loading, runQuery, runSelectedText, queryEmpty]
  );

  useHotkeys(
    'meta+shift+enter, ctrl+shift+enter',
    (e) => {
      logEvent('editor', 'shortcutAtCursorRun', 'shortcut');
      e.preventDefault();
      runCurrentStatement();
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      enabled: (e, handler) => {
        if (!loading && !queryEmpty) {
          return (
            ((handler.meta || handler.ctrl) &&
              handler.shift &&
              e.key.toLowerCase() === 'enter') ??
            false
          );
        }
        return false;
      }
    },
    [loading, runCurrentStatement, queryEmpty]
  );

  useHotkeys(
    'esc',
    (e) => {
      e.preventDefault();
      actions.cancelQuery(runId).catch((e) => {
        console.error('error cancelling query', e);
      });
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      enabled: loading && runId.length > 0
    },
    [loading, runQuery, query]
  );

  const updateRightBar = useCallback(
    (type: RightBarOption, value: string) => {
      updateRightBarTypes(tab.id, tab.rightBarType, type, value);
    },
    [tab.id, tab.rightBarType, updateRightBarTypes]
  );

  const {
    editorHeight,
    recalculateEditorHeight,
    onResize,
    editorContainerRef
  } = useResizableEditor(status, tab);

  /**
   * Hide the results view and reset the editor height back to 100%.
   */
  const hideResultsView = (): void => {
    // Reset the query status to 'new'.
    actions.resetQueryStatus(runId);
    if (editorContainerRef.current?.resizable) {
      // Reset the editor height to 100% after the results view is hidden.
      editorContainerRef.current.resizable.style.flexBasis = '100%';
    }
  };

  const hasVisibleResults = status !== 'new';

  return (
    <div css={styles.container} id="query-main-view">
      <Toolbar
        assignQuery={assignQuery}
        authorId={savedQuery?.authorId}
        deleteQuery={deleteQuery}
        deleteSavedQueryUserAccess={deleteSavedQueryUserAccess}
        name={name}
        onNameChange={changeName}
        runQuery={(runAll = false): void => {
          if (runAll) {
            void runQuery(query);
          } else {
            runSelectedText();
          }
        }}
        runCurrentStatement={runCurrentStatement}
        cancelQuery={(): void => {
          if (runId) {
            actions
              .cancelQuery(runId)
              .catch((e) => console.error('error cancelling query', e));
          }
        }}
        saveQuery={async (): Promise<void> => {
          await saveQuery(name, query).catch((e) =>
            console.error('error saving query', e)
          );
        }}
        status={status}
        disableRun={queryEmpty}
        isSaveDisabled={
          isQuerySaving ||
          (tab.saved && !isDirty(tab)) ||
          versionMismatch !== null
        }
        isSavedQuery={tab.saved}
        constructQuery={async (value: string): Promise<void> => {
          hideResultsView();
          await constructQuery(value, tab.id);
        }}
        cancelConstructionRequest={cancelConstructionRequest}
        completionIsRunning={isRunning}
        isCompletionButtonVisible={true}
        queryPermissions={savedQuery?.queryPermissions}
        queryId={savedQueryId}
        queryUnowned={!!savedQuery?.queryUnowned}
        serviceId={serviceId}
        runId={runId}
        updateSavedQueryGlobalAccess={updateSavedQueryGlobalAccess}
        updateSavedQueryUserAccess={updateSavedQueryUserAccess}
      />
      <VersionConflictBar versionMismatch={versionMismatch} />
      <div css={styles.contentContainer} ref={containerRef}>
        <div css={styles.mainContainer}>
          <Resizable
            css={styles.editorContainer(editorHeight)}
            defaultSize={{
              width: '100%',
              height: hasVisibleResults ? '50%' : '100%'
            }}
            ref={editorContainerRef}
            maxHeight={hasVisibleResults ? '80%' : '100%'}
            minHeight="10%"
            enable={{
              bottom: status !== 'new'
            }}
            onResize={onResize}
            data-has-data={status !== 'new'}
          >
            {correctedQuery && (
              <GptCorrectionPreview
                runId={runId}
                onReplaceQueryText={(oldText, newText): void => {
                  const editorContent = editorRef.current?.getText();
                  if (!editorContent) {
                    return;
                  }
                  // Update query text in the editor.
                  const newQueryText = editorContent.replace(oldText, newText);
                  editorRef.current?.replaceEditorContent(newQueryText);
                  setQuery(newQueryText);
                  hideResultsView();
                }}
              />
            )}
            <div
              css={
                // Use styles instead of conditional rendering in order
                // to preserve editor history.
                correctedQuery ? styles.hiddenEditor : styles.editor
              }
            >
              <Suspense fallback={null}>
                <SqlEditorWithAutocomplete
                  editorRef={editorRef}
                  preloadedQuery={query}
                  autocompleteOptions={autocompleteOptions}
                  onChange={(event): void => {
                    updateTab(tab.id, {
                      preview: false
                    });
                    setQuery(event.sql || '');
                    debouncedHookUpdates({
                      oldQueryVariables: queryVariables,
                      newQueryVariablesList: event.queryVariables,
                      newSql: event.sql ?? ''
                    });
                  }}
                  readOnly={
                    savedQuery?.userPermissions === 'read' ||
                    savedQuery?.queryUnowned
                  }
                  runQuery={(): void => {
                    runQuery(query).catch((e) => {
                      console.error('error running query', e);
                    });
                  }}
                  onSave={async (): Promise<void> => {
                    await saveQuery(name, query).catch((e) => {
                      console.error('error saving query', e);
                    });
                  }}
                  runSQL={(sql): void => {
                    if (sql !== undefined) {
                      runQuery(sql).catch((e) =>
                        console.error('error running query', e)
                      );
                    }
                  }}
                  logEvent={(event, interaction): void =>
                    logEvent('editor', event, interaction)
                  }
                  errorRegion={errorRegion}
                />
              </Suspense>
            </div>
            {Object.keys(queryVariables).length > 0 && (
              <Resizable enable={{ left: true }} minWidth="20%" maxWidth="40%">
                <QueryVariables
                  editable
                  query={query}
                  queryVariables={queryVariables}
                  setQueryVariables={updateQueryVariables}
                />
              </Resizable>
            )}
          </Resizable>
          {status !== 'new' && (
            <div css={styles.resultContainer}>
              <Results
                status={status}
                chartConfig={chartConfig}
                columnWidths={tab.columnWidths || {}}
                resultsDisplayType={resultsDisplayType}
                name={name}
                runId={runId}
                setDisplayType={setDisplayType}
                updateRightBar={updateRightBar}
                onNumRowsChange={recalculateEditorHeight}
                selectedRow={tab.selectedRow ?? 0}
                selectedColumn={tab.selectedColumn ?? 0}
                tabId={tab.id}
                paginationSelectorValue={tab.paginationSelector ?? 0}
                currentPage={tab.currentPage}
                editorRef={editorRef}
              />
            </div>
          )}
        </div>
        {resultsDisplayType !== 'chart' && (
          <RightBar container={containerRef.current} />
        )}
        <ChartConfigSidebar
          open={resultsDisplayType === 'chart'}
          chartConfig={chartConfig}
          close={(): void => setDisplayType('table')}
          name={name}
          portalId="query-main-view"
          runId={runId}
          updateChart={updateChartConfig}
          container={containerRef.current}
        />
      </div>
    </div>
  );
});

export default QueryView;
