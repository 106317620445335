import { assertTruthy } from '@cp/common/utils/Assert';
import { PasswordCredentialResponse, WakeServiceResponse } from 'shared';
import { defaultCapabilities } from 'shared/src/clickhouse/capabilities';

import { DatabaseStructure } from 'shared/src/clickhouse/types';
import { getInstanceAwakeStatus } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { fetchMetadataProps, useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { Credential } from 'src/state/connection';

import {
  useResetDatabaseMetadata,
  useSetMetadataError,
  useSetMetadataLoading,
  useSetMetadataLoaderVisible,
  useTables,
  useSetDatabaseMetadata,
  useSetDatabases
} from 'src/metadata/metadataState';
import { useSelectedDatabase } from 'src/metadata/selectedDatabase';
import { useSetWakeServiceModalOpen } from 'src/state/service/wakeService';
import { LoadMetadataOptions } from 'src/metadata/types';
import {
  metadataInFlightDatabase,
  metadataInFlightPromise,
  metadataInFlightServiceId,
  noopPromise,
  setMetadataInFlightPromise
} from 'src/metadata/metadataInFlight';
import { useTabActions } from 'src/state/tabs';
import { PasswordCredential, useCredentials, useSetCredentialModalOpen } from 'src/state/connection';

interface ReloadableDatabaseMetadata {
  loadMetadata: (options: LoadMetadataOptions) => Promise<void>;
}

export function useClickHouseMetadataWithCacheLoader(): ReloadableDatabaseMetadata {
  const { selectedDatabase, setSelectedDatabase } = useSelectedDatabase();
  const setMetadata = useSetDatabaseMetadata();
  const resetDatabaseMetadata = useResetDatabaseMetadata();
  const setLoading = useSetMetadataLoading();
  const setError = useSetMetadataError();
  const setMetadataLoaderVisible = useSetMetadataLoaderVisible();
  const api = useApiClient();
  const setWakeServiceModalOpen = useSetWakeServiceModalOpen();
  const tables = useTables();
  const { updateTableTabs } = useTabActions();
  const setCredentialModalOpen = useSetCredentialModalOpen();
  const setDatabases = useSetDatabases();

  const { instances } = useInstanceStateManager();
  const credentials = useCredentials();

  const loadMetadata = async ({
    cached,
    resetCurrentMetadata,
    database,
    serviceId
  }: LoadMetadataOptions): Promise<void> => {
    if (!serviceId) {
      /* do nothing while there is no service id */
      console.debug('No service id, not loading metadata');
      return;
    }

    if (!database) {
      console.debug('No database, not loading metadata');
      return;
    }

    const instance = instances[serviceId];
    if (!instance) {
      console.debug('Cannot find instance with given id, not loading metadata');
      return;
    }

    const { isAwake, isStopped, isUnknown, isProvisioning, isStarting } = getInstanceAwakeStatus(instance);

    setLoading(true);
    let outdatedMetadatLoaded = false;

    try {
      if (resetCurrentMetadata) {
        resetDatabaseMetadata(serviceId, database);
      }
      if (selectedDatabase !== database || tables.length === 0 || resetCurrentMetadata) {
        setMetadataLoaderVisible(true);
      }
      setError(null);

      const newCredentials: Credential = {
        ...credentials,
        database // overwrite the cached database on credentials
      };
      if (newCredentials.type === 'managed') {
        // overwrite the cached service id on credentials
        // TODO #9570 Investigate why credentials go out of sync with the current serviceId/database
        newCredentials.serviceId = serviceId;
      }

      const props: fetchMetadataProps = {
        serviceId: serviceId,
        cached: cached ?? true,
        database,
        wakeService: false,
        isStopped,
        credentials: newCredentials
      };

      const promise =
        metadataInFlightPromise === noopPromise ||
        metadataInFlightDatabase !== database ||
        metadataInFlightServiceId !== serviceId
          ? api.fetchMetadata(props)
          : metadataInFlightPromise;
      setMetadataInFlightPromise(promise, serviceId, database);

      let res = await (metadataInFlightPromise as Promise<
        DatabaseStructure | PasswordCredentialResponse | WakeServiceResponse
      >);

      // While loading metadata, a new request to load metadata might have been made, with a different database or serviceId
      outdatedMetadatLoaded = metadataInFlightDatabase !== database || metadataInFlightServiceId !== serviceId;
      if (outdatedMetadatLoaded) {
        // Ignore outdated metadata
        return;
      }

      setMetadataInFlightPromise(noopPromise, null, null);

      if (
        !isUnknown &&
        !isProvisioning &&
        !isStarting &&
        !isStopped &&
        !isAwake &&
        'wakeServiceConfirmation' in res &&
        res.wakeServiceConfirmation
      ) {
        await new Promise((resolve, reject) => {
          setWakeServiceModalOpen(true, 'fetch metadata', {
            resolve,
            reject
          });
        });
        const promise = api.fetchMetadata({ ...props, wakeService: true });
        setMetadataInFlightPromise(promise, serviceId, database);
        res = await promise;
        setMetadataInFlightPromise(noopPromise, null, null);
      }

      if ('passwordCredentialsRequired' in res && res.passwordCredentialsRequired) {
        const newCredentials = await new Promise<Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>>(
          (resolve, reject) => {
            setCredentialModalOpen(true, { resolve, reject });
          }
        );

        props.credentials = {
          ...newCredentials,
          connected: true,
          database,
          type: 'password'
        };

        const promise = api.fetchMetadata(props);
        setMetadataInFlightPromise(promise, serviceId, database);
        res = await promise;
        setMetadataInFlightPromise(noopPromise, null, null);
      }

      assertTruthy(isDatabaseStructure(res), 'Invalid database resource');
      const databaseStructure = res;

      setMetadata(serviceId, database, {
        version: databaseStructure.version,
        tables: databaseStructure.tables,
        views: databaseStructure.views,
        materializedViews: databaseStructure.materializedViews,
        functions: databaseStructure.functions,
        capabilities: databaseStructure.capabilities ?? defaultCapabilities
      });
      const databases = databaseStructure.databases;
      setDatabases(databases);
      if (!databases.find((d) => d.name === database)) {
        const nextDatabase = databases.find((db) => db.name === 'default')?.name ?? databases[0]?.name ?? 'default';
        setSelectedDatabase(serviceId, nextDatabase);
      }
      updateTableTabs(databaseStructure.tables);
    } catch (e) {
      console.error(e);
      const message = errorMessage(e);
      setError(message);
    } finally {
      setLoading(false);
      setMetadataLoaderVisible(false);
      if (!outdatedMetadatLoaded) {
        setMetadataInFlightPromise(noopPromise, null, null);
      }
    }
  };

  return {
    loadMetadata
  };
}

function isDatabaseStructure(
  res: DatabaseStructure | PasswordCredentialResponse | WakeServiceResponse
): res is DatabaseStructure {
  return 'version' in res;
}
