import React, { ReactElement } from 'react';
import { RowPreview } from 'shared/src/dataLoading';
import { CellProps, Grid, Panel } from '@clickhouse/click-ui';
import LoadingPanel from 'src/components/LoadingPanel/LoadingPanel';

interface Column {
  name: string;
  type: string;
}

interface Props {
  loading: boolean;
  columns: Column[];
  rows: RowPreview[][];
  showHeader?: boolean;
}

function PreviewBox({
  loading,
  columns,
  rows,
  showHeader = true
}: Props): ReactElement | null {
  const rowsLength = rows.length > 3 ? 3 : rows.length;
  const Cell: CellProps = ({
    rowIndex,
    columnIndex,
    type,
    isScrolling,
    ...props
  }) => {
    if (type === 'header-cell') {
      return <span {...props}>{columns[columnIndex].name}</span>;
    }

    return <span {...props}>{rows[rowIndex - 1][columnIndex]}</span>;
  };

  if (loading) {
    return <LoadingPanel text="Loading data" fillWidth height="150px" />;
  }

  if (rows.length === 0) {
    return null;
  }
  return (
    <Panel
      height={`${(rowsLength + 1) * 43}px`}
      fillWidth
      padding="none"
      data-testid="clickpipe-sample-preview-box"
      hasBorder
    >
      <Grid
        cell={Cell}
        rowStart={1}
        rowCount={rowsLength}
        columnCount={columns.length}
        showHeader={showHeader}
        className="fs-exclude"
      />
    </Panel>
  );
}

export default React.memo(PreviewBox);
