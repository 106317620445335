import React, { CSSProperties, useEffect } from 'react';

import { useDragLayer, XYCoord } from 'react-dnd-cjs';

import { sidebarLogEvent } from 'src/components/QueryView/analytics';

import {
  contentRootStyle,
  contentTextStyle
} from 'src/components/QueryView/QueryList/Folders/Tree/styles';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 999999
} as CSSProperties;

interface ItemStyleProps {
  currentOffset?: XYCoord | null;
}
const getItemStyles = (props: ItemStyleProps) => {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
};

interface Props {
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}
const CustomDragLayer = ({ setIsDragging }: Props) => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  useEffect(() => {
    setIsDragging(isDragging);
    if (isDragging) {
      sidebarLogEvent(item.isFolder ? 'folder' : 'query', 'Drag', 'trigger');
    }
  }, [isDragging]);

  if (!isDragging && itemType !== 'node') {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles({ currentOffset })}>
        <div
          css={contentRootStyle(0)}
          style={{
            background: 'transparent'
          }}
        >
          <div css={contentTextStyle}>{item.title}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomDragLayer;
