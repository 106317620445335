import { isUuid } from '@cp/common/utils/ValidationUtils';

export function getOrganizationIdFromUrl(): string | null {
  return extractOrganizationIdFromUrl(window.location.href);
}

export const extractOrganizationIdFromUrl = (url: string): string | null => {
  try {
    const { pathname } = new URL(url);
    return extractOrganizationIdFromPathname(pathname);
  } catch (e) {
    return null;
  }
};

export const extractOrganizationIdFromPathname = (pathname: string): string | null => {
  try {
    const parts = pathname.split('/');
    parts.shift();

    for (let i = 0; i < parts.length - 1 && parts.length > 1; i++) {
      if (parts[i] === 'organizations' && parts[i + 1] && isUuid(parts[i + 1])) {
        return parts[i + 1];
      }
    }
  } catch (e) {
    return null;
  }

  return null;
};
