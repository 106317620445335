import { assertTruthy } from '@cp/common/utils/Assert';
import { useEffect, useState } from 'react';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';
import { GetDriftIdentityJwtResponse } from '@cp/common/protocol/Drift';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useLocation } from 'react-router-dom';
import {
  getExpirableValueFromLocalStorage,
  storeExpirableValueInLocalStorage
} from 'src/lib/local-storage';

const INTERACTION_MAPPING: Record<string, number> = {
  services: 358266,
  service: 358266,
  integrations: 350481,
  admin: 350657,
  usage: 350668,
  billing: 350666,
  members: 350613,
  activity: 350615,
  learn: 350742,
  support: 350753
};

interface driftStartInteractionInput {
  interactionId: number;
}

export interface DriftAPI {
  on: (s: string, callback: () => void) => void;
  reset: () => void;
  identify: (id: string, properties: {}, authProps: {}) => void;
  page: () => void;
  unload: () => void;
  load: (id: string) => void;
  show: () => void;
  api: {
    widget: {
      hide: () => void;
    };
    startInteraction: (input: driftStartInteractionInput) => void;
  };
}

interface UseDrift {
  openChat: () => void;
}
export const useDrift = (): UseDrift => {
  const apiClient = useApiClient();
  const { user } = useUserStateManager();
  const org = useCurrentOrganizationOrThrow();
  const [isDriftEnabled, setDriftEnabled] = useState(false);

  const location = useLocation();

  const getCurrentLocation = (): string => {
    // we need to reverse as there might be more than one part matching
    // the most meaningful one for drift is at the end of the url though
    // e.g /services/:serviceId/support
    const routeParts = location.pathname.split('/').reverse();
    for (const part of routeParts) {
      if (INTERACTION_MAPPING[part]) {
        return part;
      }
    }
    return 'services';
  };

  const openChat = (): void => {
    if (drift().api) {
      drift().api.startInteraction({
        interactionId: INTERACTION_MAPPING[getCurrentLocation()]
      });
    } else {
      drift().on('ready', () => {
        drift().api.startInteraction({
          interactionId: INTERACTION_MAPPING[getCurrentLocation()]
        });
      });
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const initDrift = async (): Promise<void> => {
      if (user) {
        drift().load('bhbc5tszm6xi');
        setDriftEnabled(true);

        const nameParts = user?.name?.split(' ');
        assertTruthy(nameParts, 'Invalid name parts');
        assertTruthy(nameParts.length > 0, 'Missing name parts');

        let jwtDetails = getExpirableValueFromLocalStorage(
          'driftIdentityJwt'
        ) as GetDriftIdentityJwtResponse;

        if (!jwtDetails) {
          jwtDetails = await apiClient.drift.getUserJwt();
          const expirationMillis = jwtDetails.expiresIn * 1000;
          storeExpirableValueInLocalStorage(
            'driftIdentityJwt',
            jwtDetails,
            expirationMillis - Date.now()
          );
        }
        drift().identify(
          user.userId || '',
          {
            externalId: user.userId,
            endUser: true,
            email: user.email,
            firstName: nameParts[0],
            lastName: nameParts.slice(1).join(' '),
            organizationId: org.id
          },
          { userJwt: jwtDetails.userJwt }
        );
      } else if (isDriftEnabled) {
        // User is unauthenticated - clear user cache.
        console.log('User logs out');
        setDriftEnabled(false);
        if (drift() && typeof drift().unload === 'function') {
          drift().unload();
        }
      }
    };

    initDrift().catch(console.error);
  }, [isDriftEnabled, org, user]);

  useEffect(() => {
    if (drift().api?.widget && typeof drift().api.widget.hide === 'function') {
      drift().api.widget.hide();
    } else {
      (window as any).drift.on('ready', () => {
        drift().api.widget.hide();
      });
    }
  }, [location.pathname]);

  return { openChat };
};

function drift(): DriftAPI {
  const drift: DriftAPI = (window as any).drift as DriftAPI;
  assertTruthy(
    drift && !!drift.reset && !!drift.page && !!drift.identify,
    'drift must be defined on window'
  );
  return drift;
}
