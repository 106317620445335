import { css, SerializedStyles, Theme } from '@emotion/react';

export const sectionStyle = (theme: Theme): SerializedStyles => css`
  border-bottom: 1px solid ${theme.global.color.stroke.default};
  flex-grow: 0;
`;

export const sectionTitleStyle = (theme: Theme): SerializedStyles => css`
  padding: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${theme.global.color.text.muted};
`;

export const sectionBodyStyle = css`
  padding: 0 12px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const multiColumnStyle = css`
  grid-column: span 2 / span 2;
  min-width: min(250px, 100%);
  width: 100%;
  max-width: fit-content;
`;

export const tableParamsStyle = css`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 250px));
  gap: 12px;
  align-items: flex-start;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const scrollContainer = css`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
`;

export const nullableSwitch = css`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus-within,
  &:focus {
    .toggle-switch {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const advancedSettingsContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const advancedSettingsSection = css`
  padding: 12px;
`;

export const footerStyle = (theme: Theme): SerializedStyles =>
  css({
    border: '1px solid',
    borderColor: theme.global.color.stroke.default,
    background: theme.global.color.background.muted,
    borderRightColor: 'transparent',
    borderLeftColor: 'transparent',
    padding: '0.75rem',
    display: 'flex',
    gap: '0.75rem',
    button: {
      minWidth: 175
    }
  });

export const tableContainer = css({
  display: 'flex',
  flexFlow: 'row nowrap',
  flex: 1,
  alignItems: 'flex-start',
  overflow: 'auto',
  position: 'relative'
});
