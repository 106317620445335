import { ReactElement, useState } from 'react';
import {
  CardPrimary,
  Container,
  GridContainer,
  Link,
  Text
} from '@clickhouse/click-ui';

import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import {
  useClickPipesPeerDBTileEnabled,
  usePostgresClickpipeEnabled
} from 'src/lib/dataLoading/feature';
import PeerDBInfoBox from 'src/components/ImportView/ClickPipesImportForm/components/peerdb/PeerDBInfoBox';
import PeerDBTileStyleOverrides from 'src/components/ImportView/ClickPipesImportForm/components/peerdb/PeerDBTileStyleOverrides';

export function SetupClickPipeType(): ReactElement {
  const { setupClickPipeModel } = useClickPipesImport();
  const [showPeerDBInfo, setShowPeerDBInfo] = useState(false);
  const isPeerDBTileEnabled = useClickPipesPeerDBTileEnabled();
  const isPostgresClickPipeEnabled = usePostgresClickpipeEnabled();
  const setConfluentType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('confluent');
  };

  const setKafkaType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('kafka');
  };

  const setMskType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('msk');
  };

  const setKinesisType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('kinesis');
  };

  const setRedpandaType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('redpanda');
  };

  const setWarpStreamType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('warpstream');
  };

  const setAzureEventHubType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('azureeventhub');
  };

  const setS3Type = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('s3');
  };

  const setGcsType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('gcs');
  };

  const setPostgresType = (): void => {
    setShowPeerDBInfo(false);
    setupClickPipeModel('postgres');
  };

  const setPeerDBType = (): void => {
    setShowPeerDBInfo(!showPeerDBInfo);
  };

  return (
    <Container
      orientation="vertical"
      padding="none"
      gap="md"
      alignItems="start"
      fillWidth={false}
    >
      <Text size="md">
        If you need any help to setup ClickPipes, you can{' '}
        <Link
          href="https://clickhouse.com/docs/en/integrations/clickpipes"
          target="_blank"
          rel="noreferrer"
        >
          access the documentation
        </Link>
        for more details.
      </Text>

      <GridContainer
        rowGap="lg"
        columnGap="lg"
        gridAutoFlow="row"
        alignItems="stretch"
        gridTemplateColumns="repeat(4, 1fr)"
      >
        <CardPrimary
          icon="kafka"
          title="Apache Kafka"
          data-testid="clickpipe-tile-kafka"
          onClick={setKafkaType}
        />
        <CardPrimary
          icon="confluent"
          title="Confluent Cloud"
          data-testid="clickpipe-tile-confluent"
          onClick={setConfluentType}
        />
        <CardPrimary
          icon="aws-msk"
          title="Amazon MSK"
          data-testid="clickpipe-tile-msk"
          onClick={setMskType}
        />
        <CardPrimary
          icon="aws-kinesis"
          title="Amazon Kinesis"
          data-testid="clickpipe-tile-kinesis"
          onClick={setKinesisType}
        />
        <CardPrimary
          icon="redpanda"
          title="Redpanda"
          data-testid="clickpipe-tile-redpanda"
          onClick={setRedpandaType}
        />
        <CardPrimary
          icon="warpstream"
          title="WarpStream"
          data-testid="clickpipe-tile-warpstream"
          onClick={setWarpStreamType}
        />
        <CardPrimary
          icon="azure-event-hub"
          title="Azure Event Hubs"
          data-testid="clickpipe-tile-azure-event-hubs"
          onClick={setAzureEventHubType}
        />
        <CardPrimary
          icon="aws-s3"
          title="Amazon S3"
          data-testid="clickpipe-tile-s3"
          onClick={setS3Type}
        />
        <CardPrimary
          icon="gcs"
          data-testid="clickpipe-tile-gcs"
          title="Google Cloud Storage"
          onClick={setGcsType}
        />

        {isPostgresClickPipeEnabled && (
          <CardPrimary
            icon="postgres"
            data-testid="clickpipe-tile-postgres"
            title="Postgres CDC"
            topBadgeText="Beta"
            onClick={setPostgresType}
          />
        )}

        {isPeerDBTileEnabled && (
          <PeerDBTileStyleOverrides>
            <CardPrimary
              icon="postgres_cdc"
              data-testid="clickpipe-tile-peerdb"
              title="PostgreSQL CDC"
              topBadgeText="New"
              isSelected={showPeerDBInfo}
              onClick={setPeerDBType}
            />
          </PeerDBTileStyleOverrides>
        )}
      </GridContainer>

      {isPeerDBTileEnabled && showPeerDBInfo && <PeerDBInfoBox />}
    </Container>
  );
}
