import { createToast } from 'primitives';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import {
  useCurrentInstanceId,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import { useHttpClient } from 'src/lib/http';
import { metadataInFlightPromise } from 'src/metadata/metadataInFlight';

import {
  Credential,
  PasswordCredential,
  useConnectionCredentials,
  useCredentials
} from 'src/state/connection';
import { useOrgIdFromServiceIdOrFail } from 'src/state/service';
import { useSetWakeServiceModalOpen } from 'src/state/service/wakeService';
import { useTabQueries } from 'src/state/tabs';
import { selectTimeoutSeconds } from 'src/lib/query/constants';

import { openRowStore, RowStore } from 'src/lib/query/RowCache';
import { Row } from 'src/lib/query/RowCache/types';
import {
  ChunkedQueryCallbacks,
  RunningQuery,
  RunningQueryArgs,
  RunningQueryColumn,
  RunningQueryStatusName
} from 'src/lib/query/runningQueryTypes';
import { streamingChunkedQuery } from 'src/lib/query/streamingChunkedQuery';
import { useRunningQueryState } from 'src/lib/query/useRunningQueryState';
import { useSqlQueryFunction } from 'src/lib/clickhouse/query';
import { PASSWORD_CREDENTIAL_ERROR } from 'shared/src/errorCodes';
import { assertTruthy } from '@cp/common/utils/Assert';

export { useResultsView } from 'src/lib/query/QueryState/useResultsView';
export { useQueryActions } from 'src/lib/query/QueryState/actions';

export {
  useQueryStatus,
  useQueryColumns,
  useQueryError,
  useQueryMessage,
  useQueryMetrics
} from 'src/lib/query/QueryState/queryDetails';

const QUERY_STATUS_CHECK_INTERVAL = 30000;
const QUERY_FINISH_TYPES = [
  'QueryFinish',
  'ExceptionBeforeStart',
  'ExceptionWhileProcessing'
];

type QueryFunction = (cbs: ChunkedQueryCallbacks) => Promise<void>;

export interface RunQueryArgs {
  query: string;
  queryVariables: Record<string, string>;
  runId: string;
  credentials?: Credential;
}

export type RowCallback = (row: Row, i: number) => void;

export interface QueryStateContextActions {
  createQuery: (args: Omit<RunningQueryArgs, 'runId'>) => Promise<string>;
  createQueryInPlace: (runId: string, numRows: number) => Promise<void>;
  cancelQuery: (runId: string) => Promise<void>;
  deleteQuery: (runId: string) => Promise<void>;
  runQuery: (args: RunQueryArgs) => Promise<void>;
  queryEachRow: (runId: string, callback: RowCallback) => Promise<void>;
  resetQueryStatus: (runId: string) => void;
}

interface QueryStateContextRowStore {
  waitForRowStore: () => Promise<RowStore>;
  getRowStore: () => RowStore | null;
}

interface QueryStateContextVars
  extends QueryStateContextActions,
    QueryStateContextRowStore {
  queriesById: Record<string, RunningQuery>;
}

const QueryStateContext = createContext<null | QueryStateContextVars>(null);

type TabQueryName = string;

type CancelFunctionMap = Record<string, () => Promise<void>>;

export function QueryStateProvider({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const http = useHttpClient();
  const credentials = useCredentials();
  const { queries, actions } = useRunningQueryState();

  const tabQueries = useTabQueries();

  const rowStoreRef = useRef<null | RowStore>(null);
  const rowStoreRequestRef = useRef<null | Promise<RowStore>>(null);
  const tabQueryRef = useRef<TabQueryName[]>([]);
  const cancelFunctionByQueryIdRef = useRef<CancelFunctionMap>({});

  const setWakeServiceModalOpen = useSetWakeServiceModalOpen();
  const serviceId = useCurrentInstanceId();
  const orgId = useOrgIdFromServiceIdOrFail(serviceId);
  const { isStopped } = useIsCurrentInstanceAwakeStatus();
  const [runSql] = useSqlQueryFunction();
  const { setCredentialModalOpen } = useConnectionCredentials();

  const getRowStore = useCallback(() => {
    return rowStoreRef.current;
  }, []);

  const waitForRowStore = useCallback(async () => {
    if (!rowStoreRequestRef.current) {
      rowStoreRequestRef.current = openRowStore();
      rowStoreRequestRef.current
        .then((rowStore) => {
          /* for (const id of tabQueryRef.current) {
           *   rowStore.createQueryInPlace(id, tabQueryRef.current[id].numRows)
           * } */
          void rowStore.removeUnneededQueryIds(tabQueries);
          rowStoreRef.current = rowStore;
        })
        .catch(() => {
          //const message = e instanceof Error ? e.message : String(e)
          //amplitude().logEvent('errorOpeningIndexedDB', { message })
          createToast(
            'Error',
            'alert',
            'There was an error opening the backing store for IndexedDB.  This can happen when there is no more disk space available'
          );
        });
    }
    return rowStoreRequestRef.current;
  }, []);

  useEffect(() => {
    return () => {
      rowStoreRequestRef.current &&
        void rowStoreRequestRef.current.then((store) => store.close());
    };
  }, []);

  const addQueryRows = useCallback(
    async (queryId: string, rows: Row[]) => {
      const rowStore = await waitForRowStore();
      return rowStore.addRows(queryId, rows);
    },
    [waitForRowStore]
  );

  const createQueryInPlace = useCallback(
    async (runId: string, numRows: number) => {
      const rowStore = await waitForRowStore();
      rowStore.createQueryInPlace(runId, numRows);
    },
    [waitForRowStore]
  );

  const createQuery = useCallback(
    async (queryArgs: Omit<RunningQueryArgs, 'runId'>) => {
      const rowStore = await waitForRowStore();
      const runId = rowStore.createQuery();
      actions.create(runId, { ...queryArgs, runId });
      return runId;
    },
    [waitForRowStore, actions]
  );

  const finishQuery = useCallback(
    async (runId: string) => {
      const store = await waitForRowStore();
      store.finishQuery(runId);
    },
    [waitForRowStore]
  );

  const cancelQuery = useCallback(
    async (runId: string) => {
      const cancel = cancelFunctionByQueryIdRef.current[runId];
      if (cancel) {
        void cancel();
      }
      actions.setStatus(runId, 'cancelled');
    },
    [actions]
  );

  /**
   * Resets the status of a query with the given run ID to 'new'.
   * @param runId The ID of the query run to reset.
   */
  const resetQueryStatus = useCallback(
    (runId: string) => {
      actions.setStatus(runId, 'new');
    },
    [actions]
  );

  const deleteQuery = useCallback(
    async (runId: string) => {
      void waitForRowStore().then((rowStore) => {
        cancelQuery(runId).catch((e) =>
          console.error('error cancelling query', e)
        );
        actions.delete(runId);
        void rowStore.deleteQuery(runId);
      });
    },
    [waitForRowStore, cancelQuery, actions]
  );

  const queryEachRow = useCallback(
    async (runId: string, callback: RowCallback) => {
      const store = await waitForRowStore();
      await store.eachRow(runId, callback);
    },
    [waitForRowStore]
  );

  const runQueryFunction = useCallback(
    async (
      runId: string,
      queryArgs: RunningQueryArgs,
      queryFunction: QueryFunction
    ) => {
      try {
        await metadataInFlightPromise;
      } catch (error) {
        console.error('Error waiting for database schema to load', error);
        createToast(
          'Error',
          'alert',
          'Error waiting for database schema to load. Please try again.'
        );
        return;
      }

      let isRunning = false;
      const setStatus = (status: RunningQueryStatusName): void => {
        isRunning = status === 'running';
        actions.setStatus(runId, status);
      };
      setStatus('running');

      actions.setArgs(runId, queryArgs);

      const onEndHandler = () => {
        clearTimeout(delayedLoadingTimeout);
        clearInterval(queryStatusCheckInterval);
        void finishQuery(runId);
        actions.updateQuery(runId, {
          loadingOverlay: false,
          delayedLoading: false,
          delayedLoadingTimeout: null
        });
        setStatus('finished');
      };

      const delayedLoadingTimeout = window.setTimeout(() => {
        actions.updateQuery(runId, {
          delayedLoading: true,
          delayedLoadingTimeout: null
        });
      }, 300);

      async function checkQueryStatus(): Promise<void> {
        const result = await runSql(
          `SELECT type, exception, query_kind as "queryKind" FROM system.query_log WHERE query_id = '${runId}';`
        );

        if ('error' in result) {
          return;
        }

        const queryEndLog = result.rows.find((row) =>
          QUERY_FINISH_TYPES.includes(row[0] || '')
        );

        if (queryEndLog) {
          const [type, exception, queryKind] = queryEndLog;
          if (type === 'QueryFinish') {
            if (queryKind === 'Select') {
              onEndHandler();
            } else {
              actions.setMessage(
                runId,
                `${queryKind?.toUpperCase()} succeeded`
              );
            }
          } else if (
            type === 'ExceptionBeforeStart' ||
            type === 'ExceptionWhileProcessing'
          ) {
            actions.setResult(runId, {
              error: {
                message: `${exception}`,
                context: { type: 'server' }
              },
              numRows: 0,
              cleared: false
            });
            setStatus('error');
          }
        }
      }

      const queryStatusCheckInterval = window.setInterval(() => {
        checkQueryStatus().catch(console.error);
      }, QUERY_STATUS_CHECK_INTERVAL);

      actions.updateQuery(runId, {
        delayedLoadingTimeout
      });

      let gotRows = false;

      await queryFunction({
        onColumns: async (columns: RunningQueryColumn[]) => {
          if (isRunning) {
            clearTimeout(delayedLoadingTimeout);
            actions.setColumns(runId, columns);
          }
        },
        onData: async (rows: Row[]) => {
          gotRows = true;
          if (isRunning) {
            clearTimeout(delayedLoadingTimeout);
            const success = await addQueryRows(runId, rows);
            if (!success) {
              console.error('Error adding data to query');
              await cancelQuery(runId);
            }
            //actions.appendRows(runId, rows.length)
          }
        },
        onRawData: async (data: string) => {
          if (isRunning) {
            actions.appendRawData(runId, data);
          }
          return Promise.resolve();
        },
        onEnd: async () => {
          if (isRunning) {
            onEndHandler();
          }
        },
        onError: async (message, context) => {
          if (message.includes(PASSWORD_CREDENTIAL_ERROR)) {
            await new Promise<
              Pick<
                PasswordCredential,
                'host' | 'port' | 'username' | 'password'
              >
            >((resolve, reject) => {
              setCredentialModalOpen(true, { resolve, reject });
            }).then((newCredentials) => {
              return runQuery({
                ...queryArgs,
                credentials: {
                  ...newCredentials,
                  connected: true,
                  database: credentials.database,
                  type: 'password'
                }
              });
            });
          } else if (isRunning) {
            clearTimeout(delayedLoadingTimeout);
            clearInterval(queryStatusCheckInterval);
            finishQuery(runId).catch(console.error);
            if (message === 'Query timed out') {
              const displayMessage = `Query timed out after ${selectTimeoutSeconds} seconds`;
              createToast('Error', 'alert', displayMessage);
              if (!gotRows) {
                actions.setResult(runId, {
                  error: {
                    message: displayMessage,
                    context
                  },
                  numRows: 0,
                  cleared: false
                });
                setStatus('error');
              }
            } else if (message === 'Row limit exceeded') {
              createToast(
                'Row Limit Reached',
                'alert',
                'This query returns more than the maximum allowable results in the SQL console (500,000 rows).  Result set has been automatically limited.'
              );
            } else if (gotRows) {
              createToast('Error running query', 'alert', message);
            } else if (context.type === 'aborted') {
              setStatus('cancelled');
            } else {
              actions.setResult(runId, {
                error: {
                  message,
                  context
                },
                numRows: 0,
                cleared: false
              });
              setStatus('error');
            }
          }
        },
        onMessage: async (message) => {
          if (isRunning) {
            clearTimeout(delayedLoadingTimeout);
            clearInterval(queryStatusCheckInterval);
            actions.setMessage(runId, message);
          }
        },
        onMetrics: async (metrics) => {
          actions.setMetrics(runId, metrics);
        },
        onStatus: async () => {
          // actions.updateQuery(runId, { waking: !isAwake })
        },
        onTotals: async (totals: Row) => {
          actions.updateQuery(runId, { totals });
        },
        onProgress: (progress) => {
          actions.updateProgress(runId, progress);
        }
      });
    },
    [
      actions,
      addQueryRows,
      cancelQuery,
      credentials.database,
      finishQuery,
      setCredentialModalOpen
    ]
  );

  const runQuery = useCallback(
    async (queryArgs: RunQueryArgs): Promise<void> => {
      try {
        const { query, queryVariables } = queryArgs;
        await runQueryFunction(
          queryArgs.runId,
          queryArgs,
          async (callbacks) => {
            const executeRunQuery = async (
              wakeService: boolean
            ): Promise<void> => {
              assertTruthy(serviceId, 'No service ID found');
              console.log(
                `Running query ${queryArgs.runId} on service ${
                  credentials.type === 'managed'
                    ? credentials.serviceId
                    : credentials.host
                }`
              );
              const result = streamingChunkedQuery({
                http,
                sql: query,
                serviceId,
                runId: queryArgs.runId,
                variables: queryVariables,
                connectionCredentials:
                  queryArgs.credentials ?? credentials ?? null,
                orgId,
                wakeService, // TODO: let user bypass the wake up modal with a setting, pass true here if that setting is set
                isStopped,
                ...callbacks
              });
              const cancelFuncs = cancelFunctionByQueryIdRef.current;
              cancelFuncs[queryArgs.runId] = result.abort;
              const res = await result.completion;
              if (res.wakeServiceConfirmation) {
                await new Promise((resolve, reject) => {
                  setWakeServiceModalOpen(true, '', { resolve, reject });
                });
                void executeRunQuery(true);
              } else {
                delete cancelFuncs[queryArgs.runId];
              }
            };

            await executeRunQuery(false);
          }
        );
      } catch (e: unknown) {
        const message = e instanceof Error ? e.message : String(e);
        createToast('Error', 'alert', message);
      }
    },
    [
      runQueryFunction,
      http,
      credentials,
      isStopped,
      orgId,
      setWakeServiceModalOpen,
      serviceId
    ]
  );

  useEffect(() => {
    const oldQueries = tabQueryRef.current;
    const newQueries = tabQueries;

    for (const id of oldQueries) {
      if (!newQueries.includes(id)) {
        try {
          void waitForRowStore().then((rowStore) => {
            void cancelQuery(id);
            actions.delete(id);
            void rowStore.deleteQuery(id);
          });
        } catch (e) {
          /* NOOP */
        }
      }
    }

    tabQueryRef.current = tabQueries;
  }, [tabQueries, waitForRowStore, actions, cancelQuery]);

  const valueObject: QueryStateContextVars = {
    createQuery,
    createQueryInPlace,
    cancelQuery,
    deleteQuery,
    runQuery,
    getRowStore,
    waitForRowStore,
    queryEachRow,
    resetQueryStatus,
    queriesById: queries
  };

  const value = useMemo(() => valueObject, Object.values(valueObject));

  return (
    <QueryStateContext.Provider value={value}>
      {children}
    </QueryStateContext.Provider>
  );
}

export function useQueryStateContext(): QueryStateContextVars {
  const value = useContext(QueryStateContext);
  if (!value) {
    throw new Error('QueryStateContext is empty!');
  }
  return value;
}

export function useQuery(
  runId: string | undefined | null
): RunningQuery | null {
  const value = useContext(QueryStateContext);
  if (!value) {
    throw new Error('QueryStateContext is empty!');
  }
  if (!runId) {
    return null;
  }
  return value.queriesById[runId] ?? null;
}

export function useRunningQueries(): Record<string, RunningQueryStatusName> {
  const value = useContext(QueryStateContext);
  if (!value) {
    throw new Error('QueryStateContext is empty!');
  }

  const obj: Record<string, RunningQueryStatusName> = {};
  Object.entries(value.queriesById).forEach(([key, value]) => {
    obj[key] = value.status;
  });

  return obj;
}
