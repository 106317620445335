import { ReactElement, ReactNode } from 'react';
import SQLConsoleInitializerProvider from 'src/lib/SQLConsoleInitializerProvider';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import { MetadataInitializer } from 'src/components/MetadataInitializer';
import { QueryStateProvider } from 'src/lib/query/QueryState';
import { SavedQueriesProvider } from 'src/components/QueryView/SavedQueriesProvider';
import { SocketIoProvider } from 'src/lib/socketio';
import { DndProvider as _DndProvider, DndProviderProps } from 'react-dnd-cjs';
import { FCChild } from 'types/commonTypes';
import { QueryApiEndpointsInitializer } from 'src/lib/query/QueryApiEndpointsInitializer';

const DndProvider = _DndProvider as unknown as FCChild<
  DndProviderProps<unknown, unknown>
>;

const SQLConsoleProvider = ({
  children
}: {
  children: ReactNode;
}): ReactElement => {
  return (
    <SQLConsoleInitializerProvider>
      <SocketIoProvider>
        <MetadataInitializer>
          <SavedQueriesProvider>
            <QueryApiEndpointsInitializer>
              <QueryStateProvider>
                <DndProvider backend={HTML5Backend}>{children}</DndProvider>
              </QueryStateProvider>
            </QueryApiEndpointsInitializer>
          </SavedQueriesProvider>
        </MetadataInitializer>
      </SocketIoProvider>
    </SQLConsoleInitializerProvider>
  );
};

export default SQLConsoleProvider;
