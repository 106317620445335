import { ReactElement } from 'react';

import { IconProps } from 'src/components/icons/iconInterface';
function PaginationArrowIcon({
  color = 'currentColor',
  ...props
}: IconProps): ReactElement {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.995 6.01001L2.5 6.01001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99352 9.00598L9.99902 5.99998L6.99352 2.99498"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PaginationArrowIcon;
