import {
  Button,
  Container,
  createToast,
  Icon,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { parseHumanReadablePeriod } from '@cp/common/utils/DateTimeUtils';
import { ReactElement, useEffect, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { BackupConfiguration } from 'src/instance/instanceApiClient';
import {
  useCurrentInstanceOrThrow,
  useInstanceController
} from 'src/instance/instanceController';
import {
  convert24hTo12hTime,
  convertUTCToLocalTime
} from 'src/lib/formatters/dateTimeFormatter';
import {
  Section,
  SectionTitle,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';
import { BackupsConfigurationFlyout } from 'src/pages/SettingsPage/Backups/BackupsConfigurationFlyout';

export const BackupsConfigurationSection = (): ReactElement => {
  const hasManageBackupsPermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage-backups',
    useCurrentOrgUserRole() === 'ADMIN'
  );

  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);
  const organization = useCurrentOrganizationOrThrow();
  const instance = useCurrentInstanceOrThrow();
  const [backupConfiguration, setBackupConfiguration] = useState<
    BackupConfiguration | undefined
  >(undefined);
  const { getBackupConfiguration } = useInstanceController();
  const [triggerRefreshData, setTriggerRefreshData] = useState(0);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        await getBackupConfiguration(organization.id, instance.id, (config) => {
          setBackupConfiguration(config);
        });
      } catch (e) {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error while fetching backup configuration'
        });
      }
    };

    fetchData().catch(console.error);
  }, [triggerRefreshData, getBackupConfiguration, organization, instance]);

  const refreshData = (): void => {
    setTriggerRefreshData(triggerRefreshData + 1);
  };

  return (
    <Section data-testid={'backupsConfigurationSection'}>
      <Spacer />
      <SectionTitle>Backups</SectionTitle>
      <Spacer />
      <Subsection justifyContent="space-between">
        <SubsectionDescription>
          <SubsectionTitle>Configuration</SubsectionTitle>
          <Spacer />
          <Text color="muted">
            For an effective data protection plan, select a backup schedule
            matching your security needs, set a retention period to balance data
            access with storage efficiency, and customize backup timing to avoid
            disrupting your workflow.
          </Text>
        </SubsectionDescription>
        <SubsectionControls data-testid="backup-configuration-values-container">
          <BackupFrequency
            defaultBackupPeriod={backupConfiguration?.defaultBackupPeriod}
            customBackupPeriod={backupConfiguration?.customBackupPeriod}
            customStartTime={backupConfiguration?.customBackupStartTime}
          />
          <BackupRetention
            defaultBackupRetentionPeriod={
              backupConfiguration?.defaultBackupRetentionPeriod
            }
            customBackupRetentionPeriod={
              backupConfiguration?.customBackupRetentionPeriod
            }
          />
          <StartTime
            customBackupStartTime={backupConfiguration?.customBackupStartTime}
          />
          <Container orientation="vertical">
            <Spacer size="sm" />
            <Button
              type="secondary"
              disabled={
                !hasManageBackupsPermission ||
                instance.instanceTier === 'Development'
              }
              onClick={() => setIsFlyoutOpen(true)}
              fillWidth={false}
              data-testid="change-backup-configuration-button"
            >
              Change backup configuration
            </Button>
          </Container>
          <BackupsConfigurationFlyout
            isOpen={isFlyoutOpen}
            onOpenChange={setIsFlyoutOpen}
            backupConfiguration={backupConfiguration}
            organizationId={organization.id}
            instanceId={instance.id}
            refreshData={refreshData}
          />
        </SubsectionControls>
      </Subsection>
      <Spacer size="lg" />
    </Section>
  );
};

interface BackupFrequencyProps {
  customBackupPeriod: number | undefined;
  defaultBackupPeriod: number | undefined;
  customStartTime: string | undefined;
}

const BackupFrequency = ({
  customBackupPeriod,
  defaultBackupPeriod,
  customStartTime
}: BackupFrequencyProps): ReactElement => {
  let period = defaultBackupPeriod;

  if (customStartTime !== undefined) {
    // if there is a start time, the backup happens every 24 hours at/shortly after the start time
    period = 24;
  } else if (customBackupPeriod !== undefined) {
    period = customBackupPeriod;
  }

  if (period === undefined) {
    return (
      <Icon
        name="horizontal-loading"
        height="1.5rem"
        width="1.5rem"
        data-testid={'horizontal-loading'}
      />
    );
  }

  const backupFrequencyStr = `Backup Frequency: Every ${period} hours`;
  return (
    <Text size="sm" weight="medium" color="muted">
      {backupFrequencyStr}
    </Text>
  );
};

interface BackupRetentionProps {
  customBackupRetentionPeriod: number | undefined;
  defaultBackupRetentionPeriod: number | undefined;
}

const BackupRetention = ({
  defaultBackupRetentionPeriod,
  customBackupRetentionPeriod
}: BackupRetentionProps): ReactElement => {
  const period: number | undefined =
    customBackupRetentionPeriod ?? defaultBackupRetentionPeriod;
  let periodStr: string | undefined = undefined;

  if (period === undefined) {
    return (
      <Icon
        name="horizontal-loading"
        height="1.5rem"
        width="1.5rem"
        data-testid={'horizontal-loading'}
      />
    );
  }

  const { amount, unit } = parseHumanReadablePeriod(period);
  periodStr = `Storage Retention: ${amount} ${amount > 1 ? unit + 's' : unit}`;
  return (
    <Text size="sm" weight="medium" color="muted">
      {periodStr}
    </Text>
  );
};

interface StartTimeProps {
  customBackupStartTime: string | undefined;
}

const StartTime = ({ customBackupStartTime }: StartTimeProps): ReactElement => {
  if (customBackupStartTime !== undefined) {
    const startTimeStr: string = `Start Time: ${convert24hTo12hTime(
      customBackupStartTime
    )} UTC (${convertUTCToLocalTime(customBackupStartTime)} local)`;

    return (
      <Text size="sm" weight="medium" color="muted">
        {startTimeStr}
      </Text>
    );
  }
  return <></>;
};
