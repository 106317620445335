import { Button, Container, Icon, Text, Title } from '@clickhouse/click-ui';
import { isEmail } from '@cp/common/utils/ValidationUtils';
import { ReactElement, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useApiClient } from 'src/lib/controlPlane/client';
import styles from 'src/pages/EmailVerificationErrorPage/styles';

function EmailVerificationErrorPage(): ReactElement {
  const apiClient = useApiClient();

  const [message, setMessage] = useState<string>();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';
  const auth0Sub = searchParams.get('sub') || '';

  async function resendEmailConfirmationLink(): Promise<void> {
    setMessage(undefined);

    try {
      await apiClient.resendEmailConfirmationLink(auth0Sub);
      setMessage('Email confirmation link sent to your email');
    } catch (error) {
      setMessage('Failed to send email confirmation link');
    }
  }

  // When used correctly, this page always has 'email' parameter.
  // When used incorrectly (invoked manually) -> ignore and redirect to the root.
  if (!isEmail(email) || auth0Sub.length === 0) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container css={styles.chWrapper} justifyContent="center">
      <Container
        css={styles.innerContainer}
        alignItems="center"
        gap="md"
        orientation="vertical"
        data-testid="check-your-email-card"
      >
        <Title type={'h1'} size="xl">
          Check your email
        </Title>
        <Icon css={styles.icon} width="144px" height="144px" name="email" />
        <Container alignItems="center" orientation="vertical">
          <Text>We’ve sent an email validation link to</Text>
          <Text weight="bold">{email}</Text>
          <Text>{message}</Text>
        </Container>
        <Button
          fillWidth
          onClick={resendEmailConfirmationLink}
          align="center"
          label="Resend confirmation link"
          type="primary"
        />
        <Button
          fillWidth
          align="center"
          label="Back to sign in"
          type="secondary"
          onClick={() => navigateTo('/signIn')}
        />
      </Container>
    </Container>
  );
}

export default EmailVerificationErrorPage;
