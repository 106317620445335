import { Badge, CodeBlock, Spacer, Text } from '@clickhouse/click-ui';

import { SerializedStyles, css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { ProgressBar } from 'src/components/primitives';
import format from 'src/lib/sql/format';
import { flex, gapMd, spaceBetween } from 'src/lib/utility-styles';
import { QueryItem } from 'src/state/liveQueries/model';

import { ConfirmCancelQuery } from 'src/components/RunningQueryList/Query/ConfirmCancelQuery';
import {
  queryListInfoReg,
  queryListInfoStyle,
  runningBadge,
  runningStatusText
} from 'src/components/RunningQueryList/Query/styles';

const codeBlockStyle = css({
  padding: 0,
  '& > pre': {
    maxHeight: 150,
    overflow: 'scroll'
  }
});

const optionStyle = (): SerializedStyles =>
  css({
    width: '100%',
    maxWidth: 400,
    gap: 12,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 200,
    opacity: 0.5,
    '&:hover': {
      opacity: 1
    }
  });

interface RunningIconProps {
  openCancelQueryDialog: () => void;
}

function RunningIcon({ openCancelQueryDialog }: RunningIconProps): JSX.Element {
  const [hover, setHover] = useState(false);

  const cancelQuery = (): void => {
    if (!hover) {
      return;
    }

    openCancelQueryDialog();
  };

  return (
    <Badge
      state={hover ? 'danger' : 'info'}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      text={hover ? 'Kill Query' : 'Running'}
      icon={hover ? 'cross' : 'horizontal-loading'}
      iconDir="start"
      onClick={cancelQuery}
      data-testid="liveQueryStatusIndicator"
      css={runningBadge}
    />
  );
}

type QueryProps = {
  query: QueryItem;
};

export function Query({ query }: QueryProps): JSX.Element {
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const theme = useTheme();
  const sql = format(query.sql.trim(), 'clickhouse', 2);
  let progress = 100;
  const showProgress =
    query.status === 'running' && parseInt(query.totalRows) > 0;
  if (showProgress) {
    const total = parseInt(query.totalRows);
    const current = parseInt(
      query.queryKind === 'Insert' ? query.writtenRows : query.readRows
    );
    progress = (current / total) * 100;
  }
  let queryTitleLine;

  switch (query.status) {
    case 'cancelled':
      queryTitleLine = (
        <>
          <Badge data-testid="liveQueryStatusIndicator" text="Cancelled" />
          <Text weight="medium" color="muted">
            Finished {query.elapsed}
          </Text>
        </>
      );
      break;
    case 'completed':
      queryTitleLine = (
        <>
          <Badge
            icon="activity"
            data-testid="liveQueryStatusIndicator"
            text="Finished"
          />
          <Text weight="medium" color="muted">
            Finished {query.elapsed}
          </Text>
        </>
      );
      break;
    case 'running':
      queryTitleLine = (
        <>
          <RunningIcon
            openCancelQueryDialog={(): void => setCancelDialogOpen(true)}
          />
          <Text weight="medium" color="muted" css={runningStatusText}>
            Started {query.elapsed} ago
          </Text>
        </>
      );
      break;
    case 'failed':
      queryTitleLine = (
        <>
          <Badge data-testid="liveQueryStatusIndicator" text="Failed" />
          <Text weight="medium" color="muted">
            Finished {query.elapsed}
          </Text>
        </>
      );
      break;
  }
  return (
    <div css={optionStyle()}>
      <div css={queryListInfoStyle} data-testid="liveQueryMenuItem">
        <div css={[flex, spaceBetween, gapMd]}>{queryTitleLine}</div>
        {showProgress ? (
          <>
            <Spacer size="sm" />
            <div css={queryListInfoReg}>
              <ProgressBar
                progress={progress}
                barHeight="2px"
                barWidth="98%"
                barColor={theme.colors.alert.text.info}
                bgColor={theme.colors.alert.bg.info}
                borderColor="none"
              />
            </div>
          </>
        ) : null}
        {query.status === 'running' && (
          <>
            <Spacer size="sm" />
            <CodeBlock
              css={codeBlockStyle}
              theme="dark"
              language="sql"
              showLineNumbers
              wrapLines
              data-testid="liveQueryQueryText"
              className="running-query-code fs-exclude"
            >
              {sql}
            </CodeBlock>
          </>
        )}
        <Spacer size="sm" />
        <Text color="muted">Memory usage: {query.memUsage}</Text>
        <Text color="muted">
          {query.queryKind === 'Insert' ? query.writeInfo : query.readInfo}
        </Text>
        <Text color="muted">
          {query.status === 'running' ? 'Run' : 'Ran'} by {query.runBy}
        </Text>
      </div>
      {cancelDialogOpen && (
        <ConfirmCancelQuery
          open={cancelDialogOpen}
          queryId={query.id}
          queryText={query.sql}
          onClose={(): void => setCancelDialogOpen(false)}
        />
      )}
    </div>
  );
}
