import {
  BigStat,
  Container,
  CUIThemeType,
  Icon,
  Panel,
  Select,
  Spacer,
  Switch,
  Text,
  Title,
  Tooltip,
  useCUITheme
} from '@clickhouse/click-ui';
import {
  BillPeriodDates,
  OrganizationUsageReport,
  UsageMetric
} from '@cp/common/protocol/Billing';
import { Instance } from '@cp/common/protocol/Instance';
import { Organization } from '@cp/common/protocol/Organization';
import { alignCenter, flex, gapSm } from '@utility-styles';
import { ReactElement, useEffect, useState } from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { useAdminUsageReport } from 'src/billing/controllers/useAdminUsageReport';
import UsageChart from 'src/components/BillingInfo/UsageChart';
import UsageTable from 'src/components/BillingInfo/UsageTable';
import ChatWithUsButton from 'src/components/SupportList/ChatWithUsButton';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import SplitLayout from 'src/layout/SplitLayout';
import { formatCurrency } from 'src/lib/formatters/currencyFormatter';
import { formatDateShort } from 'src/lib/formatters/dateTimeFormatter';
import styled from 'styled-components';

export type UsagePageProps = {
  organization: Organization;
};

const StyledEmptyContainer = styled(Container)`
  margin-top: -3.5rem;
`;

const UsagePage = ({ organization }: UsagePageProps): ReactElement => {
  const theme = useCUITheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentInstances = useCurrentOrganizationInstances();
  const queryBillDate = getBillDateFromUrl(location);
  const queryMetric = getUsageMetricFromUrl(location);
  const [billDate, setBillDate] = useState<string | undefined>(queryBillDate);
  const [metric, setMetric] = useState<UsageMetric>(queryMetric);
  const [includeDeletedInstances, setIncludeDeletedInstances] =
    useState<boolean>(true);

  // load usage data from backend
  const { isChartLoading, error, usageReport, billDates, usageChart } =
    useAdminUsageReport(organization.id, billDate, metric);

  useEffect(() => {
    // initial API call triggered by the `useAdminUsageReport` hook omits billDate and returns the latest bill report + an array of billDates that can then be used for subsequent requests.
    // However, this useEffect immediately sets billDate as soon as the array is returned, causing the useAdminUsageReport to fire another request that now includes it, triggering a re-render
    // I've adjusted the logic here to only invoke `setBillDate` if the current usageReport returned from the hook does _not_ match the latest billDate
    if (
      billDates.length !== 0 &&
      !billDate &&
      billDates[0].billDate !== usageReport.billDate
    ) {
      setBillDate(billDates[0].billDate);
    }
  }, [billDates, billDate, usageReport.billDate]);

  const onChangeMetric = (newMetric: UsageMetric): void => {
    setMetric(newMetric);
    updateUrl(
      location,
      navigate,
      USAGE_PAGE_METRIC_QUERY_PARAM_NAME,
      newMetric as string
    );
  };
  const onChangeBillDate = (newBillDate: string): void => {
    setBillDate(newBillDate);
    updateUrl(
      location,
      navigate,
      USAGE_PAGE_BILL_DATE_QUERY_PARAM_NAME,
      newBillDate
    );
  };

  const instanceColors = getInstanceColors(
    theme,
    currentInstances,
    usageReport
  );

  const chartStatus =
    // prettier-ignore
    billDate === null
      ? 'ERROR'
      : isChartLoading ||
      Object.keys(usageChart).length === 0
        ? 'LOADING'
        : 'OK';

  if (error) {
    return (
      <StyledEmptyContainer
        isResponsive={false}
        alignItems="center"
        justifyContent="center"
        gap="lg"
        fillWidth
        fillHeight
      >
        <Container
          orientation="vertical"
          isResponsive={false}
          gap="lg"
          alignItems="center"
          fillWidth
          maxWidth="400px"
        >
          <Container
            gap="md"
            orientation="vertical"
            isResponsive={false}
            alignItems="center"
            fillWidth
          >
            <Icon name="document" size="xxl" />
            <Title type="h2">Report unavailable</Title>
            <Text>
              A usage report is currently not available. Please try again later
              or contact support if you think there’s been an error.
            </Text>
          </Container>
          <ChatWithUsButton />
        </Container>
      </StyledEmptyContainer>
    );
  }

  const reportStatus = Object.keys(usageReport).length === 0 ? 'LOADING' : 'OK';
  const showIncludeDeletedInstancesToggle = organization.features.includes(
    'FT_ORG_SHOW_INCLUDE_DELETED_SERVICES_TOGGLE'
  );
  return (
    <SplitLayout>
      <Container padding="lg" orientation="vertical" gap="lg" fillWidth>
        <BigStat
          title={
            usageReport.grossBillableAmount !== undefined ? (
              <Title
                type="h4"
                size="md"
                className="fs-exclude"
                data-testid={'gross-billable-amount'}
              >
                {formatCurrency(
                  usageReport.grossBillableAmount,
                  organization.paymentDetails.currency
                )}
              </Title>
            ) : (
              <Icon
                name="horizontal-loading"
                height="1.5rem"
                width="1.5rem"
                data-testid={'horizontal-loading'}
              />
            )
          }
          label="Cost this billing cycle"
          data-testid="billing-cycle-panel"
          fillWidth
        ></BigStat>

        <Spacer size="sm" />
        <Container
          orientation="horizontal"
          gap="lg"
          alignItems="center"
          fillWidth
          justifyContent="end"
          isResponsive={false}
          data-testid={'time-period-container'}
        >
          <Text color="default" weight="semibold" data-testid={'time-period'}>
            Time period
          </Text>
          <Container isResponsive={false} maxWidth="16rem">
            <Select
              value={billDate ? billDate : billDates[0]?.billDate}
              placeholder="Select a billing period"
              onSelect={(val: string): void => onChangeBillDate(val)}
            >
              {billDates
                .filter(
                  ((): ((e: BillPeriodDates) => boolean) => {
                    const seen = new Set<string>();
                    return (e: BillPeriodDates) => {
                      if (seen.has(e.billDate)) {
                        return false;
                      } else {
                        seen.add(e.billDate);
                        return true;
                      }
                    };
                  })()
                )
                .map((bill, idx) => (
                  <Select.Item
                    value={bill.billDate}
                    key={idx}
                    data-testid={`timePeriod-item-${bill.billDate}`}
                  >
                    {formatDateShort(bill.startDate)} to{' '}
                    {formatDateShort(bill.endDateInclusive)}
                  </Select.Item>
                ))}
            </Select>
          </Container>
        </Container>
        <Panel
          orientation="vertical"
          height="375px"
          fillWidth
          alignItems="center"
          hasBorder
          data-testid="chart-panel"
        >
          <UsageChart
            metric={metric}
            onChangeMetricType={onChangeMetric}
            instanceColors={instanceColors}
            status={chartStatus}
            usageData={usageChart}
          />
        </Panel>
        {showIncludeDeletedInstancesToggle && (
          <Container
            orientation="horizontal"
            alignItems="end"
            justifyContent="end"
            data-testid="deleted-instance-toggle-container"
            fillWidth
          >
            <Tooltip>
              <Tooltip.Trigger css={[flex, alignCenter, gapSm]}>
                <Switch
                  data-testid="deleted-instance-toggle"
                  checked={includeDeletedInstances}
                  dir="start"
                  label="Include deleted services"
                  orientation="horizontal"
                  onClick={(): void =>
                    setIncludeDeletedInstances(!includeDeletedInstances)
                  }
                />
              </Tooltip.Trigger>
              <Tooltip.Content side="top">
                Excluding deleted services will not change the table's total
                line.
              </Tooltip.Content>
            </Tooltip>
          </Container>
        )}
        <Container
          orientation="vertical"
          alignItems="center"
          data-testid="usage-table-container"
          fillWidth
        >
          <UsageTable
            usageReport={usageReport}
            cpInstances={currentInstances}
            instanceColors={instanceColors}
            status={reportStatus}
            includeDeletedInstances={includeDeletedInstances}
            currency={organization.paymentDetails.currency}
          />
        </Container>
      </Container>
    </SplitLayout>
  );
};

const USAGE_PAGE_METRIC_QUERY_PARAM_NAME = 'metric';
const USAGE_PAGE_BILL_DATE_QUERY_PARAM_NAME = 'billDate';

const DEFAULT_CHART_METRIC = 'compute';

const USAGE_METRIC_BY_QUERY_PARAM: Record<string, UsageMetric> = {
  storage: 'STORAGE_GB_MINUTES_TABLES',
  compute: 'COMPUTE_UNIT_MINUTES',
  backups: 'STORAGE_GB_MINUTES_PAID_BACKUPS'
};

/** Extracts metric type from URL params. */
function getUsageMetricFromUrl(location: Location): UsageMetric {
  const currentParams = new URLSearchParams(location.search);
  const metricType =
    currentParams.get(USAGE_PAGE_METRIC_QUERY_PARAM_NAME) ??
    DEFAULT_CHART_METRIC;

  return USAGE_METRIC_BY_QUERY_PARAM[metricType];
}

/** Extracts bill date type from URL params. */
function getBillDateFromUrl(location: Location): string | undefined {
  const currentParams = new URLSearchParams(location.search);
  return currentParams.get(USAGE_PAGE_BILL_DATE_QUERY_PARAM_NAME) ?? undefined;
}

const USAGE_QUERY_PARAM_BY_METRIC: Record<UsageMetric, string> =
  Object.fromEntries(
    Object.entries(USAGE_METRIC_BY_QUERY_PARAM).map(([k, v]) => [v, k])
  ) as Record<UsageMetric, string>;

/** Converts metric to URL param. */
function getAdminUsageMetricUrlParamValue(metric: UsageMetric): string {
  return USAGE_QUERY_PARAM_BY_METRIC[metric];
}

/** Updates url with the selected metric */
function updateUrl(
  location: Location,
  navigate: NavigateFunction,
  queryParamName: string,
  queryParamValue: string
): void {
  const currentParams = new URLSearchParams(location.search);
  const value: string =
    queryParamName === USAGE_PAGE_METRIC_QUERY_PARAM_NAME
      ? getAdminUsageMetricUrlParamValue(queryParamValue as UsageMetric)
      : queryParamValue;
  currentParams.set(queryParamName, value);
  navigate(`${location.pathname}?${currentParams.toString()}`);
}

function getInstanceColors(
  theme: CUIThemeType,
  instances: Instance[],
  usageReport: OrganizationUsageReport
): Map<string, string> {
  const chartColumnsBars: string[] = [
    theme.global.color.chart.bars.green,
    theme.global.color.chart.bars.blue,
    theme.global.color.chart.bars.fuchsia,
    theme.global.color.chart.bars.orange,
    theme.global.color.chart.bars.violet,
    theme.global.color.chart.bars.teal
  ];
  const instanceIds = usageReport.instanceReports
    ? usageReport.instanceReports.reduce((ids: Set<string>, instanceReport) => {
        ids.add(instanceReport.instanceId);
        return ids;
      }, new Set<string>())
    : new Set<string>();
  Object.keys(instances).forEach((instanceId) => instanceIds.add(instanceId));

  return [...instanceIds].reduce((map: Map<string, string>, id, index) => {
    const colorIndex = index % chartColumnsBars.length;
    map.set(id, chartColumnsBars[colorIndex]);
    return map;
  }, new Map<string, string>());
}

export default UsagePage;
