import { HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const SplitBackground = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme }) => `
    background: linear-gradient(
      to bottom,
      ${theme.global.color.background.split} 144px,
      ${theme.global.color.stroke.split} 145px,
      ${theme.global.color.background.default} 145px,
      ${theme.global.color.background.default} 100%
      );
  `}

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoint.sizes.sm}) {
      background: ${theme.global.color.background.default};
    }
  `}
`;
function SplitLayout(props: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <SplitBackground {...props} />;
}

export default SplitLayout;
