import {
  CodeBlock,
  Container,
  Icon,
  InfoAlert,
  Link,
  Select,
  Spacer,
  Text,
  VerticalStepper
} from '@clickhouse/click-ui';
import { QueryEndpoint } from '@prisma/client';
import { ReactElement, useState } from 'react';
import {
  getCurlCommand,
  SupportedFormat,
  V1_SUPPORTED_FORMATS
} from 'shared/src/queryEndpoints';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { QueryEndpointsCorsForm } from 'src/components/QueryEndpoints/QueryEndpointsCorsForm';
import { QueryEndpointsKeysForm } from 'src/components/QueryEndpoints/QueryEndpointsKeysForm';
import { QueryEndpointsRolesForm } from 'src/components/QueryEndpoints/QueryEndpointsRolesForm';
import { useSavedQuery } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import config from 'src/lib/config';
import { useUserFeature } from 'src/lib/features';
import { UseShareModalQueryEndpointsResult } from 'src/queryEndpoints/queryEndpointsController/useShareModalQueryEndpoints';
import { useTabActions } from 'src/state/tabs';
import styled from 'styled-components';

export type ShareQueryEndpointTabProps = {
  queryId: string;
  queryParameters?: Record<string, string>;
  onClose: () => void;
} & UseShareModalQueryEndpointsResult;

type MonitorEndpointInfoAlertProps = {
  queryEndpoint: QueryEndpoint;
  onClick: () => void;
};

function MonitorEndpointInfoAlert(
  props: MonitorEndpointInfoAlertProps
): ReactElement {
  const onClick = (): void => {
    props.onClick();
    navigateTo('#');
  };
  const message = (
    <>
      You can now monitor how your your API endpoint is used{' '}
      <Link size="sm" onClick={onClick}>
        here
      </Link>
    </>
  );
  return <InfoAlert text={message} />;
}

const CustomSelect = styled(Select)`
  label {
    width: 100px;
  }
`;

const CustomCodeBlock = styled(CodeBlock)`
  pre {
    ${({ theme }) =>
      theme.name === 'dark' &&
      `background: ${theme.global.color.background.sidebar} !important`}
  }
`;

export function ShareQueryEndpointTab({
  queryId,
  queryParameters,
  onClose,
  ...useShareModalQueryEndpointsResult
}: ShareQueryEndpointTabProps): ReactElement {
  const hasCorsFFEnabled = useUserFeature('FT_USER_QUERY_ENDPOINTS_CORS');

  const [selectedFormat, selectFormat] =
    useState<SupportedFormat>('JSONEachRow');

  const {
    initialLoading,
    validationError,
    queryEndpoint,
    removeKey,
    addKey,
    setRoles,
    roles,
    allowedOrigins,
    setAllowedOrigins
  } = useShareModalQueryEndpointsResult;

  const { selectQueryApiEndpoint } = useTabActions();
  const query = useSavedQuery(queryId);

  const openApiEndpointTab = (): void => {
    if (queryEndpoint && query) {
      selectQueryApiEndpoint(query, queryEndpoint.id);
      onClose();
    }
  };

  if (initialLoading) {
    return (
      <Container data-testid="query-endpoint-tab">
        <Icon
          name="horizontal-loading"
          size="xl"
          data-testid="query-endpoint-tab-loading"
        />
      </Container>
    );
  }

  const curlCommand = queryEndpoint
    ? getCurlCommand({
        parameters: queryParameters || ({} as Record<string, string>),
        queryEndpointHost: config.apiUrl.replace('/.api', ''),
        queryEndpointId: queryEndpoint.id,
        format: selectedFormat,
        version: queryEndpoint.version
      })
    : '';

  const apiKeyCompleted = queryEndpoint && queryEndpoint.openApiKeys.length > 0;
  const rolesCompleted = apiKeyCompleted && roles.length > 0;
  const endpointCompleted = rolesCompleted && queryEndpoint;

  return (
    <VerticalStepper data-testid="query-endpoint-tab">
      <Spacer size="lg" />
      <VerticalStepper.Step
        label="Select API key"
        collapsed={false}
        status={queryEndpoint === null ? 'active' : 'complete'}
      >
        <QueryEndpointsKeysForm
          endpointKeys={queryEndpoint?.openApiKeys ?? []}
          onAddKey={addKey}
          onRemoveKey={removeKey}
          error={validationError?.details?.message}
        />
      </VerticalStepper.Step>

      <VerticalStepper.Step
        label="Access control"
        collapsed={false}
        status={rolesCompleted ? 'complete' : 'incomplete'}
      >
        {apiKeyCompleted && (
          <QueryEndpointsRolesForm
            roles={roles}
            setRoles={(newRoles) => {
              void setRoles(newRoles);
            }}
          />
        )}
      </VerticalStepper.Step>
      {hasCorsFFEnabled && (
        <VerticalStepper.Step
          label="Security"
          collapsed={false}
          status={rolesCompleted ? 'complete' : 'incomplete'}
        >
          {rolesCompleted && (
            <QueryEndpointsCorsForm
              allowedOrigins={allowedOrigins}
              setAllowedOrigins={(value) => {
                void setAllowedOrigins(value);
              }}
            />
          )}
        </VerticalStepper.Step>
      )}
      <VerticalStepper.Step
        label="API Endpoint"
        collapsed={false}
        status={endpointCompleted ? 'complete' : 'incomplete'}
      >
        {endpointCompleted && (
          <Container orientation="vertical" gap="lg">
            <Text color="muted">
              Your endpoint will be accessed through an API key
            </Text>
            <CustomSelect
              label="Result format"
              value={selectedFormat}
              orientation="horizontal"
            >
              {V1_SUPPORTED_FORMATS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  onSelect={(value: string): void =>
                    selectFormat(value as SupportedFormat)
                  }
                >
                  {format}
                </Select.Item>
              ))}
            </CustomSelect>
            <CustomCodeBlock
              wrapLines
              language={'bash'}
              className="fs-exclude"
              data-testid="query-endpoint-syntax-highlighter"
            >
              {curlCommand}
            </CustomCodeBlock>
            <MonitorEndpointInfoAlert
              queryEndpoint={queryEndpoint}
              onClick={openApiEndpointTab}
            />
          </Container>
        )}
      </VerticalStepper.Step>
    </VerticalStepper>
  );
}
