import {
  Container,
  EllipsisContent,
  GridContainer,
  Icon,
  IconButton,
  Text,
  Tooltip,
  WarningAlert,
  useCUITheme,
  Panel,
  Spacer
} from '@clickhouse/click-ui';
import React, { ReactElement, useState } from 'react';
import { TableDetails, getCustomColumnType } from 'shared/src/clickhouse';
import { HorizontalBars } from 'src/components/TableView/DataView/RightBar/TableInspector/HorizontalBars';
import { humanFileSize } from 'src/lib/bytesUtils';

import { formatDataSize } from 'src/lib/formatters/numberFormatter';
import styled from 'styled-components';
import {
  ColumnDescriptionProps,
  ColumnProps
} from 'src/components/TableView/DataView/RightBar/TableInspector/types';
import { useColumnValuesDistribution } from 'src/components/TableView/DataView/RightBar/TableInspector/useColumnValuesDistribution';
import { useColumnTopDetails } from 'src/components/TableView/DataView/RightBar/TableInspector/useColumnTopDetails';
import { useColumnTimestampsDistribution } from 'src/components/TableView/DataView/RightBar/TableInspector/useColumnTimestampsDistribution';
import { sortAlphabetically } from 'src/lib/sorters/sortAlphabetically';
import { formatDate } from 'src/lib/formatters/dateTimeFormatter';
import {
  Gradients,
  TooltipFormatter,
  TooltipFormmatterArgs,
  XYChart,
  XYChartValue,
  XYSeriesDescriptor
} from '@clickhouse/viz-house';

interface CollapsibleSectionProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  children: ReactElement;
  title: ReactElement;
}

const CollapsibleSection = ({
  children,
  title,
  ...rest
}: CollapsibleSectionProps): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const icon = expanded ? 'chevron-down' : 'chevron-right';

  return (
    <Container orientation="vertical" {...rest}>
      <Container gap="md">
        <IconButton
          type="ghost"
          data-testid="collapsible-section-toggle"
          icon={icon}
          onClick={() => setExpanded(!expanded)}
        />
        {title}
      </Container>

      {expanded && <Container padding="sm">{children}</Container>}
    </Container>
  );
};

const ColumnDescription = ({
  name,
  type,
  dataUncompressedBytes
}: ColumnDescriptionProps): ReactElement => {
  const uncompressed = humanFileSize(dataUncompressedBytes);

  return (
    <GridContainer gridTemplateColumns="150px 150px auto">
      <Tooltip>
        <Tooltip.Trigger>
          <EllipsisContent className="fs-exclude">
            <Text weight="mono">{name}</Text>
          </EllipsisContent>
        </Tooltip.Trigger>
        <Tooltip.Content side="bottom" className="fs-exclude">
          {name}
        </Tooltip.Content>
      </Tooltip>

      <Tooltip>
        <Tooltip.Trigger>
          <EllipsisContent className="fs-exclude">
            <Text color="muted" weight="mono" align="right">
              {type}
            </Text>
          </EllipsisContent>
        </Tooltip.Trigger>
        <Tooltip.Content side="bottom" className="fs-exclude">
          {type}
        </Tooltip.Content>
      </Tooltip>

      <Container justifyContent="end" alignItems="center">
        <EllipsisContent className="fs-exclude">
          <Text color="muted" weight="mono" align="right">
            {uncompressed}
          </Text>
        </EllipsisContent>
      </Container>
    </GridContainer>
  );
};

const HorizontalSpace = styled.div`
  width: 35px;
`;

const LoadingDetails = (): ReactElement => {
  return (
    <Container>
      <Icon name="dots-horizontal" />
    </Container>
  );
};

const ColumnTopDetails = (
  props: Omit<ColumnProps, 'dataUncompressedBytes'>
): ReactElement => {
  const { loading, topN, error } = useColumnTopDetails(props);

  if (loading) {
    return <LoadingDetails />;
  }

  if (error) {
    return <WarningAlert text={error} />;
  }

  return (
    <Container gap="md">
      <HorizontalBars data={topN} />
    </Container>
  );
};

const ColumnValuesDistribution = (
  props: Omit<ColumnProps, 'dataUncompressedBytes'>
): ReactElement => {
  const { loading, values, error } = useColumnValuesDistribution(props);
  const theme = useCUITheme();

  if (loading) {
    return <LoadingDetails />;
  }

  if (error) {
    return <WarningAlert text={error} />;
  }

  const tooltipFormatter: TooltipFormatter = ({
    yValue
  }: TooltipFormmatterArgs): string => {
    return formatDataSize(yValue || 0, 'short-scale-letter');
  };

  const data: Array<XYChartValue> = [];
  const uniqueData = new Set<number>();

  values.forEach(({ min, max, count }) => {
    const x = Math.round((max + min) / 2);
    data.push({ x, y: count });
    uniqueData.add(x);
  });

  // if there are more than 5 unique values, show vertical labels at an angle of 45
  const hasVerticalLabels = uniqueData.size > 5;

  const seriesType = hasVerticalLabels ? 'bellcurve' : 'bar';

  const series: XYSeriesDescriptor = {
    type: seriesType,
    name: props.name,
    values: data,
    color: theme.global.color.chart.bars.blue,
    fillColor: Gradients.linear(theme.global.color.chart.bars.blue)
  };

  const histogram = (
    <XYChart
      series={[series]}
      height="180px"
      tooltipFormatter={tooltipFormatter}
      xAxis={{
        type: seriesType === 'bar' ? 'category' : 'linear',
        verticalLabels: hasVerticalLabels,
        domain:
          seriesType === 'bar' ? Array.from<number>(uniqueData) : undefined,
        labelsFormatter: ({ value }) =>
          formatDataSize(
            typeof value === 'number' ? value : Number(value),
            'short-scale-letter'
          )
      }}
    />
  );

  return (
    <Panel fillWidth orientation="vertical" gap="md" hasBorder>
      <Spacer size="sm" />
      {histogram}
    </Panel>
  );
};

const ColumnTimestampsDistribution = (
  props: Omit<ColumnProps, 'dataUncompressedBytes'>
): ReactElement => {
  const { loading, values, error } = useColumnTimestampsDistribution(props);
  const theme = useCUITheme();

  if (loading) {
    return <LoadingDetails />;
  }

  if (error) {
    return <WarningAlert text={error} />;
  }

  const data: Array<XYChartValue> = [];
  const uniqueData = new Set<number>();

  values.forEach(({ slot, count }) => {
    const x = new Date(slot).getTime();
    data.push({ x, y: count });
    uniqueData.add(x);
  });

  const series: XYSeriesDescriptor = {
    type: 'bellcurve',
    name: props.name,
    values: data,
    color: theme.global.color.chart.bars.blue,
    fillColor: Gradients.linear(theme.global.color.chart.bars.blue)
  };

  const histogram = (
    <XYChart
      series={[series]}
      height="180px"
      xAxis={{
        type: 'datetime',
        verticalLabels: true,
        labelsFormatter: ({ value }) => formatDate(value)
      }}
    />
  );

  return (
    <Panel fillWidth orientation="vertical" gap="md" hasBorder>
      <Spacer size="sm" />
      {histogram}
    </Panel>
  );
};

const ColumnDetails = (
  props: Omit<ColumnProps, 'dataUncompressedBytes'>
): ReactElement => {
  if (['UInt', 'Float'].some((t) => props.type.includes(t))) {
    return <ColumnValuesDistribution {...props} />;
  }

  if (['DateTime', 'Timestamp'].some((t) => props.type.includes(t))) {
    return <ColumnTimestampsDistribution {...props} />;
  }

  return <ColumnTopDetails {...props} />;
};

const Column = ({
  name,
  type,
  dataUncompressedBytes,
  tableName,
  database
}: ColumnProps): ReactElement => {
  const title = (
    <ColumnDescription
      name={name}
      type={type}
      dataUncompressedBytes={dataUncompressedBytes}
    />
  );

  const hasDetails = [
    'String',
    'IPv4',
    'UUID',
    'IPv6',
    'Enum',
    'UInt',
    'Float',
    'Date',
    'Timestamp'
  ].some((t) => type.includes(t));

  if (!hasDetails) {
    return (
      <Container gap="md" data-testid={`column-${name}`}>
        <HorizontalSpace />
        {title}
      </Container>
    );
  }

  return (
    <CollapsibleSection title={title} data-testid={`column-${name}`}>
      <ColumnDetails
        name={name}
        type={type}
        tableName={tableName}
        database={database}
      />
    </CollapsibleSection>
  );
};

type ColumnSummary = {
  name: string;
  type: string;
  dataUncompressedBytes: number;
};

type ColumnsListProps = {
  columns: TableDetails['columns'];
  tableName: string;
  database: string;
};

export const ColumnsList = ({
  columns,
  tableName,
  database
}: ColumnsListProps): ReactElement => {
  const list: Array<ColumnSummary> = columns
    .map(({ name, type, dataUncompressedBytes }) => ({
      name,
      type: getCustomColumnType(type),
      dataUncompressedBytes
    }))
    .sort((a, b) => sortAlphabetically(a.name, b.name));

  return (
    <Container
      orientation="vertical"
      gap="md"
      data-testid="table-inspector-column-details"
    >
      {list.map(({ name, type, dataUncompressedBytes }) => (
        <Column
          key={`col-${name}`}
          name={name}
          tableName={tableName}
          database={database}
          type={type}
          dataUncompressedBytes={dataUncompressedBytes}
        />
      ))}
    </Container>
  );
};
