import { SampleSchema, SourceField } from 'types/protocol';
import { Column } from 'shared/src/types/clickhouse/http';

function maybeWrapNullable(schema: SourceField): string {
  if (!schema.type.trim().toLowerCase().startsWith('nullable') && schema.optional) {
    return `Nullable(${schema.type})`;
  }
  return schema.type;
}

export function convertSampleSchemaToColumns(sourceSchema: SampleSchema): Column[] {
  return sourceSchema.map((schema) => {
    return {
      type: maybeWrapNullable(schema),
      name: schema.name
    };
  });
}

export function isNoDataReceivedError(error: string): boolean {
  return error === 'no data received from source';
}
