import { useCUITheme } from '@clickhouse/click-ui';
import React, { CSSProperties } from 'react';

import ContentLoader from 'react-content-loader';

const HEIGHT = 35;

interface Props {
  rows?: number;
  style?: CSSProperties;
}
export default function TableLoader(props: Props) {
  const { rows = 10 } = props;
  const theme = useCUITheme();
  return (
    <ContentLoader
      speed={2}
      width={250}
      height={HEIGHT * rows}
      viewBox={`0 0 250 ${HEIGHT * rows}`}
      foregroundColor={theme.global.color.stroke.intense}
      backgroundColor={theme.global.color.stroke.muted}
      {...props}
      style={{
        height: HEIGHT * rows,
        ...(props.style || {})
      }}
      data-testid="table-list-loader"
    >
      {new Array(rows).fill('').map((_, index) => (
        <React.Fragment key={`content-loader-svg-path-${index}`}>
          <path
            d="M11.375 4.0625L6.5 8.9375L1.625 4.0625"
            strokeWidth="2.4375"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              transform: `translate(18px, ${
                24 + index * 35
              }px) rotate(-90deg) scale(1.4)`
            }}
          />
          {/* <rect
            x="20"
            y={10 + index * 35}
            rx="4"
            ry="4"
            width="10"
            height="10"
          /> */}
          <rect
            x="40"
            y={10 + index * 35}
            rx="5"
            ry="5"
            width="150"
            height="10"
          />
        </React.Fragment>
      ))}
      {/* <rect x="3" y="5" rx="4" ry="4" width="20" height="20" /> */}
      {/* <rect x="35" y="10" rx="5" ry="5" width="150" height="10" /> */}
      {/* <rect x="3" y="40" rx="4" ry="4" width="20" height="20" /> */}
      {/* <rect x="35" y="45" rx="5" ry="5" width="150" height="10" /> */}
      {/* <rect x="3" y="75" rx="4" ry="4" width="20" height="20" /> */}
      {/* <rect x="35" y="80" rx="5" ry="5" width="150" height="10" /> */}
      {/* <rect x="3" y="110" rx="4" ry="4" width="20" height="20" /> */}
      {/* <rect x="35" y="115" rx="5" ry="5" width="150" height="10" /> */}
    </ContentLoader>
  );
}
