import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { PaginationState } from 'src/components/primitives/lib/Spreadsheet/types';

export interface OnPaginationStateChangeProps {
  currentPage?: number;
  pageSize?: number;
}

export type PaginationPropsType = {
  paginationState: PaginationState | undefined;
  onPaginationStateChange: (props: OnPaginationStateChangeProps) => void;
  pagination: boolean;
};

export interface PaginationProps {
  paginationProps: PaginationPropsType;
}

export default function usePaginationState(numRows: number, pageSize: number, currentPage?: number): PaginationProps {
  const prevPageSize = useRef<number>(pageSize);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    totalRows: numRows,
    currentPage: currentPage || 1,
    totalPages: Math.ceil(numRows / pageSize)
  });

  const onPaginationStateChange = useCallback(
    async ({ currentPage, pageSize: newPageSize }: OnPaginationStateChangeProps) => {
      if (paginationState.currentPage !== currentPage || newPageSize !== pageSize) {
        setPaginationState((state) => ({ ...state, currentPage: currentPage }));
      }
    },
    [pageSize, paginationState]
  );

  useEffect(() => {
    setPaginationState((oldState) => ({
      ...oldState,
      totalRows: numRows,
      totalPages: Math.ceil(numRows / pageSize)
    }));
  }, [numRows, pageSize]);

  useEffect(() => {
    if (prevPageSize.current !== pageSize) {
      prevPageSize.current = pageSize;
      setPaginationState((oldState) => ({
        ...oldState,
        currentPage: 1
      }));
    }
  }, [pageSize]);

  return useMemo(() => {
    if (pageSize === 0) {
      return {
        paginationProps: {
          paginationState: undefined,
          onPaginationStateChange: () => null,
          pagination: false
        }
      };
    } else {
      return {
        paginationProps: {
          paginationState,
          onPaginationStateChange,
          pagination: true
        }
      };
    }
  }, [onPaginationStateChange, pageSize, paginationState]);
}
