import { ReactNode, useState } from 'react';
import DashboardSidebar from 'src/components/Dashboards/DashboardsSidebar';
import Helmet from 'src/components/Helmet/Helmet';
import { SavedQueriesProvider } from 'src/components/QueryView/SavedQueriesProvider';
import { useCurrentInstance } from 'src/instance/instanceController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import createHTML5Backend from 'react-dnd-html5-backend-cjs';
import styled from 'styled-components';

import { DndProvider as _DndProvider, DndProviderProps } from 'react-dnd-cjs';
import { FCChild } from 'types/commonTypes';
import { CreateDashboardDialog } from 'src/components/Dashboards/DashboardView/CreateDashboardDialog';
import { Outlet } from 'react-router-dom';

const DndProvider = _DndProvider as unknown as FCChild<
  DndProviderProps<unknown, unknown>
>;

const DashboardPageContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;

  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
`;

export interface DashboardOutletContext {
  serviceId: string;
  showCreateDialog: () => void;
}

export function DashboardLayout(): ReactNode {
  const [isCreateDialogVisible, setIsCreateDialogVisible] =
    useState<boolean>(false);

  const instance = useCurrentInstance();

  if (!instance) {
    return null;
  }

  const showCreateDialog = (): void => {
    setIsCreateDialogVisible(true);
  };

  const closeCreateDialog = (): void => {
    setIsCreateDialogVisible(false);
  };

  return (
    <SavedQueriesProvider>
      <DndProvider backend={createHTML5Backend}>
        <DashboardPageContainer>
          {isCreateDialogVisible && (
            <CreateDashboardDialog onClose={closeCreateDialog} />
          )}
          <DashboardSidebar showCreateDialog={showCreateDialog} />
          <MainPaneLayout>
            <MainPaneLayout.Content>
              <Helmet>
                <title>Dashboards</title>
              </Helmet>
              <Outlet
                context={
                  {
                    serviceId: instance.id,
                    showCreateDialog
                  } satisfies DashboardOutletContext
                }
              />
            </MainPaneLayout.Content>
          </MainPaneLayout>
        </DashboardPageContainer>
      </DndProvider>
    </SavedQueriesProvider>
  );
}
