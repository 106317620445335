import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useCurrentInstance } from 'src/instance/instanceController';
import { useApiClient } from 'src/lib/controlPlane/client';

import { ResolveReject } from 'src/state/types';

// if the value is a string, the text in the modal is updated with the string value
const modalOpen = atom<boolean>(false);
const modalMessage = atom<string>('');
const setWakeServiceModalOpenWriteAtom = atom(
  null,
  (get, set, open: boolean, message?: string, promise?: ResolveReject<void>) => {
    set(modalOpen, open);

    if (!open) {
      set(modalMessage, '');
    } else if (message && !get(modalMessage)) {
      // only update if modalMessage isn't already set to prevent weird text flashes
      set(modalMessage, message);
    }

    if (promise) {
      set(wakeServiceClosePromisesAtom, [...get(wakeServiceClosePromisesAtom), promise]);
    }
  }
);

const wakeServiceClosePromisesAtom = atom<ResolveReject<void>[]>([]);

export function useSetWakeServiceModalOpen(): (open: boolean, message?: string, promise?: ResolveReject<void>) => void {
  return useSetAtom(setWakeServiceModalOpenWriteAtom);
}

interface UseWakeService {
  cancelWakeServiceModal: () => void;
  confirmWakeServiceModal: () => void;
  wakeServiceModalMessage: string;
  wakeServiceModalOpen: boolean;
  wakeupService: () => Promise<void>;
  setWakeServiceModalOpen: (open: boolean, message?: string, promise?: ResolveReject<void>) => void;
}

export function useWakeService(): UseWakeService {
  const wakeServiceModalOpen = useAtomValue(modalOpen);
  const wakeServiceModalMessage = useAtomValue(modalMessage);
  const [wakeServiceClosePromises, setWakeServiceClosePromises] = useAtom(wakeServiceClosePromisesAtom);
  const setWakeServiceModalOpen = useSetWakeServiceModalOpen();
  const apiClient = useApiClient();
  const currentService = useCurrentInstance();

  const cancelWakeServiceModal = useCallback((): void => {
    setWakeServiceModalOpen(false);
    // This whole pattern of promises needs to be improved. Its hard to follow and debug.
    // We should consider rearchitecturing this whole logic to be based on state instead of asking and awaiting on promises
    wakeServiceClosePromises.forEach((promise) => {
      promise.reject('Please awake service to load metadata'); // don't reject with an Error because we don't want a toast to show by the confirmation modal
    });
    setWakeServiceClosePromises([]);
  }, [wakeServiceClosePromises, setWakeServiceClosePromises, setWakeServiceModalOpen]);

  const wakeupService = useCallback(async (): Promise<void> => {
    if (!currentService?.id) {
      console.error('No service found for `wakeupService()`');
      return;
    }

    const instanceId = currentService.id;
    return await apiClient.instance.updateServiceState({ instanceId, state: 'awaking' }).then(
      () => {
        // Do nothing
      },
      (reason) => {
        console.error(`Failed to set awaking state for the service ${instanceId}`, reason);
      }
    ); // Error!});
  }, [apiClient.instance, currentService?.id]);

  const confirmWakeServiceModal = useCallback((): void => {
    wakeServiceClosePromises.forEach((promise) => {
      promise.resolve();
    });
    setWakeServiceModalOpen(false);
    setWakeServiceClosePromises([]);
    wakeupService().catch(console.error);
  }, [wakeServiceClosePromises, setWakeServiceClosePromises, setWakeServiceModalOpen, wakeupService]);

  return {
    cancelWakeServiceModal,
    confirmWakeServiceModal,
    wakeServiceModalMessage,
    wakeServiceModalOpen,
    setWakeServiceModalOpen,
    wakeupService
  };
}
