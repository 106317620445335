import React, { ReactElement } from 'react';

import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { useHotkeys } from 'react-hotkeys-hook';

import { tableContainer } from 'src/components/primitives/lib/Spreadsheet/styles';
import {
  HotkeysHandlers,
  VirtualizedTableProps
} from 'src/components/primitives/lib/Spreadsheet/types';
import Table from 'src/components/primitives/lib/Spreadsheet/VirtualizedTable';

interface GridMainProps extends VirtualizedTableProps {
  hotKeysHandlers: HotkeysHandlers;
  numRows: number;
  hideBorder: boolean;
}

function SpreadsheetGridMain({
  hotKeysHandlers,
  numRows,
  hideBorder,
  ...otherProps
}: GridMainProps): ReactElement {
  const optionKeys = Object.keys(hotKeysHandlers || {});

  useHotkeys(
    optionKeys,
    (_, handler) => {
      const list = [
        ...keys(pickBy(handler, (value) => value === true)),
        ...(handler.keys ?? [])
      ];
      const keyHandler =
        list.length > 0 ? hotKeysHandlers[list.join('+')] : null;
      if (typeof keyHandler === 'function') {
        keyHandler();
      }
    },
    {
      enableOnFormTags: [],
      enabled: (e, handler) => {
        if (['meta', 'ctrl', 'shift'].includes(e.key.toLowerCase())) {
          return false;
        }
        const list = [
          ...keys(pickBy(handler, (value) => value === true)),
          ...(handler.keys ?? [])
        ];
        if (!list.includes(e.key.toLowerCase())) {
          list.push(e.key.toLowerCase());
        }
        return typeof hotKeysHandlers[list.join('+')] !== 'undefined';
      }
    },
    [hotKeysHandlers, optionKeys]
  );

  return (
    <div
      style={{ height: '100%' }}
      css={tableContainer}
      data-hasborder={!hideBorder}
    >
      <Table numRows={numRows} {...otherProps} />
    </div>
  );
}

export default React.memo(SpreadsheetGridMain);
