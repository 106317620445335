import { ReactElement, useEffect, useRef } from 'react';

import { CreateTable } from 'src/components/CreateTableView';
import Helmet from 'src/components/Helmet/Helmet';
import { useSelectedTab, useTabActions } from 'src/state/tabs';

const CreateTablePage = (): ReactElement | null => {
  const { addCreateTableTab } = useTabActions();
  const selectedTab = useSelectedTab();
  const tabFoundRef = useRef(false);

  useEffect(() => {
    if (selectedTab) {
      tabFoundRef.current = true;
    } else if (!tabFoundRef.current) {
      addCreateTableTab();
    }
  }, [selectedTab]);

  if (selectedTab?.type !== 'createTable') {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>CreateTable page</title>
      </Helmet>
      <CreateTable />
    </>
  );
};

export default CreateTablePage;
