import {
  Container,
  EllipsisContent,
  Icon,
  Panel,
  Table,
  TableHeaderType,
  TableRowType,
  Text,
  Tooltip,
  useCUITheme
} from '@clickhouse/click-ui';
import {
  Currency,
  InstanceUsageReport,
  OrganizationUsageReport
} from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import { Instance, InstanceCloudProvider } from '@cp/common/protocol/Instance';
import { truthy } from '@cp/common/utils/Assert';
import { ReactElement } from 'react';
import { formatCurrency } from 'src/lib/formatters/currencyFormatter';
import {
  StrapiPriceObject,
  useStrapiPricingTable
} from 'src/lib/pricing/pricing';

export type UsageTableProps = {
  usageReport: OrganizationUsageReport;
  cpInstances: Array<Instance>;
  instanceColors: Map<string, string>;
  status: 'OK' | 'LOADING';
  includeDeletedInstances: boolean;
  currency: Currency;
};

const UsageTable = ({
  usageReport,
  cpInstances,
  instanceColors,
  status,
  includeDeletedInstances,
  currency
}: UsageTableProps): ReactElement => {
  const {
    pricingTable,
    error: fetchPricingError,
    isLoading: isLoadingPricingTable
  } = useStrapiPricingTable(cpInstances);

  if (status === 'LOADING') {
    return <LoadingInfo />;
  }

  const tableHeaders: Array<TableHeaderType> = [
    { label: 'Service' },
    { label: 'Cloud provider' },
    { label: 'Compute' },
    { label: 'Storage' },
    { label: 'Backups' },
    { label: 'Total' }
  ];

  const tableRows: Array<TableRowType> = [];
  for (const instanceReport of usageReport.instanceReports) {
    if (!includeDeletedInstances && instanceReport.deleted) {
      // This is a deleted instance.
      continue;
    }
    const isValidPricingTable =
      fetchPricingError === undefined && !isLoadingPricingTable;
    const cost = getInstanceCost(
      instanceReport,
      pricingTable,
      isValidPricingTable,
      currency
    );
    const instanceRecord: TableRowType = {
      id: instanceReport.instanceId,
      items: [
        {
          label: (
            <InstanceNameCell
              color={instanceColors.get(instanceReport.instanceId)}
              name={instanceReport.instanceName}
              cloudProvider={instanceReport.instanceCloudProvider}
            />
          )
        },
        {
          label: (
            <CloudProviderCell
              cloudProvider={instanceReport.instanceCloudProvider}
            />
          )
        },
        {
          label: (
            <InstanceCostCell
              weight="normal"
              cost={cost?.computeCost}
              currency={currency}
            />
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <InstanceCostCell
              weight="normal"
              cost={cost?.storageCost}
              currency={currency}
            />
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <InstanceCostCell
              weight="normal"
              cost={cost?.backupsCost}
              currency={currency}
            />
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <InstanceCostCell
              weight="normal"
              cost={cost?.totalCost}
              currency={currency}
            />
          ),
          className: 'fs-exclude'
        }
      ]
    };
    tableRows.push(instanceRecord);
  }

  const totalUsageReport = usageReport.totalUsageReport;
  const totalComputeCost = totalUsageReport['COMPUTE_UNIT_MINUTES'].cost;
  const totalStorageCost = totalUsageReport['STORAGE_GB_MINUTES_TABLES'].cost;
  // I don't know why ESlint insists this could be `any`.
  const totalBackupsCost =
    totalUsageReport['STORAGE_GB_MINUTES_PAID_BACKUPS'].cost;

  const totalCost =
    isDefined(totalComputeCost) &&
    isDefined(totalStorageCost) &&
    isDefined(totalBackupsCost)
      ? totalComputeCost + totalStorageCost + totalBackupsCost
      : undefined;
  const totalRow: TableRowType = {
    id: 'total',
    items: [
      { label: <Text weight="bold">Total</Text> },
      { label: <Text weight="bold"> </Text> },
      {
        label: (
          <InstanceCostCell
            weight="bold"
            cost={totalComputeCost}
            currency={currency}
          />
        ),
        className: 'fs-exclude'
      },
      {
        label: (
          <InstanceCostCell
            weight="bold"
            cost={totalStorageCost}
            currency={currency}
          />
        ),
        className: 'fs-exclude'
      },
      {
        label: (
          <InstanceCostCell
            weight="bold"
            cost={totalBackupsCost}
            currency={currency}
          />
        ),
        className: 'fs-exclude'
      },
      {
        label: (
          <InstanceCostCell
            weight="bold"
            cost={totalCost}
            currency={currency}
          />
        ),
        className: 'fs-exclude'
      }
    ]
  };
  tableRows.push(totalRow);
  return (
    <Table
      size="md"
      headers={tableHeaders}
      rows={tableRows}
      data-testid={'usage-table'}
    />
  );
};

const LoadingInfo = (): ReactElement => {
  const cuiTheme = useCUITheme();
  return (
    <Panel
      hasBorder
      padding="lg"
      alignItems="center"
      orientation="vertical"
      gap="lg"
      fillWidth
    >
      <Icon
        name="horizontal-loading"
        size="xxl"
        data-testid={'loading-spinner'}
        color={cuiTheme.global.color.text.default}
      />
    </Panel>
  );
};

const InstanceNameCell = ({
  color,
  name,
  cloudProvider
}: {
  color: string | undefined;
  name: string;
  cloudProvider: InstanceCloudProvider;
}): ReactElement => (
  <Container gap="xs" className="fs-exclude">
    <Icon name="dot" size="md" data-testid="color-dot" color={color} />
    {name}
  </Container>
);

const CloudProviderCell = ({
  cloudProvider
}: {
  cloudProvider: InstanceCloudProvider;
}): ReactElement => (
  <Container gap="xs" className="fx-exclude">
    <Tooltip>
      <Tooltip.Trigger>
        <EllipsisContent className="fs-exclude">
          <Icon name={cloudProvider} width="1.5rem" height="1.5rem" />
        </EllipsisContent>
      </Tooltip.Trigger>
      <Tooltip.Content side="bottom" className="fx-exclude">
        {cloudProvider.toUpperCase()}
      </Tooltip.Content>
    </Tooltip>
  </Container>
);

const InstanceCostCell = ({
  weight,
  cost,
  currency
}: {
  weight: 'normal' | 'bold';
  cost: number | undefined;
  currency: string;
}): ReactElement => {
  return cost !== undefined ? (
    <Text weight={weight} className="fs-exclude">
      {formatCurrency(cost, currency)}
    </Text>
  ) : (
    <Icon
      name="horizontal-loading"
      height="1.5rem"
      width="1.5rem"
      data-testid={'horizontal-loading'}
    />
  );
};

interface InstanceCost {
  computeCost: number;
  storageCost: number;
  backupsCost: number;
  totalCost: number;
}

/**
 * Retrieves the cost of the instanceReport.instance.
 * It checks for the cost in the instance report. If not present, tries to calculate it form Strapi pricing data.
 */
const getInstanceCost = (
  instanceReport: InstanceUsageReport,
  pricingTable: Map<string, StrapiPriceObject>,
  isValidPricingTable: boolean,
  currency: Currency
): InstanceCost | null => {
  const computeReport = instanceReport['COMPUTE_UNIT_MINUTES'];
  let computeCost = computeReport.cost;
  const storageReport = instanceReport['STORAGE_GB_MINUTES_TABLES'];
  let storageCost = storageReport.cost;
  const backupsReport = instanceReport['STORAGE_GB_MINUTES_PAID_BACKUPS'];
  let backupsCost = backupsReport.cost;
  if (
    computeCost !== undefined &&
    storageCost !== undefined &&
    backupsCost !== undefined
  ) {
    return {
      computeCost,
      storageCost,
      backupsCost,
      totalCost: computeCost + storageCost + backupsCost
    };
  } else if (
    ['Development', 'Production'].includes(instanceReport.instanceTier) &&
    isValidPricingTable &&
    pricingTable?.get(instanceReport.instanceId) !== undefined
  ) {
    const pricing = truthy(
      pricingTable.get(instanceReport.instanceId),
      'Invalid pricing table'
    );
    computeCost = computeReport.metricValue * pricing.computeUnitPrice * 8;
    storageCost = storageReport.metricValue * pricing.storageUnitPrice;
    backupsCost = backupsReport.metricValue * pricing.storageUnitPrice;
    return {
      computeCost,
      storageCost,
      backupsCost,
      totalCost: computeCost + storageCost + backupsCost
    };
  } else {
    return null;
  }
};

export default UsageTable;
