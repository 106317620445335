import React, { ReactElement } from 'react';

import { css } from '@emotion/react';
import { produce } from 'immer';

import { AdvancedTableParams } from 'src/components/CreateTableView/AdvancedTableParams';
import { Columns } from 'src/components/CreateTableView/Columns';
import {
  EditTableProvider,
  blankValue,
  CreateTableState
} from 'src/components/CreateTableView/context';
import { useImportModel } from 'src/lib/dataLoading';
import {
  NewFileUrlImport,
  NewFileUploadImport,
  FileBasedImport
} from 'shared/src/dataLoading/types';

import { isClickPipesImport } from 'shared/src/dataLoading/utils';
import { TableState } from 'shared/src/tableSchema';
import { Accordion } from '@clickhouse/click-ui';
import { TableParams } from 'src/components/ImportView/ImportForm/TableSettingsForm/TableParams';

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'inherit'
});

function TableSettingsForm(): ReactElement | null {
  let defaultState: TableState;

  const { importModel, setImportModel } = useImportModel();
  const hasTableStateForm =
    isClickPipesImport(importModel) ||
    ['file_upload', 'file_url'].includes(importModel.type || '');

  const changeState = (newState: CreateTableState): void => {
    if (hasTableStateForm && importModel.status === 'new') {
      const newImportModel = produce(
        importModel as NewFileUploadImport | NewFileUrlImport,
        (draft) => {
          draft.data.tableConfig = {
            tableType: 'newTable',
            config: newState.tableState
          };
        }
      );

      setImportModel(newImportModel);
    }
  };

  if (!hasTableStateForm) {
    return null;
  }

  const state = importModel as FileBasedImport;

  if (
    state.data.tableConfig &&
    state.data.tableConfig.tableType === 'newTable'
  ) {
    defaultState = state.data.tableConfig.config;
  } else {
    const { tableState } = blankValue;
    defaultState = tableState;
  }

  const createTableState = {
    previewOpen: blankValue.previewOpen,
    queryError: blankValue.queryError,
    tableState: defaultState
  };

  return (
    <EditTableProvider state={createTableState} onStateChange={changeState}>
      <div css={containerStyle}>
        <TableParams />
        <Accordion title="Column settings" defaultValue="item">
          <Columns />
        </Accordion>
        <AdvancedTableParams />
      </div>
    </EditTableProvider>
  );
}

export default React.memo(TableSettingsForm);
