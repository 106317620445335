import {
  ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE,
  IpAccessListEntry,
  IpAccessOption as IpAccessOptionAPI,
  getAllowAnywhereIpAccessListEntry
} from '@cp/common/protocol/Instance';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useInstanceController,
  useInstanceOrThrow
} from 'src/instance/instanceController';
import { keepUniqueElements } from '@cp/common/utils/MiscUtils';
import { createToast } from 'src/components/primitives';

export type IpAccessOption = IpAccessOptionAPI | 'nowhere';
interface ReturnProps {
  networkType: IpAccessOption;
  onSubmit: (
    ipType?: IpAccessOption,
    ipList?: Array<IpAccessListEntry>
  ) => void;
  onChange: (ipType: IpAccessOption, ipList: Array<IpAccessListEntry>) => void;
  ipList: Array<IpAccessListEntry>;
}

const useNetworkSettings = (instanceId: string | null): ReturnProps => {
  const { updateIpAccessList } = useInstanceController();
  const instance = useInstanceOrThrow(instanceId);
  const [currentEntries, setCurrentEntries] = useState<
    Array<IpAccessListEntry>
  >([]);

  const [networkType, setNetworkType] = useState<IpAccessOption>(() => {
    if (instance.ipAccessList.length === 0) {
      return 'nowhere';
    }
    return instance.ipAccessList.some(
      ({ source }) => source === ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE
    )
      ? 'anywhere'
      : 'specificLocations';
  });

  const entriesWithoutAnywhere = useMemo(() => {
    return currentEntries.filter(
      ({ source }) => source !== ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE
    );
  }, [currentEntries]);

  useEffect(() => {
    if (!instance?.ipAccessList) {
      return;
    }

    setCurrentEntries(instance.ipAccessList);
    const networkType = instance.ipAccessList.some(
      ({ source }) => source === ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE
    )
      ? 'anywhere'
      : instance.ipAccessList.length === 0
      ? 'nowhere'
      : 'specificLocations';
    setNetworkType(networkType);
  }, [instance?.ipAccessList]);

  const onChange = useCallback(
    (ipType: IpAccessOption, ipList: Array<IpAccessListEntry>): void => {
      setCurrentEntries(ipList);
      setNetworkType(ipType);
    },
    []
  );
  const onSubmit = useCallback(
    (
      incommingIpType?: IpAccessOption,
      incommingIpList?: Array<IpAccessListEntry>
    ): void => {
      if (!instance) {
        createToast('Error', 'error', 'Unable to update ip list');
        return;
      }
      const ipType = incommingIpType ?? networkType;
      const ipList = incommingIpList ?? currentEntries;

      setNetworkType(ipType);
      if (ipType === 'nowhere') {
        void updateIpAccessList({
          instanceId: instance.id,
          organizationId: instance.organizationId,
          ipAccessList: []
        });
        return;
      }

      if (ipType === 'anywhere') {
        void updateIpAccessList({
          instanceId: instance.id,
          organizationId: instance.organizationId,
          ipAccessList: keepUniqueElements(
            [...instance.ipAccessList, getAllowAnywhereIpAccessListEntry()],
            (e1, e2) => e1.source === e2.source
          )
        });
        return;
      }
      const entriesWithoutAnywhere = ipList.filter(
        ({ source }) =>
          source !== ALLOW_ANYWHERE_IP_ACCESS_LIST_SOURCE && source.length > 0
      );
      void updateIpAccessList({
        instanceId: instance.id,
        organizationId: instance.organizationId,
        ipAccessList: entriesWithoutAnywhere
      });
    },
    [currentEntries, instance, networkType, updateIpAccessList]
  );

  return {
    ipList: entriesWithoutAnywhere,
    networkType,
    onSubmit,
    onChange
  };
};

export default useNetworkSettings;
