import { ALLOW_DENY, AuthorizationDecision, Permission, Policy } from '@cp/common/protocol/Authorization';

/**
 * return ALLOW if exists and that DENY doesn't exist, defaults to DENY
 */
export const checkRolesHasPermission = (
  applicablePolicies: Policy[],
  permission: Permission
): AuthorizationDecision => {
  const decision = {
    requiredPermission: permission,
    allPolicies: applicablePolicies
  };

  const firstDeniedPolicy = applicablePolicies.find((policy) => policy.allowDeny === ALLOW_DENY.DENY);
  if (firstDeniedPolicy) {
    return {
      ...decision,
      allowDeny: ALLOW_DENY.DENY,
      reason: 'DENY_POLICY_FOUND',
      usedPolicy: firstDeniedPolicy
    };
  }

  const firstAllowedPolicy = applicablePolicies.find((policy) => policy.allowDeny === ALLOW_DENY.ALLOW);
  if (firstAllowedPolicy) {
    return {
      ...decision,
      allowDeny: ALLOW_DENY.ALLOW,
      reason: 'ALLOW_POLICY_FOUND_WITHOUT_DENY_POLICY',
      usedPolicy: firstAllowedPolicy
    };
  }

  return {
    ...decision,
    allowDeny: ALLOW_DENY.DENY,
    reason: 'NO_POLICY_FOUND'
  };
};

export const getApplicablePolicies = (policies: Policy[], permission: Permission): Policy[] =>
  policies.filter((policy) => policy.permissionId === permission);
