import { HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { GridContainer } from '@clickhouse/click-ui';

export default function TwoColsContainer({
  children,
  ...otherProps
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): ReactElement {
  return (
    <GridContainer
      gridTemplateColumns="1fr 1fr"
      fillWidth
      gap="lg"
      alignItems="start"
      {...otherProps}
    >
      {children}
    </GridContainer>
  );
}
