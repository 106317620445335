import React, { useCallback, useEffect } from 'react';
import { DataImport } from '@prisma/client';
import { FileUploadImport, FileUrlImport, SampleDataImport } from 'shared/src/dataLoading';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { DataImportModel, DataImportWithChunksModel } from 'src/dataloading/models';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { routes } from 'src/lib/routes';
import { useSocketIo } from 'src/lib/socketio';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import useSWR, { useSWRConfig } from 'swr';
import { ErrorResponse } from 'types/protocol/apiResponse';
import { createToast } from 'src/components/primitives';

type DataImportsKey = { url: string; serviceId: string };
function dataImportsKey(serviceId: string): DataImportsKey {
  return { url: '/api/getDataImports', serviceId: serviceId };
}

type DataImportKey = { url: string; serviceId: string; dataImportId: string };
function dataImportKey(serviceId: string, dataImportId: string): DataImportKey {
  return { url: '/api/getDataImport', serviceId, dataImportId };
}

export type DataImportsResponse = {
  isLoading: boolean;
  dataImports: Array<DataImportModel>;
  error?: string;
};

export function useDataImports(serviceId: string): DataImportsResponse {
  const api = useApiClient();
  const { mutate } = useSWRConfig();
  const { client: socketIo } = useSocketIo();

  const fetcher = ({ serviceId }: DataImportsKey): Promise<Array<DataImportModel>> => {
    return api.dataloading.listImports({ serviceId });
  };

  useEffect(() => {
    const listener = async (): Promise<void> => {
      try {
        await mutate(dataImportsKey(serviceId));
      } catch (error) {
        console.error(error);
      }
    };

    if (socketIo) {
      socketIo.on('dataImportChanged', listener);
    }

    return () => {
      socketIo?.removeListener('dataImportChanged', listener);
    };
  }, [socketIo, mutate, serviceId]);

  const { isLoading, data, error } = useSWR(dataImportsKey(serviceId), fetcher);

  return {
    isLoading,
    dataImports: data ?? [],
    error: error ? (error as unknown as ErrorResponse).error : undefined
  };
}

export type DataImportResponse = {
  isLoading: boolean;
  dataImport: DataImportWithChunksModel | undefined;
  error?: string;
};

export function useDataImport(serviceId: string, dataImportId: string): DataImportResponse {
  const api = useApiClient();
  const { mutate } = useSWRConfig();
  const { client: socketIo } = useSocketIo();

  const fetcher = ({ serviceId, dataImportId }: DataImportKey): Promise<DataImportWithChunksModel> => {
    return api.dataloading.getImport({ serviceId, importId: dataImportId });
  };

  useEffect(() => {
    const listener = async (): Promise<void> => {
      try {
        await mutate(dataImportKey(serviceId, dataImportId));
      } catch (error) {
        console.error(error);
      }
    };

    if (socketIo) {
      socketIo.on('dataImportChanged', listener);
    }

    return () => {
      socketIo?.removeListener('dataImportChanged', listener);
    };
  }, [socketIo, mutate, serviceId, dataImportId]);

  const { isLoading, data, error } = useSWR<DataImportWithChunksModel, ErrorResponse, DataImportKey>(
    dataImportKey(serviceId, dataImportId),
    fetcher
  );

  return {
    isLoading,
    dataImport: data,
    error: error ? (error as unknown as ErrorResponse).error : undefined
  };
}

const extractModelFromDataImport = (dataImport: DataImport): FileUrlImport | SampleDataImport | FileUploadImport => {
  return dataImport.data as unknown as FileUrlImport | SampleDataImport | FileUploadImport;
};

const openTableView = (dataImport: DataImport): void => {
  if (!['importing', 'success'].includes(dataImport.status)) {
    return;
  }

  if (!dataImport.data) {
    return;
  }

  const model = extractModelFromDataImport(dataImport);
  const serviceId = getCurrentServiceIdOrFail();
  const databaseName = model.data.tableConfig?.config.schema;
  const tableName = model.data.tableConfig?.config.name;

  if (!(databaseName && tableName)) {
    throw new Error('Database and tableName must be specified');
  }

  navigateTo(
    routes.tables({
      serviceId,
      databaseName: encodeURIComponent(databaseName),
      tableName: encodeURIComponent(tableName)
    })
  );
};

interface DataLoadingController {
  isRetrying: boolean;
  retryError: string | undefined;
  retryImport: (id: string) => Promise<void>;
  openTableView: (dataImport: DataImport) => void;
}

export function useDataLoadingController(): DataLoadingController {
  const api = useApiClient();
  const [isRetrying, setIsRetrying] = React.useState(false);
  const [retryError, setRetryError] = React.useState<string | undefined>(undefined);

  const retryImport = useCallback(
    async (id: string): Promise<void> => {
      setIsRetrying(true);
      setRetryError(undefined);
      try {
        await api.dataloading.retryDataImportJob(id);
        createToast('Success', 'success', 'Import will be retried');
      } catch (error) {
        setRetryError(errorMessage(error));
        createToast('Error', 'alert', 'Error while trying to retry the import');
      } finally {
        setIsRetrying(false);
      }
    },
    [api.dataloading]
  );

  return {
    isRetrying,
    retryError,
    retryImport,
    openTableView
  };
}
