import { NodeType } from 'shared/src/types/queryFolders';

export const walkAllContentNodes = (
  data: Array<NodeType | null>,
  callback: (item: NodeType, path: string) => void,
  path?: string
) => {
  for (let i = 0; i < data.length; i++) {
    const currentNode = data[i];
    if (currentNode) {
      const newPath = path ? `${path}-${i}` : `${i}`;
      if (currentNode.type === 'content') {
        callback(currentNode, newPath);
      }

      if (currentNode.children) {
        walkAllContentNodes(currentNode.children, callback, newPath);
      }
    }
  }
};

export const walkAllNodes = (
  data: Array<NodeType>,
  callback: (item: NodeType, path?: string) => void,
  path?: string
) => {
  for (let i = 0; i < data.length; i++) {
    const newPath = path ? `${path}-${i}` : `${i}`;
    const currentNode = data[i];
    if (currentNode) {
      callback(currentNode, newPath);
      if (currentNode.children) {
        walkAllNodes(currentNode.children, callback, newPath);
      }
    }
  }
};

export const getIds = (data: Array<NodeType>): string[] => {
  const arr = [];
  for (let i = 0; i < data.length; i++) {
    const currentNode = data[i];
    if (currentNode.type === 'content') {
      arr.push(data[i].id);
    } else if (currentNode.children) {
      arr.push(...getIds(currentNode.children));
    }
  }

  return arr;
};

export const removeNull = (data: Array<NodeType | null>): Array<NodeType> => {
  return JSON.parse(JSON.stringify(data), (k, v) => (Array.isArray(v) ? v.filter((e) => e !== null) : v));
};

export const getSearchPaths = (data: Array<NodeType | null>, search: string, path?: string): string[] => {
  const paths = [];
  for (let i = 0; i < data.length; i++) {
    const currentNode = data[i];
    if (currentNode && !currentNode.hidden) {
      const newPath = path ? `${path}-${i}` : `${i}`;
      const title = currentNode.title;
      const index = title.toLowerCase().indexOf(search.toLowerCase());
      if (index > -1) {
        paths.push(newPath);
      }

      if (currentNode.children) {
        paths.push(...getSearchPaths(currentNode.children, search, newPath));
      }
    }
  }

  return paths;
};

export interface LinearizedNode {
  type: 'content' | 'folder';
  isFirst: boolean;
  isLast: boolean;
  path: string;
  level: number;
  parentId: string;
  node: NodeType;
  expanded?: boolean;
  index?: number;
  hasChildren?: boolean;
  selected: boolean;
}

const defaultLocaleString = 'ZZZZZZZZZZZZZ';

interface UseLinearizeNodesArgs {
  currentTabId?: string | null;
  expandedPaths: string[];
  expandData: Record<string, boolean>;
  isDragging: boolean;
  search: string;
  selectedFolder: string | null;
}

// in order to render the tree using react-virtualized, we need to flatten the tree structure into an array
// of objects that tell us exactly how to render each line. these LinearizedNodes contain information like
// the node type, the level (how much to indent), etc.
// determining the sort order is also done in this function
export function useLinearizeNodes({
  currentTabId,
  expandedPaths,
  expandData,
  isDragging,
  search,
  selectedFolder
}: UseLinearizeNodesArgs) {
  const linearizeNodes = (
    nodes: NodeType[],
    level = 0,
    path?: string,
    parentId: string = '',
    forceRender?: boolean
  ): LinearizedNode[] => {
    return nodes
      .sort((a, b) => {
        return (
          (a.category || defaultLocaleString).localeCompare(b.category || defaultLocaleString) ||
          -(a.type || defaultLocaleString).localeCompare(b.type || defaultLocaleString) ||
          a.title.localeCompare(b.title)
        );
      })
      .flatMap((node: NodeType, index: number, arr: NodeType[]): LinearizedNode[] => {
        const filteredNodes = arr.filter((item) => item && !item.hidden);
        const newPath = path !== undefined ? `${path}-${index}` : `${index}`;
        const searchMatches =
          search.length === 0 ||
          (search.trim().length > 0 &&
            typeof node.title === 'string' &&
            node.title.toLowerCase().indexOf(search.toLowerCase()) > -1);

        if (node && node.type === 'content') {
          if (!forceRender && (!searchMatches || node.hidden)) {
            return [];
          }
          return [
            {
              type: 'content',
              isFirst: filteredNodes.indexOf(node) === 0,
              isLast: filteredNodes.indexOf(node) === filteredNodes.length - 1,
              level,
              node,
              parentId,
              path: newPath,
              selected: !isDragging && node?.id === currentTabId
            }
          ];
        } else if (node) {
          const render = true;

          let containsSearchMatch = searchMatches;
          if (!searchMatches && !!search && node.children) {
            walkAllNodes(node.children, (node) => {
              containsSearchMatch =
                containsSearchMatch ||
                (search.trim().length > 0 && node.title.toLowerCase().indexOf(search.toLowerCase()) > -1);
            });
          }

          if (forceRender) {
            containsSearchMatch = true;
          }

          if (containsSearchMatch && render) {
            const expanded = expandedPaths.length > 0 ? expandedPaths.includes(newPath) : expandData[node.id];
            const children = node.children
              ? linearizeNodes(node.children, level + 1, newPath, node.id, forceRender || searchMatches)
              : [];
            const selected = selectedFolder === node.id || children.some(({ selected }) => selected);
            return [
              {
                type: 'folder',
                node,
                expanded,
                index,
                isFirst: filteredNodes.indexOf(node) === 0,
                isLast: filteredNodes.indexOf(node) === filteredNodes.length - 1,
                parentId,
                path: newPath,
                level,
                hasChildren: expanded && children && children.length > 0,
                selected
              },
              ...(expanded ? children : [])
            ];
          }
        }

        return [];
      });
  };

  return linearizeNodes;
}
