import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { useUserFeature } from 'src/lib/features';

type OnboardingStep = 'CREATE_INSTANCE' | 'SECURITY' | 'SERVICE_DETAILS' | 'FINISHED';

export interface OnboardingState {
  isOnboardingInProgress: boolean;
  onboardingStep: OnboardingStep;
}
export const onboardStateAtom = atom<OnboardingState>({
  isOnboardingInProgress: false,
  onboardingStep: 'CREATE_INSTANCE'
});

type OnboardingInProgressInput = {
  isInstanceStateInitialized: boolean;
  canOrgCreateService: boolean;
  hasCurrentOrganization: boolean;
  hasInstances: boolean;
  hasOnboardingPendingAction: boolean;
  isDeveloper: boolean;
  isInternalUser: boolean;
};

interface OnboardStateManager {
  isOnboardingInProgress: boolean;
  onboardingStep: OnboardingStep;
  setOnboardingStepNext: () => void;
  setIsOnboardingInProgress: (i: OnboardingInProgressInput) => void;
  skipOnboarding: () => void;
}

const useOnboardStateManager = (): OnboardStateManager => {
  const [onboardState, setOnboardStateAtom] = useAtom(onboardStateAtom);
  const showNewOnboarding = useUserFeature('FT_USER_SHOW_NEW_ONBOARDING');

  const setIsOnboardingInProgress = useCallback(
    (input: OnboardingInProgressInput): void => {
      setOnboardStateAtom((state) => setIsOnboardingInProgressInState(state, input));
    },
    [setOnboardStateAtom]
  );

  const setOnboardingStepNext = useCallback((): void => {
    setOnboardStateAtom((state) => setStepNextInState({ state, isNewOnboarding: showNewOnboarding }));
  }, [setOnboardStateAtom, showNewOnboarding]);

  const skipOnboarding = useCallback((): void => {
    setOnboardStateAtom((state) => setLastStepInState(state));
  }, [setOnboardStateAtom]);

  return {
    isOnboardingInProgress: onboardState.isOnboardingInProgress,
    onboardingStep: onboardState.onboardingStep,
    setOnboardingStepNext,
    setIsOnboardingInProgress,
    skipOnboarding
  };
};

export const setIsOnboardingInProgressInState = (
  state: OnboardingState,
  {
    hasCurrentOrganization,
    canOrgCreateService,
    isInstanceStateInitialized,
    hasInstances,
    hasOnboardingPendingAction,
    isDeveloper,
    isInternalUser
  }: OnboardingInProgressInput
): OnboardingState =>
  produce(state, (draft) => {
    if (!hasCurrentOrganization) {
      draft.isOnboardingInProgress = false;
      return;
    }

    if (!canOrgCreateService && !draft.isOnboardingInProgress) {
      draft.isOnboardingInProgress = false;
      return;
    }

    const shouldOnboard =
      isInstanceStateInitialized && hasOnboardingPendingAction && (isInternalUser || (!hasInstances && !isDeveloper));

    draft.isOnboardingInProgress = draft.isOnboardingInProgress || shouldOnboard;
  });

export const setStepNextInState = ({
  state,
  isNewOnboarding
}: {
  state: OnboardingState;
  isNewOnboarding: boolean;
}): OnboardingState =>
  produce(state, (draft) => {
    switch (draft.onboardingStep) {
      case 'CREATE_INSTANCE':
        draft.onboardingStep = isNewOnboarding ? 'SERVICE_DETAILS' : 'SECURITY';
        break;
      case 'SECURITY':
        draft.onboardingStep = 'SERVICE_DETAILS';
        break;
      case 'SERVICE_DETAILS':
        draft.onboardingStep = 'FINISHED';
        draft.isOnboardingInProgress = false;
    }
  });

/**
 * Sets the last (finished) step in the onboarding state.
 * @param {OnboardingState} state - The current onboarding state.
 * @returns {OnboardingState} The new onboarding state.
 */
export const setLastStepInState = (state: OnboardingState): OnboardingState =>
  produce(state, (draft) => {
    draft.onboardingStep = 'FINISHED';
    draft.isOnboardingInProgress = false;
  });

export { useOnboardStateManager };
