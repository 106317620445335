import { ReactElement, useState } from 'react';
import {
  Panel,
  Title,
  Text,
  ButtonGroup,
  Container
} from '@clickhouse/click-ui';
import BusinessTab from 'src/pages/MarketplaceOnboarding/BusinessTab';
import IndividualTab from 'src/pages/MarketplaceOnboarding/IndividualTab';

export type PageType = 'business' | 'individual';

type MarketplaceOnboardingPageProps = {
  onCompleteSignUp: (pageType: PageType) => Promise<void>;
  isLoading: boolean;
};

function MarketplaceOnboardingPage({
  onCompleteSignUp,
  isLoading
}: MarketplaceOnboardingPageProps): ReactElement {
  const [pageType, setPageType] = useState<PageType>('business');
  const [accountTypeSelectorVisible, setAccountTypeSelectorVisible] =
    useState(true);

  return (
    <Container justifyContent="center" padding="lg">
      <Panel
        orientation="vertical"
        color="default"
        hasBorder
        hasShadow
        height=""
        padding="md"
        radii="sm"
        width="40%"
      >
        <Title type="h1" size="lg">
          Before you start
        </Title>
        <Text color="default">
          We just need to ask you a few questions to get started. Select an
          account type.
        </Text>
        {accountTypeSelectorVisible && (
          <>
            <ButtonGroup
              onClick={(value: string): void => {
                setPageType(value as PageType);
              }}
              options={[
                {
                  label: 'Business',
                  value: 'business'
                },
                {
                  label: 'Individual',
                  value: 'individual'
                }
              ]}
              selected={pageType}
              type="default"
            />
          </>
        )}
        {pageType === 'business' ? (
          <BusinessTab
            onShowAccountTypeSelector={setAccountTypeSelectorVisible}
            onCompleteSignUp={() => {
              onCompleteSignUp('business').catch(console.error);
            }}
            isLoading={isLoading}
          />
        ) : (
          <IndividualTab
            onCompleteSignUp={() => {
              onCompleteSignUp('individual').catch(console.error);
            }}
            isLoading={isLoading}
          />
        )}
      </Panel>
    </Container>
  );
}

export default MarketplaceOnboardingPage;
