import { FileType } from 'shared/src/dataLoading';
import { JSONParser } from '@streamparser/json-whatwg';
export function detectFileTypeFromFileName(fileName: string): 'csv' | 'tsv' | 'json' | undefined {
  const extension = fileName.toLowerCase().split('.').pop();

  if (extension === 'csv') {
    return 'csv';
  } else if (extension === 'tsv') {
    return 'tsv';
  } else if (extension === 'json') {
    return 'json';
  } else {
    return undefined;
  }
}

const fileMimeTypeMapping: Record<string, FileType> = {
  csv: 'text/csv',
  json: 'application/json',
  tsv: 'text/tab-separated-values'
};

export function detectFileMimeTypeFromFilename(fileName: string): FileType | 'unknown' {
  const fileExtension = detectFileTypeFromFileName(fileName);
  if (!fileExtension) {
    return 'unknown';
  }

  return fileMimeTypeMapping[fileExtension] ?? 'unknown';
}

export async function detectFileType(fileOrUrl: File | string): Promise<'csv' | 'tsv' | 'json' | undefined> {
  if (typeof fileOrUrl === 'string') {
    const urlParts = fileOrUrl.toLowerCase().split('.');
    const extension = urlParts[urlParts.length - 1];

    if (extension === 'csv') {
      return 'csv';
    } else if (extension === 'tsv') {
      return 'tsv';
    } else if (extension === 'json') {
      return 'json';
    } else {
      const response = await fetch(fileOrUrl, { method: 'HEAD' });

      if (response.ok) {
        const contentType = response.headers.get('content-type');

        if (contentType?.startsWith('text/csv')) {
          return 'csv';
        } else if (contentType?.startsWith('text/tab-separated-values')) {
          return 'tsv';
        } else if (contentType?.startsWith('application/json')) {
          return 'json';
        }
      }
    }
  } else {
    return detectFileTypeFromFileName(fileOrUrl.name);
  }

  return undefined;
}

function isJSONEachRow(line: string): boolean {
  try {
    JSON.parse(line);
    return true;
  } catch (error) {
    return false;
  }
}

function isJSONArray(line: string): boolean {
  return line.trim().startsWith('[');
}

function detectJsonType(line: string) {
  if (isJSONEachRow(line.trim())) {
    return 'JSONEachRow';
  } else if (isJSONArray(line.trim())) {
    return 'JSONArray';
  } else {
    return 'JSONPrettyObject';
  }
}

type NonJsonEachRow = 'JSONArray' | 'JSONPrettyObject';
type JSONTypes = 'JSONEachRow' | NonJsonEachRow;

async function inferJsonType(stream: ReadableStream<Uint8Array>): Promise<JSONTypes> {
  const reader = stream.getReader();
  async function readFirstLine(): Promise<string> {
    const { done, value } = await reader.read();

    const chunk: string = new TextDecoder('utf-8').decode(value);
    const lines: string[] = chunk.split(/\r?\n/).filter((line) => line.trim() !== '');

    if (lines.length > 0) {
      return lines[0];
    } else if (!done) {
      return readFirstLine();
    } else {
      return '';
    }
  }
  const firstLine = await readFirstLine();
  return detectJsonType(firstLine);
}

function readLinesByChunks(stream: ReadableStream<Uint8Array>, n: number): Promise<string> {
  let lineCount = 0;
  let result = '';
  const reader = stream.getReader();
  return new Promise<string>((resolve, reject) => {
    function processChunk(chunk: string) {
      const lines = chunk.split(/\r?\n/);

      lines.forEach((line) => {
        if (line.trim() !== '') {
          result += line.replace(/\r\n/g, '\n') + '\n';
          lineCount++;

          if (lineCount >= n) {
            reader.cancel().catch(console.error);
            resolve(result);
            return;
          }
        }
      });

      readChunk();
    }

    function readChunk() {
      reader
        .read()
        .then(({ done, value }) => {
          if (done) {
            resolve(result);
            return;
          }

          const chunk = new TextDecoder('utf-8').decode(value);
          processChunk(chunk);
        })
        .catch((error) => {
          reject(error);
        });
    }

    readChunk();
  });
}

async function streamJson(stream: ReadableStream<Uint8Array>, n: number, jsonType: NonJsonEachRow): Promise<string> {
  let lineCount = 0;
  let result = '';
  const paths = jsonType === 'JSONArray' ? ['$.*'] : ['$'];
  const parser = new JSONParser({ stringBufferSize: undefined, paths });
  const reader = stream.pipeThrough(parser).getReader();
  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const readData = await reader.read();

      if (!readData) {
        break;
      }
      const { done, value: parsedElementInfo } = readData;

      if (!parsedElementInfo) {
        break;
      }

      const { value, stack } = parsedElementInfo;
      if (stack.length > 1 || (stack.length === 1 && stack[0]?.value)) {
        continue;
      }

      result += JSON.stringify(value) + '\n';

      lineCount++;

      if (lineCount >= n) {
        break;
      }

      if (done) break;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

  return Promise.resolve(result);
}
async function readFirstNLines(
  stream: ReadableStream<Uint8Array>,
  n: number,
  fileType: FileType | 'unknown'
): Promise<string> {
  if (fileType === 'application/json') {
    const [stream1, stream2] = stream.tee();
    const jsonType: 'JSONEachRow' | 'JSONArray' | 'JSONPrettyObject' = await inferJsonType(stream1);
    return jsonType === 'JSONEachRow' ? readLinesByChunks(stream2, n) : streamJson(stream2, n, jsonType);
  } else {
    return readLinesByChunks(stream, n);
  }
}

export interface FileReaderOptions {
  file: File;
  numLines: number;
  decompress?: boolean;
  fileType: FileType | 'unknown';
}
export async function readFirstNLinesFromFile({ file, numLines, fileType }: FileReaderOptions): Promise<string> {
  const fileStream: ReadableStream<Uint8Array> = file.stream();
  return readFirstNLines(fileStream, numLines, fileType);
}
