import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigationEventEmitter } from 'src/components/NavigationProvider/navigationEmitter';
import { useSetLastVisitedSvcUrl } from 'src/components/NavigationProvider/NavigationState';
export const NavigationProvider = ({
  children
}: {
  children: ReactNode;
}): ReactElement => {
  const navigate = useNavigate();
  const setLastVisitedSvcUrl = useSetLastVisitedSvcUrl();
  const { pathname } = useLocation();
  const navigateRef = useRef(navigate);

  // we don't want to unmount/mount the emitter on every render.
  // To avoid that we simply use a ref and only subscribe to the emitter once.
  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    if (pathname.startsWith('/services/') || pathname === '/') {
      setLastVisitedSvcUrl(pathname);
    }
  }, [pathname, setLastVisitedSvcUrl]);

  useEffect(() => {
    const navigateTo = (r: string | number): void => {
      if (typeof r === 'number') {
        navigateRef.current(r);
      } else {
        navigateRef.current(r, { replace: r === pathname });
      }
    };

    navigationEventEmitter.on(navigateTo);
    return () => {
      navigationEventEmitter.off(navigateTo);
    };
  }, []);

  return <>{children}</>;
};
