import { Spacer, Title } from '@clickhouse/click-ui';
import {
  PASSWORD_MASK,
  getPasswordSubstitution
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings'; // replace with your actual import
import { ReactElement } from 'react';
import LanguageClientExtras from 'src/components/ServiceConnect/Tabs/LanguageClientExtras';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';
import { FullWidthCodeBlock } from 'src/components/ServiceConnect/Tabs/FullWidthCodeBlock';

const getPythonClientCode = (
  hostname: string,
  username: string,
  password: string
): string => {
  return `import clickhouse_connect

if __name__ == '__main__':
    client = clickhouse_connect.get_client(
        host='${hostname}',
        user='${username}',
        password='${getPasswordSubstitution(password)}',
        secure=True
    )
    print("Result:", client.query("SELECT 1").result_set[0][0])`;
};

interface PythonConnectionTabProps {
  hostname: string;
  username: string;
  password: string;
  showPassword?: boolean;
}

const PythonConnectionTab = ({
  hostname,
  username,
  password,
  showPassword
}: PythonConnectionTabProps): ReactElement => {
  const unmasked = getPythonClientCode(hostname, username, password);
  const masked = password
    ? getPythonClientCode(hostname, username, PASSWORD_MASK)
    : undefined;

  return (
    <>
      <Title type="h3">Installation</Title>
      <FullWidthCodeBlock
        language="bash"
        showLineNumbers
        wrapLines
        data-testid="service-connect-code-block-python-install"
      >
        pip install clickhouse-connect
      </FullWidthCodeBlock>
      <Title type="h3">Usage</Title>
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        language="python"
        data-testid="service-connect-code-block-python-usage"
        showPassword={showPassword}
      />
      <Spacer />
      <LanguageClientExtras
        title="Python Client"
        docsLink="https://clickhouse.com/docs/en/integrations/python"
        examplesLink="https://github.com/ClickHouse/clickhouse-connect/tree/main/examples"
      />
    </>
  );
};

export default PythonConnectionTab;
