import { isNumericType, isString } from 'shared/src/clickhouse/dataTypes';

import { OperatorOption } from 'src/components/TableView/DataView/Toolbar/Filter/types';

type Maybe<T> = T | undefined;

interface ColumnWithType {
  type: string;
}

export const defaultOperators = [
  {
    label: 'equals',
    value: '='
  },
  {
    label: 'does not equal',
    value: '!='
  },
  {
    label: 'contains',
    value: 'contains'
  }
];

export const nullOperators = [
  {
    label: 'is null',
    value: 'isNull'
  },
  {
    label: 'is not null',
    value: 'isNotNull'
  }
];

export const stringOperators = [
  {
    label: 'in',
    value: 'in'
  },
  {
    label: 'not in',
    value: 'notIn'
  },
  {
    label: 'starts with',
    value: 'startsWith'
  },
  {
    label: 'ends with',
    value: 'endsWith'
  }
];

export const numericOperators = [
  {
    label: 'in',
    value: 'in'
  },
  {
    label: 'not in',
    value: 'notIn'
  },
  {
    label: 'is greater than',
    value: '>'
  },
  {
    label: 'is less than',
    value: '<'
  },
  {
    label: 'is greater than or equal to',
    value: '>='
  },
  {
    label: 'is less than or equal to',
    value: '<='
  }
];

export function getOperatorOptions(column: Maybe<ColumnWithType>): OperatorOption[] {
  let options = defaultOperators;

  if (!column) {
    return options;
  }

  if (isString(column.type)) {
    options = options.concat(stringOperators);
  }

  if (isNumericType(column.type)) {
    options = options.concat(numericOperators);
  }

  return [...options, ...nullOperators];
}
