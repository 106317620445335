import {
  Alert,
  Button,
  Container,
  Dialog,
  Link,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { InstanceMaintenanceWindow } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import { formatTime } from 'src/lib/formatters/dateTimeFormatter';

interface StartSMTMigrationContentProps {
  instanceName: string;
  endMaintenanceTime: number;
}

export const StartSMTMigrationContent = ({
  instanceName,
  endMaintenanceTime
}: StartSMTMigrationContentProps): ReactElement => {
  return (
    <>
      <Text size="md">
        This service requires maintenance, would you like to perform it now?
        Starting this process will migrate <b>{instanceName}</b> from using the
        ReplicatedMergeTree engine to using the SharedMergeTree engine.{' '}
        <Link
          href="https://clickhouse.com/blog/clickhouse-cloud-boosts-performance-with-sharedmergetree-and-lightweight-updates"
          target="_blank"
          data-testid="start-maintenance-modal-more-link"
        >
          Learn more
        </Link>
      </Text>
      <Spacer size="md"></Spacer>
      <Text>
        Please perform this maintenance before{' '}
        <b>{formatTime(endMaintenanceTime)} (local time).</b>
      </Text>
      <Spacer size="md"></Spacer>
      <Alert
        showIcon={true}
        size="medium"
        state="info"
        text="This maintenance process will involve downtime. During maintenance, your service will be stopped and unavailable until the migration is completed. This maintenance process may take up to 1 hour depending on the size of your service."
      ></Alert>
    </>
  );
};

interface StopServiceModalProps {
  open: boolean;
  instanceName: string;
  onStop: () => void;
  onCancel: () => void;
  onStartMaintenance?: () => void;
  maintenanceWindow?: InstanceMaintenanceWindow;
}

export const StopServiceModal = ({
  open,
  onCancel,
  instanceName,
  onStop,
  maintenanceWindow,
  onStartMaintenance
}: StopServiceModalProps): ReactElement => {
  const message: ReactElement = maintenanceWindow ? (
    <StartSMTMigrationContent
      instanceName={instanceName}
      endMaintenanceTime={maintenanceWindow.endMaintenanceTime}
    ></StartSMTMigrationContent>
  ) : (
    <Text>Are you sure you would like to stop {instanceName}</Text>
  );
  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
        }
      }}
    >
      <Dialog.Content title="Stop service?" showClose onClose={onCancel}>
        {message}
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Cancel" onClick={onCancel} />
          <Button
            type={maintenanceWindow ? 'secondary' : 'danger'}
            onClick={onStop}
            data-testid="stop-service-modal-confirmation-button"
          >
            Stop service
          </Button>
          {maintenanceWindow && (
            <Button
              type="primary"
              onClick={onStartMaintenance}
              data-testid="stop-service-modal-maintenance-button"
            >
              Perform maintenance
            </Button>
          )}
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
