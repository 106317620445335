import type { languages, IPosition } from 'monaco-editor';

import { statementForCursor } from 'src/lib/sql/parse/statementRanges';

import { AutocompleteProviderOptions, ITextModel } from 'src/lib/autocomplete/types';
import { getSuggestions, initWorker, terminateWorker } from 'src/lib/autocomplete/workerProvider';
import environment from 'src/lib/config/environment';

const noAutocompleteProvider = () => {
  const items: languages.CompletionItem[] = [];
  return {
    triggerCharacters: [' ', '.', '('],
    provideCompletionItems() {
      return {
        suggestions: items
      };
    }
  };
};

export function getWorkerProvider(options: AutocompleteProviderOptions): languages.CompletionItemProvider {
  if (typeof Worker !== 'undefined' && environment.CLIENT_CI !== 'true') {
    try {
      initWorker();
      return {
        triggerCharacters: [' ', '.', '('],
        provideCompletionItems(
          model: ITextModel,
          position: IPosition
        ): languages.ProviderResult<languages.CompletionList> {
          const text = model.getValue();
          const textOffset = model.getOffsetAt(position);

          const statementInfo = statementForCursor(text, {}, textOffset);

          const statement = statementInfo ? statementInfo.text : '';
          const offset = statementInfo ? textOffset - statementInfo.start : 0;
          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn
          };
          return getSuggestions(statement, offset, range, options);
        }
      };
    } catch (error) {
      console.warn('Could not get autocomplete worker', error);
      return noAutocompleteProvider();
    }
  } else {
    return noAutocompleteProvider();
  }
}

export function terminateWorkerProvider() {
  if (typeof Worker !== 'undefined') {
    terminateWorker();
  }
}
