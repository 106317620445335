import { BYTES_PER_GB } from '@cp/common/protocol/Units';

import { hidden, relativeStyle, w100 } from '@utility-styles';
import { Galaxy } from 'galaxy';
import { createToast } from 'primitives';
import React, { HTMLAttributes, ReactNode } from 'react';
import { FileType, NewFileUploadImport } from 'shared/src/dataLoading';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';

import { useSqlQueryFunction } from 'src/lib/clickhouse/query';

import { useApiClient } from 'src/lib/controlPlane/client';
import { DataPreviewer } from 'src/lib/dataLoading/DataPreviewer';
import { ChDescriber } from 'src/lib/dataLoading/describe';
import { getFileFormatOptions } from 'src/lib/dataLoading/fileTypeOptions';
import {
  MAX_SCHEMA_INFERENCE_SIZE,
  parseData
} from 'src/lib/dataLoading/parseData';
import {
  detectFileMimeTypeFromFilename,
  readFirstNLinesFromFile
} from 'src/lib/dataLoading/parseData/fileUtils';
import { errorMessage } from 'src/lib/errors/errorMessage';

import { routes } from 'src/lib/routes';
import { useUploadToS3 } from 'src/lib/upload';
import { getFileNameWithoutExtension, underscoreName } from 'src/lib/uri-utils';
import { useTables } from 'src/metadata/metadataState';
import { useConnectionCredentials } from 'src/state/connection';
import { useCancelableFileUpload, useImportModel } from 'src/state/dataLoading';
import {
  getCurrentServiceIdOrFail,
  useOrgIdFromServiceIdOrFail
} from 'src/state/service';

export const MAX_FILE_SIZE_IN_GBS = 1;

const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_IN_GBS * BYTES_PER_GB;

type Props = { children: ReactNode } & HTMLAttributes<HTMLFormElement>;

function ImportFileInput({ children, ...otherProps }: Props): JSX.Element {
  const api = useApiClient();
  const uploadFileToS3 = useUploadToS3();
  const { selectedDatabase } = useConnectionCredentials();
  const { importModel, setImportModel, updateImportModel, setProgress } =
    useImportModel();
  const inputRef = React.useRef<HTMLInputElement>(null);
  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  const { setUploadXhr } = useCancelableFileUpload();

  const [runQuery] = useSqlQueryFunction();
  const describer = new ChDescriber(runQuery);
  const serviceId = getCurrentServiceIdOrFail();
  const organizationId = useOrgIdFromServiceIdOrFail(serviceId);
  const tables = useTables();
  const dataPreviewer = new DataPreviewer(
    api,
    serviceId,
    tables,
    selectedDatabase
  );

  const handleFile = async (file: File): Promise<void> => {
    const fileType = detectFileMimeTypeFromFilename(file.name);

    if (
      !['text/tab-separated-values', 'text/csv', 'application/json'].includes(
        fileType
      )
    ) {
      createToast(
        'File type not supported',
        'alert',
        'File type is not supported. Please submit a JSON, CSV or TSV file'
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE_BYTES) {
      createToast(
        'File is too big',
        'alert',
        `File must be smaller than ${MAX_FILE_SIZE_IN_GBS}GB`
      );
      return;
    }

    const options = getFileFormatOptions(fileType as FileType);

    const fileSampleRaw = await readFirstNLinesFromFile({
      file,
      numLines: MAX_SCHEMA_INFERENCE_SIZE,
      fileType
    });

    const formatType = options[0].value;

    const newImportModel: NewFileUploadImport = {
      id: importModel.id,
      name: file.name,
      status: 'new',
      type: 'file_upload',
      data: {
        fileSampleRaw,
        rowsPreview: [],
        file: file,
        fileName: file.name,
        fileType: fileType as FileType,
        formatType,
        sizeBytes: file.size,
        storageId: '',
        tempS3File: ''
      }
    };

    setProgress(0);
    setImportModel(newImportModel);

    const { columns, rows, separator } = await parseData(
      fileSampleRaw,
      formatType,
      fileType as FileType,
      describer
    );

    updateImportModel((draft: NewFileUploadImport) => {
      if (draft.data) {
        draft.data.separator = separator;
      }
    });

    if (!rows || rows.length === 0) {
      throw new Error('File is empty');
    }

    const tableName = underscoreName(getFileNameWithoutExtension(file.name));
    const tableType = 'newTable';
    const tableConfig = dataPreviewer.generateTableConfig(
      tableType,
      tableName,
      columns
    );

    updateImportModel((draft: NewFileUploadImport) => {
      draft.data.rowsPreview = rows;
      draft.data.tableConfig = tableConfig;
    });

    uploadFileToS3(file, organizationId, serviceId, setProgress, setUploadXhr)
      .then(([fileDownloadUrl, storageId]) => {
        updateImportModel((draft: NewFileUploadImport) => {
          draft.data.storageId = storageId;
          draft.data.tempS3File = fileDownloadUrl;
        });
      })
      .catch(console.error);

    navigateTo(
      routes.import({ serviceId: serviceId, importId: importModel.id })
    );
  };

  // handle drag events
  const handleDrag = function (
    e: React.DragEvent<HTMLFormElement | HTMLDivElement>
  ): void {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const onButtonClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleChange = async function (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    e.preventDefault();

    if (e.target?.files && e.target.files[0]) {
      try {
        await handleFile(e.target.files[0]);
      } catch (error) {
        createToast('error', 'alert', errorMessage(error));
      }
      e.target.value = '';
    }
  };

  const handleDrop = async function (
    e: React.DragEvent<HTMLFormElement | HTMLDivElement>
  ): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      try {
        await handleFile(e.dataTransfer.files[0]);
      } catch (error) {
        createToast('error', 'alert', errorMessage(error));
      }
    }
  };

  return (
    <form
      css={[relativeStyle, w100]}
      onDragEnter={handleDrag}
      onSubmit={(e): void => e.preventDefault()}
      {...otherProps}
    >
      <input
        css={hidden}
        type="file"
        id="input-file-upload"
        data-testid="input-file-upload"
        ref={inputRef}
        multiple={true}
        onChange={(e): void => {
          Galaxy.galaxy().track('dataLoading.importTypeContainer.fileUpload', {
            interaction: 'click'
          });
          handleChange(e).catch(console.error);
        }}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? 'drag-active' : ''}
        onClick={onButtonClick}
        css={w100}
      >
        {children}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(e): void => {
            Galaxy.galaxy().track(
              'dataLoading.importTypeContainer.fileUpload',
              { interaction: 'drop' }
            );
            handleDrop(e).catch(console.error);
          }}
        ></div>
      )}
    </form>
  );
}

export default React.memo(ImportFileInput);
