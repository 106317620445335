import { IdentityProvider } from '@cp/common/protocol/Account';
import React, { useContext } from 'react';
import { User } from 'src/lib/auth/types';

export type LoginOptions = {
  url?: string;
  connection?: string;
};
export interface AuthContextInterface {
  /* Determining your current authentication state */
  loading: boolean;
  isAuthenticated: boolean;
  /* The current user's data from the `getCurrentUser` function on the api side */
  currentUser: null | User;
  loginWithRedirect: (options: LoginOptions) => Promise<void>;
  signupWithRedirect: (url?: string) => Promise<void>;
  logOut: (url?: string) => Promise<void>;
  getAccessToken: () => Promise<string | null>;
  refreshToken?: () => Promise<string | null>;
  idp: IdentityProvider;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  loading: true,
  isAuthenticated: false,
  currentUser: null,
  loginWithRedirect: () => Promise.resolve(),
  signupWithRedirect: () => Promise.resolve(),
  logOut: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(null),
  idp: 'AUTH0'
});

export const useAuth = (): AuthContextInterface => {
  return useContext(AuthContext);
};
