import { Button } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import Helmet from 'src/components/Helmet/Helmet';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useIsRegionBlocked } from 'src/lib/blockedRegion';
import { useOrgFeature } from 'src/lib/features';
import { useCurrentUserWithRoleInCurrentOrg } from 'src/lib/user/useCurrentUserWithRoleInCurrentOrg';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { ServiceListPageContents } from 'src/pages/ServiceListPage/ServiceList';
import { DataWarehouseListPage } from 'src/pages/DataWarehouseListPage/DataWarehouseListPage';

const ServiceListPage = (): ReactElement | null => {
  const isHydra = useOrgFeature('FT_ORG_HYDRA_UI');
  const isHydraUI = useOrgFeature('FT_ORG_HYDRA');
  const currentOrganization = useCurrentOrganizationOrThrow();
  const currentOrganizationName = currentOrganization.name;
  const instances = useCurrentOrganizationInstances();
  const headerText = `All services - ${currentOrganizationName}`;
  const currentUser = useCurrentUserWithRoleInCurrentOrg();
  const isBlocked = useIsRegionBlocked();
  const { createNewServiceOrShowTrialLimitModal } =
    useServiceLimitReachedModal();

  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    currentUser.role === 'ADMIN'
  );

  if (!currentUser) {
    return null;
  }

  if (isHydra && isHydraUI) {
    return (
      <DataWarehouseListPage
        instances={instances}
        currentOrganizationName={currentOrganizationName}
        organizationId={currentOrganization.id}
      />
    );
  }
  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label={headerText} data-testid="title">
        {instances.length !== 0 && hasManageServicePermission && !isBlocked && (
          <Button
            label="Create new service"
            iconLeft="plus"
            onClick={createNewServiceOrShowTrialLimitModal}
            data-testid="create-service-button-top"
          />
        )}
      </MainPaneLayout.Topbar>

      <MainPaneLayout.Content>
        <Helmet>
          <title>ClickHouse Cloud</title>
        </Helmet>
        <MaxWidthContainer
          orientation="vertical"
          padding="lg"
          data-testid="service-list-page"
          maxWidth="1740px"
        >
          <ServiceListPageContents
            instances={instances}
            hasManageServicePermission={hasManageServicePermission}
          />
        </MaxWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default ServiceListPage;
