import {
  Container,
  Dialog,
  Text,
  Spacer,
  Separator,
  Button
} from '@clickhouse/click-ui';
import React, { ReactElement } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';

type Props = {
  open: boolean;
  serviceName: string;
};
function ServiceDeletedDialog({
  open,
  serviceName
}: Props): ReactElement | null {
  const goToServices = (): void => {
    navigateTo(routes.serviceList());
  };

  const textBody = `${serviceName} is being deleted. Please select a new service.`;

  return (
    <Dialog modal open={open}>
      <Dialog.Content title="Service is being deleted">
        <Text size="md">{textBody}</Text>
        <Spacer />

        <Separator size="xl" />

        <Container justifyContent="end">
          <Button type="primary" onClick={goToServices}>
            Select a new service
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

const MemoizedInstanceTerminatingDialog = React.memo(ServiceDeletedDialog);
MemoizedInstanceTerminatingDialog.displayName = 'ServiceDeletedDialog';
export default MemoizedInstanceTerminatingDialog;
