import { ReactElement } from 'react';
import {
  Button,
  Container,
  Separator,
  Spacer,
  Title
} from '@clickhouse/click-ui';
import { EntityInfoContainer } from 'src/components/BillingCardModal/EntityInfoContainer';

type BusinessTabCompanyDetailsParams = {
  onComplete: () => void;
  onBack: () => void;
  isLoading: boolean;
};

const BusinessTabCompanyDetails = ({
  onComplete,
  onBack,
  isLoading
}: BusinessTabCompanyDetailsParams): ReactElement => {
  return (
    <>
      <Title type="h2" size="sm">
        Business account
      </Title>
      <Spacer size="md" />
      <EntityInfoContainer />
      <Separator size="md" />
      <Container justifyContent="end" gap="md" fillWidth={true}>
        <Button onClick={onBack} iconLeft="arrow-left" type="secondary">
          Back
        </Button>
        <Button
          onClick={onComplete}
          iconRight="arrow-right"
          loading={isLoading}
          data-testid="company-details-update-button"
        >
          Complete sign up
        </Button>
      </Container>
    </>
  );
};

export default BusinessTabCompanyDetails;
