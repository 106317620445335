import {
  useEffect,
  createContext,
  useState,
  ReactNode,
  ReactElement,
  useContext
} from 'react';
import { useAuth } from 'src/components/auth';
import config from 'src/lib/config';
import { WebSocketsCpClient, getOrCreateWsClient } from 'src/lib/websockets';

interface WsClient {
  client: WebSocketsCpClient | null;
}
export const WsClientContext = createContext<WsClient>({
  client: null
});

interface Props {
  children: ReactNode;
}

export function useWsClient(): WsClient {
  return useContext(WsClientContext);
}

export const WsClientProvider = ({ children }: Props): ReactElement => {
  const { getAccessToken, currentUser } = useAuth();
  const [wsClient, setWsClient] = useState<WebSocketsCpClient | null>(null);

  useEffect(() => {
    let client: WebSocketsCpClient | undefined;

    const initWsClient = async (): Promise<void> => {
      console.log('Initializing the Service WS Client');
      try {
        client = await getOrCreateWsClient({
          getAccessToken,
          webSocketsEndpoint: config.websockets.endpoint
        });
      } catch (e) {
        console.log('Could not initialize the websockets client');
        console.error(e);
      }

      setWsClient(client ?? null);
    };

    initWsClient().catch(console.error);

    return () => {
      if (!client) {
        return;
      }

      client.disconnect();
    };
  }, [getAccessToken, currentUser?.id]);

  return (
    <WsClientContext.Provider value={{ client: wsClient }}>
      {children}
    </WsClientContext.Provider>
  );
};
