/** Conversion constants between bytes & GB. */
/** KB is 1000, KiB is 1024**/
/** Kilobytes */
export const BYTES_PER_KB = 1000;
/** Megabytes */
export const BYTES_PER_MB = BYTES_PER_KB * BYTES_PER_KB;
/** Gigabytes */
export const BYTES_PER_GB = BYTES_PER_MB * BYTES_PER_KB;
/** Terabytes */
export const BYTES_PER_TB = BYTES_PER_GB * BYTES_PER_KB;

/** How many GB in one TB. */
export const GB_PER_TB = BYTES_PER_TB / BYTES_PER_GB;

/** Conversion constants between bytes & GiB. */
/** KB is 1000, KiB is 1024**/
/** Kibi-bytes */
export const BYTES_PER_KiB = 1024;
/** Mibi-bytes */
export const BYTES_PER_MiB = BYTES_PER_KiB * BYTES_PER_KiB;
/** Gibi-bytes */
export const BYTES_PER_GiB = BYTES_PER_MiB * BYTES_PER_KiB;
/** Tibi-bytes */
export const BYTES_PER_TiB = BYTES_PER_GiB * BYTES_PER_KiB;
