import { Draft } from 'immer';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Icon,
  NumberField,
  Select,
  Spacer,
  Text,
  TextField,
  useCUITheme
} from '@clickhouse/click-ui';

import {
  CDCSettings,
  ClickPipePostgresImport,
  PostgresImport
} from 'shared/src/dataLoading';
import { useApiClient } from 'src/lib/controlPlane/client';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { getPostgresConfigWithCredentials } from 'src/lib/dataLoading/clickpipes/postgres';

const isNumericPresent = (value: number | undefined): boolean =>
  typeof value === 'number' && value > 0;

type FieldKey = keyof CDCSettings;
type ConnectionStatus = 'unknown' | 'error' | 'success';

function validateCDCSettings(
  importModel: ClickPipePostgresImport
): Map<FieldKey, string> {
  const { cdcSettings } = importModel.data;
  const errors: Map<FieldKey, string> = new Map();
  if (!isNumericPresent(Number(cdcSettings.syncInterval))) {
    errors.set('syncInterval', 'Field is required');
  }
  if (!isNumericPresent(Number(cdcSettings.initialLoadParallelism))) {
    errors.set('initialLoadParallelism', 'Field is required');
  }
  if (!isNumericPresent(Number(cdcSettings.pullBatchSize))) {
    errors.set('pullBatchSize', 'Field is required');
  }
  if (!isNumericPresent(Number(cdcSettings.snapshotNumRowsPerPartition))) {
    errors.set('snapshotNumRowsPerPartition', 'Field is required');
  }
  if (!isNumericPresent(Number(cdcSettings.snapshotNumberOfParallelTables))) {
    errors.set('snapshotNumberOfParallelTables', 'Field is required');
  }

  return errors;
}

const CDCConfiguration = () => {
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const [publicationsLoading, setPublicationsLoading] = useState(false);
  const defaultErrorState: Record<string, string | undefined> = {};
  const serviceId = getCurrentServiceIdOrFail();
  const [errors, setErrors] = useState(defaultErrorState);
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>('unknown');
  const [connectionError, setConnectionError] = useState<string | undefined>(
    undefined
  );
  const theme = useCUITheme();
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipePostgresImport>();
  const [publications, setPublications] = useState<readonly string[]>([]);
  const settings = importModel.data.cdcSettings;
  const auth = importModel.data.auth;
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(
    settings.replicationMode === 'snapshot'
  );
  const setShow = () => {
    setShowAdvancedSettings((old) => !old);
  };
  const setError = (key: FieldKey, error: string | undefined): void => {
    setErrors((oldErrors) => {
      const newErrors = { ...oldErrors };
      error === undefined ? delete newErrors[key] : (newErrors[key] = error);
      return newErrors;
    });
  };

  const fetchPublications = async () => {
    setPublicationsLoading(true);
    try {
      const request = getPostgresConfigWithCredentials(importModel);
      const listPublicationResponse = await api.listPostgresPublications(
        request,
        serviceId
      );
      setPublications(listPublicationResponse.data.publications);
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message ?? 'Unable to fetch publications.';
        setConnectionError(message);
        setConnectionStatus('error');
      } else {
        setConnectionError('Unable to fetch publications.');
        setConnectionStatus('error');
      }
    } finally {
      setPublicationsLoading(false);
    }
  };

  const setCDCSetting = (field: FieldKey, value: string | number): void => {
    setError(field, undefined);
    updateImportModel<Draft<PostgresImport>>((model) => {
      const cdcSettings = model.data.cdcSettings as CDCSettings;
      model.data.cdcSettings = { ...cdcSettings, [field]: value };
    });
  };

  const validateModel = (): boolean => {
    const validationErrors = validateCDCSettings(importModel);
    for (const [field, message] of validationErrors.entries()) {
      setError(field, message);
    }
    return validationErrors.size === 0;
  };

  const validateSettings = async (): Promise<boolean> => {
    const valid = validateModel();
    if (!valid) {
      return false;
    }
    return true;
  };

  const nextStep = async (): Promise<void> => {
    setLoading(true);
    const valid = await validateSettings();
    if (!valid) {
      setLoading(false);
      return;
    }
    updateImportModel<Draft<PostgresImport>>((model) => {
      model.data.step = 3;
    });
    setLoading(false);
  };

  const back = (): void => {
    updateImportModel((draft: Draft<ClickPipePostgresImport>) => {
      draft.data.step = 1;
    });
  };

  const refresh = async (): Promise<void> => {
    updateImportModel((draft: Draft<ClickPipePostgresImport>) => {
      draft.data.cdcSettings.publicationName = '';
    });
    await fetchPublications();
  };

  useEffect(() => {
    if (settings.replicationMode === 'cdc') {
      void fetchPublications();
    }
  }, [settings.replicationMode, auth]);

  return (
    <Container orientation="vertical" gap="sm" maxWidth="800px">
      {settings.replicationMode === 'cdc' && publications && (
        <>
          {connectionStatus === 'error' && connectionError && (
            <Alert
              text={connectionError}
              state="danger"
              size="medium"
              showIcon={false}
            />
          )}
          <Text color="muted">
            ClickPipes requires a requires a publication associated with the
            tables you wish to sync.
          </Text>
          <Container orientation="vertical" gap="xs">
            {publicationsLoading ? (
              <Alert
                data-testid="postgres-clickpipes-loading-publications"
                text="Loading publications..."
              />
            ) : (
              <Select
                placeholder="Select publication"
                data-testid="postgres-clickpipes-publication-select"
                showSearch={true}
                onSelect={(option) => setCDCSetting('publicationName', option)}
                value={settings.publicationName}
              >
                {publications?.map((publication) => (
                  <Select.Item
                    data-testid="postgres-clickpipes-publication-option"
                    key={publication}
                    value={publication}
                  >
                    {publication}
                  </Select.Item>
                ))}
              </Select>
            )}
            <Text color="default" size="sm">
              If no publications are selected, one will be automatically created
              for you.
            </Text>
          </Container>
        </>
      )}
      <Spacer size="xs" />
      {settings.replicationMode === 'cdc' && (
        <Button type="empty" style={{ paddingLeft: 0 }} onClick={setShow}>
          <Icon
            name={showAdvancedSettings ? 'chevron-down' : 'chevron-right'}
            size="md"
            color={theme.name === 'light' ? 'black' : undefined}
          />
          <Text size="md" weight="medium" color="default">
            Advanced settings
          </Text>
        </Button>
      )}

      {showAdvancedSettings && (
        <Container orientation="vertical" gap="sm">
          {settings.replicationMode === 'cdc' && (
            <NumberField
              loading={false}
              label="Sync interval (seconds)"
              error={errors['syncInterval']}
              name="syncInterval"
              value={settings?.syncInterval}
              onChange={(value) => setCDCSetting('syncInterval', value)}
              data-testid="postgres-clickpipe-sync-interval"
              autoComplete="off"
              className="fs-exclude"
            />
          )}
          <NumberField
            loading={false}
            label="Parallel threads for initial load"
            error={errors['initialLoadParallelism']}
            name="initialLoadParallelism"
            value={settings?.initialLoadParallelism}
            onChange={(value) => setCDCSetting('initialLoadParallelism', value)}
            data-testid="postgres-clickpipe-initial-load-parallelism"
            autoComplete="off"
            className="fs-exclude"
          />
          {settings.replicationMode === 'cdc' && (
            <NumberField
              loading={false}
              label="Pull batch size"
              error={errors['pullBatchSize']}
              name="pullBatchSize"
              value={settings?.pullBatchSize}
              onChange={(value) => setCDCSetting('pullBatchSize', value)}
              data-testid="postgres-clickpipe-pull-batch-size"
              autoComplete="off"
              className="fs-exclude"
            />
          )}
          <NumberField
            loading={false}
            label="Snapshot number of rows per partition"
            error={errors['snapshotNumRowsPerPartition']}
            name="snapshotRowsPerPartition"
            value={settings?.snapshotNumRowsPerPartition}
            onChange={(value) =>
              setCDCSetting('snapshotNumRowsPerPartition', value)
            }
            data-testid="postgres-clickpipe-snapshot-rows-per-partition"
            autoComplete="off"
            className="fs-exclude"
          />
          <NumberField
            loading={false}
            label="Snapshot number of tables in parallel"
            error={errors['snapshotNumberOfParallelTables']}
            name="snapshotParallelTables"
            value={settings?.snapshotNumberOfParallelTables}
            onChange={(value) =>
              setCDCSetting('snapshotNumberOfParallelTables', value)
            }
            data-testid="postgres-clickpipe-snapshot-num-parallel-tables"
            autoComplete="off"
            className="fs-exclude"
          />
          {settings.replicationMode === 'cdc' && (
            <TextField
              label="Replication slot"
              error={errors['replicationSlotName']}
              name="replicationSlotName"
              value={settings?.replicationSlotName}
              onChange={(value) => setCDCSetting('replicationSlotName', value)}
              data-testid="postgres-clickpipe-replication-slot-name"
              autoComplete="off"
              placeholder="clickpipe_<unique_identifier>"
              className="fs-exclude"
            />
          )}
        </Container>
      )}
      <Spacer size="sm" />
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          <Icon name="arrow-left" /> Previous
        </Button>
        <Button
          type="secondary"
          onClick={refresh}
          loading={loading}
          disabled={loading}
          data-testid="cdc-settings-refresh"
        >
          <Icon name="refresh" /> Refresh
        </Button>
        <Button
          onClick={(): void => void nextStep()}
          loading={loading}
          disabled={loading}
          data-testid="postgres-clickpipe-cdc-settings-next-step"
        >
          Next <Icon name="arrow-right" />
        </Button>
      </Container>
    </Container>
  );
};
export default CDCConfiguration;
