import { Container } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Container)`
  background: ${({ theme }): string => theme.palette.brand['300']};
  border-radius: 99%;
  height: 4rem;
  width: 4rem;
  padding: 1rem;
`;

export const ClickHouseVerticalLoading = (): ReactElement => {
  return (
    <Wrapper isResponsive={false} justifyContent="center" alignItems="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 54 54"
        fill="none"
      >
        <rect
          width="5.9998"
          height="6.75"
          y="23.6241"
          rx="1.45943"
          fill="#C0C0C0"
        >
          <animate
            id="logoRectAnimate1"
            attributeName="height"
            begin="0s;logoRectEndAnimate1.end+1s"
            dur="0.5s"
            values="6.75;53.9982"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="y"
            begin="0s;logoRectEndAnimate1.end+1s"
            dur="0.5s"
            values="23.6241;0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            id="logoRectEndAnimate1"
            attributeName="height"
            begin="logoRectEndAnimate2.end"
            dur="0.5s"
            values="53.9982;6.75"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            attributeName="y"
            begin="logoRectEndAnimate2.end"
            dur="0.5s"
            values="0;23.6241"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            attributeName="fill"
            begin="0s;logoRectEndAnimate1.end+1s"
            dur="0.5s"
            values="#C0C0C0;#161517"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="fill"
            begin="logoRectEndAnimate2.end"
            dur="0.5s"
            values="#161517;#C0C0C0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
        </rect>

        <rect
          x="12"
          y="23.6241"
          width="5.9998"
          height="6.75"
          rx="1.45943"
          fill="#C0C0C0"
        >
          <animate
            id="logoRectAnimate2"
            attributeName="height"
            begin="logoRectAnimate1.end"
            dur="0.5s"
            values="6.75;53.9982"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            attributeName="y"
            begin="logoRectAnimate1.end"
            dur="0.5s"
            values="23.6241;0"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            id="logoRectEndAnimate2"
            attributeName="height"
            begin="logoRectEndAnimate3.end"
            dur="0.5s"
            values="53.9982;6.75"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            attributeName="y"
            begin="logoRectEndAnimate3.end"
            dur="0.5s"
            values="0;23.6241"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            attributeName="fill"
            begin="logoRectAnimate1.end"
            dur="0.5s"
            values="#C0C0C0;#161517"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="fill"
            begin="logoRectEndAnimate3.end"
            dur="0.5s"
            values="#161517;#C0C0C0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
        </rect>

        <rect
          x="24.001"
          y="23.6241"
          width="5.9998"
          height="6.75"
          rx="1.45943"
          fill="#C0C0C0"
        >
          <animate
            id="logoRectAnimate3"
            attributeName="height"
            begin="logoRectAnimate2.end"
            dur="0.5s"
            values="6.75;53.9982"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="y"
            begin="logoRectAnimate2.end"
            dur="0.5s"
            values="23.6241;0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            id="logoRectEndAnimate3"
            attributeName="height"
            begin="logoRectEndAnimate4.end"
            dur="0.5s"
            values="53.9982;6.75"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="y"
            begin="logoRectEndAnimate4.end"
            dur="0.5s"
            values="0;23.6241"
            calcMode="linear"
            fill="freeze"
            repeatCount="1"
          />
          <animate
            attributeName="fill"
            begin="logoRectAnimate2.end"
            dur="0.5s"
            values="#C0C0C0;#161517"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="fill"
            begin="logoRectEndAnimate4.end"
            dur="0.5s"
            values="#161517;#C0C0C0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
        </rect>

        <rect
          x="35.998"
          y="23.6241"
          width="5.9998"
          height="6.75"
          rx="1.45943"
          fill="#C0C0C0"
        >
          <animate
            id="logoRectAnimate4"
            attributeName="height"
            begin="logoRectAnimate3.end"
            dur="0.5s"
            values="6.75;53.9982"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="y"
            begin="logoRectAnimate3.end"
            dur="0.5s"
            values="23.6241;0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            id="logoRectEndAnimate4"
            attributeName="height"
            begin="logoRectAnimate5.end"
            dur="0.5s"
            values="53.9982;6.75"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="y"
            begin="logoRectAnimate5.end"
            dur="0.5s"
            values="0;23.6241"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="fill"
            begin="logoRectAnimate3.end"
            dur="0.5s"
            values="#C0C0C0;#161517"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
          <animate
            attributeName="fill"
            begin="logoRectAnimate5.end"
            dur="0.5s"
            values="#161517;#C0C0C0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
        </rect>

        <rect
          x="48.001"
          y="23.6241"
          width="5.9998"
          height="6.75"
          rx="1.45943"
          fill="#C0C0C0"
        >
          <animate
            id="logoRectAnimate5"
            attributeName="height"
            begin="logoRectAnimate4.end"
            dur="1s"
            values="6.75;11.9996;6.75"
            calcMode="linear"
            fill="freeze"
          />
          <animate
            attributeName="y"
            begin="logoRectAnimate4.end"
            dur="1s"
            values="23.6241;21.0005;23.6241"
            calcMode="linear"
            fill="freeze"
          />
          <animate
            attributeName="fill"
            begin="logoRectAnimate4.end"
            dur="1s"
            values="#C0C0C0;#161517;#C0C0C0"
            calcMode="linear"
            repeatCount="1"
            fill="freeze"
          />
        </rect>
      </svg>
    </Wrapper>
  );
};
