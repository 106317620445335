import { ReactElement } from 'react';

import { RemoveTabModalProps } from 'src/components/App/MainView/TabBar/RemoveTabModal/types';
import { ConfirmationDialog } from '@clickhouse/click-ui';

function RemoveTabModal({
  open = false,
  title,
  content,
  onCancel,
  onClick
}: RemoveTabModalProps): ReactElement | null {
  if (open !== true) {
    return null;
  }

  const apply = (): void => {
    onClick && onClick();
    onCancel && onCancel();
  };

  if (!title) {
    console.warn('RemoveTabModal: title is not defined');
  }

  return (
    <ConfirmationDialog
      title={title ?? ''}
      open={open}
      onCancel={onCancel}
      onConfirm={apply}
      primaryActionLabel="Confirm"
      secondaryActionLabel="Cancel"
    >
      {content}
    </ConfirmationDialog>
  );
}

export default RemoveTabModal;
