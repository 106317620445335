import { ServiceQueryAPIEndpoint } from '@prisma/client';
import { useCallback, useEffect, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { UpsertServiceQueryApiEndpointInput } from 'src/queryEndpoints/queryEndpointApiClient';

type ServiceQueryApiEndpointHookResult = {
  hasServiceQueryApiEndpoint: boolean;
  serviceQueryApiEndpoint: ServiceQueryAPIEndpoint | null;
  isLoading: boolean;
  error: Error | null;
  upsertServiceQueryApiEndpoint: (input: UpsertServiceQueryApiEndpointInput) => Promise<void>;
  disableServiceQueryApiEndpoint: () => Promise<void>;
};

export function useServiceQueryApiEndpoint(currentServiceId: string): ServiceQueryApiEndpointHookResult {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [serviceQueryApiEndpoint, setServiceQueryApiEndpoint] = useState<ServiceQueryAPIEndpoint | null>(null);
  const [hasServiceQueryApiEndpoint, setHasServiceQueryApiEndpoint] = useState(false);

  const apiClient = useApiClient();

  useEffect(() => {
    const fetchData = async (serviceId: string): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const value = await apiClient.queryEndpoints.getServiceQueryApiEndpoint(serviceId);
        console.log(value);
        setServiceQueryApiEndpoint(value);
        if (value) {
          setHasServiceQueryApiEndpoint(true);
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error('An unknown error occurred'));
        }
      } finally {
        setLoading(false);
      }
    };

    if (currentServiceId) {
      fetchData(currentServiceId).catch(console.error);
    }

    return (): void => {
      setLoading(true);
      setError(null);
      setServiceQueryApiEndpoint(null);
      setHasServiceQueryApiEndpoint(false);
    };
  }, [apiClient.queryEndpoints, currentServiceId]);

  const upsertServiceQueryApiEndpoint = useCallback(
    async (input: UpsertServiceQueryApiEndpointInput): Promise<void> => {
      setHasServiceQueryApiEndpoint(true);
      setLoading(true);
      setError(null);

      try {
        const value = await apiClient.queryEndpoints.upsertServiceQueryApiEndpoint(currentServiceId, input);
        setServiceQueryApiEndpoint(value);
      } catch (err) {
        setHasServiceQueryApiEndpoint(false);
        if (err instanceof Error) {
          setError(err);
        } else {
          setError(new Error('An unknown error occurred'));
        }
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading, apiClient.queryEndpoints, currentServiceId, setServiceQueryApiEndpoint]
  );

  const disableServiceQueryApiEndpoint = useCallback(async (): Promise<void> => {
    setLoading(true);
    setError(null);
    setHasServiceQueryApiEndpoint(false);
    try {
      await apiClient.queryEndpoints.deleteServiceQueryApiEndpoint(currentServiceId);
      setServiceQueryApiEndpoint(null);
    } catch (err) {
      setHasServiceQueryApiEndpoint(true);
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error('An unknown error occurred'));
      }
    } finally {
      setLoading(false);
    }
  }, [setError, setLoading, apiClient.queryEndpoints, currentServiceId, setServiceQueryApiEndpoint]);

  return {
    hasServiceQueryApiEndpoint,
    serviceQueryApiEndpoint,
    isLoading: loading,
    error,
    upsertServiceQueryApiEndpoint,
    disableServiceQueryApiEndpoint
  };
}
