import { Table, TableHeaderType, TableRowType } from '@clickhouse/click-ui';
import { REGION_BY_ID } from '@cp/common/protocol/Region';
import { ReactNode } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';

interface OrganizationPrivateEndpointListProps {
  onDelete: (endpointIndex: number) => void;
}

export function OrganizationPrivateEndpointList({
  onDelete: onDeleteProp
}: OrganizationPrivateEndpointListProps): ReactNode {
  const currentOrganization = useCurrentOrganizationOrThrow();
  const instances = useCurrentOrganizationInstances();
  const hasManagePrivateEndpointsPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:service:manage-private-endpoints',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  const headers: Array<TableHeaderType> = [
    { label: 'Provider', width: '10%' },
    { label: 'Region', width: '10%' },
    { label: 'Endpoint Id', width: '35%' },
    { label: 'Description', width: '35%' },
    { label: 'Services', width: '10%' }
  ];

  const rows: Array<TableRowType> = currentOrganization.privateEndpoints.map(
    (endpoint) => {
      const instanceCount = instances.reduce(
        (count, instance) =>
          instance.privateEndpointIds.includes(endpoint.id) ? count + 1 : count,
        0
      );
      const regionId = endpoint.region;
      const displayRegion = regionId ? REGION_BY_ID[regionId].displayId : '';
      const id = `private-endpoint-${endpoint.id}`;
      return {
        id,
        'data-testid': id,
        items: [
          {
            label: endpoint.cloudProvider,
            'data-testid': `${id}-cloud-provider`
          },
          { label: displayRegion, 'data-testid': `${id}-region` },
          { label: endpoint.id, 'data-testid': `${id}-id` },
          {
            label: endpoint.description,
            'data-testid': `${id}-description`
          },
          {
            label: instanceCount,
            'data-testid': `${id}-instance-count`
          }
        ]
      };
    }
  );

  const onDelete = (_: unknown, index: number): void => onDeleteProp(index);

  return (
    <Table
      size="md"
      headers={headers}
      rows={rows}
      onDelete={hasManagePrivateEndpointsPermission ? onDelete : undefined}
    />
  );
}
