import { Container, Link, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

export default function Description(): ReactElement {
  return (
    <Container maxWidth="70%">
      <Text>
        View and create API keys for the ClickHouse Cloud console. API
        permissions mirror user roles and do not grant access to your ClickHouse
        databases.{' '}
        <Link
          href="https://clickhouse.com/docs/en/cloud/manage/openapi"
          target="_blank"
        >
          Read more
        </Link>
      </Text>
    </Container>
  );
}
