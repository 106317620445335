import { CUIThemeType, IconName } from '@clickhouse/click-ui';
import {
  Instance,
  INSTANCE_TIER_TO_CPU_MEM_RATIO_MAP,
  INSTANCE_TIER_TO_REPLICA_COUNT_MAP,
  InstanceState,
  InstanceTier
} from '@cp/common/protocol/Instance';
import { Region, REGION_BY_ID, RegionId } from '@cp/common/protocol/Region';
import { truthy } from '@cp/common/utils/Assert';
import { generate } from 'generate-password-ts';
import { capitalize } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const createTestInstanceObject = ({
  id = uuidv4(),
  clickhouseVersion = '23.09',
  cloudProvider = 'aws',
  creationDate = Date.now(),
  customAutoscaleValues = [],
  dbUsername = 'default',
  defaultDatabaseRoleMappings = [],
  endpoints = {
    https: { hostname: 'localhost', port: 8080 },
    mysql: { hostname: 'localhost', port: 3143 },
    nativesecure: { hostname: 'localhost', port: 4001 }
  },
  features = [],
  hasUserData = true,
  idleTimeoutMinutes = 10,
  instanceTier = 'Production',
  ipAccessList = [],
  lastBackupStarted = Date.now(),
  maintenanceWindows = [],
  name = 'My Test Instance',
  organizationId = 'abc',
  state = 'running',
  pendingActions = [],
  regionId = 'us-east-2',
  enableIdleScaling = true,
  minAutoScalingTotalMemory,
  maxAutoScalingTotalMemory,
  privateEndpointIds = [],
  backupConfiguration = {
    autoBackupEnabled: true,
    incrementalBackupsEnabled: false,
    incrementalBackupsOnly: false
  },
  activeMaintenanceKind = undefined,
  dataWarehouseId = uuidv4(),
  isPrimary = true,
  sqlConsoleRolesDefined = true,
  database = 'default',
  byocId = undefined,
  isReadonly = false
}: Partial<Instance> = {}): Instance => ({
  id,
  clickhouseVersion,
  cloudProvider,
  creationDate,
  customAutoscaleValues,
  dbUsername,
  defaultDatabaseRoleMappings,
  endpoints,
  features,
  hasUserData,
  idleTimeoutMinutes,
  instanceTier,
  ipAccessList,
  lastBackupStarted,
  maintenanceWindows,
  name,
  organizationId,
  state,
  pendingActions,
  regionId,
  enableIdleScaling,
  minAutoScalingTotalMemory,
  maxAutoScalingTotalMemory,
  privateEndpointIds,
  backupConfiguration,
  activeMaintenanceKind,
  dataWarehouseId,
  isPrimary,
  sqlConsoleRolesDefined,
  database,
  byocId,
  isReadonly
});

export const formatMemoryAllocation = (memoryAllocation: number, instanceTier: InstanceTier): string => {
  const defaultReplicas = INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instanceTier ?? 'Production'];
  const cpuMemRatio = INSTANCE_TIER_TO_CPU_MEM_RATIO_MAP[instanceTier ?? 'Production'];
  return `${memoryAllocation / defaultReplicas} GiB, ${Math.floor(
    memoryAllocation / defaultReplicas / cpuMemRatio
  )} vCPU`;
};

const instanceNumReplicas = (instanceTier: InstanceTier, dpNumReplicas: number): number => {
  if (dpNumReplicas === 0) {
    return INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instanceTier];
  } else {
    return dpNumReplicas;
  }
};

export const formatClusterMemoryAllocation = (
  memoryAllocation: number,
  instanceTier: InstanceTier,
  dpNumReplicas: number
): string => {
  const defaultReplicas = INSTANCE_TIER_TO_REPLICA_COUNT_MAP[instanceTier];
  const cpuMemRatio = INSTANCE_TIER_TO_CPU_MEM_RATIO_MAP[instanceTier];
  const replicaSize = memoryAllocation / defaultReplicas;
  const clusterMemorySize = replicaSize * instanceNumReplicas(instanceTier, dpNumReplicas);
  return `${clusterMemorySize} GiB, ${Math.floor(clusterMemorySize / cpuMemRatio)} vCPU`;
};

export type InstanceStateWithUpgrade = InstanceState | 'upgrading' | 'upgrading-error';

export function instanceStateLabel(instanceState: InstanceStateWithUpgrade): string {
  switch (instanceState) {
    case 'upgrading':
      return 'Upgrade in progress';
    case 'upgrading-error':
      return 'Upgrade failed';
    default:
      return capitalize(instanceState);
  }
}

export function instanceStateIcon(instanceState: InstanceStateWithUpgrade): IconName {
  switch (instanceState) {
    case 'provisioning':
    case 'starting':
    case 'stopping':
    case 'terminating':
    case 'upgrading':
    case 'awaking':
    case 'partially_running':
      return 'horizontal-loading';
    case 'running':
      return 'check';
    case 'idle':
      return 'dots-horizontal';
    case 'stopped':
      return 'pause';
    case 'failed':
    case 'terminated':
      return 'cross';
    case 'upgrading-error':
    case 'degraded':
      return 'warning';
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unsupported instance state: ${instanceState}`);
  }
}
export type InstanceColors = {
  backgroundColor: string;
  iconColor: string;
};

export function instanceStateColors(theme: CUIThemeType, instanceState: InstanceStateWithUpgrade): InstanceColors {
  switch (instanceState) {
    case 'running':
    case 'idle':
    case 'awaking':
    case 'partially_running':
      return {
        backgroundColor: theme.global.color.feedback.success.background,
        iconColor: theme.global.color.feedback.success.foreground
      };
    case 'failed':
    case 'terminated':
    case 'degraded':
    case 'terminating':
    case 'upgrading-error':
      return {
        backgroundColor: theme.global.color.feedback.danger.background,
        iconColor: theme.global.color.feedback.danger.foreground
      };
    default:
      return {
        backgroundColor: theme.global.color.feedback.neutral.background,
        iconColor: theme.global.color.feedback.neutral.foreground
      };
  }
}

export function instanceRegionName(regionId: RegionId): string {
  const region: Region = truthy(REGION_BY_ID[regionId], 'Invalid region');
  return `${region.name} (${region.displayId})`;
}

/** Browser-side version of 'makeSha256Base64Server'.  */
export async function makeSha256Base64Browser(text: string): Promise<string> {
  const textBuffer = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', textBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Make an array of char code numbers.
  const binaryString = String.fromCharCode.apply(null, hashArray); // Convert char codes to a binary string.
  return btoa(binaryString);
}

/** Browser-side sha1 hash function */
export async function makeDoubleSha1HashBrowser(text: string): Promise<string> {
  const textEncoder = new TextEncoder();
  const hash1 = await crypto.subtle.digest('SHA-1', textEncoder.encode(text));
  const hash2 = await crypto.subtle.digest('SHA-1', hash1);
  return Array.from(new Uint8Array(hash2))
    .map((v) => v.toString(16).padStart(2, '0'))
    .join('');
}

/** Generates a random password for ClickHouse instance. */
export function generateClickHouseDbPassword(): string {
  return generate({
    length: 13,
    numbers: true,
    lowercase: true,
    uppercase: true,
    exclude: '*+$&,><:\'"`@#%/=;|?[]<>{}()^!-\\',
    symbols: true,
    strict: true
  });
}

export const formatStateForDisplay = (state: InstanceStateWithUpgrade): string => {
  return state.replace('_', ' ');
};

export function countSecondaryInstances(instanceId: string | null, instances: Record<string, Instance>): number {
  if (!instanceId) {
    return 0;
  }

  const instance = instances[instanceId];

  if (!instance) {
    return 0;
  }

  return Object.values(instances).reduce(
    (count, otherInstance) =>
      !otherInstance.isPrimary && otherInstance.dataWarehouseId === instance.dataWarehouseId ? count + 1 : count,
    0
  );
}

interface InstanceDataSourceEnabled {
  enabled: boolean;
  reason: string;
  shouldRedirect?: boolean;
}

export const DB_ACCESS_ALLOWED_STATES: Array<InstanceState> = [
  'awaking',
  'degraded',
  'idle',
  'partially_running',
  'running',
  'starting'
];

export function getInstanceDatabaseAccess(instance?: Instance): InstanceDataSourceEnabled {
  if (!instance) {
    return { enabled: false, reason: 'No service is selected' };
  }

  const stateAllowsDataSources = DB_ACCESS_ALLOWED_STATES.includes(instance.state ?? '');

  if (!stateAllowsDataSources) {
    return { enabled: false, reason: `Service is ${formatStateForDisplay(instance.state)}` };
  } else if (instance.byocId) {
    return { enabled: false, reason: 'Service is created in customer infrastructure', shouldRedirect: true };
  }

  return { enabled: true, reason: '' };
}
