import { ConfirmationDialog, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { routes } from 'src/lib/routes';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

function TrialServiceLimitReachedModal(): ReactElement | null {
  const currentOrg = useCurrentOrganizationOrThrow();
  const { isTrialModalOpen, setIsTrialModalOpen } =
    useServiceLimitReachedModal();

  if (!isTrialModalOpen) {
    return null;
  }

  const navigateToBilling = (): void => {
    navigateTo(routes.billingHome({ orgId: currentOrg.id }));
  };

  return (
    <ConfirmationDialog
      title="Trial service limit reached"
      open={isTrialModalOpen}
      onCancel={() => setIsTrialModalOpen(false)}
      onConfirm={navigateToBilling}
      primaryActionLabel="Enter payment details"
      secondaryActionLabel="Close"
      showClose
      data-testis="trial-service-limit-dialog"
    >
      <Text>
        During the trial period, you can create and run one service. If you'd
        like to start using multiple services, please enter your payment
        details.
      </Text>
    </ConfirmationDialog>
  );
}

export default TrialServiceLimitReachedModal;
