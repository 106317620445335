import {
  Container,
  Link,
  Panel,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { Galaxy } from 'galaxy';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import ClickHouseFullIcon from 'src/layout/ServiceSidebar/ClickHouseFullIcon';
import { useIsRegionBlocked } from 'src/lib/blockedRegion';
import BlockedRegionPanel from 'src/components/BlockedRegion';
import { useOnboardController } from 'src/onboard/controller/useOnboardController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import styled from 'styled-components';
import ServiceCreationStepper from 'src/components/CreateNewService/ServiceCreationStepper';
import { CreationStep } from 'src/components/CreateNewService/useCreateService';
import { OnBoardArrowSvg } from 'src/pages/OnBoardPage/OnBoardArrowSvg';

const CustomPanel = styled(Panel)`
  flex-wrap: nowrap;
  max-width: 100%;
  ${({ theme }): string => `
    @media (max-width: ${theme.breakpoint.sizes.sm}) {
      padding-inline: 0.75rem;
    }
  `}
`;

const OnboardLayoutContent = styled(MainPaneLayout.Content)`
  position: relative;
`;

const OnboardLayoutBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: url('/images/dot_grid.svg');
  filter: invert(${({ theme }) => (theme.name === 'dark' ? 0 : 1)});
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const LeftContainer = styled(Container)<{ $hide: boolean }>`
  ${({ $hide, theme }): string =>
    $hide
      ? `
    @media (max-width: ${theme.breakpoint.sizes.md}) {
    display: none;
    }
  `
      : ''};
`;

const WrapperContainer = styled(Container)`
  ${({ theme }): string => `
    @media (max-width: ${theme.breakpoint.sizes.sm}) {
      padding-inline: 1rem;
    }
  `}
`;

const SvgWrapper = styled(Container)`
  ${({ theme }): string => `
    @media (max-width: ${theme.breakpoint.sizes.md}) {
    display: none;
    }
  `}
`;

const BlockedContentWrapper = ({
  children,
  removeOnboarding
}: {
  children: ReactNode;
  removeOnboarding: () => Promise<void>;
}): ReactNode => {
  const isBlocked = useIsRegionBlocked();
  if (isBlocked) {
    return (
      <BlockedRegionPanel
        showContinueButton
        onContinueButtonClick={removeOnboarding}
      />
    );
  }
  return children;
};

const OnBoardPage = (): ReactElement => {
  const { setPostOnboardingPendingActions } = useOnboardController();
  const [step, setStep] = useState<CreationStep>('CREATE_INSTANCE');

  useEffect(() => {
    history.pushState(null, '', location.href);

    // pop state will only be called if the user interacts with the page
    // this is a security feature in browsers. see https://github.com/mdn/content/issues/10870
    const onPopState = (): void => {
      history.go(1);
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  const onClickSkipOnboarding = (): void => {
    Galaxy.galaxy().track(
      'onboardingPage.onboardingLayout.skipOnboardingClick',
      {
        interaction: 'click'
      }
    );
    void setPostOnboardingPendingActions();
  };

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar>
        <Container
          justifyContent="space-between"
          fillWidth
          isResponsive={false}
        >
          <ClickHouseFullIcon />
          <Link onClick={onClickSkipOnboarding} component="div">
            Skip onboarding
          </Link>
        </Container>
      </MainPaneLayout.Topbar>
      <OnboardLayoutContent>
        <OnboardLayoutBackground />
        <WrapperContainer
          padding="xxl"
          alignItems="start"
          justifyContent="center"
          fillHeight
          overflow="auto"
          isResponsive={false}
        >
          <Container
            gap="xl"
            orientation="vertical"
            justifyContent="center"
            alignItems="center"
            maxWidth="80vw"
            data-testid="onboard-container"
          >
            <BlockedContentWrapper
              removeOnboarding={async () => {
                await setPostOnboardingPendingActions();
              }}
            >
              <CustomPanel
                hasBorder
                padding="xl"
                orientation="horizontal"
                color="default"
                hasShadow
              >
                <Container padding="none" fillWidth alignItems="start" gap="lg">
                  <LeftContainer
                    isResponsive={false}
                    orientation="vertical"
                    alignItems="start"
                    gap="md"
                    data-testid="create-new-service-page"
                    $hide={step !== 'CREATE_INSTANCE'}
                  >
                    <Title type="h2">Hi there!</Title>
                    <Text component="div">
                      To start ingesting data into ClickHouse Cloud, first
                      create a service.
                      <Spacer />A service is an instance of ClickHouse that
                      lives in the Cloud and can host multiple databases. Please
                      select your Cloud provider and region to get started.
                    </Text>
                    <SvgWrapper
                      isResponsive={false}
                      alignItems="center"
                      justifyContent="center"
                      padding="xxl"
                    >
                      <OnBoardArrowSvg />
                    </SvgWrapper>
                    <Spacer />
                  </LeftContainer>
                  <Container isResponsive={false} orientation="vertical">
                    <ServiceCreationStepper
                      type="onboarding"
                      onStepChange={setStep}
                    />
                  </Container>
                </Container>
              </CustomPanel>
            </BlockedContentWrapper>
          </Container>
        </WrapperContainer>
      </OnboardLayoutContent>
    </MainPaneLayout>
  );
};

export default OnBoardPage;
