import {
  Rectangle,
  SelectedRegion,
  SelectionFocus
} from '@clickhouse/click-ui';
import { RefObject } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { impossibleValue } from 'shared';
import { Row } from 'shared/src/clickhouse';
import { emptyIterable, rangeIterator } from 'src/lib/iterator';

interface CopyGridElementsProps {
  selection: SelectedRegion;
  rowCount: number;
  columnCount: number;
  pageStart?: number;
  focus: SelectionFocus;
  outerRef: RefObject<HTMLDivElement>;
  getRowsByIndex: (rowIndexes: Iterable<number>) => Promise<(null | Row)[]>;
}

const addCellToRow = (row: HTMLTableRowElement, value: string | null): void => {
  const td = document.createElement('td');
  const html = renderToStaticMarkup(<span>{value}</span>);
  td.innerHTML = html;
  row.appendChild(td);
};

const columnListLoop = (
  tbody: HTMLTableSectionElement,
  columnList: Array<number>,
  rows: Array<null | Row>,
  rowIndex: number
): void => {
  const row = document.createElement('tr');
  columnList.forEach((columnIndex) => {
    const rowItem = rows[rowIndex];
    const value = rowItem ? rowItem[columnIndex] : null;
    addCellToRow(row, value);
  });
  tbody.appendChild(row);
};

export const copyGridElements = async ({
  selection,
  rowCount,
  columnCount,
  pageStart = 0,
  focus,
  outerRef,
  getRowsByIndex
}: CopyGridElementsProps): Promise<void> => {
  const bounds = {
    left: 0,
    right: columnCount - 1,
    top: pageStart,
    bottom: pageStart + rowCount - 1
  };
  const rowIndexes = rowsInSelection(selection, bounds);
  const rows = await getRowsByIndex(rowIndexes);

  if (!outerRef.current) {
    throw new Error('Could not fetch selection');
  }
  const table = document.createElement('table');
  table.style.position = 'absolute';
  table.style.top = '-200px';
  table.style.left = '-200px';
  table.style.width = '0px';
  table.style.height = '0px';
  table.style.overflow = 'hidden';
  const thead = document.createElement('thead');
  const tbody = document.createElement('tbody');

  switch (selection.type) {
    case 'rectangle':
      Array.from(
        { length: selection.bounds.bottom + 1 - selection.bounds.top },
        (_, index) => selection.bounds.top + index
      ).forEach((_, idx) => {
        const columnList = Array.from(
          { length: selection.bounds.right + 1 - selection.bounds.left },
          (_, index) => selection.bounds.left + index
        );
        columnListLoop(tbody, columnList, rows, idx);
      });
      break;
    case 'columns':
      Array.from({ length: rowCount }, (_, index) => pageStart + index).forEach(
        (rowIndex) => {
          const columnList = [...selection.columns].sort();
          columnListLoop(tbody, columnList, rows, rowIndex);
        }
      );
      break;
    case 'rows':
      [...selection.rows].sort().forEach((_, idx) => {
        const columnList = Array.from(
          { length: columnCount },
          (_, index) => pageStart + index
        );
        columnListLoop(tbody, columnList, rows, idx);
      });
      break;
    case 'empty': {
      const singleRowIndexes = rowsInSelection(
        {
          type: 'rows',
          rows: new Set([focus.row]),
          anchorRow: 0
        },
        bounds
      );
      const localRows = await getRowsByIndex(singleRowIndexes);
      columnListLoop(tbody, [focus.column], localRows, 0);
      break;
    }
    default:
      throw new Error('incorrect selection provided');
  }

  table.appendChild(thead);
  table.appendChild(tbody);

  outerRef.current.appendChild(table);

  const windowSelection = window.getSelection();
  if (windowSelection) {
    const range = document.createRange();
    range.selectNodeContents(table);
    windowSelection.removeAllRanges();
    windowSelection.addRange(range);
    await navigator.clipboard.writeText(table.innerText);
    windowSelection.removeAllRanges();

    outerRef.current.removeChild(table);
  } else {
    throw new Error('Could not fetch selection');
  }
};

export function rowsInSelection(
  selection: SelectedRegion,
  bounds: Rectangle
): Iterable<number> {
  switch (selection.type) {
    case 'rectangle':
      return rangeIterator(selection.bounds.top - 1, selection.bounds.bottom);
    case 'columns':
      return rangeIterator(bounds.top, bounds.bottom + 1);
    case 'rows':
      return [...selection.rows].sort().map((row) => row - 1);
    case 'empty':
      return emptyIterable();
    default:
      return impossibleValue(selection);
  }
}

export default copyGridElements;
