import Name from 'src/components/primitives/lib/Toolbar/Name';
import {
  gapContainer,
  toolbarContainer
} from 'src/components/primitives/lib/Toolbar/styles';
import {
  GapProps,
  ToolbarProps
} from 'src/components/primitives/lib/Toolbar/types';

function Toolbar(props: ToolbarProps) {
  if ('name' in props) {
    const {
      buttonGap = 12,
      name,
      onNameChange,
      allowEditing,
      children,
      log,
      className,
      ...otherProps
    } = props;
    return (
      <Gap
        gap={10}
        css={toolbarContainer}
        className={className}
        {...otherProps}
      >
        <Name
          name={name}
          onNameChange={onNameChange}
          allowEditing={allowEditing}
          log={log}
          className="fs-exclude"
        />
        <Gap gap={buttonGap}>{children}</Gap>
      </Gap>
    );
  } else {
    const {
      nameElement,
      buttonGap = 12,
      children,
      className,
      ...otherProps
    } = props;

    return (
      <Gap
        gap={10}
        css={toolbarContainer}
        className={className}
        {...otherProps}
      >
        {nameElement}
        <Gap gap={buttonGap}>{children}</Gap>
      </Gap>
    );
  }
}
const Gap = ({ children, gap, className, ...props }: GapProps) => {
  return (
    <div
      css={gapContainer(gap)}
      className={`toolbar-gap-container-${gap} ${className ?? ''}`}
      {...props}
    >
      {children}
    </div>
  );
};

Gap.displayName = 'Gap';
Toolbar.Gap = Gap;

export default Toolbar;
