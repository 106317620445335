interface CellClick {
  type: 'cell';
  row: number;
  column: number;
}

interface ColumnHeaderClick {
  type: 'columnHeader';
  column: number;
}

interface RowHeaderClick {
  type: 'rowHeader';
  row: number;
}

export type ClickLocation = CellClick | ColumnHeaderClick | RowHeaderClick;

export function getContainingCellPosition(domNode: HTMLElement | null, container: HTMLElement): ClickLocation | null {
  let node: ParentNode | null = domNode;

  while (node && node !== container) {
    if (node instanceof HTMLElement) {
      const data = node.dataset;
      if (data.location === 'column-header' && data.column !== undefined) {
        return {
          type: 'columnHeader',
          column: parseInt(data.column)
        };
      } else if (data.location === 'row-header' && data.row !== undefined) {
        return {
          type: 'rowHeader',
          row: parseInt(data.row)
        };
      } else if (data.location === 'cell' && data.column !== undefined && data.row !== undefined) {
        return {
          type: 'cell',
          row: parseInt(data.row),
          column: parseInt(data.column)
        };
      }
    }
    node = node.parentNode;
  }

  return null;
}
