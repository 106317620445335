import styled from 'styled-components';

const PeerDBTileStyleOverrides = styled.div`
  svg {
    width: 80%;
    height: auto;
  }
`;

export default PeerDBTileStyleOverrides;
