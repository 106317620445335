import React from 'react';
import { atom, useAtom } from 'jotai';
import {
  GptCorrectionRequest,
  GtpCorrectionResponse,
  useApiClient
} from 'src/lib/controlPlane/client';
import { useGptRequest } from 'src/lib/gpt/GptRequestHooks';
import { RunningQuery } from 'src/lib/query/runningQueryTypes';
import { getCurrentServiceId } from 'src/state/service';
import { useConnectionCredentials, useCredentials } from 'src/state/connection';
import useQueryCorrectionTracking from 'src/lib/gpt/GPTQueryTrackingHook';
import { useTabs } from 'src/state/tabs';

export interface GptCorrectionHookValue {
  // A function that sends a request to the OpenAI GPT API to correct a query
  requestGptCorrection: (runId: string, query: RunningQuery) => Promise<void>;
  //  A function that cancels a request to the OpenAI GPT API
  // Please note: we do only 1 request at at time. This is why
  // this function doesn't have a runId parameter.
  cancelRequest: () => void;
  // A boolean indicating whether a request to the OpenAI GPT API is currently running
  isRequestRunning: boolean;
  // A function that returns the corrected query text for a given runId
  getCorrectedQueryText: (runId: string) => string | undefined;
  // A function that discards the corrected query for a given runId, removes it from the internal list
  // and sends a message to the API to track the discarded correction
  discardCorrection: (runId: string) => void;
  // A function that accepts the corrected query for a given runId and sends a message to the API
  // to track the accepted correction
  acceptCorrection: (runId: string) => void;
}

const correctedQueriesAtom = atom<Record<string, GtpCorrectionResponse>>({});
const abortControllerAtom = atom<AbortController | null>(null);

/**
 * A hook for making requests to OpenAI GPT to correct errors in SQL queries.
 * @returns An object with functions and properties for making and managing requests.
 */
export const useGptCorrection = (): GptCorrectionHookValue => {
  const api = useApiClient();
  const { selectedDatabase } = useConnectionCredentials();
  const serviceId = getCurrentServiceId() || '';
  const { sendGptFeedback, startTrackingQuery } = useQueryCorrectionTracking();
  const tabs = useTabs();
  const credential = useCredentials();

  // A list of corrected queries, indexed by query run ID
  const [correctedQueries, setCorrectedQueries] = useAtom(correctedQueriesAtom);

  const { sendRequest, cancelRequest, isRequestRunning } = useGptRequest(
    api.getGptCorrection.bind(api),
    abortControllerAtom
  );

  /**
   * A function that sends a request to the OpenAI GPT API to correct a query.
   * @param runId The ID of the query run to correct
   * @param query The `RunningQuery` object to correct
   */
  const requestGptCorrection = React.useCallback(
    async (runId: string, query: RunningQuery) => {
      if (!query) {
        return;
      }
      const request: GptCorrectionRequest = {
        queryText: query.args?.query || '',
        error: query?.result?.error?.message || '',
        serviceId,
        credential
      };
      const correctedQuery = await sendRequest(request);
      if (!correctedQuery) {
        // The process has been probably interrupted by the user
        return;
      }
      setCorrectedQueries((prev) => ({ ...prev, [runId]: correctedQuery }));
    },
    [serviceId, credential, sendRequest, setCorrectedQueries]
  );

  /**
   * Retrieves the corrected query for a specific query run ID.
   * @param runId The ID of the query run for which the corrected query is needed
   * @returns The corrected query for the specified query run ID, or `undefined`
   * if no corrected query is found in the internal list
   */
  const getCorrectedQueryText = (runId: string): string | undefined => {
    return correctedQueries[runId]?.completion;
  };

  /**
   * Removes the corrected query associated with a specific query run ID from the internal list.
   * @param runId The ID of the query run for which the corrected query should be discarded.
   */
  const discardCorrectedQueryFromList = (runId: string): void => {
    setCorrectedQueries((prev) => {
      const newCorrectedQueries = { ...prev };
      delete newCorrectedQueries[runId];
      return newCorrectedQueries;
    });
  };

  /**
   * Sends a message to the API indicating that a correction has been discarded,
   * and removes the associated corrected query from the internal list.
   * @param runId The ID of the query run for which the correction was discarded.
   */
  const discardCorrection = (runId: string): void => {
    discardCorrectedQueryFromList(runId);
    const traceId = correctedQueries[runId]?.traceId;
    sendGptFeedback(serviceId || '', traceId, 'declined');
  };

  /**
   * Sends a message to the API indicating that a correction has been accepted,
   * and removes the associated corrected query from the internal list.
   * @param runId The ID of the query run for which the correction was accepted
   */
  const acceptCorrection = (runId: string): void => {
    discardCorrectedQueryFromList(runId);
    const correctedQuery = correctedQueries[runId];
    const traceId = correctedQuery?.traceId;
    sendGptFeedback(serviceId || '', traceId, 'accepted');

    // Find the tab with the runId
    const tab = tabs.find((tab) => {
      if (tab.type !== 'query') {
        return false;
      }
      return tab.queryRunId === runId;
    });
    if (!tab || tab.type !== 'query') {
      return;
    }
    startTrackingQuery({
      tabId: tab.id,
      traceId,
      queryText: correctedQuery?.completion || '',
      reported: false
    });
  };

  return {
    requestGptCorrection,
    cancelRequest,
    isRequestRunning,
    getCorrectedQueryText,
    discardCorrection,
    acceptCorrection
  };
};
