import {
  Alert,
  Button,
  ButtonGroup,
  Container,
  Flyout,
  Panel,
  Select,
  SelectOptionListItem,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { isDefined } from '@cp/common/protocol/Common';

import {
  backupPeriodValuesHours,
  backupRetentionValuesHours
} from '@cp/common/protocol/Instance';
import { parseHumanReadablePeriod } from '@cp/common/utils/DateTimeUtils';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { BackupConfiguration } from 'src/instance/instanceApiClient';
import { useInstanceController } from 'src/instance/instanceController';
import {
  convert24hTo12hTime,
  convertUTCToLocalTime
} from 'src/lib/formatters/dateTimeFormatter';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import styled from 'styled-components';

const ScheduleSelect = styled(Select)`
  label {
    width: 100%;
  }
`;
const StyledOptionsContainer = styled.div`
  max-height: 300px;
`;
export interface BackupsConfigurationFlyoutProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  backupConfiguration?: BackupConfiguration;
  organizationId: string;
  instanceId: string;
  refreshData: () => void;
}
type Tab = 'Frequency' | 'Scheduled';

export const BackupsConfigurationFlyout = ({
  isOpen,
  onOpenChange,
  backupConfiguration,
  organizationId,
  instanceId,
  refreshData
}: BackupsConfigurationFlyoutProps): ReactElement => {
  const hasManageBackupsPermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage-backups',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const { updateBackupConfiguration } = useInstanceController();
  const [selectedTab, setSelectedTab] = useState<Tab>('Frequency');
  const onTabChange = (tab: Tab): void => {
    setSelectedTab(tab);
  };

  const [selectedRetentionPeriod, setSelectedRetentionPeriod] = useState(
    backupConfiguration?.customBackupRetentionPeriod?.toString() ??
      backupConfiguration?.defaultBackupRetentionPeriod.toString()
  );
  const [selectedBackupPeriod, setSelectedBackupPeriod] = useState(
    backupConfiguration?.customBackupPeriod?.toString() ??
      backupConfiguration?.defaultBackupPeriod.toString()
  );
  const [selectedStartTime, setSelectedStartTime] = useState(
    backupConfiguration?.customBackupStartTime ?? '00:00'
  );

  useEffect(() => {
    setSelectedRetentionPeriod(
      backupConfiguration?.customBackupRetentionPeriod?.toString() ??
        backupConfiguration?.defaultBackupRetentionPeriod.toString()
    );
    setSelectedBackupPeriod(
      backupConfiguration?.customBackupPeriod?.toString() ??
        backupConfiguration?.defaultBackupPeriod.toString()
    );
    setSelectedStartTime(backupConfiguration?.customBackupStartTime ?? '00:00');

    const initialTab = backupConfiguration?.customBackupStartTime
      ? 'Scheduled'
      : 'Frequency';
    setSelectedTab(initialTab);
  }, [backupConfiguration]);

  const retentionOptions: SelectOptionListItem[] =
    backupRetentionValuesHours.map((value: number): SelectOptionListItem => {
      const { amount, unit } = parseHumanReadablePeriod(value);
      const label = `${amount} ${amount > 1 ? unit + 's' : unit}`;
      const valueString = value.toString();
      return {
        value: valueString,
        label,
        'data-testid': `retention-value-${valueString}`
      };
    });

  const periodOptions: SelectOptionListItem[] = backupPeriodValuesHours.map(
    (value: number): SelectOptionListItem => {
      const label = `${value} hours`;
      const valueString = value.toString();
      return {
        value: valueString,
        label,
        'data-testid': `period-value-${valueString}`
      };
    }
  );

  const hours: number[] = Array(24)
    .fill(null)
    .map((_, index) => index);
  const startTimeOptions: string[] = hours.map((hour): string => {
    const hourFormatted = hour.toString().padStart(2, '0');
    const value = `${hourFormatted}:00`;
    return value;
  });

  const onCancel = (): void => {
    // revert values (to open again clean)
    setSelectedRetentionPeriod(
      backupConfiguration?.customBackupRetentionPeriod?.toString() ??
        backupConfiguration?.defaultBackupRetentionPeriod.toString()
    );
    setSelectedBackupPeriod(
      backupConfiguration?.customBackupPeriod?.toString() ??
        backupConfiguration?.defaultBackupPeriod.toString()
    );
    setSelectedStartTime(backupConfiguration?.customBackupStartTime ?? '00:00');

    const tab = backupConfiguration?.customBackupStartTime
      ? 'Scheduled'
      : 'Frequency';
    setSelectedTab(tab);
  };

  const onSave = (): void => {
    if (isDefined(selectedBackupPeriod) && isDefined(selectedRetentionPeriod)) {
      const newBackupConfiguration = {
        customBackupPeriod:
          selectedTab === 'Frequency'
            ? parseInt(selectedBackupPeriod)
            : undefined,
        customBackupStartTime:
          selectedTab === 'Scheduled' ? selectedStartTime : undefined,
        customBackupRetentionPeriod: parseInt(selectedRetentionPeriod)
      };

      updateBackupConfiguration(
        organizationId,
        instanceId,
        newBackupConfiguration
      )
        .then(() => {
          refreshData();
        })
        .catch(console.log);
    }
  };

  return (
    <Flyout open={isOpen} onOpenChange={onOpenChange}>
      <Flyout.Content strategy="fixed">
        <Flyout.Header title="Backup configuration" description="" />
        <Flyout.Body
          align="top"
          alignItems={'center'}
          maxWidth="var(--flyout-width, 100%)"
        >
          <Spacer size="md" />
          <Flyout.Element>
            <Select
              value={selectedRetentionPeriod}
              label="Retention"
              options={retentionOptions}
              onSelect={setSelectedRetentionPeriod}
              data-testid={'retention-select'}
            />
            <Panel fillWidth={true} alignItems={'center'}>
              <ButtonGroup
                fillWidth={true}
                onClick={(tab: string): void => onTabChange(tab as Tab)}
                options={[
                  {
                    label: 'Frequency',
                    value: 'Frequency'
                  },
                  {
                    label: 'Scheduled',
                    value: 'Scheduled'
                  }
                ]}
                selected={selectedTab}
                type="default"
              />
              {selectedTab === 'Frequency' ? (
                <Select
                  value={selectedBackupPeriod}
                  label="Backup every"
                  options={periodOptions}
                  onSelect={setSelectedBackupPeriod}
                  data-testid={'backup-every-select'}
                />
              ) : (
                <>
                  <ScheduleSelect
                    value={selectedStartTime}
                    label={
                      <DetailedStartTimeLabel
                        label={`Start time ${convert24hTo12hTime(
                          selectedStartTime
                        )} (UTC) `}
                        value={selectedStartTime}
                      />
                    }
                    onSelect={setSelectedStartTime}
                    data-testid={'schedule-select'}
                  >
                    <StyledOptionsContainer>
                      {startTimeOptions.map((o) => (
                        <Select.Item
                          key={o}
                          value={o}
                          data-testid={`start-time-value-${o}`}
                        >
                          <DetailedStartTimeLabel
                            label={convert24hTo12hTime(o)}
                            value={o}
                          />
                        </Select.Item>
                      ))}
                    </StyledOptionsContainer>
                  </ScheduleSelect>
                  <Text color="muted">
                    Backups will start within an hour of specified start time
                    every 24 hours.
                  </Text>
                </>
              )}
            </Panel>
            <Separator size={'md'} />
            <Alert
              showIcon
              state="info"
              text="Increasing the frequency or retention of the backups might add to your monthly costs.
              You will be able to view more information about your backup costs on the cost breakdown page."
            />
          </Flyout.Element>
        </Flyout.Body>
        {hasManageBackupsPermission && (
          <Flyout.Footer data-testid="network-flyout-footer">
            <Flyout.Close label="Cancel" onClick={onCancel} />
            <Button
              onClick={onSave}
              disabled={
                !isDefined(selectedBackupPeriod) ||
                !isDefined(selectedRetentionPeriod)
              }
            >
              Save
            </Button>
          </Flyout.Footer>
        )}
      </Flyout.Content>
    </Flyout>
  );
};

interface DetailedStartTimeLabelProps {
  label: ReactNode;
  value: string;
}
const DetailedStartTimeLabel = ({
  label,
  value
}: DetailedStartTimeLabelProps) => (
  <Container
    component="span"
    justifyContent="space-between"
    isResponsive={false}
    fillWidth
  >
    <span>{label}</span>
    <span>{`${convertUTCToLocalTime(value)} Local`}</span>
  </Container>
);
