import { Container, createToast, Flyout } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import {
  DashboardConfig,
  DashboardObject,
  DashboardObjectConfig
} from 'shared/src/types/dashboard';
import { dashboardObjectConfigSchema } from 'shared/src/dashboard';
import ConfigSchema from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/ConfigSchema';
import { useUpdateDashboardConfig } from 'src/dashboard/dashboardController';

interface DashboardObjectConfigSidebarProps {
  dashboardId: string;
  dashboardConfig: DashboardConfig;
  dashboardObject?: DashboardObject;
  editingObjectId: string | null;
  onClose: () => void;
  open: boolean;
  updateDashboardObjectConfig: (
    config: DashboardObjectConfig
  ) => Promise<DashboardObjectConfig | null>;
}

export default function DashboardObjectConfigSidebar({
  dashboardId,
  dashboardConfig,
  dashboardObject,
  editingObjectId,
  onClose,
  open,
  updateDashboardObjectConfig
}: DashboardObjectConfigSidebarProps): ReactElement {
  const objectType = dashboardObject?.config.type;
  const schema = objectType
    ? dashboardObjectConfigSchema[objectType]?.schema
    : null;

  const updateDashboardConfig = useUpdateDashboardConfig();

  const deleteDashboardObject = (): void => {
    if (editingObjectId) {
      const newConfig = Object.assign({}, dashboardConfig);

      delete newConfig.dashboardObjectPositions[editingObjectId];
      delete newConfig.dashboardObjects[editingObjectId];

      const description = dashboardObject?.config?.elementId
        ? `${dashboardObject.config.elementId} has been deleted`
        : 'That element has been deleted';

      updateDashboardConfig(dashboardId, newConfig)
        .then(() => {
          createToast({
            type: 'success',
            title: 'Deleted',
            description,
            duration: 5_000
          });
          onClose();
        })
        .catch(console.error);
    }
  };

  return (
    <Flyout
      open={!!editingObjectId && open}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Flyout.Content
        strategy="fixed"
        size="wide"
        onClick={(e) => e.stopPropagation()} // prevent container from unselecting editing element
      >
        <Flyout.Header title="Edit" />
        <Flyout.Body>
          <Container orientation="vertical" padding="lg" minHeight="100%">
            {editingObjectId && dashboardObject && schema && (
              <ConfigSchema
                dashboardId={dashboardId}
                dashboardConfig={dashboardConfig}
                dashboardObjectConfig={dashboardObject.config}
                deleteDashboardObject={deleteDashboardObject}
                editingObjectId={editingObjectId}
                key={editingObjectId}
                schema={schema}
                updateDashboardConfig={updateDashboardObjectConfig}
              />
            )}
          </Container>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
}
