import {
  Alert,
  AutoComplete,
  AutoCompleteOptionListItem,
  Button,
  Container,
  IconButton,
  Title
} from '@clickhouse/click-ui';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IntegrationLogo } from 'src/components/IntegrationLogo/IntegrationLogo';
import LoadingPanel from 'src/components/LoadingPanel/LoadingPanel';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useGettingStarted } from 'src/instance/controller/useGettingStarted';
import useIntegrations from 'src/integrations/controller/useIntegrations';
import { IntegrationSummaryType } from 'src/integrations/controller/useIntegrationSummary';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { routes } from 'src/lib/routes';
import { RequestIntegrationDialog } from 'src/pages/IntegrationsPage/RequestIntegrationDialog';

interface IntegrationLayoutProps {
  children: ReactNode;
}

export const IntegrationLayout = ({
  children
}: IntegrationLayoutProps): ReactElement => {
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const { serviceId, integrationId } = useParams();
  const { integrations, isLoading, error } = useIntegrations();
  const { updateGettingStarted, visitedIntegration } = useGettingStarted();

  useEffect(() => {
    if (serviceId && !visitedIntegration) {
      updateGettingStarted({
        visitedIntegration: true
      });
    }
  }, []);

  const onSelect = (integrationId: string): void => {
    const integration = integrations.find((i) => i.slug === integrationId);
    if (integration && integration.openInNewWindow) {
      window.open(integration.docsLink, '_blank');
    } else {
      navigateTo(routes.integration({ serviceId, integrationId }));
    }
  };

  const navigateBack = (): void => {
    navigateTo(routes.integrations({ serviceId }));
  };

  const options: Array<AutoCompleteOptionListItem> = useMemo(
    () =>
      integrations.map((integration) => {
        return {
          id: integration.slug,
          value: integration.slug,
          label: (
            <Container isResponsive={false} gap="md">
              <IntegrationLogo
                integration={integration as IntegrationSummaryType}
                size="md"
              />
              <div>{integration.name}</div>
            </Container>
          )
        };
      }),
    [integrations]
  );

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar
        label={!integrationId ? 'Integrations' : undefined}
      >
        <Container gap="sm" justifyContent="end">
          {integrationId && (
            <Container orientation="horizontal" gap="md">
              <IconButton
                icon="chevron-left"
                size="sm"
                type="ghost"
                onClick={navigateBack}
              />
              <Title type="h2" onClick={navigateBack}>
                View all integrations
              </Title>
            </Container>
          )}
          <Container maxWidth="250px">
            <AutoComplete
              placeholder="Search integrations"
              onSelect={onSelect}
              disabled={isLoading || !!error}
              options={options}
            />
          </Container>
          <Button
            fillWidth={false}
            label="Request integration"
            onClick={() => setOpenRequestDialog((open) => !open)}
            data-testid="request-integration-button"
          />
          <RequestIntegrationDialog
            open={openRequestDialog}
            onOpenChange={setOpenRequestDialog}
          />
        </Container>
      </MainPaneLayout.Topbar>
      <MainPaneLayout.Content>
        {error && (
          <Container isResponsive={false} padding="md">
            <Alert text={String(error)} dismissible={false} />
          </Container>
        )}
        {isLoading && (
          <Container isResponsive={false} padding="md">
            <LoadingPanel
              fillWidth
              text={`Loading integration${integrationId ? '' : 's'}`}
            />
          </Container>
        )}
        {!isLoading && children}
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};
