import { IconButton, Select, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

import { SchemaColumn } from 'shared/src/clickhouse/types';
import { LogFn } from 'src/lib/logger';

import styles from 'src/components/TableView/DataView/Toolbar/Sort/styles';

type UpdateSortHandler = (event: {
  column?: string;
  direction?: string;
}) => void;

interface Props {
  column?: string;
  columns: Array<SchemaColumn>;
  updateSort: UpdateSortHandler;
  direction?: string;
  removeSort: () => void;
  logEvent: LogFn;
}

export default function SortOption({
  column,
  direction = 'ASC',
  removeSort,
  columns,
  updateSort,
  logEvent
}: Props): ReactElement {
  const onColumnChange = (value: string): void => {
    logEvent('columnSelectorSelect', 'click');
    updateSort({
      column: value
    });
  };

  const onSortChange = (value: string): void => {
    logEvent('orderBySelectorSelect', 'click');
    updateSort({
      direction: value
    });
  };

  return (
    <div css={styles.sortContainer}>
      <Select
        showSearch
        onSelect={onColumnChange}
        onFocus={(): void => logEvent('columnSelectorFocus', 'trigger')}
        onBlur={(): void => logEvent('columnSelectorBlur', 'trigger')}
        value={column}
        className="fs-exclude"
      >
        {columns.map((column) => (
          <Select.Item key={column.name} value={column.name}>
            <Text className="fs-exclude">{column.name}</Text>
            <Text data-hide-in-trigger color="muted">
              {column.type}
            </Text>
          </Select.Item>
        ))}
      </Select>
      <Select
        onSelect={onSortChange}
        onFocus={(): void => logEvent('orderBySelectorFocus', 'trigger')}
        onBlur={(): void => logEvent('orderBySelectorBlur', 'trigger')}
        value={direction}
      >
        <Select.Item value="ASC">Ascending</Select.Item>
        <Select.Item value="DESC">Descending</Select.Item>
      </Select>
      <IconButton
        size="sm"
        icon="cross"
        onClick={removeSort}
        className="sort-close"
      />
    </div>
  );
}
