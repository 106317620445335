import React, { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import { routes } from 'src/lib/routes';
import { useImportModel } from 'src/lib/dataLoading';
import { NewSampleDataImport } from 'shared/src/dataLoading';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import PanelCard from 'src/components/ImportView/ImportTypeSelector/PanelCard';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { Galaxy } from 'galaxy';

function SampleDataCard(): ReactElement | null {
  const { importModel, setImportModel } = useImportModel();

  if (!importModel) {
    return null;
  }

  const currentServiceId = getCurrentServiceIdOrFail();

  const setSampleDataImport = (): void => {
    const importId = importModel.id || uuid();
    const model: NewSampleDataImport = {
      id: importId,
      name: 'Sample Data',
      status: 'new',
      type: 'sample_data',
      data: {}
    };
    setImportModel(model);
    Galaxy.galaxy().track('dataLoading.importTypeContainer.sampleDataSelect', {
      interaction: 'click'
    });
    navigateTo(routes.import({ serviceId: currentServiceId, importId }));
  };

  return (
    <PanelCard
      title="Predefined sample data"
      iconName="light-bulb"
      onClick={setSampleDataImport}
      className="sampleDataCard"
    />
  );
}

export default React.memo(SampleDataCard);
