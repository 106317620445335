import { useCallback, useEffect, useRef, useMemo, useState } from 'react';

import downloadjs from 'downloadjs';
import { unparse } from 'papaparse';
import { formatDate } from 'src/lib/formatters/dateTimeFormatter';

import { emptyAsyncIterable } from 'src/lib/iterator';
import { useCacheQueryViewResults } from 'src/state/queryResults';

import { Range, OnRangeChangeArgs } from 'src/lib/query/RowCache/RowIndexes';
import { Row } from 'src/lib/query/RowCache/types';
import { RunningQueryColumn, RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';

import { useQueryColumns, useQueryStatus, useQueryTotals } from 'src/lib/query/QueryState/queryDetails';

import { RowCallback, useQueryStateContext } from 'src/lib/query/QueryState/';

export interface UseResultsViewArgs {
  queryId: string | null;
  filter?: string;
  startRow?: number;
  endRow?: number;
  name: string;
}

export type RowIteratorFunction = {
  (): Promise<AsyncIterable<Row>>;
  (start: number, end: number): Promise<AsyncIterable<Row>>;
  (indexes: Iterable<number>): Promise<AsyncIterable<Row>>;
};

export interface UseResultsViewResult {
  columns: RunningQueryColumn[] | undefined;
  status: RunningQueryStatusName;
  getRow: (index: number) => null | Row;
  eachRow: (callback: RowCallback) => Promise<void>;
  rowIterator: RowIteratorFunction;
  numRows: number;
  downloadResults: () => Promise<void>;
  filterFinished: boolean;
  totals?: Row;
}

interface ThrottledUpdateResult<T> {
  val: T;
  force: boolean;
}

type ThrottledUpdate<T> = ThrottledUpdateResult<T> | ((oldVal: T) => ThrottledUpdateResult<T>);
type ThrottledSetter<T> = (newVal: ThrottledUpdate<T>) => void;

function useThrottledState<T>(initialState: T | (() => T), timeout = 250): [T, ThrottledSetter<T>] {
  const [debouncedVal, setDebouncedVal] = useState<T>(initialState);
  const valRef = useRef(debouncedVal);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const setVal = useCallback<ThrottledSetter<T>>(
    (update) => {
      let result: ThrottledUpdateResult<T>;
      if (typeof update === 'function') {
        result = update(valRef.current);
      } else {
        result = update;
      }

      valRef.current = result.val;

      if (result.force) {
        setDebouncedVal(valRef.current);
      } else if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          setDebouncedVal(valRef.current);
          timeoutRef.current = undefined;
        }, timeout);
      }
    },
    [timeout]
  );

  return useMemo(() => {
    return [debouncedVal, setVal];
  }, [debouncedVal, setVal]);
}

export function useResultsView({
  queryId,
  filter = '',
  startRow: startRowProp,
  endRow: endRowProp,
  name
}: UseResultsViewArgs): UseResultsViewResult {
  const [cachedQueryViewResults, setCacheQueryViewResults] = useCacheQueryViewResults(queryId);

  const rangeRef = useRef<Range | null>(null);
  const { waitForRowStore, getRowStore } = useQueryStateContext();
  const [data, setData] = useThrottledState<null | OnRangeChangeArgs>(cachedQueryViewResults);
  const lastQueryIdRef = useRef<null | string>(null);

  const getRow = useCallback(
    (index: number): Row | null => {
      if (!data?.rows) {
        return null;
      } else {
        return data.rows.get(index) ?? null;
      }
    },
    [data?.rows]
  );

  const onChange = useCallback(
    (data: OnRangeChangeArgs) => {
      setData((oldVal) => {
        return {
          force: oldVal !== null && data.numRows > 0, // force update when we first get rows
          val: data
        };
      });
      setCacheQueryViewResults(data);
    },
    [setData, setCacheQueryViewResults]
  );

  useEffect(() => {
    return () => {
      waitForRowStore()
        .then((rowStore) => {
          if (rangeRef.current && lastQueryIdRef.current) {
            rowStore.unsubscribeToRange(lastQueryIdRef.current, rangeRef.current);
          }
        })
        .catch(console.error);
    };
  }, [waitForRowStore]);

  const marginSize = 500;

  const rowStore = getRowStore();
  const rowStoreRowNum = queryId && rowStore ? rowStore.queryNumRows(queryId) : 0;
  const numRowsReturn = data?.numRows ?? rowStoreRowNum;
  const startRow = startRowProp === undefined ? 0 : startRowProp;
  const endRow = endRowProp === undefined ? numRowsReturn : endRowProp;

  const pageStart = Math.max(0, startRow - marginSize);
  const pageEnd = Math.ceil(endRow + marginSize);

  if (!Number.isInteger(pageStart) || !Number.isInteger(pageEnd)) {
    console.error('Invalid page range', { pageStart, pageEnd });
  }

  useEffect(() => {
    const lastQueryId = lastQueryIdRef.current;
    lastQueryIdRef.current = queryId;

    waitForRowStore()
      .then((rowStore) => {
        if (lastQueryId && lastQueryId !== queryId && rangeRef.current) {
          rowStore.unsubscribeToRange(lastQueryId, rangeRef.current);
          rangeRef.current = null;
        }

        if (queryId) {
          if (rangeRef.current) {
            rangeRef.current = rowStore.updateSubscribedRange(queryId, rangeRef.current, pageStart, pageEnd, {
              onChange,
              filter
            });
          } else {
            rangeRef.current = rowStore.subscribeToRange(queryId, pageStart, pageEnd, { onChange, filter });
          }
        }
      })
      .catch(console.error);
  }, [waitForRowStore, onChange, pageStart, pageEnd, filter, queryId]);

  const eachRow = useCallback(
    async (rowCallback: (row: Row, i: number) => void): Promise<void> => {
      return waitForRowStore()
        .then((rowStore) => {
          if (queryId) {
            rowStore.eachRow(queryId, rowCallback).catch(console.error);
          }
        })
        .catch(console.error);
    },
    [waitForRowStore, queryId]
  );

  const rowIterator: RowIteratorFunction = useCallback<RowIteratorFunction>(
    async (...args: [] | [number, number] | [Iterable<number>]): Promise<AsyncIterable<Row>> => {
      const rowStore = await waitForRowStore();
      if (!queryId) {
        return emptyAsyncIterable();
      }
      if (args.length === 0) {
        return rowStore.rowIterator(queryId, rangeRef.current);
      } else if (args.length === 2) {
        const [start, end] = args;
        return rowStore.rowIterator(queryId, rangeRef.current, start, end);
      } else {
        const indexes = args[0];
        return rowStore.rowsByIndex(queryId, indexes, rangeRef.current);
      }
    },
    [waitForRowStore, queryId]
  );

  const columns = useQueryColumns(queryId);
  const status = useQueryStatus(queryId);

  const downloadResults = useCallback(async () => {
    const rows: Row[] = [];
    for await (const row of await rowIterator()) {
      rows.push(row);
    }
    if (rows && rows.length > 0 && columns) {
      //const rowData = Array.from(rows.values())
      const colNames = columns.map((col) => col.name);

      downloadjs(
        unparse([colNames, ...rows], { quotes: true }),
        `${name}_${formatDate(new Date())}.csv`.toLowerCase(),
        'text/csv'
      );
    }
  }, [columns, name, rowIterator]);

  const totals = useQueryTotals(queryId);

  return useMemo(
    () => ({
      columns,
      status,
      getRow,
      eachRow,
      rowIterator,
      numRows: numRowsReturn,
      filterFinished: data?.filterFinished ?? false,
      downloadResults,
      totals
    }),
    [columns, status, getRow, eachRow, rowIterator, numRowsReturn, downloadResults, data?.filterFinished, totals]
  );
}
