import { Position, editor, languages } from 'monaco-editor';
import React, { useEffect } from 'react';
import { ApiClient, useApiClient } from 'src/lib/controlPlane/client';
import { useIsInlineCodeCompletionEnabled } from 'src/lib/gpt/GptFeatureFlagHook';
import { useUserPreferencesController } from 'src/user/userPreferencesController';
import { Credential, useCredentials } from 'src/state/connection';

type GptCodeCopleteParams = {
  serviceId: string;
};

// Delay (msec) before sending the code completion request to the backend, after user stops typing
const CODE_COMPLETE_DELAY = 500;

// If current line ends with one of these characters, code completion will be triggered
const SEARCH_TRIGGER_CHARS = [
  '.',
  ',',
  '{',
  '(',
  ' ',
  '-',
  '_',
  '+',
  '-',
  '*',
  '=',
  '/',
  '?',
  '<',
  '>'
];

/**
 * Format code completion text generated by LLM into InlineCompletions required by Monaco editor
 * @param gptCompletion - code completion text generated by LLM
 * @returns InlineCompletions array required by Monaco editor
 */
export const getCompletionItems = (
  gptCompletion: string
): languages.InlineCompletions<languages.InlineCompletion> => {
  const completionItems: languages.InlineCompletions<languages.InlineCompletion> =
    {
      items: gptCompletion
        ? [
            {
              insertText: gptCompletion.trimStart()
            }
          ]
        : []
    };
  return completionItems;
};

/**
 * Extract the text from the Monaco editor and sends an API request to the backend for code completion.
 * @param model - Monaco editor model
 * @param position - Position of the cursor
 * @param resolve - Resolve function of the promise, should be called when the request is complete
 * @param api - API client instance
 * @param serviceId - Service id
 * @param credential - Credential object
 */
const sendApiRequest = (
  model: editor.ITextModel,
  position: Position,
  resolve: (
    value: languages.InlineCompletions<languages.InlineCompletion>
  ) => void,
  api: ApiClient,
  serviceId: string,
  credential: Credential
): void => {
  const codeBeforeCursor = model.getValueInRange({
    startLineNumber: 1,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: position.column
  });

  if (codeBeforeCursor.trim().length === 0) {
    // The edistor doesn't contain any code to complete
    resolve({ items: [] });
    return;
  }

  const currLineBeforeCursor = model.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: position.column
  });

  const lastChar = currLineBeforeCursor[currLineBeforeCursor.length - 1];
  const lastCharIsTrigger = SEARCH_TRIGGER_CHARS.includes(lastChar);
  const isLineEmpty = currLineBeforeCursor.trim() === '';
  if (!lastCharIsTrigger && !isLineEmpty) {
    // The line is being edited, do not trigger code completion
    return;
  }

  const codeAfterCursor = model.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: position.column,
    endLineNumber: model.getLineCount(),
    endColumn: model.getLineMaxColumn(model.getLineCount())
  });

  api
    .getGptInlineCodeCompletion({
      codeBeforeCursor,
      codeAfterCursor,
      serviceId: serviceId || '',
      credential
    })
    .then((result) => {
      resolve(getCompletionItems(result));
    })
    .catch((err) => {
      console.error('[getGptInlineCodeCompletion]', err);
      resolve({ items: [] });
    });
};

/**
 * Provides code completion for the Monaco editor
 * by sending an API request to the backend, after waitnig for CODE_COMPLETE_DELAY.
 * If a new request is made before the delay is over, the old request is ignored.
 * @param model - Monaco editor model
 * @param position - Position of the cursor
 * @param requestIdRef - Ref to the latest request id
 * @param api - API client instance
 * @param serviceId - Service id
 * @param credential - Credential object
 * @returns Promise with InlineCompletions array
 */
async function provideInlineCompletions(
  model: editor.ITextModel,
  position: Position,
  requestIdRef: React.MutableRefObject<number>,
  api: ApiClient,
  serviceId: string,
  credential: Credential
): Promise<languages.InlineCompletions<languages.InlineCompletion>> {
  return new Promise((resolve) => {
    const currentId = (requestIdRef.current += 1);
    requestIdRef.current = currentId;
    setTimeout(() => {
      if (currentId !== requestIdRef.current) {
        // New requests have been made, we should abandon this one
        resolve({ items: [] });
        return;
      }
      sendApiRequest(model, position, resolve, api, serviceId, credential);
    }, CODE_COMPLETE_DELAY);
  });
}

export function useGptInlineCodeCompletion({
  serviceId
}: GptCodeCopleteParams): void {
  // Use ref to keep track of the latest request id.
  // This is used to ignore the old requests when a new request is made
  const requestIdRef = React.useRef<number>(0);
  const api = useApiClient();
  const credential = useCredentials();

  // Check the feature flag
  const isInlineCodeCompetionFeatureFlagEnabled =
    useIsInlineCodeCompletionEnabled();
  // Check the user preference
  const { isGptInlineCodeCompletionEnabled } = useUserPreferencesController();

  useEffect(() => {
    if (
      !isInlineCodeCompetionFeatureFlagEnabled ||
      !isGptInlineCodeCompletionEnabled
    ) {
      // Do nothing if inline code completion is disabled
      return;
    }

    // Register the completion item provider for ClickHouse SQL language
    const provider = languages.registerInlineCompletionsProvider('chdb', {
      provideInlineCompletions: (model, position) =>
        provideInlineCompletions(
          model,
          position,
          requestIdRef,
          api,
          serviceId,
          credential
        ),
      freeInlineCompletions: () => {}
    });
    return () => {
      provider.dispose();
    };
  }, [
    api,
    credential,
    isGptInlineCodeCompletionEnabled,
    isInlineCodeCompetionFeatureFlagEnabled,
    serviceId
  ]);
}
