import {
  Badge,
  Container,
  Link as CUILink,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import {
  supportCasePriorityColor,
  supportCaseStatusColor,
  supportCaseStatusDisplay
} from 'shared/src/types/support';
import {
  formatMomentUptoWeek,
  formatTimestampWithSeconds
} from 'src/lib/formatters/dateTimeFormatter';
import { useGetServiceById } from 'src/state/service';
import {
  SupportCasePriority,
  SupportCaseStatus
} from '@cp/common/protocol/Support';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

interface Props {
  caseNumber: string;
  createdOn: string;
  lastModifiedDate: string;
  primaryClickHouseContactName?: string;
  priority: SupportCasePriority;
  status: SupportCaseStatus;
  serviceId?: string;
}

function CaseDetails({
  caseNumber,
  createdOn,
  lastModifiedDate,
  primaryClickHouseContactName,
  priority,
  status,
  serviceId
}: Props): ReactElement {
  const getServiceById = useGetServiceById();
  const service = getServiceById(serviceId ?? null);

  return (
    <Container
      orientation="vertical"
      gap="md"
      maxWidth="33%"
      alignItems="start"
    >
      <Title type="h3">Details</Title>
      <Container
        orientation="vertical"
        padding="none"
        gap="md"
        alignItems="start"
      >
        <div>
          <Text size="sm" color="muted">
            Case number
          </Text>
          <Text>{caseNumber}</Text>
        </div>
        <Container orientation="vertical">
          <Text size="sm" color="muted">
            Severity
          </Text>
          <Badge
            size="sm"
            text={capitalize(priority).replace('_', ' ')}
            state={supportCasePriorityColor[priority]}
          />
        </Container>
        <div>
          <Text size="sm" color="muted">
            Primary ClickHouse contact
          </Text>
          <Text>{primaryClickHouseContactName}</Text>
        </div>
        {service !== undefined && (
          <div>
            <Text size="sm" color="muted">
              Cloud service
            </Text>
            <CUILink to={`/services/${service?.id}/settings`} component={Link}>
              {service?.name}
            </CUILink>
          </div>
        )}
        <div>
          <Text size="sm" color="muted">
            Status
          </Text>
          <Badge
            size="sm"
            text={supportCaseStatusDisplay[status]}
            state={supportCaseStatusColor[status]}
          />
        </div>
        <div>
          <Text size="sm" color="muted">
            Created on
          </Text>
          <Text>{formatTimestampWithSeconds(createdOn)}</Text>
        </div>
        <div>
          <Text size="sm" color="muted">
            Last updated on
          </Text>
          <Text>{formatMomentUptoWeek(lastModifiedDate)}</Text>
        </div>
      </Container>
    </Container>
  );
}

export default CaseDetails;
