import { CardPrimary, Text, Container } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import NoDecorationLink from 'src/components/NoDecorationLink/NoDecorationLink';
import styled from 'styled-components';
interface EventCardProps {
  title: string;
  imgUrl: string;
  time: string;
  lessonCount: number;
  url: string;
}
const EventsCardContainer = styled(CardPrimary)`
  padding: 0;
  align-self: stretch;
`;
const Image = styled.img`
  height: 12rem;
  width: 100%;
  object-fit: cover;
  object-position: left;
`;

function EventsCard({
  url,
  imgUrl,
  title,
  time,
  lessonCount
}: EventCardProps): ReactElement {
  return (
    <EventsCardContainer>
      <NoDecorationLink to={url} target="_blank">
        <Image
          src={imgUrl}
          width={342}
          height={196}
          alt={`Image for ${title}`}
        />
        <Container
          orientation="vertical"
          padding="md"
          grow="1"
          justifyContent="space-between"
          minHeight="6.25rem"
        >
          <Text size="lg" weight="semibold">
            {title}
          </Text>
          <Text color="muted">
            {time} • {lessonCount} {lessonCount === 1 ? 'course' : 'courses'}
          </Text>
        </Container>
      </NoDecorationLink>
    </EventsCardContainer>
  );
}

export default EventsCard;
