import { languages } from 'monaco-editor';

import { conf, language } from 'src/lib/monaco-ch-language/clickhouse';

const CLICKHOUSE_LANGUAGE_ID = 'chdb';
export const init = () => {
  if (!languages.getLanguages().find((language) => language.id === CLICKHOUSE_LANGUAGE_ID)) {
    languages.register({
      id: CLICKHOUSE_LANGUAGE_ID,
      extensions: [],
      aliases: ['ClickHouse', 'clickhouse', 'ch']
    });
    languages.setMonarchTokensProvider(CLICKHOUSE_LANGUAGE_ID, language);

    languages.onLanguage(CLICKHOUSE_LANGUAGE_ID, () => {
      languages.setLanguageConfiguration(CLICKHOUSE_LANGUAGE_ID, conf);
    });
  }
};
