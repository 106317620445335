import { useCallback, useMemo } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { InstanceFeatureId } from '@cp/common/protocol/features';
import { isUuid } from '@cp/common/utils/ValidationUtils';

import { ServicesPerOrganization, Service } from 'src/lib/controlPlane/client';
import { useCurrentInstance, useCurrentOrganizationInstances } from 'src/instance/instanceController';
import { Instance } from '@cp/common/protocol/Instance';

const orgsWithServicesAtom = atom<ServicesPerOrganization[]>([]);

/**
 * React hook to get the organizations with their services.
 * @returns {ServicesPerOrganization[]} - The list of organizations with their services.
 */
export function useOrgs(): ServicesPerOrganization[] {
  return useAtomValue(orgsWithServicesAtom);
}

/**
 * React hook to set the organizations with their services.
 * @returns {Function} - A setter function that takes a list of organizations with their services.
 */
export function useSetOrgsWithServices(): (orgs: ServicesPerOrganization[]) => void {
  return useSetAtom(orgsWithServicesAtom);
}

export const extractServiceIdFromUrl = (url: string): string | null => {
  try {
    const { pathname } = new URL(url);
    const parts = pathname.split('/');
    parts.shift();

    for (let i = 0; i < parts.length - 1 && parts.length > 1; i++) {
      if (parts[i] === 'services' && parts[i + 1] && isUuid(parts[i + 1])) {
        return parts[i + 1];
      }
    }
  } catch (e) {
    return null;
  }

  return null;
};

export function getCurrentServiceId(): string | null {
  return extractServiceIdFromUrl(window.location.href);
}

export function getCurrentServiceIdOrFail(): string {
  const serviceId = getCurrentServiceId();
  if (!serviceId) {
    throw new Error('serviceID is not defined');
  }
  return serviceId;
}

export function useOrgIdFromServiceId(serviceId: string | null): string | null | undefined {
  const orgsWithServices = useAtomValue(orgsWithServicesAtom);
  const orgId = useMemo(() => {
    if (!serviceId) {
      return null;
    }

    const services = orgsWithServices
      .map((org) => {
        return org.services.map((service) => ({
          ...service,
          orgId: org.id
        }));
      })
      .flat();
    const service = services.find((service) => service.id === serviceId);

    return service?.orgId;
  }, [orgsWithServices, serviceId]);

  return orgId;
}

export function useOrgIdFromServiceIdOrFail(serviceId: string | null): string {
  const result = useOrgIdFromServiceId(serviceId);
  if (!result) {
    throw new Error('organizationId is not defined');
  }

  return result;
}

export function useGetServiceById(): (serviceId: string | null) => Instance | undefined {
  const instances = useCurrentOrganizationInstances();

  const getServiceById = useCallback(
    (serviceId: string | null): Instance | undefined => {
      if (!serviceId) {
        return undefined;
      }

      return instances.find((service) => service.id === serviceId);
    },
    [instances]
  );

  return getServiceById;
}

/**
 * Get the current service from a list of organizations with services.
 * @param {ServicesPerOrganization[]} orgsWithServices - The list of organizations with their services.
 * @returns {Service | undefined} - The current service or undefined if not found.
 */
export const getCurrentService = (orgsWithServices: ServicesPerOrganization[]): Service | undefined => {
  const serviceId = getCurrentServiceId();
  const services = orgsWithServices
    .map((org) =>
      org.services.map((service) => {
        service.organizationId = org.id;
        return service;
      })
    )
    .flat();
  const service = services.find((service) => service.id === serviceId);
  return service;
};

export function useServiceFeatureFlag(feature: InstanceFeatureId): boolean {
  const service = useCurrentInstance();
  const serviceFeatures = service?.features || [];
  const isServiceFeatureFlagEnabled = serviceFeatures.includes(feature);
  return isServiceFeatureFlagEnabled;
}
