import { VerticalStepper } from '@clickhouse/click-ui';
import { ClickPipeSourceType } from 'shared/src/dataLoading';
import IncomingData from 'src/components/ImportView/ClickPipesImportForm/IncomingData';
import ParseInformation from 'src/components/ImportView/ClickPipesImportForm/ParseInformation';
import CDCConfiguration from 'src/components/ImportView/ClickPipesImportForm/CDCConfiguration';
import ConfigureTables from 'src/components/ImportView/ClickPipesImportForm/ConfigureTables';

interface ClickhouseStepProps {
  step: number;
  gotoPage: (index: number) => () => void;
}

interface ClickhouseFormProps {
  step: number;
  gotoPage: (index: number) => () => void;
  clickpipeType: ClickPipeSourceType;
}

export function ClickhouseConfigurationSteps({
  step,
  gotoPage,
  clickpipeType
}: ClickhouseFormProps) {
  switch (clickpipeType.toString()) {
    case 'postgres':
      return <ClickpipesPostgresSteps step={step} gotoPage={gotoPage} />;
    default:
      return <ClickPipesDefaultSteps step={step} gotoPage={gotoPage} />;
  }
}

function ClickPipesDefaultSteps({ step, gotoPage }: ClickhouseStepProps) {
  return (
    <>
      <VerticalStepper.Step
        label="Incoming Data"
        status={step === 2 ? 'active' : step > 2 ? 'complete' : 'incomplete'}
        onClick={gotoPage(2)}
      >
        <IncomingData />
      </VerticalStepper.Step>
      <VerticalStepper.Step
        label="Parse Information"
        status={step === 3 ? 'active' : step > 3 ? 'complete' : 'incomplete'}
        onClick={gotoPage(3)}
      >
        <ParseInformation />
      </VerticalStepper.Step>
    </>
  );
}

function ClickpipesPostgresSteps({ step, gotoPage }: ClickhouseStepProps) {
  return (
    <>
      <VerticalStepper.Step
        label="Configure your ClickPipe"
        status={step === 2 ? 'active' : step > 2 ? 'complete' : 'incomplete'}
        onClick={gotoPage(2)}
      >
        <CDCConfiguration />
      </VerticalStepper.Step>

      <VerticalStepper.Step
        label="Configure Tables"
        status={step === 3 ? 'active' : step > 3 ? 'complete' : 'incomplete'}
        onClick={gotoPage(3)}
      >
        <ConfigureTables />
      </VerticalStepper.Step>
    </>
  );
}
