import { Config } from 'src/lib/config/';

export default (config: Config): Config => {
  config.env = 'staging';
  config.controlPlane.clientId = 'r8c81rpdrjrk0664dc81r0jj9';
  config.controlPlane.host = 'https://control-plane.clickhouse-staging.com';
  config.controlPlane.apiHost = 'https://control-plane.clickhouse-staging.com';
  config.controlPlane.redirectUri = 'https://console.clickhouse-staging.com/callback';
  config.features.passwordlessAuth = true;
  config.features.dataLoading = false;

  config.auth0Web = {
    domain: 'auth.control-plane.clickhouse-staging.com',
    clientId: 'DuUbfP5Vyh1yOhlaQA6NhIRgu0ZoUuCU',
    audience: 'control-plane-web',
    callbackPath: '/',
    auth0domain: 'ch-staging.us.auth0.com',
    databaseConnectionName: 'ControlPlaneUserDb',
    cookieDomain: '.clickhouse-staging.com'
  };

  config.websockets = {
    endpoint: 'wss://lq385weag8.execute-api.us-east-2.amazonaws.com/staging'
  };

  config.datadog = {
    rum: {
      applicationId: 'efb90891-235f-4961-9c9d-d4fd748dccf0',
      clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
      site: 'us3.datadoghq.com',
      service: 'console',
      env: 'staging',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: window._env.APP_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      startSessionReplayRecording: false
    },
    logs: {
      version: window._env.APP_VERSION,
      clientToken: 'pub3a6f788f34c62f6bbcdc950404bdf24c',
      site: 'us3.datadoghq.com',
      service: 'console',
      env: 'staging',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['error']
    }
  };
  config.fullStory = {
    orgId: 'o-1GR1DR-na1'
  };

  config.growthBook = {
    clientKey: 'sdk-KyNZpGjTJ7Qh1I'
  };

  config.tackle = {
    awsMarketplaceProdPageUrl: 'https://aws.amazon.com/marketplace/pp/prodview-smcfvv6qwjzzq',
    gcpMarketplaceProdPageUrl: 'https://console.cloud.google.com/marketplace/orders',
    azureMarketplaceProdPageUrl: 'https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBladeV2'
  };

  config.stripe = {
    publishableKey:
      'pk_test_51LN1yACMJ7CnQDwUGWtDH2nzAIZfDXFDGD88RaISBMNzqSKqRUxhgtNDB38zh3EDsafzcfukuUfW8pXeVRxI8Vei00x1NN3Fwo'
  };

  config.recaptcha = {
    siteKey: '6Ld4gI4hAAAAAAdMweM5HJA8ToExymNcOy7SE3Sm'
  };
  return config;
};
