import { RowPreview } from 'shared/src/dataLoading';

export function parseJsonDataFromString(jsonRawData: string): [RowPreview[][], string] {
  const jsonRows = jsonRawData
    .split('\n')
    .filter((row) => row.length > 0)
    .map((row) => {
      return JSON.parse(row);
    });
  const rows = jsonRows.map((row) =>
    Object.values(row).map((value) => {
      return typeof value === 'string' ? value : JSON.stringify(value);
    })
  );
  return [rows, jsonRawData];
}
