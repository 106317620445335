import { ReactElement } from 'react';
import { Container, TextField, InfoAlert } from '@clickhouse/click-ui';

type QueryEndpointsCorsFormProps = {
  allowedOrigins: string;
  setAllowedOrigins: (value: string) => void;
};

export function QueryEndpointsCorsForm({
  allowedOrigins,
  setAllowedOrigins
}: QueryEndpointsCorsFormProps): ReactElement {
  return (
    <Container orientation="vertical" gap="md">
      <InfoAlert
        text={
          <>
            You can configure CORS by specifying allowed domains. Leave this
            field empty to restrict access to backend servers only.
            <br />
            Example: my-domain.com, my-other-domain.com
          </>
        }
      />
      <TextField
        label="Allowed domains"
        data-testid="allowed-origin-input"
        value={allowedOrigins}
        onChange={(value: string): void => {
          setAllowedOrigins(value);
        }}
      />
    </Container>
  );
}
