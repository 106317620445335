import { Container } from '@clickhouse/click-ui';
import styled from 'styled-components';

export const MainSidebarContainer = styled(Container)`
  background: ${({ theme }) => theme.global.color.background.sidebar};
  transition: max-width 0.3s ease-in-out;
`;

export const SidebarElementsContainer = styled(Container)`
  gap: 2px;
  & > div {
    width: 100%;
  }
`;

export const RelativeContainer = styled(Container)`
  position: relative;
`;
