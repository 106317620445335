import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import ConnectWithContent from 'src/components/ServiceConnect/ConnectWithContent';
import CredentialsInfo from 'src/components/ServiceConnect/CredentialsInfo';
import {
  ProtocolProps,
  ServiceModalMySqlProtocolProps
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

export interface ServiceConnectProps {
  username: string;
  description?: string;
  mysqlProps: ServiceModalMySqlProtocolProps;
  nativesecure: ProtocolProps;
  https: ProtocolProps;
  onPasswordReset: () => Promise<void>;
  showDownload?: boolean;
  showContent?: boolean;
  dataWarehouseId?: string;
}

export interface ServiceConnectModalProps extends ServiceConnectProps {
  serviceName: string;
  open?: boolean;
  onClose?: () => void;
}

export const ServiceConnectContent = ({
  onPasswordReset,
  username,
  description,
  nativesecure,
  https,
  mysqlProps,
  showDownload,
  showContent = true,
  dataWarehouseId
}: ServiceConnectProps): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);

  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  return (
    <Container orientation="vertical">
      <Text color="muted">
        {description ??
          'Get started by creating a high-powered serverless ClickHouse database in minutes.'}
      </Text>
      <Spacer />
      <CredentialsInfo
        hasManagePermission={hasManageServicePermission}
        username={username}
        password={nativesecure.password}
        onPasswordReset={onPasswordReset}
        onTogglePassword={setShowPassword}
        showDownload={showDownload}
        dataWarehouseId={dataWarehouseId}
      />
      {showContent && (
        <>
          <Spacer />
          <ConnectWithContent
            showPassword={showPassword}
            https={https}
            mysqlProps={mysqlProps}
            username={username}
            nativesecure={nativesecure}
          />
        </>
      )}
    </Container>
  );
};

/**
 * The dialog containing various connection options for the service.
 */
export const ServiceConnectModal = ({
  open,
  onClose,
  onPasswordReset,
  serviceName,
  username,
  nativesecure,
  https,
  mysqlProps,
  showDownload,
  showContent,
  dataWarehouseId
}: ServiceConnectModalProps): ReactElement => {
  return (
    <Dialog open={open}>
      <Dialog.Content
        title={`Connect to ${serviceName}`}
        data-testid="service-connect-modal"
        showClose
        onClose={onClose}
      >
        <ServiceConnectContent
          onPasswordReset={onPasswordReset}
          username={username}
          nativesecure={nativesecure}
          https={https}
          mysqlProps={mysqlProps}
          showDownload={showDownload}
          showContent={showContent}
          dataWarehouseId={dataWarehouseId}
        />
        <Spacer />
        <Separator size="xs" />
        <Spacer />
        <Container justifyContent="end" isResponsive={false}>
          <Button
            label="Close"
            onClick={onClose}
            data-testid="service-connect-close-button"
            type="secondary"
          />
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
