import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';

interface ConfirmRemoveEndpointFromOrgAndServiceProps {
  endpointId: string | undefined;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ConfirmRemoveEndpointFromOrgAndService({
  endpointId,
  onClose,
  onConfirm
}: ConfirmRemoveEndpointFromOrgAndServiceProps): ReactNode {
  const instances = useCurrentOrganizationInstances();

  const instancesWithEndpoint = instances.filter(
    (instance) => endpointId && instance.privateEndpointIds.includes(endpointId)
  );

  return (
    <Dialog open={endpointId !== undefined}>
      <Dialog.Content
        title="Delete private endpoint?"
        data-testid="remove-private-endpoint-from-org-and-service-modal"
        showClose
        onClose={onClose}
      >
        <Text color="muted">
          By deleting this private endpoint, you will be removing it from the
          following services:
        </Text>
        <ul>
          {instancesWithEndpoint.map((instance) => (
            <li key={instance.id}>{instance.name}</li>
          ))}
        </ul>
        <Spacer />
        <Separator size="xs" />
        <Spacer />
        <Container justifyContent="end" isResponsive={false} gap="sm">
          <Button
            label="Cancel"
            onClick={onClose}
            data-testid="remove-private-endpoint-from-org-and-service-close-button"
            type="secondary"
          />
          <Button
            label="Delete endpoint"
            onClick={onConfirm}
            data-testid="remove-private-endpoint-from-org-and-service-confirm-button"
            type="danger"
          />
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}
