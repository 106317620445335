import { formatDataSize } from 'src/lib/formatters/numberFormatter';
import { MetricChartInput } from 'src/components/Metrics/MetricChart';
import { MetricSummaryProps } from 'src/components/Metrics/MetricSummary';

export type MetricChartSummaryInput = {
  metric: MetricChartInput;
  seriesData: Highcharts.SeriesOptionsType[];
};

export type MetricSummaryChartProps = {
  metricChartInput: MetricChartSummaryInput;
  summaryInput: MetricSummaryProps;
};

export const timePeriods = [
  { label: 'Last hour', value: 'Last hour' },
  { label: 'Last 24 hours', value: 'Last 24 hours' },
  { label: 'Last week', value: 'Last week' },
  { label: 'Last month', value: 'Last month' },
  { label: 'Last year', value: 'Last year' }
];

export function getDefaultMetric(): MetricSummaryChartProps {
  return {
    metricChartInput: {
      metric: {
        title: '',
        helpTooltipText: '',
        query: {}
      },
      seriesData: []
    },
    summaryInput: {
      summaryInput: {
        title: '',
        data: 0,
        dataFormat: '',
        summary: null,
        helpTooltipText: ''
      }
    }
  };
}

export function parseMetric(
  response: any,
  metric_name: string,
  time_period: string
): MetricSummaryChartProps {
  const metric = response.data;
  switch (metric_name) {
    case 'clickpipes_errors':
      const errors_metric: MetricChartInput = {
        title: '',
        helpTooltipText: 'This chart shows ClickPipe errors over time',
        tooltipDataSizePointFormat: 'human-readable-number',
        yAxisValueFormat: '{value}',
        query: { timePeriod: time_period },
        seriesOptions: [
          {
            type: 'area',
            name: 'Total errors',
            color: 'red'
          }
        ]
      };
      const errors_summaryInput: MetricSummaryProps = {
        summaryInput: {
          title: 'Total errors',
          data: metric.metrics[metric_name].sum,
          dataFormat: 'human-readable-number',
          summary: '',
          helpTooltipText:
            'This is the total errors ClickPipes caught during ingestion.'
        }
      };
      const errors_series_data: Highcharts.SeriesOptionsType[] = [
        {
          color: '#FF0000',
          type: 'area',
          name: 'Errors',
          data: metric.metrics[metric_name].chart_sum
        }
      ];
      return {
        metricChartInput: {
          metric: errors_metric,
          seriesData: errors_series_data
        },
        summaryInput: errors_summaryInput
      };
    case 'clickpipes_fetched_events':
      const fetched_events_metric: MetricChartInput = {
        title: '',
        helpTooltipText:
          'This chart shows the number of records received by the ClickPipe',
        tooltipDataSizePointFormat: 'human-readable-number',
        yAxisValueFormat: '{value}',
        query: { timePeriod: time_period },
        seriesOptions: [
          {
            type: 'area',
            name: 'Total received records',
            color: 'orange'
          }
        ]
      };
      const fetched_events_summaryInput: MetricSummaryProps = {
        summaryInput: {
          title: 'Total records',
          data: metric.metrics[metric_name].sum,
          dataFormat: 'human-readable-number',
          summary: '',
          helpTooltipText: 'This is the total records received by the ClickPipe'
        }
      };
      const fetched_events_series_data: Highcharts.SeriesOptionsType[] = [
        {
          color: '#FFC43D',
          type: 'area',
          name: 'Fetched events',
          data: metric.metrics[metric_name].chart_sum
        }
      ];
      return {
        metricChartInput: {
          metric: fetched_events_metric,
          seriesData: fetched_events_series_data
        },
        summaryInput: fetched_events_summaryInput
      };
    case 'clickpipes_sent_bytes_compressed':
      const sent_bytes_compressed_metric: MetricChartInput = {
        title: '',
        helpTooltipText:
          'This chart shows the number of bytes sent through the ClickPipe',
        tooltipDataSizePointFormat: 'bytes-letter',
        yAxisValueFormat: '{value}',
        yAxisValueFormatter: (value: number | string) => {
          if (typeof value === 'string') {
            return value;
          }
          return formatDataSize(value, 'binary-bytes-letter', 1);
        },
        query: { timePeriod: time_period },
        seriesOptions: [
          {
            type: 'area',
            name: 'Total sent bytes',
            color: 'green'
          }
        ]
      };
      const sent_bytes_compressed_summaryInput: MetricSummaryProps = {
        summaryInput: {
          title: 'Data Ingested',
          data: metric.metrics[metric_name].sum,
          dataFormat: 'bytes-letter',
          summary: '',
          helpTooltipText: 'This is the total bytes sent through the ClickPipe'
        }
      };
      const sent_bytes_compressed_series_data: Highcharts.SeriesOptionsType[] =
        [
          {
            color: '#5DA02E',
            type: 'area',
            name: 'Total bytes',
            data: metric.metrics[metric_name].chart_sum
          }
        ];
      return {
        metricChartInput: {
          metric: sent_bytes_compressed_metric,
          seriesData: sent_bytes_compressed_series_data
        },
        summaryInput: sent_bytes_compressed_summaryInput
      };
    case 'clickpipes_latency':
      const latency_metric: MetricChartInput = {
        title: '',
        helpTooltipText: 'This chart shows ClickPipe latency over time',
        tooltipDataSizePointFormat: 'human-readable-number',
        yAxisValueFormat: '{value}',
        query: { timePeriod: time_period },
        seriesOptions: [
          {
            type: 'area',
            name: 'Latency',
            color: 'blue'
          }
        ]
      };
      const latency_summaryInput: MetricSummaryProps = {
        summaryInput: {
          title: 'Latency (ms)',
          data: Math.trunc(metric.metrics[metric_name].avg),
          dataFormat: 'human-readable-number',
          summary: '',
          helpTooltipText: 'Average latency for this ClickPipe.'
        }
      };
      const latency_series_data: Highcharts.SeriesOptionsType[] = [
        {
          color: '#B5CDF9',
          type: 'area',
          name: 'Latency (ms)',
          data: metric.metrics[metric_name].chart_avg
        }
      ];
      return {
        metricChartInput: {
          metric: latency_metric,
          seriesData: latency_series_data
        },
        summaryInput: latency_summaryInput
      };
  }
  return getDefaultMetric();
}

export function getAggregationPeriod(period: string): string {
  switch (period) {
    case 'Last hour':
      return '1 minute';
    case 'Last 24 hours':
      return '5 minutes';
    case 'Last week':
      return '1 hour';
    case 'Last month':
      return '3 hours';
    case 'Last year':
      return '1 day';
    default:
      return '';
  }
}
