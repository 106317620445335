import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const placeholderParametersPrefix = 'param_';
export type UrlParamValues = Record<string, string>;

type UsePlaceholderParamsResult = [UrlParamValues, (newParams: UrlParamValues) => void];

/**
 * Converts placeholder parameters to a URL query string.
 * @param {UrlParamValues} placeholderParams - An object mapping placeholder parameter names to their values, e.g. { a: '1', b: '2' }.
 * @returns {string} A string representation of the placeholder parameters as URL query parameters, e.g. "param_a=1&param_b=2".
 */
export const placehoderParamsToUrlParams = (placeholderParams: UrlParamValues): string => {
  const searchParams = new URLSearchParams();
  Object.keys(placeholderParams).forEach((key) => {
    searchParams.set(`${placeholderParametersPrefix}${key}`, placeholderParams[key]);
  });
  return searchParams.toString();
};

/**
 * A React hook that manages URL parameters with a specific prefix (indicating they are placeholder parameters)
 * and provides a way to update them.
 * It separates placeholder parameters from other URL parameters and allows updating only
 * the placeholder parameters without affecting the others.
 */
export function usePlaceholderParams(): UsePlaceholderParamsResult {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const {
    placeholderParams, // Placehloder parameters are the ones that start with "param_"
    nonPlaceholderParams // Remaining parameters
  } = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const placeholderParams: UrlParamValues = {};
    const nonPlaceholderParams: UrlParamValues = {};
    searchParams.forEach((value, key) => {
      const matches = key.match(/^param_(.*)$/);
      if (matches) {
        placeholderParams[matches[1]] = value;
      } else {
        nonPlaceholderParams[key] = value;
      }
    });
    return { placeholderParams, nonPlaceholderParams };
  }, [search]);

  /**
   * Makes a new URL query string based on the provided parameters and navigates to the updated URL.
   * @param {UrlParamValues} newParams - An object mapping placeholder parameter names to their new values.
   */
  const setParams = useCallback(
    (newParams: UrlParamValues) => {
      // Convert the new placeholder parameters to a search string
      const placeholderParamsString = placehoderParamsToUrlParams(newParams);
      const updatedSearchParams = new URLSearchParams(placeholderParamsString);
      // Add non-placeholder parameters back to the search
      Object.keys(nonPlaceholderParams).forEach((key) => {
        updatedSearchParams.set(key, nonPlaceholderParams[key]);
      });
      navigate(
        {
          pathname: pathname,
          search: `?${updatedSearchParams.toString()}`
        },
        { replace: true }
      );
    },
    [navigate, pathname, nonPlaceholderParams]
  );

  return [placeholderParams, setParams];
}
