import { css } from '@emotion/react';

import { impossibleValue } from 'shared/src/impossibleValue';
import { PropsOf } from 'types/commonTypes';
import {
  ObjectStorageSource,
  KafkaSource,
  KinesisSource,
  PostgresSource
} from 'types/protocol';
import { Icon, EllipsisContent } from '@clickhouse/click-ui';
import React, { ReactElement } from 'react';

interface ClickPipeTypeProps {
  type:
    | KafkaSource['type']
    | ObjectStorageSource['type']
    | KinesisSource['type']
    | PostgresSource['type'];
}
type IconProps = PropsOf<typeof Icon>;
type ImageName = IconProps['name'];
type ClickPipeInfo = {
  name: string;
  icon: ImageName;
};

export function getClickPipeTypeName(
  type: ClickPipeTypeProps['type']
): ClickPipeInfo {
  if (type === 'azureeventhub') {
    return {
      name: 'Azure Event Hubs',
      icon: 'azure-event-hub'
    };
  } else if (type === 'confluent') {
    return {
      name: 'Confluent Cloud',
      icon: 'confluent'
    };
  } else if (type === 'gcs') {
    return {
      name: 'Google Cloud Storage',
      icon: 'gcs'
    };
  } else if (type === 'kafka') {
    return {
      name: 'Apache Kafka',
      icon: 'kafka'
    };
  } else if (type === 'kinesis') {
    return {
      name: 'Amazon Kinesis',
      icon: 'aws-kinesis'
    };
  } else if (type === 'msk') {
    return {
      name: 'Amazon MSK',
      icon: 'aws-msk'
    };
  } else if (type === 'postgres') {
    return {
      name: 'Postgres',
      icon: 'postgres'
    };
  } else if (type === 'redpanda') {
    return {
      name: 'Redpanda',
      icon: 'redpanda'
    };
  } else if (type === 's3') {
    return {
      name: 'Amazon S3',
      icon: 'aws-s3'
    };
  } else if (type === 'upstash') {
    return {
      name: 'Upstash Kafka',
      icon: 'upstash'
    };
  } else if (type === 'warpstream') {
    return {
      name: 'WarpStream',
      icon: 'warpstream'
    };
  }

  return impossibleValue(type);
}

const wrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  span: {
    paddingLeft: '3px'
  }
});

const ClickPipeType = ({ type }: ClickPipeTypeProps): ReactElement => {
  const { name, icon } = getClickPipeTypeName(type);

  return (
    <EllipsisContent css={wrapperStyle}>
      <div>
        <Icon size="sm" name={icon} />{' '}
      </div>
      <span>{name}</span>
    </EllipsisContent>
  );
};

export default React.memo(ClickPipeType);
