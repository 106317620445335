import { useAuth } from 'src/components/auth';
import { User } from 'src/lib/auth/types';

/**
 * A hook that returns the current user from the auth client.
 * The hook will re-render when the user changes.
 * @returns current user features or null
 */
export function useCurrentUser(): User | null {
  const { currentUser } = useAuth();
  return currentUser;
}

/**
 * A hook that returns the current user from the auth client.
 * The hook will re-render when the user changes.
 * @returns current user features or throws
 */
export function useCurrentUserOrThrow(): User {
  const { currentUser } = useAuth();
  if (!currentUser) {
    throw new Error('User is not defined!');
  }
  return currentUser;
}
