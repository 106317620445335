import { cloudProvidersAllowingPrivateEndpoints, Instance } from '@cp/common/protocol/Instance';
import { useEffect, useRef } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { InstancePrivateEndpointConfig } from 'src/instance/instanceApiClient';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

export function useInstancePrivateEndpointConfig(instance: Instance | undefined): InstancePrivateEndpointConfig | null {
  const apiClient = useApiClient();
  const {
    setPrivateEndpointConfig,
    instanceState: { privateEndpointConfigs }
  } = useInstanceStateManager();
  const hasViewPrivateEndpointsPermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:view-private-endpoints',
    useCurrentOrgUserRole() === 'ADMIN'
  );

  const instanceIdRef = useRef<string>();

  const privateEndpointConfig = instance ? privateEndpointConfigs[instance.id] ?? null : null;

  // If the current instance id changes and we have no private endpoint config,
  // fetch it
  useEffect(() => {
    if (
      instance?.id &&
      instanceIdRef.current !== instance.id &&
      !privateEndpointConfig &&
      hasViewPrivateEndpointsPermission &&
      cloudProvidersAllowingPrivateEndpoints.includes(instance.cloudProvider)
    ) {
      apiClient.instance
        .getPrivateEndpointConfig(instance.organizationId, instance.id)
        .then((config) => setPrivateEndpointConfig(instance.id, config))
        .catch((e) => console.error('Error getting private endpoint config', e));
    }
  }, [
    instance?.organizationId,
    instance?.id,
    instance?.cloudProvider,
    privateEndpointConfigs,
    setPrivateEndpointConfig,
    apiClient.instance,
    privateEndpointConfig,
    hasViewPrivateEndpointsPermission
  ]);

  return privateEndpointConfig;
}
