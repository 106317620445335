import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type SetterOrCallback<T> = (val: T | ((prev: T) => T), clean?: boolean) => void;
type Options = {
  type: 'string' | 'number' | 'boolean';
};

/**
 * Hook to store and set params in the URL search params. It returns the current value and a setter as `useStore` hook.
 * Currently supports string, number and boolean types.
 * @param key - search param key
 * @param defaultValue - default value
 * @param options - type of value, default is string
 */
export function useSearchParam(
  key: string,
  defaultValue: number,
  options: { type: 'number' }
): [number, SetterOrCallback<number>];
export function useSearchParam(
  key: string,
  defaultValue: boolean,
  options: { type: 'boolean' }
): [boolean, SetterOrCallback<boolean>];
export function useSearchParam(
  key: string,
  defaultValue: string,
  options: { type: 'string' }
): [string, SetterOrCallback<string>];
export function useSearchParam<T extends string | number | boolean>(
  key: string,
  defaultValue: T,
  options: Options = { type: 'string' }
): [T, SetterOrCallback<T>] {
  const [searchParams, setSearchParams] = useSearchParams();

  const valueFromParams = searchParams.get(key);
  let value: T;
  if (valueFromParams === null) {
    value = defaultValue;
  } else {
    switch (options.type) {
      case 'number':
        value = parseInt(valueFromParams, 10) as T;
        break;
      case 'boolean':
        value = (valueFromParams === 'true') as T;
        break;
      case 'string':
        value = valueFromParams as T;
        break;
    }
  }

  const setValue = useCallback(
    (val: T | ((prev: T) => T), clean: boolean = false) => {
      let newValue;
      // We use `window.location.search` instead of `searchParams`
      // @see https://github.com/remix-run/react-router/issues/8587
      const urlParams = new URLSearchParams(window.location.search);
      if (typeof val === 'function') {
        newValue = val(value);
      } else {
        newValue = val;
      }

      if (clean) {
        // Remove all keys from the URL and set the new value
        setSearchParams(new URLSearchParams({ [key]: newValue.toString() }));
      } else {
        urlParams.set(key, newValue.toString());
        setSearchParams(urlParams);
      }
    },
    [key, value, setSearchParams]
  );

  return [value, setValue];
}
