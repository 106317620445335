import { Container, ContainerProps, Icon, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

interface Props extends ContainerProps {
  text?: string;
}
const LoadingContainer = ({ text, ...props }: Props): ReactElement => {
  return (
    <Container
      orientation="vertical"
      isResponsive={false}
      fillHeight
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Icon name="horizontal-loading" size="xxl" />
      <Text size="lg" weight="semibold">
        {text ?? 'Loading'}
      </Text>
    </Container>
  );
};

export default LoadingContainer;
