import {
  Button,
  ButtonGroup,
  Container,
  Icon,
  Text,
  TextField,
  Title
} from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import { useOpenFilterConfigObjectFlyout } from 'src/dashboard/dashboardController';
import { Dashboard } from 'shared/src/types/dashboard';
import { Link } from 'react-router-dom';
import { routes } from 'src/lib/routes';
import { NewVisualizationButton } from 'src/components/Dashboards/NewVisualizationButton';
import { BorderBottomContainer } from 'src/components/Dashboards/DashboardView/styles';

type DashboardToolbarProps = {
  dashboard: Dashboard | null;
  editable?: boolean;
  serviceId: string;
  setEditable?: (value: boolean) => void;
  updateDashboardName: (
    dashboardId: string,
    dashboardName: string
  ) => Promise<Dashboard>;
};

interface EditGroupProps {
  editable?: boolean;
  isLoading?: boolean;
  setEditable?: (value: boolean) => void;
}

const EditGroup = ({
  editable,
  isLoading,
  setEditable
}: EditGroupProps): ReactNode => {
  if (isLoading || !setEditable) {
    return (
      <ButtonGroup
        onClick={() => {}}
        options={[
          { label: 'Viewing', value: 'viewing' },
          { label: 'Editing', value: 'editing' }
        ]}
        selected={editable ? 'editing' : 'viewing'}
      />
    );
  }

  return (
    <ButtonGroup
      onClick={(value: string): void => {
        setEditable(value === 'editing');
      }}
      options={[
        { label: 'Viewing', value: 'viewing' },
        { label: 'Editing', value: 'editing' }
      ]}
      selected={editable ? 'editing' : 'viewing'}
    />
  );
};

interface DashboardControlsProps {
  dashboard?: Dashboard;
  editable?: boolean;
  isLoading?: boolean;
}

function DashboardControls({
  dashboard,
  editable,
  isLoading
}: DashboardControlsProps): ReactNode {
  const openFilterConfig = useOpenFilterConfigObjectFlyout();

  if (isLoading || !dashboard) {
    return (
      <Container
        gap="sm"
        justifyContent="end"
        orientation="horizontal"
        padding="sm"
      >
        <Button disabled iconLeft="filter" type="secondary">
          Global filters
        </Button>
      </Container>
    );
  }

  return (
    <Container
      gap="sm"
      justifyContent="end"
      orientation="horizontal"
      padding="sm"
    >
      {editable && <NewVisualizationButton dashboard={dashboard} />}
      <Button iconLeft="filter" onClick={openFilterConfig} type="secondary">
        Global filters
      </Button>
    </Container>
  );
}

export function DashboardToolbar({
  dashboard,
  editable,
  serviceId,
  setEditable,
  updateDashboardName
}: DashboardToolbarProps): ReactNode {
  if (!dashboard) {
    return (
      <>
        <BorderBottomContainer alignItems="start" orientation="vertical">
          <Container
            gap="sm"
            justifyContent="space-between"
            orientation="horizontal"
            padding="sm"
          >
            <Link to={routes.dashboards({ serviceId })}>
              <Icon name="arrow-left" />
            </Link>
            <Container
              alignItems="center"
              justifyContent="end"
              gap="sm"
              fillWidth
            >
              <EditGroup
                editable={false}
                isLoading={true}
                setEditable={setEditable}
              />
            </Container>
          </Container>
        </BorderBottomContainer>
        <BorderBottomContainer alignItems="start" orientation="vertical">
          <DashboardControls isLoading />
        </BorderBottomContainer>
      </>
    );
  }

  const updateName = (dashboardName: string): void => {
    void updateDashboardName(dashboard.id, dashboardName);
  };

  return (
    <>
      <BorderBottomContainer alignItems="start" orientation="vertical">
        <Container
          gap="sm"
          justifyContent="space-between"
          orientation="horizontal"
          padding="sm"
        >
          <Link to={routes.dashboards({ serviceId })}>
            <Icon name="arrow-left" />
          </Link>
          <Container justifyContent="start">
            {editable ? (
              <TextField onChange={updateName} value={dashboard.name} />
            ) : (
              <Title type="h2" size="sm">
                {dashboard.name}
              </Title>
            )}
          </Container>
          <Container
            alignItems="center"
            justifyContent="end"
            gap="sm"
            fillWidth
          >
            <EditGroup editable={editable} setEditable={setEditable} />
          </Container>
        </Container>
      </BorderBottomContainer>
      <BorderBottomContainer alignItems="start" orientation="vertical">
        <DashboardControls dashboard={dashboard} editable={editable} />
      </BorderBottomContainer>
    </>
  );
}
