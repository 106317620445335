import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { useImportModel } from 'src/lib/dataLoading';
import { routes } from 'src/lib/routes';

import { useParams } from 'src/lib/routes/useParams';
import { alignCenter, flex, gapSm } from 'src/lib/utility-styles';
import {
  ClickPipeKafkaImport,
  isFileBasedImport,
  ClickPipesImportData,
  isClickPipesImport
} from 'shared/src/dataLoading';
import { getCurrentServiceId } from 'src/state/service';

import Preview from 'src/components/ImportView/DataSourcesTopBar/Preview';
import { IconButton } from '@clickhouse/click-ui';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { Draft } from 'immer';
import { BreadCrumbs } from 'src/components/Breadcrumbs/Breadcrumbs';
import { RelativeContainer } from 'src/layout/GlobalSidebarStyles';

const DataSourceContainer = RelativeContainer;

type Link =
  | { label: string; href: string; onClick: null }
  | { label: string; onClick: () => void; href: null };

const typeToName = {
  file_url: 'File URL',
  file_upload: 'File upload',
  sample_data: 'Sample data',
  kafka: 'Kafka',
  confluent: 'Confluent',
  msk: 'MSK',
  redpanda: 'Redpanda',
  upstash: 'Upstash',
  warpstream: 'WarpStream',
  azureeventhub: 'Azure Event Hubs',
  kinesis: 'Amazon Kinesis',
  s3: 'Amazon S3',
  gcs: 'Google Cloud Storage',
  postgres: 'Postgres CDC'
} as const;

const isImportableType = (
  importModel: unknown
): importModel is ClickPipesImportData => {
  return (importModel as ClickPipesImportData).step !== undefined;
};

export default function DataSourcesTopBar(): ReactElement | null {
  const { resetImportModel, importModel, updateImportModel } = useImportModel();
  const { importId } = useParams();
  const backFn = (): void => {
    const serviceId = getCurrentServiceId();
    resetImportModel();
    const route = serviceId ? routes.imports({ serviceId }) : routes.root();
    navigateTo(route);
  };

  if (!importId) {
    return null;
  }

  const resetToStart = () => {
    updateImportModel((draft: Draft<ClickPipeKafkaImport>) => {
      draft.data.step = 0;
    });
  };

  const links: Array<Link> = isClickPipesImport(importModel)
    ? [
        { label: 'Data sources', onClick: backFn, href: null },
        { label: 'ClickPipes', onClick: resetToStart, href: null }
      ]
    : [{ label: 'Data sources', onClick: backFn, href: null }];

  if (
    importId &&
    importModel.type &&
    (!isClickPipesImport(importModel) ||
      (isClickPipesImport(importModel) &&
        isImportableType(importModel.data) &&
        importModel.data.step > 0))
  ) {
    links.push({
      label: typeToName[importModel.type],
      href: '.',
      onClick: null
    });
  }

  return (
    <>
      <DataSourceContainer
        justifyContent="space-between"
        isResponsive={false}
        overflow="hidden"
        fillHeight
      >
        <div css={[flex, gapSm, alignCenter]}>
          <IconButton
            icon="chevron-left"
            size="sm"
            type="ghost"
            onClick={backFn}
          />

          <BreadCrumbs links={links} />
        </div>
        <div css={[flex, alignCenter]}>
          {isFileBasedImport(importModel) && importModel.data.tableConfig && (
            <Preview />
          )}
        </div>
      </DataSourceContainer>
    </>
  );
}
