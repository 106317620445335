import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

interface ErrorResponse {
  message: string;
}

export interface DeleteSavedQueryUserAccessArgs {
  queryId: string;
  serviceId: string;
  userId: string;
}

export interface UpdateSavedQueryGlobalAccessArgs {
  permissions: string;
  queryId: string;
  serviceId: string;
}

export interface UpdateSavedQueryUserAccessArgs {
  permissions: string;
  queryId: string;
  serviceId: string;
  userId: string;
}

export class SavedQueryAccessApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async deleteSavedQueryUserAccess({ queryId, serviceId, userId }: DeleteSavedQueryUserAccessArgs): Promise<void> {
    const res = await this.httpClient.destroy(
      `${config.apiUrl}/services/${serviceId}/queries/${queryId}/queryPermissions`,
      {
        body: JSON.stringify({
          userId
        })
      },
      { includeAuthProviderHeader: true }
    );

    if (res.status !== 200) {
      try {
        const body = await res.text();
        const json = JSON.parse(body) as ErrorResponse;
        throw new Error(json.message);
      } catch (e) {
        throw new Error('Unexpected error occurred. Please try again later.');
      }
    }
  }

  async updateSavedQueryGlobalAccess({
    permissions,
    queryId,
    serviceId
  }: UpdateSavedQueryGlobalAccessArgs): Promise<void> {
    const res = await this.httpClient.post(
      `${config.apiUrl}/services/${serviceId}/queries/${queryId}/queryPermissions`,
      {
        body: JSON.stringify({
          permissions,
          type: 'global'
        })
      },
      { includeAuthProviderHeader: true }
    );

    if (res.status !== 200) {
      try {
        const body = await res.text();
        const json = JSON.parse(body) as ErrorResponse;
        throw new Error(json.message);
      } catch (e) {
        throw new Error('Unexpected error occurred. Please try again later.');
      }
    }
  }

  async updateSavedQueryUserAccess({
    permissions,
    queryId,
    serviceId,
    userId
  }: UpdateSavedQueryUserAccessArgs): Promise<void> {
    const res = await this.httpClient.post(
      `${config.apiUrl}/services/${serviceId}/queries/${queryId}/queryPermissions`,
      {
        body: JSON.stringify({
          permissions,
          type: 'user',
          userId
        })
      },
      { includeAuthProviderHeader: true }
    );

    if (res.status !== 200) {
      try {
        const body = await res.text();
        const json = JSON.parse(body) as ErrorResponse;
        throw new Error(json.message);
      } catch (e) {
        throw new Error('Unexpected error occurred. Please try again later.');
      }
    }
  }
}
