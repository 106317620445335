import { GetAutoScalingLimitsResponse } from '@cp/common/protocol/AutoScaling';
import { ActiveMaintenanceKind, InstanceTier } from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { useCallback } from 'react';
import { useInstanceAutoScalingStateManager } from 'src/instance/instanceAutoScalingState';
import { useApiClient } from 'src/lib/controlPlane/client';
import { assertTruthy } from '@cp/common/utils/Assert';
import { createToast } from 'src/components/primitives';
import { useInstanceStateManager } from 'src/instance/instanceState';

export interface InstanceAutoScalingController {
  fetchAutoScalingSettings: (
    instanceId: string,
    instanceTier: InstanceTier,
    instanceCustomAutoScaleValues: Array<number>,
    regionId: RegionId,
    isCustomValuesFeatureEnabled: boolean,
    isPayingOrg: boolean,
    isByocOrg: boolean,
    isDisableAutoscalingFeatureEnabled: boolean,
    activeMaintenanceKind: ActiveMaintenanceKind | undefined
  ) => Promise<void>;

  fetchNewInstanceAutoScalingSettings: (
    instanceId: string,
    instanceTier: InstanceTier,
    instanceCustomAutoScaleValues: Array<number>,
    regionId: RegionId,
    isCustomValuesFeatureEnabled: boolean,
    isPayingOrg: boolean,
    isByocOrg: boolean,
    isDisableAutoscalingFeatureEnabled: boolean,
    activeMaintenanceKind: ActiveMaintenanceKind | undefined
  ) => Promise<void>;

  saveMemoryAutoScalingSettings: (instanceId: string, canBeHorizontallyScaled: boolean) => Promise<void>;

  saveIdleTimeoutAutoScalingSettings: (instanceId: string) => Promise<void>;

  updateMinMemory: (minTotalMemory: number, instanceId: string) => void;

  updateMaxMemory: (maxTotalMemory: number, instanceId: string) => void;

  updateNumReplicas: (minReplicas: number, maxReplicas: number, instanceId: string) => void;

  cancelMemoryChanges: (instanceId: string) => void;

  cancelIdleTimeoutChanges: (instanceId: string) => void;
}

export const useInstanceAutoScalingController = (): InstanceAutoScalingController => {
  const apiClient = useApiClient();
  const { instances } = useInstanceStateManager();

  const getOrganizationIdFromInstanceId = useCallback(
    (instanceId: string): string => instances[instanceId]?.organizationId,
    [instances]
  );

  const {
    autoScalingState,
    computeInstanceAutoScaling,
    setInstanceMemoryAutoScalingSavedSuccessfully,
    setInstanceIdleTimeoutAutoScalingSavedSuccessfully,
    updateInstanceMaxMemoryAutoScaling,
    updateInstanceMinMemoryAutoScaling,
    updateInstanceNumReplicas,
    cancelMemoryInstanceAutoScalingChanges,
    cancelIdleTimeoutInstanceAutoScalingChanges,
    setInstanceAutoScalingIsLoading
  } = useInstanceAutoScalingStateManager();

  const getRegionAutoScalingLimits = useCallback(
    async (regionId: RegionId): Promise<GetAutoScalingLimitsResponse> => {
      return apiClient.instance.getRegionAutoScalingLimits({ regionId });
    },
    [apiClient]
  );
  // When creating a custom hook, it's important to memoize the return value so that the hook does not cause unnecessary re-renders.
  // https://reactjs.org/docs/hooks-custom.html#useyourimagination
  // On this case we would always return a new object defined whenever this hook is called and whenever apiClient changes.
  // By wrapping the return value in useCallback, we ensure that the object is only created once and only when apiClient changes for all clients of this hook.
  const fetchAutoScalingSettings = useCallback(
    async (
      instanceId: string,
      instanceTier: InstanceTier,
      instanceCustomAutoScaleValues: Array<number> = [],
      regionId: RegionId,
      isCustomValuesFeatureEnabled: boolean,
      isPayingOrg: boolean,
      isByocOrg: boolean,
      isDisableAutoscalingFeatureEnabled: boolean,
      activeMaintenanceKind: ActiveMaintenanceKind | undefined
    ): Promise<void> => {
      try {
        const settings = await apiClient.instance.getAutoScalingSettings({
          instanceId
        });
        const limits = await getRegionAutoScalingLimits(regionId);

        computeInstanceAutoScaling({
          instanceId,
          instanceTier,
          instanceCustomAutoScaleValues,
          regionId,
          isCustomValuesFeatureEnabled,
          isPayingOrg,
          isByocOrg,
          autoScalingSettings: settings,
          limits,
          isDisableAutoscalingFeatureEnabled,
          activeMaintenanceKind
        });
      } catch (e) {
        createToast('Error', 'alert', 'Error while trying to retrieve the auto scaling settings');
      }
    },
    [apiClient.instance, getRegionAutoScalingLimits, computeInstanceAutoScaling]
  );

  const fetchNewInstanceAutoScalingSettings = useCallback(
    async (
      instanceId: string,
      instanceTier: InstanceTier,
      instanceCustomAutoScaleValues: Array<number> = [],
      regionId: RegionId,
      isCustomValuesFeatureEnabled: boolean,
      isPayingOrg: boolean,
      isByocOrg: boolean,
      isDisableAutoscalingFeatureEnabled: boolean,
      activeMaintenanceKind: ActiveMaintenanceKind | undefined
    ): Promise<void> => {
      try {
        const limits = await getRegionAutoScalingLimits(regionId);

        computeInstanceAutoScaling({
          instanceId,
          instanceTier,
          instanceCustomAutoScaleValues,
          regionId,
          isCustomValuesFeatureEnabled,
          isPayingOrg,
          isByocOrg,
          limits,
          isDisableAutoscalingFeatureEnabled,
          activeMaintenanceKind
        });
      } catch (e) {
        createToast('Error', 'alert', 'Error while trying to retrieve the auto scaling settings for the new service');
      }
    },
    [getRegionAutoScalingLimits, computeInstanceAutoScaling]
  );

  const saveMemoryAutoScalingSettings = useCallback(
    async (instanceId: string, canBeHorizontallyScaled: boolean): Promise<void> => {
      setInstanceAutoScalingIsLoading({ instanceId, isLoading: true });
      const autoScalingSettings = autoScalingState[instanceId];
      try {
        assertTruthy(
          autoScalingSettings.maxAutoScalingTotalMemory &&
            autoScalingSettings.minAutoScalingTotalMemory &&
            autoScalingSettings.originIdleTimeoutMinutes &&
            (!canBeHorizontallyScaled || (autoScalingSettings.minReplicas && autoScalingSettings.maxReplicas)),
          'Invalid autoscaling settings'
        );

        await apiClient.instance.saveAutoScalingSettings({
          maxAutoScalingTotalMemory: autoScalingSettings.maxAutoScalingTotalMemory,
          minAutoScalingTotalMemory: autoScalingSettings.minAutoScalingTotalMemory,
          maxReplicas: canBeHorizontallyScaled ? autoScalingSettings.maxReplicas : undefined,
          minReplicas: canBeHorizontallyScaled ? autoScalingSettings.minReplicas : undefined,
          enableIdleScaling: autoScalingSettings.originEnableIdleScaling,
          idleTimeoutMinutes: autoScalingSettings.originIdleTimeoutMinutes,
          instanceId,
          organizationId: getOrganizationIdFromInstanceId(instanceId)
        });

        setInstanceMemoryAutoScalingSavedSuccessfully({
          instanceId
        });

        createToast('Auto scaling saved', 'success', 'Auto scaling settings saved successfully');
      } catch (e) {
        createToast('Error', 'alert', 'Error while trying to save the auto scaling settings');
      } finally {
        setInstanceAutoScalingIsLoading({ instanceId, isLoading: false });
      }
    },
    [
      apiClient.instance,
      autoScalingState,
      getOrganizationIdFromInstanceId,
      setInstanceMemoryAutoScalingSavedSuccessfully,
      setInstanceAutoScalingIsLoading
    ]
  );

  const saveIdleTimeoutAutoScalingSettings = useCallback(
    async (instanceId: string): Promise<void> => {
      setInstanceAutoScalingIsLoading({ instanceId, isLoading: true });
      const autoScalingSettings = autoScalingState[instanceId];
      try {
        assertTruthy(autoScalingSettings.idleTimeoutMinutes !== undefined, 'Idle time out must be defined');
        await apiClient.instance.saveAutoScalingSettings({
          enableIdleScaling: autoScalingSettings.enableIdleScaling,
          idleTimeoutMinutes: autoScalingSettings.idleTimeoutMinutes,
          instanceId,
          organizationId: getOrganizationIdFromInstanceId(instanceId)
        });

        setInstanceIdleTimeoutAutoScalingSavedSuccessfully({
          instanceId
        });
        createToast('Auto scaling settings saved', 'success', 'Auto scaling idling settings saved successfully');
      } catch (e) {
        createToast('alert', 'alert', 'Error while trying to save the auto scaling idling settings');
      } finally {
        setInstanceAutoScalingIsLoading({ instanceId, isLoading: false });
      }
    },
    [
      apiClient.instance,
      autoScalingState,
      getOrganizationIdFromInstanceId,
      setInstanceAutoScalingIsLoading,
      setInstanceIdleTimeoutAutoScalingSavedSuccessfully
    ]
  );

  const updateMinMemory = useCallback(
    (minAutoScalingTotalMemory: number, instanceId: string): void => {
      updateInstanceMinMemoryAutoScaling({
        minAutoScalingTotalMemory,
        instanceId
      });
    },
    [updateInstanceMinMemoryAutoScaling]
  );

  const updateMaxMemory = useCallback(
    (maxAutoScalingTotalMemory: number, instanceId: string): void => {
      updateInstanceMaxMemoryAutoScaling({
        maxAutoScalingTotalMemory,
        instanceId
      });
    },
    [updateInstanceMaxMemoryAutoScaling]
  );

  const updateNumReplicas = useCallback(
    (minReplicas: number, maxReplicas: number, instanceId: string): void => {
      updateInstanceNumReplicas({ minReplicas, maxReplicas, instanceId });
    },
    [updateInstanceNumReplicas]
  );

  const cancelMemoryChanges = (instanceId: string): void => cancelMemoryInstanceAutoScalingChanges({ instanceId });

  const cancelIdleTimeoutChanges = (instanceId: string): void =>
    cancelIdleTimeoutInstanceAutoScalingChanges({ instanceId });

  return {
    fetchNewInstanceAutoScalingSettings,
    fetchAutoScalingSettings,
    saveMemoryAutoScalingSettings,
    saveIdleTimeoutAutoScalingSettings,
    updateMinMemory,
    updateMaxMemory,
    updateNumReplicas,
    cancelMemoryChanges,
    cancelIdleTimeoutChanges
  };
};
