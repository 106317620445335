import { ClickhouseLoading } from 'primitives';
import React, { ReactElement, Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes as ReactRoutes, useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/components/auth';
import { ClickUIWrapper } from 'src/components/ClickUIWrapper/ClickUIWrapper';
import OrganizationProvider from 'src/components/OrganizationProvider';
import RequiresCurrentServiceGuard from 'src/components/ServiceProvider';
import { extractPathNameAndQuery } from 'src/lib/routes/utils';
import { CreateNewServicePage } from 'src/pages/CreateNewServicePage/CreateNewServicePage';
import EmailVerificationErrorPage from 'src/pages/EmailVerificationErrorPage/EmailVerificationErrorPage';
import OrganizationBillingPage from 'src/pages/OrganizationBillingPage/OrganizationBillingPage';
import OrganizationDetailsPage from 'src/pages/OrganizationDetailsPage/OrganizationDetailsPage';
import OrganizationUsagePage from 'src/pages/OrganizationUsagePage/OrganizationUsagePage';
import ServiceListPage from 'src/pages/ServiceListPage/ServiceListPage';
import TermsOfServicePage from 'src/pages/TermsOfServicePage/TermsOfServicePage';
import { AuthenticatedAppGuard } from 'src/routeGuards/AuthenticatedAppGuard';
import { UsagePageFeatureGuard } from 'src/routeGuards/UsagePageFeatureGuard';
import OrgLayout from 'src/layout/OrgLayout';
import ApiKeysPage from 'src/pages/ApiKeysPage';
import BackupsPage from 'src/pages/BackupsPage/BackupsPage';
import CreateTablePage from 'src/pages/CreateTablePage/CreateTablePage';
import HomePage from 'src/pages/HomePage/HomePage';
import ImportPage from 'src/pages/ImportPage/ImportPage';
import ImportsPage from 'src/pages/ImportsPage/ImportsPage';
import IntegrationsPage from 'src/pages/IntegrationsPage/IntegrationsPage';
import { IntegrationSummaryPage } from 'src/pages/IntegrationsPage/IntegrationSummary';
import LearnPage from 'src/pages/LearnPage/LearnPage';
import MarketplaceOnboarding from 'src/pages/MarketplaceOnboarding';
import MembersPage from 'src/pages/MembersPage';
import MonitoringPage from 'src/pages/MonitoringPage/MonitoringPage';
import { QueryInsightsPage } from 'src/pages/MonitoringPage/QueryInsightsPage';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';
import ProfilePage from 'src/pages/ProfilePage';
import QueryPage from 'src/pages/QueryPage/QueryPage';
import SecurityPage from 'src/pages/SecurityPage';
import SettingsPage from 'src/pages/SettingsPage/SettingsPage';
import SupportCasePage from 'src/pages/SupportCasePage/SupportCasePage';
import SupportListPage from 'src/pages/SupportListPage/SupportListPage';
import SupportNewCasePage from 'src/pages/SupportNewCasePage/SupportNewCasePage';
import OnBoardPage from 'src/pages/OnBoardPage/OnBoardPage';

import TablePage from 'src/pages/TablePage/TablePage';
import { CheckImportGuard } from 'src/routeGuards/CheckImportGuard';
import { RedirectConsolePathRouteGuard } from 'src/routeGuards/RedirectConsolePathRouteGuard';
import { ServiceLayoutGuard } from 'src/routeGuards/ServiceLayoutGuard';
import { UnifiedConsoleFeatureGuard } from 'src/routeGuards/UnifiedConsoleFeatureGuard';
import { QueryInsightsLayout } from 'src/routeGuards/QueryInsightsLayout';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { ApproveOrgInvitationsPage } from 'src/pages/ApproveOrgInvitationsPage/ApproveOrgInvitationsPage';
import styled from 'styled-components';
import UnifiedConsoleGuard from 'src/routeGuards/UnifiedConsoleGuard';
import SqlConsoleLayout from 'src/layout/SqlConsoleLayout';
import { DashboardsGuard } from 'src/routeGuards/DashboardsGuard';
import DataSourcesLayout from 'src/layout/DataSourcesLayout';
import ServiceLayout from 'src/layout/ServiceLayout';
import { OrganizationPrivateEndpointsPage } from 'src/pages/PrivateEndpointsPage';
import GettingStartedPage from 'src/pages/GettingStartedPage/GettingStartedPage';
import NotificationsPage from 'src/pages/NotificationsPage/NotificationsPage';
import { ByocDatabaseAccessGuard } from 'src/routeGuards/ByocDatabaseAccessGuard';
import OrgAuditPage from 'src/pages/OrgAuditPage/OrgAuditPage';
import { ByocOnboarding } from 'src/pages/ByocOnboarding';
import { DashboardPage } from 'src/pages/Dashboard/DashboardPage';
import { DashboardListPage } from 'src/pages/Dashboard/DashboardListPage';
import { DashboardLayout } from 'src/components/Dashboards/DashboardLayout';

const RoutesContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const LoginRedirect = (): ReactElement | null => {
  const { loginWithRedirect, isAuthenticated, loading } = useAuth();
  const [params] = useSearchParams();

  useEffect(() => {
    const url = extractPathNameAndQuery(params.get('redirectTo') ?? '/');
    const connection = params.get('connection') || undefined;
    if (!loading && !isAuthenticated) {
      void loginWithRedirect({ url, connection });
    } else {
      navigateTo(url);
    }
  }, [isAuthenticated, loading, loginWithRedirect, params]);

  return null;
};

const SignupRedirect = (): null => {
  const { signupWithRedirect } = useAuth();
  const [params] = useSearchParams();

  useEffect(() => {
    const url = params.get('redirectTo') ?? '/';
    void signupWithRedirect(extractPathNameAndQuery(url));
  }, [signupWithRedirect]);

  return null;
};

const LoadingPage = (): ReactElement => {
  return <ClickhouseLoading isFullScreen />;
};

const UnauthenticatedApp = (): ReactElement => (
  <ClickUIWrapper>
    <Outlet />
  </ClickUIWrapper>
);

const Routes = (): ReactElement => {
  return (
    <RoutesContainer>
      <ReactRoutes>
        {/* Authenticated routes */}
        <Route element={<AuthenticatedAppGuard />}>
          <Route path="/callback" element={<LoadingPage />} />
          <Route element={<UnifiedConsoleFeatureGuard />}>
            <Route element={<OrgLayout />}>
              <Route path="/profile" element={<ProfilePage />} id="profilePage" />
              <Route path="/security" element={<SecurityPage />} id="securityPage" />
              <Route element={<OrganizationProvider />}>
                <Route path="organizations/:organizationId">
                  <Route index element={<OrganizationDetailsPage />} id="organizationDetailsPage" />
                  <Route path="admin" element={<Navigate to=".." />} id="organizationDetailsPageRedirect" />
                  <Route element={<UsagePageFeatureGuard />} errorElement={<NotFoundPage />}>
                    <Route path="admin/usage" element={<Navigate to="../usage" />} id="organizationUsagePageRedirect" />
                    <Route path="usage" element={<OrganizationUsagePage />} id="organizationUsagePage" />
                  </Route>
                  <Route errorElement={<NotFoundPage />}>
                    <Route path="admin/billing" element={<Navigate to="../billing" />} id="organizationBillingPageRedirect" />
                    <Route path="billing" element={<OrganizationBillingPage />} id="organizationBillingPage" />
                  </Route>
                  <Route path="activity" element={<Navigate to="../audit" />} id="organizationActivityPageRedirect" />
                  <Route path="audit" element={<OrgAuditPage />} id="organizationAuditPage" />

                  <Route path="notifications" element={<NotificationsPage />} />
                  <Route path="keys" element={<ApiKeysPage />} id="apiKeysPage" />
                  <Route path="members" element={<MembersPage />} id="membersPage" />
                  <Route path="private-endpoints" element={<OrganizationPrivateEndpointsPage />} id="privateEndpointsPage" />
                </Route>
              </Route>
            </Route>
            <Route path="marketplaceOnboarding" element={<MarketplaceOnboarding />} id="marketplaceOnboarding" />
            <Route path="/byocOnboarding" element={<ByocOnboarding />} id="byocOnboarding" />
          </Route>
          <Route element={<RequiresCurrentServiceGuard />} path="/services/:serviceId" errorElement={<NotFoundPage />}>
            <Route element={<ByocDatabaseAccessGuard />}>
              <Route element={<UnifiedConsoleGuard />}>
                <Route element={<SqlConsoleLayout />}>
                  <Route path="console">
                    <Route index element={<HomePage />} />
                    <Route path="database">
                      <Route path=":databaseName/table/:tableName" element={<TablePage />} />
                      {/* This route is a hack to deal with the bad resolution of these routes whenever tableName contains all special chars*/}
                      <Route path="*" element={<TablePage />} />
                    </Route>
                    <Route path="query/:queryId" element={<QueryPage />} />
                    <Route path="createTable" element={<CreateTablePage />} />
                  </Route>
                </Route>

                <Route element={<RedirectConsolePathRouteGuard />}>
                  <Route index element={<HomePage />} />
                  <Route path="database">
                    <Route path=":databaseName/table/:tableName" element={<TablePage />} id="tables" />
                    <Route path="*" element={<TablePage />} id="tables" />
                  </Route>
                  <Route path="query/:queryId" element={<QueryPage />} id="query" />
                  <Route path="createTable" element={<CreateTablePage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="imports" element={<CheckImportGuard />}>
                  <Route
                    index
                    element={
                      <DataSourcesLayout requiresMetadata={false}>
                        <ImportsPage />
                      </DataSourcesLayout>
                    }
                    id="imports"
                  />
                  <Route
                    path=":importId"
                    element={
                      <DataSourcesLayout requiresMetadata>
                        <ImportPage />
                      </DataSourcesLayout>
                    }
                    id="import"
                  />
                </Route>
              </Route>
            </Route>

            <Route element={<UnifiedConsoleFeatureGuard />}>
              <Route element={<ServiceLayout />}>
                <Route element={<DashboardsGuard />}>
                  <Route id="dashboards" path="dashboards" element={<DashboardLayout />}>
                    <Route index={true} element={<DashboardListPage />} />
                    <Route id="dashboardDetail" path=":dashboardId" element={<DashboardPage />} />
                  </Route>
                </Route>
                <Route path="settings" element={<SettingsPage />} id="serviceSettings" />
                <Route path="integrations">
                  <Route index element={<IntegrationsPage />} id="serviceIntegrations" />
                  <Route path=":integrationId" element={<IntegrationSummaryPage />} />
                </Route>
                <Route path="health" element={<MonitoringPage />} id="health" />
                <Route
                  path="queryInsights"
                  element={
                    <QueryInsightsLayout>
                      <QueryInsightsPage />
                    </QueryInsightsLayout>
                  }
                  id="queryInsights"
                />
                <Route path="backups" element={<BackupsPage />} id="backups" />
                <Route path="learn" element={<LearnPage />} id="learn" />
                <Route path="support">
                  <Route index element={<SupportListPage />} id="support" />
                  <Route path="newCase" element={<SupportNewCasePage />} />
                  <Route path=":caseId" element={<SupportCasePage />} />
                </Route>
                <Route path="gettingStarted" element={<GettingStartedPage />} id="gettingStarted" />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Route>
          <Route path="/login" element={<LoginRedirect />} id="loginLegacy" /> {/* Legacy route for old links. Remove in a couple of weeks */}
          <Route path="/signIn" element={<LoginRedirect />} id="login" />
          <Route path="/signUp" element={<SignupRedirect />} id="signup" />
          <Route element={<ServiceLayoutGuard />} errorElement={<NotFoundPage />}>
            <Route path="/learn" element={<LearnPage />} id="learn" />
            <Route path="/support">
              <Route index element={<SupportListPage />} id="support" />
              <Route path="newCase" element={<SupportNewCasePage />} />
              <Route path=":caseId" element={<SupportCasePage />} />
            </Route>
            <Route path="integrations">
              <Route index element={<IntegrationsPage />} id="serviceIntegrations" />
              <Route path=":integrationId" element={<IntegrationSummaryPage />} />
            </Route>
            <Route path="/createNewService" element={<CreateNewServicePage />} />
            <Route path="/services" element={<ServiceListPage />} id="servicesRedirect" />
            <Route path="/warehouses" element={<ServiceListPage />} id="warehousesRedirect" />
            <Route path="/" element={<ServiceListPage />} id="selectService" errorElement={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/onboard" element={<OnBoardPage />} id="onBoard" errorElement={<NotFoundPage />} />
          <Route path="/approveOrgInvitations" element={<ApproveOrgInvitationsPage />} id="approveOrgInvitations" errorElement={<NotFoundPage />} />
          <Route path="/termsOfService" element={<TermsOfServicePage />} id="termsOfService" />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        {/* Unauthenticated routes */}
        <Route element={<UnauthenticatedApp />}>
          <Route path="/error/emailVerification" element={<EmailVerificationErrorPage />} />
        </Route>
      </ReactRoutes>
    </RoutesContainer>
  );
};

export default Routes;
