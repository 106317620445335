import { ReactElement } from 'react';
import {
  Container,
  createToast,
  Label,
  Select,
  Text,
  TextField
} from '@clickhouse/click-ui';
import FilterConfigControls from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/FilterConfigControls';
import ObjectLinkControls from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar/ObjectLinkControls';
import {
  DashboardConfig,
  DashboardConfigField
} from 'shared/src/types/dashboard';
import { UpdateDashboardFilterConfigFunc } from 'src/dashboard/dashboardController';
import { useDashboardState } from 'src/state/dashboard/dashboardState';

interface StringFieldProps {
  config: DashboardConfig;
  dashboardId: string;
  defaultValue?: string;
  editingObjectId: string;
  field: string;
  filterable: boolean;
  label: string;
  linkable: boolean;
  onCancel: () => void;
  onChange: (value: DashboardConfigField<string>) => void;
  onConfirm: (changes?: DashboardConfigField<string>) => void;
  onFilterConfirm?: UpdateDashboardFilterConfigFunc;
  value?: DashboardConfigField<string>;
}

export default function StringField({
  config,
  dashboardId,
  defaultValue = '',
  editingObjectId,
  field,
  filterable,
  label,
  linkable,
  onCancel,
  onChange,
  onConfirm,
  onFilterConfirm,
  value
}: StringFieldProps): ReactElement {
  const dashboardState = useDashboardState({
    dashboardId
  });

  const onTypeChange = (newType: string): void => {
    if (newType === value?.type) {
      return;
    }

    if (newType === 'constant') {
      const newDefaultValue =
        (value?.type === 'filter' &&
          config.dashboardFilters.config[field].defaultValue) ||
        defaultValue;

      onConfirm({
        type: 'constant',
        value: newDefaultValue
      });
    } else if (newType === 'filter') {
      if (typeof onFilterConfirm === 'function') {
        const filterConfig = config.dashboardFilters.config[field];
        const newDefaultValue =
          filterConfig?.defaultValue ||
          (value?.type === 'constant' && value.value) ||
          defaultValue;

        onFilterConfirm(field, {
          defaultValue: newDefaultValue,
          filterType: 'string'
        }).catch(console.error);
      } else {
        createToast({
          title: 'Cannot update filter',
          description: 'This field is not filterable'
        });
      }
    } else if (newType === 'reference') {
      onChange({
        type: 'reference',
        objectId: '',
        field: ''
      });
    }
  };

  return (
    <Container orientation="vertical" gap="sm" maxWidth="90%">
      <Text weight="bold">{label}</Text>
      <Container gap="sm" alignItems="end">
        <Container gap="sm" orientation="vertical" maxWidth="30%">
          <Label>Value type</Label>
          <Select onSelect={onTypeChange} value={value?.type || 'filter'}>
            {filterable && <Select.Item value="filter">Filter</Select.Item>}
            <Select.Item value="constant">Constant</Select.Item>
            <Select.Item value="reference">Reference</Select.Item>
          </Select>
        </Container>
        {value?.type === 'constant' && (
          <TextField
            onBlur={() => onConfirm()}
            onChange={(newValue) => {
              onChange({
                type: 'constant',
                value: newValue
              });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }

              if (e.key === 'Escape') {
                e.stopPropagation();
                onCancel();
              }
            }}
            value={value.value}
          />
        )}
        {linkable && value?.type === 'reference' && (
          <ObjectLinkControls
            config={config}
            editingObjectId={editingObjectId}
            onConfirm={onConfirm}
            value={value}
          />
        )}
        {filterable && value?.type === 'filter' && (
          <FilterConfigControls
            defaultValue={defaultValue}
            filterConfig={config.dashboardFilters.config[field]}
            onConfirm={({ defaultValue, filterType }): void => {
              if (typeof onFilterConfirm === 'function') {
                onFilterConfirm(field, {
                  defaultValue,
                  filterType
                }).catch(console.error);
              } else {
                createToast({
                  title: 'Cannot update filter',
                  description: 'This field is not filterable'
                });
              }
            }}
            value={value}
          />
        )}
      </Container>
      {filterable && value?.type === 'filter' && (
        <Label>
          Current filter value:{' '}
          {(dashboardState[field]?.value as string) || 'null'}
        </Label>
      )}
    </Container>
  );
}
