import { ReactElement } from 'react';
import { Button, Container, Separator } from '@clickhouse/click-ui';
import { useIsAddressFormValid } from 'src/components/BillingCardModal/FormState';
import { AddressInfoContainer } from 'src/components/BillingCardModal/AddressInfoContainer';

type BusinessTabAddressParams = {
  onCompanyDetails: () => void;
  isLoading: boolean;
};
const BusinessTabAddress = ({
  onCompanyDetails,
  isLoading
}: BusinessTabAddressParams): ReactElement => {
  const canBeSubmitted = useIsAddressFormValid();
  return (
    <>
      <AddressInfoContainer />
      <Separator size="md" />
      <Container justifyContent="end" gap="md" fillWidth={true}>
        <Button
          onClick={onCompanyDetails}
          disabled={!canBeSubmitted}
          iconRight="arrow-right"
          loading={isLoading}
          data-testid="company-or-payment-update-button"
        >
          Company Details
        </Button>
      </Container>
    </>
  );
};

export default BusinessTabAddress;
