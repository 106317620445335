import { ReactElement, useState } from 'react';
import { SidebarNavigationItemProps } from '@clickhouse/click-ui';
import { ServiceConnect } from 'src/components/ServiceConnect/ServiceConnect';
import styled from 'styled-components';
import { useCurrentInstance } from 'src/instance/instanceController';
import { CustomSidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';

const ConnectItem = styled.div`
  border-color: ${({ theme }): string =>
    theme.click.button.alignedLeft.color.stroke.default};
  border-width: 1px;
  border-style: solid;
`;

const ServiceConnectSidebarItem = (
  props: SidebarNavigationItemProps
): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const instance = useCurrentInstance();

  const openModal = (): void => {
    setIsModalOpen(true);
  };

  return (
    <>
      <ConnectItem
        as={CustomSidebarItem}
        onClick={openModal}
        $collapsible={false}
        {...props}
      />
      <ServiceConnect
        instance={instance}
        isModal={true}
        open={isModalOpen}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default ServiceConnectSidebarItem;
