import { Container, Icon, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useLastVisitedSvcUrl } from 'src/components/NavigationProvider/NavigationState';
import styled from 'styled-components';

export const SidebarHeader = (): ReactElement => {
  const lastVisitedSvcUrl = useLastVisitedSvcUrl();
  return (
    <Link to={lastVisitedSvcUrl ?? '/'}>
      <Container
        orientation="horizontal"
        isResponsive={false}
        padding="none"
        gap="xs"
      >
        <IconWrapper name="chevron-left" />
        <Container gap="none" orientation="vertical">
          <Text size="lg" weight="medium">
            Settings
          </Text>
          <Text size="sm" color="muted">
            Return to your service view
          </Text>
        </Container>
      </Container>
    </Link>
  );
};

const IconWrapper = styled(Icon)`
  color: ${({ theme }) => theme.click.global.color.text.default};
`;
