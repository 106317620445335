import React, { ReactElement } from 'react';
import * as uuid from 'uuid';
import { routes } from 'src/lib/routes';
import { getClickPipeImportModel, useImportModel } from 'src/state/dataLoading';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { CardPrimary, CardSecondary } from '@clickhouse/click-ui';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';

interface Props {
  type?: 'primary' | 'secondary';
}

const title = 'Set up a ClickPipe';
const description =
  'Configure ClickPipes to seamlessly ingest data from remote data sources into ClickHouse';
const infoText = 'Get started';

function KafkaDataCard(props: Props): ReactElement | null {
  const { setImportModel } = useImportModel();
  const serviceId = getCurrentServiceIdOrFail();

  const onClickHandler = (): void => {
    const model = getClickPipeImportModel(uuid.v4());
    setImportModel(model);
    navigateTo(routes.import({ serviceId, importId: model.id }));
  };

  if (props.type === 'secondary') {
    return (
      <div onClick={onClickHandler}>
        <CardSecondary
          title={title}
          icon="waves"
          description={description}
          infoText={infoText}
        />
      </div>
    );
  }

  return (
    <CardPrimary
      size="sm"
      icon="waves"
      title={title}
      description={description}
      infoText={infoText}
      onClick={onClickHandler}
      alignContent="center"
      onButtonClick={onClickHandler}
      data-testid="clickpipes-data-card"
    />
  );
}

export default React.memo(KafkaDataCard);
