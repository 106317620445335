import { css, SerializedStyles, Theme } from '@emotion/react';

export const containerStyle = (theme: Theme): SerializedStyles => css`
  padding: 15px;
  width: 100%;
  position: relative;
  background-color: ${theme.global.color.background.default};
  height: 100%;
  overflow: auto;
  border-left: 1px solid ${theme.global.color.stroke.default};
`;

export const hintStyle = (theme: Theme): SerializedStyles => css`
  color: ${theme.global.color.text.muted};
  font-size: 12px;
  line-height: 20px;
`;

export const sectionHeaderStyle = (theme: Theme): SerializedStyles => css`
  color: ${theme.global.color.text.default};
  margin-bottom: 15px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
