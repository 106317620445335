import { useUserStateManager } from 'src/user/userState';

/**
 * Checks regionBlocked flag in the user state.
 * @returns true is the user region is blocked.
 */
export function useIsRegionBlocked(): boolean {
  const { user } = useUserStateManager();
  // If the user state has not been yet loaded, user is {}.
  // We assume that the region is not blocked at this moment.
  return !!user.regionBlocked;
}
