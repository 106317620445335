import { Spacer, Title } from '@clickhouse/click-ui';
import {
  PASSWORD_MASK,
  getPasswordSubstitution
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings'; // replace with your actual import
import { ReactElement } from 'react';
import LanguageClientExtras from 'src/components/ServiceConnect/Tabs/LanguageClientExtras';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';
import { FullWidthCodeBlock } from 'src/components/ServiceConnect/Tabs/FullWidthCodeBlock';

const getGoClientCode = (
  hostname: string,
  username: string,
  password: string,
  port: string
): string => {
  return `package main

import (
  "crypto/tls"
  "fmt"
  "github.com/ClickHouse/clickhouse-go/v2"
)

func main() {
  conn := clickhouse.OpenDB(&clickhouse.Options{
    Addr: []string{"${hostname}:${port}"}, // ${port} is a secure native TCP port
    Protocol: clickhouse.Native,
    TLS: &tls.Config{}, // enable secure TLS
    Auth: clickhouse.Auth{
      Username: "${username}",
      Password: "${getPasswordSubstitution(password)}",
    },
  })
  row := conn.QueryRow("SELECT 1")
  var col uint8
  if err := row.Scan(&col); err != nil {
    fmt.Printf("An error while reading the data: %s", err)
  } else {
    fmt.Printf("Result: %d", col)
  }
}`;
};

interface GoConnectionTabProps {
  hostname: string;
  username: string;
  password: string;
  port: string;
  showPassword?: boolean;
}

const GoConnectionTab = ({
  hostname,
  username,
  password,
  port,
  showPassword
}: GoConnectionTabProps): ReactElement => {
  const unmasked = getGoClientCode(hostname, username, password, port);
  const masked = password
    ? getGoClientCode(hostname, username, PASSWORD_MASK, port)
    : undefined;
  return (
    <>
      <Title type="h3">Installation</Title>
      <FullWidthCodeBlock
        language="bash"
        showLineNumbers
        wrapLines
        data-testid="service-connect-code-block-go-install"
      >
        go get github.com/ClickHouse/clickhouse-go/v2
      </FullWidthCodeBlock>
      <Title type="h3">Usage</Title>
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        data-testid="service-connect-code-block-go-usage"
        language="golang"
        showPassword={showPassword}
      />
      <Spacer />
      <LanguageClientExtras
        title="Go Client"
        docsLink="https://clickhouse.com/docs/en/integrations/go"
        examplesLink="https://github.com/ClickHouse/clickhouse-go/tree/main/examples"
      />
    </>
  );
};

export default GoConnectionTab;
