import { SerializedStyles, Theme, css } from '@emotion/react';

export const btnStyle = (open: boolean): SerializedStyles =>
  css({
    transform: `rotate(${open ? 0 : '180deg'})`,
    transition: 'transform .3s ease-in-out'
  });

export const consoleSidebarContainer = (theme: Theme): SerializedStyles =>
  css({
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRight: '1px solid',
    borderRightColor: theme.global.color.stroke.default
  });

export const tabsStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden'
});

export const tabContentStyle = css({
  display: 'flex',
  flexDirection: 'column',
  '&[data-state="active"]': {
    flex: 1,
    overflow: 'hidden'
  }
});

export const triggerListStyle = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  minHeight: 50
});
