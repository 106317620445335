import { Button, Checkbox, Container, Text, Title } from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import ClickHouseFullIcon from 'src/layout/ServiceSidebar/ClickHouseFullIcon';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useUserStateManager } from 'src/user/userState';
import styled from 'styled-components';

function TermsOfServicePage(): ReactElement {
  const apiClient = useApiClient();
  const [accepted, setAccepted] = useState(true);
  const userState = useUserStateManager();

  const handleAcceptTOS = async (): Promise<void> => {
    try {
      await apiClient.acceptAccountTOS();
      const checkUserHasAcceptedTOSResponse =
        await apiClient.checkUserHasAcceptedTOS();
      userState.setUser({
        ...userState.user,
        acceptedTOSContractId: checkUserHasAcceptedTOSResponse.contractId
      });
      navigateTo('/');
    } catch (e) {
      console.error('Error accepting TOS', e);
    }
  };

  return (
    <ContainerWithBG alignItems="start">
      <Container orientation="vertical" alignItems="center">
        <CHIcon />
        <InnerContainer
          gap="lg"
          orientation="vertical"
          data-testid="check-your-email-card"
        >
          <Container justifyContent="center">
            <Title type={'h1'}>
              Accept Terms of Service and Privacy Policy
            </Title>
          </Container>
          <Text>
            Please review and accept the following documents to finish creating
            your account.
          </Text>
          <Checkbox
            label={
              <Text size="sm">
                I understand and agree to the{' '}
                <a
                  href="https://clickhouse.com/legal/agreements/terms-of-service"
                  target="_blank"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://clickhouse.com/legal/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </Text>
            }
            checked={accepted}
            onCheckedChange={(checkedState) => setAccepted(!!checkedState)}
          />
          <Button
            fillWidth
            disabled={!accepted}
            onClick={handleAcceptTOS}
            align="center"
            label="Continue"
            type="primary"
          />
        </InnerContainer>
      </Container>
    </ContainerWithBG>
  );
}

const ContainerWithBG = styled(Container)`
  background-image: linear-gradient(
    170deg,
    black 0%,
    black calc(45% - 20.8px),
    #fcff74 calc(45% - 20px),
    #fcff74 45%,
    white 45%,
    white
  );
  background-size: max(100%, 1100px) max(100%, 1024px);
  background-repeat: no-repeat;
  padding: clamp(32px, 20vh, 128px) 0 0;
  min-height: 100vh;
`;

const InnerContainer = styled(Container)`
  background: ${({ theme }): string => theme.global.color.background.default};
  max-width: 496px;
  width: 100%;
  box-shadow: 0 10px 40px 0 rgb(16 24 40 / 10%);
  border-radius: 8px;
  padding: 40px 48px;
  border: 1px solid ${({ theme }): string => theme.palette.neutral[200]};
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 40px 24px;
  }
`;

const CHIcon = styled(ClickHouseFullIcon)`
  color: white;
  margin-bottom: 48px;
`;

export default TermsOfServicePage;
