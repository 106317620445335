import { Link } from '@clickhouse/click-ui';
import downloadjs from 'downloadjs';
import React, { ReactElement, useState } from 'react';
import { useUsageStatementCSV } from 'src/billing/controllers/useUsageStatementCSV';

export type DownloadCSVLinkProps = { organizationId: string; billId?: string };

const DownloadCSVLink = ({
  organizationId,
  billId
}: DownloadCSVLinkProps): ReactElement => {
  const [csvButtonDisabled, setCsvButtonDisabled] = useState(false);
  const { getUsageStatementCSV } = useUsageStatementCSV();

  if (!billId) {
    return <>N/A</>;
  }

  const onClick = async (e: React.MouseEvent): Promise<void> => {
    if (billId) {
      e.preventDefault();
      if (csvButtonDisabled) return;
      setCsvButtonDisabled(true);
      try {
        const csvContents = await getUsageStatementCSV(organizationId, billId);
        downloadjs(csvContents, `statement-${billId}.csv`, 'text/csv');
      } catch (e) {
        // ignore, toast is created on controller
      } finally {
        setCsvButtonDisabled(false);
      }
    }
  };

  return (
    <Link
      onClick={(e: React.MouseEvent): void => {
        onClick(e).catch(console.error);
      }}
    >
      CSV
    </Link>
  );
};

export default DownloadCSVLink;
