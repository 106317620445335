import React from 'react';

import { useTheme } from '@emotion/react';
import isFunction from 'lodash/isFunction';
import last from 'lodash/last';

import { filterProps, isNumber } from './utils';

const minDistance = 20;

const CartesianGrid = (props) => {
  const {
    x,
    y,
    width,
    height,
    horizontal,
    vertical,
    horizontalCoordinatesGenerator,
    verticalCoordinatesGenerator,
    xAxis,
    yAxis,
    offset,
    chartWidth,
    chartHeight
  } = props;
  const theme = useTheme();

  const renderLineItem = (option, props) => {
    let lineItem;

    if (React.isValidElement(option)) {
      lineItem = React.cloneElement(option, props);
    } else if (isFunction(option)) {
      lineItem = option(props);
    } else {
      const { x1, y1, x2, y2, key, ...others } = props;

      lineItem = (
        <line
          {...filterProps(others)}
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          fill="none"
          key={key}
        />
      );
    }

    return lineItem;
  };

  const renderHorizontal = (points) => {
    const { x, xAxis, yAxis, width, height } = props;
    const offset = xAxis.y;
    const distance = Math.max(
      height /
        (yAxis.type === 'category' ? yAxis.domain.length : points.length - 1),
      minDistance
    );
    const count = Math.min(
      yAxis.type === 'category' ? yAxis.domain.length : points.length,
      Math.round(height / minDistance)
    );
    const horizontalPoints = [];
    for (let i = 0; i <= count; i++) {
      if (i === 0) {
        if (yAxis.type === 'category') {
          horizontalPoints.push(offset - distance);
        } else {
          horizontalPoints.push(offset);
        }
      } else if (yAxis.type === 'number' && i === 1) {
        horizontalPoints.push(offset - distance / 2);
      } else {
        horizontalPoints.push(horizontalPoints[i - 1] - distance);
      }
    }
    const indexOfZero =
      yAxis &&
      yAxis.niceTicks &&
      Array.isArray(yAxis.niceTicks) &&
      yAxis.niceTicks.findIndex((item) => item === 0);
    if (indexOfZero && indexOfZero > 0) {
      horizontalPoints.push(points[indexOfZero]);
    }

    horizontalPoints.push(last(points));

    if (!horizontalPoints || !horizontalPoints.length) {
      return null;
    }

    const items = horizontalPoints.map((entry, i) => {
      const newProps = {
        ...props,
        x1: x,
        y1: entry,
        x2: x + width,
        y2: entry,
        key: `line-${i}`,
        index: i
      };

      return renderLineItem(false, newProps);
    });

    return <g className="recharts-cartesian-grid-horizontal">{items}</g>;
  };

  const renderVertical = (points) => {
    const { y, xAxis, height, width } = props;
    const offset = xAxis.x;
    const distance = Math.max(
      width /
        (xAxis.type === 'category' ? xAxis.domain.length : points.length - 1),
      minDistance
    );
    const count = Math.min(
      xAxis.type === 'category' ? xAxis.domain.length : points.length,
      Math.round(width / minDistance)
    );
    const verticalPoints = [];
    for (let i = 0; i < count - 1; i++) {
      if (i === 0) {
        if (xAxis.type === 'category') {
          verticalPoints.push(offset + distance);
        } else {
          verticalPoints.push(offset + distance / 2);
        }
      } else if (i === 1 && xAxis.type === 'number') {
        verticalPoints.push(offset + distance / 2);
      } else {
        verticalPoints.push(verticalPoints[i - 1] + distance);
      }
    }

    verticalPoints.push(last(points));

    if (!verticalPoints || !verticalPoints.length) {
      return null;
    }

    const items = verticalPoints.map((entry, i) => {
      const newProps = {
        ...props,
        x1: entry,
        y1: y,
        x2: entry,
        y2: y + height,
        key: `line-${i}`,
        index: i
      };

      return renderLineItem(false, newProps);
    });

    items.push(
      <line
        stroke={theme.colors.c4}
        x1={xAxis.x + width}
        y1={y}
        x2={xAxis.x + width}
        y2={y + height}
        fill="none"
        key={`line-${items.length}`}
      />
    );

    return <g className="recharts-cartesian-grid-vertical">{items}</g>;
  };

  const renderVerticalStripes = (verticalPoints) => {
    const { verticalFill } = props;
    if (!verticalFill || !verticalFill.length) {
      return null;
    }

    const { fillOpacity, x, y, width, height } = props;
    const verticalPointsUpdated = verticalPoints.slice().sort((a, b) => a - b);

    if (x !== verticalPointsUpdated[0]) {
      verticalPointsUpdated.unshift(0);
    }

    const items = verticalPointsUpdated.map((entry, i) => {
      const lineWidth = verticalPointsUpdated[i + 1]
        ? verticalPointsUpdated[i + 1] - entry
        : x + width - entry;
      if (lineWidth <= 0) {
        return null;
      }
      const colorIndex = i % verticalFill.length;
      return (
        <rect
          key={`react-${i}`} // eslint-disable-line react/no-array-index-key
          x={Math.round(entry + x - x)}
          y={y}
          width={lineWidth}
          height={height}
          stroke={theme.colors.c4}
          fill={verticalFill[colorIndex]}
          fillOpacity={fillOpacity}
          className="recharts-cartesian-grid-bg"
        />
      );
    });

    return <g className="recharts-cartesian-gridstripes-vertical">{items}</g>;
  };

  const renderHorizontalStripes = (horizontalPoints) => {
    const { horizontalFill } = props;
    if (!horizontalFill || !horizontalFill.length) {
      return null;
    }

    const { fillOpacity, x, y, width, height } = props;
    const horizontalPointsUpdated = horizontalPoints
      .slice()
      .sort((a, b) => a - b);
    if (y !== horizontalPointsUpdated[0]) {
      horizontalPointsUpdated.unshift(0);
    }

    const items = horizontalPointsUpdated.map((entry, i) => {
      const lineHeight = horizontalPointsUpdated[i + 1]
        ? horizontalPointsUpdated[i + 1] - entry
        : y + height - entry;
      if (lineHeight <= 0) {
        return null;
      }
      const colorIndex = i % horizontalFill.length;
      return (
        <rect
          key={`react-${i}`}
          y={Math.round(entry + y - y)}
          x={x}
          height={lineHeight}
          width={width}
          stroke={theme.colors.c4}
          fill={horizontalFill[colorIndex]}
          fillOpacity={fillOpacity}
          className="recharts-cartesian-grid-bg"
        />
      );
    });

    return <g className="recharts-cartesian-gridstripes-horizontal">{items}</g>;
  };

  const renderBackground = () => {
    const { fill } = props;

    if (!fill || fill === 'none') {
      return null;
    }

    const { fillOpacity, x, y, width, height } = props;

    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke={theme.colors.c4}
        fill={fill}
        fillOpacity={fillOpacity}
        className="recharts-cartesian-grid-bg"
      />
    );
  };

  if (
    !isNumber(width) ||
    width <= 0 ||
    !isNumber(height) ||
    height <= 0 ||
    !isNumber(x) ||
    x !== +x ||
    !isNumber(y) ||
    y !== +y
  ) {
    return null;
  }

  let { horizontalPoints, verticalPoints } = props;

  // No horizontal points are specified
  if (
    (!horizontalPoints || !horizontalPoints.length) &&
    isFunction(horizontalCoordinatesGenerator)
  ) {
    horizontalPoints = horizontalCoordinatesGenerator({
      yAxis,
      width: chartWidth,
      height: chartHeight,
      offset
    });
  }

  // No vertical points are specified
  if (
    (!verticalPoints || !verticalPoints.length) &&
    isFunction(verticalCoordinatesGenerator)
  ) {
    verticalPoints = verticalCoordinatesGenerator({
      xAxis,
      width: chartWidth,
      height: chartHeight,
      offset
    });
  }

  return (
    <g className="recharts-cartesian-grid">
      {renderBackground()}
      {horizontal && renderHorizontal(horizontalPoints)}
      {/* {horizontal && renderCenterHorizontal(horizontalPoints)} */}
      {vertical && renderVertical(verticalPoints)}

      {horizontal && renderHorizontalStripes(horizontalPoints)}
      {vertical && renderVerticalStripes(verticalPoints)}
    </g>
  );
};

CartesianGrid.displayName = 'CartesianGrid';
CartesianGrid.defaultProps = {
  horizontal: true,
  vertical: true,
  // The ordinates of horizontal grid lines
  horizontalPoints: [],
  // The abscissas of vertical grid lines
  verticalPoints: [],
  fill: 'none',
  // The fill of colors of grid lines
  verticalFill: [],
  horizontalFill: []
};
export default CartesianGrid;
