export const ROWS_PER_PAGE = 5000;

export interface PageLocation {
  page: number;
  offset: number;
}

export function rowToPage(rowIndex: number): number {
  return Math.floor(rowIndex / ROWS_PER_PAGE);
}

export function rowToPageAndOffset(rowIndex: number): PageLocation {
  const page = rowToPage(rowIndex);
  const offset = rowIndex - page * ROWS_PER_PAGE;
  return { page, offset };
}

export function pageAndOffsetToRow({ page, offset }: PageLocation) {
  return page * ROWS_PER_PAGE + offset;
}
