import { ReactElement, Suspense } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useIsUnifiedConsole } from 'src/lib/features';

const RedirectConsole = (): ReactElement | null => {
  const { pathname } = useLocation();
  const { serviceId } = useParams();

  if (serviceId) {
    const match = pathname.match(/\/services\/[^/]+\/console(.*)/);
    const newUrl = match
      ? pathname
      : pathname.replace(
          `/services/${serviceId}`,
          `/services/${serviceId}/console`
        );

    return <Navigate replace to={newUrl} />;
  }
  return null;
};

export const RedirectConsolePathRouteGuard = (): ReactElement => {
  const enableUnifiedConsole = useIsUnifiedConsole();
  if (enableUnifiedConsole) {
    return <RedirectConsole />;
  }
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};
