import {
  Container,
  SidebarCollapsibleItem,
  SidebarCollapsibleItemProps
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CustomSidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';

interface SidebarItemProps extends SidebarCollapsibleItemProps {
  paths: Array<string>;
  end?: boolean;
}
const SidebarContainer = styled(Container)`
  gap: 1px;
  padding-top: 1px;
`;

const CollapsibleSidebarItem = ({
  label,
  paths,
  icon,
  children,
  ...props
}: SidebarItemProps): ReactElement => {
  const { pathname } = useLocation();
  const match = paths.some((path) => matchPath(path, pathname));
  const [open, setOpen] = useState(match);

  return (
    <CustomSidebarItem
      as={SidebarCollapsibleItem}
      $collapsible
      open={open}
      onOpenChange={setOpen}
      type="main"
      icon={icon}
      label={label}
      {...props}
    >
      <SidebarContainer orientation="vertical">{children}</SidebarContainer>
    </CustomSidebarItem>
  );
};

export default CollapsibleSidebarItem;
