import { Container, Select, TextField } from '@clickhouse/click-ui';
import {
  COMPANY_SIZE_ARRAY,
  CompanySize
} from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import {
  useCompanyName,
  useNumEmployees,
  useWebsiteUrl
} from 'src/components/BillingCardModal/FormState';

export const EntityInfoContainer = (): ReactElement => {
  const { numEmployees, setNumEmployees } = useNumEmployees();
  const { websiteUrl, setWebsiteUrl } = useWebsiteUrl();
  const { companyName, setCompanyName, companyNameError } = useCompanyName();

  return (
    <>
      <Container
        orientation="horizontal"
        justifyContent="space-between"
        gap="md"
        fillWidth
      >
        <TextField
          dir="start"
          label="Company or organization"
          orientation="vertical"
          type="text"
          value={companyName}
          error={companyNameError}
          onChange={setCompanyName}
          data-testid="company-name"
        />
      </Container>
      <Container
        orientation="horizontal"
        justifyContent="space-between"
        gap="md"
        fillWidth
      >
        <TextField
          dir="start"
          label="Website"
          orientation="vertical"
          type="text"
          value={websiteUrl}
          onChange={setWebsiteUrl}
          data-testid="website-url"
        />
        <Select
          onSelect={(value: string): void => {
            setNumEmployees(value as CompanySize);
          }}
          value={numEmployees}
          label="Number of employees"
          data-testid="company-size-select"
        >
          {COMPANY_SIZE_ARRAY.map((size) => (
            <Select.Item
              value={size}
              key={size}
              data-testid="company-size-option"
              data-testid-value={size}
            >
              {size}
            </Select.Item>
          ))}
        </Select>
      </Container>
    </>
  );
};
