import { Table } from 'shared/src/clickhouse/types';

const headerHeight = 18;

const tableMargin = 16;
const tableNameHeight = 32;
const tableColumnHeight = 20;

export function tableHeight(table: Table, expanded: boolean): number {
  if (!expanded) {
    return tableNameHeight + 1;
  } else {
    return tableNameHeight + tableMargin + tableColumnHeight * table.columns.length;
  }
}

export interface ExpandableResource extends Table {
  detailsAreOpen: boolean;
}

export function sectionHeight(resources: ExpandableResource[], expanded: boolean): number {
  if (resources.length === 0 || !expanded) {
    return 0;
  }

  const resourcesHeight = resources
    .map((resource) => tableHeight(resource, resource.detailsAreOpen))
    .reduce((sum, x) => sum + x, 0);

  return resourcesHeight;
}

interface ExpandableEntities {
  name: string;
  expanded: boolean;
}

interface EntityWithHeights extends ExpandableEntities {
  height: number;
}

export function fittedHeights(sections: EntityWithHeights[], availableHeight: number): Record<string, number> {
  function findBestFit(sections: EntityWithHeights[], availableHeight: number): Record<string, number> {
    let remainingHeight = availableHeight - headerHeight * sections.filter(({ expanded }) => expanded).length;
    const queue = [...sections];
    const result: Record<string, number> = {};
    while (queue.length > 0) {
      const section = queue.shift();
      let fittedHeight = 0;

      if (!section) {
        break;
      }

      if (remainingHeight > 0) {
        const optimalMinHeight = remainingHeight / (queue.length + 1);
        fittedHeight = Math.min(optimalMinHeight, section.height);
      }

      result[section.name] = fittedHeight;
      remainingHeight -= fittedHeight;
    }
    return result;
  }

  const sectionsWithHeight = sections.filter((section) => section.height > 0).sort((a, b) => a.height - b.height);

  return findBestFit(sectionsWithHeight, availableHeight);
}
