import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import ConsoleCredentialsModal from 'src/components/ConsoleCredentialsModal';
import CredentialsModal from 'src/components/CredentialsModal';
import RestartServiceModal from 'src/components/RestartServiceModal';
import WakeServiceModal from 'src/components/WakeServiceModal';
import { useQueryRunner } from 'src/lib/clickhouse/query';
import { useIsUnifiedConsole } from 'src/lib/features';
import { useMetadataLoading } from 'src/metadata/metadataState';
import {
  PasswordCredential,
  useConnectionCredentials,
  useCredentials
} from 'src/state/connection';
import { useLiveQueriesUpdater } from 'src/state/liveQueries';
import { useRestartService } from 'src/state/service/restartService';
import { useWakeService } from 'src/state/service/wakeService';
import { useCurrentInstance } from 'src/instance/instanceController';
import { PASSWORD_CREDENTIAL_ERROR } from 'shared';
import { createToast } from '@clickhouse/click-ui';
import { useIsRestoringTabs } from 'src/state/tabs';
import StoppedServiceModal from 'src/components/StoppedServiceModal';

const container = (modalInComponent: boolean): SerializedStyles =>
  css(`
  position: relative;
  width: 100%;
  height: 100%;
  ${
    modalInComponent
      ? `
      [role=dialog] {
        position: absolute;
      }`
      : ''
  }
`);

const consoleServicePageContainer = css({
  display: 'flex',
  width: '100%',
  height: '100%'
});
interface LoginModalProps {
  isUnified: boolean;
  open: boolean;
  onSuccess: (
    credentials: Pick<
      PasswordCredential,
      'host' | 'port' | 'username' | 'password'
    >
  ) => void;
  onClose: () => void;
  container: HTMLElement | null;
  loading?: boolean;
}
function LoginModal({
  isUnified,
  open: openProp,
  onSuccess,
  container,
  onClose
}: LoginModalProps): ReactElement {
  if (isUnified) {
    return (
      <ConsoleCredentialsModal
        open={openProp}
        onClose={onClose}
        onSuccess={onSuccess}
        onOpenChange={(open: boolean): void => {
          if (!open) {
            onClose();
          }
        }}
        container={container}
      />
    );
  }
  return (
    <CredentialsModal open={openProp} onClose={onClose} onSuccess={onSuccess} />
  );
}

const OutputContent = React.memo(
  ({
    loading,
    isUnified,
    children
  }: {
    loading?: boolean;
    isUnified?: boolean;
    children: ReactNode;
  }): ReactElement => {
    if (isUnified) {
      return (
        <div
          css={consoleServicePageContainer}
          data-testid="console-service-layout"
          data-loaded={!loading}
        >
          {children}
        </div>
      );
    }
    return <>{children}</>;
  }
);
OutputContent.displayName = 'OutputContent';

function CredentialsModalLayout({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null);
  const isMetadataLoading = useMetadataLoading();
  const isUnified = useIsUnifiedConsole();
  const currentInstance = useCurrentInstance();
  const isRestoringTabs = useIsRestoringTabs();
  const credentials = useCredentials();
  const { setCredentialModalOpen } = useConnectionCredentials();

  const tryConnection = useQueryRunner({
    sql: 'SELECT 1',
    skipConnectionInit: true
  });

  const { cancelCredentialModal, confirmCredentialModal, credentialModalOpen } =
    useConnectionCredentials();

  useEffect(() => {
    const connect = async (): Promise<void> => {
      try {
        await tryConnection(); // this will trigger the credential modal if the connection fails
      } catch (e) {
        if (e instanceof Error) {
          if (e.message.includes(PASSWORD_CREDENTIAL_ERROR)) {
            setCredentialModalOpen(true);
          } else {
            createToast({
              title: 'Error',
              type: 'danger',
              description: e.message
            });
          }
        }
      }
    };

    if (!credentials.connected) {
      void connect();
    }
  }, [credentials.connected]);

  const isStopped = ['stopped', 'stopping'].includes(
    currentInstance?.state ?? ''
  );

  const {
    cancelWakeServiceModal,
    confirmWakeServiceModal,
    wakeServiceModalMessage,
    wakeServiceModalOpen
  } = useWakeService();

  const {
    cancelRestartServiceModal,
    confirmRestartServiceModal,
    restartServiceModalMessage,
    restartServiceModalOpen
  } = useRestartService();

  useLiveQueriesUpdater();

  return (
    <div css={container(isUnified && credentialModalOpen)} ref={ref}>
      {isStopped && currentInstance?.id && (
        <StoppedServiceModal serviceId={currentInstance.id} />
      )}

      <LoginModal
        isUnified={isUnified}
        open={credentialModalOpen}
        onClose={cancelCredentialModal}
        onSuccess={confirmCredentialModal}
        container={ref.current}
      />
      <WakeServiceModal
        instanceId={currentInstance?.id}
        message={wakeServiceModalMessage || 'run this query'}
        open={wakeServiceModalOpen}
        onCancel={cancelWakeServiceModal}
        onConfirm={confirmWakeServiceModal}
      />
      <RestartServiceModal
        message={restartServiceModalMessage || 'run this query'}
        open={restartServiceModalOpen}
        onCancel={cancelRestartServiceModal}
        onConfirm={confirmRestartServiceModal}
      />
      <OutputContent
        loading={isMetadataLoading || isRestoringTabs}
        isUnified={isUnified}
      >
        {children}
      </OutputContent>
    </div>
  );
}

export default CredentialsModalLayout;
