import {
  ButtonGroup,
  CardSecondary,
  Container,
  GridContainer,
  Title,
  useCUITheme
} from '@clickhouse/click-ui';
import { css } from '@emotion/react';
import { ReactElement } from 'react';
import { Integration } from 'shared/src/types/integration';
import Helmet from 'src/components/Helmet/Helmet';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { INTEGRATIONS_CATEGORY_OPTIONS } from 'src/integrations/common/integrationCategories';
import useIntegrationsList from 'src/integrations/controller/useIntegrationsList';
import { IntegrationLayout } from 'src/layout/IntegrationLayout';
import { routes } from 'src/lib/routes';
import { useParams } from 'src/lib/routes/useParams';

import { mapIntegrationToClickUiLogo } from 'src/util/strapi';
import styled from 'styled-components';

const buttonGroupStyle = css({
  margin: '16px'
});
const WrapperContainer = styled(Container)`
  padding-top: 0;
`;
const CustomCardSecondary = styled(CardSecondary)`
  min-width: min(100%, 320px);
`;

function navigateToIntegration(
  serviceId: string,
  integration: Integration
): void {
  if (integration.openInNewWindow) {
    window.open(integration.docsLink, '_blank');
  } else {
    navigateTo(
      routes.integration({ serviceId, integrationId: integration.slug })
    );
  }
}

const IntegrationsPage = (): ReactElement | null => {
  const { serviceId } = useParams();
  const { integrationsByCategories, selectedCategory, onCategoryChange } =
    useIntegrationsList();
  const { name: cuiThemeName } = useCUITheme();

  return (
    <IntegrationLayout>
      <Helmet>
        <title>Integrations</title>
      </Helmet>
      <ButtonGroup
        options={INTEGRATIONS_CATEGORY_OPTIONS}
        selected={selectedCategory}
        onClick={onCategoryChange}
        css={buttonGroupStyle}
      />
      <Container
        isResponsive={false}
        alignItems="start"
        overflow="auto"
        grow="1"
      >
        <MaxWidthContainer orientation="vertical" maxWidth="1240px">
          <WrapperContainer
            orientation="vertical"
            gap="lg"
            padding="lg"
            fillHeight
            isResponsive={false}
          >
            {integrationsByCategories?.map((integrationsByCategory) => (
              <Container
                orientation="vertical"
                key={integrationsByCategory.category}
                gap="md"
              >
                <Title
                  type="h4"
                  data-testid={`integration-category-title-${integrationsByCategory.category}`}
                >
                  {integrationsByCategory.label}
                </Title>
                <GridContainer
                  gridTemplateColumns="repeat(auto-fill, minmax(290px, 1fr))"
                  gap="md"
                >
                  {integrationsByCategory.integrations.map((item, index) => {
                    const clickUiLogoName = mapIntegrationToClickUiLogo(
                      item.name
                    );

                    // icon URL takes precedence over the icon
                    // do not set the icon URL for ClickUI logos
                    const iconUrl = clickUiLogoName
                      ? undefined
                      : cuiThemeName === 'light'
                      ? item.logo.fullUrl
                      : item.logo_dark.fullUrl;
                    return (
                      <CustomCardSecondary
                        icon={
                          // a temporary workaround to handle the missing logos
                          clickUiLogoName ?? 'clickhouse'
                        }
                        iconUrl={iconUrl}
                        title={item.name}
                        badgeText={item.supportLevel.toLowerCase()}
                        description={item.shortDescription}
                        infoText="Read more"
                        infoIconSize="sm"
                        infoIcon={item.openInNewWindow ? 'popout' : undefined}
                        key={index}
                        onClick={() => navigateToIntegration(serviceId, item)}
                        data-testid={`integration-card-${item.name}`}
                      />
                    );
                  })}
                </GridContainer>
              </Container>
            ))}
          </WrapperContainer>
        </MaxWidthContainer>
      </Container>
    </IntegrationLayout>
  );
};

export default IntegrationsPage;
