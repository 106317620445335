import {
  AutoComplete,
  AutoCompleteOptionListItem,
  Container
} from '@clickhouse/click-ui';
import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { ReactElement, useMemo } from 'react';
import { routes } from 'src/lib/routes';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';

interface Props {
  supportCases?: Array<SupportCaseDetails>;
  isLoading?: boolean;
  error?: Error;
  serviceId?: string;
}

function SupportCaseDropdown({
  supportCases = [],
  isLoading,
  error,
  serviceId
}: Props): ReactElement {
  const onSelect = (caseId: string): void => {
    navigateTo(routes.supportCase({ serviceId, caseId }));
  };
  const options: Array<AutoCompleteOptionListItem> = useMemo(
    () =>
      supportCases.map((supportCase) => {
        return {
          id: supportCase.id,
          value: supportCase.id,
          label: supportCase.subject
        };
      }),
    [supportCases]
  );

  return (
    <Container minWidth="220px">
      <AutoComplete
        placeholder="Search support cases"
        onSelect={onSelect}
        disabled={isLoading || !!error}
        options={options}
      />
    </Container>
  );
}

export default SupportCaseDropdown;
