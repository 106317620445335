import { Button, Container, createToast, Panel } from '@clickhouse/click-ui';
import { ReactElement, useMemo } from 'react';
import {
  AccordionWrapper,
  CommonAccordionProps
} from 'src/components/GettingStarted/AccordionWrapper';
import {
  InvitationRecord,
  useInviteMembers
} from 'src/inviteMembers/controller/useInviteMembers';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { InviteMembersForm } from 'src/components/InviteMembersForm/InviteMembersForm';
import { Galaxy } from 'galaxy';

const initialRecords: Array<InvitationRecord> = [
  { email: '', role: null },
  { email: '', role: null },
  { email: '', role: null }
];

const InviteMembersAccordion = ({
  markAsCompleted,
  onMemberInvited,
  showProgress,
  ...props
}: CommonAccordionProps & {
  onMemberInvited: () => void;
}): ReactElement => {
  const currentOrganization = useCurrentOrganizationOrThrow();
  const { canInviteMembers } = currentOrganization.restrictions;
  const pendingInvitationEmails = useMemo(
    () => Object.keys(currentOrganization.invitations),
    [currentOrganization.invitations]
  );

  const organizationEmails: string[] = useMemo(() => {
    return Object.values(currentOrganization.users).map((user) => user.email);
  }, [currentOrganization.users]);

  const {
    members,
    onChange,
    onSubmit: onSubmitProp,
    loading,
    error,
    onEmailBlur,
    onEmailFocus,
    disableSubmit,
    addNewEntry: addNewEntryProp,
    onDelete
  } = useInviteMembers({
    records: initialRecords,
    pendingInvitationEmails,
    organizationEmails,
    onSuccess: markAsCompleted
  });

  const onSubmit = async (): Promise<void> => {
    Galaxy.galaxy().track(
      'gettingStarted.inviteMembersAccordion.inviteMembersButtonClick',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    try {
      const submittedMembers = await onSubmitProp();
      const membersLength = submittedMembers.length;
      onMemberInvited();
      createToast({
        type: 'success',
        title: 'Members invited successfully',
        description:
          membersLength > 1
            ? `${membersLength} members were invited successfully`
            : `${submittedMembers[0]} was invited successfully`
      });
    } catch (e) {
      /* error would be shown in the block */
    }
  };

  const addNewEntry = (): void => {
    Galaxy.galaxy().track(
      'gettingStarted.inviteMembersAccordion.addNewMemberButtonClick',
      {
        interaction: 'click',
        from: showProgress ? 'main' : 'help'
      }
    );
    void addNewEntryProp();
  };

  return (
    <AccordionWrapper
      title="Invite team members"
      value="inviteMembers"
      {...props}
    >
      <Panel padding="sm">
        <Container maxWidth="800px" isResponsive={false} orientation="vertical">
          <InviteMembersForm
            error={error}
            canInviteMembers={canInviteMembers}
            description="Share your hosted ClickHouse account with other people from your team and grant them access to your service."
            members={members}
            onChange={onChange}
            onEmailBlur={onEmailBlur}
            onEmailFocus={onEmailFocus}
            fillWidth
            onDelete={onDelete}
          />
        </Container>
        <Container isResponsive={false} gap="md">
          <Button
            type="primary"
            iconLeft="plus"
            disabled={disableSubmit || loading || !canInviteMembers}
            onClick={onSubmit}
            loading={loading}
          >
            Invite members
          </Button>
          <Button onClick={addNewEntry} type="secondary">
            Add new entry
          </Button>
          {showProgress && !props.isCompleted && (
            <Button iconLeft="check" type="secondary" onClick={markAsCompleted}>
              Mark as completed
            </Button>
          )}
        </Container>
      </Panel>
    </AccordionWrapper>
  );
};

export default InviteMembersAccordion;
