import { css, SerializedStyles, Theme } from '@emotion/react';

export const optionStyle = css({
  '& > div': {
    paddingRight: 30
  },
  "&[data-state='checked']::after": {
    content: '"\\2713"',
    position: 'absolute',
    right: '1rem',
    color: 'inherit'
  }
});

export const popoverBtnStyle = (theme: Theme): SerializedStyles =>
  css({
    display: 'flex',
    alignItems: 'center',
    gap: 'inherit',
    width: 'auto',
    maxWidth: 200,
    justifyContent: 'space-between',
    borderRadius: 4,
    padding: 8,
    userSelect: 'none',
    '&:hover': {
      background: theme.global.color.background.muted
    }
  });

export const buttonContainer = css({
  padding: '0.5rem',
  width: '100%'
});
