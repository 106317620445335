import { ClickPipe, ClickPipeStatus, ClickPipesS3Status } from 'types/protocol';

export function getClickPipeStatus(
  pipe: ClickPipe,
  getNumberOfRecords: (pipe: ClickPipe) => string | null | undefined
): ClickPipeStatus | ClickPipesS3Status {
  if (
    (pipe.source.type === 's3' || pipe.source.type === 'gcs') &&
    (pipe.status === 'Provisioning' || pipe.status === 'Running')
  ) {
    const numberOfFiles = getNumberOfRecords(pipe);
    if (numberOfFiles === null || numberOfFiles === undefined || numberOfFiles === '0') {
      return 'Fetching';
    }
  }
  return pipe.status?.length === 0 ? 'Provisioning' : pipe.status ?? 'Unknown';
}
