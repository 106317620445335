import {
  SidebarNavigationItem,
  SidebarNavigationItemProps,
  Tooltip
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

export const CustomSidebarItem = styled(SidebarNavigationItem)<{
  $collapsible: boolean;
}>`
  &,
  &[data-selected='true'],
  &:hover,
  &:focus {
    @media screen and (max-width: 640px) {
      ${({ theme, $collapsible }) => `
    padding: ${theme.click.sidebar.navigation.item.default.space.y} ${
      theme.click.sidebar.navigation.item.default.space.right
    } ${theme.click.sidebar.navigation.item.default.space.y} ${
      $collapsible
        ? theme.click.sidebar.navigation.item.default.space.left
        : theme.click.image.sm.size.width
    };
    font: ${theme.click.sidebar.navigation.item.typography.default};
    gap: inherit;
    `}
    }
  }
`;

interface SidebarItemProps extends SidebarNavigationItemProps {
  path: string;
  collapsible?: boolean;
  disabled?: boolean;
  disabledTooltipMessage?: string;
}

export const SidebarItem = ({
  label,
  path,
  icon,
  collapsible = false,
  disabled = false,
  disabledTooltipMessage,
  ...props
}: SidebarItemProps): ReactElement => {
  const match = useMatch(path);
  const item = (
    <CustomSidebarItem
      type="main"
      disabled={disabled}
      icon={icon}
      label={label}
      selected={!!match}
      $collapsible={collapsible}
      {...props}
    />
  );

  if (disabled && disabledTooltipMessage) {
    return (
      <Tooltip>
        <Tooltip.Trigger>{item}</Tooltip.Trigger>
        <Tooltip.Content>{disabledTooltipMessage}</Tooltip.Content>
      </Tooltip>
    );
  } else {
    return item;
  }
};
