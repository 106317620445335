import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsButton } from 'src/components/Breadcrumbs/BreadcrumbsButton';

export type BreadCrumbLink =
  | {
      label: string;
      href: string;
      onClick: null;
    }
  | { label: string; onClick: () => void; href: null };

export function Breadcrumb({ link }: { link: BreadCrumbLink }): ReactElement {
  if (link.href) {
    if (link.href !== '#') {
      return <Link to={link.href}>{link.label}</Link>;
    } else {
      // link that doesn't redirect - it's just a label
      return <div>{link.label}</div>;
    }
  }

  const noop = (): void => {};
  const onClickHandler = link.onClick || noop;

  return (
    <BreadcrumbsButton onClick={onClickHandler} role="button" tabIndex={-1}>
      {link.label}
    </BreadcrumbsButton>
  );
}
