import { ReactElement, useCallback, useRef } from 'react';
import { Toolbar } from 'src/components/CreateTableView/Toolbar';
import { emptyTableState } from 'shared/src/tableSchema';
import { useSelectedTab, useUpdateTab } from 'src/state/tabs';
import { AdvancedTableParams } from 'src/components/CreateTableView/AdvancedTableParams';
import { Columns } from 'src/components/CreateTableView/Columns';
import {
  CreateTableProviderState,
  CreateTableState,
  EditTableProvider,
  useEditTable
} from 'src/components/CreateTableView/context';
import Footer from 'src/components/CreateTableView/Footer';
import { Section } from 'src/components/CreateTableView/Section';
import {
  advancedSettingsSection,
  scrollContainer,
  container,
  tableContainer
} from 'src/components/CreateTableView/styles';
import { TableParams } from 'src/components/CreateTableView/TableParams';
import { DangerAlert } from '@clickhouse/click-ui';
import styled from 'styled-components';
import { Preview } from 'src/components/CreateTableView/Preview';
import { css } from '@emotion/react';

const ErrorBox = styled(DangerAlert)`
  margin-top: 12px;
  flex-grow: 1;
`;
function QueryError(): ReactElement | null {
  const { queryError } = useEditTable();
  if (!queryError) {
    return null;
  } else {
    return <ErrorBox state="danger" text={queryError} data-testid="errorBox" />;
  }
}

const blankValue: CreateTableProviderState = {
  tableState: emptyTableState(''),
  setTableState: () => null,
  previewOpen: false,
  setPreviewOpen: () => null,
  queryError: undefined,
  setQueryError: () => null
};

const hAuto = css({
  height: 'auto'
});

export function CreateTableContent(): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div css={container}>
      <Toolbar />
      <div css={tableContainer} ref={ref}>
        <div css={[container, hAuto]}>
          <Section title="Table parameters">
            <TableParams />
          </Section>
          <div css={scrollContainer}>
            <Section title="Columns">
              <Columns allowDeletion />
            </Section>
            <div css={advancedSettingsSection}>
              <AdvancedTableParams />
            </div>
          </div>
          <QueryError />
          <Footer />
        </div>
        <Preview container={ref.current} />
      </div>
    </div>
  );
}

export function CreateTable(): ReactElement {
  const tab = useSelectedTab();
  const updateTab = useUpdateTab();

  let state: CreateTableState;
  if (!tab || tab.type !== 'createTable') {
    console.warn('create table tab not found!');
    const { tableState, previewOpen, queryError } = blankValue;
    state = { tableState, previewOpen, queryError };
  } else {
    state = tab.state;
  }

  const changeState = useCallback(
    (newState: CreateTableState) => {
      tab?.id &&
        updateTab(tab.id, {
          state: newState
        });
    },
    [tab?.id, updateTab]
  );

  return (
    <EditTableProvider state={state} onStateChange={changeState}>
      <CreateTableContent />
    </EditTableProvider>
  );
}
