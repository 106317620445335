import React, { ReactElement } from 'react';
import { ConfirmationDialog } from '@clickhouse/click-ui';
import { createToast } from 'primitives';
import { useDataImport } from 'src/dataloading/dataloadingController';
import { useCancelDataImport } from 'src/dataloading/useCancelDataImport';
import { errorMessage } from 'src/lib/errors/errorMessage';

type CancelDataImportConfirmDialogProps = {
  open: boolean;
  serviceId: string;
  dataImportId: string;
  onClose: () => void;
};

function CancelDataImportConfirmDialog({
  open,
  serviceId,
  dataImportId,
  onClose
}: CancelDataImportConfirmDialogProps): ReactElement {
  const { dataImport } = useDataImport(serviceId, dataImportId);
  const { loading, cancelDataImport } = useCancelDataImport({
    serviceId,
    dataImportId
  });

  const onConfirm = (): void => {
    cancelDataImport()
      .then(onClose)
      .catch((error) => {
        createToast('Error cancelling import', 'error', errorMessage(error));
        console.error(error);
      });
  };

  return (
    <ConfirmationDialog
      message={`Are you sure you want to cancel the import "${dataImport?.name}"?`}
      onCancel={onClose}
      onConfirm={onConfirm}
      loading={loading}
      open={open}
      primaryActionLabel="Confirm"
      secondaryActionLabel="Cancel"
      title={'Cancel Import'}
    />
  );
}

const MemoizedCancelDataImportConfirmDialog = React.memo(
  CancelDataImportConfirmDialog
);
MemoizedCancelDataImportConfirmDialog.displayName =
  'CancelDataImportConfirmDialog';
export default MemoizedCancelDataImportConfirmDialog;
