import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import useSWR, { SWRResponse } from 'swr';
import { CaseDetailsUpdatePayload, SupportCaseDetails } from '@cp/common/protocol/Support';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useApiClient } from 'src/lib/controlPlane/client';

export interface SupportStateProps extends SWRResponse<Array<SupportCaseDetails>, Error> {
  organizationId: string;
}

export const useSupportCases = (): SupportStateProps => {
  const api = useApiClient();

  const organizationId = useCurrentOrganizationOrThrow().id;

  const supportData = useSWR<Array<SupportCaseDetails>, Error>('fetchSupportList', async () => {
    return await api.support.listCases(organizationId);
  });
  useWebSocketsNotification<CaseDetailsUpdatePayload>({
    subscriptionDetails: {
      type: 'CASE_DETAILS_UPDATE',
      objId: organizationId
    },
    handler: ({ payload }) => {
      if (payload.details) {
        const supportCases = [...(supportData.data ?? [])];
        const selectedCaseIndex = supportCases.findIndex((supportCase) => supportCase.id === payload.details.id);
        if (selectedCaseIndex >= 0) {
          supportCases[selectedCaseIndex] = payload.details;
        }
        void supportData.mutate(supportCases);
      }
    }
  });

  return { ...supportData, organizationId };
};
