import { css } from '@emotion/react';
import { CodeBlock, Container, Label } from '@clickhouse/click-ui';

import { SchemaRegistryResponse } from 'types/protocol';

type Props = {
  enabled: boolean;
  schema: SchemaRegistryResponse;
  consumerGroup: string;
};

export default function Schema(props: Props): JSX.Element | null {
  if (!props.enabled || !props.schema || props.schema.schema === '') {
    return null;
  }

  const schema = {
    schema: JSON.stringify(JSON.parse(props.schema.schema), null, 2),
    schemaType: props?.schema?.schemaType ?? 'string'
  };

  const sampleCodeStyle = css({
    width: '100%',
    maxHeight: '250px',
    pre: {
      maxHeight: 'inherit'
    }
  });

  return (
    <>
      <Container style={{ marginBottom: '-10px' }}>
        <div style={{ width: '20%' }}>
          <Label>Schema</Label>
        </div>

        <div style={{ width: '80%', textAlign: 'right' }}>
          <Label>Consumer group: {props.consumerGroup}</Label>
        </div>
      </Container>

      <CodeBlock
        css={sampleCodeStyle}
        showLineNumbers
        language={schema.schemaType ?? 'string'}
        className="fs-exclude"
        data-testid="schema-syntax-highlighter"
      >
        {schema.schema}
      </CodeBlock>
    </>
  );
}
