import { Organization, OrganizationRole } from '@cp/common/protocol/Organization';
import { convertArrayToRecord, isEmptyRecord } from '@cp/common/utils/MiscUtils';
import { produce } from 'immer';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { useCurrentUser, useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';

const localCurrentOrganizationId = localStorage.getItem('currentOrganizationId') || '';

export type OrganizationState = {
  organizations: Record<string, Organization>;
  currentOrganizationId: string;
  isOrganizationStateInitialized: boolean;
};
export const organizationStateAtom = atom<OrganizationState>({
  organizations: {},
  currentOrganizationId: localCurrentOrganizationId,
  isOrganizationStateInitialized: false
});

export const useCurrentOrganization = (): Organization | undefined => {
  const { organizations, currentOrganizationId } = useOrganizationStateManager();
  return organizations[currentOrganizationId];
};

export const useCurrentOrganizationOrThrow = (): Organization => {
  const currentOrganization = useCurrentOrganization();

  if (!currentOrganization) {
    throw new Error('No current organization found');
  }

  return currentOrganization;
};

export function useOrganizationRole(orgId: string): OrganizationRole | null {
  const { organizations } = useOrganizationStateManager();
  const currentUser = useCurrentUser();

  if (!currentUser) {
    return null;
  }

  const org = organizations[orgId];
  const user = org?.users[currentUser.id];
  return user?.role || null;
}

interface OrganizationStateManager {
  organizations: Record<string, Organization>;
  currentOrganizationId: string;
  setCurrentOrganizationId: (currentOrganizationId: string) => void;
  assignCurrentOrganization: () => void;
  getOrganization: (id: string) => Organization | undefined;
  setOrganization: (o: Organization) => void;
  deleteOrganization: (id: string) => void;
  setOrganizations: (organizations: Array<Organization>) => void;
  updateOrganizations: (organizations: Array<Organization>) => void;
  switchCurrentOrganization: (id: string) => void;
  isOrganizationStateInitialized: boolean;
}
const useOrganizationStateManager = (): OrganizationStateManager => {
  const [organizationState, setOrganizationStateAtom] = useAtom(organizationStateAtom);
  const { setIsInstanceStateLoading } = useInstanceStateManager();

  const setOrganization = useCallback(
    (organization: Organization): void => {
      setOrganizationStateAtom((state) => setOrganizationInState(state, organization));
    },
    [setOrganizationStateAtom]
  );

  const setOrganizations = useCallback(
    (organizationsToSet: Array<Organization>): void => {
      setOrganizationStateAtom((state) => setOrganizationsInState(state, organizationsToSet));
    },
    [setOrganizationStateAtom]
  );

  const deleteOrganization = useCallback(
    (organizationId: string): void => {
      setOrganizationStateAtom((state) => deleteOrganizationInState(state, organizationId));
    },
    [setOrganizationStateAtom]
  );
  /** Update or set a part of the state. */
  const updateOrganizations = useCallback(
    (organiationsToSet: Array<Organization>): void => {
      setOrganizationStateAtom((state) => updateOrganizationsInState(state, organiationsToSet));
    },
    [setOrganizationStateAtom]
  );

  const getOrganization = useCallback(
    (organizationId: string): Organization | undefined => organizationState.organizations[organizationId],
    [organizationState.organizations]
  );

  const setCurrentOrganizationId = useCallback(
    (id: string): void => {
      localStorage.setItem('currentOrganizationId', id);
      setOrganizationStateAtom((state) => setCurrentOrganizationIdInState(state, id));
    },
    [setOrganizationStateAtom]
  );

  const assignCurrentOrganization = useCallback((): void => {
    setOrganizationStateAtom((state) => {
      const res = assignCurrentOrganizationIdInState(state);
      localStorage.setItem('currentOrganizationId', res.currentOrganizationId);

      if (organizationState.currentOrganizationId !== res.currentOrganizationId) {
        setIsInstanceStateLoading(true);
      }
      return res;
    });
  }, [setOrganizationStateAtom, setIsInstanceStateLoading, organizationState.currentOrganizationId]);

  const switchCurrentOrganization = useCallback(
    (id: string): void => {
      setCurrentOrganizationId(id);
      assignCurrentOrganization();
    },
    [assignCurrentOrganization, setCurrentOrganizationId]
  );

  return {
    organizations: organizationState.organizations,
    currentOrganizationId: organizationState.currentOrganizationId,
    assignCurrentOrganization,
    switchCurrentOrganization,
    setOrganization,
    deleteOrganization,
    setOrganizations,
    getOrganization,
    updateOrganizations,
    setCurrentOrganizationId,
    isOrganizationStateInitialized: organizationState.isOrganizationStateInitialized
  };
};

export const setOrganizationsInState = (
  state: OrganizationState,
  organizations: Array<Organization>
): OrganizationState =>
  produce(state, (draft) => {
    draft.organizations = {
      ...convertArrayToRecord(organizations)
    };
    draft.isOrganizationStateInitialized = true;
  });

export const setOrganizationInState = (state: OrganizationState, organization: Organization): OrganizationState =>
  produce(state, (draft) => {
    draft.organizations[organization.id] = organization;
  });

export const useCurrentOrgUserRole = (): OrganizationRole => {
  const { users } = useCurrentOrganizationOrThrow();
  const { id } = useCurrentUserOrThrow();

  return Object.values(users).find((user) => user.userId === id)?.role ?? 'DEVELOPER';
};

export const useCurrentOrgPaymentMethodCaptured = (): boolean => {
  const { paymentDetails } = useCurrentOrganizationOrThrow();
  return paymentDetails.paymentMethodCaptured;
};

export const updateOrganizationsInState = (
  state: OrganizationState,
  organizations: Array<Organization>
): OrganizationState =>
  produce(state, (draft) => {
    for (const org of organizations) {
      draft.organizations[org.id] = org;
    }
  });

export const deleteOrganizationInState = (state: OrganizationState, organizationId: string): OrganizationState =>
  produce(state, (draft) => {
    delete draft.organizations[organizationId];
  });

export const setCurrentOrganizationIdInState = (
  state: OrganizationState,
  currentOrganizationId: string
): OrganizationState =>
  produce(state, (draft) => {
    draft.currentOrganizationId = currentOrganizationId;
  });

export const assignCurrentOrganizationIdInState = (state: OrganizationState): OrganizationState =>
  produce(state, (draft) => {
    const organizations = draft.organizations;
    const currentOrganizationId = draft.currentOrganizationId;

    if (isEmptyRecord(organizations)) {
      return;
    }

    if (!currentOrganizationId || !organizations[currentOrganizationId]) {
      const newCurrentOrganizationId = Object.values(organizations).sort((a, b) => b.createdAt - a.createdAt)[0].id;
      draft.currentOrganizationId = newCurrentOrganizationId;
    }
  });
export { useOrganizationStateManager };
