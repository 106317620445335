export function underscoreName(fileName: string): string {
  return fileName.replace(/[^a-zA-Z0-9]+/g, '_');
}

export function getFileNameWithoutExtension(uri: string): string {
  const fileNameRegex = /[^/\\&\?]+\.\w{1,4}(?=([\?&].*$|$))/i;
  const match = uri.match(fileNameRegex);

  if (!match) {
    throw new Error('File name not found in the provided URI.');
  }

  const fileNameWithExtension = match[0];
  const fileName = fileNameWithExtension.replace(/\.[^/.]+$/, '').replace(/\.[^/.]+$/, '');
  return underscoreName(fileName);
}
