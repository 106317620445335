import { ReactElement, ReactNode, useEffect } from 'react';

import { useClickHouseMetadataWithCacheLoader } from 'src/metadata/useClickHouseMetadataWithCacheLoader';
import {
  DatabaseMetadata,
  useSetMetadataLoaderVisible
} from 'src/metadata/metadataState';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import { logger } from 'src/lib/logger';
import {
  useCurrentInstance,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import {
  LoadMetadataEvent,
  metadataEventEmitter
} from 'src/components/MetadataInitializer/metadataEmitter';
import { LoadMetadataOptions } from 'src/metadata/types';

export interface Metadata {
  error?: string | null;
  loading: boolean;
  metadata?: DatabaseMetadata;
  reload: (cached?: boolean) => Promise<void>;
}

type MetadataContextProps = {
  children: ReactNode;
};

export const MetadataInitializerWithCache = ({
  children
}: MetadataContextProps): ReactElement => {
  const { loadMetadata } = useClickHouseMetadataWithCacheLoader();
  const selectedDatabase = useSelectedDatabaseValue();
  const { isAsleep, isDeleted, isStopped } = useIsCurrentInstanceAwakeStatus();
  const setMetadataLoaderVisible = useSetMetadataLoaderVisible();
  const currentInstance = useCurrentInstance();
  const shouldLoadMetadataFromCache = isAsleep;

  useEffect(() => {
    const handler = (event: LoadMetadataEvent): void => {
      if (isDeleted || isStopped) {
        // do not load any metadata
        return;
      }

      const options: LoadMetadataOptions = {
        ...event,
        cached:
          typeof event.cached !== 'undefined'
            ? event.cached
            : shouldLoadMetadataFromCache
      };

      loadMetadata(options).catch((error) =>
        console.error('Could not fetch metadata', error)
      );

      logger.track({
        view: 'app',
        event: 'refreshMetadataOnDatabaseChange',
        component: 'MetadataContext.Provider',
        interaction: 'trigger'
      });
    };

    metadataEventEmitter.on(handler);

    return () => {
      metadataEventEmitter.off(handler);
    };
  }, [loadMetadata, isDeleted, isStopped, shouldLoadMetadataFromCache]);

  useEffect(() => {
    if (isDeleted || isStopped) {
      // do not load any metadata
      return;
    }

    const loadMetadataWithLastSelectedDatabase = async (): Promise<void> => {
      setMetadataLoaderVisible(true);
      console.log('loading last selected database', selectedDatabase);
      // in case they are the same, we need to reload the metadata because we might be in a different service where the metadata is not loaded
      console.debug('MetadataInitializer: Loading database schema');

      if (!currentInstance) {
        console.error('no current service');
        return;
      }

      loadMetadata({
        cached: shouldLoadMetadataFromCache,
        database: selectedDatabase,
        serviceId: currentInstance.id
      }).catch(console.error);
    };
    // This hook will be fired whenever the MetadataInitializer is mounted, or whenever the status of the instance changes to AWAKE
    loadMetadataWithLastSelectedDatabase().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInstance?.id, selectedDatabase]);

  return <>{children}</>;
};
