import {
  Button,
  Container,
  Dialog,
  Separator,
  Text,
  Link,
  Spacer,
  Alert
} from '@clickhouse/click-ui';
import { InstanceMaintenanceWindow } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';

interface StartSMTMigrationContentProps {
  instanceName: string;
}

export const StartSMTMigrationContent = ({
  instanceName
}: StartSMTMigrationContentProps): ReactElement => {
  return (
    <>
      <Text size="md">
        Starting this process will migrate <b>{instanceName}</b> from using the
        ReplicatedMergeTree engine to using the SharedMergeTree engine.{' '}
        <Link
          href="https://clickhouse.com/blog/clickhouse-cloud-boosts-performance-with-sharedmergetree-and-lightweight-updates"
          target="_blank"
          data-testid="start-maintenance-modal-more-link"
        >
          Learn more
        </Link>
      </Text>
      <Spacer size="md"></Spacer>
      <Text>
        This maintenance process will involve downtime. Your service will be
        unavailable during the migration. Do you want to perform the migration
        now?
      </Text>
      <Spacer size="md"></Spacer>
      <Alert
        showIcon={true}
        size="medium"
        state="info"
        text="This maintenance process will involve downtime. During maintenance, your service will be stopped and unavailable until the migration is completed.  The estimated time to complete the maintenance is typically 5-10 minutes; however, it can take up to 1 hour for larger services."
      ></Alert>
    </>
  );
};

interface StartMigrationModalProps {
  instanceName: string;
  maintenanceWindow: InstanceMaintenanceWindow;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export const StartMigrationModal = ({
  onCancel,
  onConfirm,
  open,
  maintenanceWindow,
  instanceName
}: StartMigrationModalProps): ReactElement | null => {
  if (!maintenanceWindow || maintenanceWindow.migrationKind !== 'SMT') {
    // The only migration that we have today that requires the user to start the migration is SMT.
    // In the future if we'll have more migrations that require the user to trigger them we should remove/extend this
    // check and create a dedicated dialogContent for that migration.
    return null;
  }
  const title = maintenanceWindow.maintenanceInfoName
    ? `Maintenance - ${maintenanceWindow.maintenanceInfoName}`
    : 'Start migration';

  const dialogContent: ReactElement = (
    <StartSMTMigrationContent
      instanceName={instanceName}
    ></StartSMTMigrationContent>
  );
  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
        }
      }}
    >
      <Dialog.Content
        title={title}
        showClose
        onClose={onCancel}
        data-testid="start-migration-modal"
      >
        {dialogContent}
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Cancel" onClick={onCancel} />
          <Button onClick={onConfirm} data-testid="start-migration-button">
            Perform maintenance
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
