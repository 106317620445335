import { atom, useAtom, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { OnRangeChangeArgs } from 'src/lib/query/RowCache/RowIndexes';

// Create a Jotai atom to store the cached query view results
const cachedResultsViewAtom = atom<{ [key: string]: OnRangeChangeArgs }>({});

type UseCacheQueryViewResultsType = [OnRangeChangeArgs | null, (data: OnRangeChangeArgs) => void];

export function useCacheQueryViewResults(queryId: string | null): UseCacheQueryViewResultsType {
  const [resultsView, setResultsView] = useAtom(cachedResultsViewAtom);
  const cachedQueryViewResults = queryId ? resultsView[queryId] ?? null : null;
  const setCacheQueryViewResults = useCallback(
    (data: OnRangeChangeArgs): void => {
      if (queryId) {
        setResultsView((cachedResults) => ({
          ...cachedResults,
          [queryId]: data
        }));
      }
    },
    [setResultsView, queryId]
  );
  return useMemo(
    () => [cachedQueryViewResults, setCacheQueryViewResults],
    [cachedQueryViewResults, setCacheQueryViewResults]
  );
}

/**
 * Hook that returns a function to delete the cached query view results for a given query run ID
 */
export function useDeleteResultsFromCache(): (queryRunId: string | null | undefined) => void {
  const setCachedResultsView = useSetAtom(cachedResultsViewAtom);

  return useCallback(
    (queryRunId: string | null | undefined): void => {
      if (!queryRunId) {
        // Do not update the atom if the query run ID is null or undefined
        return;
      }
      setCachedResultsView((cachedResults) => {
        if (!cachedResults[queryRunId]) {
          // Do not update the atom if the query run ID is not in the cache
          return cachedResults;
        }
        const newCachedResults = { ...cachedResults };
        delete newCachedResults[queryRunId];
        return newCachedResults;
      });
    },
    [setCachedResultsView]
  );
}
