import { POCRequest } from '@cp/common/protocol/Account';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { createToast } from 'src/components/primitives';
import { useRecaptcha } from 'src/components/RecaptchaProvider';
import { useApiClient } from 'src/lib/controlPlane/client';

interface ReturnProps {
  openPOCDialog: boolean;
  setOpenPOCDialog: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  sendRequestPOC: (value: Omit<POCRequest, 'recaptchaToken' | 'rpcAction'>) => Promise<void>;
}

const useRequestPOC = (): ReturnProps => {
  const [openPOCDialog, setOpenPOCDialog] = useState(false);
  const api = useApiClient();
  const [loading, setLoading] = useState(false);
  const getRecaptchaToken = useRecaptcha();
  const sendRequestPOC = useCallback(
    async (requestContent: Omit<POCRequest, 'recaptchaToken' | 'rpcAction'>) => {
      try {
        setLoading(true);
        const recaptchaToken = await getRecaptchaToken('poc');
        if (!recaptchaToken) {
          createToast('Error', 'error', 'Invalid Captcha');
          return;
        }

        await api.account.sendPOC({ ...requestContent, recaptchaToken });
        setOpenPOCDialog(false);
        createToast(
          'Thank you for your request',
          'success',
          'Our team will review your proof of concept request and reach out to you shortly.'
        );
      } catch (e) {
        createToast(
          'Unable to send request',
          'error',
          'Due to some issues we were unable to send the request. Kindly try again later'
        );
      } finally {
        setLoading(false);
      }
    },
    [api.account, getRecaptchaToken]
  );

  return {
    openPOCDialog,
    loading,
    sendRequestPOC,
    setOpenPOCDialog
  };
};

export default useRequestPOC;
