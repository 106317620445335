import { logger, InteractionType } from 'src/lib/logger';

export function sidebarLogEvent(name: string, type: string, interaction: InteractionType = 'click') {
  logger.track({
    view: 'sidebar',
    component: 'queryList',
    event: `${name}${type}`,
    interaction: interaction
  });
}

export function logCreateFolder() {
  sidebarLogEvent('queryContextMenu', 'FolderCreate');
}

export function onContextMenuToggle(open: boolean) {
  sidebarLogEvent('queryContextMenu', open ? 'Open' : 'Blur', 'trigger');
}

export function logEvent(component: string, event: string, interaction: InteractionType = 'click') {
  logger.track({
    view: 'queryView',
    component,
    event,
    interaction
  });
}
