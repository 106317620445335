import { ReactElement } from 'react';
import { RoleSelector } from 'src/components/RoleSelector';
import { Text, Container } from '@clickhouse/click-ui';

type QueryEndpointsRolesFormProps = {
  setRoles: (roles: Array<string>) => void;
  roles: Array<string>;
};

export function QueryEndpointsRolesForm({
  setRoles,
  roles
}: QueryEndpointsRolesFormProps): ReactElement {
  return (
    <Container gap="sm" orientation="vertical">
      <Text color="muted">
        Select the Database role that will be used to run the query
      </Text>
      <RoleSelector
        onSelect={setRoles}
        extraRoles={[
          {
            value: 'sql_console_admin',
            label: 'Full access'
          },
          {
            value: 'sql_console_read_only',
            label: 'Read only'
          }
        ]}
        value={roles}
      />
    </Container>
  );
}
