import { useMemo } from 'react';

import { editor, Range } from 'monaco-editor';

import { StatementRange } from 'src/lib/sql/parse/statementRanges';

export const flashTimeoutSeconds = 1;

export function useErrorDecoration(
  model: editor.ITextModel | undefined,
  errorRange?: StatementRange
): editor.IModelDeltaDecoration[] {
  const decorations = useMemo(
    function memoizedDecorations(): editor.IModelDeltaDecoration[] {
      if (!model || !errorRange) {
        return [];
      }

      return [
        {
          range: Range.fromPositions(model.getPositionAt(errorRange.start), model.getPositionAt(errorRange.end)),
          options: {
            inlineClassName: 'error-statement'
          }
        }
      ];
    },
    [model, errorRange]
  );

  return decorations;
}
