import { MouseEventHandler, ReactElement } from 'react';
import { Button, ButtonProps } from '@clickhouse/click-ui';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { getCurrentServiceId } from 'src/state/service';

function NewCaseButton({
  onClick: onClickProp,
  ...props
}: ButtonProps): ReactElement {
  const serviceId = getCurrentServiceId();
  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (typeof onClickProp === 'function') {
      onClickProp(e);
    }
    navigateTo(routes.newSupportCase({ serviceId }));
  };
  return (
    <Button iconLeft="plus" onClick={onClick} {...props}>
      New case
    </Button>
  );
}

export default NewCaseButton;
