import { Icon, MultiSelect, TextField, Tooltip } from '@clickhouse/click-ui';
import { createToast } from 'primitives';
import { ReactElement, useEffect, useRef, useState } from 'react';

import {
  getColumn,
  useEditTable
} from 'src/components/CreateTableView/context';
import { useVisited } from 'src/components/CreateTableView/useVisited';

import { alignCenter, flex, gapSm } from 'src/lib/utility-styles';
import { SortKey } from 'shared/src/tableSchema';
import { standardQuoteIdentifier } from 'shared/src/tableSchema/escape';
import { useTableNames } from 'src/metadata/metadataState';
import DestinationTableSettings from 'src/components/ImportView/ImportForm/DestinationTableSettings';
import styled from 'styled-components';

export const MultiColumn = styled(MultiSelect)`
  grid-column: span 2 / span 2;
  min-width: calc(50% - 0.25rem);
  width: 100%;
  max-width: fit-content;
`;

export function TableParams(): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const { tableState, setTableState } = useEditTable();
  const [nameVisited, visitName] = useVisited();

  const tableNames = useTableNames();

  const setName = (newName: string): void => {
    const withoutWhitespace = newName.replace(/\s/g, '');
    setTableState({
      ...tableState,
      name: withoutWhitespace
    });
  };

  const columns = tableState.columnIds
    .filter((columnId) => {
      const column = getColumn(tableState, columnId);
      return (
        column &&
        column.name.length > 0 &&
        !column.type.startsWith('Nullable(') &&
        !column.removed
      );
    })
    .map((id) => {
      const column = tableState.columns[id];
      return {
        id,
        name: column.name,
        value: standardQuoteIdentifier(column.name)
      };
    });

  const [selectedColumns, setSelectedColumns] = useState(
    tableState.orderBy.flatMap((value) => {
      const colName = columns.find((column) => column.id === value.id)?.name;
      if (!colName) {
        return [];
      }
      if (value.type !== 'expression') {
        return colName;
      }
      return `${value.expression}(${colName})`;
    })
  );

  const sortKeyLabel: JSX.Element = (
    <Tooltip>
      <Tooltip.Trigger css={[flex, alignCenter, gapSm]}>
        <span>Sorting key</span> <Icon name="info-in-circle" size="xs" />
      </Tooltip.Trigger>
      <Tooltip.Content side="top">
        Select a field or enter an expression to add it to the sorting key. Drag
        fields in the sorting key to re-order them
      </Tooltip.Content>
    </Tooltip>
  );

  useEffect(() => {
    setTimeout(() => {
      inputRef.current &&
        (tableState.name.length === 0 ||
          tableNames.includes(tableState.name)) &&
        inputRef.current.focus();
    }, 0);
  }, [tableNames, tableState.name]);

  const addSortKey = (values: string[]): void => {
    const sortList: string[] = [];
    const orderBy: SortKey = values.flatMap((value) => {
      const match = value.match(/^([_a-zA-Z][_a-zA-Z0-9]*)\((.*)\)$/);
      if (match) {
        const expression = match[1];
        const colName = match[2];
        const column = columns.find((col) => col.name === colName);
        if (!column) {
          createToast(
            'error',
            'alert',
            `Invalid expression. ${colName} does not exist`
          );
          return [];
        }
        sortList.push(`${expression}(${colName})`);
        return { id: column.id, type: 'expression', expression };
      } else {
        const column = columns.find((col) => col.name === value);
        if (column) {
          sortList.push(column.name);
          return {
            id: column.id,
            type: 'column'
          };
        } else {
          createToast('error', 'alert', 'Invalid expression or column');
          return [];
        }
      }
    });

    setTableState({
      ...tableState,
      orderBy
    });
    setSelectedColumns(sortList);
  };

  return (
    <DestinationTableSettings>
      <TextField
        label="Name"
        ref={inputRef}
        value={tableState.name}
        onBlur={visitName}
        onChange={setName}
        placeholder="Enter table name"
        width={200}
        error={
          tableNames.includes(tableState.name)
            ? 'Table already exists'
            : nameVisited && tableState.name.length === 0
        }
        className="fs-exclude"
      />

      <MultiColumn
        label={sortKeyLabel}
        value={selectedColumns}
        showSearch
        sortable
        onSelect={addSortKey}
        data-testid="sort-key-selector-button"
        allowCreateOption
        className="fs-exclude"
      >
        {columns.map((column) => (
          <MultiSelect.Item
            value={column.name}
            key={column.name}
            className="fs-exclude"
          >
            {column.name}
          </MultiSelect.Item>
        ))}
      </MultiColumn>
    </DestinationTableSettings>
  );
}
