import {
  Container,
  ContainerProps,
  Separator,
  Title,
  TitleProps
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';

export const Subsection = (props: ContainerProps): ReactElement => (
  <Container fillWidth alignItems="start" gap="xl" {...props} />
);

export const Section = (props: ContainerProps): ReactElement => (
  <Container orientation="vertical" {...props} />
);

export const SectionTitle = (props: Partial<TitleProps>): ReactElement => (
  <Title type="h2" {...props} />
);

export const SubsectionTitle = (props: Partial<TitleProps>): ReactElement => (
  <Title type="h2" size="sm" {...props} />
);

export const NarrowColumn = (props: ContainerProps): ReactElement => (
  <Container orientation="vertical" fillWidth maxWidth="34%" {...props} />
);

export const Column = (props: ContainerProps): ReactElement => (
  <Container orientation="vertical" {...props} />
);

export const SectionSeparator = (): ReactElement => (
  <Container>
    <Separator size="md" />
  </Container>
);

export const SubsectionDescription = NarrowColumn;

export const SubsectionControls = Column;
