import { IconButton } from '@clickhouse/click-ui';

import styled from 'styled-components';

// TODO: Whenever we have the info icon button in ClickUI replace any occurrence of this component
// https://github.com/ClickHouse/control-plane/issues/5639
const InfoIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.click.feedback.color.info.background};
  color: ${({ theme }) => theme.click.feedback.color.info.foreground};
  border-color: ${({ theme }) => theme.click.feedback.color.info.foreground};
  &:hover {
    color: ${({ theme }) => theme.click.feedback.color.info.foreground};
    border-color: ${({ theme }) => theme.click.feedback.color.info.foreground};
    background: ${({ theme }) => theme.click.feedback.color.info.background};
    filter: brightness(0.95);
  }
`;

export default InfoIconButton;
