import { Spacer } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import useNetworkSettings from 'src/instance/controller/useNetworkSettings';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { DbAccessSection } from 'src/pages/SettingsPage/DbAccessSection';
import { GptAccessSection } from 'src/pages/SettingsPage/GptAccessSection';
import { NetworkingSectionView } from 'src/pages/SettingsPage/NetworkingSectionView';
import { NetworkSecurityInformationSection } from 'src/pages/SettingsPage/NetworkSecurityInformation';
import { PrivateEndpointSection } from 'src/pages/SettingsPage/PrivateEndpointSection';
import { QueryAPIEndpointsSection } from 'src/pages/SettingsPage/QueryAPIEndpointsSection';
import { useServiceFeatureFlag } from 'src/state/service';

export const NetworkingSection = ({
  title
}: {
  title: string;
}): ReactElement | null => {
  const currentInstance = useCurrentInstanceOrThrow();
  const {
    networkType: type,
    onSubmit,
    ipList
  } = useNetworkSettings(currentInstance.id);

  const hasGenenericQueryApiEndpoint = useServiceFeatureFlag(
    'FT_INSTANCE_GENERIC_QUERY_API_ENDPOINT'
  );

  return (
    <>
      <NetworkingSectionView
        title={title}
        ipsAccessList={ipList}
        onSubmit={onSubmit}
        selectedIpAccessOption={type}
      />
      <Spacer size="lg" />
      <PrivateEndpointSection />
      <Spacer size="lg" />
      <DbAccessSection />
      <Spacer size="lg" />
      <GptAccessSection />

      {hasGenenericQueryApiEndpoint && (
        <>
          <Spacer size="lg" />
          <QueryAPIEndpointsSection serviceId={currentInstance.id} />
        </>
      )}

      <Spacer size="lg" />
      <Spacer size="lg" />
      <Spacer size="lg" />
      <NetworkSecurityInformationSection
        cloudProvider={currentInstance.cloudProvider}
      />
    </>
  );
};
