import { Accordion, Select, TextField } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { TableState } from 'shared/src/tableSchema';
import {
  TableEngineConfig,
  TableEngineType
} from 'shared/src/tableSchema/tableEngine';
import { useEditTable } from 'src/components/CreateTableView/context';
import { EngineParams } from 'src/components/CreateTableView/EngineParams';
import {
  advancedSettingsContentStyle,
  tableParamsStyle
} from 'src/components/CreateTableView/styles';

const engines: TableEngineType[] = [
  'MergeTree',
  'ReplacingMergeTree',
  'SummingMergeTree'
];

function blankEngine(type: string): TableEngineConfig {
  switch (type) {
    case 'MergeTree':
      return { type };
    case 'ReplacingMergeTree':
      return { type, versionColumnId: '' };
    case 'SummingMergeTree':
      return { type, columnIds: [] };
    case 'AggregatingMergeTree':
      return { type };
    case 'CollapsingMergeTree':
      return { type, signColumnId: '' };
    case 'VersionedCollapsingMergeTree':
      return { type, signColumnId: '', versionColumnId: '' };
    default: {
      return { type: 'MergeTree' };
    }
  }
}

export const AdvancedTableParams = (): ReactElement | null => {
  const { tableState, setTableState } = useEditTable();

  const change = (change: Partial<TableState>): void => {
    setTableState((oldState) => ({
      ...oldState,
      ...change
    }));
  };

  const setEngine = (type: string): void => {
    if (type !== tableState.engine.type) {
      setTableState({
        ...tableState,
        engine: blankEngine(type)
      });
    }
  };

  return (
    <Accordion title="Advanced settings">
      <div css={advancedSettingsContentStyle}>
        <div css={tableParamsStyle}>
          <TextField
            label="Partition by"
            value={tableState.partitionBy}
            placeholder="Partitioning key expression"
            onChange={(partitionBy: string): void => change({ partitionBy })}
            data-testid="tableOption-Partition by"
            className="fs-exclude"
          />
          <TextField
            label="Primary key"
            value={tableState.primaryKey}
            placeholder="Primary key expression"
            onChange={(primaryKey: string): void => change({ primaryKey })}
            data-testid="tableOption-Primary key"
            className="fs-exclude"
          />
          <Select
            label="Engine"
            value={tableState.engine.type}
            onSelect={setEngine}
            data-testid="tableOption-Engine"
          >
            {engines.map((name) => (
              <Select.Item key={name} value={name}>
                {name}
              </Select.Item>
            ))}
          </Select>
        </div>
        <div css={tableParamsStyle}>
          <EngineParams />
        </div>
      </div>
    </Accordion>
  );
};
