import { ReactElement, useCallback, useState } from 'react';

import { errorMessage } from 'src/lib/errors/errorMessage';
import { useQueryActions } from 'src/lib/query/QueryState';
import { createToast } from 'primitives';
import { CodeBlock, ConfirmationDialog, Text } from '@clickhouse/click-ui';
import styled from 'styled-components';

interface ConfirmDeleteProps {
  open: boolean;
  queryId: string;
  queryText: string;
  onClose: () => void;
}

const CustomCodeBlock = styled(CodeBlock)`
  max-height: 400px;
  overflow-y: auto;
`;

export function ConfirmCancelQuery({
  open,
  queryId,
  queryText,
  onClose
}: ConfirmDeleteProps): ReactElement<ConfirmDeleteProps> {
  const [isCancelling, setIsCancelling] = useState(false);
  const { cancelQuery } = useQueryActions();

  const onSubmit = useCallback(async () => {
    setIsCancelling(true);
    try {
      await cancelQuery(queryId);
    } catch (e) {
      createToast('error', 'alert', errorMessage(e));
    } finally {
      setIsCancelling(false);
    }
    onClose();
  }, [cancelQuery, onClose, queryId]);

  return (
    <ConfirmationDialog
      title="Terminate this query?"
      onConfirm={onSubmit}
      onCancel={onClose}
      open={open}
      primaryActionLabel="Kill Query"
      secondaryActionLabel="Cancel"
      loading={isCancelling}
    >
      <>
        <Text>Are you sure you want to kill this running query?</Text>
        <CustomCodeBlock
          theme="dark"
          language="sql"
          showLineNumbers
          wrapLines
          className="running-query-code fs-exclude"
        >
          {queryText}
        </CustomCodeBlock>
      </>
    </ConfirmationDialog>
  );
}
