import { css, Theme, SerializedStyles } from '@emotion/react';

export const queryListInfoStyle = css({
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  height: 'inherit',
  maxHeight: 'inherit'
});

export const queryListInfoReg = (theme: Theme): SerializedStyles =>
  css({
    display: 'flex',
    justifyContent: 'center',
    color: theme.global.color.text.muted,
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400
  });
export const confirmKillQuerySqlStyle = (theme: Theme): SerializedStyles =>
  css({
    borderRadius: '4px',
    marginTop: '12px',
    overflowY: 'scroll',
    maxHeight: '310px',
    border: `1px solid ${theme.global.color.stroke.default}`,
    '&>pre': {
      margin: '0 !important'
    }
  });

export const runningStatusText = css({
  width: 180,
  display: 'flex',
  justifyItems: 'flex-end',
  textAlign: 'left'
});

export const runningBadge = css({
  width: 100
});
