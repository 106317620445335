import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import TableView from 'src/components/TableView';
import { Table, TableDetails } from 'shared/src/clickhouse/types';
import { DataImportModel } from 'src/dataloading/models';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { ApiClient, useApiClient } from 'src/lib/controlPlane/client';
import { Credential, useCredentials } from 'src/state/connection';
import {
  useDatabases,
  useMaterializedViews,
  useMetadataLoading,
  useTables,
  useViews
} from 'src/metadata/metadataState';
import {
  getDecodedTableAndDatabaseFromParams,
  useSelectedTab,
  useTabActions
} from 'src/state/tabs';
import { TableTab, ViewParameterValues } from 'src/state/tabs/types';
import { usePlaceholderParams } from 'src/lib/routes/usePlaceholderParams';
import Helmet from 'src/components/Helmet/Helmet';
import useSWR from 'swr';

interface TableViewWithTableDetailsProps {
  tab: TableTab;
}

const fetcher =
  (
    api: ApiClient,
    serviceId: string,
    database: string,
    table: string,
    credentials: Credential
  ) =>
  (): Promise<TableDetails | null> => {
    return api.fetchTableDetails(serviceId, database, table, credentials);
  };

function TableViewWithTableDetails({
  tab
}: TableViewWithTableDetailsProps): ReactElement | null {
  const serviceId = useCurrentInstanceOrThrow().id;
  const api = useApiClient();
  const credentials = useCredentials();
  const database = tab.table.schema;
  const table = tab.table.tableName;

  const { isLoading, data, error } = useSWR(
    credentials.connected
      ? `${serviceId}-${tab.table.schema}-${tab.table.tableName}`
      : null,
    fetcher(api, serviceId, database, table, credentials)
  );

  if (isLoading || !data) {
    return null;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return <TableView tab={tab} selectedTableDetails={data} />;
}

const TablePage = (): ReactElement | null => {
  const { addTableTab } = useTabActions();
  const params = useParams();
  const [placeholderParams] = usePlaceholderParams();
  const databases = useDatabases();
  const metadataLoading = useMetadataLoading();
  const metadataTables = useTables();
  const views = useViews();
  const materializedViews = useMaterializedViews();

  const [databaseName, tableName] =
    getDecodedTableAndDatabaseFromParams(params);

  const tables: Table[] | null = useMemo(() => {
    return metadataLoading
      ? []
      : [...metadataTables, ...materializedViews, ...views];
  }, [metadataLoading, metadataTables, materializedViews, views]);
  const tabFoundRef = useRef(false);

  const selectedTab = useSelectedTab();

  const viewParametersRef = useRef<ViewParameterValues>({});
  useEffect(() => {
    viewParametersRef.current = placeholderParams;
  }, [placeholderParams]);

  useEffect(() => {
    if (!metadataLoading && databases.length > 0) {
      const table = tables.find(
        (t) => t.tableName === tableName && t.schema === databaseName
      );

      if (!tabFoundRef.current && table && !selectedTab) {
        addTableTab(table, { parameters: viewParametersRef.current });
      }
    }

    if (selectedTab) {
      tabFoundRef.current = true;
    }
  }, [
    tableName,
    databaseName,
    selectedTab,
    tables,
    metadataLoading,
    databases
  ]);

  if (selectedTab?.type !== 'table') {
    return null;
  } else {
    return (
      <>
        <Helmet>
          <title>{selectedTab.title}</title>
        </Helmet>
        <TableViewWithTableDetails key={selectedTab.id} tab={selectedTab} />
      </>
    );
  }
};

export default TablePage;
