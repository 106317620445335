import { Container, Icon, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';

import ClickPipesList from 'src/components/ClickPipesList';
import DataImports from 'src/components/DataImports';
import Helmet from 'src/components/Helmet/Helmet';
import ImportTypeSelector from 'src/components/ImportView/ImportTypeSelector';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import SplitLayout from 'src/layout/SplitLayout';
import { useApiClient } from 'src/lib/controlPlane/client';
import { routes } from 'src/lib/routes';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import useSWR from 'swr';
import { BareFetcher, KeyedMutator, PublicConfiguration } from 'swr/_internal';
import { ClickPipe } from 'types/protocol';

type FetcherKey = { url: string; serviceId: string };

type PipesResponse = {
  isLoading: boolean;
  data?: ClickPipe[];
  error?: Error;
  mutate: KeyedMutator<Array<ClickPipe>>;
};

function fetcherKey(serviceId: string): FetcherKey {
  return { url: '/api/getPipes', serviceId: serviceId };
}

function useClickPipesImports(
  serviceId: string,
  options: Partial<
    PublicConfiguration<ClickPipe[], Error, BareFetcher<ClickPipe[]>>
  >
): PipesResponse {
  const api = useApiClient();
  const fetcher = ({ serviceId }: FetcherKey): Promise<ClickPipe[]> => {
    return api.listClickPipes({ serviceId });
  };

  return useSWR(fetcherKey(serviceId), fetcher, options);
}

const ImportsPage = (): ReactElement => {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const params = useParams();
  const { serviceId } = params;

  if (!serviceId) {
    throw new Error('Unexpected error. Missing serviceId');
  }

  if (!hasManageServicePermission) {
    navigateTo(routes.home({ serviceId }));
  }

  const { data, error, isLoading, mutate } = useClickPipesImports(serviceId, {
    // a bit lower than metric refresh interval in ClickPipesList
    refreshInterval: 15_000
  });

  return (
    <>
      <MainPaneLayout>
        <MainPaneLayout.Topbar label="Data Sources" />
        <MainPaneLayout.Content>
          <Helmet>
            <title>Imports</title>
          </Helmet>
          <SplitLayout>
            <Container padding="lg">
              <Container orientation="vertical" gap="lg">
                <ImportTypeSelector />
                {isLoading && (
                  <Container gap="sm">
                    <Icon name="horizontal-loading" size="sm" />
                    <Text color="muted">Loading</Text>
                  </Container>
                )}

                {error && (
                  <Container gap="sm">
                    <Icon name="warning" size="sm" />
                    <Text color="muted">Failed to load ClickPipes</Text>
                  </Container>
                )}

                <Container orientation="vertical" gap="lg">
                  {!isLoading &&
                    !error &&
                    data !== undefined &&
                    data?.length > 0 && (
                      <>
                        <ClickPipesList
                          pipes={data}
                          serviceId={serviceId}
                          mutate={mutate}
                        />
                      </>
                    )}
                  <DataImports serviceId={serviceId} />
                </Container>
              </Container>
            </Container>
          </SplitLayout>
        </MainPaneLayout.Content>
      </MainPaneLayout>
    </>
  );
};

export default ImportsPage;
