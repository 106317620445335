import { Select } from '@clickhouse/click-ui';
import { useEffect } from 'react';
import {
  ClickPipeKafkaType,
  KafkaSaslMechanism,
  getSupportedAndDefaultSaslMechanisms
} from 'shared/src/dataLoading';

interface SaslMechanismSelectProps {
  type: ClickPipeKafkaType;
  value: KafkaSaslMechanism;
  onSelect: (value: KafkaSaslMechanism) => void;
}

export function SaslMechanismSelect(
  props: SaslMechanismSelectProps
): JSX.Element {
  const { supports, default: defaultMechanism } =
    getSupportedAndDefaultSaslMechanisms(props.type);

  useEffect(() => {
    props.onSelect(defaultMechanism);
  }, []);

  return (
    <Select
      label="SASL Mechanism"
      name="saslMechanism"
      data-testid="saslMechanism"
      value={props.value}
      onSelect={(value: string): void =>
        props.onSelect(value as KafkaSaslMechanism)
      }
    >
      <Select.Item
        value="basic"
        disabled={!supports.includes('basic')}
        defaultChecked={defaultMechanism === 'basic'}
      >
        SASL/PLAIN
      </Select.Item>
      <Select.Item
        value="scram_sha_256"
        disabled={!supports.includes('scram_sha_256')}
        defaultChecked={defaultMechanism === 'scram_sha_256'}
      >
        SASL/SCRAM-SHA-256
      </Select.Item>
      <Select.Item
        value="scram_sha_512"
        disabled={!supports.includes('scram_sha_512')}
        defaultChecked={defaultMechanism === 'scram_sha_512'}
      >
        SASL/SCRAM-SHA-512
      </Select.Item>
    </Select>
  );
}
