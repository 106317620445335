import {
  Container,
  InfoAlert,
  Link,
  SuccessAlert,
  Text,
  TextField,
  WarningAlert
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { CustomerKeyValidationState } from 'src/instance/controller/useNewInstanceCustomerKeyConfig';
import {
  Subsection,
  SubsectionControls,
  SubsectionDescription
} from 'src/lib/pageElements';
import styled from 'styled-components';

interface NewServiceCustomerKeySectionProps {
  keyId: string;
  roleId: string;
  onKeyIdChange: (newKeyId: string) => void;
  onRoleIdChange: (newRoleId: string) => void;
  onValidate: () => void;
  validationStatus: CustomerKeyValidationState;
}

const CustomSubsection = styled(Subsection)`
  ${({ theme }) => `

  @container (${theme.breakpoint.sizes.md}) {
      flex-direction: column;

  }
      `}
`;

export function NewServiceCustomerKeySection({
  keyId,
  roleId,
  onKeyIdChange,
  onRoleIdChange,
  onValidate,
  validationStatus
}: NewServiceCustomerKeySectionProps): ReactElement {
  return (
    <CustomSubsection justifyContent="space-between">
      <SubsectionDescription>
        <Container fillWidth>
          <Text color="muted">
            Bring your own KMS key to encrypt your data.{' '}
            <Link
              href="https://clickhouse.com/docs/en/cloud/manage/cmek"
              target="_blank"
            >
              Learn more
            </Link>
          </Text>
        </Container>
      </SubsectionDescription>
      <SubsectionControls>
        <Container orientation="vertical" gap="lg">
          <TextField
            placeholder="ARN for AWS KMS Key"
            label="AWS ARN"
            value={keyId}
            onChange={onKeyIdChange}
            onBlur={onValidate}
            data-testid="customerKeyId"
          />
          <TextField
            placeholder="ARN for ClickHouse IAM"
            label="AWS Assumed Role ARN (optional)"
            value={roleId}
            onChange={onRoleIdChange}
            onBlur={onValidate}
            data-testid="customerKeyRoleId"
          />
          {validationStatus === 'checking' && (
            <InfoAlert
              text="Checking..."
              data-testid="customerKeysValidationMessage"
            />
          )}
          {validationStatus === 'valid' && (
            <SuccessAlert
              text="Key information valid"
              data-testid="customerKeysValidationMessage"
            />
          )}
          {validationStatus === 'invalid' && (
            <WarningAlert
              text="Key information invalid"
              data-testid="customerKeysValidationMessage "
            />
          )}
        </Container>
      </SubsectionControls>
    </CustomSubsection>
  );
}
