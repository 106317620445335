import { ReactElement, ReactNode } from 'react';

import { TableDetails } from 'shared/src/clickhouse/types';
import ResizableFlyout from 'src/components/RightBar/ResizableFlyout';

import {
  CodeBlock,
  Container,
  EllipsisContent,
  FlyoutProps,
  Spacer,
  FullWidthTabs,
  Text,
  Title
} from '@clickhouse/click-ui';
import { humanFileSize } from 'src/lib/bytesUtils';
import { formatNumber } from 'src/lib/formatters/numberFormatter';
import { ColumnsList } from 'src/components/TableView/DataView/RightBar/TableInspector/ColumnsList';
import FatalErrorBoundary from 'src/components/FatalErrorBoundary';

interface TableSchemaProps extends FlyoutProps {
  table: TableDetails;
  container?: HTMLElement | null;
}

const DescribableList = ({
  title,
  list
}: DescribableListProps): ReactElement => (
  <>
    <Title size="sm" type="h2">
      {title}
    </Title>
    <Container orientation="vertical" gap="md">
      {list.map(({ name, descriptor }, idx) => (
        <Container
          key={`descriptor-${idx}`}
          justifyContent="space-between"
          gap="xs"
          isResponsive={false}
        >
          <EllipsisContent>
            <Text weight="mono" data-testid={`schema-column-${name}`}>
              {name}
            </Text>
          </EllipsisContent>
          <EllipsisContent>
            <Text color="muted" weight="mono" align="right">
              {descriptor}
            </Text>
          </EllipsisContent>
        </Container>
      ))}
    </Container>
  </>
);

type DescribableListProps = {
  title: string;
  list: Array<{ name: ReactNode; descriptor: string | number }>;
};

function TableProperties({ table }: { table: TableDetails }): ReactElement {
  const getOrDefault = (
    value: string | undefined | null,
    defaultValue: string
  ): string => {
    return value && value.length > 0 ? value : defaultValue;
  };

  const humanizedValue = (value: number | null): string => {
    return value ? humanFileSize(value) : '-';
  };

  const { totalRows, totalBytes, totalBytesUncompressed } = table.stats;

  const list: Array<{ name: ReactNode; descriptor: string }> = [
    { name: 'Engine', descriptor: getOrDefault(table.engine, '-') },
    {
      name: 'Sorting key',
      descriptor: getOrDefault(table.sortingKey, '-')
    },
    { name: 'Primary key', descriptor: getOrDefault(table.primaryKey, '-') },
    {
      name: 'Total rows',
      descriptor: totalRows ? `${formatNumber(totalRows)} rows ` : '-'
    },
    {
      name: 'Size',
      descriptor: humanizedValue(totalBytes)
    },
    {
      name: 'Size uncompressed',
      descriptor: humanizedValue(totalBytesUncompressed)
    }
  ];
  return (
    <Container orientation="vertical" gap="md">
      <DescribableList title="Properties" list={list} />
      <Title type="h2">Create table SQL</Title>
      <CodeBlock language="sql" wrapLines showLineNumbers>
        {table.createTableQuery}
      </CodeBlock>
    </Container>
  );
}

export default function TableInspector({
  table,
  ...props
}: TableSchemaProps): ReactElement {
  return (
    <FatalErrorBoundary>
      <ResizableFlyout
        header="Table inspector"
        width={600}
        minWidth={600}
        {...props}
      >
        <ResizableFlyout.Body>
          <Container orientation="vertical" gap="md">
            <FullWidthTabs defaultValue="tableInfo">
              <FullWidthTabs.TriggersList>
                <FullWidthTabs.Trigger value="tableInfo">
                  Table info
                </FullWidthTabs.Trigger>
                <FullWidthTabs.Trigger value="columnInfo">
                  Column info
                </FullWidthTabs.Trigger>
              </FullWidthTabs.TriggersList>
              <FullWidthTabs.Content value="tableInfo">
                <Spacer size="md" />
                <TableProperties table={table} />
              </FullWidthTabs.Content>
              <FullWidthTabs.Content value="columnInfo">
                <Spacer size="md" />
                <ColumnsList
                  columns={table.columns}
                  tableName={table.tableName}
                  database={table.schema ?? ''}
                />
              </FullWidthTabs.Content>
            </FullWidthTabs>
          </Container>
        </ResizableFlyout.Body>
      </ResizableFlyout>
    </FatalErrorBoundary>
  );
}
