import { ReactElement } from 'react';
import { Link, Spacer, Text } from '@clickhouse/click-ui';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';
import {
  PASSWORD_MASK,
  ProtocolProps,
  getHttpsConnectionString
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';

type HttpConnectionTabProps = {
  username: string;
  credentials: ProtocolProps;
  showPassword?: boolean;
};

const HttpConnectionTab = ({
  username,
  credentials,
  showPassword
}: HttpConnectionTabProps): ReactElement => {
  const { hostname, password, port } = credentials;

  const unmasked = getHttpsConnectionString(hostname, username, password, port);
  const masked = password
    ? getHttpsConnectionString(hostname, username, PASSWORD_MASK, port)
    : undefined;

  return (
    <>
      <Spacer />
      <Text>Run the following command from your terminal:</Text>
      <Spacer />
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        language="bash"
        data-testid="service-connect-code-block-https-usage"
        showPassword={showPassword}
      />
      <Spacer />
      <Text>
        Visit the documentation to learn how to use the{' '}
        <Link
          href="https://clickhouse.com/docs/en/interfaces/http"
          target="_blank"
          icon="share-arrow"
        >
          HTTP Interface
        </Link>
      </Text>
    </>
  );
};

export default HttpConnectionTab;
