import { ReactNode, useEffect, useState } from 'react';
import { ExecuteQueryResult } from 'shared/src/queryEndpoints/protocol';
import { ChartConfig } from 'shared/src/types/dashboard';
import { useExecuteQuery } from 'src/queryEndpoints/queryEndpointsController/useExecuteQuery';

export interface VizhouseChartProps {
  chartConfig: ChartConfig;
  debug?: boolean;
  sql: string;
  variables?: Record<string, unknown>;
}

export const VizhouseChart = ({
  debug,
  sql,
  variables = {}
}: VizhouseChartProps): ReactNode => {
  const [results, setResults] = useState<ExecuteQueryResult>();
  const executeQuery = useExecuteQuery();

  useEffect(() => {
    if (sql) {
      executeQuery(sql, variables)
        .then((queryResults) => {
          setResults(queryResults);
        })
        .catch(console.error);
    }
  }, [executeQuery, sql]);

  if (debug && results) {
    console.log(results);
  }

  return null;
};
