import { Fragment, useState } from 'react';
import { useQueryStatus } from 'src/lib/query/QueryState';
import { useLiveQueries } from 'src/state/liveQueries';
import { useSelectedTab } from 'src/state/tabs';

import { Query } from 'src/components/RunningQueryList/Query';
import { Button, Popover, Separator, Tooltip } from '@clickhouse/click-ui';
import QueryListLoading from 'src/components/RunningQueryList/QueryListLoading';
import styled from 'styled-components';

//TODO: We need a PopoverButtonTrigger component
const CustomButton = styled(Button)`
  border: transparent;
  &:hover,
  &:visited,
  &:active,
  &:focus {
    border: transparent;
  }
`;

const Content = styled(Popover.Content)`
  overflow: scroll;
`;

export default function RunningQueryList(): JSX.Element {
  const [open, setOpen] = useState(false);
  const {
    queries,
    failed,
    hasActiveQueries,
    loading,
    insufficientPermissions
  } = useLiveQueries(open ? 'fullListing' : 'statusOnly');
  const tab = useSelectedTab();

  const status = useQueryStatus(tab?.type === 'query' ? tab.queryRunId : null);

  const triggerButtonIcon =
    status === 'running' || hasActiveQueries ? 'horizontal-loading' : 'query';

  let tooltip = 'View running queries on service';
  if (failed) {
    tooltip =
      'We encountered an error.  Please refresh the page or contact support';
  } else if (insufficientPermissions) {
    tooltip = 'Insufficient permissions to view running queries';
  }

  return (
    <>
      <Popover open={open && !insufficientPermissions} onOpenChange={setOpen}>
        <Popover.Trigger>
          <Tooltip>
            <Tooltip.Trigger data-testid="liveQueryMenuButton">
              <CustomButton
                type="secondary"
                iconLeft={triggerButtonIcon}
                label="Queries"
                disabled={insufficientPermissions || failed}
              />
            </Tooltip.Trigger>
            <Tooltip.Content>{tooltip}</Tooltip.Content>
          </Tooltip>
        </Popover.Trigger>
        <Content data-testid="liveQueryMenuBody" side="bottom" align="end">
          {loading && <QueryListLoading />}
          {!loading &&
            queries.map((query, index) => (
              <Fragment key={query.id}>
                {index !== 0 && <Separator size="xs" />}
                <Query query={query} />
              </Fragment>
            ))}
        </Content>
      </Popover>
    </>
  );
}
