import { useTheme } from '@emotion/react';

import { IconProps } from 'src/components/icons/iconInterface';

export function InfoIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="16"
        height="16"
        rx="8"
        fill={color || theme.colors.alert.bg.info}
      />
      <path
        d="M7.22816 12V5.45455H8.77077V12H7.22816ZM8.00373 4.52557C7.75941 4.52557 7.54918 4.4446 7.37305 4.28267C7.19691 4.1179 7.10884 3.92045 7.10884 3.69034C7.10884 3.45739 7.19691 3.25994 7.37305 3.09801C7.54918 2.93324 7.75941 2.85085 8.00373 2.85085C8.25089 2.85085 8.46112 2.93324 8.63441 3.09801C8.81055 3.25994 8.89862 3.45739 8.89862 3.69034C8.89862 3.92045 8.81055 4.1179 8.63441 4.28267C8.46112 4.4446 8.25089 4.52557 8.00373 4.52557Z"
        fill={theme.colors.alert.text.info}
      />
    </svg>
  );
}
