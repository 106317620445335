import {
  Gradients,
  TimeSeriesChart,
  TimeSeriesSeriesDescriptor
} from '@clickhouse/viz-house';
import React, { PropsWithChildren } from 'react';
import { MetricReport } from '@cp/common/protocol/Metrics';
import { ReactElement } from 'react';

import {
  formatDate,
  formatDayMonth,
  formatMonthYear,
  formatTimeHoursMinutes
} from 'src/lib/formatters/dateTimeFormatter';

import {
  MetricChartInput,
  MetricChartSeriesOptions
} from 'src/metrics/metricChart';
import { assertTruthy } from '@cp/common/utils/Assert';

import { DEFAULT_COLOR_FOR_METRICS } from 'src/metrics/metricChartInputs';

import {
  Button,
  Container,
  ContainerProps,
  Icon,
  Panel,
  PanelProps,
  Text
} from '@clickhouse/click-ui';

import MetricSummary from 'src/components/ServiceMetrics/MetricSummary';
import { DataSizePointFormat, formatDataSize } from 'src/metrics/dataSize';

import { Galaxy } from 'galaxy';

type Props = {
  metric: MetricChartInput;
  error: string | undefined;
  loading: boolean;
  report: MetricReport | undefined;
  reload: () => void;
};

const defaultSeriesOptions = [{}] as Array<Partial<MetricChartSeriesOptions>>;

type ChartPanelProps = {
  panelProps?: PanelProps;
  containerProps?: ContainerProps;
};
const ChartPanel = ({
  panelProps,
  containerProps,
  children
}: PropsWithChildren<ChartPanelProps>): ReactElement => (
  <Panel
    hasBorder
    width="100%"
    padding="none"
    orientation="vertical"
    {...panelProps}
  >
    <Container gap="xs" fillHeight {...containerProps}>
      {children}
    </Container>
  </Panel>
);
const MonitoringChart = ({
  error,
  report,
  metric,
  loading,
  reload
}: Props): ReactElement => {
  if (loading) {
    return (
      <ChartPanel>
        <Container justifyContent="center" fillHeight minHeight="190px">
          <Icon name="horizontal-loading" size="lg" />
          <Text size="md" color="muted">
            Loading metric...
          </Text>
        </Container>
      </ChartPanel>
    );
  } else if (error || !report) {
    return (
      <ChartPanel>
        <Container
          alignItems="center"
          justifyContent="center"
          orientation="vertical"
          fillHeight
          maxHeight="190px"
          gap="md"
        >
          <Container justifyContent="center" gap="xs">
            <Icon name="warning" size="lg" />
            <Text size="md" color="muted">
              {error}
            </Text>
          </Container>
          <Button
            type="secondary"
            label="Reload"
            iconLeft="refresh"
            onClick={reload}
            disabled={loading}
          />
        </Container>
      </ChartPanel>
    );
  }

  const handleHover = (): void => {
    Galaxy.galaxy().track('serviceHealth.monitoringChart.hovered', {
      interaction: 'trigger',
      chart: metric.query.type
    });
  };

  return (
    <ChartPanel panelProps={{ onMouseOver: handleHover }}>
      <Container maxWidth="25%" fillHeight>
        <MetricSummary metric={metric} report={report} />
      </Container>
      <Container maxWidth="74%" data-testid={`chart-${metric.query.type}`}>
        <VizHouseSeriesChart metric={metric} report={report} />
      </Container>
    </ChartPanel>
  );
};

type SeriesChartProps = {
  metric: MetricChartInput;
  report: MetricReport | undefined;
};

function VizHouseSeriesChart({
  metric,
  report
}: SeriesChartProps): ReactElement {
  const seriesData: Array<Array<[number, number]>> = report?.data ?? [];
  const options = metric.seriesOptions || defaultSeriesOptions; // Single series chart by default.
  const data = seriesData.length > 0 ? seriesData : options.map(() => []); // Empty data per each series by default.
  assertTruthy(data.length === options.length, 'Invalid chart input data');

  const defaultSeriesColor = DEFAULT_COLOR_FOR_METRICS;
  const series: Array<TimeSeriesSeriesDescriptor> = [];

  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    series.push({
      name: option.name || metric.title,
      type: option.type === 'line' ? 'line' : 'area',
      color: option.color || defaultSeriesColor,
      fillColor:
        option.type === 'line'
          ? undefined
          : Gradients.linear(option.color || defaultSeriesColor),
      values: seriesData[i].map(([x, y]) => ({ x, y }))
    });
  }
  const tickPositioner = (): Array<number> => {
    const yValues =
      data.length > 1
        ? data.flatMap((subArray) =>
            subArray.map((innerArray) => innerArray[1])
          )
        : data[0].map((innerArray) => innerArray[1]);

    const dataMax = Math.max(...yValues);
    if (dataMax <= 0) {
      return [0];
    }
    const baseMultiple =
      metric.tooltipDataSizePointFormat === 'binary-bytes-letter' ? 2 : 10;
    const power = Math.floor(Math.log(dataMax) / Math.log(baseMultiple));
    const topTick =
      Math.ceil(dataMax / Math.pow(baseMultiple, power)) *
      Math.pow(baseMultiple, power);
    const middleTick = topTick / 2;
    return [0, middleTick, topTick];
  };

  const period = metric.query.timePeriod ?? report?.timePeriod ?? 'LAST_HOUR';

  return (
    <TimeSeriesChart
      series={series}
      title={metric.title}
      height="190px"
      period={period}
      xAxis={{
        verticalLabels: false,
        labelsFormatter: (ctx) => {
          // TODO: We need to consolidate the time formats https://github.com/ClickHouse/control-plane/issues/10149

          switch (period) {
            case 'LAST_HOUR':
            case 'LAST_DAY':
              return formatTimeHoursMinutes(ctx.value);
            case 'LAST_WEEK':
            case 'LAST_MONTH':
              return formatDayMonth(ctx.value);
            case 'LAST_YEAR':
              return formatMonthYear(ctx.value);
            default:
              return formatDate(ctx.value);
          }
        }
      }}
      yAxis={{
        labelsFormatter: (ctx) => {
          if (metric.yAxisValueFormatter) {
            return metric.yAxisValueFormatter(ctx.value);
          }

          if (typeof ctx.value === 'string') {
            return ctx.value;
          }

          return formatDataSize(
            ctx.value,
            metric.yAxisValueFormat as DataSizePointFormat,
            1
          );
        }
      }}
      tooltipFormatter={(options) => {
        const dataSizeFormat =
          metric.tooltipDataSizePointFormat || 'short-scale-letter';
        const suffix = metric.tooltipValueSuffix || '';
        return formatDataSize(options.yValue || 0, dataSizeFormat) + suffix;
      }}
      highChartsPropsOverrides={{
        yAxis: {
          tickPositioner
        }
      }}
    />
  );
}

const MemoedMonitoringChart = React.memo(MonitoringChart);

export default MemoedMonitoringChart;
