import { TimePeriod } from '@cp/common/protocol/Metrics';

export function formatTimePeriod(timePeriod: TimePeriod): string {
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      return 'Last 15 minutes';
    case 'LAST_HOUR':
      return 'Last hour';
    case 'LAST_DAY':
      return 'Last 24 hours';
    case 'LAST_WEEK':
      return 'Last week';
    case 'LAST_MONTH':
      return 'Last month';
    case 'LAST_YEAR':
      return 'Last year';
  }
  return 'Unknown period';
}
