import { Container, Link, Text } from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';

export const ReadMoreContainer = styled(Container)`
  white-space: pre-wrap;
  word-break: break-word;
`;

const ReadMore = ({
  children,
  maxLength
}: {
  children: string;
  maxLength: number;
}): ReactElement => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = (): void => {
    setIsTruncated(!isTruncated);
  };

  return (
    <ReadMoreContainer>
      {isTruncated ? (
        <Text>
          {children.length > maxLength
            ? children.slice(0, maxLength) + '...'
            : children}
          {children.length > maxLength && (
            <Link onClick={toggleTruncate}>Read more</Link>
          )}
        </Text>
      ) : (
        <Text>
          {children}
          <Link onClick={toggleTruncate}>Read less</Link>
        </Text>
      )}
    </ReadMoreContainer>
  );
};

export default ReadMore;
