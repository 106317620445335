import { ReactElement } from 'react';
import ApiEndpointInsights from 'src/components/QueryView/ApiEndpointInsights';
import { Badge, Container, Flyout, Title } from '@clickhouse/click-ui';

type QueryApiEndpointsFlyoutProps = {
  onClose: () => void;
};

export default function QueryApiEndpointsFlyout({
  onClose
}: QueryApiEndpointsFlyoutProps): ReactElement {
  return (
    <Flyout
      open
      onOpenChange={(open: boolean) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Flyout.Content strategy="fixed" size="wide">
        <Flyout.Header>
          <Container orientation="horizontal" gap="xs" padding="none">
            <Title type="h3" size="xl">
              API Insights
            </Title>
            <Badge size="md" text="beta" state="info" />
          </Container>
        </Flyout.Header>
        <Flyout.Body align="default">
          <Flyout.Element>
            <ApiEndpointInsights />
          </Flyout.Element>
        </Flyout.Body>
        <Flyout.Footer>
          <Flyout.Close label="Close" />
        </Flyout.Footer>
      </Flyout.Content>
    </Flyout>
  );
}
