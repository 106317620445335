import { SVGAttributes } from 'react';

function WarningRoundedIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="16" height="16" rx="8" fill="#FFCACA" />
      <path
        d="M8.96697 3.31818L8.80291 10.5071H7.20206L7.04297 3.31818H8.96697ZM8.00249 13.6094C7.70088 13.6094 7.44235 13.5033 7.22692 13.2912C7.0148 13.0791 6.90874 12.8205 6.90874 12.5156C6.90874 12.2173 7.0148 11.9621 7.22692 11.75C7.44235 11.5379 7.70088 11.4318 8.00249 11.4318C8.29747 11.4318 8.55268 11.5379 8.76811 11.75C8.98686 11.9621 9.09624 12.2173 9.09624 12.5156C9.09624 12.7178 9.04486 12.9018 8.94212 13.0675C8.84268 13.2332 8.71011 13.3658 8.54439 13.4652C8.38198 13.5613 8.20135 13.6094 8.00249 13.6094Z"
        fill="#C70F0F"
      />
    </svg>
  );
}

export default WarningRoundedIcon;
