import { buildAllowListCurrentIpDescription, IpAccessListEntry } from '@cp/common/protocol/Instance';
import { isIpAccessSource } from '@cp/common/utils/ValidationUtils';
import cloneDeep from 'lodash/cloneDeep';
import xorBy from 'lodash/xorBy';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import { IpAccessOption } from 'src/instance/controller/useNetworkSettings';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';

const locationSpecificDefault: IpAccessListEntry = {
  source: '',
  description: ''
};

export const getDuplicateSources = (ipsAccessList: Array<IpAccessListEntry>): Array<string> => {
  if (ipsAccessList.length > 0) {
    const duplicateIpList = xorBy(ipsAccessList, uniqBy(ipsAccessList, 'source'));
    return map(duplicateIpList, 'source');
  }
  return [];
};

const getInvalidSources = (ipsAccessList: Array<IpAccessListEntry>): Array<string> => {
  if (ipsAccessList.length > 0) {
    const uniqueIpList = uniqBy(ipsAccessList, 'source');
    const invalidIps = uniqueIpList.flatMap((ip) => (isIpAccessSource(ip.source) ? [] : ip.source));

    return invalidIps;
  }
  return [];
};

const updateCurrentIpInArray = (
  ipList: Array<IpAccessListEntry>,
  source: string,
  description: string
): Array<IpAccessListEntry> => {
  const newIpList = cloneDeep(ipList);
  const lastItem = newIpList[newIpList.length - 1];
  if (lastItem.source.length === 0) {
    lastItem.source = source;
    if (lastItem.description.length === 0) {
      lastItem.description = description;
    }
  } else {
    newIpList.push({
      source,
      description
    });
  }
  return newIpList;
};

interface UseNetworkAccessListProps {
  ipsAccessList: Array<IpAccessListEntry>;
  type: IpAccessOption;
  onSubmit: (ipType: IpAccessOption, ipList: Array<IpAccessListEntry>) => void;
  open?: boolean;
}

export const useNetworkAccessList = ({
  ipsAccessList,
  open,
  type,
  onSubmit: onSubmitProp
}: UseNetworkAccessListProps) => {
  const hasUpdateIpAddressListPermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage-ip-access-list',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const [duplicateList, setDuplicateList] = useState<Array<string>>(getDuplicateSources(ipsAccessList));
  const [invalidIpList, setInvalidIpList] = useState<Array<string>>([]);
  const { user } = useUserStateManager();
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
  const [ipOption, setIpOption] = useState<IpAccessOption>(type);
  const [ipList, setIpList] = useState<Array<IpAccessListEntry>>(() => {
    if (ipsAccessList && ipsAccessList.length > 0) {
      return ipsAccessList;
    }
    return [{ ...locationSpecificDefault }];
  });

  useEffect(() => {
    if (!open) {
      setDuplicateList(getDuplicateSources(ipsAccessList));
    } else {
      setIpOption(type);
      if (ipsAccessList && ipsAccessList.length > 0) {
        setIpList(ipsAccessList);
      } else if (ipsAccessList?.length === 0) {
        setIpList([{ ...locationSpecificDefault }]);
        setDisabledSaveBtn(true);
      }
    }
  }, [ipsAccessList, open, type]);

  const addItem = (): void => {
    setDisabledSaveBtn(true);
    setIpList((ipList) => [...ipList, { ...locationSpecificDefault }]);
  };

  const onValueChange = (index: number) => (type: 'source' | 'description', value: string) => {
    const newIpList = cloneDeep(ipList);
    newIpList[index][type] = value;
    setIpList(newIpList);
  };

  const validateErrorsAndUpdateDisabledButton = (ipList: Array<IpAccessListEntry>): void => {
    const duplicateList = getDuplicateSources(ipList);
    const invalidList = getInvalidSources(ipList);
    setDuplicateList(duplicateList);
    setInvalidIpList(invalidList);
    const hasEmptyList = ipList.some((ip) => {
      return ip.source.length === 0;
    });

    setDisabledSaveBtn(hasEmptyList || invalidList.length > 0 || duplicateList.length > 0);
  };

  const removeItem = (index: number) => (): void => {
    if (ipList.length === 1) {
      setIpList([{ ...locationSpecificDefault }]);
      setDuplicateList([]);
      setDisabledSaveBtn(true);
    } else {
      setIpList((ipList) => {
        const newIpList = cloneDeep(ipList);
        newIpList.splice(index, 1);
        validateErrorsAndUpdateDisabledButton(newIpList);
        return newIpList;
      });
    }
  };
  const onSubmit: MouseEventHandler<HTMLButtonElement> = () => {
    onSubmitProp(ipOption, ipList);
  };

  const onRadioGroupChange = (value: string): void => {
    setIpOption(value as IpAccessOption);
    if (value === 'specificLocations') {
      onBlur();
    }
  };

  const addCurrentIp = (): void => {
    const source = user.ipAddress || '';
    setIpList((ipList) => {
      const description = buildAllowListCurrentIpDescription(user.name ?? 'Home');
      const newIpList = updateCurrentIpInArray(ipList, source, description);
      validateErrorsAndUpdateDisabledButton(newIpList);
      return newIpList;
    });
  };

  const onBlur = (): void => {
    validateErrorsAndUpdateDisabledButton(ipList);
    getInvalidSources(ipList);
  };

  const IP_ADDRESS_INPUT_COLUMN = hasUpdateIpAddressListPermission ? '1fr 1fr 32px' : '1fr 1fr';

  return {
    hasUpdateIpAddressListPermission,
    duplicateList,
    invalidIpList,
    IP_ADDRESS_INPUT_COLUMN,
    addItem,
    onValueChange,
    removeItem,
    onSubmit,
    onRadioGroupChange,
    addCurrentIp,
    onBlur,
    disabledSaveBtn,
    ipOption,
    ipList
  };
};
