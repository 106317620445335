import {
  Alert,
  Container,
  Icon,
  Tabs,
  Text,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { IntegrationLogo } from 'src/components/IntegrationLogo/IntegrationLogo';
import { IntegrationMarkdown } from 'src/components/IntegrationSummary/IntegrationMarkdown';
import IntegrationSummarySidebar from 'src/components/IntegrationSummary/IntegrationSummarySidebar';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import { IntegrationSummaryType } from 'src/integrations/controller/useIntegrationSummary';
import styled from 'styled-components';

const IntegrationTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

const TabsTrigger = styled(Tabs.Trigger)`
  white-space: nowrap;
`;

const TabsTriggersList = styled(Tabs.TriggersList)`
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  scrollbar-width: 0px;
`;

export const IntegrationSummary = ({
  integration,
  error,
  isLoading
}: {
  integration?: IntegrationSummaryType;
  isLoading?: boolean;
  error?: Error;
}): ReactElement | null => {
  if (isLoading) {
    return (
      <MaxWidthContainer
        padding="md"
        alignItems="center"
        justifyContent="center"
        grow="1"
        isResponsive={false}
      >
        <Container
          maxWidth="50%"
          fillHeight
          maxHeight="calc(100% - 3.5rem)"
          minHeight="320px"
          justifyContent="center"
          orientation="vertical"
          alignItems="center"
        >
          <Icon name="horizontal-loading" size="xxl" />
          <Text size="lg">Loading integration</Text>
        </Container>
      </MaxWidthContainer>
    );
  }

  if (error) {
    <Alert
      text={String(error)}
      dismissible={false}
      size="medium"
      state="warning"
    />;
  }

  if (!integration) {
    return null;
  }

  return (
    <>
      <Container
        orientation="horizontal"
        justifyContent="start"
        gap="sm"
        padding="md"
      >
        <IntegrationLogo integration={integration} size="xxl" />
        <Container isResponsive={false} maxWidth="400px" orientation="vertical">
          <Title size="md" type="h3" data-testid="integration-title">
            {integration.name}
          </Title>
          <Text>{integration.shortDescription}</Text>
        </Container>
      </Container>
      <Container
        alignItems="start"
        gap="xl"
        isResponsive={false}
        grow="1"
        justifyContent="space-between"
        minWidth="auto"
        orientation="horizontal"
        padding="lg"
        wrap="nowrap"
        overflow="hidden"
      >
        <Container
          maxWidth="calc(100% - 320px)"
          fillHeight
          isResponsive={false}
          grow="1"
          alignItems="start"
          overflow="auto"
        >
          <IntegrationTabs
            ariaLabel="Integration details."
            defaultValue="how-to-connect"
          >
            <TabsTriggersList>
              {integration.list?.map((tab) => {
                if (tab.link) {
                  return (
                    <TabsTrigger
                      key={tab.value}
                      value={tab.value}
                      data-testid={`${tab.value}Tab`}
                      disabled={true}
                    >
                      <a
                        href={tab.link}
                        target="_blank"
                        style={{ color: '#696e79' }}
                      >
                        {tab.label}
                      </a>
                    </TabsTrigger>
                  );
                }
                return (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    data-testid={`${tab.value}Tab`}
                  >
                    {tab.label}
                  </TabsTrigger>
                );
              })}
            </TabsTriggersList>
            {integration.list?.map((tab) => {
              if (!tab.content) {
                return null;
              }
              return (
                <Tabs.Content
                  key={tab.value}
                  value={tab.value}
                  data-testid={`${tab.value}TabContent`}
                >
                  <IntegrationMarkdown>{tab.content ?? ''}</IntegrationMarkdown>
                </Tabs.Content>
              );
            })}
          </IntegrationTabs>
        </Container>
        <IntegrationSummarySidebar integration={integration} />
      </Container>
    </>
  );
};
