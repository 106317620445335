import { InstanceMysqlSettings } from '@cp/common/protocol/Instance';
import { atom, useAtom } from 'jotai';

interface InstanceMySqlSettingsStateManager {
  mysqlSettings: InstanceMysqlSettings | null;
  setMysqlSettings: (settings: InstanceMysqlSettings) => void;
  mySqlSettingsLoading: boolean;
  setMySqlSettingsLoading: (loading: boolean) => void;
}

const mysqlSettingsAtom = atom<InstanceMysqlSettings | null>(null);
const mySqlSettingsLoadingAtom = atom<boolean>(false);

const useInstanceMySqlSettingsStateManager = (): InstanceMySqlSettingsStateManager => {
  const [mysqlSettings, setMysqlSettings] = useAtom(mysqlSettingsAtom);
  const [mySqlSettingsLoading, setMySqlSettingsLoading] = useAtom(mySqlSettingsLoadingAtom);

  return {
    mysqlSettings,
    setMysqlSettings,
    mySqlSettingsLoading,
    setMySqlSettingsLoading
  };
};

export default useInstanceMySqlSettingsStateManager;
