import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useUINotifications } from 'src/notifications/controller/useUINotifications';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { NotificationBellView } from 'src/components/Notifications/NotificationBellView';
import { NotificationsFlyout } from 'src/components/Notifications/NotificationFlyout';
import { Tooltip } from '@clickhouse/click-ui';

export const NotificationBell = ({
  notificationContainer
}: {
  notificationContainer: HTMLElement | null;
}): ReactElement => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const {
    notifications,
    markAsRead,
    fetchUINotifications,
    isLoading,
    unreadCount
  } = useUINotifications();

  const onToggleOpen = useCallback(() => {
    // if flyout was open,
    // then we close it and re-fetch notifications again to refresh unread count
    if (isFlyoutOpen) {
      void fetchUINotifications();
    }
    setIsFlyoutOpen((prev) => !prev);
  }, [fetchUINotifications, isFlyoutOpen]);

  useEffect(() => {
    if (isFlyoutOpen && unreadCount > 0) {
      void markAsRead();
    }
  }, [isFlyoutOpen, markAsRead, unreadCount]);

  const organization = useCurrentOrganizationOrThrow();

  let tooltipContent = 'Notifications';
  if (unreadCount === 1) {
    tooltipContent = 'You have 1 unread notification';
  } else if (unreadCount > 1) {
    tooltipContent = 'You have ' + unreadCount + ' unread notifications';
  }

  return (
    <NotificationsFlyout
      isOpen={isFlyoutOpen}
      onToggleOpen={onToggleOpen}
      notifications={notifications}
      notificationContainer={notificationContainer}
      organizationId={organization.id}
      triggerButton={
        <Tooltip>
          <Tooltip.Trigger>
            <NotificationBellView
              hasUnread={unreadCount > 0}
              isFlyoutOpen={isFlyoutOpen}
              isLoading={isLoading}
            />
          </Tooltip.Trigger>
          <Tooltip.Content>{tooltipContent}</Tooltip.Content>
        </Tooltip>
      }
    />
  );
};
