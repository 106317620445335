import { getSortKey } from 'src/components/CreateTableView/context';
import { TableState } from 'shared/src/tableSchema';

export function validateTable(table: TableState): string[] {
  const errors: string[] = [];

  if (!table.name) {
    errors.push('Please enter a name for your table');
  }

  if (table.columnIds.length < 1) {
    errors.push('Please add at least one column');
  }

  const columns = Object.values(table.columns);

  if (columns.some((col) => !col.name)) {
    errors.push('One or more columns are missing a name');
  }

  if (columns.some((col) => !col.type)) {
    errors.push('One or more columns are missing a type');
  }

  const sortKey = getSortKey(table);

  if (
    sortKey.some((keyPart) => {
      const type = table.columns[keyPart.id]?.type;
      return type && type.startsWith('Nullable(');
    })
  ) {
    errors.push('Sort key cannot contain a nullable field');
  }

  if (sortKey.some((keyPart) => !table.columns[keyPart.id])) {
    errors.push('Sort key references a column that does not exist');
  }

  if (table.engine.type === 'CollapsingMergeTree' && !table.engine.signColumnId) {
    errors.push('Must specify sign column for CollapsingMergeTree');
  } else if (table.engine.type === 'VersionedCollapsingMergeTree' && !table.engine.signColumnId) {
    errors.push('Must specify sign column for VersionedCollapsingMergeTree');
  } else if (table.engine.type === 'VersionedCollapsingMergeTree' && !table.engine.versionColumnId) {
    errors.push('Must specify version column for CollapsingMergeTree');
  }

  return errors;
}
