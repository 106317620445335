import {
  Alert,
  CodeBlock,
  InlineCodeBlock,
  Link,
  Text,
  Title,
  VerticalStepProps,
  VerticalStepper,
  VerticalStepperProps,
  createToast
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import Markdown, { Components } from 'react-markdown';
import type { ExtraProps } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

type ElementNode = ExtraProps['node'];

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'vertical-stepper': VerticalStepperProps;
      'vertical-stepper-step': VerticalStepProps;
      'vertical-stepper-without-label': VerticalStepperProps;
      'vertical-stepper-without-label-step': VerticalStepProps;
    }
  }
}

const VerticalStepperLabelStep = styled.div`
  margin-top: -1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1lh;
  ul {
    margin: 0;
  }
`;

function getDefaultComponents(): Components {
  return {
    p: ({ node, children, ...props }) => (
      <Text {...props} color="default" component="div">
        {children}
      </Text>
    ),
    pre: ({ children }) => <>{children}</>,
    blockquote: ({ node, children, ...props }) => (
      <Alert
        type="default"
        state="info"
        text={children}
        dismissible={false}
        showIcon={false}
        {...props}
      />
    ),
    h1: ({ node, color, ...props }) => (
      <Title type="h1" color="default" {...props} ref={undefined} />
    ),
    h2: ({ node, color, ...props }) => (
      <Title {...props} type="h2" color="default" ref={undefined} />
    ),
    h3: ({ node, color, ...props }) => (
      <Title type="h3" color="default" {...props} ref={undefined} />
    ),
    h4: ({ node, color, ...props }) => (
      <Title type="h4" color="default" {...props} ref={undefined} />
    ),
    h5: ({ node, color, ...props }) => (
      <Title type="h5" color="default" {...props} ref={undefined} />
    ),
    h6: ({ node, color, ...props }) => (
      <Title type="h6" color="default" {...props} ref={undefined} />
    ),
    a: ({ node, ...props }) => <Link {...props} />,
    code: ({
      children,
      className,
      node,
      inline,
      ...rest
    }: JSX.IntrinsicElements['code'] & {
      inline?: boolean;
      node?: ElementNode;
    }) => {
      const match = /language-(\w+)/.exec(className || '');
      if (
        inline ||
        (node && node?.position?.start?.line === node?.position?.end?.line)
      ) {
        return <InlineCodeBlock {...rest}>{children}</InlineCodeBlock>;
      }

      const onCopy = () => {
        createToast({
          title: 'Success',
          type: 'success',
          description: 'Copied Successfully'
        });
      };

      const onCopyError = () => {
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Failed to copy code'
        });
      };

      return (
        <CodeBlock
          language={match ? match[1] : 'none'}
          showLineNumbers={match && match[1] == 'sql' ? true : false}
          wrapLines
          onCopy={onCopy}
          onCopyError={onCopyError}
        >
          {children as string}
        </CodeBlock>
      );
    },
    'vertical-stepper': ({ node, ...props }): ReactElement => (
      <VerticalStepper {...props} />
    ),
    'vertical-stepper-step': ({ node, ...props }): ReactElement => (
      <VerticalStepper.Step status="complete" collapsed={false} {...props} />
    ),
    'vertical-stepper-without-label': ({ node, ...props }): ReactElement => (
      <VerticalStepper {...props} />
    ),
    'vertical-stepper-without-label-step': ({
      node,
      children,
      ...props
    }): ReactElement => (
      <VerticalStepper.Step status="complete" collapsed={false} {...props}>
        <VerticalStepperLabelStep>{children}</VerticalStepperLabelStep>
      </VerticalStepper.Step>
    )
  };
}

const StyledIntegrationMarkdown = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1lh;
`;

export const IntegrationMarkdown = ({
  children
}: {
  children: string;
}): ReactElement => {
  const newComponents = getDefaultComponents();
  return (
    <StyledIntegrationMarkdown>
      <Markdown
        rehypePlugins={[rehypeRaw]}
        components={newComponents}
        unwrapDisallowed
        remarkPlugins={[remarkGfm]}
      >
        {children}
      </Markdown>
    </StyledIntegrationMarkdown>
  );
};
