import {
  Container,
  Dropdown,
  IconButton,
  SearchField
} from '@clickhouse/click-ui';

import React, { ReactElement } from 'react';

import { logEvent } from 'src/components/QueryView/analytics';
import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { QueryMetrics, QueryProgress } from 'src/lib/query/streamingQuery';
import { ResultsDisplayType } from 'src/state/tabs/types';
import { useUserFeature } from 'src/lib/features';

import {
  formatBytes,
  formatMsToSec
} from 'src/components/QueryView/Results/metrics';

import DisplaySelector from 'src/components/QueryView/Results/ResultToolbar/DisplaySelector';
import styles from 'src/components/QueryView/Results/ResultToolbar/styles';
import FixQueryButton from 'src/components/QueryView/Results/FixQueryButton';
import styled from 'styled-components';
import CopyResultsToClipboardButton from 'src/components/QueryView/Results/ResultToolbar/CopyResultsToClipboardButton';
import ProgressToolbarStatusContainer from 'src/components/QueryView/Results/ResultToolbar/QueryProgress/ProgressToolbarStatusContainer';
import RunningQueryIndicator from 'src/components/QueryView/Results/ResultToolbar/RunningQueryIndicator';
import RunningQueryProgressBar from 'src/components/QueryView/Results/ResultToolbar/RunningQueryProgressBar';

const ToolbarContainer = styled(Container)`
  padding: 7px 10px;
  border-bottom: 1px solid
    ${({ theme }): string => theme.global.color.stroke.default};
`;

interface ResultToolbarProps {
  resultsDisplayType: ResultsDisplayType;
  downloadResults: () => Promise<void>;
  metrics?: QueryMetrics;
  progress?: QueryProgress;
  search: string;
  setDisplayType: React.Dispatch<ResultsDisplayType>;
  setSearch: (search: string) => void;
  status: RunningQueryStatusName;
  hasData: boolean;
  runId?: string;
  showFixQuery: boolean;
  rawData: string | undefined;
}

type ResultToolbarSearchFieldProps = {
  search: string;
  setSearch: (search: string) => void;
  status: RunningQueryStatusName;
  hasRawData: boolean;
};

function ResultToolbarSearchField(
  props: ResultToolbarSearchFieldProps
): ReactElement {
  const { search, setSearch, status, hasRawData } = props;
  return (
    <Container maxWidth="250px">
      <SearchField
        data-testid="resultToolbarSearchField"
        disabled={status === 'running' || hasRawData}
        onChange={(value: string): void => {
          setSearch(value);
          logEvent('searchInput', 'keypress');
        }}
        placeholder="Search results..."
        value={search}
        onFocus={(): void =>
          logEvent('resultToolBar', 'searchFocus', 'trigger')
        }
        onBlur={(): void => logEvent('resultToolBar', 'searchBlur', 'trigger')}
        className="fs-exclude"
      />
    </Container>
  );
}

type ToolbarStatusProps = {
  status: RunningQueryStatusName;
  metrics?: QueryMetrics;
};

function ToolbarStatus(props: ToolbarStatusProps): ReactElement {
  const { status, metrics } = props;
  return (
    <div css={styles.elapseStyle} className="ignore-disable">
      {status === 'running' && <RunningQueryIndicator />}
      {status !== 'running' && typeof metrics !== 'undefined' && (
        <>
          {metrics?.durationMS && (
            <div>Elapsed: {formatMsToSec(metrics.durationMS)}s</div>
          )}
          {Boolean(metrics?.rows) && (
            <div>
              Read: {metrics.rows.toLocaleString()} rows
              {metrics?.bytes && ` (${formatBytes(metrics.bytes)})`}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default function ResultToolbar({
  downloadResults,
  resultsDisplayType,
  metrics,
  progress,
  search,
  setDisplayType,
  setSearch,
  status,
  hasData,
  runId,
  showFixQuery,
  rawData
}: ResultToolbarProps): ReactElement {
  const hasRawData = !!rawData;
  const onDownloadCsv = (): void => {
    downloadResults()
      .then(() => {
        logEvent('resultToolBar', 'contextMenuCSVDownload', 'click');
      })
      .catch(console.error);
  };
  const progressTrackingFeatureFlag = useUserFeature(
    'FT_USER_QUERY_PROGRESS_TRACKING'
  );
  return (
    <>
      <ToolbarContainer justifyContent="space-between">
        <Container wrap="nowrap">
          <Container
            gap="xs"
            padding="none"
            justifyContent="space-between"
            fillWidth={false}
          >
            <ResultToolbarSearchField
              search={search}
              setSearch={setSearch}
              status={status}
              hasRawData={hasRawData}
            />

            {showFixQuery && <FixQueryButton runId={runId} />}

            {hasRawData && <CopyResultsToClipboardButton text={rawData} />}
          </Container>
          {status === 'running' && progressTrackingFeatureFlag ? (
            <ProgressToolbarStatusContainer
              status={status}
              progress={progress}
            />
          ) : (
            <ToolbarStatus status={status} metrics={metrics} />
          )}
        </Container>
        <Container wrap="nowrap" fillWidth={false}>
          <DisplaySelector
            resultsDisplayType={resultsDisplayType}
            setDisplayType={setDisplayType}
            log={(type: string): void =>
              logEvent('resultToolBar', `viewType${type}ButtonClick`, 'click')
            }
            isContentEmpty={['error', 'cancelled'].includes(status) || !hasData}
            hasRawData={hasRawData}
          />
          {!hasRawData && (
            <Dropdown
              onOpenChange={(open: boolean): void => {
                logEvent(
                  'resultToolBar',
                  open ? 'contextMenuOpen' : 'contextMenuBlur',
                  'trigger'
                );
              }}
            >
              <Dropdown.Trigger>
                <IconButton icon="dots-horizontal" type="ghost" />
              </Dropdown.Trigger>
              <Dropdown.Content align="end" side="bottom">
                <Dropdown.Item icon="table" onClick={onDownloadCsv}>
                  Download as CSV...
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          )}
        </Container>
      </ToolbarContainer>
      {progressTrackingFeatureFlag && (
        <RunningQueryProgressBar status={status} progress={progress} />
      )}
    </>
  );
}
