import {
  CodeBlock,
  Container,
  Icon,
  Label,
  Panel,
  Spacer,
  Switch,
  Text
} from '@clickhouse/click-ui';
import { css, Theme } from '@emotion/react';
import { KafkaTopicFormat } from 'shared/src/dataLoading';
import { ErrorBox } from 'src/components/ImportView/ClickPipesImportForm/IncomingData/ErrorBox';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';
import { useClickPipeAvroFlattenEnabled } from 'src/lib/dataLoading/feature';

const sampleCodeStyle = css({
  width: '100%',
  maxHeight: '300px',
  pre: {
    maxHeight: 'inherit'
  }
});

const flattenCodeStyle = (theme: Theme) =>
  css({
    width: '100%',
    maxHeight: '300px',
    pre: {
      maxHeight: 'inherit',
      border: `1px solid ${theme.global.color.accent.default}`,
      borderRadius: '0.25rem'
    }
  });

const alignRight = css({
  marginLeft: 'auto',
  marginRight: '0'
});

const loadingContainerStyle = [
  sampleCodeStyle,
  css({
    justifyContent: 'center',
    minHeight: 96
  })
];

interface OffsetInfo {
  offset: string | undefined;
  partition: string | undefined;
  timestamp: string | undefined;
}

interface Props {
  format: KafkaTopicFormat;
  sample: string | null;
  flattenSample?: string | null;
  error: string | null;
  loading: boolean;
  flatten?: boolean | null;
  setFlatten?: (value: boolean) => void | null;
  offsetInfo: OffsetInfo;
}

export default function Sample({
  sample,
  format,
  loading,
  error,
  flatten,
  setFlatten,
  flattenSample,
  offsetInfo
}: Props): JSX.Element | null {
  const isAvroFlattenEnabled = useClickPipeAvroFlattenEnabled();

  if (loading) {
    return (
      <Panel
        orientation="horizontal"
        color="muted"
        alignItems="center"
        fillWidth
        css={loadingContainerStyle}
      >
        <Icon name="loading-animated" />
        {''}
        <Text color="muted" weight="medium">
          Loading Topic...
        </Text>
      </Panel>
    );
  }

  if (error) {
    return <ErrorBox error={error} title={'Could not fetch sample.'} />;
  }

  if (!sample) {
    return null;
  }

  if (flatten && !flattenSample) {
    return null;
  }

  return (
    <Container orientation="vertical" gap="md">
      <TwoColsContainer style={{ marginBottom: '-10px' }}>
        <Label>Sample Data</Label>

        {((format === 'AvroConfluent' &&
          isAvroFlattenEnabled &&
          flatten != null) ||
          (format === 'JSONEachRow' && flatten != null)) && (
          <Container orientation="horizontal" gap="md">
            <div css={alignRight}>
              <Switch
                checked={flatten}
                onCheckedChange={setFlatten}
                dir="end"
                orientation="horizontal"
                label="Flatten object"
                data-testid="flatten-switch"
              ></Switch>
            </div>
          </Container>
        )}
      </TwoColsContainer>

      {offsetInfo.partition && offsetInfo.offset && offsetInfo.timestamp && (
        <Panel
          alignItems="start"
          color="muted"
          orientation="vertical"
          fillWidth={true}
          style={{
            gap: '3px',
            padding: '25px 20px',
            marginBottom: '-8px'
          }}
        >
          <Label>Sample displayed from partition: {offsetInfo.partition}</Label>
          <Label>Offset: {offsetInfo.offset} </Label>
          <Label>Timestamp: {offsetInfo.timestamp}</Label>
        </Panel>
      )}

      {flatten && flattenSample && (
        <TwoColsContainer style={{ gap: '8px' }}>
          <CodeBlock
            css={sampleCodeStyle}
            language={format}
            data-testid="unflattened-syntax-highlighter"
            className="fs-exclude"
          >
            {sample}
          </CodeBlock>
          <CodeBlock
            css={flattenCodeStyle}
            language={format}
            data-testid="flattened-syntax-highlighter"
            className="fs-exclude"
          >
            {flattenSample}
          </CodeBlock>
        </TwoColsContainer>
      )}
      {!flatten && (
        <CodeBlock
          css={sampleCodeStyle}
          language={format}
          data-testid="syntax-highlighter"
          className="fs-exclude"
        >
          {sample}
        </CodeBlock>
      )}
    </Container>
  );
}
