import { css } from '@emotion/react';

export default {
  hostDetails: css({
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    width: '100%',
    gap: 'inherit'
  }),

  inputLabelStyle: css({
    width: '100%',
    label: {
      minWidth: 57
    }
  })
};
