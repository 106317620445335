import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSqlQueryFunction } from 'src/lib/clickhouse/query';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { validateTable } from 'src/lib/tableSchema/validate';
import { createTableStatement } from 'shared/src/tableSchema/generateSql';
import { useMetadataError, useTables } from 'src/metadata/metadataState';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import { createTableTab, useTabActions } from 'src/state/tabs';
import { useEditTable } from 'src/components/CreateTableView/context';
import { footerStyle } from 'src/components/CreateTableView/styles';
import { Button, createToast, Tooltip } from '@clickhouse/click-ui';

function Footer(): ReactElement {
  const { useReplaceCurrentTab } = useTabActions();
  const { tableState, setTableState, setPreviewOpen, setQueryError } =
    useEditTable();
  const schema = useSelectedDatabaseValue();
  const [running, setRunning] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [runQuery] = useSqlQueryFunction();

  useEffect(() => {
    setTableState((oldState) => ({
      ...oldState,
      schema
    }));
  }, [schema, setTableState]);

  const errors = useMemo(() => validateTable(tableState), [tableState]);
  const firstError = errors.length > 0 ? errors[0] : '';

  const replaceCurrentTab = useReplaceCurrentTab();
  const hasMetadataError = !!useMetadataError();

  const tables = useTables();

  // we have stash the table name in here on creation so when the metadata
  // reloads we'll know how to open the new table tab
  const [tableNameToOpen, setTableNameToOpen] = useState('');

  useEffect(() => {
    if (tables.length > 0 && !hasMetadataError && tableNameToOpen != '') {
      const table = tables.find(
        (searchTable) => searchTable.tableName === tableNameToOpen
      );
      if (table) {
        replaceCurrentTab(createTableTab(table));
      }
    }
  }, [tables, hasMetadataError, tableNameToOpen, replaceCurrentTab]);

  const runCreateTable = async (): Promise<void> => {
    setRunning(true);
    try {
      const statement = createTableStatement(tableState);
      const result = await runQuery(statement);
      if ('error' in result) {
        setQueryError(result.error);
      } else {
        setQueryError(undefined);
        setTableNameToOpen(tableState.name);
        createToast({
          title: 'Success',
          description: `Table "${tableState.name}" has been created successfully`,
          type: 'success'
        });
      }
    } catch (e) {
      setQueryError(errorMessage(e));
    } finally {
      setRunning(false);
    }
  };

  return (
    <div css={footerStyle}>
      <Button
        type="secondary"
        disabled={errors.length > 0}
        onClick={(): void => {
          setPreviewOpen(true);
        }}
      >
        Open Query
      </Button>
      <Tooltip
        open={tooltipOpen && errors.length !== 0}
        onOpenChange={setTooltipOpen}
      >
        <Tooltip.Trigger>
          <Button
            disabled={errors.length > 0 || running}
            onClick={runCreateTable}
            loading={running}
          >
            Create table
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content>{firstError}</Tooltip.Content>
      </Tooltip>
    </div>
  );
}

export default Footer;
