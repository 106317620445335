import {
  Badge,
  CardPrimary,
  Container,
  Icon,
  Link,
  Separator,
  Spacer,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import { ReactElement, ReactNode } from 'react';
import {
  getIntegrationDescription,
  getIntegrationLicenseLevelText,
  getIntegrationSupportLevelText
} from 'shared';
import { IntegrationSummaryType } from 'src/integrations/controller/useIntegrationSummary';
import styled from 'styled-components';

const CustomBadge = styled(Badge)<{ $capitalize?: boolean }>`
  text-transform: ${({ $capitalize }): string =>
    $capitalize ? ' capitalize' : 'initial'};
`;

const DetailsRow = ({
  label,
  value,
  capitalize
}: {
  label: ReactNode;
  value?: ReactNode;
  capitalize?: boolean;
}): ReactElement | null => {
  if (!value) {
    return null;
  }

  return (
    <Container isResponsive={false} justifyContent="space-between" gap="sm">
      <Text component="div">{label}</Text>
      <CustomBadge $capitalize={capitalize} text={value} />
    </Container>
  );
};

const IntegrationSummarySidebar = ({
  integration
}: {
  integration: IntegrationSummaryType;
}): ReactElement => {
  return (
    <Container
      orientation="vertical"
      alignItems="start"
      gap="lg"
      maxWidth="320px"
    >
      <Container orientation="vertical" isResponsive={false}>
        <Spacer size="sm" />
        <Text>Details</Text>
        <Separator size="md" />
        <CardPrimary disabled={true} size="md" style={{ cursor: 'default' }}>
          {integration.website && (
            <DetailsRow
              label="Provider"
              value={
                <Link target="_blank" href={integration.website}>
                  {integration.name}
                </Link>
              }
            />
          )}
          <DetailsRow
            label={
              <Container isResponsive={false} gap="sm">
                <Text>Support Level</Text>
                <Tooltip>
                  <Tooltip.Trigger>
                    <Icon name="info-in-circle" />
                  </Tooltip.Trigger>
                  <Tooltip.Content maxWidth="320px" align="center">
                    {getIntegrationDescription(integration.supportLevel)}
                  </Tooltip.Content>
                </Tooltip>
              </Container>
            }
            value={getIntegrationSupportLevelText(integration.supportLevel)}
            capitalize
          />
          {integration.version && (
            <DetailsRow label="Version" value={integration.version} />
          )}
          {integration.license && (
            <DetailsRow
              label="License"
              value={getIntegrationLicenseLevelText(integration.license)}
            />
          )}
        </CardPrimary>
      </Container>
      {integration.events.length > 0 && (
        <div>
          <Text>You might be interested in</Text>
          <Separator size="md" />
          {integration.events.map((event, index) => (
            <CardPrimary
              key={`event-${integration.name}-${index}`}
              onClick={() => {
                window.open(
                  event?.url ?? `https://clickhouse.com/blog/${event.slug}`,
                  '_blank'
                );
              }}
            >
              <Text weight="semibold">{event.title}</Text>
              <Text>{event.shortDescription}</Text>
              <Separator size="xs" />
              <Container justifyContent="end" isResponsive={false} gap="sm">
                <Text>Read more</Text>
                <Icon name="chevron-right" />
              </Container>
            </CardPrimary>
          ))}
        </div>
      )}
    </Container>
  );
};

export default IntegrationSummarySidebar;
