import React, { ReactElement } from 'react';
import ImportFileInput from 'src/components/ImportView/ImportFileInput';
import { CardPrimary, Text } from '@clickhouse/click-ui';

function FileDropPanel(): ReactElement {
  return (
    <ImportFileInput>
      <CardPrimary
        icon="upload"
        title="Upload File"
        size="md"
        alignContent="center"
        description={
          <>
            <Text size="md" align="center" weight="medium">
              Drag your file here or click to browse. We support JSON, CSV and
              TSV.
            </Text>
            <Text align="center" color="muted">
              Max file size: 1GB
            </Text>
          </>
        }
      />
    </ImportFileInput>
  );
}

export default React.memo(FileDropPanel);
