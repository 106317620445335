const isMac = navigator.platform.indexOf('Mac') === 0;
const isIphone = navigator.platform === 'iPhone';

export const shortcutSymbol = isMac || isIphone ? '⌘' : 'Ctrl';

export type BrowserPlatform = 'linux' | 'mac' | 'windows' | 'unknown';

/** Returns platform of the active browser. */
export function getBrowserPlatform(): BrowserPlatform {
  // See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform.
  if (!navigator?.platform) return 'unknown';
  if (navigator.platform.includes('Linux')) return 'linux';
  if (navigator.platform.includes('Mac')) return 'mac';
  if (navigator.platform.includes('Win')) return 'windows';
  return 'unknown';
}
