import ReactDOMServer from 'react-dom/server';
import * as Highcharts from 'highcharts';
import { formatDataSize } from 'src/lib/formatters/numberFormatter';
import { chartTooltipSeries } from 'src/components/Metrics/styles';
import { SerializedStyles } from '@emotion/react';

export const DEFAULT_COLOR_FOR_METRICS = '#2CA300';

/** Generates area chart fill gradient stops based on the series (line) color. */
export const generateLinearGradientStops = (
  color: string = DEFAULT_COLOR_FOR_METRICS
): Array<Highcharts.GradientColorStopObject> => {
  if (!color.startsWith('#') || color.length !== 7) {
    throw new Error('invalid color');
  }
  return [
    [-0.2302, `${color}90`],
    [-0.1782, `${color}33`],
    [1, `${color}00`]
  ] as Array<Highcharts.GradientColorStopObject>;
};

export const getXAxisDateTimeOptions = (): Highcharts.XAxisOptions => {
  return {
    title: { text: '' },
    type: 'datetime',
    showEmpty: false,
    // See https://api.highcharts.com/highstock/xAxis.dateTimeLabelFormats for defaults.
    // We have 'millis' rounded to 'seconds' to make 1-point mode
    // (when Highcharts can't detect the correct rounding interval) do not show millis at all.
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b',
      week: '%e. %b',
      month: "%b '%y",
      year: '%Y'
    }
  };
};

interface options {
  css?: SerializedStyles;
  seriesName?: string;
  tooltipDataSizePointFormat?: string;
  tooltipValueSuffix?: string;
  yValue?: number;
}

export const tooltipPointFormatter = ({
  css,
  seriesName,
  tooltipDataSizePointFormat,
  tooltipValueSuffix,
  yValue
}: options): string => {
  const dataSizeFormat = tooltipDataSizePointFormat || 'short-scale-letter';
  const suffix = tooltipValueSuffix || '';
  let name = seriesName ?? '';
  const detailsStartIndex = name.indexOf('(');
  if (detailsStartIndex > 0) {
    name = name.substring(0, detailsStartIndex).trim();
  }
  const formattedValue = formatDataSize(yValue || 0, dataSizeFormat) + suffix;
  const element = (
    <div css={css ?? chartTooltipSeries}>
      {name}: <span className="chart_tooltip_value">{formattedValue}</span>
    </div>
  );
  const renderedElement = ReactDOMServer.renderToString(element).replaceAll(
    '<!-- -->',
    ''
  );
  return renderedElement;
};

/**
 * This function calculates the height of chart ticks on the y axis so that the points of the graph
 * fit nicely within the space of the graph.
 *
 * It calculates the highest tick as a function of the maximum value of all points.
 * It then calculates the middle tick as a function of the highest tick.
 *
 * @see https://api.highcharts.com/highstock/yAxis.tickPositioner
 **/
export const calculateTickPosition = (maxValue: number): Array<number> => {
  if (maxValue <= 0) {
    return [0];
  }

  const base = 10;
  const exponent = Math.floor(Math.log(maxValue) / Math.log(base));
  const topTick =
    Math.ceil(maxValue / Math.pow(base, exponent)) * Math.pow(base, exponent);
  const middleTick = topTick / 2;
  return [0, middleTick, topTick];
};

export const tickPositioner = (
  seriesData: Array<Array<number>>
): Array<number> => {
  const maxValue: number =
    seriesData.length > 1
      ? seriesData.reduce(
          (max: number, [, queryCount]: Array<number>): number => {
            return Math.max(max, queryCount);
          },
          0
        )
      : seriesData[0][1];

  return calculateTickPosition(maxValue);
};

export interface CHSeriesLineOptions extends Highcharts.SeriesLineOptions {
  lineColor?:
    | Highcharts.ColorString
    | Highcharts.GradientColorObject
    | Highcharts.PatternObject;
}
