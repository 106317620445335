import { ReactElement } from 'react';
import { Spacer, Text, Link } from '@clickhouse/click-ui';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';
import {
  PASSWORD_MASK,
  ProtocolProps,
  getNativeConnectionString
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import { FullWidthCodeBlock } from 'src/components/ServiceConnect/Tabs/FullWidthCodeBlock';

interface NativeConnectionTabProps {
  username: string;
  credentials: ProtocolProps;
  showPassword?: boolean;
}

const NativeConnectionTab = ({
  username,
  credentials,
  showPassword
}: NativeConnectionTabProps): ReactElement => {
  const { hostname, password } = credentials;
  const unmasked = getNativeConnectionString(hostname, username, password);
  const masked = password
    ? getNativeConnectionString(hostname, username, PASSWORD_MASK)
    : undefined;
  return (
    <>
      <h3>Installation</h3>
      <Text>
        Run the following command in your terminal to install it on Linux,
        macOS, or FreeBSD.
        <br />
        Or check out all the{' '}
        <Link
          href="https://clickhouse.com/docs/en/install"
          target="_blank"
          icon="share-arrow"
        >
          installation options
        </Link>
      </Text>
      <Spacer />
      <FullWidthCodeBlock
        language="bash"
        showLineNumbers
        wrapLines
        data-testid="service-connect-code-block-native-install"
      >
        curl https://clickhouse.com/ | sh
      </FullWidthCodeBlock>
      <h3>Connection</h3>
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        data-testid="service-connect-code-block-native-usage"
        showPassword={showPassword}
      />
      <Spacer />
      <Text>
        Visit the documentation to learn how to use the{' '}
        <Link
          href="https://clickhouse.com/docs/en/getting-started/quick-start#3-start-the-client"
          target="_blank"
          icon="share-arrow"
        >
          ClickHouse Client
        </Link>
      </Text>
    </>
  );
};

export default NativeConnectionTab;
