import { useRef, useCallback, RefObject, useMemo } from 'react';

import { Resizable } from 're-resizable';

import { UpdateTabFunc, useUpdateTab } from 'src/state/tabs';
import { QueryTab } from 'src/state/tabs/types';
import {
  useFunctions,
  useMaterializedViews,
  useMetadataError,
  useMetadataLoading,
  useTables,
  useViews
} from 'src/metadata/metadataState';
import { useConnectionCredentials } from 'src/state/connection';
import { AutocompleteProviderOptions, Resource } from 'src/lib/autocomplete/types';
import { SchemaFunction, Table } from 'shared/src/clickhouse';
import debounce from 'lodash/debounce';
import { QueryVariable } from 'shared/src/sql/parse/parseQueryVariables';

type MetadataToAutocompleteOptionsArgs = {
  tables: Table[];
  views: Table[];
  materializedViews: Table[];
  functions: SchemaFunction[];
  hasMetadataError: boolean;
  isMetadataLoading: boolean;
};

function metadataToAutocompleteOptions({
  tables,
  views,
  functions: functionsList,
  materializedViews,
  hasMetadataError,
  isMetadataLoading
}: MetadataToAutocompleteOptionsArgs): {
  resources: Resource[] | null;
  functions: Resource[] | null;
} {
  if (isMetadataLoading || hasMetadataError) {
    return { resources: null, functions: null };
  }

  const tableList: Table[] = tables.concat(views).concat(materializedViews);
  const resources = tableList.map((table) => ({
    id: table.id || `${table.schema ?? ''}.${table.tableName}`,
    name: table.tableName,
    properties: {
      schema: table.schema,
      columns: table.columns,
      type: table.type
    }
  }));

  const functions = functionsList.map((fn) => ({
    id: fn.name,
    name: fn.name,
    properties: {
      type: 'function'
    }
  }));

  return { resources, functions };
}

export function useAutocompleteOptions(): AutocompleteProviderOptions {
  const tables = useTables();
  const views = useViews();
  const materializedViews = useMaterializedViews();
  const hasMetadataError = !!useMetadataError();
  const functions = useFunctions();
  const isMetadataLoading = useMetadataLoading();
  const { selectedDatabase } = useConnectionCredentials();

  return useMemo(() => {
    const resources = metadataToAutocompleteOptions({
      tables,
      views,
      materializedViews,
      functions,
      hasMetadataError,
      isMetadataLoading
    });
    return {
      id: `my-connection-id:${selectedDatabase}`,
      type: 'pg',
      resources: resources.resources,
      functions: resources.functions,
      schema: 'public'
    };
  }, [tables, views, materializedViews, functions, selectedDatabase, hasMetadataError, isMetadataLoading]);
}

interface UseDebouncedQueryVariablesUpdateArgs {
  tabId?: string;
  updateQueryVariables: (queryVariables: Record<string, string>) => void;
  updateTab?: UpdateTabFunc;
}

interface UpdateHookProps {
  newSql: string;
  oldQueryVariables: Record<string, string>;
  newQueryVariablesList: QueryVariable[];
}

const QUERY_VARIABLES_DEBOUNCE = 250;

export function useDebouncedQueryVariablesUpdate({
  tabId,
  updateQueryVariables,
  updateTab
}: UseDebouncedQueryVariablesUpdateArgs) {
  return useCallback(
    debounce(
      ({ oldQueryVariables, newSql, newQueryVariablesList }: UpdateHookProps) => {
        const newQueryVariables = Object.fromEntries(
          newQueryVariablesList.map((newVariable) => {
            return [newVariable.name, oldQueryVariables[newVariable.name] ?? ''];
          })
        );

        const anyVariablesAdded = Object.keys(newQueryVariables).some(
          (varName) => !Object.hasOwnProperty.call(oldQueryVariables, varName)
        );

        const anyVariablesRemoved = Object.keys(oldQueryVariables).some(
          (varName) => !Object.hasOwnProperty.call(newQueryVariables, varName)
        );

        if (anyVariablesAdded || anyVariablesRemoved) {
          updateQueryVariables(newQueryVariables);
        }

        if (typeof updateTab === 'function' && tabId) {
          updateTab(tabId, {
            query: newSql,
            queryVariables: newQueryVariables
          });
        }
      },
      QUERY_VARIABLES_DEBOUNCE,
      {
        leading: false,
        trailing: true
      }
    ),
    []
  );
}

interface UseResizableEditor {
  editorHeight: string;
  recalculateEditorHeight: (numRows: number) => void;
  onResize: () => void;
  editorContainerRef: RefObject<Resizable>;
}

function getEditorHeight(status: string, tab: QueryTab): string {
  return status === 'new' ? '100%' : tab.editorHeight || '50%';
}

export function useResizableEditor(status: string, tab: QueryTab): UseResizableEditor {
  const editorContainerRef = useRef<Resizable>(null);
  const editorResizedRef = useRef<boolean>(false);
  const editorHeight = getEditorHeight(status, tab);
  const query = tab.query || '';
  const updateTab = useUpdateTab();

  const recalculateEditorHeight = useCallback(
    (numRows: number) => {
      if (
        editorContainerRef.current &&
        status !== 'new' &&
        editorContainerRef.current.resizable &&
        !editorResizedRef.current
      ) {
        editorResizedRef.current = true;

        const parentHeight = editorContainerRef.current.resizable.parentElement?.scrollHeight || 0;

        const minHeight = Math.max(parentHeight * 0.15, (query || '').split('\n').length * 24 + 16 + 5);

        const availableSpace = parentHeight - (numRows * 24 + 16);
        const fiftyPercent = Math.floor(parentHeight / 2);
        const newFlexBasis = Math.min(availableSpace > minHeight ? availableSpace : minHeight, fiftyPercent);

        updateTab(tab.id, { editorHeight: `${newFlexBasis}px` });
        editorContainerRef.current.resizable.style.flexBasis = `${newFlexBasis}px`;
      }
    },
    [query, status, tab.id, updateTab]
  );

  const onResize = (): void => {
    if (editorContainerRef?.current?.resizable) {
      updateTab(tab.id, {
        editorHeight: editorContainerRef.current.resizable.style.flexBasis
      });
    }
  };

  return {
    editorHeight,
    recalculateEditorHeight,
    onResize,
    editorContainerRef
  };
}
