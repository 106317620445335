import { ReactElement, useEffect, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import MarketplaceCancellationModal from 'src/components/PaymentAlertModals/MarketplaceCancellationModal';
import NonpaymentModal from 'src/components/PaymentAlertModals/NonpaymentModal';
import TrialExpiredModal from 'src/components/PaymentAlertModals/TrialExpiredModal';
import { routes } from 'src/lib/routes';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';

function PaymentAlertModals(): ReactElement | null {
  const [isTrialExpiredModalOpen, setIsTrialExpiredModalOpen] = useState(false);
  const [isNonpaymentModalOpen, setIsNonpaymentModalOpen] = useState(false);
  const [
    isMarketplaceCancellationModalOpen,
    setIsMarketplaceCancellationModalOpen
  ] = useState(false);
  const { billingStatus, id: currentOrgId } = useCurrentOrganizationOrThrow();
  const hasManageBillingPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage-billing',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  useEffect(() => {
    if (
      billingStatus === 'IN_TRIAL_GRACE_PERIOD' ||
      billingStatus === 'DECOMMISSIONED'
    ) {
      // Show Trial Expired dialog every time when billing status changes to IN_TRIAL_GRACE_PERIOD or DECOMMISSIONED,
      // or on the first render if the billing status is already IN_TRIAL_GRACE_PERIOD or DECOMMISSIONED.
      setIsTrialExpiredModalOpen(true);
    } else if (billingStatus === 'IN_NONPAYMENT_GRACE_PERIOD') {
      // Show Nonpayment dialog every time when billing status changes to IN_NONPAYMENT_GRACE_PERIOD,
      // or on the first render if the billing status is already IN_NONPAYMENT_GRACE_PERIOD.
      setIsNonpaymentModalOpen(true);
    } else if (billingStatus === 'IN_MP_GRACE_PERIOD') {
      // Show marketplace cancellation dialog every time when billing status changes to IN_MP_GRACE_PERIOD,
      // or on the first render if the billing status is already IN_MP_GRACE_PERIOD.
      setIsMarketplaceCancellationModalOpen(true);
    } else {
      // if the billing status is updated we should automatically close the dialogs
      setIsNonpaymentModalOpen(false);
      setIsMarketplaceCancellationModalOpen(false);
      setIsTrialExpiredModalOpen(false);
    }
  }, [billingStatus]);

  const onEnterPaymentDetails = (): void => {
    navigateTo(routes.billingHome({ orgId: currentOrgId }));
  };

  const onContactSupport = (): void => {
    // TODO change it to the Support page URL when the page is ready.
    navigateTo('/profile');
  };

  return (
    <>
      <NonpaymentModal
        hasManageBillingPermission={hasManageBillingPermission}
        open={isNonpaymentModalOpen}
        onEnterPaymentDetails={onEnterPaymentDetails}
        onContactSupport={onContactSupport}
        onClose={(): void => setIsNonpaymentModalOpen(false)}
      />
      <MarketplaceCancellationModal
        open={isMarketplaceCancellationModalOpen}
        onContactSupport={onContactSupport}
        onClose={(): void => setIsMarketplaceCancellationModalOpen(false)}
      />
      <TrialExpiredModal
        hasManageBillingPermission={hasManageBillingPermission}
        open={isTrialExpiredModalOpen}
        onCancel={(): void => setIsTrialExpiredModalOpen(false)}
        onEnterPaymentDetails={onEnterPaymentDetails}
      />
    </>
  );
}

export default PaymentAlertModals;
