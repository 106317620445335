import {
  Alert,
  Container,
  GridContainer,
  RadioGroup,
  Select,
  Text,
  TextField
} from '@clickhouse/click-ui';
import { useMemo, useState } from 'react';
import {
  ClickPipePostgresImport,
  PostgresImport
} from 'shared/src/dataLoading';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { Draft } from 'immer';
import {
  useDatabases,
  useMetadataError,
  useMetadataLoading
} from 'src/metadata/metadataState';
import { isUserDatabase } from 'src/components/ImportView/ImportForm/DestinationTableSettings';

const ConfigureDatabases = () => {
  const existingDatabases = useDatabases();
  const isMetadataLoading = useMetadataLoading();
  const hasMetadataError = Boolean(useMetadataError());
  const dbOptions = useMemo(() => {
    if (isMetadataLoading) {
      return [];
    } else {
      const options =
        existingDatabases.map((db) => ({
          label: db.name,
          value: db.name,
          className: 'fs-exclude'
        })) ?? [];

      return options.filter((option) => isUserDatabase(option.value));
    }
  }, [existingDatabases, isMetadataLoading, hasMetadataError]);

  const [databaseSelect, setDatabaseSelect] = useState<'new' | 'existing'>(
    'existing'
  );
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipePostgresImport>();

  const data = importModel.data;
  const setTargetClickhouseDatabase = (databaseName: string) => {
    updateImportModel((draft: Draft<PostgresImport>) => {
      draft.data.cdcSettings.targetClickhouseDatabase = databaseName;
    });
  };

  return (
    <Container orientation="vertical" gap="md">
      <Text color="default">
        Select or enter the ClickHouse database where you want to replicate your
        tables
      </Text>
      <GridContainer
        fillWidth
        gridTemplateColumns={'25% auto'}
        alignItems="center"
      >
        <RadioGroup
          onValueChange={(val) => setDatabaseSelect(val as 'new' | 'existing')}
          value={databaseSelect}
        >
          <RadioGroup.Item
            value="existing"
            label="Existing database"
            data-testid="clickpipes-postgres-existing-database"
          />
          <RadioGroup.Item
            value="new"
            label="New database"
            data-testid="clickpipes-postgres-new-database"
          />
        </RadioGroup>

        {databaseSelect === 'existing' ? (
          <Select
            disabled={hasMetadataError || isMetadataLoading}
            onSelect={setTargetClickhouseDatabase}
            value={data.cdcSettings.targetClickhouseDatabase}
            placeholder="Select a database"
            data-testid="clickpipes-postgres-existing-database-select"
            options={dbOptions}
            className="fs-exclude"
          />
        ) : (
          <TextField
            onChange={setTargetClickhouseDatabase}
            value={data.cdcSettings.targetClickhouseDatabase}
            placeholder="Enter database name"
            data-testid="clickpipes-postgres-new-database-field"
          />
        )}
      </GridContainer>
    </Container>
  );
};
export default ConfigureDatabases;
