import { ReactElement, useState } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { useOrganizationBillingDetails } from 'src/billing/controllers/useOrganizationBillingDetails';
import { useUpdateBillingContact } from 'src/billing/controllers/useUpdateBillingContact';
import BillingCardModal from 'src/components/BillingCardModal';
import { BillingContactModal } from 'src/components/BillingContactModal';
import { useCurrentUserWithRoleInCurrentOrg } from 'src/lib/user/useCurrentUserWithRoleInCurrentOrg';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';
import BillingPage from 'src/pages/OrganizationBillingPage/BillingPage';

const OrganizationBillingPage = (): ReactElement | null => {
  const currentUser = useCurrentUserWithRoleInCurrentOrg();
  const {
    isLoading,
    getOrganizationBillingDetails,
    organizationBillingDetails
  } = useOrganizationBillingDetails();
  const currentOrganization = useCurrentOrganizationOrThrow();
  const hasManageBillingPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage-billing',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const [billingCardModalVisible, setBillingCardModalVisible] = useState(false);
  const [
    isEditBillingContactModalVisible,
    setIsEditBillingContactModalVisible
  ] = useState<boolean>(false);
  const { updateBillingContact } = useUpdateBillingContact();

  if (!currentUser || !currentOrganization || !hasManageBillingPermission) {
    return null;
  }

  const showBillingContactModal = function (): void {
    setIsEditBillingContactModalVisible(true);
  };

  const hideBillingContactModal = function (): void {
    setIsEditBillingContactModalVisible(false);
  };

  const saveBillingContact = async function (
    billingContact: string
  ): Promise<void> {
    await updateBillingContact(currentOrganization.id, billingContact);
    void getOrganizationBillingDetails();
  };

  return (
    <>
      <BillingPage
        currentOrganization={currentOrganization}
        organizationBillingDetails={organizationBillingDetails}
        onOpenBillingCardModal={(): void => {
          setBillingCardModalVisible(true);
        }}
        onEditBillingContact={showBillingContactModal}
        isLoading={isLoading}
      />
      <BillingCardModal
        isOpen={billingCardModalVisible}
        organization={currentOrganization}
        organizationBillingDetails={organizationBillingDetails}
        getOrganizationBillingDetails={getOrganizationBillingDetails}
        onClose={(): void => {
          setBillingCardModalVisible(false);
          getOrganizationBillingDetails().catch((error) => {
            console.error(error);
          });
        }}
        loadingBillingDetails={isLoading}
      />
      {isEditBillingContactModalVisible && (
        <BillingContactModal
          billingContact={organizationBillingDetails.billingContact}
          onClose={hideBillingContactModal}
          isOpen={isEditBillingContactModalVisible}
          organization={currentOrganization}
          onChange={saveBillingContact}
        />
      )}
    </>
  );
};

export default OrganizationBillingPage;
