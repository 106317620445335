import { Separator, Container } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { CloudStatusEmbed } from 'src/components/CloudStatusEmbed';
import {
  CustomSidebarItem,
  SidebarItem
} from 'src/layout/ServiceSidebar/SidebarItem';
import { getCurrentServiceId } from 'src/state/service';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useDrift } from 'src/drift/useDrift';
import { useMainSidebar } from 'src/layout/SidebarWrapper/SidebarWrapper';

export const SidebarFooter = (): ReactElement => {
  const instanceId = getCurrentServiceId();
  const { openChat } = useDrift();

  const { closeSidebar } = useMainSidebar();

  return (
    <>
      <Separator size="sm" />
      <Container alignItems="start" orientation="vertical" padding="md">
        <SidebarItem
          type="main"
          icon="puzzle-piece"
          label="Integrations"
          path={
            instanceId
              ? '/services/:serviceId/integrations/*'
              : '/integrations/*'
          }
          data-testid="integrationsSidebarButton"
          onClick={(): void => {
            navigateTo(routes.integrations({ serviceId: instanceId }));
            closeSidebar();
          }}
        />
        <CustomSidebarItem
          type="main"
          icon="chat"
          label="Chat with support"
          onClick={openChat}
          $collapsible={false}
          data-testid="chat-with-support-button"
        />
        <CloudStatusEmbed />
      </Container>
    </>
  );
};
