import { ReactElement, ReactNode } from 'react';

import { DatabaseMetadata } from 'src/metadata/metadataState';
import { MetadataInitializerWithCache } from 'src/components/MetadataInitializer/MetadataInitializerWithCache';

export interface Metadata {
  error?: string | null;
  loading: boolean;
  metadata?: DatabaseMetadata;
  reload: (cached?: boolean) => Promise<void>;
}

type MetadataContextProps = {
  children: ReactNode;
};

export const MetadataInitializer = ({
  children
}: MetadataContextProps): ReactElement => {
  return (
    <MetadataInitializerWithCache>{children}</MetadataInitializerWithCache>
  );
};
