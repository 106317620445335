import { Logo } from '@clickhouse/click-ui';
import { stringify } from 'qs';
import { PropsOf } from 'types/commonTypes';

type LogoName = PropsOf<typeof Logo>['name'];

const strapiApiUrl = 'https://cms.clickhouse-dev.com:1337';
const url = `${strapiApiUrl}/api/`;
const logo = [
  'airbyte',
  'aws-s3',
  'confluent',
  'dbt',
  'gcs',
  'kafka',
  'mysql',
  'postgres',
  'prequel',
  'vector',
  'deepnote',
  'grafana',
  'hex',
  'metabase',
  'superset',
  'tableau',
  'clickhouse',
  'datagrip',
  'dbeaver',
  'c#',
  'golang',
  'jdbc',
  'nodejs',
  'python',
  'mongodb'
] as const;
type LogoArray = (typeof logo)[number];
const isLogo = (x: any): x is LogoArray => logo.includes(x);

const stagingOnlyFilter = { $eq: false };
export function getStagingOnlyFilters(): Array<Record<'StagingOnly', any>> {
  return [{ StagingOnly: { $null: true } }, { StagingOnly: stagingOnlyFilter }, { StagingOnly: { $eq: false } }];
}

export async function getPathsValues(
  pathName: string,
  obj: Record<string, any>,
  type = 'slug',
  isDynamicUrl = false,
  paramName = 'slug',
  list: any[] = [],
  pageNumber: number = 1
) {
  const newParam = {
    ...obj,
    pagination: {
      page: pageNumber
    }
  };
  const { data, pagination } = await findAll(pathName, newParam);
  // @ts-ignore
  const urlList = data.map((page: Record<string, any>) => {
    const slugList = isDynamicUrl ? page[type].split('/').filter((slug: string) => slug.length > 0) : page[type];
    return {
      params: {
        [paramName]: slugList
      }
    };
  });
  if (pagination.pageCount > pageNumber) {
    const newPages = await getPathsValues(pathName, obj, type, isDynamicUrl, pathName, urlList, pageNumber + 1);
    list = list.concat(newPages);
  } else {
    list = list.concat(urlList);
  }

  return list;
}

export async function fetchAll(
  pathName: string,
  params: Record<string, any>,
  list: any[] = [],
  pageNumber: number = 1
) {
  const newParam = {
    ...params,
    pagination: {
      page: pageNumber
    }
  };
  const { data, pagination } = await findAll(pathName, newParam);
  if (pageNumber < pagination.pageCount) {
    const newData = await fetchAll(pathName, params, data, pageNumber + 1);
    list = list.concat(newData);
  } else {
    list = list.concat(data);
  }
  return list;
}

function convertSvg(convertedObj: Record<string, any>): Record<string, any> {
  if (convertedObj?.mime && (convertedObj.mime.includes('svg') || convertedObj.mime.includes('png'))) {
    convertedObj.fullUrl = `${strapiApiUrl}${convertedObj.url}`;
  }
  return convertedObj;
}

async function convertStrapiObjectArray(item: Record<string, any>): Promise<Record<string, any>> {
  const convertedObj = await convertStrapiObject(item);
  return convertSvg(convertedObj);
}

export async function convertStrapiObject(element: any): Promise<Record<string, any>> {
  const newElement = 'attributes' in element && 'id' in element ? { id: element.id, ...element.attributes } : element;
  const result: Record<string, any> = {};
  for (const entry of Object.entries(newElement)) {
    const field: string = entry[0];
    const fieldValue: any = entry[1];

    if (Array.isArray(fieldValue)) {
      result[field] = await Promise.all(fieldValue.map(convertStrapiObjectArray));
      continue;
    }

    if (typeof fieldValue === 'object' && fieldValue) {
      if ('data' in fieldValue && Array.isArray(fieldValue.data)) {
        result[field] = await Promise.all(fieldValue.data.map(convertStrapiObjectArray));
        continue;
      }
      let convertedObj: any = await convertStrapiObject(fieldValue);
      if ('data' in convertedObj && Object.keys(convertedObj).length === 1) {
        convertedObj = convertedObj.data;
      }

      result[field] = convertSvg(convertedObj);
      continue;
    }

    result[field] = fieldValue;
  }
  return result;
}

export type StrapiPagination = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export async function findAll<T>(
  pathName: string,
  params: Record<string, unknown>
): Promise<{ data: T[]; pagination: StrapiPagination }> {
  const newParamString = stringify(params, {
    encodeValuesOnly: true // prettify URL
  });

  const response = await fetch(`${url}${pathName}${newParamString.length > 0 ? `?${newParamString}` : ''}`);

  const { data, meta } = await response.json();
  const dataList = await Promise.all(
    data.map(async (item: Record<string, any>): Promise<Record<string, any>> => {
      const converted = await convertStrapiObject(item);
      return converted;
    })
  );
  return {
    data: dataList,
    pagination: meta.pagination
  };
}

export async function findOne(pathName: string, params: Record<string, any>) {
  const newParamString = stringify(params, {
    encodeValuesOnly: true // prettify URL
  });
  const response = await fetch(`${url}${pathName}${newParamString.length > 0 ? `?${newParamString}` : ''}`);

  const { data } = await response.json();
  return await convertStrapiObject(data);
}

export async function findHeader(requestString: string) {
  const headerData = await findOne(requestString, {
    populate: ['seo', 'seo.image']
  });

  return headerData.seo;
}

/**
 * Convert integration name to logo name.
 * Note that not all integrations have a logo in ClickUI, so we return undefined in that case.
 */
export function mapIntegrationToClickUiLogo(integrationName: string): LogoName | undefined {
  const lowerIntegrationName = integrationName.toLowerCase();

  if (isLogo(lowerIntegrationName)) {
    return lowerIntegrationName.replace(/^\s+|\s+$/g, '') as LogoName;
  } else {
    switch (lowerIntegrationName) {
      case 'amazon s3':
        return 'aws-s3' as LogoName;
      case 'confluent cloud':
        return 'confluent' as LogoName;
      case 'google cloud storage (gcs)':
        return 'gcs' as LogoName;
      case 'postgresql':
        return 'postgres' as LogoName;
      case 'clickhouse client':
        return 'clickhouse' as LogoName;
      case 'go':
        return 'golang' as LogoName;
      case 'node.js':
        return 'nodejs' as LogoName;
      case 'java':
        return 'jdbc' as LogoName;
      default:
        return undefined;
    }
  }
}
