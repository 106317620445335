import { ReactElement, useState } from 'react';

import isEqual from 'lodash/isEqual';
import { createToast } from 'primitives';

import { logEvent } from 'src/components/QueryView/analytics';
import { InteractionType } from 'src/lib/logger';

import { Field } from 'src/components/TableView/DataView/RightBar/RightBarField';
import { Button, FlyoutProps } from '@clickhouse/click-ui';
import ResizableFlyout from 'src/components/RightBar/ResizableFlyout';

interface Column {
  name: string;
  type: string;
}

interface InsertRowProps extends FlyoutProps {
  columns?: Column[];
  container?: HTMLElement | null;
  insert: (props: any) => Promise<void>;
}

export default function InsertRow({
  insert,
  columns = [],
  ...props
}: InsertRowProps): ReactElement {
  const [fields, setFields] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const disabled = isEqual(fields, {});

  const onInsert = async (): Promise<void> => {
    try {
      logEvent('insertRow', 'insertButtonClick');
      setLoading(true);
      await insert(fields);
      setFields({});
      createToast(
        'Successfully inserted row!',
        'success',
        'The row was successfully inserted.'
      );
    } catch (e: any) {
      createToast('Error', 'alert', e.message);
    } finally {
      setLoading(false);
    }
  };
  const log = (event: string, interaction?: InteractionType): void =>
    logEvent('insertRow', event, interaction);

  return (
    <ResizableFlyout header="Insert Row" logEvent={log} {...props}>
      <ResizableFlyout.Body data-testid="rightBarInsertRowContent">
        {columns.map((column, i) => (
          <Field
            key={i}
            name={column.name}
            onChange={(value: string): void => {
              setFields((fields) => {
                const newFields: Record<string, string> = { ...fields };
                newFields[column.name] = value;
                return newFields;
              });
            }}
            type={column.type}
            value={fields[column.name] ?? ''}
            logEvent={log}
          />
        ))}
      </ResizableFlyout.Body>
      <ResizableFlyout.Footer>
        <ResizableFlyout.Close label="Cancel" />
        <Button
          loading={loading}
          disabled={loading || disabled}
          onClick={onInsert}
        >
          Insert
        </Button>
      </ResizableFlyout.Footer>
    </ResizableFlyout>
  );
}
