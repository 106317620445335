import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAuth } from 'src/components/auth';
import config from 'src/lib/config';
import socketIoUrl from 'src/lib/config/socketIoUrl';

import { DataImport } from 'types/prisma';
import { io, Socket } from 'socket.io-client';

import { useParams } from 'react-router-dom';

import type { TableDrop } from 'shared/src/types/query';
import { loadDatabaseMetadata } from 'src/components/MetadataInitializer/metadataEmitter';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import { refreshSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesEmitter';

interface ServerToClientEvents {
  queryAccessChanged: () => void;
  queryListChanged: () => void;
  metadataChanged: () => void;
  tableDrops: (tableDrops: TableDrop[]) => void;
  queryChanged: (queryId: string, changedByEmail: string) => void;
  queryDeleted: (queryId: string, deletedByEmail: string) => void;
  dataImportChanged: (dataImport: DataImport) => void;
}

type ClientToServerEvents = Record<never, never>;

type Client = Socket<ServerToClientEvents, ClientToServerEvents>;

interface SocketIoClient {
  client: Socket | null;
}

type Props = {
  children: ReactNode;
};

export const SocketIoContext = React.createContext<SocketIoClient>({
  client: null
});

export function useSocketIo(): SocketIoClient {
  return useContext(SocketIoContext);
}

export const SocketIoProvider = ({ children }: Props): ReactElement => {
  const params = useParams();
  const { isAuthenticated, loading, getAccessToken } = useAuth();
  const serviceId: string | undefined = params.serviceId;
  const database = useSelectedDatabaseValue();

  const [client, setClient] = useState<Socket | null>(null);
  const clientRef = useRef<Socket | null>(null);

  useEffect(() => {
    const initSocketIo = (serviceId: string, token: string): void => {
      console.log(`initializing socketIo for service ${serviceId}`);
      const client: Client = io(socketIoUrl[config.env], {
        path: '/socket.io',
        query: {
          token,
          serviceId
        },
        transports: ['websocket']
      });

      client.on('connect', () => {
        console.log(`socketio connected for service ${serviceId}`);
      });

      client.on('connect_error', (e) => {
        console.error(`socketio connection error for ${serviceId}`, e.message);
      });

      client.on('disconnect', (reason, details) => {
        let description;
        if (details instanceof Error) {
          description = details.message;
        } else {
          description = details?.description;
        }
        console.log(`socketio disconnected:
  ${reason}
  ${description}`);
      });

      client.on('queryAccessChanged', () => {
        // could do something more efficient to fetch this specific query?
        console.log('socketio received queryAccessChanged');
        refreshSavedQueries();
      });

      client.on('queryListChanged', () => {
        console.log('socketio received queryListChanged');
        refreshSavedQueries();
      });

      client.on('queryChanged', (queryId, changedByEmail) => {
        // could do something more efficient to fetch this specific query?
        console.log(
          `socketio received queryChanged queryId=${queryId} by=${changedByEmail}`
        );
        refreshSavedQueries();
      });

      client.on('queryDeleted', (queryId, deletedByEmail) => {
        // could do something more efficient to fetch this specific query?
        console.log(
          `socketio received queryDeleted queryId=${queryId} by=${deletedByEmail}`
        );
        refreshSavedQueries();
      });

      client.on('metadataChanged', () => {
        console.log('socketio received metadataChanged');

        // We force the cache to be refreshed
        console.log('Loading metadata from WS', database);
        loadDatabaseMetadata({ cached: false, database, serviceId });
      });

      setClient(client);
      clientRef.current = client;
    };

    if (isAuthenticated && !loading && serviceId) {
      getAccessToken()
        .then((accessToken) => {
          if (accessToken) {
            try {
              initSocketIo(serviceId, accessToken);
            } catch (e) {
              console.error('Could not init socketio: ', e);
            }
          }
        })
        .catch((e) =>
          console.error('could not get access token for socketio:', e)
        );
    }

    return (): void => {
      if (clientRef.current) {
        console.log('Shutting down socketio');
        clientRef.current.close();
      }
      setClient(null);
      clientRef.current = null;
    };
  }, [getAccessToken, serviceId, isAuthenticated, loading, database]);

  return (
    <SocketIoContext.Provider value={{ client }}>
      {children}
    </SocketIoContext.Provider>
  );
};
