import React, { ReactElement } from 'react';
import ImportFileInput from 'src/components/ImportView/ImportFileInput';
import { flexGrow } from 'src/components/ImportView/ImportTypeSelector/styles';
import PanelCard from 'src/components/ImportView/ImportTypeSelector/PanelCard';

function FileUploadCard({
  maxFileSizeInGB
}: {
  maxFileSizeInGB: number;
}): ReactElement {
  return (
    <ImportFileInput className="fileUploadCard" css={flexGrow}>
      <PanelCard
        title="Upload a file"
        subTitle={`JSON, CSV or TSV (up to ${maxFileSizeInGB}GB)`}
        iconName="upload"
      />
    </ImportFileInput>
  );
}

export default React.memo(FileUploadCard);
