import { css } from '@emotion/react';

const defaultClasses = {};

defaultClasses.root = css`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: visible;
  user-select: inherit;
`;

defaultClasses.headerContainer = css`
  user-select: inherit;
`;

defaultClasses.header = (theme) => css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: ${theme.colors.c4};
  margin-bottom: 10px;
  user-select: inherit;
`;

defaultClasses.subtitle = (theme) => css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${theme.colors.c3};
  margin-bottom: 10px;
  user-select: inherit;
`;

defaultClasses.footer = (theme) => css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: ${theme.colors.c4};
  padding-left: 15px;
  user-select: inherit;
`;

defaultClasses.chartStyle = (theme) =>
  css({
    '.recharts-default-tooltip, .d3-tooltip': {
      backgroundColor: `${theme.global.color.background.default} !important`,
      border: `1px solid ${theme.global.color.stroke.default} !important`,
      boxShadow: `0px 24px 34px ${theme.global.color.shadow.default} !important`
    },
    '.recharts-text.recharts-label > tspan, .d3-axis-label': {
      color: `${theme.global.color.text.muted} !important`,
      fill: `${theme.global.color.text.muted} !important`
    },
    '.recharts-cartesian-axis-line': {
      stroke: `${theme.global.color.stroke.default} !important`,
      userSelect: 'inherit'
    },
    '.recharts-cartesian-axis-tick > text, .d3-axis-tick': {
      color: `${theme.global.color.text.muted} !important`,
      fill: `${theme.global.color.text.muted} !important`
    },
    '.recharts-cartesian-grid-horizontal': {
      '&> line': {
        stroke: `${theme.global.color.stroke.default} !important`,
        userSelect: 'inherit'
      }
    },
    '.recharts-cartesian-grid-vertical': {
      '&> line': {
        stroke: `${theme.global.color.stroke.default} !important`,
        userSelect: 'inherit'
      }
    },
    '.recharts-tooltip-cursor': {
      fill: `${theme.global.color.background.muted} !important`,
      color: `${theme.global.color.background.muted} !important`,
      opacity: 0.25
    },
    '.recharts-tooltip-label, .d3-tooltip > p': {
      color: `${theme.global.color.text.default} !important`
    }
  });

export default defaultClasses;
