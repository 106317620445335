import React, { HTMLAttributes, ReactElement } from 'react';
import { TextField } from '@clickhouse/click-ui';

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  url: string;
  onChange: (url: string) => void | Promise<void>;
  error?: string;
};

function FileUrlInput({
  url,
  onChange,
  error,
  ...otherProps
}: Props): ReactElement | null {
  const onChangeHandler = (value: string): void => {
    void onChange(value);
  };

  return (
    <div {...otherProps}>
      <TextField
        label="File Path"
        value={url}
        onChange={onChangeHandler}
        placeholder="Enter URL"
        data-testid="tableOption-File Path"
        error={error}
        className="fs-exclude"
      />
    </div>
  );
}
const MemoizedFileUrlInput = React.memo(FileUrlInput);
MemoizedFileUrlInput.displayName = 'FileUrlInput';
export default MemoizedFileUrlInput;
