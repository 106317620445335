import { Draft } from 'immer';

import { useImportModel } from 'src/state/dataLoading';
import {
  ClickPipesStep,
  ClickPipeImport,
  ClickPipeSourceType,
  isClickPipesObjectStorageImport,
  isClickPipesKafkaImport,
  ClickPipeKafkaImport,
  ClickPipeKinesisImport,
  isClickPipesKinesisImport,
  ClickPipeObjectStorageImport,
  isClickPipesPostgresImport,
  ClickPipePostgresImport
} from 'shared/src/dataLoading';

export function useClickPipesImport<T extends ClickPipeImport>() {
  const hook = useImportModel();

  const setupStreamingModel = (draft: Draft<ClickPipeKafkaImport>): void => {
    // if the user switches kafka type, reset the username / password
    draft.data.credentials = {
      username: '',
      password: ''
    };

    // defaults
    draft.data.format = 'JSONEachRow';
    draft.data.group = `clickpipes-${draft.id}`;
    draft.data.flatten = false;
    draft.data.ingestRoleId = [];
    draft.data.schemaRegistryEnabled = false;
    draft.data.offsetStrategyEnabled = false;
    draft.data.offsetStrategy = 'from_beginning';
    draft.data.offsetOverride = 'from_selected';
    draft.data.offsetValue = '0';
  };

  const setupObjectStorageModel = (draft: Draft<ClickPipeObjectStorageImport>): void => {
    draft.data.format = 'JSONEachRow';
    draft.data.useContinuousIngestion = false;
    draft.data.auth = {
      type: 'iam_user' as const,
      access_key_id: '',
      secret_key: ''
    };
    draft.data.path = '';
    draft.data.ingestRoleId = [];
  };

  const setupKinesisModel = (draft: Draft<ClickPipeKinesisImport>): void => {
    draft.data.format = 'JSONEachRow';
    draft.data.ingestRoleId = [];
    draft.data.flatten = false;
    draft.data.enhancedFanout = true;
    draft.data.auth = {
      type: 'iam_user' as const,
      username: '',
      password: ''
    };
  };

  const setupPostgresModel = (draft: Draft<ClickPipePostgresImport>): void => {
    draft.data.auth = {
      type: 'basic_unencrypted',
      host: '',
      port: '5432',
      username: '',
      password: '',
      database: ''
    };
    draft.data.cdcSettings = {
      syncInterval: 60,
      initialLoadParallelism: 4,
      pullBatchSize: 1000000,
      snapshotNumRowsPerPartition: 1000000,
      snapshotNumberOfParallelTables: 1,
      replicationSlotName: '',
      publicationName: '',
      replicationMode: 'cdc',
      targetClickhouseDatabase: '',
      tableSelections: []
    };
    draft.data.alert = {
      slotLagGrowth: 10,
      openConnections: 8
    };
  };

  const setupClickPipeModel = (type: ClickPipeSourceType): void => {
    hook.updateImportModel((draft: Draft<ClickPipeImport>) => {
      draft.type = type;
      draft.data.type = type;
      draft.data.step = 1;

      if (isClickPipesObjectStorageImport(draft)) {
        setupObjectStorageModel(draft);
      } else if (isClickPipesKafkaImport(draft)) {
        setupStreamingModel(draft);
      } else if (isClickPipesKinesisImport(draft)) {
        setupKinesisModel(draft);
      } else if (isClickPipesPostgresImport(draft)) {
        setupPostgresModel(draft);
      }
    });
  };

  const previousStep = (): void => {
    hook.updateImportModel((draft: Draft<ClickPipeImport>) => {
      const currentStep = draft.data.step || 1;
      if (currentStep > 1) {
        draft.data.step = (currentStep - 1) as ClickPipesStep;
      }
    });
  };

  return {
    ...hook,
    importModel: hook.importModel as T,
    updateImportModel: hook.updateImportModel as <T extends ClickPipeImport>(
      updater: (draft: Draft<T>) => void
    ) => void,
    previousStep,
    setupClickPipeModel: setupClickPipeModel
  };
}
