import { QuestionAnswerType } from '@cp/common/protocol/Account';
import { createToast } from 'primitives';
import { useCallback } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';

export interface ExistQuestionnaireController {
  addUserExitQuestionnaire: (questions: Array<QuestionAnswerType>, organizationId: string) => Promise<void>;
}

export const useExitQuestionnaire = (): ExistQuestionnaireController => {
  const apiClient = useApiClient();

  const addUserExitQuestionnaire = useCallback(
    async (questions: Array<QuestionAnswerType>, organizationId: string) => {
      try {
        await apiClient.account.addUserExitQuestionnaire({
          questions,
          organizationId
        });
      } catch (e) {
        console.error(e);
        createToast('Error', 'error', 'Failed to submit your questionnaire');
      }
    },
    [apiClient]
  );

  return { addUserExitQuestionnaire };
};
