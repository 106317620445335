import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  Text,
  TextAreaField
} from '@clickhouse/click-ui';
import { useRecaptcha } from 'src/components/RecaptchaProvider';
import { useMetricsFeedback } from 'src/metrics/metricsController';
import { createToast } from 'primitives';
import { Galaxy } from 'galaxy';

type Props = {
  open: boolean;
  onClose: () => void;
};

const FeedbackDialog = React.memo(({ open, onClose }: Props): ReactElement => {
  const getRecaptchaToken = useRecaptcha();
  const [description, setDescription] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { result, loading, sendMetricsFeedback } = useMetricsFeedback();

  const validate = (): boolean => {
    if (!description || description.length === 0) {
      setError('Please fill in the feedback request');
      return false;
    } else {
      setError(null);
    }
    return true;
  };

  const submit = async (): Promise<void> => {
    if (!validate()) {
      return;
    }

    const token = await getRecaptchaToken('metrics_feedback');
    void sendMetricsFeedback(description, token ?? '');
    Galaxy.galaxy().track('serviceHealth.requestMetric.submitted', {
      interaction: 'click',
      description
    });
  };

  const onChange = (value: string): void => {
    setError(null);
    setDescription(value);
  };

  useEffect(() => {
    if (result) {
      const alertType = result.successful ? 'success' : 'error';
      const title = result.successful ? 'Success' : 'Error';
      createToast(title, alertType, result.message);

      if (result.successful) {
        onClose();
      }
    }
  }, [result]);

  return (
    <Dialog modal open={open}>
      <Dialog.Content
        title="Request new metrics"
        data-testid="metrics-feedback-dialog"
      >
        <Text size="md">
          Please indicate the metric that you’d like to see included and a brief
          summary of why you think it would be helpful.
        </Text>
        <Spacer />
        <TextAreaField
          error={error ?? undefined}
          value={description}
          onChange={onChange}
          rows={10}
          placeholder="Metric name and description"
          data-testid="metric-description"
        />
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Close" onClick={onClose} />
          <Button
            loading={loading}
            disabled={loading || !!error}
            data-testid="submit-metric-request-button"
            onClick={(): void => {
              submit().catch(console.error);
            }}
          >
            Request metric
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
});

export default FeedbackDialog;
