import { ReactElement } from 'react';
import { InviteMembersModal } from 'src/layout/InviteMembersModal/InviteMembersModal';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

export interface InviteMembersModalLayoutProps {
  open: boolean;
  onClose: () => void;
}

export const InviteMembersModalLayout = ({
  open,
  onClose
}: InviteMembersModalLayoutProps): ReactElement | null => {
  const currentOrganization = useCurrentOrganizationOrThrow();
  const orgInvites = currentOrganization.invitations;
  const { canInviteMembers } = currentOrganization.restrictions;
  const pendingInvitationEmails = Object.keys(orgInvites);
  const organizationEmails: string[] = Object.values(
    currentOrganization.users
  ).map((user) => user.email);

  return (
    <InviteMembersModal
      open={open}
      onClose={onClose}
      pendingInvitationEmails={pendingInvitationEmails}
      organizationEmails={organizationEmails}
      canInviteMembers={canInviteMembers}
    />
  );
};
