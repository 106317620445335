import { apiUrl } from 'src/lib/controlPlane/apiUrls';
import { HttpClient } from 'src/lib/http';
import {
  AssignSavedQueryInput,
  CreateSavedQueryInput,
  UpdateSavedQueryInput,
  DeleteSavedQueryInput,
  SavedQueryDTO
} from 'shared/src/types/savedQuery';

/**
 * Updates an existing saved query for the given service ID.
 * @param serviceId The ID of the service to update the saved query for.
 * @param input The input object containing the updated saved query data.
 * @returns nothing.
 * @throws An error if the request fails or the response is not successful.
 */
export const assign = async (
  http: HttpClient,
  serviceId: string,
  input: AssignSavedQueryInput
): Promise<SavedQueryDTO> => {
  const response = await http.patch(apiUrl(`/savedQuery/author?serviceId=${encodeURIComponent(serviceId)}`), {
    body: JSON.stringify(input)
  });

  if (!response.ok) {
    const body = await response.json();
    const errorMessage = body ? body.error : `error ${response.status}`;
    throw new Error(`Cannot update saved query: ${errorMessage}`);
  }

  const body = await response.json();
  return body.data as SavedQueryDTO;
};

/**
 * Retrieves a list of saved queries for the given service ID.
 * @param serviceId The ID of the service to retrieve saved queries for.
 * @returns An array of saved queries.
 * @throws An error if the request fails or the response is not successful.
 */
export const list = async (http: HttpClient, serviceId: string): Promise<SavedQueryDTO[]> => {
  const response = await http.get(apiUrl(`/savedQuery?serviceId=${encodeURIComponent(serviceId)}`), {});
  const body = await response.json();

  if (!response.ok) {
    const errorMessage = body ? body.error : `error ${response.status}`;
    throw new Error(`Cannot get query folder tree: ${errorMessage}`);
  }
  const { data } = body;
  const { queries } = data;
  return queries;
};

/**
 * Retrieves a saved query by ID for the given service ID.
 * @param serviceId The ID of the service to retrieve the saved query for.
 * @param id The ID of the saved query to retrieve.
 * @returns The saved query object.
 * @throws An error if the request fails or the response is not successful.
 */
export const get = async (http: HttpClient, serviceId: string, id: string): Promise<SavedQueryDTO> => {
  const response = await http.get(
    apiUrl(`/savedQuery?id=${encodeURIComponent(id)}&serviceId=${encodeURIComponent(serviceId)}`),
    {}
  );
  const body = await response.json();

  if (!response.ok) {
    const errorMessage = body ? body.error : `error ${response.status}`;
    throw new Error(`Cannot get saved query: ${errorMessage}`);
  }

  const { data } = body;
  return data;
};

/**
 * Creates a new saved query for the given service ID.
 * @param serviceId The ID of the service to create the saved query for.
 * @param input The input object containing the saved query data.
 * @returns The created saved query object.
 * @throws An error if the request fails or the response is not successful.
 */
export const create = async (
  http: HttpClient,
  serviceId: string,
  input: CreateSavedQueryInput
): Promise<SavedQueryDTO> => {
  const response = await http.post(apiUrl(`/savedQuery?serviceId=${encodeURIComponent(serviceId)}`), {
    body: JSON.stringify(input)
  });
  const body = await response.json();

  if (!response.ok) {
    const errorMessage = body ? body.error : `error ${response.status}`;
    throw new Error(`Cannot create saved query: ${errorMessage}`);
  }

  const { data } = body;
  return data;
};

/**
 * Updates an existing saved query for the given service ID.
 * @param serviceId The ID of the service to update the saved query for.
 * @param input The input object containing the updated saved query data.
 * @returns nothing.
 * @throws An error if the request fails or the response is not successful.
 */
export const update = async (http: HttpClient, serviceId: string, input: UpdateSavedQueryInput): Promise<void> => {
  const response = await http.patch(apiUrl(`/savedQuery?serviceId=${encodeURIComponent(serviceId)}`), {
    body: JSON.stringify(input)
  });

  if (!response.ok) {
    const body = await response.json();
    const errorMessage = body ? body.error : `error ${response.status}`;
    throw new Error(`Cannot update saved query: ${errorMessage}`);
  }
};

/**
 * Deletes an existing saved query for the given service ID.
 * @param serviceId The ID of the service to delete the saved query for.
 * @param input The input object containing the ID of the saved query to delete.
 * @returns nothing.
 * @throws An error if the request fails or the response is not successful.
 */
export const remove = async (http: HttpClient, serviceId: string, input: DeleteSavedQueryInput): Promise<void> => {
  const response = await http.destroy(apiUrl(`/savedQuery?serviceId=${encodeURIComponent(serviceId)}`), {
    body: JSON.stringify(input)
  });

  if (!response.ok) {
    const body = await response.json();
    const errorMessage = body ? body.error : `error ${response.status}`;
    throw new Error(`Cannot delete saved query: ${errorMessage}`);
  }
};
