import { useCurrentInstanceId } from 'src/instance/instanceController';
import {
  getCachedDatabaseValue,
  getSelectedDatabaseCacheKey,
  useSelectedDatabase
} from 'src/metadata/selectedDatabase';
import { useConnectionCredentials, useCredentials } from 'src/state/connection';
import { useClearLiveQueries } from 'src/state/liveQueries';

interface SelectServiceArgs {
  serviceId: string;
}

// would prefer to put this in a `useServiceState` hook that lets us control more service related state
// but this is fine for now
export function useSelectService(): (args: SelectServiceArgs) => void {
  const { updateCredentials } = useConnectionCredentials();
  const { setSelectedDatabase } = useSelectedDatabase();
  const clearLiveQueries = useClearLiveQueries();
  const currentServiceId = useCurrentInstanceId();

  return ({ serviceId }: SelectServiceArgs) => {
    clearLiveQueries();

    if (currentServiceId !== serviceId) {
      updateCredentials({
        connected: false,
        database: 'default', // derived atom will ignore this
        serviceId,
        type: 'managed'
      });
    }
    const database = getCachedDatabaseValue(getSelectedDatabaseCacheKey(serviceId));
    setSelectedDatabase(serviceId, database);
  };
}
