import {
  Button,
  Container,
  Link,
  Spacer,
  Label,
  Title,
  TextField,
  VerticalStepper,
  Text
} from '@clickhouse/click-ui';
import {
  REGION_BY_ID,
  RegionId,
  getAvailableRegionsByStage,
  isAwsRegionId
} from '@cp/common/protocol/Region';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { RegionSelector } from 'src/components/RegionSelector';
import ClickHouseFullIcon from 'src/layout/ServiceSidebar/ClickHouseFullIcon';
import config from 'src/lib/config';
import { useOnboardByoc } from 'src/organization/organizationController';
import { useOrganizationStateManager } from 'src/organization/organizationState';
import { createToast } from '@clickhouse/click-ui';
import useCreateDefaultOrganization from 'src/organization/controller/useCreateDefaultOrganization';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useOnboardStateManager } from 'src/onboard/onboardState';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';

export function ByocOnboarding(): ReactNode {
  const [stackCreated, setStackCreated] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [selectedRegion, setSelectedRegion] = useState<RegionId>();
  const onboardByoc = useOnboardByoc();
  const { organizations, currentOrganizationId, setCurrentOrganizationId } =
    useOrganizationStateManager();
  const [inProgress, setInProgress] = useState(false);
  const { createDefaultOrgForUser } = useCreateDefaultOrganization();
  const createdDefaultOrgRef = useRef(false);
  const apiClient = useApiClient();
  const { skipOnboarding } = useOnboardStateManager();

  const byocOrg = Object.values(organizations).find(
    (org) => isOrgByoc(org) && Object.keys(org.byocConfig).length === 0
  );

  const onStackCreated = useCallback(() => {
    setStackCreated(true);
  }, []);

  const onChangeAccountId = useCallback((newValue: string) => {
    setAccountId(newValue);
  }, []);

  const onRegionChange = useCallback((newRegion: RegionId) => {
    setSelectedRegion(newRegion);
  }, []);

  const onSetupInfrastructure = useCallback(() => {
    if (!byocOrg?.id) {
      createToast({
        title: 'Error',
        type: 'danger',
        description: 'No BYOC org found'
      });
      return;
    }
    if (selectedRegion) {
      setInProgress(true);
      onboardByoc(byocOrg.id, accountId, selectedRegion)
        .then(async () => {
          await apiClient.updateUserDetails({
            pendingActionTypesToRemove: ['onboarding']
          });
          skipOnboarding();
          setCurrentOrganizationId(byocOrg.id);
          navigateTo(routes.serviceList());
        })
        .catch(console.error);
    }
  }, [
    onboardByoc,
    accountId,
    selectedRegion,
    byocOrg?.id,
    setCurrentOrganizationId,
    skipOnboarding,
    apiClient
  ]);

  const awsRegionIds = getAvailableRegionsByStage(config.env).filter(
    isAwsRegionId
  );
  const awsRegions = awsRegionIds.map((regionId) => REGION_BY_ID[regionId]);

  useEffect(() => {
    if (!byocOrg && !createdDefaultOrgRef.current) {
      createdDefaultOrgRef.current = true;
      createDefaultOrgForUser().catch(console.error);
    }
  }, [byocOrg, createDefaultOrgForUser, currentOrganizationId]);

  return (
    <Container alignItems="center" orientation="vertical" padding="xl">
      <ClickHouseFullIcon width="180px" height="35px" />
      <Spacer size="xl" />
      <Container orientation="vertical" gap="sm" maxWidth="600px">
        <Title type="h2" size="xl">
          Create your service
        </Title>
        <Text>
          Lets get started creating a ClickHouse service. Once you’ve completed
          the steps below, we’ll send you over to your AWS Console to configure
          the account there.
        </Text>
        <Text>
          We’ll then pre-populate a CloudFormation template that creates an IAM
          role and S3 buck for you, and deploy your service.
        </Text>
        <VerticalStepper>
          <VerticalStepper.Step
            label="Log into your desired AWS account and create a stack"
            status={stackCreated ? 'complete' : 'active'}
          >
            <Text color="muted">
              To prepare for migration, confirm the infra-structure setup
              creation{' '}
              <Link
                target="_blank"
                icon="popout"
                href="https://us-west-2.console.aws.amazon.com/cloudformation/home?region=us-west-2#/stacks/quickcreate?templateURL=https://s3.us-east-2.amazonaws.com/clickhouse-public-resources.clickhouse.cloud/cf-templates/byoc.yaml&&stackName=ClickHouseByoc"
              >
                over this link
              </Link>
            </Text>
            <Container gap="md">
              <Text color="muted">
                When you've finished creating the stack, click the following
                button:
              </Text>
            </Container>
            <Spacer size="md" />
            <Container gap="md" justifyContent="center">
              <Button onClick={onStackCreated}>
                I have set up the CloudFormation stack
              </Button>
            </Container>
          </VerticalStepper.Step>
          <VerticalStepper.Step
            label="Share your information and set up AWS infrastructure"
            status={stackCreated ? 'active' : 'incomplete'}
          >
            <Container orientation="vertical" gap="md">
              <Text color="muted">To proceed, you'll need the following</Text>
              <Label htmlFor="accountId">AWS account ID</Label>
              <TextField
                id="accountId"
                disabled={inProgress}
                value={accountId}
                onChange={onChangeAccountId}
              />
              <RegionSelector
                disabled={inProgress}
                regions={awsRegions}
                selectedRegionId={selectedRegion}
                onRegionChange={onRegionChange}
              />
              <Button
                onClick={onSetupInfrastructure}
                disabled={inProgress || !selectedRegion || !accountId}
              >
                Setup Infrastructure
              </Button>
              <Text color="muted">
                New regions setup can take up to 40 minutes.
              </Text>
            </Container>
          </VerticalStepper.Step>
        </VerticalStepper>
      </Container>
    </Container>
  );
}
