/**
 * Information passed by Tackle.
 * See https://docs.tackle.io/articles/#!tackle-docs/add-a-self-hosted-registration-page/a/h3__1227129135.
 */
import { isTackleMarketplace, TackleMarketplace, TackleSubscriptionToken } from '@cp/common/protocol/Tackle';
import { isNotEmpty } from '@cp/common/utils/ValidationUtils';

/**
 * Checks if the provided value is a valid Tackle subscription token.
 * @param {any} value - The value to check.
 * @returns {boolean} True if the value is a valid Tackle subscription token, false otherwise.
 */
export function isTackleSubscriptionToken(token: unknown): token is TackleSubscriptionToken {
  if (!token || typeof token !== 'object') {
    return false;
  }
  const { marketplace, customerId, productId, awsAccountId } = token as TackleSubscriptionToken;
  let isValid = isTackleMarketplace(marketplace) && isNotEmpty(customerId) && isNotEmpty(productId);
  if (!isValid) {
    return false;
  }
  if (marketplace === 'aws') {
    const expectedFieldCount = typeof awsAccountId === 'string' ? 4 : 3;
    isValid = expectedFieldCount === Object.keys(token).length;
  } // GCP or Azure have no extra parameters to store.
  return isValid;
}

/**
 *  Parses tackle subscription token from the current URL.
 *  See https://developers.tackle.io/docs/setup-your-self-hosted-registration-page.
 *  @returns The token if it is present in the URL, otherwise undefined.
 */
export function parseTackleSubscriptionTokenFromUrl(): TackleSubscriptionToken | undefined {
  const url = new URL(window.location.href);
  const marketplace: TackleMarketplace | undefined = url.searchParams.get('aws_customer_id')
    ? 'aws'
    : url.searchParams.get('gcp_customer_id')
    ? 'gcp'
    : url.searchParams.get('azure_customer_id')
    ? 'azure'
    : undefined;
  if (!isTackleMarketplace(marketplace)) {
    return undefined;
  }
  const customerIdParamName = `${marketplace}_customer_id`;
  const productIdParamName = `${marketplace}_product_id`;
  const token: TackleSubscriptionToken = {
    marketplace,
    customerId: url.searchParams.get(customerIdParamName) || '',
    productId: url.searchParams.get(productIdParamName) || ''
  };
  const awsAccountId = marketplace === 'aws' ? url.searchParams.get('CustomerAWSAccountID') : undefined;
  if (awsAccountId) {
    token.awsAccountId = awsAccountId;
  }
  if (!isTackleSubscriptionToken(token)) {
    // The received token is invalid - do nothing.
    return undefined;
  }
  return token;
}
