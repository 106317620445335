import { Container, HoverCard, Icon, Panel, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import styled from 'styled-components';

export const SummaryPanel = styled(Panel)`
  position: relative;
  overflow: hidden;
`;

const SummaryCard = styled(HoverCard.Trigger)`
  position: absolute;
  height: 4rem;
  width: 4rem;
  background: ${({ theme }) => theme.click.global.color.background.default};
  transform: rotate(45deg);
  bottom: -2rem;
  left: -2rem;
`;

const TooltipIcon = styled(Icon)`
  transform: rotate(-45deg);
  left: 0.85rem;
  bottom: 0.85rem;
  position: relative;
`;
interface SummaryTooltipProps {
  help: string;
}
export const SummaryTooltipContainer = ({
  help
}: SummaryTooltipProps): ReactElement => {
  return (
    <HoverCard openDelay={0} closeDelay={25}>
      <SummaryCard>
        <Text color="muted" component="div">
          <TooltipIcon name="information" size="lg" />
        </Text>
      </SummaryCard>
      <HoverCard.Content side="right" align="start" sideOffset={-5}>
        <Container
          orientation="vertical"
          gap="xs"
          padding="none"
          alignItems="start"
          fillWidth={false}
        >
          {help.split('\n').map((line) => (
            <Text size="sm" key={line}>
              {line}
            </Text>
          ))}
        </Container>
      </HoverCard.Content>
    </HoverCard>
  );
};
