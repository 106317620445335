import { atom, useAtom } from 'jotai';
import { useGptConsent } from 'src/lib/gpt/GptConsentHook';
import { useGptConsentModal } from 'src/lib/gpt/GptConsentModalHook';

const isGptConstructionPopupVisibleAtom = atom(false);

// Return type of the useGptPopups hook.
type UseGptPopupsResult = {
  isGptConstructionPopupVisible: boolean;
  showGptConstructionPopup: () => void;
  hideGptConstructionPopup: () => void;
};

/**
 * This hook handles visibility state of the GPT construction popup and the GPT consent modal.
 * @returns GptConstructionPopupResult
 */
export function useGptPopups(): UseGptPopupsResult {
  const [isGptConstructionPopupVisible, setGptConstructionPopupVisible] =
    useAtom(isGptConstructionPopupVisibleAtom);
  const { hasConsent } = useGptConsent();
  const { showGptConsentModal } = useGptConsentModal();

  /**
   * Show GPT construction popup.
   * If user didn't give GPT consent, show the GPT consent modal.
   */
  const showGptConstructionPopup = (): void => {
    if (hasConsent) {
      setGptConstructionPopupVisible(true);
    } else {
      void showGptConsentModal().then((accepted) => {
        if (accepted) {
          setGptConstructionPopupVisible(true);
        }
      });
    }
  };

  /**
   * Hide the GPT construction popup.
   */
  const hideGptConstructionPopup = (): void => {
    setGptConstructionPopupVisible(false);
  };

  return {
    isGptConstructionPopupVisible,
    showGptConstructionPopup,
    hideGptConstructionPopup
  };
}
