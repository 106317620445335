import React, { HTMLAttributes, ReactElement } from 'react';

import { css } from '@emotion/react';
import { getFileFormatOptions } from 'src/lib/dataLoading/fileTypeOptions';
import {
  CsvFormatType,
  csvFormatTypes,
  FileFormatType
} from 'shared/src/dataLoading';
import {
  Container,
  Select,
  SelectOptionListItem,
  TextField
} from '@clickhouse/click-ui';
import { useDebounceFunction } from 'src/lib/hooks';

type Props = {
  formatType: FileFormatType | null | undefined;
  updateDataPreview: (
    fileFormatType: FileFormatType | null,
    separator: string | null | undefined
  ) => void;
  setSeparator: (separator: string) => void;
  separator: string | undefined | null;
  setFormatType: (type: FileFormatType) => void;
} & HTMLAttributes<HTMLDivElement>;

const formatTypeStyle = css({ minWidth: '220px', width: '220px' });

function FileFormatInput({
  formatType,
  setFormatType,
  updateDataPreview,
  separator,
  setSeparator
}: Props): ReactElement | null {
  // we always return all options, since the user might want to change the format type (specially when the file format is totally unknown)
  const options = getFileFormatOptions(null) as Array<SelectOptionListItem>;

  const debouncedUpdateDataPreview = useDebounceFunction(
    updateDataPreview,
    250
  );
  const onFormatTypeChange = (value: FileFormatType): void => {
    setFormatType(value);
    void debouncedUpdateDataPreview(value, separator);
  };

  const onChangeSeparator = (value: string): void => {
    if (!formatType) {
      throw new Error('Unexpected state');
    }
    setSeparator(value);
    void debouncedUpdateDataPreview(formatType, value);
  };

  const showSeparatorInput = formatType
    ? csvFormatTypes.includes(formatType as CsvFormatType)
    : false;

  return (
    <Container gap="md" alignItems="start" fillWidth={false}>
      <Select
        label="File type"
        options={options}
        showSearch
        value={formatType as string}
        onSelect={(value: string): void =>
          onFormatTypeChange(value as FileFormatType)
        }
        css={formatTypeStyle}
      />

      {showSeparatorInput && (
        <Container maxWidth="50px" minWidth="50px" fillWidth={false}>
          <TextField
            value={separator ?? ''}
            onChange={onChangeSeparator}
            label="Separator"
          />
        </Container>
      )}
    </Container>
  );
}
const MemoizedFileFormatInput = React.memo(FileFormatInput);
MemoizedFileFormatInput.displayName = 'FileFormatInput';
export default MemoizedFileFormatInput;
