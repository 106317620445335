import {
  Container,
  Table,
  TableHeaderType,
  TableRowType,
  Text
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import DownloadCSVLink from 'src/components/BillingInfo/DownloadCSVLink';
import { parseInvoiceDateRange } from 'src/components/BillingInfo/invoiceDateRangeFormatter';
import { formatDataSize } from 'src/lib/formatters/numberFormatter';

export type UsageStatement = {
  /** Unix timestamp in milliseconds. */
  periodStartDate?: number;
  /** Unix timestamp in milliseconds. */
  periodEndDate?: number;
  /** M3ter bill id, used to link invoices with bill periods and allow downloading CSV statement. */
  billId: string;
  /** Amount of CHC spent in this bill (before credits applied). */
  billGrossTotal: number;
  /** Amount of CHC spent in this bill (after credits applied). */
  billNetTotal: number;
  /** Amount of CHC credit applied to this bill. */
  creditApplied: number;
  /** This bill is locked and can no longer change. */
  locked: boolean;
  /** Organization ID from bill belongs. */
  organizationId: string;
};

export type UsageStatementsTableProps = { usageStatements: UsageStatement[] };

const UsageStatementsTable = ({
  usageStatements
}: UsageStatementsTableProps): ReactElement => {
  const headers: Array<TableHeaderType> = [
    { label: 'Time period' },
    {
      label: (
        <Container fillWidth orientation="horizontal" justifyContent="end">
          Gross usage
        </Container>
      ),
      width: '25%'
    },
    {
      label: (
        <Container fillWidth orientation="horizontal" justifyContent="end">
          Net usage
        </Container>
      ),
      width: '25%'
    },
    {
      label: (
        <Container fillWidth orientation="horizontal" justifyContent="end">
          Credits applied
        </Container>
      ),
      width: '15%'
    },
    { label: 'Statement', width: '10%' }
  ];

  const rows: Array<TableRowType> = usageStatements.map((us) => {
    return {
      id: us.billId,
      items: [
        {
          label: (
            <Container orientation={'horizontal'} fillWidth={false} gap="xs">
              {parseInvoiceDateRange(us.periodStartDate, us.periodEndDate)}
            </Container>
          )
        },
        {
          label: (
            <Text align="right">
              {formatDataSize(us.billGrossTotal, 'human-readable-number')}
            </Text>
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <Text align="right">
              {formatDataSize(us.billNetTotal, 'human-readable-number')}
            </Text>
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <Text align="right">
              {formatDataSize(us.creditApplied, 'human-readable-number')}
            </Text>
          ),
          className: 'fs-exclude'
        },
        {
          label: (
            <DownloadCSVLink
              organizationId={us.organizationId}
              billId={us.billId}
            />
          )
        }
      ]
    };
  });

  return <Table headers={headers} rows={rows} />;
};

export default UsageStatementsTable;
