import {
  Container,
  GridContainer,
  Panel,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { Instance } from '@cp/common/protocol/Instance';
import { ReactElement, useRef, useState } from 'react';
import { InstanceState } from 'src/components/ServiceMetrics/InstanceState';
import { InstanceSummary } from 'src/components/ServiceMetrics/InstanceSummary';
import { useMetrics } from 'src/metrics/metricsController';
import { TimePeriod } from '@cp/common/protocol/Metrics';
import MonitoringChart from 'src/components/ServiceMetrics/MonitoringChart';
import {
  MILLIS_PER_DAY,
  MILLIS_PER_HOUR,
  MILLIS_PER_MINUTE
} from '@cp/common/utils/DateTimeUtils';
import { assertTruthy } from '@cp/common/utils/Assert';
import FeedbackPanel from 'src/components/ServiceMetrics/FeedbackPanel';
import { useSearchParams } from 'react-router-dom';
import { TimeSelect } from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { Galaxy } from 'galaxy';

type Props = { instance: Instance };

/** Converts time period to URL query parameter value. */
function convertTimePeriodToUrlParameter(
  timePeriod: TimePeriod | undefined
): string | undefined {
  if (!timePeriod) {
    return undefined;
  }
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      return '15min';
    case 'LAST_HOUR':
      return '1h';
    case 'LAST_DAY':
      return '24h';
    case 'LAST_WEEK':
      return '1w';
    case 'LAST_MONTH':
      return '1m';
    case 'LAST_YEAR':
      return '1y';
  }
}

/** Converts TimePeriod query URL parameter value to the API enum value. */
export function convertUrlParameterToTimePeriod(
  parameterValue: string | undefined | null
): TimePeriod | undefined {
  if (parameterValue === undefined || parameterValue === null) {
    return undefined;
  }
  switch (parameterValue) {
    case '15min':
      return 'LAST_15_MINUTES';
    case '1h':
      return 'LAST_HOUR';
    case '24h':
      return 'LAST_DAY';
    case '1w':
      return 'LAST_WEEK';
    case '1m':
      return 'LAST_MONTH';
    case '1y':
      return 'LAST_YEAR';
  }
  return undefined;
}

/**
 * Returns aggregation period for the given time period.
 * For all sampling points within an aggregation period there is only 1 point in the result chart series.
 */
function getAggregationPeriodMillis(timePeriod: TimePeriod): number {
  // Current values for each time period are copied from the default Grafana values for Prometheus dashboard.
  let stepInMillis: number | undefined;
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      stepInMillis = MILLIS_PER_MINUTE;
      break;
    case 'LAST_HOUR':
      // Grafana value: stepInMillis = 20 * MILLIS_PER_SECOND;
      stepInMillis = MILLIS_PER_MINUTE;
      break;
    case 'LAST_DAY':
      stepInMillis = 5 * MILLIS_PER_MINUTE;
      break;
    case 'LAST_WEEK':
      stepInMillis = MILLIS_PER_HOUR;
      break;
    case 'LAST_MONTH':
      stepInMillis = 3 * MILLIS_PER_HOUR;
      break;
    case 'LAST_YEAR':
      stepInMillis = MILLIS_PER_DAY;
      break;
  }
  if (!stepInMillis) {
    throw new Error(`Unsupported time period: ${timePeriod}`);
  }
  return stepInMillis;
}

/** Returns visual representation of the aggregation period for the given time period. */
function getAggregationPeriodTextByTimePeriod(timePeriod: TimePeriod): string {
  // Validate every value before we return result.
  // So if 'getAggregationPeriodMillis' is changed tests with method will fail.
  const aggregationPeriodMillis = getAggregationPeriodMillis(timePeriod);
  switch (timePeriod) {
    case 'LAST_15_MINUTES':
      assertTruthy(
        aggregationPeriodMillis === MILLIS_PER_MINUTE,
        'Invalid aggregationPeriodMillis'
      );
      return '1 minute';
    case 'LAST_HOUR':
      assertTruthy(
        aggregationPeriodMillis === MILLIS_PER_MINUTE,
        'Invalid aggregationPeriodMillis'
      );
      return '1 minute';
    case 'LAST_DAY':
      assertTruthy(
        aggregationPeriodMillis === 5 * MILLIS_PER_MINUTE,
        'Invalid aggregationPeriodMillis'
      );
      return '5 minutes';
    case 'LAST_WEEK':
      assertTruthy(
        aggregationPeriodMillis === MILLIS_PER_HOUR,
        'Invalid aggregationPeriodMillis'
      );
      return '1 hour';
    case 'LAST_MONTH':
      assertTruthy(
        aggregationPeriodMillis === 3 * MILLIS_PER_HOUR,
        'Invalid aggregationPeriodMillis'
      );
      return '3 hours';
    case 'LAST_YEAR':
      assertTruthy(
        aggregationPeriodMillis === MILLIS_PER_DAY,
        'Invalid aggregationPeriodMillis'
      );
      return '1 day';
  }
}

export function ServiceMetrics({ instance }: Props): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPeriod: TimePeriod =
    convertUrlParameterToTimePeriod(searchParams.get('period')) || 'LAST_HOUR';

  const [period, setPeriod] = useState<TimePeriod>(initialPeriod);
  const stableLoadingState = useRef<boolean | null>(null);

  const handlePeriodChange = (p: TimePeriod): void => {
    setPeriod(p);
    setSearchParams({ period: convertTimePeriodToUrlParameter(p) || '' });
    Galaxy.galaxy().track('serviceHealth.timePeriod.changed', {
      interaction: 'click',
      timePeriod: p
    });
  };
  const { metrics, errors, metricChartInputs, loading, reload } = useMetrics(
    instance.id,
    instance.organizationId,
    period
  );
  const endpoint = instance.endpoints['https'];

  if (loading !== stableLoadingState.current) {
    if (loading === true) {
      // Galaxy may not be finished loading by the time this code path gets hit, we have to account for the error it throws if it's not initialized
      let galaxy;
      try {
        galaxy = Galaxy.galaxy();
        galaxy.track('serviceHealth.loading.started');
      } catch {
        galaxy = undefined;
      }
    }

    if (loading === false) {
      Galaxy.galaxy().track('serviceHealth.loading.ended');
    }
    stableLoadingState.current = loading;
  }

  const charts = metricChartInputs.map((input, index) => {
    const error = errors[input.query.type];
    const report = metrics[input.query.type];
    const reloadMetric = () => reload(input.query.type);
    return (
      <MonitoringChart
        key={`chart-${index}`}
        metric={input}
        report={report ? report[0] : undefined}
        loading={loading}
        error={error}
        reload={reloadMetric}
      />
    );
  });

  return (
    <Container
      orientation="vertical"
      gap="xs"
      padding="lg"
      data-testid="service-metrics"
    >
      <Panel
        alignItems="start"
        color="default"
        hasBorder
        height=""
        orientation="horizontal"
        padding="md"
        width="100%"
      >
        <InstanceState instanceState={instance.state} />
        <Separator size="sm" />
        <InstanceSummary instance={instance} />
      </Panel>

      <Spacer size="sm" />

      <Container gap="lg" justifyContent="end">
        <Container gap="xs" justifyContent="end" fillWidth={false}>
          <Text color="default" weight="medium">
            Aggregation period
          </Text>
          <Text color="muted">
            {getAggregationPeriodTextByTimePeriod(period)}
          </Text>
        </Container>
        <TimeSelect onSelect={handlePeriodChange} selectedPeriod={period} />
      </Container>

      <Spacer size="sm" />

      <GridContainer
        gap="lg"
        gridTemplateColumns="repeat(auto-fill, minmax(675px, 1fr))"
        maxWidth="2440px"
        minWidth="400px"
      >
        {charts}
        <FeedbackPanel host={endpoint.hostname} port={endpoint.port} />
      </GridContainer>
    </Container>
  );
}
