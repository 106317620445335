import { ConfirmationDialog, TextField } from '@clickhouse/click-ui';
import { KeyboardEvent, ReactElement, useState } from 'react';

interface RenameWarehouseDialogProps {
  open: boolean;
  warehouseName?: string;
  onCancel: () => void;
  onConfirm: (name: string) => Promise<void>;
}

export const RenameDataWarehouseDialog = ({
  warehouseName: warehouseNameProp = '',
  open,
  onCancel,
  onConfirm: onConfirmProp
}: RenameWarehouseDialogProps): ReactElement => {
  const [warehouseName, setWarehouseName] = useState(warehouseNameProp);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const onChange = (name: string): void => {
    setWarehouseName(name);
    setDisabled(name === warehouseNameProp || name.length === 0);
  };

  const onConfirm = async (): Promise<void> => {
    setLoading(true);
    try {
      await onConfirmProp(warehouseName);
      onCancel();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="Rename warehouse as..."
      primaryActionLabel="Rename"
      secondaryActionLabel="Cancel"
      loading={loading}
      data-testid="saveQueryModal"
      disabled={disabled}
    >
      <TextField
        disabled={loading}
        label="Query Name:"
        autoComplete="off"
        value={warehouseName}
        onChange={onChange}
        type="text"
        id="rename-warehouse-input"
        name="rename-warehouse-input"
        data-testid="rename-warehouse-input"
        placeholder="Enter the warehouse name here"
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void => {
          const value = e.currentTarget.value;
          if (
            e.key === 'Enter' &&
            value.length > 0 &&
            warehouseNameProp !== value
          ) {
            void onConfirm();
          }
        }}
        className="fs-exclude"
      />
    </ConfirmationDialog>
  );
};
