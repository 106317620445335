import { Alert } from '@clickhouse/click-ui';

interface Props {
  title: string;
  error: string;
}

export function ErrorBox(props: Props): JSX.Element | null {
  return (
    <Alert
      showIcon
      size="medium"
      state="danger"
      text={props.error}
      title={props.title}
    />
  );
}
