import {
  IconButton,
  SearchField,
  SidebarNavigationItem,
  Text
} from '@clickhouse/click-ui';
import { css } from '@emotion/react';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { marginStyle } from 'src/components/global-styles';
import { sidebarLogEvent } from 'src/components/QueryView/analytics';
import {
  currentServiceHistoryAtom,
  HistoryItem,
  useHistory
} from 'src/state/history';
import { useTabActions } from 'src/state/tabs';

const logName = 'historyQuery';

const historyListing = css({
  paddingLeft: '1rem',
  overflow: 'scroll',
  scrollbarGutter: 'stable both-sides'
});
export function QueryHistory(): JSX.Element {
  const { addNewQueryTab } = useTabActions();
  const { removeHistoryItem } = useHistory();
  const items = useAtomValue(currentServiceHistoryAtom);

  const [search, setSearch] = useState('');

  const filtered = useMemo(() => {
    const words = search.toLowerCase().split(/\s+/);
    return items.filter((item) => {
      const name = item.sql.toLowerCase();
      return words.every((part) => name.includes(part));
    });
  }, [items, search]);

  const deleteItem = (id: string): void => {
    removeHistoryItem(id);
  };

  const selectItem = (item: HistoryItem): void => {
    addNewQueryTab({
      query: item.sql,
      type: 'query',
      title: 'Untitled query',
      saved: false
    });
  };

  return (
    <>
      <div css={marginStyle('1rem')}>
        <SearchField
          onChange={(value: string): void => {
            sidebarLogEvent('searchHistory', 'Input');
            setSearch(value);
          }}
          placeholder="Search query history"
          value={search}
          onFocus={(): void =>
            sidebarLogEvent('searchHistory', 'Focus', 'trigger')
          }
          onBlur={(): void =>
            sidebarLogEvent('searchHistory', 'Blur', 'trigger')
          }
          className="fs-exclude"
        />
      </div>
      <div css={historyListing}>
        {filtered.map((item) => (
          <SidebarNavigationItem
            type="sqlSidebar"
            key={item.id}
            aria-label={item.sql}
            role="listitem"
            onClick={(e): void => {
              e.stopPropagation();
              selectItem(item);
              sidebarLogEvent(logName, 'Open');
            }}
            label={
              <>
                <div className="itemMain">
                  <Text color="default" className="fs-exclude">
                    {item.sql}
                  </Text>
                  <Text color="muted">{item.when.toISOString()}</Text>
                </div>
                <IconButton
                  size="xs"
                  icon="cross"
                  type="ghost"
                  className="closeIcon"
                  onClick={(e): void => {
                    e.stopPropagation();
                    deleteItem(item.id);
                    sidebarLogEvent(logName, 'Delete');
                  }}
                />
              </>
            }
          />
        ))}
      </div>
    </>
  );
}
