import { UploadSignatureDetails } from '@cp/common/protocol/Storage';
import { useCallback, useRef, useState } from 'react';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
type ProgressCallbackType = (file: File, percentage: number) => void;

function uploadS3(
  signatureDetails: UploadSignatureDetails,
  file: File,
  progressCallback: ProgressCallbackType
): Promise<unknown> {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();

    const formData = new FormData();
    const fields = signatureDetails.fields;
    formData.append('key', fields.key);
    formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
    formData.append('X-Amz-Date', fields['X-Amz-Date']);
    formData.append('Policy', fields['Policy']);
    formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
    if (fields['X-Amz-Security-Token']) {
      formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token']);
    }
    formData.append('file', file);

    xhr.onreadystatechange = (): void => {
      if (xhr.readyState === 4) {
        resolve(xhr);
      }
    };
    xhr.onerror = (): void => {
      reject(xhr);
    };

    if (progressCallback) {
      xhr.upload.onprogress = (e): void => {
        if (e.lengthComputable) {
          const percentComplete = (e.loaded / e.total) * 100;
          progressCallback(file, percentComplete);
        }
      };
    }

    xhr.open('POST', signatureDetails.url);
    xhr.send(formData);
  });
}

type StatusType = 'default' | 'completed' | 'in_progress';
type ProgressFileContent = {
  error?: string;
  file: File;
  signatureDetails?: UploadSignatureDetails;
  progress?: number;
};

type ProgressRefType = {
  date: number;
  files: {
    [fileName: string]: ProgressFileContent;
  };
};

export type SupportAttachmentReturnType = {
  status: StatusType;
  progress?: number;
  uploadFiles: (files: FileList, caseId?: string) => Promise<[Array<File>, Array<string>]>;
  downloadAttachment: ({ storageId }: { storageId: string }) => Promise<void>;
};

function useSupportAttachment(): SupportAttachmentReturnType {
  const [progress, setProgress] = useState<number | undefined>();
  const [uploadStatus, setUploadStatus] = useState<StatusType>('default');
  const organization = useCurrentOrganizationOrThrow();
  const progressRef = useRef<ProgressRefType>({
    date: Date.now(),
    files: {}
  });
  const failedFileNames = useRef<Array<string>>([]);
  const uploadFilesData = useRef<Array<File>>([]);
  const api = useApiClient();

  const onProgressChange: ProgressCallbackType = (file, percentage) => {
    progressRef.current.files[file.name].progress = percentage;
    const fileValues = Object.values(progressRef.current.files);
    const totalProgress = fileValues.reduce((prevValue, currentValue) => {
      return prevValue + (currentValue.progress ?? 0);
    }, 0);
    setProgress(totalProgress / fileValues.length);
  };

  const prepareSupportAttachmentPromises = (files: FileList, caseId?: string): Promise<UploadSignatureDetails>[] => {
    const promiseList: Promise<UploadSignatureDetails>[] = [];
    for (const file of files) {
      progressRef.current.files[file.name] = {
        file
      };
      promiseList.push(
        api.support.prepareSupportAttachmentUpload(
          organization.id,
          file.name,
          file.type || 'application/octet-stream',
          caseId
        )
      );
    }
    return promiseList;
  };

  const prepareSupportUploadPromises = (files: FileList, results: PromiseSettledResult<UploadSignatureDetails>[]) => {
    const promiseList: Promise<unknown>[] = [];
    results.forEach((result, index) => {
      const file = files.item(index);
      if (file && result.status === 'fulfilled') {
        progressRef.current.files[file.name].signatureDetails = result.value;
        promiseList.push(uploadS3(result.value, file, onProgressChange));
        uploadFilesData.current.push(file);
      } else if (file) {
        failedFileNames.current.push(file.name);
        progressRef.current.files[file.name].error = 'Failed to Upload';
      }
    });
    return promiseList;
  };

  const filterUploadResponse = (results: PromiseSettledResult<unknown>[]) => {
    const response: [Array<File>, Array<string>] = [[], []];
    results.forEach((result, index) => {
      const file = uploadFilesData.current[index];
      if (result.status === 'rejected') {
        failedFileNames.current.push(file.name);
      } else {
        response[0].push(file);
        const storageId = progressRef.current.files[file.name].signatureDetails?.id;
        if (storageId) {
          response[1].push(storageId);
        }
      }
    });
    return response;
  };

  const uploadFiles = useCallback(
    async (files: FileList, caseId?: string): Promise<[Array<File>, Array<string>]> => {
      setUploadStatus('in_progress');
      const progressFiles: Record<string, ProgressFileContent> = {};
      const currentDate = Date.now();
      progressRef.current = {
        date: currentDate,
        files: progressFiles
      };
      const results = await Promise.allSettled(prepareSupportAttachmentPromises(files, caseId));

      if (currentDate !== progressRef.current.date) {
        return [[], []];
      }
      failedFileNames.current = [];
      uploadFilesData.current = [];
      const finalResults = await Promise.allSettled(prepareSupportUploadPromises(files, results));
      if (currentDate !== progressRef.current.date) {
        return [[], []];
      }
      const uploadResponse = filterUploadResponse(finalResults);

      if (failedFileNames.current.length > 0) {
        const plural = failedFileNames.current.length > 1;
        createToast(
          'Failed to upload files',
          'error',
          `File${plural ? 's' : ''} ${failedFileNames.current.join(', ')} could not be uploaded`
        );
      }
      setUploadStatus(uploadResponse[0].length === 0 ? 'default' : 'completed');
      return uploadResponse;
    },
    [prepareSupportAttachmentPromises, prepareSupportUploadPromises]
  );

  const downloadAttachment = useCallback(
    async ({ storageId }: { storageId: string }): Promise<void> => {
      const url = await api.support.downloadSupportAttachment(storageId, organization.id);
      window.open(url, '_blank');
    },
    [api, organization.id]
  );

  return {
    status: uploadStatus,
    progress,
    uploadFiles,
    downloadAttachment
  };
}

export default useSupportAttachment;
