import {
  Accordion,
  Badge,
  BadgeState,
  EllipsisContent,
  Link,
  Table,
  TableHeaderType,
  TableRowType,
  Tooltip
} from '@clickhouse/click-ui';
import { Galaxy } from 'galaxy';
import { createToast } from 'primitives';

import React, { ReactElement } from 'react';
import { ImportStatus } from 'shared/src/dataLoading';
import Actions from 'src/components/DataImports/Actions';
import DataImportDetail from 'src/components/DataImports/DataImportDetail';
import CancelDataImportConfirmDialog from 'src/components/DataImports/DataImportDetail/CancelDataImportConfirmDialog';

import {
  useDataImports,
  useDataLoadingController
} from 'src/dataloading/dataloadingController';
import { isSuccessful } from 'src/dataloading/models';
import { errorMessage } from 'src/lib/errors/errorMessage';
import {
  formatDateHuman,
  formatUtcTimestamp
} from 'src/lib/formatters/dateTimeFormatter';
import styled from 'styled-components';

import { DataImport } from 'types/prisma';

const TableAccordion = styled(Accordion)`
  td > div {
    height: auto;
  }
`;

const fileTypesLabels: Record<string, string> = {
  file_url: 'URL',
  file_upload: 'File',
  sample_data: 'Sample Data'
};

type ImportDetailsCellProps = {
  dataImport: DataImport;
  onClickOpenDetails: (dataImport: DataImport) => void;
};

const ImportDetailsCell = ({
  dataImport,
  onClickOpenDetails
}: ImportDetailsCellProps): ReactElement => {
  const { openTableView } = useDataLoadingController();
  const isSuccess = isSuccessful(dataImport.status as ImportStatus);
  const label = isSuccess ? 'View data' : 'View Details';

  const onClick = (): void => {
    if (isSuccess) {
      Galaxy.galaxy().track('dataLoading.list.openTableViewSelect', {
        interaction: 'click',
        importId: dataImport.id
      });
      openTableView(dataImport);
    } else {
      Galaxy.galaxy().track('dataLoading.list.openImportDetailsSelect', {
        interaction: 'click',
        importId: dataImport.id
      });
      onClickOpenDetails(dataImport);
    }
  };

  return (
    <EllipsisContent role="button" tabIndex={-1} onClick={onClick}>
      <Link>{label}</Link>
    </EllipsisContent>
  );
};

type Props = { serviceId: string };
function DataImports({ serviceId }: Props): ReactElement | null {
  const { dataImports, error, isLoading } = useDataImports(serviceId);
  const [selectedDataImport, setSelectedDataImport] =
    React.useState<DataImport | null>(null);

  const [cancelDataImportOpen, setCancelDataImportDialogOpen] =
    React.useState(false);

  const [selectedToCancel, setSelectedToCancel] = React.useState<string | null>(
    null
  );

  const { retryImport } = useDataLoadingController();

  if (error) return <div>failed to load imports</div>;
  if (isLoading) return null;

  if (dataImports.length === 0) {
    return null;
  }

  const headers: Array<TableHeaderType> = [
    { label: 'Name', width: '30%' },
    { label: 'Source', width: '20%' },
    { label: 'Status', width: '15%' },
    { label: 'Table data', width: '10%' },
    { label: 'Created on', width: '15%' },
    { label: 'Actions', width: '10%' }
  ];

  const getStatus = (status: ImportStatus): BadgeState => {
    switch (status) {
      case 'canceled':
      case 'failed':
      case 'failed_splitting':
      case 'stopped':
        return 'danger';
      case 'success':
        return 'success';
      case 'importing':
      case 'queued_for_importing':
      case 'splitting':
        return 'info';
      case 'new':
      case 'queued':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getText = (status: ImportStatus): string => {
    switch (status) {
      case 'canceled':
        return 'canceled';
      case 'failed':
      case 'failed_splitting':
        return 'failed';
      case 'stopped':
        return 'stopped';
      case 'success':
        return 'success';
      case 'importing':
      case 'queued_for_importing':
      case 'splitting':
        return 'importing';
      case 'queued':
        return 'queued';
      default:
        return 'default';
    }
  };

  const getTypeLabel = (fileType: string): string => {
    return fileTypesLabels[fileType] ?? 'unknown';
  };

  const rows: Array<TableRowType> = dataImports.map((dataImport, idx) => {
    const status = dataImport.status as ImportStatus;
    return {
      id: dataImport.id,
      items: [
        {
          label: (
            <Tooltip>
              <EllipsisContent
                component={Tooltip.Trigger}
                className="fs-exclude"
              >
                {dataImport.name}
              </EllipsisContent>
              <Tooltip.Content side="bottom" className="fs-exclude">
                {dataImport.name}
              </Tooltip.Content>
            </Tooltip>
          )
        },
        {
          label: (
            <EllipsisContent key={`type-${idx}`}>
              {getTypeLabel(dataImport.getImportModel().type)}
            </EllipsisContent>
          )
        },
        {
          label: (
            <Badge
              key={`status-${idx}`}
              state={getStatus(status)}
              text={getText(status)}
            />
          )
        },
        {
          label: (
            <ImportDetailsCell
              key={`details-${idx}`}
              dataImport={dataImport}
              onClickOpenDetails={(dataImport: DataImport): void => {
                setSelectedDataImport(dataImport);
              }}
            />
          )
        },
        {
          label: (
            <Tooltip>
              <EllipsisContent component={Tooltip.Trigger}>
                {formatDateHuman(dataImport.createdAt)}
              </EllipsisContent>
              <Tooltip.Content side="bottom">
                {formatUtcTimestamp(
                  dataImport.createdAt,
                  'YYYY-MM-DD HH:mm:ss (UTC)'
                )}
              </Tooltip.Content>
            </Tooltip>
          )
        },
        {
          label: (
            <Actions
              key={`actions-${idx}`}
              importId={dataImport.id}
              serviceId={serviceId}
              status={status}
              onOpenDetails={() => {
                setSelectedDataImport(dataImport);
              }}
              onRetry={() => {
                Galaxy.galaxy().track('dataLoading.list.retryImportSelect', {
                  interaction: 'click',
                  importId: dataImport.id
                });
                retryImport(dataImport.id).catch((error) => {
                  console.error(error);
                  Galaxy.galaxy().track('dataLoading.list.retryImportError', {
                    interaction: 'click',
                    importId: dataImport.id
                  });
                  createToast(
                    'Error retrying import',
                    'error',
                    errorMessage(error)
                  );
                });
              }}
              onCancel={(dataImportId) => {
                setSelectedToCancel(dataImportId);
                setCancelDataImportDialogOpen(true);
                Galaxy.galaxy().track(
                  'dataLoading.list.cancelImportConfirmDialogOpen',
                  { interaction: 'click', importId: dataImportId }
                );
              }}
            />
          )
        }
      ]
    };
  });

  if (!(rows && rows.length > 0)) {
    return null;
  }

  return (
    <TableAccordion title="Data upload history" defaultValue="item">
      {cancelDataImportOpen && selectedToCancel && (
        <CancelDataImportConfirmDialog
          open={cancelDataImportOpen}
          serviceId={serviceId}
          dataImportId={selectedToCancel}
          onClose={(): void => {
            Galaxy.galaxy().track(
              'dataLoading.list.cancelImportConfirmDialogClose',
              { interaction: 'click', importId: selectedDataImport?.id }
            );
            setCancelDataImportDialogOpen(false);
            setSelectedToCancel(null);
          }}
        />
      )}
      <Table headers={headers} rows={rows} />
      {selectedDataImport && (
        <DataImportDetail
          dataImport={selectedDataImport}
          onOpenChange={(open: boolean): void => {
            if (!open) {
              setSelectedDataImport(null);
              Galaxy.galaxy().track('dataLoading.list.importDetailClose', {
                interaction: 'click',
                importId: selectedDataImport.id
              });
            } else {
              Galaxy.galaxy().track('dataLoading.list.importDetailOpen', {
                interaction: 'click',
                importId: selectedDataImport.id
              });
            }
          }}
        />
      )}
    </TableAccordion>
  );
}

const MemoizedDataImports = React.memo(DataImports);
MemoizedDataImports.displayName = 'DataImports';
export default MemoizedDataImports;
