import { Accordion, Container, Icon, Spacer, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import CreditBalancesTable, {
  CreditBalance
} from 'src/components/BillingInfo/CreditBalancesTable';
import InvoicesTable, {
  Invoice
} from 'src/components/BillingInfo/InvoicesTable';
import UsageStatementsTable, {
  UsageStatement
} from 'src/components/BillingInfo/UsageStatementsTable';

export type BillingInfoProps = {
  showInvoices: boolean;
  invoices: Invoice[];
  creditBalances: CreditBalance[];
  usageStatements: UsageStatement[];
  isLoading: boolean;
};

const NotYetText = ({
  isLoading,
  text
}: {
  isLoading: boolean;
  text: string;
}): ReactElement => {
  if (isLoading) {
    return <Icon name="horizontal-loading" />;
  }
  return <Text color="muted">No {text} yet</Text>;
};

const BillingInfo = ({
  showInvoices,
  invoices,
  creditBalances,
  usageStatements,
  isLoading
}: BillingInfoProps): ReactElement => {
  return (
    <>
      {showInvoices && (
        <Accordion title="Invoices" defaultValue="item" size={'lg'}>
          {invoices.length > 0 ? (
            <InvoicesTable invoices={invoices} />
          ) : (
            <NotYetText text="invoices" isLoading={isLoading} />
          )}
        </Accordion>
      )}
      <Spacer size="md" />
      <Accordion title="Credits" defaultValue="item" size={'lg'}>
        {creditBalances.length > 0 ? (
          <Container gap="md" orientation="vertical">
            <CreditBalancesTable creditBalances={creditBalances} />
          </Container>
        ) : (
          <NotYetText text="credits" isLoading={isLoading} />
        )}
      </Accordion>
      <Spacer size="md" />
      <Accordion title="Usage statements" defaultValue="item" size={'lg'}>
        {usageStatements.length > 0 ? (
          <>
            <Text color="muted">
              Usage statements are finalized 48 hours after the end of the
              billing period. This statement reflects approximate usage (to
              date) and may change.
            </Text>
            <Spacer size="sm" />
            <UsageStatementsTable usageStatements={usageStatements} />
          </>
        ) : (
          <NotYetText text="usage statements" isLoading={isLoading} />
        )}
      </Accordion>
    </>
  );
};

export default BillingInfo;
