import { Draft } from 'immer';
import { useState } from 'react';
import { DataPreviewer } from 'src/lib/dataLoading/DataPreviewer';
import {
  ClickPipeImport,
  TableType,
  isClickPipesKafkaImport
} from 'shared/src/dataLoading';
import { convertSampleSchemaToColumns } from 'src/lib/dataLoading/clickpipes/samples';
import { useTableNames, useTables } from 'src/metadata/metadataState';
import { useColumns } from 'src/state/dataLoading';
import { getCurrentServiceIdOrFail } from 'src/state/service';

import PreviewBox from 'src/components/ImportView/ImportForm/PreviewBox';
import TableSettingsForm from 'src/components/ImportView/ImportForm/TableSettingsForm';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';

import ExistingTableForm from 'src/components/ImportView/ClickPipesImportForm/ParseInformation/ExistingTableForm';
import { useApiClient } from 'src/lib/controlPlane/client';
import {
  Alert,
  Button,
  RadioGroup,
  Text,
  Container,
  Separator
} from '@clickhouse/click-ui';

import { validate } from 'src/components/ImportView/ClickPipesImportForm/ParseInformation/validation';

export default function ParseInformation(): JSX.Element {
  const { importModel, setTableConfig, updateImportModel, previousStep } =
    useClickPipesImport<ClickPipeImport>();
  const api = useApiClient();
  const [errors, setErrors] = useState<ReadonlyArray<string> | undefined>(
    undefined
  );

  const columns = useColumns();

  const tableConfig = importModel.data.tableConfig;
  const tableType = tableConfig?.tableType;

  const selectedDatabase = tableConfig?.config.schema || 'default';
  const tables = useTables();
  const tableNames = useTableNames();

  const serviceId = getCurrentServiceIdOrFail();

  const dataPreviewer = new DataPreviewer(
    api,
    serviceId,
    tables,
    selectedDatabase
  );
  const rows = importModel.data.rowsPreview;

  const setTableType = (newType: TableType): void => {
    if (tableType === newType) {
      return;
    }

    let tableName: string;
    if (newType === 'existing') {
      tableName = tables.length > 0 ? tables[0].tableName : '';
    } else if (isClickPipesKafkaImport(importModel)) {
      tableName = importModel.data.topic;
    } else {
      tableName = importModel.name.toLocaleLowerCase().replace(/ /g, '_');
    }

    const tableConfig = dataPreviewer.generateTableConfig(
      newType,
      tableName,
      convertSampleSchemaToColumns(importModel.data.sourceSchema)
    );

    setTableConfig(tableConfig);
    setErrors(undefined);
  };

  const next = (): void => {
    const validationErrors = validate(
      tableType,
      tableConfig.config,
      tableNames
    );
    if (validationErrors.length === 0) {
      setErrors(undefined);
      updateImportModel((draft: Draft<ClickPipeImport>) => {
        draft.data.step = 4;
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Container orientation="vertical" gap="md" maxWidth="800px">
      <Text size="md">
        Now you need to create a new table or select an existing one where the
        incoming data can be stored. The table would have columns for each
        relevant piece of information such as date, amount, payer, and payment
        method.
      </Text>
      <PreviewBox loading={false} columns={columns} rows={rows} />
      <Separator size="sm" />
      <RadioGroup
        label="Upload data to"
        onValueChange={setTableType}
        value={tableType}
      >
        <RadioGroup.Item value="newTable" label="New table" />
        <RadioGroup.Item value="existing" label="Existing table" />
      </RadioGroup>

      {tableType === 'newTable' && <TableSettingsForm />}

      {tableType === 'existing' && (
        <ExistingTableForm collapsibleColumns={false} />
      )}

      {errors && (
        <Alert
          showIcon
          state="danger"
          data-testid="clickpipe-destination-error"
          text={errors.map((error) => (
            <Text color="danger" key={error}>
              {error}
            </Text>
          ))}
          type="default"
        />
      )}
      <Container gap="md">
        <Button type="secondary" onClick={previousStep}>
          Back
        </Button>
        <Button onClick={next} data-testid="parse-info-next-step">
          Next: Details and Settings
        </Button>
      </Container>
    </Container>
  );
}
