import { useCurrentOrganization } from 'src/organization/organizationState';
import { DataWarehouseUpdatePayload } from '@cp/common/protocol/DataWarehouse';
import { useDataWarehouseStateManager } from 'src/dataWarehouses/dataWarehousesState';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';

export function useInitDataWarehouseState(): void {
  const { setDataWarehouses, updateDataWarehouse } = useDataWarehouseStateManager();
  const organization = useCurrentOrganization();

  useWebSocketsNotification<DataWarehouseUpdatePayload>({
    subscriptionDetails: {
      type: 'ORG_DATA_WAREHOUSE_UPDATE',
      objId: organization?.id ?? ''
    },
    handler: ({ payload }) => {
      if (payload.updateType === 'COMPLETE') {
        setDataWarehouses(payload.dataWarehouses);
      } else {
        for (const dataWarehouse of payload.dataWarehouses) {
          updateDataWarehouse(dataWarehouse);
        }
      }
    }
  });
}
