import { css, SerializedStyles, Theme } from '@emotion/react';

export const spreadsheetContainer = (theme: Theme) => css`
  width: 100%;
  top: 32px;
  height: calc(100% - 32px);
  position: absolute;
  overflow: hidden;
  background: ${theme.global.color.background.default};

  & .ReactVirtualized__Grid {
    padding-right: 18px;
    padding-bottom: 18px;
    overflow: hidden !important;

    &:hover {
      overflow: overlay !important;
      @supports not (overflow: overlay) {
        overflow: auto !important;
      }
    }

    &:focus {
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
      outline: 0;
    }

    &:focus-visible {
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
      outline: 0;
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

export const headerContainer = (theme: Theme) => css`
  & .ReactVirtualized__Grid {
    overflow: hidden !important;
    background: transparent;
    pointer-events: none;
    padding-right: 18px;

    .ReactVirtualized__Grid__innerScrollContainer {
      background: ${theme.global.color.background.default};
      pointer-events: auto;
    }
  }

  &[data-scrolled='true'] .ReactVirtualized__Grid__innerScrollContainer {
    border-bottom: 1px solid ${theme.global.color.stroke.default};
  }
`;

export const cellHeaderStyle = (theme: Theme) => css`
  padding: 3px;
  position: sticky;
  top: 0;
  height: 100%;
  background: ${theme.global.color.background.muted};
  color: ${theme.global.color.text.muted};
  user-select: none;
  width: 100%;
  cursor: default;
  overflow: hidden;

  .header-cell-context-menu {
    height: 100%;
    width: 100%;
  }

  .header-tooltip {
    padding: 4px 5px;
    height: 100%;
    width: 100%;
  }

  &[data-selected='true'] {
    background: ${theme.global.color.stroke.default};
    color: ${theme.global.color.text.default};
  }
`;

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

export const headerClickContainer = css`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const cellDataStyle = (isNumber: boolean, isEmpty: boolean) => (theme: Theme) => css`
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  height: 100%;
  padding: 8px;
  background: transparent;
  position: relative;
  user-select: text;
  cursor: default;
  color: ${theme.global.color.text.default};

  ${ellipsis};
  ${isEmpty && `color: ${theme.global.color.text.muted};`};
  ${isNumber && 'text-align: right;'};
`;

export const commonBorderStyle = (theme: Theme): SerializedStyles => css`
  position: relative;
  border: 1px solid ${theme.global.color.stroke.default};

  &:not([data-is-last-row='true']) {
    border-bottom: none;
  }

  &:not([data-is-last-column='true']) {
    border-right: none;
  }
`;

export const rowNumberStyle = (theme: Theme): SerializedStyles =>
  css({
    background: theme.global.color.background.muted,
    textAlign: 'right',
    borderLeft: 'none',
    color: theme.global.color.text.muted,
    "&[data-selected-row='true']": {
      background: theme.global.color.background.default
    },
    "&[data-selected-entire-row='true']": {
      background: theme.global.color.background.muted
    }
  });

export const noRow = (theme: Theme) => css`
  flex-grow: 1;
  color: ${theme.global.color.text.default};
  background: transparent;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  cursor: default;
`;

export const tableContainer = (theme: Theme) => css`
  position: relative;

  &[data-hasborder='true'] {
    border-top: 1px solid ${theme.global.color.stroke.default};
  }

  & .dataCellOuter {
    position: relative;
    border: 1px solid ${theme.global.color.stroke.default};

    &:not([data-is-last-row='true']) {
      border-bottom: none;
    }

    &:not([data-is-last-column='true']) {
      border-right: none;
    }

    height: 100%;

    .ContextMenu {
      min-width: 100px;
    }

    & > div:not(.ContextMenu) {
      height: 100%;
    }

    &[data-isfocused='true'] .dataCellInner {
      box-shadow: inset 0 0 0 1px ${theme.global.color.accent.default};
    }

    &:focus-within {
    }

    &[data-selected='true'] {
      &[data-column='0'] {
        border-left-color: ${theme.global.color.accent.default};
      }

      &[data-row='0'] {
        border-top-color: ${theme.global.color.accent.default};
      }
    }

    &[data-selected='true'],
    &[data-isfocused='true'] {
      background: ${theme.global.color.background.muted};

      &[data-is-last-column='true'] {
        border-right-color: ${theme.global.color.accent.default};
      }

      &[data-is-last-row='true'] {
        border-bottom-color: ${theme.global.color.accent.default};
      }
    }
  }

  & .dataCellBorderTop {
    border-top-color: ${theme.global.color.accent.default};
  }

  & .dataCellBorderLeft {
    border-left-color: ${theme.global.color.accent.default};
  }

  & .dataCellInner {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    height: 100%;
    padding: 0 8px;
    background: transparent;
    position: relative;
    user-select: text;
    cursor: default;
    color: ${theme.global.color.text.default};
    display: flex;
    align-items: center;

    &[data-selected-row='true'] {
      background: ${theme.global.color.background.muted};
    }

    p {
      background: transparent;
      color: inherit;
      margin: 0;
      padding: 0;
      height: var(--line-height);

      .enter-key {
        color: ${theme.global.color.text.muted};
      }

      ${ellipsis};
    }
  }

  & .dataCellIsEmpty {
    color: ${theme.global.color.text.muted};
  }

  & .dataCellIsNumber {
    text-align: right;
  }

  .header-cell {
    border-left: 1px solid ${theme.global.color.stroke.default};

    &:nth-last-of-type(1) {
      border-right: 1px solid ${theme.global.color.stroke.default};
    }

    &:first-of-type {
      border-left: none;
    }
  }
`;
export const root = css`
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  --line-height: 17px;

  &[data-monospace='true'] {
    font-family: Inconsolata, Consolas, monospace;
    --line-height: 24px;
    font-size: 15px;
    line-height: 24px;

    .ContextMenu {
      font-family: 'Inter';
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const spreadsheetLoaderStyle = css({
  width: 0,
  height: 0,
  flex: '1 1 auto'
});
