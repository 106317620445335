import { ReactElement, useState } from 'react';
import { Container } from '@clickhouse/click-ui';
import BusinessTabAddress from 'src/pages/MarketplaceOnboarding/BusinessTabAddress';
import BusinessTabCompanyDetails from 'src/pages/MarketplaceOnboarding/BusinessTabCompanyDetails';

type PageType = 'address' | 'company-details';

type BusinessTabParams = {
  onShowAccountTypeSelector: (show: boolean) => void;
  onCompleteSignUp: () => void;
  isLoading: boolean;
};
const BusinessTab = ({
  onShowAccountTypeSelector,
  onCompleteSignUp,
  isLoading
}: BusinessTabParams): ReactElement => {
  const [pageType, setPageType] = useState<PageType>('address');
  return (
    <Container orientation="vertical">
      {pageType === 'address' ? (
        <BusinessTabAddress
          onCompanyDetails={() => {
            setPageType('company-details');
            onShowAccountTypeSelector(false);
          }}
          isLoading={isLoading}
        />
      ) : (
        <BusinessTabCompanyDetails
          onComplete={() => {
            onCompleteSignUp();
          }}
          onBack={() => {
            setPageType('address');
            onShowAccountTypeSelector(true);
          }}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};

export default BusinessTab;
