import { Avatar, Container, Icon, Select, Text } from '@clickhouse/click-ui';
import { OrganizationUser } from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import { getInitials } from 'src/pages/MembersPage/utils';
import { permissionsLabel } from 'src/components/QueryView/Toolbar/constants';

interface ShareModalUserProps {
  editable?: boolean;
  isCurrentUser: boolean;
  onChange?: (value: string) => void;
  onDelete?: () => void;
  permissions: 'none' | 'read' | 'write' | 'owner';
  user: OrganizationUser;
}

export default function ShareModalUser({
  editable,
  isCurrentUser,
  onChange,
  onDelete,
  permissions,
  user
}: ShareModalUserProps): ReactElement {
  return (
    <Container orientation="horizontal" justifyContent="space-between">
      <Container gap="sm" grow="6">
        <Avatar text={getInitials(user.name)} />
        <Container orientation="vertical">
          <Text>{user.name}</Text>
          <Text color="muted" size="sm">
            {user.email} {isCurrentUser && '(You)'}
          </Text>
        </Container>
      </Container>
      {permissions === 'owner' ? (
        <Container grow="1">
          <Text style={{ whiteSpace: 'nowrap' }}>Query owner</Text>
        </Container>
      ) : editable ? (
        <Container gap="sm" grow="1">
          <Container grow="6" maxWidth="11rem" minWidth="11rem">
            <Select
              disabled={typeof onChange !== 'function'}
              onSelect={onChange}
              value={permissions}
            >
              <Select.Item value="read">Read only access</Select.Item>
              <Select.Item value="write">Write access</Select.Item>
            </Select>
          </Container>
          {typeof onDelete === 'function' && (
            <Icon cursor="pointer" name="cross" onClick={onDelete} />
          )}
        </Container>
      ) : (
        <Text>{permissionsLabel[permissions]}</Text>
      )}
    </Container>
  );
}
