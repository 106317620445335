import { ConfirmationDialog, Link, Spacer, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useIsUnifiedConsole } from 'src/lib/features';
import { routes } from 'src/lib/routes';

interface WakeServiceModalProps {
  instanceId?: string;
  message: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function WakeServiceModal({
  instanceId,
  message,
  open,
  onCancel,
  onConfirm
}: WakeServiceModalProps): ReactElement | null {
  const isUnifiedConsole = useIsUnifiedConsole();

  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="Wake this service?"
      primaryActionLabel="Wake service"
      secondaryActionLabel="Cancel"
      data-testid="waking-service-confirmation-dialog"
    >
      <Text>
        {`This service is currently in an idle state. To ${message}, it will need
        to be woken up.`}
      </Text>
      <Spacer />
      <Text>
        You can adjust your idling period on the{' '}
        <Link
          href={
            isUnifiedConsole
              ? routes.serviceSettings({ serviceId: instanceId || '' })
              : routes.controlPlaneSettings({ serviceId: instanceId || '' })
          }
        >
          settings screen
        </Link>
      </Text>
    </ConfirmationDialog>
  );
}

export default WakeServiceModal;
