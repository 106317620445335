import {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import uniq from 'lodash/uniq';

import {
  logCreateFolder,
  onContextMenuToggle
} from 'src/components/QueryView/analytics';
import { useDebounce } from 'src/lib/hooks';

import { Folder, NodeType } from 'shared/src/types/queryFolders';
import { getSearchPaths } from 'src/components/QueryView/QueryList/Folders/utils';

import {
  getNode,
  insertNode,
  removeNodeFromTree
} from 'shared/src/utils/queryFolders';

import CustomDragLayer from 'src/components/QueryView/QueryList/Folders/Tree/CustomDragLayer';
import Drop from 'src/components/QueryView/QueryList/Folders/Tree/Drop';
import { treeContainer } from 'src/components/QueryView/QueryList/Folders/Tree/styles';
import TreeNodes from 'src/components/QueryView/QueryList/Folders/Tree/TreeNodes';
import {
  OnDropType,
  TreeProps
} from 'src/components/QueryView/QueryList/Folders/Tree/types';
import { ContextMenu, Text } from '@clickhouse/click-ui';
import { relative } from 'src/lib/utility-styles';
import { AutoSizer as _AutoSizer } from 'react-virtualized';
import { marginStyle } from 'src/components/global-styles';
import { AutoSizerProps } from 'react-virtualized';
import { assertTruthy } from '@cp/common/utils/Assert';

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

const Tree = (props: TreeProps): ReactElement => {
  const {
    noDataMessage,
    onChange,
    onClick,
    onCreateFolder,
    onCreateOption,
    onDelete,
    search: searchProp,
    treeData,
    type,
    mainRef,
    currentTabId,
    scrollRef,
    onContentRename,
    selectedFolder
  } = props;
  const treeContainerRef = useRef<HTMLDivElement>(null);
  const [expandData, setExpandData] = useState<Record<string, boolean>>({});
  const [expandedPaths, setExpandedPaths] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [draggingOver, setDraggingOver] = useState(false);
  const [draggingBelow, setDraggingBelow] = useState(false);
  const search = useDebounce(searchProp, 100);

  const onTitleChange = useCallback(
    (nodeId: string, path: string, title: string): void => {
      const data = cloneDeep(treeData) as Folder[];
      const targetPath = '[' + path.split('-').join('].children[') + ']';
      set(data, `${targetPath}.title`, title);

      const nodeResult = getNode(data, nodeId);
      assertTruthy(nodeResult, 'Invalid node');

      onChange(data, nodeResult.path, 'update', {
        ...nodeResult.node,
        title
      } as NodeType);
    },
    [onChange, treeData]
  );

  const onDrop: OnDropType = useCallback(
    (sourceId, targetId, direction, item) => {
      const data = cloneDeep(treeData);
      if (!sourceId) {
        return;
      }

      const sourceNodeResult = getNode(data, sourceId);
      if (!sourceNodeResult) {
        return;
      }

      if (targetId !== '') {
        const targetNodeResult = getNode(data, targetId);
        if (!targetNodeResult) {
          return;
        }
      }

      const sourcePath = removeNodeFromTree(data, sourceNodeResult);
      if (!sourcePath) {
        return;
      }

      const { newTree } = insertNode({
        node: sourceNodeResult.node,
        targetId,
        tree: data
      });

      onChange(newTree, sourcePath, 'move', targetId);
    },
    [onChange, treeData]
  );

  const onExpand = useCallback(
    (path: string, id: string): void => {
      if (search && search.trim().length > 0) {
        setExpandedPaths((state) => {
          if (state.includes(path)) {
            state = state.filter((item) => item !== path);
          } else {
            state.push(path);
            state = uniq(state);
          }

          return state;
        });

        return;
      }

      setExpandData((state) => {
        const newState = { ...state };
        newState[id] = !newState[id];
        return newState;
      });
    },
    [onChange, search, treeData]
  );

  const [firstPath, lastPath]: [string | null, string | null] = useMemo(() => {
    let firstPath: string | number | null = null;
    let lastPath: string | number | null = null;
    if (treeData) {
      treeData.forEach((node, index) => {
        if (node && !node.hidden) {
          if (firstPath === null) {
            firstPath = index;
          }

          lastPath = index;
        }
      });

      firstPath = firstPath !== null && firstPath > -1 ? `${firstPath}` : null;
      lastPath = lastPath !== null && lastPath > -1 ? `${lastPath}` : null;
    }

    return [firstPath, lastPath];
  }, [treeData]);

  const shouldShowEmpty = useMemo(() => {
    return !treeData.some((item) => {
      if (item.type === 'content') {
        return !item.hidden;
      } else {
        return true;
      }
    });
  }, [treeData]);

  useEffect(() => {
    if (search && search.trim().length > 0) {
      let paths = getSearchPaths(treeData, search);
      paths = paths.reduce((acc: string[], path: string) => {
        const chars = path.split('-');
        const arr = [];
        for (let i = 0; i < chars.length; i++) {
          arr.push(chars.slice(0, i + 1).join('-'));
        }

        acc.push(...arr);
        return acc;
      }, []);

      setExpandedPaths(uniq(paths));
    } else {
      if (expandedPaths.length > 0) {
        setExpandedPaths([]);
      }
    }
  }, [search]);

  return (
    <AutoSizer>
      {(size): ReactNode => (
        <ContextMenu onOpenChange={onContextMenuToggle}>
          <ContextMenu.Trigger>
            <div
              css={treeContainer(size.height)}
              tabIndex={-1}
              role="list"
              ref={treeContainerRef}
              onClick={(): void => {
                treeContainerRef.current && treeContainerRef.current.focus();
              }}
            >
              <div css={relative} data-testid="tree-context-menu">
                <Drop
                  onDrop={onDrop}
                  parentId=""
                  path={firstPath}
                  onDrag={setDraggingOver}
                  size={size}
                  direction="top"
                  isDragging={isDragging}
                />
              </div>
              <CustomDragLayer setIsDragging={setIsDragging} />
              {noDataMessage && shouldShowEmpty && (
                <Text css={marginStyle('0 1rem')}>{noDataMessage}</Text>
              )}
              <TreeNodes
                nodes={treeData}
                search={search}
                onClick={onClick}
                onCreateFolder={onCreateFolder}
                onDelete={onDelete}
                onDrop={onDrop}
                onExpand={onExpand}
                onTitleChange={onTitleChange}
                expandedPaths={expandedPaths}
                expandData={expandData}
                isDragging={isDragging}
                type={type}
                mainRef={mainRef}
                currentTabId={currentTabId}
                scrollRef={scrollRef}
                size={size}
                onContentRename={onContentRename}
                selectedFolder={selectedFolder}
              />
              {lastPath && (
                <div css={relative}>
                  <Drop
                    onDrop={onDrop}
                    parentId=""
                    path={lastPath}
                    onDrag={setDraggingBelow}
                    direction="bottom"
                  />
                </div>
              )}
            </div>
          </ContextMenu.Trigger>
          <ContextMenu.Content side="bottom" align="start">
            <ContextMenu.Item
              onClick={(): void => {
                void onCreateFolder();
                logCreateFolder();
              }}
            >
              Create folder
            </ContextMenu.Item>
            <ContextMenu.Item onClick={onCreateOption.onClick}>
              {onCreateOption.label}
            </ContextMenu.Item>
          </ContextMenu.Content>
        </ContextMenu>
      )}
    </AutoSizer>
  );
};

export default Tree;
