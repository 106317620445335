import {
  GetLastUINotificationsRequest,
  GetLastUINotificationsResponse,
  GetNotificationRequest,
  GetNotificationResponse,
  UpdateMarkAsReadNotificationRequest,
  NotificationChannelSetting,
  GetNotificationChannelSettingsRequest,
  GetNotificationChannelSettingsResponse,
  UpdateChannelSettingRequest,
  UpdateChannelSettingResponse
} from '@cp/common/protocol/Notifications';
import { HttpClient } from 'src/lib/http';
import config from 'src/lib/config';
import { NotificationName } from '@cp/common/protocol/NotificationInventory';

const instanceApiUrl = `${config.controlPlane.apiHost}/api/notification`;

type NotificationsRequest =
  | GetLastUINotificationsRequest
  | GetNotificationRequest
  | GetNotificationChannelSettingsRequest
  | UpdateChannelSettingRequest
  | UpdateMarkAsReadNotificationRequest;

export type FetchUINotificationsInput = Omit<GetLastUINotificationsRequest, 'rpcAction'>;

export class NotificationApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  rpcRequest(request: NotificationsRequest, url = instanceApiUrl): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async getLastUINotifications({
    organizationId
  }: {
    organizationId: string;
  }): Promise<GetLastUINotificationsResponse> {
    const request: GetLastUINotificationsRequest = {
      rpcAction: 'getLastUINotifications',
      organizationId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while fetching notifications');
    }
    return response.json() as Promise<GetLastUINotificationsResponse>;
  }

  async updateMarkAsRead({
    lastNotificationId,
    organizationId
  }: {
    lastNotificationId: string;
    organizationId: string;
  }): Promise<void> {
    const request: UpdateMarkAsReadNotificationRequest = {
      rpcAction: 'updateMarkAsRead',
      organizationId,
      lastId: lastNotificationId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while marking notification as read');
    }
  }

  async getNotifications({
    pagination,
    search,
    timeFrom,
    severity,
    category,
    organizationId
  }: Omit<GetNotificationRequest, 'rpcAction'>): Promise<GetNotificationResponse> {
    const request: GetNotificationRequest = {
      rpcAction: 'getNotifications',
      organizationId,
      pagination,
      search,
      timeFrom,
      severity,
      category
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while filtering notifications');
    }
    return response.json() as Promise<GetNotificationResponse>;
  }
  async channelSettings({
    organizationId
  }: Omit<GetNotificationChannelSettingsRequest, 'rpcAction'>): Promise<GetNotificationChannelSettingsResponse> {
    const request: GetNotificationChannelSettingsRequest = {
      rpcAction: 'getChannelSettings',
      organizationId
    };

    // TODO add useSwr, @see https://github.com/ClickHouse/control-plane/issues/10278
    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while fetching notification channel settings');
    }
    return response.json() as Promise<GetNotificationChannelSettingsResponse>;
  }

  async updateChannelSetting({
    organizationId,
    notificationName,
    setting
  }: {
    organizationId: string;
    notificationName: NotificationName;
    setting: NotificationChannelSetting;
  }): Promise<UpdateChannelSettingResponse> {
    const request: UpdateChannelSettingRequest = {
      rpcAction: 'updateChannelSetting',
      organizationId,
      notificationName,
      setting
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while updating notification channel settings');
    }

    return response.json() as Promise<UpdateChannelSettingResponse>;
  }
}
