import {
  Container,
  IconButton,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType,
  Title
} from '@clickhouse/click-ui';
import {
  NOTIFICATION_SEVERITY_LEVELS,
  NotificationCategory,
  NotificationName,
  NotificationType
} from '@cp/common/protocol/NotificationInventory';
import {
  GetNotificationChannelSettingsResponse,
  NotificationChannelServiceSetting
} from '@cp/common/protocol/Notifications';
import { memo, useMemo } from 'react';
import { getHumanReadableCategory } from 'src/pages/NotificationsPage/helpers';
import { NotificationSeverityBadge } from 'src/pages/NotificationsPage/NotificationSeverityBadge';
import { NotifyViaCell } from 'src/pages/NotificationsPage/Settings/NotifyViaCell';
import { OnServiceCell } from 'src/pages/NotificationsPage/Settings/OnServiceCell';

type SettingsTableProps = {
  notificationTypes: Partial<{
    [K in NotificationName]: NotificationType;
  }>;
  selectNotification: (notificationName: NotificationName) => void;
  notificationsSettings?: GetNotificationChannelSettingsResponse['settings'];
  isLoading: boolean;
  notificationCategory: NotificationCategory;
};

const getHeaders = (
  notificationCategory: NotificationCategory
): TableHeaderType[] => {
  return [
    {
      label: 'Notify when',
      width: '100%'
    },
    {
      label: 'Severity',
      width: '120px'
    },
    {
      label: 'Notify via',
      width: notificationCategory === 'service' ? '120px' : '470px'
    },
    ...(notificationCategory === 'service'
      ? [{ label: 'On service', width: '350px' }]
      : []),
    {
      label: 'Actions',
      width: '80px'
    }
  ];
};

export const SettingsTable = memo(
  ({
    notificationTypes,
    selectNotification,
    notificationCategory,
    notificationsSettings,
    isLoading
  }: SettingsTableProps) => {
    const headers = useMemo(
      () => getHeaders(notificationCategory),
      [notificationCategory]
    );

    const rows = useMemo((): TableRowType[] => {
      const orderedBySeverity = Object.keys(notificationTypes).sort(
        (a, b) =>
          NOTIFICATION_SEVERITY_LEVELS[
            notificationTypes[b as NotificationName]?.severity || 'info'
          ] -
          NOTIFICATION_SEVERITY_LEVELS[
            notificationTypes[a as NotificationName]?.severity || 'info'
          ]
      );
      return orderedBySeverity.map((notificationName) => {
        const notificationType =
          notificationTypes[notificationName as NotificationName];
        if (!notificationType) {
          return { id: notificationName, items: [] };
        }

        const { title } = notificationType;
        const openFlyoutForNotification = (): void => {
          selectNotification(notificationName as NotificationName);
        };

        const isEditable = notificationType.isConfigurable;
        return {
          id: notificationName,
          items: [
            {
              label: <>{title}</>
            },
            {
              label: (
                <NotificationSeverityBadge
                  notificationName={notificationName as NotificationName}
                />
              )
            },
            {
              label: (
                <NotifyViaCell
                  setting={
                    notificationsSettings?.[
                      notificationName as NotificationName
                    ]
                  }
                />
              )
            },
            ...(notificationCategory === 'service'
              ? [
                  {
                    label: (
                      <OnServiceCell
                        openFlyoutForNotification={openFlyoutForNotification}
                        setting={
                          notificationsSettings?.[
                            notificationName as NotificationName
                          ] as NotificationChannelServiceSetting
                        }
                      />
                    )
                  }
                ]
              : []),
            {
              label: (
                <Container justifyContent="end">
                  <IconButton
                    onClick={openFlyoutForNotification}
                    icon="pencil"
                    disabled={!isEditable || isLoading}
                  />
                </Container>
              )
            }
          ]
        };
      });
    }, [
      notificationTypes,
      notificationsSettings,
      notificationCategory,
      isLoading,
      selectNotification
    ]);

    return (
      <Container orientation="vertical">
        <Title type="h2">
          {getHumanReadableCategory(notificationCategory)}
        </Title>
        <Spacer size="md" />
        <Table headers={headers} rows={rows} />
      </Container>
    );
  }
);
