import { Button, Separator } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useApiKeys } from 'src/apiKey/apiKeyController';
import { useAuth } from 'src/components/auth';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import CreateKeyModal from 'src/pages/ApiKeysPage/CreateKeyModal';
import DeleteKeyModal from 'src/pages/ApiKeysPage/DeleteKeyModal';
import Description from 'src/pages/ApiKeysPage/Description';
import KeysList from 'src/pages/ApiKeysPage/KeysList';
import NewKeyInfo from 'src/pages/ApiKeysPage/NewKeyInfo';
import NoKeys from 'src/pages/ApiKeysPage/NoKeys';

export default function ApiKeysPage(): ReactElement | null {
  const organizationId = useCurrentOrganizationOrThrow().id;
  const {
    closeCreateKeyModal,
    createKeyModalOpen,
    createOrEditKey,
    deleteKey,
    deleteKeyModalOpen,
    deletingKey,
    editingKey,
    keys,
    loading,
    newKeyInfo,
    openCreateKeyModal,
    openDeleteKeyModal,
    openEditKeyModal,
    role,
    setDeleteKeyModalOpen,
    toggleEnabled
  } = useApiKeys({ organizationId });
  const { currentUser } = useAuth();

  if (!role) {
    return null;
  }

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="API keys">
        {keys.length !== 0 && (
          <Button iconLeft="plus" onClick={openCreateKeyModal}>
            New API key
          </Button>
        )}
      </MainPaneLayout.Topbar>
      <MainPaneLayout.Content>
        <MaxWidthContainer
          alignItems="start"
          gap="md"
          orientation="vertical"
          padding="lg"
        >
          {!loading ? (
            keys.length === 0 ? (
              <>
                <MaxWidthContainer
                  alignItems="center"
                  gap="md"
                  orientation="vertical"
                  padding="lg"
                >
                  <NoKeys openCreateKeyModal={openCreateKeyModal} />
                </MaxWidthContainer>
              </>
            ) : (
              <>
                <Description />
                {newKeyInfo && (
                  <>
                    <Separator size="sm" />
                    <NewKeyInfo newKeyInfo={newKeyInfo} />
                  </>
                )}
                <KeysList
                  currentUser={currentUser}
                  openDeleteKeyModal={openDeleteKeyModal}
                  openEditKeyModal={openEditKeyModal}
                  keys={keys}
                  role={role}
                  toggleEnabled={toggleEnabled}
                />
              </>
            )
          ) : null}
        </MaxWidthContainer>
      </MainPaneLayout.Content>
      <CreateKeyModal
        editingKey={editingKey}
        onClose={closeCreateKeyModal}
        onSubmit={createOrEditKey}
        open={createKeyModalOpen}
      />
      <DeleteKeyModal
        deletingKey={deletingKey}
        onClose={(): void => {
          setDeleteKeyModalOpen(false);
        }}
        onSubmit={deleteKey}
        open={deleteKeyModalOpen}
      />
    </MainPaneLayout>
  );
}
