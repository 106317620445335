import { Alert, Link, Text } from '@clickhouse/click-ui';
import { ReactElement, ReactNode, useEffect } from 'react';
import { internalQuerySetting } from 'shared/src/clickhouse';
import { useQueryRunner } from 'src/lib/clickhouse/query';
import { logger } from 'src/lib/logger';

export const IDLE_SERVICE_ALERT_TEXT =
  'This service is idle. Wake your service to view table schema metadata and run queries.';
export const WAKING_SERVICE_ALERT_TEXT =
  'Waking service, this may take a few moments...';

export const PROVISIONING_SERVICE_ALERT_TEXT =
  'Provisioning service, this may take a few moments...';

export const STARTING_SERVICE_ALERT_TEXT =
  'Restarting service, this may take a few moments......';

const styles = {
  refreshDataLinkStyle: {
    background: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    font: 'inherit'
  },
  blueText: {
    color: '#135be6'
  }
};

interface ServiceAlertBannerProps {
  isAsleep: boolean;
  isAwaking: boolean;
  isAwake: boolean;
  isStopped: boolean;
  isStarting: boolean;
  isProvisioning: boolean;
  awakeServiceCallback: React.ReactEventHandler;
}

const ServiceAlertBanner = ({
  isAsleep,
  isAwaking,
  isAwake,
  isStopped,
  isStarting,
  isProvisioning,
  awakeServiceCallback
}: ServiceAlertBannerProps): ReactElement | null => {
  const awakeService = useQueryRunner({
    sql: `SELECT 1 SETTINGS ${internalQuerySetting};`,
    options: { wakeService: true }
  });

  useEffect(() => {
    if (isAwaking) {
      const interval = setInterval(() => {
        awakeService().catch(console.error);
        logger.track({
          view: 'app',
          component: 'alertBanner',
          event: 'awakeServicePing',
          interaction: 'trigger'
        });
      }, 20000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isAwaking]);

  if (isAwake || isStopped) {
    return null;
  }

  const serviceStateBannerText: ReactNode = (
    <>
      {isAsleep && (
        <Text>
          {IDLE_SERVICE_ALERT_TEXT + ' '}
          <Link
            size="sm"
            onClick={awakeServiceCallback}
            style={styles.refreshDataLinkStyle}
          >
            Wake service
          </Link>
        </Text>
      )}
      {isAwaking && (
        <Text style={styles.blueText}>{WAKING_SERVICE_ALERT_TEXT}</Text>
      )}
      {isStarting && (
        <Text style={styles.blueText}>{STARTING_SERVICE_ALERT_TEXT}</Text>
      )}
      {isProvisioning && (
        <Text style={styles.blueText}>{PROVISIONING_SERVICE_ALERT_TEXT}</Text>
      )}
    </>
  );

  return (
    <Alert
      text={serviceStateBannerText}
      showIcon
      state="info"
      type="banner"
      size="small"
      customIcon={isAsleep ? 'alarm' : 'horizontal-loading'}
      data-testid="service-state-alert-banner"
    ></Alert>
  );
};

export default ServiceAlertBanner;
