import { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export default {
  container: (isUnified: boolean): SerializedStyles => css`
    height: 100%;
    width: inherit;
    min-width: inherit;
    ${isUnified
      ? `
    display: flex;
    flex-direction: column;
    flex: 1;
    `
      : `
     display: grid;
    grid-template-rows: 56px auto auto auto 1fr;
    `}
  `,
  databaseSelectorRow: css`
    display: grid;
    place-items: center;
    padding: 0 1rem;
    width: inherit;
  `,
  newTableRow: css`
    width: inherit;
    gap: 10px;
    padding: 1rem;
    padding-bottom: 0;
    button {
      width: fill-available;
    }
  `,
  tableListContainer: css({
    position: 'relative',
    flex: 1
  })
};
