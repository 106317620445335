import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

type ByocOnboardingStatus =
  // We are not a BYOC organization
  | 'no_byoc'
  // We're waiting for first region to complete
  | 'pending'
  // Onboarding first region is no longer pending
  | 'completed';

export function useByocOnboardingStatus(): ByocOnboardingStatus {
  const currentOrganization = useCurrentOrganizationOrThrow();

  if (!isOrgByoc(currentOrganization)) {
    return 'no_byoc';
  }

  const byocConfig = currentOrganization.byocConfig;
  const providerConfigs = Object.values(byocConfig);
  const accountConfigs = providerConfigs.flatMap((config) => Object.values(config));
  const regionConfigs = accountConfigs.flatMap((config) => Object.values(config.regions));

  if (regionConfigs.length === 1 && regionConfigs[0].state === 'infra-provisioning') {
    // There is only one config and we're waiting for it
    return 'pending';
  } else {
    return 'completed';
  }
}
