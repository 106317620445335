import React, { useMemo, useRef } from 'react';

import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { v4 as uuid } from 'uuid';

import AxisTick from './ChartComponents/AxisTick';
import CartesianGrid from './ChartComponents/CartesianGrid';
import PrimitiveLegend from './ChartComponents/Legend';
import {
  useDataLimit,
  useResizeListener,
  useVerticalInterval,
  useXAxisHeight,
  useYAxisWidth
} from './ChartUtils/hooks';
import defaultClasses from './styles';

const PrimitiveComposedChart = React.forwardRef((props, ref) => {
  const {
    animate,
    footer,
    layout,
    data: dataProp,
    editable,
    showLegend,
    subtitle,
    stacked,
    title,
    xAxis,
    xAxisType,
    xAxisLabel,
    xAxisTickVertical,
    yAxis,
    yAxisType,
    yAxisLabel,
    allowEscapeViewBox
  } = props;
  const theme = useTheme();

  const data = useDataLimit(dataProp);
  const id = useRef(`bar-chart-${uuid()}`);
  const [height, rootRef, headerRef, footerRef, onResize, width] =
    useResizeListener([title, subtitle, footer]);
  const interval = useVerticalInterval(data, width);
  const charLimit = useMemo(
    () => Math.max(Math.round((height / 100) * 6), 10),
    [height]
  );
  const yAxisWidth = useYAxisWidth(id.current, yAxisLabel, yAxis, data);
  const [xAxisHeight, yOffset] = useXAxisHeight(
    id.current,
    xAxisLabel,
    xAxis,
    xAxisTickVertical,
    data,
    charLimit
  );
  const colors = theme.colors.chart.values;

  const [charts, gradients] = useMemo(() => {
    const axis = layout === 'vertical' ? yAxis : xAxis;
    const radius = layout === 'vertical' ? [3, 3, 0, 0] : [0, 3, 3, 0];
    let charts;
    const gradients = [];
    if (typeof axis === 'string') {
      charts = [
        <Bar
          isAnimationActive={animate}
          key={0}
          dataKey={axis}
          fill={colors[0]}
          barSize={15}
          radius={radius}
        />
      ];
    } else if (Array.isArray(axis)) {
      charts = axis.map((item, key) => {
        switch (item.type) {
          case 'area': {
            gradients.push(
              <linearGradient
                key={key}
                id={`area-color-${key}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={item.color || colors[key % colors.length]}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={item.color || colors[key % colors.length]}
                  stopOpacity={0}
                />
              </linearGradient>
            );
            return (
              <Area
                isAnimationActive={animate}
                key={key}
                type="linear"
                dataKey={item.column}
                strokeWidth={2}
                stroke={item.color}
                fill={`url(#area-color-${key})`}
                // activeDot={{ r: 5 }}
              />
            );
          }
          case 'line': {
            return (
              <Line
                dot={false}
                isAnimationActive={animate}
                key={key}
                type="linear"
                dataKey={item.column}
                strokeWidth={2}
                stroke={item.color || colors[key % colors.length]}
                activeDot={{ r: 5 }}
              />
            );
          }
          case 'scatter': {
            return (
              <Scatter
                isAnimationActive={animate}
                key={key}
                dataKey={item.column}
                fill={item.color || colors[key]}
                activeDot={{ r: 5 }}
              />
            );
          }
          case 'bar':
          default: {
            return (
              <Bar
                isAnimationActive={animate}
                key={key}
                stackId={stacked ? 'a' : null}
                dataKey={item.column}
                fill={item.color || colors[key % colors.length]}
                barSize={15}
                radius={
                  key !== axis.length - 1 && stacked ? [0, 0, 0, 0] : radius
                }
              />
            );
          }
        }
      });
    }

    return [charts, gradients];
  }, [layout, JSON.stringify(xAxis), JSON.stringify(yAxis), animate]);

  return (
    <div id={id.current} ref={rootRef} css={defaultClasses.root}>
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
      <div ref={headerRef} css={defaultClasses.headerContainer}>
        <div css={defaultClasses.header}>
          {title || (editable ? 'Enter title here' : '')}
        </div>
        {subtitle && (
          <div css={defaultClasses.subtitle}>
            {subtitle || (editable ? 'Enter subtitle here' : '')}
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart
          key={`${xAxisLabel}${yAxisLabel}`}
          data={Array.isArray(data) ? data : null}
          layout={layout === 'vertical' ? 'horizontal' : 'vertical'}
          css={defaultClasses.chartStyle}
        >
          {gradients && gradients.length > 0 && <defs>{gradients}</defs>}
          <CartesianGrid parentRef={rootRef} />
          {xAxisTickVertical && (
            <XAxis
              interval={interval}
              height={xAxisHeight}
              dataKey={layout === 'vertical' ? xAxis : null}
              type={layout === 'vertical' ? xAxisType || 'category' : 'number'}
              tick={<AxisTick charLimit={charLimit} />}
              tickMargin={6.5}
            >
              <Label
                value={
                  xAxisLabel || (editable ? 'Enter x-axis label here' : '')
                }
                position="insideBottom"
                offset={-3}
              />
            </XAxis>
          )}
          {!xAxisTickVertical && (
            <XAxis
              interval="preserveStart"
              height={xAxisHeight}
              dataKey={layout === 'vertical' ? xAxis : null}
              type={layout === 'vertical' ? xAxisType || 'category' : 'number'}
              tickMargin={4}
            >
              <Label
                value={
                  xAxisLabel || (editable ? 'Enter x-axis label here' : '')
                }
                position="insideBottom"
                offset={-3}
              />
            </XAxis>
          )}
          <YAxis
            dx={0}
            width={yAxisWidth + yOffset}
            dataKey={layout === 'horizontal' ? yAxis : null}
            type={layout === 'vertical' ? 'number' : yAxisType || 'category'}
          >
            <Label
              offset={0}
              value={yAxisLabel || (editable ? 'Enter y-axis label here' : '')}
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Tooltip
            cursor={{
              fill: 'rgba(240, 240, 240, 0.5)',
              stroke: 'rgba(240, 240, 240, 0.7)'
            }}
            allowEscapeViewBox={{
              x: allowEscapeViewBox,
              y: allowEscapeViewBox
            }}
          />
          {charts.length > 1 && showLegend && (
            <Legend content={PrimitiveLegend} />
          )}
          {charts}
        </ComposedChart>
      </ResponsiveContainer>
      <div ref={footerRef} css={defaultClasses.footer}>
        {footer || (editable ? 'Enter footer here' : '')}
      </div>
    </div>
  );
});

PrimitiveComposedChart.displayName = 'ComposedChart';

PrimitiveComposedChart.propTypes = {
  animate: PropTypes.bool,
  footer: PropTypes.string,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  data: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool,
  showLegend: PropTypes.bool,
  subtitle: PropTypes.string,
  stacked: PropTypes.bool,
  title: PropTypes.string,
  xAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string,
        type: PropTypes.oneOf(['bar', 'area', 'line', 'scatter'])
      })
    ),
    PropTypes.string
  ]),
  xAxisType: PropTypes.oneOf(['category', 'number']),
  xAxisLabel: PropTypes.string,
  xAxisTickVertical: PropTypes.bool,
  allowEscapeViewBox: PropTypes.bool,
  yAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        color: PropTypes.string,
        type: PropTypes.oneOf(['bar', 'area', 'line', 'scatter'])
      })
    ),
    PropTypes.string
  ]),
  yAxisType: PropTypes.oneOf(['category', 'number']),
  yAxisLabel: PropTypes.string
};

PrimitiveComposedChart.defaultProps = {
  animate: true,
  data: [],
  editable: false,
  layout: 'vertical',
  showLegend: true,
  stacked: false,
  xAxisTickVertical: false,
  allowEscapeViewBox: false
};

export default PrimitiveComposedChart;
