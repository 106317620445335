import React, { ErrorInfo, PropsWithChildren } from 'react';

import FatalErrorPage from 'src/pages/FatalErrorPage/FatalErrorPage';

class FatalErrorBoundary extends React.Component<
  PropsWithChildren<any>,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return <FatalErrorPage />;
    }

    return <>{this.props.children}</>;
  }
}

export default FatalErrorBoundary;
