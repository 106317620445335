import {
  Badge,
  Container,
  Icon,
  Link,
  Logo,
  Panel,
  Separator,
  Text,
  Title,
  Tooltip
} from '@clickhouse/click-ui';
import {
  Instance,
  InstanceTier,
  MaintenanceWindowInfoMetadata,
  MaintenanceWindowStatus
} from '@cp/common/protocol/Instance';
import { assertTruthy } from '@cp/common/utils/Assert';
import { getMaintenanceWindows } from '@cp/common/utils/MaintenanceWindowUtils';

import { ReactElement, useEffect, useState } from 'react';
import { StatusIcon } from 'src/components/InstanceStatusIcon';
import ServiceActionsMenu from 'src/components/ServiceActionsMenu/ServiceActionsMenu';
import {
  InstanceStateWithUpgrade,
  formatStateForDisplay
} from 'src/instance/instance';
import {
  useInstanceController,
  useSecondaryInstanceCountForInstance
} from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { StartMigrationModal } from 'src/pages/SettingsPage/StartMigrationModal';

import styled from 'styled-components';
import { useSelectService } from 'src/state/service/selectService';
import { routes } from 'src/lib/routes';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useOrgFeature } from 'src/lib/features';

export interface ServiceCardProps {
  serviceId: string;
  dataWarehouseId: string;
  title: string;
  isPrimaryInstance: boolean;
  version: string;
  instanceTier: InstanceTier;
  status: InstanceStateWithUpgrade;
  region: string;
  onClick: () => void;
  provider: 'aws' | 'gcp' | 'azure';
  maintenanceStatus: MaintenanceWindowStatus;
  isDesktop: boolean;
  isEncrypted: boolean;
}

const CardContainer = styled(Panel)<{ disabled: boolean }>`
  transition: background-color 0.2s;
  gap: 0;
  &:hover,
  :focus {
    background-color: ${({ theme }): string =>
      theme.click.card.secondary.color.background.hover};
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : '')};
  }
`;

const LeftHeaderElemnt = styled(Container)`
  padding-right: 0;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
`;

const CardBody = styled(Container)`
  cursor: ${(props): string => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const TooltipTrigger = styled(Tooltip.Trigger)`
  overflow: hidden;
  max-width: 100%;
`;

const ServiceTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${({ theme }): string => theme.spaces[2]};
`;

const ServiceState = styled(Text)`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const CustomBadge = styled(Badge)`
  width: max-content;
`;

function getAbbreviatedInstanceTier(tier: InstanceTier): string {
  switch (tier) {
    case 'Development':
      return 'Dev';
    case 'Production':
      return 'Prod';
    default:
      return tier;
  }
}

export const ServiceCard = ({
  isPrimaryInstance,
  title,
  version,
  instanceTier,
  status,
  onClick,
  provider,
  region,
  serviceId,
  dataWarehouseId,
  maintenanceStatus,
  isEncrypted
}: ServiceCardProps): ReactElement => {
  const [isPerformMaintenanceConfirmed, setIsPerformMaintenanceConfirmed] =
    useState(false);
  const { startFullMaintenance } = useInstanceController();
  const isDisabled = ['terminating', 'terminated', 'upgrading-error'].includes(
    status
  );
  const [startMigrationDialogOpen, setStartMigrationDialogOpen] =
    useState(false);

  // TODO(#9283):: Move isMaintenanceTriggered to InstanceState
  useEffect(() => {
    if (!maintenanceStatus) {
      setIsPerformMaintenanceConfirmed(false);
    }
  }, [maintenanceStatus]);

  const handleCardClick = (): void => {
    if (isDisabled) {
      return;
    }
    onClick();
  };

  const onPerformMaintenanceConfirm = (): void => {
    setIsPerformMaintenanceConfirmed(true);
  };

  const maintenanceButtonStatus: MaintenanceWindowStatus =
    isPerformMaintenanceConfirmed ? 'in-progress' : maintenanceStatus;

  const secondaryInstanceCount =
    useSecondaryInstanceCountForInstance(serviceId);

  const hydraFeatureEnabled = useOrgFeature('FT_ORG_HYDRA');

  const showBetaBadge = hydraFeatureEnabled && secondaryInstanceCount > 0;

  const { instances } = useInstanceStateManager();
  const primaryInstance: Instance | undefined = Object.values(instances).find(
    (otherInstance) =>
      otherInstance.isPrimary &&
      otherInstance.dataWarehouseId === dataWarehouseId
  );
  const isInstanceStopped = primaryInstance?.state === 'stopped';

  const showHydraInformation =
    hydraFeatureEnabled && secondaryInstanceCount > 0;
  const windows: Array<MaintenanceWindowInfoMetadata> = primaryInstance
    ? getMaintenanceWindows([primaryInstance])
    : [];

  const selectService = useSelectService();

  const handleStartMigration = (runAfterMaintenance = true): void => {
    assertTruthy(primaryInstance, 'Invalid primary instance');
    void startFullMaintenance({
      instanceId: primaryInstance.id,
      organizationId: primaryInstance.organizationId,
      runAfterMaintenance: runAfterMaintenance
    });

    onPerformMaintenanceConfirm();
    setStartMigrationDialogOpen(false);
  };

  return (
    <>
      <StartMigrationModal
        instanceName={primaryInstance?.name || ''}
        maintenanceWindow={windows[0]}
        open={startMigrationDialogOpen}
        onConfirm={(): void => handleStartMigration(!isInstanceStopped)}
        onCancel={(): void => setStartMigrationDialogOpen(false)}
      />
      <CardContainer
        hasBorder
        width="100%"
        padding="none"
        orientation="vertical"
        alignItems="start"
        data-testid="service-card"
        disabled={isDisabled}
      >
        <Panel
          width="100%"
          color="muted"
          radii="none"
          padding="none"
          orientation="horizontal"
        >
          <Container isResponsive={false}>
            <LeftHeaderElemnt
              orientation="horizontal"
              alignItems="start"
              padding="sm"
              gap="xs"
              justifyContent="space-between"
              isResponsive={false}
              fillWidth={true}
              disabled={isDisabled}
              onClick={handleCardClick}
              maxWidth="calc(100% - 2.75rem)"
            >
              <Container
                orientation="horizontal"
                gap="md"
                isResponsive={false}
                overflow="hidden"
                fillHeight
              >
                <Tooltip>
                  <TooltipTrigger>
                    <ServiceTitle
                      type="h3"
                      size="sm"
                      color="default"
                      data-testid="instance-title"
                    >
                      {title}
                    </ServiceTitle>
                  </TooltipTrigger>
                  <Tooltip.Content data-testid="tooltip-content">
                    {title}
                  </Tooltip.Content>
                </Tooltip>
                <Title
                  type="h4"
                  size="sm"
                  color="muted"
                  data-testid="instance-version"
                >
                  {version}
                </Title>
              </Container>
              <Container
                justifyContent="end"
                gap="sm"
                fillWidth={false}
                isResponsive={false}
              >
                <CustomBadge
                  text={getAbbreviatedInstanceTier(instanceTier)}
                  state={'neutral'}
                  data-testid="instance-tier"
                />
                {showBetaBadge && (
                  <Tooltip>
                    <Tooltip.Trigger>
                      <CustomBadge
                        text="BETA"
                        state="info"
                        color="default"
                        data-testid="instance-tier"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content maxWidth="300px" showArrow side="right">
                      This service allows compute-compute separation which
                      allows multiple services access the data stored on the
                      primary service
                    </Tooltip.Content>
                  </Tooltip>
                )}
                {isEncrypted && <Icon name={'key'} />}
              </Container>
            </LeftHeaderElemnt>
            <Container
              orientation="horizontal"
              alignItems="start"
              padding="sm"
              justifyContent="space-between"
              isResponsive={false}
              fillWidth={false}
            >
              <ServiceActionsMenu
                serviceId={serviceId}
                isDisabled={isDisabled}
                isInMaintenance={maintenanceButtonStatus === 'in-progress'}
                onStartMigrationMenuClick={() =>
                  setStartMigrationDialogOpen(true)
                }
                handleStartMigration={handleStartMigration}
              />
            </Container>
          </Container>
        </Panel>
        <Separator orientation="horizontal" size="xs" />
        <CardBody
          padding="md"
          orientation="vertical"
          gap="md"
          alignItems="start"
          isResponsive={false}
          disabled={isDisabled}
          onClick={handleCardClick}
        >
          <Container
            orientation="horizontal"
            gap="sm"
            justifyContent="start"
            isResponsive={false}
            fillWidth={false}
          >
            <StatusIcon status={status} />

            <ServiceState data-testid="instance-state">
              {formatStateForDisplay(status)}
            </ServiceState>
          </Container>
          <Container
            orientation="horizontal"
            justifyContent="space-between"
            isResponsive={false}
          >
            <Container
              orientation="horizontal"
              justifyContent="start"
              gap="sm"
              isResponsive={false}
            >
              <Logo name={provider} width="20px" height="20px" />
              <Text data-testid="instance-region">{region}</Text>
            </Container>
            {!!maintenanceButtonStatus && (
              <Container justifyContent="end" isResponsive={false}>
                <Badge
                  text={
                    maintenanceButtonStatus === 'in-progress'
                      ? 'Performing maintenance'
                      : `Maintenance ${maintenanceButtonStatus}`
                  }
                  state="info"
                  data-testid="maintenance-status-state"
                  onClick={(e) => {
                    if (maintenanceButtonStatus === 'available') {
                      e.stopPropagation();
                      e.preventDefault();
                      setStartMigrationDialogOpen(true);
                    }
                  }}
                ></Badge>
              </Container>
            )}
            {showHydraInformation && isPrimaryInstance && (
              <Container justifyContent="end" isResponsive={false}>
                Primary service
              </Container>
            )}
            {showHydraInformation && !isPrimaryInstance && primaryInstance && (
              <Container orientation="vertical" alignItems="end">
                {'Sub-service of '}
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigateTo(
                      routes.service({ serviceId: primaryInstance.id })
                    );
                    selectService({ serviceId: primaryInstance.id });
                  }}
                  href={routes.service({ serviceId: primaryInstance.id })}
                >
                  {primaryInstance.name}
                </Link>
              </Container>
            )}
          </Container>
        </CardBody>
      </CardContainer>
    </>
  );
};
