import { useMemo } from 'react';
import { useAuth } from 'src/components/auth';
import { errorMessage } from 'src/lib/errors/errorMessage';

declare global {
  interface Window {
    skipLogoutOnUnauthorized?: boolean; // activate this whenever you need to debug the logout flow
  }
}

export interface FetchOptions extends Omit<RequestInit, 'headers'> {
  headers?: Record<string, string>;
}

interface SecureFetchOptions {
  includeAuthProviderHeader: boolean;
  getAccessToken: () => Promise<string | null>;
  refreshToken?: () => Promise<string | null>;
  logOut: (url?: string) => Promise<void>;
}

export type PublicSecureFetchOptions = {
  includeAuthProviderHeader: boolean;
};

export type HttpClient = {
  get: (url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions) => Promise<Response>;
  post: (url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions) => Promise<Response>;
  patch: (url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions) => Promise<Response>;
  destroy: (url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions) => Promise<Response>;
  put: (url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions) => Promise<Response>;
};

/**
 * Global state, so we never have more than one request to fetch a new token in flight at once.
 */
let newTokenFetchInProgress: null | Promise<string | null> = null;

async function getNewToken(extraOptions: SecureFetchOptions): Promise<string | null> {
  const { getAccessToken, refreshToken } = extraOptions;
  if (newTokenFetchInProgress) {
    console.log('refresh token in progress');
    return newTokenFetchInProgress;
  } else {
    console.log('getNewToken: getting new access token');
    try {
      newTokenFetchInProgress = refreshToken ? refreshToken() : getAccessToken();
      return await newTokenFetchInProgress;
    } finally {
      newTokenFetchInProgress = null;
    }
  }
}

async function getCurrentToken(extraOptions: SecureFetchOptions): Promise<string | null> {
  const { getAccessToken } = extraOptions;
  if (newTokenFetchInProgress) {
    // If we're already fetching a new token, the old one is expired and no request made with it
    // will succeed, so we should wait for it
    console.log('refresh token in progress');
    return await newTokenFetchInProgress;
  } else {
    return await getAccessToken();
  }
}

export class SecureHttpClient implements HttpClient {
  private readonly getAccessToken: () => Promise<string | null>;
  private readonly refreshToken?: () => Promise<string | null>;
  private readonly logOut: (url?: string) => Promise<void>;

  constructor(
    getAccessToken: () => Promise<string | null>,
    logOut: (url?: string) => Promise<void>,
    refreshToken?: () => Promise<string | null>
  ) {
    this.getAccessToken = getAccessToken;
    this.logOut = logOut;
    this.refreshToken = refreshToken;
  }

  async get(url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions): Promise<Response> {
    return this.secureFetch(url, { method: 'GET', ...options }, this.getSecureFetchOptions(extraSecureOptions));
  }

  async post(url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions): Promise<Response> {
    return this.secureFetch(url, { method: 'POST', ...options }, this.getSecureFetchOptions(extraSecureOptions));
  }

  async patch(url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions): Promise<Response> {
    return this.secureFetch(url, { method: 'PATCH', ...options }, this.getSecureFetchOptions(extraSecureOptions));
  }

  async destroy(url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions): Promise<Response> {
    return this.secureFetch(url, { method: 'DELETE', ...options }, this.getSecureFetchOptions(extraSecureOptions));
  }

  async put(url: string, options?: FetchOptions, extraSecureOptions?: PublicSecureFetchOptions): Promise<Response> {
    return this.secureFetch(url, { method: 'PUT', ...options }, this.getSecureFetchOptions(extraSecureOptions));
  }

  private async secureFetch(url: string, options: FetchOptions, extraOptions: SecureFetchOptions): Promise<Response> {
    const headers: Record<string, string> = options.headers || {};

    const { logOut } = extraOptions;
    const accessToken = await getCurrentToken(extraOptions);

    if (accessToken) {
      if (extraOptions.includeAuthProviderHeader) {
        headers['auth-provider'] = 'custom';
      }
      headers['authorization'] = `Bearer ${accessToken}`;
    } else {
      return fetch(url, options);
    }

    const fetchOptions = {
      ...options,
      headers
    };

    const isUnauthenticated = (url: string, response: Response): boolean => {
      return response.status === 401;
    };

    // TODO: whenever we fully migrate to Auth0 this should be removed
    try {
      const response = await fetch(url, fetchOptions);

      if (isUnauthenticated(url, response)) {
        try {
          const accessToken = await getNewToken(extraOptions);
          fetchOptions.headers['authorization'] = `Bearer ${accessToken || ''}`;
          const responseRetry = await fetch(url, fetchOptions);

          if (isUnauthenticated(url, responseRetry)) {
            //by default we always logout. If this variable is set we skip logout for testing purposes
            // if we have the function refreshToken
            if (!window.skipLogoutOnUnauthorized) {
              await logOut();
            }
            throw new Error('This resource requires authentication.');
          } else {
            return responseRetry;
          }
        } catch (error) {
          if (!window.skipLogoutOnUnauthorized) {
            await logOut();
          }
          throw new Error(`Please re-authenticate: ${errorMessage(error)}`);
        }
      } else {
        return response;
      }
    } catch (error) {
      console.error(errorMessage(error));
      throw error;
    }
  }

  private getSecureFetchOptions(extraSecureOptions?: PublicSecureFetchOptions): SecureFetchOptions {
    return {
      includeAuthProviderHeader: extraSecureOptions?.includeAuthProviderHeader ?? true,
      getAccessToken: this.getAccessToken,
      refreshToken: this.refreshToken,
      logOut: this.logOut
    };
  }
}

export function useHttpClient(): HttpClient {
  const { getAccessToken, refreshToken, logOut } = useAuth();

  return useMemo(
    () => new SecureHttpClient(getAccessToken, logOut, refreshToken),
    [getAccessToken, logOut, refreshToken]
  );
}
