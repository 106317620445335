import { SupportCaseDetails } from '@cp/common/protocol/Support';
import { ReactElement, ReactNode } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import SupportCaseDropdown from 'src/components/SupportCaseDropdown/SupportCaseDropdown';
import NewCaseButton from 'src/components/SupportList/NewCaseButton';
import ChatWithUsButton from 'src/components/SupportList/ChatWithUsButton';
import { IconButton, Text, Title, Container } from '@clickhouse/click-ui';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import styled from 'styled-components';

const CustomText = styled(Text)`
  font: inherit;
`;

const CustomTitle = styled(Title)`
  display: flex;
  gap: inherit;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoint.sizes.md}) {
      padding-left: ${theme.spaces[2]};
    }
  `}
`;

const HideForMobile = styled(Container)`
  display: flex;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoint.sizes.md}) {
      display: none;
    }
  `}
`;

const SupportBreadcrumb = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

interface Props {
  children: ReactNode;
  supportCases?: Array<SupportCaseDetails>;
  isLoading?: boolean;
  error?: Error;
}
function SupportLayout({
  children,
  supportCases,
  isLoading,
  error
}: Props): ReactElement {
  const matchSupportListPage = useMatch('/services/:serviceId/support');
  const matchSupportListPageWithoutService = useMatch('/support');

  const isSupportListPage =
    matchSupportListPage || matchSupportListPageWithoutService;

  const { serviceId, caseId } = useParams();
  const location = useLocation();

  const navigateToSupportPage = (): void => {
    if (location.key !== 'default') {
      navigateTo(-1);
    } else if (serviceId) {
      navigateTo(routes.support({ serviceId }));
    }
  };

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar>
        <Container gap="xxs" isResponsive={false}>
          {!isSupportListPage && (
            <IconButton
              icon="chevron-left"
              type="ghost"
              onClick={navigateToSupportPage}
            />
          )}
          <CustomTitle size="sm" type="h2">
            <Container gap="xxs" isResponsive={false}>
              <SupportBreadcrumb onClick={navigateToSupportPage}>
                Support
              </SupportBreadcrumb>
              {!isSupportListPage && (
                <>
                  <CustomText color="muted" component="span">
                    /
                  </CustomText>
                  <CustomText color="muted" component="span">
                    {caseId ? caseId : 'New case'}
                  </CustomText>
                </>
              )}
            </Container>
          </CustomTitle>
        </Container>
        <Container
          padding="none"
          justifyContent="end"
          alignItems="center"
          gap="md"
          isResponsive={false}
        >
          <HideForMobile gap="md">
            <SupportCaseDropdown
              supportCases={supportCases}
              isLoading={isLoading}
              error={error}
              serviceId={serviceId}
            />
            <ChatWithUsButton />
          </HideForMobile>
          <NewCaseButton />
        </Container>
      </MainPaneLayout.Topbar>
      <MainPaneLayout.Content>{children}</MainPaneLayout.Content>
    </MainPaneLayout>
  );
}

export default SupportLayout;
