import { SerializedStyles } from '@emotion/react';
import { virtualizedScrollPosition } from 'global-styles';
import React, {
  CSSProperties,
  FC,
  MouseEvent,
  PointerEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  AutoSizer as _AutoSizer,
  AutoSizerProps,
  Grid,
  GridProps,
  GridCellProps,
  ScrollSync as _ScrollSync,
  ScrollSyncProps
} from 'react-virtualized';
import {
  OverscanIndices,
  RenderedSection
} from 'react-virtualized/dist/es/Grid';

import 'react-virtualized/styles.css'; // only needs to be imported once
import { useRefCallback } from 'src/lib/hooks';
import { LEFT_MOUSE_BTN, RIGHT_MOUSE_BTN } from 'src/lib/mouseBtns';

import { useBounds } from 'src/components/primitives/lib/Spreadsheet/bounds';
import { getContainingCellPosition } from 'src/components/primitives/lib/Spreadsheet/clickLocation';
import DataCell from 'src/components/primitives/lib/Spreadsheet/DataCell';
import DataContextMenu from 'src/components/primitives/lib/Spreadsheet/DataContextMenu';
import HeaderCell from 'src/components/primitives/lib/Spreadsheet/HeaderCell';
import {
  cellSelected,
  columnIsSelected,
  rowAnySelected,
  rowIsSelected,
  useSelectionActions
} from 'src/components/primitives/lib/Spreadsheet/selectionActions';
import {
  cellDataStyle,
  cellHeaderStyle,
  commonBorderStyle,
  headerClickContainer,
  headerContainer,
  rowNumberStyle,
  spreadsheetContainer
} from 'src/components/primitives/lib/Spreadsheet/styles';
import { useResizableColumns } from 'src/components/primitives/lib/Spreadsheet/tableColumnResizeHelpers';
import type {
  EventType,
  SelectionAction,
  SelectionPos,
  VirtualizedTableProps
} from 'src/components/primitives/lib/Spreadsheet/types';

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const ScrollSync = _ScrollSync as unknown as FC<ScrollSyncProps>;
const PRIMARY_BUTTON = 1; // Primary button (usually the left button)
const AUXILIARY_BUTTON = 4; // Auxiliary button (usually the mouse wheel button or middle button)

const headerStyle: CSSProperties = {
  overflowX: 'hidden',
  willChange: 'scroll-position'
};

declare module 'react-virtualized' {
  interface Grid {
    getTotalColumnsWidth: () => number;
    getTotalRowsHeight: () => number;
  }
}

const SPGrid = Grid as unknown as FC<GridProps>;

const BORDER_PADDING = 1; // padding for mouse move to capture pointer events

const VirtualizedTable = React.memo(function VirtualizedTable(
  props: VirtualizedTableProps
): JSX.Element {
  const {
    numRows,
    getRow: getRowProp,
    columns,
    showRowNumber = true,
    pagination,
    rowsPerPage,
    currentPage,
    onColumnResize,
    getMenuOptions,
    selection,
    onChangeFocus,
    onVisibleRowsChanged,
    onHeaderClick: onHeaderClickProp,
    onHeaderRightClick,
    filterApplied,
    setSelection,
    focus,
    onSelectionContextMenu,
    onEditCell,
    log,
    totals
  } = props;

  const getRow = useCallback(
    (i: number) => {
      if (i < numRows) {
        return getRowProp(i);
      } else if (i === numRows && totals) {
        return totals;
      } else {
        return null;
      }
    },
    [getRowProp, totals, numRows]
  );

  const dragState = useRef<number | false>(false);
  const pageStart = rowsPerPage * (currentPage - 1);

  const bounds = useBounds(columns.length, pageStart, rowsPerPage);

  const { select, clearSelection, moveSelection } = useSelectionActions({
    log,
    focus,
    numColumns: columns.length,
    numRows,
    selection
  });

  const headerGridRef = useRef<Grid>(null);
  const gridRef = useRef<Grid>(null);

  const maxRowNumber = rowsPerPage * currentPage;

  const {
    isResizing,
    resize,
    finishResize,
    autoResizeColumn,
    containerRef,
    columnWidth
  } = useResizableColumns({
    columns,
    onColumnResize,
    headerGridRef,
    gridRef,
    getRow,
    numRows,
    showRowNumber,
    maxRowNumber,
    hasTotals: totals !== undefined
  });

  const scrollGridTo = useCallback(
    (column: number | undefined, row: number | undefined) => {
      if (
        gridRef.current &&
        typeof column === 'number' &&
        typeof row === 'number'
      ) {
        gridRef.current.scrollToCell({
          columnIndex: showRowNumber ? column + 1 : column,
          rowIndex: row - rowsPerPage * (currentPage - 1)
        });
      }
    },
    [rowsPerPage, currentPage, showRowNumber]
  );

  const onChangeFocusEvent = useCallback(
    ({ row, column }: SelectionPos) => {
      const corrected = onChangeFocus({ row, column });
      scrollGridTo(column, row);
      return corrected;
    },
    [onChangeFocus, scrollGridTo]
  );

  let rowsDisplayed = totals ? numRows + 1 : numRows;

  if (pagination) {
    const rowsAfterPageStart = numRows - (filterApplied ? 0 : pageStart);
    rowsDisplayed = Math.max(0, Math.min(rowsAfterPageStart, rowsPerPage));
  }

  const inSelection = ({ row, column }: SelectionPos): boolean => {
    if (
      row >= rowsDisplayed + pageStart ||
      column >= columns.length ||
      row < 0 ||
      column < 0
    ) {
      return false;
    }
    return cellSelected(selection, column, row);
  };

  const clearSelectionAndFocus = useCallback(
    (force: boolean) => {
      setSelection(clearSelection(force));
      containerRef.current && containerRef.current.focus();
    },
    [setSelection, clearSelection, containerRef]
  );

  const changeSelectionAndFocus = useCallback(
    (action: SelectionAction, event: EventType = 'click') => {
      const newSelection = select(action, event);
      setSelection(newSelection);
      containerRef.current && containerRef.current.focus();
    },
    [select, setSelection, containerRef]
  );

  const [scrollToRow, setScrollToRow] = useState<number | undefined>(focus.row);
  const [scrollToColumn, setScrollToColumn] = useState<number | undefined>(
    showRowNumber ? focus.column + 1 : focus.column
  );

  useEffect(() => {
    // We only want to supply scrollTo(Row|Column) properties to the grid the first time
    // it renders. Otherwise it seems to occasionally scroll the view back to the selected
    // cell when resizing columns.
    setScrollToColumn(undefined);
    setScrollToRow(undefined);
  }, []);

  // When the page changes, scroll to top and select first cell
  useEffect(() => {
    // We only want to supply scrollTo(Row|Column) properties to the grid the first time
    // it renders. Otherwise it seems to occasionally scroll the view back to the selected
    // cell when resizing columns.
    setScrollToColumn(undefined);
    setScrollToRow(undefined);
  }, []);

  const lastPageStartRef = useRef(-1);

  // When the page changes, scroll to top and select first cell
  useEffect(() => {
    if (lastPageStartRef.current !== pageStart) {
      lastPageStartRef.current = pageStart;
      const position = {
        row: pageStart,
        column: 0
      };

      if (focus.row <= pageStart + rowsDisplayed) {
        position.row = focus.row;
        position.column = focus.column;
      }
      onChangeFocusEvent(position);
      clearSelection(false);
    }
  }, [
    pageStart,
    rowsDisplayed,
    clearSelection,
    focus.column,
    focus.row,
    onChangeFocusEvent
  ]);

  const onHeaderClick = useRefCallback(
    (dataColumnIndex: number, event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.ctrlKey || event.metaKey || event.altKey) {
        onChangeFocusEvent({ row: pageStart, column: dataColumnIndex });
        if (
          numRows <= rowsPerPage ||
          rowIsSelected(selection, dataColumnIndex, bounds)
        ) {
          changeSelectionAndFocus({
            type: 'columnSelection',
            column: dataColumnIndex
          });
        } else {
          changeSelectionAndFocus({
            type: 'columnSelection',
            column: dataColumnIndex
          });
        }
      } else if (event.shiftKey) {
        changeSelectionAndFocus({
          type: 'shiftColumnSelection',
          column: dataColumnIndex
        });
      } else {
        onChangeFocusEvent({ row: pageStart, column: dataColumnIndex });
        changeSelectionAndFocus({
          type: 'columnSelection',
          column: dataColumnIndex
        });
      }

      if (typeof onHeaderClickProp === 'function') {
        onHeaderClickProp({ column: columns[dataColumnIndex] });
      }
    }
  );

  const renderHeader = ({
    columnIndex,
    key,
    style
  }: GridCellProps): JSX.Element => {
    let dataColumnIndex = columnIndex;
    if (showRowNumber) {
      if (columnIndex === 0) {
        return (
          <div key={key} style={style} css={cellHeaderStyle}>
            <div css={headerClickContainer}>#</div>
          </div>
        );
      } else {
        --dataColumnIndex;
      }
    }
    const isSelected = columnIsSelected(selection, dataColumnIndex, bounds);

    const column = columns[dataColumnIndex];

    return (
      <div style={style} key={key} className="header-cell">
        <HeaderCell
          column={column}
          columnWidth={columnWidth(columnIndex)}
          isResizing={isResizing}
          columnIndex={dataColumnIndex}
          getOnResize={resize}
          getOnFinishResize={finishResize}
          getOnAutoResize={autoResizeColumn}
          onClick={onHeaderClick}
          onRightClick={onHeaderRightClick}
          isSelected={isSelected}
        />
      </div>
    );
  };

  const onCellClick = useCallback(
    (row: number, column: number, event: React.MouseEvent) => {
      if (event.shiftKey) {
        changeSelectionAndFocus({ type: 'shiftSelection', row, column });
      } else {
        onChangeFocusEvent({ row, column });
        changeSelectionAndFocus({ type: 'normal', row, column });
        containerRef.current && containerRef.current.focus();
      }
    },
    [onChangeFocusEvent, changeSelectionAndFocus, containerRef]
  );

  const renderCell = ({
    columnIndex,
    key,
    rowIndex: pageRowIndex,
    style
  }: GridCellProps): JSX.Element => {
    const rowIndex = pageStart + pageRowIndex;
    let dataColumnIndex = columnIndex;

    const selectedRow = rowAnySelected(selection, rowIndex);
    if (showRowNumber) {
      const selectedEntireRow = rowIsSelected(selection, rowIndex, bounds);
      if (columnIndex === 0) {
        const onClick = (event: React.MouseEvent): void => {
          event.preventDefault();
          event.stopPropagation();
          if (event.ctrlKey || event.metaKey || event.altKey) {
            onChangeFocusEvent({ row: rowIndex, column: 0 });
            changeSelectionAndFocus({ type: 'rowSelection', row: rowIndex });
          } else if (event.shiftKey) {
            changeSelectionAndFocus({
              type: 'shiftRowSelection',
              row: rowIndex
            });
          } else {
            onChangeFocusEvent({ row: rowIndex, column: 0 });
            changeSelectionAndFocus({ type: 'rowSelection', row: rowIndex });
          }
        };
        let rowNum: string;

        if (rowIndex === numRows && totals) {
          rowNum = 'Totals';
        } else {
          rowNum = String(rowIndex);
        }
        return (
          <div
            key={`cell-${columnIndex}-${rowIndex}`}
            style={style}
            role="listitem"
            css={(theme): Array<SerializedStyles> => [
              cellDataStyle(true, false)(theme),
              commonBorderStyle(theme),
              rowNumberStyle(theme)
            ]}
            onClick={onClick}
            data-location="row-header"
            data-row={rowIndex}
            data-selected-row={selectedRow}
            data-selected-entire-row={selectedEntireRow}
            data-copy="false"
          >
            {rowNum}
          </div>
        );
      } else {
        --dataColumnIndex;
      }
    }

    const isFocused =
      dataColumnIndex === focus?.column && rowIndex === focus?.row;
    const rightOfFocus =
      dataColumnIndex - 1 === focus?.column && rowIndex === focus?.row;
    const belowFocus =
      dataColumnIndex === focus?.column && rowIndex - 1 === focus?.row;

    const isSelected = inSelection({ row: rowIndex, column: dataColumnIndex });
    const rightOfSelectionBorder =
      isSelected !==
      inSelection({
        row: rowIndex,
        column: dataColumnIndex - 1
      });
    const belowSelectionBorder =
      isSelected !==
      inSelection({
        row: rowIndex - 1,
        column: dataColumnIndex
      });

    const selectionBorderLeft =
      rightOfSelectionBorder || rightOfFocus || isFocused;
    const selectionBorderTop = belowSelectionBorder || belowFocus || isFocused;

    return (
      <div style={style} key={key}>
        <DataCell
          rowIndex={rowIndex}
          dataColumnIndex={dataColumnIndex}
          getRow={getRow}
          isFocused={isFocused}
          selectionBorderLeft={selectionBorderLeft}
          selectionBorderTop={selectionBorderTop}
          isSelected={isSelected}
          isLastColumn={columns.length === columnIndex}
          isLastRow={rowsDisplayed === pageRowIndex + 1}
          selectedRow={selectedRow}
        />
      </div>
    );
  };

  const displayRows = useRef<[number, number] | null>(null);
  const sectionRendered = useCallback(
    ({
      rowStartIndex,
      rowStopIndex
    }: Pick<RenderedSection, 'rowStartIndex' | 'rowStopIndex'>) => {
      displayRows.current = [rowStartIndex, rowStopIndex];
      typeof onVisibleRowsChanged === 'function' &&
        onVisibleRowsChanged(
          rowStartIndex + pageStart,
          rowStopIndex + pageStart
        );
    },
    [onVisibleRowsChanged, pageStart]
  );

  useEffect(() => {
    if (displayRows.current) {
      const [displayStart, displayEnd] = displayRows.current;
      sectionRendered({
        rowStartIndex: displayStart,
        rowStopIndex: displayEnd
      });
    }
  }, [pageStart, sectionRendered]);

  const keyDown = useCallback(
    async (e: React.KeyboardEvent) => {
      const moveAnchor = e.shiftKey;
      if (e.key !== 'c' && (e.ctrlKey || e.metaKey)) {
        return;
      }

      e.preventDefault();

      const applyAction = (action: SelectionAction | null): void => {
        if (action) {
          changeSelectionAndFocus(action);
        }
        if (action?.type === 'normal') {
          onChangeFocusEvent({ row: action.row, column: action.column });
        }
      };

      switch (e.key) {
        case 'ArrowLeft':
          applyAction(moveSelection(-1, 0, moveAnchor, 'keypress'));
          break;
        case 'ArrowRight':
          applyAction(moveSelection(1, 0, moveAnchor, 'keypress'));
          break;
        case 'ArrowUp':
          applyAction(moveSelection(0, -1, moveAnchor, 'keypress'));
          break;
        case 'ArrowDown':
          applyAction(moveSelection(0, 1, moveAnchor, 'keypress'));
          break;
        case 'Enter':
          changeSelectionAndFocus(
            { type: 'normal', row: focus.row, column: focus.column },
            'keypress'
          );
          break;
        case 'Escape':
          clearSelectionAndFocus(true);
          break;
      }
    },
    [
      onChangeFocusEvent,
      clearSelectionAndFocus,
      focus.column,
      focus.row,
      changeSelectionAndFocus,
      moveSelection
    ]
  );

  const mouseDown = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    if (e.target instanceof HTMLElement && containerRef.current) {
      const position = getContainingCellPosition(
        e.target,
        containerRef.current
      );
      if (position?.type === 'cell') {
        const { row, column } = position;
        if (e.button !== RIGHT_MOUSE_BTN) {
          onCellClick(row, column, e);
        }
      }
    }
    containerRef.current && containerRef.current.focus();
  };

  const mouseDoubleClick = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    if (e.target instanceof HTMLElement && containerRef.current) {
      const position = getContainingCellPosition(
        e.target,
        containerRef.current
      );
      if (position?.type === 'cell') {
        const { row, column } = position;
        if (e.button === LEFT_MOUSE_BTN) {
          const rowData = getRow(row);
          const value = rowData?.[column];
          if (value !== undefined && onEditCell) {
            onEditCell({
              columnIndex: column,
              rowIndex: row,
              rowData,
              value
            });
          }
        }
      }
    }
  };

  const mouseMove = (e: MouseEvent<HTMLElement>): void => {
    if (
      dragState.current !== false &&
      gridRef.current !== null &&
      containerRef.current !== null &&
      (e.buttons === PRIMARY_BUTTON || e.buttons === AUXILIARY_BUTTON)
    ) {
      const columnsWidth = gridRef.current.getTotalColumnsWidth();
      const rowsHeight = gridRef.current.getTotalRowsHeight();
      const { clientX: x, clientY: y } = e;
      const { scrolledBottom, scrolledTop, scrolledLeft, scrolledRight } =
        containerRef.current.dataset;
      const clientRect = containerRef.current.getBoundingClientRect();
      let { bottom, right } = clientRect;
      const { top, left } = clientRect;
      if (right > columnsWidth + left) {
        right = columnsWidth + left;
      }
      if (bottom > rowsHeight + top) {
        bottom = rowsHeight + top;
      }
      let [newColumn, newRow] = [x, y];

      if (
        x < left + BORDER_PADDING ||
        x > right - BORDER_PADDING ||
        y < left + BORDER_PADDING ||
        y > left - BORDER_PADDING
      ) {
        containerRef.current.setPointerCapture(dragState.current);
      }

      if (x < left) {
        newColumn = left + (scrolledLeft === 'true' ? 40 : 10);
      } else if (x > right) {
        newColumn = right - 19;
      }
      if (y < top) {
        newRow = top + 10;
      } else if (y > bottom) {
        newRow = bottom - 19;
      }
      const target = document.elementFromPoint(
        newColumn,
        newRow
      ) as HTMLElement;
      let position = getContainingCellPosition(target, containerRef.current);

      if (position?.type === 'cell') {
        let { column: columnIndex, row: rowIndex } = position;

        if (x < left && scrolledLeft === 'false') {
          columnIndex = columnIndex > 2 ? columnIndex - 1 : 0;
        } else if (x > right && scrolledRight === 'false') {
          columnIndex = columnIndex + 2;
        }
        if (y < top && scrolledTop === 'false') {
          rowIndex = rowIndex - 1;
        } else if (y > bottom && scrolledBottom === 'false') {
          rowIndex = rowIndex + 1;
        }
        if (columnIndex !== position.column || rowIndex !== position.row) {
          gridRef.current.scrollToCell({
            columnIndex: columnIndex > 1 ? columnIndex : 0,
            rowIndex
          });
          //recomputing position after scroll
          position = getContainingCellPosition(target, containerRef.current);
        }
        if (position?.type === 'cell') {
          // position added as there is a posibility that the second position can return null
          const { column, row } = position;
          setSelection(
            select({ type: 'shiftSelection', row, column }, 'click')
          );
        }
      }
    }
  };

  const onPointerDown = (e: PointerEvent<HTMLDivElement>): void => {
    if (![PRIMARY_BUTTON, AUXILIARY_BUTTON].includes(e.buttons)) {
      return;
    }

    dragState.current = e.pointerId;
  };

  const onPointerUp = (e: PointerEvent<HTMLDivElement>): void => {
    if (![PRIMARY_BUTTON, AUXILIARY_BUTTON].includes(e.buttons)) {
      return;
    }

    if (containerRef.current) {
      containerRef.current.releasePointerCapture(e.pointerId);
    }
    dragState.current = false;
  };

  const columnCount = showRowNumber ? columns.length + 1 : columns.length;

  return (
    <React.Fragment>
      <AutoSizer>
        {({ width, height }): JSX.Element => (
          <DataContextMenu
            onSelectionContextMenu={onSelectionContextMenu}
            onSelectCell={(pos): void => {
              onChangeFocusEvent(pos);
              changeSelectionAndFocus({ type: 'normal', ...pos });
              containerRef.current && containerRef.current.focus();
            }}
            getMenuOptions={getMenuOptions}
            selection={selection}
            bounds={bounds}
            containerRef={containerRef}
          >
            <ScrollSync>
              {({
                onScroll,
                scrollLeft,
                scrollTop,
                clientHeight,
                clientWidth,
                scrollHeight,
                scrollWidth
              }) => (
                <>
                  <div css={headerContainer} data-scrolled={scrollTop > 0}>
                    <SPGrid
                      ref={headerGridRef}
                      cellRenderer={renderHeader}
                      rowCount={1}
                      columnCount={columnCount}
                      rowHeight={32}
                      columnWidth={({ index }): number => columnWidth(index)}
                      width={width}
                      height={32}
                      scrollLeft={scrollLeft}
                      style={headerStyle}
                      overscanColumnCount={1000}
                      overscanIndicesGetter={({
                        cellCount
                      }): OverscanIndices => ({
                        // The headers don't really need to be virtualized at all
                        // here we focus it to render everything
                        overscanStartIndex: 0,
                        overscanStopIndex: cellCount - 1
                      })}
                    />
                  </div>
                  <div
                    tabIndex={-1}
                    role="list"
                    css={spreadsheetContainer}
                    ref={containerRef}
                    className="spreadsheet"
                    onKeyDown={(e): void => void keyDown(e)}
                    onDoubleClick={mouseDoubleClick}
                    onMouseDown={mouseDown}
                    onMouseMove={mouseMove}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                    data-scrolled-top={scrollTop === 0}
                    data-scrolled-left={scrollLeft === 0}
                    data-scrolled-bottom={
                      scrollTop + clientHeight === scrollHeight + 18 // 18px is the padding bottom assigned to this div
                    }
                    data-scrolled-right={
                      scrollLeft + clientWidth === scrollWidth + 18 // 18px is the padding right assigned to this div
                    }
                  >
                    <SPGrid
                      ref={gridRef}
                      cellRenderer={renderCell}
                      rowCount={rowsDisplayed}
                      columnCount={columnCount}
                      rowHeight={32}
                      columnWidth={({ index }): number => columnWidth(index)}
                      onSectionRendered={sectionRendered}
                      width={width}
                      height={height - 32}
                      onScroll={onScroll}
                      style={virtualizedScrollPosition}
                      scrollToRow={scrollToRow}
                      scrollToColumn={scrollToColumn}
                    />
                  </div>
                </>
              )}
            </ScrollSync>
          </DataContextMenu>
        )}
      </AutoSizer>
    </React.Fragment>
  );
});

export default VirtualizedTable;
