export const noopPromise = Promise.resolve();
export let metadataInFlightPromise: Promise<unknown> = noopPromise;
export let metadataInFlightServiceId: string | null = null;
export let metadataInFlightDatabase: string | null = null;

export const setMetadataInFlightPromise = (
  promise: Promise<unknown>,
  serviceId: string | null,
  database: string | null
): void => {
  metadataInFlightPromise = promise;
  metadataInFlightServiceId = serviceId;
  metadataInFlightDatabase = database;
};
