import { css } from '@emotion/react';
import { v4 as uuid } from 'uuid';
import { getCurrentServiceId } from 'src/state/service';
import { useSelectedTab, useTabActions, useUpdateTab } from 'src/state/tabs';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { InfoIcon } from 'src/components/QueryView/VersionConflictBar/InfoIcon';
import {
  versionMismatchBar,
  versionMismatchButtons,
  versionMismatchIcon,
  versionMismatchMessage
} from 'src/components/QueryView/VersionConflictBar/styles';
import { Button } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

interface Props {
  updatedByEmail: string;
}

const userEmail = css({
  fontWeight: 500,
  paddingRight: '0.3em'
});

export function QueryUpdatedBar({ updatedByEmail }: Props): ReactElement {
  const { setSelectedTab } = useTabActions();
  const tab = useSelectedTab();
  const queryId = tab?.type === 'query' ? tab.queryId : undefined;
  const { createQuery, getQueryById } = useSavedQueries();
  const savedQuery = queryId && getQueryById(queryId);
  const updateTab = useUpdateTab();

  const duplicate = async (): Promise<void> => {
    const serviceId = getCurrentServiceId();
    if (tab && tab.type === 'query' && serviceId) {
      const newQueryId = uuid();
      const oldTitle = tab.title ?? 'Untitled query';
      const result = await createQuery({
        id: newQueryId,
        name: `${oldTitle} (copy)`,
        query: tab.query,
        database: tab.database ?? 'default'
      });
      if (result) {
        const update = {
          queryId: newQueryId,
          updatedAt: result.updatedAt,
          title: result.name
        };
        updateTab(tab.id, update);
        setSelectedTab({ ...tab, ...update });
      }
    }
  };

  const reload = (): void => {
    if (tab && savedQuery) {
      updateTab(tab.id, {
        query: savedQuery.query,
        title: savedQuery.name,
        updatedAt: savedQuery.updatedAt
      });
    }
  };

  return (
    <div css={versionMismatchBar}>
      <div css={versionMismatchMessage}>
        <div css={versionMismatchIcon}>
          <InfoIcon />
        </div>
        <span css={userEmail}>{updatedByEmail}</span>
        updated this query
      </div>
      <div css={versionMismatchButtons}>
        <Button type="secondary" onClick={duplicate}>
          Duplicate and Save
        </Button>
        <Button type="secondary" onClick={reload}>
          Reload Query
        </Button>
      </div>
    </div>
  );
}
