import { useEffect } from 'react';
import {
  WebSocketsCpClient,
  WebSocketsNotificationHandler
} from 'src/lib/websockets';
import { useAuth } from 'src/components/auth';
import { SubscriptionDetails } from '@cp/common/protocol/WebSocket';
import { useWsClient } from 'src/lib/websockets/WebSocketsClientProvider';

export interface WSSubscription<PayloadType> {
  subscriptionDetails: SubscriptionDetails;
  handler: WebSocketsNotificationHandler<PayloadType>;
  dependencies?: Array<unknown>;
}
export function useWebSocketsNotification<PayloadType>({
  subscriptionDetails,
  handler,
  dependencies = []
}: WSSubscription<PayloadType>): void {
  const { client: wsClient } = useWsClient();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser?.id || !wsClient || !subscriptionDetails.objId) {
      return;
    }

    return wsClient.addNotificationListener(subscriptionDetails, handler);
  }, [
    wsClient,
    subscriptionDetails.objId,
    subscriptionDetails.type,
    currentUser?.id,
    ...dependencies
  ]);
}

export interface WebSocketsSubscriptionManager {
  subscribeToWsNotification: <PayloadType>(
    w: WSSubscription<PayloadType>
  ) => void;
}

export const subscribeToWsNotification = <PayloadType,>(
  { subscriptionDetails, handler }: WSSubscription<PayloadType>,
  wsClient: WebSocketsCpClient
): (() => void) => {
  if (!wsClient) {
    return () => {};
  }

  return wsClient.addNotificationListener(subscriptionDetails, handler);
};
