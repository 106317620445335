import { CellProps, Container, Grid } from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import { EllipsisContainer } from 'src/layout/GlobalStyles';
import { QueryResult } from 'src/lib/clickhouse/query';
import styled from 'styled-components';

const ResultsContainer = styled.div`
  height: 400px;
  width: 500px;
`;

interface InlineQueryResultsProps {
  loading: boolean;
  results: QueryResult | null;
}

export default function InlineQueryResults({
  loading,
  results
}: InlineQueryResultsProps): ReactNode {
  if (loading) {
    return <div>loading...</div>;
  }

  if (results) {
    if ('error' in results) {
      return <div>{results.error}</div>;
    } else {
      const Cell: CellProps = ({ columnIndex, rowIndex, type, ...props }) => {
        if (!results || 'error' in results) {
          return null;
        }

        if (type === 'header-cell') {
          return (
            <Container color="muted">
              <EllipsisContainer {...props}>
                {results.columns[columnIndex].name}
              </EllipsisContainer>
            </Container>
          );
        }

        return <span {...props}>{results.rows[rowIndex]?.[columnIndex]}</span>;
      };

      return (
        <ResultsContainer>
          <Grid
            columnCount={results.columns.length ?? 0}
            rowCount={results.rows.length}
            cell={Cell}
            focus={{
              row: 0,
              column: 0
            }}
            showToast={true}
          />
        </ResultsContainer>
      );
    }
  }

  return null;
}
