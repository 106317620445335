import { Title } from '@clickhouse/click-ui';
import { ChartsThemeProvider } from '@clickhouse/viz-house';
import React, { useRef } from 'react';
import defaultClasses from 'src/components/primitives/lib/Charts/styles';
import { v4 as uuid } from 'uuid';

interface ChartWrapperProps {
  title?: string;
  subtitle?: string;
  footer?: string;
  editable?: boolean;
  children: React.ReactNode;
}

const ChartWrapper: React.FC<ChartWrapperProps> = ({
  title,
  subtitle,
  footer,
  editable,
  children
}) => {
  const id = useRef(`area-chart-${uuid()}`);
  return (
    <div id={id.current} css={defaultClasses.root}>
      <Title type="h1" size="md" color="muted">
        {title || (editable ? 'Enter title here' : '')}
      </Title>
      {subtitle && (
        <Title type="h2" size="sm" color="muted">
          {subtitle || (editable ? 'Enter subtitle here' : '')}
        </Title>
      )}
      <ChartsThemeProvider>{children}</ChartsThemeProvider>
      <Title type="h2" size="sm" color="muted">
        {footer || (editable ? 'Enter footer here' : '')}
      </Title>
    </div>
  );
};

export default ChartWrapper;
