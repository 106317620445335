import { Alert } from '@clickhouse/click-ui';

import { ReactElement, useEffect } from 'react';
import { useAlertStateManager } from 'src/alert/alertState';
import MaintenanceAlertBanner from 'src/components/AlertBanner/MaintenanceAlertBanner';

import { getCurrentServiceId } from 'src/state/service';
import ServiceAlertBannerContainer from 'src/components/AlertBanner/ServiceAlertBannerContainer';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import OrganizationAlertBanner from 'src/components/AlertBanner/OrganizationAlertBanner';

/*
 * Displays an Alert banner across the top of the UI
 */
const AlertBanner = (): ReactElement => {
  const { alertState } = useAlertStateManager();
  const currentOrganization = useCurrentOrganizationOrThrow();
  const currentServiceId = getCurrentServiceId();
  const { setBillingAlert } = useAlertStateManager();

  useEffect(() => {
    setBillingAlert({
      billingStatus: currentOrganization.billingStatus,
      trialCommitment: currentOrganization.cachedCommitmentState?.TRIAL || null
    });
  }, [currentOrganization, setBillingAlert]);

  return (
    <>
      {alertState.billingAlert && (
        <Alert
          dismissible
          showIcon={false}
          size="small"
          state="warning"
          text={alertState.billingAlert.alertText}
          type="banner"
          data-testid="billing-alert-banner"
        />
      )}
      <OrganizationAlertBanner />
      {currentServiceId && <ServiceAlertBannerContainer />}
      <MaintenanceAlertBanner
        currentOrganizationId={currentOrganization.id}
        currentServiceId={currentServiceId}
      />
    </>
  );
};

export default AlertBanner;
