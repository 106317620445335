import {
  sectionBodyStyle,
  sectionStyle,
  sectionTitleStyle
} from 'src/components/CreateTableView/styles';

interface SectionProps {
  title: string;
  children: JSX.Element | string;
}

export function Section({ title, children }: SectionProps) {
  return (
    <div css={sectionStyle}>
      <div css={sectionTitleStyle}>{title}</div>
      <div css={sectionBodyStyle}>{children}</div>
    </div>
  );
}
