import { Alert, Link, Text } from '@clickhouse/click-ui';
import { getMaintenanceWindows } from '@cp/common/utils/MaintenanceWindowUtils';
import { ReactElement, ReactNode, useState } from 'react';
import MaintenanceWindowInfoModal from 'src/components/MaintenanceWindowInfoModal';
import { useInstanceStateManager } from 'src/instance/instanceState';
import {
  formatDateHumanShort,
  formatTime,
  humanizeDuration
} from 'src/lib/formatters/dateTimeFormatter';

export interface MaintenanceAlertBannerProps {
  currentServiceId: string | null;
  currentOrganizationId: string;
}

const styles = {
  blueText: {
    color: '#135be6'
  },
  linkStyle: {
    textDecoration: 'underline'
  }
};

const MaintenanceAlertBanner = ({
  currentServiceId,
  currentOrganizationId
}: MaintenanceAlertBannerProps): ReactElement | null => {
  const { instances } = useInstanceStateManager();
  const [maintenanceWindowModalOpen, setMaintenanceWindowModalOpen] =
    useState(false);
  const currentInstances = Object.values(instances)
    .filter((i) => i.organizationId === currentOrganizationId)
    .sort(
      (i1, i2) =>
        (i2.id === currentServiceId ? 1 : 0) -
        (i1.id === currentServiceId ? 1 : 0)
    );

  const activeMaintenanceKind =
    currentInstances.length > 0 && currentServiceId
      ? currentInstances[0].activeMaintenanceKind
      : undefined;
  const instancesInScope =
    currentInstances.length > 0 && currentServiceId
      ? [currentInstances[0]]
      : currentInstances;
  const windows = getMaintenanceWindows(instancesInScope);
  if (windows.length === 0) {
    return null;
  }

  const { startMaintenanceTime, endMaintenanceTime, initiateWhenStopped } =
    windows[0];
  const windowDuration = humanizeDuration(
    endMaintenanceTime - startMaintenanceTime,
    'milliseconds'
  );
  const serviceSubject = currentServiceId
    ? 'This service'
    : 'One or more services in this organization';

  let message = '';
  if (activeMaintenanceKind === 'fullMaintenance') {
    message = `${serviceSubject} is in maintenance, you will be notified when it is over.`;
  } else if (activeMaintenanceKind === 'partialMaintenance') {
    message = `${serviceSubject} is in maintenance that should end before ${formatTime(
      endMaintenanceTime
    )} (local time).`;
  } else if (initiateWhenStopped) {
    message = `${
      currentServiceId
        ? 'This service requires'
        : 'One or more services in this organization require'
    } maintenance that should be performed before ${formatDateHumanShort(
      endMaintenanceTime
    )}.`;
  } else {
    message = `${serviceSubject} will enter a scheduled maintenance window for approximately ${windowDuration} on ${formatTime(
      startMaintenanceTime
    )} (local time).`;
  }
  const maintenanceText: ReactNode = (
    <>
      <Text style={styles.blueText}>
        {message}{' '}
        <Link
          style={styles.linkStyle}
          size="md"
          onClick={(): void => {
            setMaintenanceWindowModalOpen(true);
          }}
          data-testid="maintenance-alert-learn-more-button"
        >
          Learn more
        </Link>
      </Text>
    </>
  );

  return (
    <>
      <Alert
        showIcon={true}
        size="medium"
        state="info"
        text={maintenanceText}
        type="banner"
        data-testid="maintenance-alert-banner"
      />
      <MaintenanceWindowInfoModal
        isOpen={maintenanceWindowModalOpen}
        onClose={(): void => {
          setMaintenanceWindowModalOpen(false);
        }}
        windows={getMaintenanceWindows(currentInstances)}
      />
    </>
  );
};

export default MaintenanceAlertBanner;
