import {
  CreateOpenapiKeyRequest,
  CreateOpenapiKeyResponse,
  DeleteOpenapiKeyRequest,
  UpdateOpenapiKeyRequest
} from '@cp/common/protocol/OpenapiKey';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const apiKeyApiUrl = `${config.controlPlane.apiHost}/api/keys`;

interface ErrorResponse {
  message: string;
}

type ApiKeyRequest = CreateOpenapiKeyRequest | UpdateOpenapiKeyRequest | DeleteOpenapiKeyRequest;

export type CreateOpenapiKeyOptions = Omit<CreateOpenapiKeyRequest, 'rpcAction'>;
export type UpdateOpenapiKeyOptions = Omit<UpdateOpenapiKeyRequest, 'rpcAction'>;

export class ApiKeyApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: ApiKeyRequest, url = apiKeyApiUrl): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async createKey(options: CreateOpenapiKeyOptions): Promise<CreateOpenapiKeyResponse> {
    const request: CreateOpenapiKeyRequest = { ...options, rpcAction: 'create' };
    const res = await this.rpcRequest(request);

    if (res.status !== 200) {
      try {
        const body = await res.text();
        const json = JSON.parse(body) as ErrorResponse;
        throw new Error(json.message);
      } catch (e) {
        throw new Error('Unexpected error occurred. Please try again later.');
      }
    }

    const body = (await res.json()) as CreateOpenapiKeyResponse;

    return body;
  }

  async deleteKey(keyId: string): Promise<void> {
    const request: DeleteOpenapiKeyRequest = { keyId, rpcAction: 'delete' };
    const res = await this.rpcRequest(request);
    if (res.status !== 200) {
      try {
        const body = await res.text();
        const json = JSON.parse(body) as ErrorResponse;
        throw new Error(json.message);
      } catch (e) {
        throw new Error('Unexpected error occurred. Please try again later.');
      }
    }
  }

  async updateKey(options: UpdateOpenapiKeyOptions): Promise<void> {
    const request: UpdateOpenapiKeyRequest = { ...options, rpcAction: 'update' };
    const res = await this.rpcRequest(request);
    if (res.status !== 200) {
      try {
        const body = await res.text();
        const json = JSON.parse(body) as ErrorResponse;
        throw new Error(json.message);
      } catch (e) {
        throw new Error('Unexpected error occurred. Please try again later.');
      }
    }
  }
}
