import { MultiAccordion } from '@clickhouse/click-ui';
import { GettingStartedSteps } from '@cp/common/protocol/Instance';
import { ReactElement, ReactNode } from 'react';

export type GettingStartedValueType = keyof GettingStartedSteps;

export interface CommonAccordionProps {
  isCompleted: boolean;
  markAsCompleted?: () => void;
  showProgress: boolean;
}

interface AccordionWrapperProps
  extends Omit<CommonAccordionProps, 'showProgress' | 'markAsCompleted'> {
  children: ReactNode;
  value: GettingStartedValueType;
  title: string;
}

export const AccordionWrapper = ({
  title,
  isCompleted,
  children,
  value
}: AccordionWrapperProps): ReactElement => {
  return (
    <MultiAccordion.Item title={title} value={value} isCompleted={isCompleted}>
      {children}
    </MultiAccordion.Item>
  );
};
