import { PIXEL_PER_CHAR } from 'src/lib/grid/constants';
export function getValueOfCell(value: string | null, isFocused: boolean, maxPx: number): string | null {
  if (value === null) {
    return null;
  }

  if (isFocused) {
    return value;
  }

  // lets assume 7px per character
  const maxStrWidth = maxPx / PIXEL_PER_CHAR;

  if (value.length <= maxStrWidth) {
    return value;
  }

  return value.substring(0, maxStrWidth) + '...';
}
