import React, { useState } from 'react';

import { Label, NumberField, Text } from '@clickhouse/click-ui';

import { logEvent } from './analytics';
import { alignCenter, flex, gapSm } from 'src/lib/utility-styles';
import { marginStyle } from '../global-styles';

function ConfigRange({
  field,
  fieldConfig,
  columnName,
  value,
  onChange,
  event
}) {
  const [min, setMin] = useState(value?.min);
  const [max, setMax] = useState(value?.max);

  const parseValue = (val, oldVal) => {
    if (typeof val !== 'string') {
      return oldVal;
    } else if (val === '') {
      return undefined;
    } else {
      const parsed = parseFloat(val);
      if (Number.isNaN(parsed)) {
        return oldVal;
      } else {
        return parsed;
      }
    }
  };

  const blur = () => {
    const newMin = parseValue(min, value?.min);
    const newMax = parseValue(max, value?.max);
    onChange({
      min: newMin,
      max: newMax
    });
  };

  return (
    <div key={field}>
      <Label
        css={[[flex, alignCenter, marginStyle({ bottom: '0.5rem' })]]}
        className="fs-exclude"
      >
        {fieldConfig.label} ({columnName})
      </Label>
      <div css={[flex, alignCenter, gapSm]}>
        <NumberField
          value={min}
          onChange={setMin}
          onBlur={() => {
            logEvent(`${event}MinBlur`, 'trigger');
            blur();
          }}
          onFocus={() => {
            logEvent(`${event}MinFocus`, 'trigger');
          }}
          placeholder="min"
          className="fs-exclude"
        />
        <Text size="sm" color="muted">
          to
        </Text>
        <NumberField
          value={max}
          onChange={setMax}
          onBlur={() => {
            logEvent(`${event}MaxBlur`, 'trigger');
            blur();
          }}
          onFocus={() => {
            logEvent(`${event}MaxFocus`, 'trigger');
          }}
          placeholder="max"
          className="fs-exclude"
        />
      </div>
    </div>
  );
}

export default React.memo(function ConfigRangeList({
  field,
  fieldConfig,
  value,
  onChange,
  event
}) {
  const change = (i) => (newValue) => {
    if (!value) {
      const result = Array(fieldConfig.columns.length).fill();
      result[i] = newValue;
      onChange(result);
    } else {
      // there must be a more idiomatic way of accomplishing this
      const result = [...value];
      result.length = fieldConfig.columns.length; // make sure it's the right length
      result.fill(null, value.length);
      result[i] = newValue;
      onChange(result);
    }
  };

  return fieldConfig.columns.map((columnName, i) => {
    return (
      <ConfigRange
        key={columnName}
        field={field}
        fieldConfig={fieldConfig}
        columnName={columnName}
        value={value && value[i]}
        onChange={change(i)}
        event={event}
      />
    );
  });
});
