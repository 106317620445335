import {
  Button,
  Container,
  Icon,
  Text,
  Checkbox,
  TextField,
  Select
} from '@clickhouse/click-ui';
import { useCUITheme } from '@clickhouse/click-ui';
import { useMemo, useState } from 'react';
import {
  CDCTableSelection,
  ClickPipePostgresImport,
  PostgresImport
} from 'shared/src/dataLoading';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import { PostgresSourceTable, PostgresTableColumn } from 'types/protocol';
import ColumnBox from 'src/components/ImportView/ClickPipesImportForm/ConfigureTables/columnBox';

const TableBox = ({
  table,
  preselected
}: {
  table: PostgresSourceTable & { schemaName: string };
  preselected?: boolean;
}) => {
  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipePostgresImport>();
  const theme = useCUITheme();
  const [showColumnSettings, setShowColumnSettings] = useState<boolean>(false);

  const setTargetTableName = (tableName: string) => {
    updateImportModel((model: PostgresImport) => {
      model.data.cdcSettings.tableSelections =
        model.data.cdcSettings.tableSelections?.map(
          (tableSelection: CDCTableSelection) => {
            if (
              tableSelection.sourceSchemaName === table.schemaName &&
              tableSelection.sourceTable === table.name
            ) {
              tableSelection.targetTable = tableName;
            }
            return tableSelection;
          }
        );
    });
  };

  const handleOnSelect = (state: boolean) => {
    if (state) {
      updateImportModel((draft: PostgresImport) => {
        const tableSelections = draft.data.cdcSettings.tableSelections;
        tableSelections.push({
          sourceSchemaName: table.schemaName,
          sourceTable: table.name,
          targetTable: table.name,
          excludedColumns: [],
          selectedColumns: table.columns.map((column) => column.name)
        });
        draft.data.cdcSettings.tableSelections = tableSelections;
      });
    } else {
      updateImportModel((draft: PostgresImport) => {
        draft.data.cdcSettings.tableSelections =
          draft.data.cdcSettings.tableSelections?.filter(
            (tableSelection: CDCTableSelection) =>
              !(
                tableSelection.sourceSchemaName == table.schemaName &&
                tableSelection.sourceTable == table.name
              )
          );
      });
    }
  };

  const isSelected = useMemo(() => {
    return importModel.data.cdcSettings.tableSelections?.some(
      (tableSelection: CDCTableSelection) => {
        return (
          tableSelection.sourceSchemaName === table.schemaName &&
          tableSelection.sourceTable === table.name
        );
      }
    );
  }, [importModel.data.cdcSettings.tableSelections]);

  return (
    <Container orientation="vertical" gap="xs">
      <Container
        padding="xs"
        orientation="vertical"
        gap="md"
        style={{
          border: '1px solid ' + theme.global.color.stroke.default,
          borderRadius: '4px'
        }}
      >
        <Container key={table.name} orientation="horizontal" gap="md" fillWidth>
          <Checkbox
            key={`postgres-clickpipe-source-table-${table.name}`}
            onCheckedChange={(state) => handleOnSelect(state as boolean)}
            checked={isSelected || preselected}
            data-testid={`postgres-clickpipes-table-checkbox-${table.schemaName}.${table.name}`}
            label={table.name}
          />
          <Text color="muted"> {table.table_size} </Text>
        </Container>
        {(isSelected || preselected) && (
          <Container orientation="vertical" gap="sm">
            <Text
              style={{ fontWeight: '500' }}
              data-testid={`postgres-clickpipes-dest-table-${table.name}`}
            >
              Destination table setting
            </Text>
            <Container orientation="horizontal" gap="xs">
              <TextField
                label="Target table"
                placeholder="Enter table name"
                onChange={setTargetTableName}
                value={
                  importModel.data.cdcSettings.tableSelections?.find(
                    (tableSelection: CDCTableSelection) => {
                      return (
                        tableSelection.sourceSchemaName === table.schemaName &&
                        tableSelection.sourceTable === table.name
                      );
                    }
                  )?.targetTable
                }
                data-testid={`postgres-clickpipes-target-table-${table.schemaName}.${table.name}`}
              />
              <Select
                label="Sorting key"
                placeholder="Select columns"
                onSelect={(): void => {}}
              />
            </Container>

            <Button
              type="empty"
              style={{ paddingLeft: 0 }}
              onClick={() => {
                setShowColumnSettings(!showColumnSettings);
              }}
            >
              <Icon
                name={showColumnSettings ? 'chevron-down' : 'chevron-right'}
                size="md"
                color={theme.name === 'light' ? 'black' : undefined}
              />
              <Text size="md" weight="medium" color="default">
                Column settings
              </Text>
            </Button>
            {showColumnSettings && (
              <Container orientation="vertical" gap="xs" padding="xs">
                {table.columns.map((column: PostgresTableColumn, index) => (
                  <ColumnBox
                    key={index}
                    columnName={column.name}
                    columnType={column.data_type}
                    canBeReplicated={
                      column.is_in_publication && column.is_replident_column
                    }
                  />
                ))}
              </Container>
            )}
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default TableBox;
