import { ReactElement } from 'react';
import { Suspense } from 'react';
import { useGptCorrection } from 'src/lib/gpt/GptCorrectionHook';
import DiffEditor from 'src/components/primitives/lib/DiffEditor';
import styles from 'src/components/QueryView/GptCorrectionPreview/styles';
import { useQuery } from 'src/lib/query/QueryState';
import useLocalStorageValue from 'src/state/localStorageHook';
import { Checkbox, Button } from '@clickhouse/click-ui';

const SIDE_BY_SIDE_LOCAL_STORAGE_KEY = 'queryDiffSideBySide';

interface GptCorrectionPreviewProps {
  runId: string;
  onReplaceQueryText: (oldText: string, newText: string) => void;
}

/**
 * A component that displays a preview of a GPT-corrected ClickHouse SQL query
 * as a text diff and allows the user to apply or discard the correction.
 * @param runId The ID of the query run to correct
 * @param onReplaceQueryText A callback function to replace the original query text with the corrected text
 */
export const GptCorrectionPreview = ({
  runId,
  onReplaceQueryText
}: GptCorrectionPreviewProps): ReactElement | null => {
  const { acceptCorrection, discardCorrection, getCorrectedQueryText } =
    useGptCorrection();
  const corectedQueryText = getCorrectedQueryText(runId);
  const query = useQuery(runId);
  const originalQueryText = query?.args?.query;
  const [sideBySide, setSideBySide] = useLocalStorageValue<boolean>(
    SIDE_BY_SIDE_LOCAL_STORAGE_KEY,
    false
  );

  if (!originalQueryText || !corectedQueryText) {
    return null;
  }
  return (
    <div css={styles.container}>
      <Suspense fallback={null}>
        <DiffEditor
          originalText={originalQueryText}
          modifiedText={corectedQueryText}
          sideBySide={sideBySide}
        />
      </Suspense>
      <div css={styles.buttonContainer}>
        <Button
          type="primary"
          onClick={(): void => {
            onReplaceQueryText(originalQueryText, corectedQueryText);
            acceptCorrection(runId);
          }}
        >
          Apply
        </Button>
        <Button
          type="secondary"
          onClick={(): void => {
            discardCorrection(runId);
          }}
          data-testid="gptCorrectionCancelButton"
        >
          Cancel
        </Button>
        <Checkbox
          label="Side by Side"
          onCheckedChange={setSideBySide}
          checked={sideBySide}
        />
      </div>
    </div>
  );
};
