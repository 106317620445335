import { ReactElement } from 'react';

import { impossibleValue } from 'shared/src/impossibleValue';
import { QueryTab } from 'src/state/tabs/types';

import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';

import { QueryDeletedBar } from 'src/components/QueryView/VersionConflictBar/QueryDeletedBar';
import { QueryUpdatedBar } from 'src/components/QueryView/VersionConflictBar/QueryUpdatedBar';
import { useCurrentOrganization } from 'src/organization/organizationState';

interface Props {
  versionMismatch: VersionMismatch;
}

interface UpdatedStatus {
  status: 'updated';
  updatedByEmail: string;
}

interface DeletedStatus {
  status: 'deleted';
}

export type VersionMismatch = null | UpdatedStatus | DeletedStatus;

export function useVersionMismatch(tab: QueryTab): VersionMismatch {
  const savedQueries = useSavedQueries();
  const organization = useCurrentOrganization();

  if (
    savedQueries.loadingState === 'loading' ||
    savedQueries.loadError ||
    !organization
  ) {
    return null;
  }

  const serverSavedQuery = savedQueries.getQueryById(tab.queryId);
  if (!serverSavedQuery && tab.saved) {
    return { status: 'deleted' };
  } else if (
    tab.saved &&
    serverSavedQuery &&
    serverSavedQuery?.updatedAt !== tab.updatedAt
  ) {
    const updatedByUser =
      organization.users[serverSavedQuery.updatedByUserId || ''];
    return {
      status: 'updated',
      updatedByEmail: updatedByUser?.email ?? 'unknown user'
    };
  }
  return null;
}

export function VersionConflictBar({
  versionMismatch
}: Props): ReactElement | null {
  if (!versionMismatch) {
    return null;
  } else if (versionMismatch.status === 'deleted') {
    return <QueryDeletedBar />;
  } else if (versionMismatch.status === 'updated') {
    return <QueryUpdatedBar updatedByEmail={versionMismatch.updatedByEmail} />;
  } else {
    impossibleValue(versionMismatch);
  }
}
