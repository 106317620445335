import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef
} from 'react';

import PropTypes from 'prop-types';

export interface ScrollToProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  selected: boolean;
}

const ScrollTo = forwardRef<HTMLDivElement, ScrollToProps>(
  ({ children, selected, ...props }, ref) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (!scrollRef.current || !selected) {
        return;
      }

      scrollRef.current.scrollIntoView();
    }, [ref, selected]);

    return (
      <div ref={ref} {...props}>
        <div ref={scrollRef}>{children}</div>
      </div>
    );
  }
);

ScrollTo.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default ScrollTo;
