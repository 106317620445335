import { ReactElement } from 'react';
import {
  AllocatedMemoryMetricReportSummary,
  InsertQpsMetricReportSummary,
  MetricReport,
  MetricType,
  ReadThroughputMetricReportSummary,
  ResidentMemoryUsageMetricReportSummary,
  RowCountMetricReportSummary,
  S3StorageUsageMetricReportSummary,
  SelectQpsMetricReportSummary,
  SqlStatementsPerTypeMetricReportSummary,
  SuccessfulQueriesMetricReportSummary,
  TimePeriod,
  WriteThroughputMetricReportSummary
} from '@cp/common/protocol/Metrics';
import {
  Separator,
  Text,
  Panel,
  Title,
  Icon,
  Container
} from '@clickhouse/click-ui';
import { MetricChartInput } from 'src/metrics/metricChart';
import { DataSizePointFormat, formatDataSize } from 'src/metrics/dataSize';
import { formatTimePeriod } from 'src/metrics/timePeriod';
import { assertTruthy } from '@cp/common/utils/Assert';
import {
  SummaryPanel,
  SummaryTooltipContainer
} from 'src/components/ServiceMetrics/SummaryTooltip';

type DeltaSummaryProps = {
  title1: string;
  value1: string;
  color1?: string;
  title2: string;
  value2: string;
  color2?: string;
  help: string;
};

function formatNumberToPercentage(number: number): string {
  return `${number.toFixed(2)}%`;
}

const StatBox = ({
  label,
  value,
  dotColor
}: {
  label: string;
  value: string;
  dotColor?: string;
}): ReactElement => {
  return (
    <Panel
      orientation="vertical"
      padding="none"
      hasBorder={false}
      color="transparent"
      alignItems="start"
    >
      <Text color="muted" size="md">
        {label}
      </Text>
      <Container gap="xxs">
        {dotColor && <Icon name="dot" color={dotColor} />}
        <Title type="h1" size="xl">
          {value}
        </Title>
      </Container>
    </Panel>
  );
};

function DeltaSummary({
  title1,
  value1,
  color1,
  title2,
  value2,
  color2,
  help
}: DeltaSummaryProps): ReactElement {
  return (
    <SummaryPanel fillWidth fillHeight color="muted" radii="none">
      <Container
        orientation="vertical"
        padding="md"
        fillHeight
        gap="xs"
        maxHeight="12rem"
        justifyContent="center"
      >
        <StatBox label={title1} value={value1} dotColor={color1} />
        <Separator size="md" />
        <StatBox label={title2} value={value2} dotColor={color2} />
      </Container>
      <Separator size="sm" orientation="vertical" asChild />
      <SummaryTooltipContainer help={help} />
    </SummaryPanel>
  );
}

type TotalSummaryProps = {
  title: string;
  value: string;
  description: string;
  help: string;
};

function TotalSummary({
  title,
  value,
  description,
  help
}: TotalSummaryProps): ReactElement {
  return (
    <SummaryPanel fillWidth fillHeight color="muted" radii="none">
      <Container
        orientation="vertical"
        padding="md"
        fillHeight
        gap="xs"
        maxHeight="12rem"
        justifyContent="center"
      >
        <StatBox label={title} value={value} />
        <Separator size="md" />

        <Text color="muted" size="sm">
          {description}
        </Text>
      </Container>
      <Separator size="sm" orientation="vertical" asChild />
      <SummaryTooltipContainer help={help} />
    </SummaryPanel>
  );
}

type Props = {
  metric: MetricChartInput;
  report: MetricReport;
};

function getSummaryBytesDeltaText(
  deltaUsageInBytes: number,
  timePeriod: TimePeriod
): string {
  const deltaText =
    deltaUsageInBytes === 0
      ? 'No change'
      : (deltaUsageInBytes > 0 ? 'Increase of ' : 'Decrease of ') +
        formatDataSize(Math.abs(deltaUsageInBytes));
  const timeText = `in ${formatTimePeriod(timePeriod).toLowerCase()}`;
  return `${deltaText} ${timeText}`;
}

function getSummaryAvgText(
  metric: string,
  value: number,
  format: DataSizePointFormat = 'bytes-letter'
): string {
  const valueText = formatDataSize(Math.abs(value), format, 0);
  return `Average ${metric}: ${valueText}`;
}

function DataStored({ report, metric }: Props): ReactElement {
  const summary = report.summary as S3StorageUsageMetricReportSummary;
  const value = formatDataSize(
    summary.currentUsageInBytes,
    metric.tooltipDataSizePointFormat
  );
  const description = getSummaryBytesDeltaText(
    summary.deltaUsageInBytes,
    metric.query.timePeriod
  );
  return (
    <TotalSummary
      title="Storage usage"
      value={value}
      description={description}
      help={metric.helpTooltipText}
    />
  );
}

function AllocatedMemory({ report, metric }: Props): ReactElement {
  const summary = report.summary as AllocatedMemoryMetricReportSummary;
  const value = formatDataSize(
    summary.currentMemoryInBytes,
    metric.tooltipDataSizePointFormat
  );
  const description = getSummaryBytesDeltaText(
    summary.deltaMemoryInBytes,
    metric.query.timePeriod
  );
  return (
    <TotalSummary
      title="Allocated memory"
      value={value}
      description=""
      help={metric.helpTooltipText}
    />
  );
}

function ResidentMemory({ report, metric }: Props): ReactElement {
  const summary = report.summary as ResidentMemoryUsageMetricReportSummary;
  const value = formatDataSize(
    summary.currentUsageInBytes,
    metric.tooltipDataSizePointFormat
  );
  const description = getSummaryBytesDeltaText(
    summary.deltaUsageInBytes,
    metric.query.timePeriod
  );
  return (
    <TotalSummary
      title={metric.title}
      value={value}
      description={description}
      help={metric.helpTooltipText}
    />
  );
}

function SuccessfulQueries({ report, metric }: Props): ReactElement {
  const summary = report.summary as SuccessfulQueriesMetricReportSummary;
  assertTruthy(metric.seriesOptions, 'Invalid series data');
  const title1 = metric.seriesOptions[0].name ?? '';
  const title2 = metric.seriesOptions[1].name ?? '';
  const percentage1 = formatNumberToPercentage(summary.successRatePercent);
  const percentage2 = formatNumberToPercentage(summary.failRatePercent);

  return (
    <DeltaSummary
      title1={title1}
      title2={title2}
      value1={percentage1}
      value2={percentage2}
      color1={metric.seriesOptions[0].color}
      color2={metric.seriesOptions[1].color}
      help={metric.helpTooltipText}
    />
  );
}

function SelectQps({ report, metric }: Props): ReactElement {
  const summary = report.summary as SelectQpsMetricReportSummary;
  const value = formatDataSize(
    summary.totalSelects,
    metric.tooltipDataSizePointFormat,
    0
  );
  const description = getSummaryAvgText(
    'selects per second',
    summary.selectQueriesPerSecond,
    metric.tooltipDataSizePointFormat
  );

  return (
    <TotalSummary
      title="Total select queries"
      value={value}
      description={description}
      help={metric.helpTooltipText}
    />
  );
}

function ReadThroughput({ report, metric }: Props): ReactElement {
  const summary = report.summary as ReadThroughputMetricReportSummary;
  const value = formatDataSize(
    summary.totalBytesRead,
    metric.tooltipDataSizePointFormat
  );
  const description = getSummaryAvgText(
    'read size per second',
    summary.bytesReadPerSecond,
    metric.tooltipDataSizePointFormat
  );

  return (
    <TotalSummary
      title="Total read size"
      value={value}
      description={description}
      help={metric.helpTooltipText}
    />
  );
}

function InsertQPS({ report, metric }: Props): ReactElement {
  const summary = report.summary as InsertQpsMetricReportSummary;
  const value = formatDataSize(
    summary.totalInserts,
    metric.tooltipDataSizePointFormat,
    0
  );
  const description = getSummaryAvgText(
    'inserts per second',
    summary.insertQueriesPerSecond,
    metric.tooltipDataSizePointFormat
  );

  return (
    <TotalSummary
      title="Total insert queries"
      value={value}
      description={description}
      help={metric.helpTooltipText}
    />
  );
}

function WriteTroughput({ report, metric }: Props): ReactElement {
  const summary = report.summary as WriteThroughputMetricReportSummary;
  const value = formatDataSize(
    summary.totalBytesWritten,
    metric.tooltipDataSizePointFormat
  );
  const description = getSummaryAvgText(
    'write size per second',
    summary.bytesWrittenPerSecond,
    metric.tooltipDataSizePointFormat
  );

  return (
    <TotalSummary
      title="Total write size"
      value={value}
      description={description}
      help={metric.helpTooltipText}
    />
  );
}

function SqlStatementsPerType({ report, metric }: Props): ReactElement {
  const summary = report.summary as SqlStatementsPerTypeMetricReportSummary;
  assertTruthy(metric.seriesOptions, 'Invalid series data');
  const title1 = metric.seriesOptions[0].name ?? '';
  const title2 = metric.seriesOptions[1].name ?? '';
  const percentage1 = `${summary.selectsPercentPerPeriod.toFixed(2)}%`;
  const percentage2 = `${summary.insertsPercentPerPeriod.toFixed(2)}%`;

  return (
    <DeltaSummary
      title1={title1}
      title2={title2}
      value1={percentage1}
      value2={percentage2}
      color1={metric.seriesOptions[0].color}
      color2={metric.seriesOptions[1].color}
      help={metric.helpTooltipText}
    />
  );
}

function RowCount({ report, metric }: Props): ReactElement {
  const summary = report.summary as RowCountMetricReportSummary;
  assertTruthy(metric.seriesOptions, 'Invalid series data');
  const title1 = metric.seriesOptions[0].name ?? '';
  const title2 = metric.seriesOptions[1].name ?? '';
  const selectedRows = formatDataSize(
    summary.selectedRows,
    metric.tooltipDataSizePointFormat
  );
  const insertedRows = formatDataSize(
    summary.insertedRows,
    metric.tooltipDataSizePointFormat
  );

  return (
    <DeltaSummary
      title1={title1}
      title2={title2}
      value1={selectedRows}
      value2={insertedRows}
      color1={metric.seriesOptions[0].color}
      color2={metric.seriesOptions[1].color}
      help={metric.helpTooltipText}
    />
  );
}

export default function MetricSummary({ metric, report }: Props): ReactElement {
  const metricType = metric.query.type as MetricType;

  switch (metric.query.type) {
    case 'S3_STORAGE_USAGE':
      return <DataStored report={report} metric={metric} />;
    case 'ALLOCATED_MEMORY':
      return <AllocatedMemory report={report} metric={metric} />;
    case 'RESIDENT_MEMORY_USAGE':
      return <ResidentMemory report={report} metric={metric} />;
    case 'SUCCESSFUL_QUERIES':
      return <SuccessfulQueries report={report} metric={metric} />;
    case 'SELECT_QPS':
      return <SelectQps report={report} metric={metric} />;
    case 'READ_THROUGHPUT':
      return <ReadThroughput report={report} metric={metric} />;
    case 'INSERT_QPS':
      return <InsertQPS report={report} metric={metric} />;
    case 'WRITE_THROUGHPUT':
      return <WriteTroughput report={report} metric={metric} />;
    case 'SQL_STATEMENTS_PER_TYPE':
      return <SqlStatementsPerType report={report} metric={metric} />;
    case 'ROW_COUNT':
      return <RowCount report={report} metric={metric} />;

    default:
      throw new Error(`Unsupported metric type: ${metricType}`);
  }
}
