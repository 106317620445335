import { css, SerializedStyles, Theme } from '@emotion/react';

import { calculateHexColorAlpha } from 'src/components/primitives/lib/utils';

export const loadingStyle = (theme: Theme) => css`
  height: 100%;
  width: 100%;
  &[data-fullscreen='true'] {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
  }
  background: ${theme.colors.black}${calculateHexColorAlpha(0.8)};
  backdrop-filter: blur(2px);

  display: grid;
  place-items: center;
  -webkit-app-region: drag;
`;

export const loadingIconStyle = css`
  height: 75px;
  width: 75px;
`;

export const containerStyle = css`
  margin-top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-app-region: no-drag;
`;

export const suggestion = (theme: Theme): SerializedStyles =>
  css({
    marginTop: 25,
    marginBottom: 25,
    fontWeight: 500,
    color: theme.global.color.background.default,
    display: 'flex',
    '& > div': {
      marginLeft: 5,
      lineHeight: '30px'
    },
    span: {
      background: theme.global.color.text.default,
      border: `1px solid ${theme.global.color.text.muted}`,
      boxSizing: 'border-box',
      borderRadius: 4,
      padding: 6,
      margin: '0 5px',
      whiteSpace: 'nowrap'
    }
  });
