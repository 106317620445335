import {
  DeleteDataWarehouseRequest,
  ChangeDataWarehouseNameRequest,
  GetDataWarehouseSizeRequest,
  ChangeDataWarehouseNameResponse,
  UpdateWarehouseReleaseChannelRequest,
  ResetDataWarehousePasswordRequest
} from '@cp/common/protocol/DataWarehouse';
import { FAST_RELEASE_CHANNEL, ReleaseChannel } from '@cp/common/protocol/Instance';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import { makeDoubleSha1HashBrowser, makeSha256Base64Browser } from 'src/instance/instance';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const warehouseApiUrl = `${config.controlPlane.apiHost}/api/dataWarehouse`;

export interface DataWarehouseActionInput {
  dataWarehouseId: string;
  organizationId: string;
}

export interface StartFullMaintenanceInput extends DataWarehouseActionInput {
  runAfterMaintenance: boolean;
}

type DataWarehouseRequest =
  | DeleteDataWarehouseRequest
  | ChangeDataWarehouseNameRequest
  | GetDataWarehouseSizeRequest
  | UpdateWarehouseReleaseChannelRequest
  | ResetDataWarehousePasswordRequest;

export class DataWarehouseApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: DataWarehouseRequest, url = warehouseApiUrl): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async delete({ organizationId, dataWarehouseId }: DataWarehouseActionInput): Promise<void> {
    const request: DeleteDataWarehouseRequest = {
      rpcAction: 'delete',
      organizationId,
      dataWarehouseId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while deleting the service');
    }
  }

  async getWarehouseSize({ organizationId, dataWarehouseId }: DataWarehouseActionInput): Promise<number> {
    const request: GetDataWarehouseSizeRequest = {
      rpcAction: 'warehouseSize',
      organizationId,
      dataWarehouseId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while deleting the service');
    }
    const { size } = await response.json();
    return Number(size);
  }

  async rename({
    dataWarehouseId,
    organizationId,
    name
  }: {
    dataWarehouseId: string;
    organizationId: string;
    name: string;
  }): Promise<ChangeDataWarehouseNameResponse> {
    const request: ChangeDataWarehouseNameRequest = {
      rpcAction: 'rename',
      dataWarehouseId,
      organizationId,
      name
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }
    return await response.json();
  }

  async fastUpgrade({
    dataWarehouseId,
    organizationId
  }: Omit<UpdateWarehouseReleaseChannelRequest, 'rpcAction' | 'releaseChannel'>): Promise<void> {
    const releaseChannel = FAST_RELEASE_CHANNEL as ReleaseChannel;
    const request: UpdateWarehouseReleaseChannelRequest = {
      rpcAction: 'fastUpgrade',
      dataWarehouseId,
      organizationId,
      releaseChannel
    };

    const response = await this.rpcRequest(request);
    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }
  }

  async resetPassword({
    dataWarehouseId,
    password,
    organizationId
  }: {
    dataWarehouseId: string;
    password: string;
    organizationId: string;
  }): Promise<void> {
    const passwordHash = await makeSha256Base64Browser(password);
    const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
    const request: ResetDataWarehousePasswordRequest = {
      rpcAction: 'resetPassword',
      passwordHash,
      doubleSha1Password,
      organizationId,
      dataWarehouseId
    };

    const response = await this.rpcRequest(request);

    if (!response.ok) {
      throw new Error('Error while deleting the service');
    }
  }
}
