import { Address } from '@cp/common/protocol/Billing';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import config from 'src/lib/config';
import { Elements } from '@stripe/react-stripe-js';
import { ReactElement } from 'react';
import { BillingInfoForm } from 'src/components/BillingCardModal/BillingInfoForm';
import { useClientSecret } from 'src/billing/controllers/useClientSecret';
import { Container, Icon } from '@clickhouse/click-ui';

export type BillingStageProps = {
  organizationId: string;
  canUpdatePaymentMethod: boolean;
  billingAddress: Partial<Address> | undefined;
  onAdvance: () => Promise<void>;
  onBack: () => void;
};

const stripePromise = loadStripe(config.stripe.publishableKey);

export const BillingInfoWrapper = ({
  organizationId,
  canUpdatePaymentMethod,
  billingAddress,
  onAdvance,
  onBack
}: BillingStageProps): ReactElement => {
  const { clientSecret, isLoading, getClientSecret } = useClientSecret();
  if (clientSecret.clientSecret === '') {
    return (
      <Container
        fillWidth
        alignItems="center"
        justifyContent="center"
        orientation="vertical"
        padding="lg"
      >
        <Icon name="horizontal-loading" height="60px" width="60px" />
      </Container>
    );
  }

  const options: StripeElementsOptions = {
    clientSecret: clientSecret.clientSecret
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <BillingInfoForm
        onBack={onBack}
        onAdvance={onAdvance}
        organizationId={organizationId}
        canUpdatePaymentMethod={canUpdatePaymentMethod}
        billingAddress={billingAddress}
        clientSecret={clientSecret}
        getClientSecret={getClientSecret}
      />
    </Elements>
  );
};
