import {
  Alert,
  ConfirmationDialog,
  Container,
  Link,
  Spacer,
  Text as CUIText
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';

type FastUpgradeDialogProps = {
  dataWarehouseId?: string;
  isDialogOpen: boolean;
  onClose: () => void;
  onActivateClick: (props: { dataWarehouseId: string }) => Promise<void>;
};

export const FastUpgradeDialog = ({
  dataWarehouseId = '',
  isDialogOpen,
  onClose,
  onActivateClick
}: FastUpgradeDialogProps): ReactElement => {
  const [isSaving, setIsSaving] = useState(false);
  const onConfirm = async (): Promise<void> => {
    try {
      setIsSaving(true);
      await onActivateClick({ dataWarehouseId });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ConfirmationDialog
      open={isDialogOpen}
      title="Enroll in fast releases"
      onCancel={onClose}
      showClose
      primaryActionLabel="Activate fast release channel"
      onConfirm={onConfirm}
      loading={isSaving}
    >
      <CUIText color="muted">
        While we conduct extensive testing on all of our releases, we don’t
        recommend enrolling in the fast release channel when working with
        production environments.{' '}
        <Link
          href="https://clickhouse.com/docs/en/manage/updates#release-channels-upgrade-schedule"
          target="_blank"
        >
          Learn more
        </Link>
      </CUIText>
      <Spacer />
      <Container orientation="vertical" gap="xs">
        <Alert
          state="info"
          size="medium"
          text={
            <>
              Once subscribed to the fast release you cannot change to the
              regular release schedule.
            </>
          }
        />
        <Alert
          state="info"
          size="medium"
          text={<>This change might trigger a service restart.</>}
        />
      </Container>
    </ConfirmationDialog>
  );
};
