import { SVGAttributes } from 'react';

function SuccessRoundedIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="16" height="16" rx="8" fill="#CBEABC" />
      <path
        d="M11.2726 5.95459L7.31305 10.4798L5.33325 8.21722"
        stroke="#407B24"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessRoundedIcon;
