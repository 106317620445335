import { Text, Link } from '@clickhouse/click-ui';
import { Notification } from '@cp/common/protocol/Notifications';
import { memo, ReactElement, ReactNode } from 'react';
import { WrappedLink } from 'src/components/WrappedLink/WrappedLink';
import { routes } from 'src/lib/routes';

const ServiceRelatedLink = ({
  to,
  children,
  isDeleted
}: {
  to: string;
  children: ReactNode;
  isDeleted?: boolean;
}): ReactElement => {
  if (isDeleted) {
    return <>{children} (deleted) </>;
  }
  return <WrappedLink to={to}>{children}</WrappedLink>;
};

export const NotificationItemBody = memo(
  ({ notification }: { notification: Notification }): ReactElement => {
    switch (notification.name) {
      case 'service_vertical_autoscaling': {
        const payload = notification.payload;
        return (
          <Text>
            Service{' '}
            <ServiceRelatedLink
              to={routes.health({ serviceId: notification.resourceId })}
              isDeleted={notification.isServiceDeleted}
            >
              {notification.serviceName}
            </ServiceRelatedLink>
            has been scaled to {payload.recommended_memory_gb} GiB,{' '}
            {payload.recommended_cpu} vCPU per replica
          </Text>
        );
      }
      case 'service_vertical_autoscaling_clip_up': {
        return (
          <Text>
            Service{' '}
            <ServiceRelatedLink
              to={routes.health({ serviceId: notification.resourceId })}
              isDeleted={notification.isServiceDeleted}
            >
              {notification.serviceName}
            </ServiceRelatedLink>
            is at maximum configured CPU/memory capacity and can not be scaled
            up.{' '}
            {!notification.isServiceDeleted && (
              <>
                Configure service size in{' '}
                <ServiceRelatedLink
                  to={routes.serviceSettings({
                    serviceId: notification.resourceId
                  })}
                  isDeleted={notification.isServiceDeleted}
                >
                  settings
                </ServiceRelatedLink>
              </>
            )}
          </Text>
        );
      }
      case 'service_vertical_autoscaling_clip_down': {
        return (
          <Text>
            Service{' '}
            <ServiceRelatedLink
              to={routes.health({ serviceId: notification.resourceId })}
              isDeleted={notification.isServiceDeleted}
            >
              {notification.serviceName}
            </ServiceRelatedLink>
            is at minimum configured CPU/memory capacity and can not be scaled
            down.
            {!notification.isServiceDeleted && (
              <>
                Configure service size in{' '}
                <ServiceRelatedLink
                  to={routes.serviceSettings({
                    serviceId: notification.resourceId
                  })}
                  isDeleted={notification.isServiceDeleted}
                >
                  settings
                </ServiceRelatedLink>
              </>
            )}
          </Text>
        );
      }
      case 'billing_credit_passed_50pc_threshold': {
        return (
          <Text>
            You have consumed 50% of your ClickHouse Cloud credits. Please reach
            out to our{' '}
            <Link href="mailto:sales@clickhouse.com">sales team</Link>to discuss
            adding additional credits.
          </Text>
        );
      }
      case 'billing_credit_passed_75pc_threshold': {
        return (
          <Text>
            You have consumed 75% of your ClickHouse Cloud credits. Please reach
            out to our{' '}
            <Link href="mailto:sales@clickhouse.com">sales team</Link>to discuss
            adding additional credits.
          </Text>
        );
      }
      case 'billing_credit_passed_90pc_threshold': {
        return (
          <Text>
            You have consumed 90% of your ClickHouse Cloud credits. Please reach
            out to our{' '}
            <Link href="mailto:sales@clickhouse.com">sales team</Link>to discuss
            adding additional credits.
          </Text>
        );
      }
      case 'billing_payment_failed': {
        return (
          <Text>
            Payment failure! Please add a valid payment method on the{' '}
            <WrappedLink
              to={routes.billingHome({ orgId: notification.organizationId })}
            >
              billing page
            </WrappedLink>
            before {notification.payload.suspendDate} to prevent suspension of
            all services.
          </Text>
        );
      }
      default: {
        // @ts-expect-error default case can be reached if notification name changed or removed
        // Usually we need to do a migration for such cases, but to avoid breaking the app we just show the name
        return <Text>{notification.name}</Text>;
      }
    }
  }
);
