import { css } from '@emotion/react';

export const relative = css({ position: 'relative' });

export const mlAuto = css({ marginLeft: 'auto' });

export const gapSm = css({ gap: '8px' });
export const gapMd = css({ gap: '12px' });

export const mr10 = css({ marginRight: '10px' });
export const ml10 = css({ marginLeft: '10px' });

export const hidden = css({ display: 'none' });
export const flex = css({ display: 'flex' });
export const alignCenter = css({ alignItems: 'center' });

export const alignSelfTop = css({ alignSelf: 'flex-start' });

export const spaceBetween = css({ justifyContent: 'space-between' });
export const justifyCenter = css({ justifyContent: 'center' });

export const w100 = css({ width: '100%' });

export const relativeStyle = css({
  position: 'relative'
});
