import dayjs from 'dayjs';

export function prettifyTimeAgo(date: string): string {
  const fromDate = dayjs(date);
  const currentDate = dayjs();
  const daysAgo = currentDate.diff(fromDate, 'days');

  if (daysAgo === 0) {
    return 'Today';
  } else if (daysAgo === 1) {
    return 'Yesterday';
  } else if (daysAgo > 1 && daysAgo < 7) {
    return 'This Week';
  } else {
    return `${daysAgo} days ago`;
  }
}
