import { Button, Container, Icon, Text, Title } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import PlusIcon from 'src/components/icons/PlusIcon';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

interface NoKeysProps {
  onCreatePrivateEndpoint: () => void;
}

export default function NoPrivateEndpoints({
  onCreatePrivateEndpoint
}: NoKeysProps): ReactElement {
  const hasManagePrivateEndpointsPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:service:manage-private-endpoints',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  return (
    <Container
      alignItems="center"
      justifyContent="center"
      orientation="vertical"
      fillWidth={false}
    >
      <Container
        alignItems="center"
        gap="md"
        orientation="vertical"
        fillWidth={false}
      >
        <Icon
          name="arrow-directions"
          size="xxl"
          strokeWidth="0.5"
          vectorEffect="non-scaling-stroke"
        />
        <Title type="h4">Create your first private endpoint</Title>
        <Text align="center">
          Create and manage private endpoints that can be used by
          <br />
          the services within this organization
        </Text>
        <Button
          onClick={onCreatePrivateEndpoint}
          disabled={!hasManagePrivateEndpointsPermission}
        >
          <PlusIcon /> Add new endpoint
        </Button>
      </Container>
    </Container>
  );
}
