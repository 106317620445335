import { ReactElement } from 'react';
import styled from 'styled-components';
import { Container, Link, Separator, Text, Title } from '@clickhouse/click-ui';
import { SupportParams, routes } from 'src/lib/routes';
import {
  GETTING_STARTED_OPTIONS,
  GettingStartedOptionType
} from 'src/pages/GettingStartedPage/gettingStartedOptions';
import { GettingStartedSteps } from '@cp/common/protocol/Instance';
import { Galaxy } from 'galaxy';

const SidebarContainer = styled(Container)`
  padding-left: 0;
`;
interface GettingStartedSidebarProps {
  type: keyof GettingStartedSteps;
  serviceId?: string;
  isServiceCreated: boolean;
}

const Video = styled.iframe`
  aspect-ratio: 16/9;
  border-radius: 8px;
  width: 100%;
  height: fit-content;
`;

const GettingStartedOption = ({
  type,
  href,
  text,
  onClickTrack
}: GettingStartedOptionType & {
  onClickTrack: () => void;
}): ReactElement => {
  if (type === 'video') {
    return (
      <Video
        src={href}
        width="360"
        height="202"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        onClick={onClickTrack}
      />
    );
  }
  return (
    <Link href={href} size="sm" target="_blank" onClick={onClickTrack}>
      {text}
    </Link>
  );
};

const params: SupportParams = {
  subject: 'Expert Session request',
  severity: 'SEV_3'
};

export const GettingStartedSidebar = ({
  type,
  serviceId,
  isServiceCreated
}: GettingStartedSidebarProps): ReactElement => {
  const trackEventContactUs = (): void => {
    Galaxy.galaxy().track('gettingStartedPage.sidebar.contactUsButtonClicked', {
      interaction: 'click'
    });
  };

  const onSidebarItemClickTrack = (): void => {
    Galaxy.galaxy().track('gettingStartedPage.sidebar.sidebarItemClicked', {
      interaction: 'click'
    });
  };

  return (
    <SidebarContainer
      orientation="vertical"
      padding="lg"
      isResponsive={false}
      gap="sm"
    >
      <Title type="h3" size="sm" color="muted">
        {type === 'createService' && !isServiceCreated
          ? 'WHILE YOU’RE WAITING...'
          : 'DIVE DEEPER'}
      </Title>
      <Container isResponsive={false} orientation="vertical" gap="xs">
        {GETTING_STARTED_OPTIONS[type].map((option) => (
          <GettingStartedOption
            key={option.href}
            {...option}
            onClickTrack={onSidebarItemClickTrack}
          />
        ))}
      </Container>
      {type !== 'createService' && (
        <>
          <Separator size="sm" />
          <Container orientation="vertical" isResponsive={false} gap="xxs">
            <Title type="h5" size="sm">
              Free consultative support
            </Title>
            <Text component="div" size="sm" weight="medium">
              Having trouble ingesting data or properly setting up your
              environment? Do you need help migrating your data from another
              database? We’re here to help.{' '}
              <Link
                href={routes.newSupportCase({ serviceId, params })}
                size="sm"
                weight="medium"
                onClick={trackEventContactUs}
              >
                Contact us
              </Link>
            </Text>
          </Container>
        </>
      )}
    </SidebarContainer>
  );
};
