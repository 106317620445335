import { Draft } from 'immer';
import { css } from '@emotion/react';
import { ReactElement, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Icon,
  Panel,
  TextField,
  Select,
  Switch,
  Text,
  Spacer
} from '@clickhouse/click-ui';

import { humanFileSize } from 'src/lib/bytesUtils';
import { useTables } from 'src/metadata/metadataState';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useApiClient } from 'src/lib/controlPlane/client';
import { DataPreviewer } from 'src/lib/dataLoading/DataPreviewer';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import { useClickPipeCustomDelimiter } from 'src/lib/dataLoading/feature';
import { convertSampleSchemaToColumns } from 'src/lib/dataLoading/clickpipes/samples';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';
import {
  ClickPipeObjectStorageImport,
  objectStorageFormats
} from 'shared/src/dataLoading';
import {
  ObjectStorageSourceListing,
  SampleSchemaObjectStorageRequest
} from 'types/protocol';

const fileContainerStyle = css({
  display: 'flex',
  width: '100%',
  alignContent: 'center'
});

const fileNameStyle = css({
  flexGrow: 1,
  padding: '0 10px'
});

const fileListStyle = css({
  maxHeight: '120px',
  paddingLeft: '0.5rem',
  margin: 0,
  width: '100%',
  overflowY: 'scroll'
});

function noop(): void {}

function isCSVType(format: string): boolean {
  return format === 'CSV' || format === 'CSVWithNames';
}

export function ObjectStorageSourceListing(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [fileListing, setFileListing] = useState<
    ObjectStorageSourceListing | undefined
  >(undefined);
  const isCustomDelimiterEnabled = useClickPipeCustomDelimiter();

  const [error, setError] = useState<string | null>(null);
  const { importModel, updateImportModel, setTableConfig } =
    useClickPipesImport<ClickPipeObjectStorageImport>();
  const tables = useTables();
  const api = useApiClient();
  const database = useSelectedDatabaseValue();
  const serviceId = useCurrentInstanceId() ?? '';

  useEffect(() => {
    async function updateFileListing(): Promise<void> {
      if (!serviceId) {
        return;
      }
      setLoading(true);
      setError(null);

      try {
        const response = await api.sourceListing(
          {
            id: importModel.id,
            type: importModel.data.type,
            path: importModel.data.path,
            auth: importModel.data.auth
          },
          serviceId
        );

        setFileListing(response);
      } catch (error) {
        setError(errorMessage(error));
        setFileListing(undefined);
      } finally {
        setLoading(false);
      }
    }
    if (importModel.data.path) {
      updateFileListing().catch(setError);
    } else {
      setFileListing(undefined);
    }
  }, [importModel.id, importModel.data.path]);

  const setContinuousIngestion = (): void => {
    updateImportModel((model: Draft<ClickPipeObjectStorageImport>) => {
      model.data.useContinuousIngestion = !model.data.useContinuousIngestion;
    });
  };

  const back = (): void => {
    updateImportModel((draft: Draft<ClickPipeObjectStorageImport>) => {
      draft.data.step = 1;
    });
  };

  const next = (): void => {
    if (loading) {
      return;
    }

    async function updateSample(): Promise<void> {
      setLoading(true);
      setError(null);
      try {
        const req: SampleSchemaObjectStorageRequest = {
          id: importModel.id,
          type: importModel.data.type,
          auth: importModel.data.auth,
          path: importModel.data.path,
          format: importModel.data.format,
          delimiter: importModel.data.delimiter
        };
        const { schema, sampleValues } = await api.getSampleSchema(
          req,
          serviceId
        );

        updateImportModel((draft: Draft<ClickPipeObjectStorageImport>) => {
          draft.data.sourceSchema = [...schema];
          draft.data.rowsPreview = sampleValues;
        });
        const dataPreviewer = new DataPreviewer(
          api,
          serviceId,
          tables,
          database
        );
        const tableConfig = dataPreviewer.generateTableConfig(
          'newTable',
          `s3-${importModel.id}`,
          convertSampleSchemaToColumns(schema)
        );
        setTableConfig(tableConfig);

        updateImportModel((draft: Draft<ClickPipeObjectStorageImport>) => {
          draft.data.step = 3;
        });
      } catch (error) {
        setError(errorMessage(error));
      } finally {
        setLoading(false);
      }
    }

    void updateSample();
  };

  function setFormat(value: string): void {
    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      setError(null);
      model.data.format = value;
    });
  }

  function setDelimiter(value: string): void {
    updateImportModel<Draft<ClickPipeObjectStorageImport>>((model) => {
      setError(null);
      model.data.delimiter = value;
    });
  }

  return (
    <Container orientation="vertical" gap="md" maxWidth="800px">
      <Container>
        <TextField
          disabled
          label="File paths"
          name="path"
          value={importModel.data.path}
          onChange={noop}
          placeholder="path"
          data-testid="object-storage-path"
          className="fs-exclude"
        />
      </Container>

      <TwoColsContainer>
        <Select
          label="File type"
          onSelect={setFormat}
          value={importModel.data.format}
          data-testid="object-storage-format"
        >
          {objectStorageFormats.map((format: string) => (
            <Select.Item value={format} key={format}>
              {format}
            </Select.Item>
          ))}
        </Select>

        {isCustomDelimiterEnabled && isCSVType(importModel.data.format) && (
          <Container>
            <TextField
              label={'Specify delimiter'}
              name={'delimiter'}
              value={importModel.data.delimiter}
              onChange={setDelimiter}
              maxLength={1}
              placeholder={','}
              data-testid={'object-storage-delimiter'}
              className={'fs-exclude'}
            />
          </Container>
        )}
      </TwoColsContainer>

      <Panel
        alignItems="start"
        color="muted"
        data-testid="large-file-warning"
        fillWidth
      >
        <Icon name="information" />
        <Text size="md">
          Only the first 25 matching files are being displayed. Files larger
          than 10 GB are going to be skipped.
        </Text>
      </Panel>
      {fileListing && (
        <Panel
          alignItems="start"
          color="default"
          hasBorder
          height=""
          orientation="vertical"
          padding="sm"
          radii="sm"
          width="100%"
          data-testid="object-file-paths"
        >
          <ul css={fileListStyle}>
            {fileListing.items.map((file) => (
              <li key={file.name} css={fileContainerStyle}>
                <Icon name="document" size="xs" />
                <Text size="md" css={fileNameStyle}>
                  {file.name}
                </Text>
                <Text size="md">{humanFileSize(file.size)}</Text>
              </li>
            ))}
          </ul>
        </Panel>
      )}

      <Panel
        alignItems="start"
        color="default"
        hasBorder
        height=""
        orientation="vertical"
        padding="sm"
        radii="sm"
        width="100%"
      >
        <Switch
          label="Continuous ingestion"
          orientation="horizontal"
          dir="end"
          data-testid="continuous-ingestion-enabled"
          onClick={setContinuousIngestion}
          checked={importModel.data.useContinuousIngestion}
        />
      </Panel>
      {error && (
        <Alert
          showIcon
          size="medium"
          state="danger"
          text={error}
          title="Exception during reading."
        />
      )}
      <Spacer size="xs" />
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          Back
        </Button>
        <Button
          onClick={next}
          loading={loading}
          disabled={Boolean(loading || error || !importModel.data.path)}
          data-testid="incoming-data-next-step"
        >
          Next: Parse Information
        </Button>
      </Container>
    </Container>
  );
}
