import { Address } from '@cp/common/protocol/Billing';
import { STATES_SELECT } from 'src/billing/common/stateSelection';

export const isValidState = (country: string | undefined, state: string | undefined): boolean => {
  const stateAvailable = country !== undefined && STATES_SELECT[country]?.length > 0;
  const stateValid =
    (stateAvailable && state !== undefined && STATES_SELECT[country]?.filter((s) => s.value === state).length > 0) ||
    (!stateAvailable && state === undefined);
  return stateValid;
};

export const validateAddress = (partialAddress: Partial<Address> | undefined): boolean => {
  return !!(
    partialAddress &&
    partialAddress.line1 &&
    partialAddress.city &&
    partialAddress.country &&
    partialAddress.postalCode &&
    isValidState(partialAddress.country, partialAddress.state)
  );
};
