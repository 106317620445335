import { Dropdown, Icon, Text as CUIText } from '@clickhouse/click-ui';
import {
  DEFAULT_RELEASE_CHANNEL,
  ReleaseChannel
} from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import styled from 'styled-components';

const DeleteDropdownItem = styled(Dropdown.Item)`
  color: ${({ theme }): string =>
    theme.global.color.feedback.danger.foreground};
`;

export const DataWarehouseActionMenu = ({
  openRenameWarehouse,
  openDeleteWarehouse,
  onFastUpgrade,
  releaseChannel,
  openResetDB,
  version
}: {
  openRenameWarehouse: () => void;
  openDeleteWarehouse: () => void;
  onFastUpgrade: () => void;
  openResetDB: () => void;
  version: string;
  releaseChannel?: ReleaseChannel;
}): ReactElement => {
  const showEnrollButton =
    releaseChannel === undefined || releaseChannel === DEFAULT_RELEASE_CHANNEL;

  return (
    <Dropdown>
      <Dropdown.Trigger data-testid="warehouse-menu-button">
        <Icon name="dots-vertical" />
      </Dropdown.Trigger>
      <Dropdown.Content>
        <Dropdown.Item onClick={openRenameWarehouse}>
          Rename warehouse
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!showEnrollButton}
          data-testid="current-warehouse-version"
          onClick={onFastUpgrade}
        >
          {showEnrollButton ? 'Enroll fast upgrades' : ' Fast upgrade enabled'}
          <CUIText color="muted">Current version: {version}</CUIText>
        </Dropdown.Item>
        <Dropdown.Item onClick={openResetDB}>Reset DB password</Dropdown.Item>
        <DeleteDropdownItem onClick={openDeleteWarehouse}>
          Delete warehouse
        </DeleteDropdownItem>
      </Dropdown.Content>
    </Dropdown>
  );
};
