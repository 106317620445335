import { css } from '@emotion/react';

export default {
  container: css`
    width: 100%;
    padding: 20px;
    flex: 1;
    overflow: overlay;
    @supports not (overflow: overlay) {
      overflow: auto;
    }
  `,
  cardListContainer: css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 25px;
    max-width: 705px;
  `,
  fitContentWidth: css({
    width: 'fit-content'
  })
};
