import { getCurrentServiceId } from 'src/state/service';
import {
  useSelectedTab,
  useSelectedTabIndex,
  useTabActions,
  useUpdateTab
} from 'src/state/tabs';

import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';

import { InfoIcon } from 'src/components/QueryView/VersionConflictBar/InfoIcon';
import {
  versionMismatchBar,
  versionMismatchButtons,
  versionMismatchIcon,
  versionMismatchMessage
} from 'src/components/QueryView/VersionConflictBar/styles';
import { Button } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

export function QueryDeletedBar(): ReactElement {
  const { setSelectedTab, useCloseTab } = useTabActions();
  const tabIndex = useSelectedTabIndex();
  const closeTab = useCloseTab();
  const tab = useSelectedTab();
  const { createQuery } = useSavedQueries();
  const updateTab = useUpdateTab();

  const discard = (): void => {
    closeTab(tabIndex);
  };

  const resave = async (): Promise<void> => {
    const serviceId = getCurrentServiceId();
    if (tab && tab.type === 'query' && serviceId) {
      const result = await createQuery({
        id: tab.queryId,
        name: tab.title ?? 'Untitled query',
        query: tab.query,
        database: tab.database ?? 'default'
      });
      if (result) {
        const update = {
          updatedAt: result.updatedAt,
          title: result.name
        };
        updateTab(tab.id, update);
        setSelectedTab({ ...tab, ...update });
      }
    }
  };

  return (
    <div css={versionMismatchBar}>
      <div css={versionMismatchMessage}>
        <div css={versionMismatchIcon}>
          <InfoIcon />
        </div>
        This query has been deleted.
      </div>
      <div css={versionMismatchButtons}>
        <Button type="secondary" onClick={discard}>
          Discard Query
        </Button>
        <Button type="secondary" onClick={resave}>
          Re-save Query
        </Button>
      </div>
    </div>
  );
}
