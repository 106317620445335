import {
  Container,
  Link,
  Select,
  SelectOptionListItem,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import {
  InstanceDatabaseAccessMapping,
  InstanceDatabaseAccessType,
  isInstanceDatabaseAccessType
} from '@cp/common/protocol/Instance';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import { ReactElement, useCallback, useMemo } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import {
  useCurrentInstance,
  useInstanceController
} from 'src/instance/instanceController';
import {
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';

const ROLE_DISPLAY_NAMES: Record<OrganizationRole, string> = {
  DEVELOPER: 'Developer',
  ADMIN: 'Admin'
};

const accessTypeOptions: Array<SelectOptionListItem> = [
  {
    label: 'No access',
    value: 'NO_ACCESS'
  },
  {
    label: 'Read only',
    value: 'READ_ONLY'
  },
  {
    label: 'Full access',
    value: 'FULL_ACCESS'
  }
];

interface RoleMapping extends InstanceDatabaseAccessMapping {
  displayName: string;
  userCount: number;
}

function useInstanceRoleMappings(): Array<RoleMapping> {
  const organization = useCurrentOrganizationOrThrow();
  const users = Object.values(organization.users);
  const instance = useCurrentInstance();

  return useMemo(() => {
    const instanceMappings = instance?.defaultDatabaseRoleMappings ?? [];
    return instanceMappings.map((mapping) => {
      const userCount = users.reduce(
        (count, user) => (user.role === mapping.cpRole ? count + 1 : count),
        0
      );

      return {
        ...mapping,
        displayName: roleDisplayName(mapping.cpRole),
        userCount
      };
    });
  }, [users, instance?.defaultDatabaseRoleMappings]);
}

function roleDisplayName(role: string): string {
  return ROLE_DISPLAY_NAMES[role as OrganizationRole] ?? role;
}

function formatUserCount(count: number): string {
  if (count === 1) {
    return `${count} user`;
  } else {
    return `${count} users`;
  }
}

interface AccessTypeProps {
  mapping: RoleMapping;
  updateMapping: (
    role: OrganizationRole,
    access: InstanceDatabaseAccessType
  ) => void;
  hasManageDBRoleMappingPermission: boolean;
}

function AccessType({
  mapping,
  updateMapping,
  hasManageDBRoleMappingPermission
}: AccessTypeProps): ReactElement {
  const onChange = useCallback(
    (newValue: string) => {
      if (isInstanceDatabaseAccessType(newValue)) {
        updateMapping(mapping.cpRole, newValue);
      }
    },
    [updateMapping, mapping.cpRole]
  );

  return (
    <Container data-testid={`db-access-${mapping.cpRole}`}>
      <Select
        label={`${mapping.displayName} (${formatUserCount(mapping.userCount)})`}
        value={mapping.databaseAccess ?? 'NO_ACCESS'}
        onSelect={onChange}
        options={accessTypeOptions}
        data-testid={`databaseAccessSelect-${mapping.cpRole}`}
        disabled={!hasManageDBRoleMappingPermission}
      />
    </Container>
  );
}

export function DbAccessSection(): ReactElement | null {
  const hasManageDBRoleMappingPermission =
    useUserAndOrgRolesHasPermissionForInstance(
      'control-plane:service:manage-db-role-mapping',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const { updateInstanceDbRoleMapping } = useInstanceController();
  const instance = useCurrentInstance();
  const roleMappings = useInstanceRoleMappings();

  const updateMapping = useCallback(
    (role: OrganizationRole, access: InstanceDatabaseAccessType) => {
      if (instance?.id) {
        const newMappings = roleMappings.map((mapping) => ({
          cpRole: mapping.cpRole,
          databaseAccess:
            role === mapping.cpRole ? access : mapping.databaseAccess
        }));
        updateInstanceDbRoleMapping({
          instanceId: instance.id,
          organizationId: instance.organizationId,
          mappings: newMappings
        }).catch((e) => {
          console.error(e);
        });
      }
    },
    [
      updateInstanceDbRoleMapping,
      instance?.id,
      instance?.organizationId,
      roleMappings
    ]
  );

  return (
    <Subsection justifyContent="space-between">
      <SubsectionDescription>
        <SubsectionTitle>SQL console access</SubsectionTitle>
        <Spacer size="sm" />
        <Text color="muted">
          Control the permissions and access that different user roles are
          entitled to when using the SQL console.{' '}
          <Link
            href="https://clickhouse.com/docs/en/cloud/security/cloud-access-management"
            target="_blank"
          >
            Learn more
          </Link>
        </Text>
      </SubsectionDescription>

      <SubsectionControls>
        <Container gap="md" orientation="vertical">
          {roleMappings.map((mapping) => (
            <AccessType
              key={mapping.cpRole}
              mapping={mapping}
              updateMapping={updateMapping}
              hasManageDBRoleMappingPermission={
                hasManageDBRoleMappingPermission
              }
            />
          ))}
        </Container>
      </SubsectionControls>
    </Subsection>
  );
}
