import React, { FC, ReactElement, useMemo, useState } from 'react';

import {
  DragDropContext as _DragDropContext,
  Draggable as _Draggable,
  DragStart,
  Droppable as _Droppable,
  DropResult,
  DragDropContextProps,
  DroppableProps,
  DraggableProps
} from 'react-beautiful-dnd';

import styles from 'src/components/App/MainView/TabBar/styles';
import Tab from 'src/components/App/MainView/TabBar/Tab';
import ScrollTo from 'src/components/App/MainView/TabBar/Tabs/ScrollTo';
import { RIGHT_MOUSE_BTN } from 'src/lib/mouseBtns';
import { useSetTabs, useTabActions, useUpdateTab } from 'src/state/tabs';
import { Tab as TabType } from 'src/state/tabs/types';

import {
  LogFn,
  MenuOption,
  StatusType
} from 'src/components/App/MainView/TabBar/types';

interface Props {
  tabs: TabType[];
  selectedTabIndex: number;
  onTabClick: (idx: number, tab?: TabType) => void;
  onTabClose: (idx: number, tab?: TabType) => void;
  getMenuOptions: (type: TabType['type'], index: number) => Array<MenuOption>;
  getStatus: (tab: TabType) => StatusType;
  log: LogFn;
  containerWidth?: number;
}
const DragDropContext = _DragDropContext as unknown as FC<DragDropContextProps>;
const Droppable = _Droppable as unknown as FC<DroppableProps>;
const Draggable = _Draggable as unknown as FC<DraggableProps>;
export default function Tabs({
  tabs,
  selectedTabIndex,
  onTabClick,
  onTabClose,
  getMenuOptions,
  getStatus,
  log,
  containerWidth
}: Props): ReactElement {
  const { setSelectedTabIndex } = useTabActions();
  const [draggedElementId, setDraggedElementId] = useState<string | null>();
  const setTabs = useSetTabs();
  const updateTab = useUpdateTab();

  const reorderItems = (startIndex: number, endIndex: number): void => {
    const reorderedTabs = [...tabs];
    const [removed] = reorderedTabs.splice(startIndex, 1);
    reorderedTabs.splice(endIndex, 0, removed);

    setTabs(reorderedTabs);
    setSelectedTabIndex(endIndex);
  };

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    reorderItems(result.source.index, result.destination.index);
  };

  const onWheel = (e: React.WheelEvent<HTMLDivElement>): void => {
    const delta = Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY;
    let pixelDelta = 0;
    if (e.deltaMode === 0) {
      pixelDelta = delta;
    } else if (e.deltaMode === 1) {
      pixelDelta = delta / 10;
    }
    e.currentTarget.scrollLeft += pixelDelta;
  };

  const disambiguatedNames = useMemo(() => {
    const nameOccurrences = new Map<string | undefined, number>();

    tabs.forEach((tab) => {
      const title = tab.title;
      const count = nameOccurrences.get(title) ?? 0;
      nameOccurrences.set(title, count + 1);
    });

    const names = new Map<string, string | undefined>();

    tabs.forEach((tab) => {
      if (tab.type === 'table') {
        const duplicates = nameOccurrences.get(tab.title) ?? 0;
        if (duplicates > 1 && tab.table.schema) {
          names.set(tab.id, `${tab.table.schema}.${tab.table.tableName}`);
        } else {
          names.set(tab.id, tab.title);
        }
      } else {
        names.set(tab.id, tab.title);
      }
    });

    return names;
  }, [tabs]);

  const tabsLength = tabs.length;

  return (
    <DragDropContext
      onDragStart={(result: DragStart): void => {
        setDraggedElementId(result.draggableId);
      }}
      onDragEnd={(result: DropResult): void => {
        const tabIndex = result.source.index;
        if (tabIndex && tabs[tabIndex]) {
          log(tabs[tabIndex].type, 'rearrangeDrag', 'trigger');
        }
        onDragEnd(result);
        setDraggedElementId(null);
      }}
    >
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            css={styles.tabsContainer}
            ref={provided.innerRef}
            onWheel={onWheel}
            {...provided.droppableProps}
          >
            {tabs.map((tab, index: number) => (
              <Draggable
                draggableId={`tab-bar-${index}`}
                index={index}
                key={`tab-bar-${index}`}
              >
                {(provided) => (
                  <ScrollTo
                    ref={provided.innerRef}
                    selected={selectedTabIndex === index}
                    css={styles.scrollToContainer(
                      containerWidth ? containerWidth / tabsLength : 200
                    )}
                    data-dragging={draggedElementId === `tab-bar-${index}`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div
                      ref={provided.innerRef}
                      css={styles.tabContainer}
                      data-run-at={tab.type === 'query' ? tab.lastRunAt : ''}
                      data-edited-at={tab.type === 'query' ? tab.editedAt : ''}
                    >
                      <Tab
                        key={tab.id}
                        {...tab}
                        title={disambiguatedNames.get(tab.id)}
                        onClick={(e): void => {
                          if (e.button !== RIGHT_MOUSE_BTN) {
                            log(tab.type, 'focus');
                            onTabClick(index, tab);
                          }
                        }}
                        onDoubleClick={(): void => {
                          updateTab(tab.id, {
                            preview: false
                          });
                        }}
                        onClose={(
                          e: React.MouseEvent<HTMLElement, MouseEvent>
                        ): void => {
                          e.preventDefault();
                          e.stopPropagation();
                          onTabClose(index, tab);
                        }}
                        selected={index === selectedTabIndex}
                        menuOptions={getMenuOptions(tab?.type, index)}
                        status={getStatus(tab)}
                        log={log}
                      />
                    </div>
                  </ScrollTo>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
