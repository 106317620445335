import { Container, Spacer } from '@clickhouse/click-ui';
import {
  NOTIFICATION_INVENTORY,
  NotificationCategory,
  NotificationName,
  NotificationType
} from '@cp/common/protocol/NotificationInventory';
import { ReactElement, useCallback } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { useSearchParam } from 'src/lib/routes/useSearchParam';
import { useNotificationsChannelSettings } from 'src/notifications/controller/useNotificationsChannelSettings';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';
import { SettingsFlyout } from 'src/pages/NotificationsPage/Settings/SettingsFlyout';
import { SettingsTable } from 'src/pages/NotificationsPage/Settings/SettingsTable';

const NOTIFICATIONS_BY_CATEGORY = Object.keys(NOTIFICATION_INVENTORY).reduce(
  (acc, notificationName) => {
    const notification =
      NOTIFICATION_INVENTORY[notificationName as NotificationName];
    if (!acc[notification.category]) {
      acc[notification.category] = {};
    }
    acc[notification.category][notificationName as NotificationName] =
      notification;

    return acc;
  },
  {} as Record<
    NotificationCategory,
    Partial<{
      [K in NotificationName]: NotificationType;
    }>
  >
);

export const SettingsTab = (): ReactElement => {
  const [selectedNotification, setSelectedNotification] = useSearchParam(
    'notification',
    '',
    { type: 'string' }
  );

  const isFlyoutOpen = !!selectedNotification;

  const selectedNotificationType =
    NOTIFICATION_INVENTORY[selectedNotification as NotificationName];
  const organization = useCurrentOrganizationOrThrow();

  const { isLoading, currentSettings, settings, onSettingChange, onSubmit } =
    useNotificationsChannelSettings(organization.id);

  const onFlyoutClose = useCallback(() => {
    setSelectedNotification('');
  }, [setSelectedNotification]);

  const onSave = useCallback(async () => {
    if (!selectedNotification) {
      return;
    }
    await onSubmit(selectedNotification as NotificationName);
    onFlyoutClose();
  }, [onFlyoutClose, onSubmit, selectedNotification]);

  const hasManageBillingPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage-billing',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  return (
    <>
      <Container orientation="vertical">
        <Spacer size="lg" />
        <Container gap="sm" orientation="vertical">
          <SettingsTable
            notificationCategory="service"
            isLoading={isLoading}
            notificationTypes={NOTIFICATIONS_BY_CATEGORY.service}
            notificationsSettings={settings}
            selectNotification={setSelectedNotification}
          />

          {hasManageBillingPermission && (
            <>
              <Spacer size="md" />
              <SettingsTable
                notificationCategory="billing"
                isLoading={isLoading}
                notificationsSettings={settings}
                notificationTypes={NOTIFICATIONS_BY_CATEGORY.billing}
                selectNotification={setSelectedNotification}
              />
            </>
          )}
        </Container>
      </Container>
      <SettingsFlyout
        isFlyoutOpen={isFlyoutOpen}
        selectedNotificationType={selectedNotificationType}
        setting={currentSettings?.[selectedNotification as NotificationName]}
        onSettingChange={onSettingChange}
        onSaveSettings={onSave}
        isLoading={isLoading}
        selectedNotificationName={selectedNotification as NotificationName}
        onClose={onFlyoutClose}
      />
    </>
  );
};
