import { RpcRequest } from '@cp/common/utils/ProtocolUtils';

export const DRIFT_API_PATH = 'drift';

export type GetDriftIdentityJwtRequest = RpcRequest<'getDriftIdentityJwt'>;

export interface GetDriftIdentityJwtResponse {
  userJwt: string;
  /** Expiration timestamp in seconds. */
  expiresIn: number;
}

export type DriftRpcAction = 'getDriftIdentityJwt';
