import {
  Container,
  Dialog,
  Link,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import {
  DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS,
  STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS
} from '@cp/common/environment/CommonEnvironment';
import { ReactElement } from 'react';

interface MarketplaceCancellationModalProps {
  open: boolean;
  onContactSupport: () => void;
  onClose: () => void;
}

function MarketplaceCancellationModal({
  open,
  onContactSupport,
  onClose
}: MarketplaceCancellationModalProps): ReactElement | null {
  if (open !== true) {
    return null;
  }

  return (
    <Dialog open={open}>
      <Dialog.Content
        title="Oops ! It seems like you are unsubscribed from ClickHouse Cloud."
        showClose={true}
        onClose={onClose}
      >
        <Text>
          Your ClickHouse Cloud Subscription was terminated. If you would like
          to resubscribe, please navigate to your marketplace account and
          reactive your subscription.
        </Text>
        <Spacer size="md" />
        <Text color="muted">
          Services will be automatically suspended after{' '}
          {STOP_SERVICES_FOR_DQ_ORGS_THRESHOLD_IN_DAYS - 1} days of nonpayment.
          Services and data will be permanently deleted after{' '}
          {DECOMMISSION_DQ_ORGS_THRESHOLD_IN_DAYS} days. If you need more time
          to resolve the payment issues or you think you’re receiving this
          message in error, please contact our{' '}
          <Link href="mailto:sales@clickhouse.com">sales team</Link>.
        </Text>

        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close
            label="Contact support"
            type="primary"
            onClick={onContactSupport}
          />
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

export default MarketplaceCancellationModal;
