import React, { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import { routes } from 'src/lib/routes';
import { getNewUrlImportModel, useImportModel } from 'src/lib/dataLoading';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import PanelCard from 'src/components/ImportView/ImportTypeSelector/PanelCard';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { Galaxy } from 'galaxy';

function FileUrlCard(): ReactElement | null {
  const { importModel, setImportModel } = useImportModel();

  if (!importModel) {
    return null;
  }

  const currentServiceId = getCurrentServiceIdOrFail();

  const selectUrlType = (): void => {
    Galaxy.galaxy().track('dataLoading.importTypeContainer.filUrlSelect', {
      interaction: 'click'
    });
    const newImportModel = getNewUrlImportModel(importModel.id || uuid());
    setImportModel(newImportModel);

    navigateTo(
      routes.import({
        serviceId: currentServiceId,
        importId: importModel.id
      })
    );
  };

  return (
    <PanelCard
      title="File URL"
      subTitle="JSON, CSV or TSV"
      iconName="url"
      onClick={selectUrlType}
      className="fileUrlCard"
    />
  );
}

export default React.memo(FileUrlCard);
