import { ReactElement } from 'react';
import { OptionProps } from 'src/components/CreateTableView/ColumnOptions/types';
import { TextField } from '@clickhouse/click-ui';

function ColumnDefaultValue({ column, change }: OptionProps): ReactElement {
  return (
    <TextField
      disabled={column.removed}
      value={column.defaultExpression}
      onChange={(defaultExpression: string): void => {
        change({ defaultExpression });
      }}
    />
  );
}

export default ColumnDefaultValue;
