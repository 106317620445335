import React from 'react';

export const OnBoardArrowSvg = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
): React.ReactElement => {
  return (
    <svg
      width="209"
      height="146"
      viewBox="0 0 209 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M196.283 119.883C199.335 117.77 201.684 115.762 203.331 113.86L203.326 113.846C203.641 113.76 203.956 113.674 204.27 113.587C205.282 113.304 206.188 112.719 206.867 111.906C208.392 110.083 208.847 108.342 208.234 106.682C207.888 105.746 207.202 104.835 206.175 103.951L206.171 103.948C205.927 103.738 205.66 103.543 205.37 103.362L205.365 103.348C204.532 102.176 203.24 101.368 201.756 101.185C187.145 99.392 171.155 94.5635 153.787 86.6992C151.169 85.5144 148.1 86.6923 146.91 89.3382C146.311 90.6709 146.302 92.1245 146.773 93.3961C147.236 94.6497 148.165 95.7266 149.455 96.336C161.504 102.032 169.155 105.329 172.407 106.228L172.401 106.214C173.943 106.765 175.724 107.314 177.743 107.862L177.748 107.876C164.287 109.353 149.697 109.313 133.978 107.754L133.793 107.739C112.373 106.393 92.1215 100.602 73.0382 90.3658C72.9461 90.3163 72.8524 90.2696 72.7572 90.2257C45.2595 77.5558 26.9001 58.7517 17.6792 33.8133C14.4242 25.0099 12.3082 15.4428 11.3306 5.11084C11.2857 4.63527 11.1798 4.18032 11.0218 3.75302C10.2133 1.5665 8.03704 0.111448 5.63603 0.345385C2.76629 0.624879 0.663768 3.21158 0.939093 6.12288C2.02021 17.5475 4.35823 28.1217 7.9538 37.8462C18.0703 65.2067 38.1404 85.8391 68.1642 99.7421L68.1597 99.7299C88.5141 110.647 110.115 116.837 132.963 118.299L132.962 118.296C150.058 119.991 165.935 119.962 180.594 118.208L180.6 118.223C167.92 127.251 158.768 133.099 153.142 135.77C150.658 136.949 149.518 139.86 150.465 142.421C150.513 142.552 150.567 142.682 150.627 142.811C151.848 145.46 154.955 146.603 157.566 145.363C165.114 141.78 178.02 133.287 196.283 119.883Z"
        fill="currentColor"
      />
    </svg>
  );
};
