import React, { useState } from 'react';
import { IconButton, Text, Container } from '@clickhouse/click-ui';
import CopyButton from 'src/components/CopyButton';
import { PASSWORD_MASK } from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import styled from 'styled-components';

const BreakWordContainer = styled(Container)`
  word-break: break-all;
  align-self: center;
`;
interface PasswordDisplayProps {
  password: string;
  onToggle?: (open: boolean) => void;
}

const PasswordDisplay: React.FC<PasswordDisplayProps> = ({
  password,
  onToggle
}) => {
  const [show, setShow] = useState(false);

  const toggle = (): void => {
    const newShow = !show;
    setShow(newShow);
    if (typeof onToggle === 'function') {
      onToggle(newShow);
    }
  };

  return (
    <>
      <BreakWordContainer isResponsive={false} component={Text}>
        {show ? password : PASSWORD_MASK}
      </BreakWordContainer>
      <Container fillWidth={false} isResponsive={false} gap="xs">
        <IconButton
          icon={show ? 'eye-closed' : 'eye'}
          onClick={toggle}
          data-testid="password-display-eye-icon"
        />
        <CopyButton text={password} />
      </Container>
    </>
  );
};

export default PasswordDisplay;
