export class JSONLinesTransformStream {
  private bufferedString: string;
  private decoder: TextDecoder;
  private transformStream: TransformStream<Uint8Array, any>;

  constructor() {
    this.bufferedString = '';
    this.decoder = new TextDecoder('utf-8');
    this.transformStream = new TransformStream({
      transform: (chunk, controller) => this._transform(chunk, controller),
      flush: (controller) => this._flush(controller)
    });
  }

  private _transform(chunk: Uint8Array, controller: TransformStreamDefaultController<any>): void {
    this.bufferedString += this.decoder.decode(chunk, { stream: true });
    const lines = this.bufferedString.split(/\r?\n/);

    // Process all lines except the last one (it might be incomplete)
    for (let i = 0; i < lines.length - 1; i++) {
      if (lines[i].trim()) {
        try {
          const jsonObject = JSON.parse(lines[i]);
          controller.enqueue(jsonObject); // Push the JSON object to the readable side
        } catch (error) {
          controller.error(new Error('Failed to parse JSON: ' + (error as Error).message));
        }
      }
    }

    // Keep the last line (it might be incomplete)
    this.bufferedString = lines[lines.length - 1];
  }

  private _flush(controller: TransformStreamDefaultController<any>): void {
    // Process any remaining buffered string
    if (this.bufferedString.trim()) {
      try {
        const jsonObject = JSON.parse(this.bufferedString);
        controller.enqueue(jsonObject); // Push the JSON object to the readable side
      } catch (error) {
        controller.error(new Error('Failed to parse JSON: ' + (error as Error).message));
      }
    }
  }

  get readable(): ReadableStream<any> {
    return this.transformStream.readable;
  }

  get writable(): WritableStream<Uint8Array> {
    return this.transformStream.writable;
  }
}
