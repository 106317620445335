import { ReactElement, useState } from 'react';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { MembersPageView } from 'src/pages/MembersPage/MembersPageView';
import { InviteMembersModalLayout } from 'src/layout/InviteMembersModal';
import { useUserStateManager } from 'src/user/userState';
import { assertTruthy } from '@cp/common/utils/Assert';

const MembersPage = (): ReactElement | null => {
  const { user } = useUserStateManager();
  const currentOrganization = useCurrentOrganizationOrThrow();
  const [inviteMembersModalVisible, setInviteMembersModalVisible] =
    useState(false);

  if (!user || !currentOrganization) {
    return null;
  }

  const currentOrgUser = Object.values(currentOrganization.users).find(
    (u) => u.userId === user.userId
  );

  assertTruthy(currentOrgUser, `${user.email} is not part of the organization`);
  return (
    <>
      <MembersPageView
        currentUser={currentOrgUser}
        currentOrganization={currentOrganization}
        onInviteMembers={(): void => {
          setInviteMembersModalVisible(true);
        }}
      />
      <InviteMembersModalLayout
        open={inviteMembersModalVisible}
        onClose={(): void => {
          setInviteMembersModalVisible(false);
        }}
      />
    </>
  );
};

export default MembersPage;
