export function formatCurrency(amount: number, currency: string): string {
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol'
    }).format(amount);
  } catch (e) {
    console.error(`Error formatting amount ${amount} with currency ${currency}`, e);
    return `${amount} ${currency}`;
  }
}
