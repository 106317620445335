import { WritableAtom, atom } from 'jotai';

export function getInitialValue<T>(key: string, initialValue: T): T {
  const item = localStorage.getItem(key);
  if (item !== null) {
    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return initialValue;
    }
  }
  return initialValue;
}

/**
 * Creates an atom that stores its value in local storage under a key scoped by a service ID.
 *
 * @param prefix - The key prefix to use for the local storage key.
 * @param initialValue - The initial value to use if the key is not found in local storage.
 * @returns A tuple containing:
 *  - derivedAtom: A writable atom that stores the value in local storage under the appropriate service ID key.
 *    Use this atom to read and update the value.
 *    The value will be automatically persisted to local storage:
 *    - It will be loaded from the local storage when the atom is created.
 *    - It will be saved to the local storage every time when the atom is updated.
 *  - currentServiceIdAtom: A writable atom that stores the current service ID used to form the local storage key.
 *    Use this atom to change the service ID.
 */
export function atomWithScopedStorage<T>(
  prefix: string,
  initialValue: T,
  initialServiceId: string
): [WritableAtom<T, unknown[], void>, WritableAtom<string, [string], void>] {
  // This atom contains the service id associated with the tabs/selected tab id.
  // This atom value used as a key when persisting tabs in the local storage.
  // We cannot use getCurrentServiceId() directly, because
  // the tabs state for the previously selected service
  // might exists for a short time after switching services/organizations.
  // This atom is used to ensure that we are always using the correct service
  // when saving tabs/selected tab id in the local storage.
  const currentServiceIdAtom = atom<string>(initialServiceId);

  const baseAtom = atom<T>(getInitialValue(getScopedStorageKey(prefix, initialServiceId), initialValue));
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = (typeof update === 'function' ? update(get(baseAtom)) : update) as T;
      set(baseAtom, nextValue);
      const currentServiceId = get(currentServiceIdAtom);
      localStorage.setItem(getScopedStorageKey(prefix, currentServiceId), JSON.stringify(nextValue));
    }
  );

  return [derivedAtom, currentServiceIdAtom];
}

export function getScopedStorageKey(key: string, currentServiceId: string): string {
  return `${key}:${currentServiceId}`;
}
