import { GptFeedbackType } from 'types/protocol/gpt';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useRunningQueries } from 'src/lib/query/QueryState';
import { useCallback, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { useTabs } from 'src/state/tabs';
import { getCurrentServiceId } from 'src/state/service';

export type TrackedQuery = {
  tabId: string;
  traceId: string;
  queryText: string;
  // The query result has been already reported to the server
  reported?: boolean;
};

type QueryCorrectionTrackingHookReturnType = {
  trackedQueries: TrackedQuery[];
  sendGptFeedback: (
    serviceId: string,
    traceId: string,
    response: GptFeedbackType
  ) => void;
  startTrackingQuery: (query: TrackedQuery) => void;
};

const trackedQueriesAtom = atom<TrackedQuery[]>([]);

/**
 * This hook is responsible for tracking the status of running queries that have been constructed or corrected using GPT.
 * It performs the following actions:
 * - If a user accepts or rejects a GPT correction, it reports this to the server.
 * - If a constructed or corrected query fails or succeeds, it reports this to the server.
 * - If a query that has been constructed or corrected with GPT is modified by the user, it reports this to the server.
 */
const useQueryCorrectionTracking =
  (): QueryCorrectionTrackingHookReturnType => {
    const api = useApiClient();
    const runningQueries = useRunningQueries();
    const [trackedQueries, setTrackedQueries] = useAtom(trackedQueriesAtom);
    const tabs = useTabs();

    /**
     * A function that sends a user's feedback to a GPT-assisted query to the server.
     * @param traceId The unique identifier for the correction
     * @param response The user's response to the correction
     */
    const sendGptFeedback = useCallback(
      (serviceId: string, traceId: string, response: GptFeedbackType): void => {
        api
          .postGptFeedback({
            serviceId,
            traceId,
            response
          })
          .catch(console.error);
      },
      [api]
    );

    useEffect(() => {
      const serviceId = getCurrentServiceId() || '';

      tabs.forEach((tab) => {
        if (!tab || tab.type !== 'query') {
          // Ignore tabs that are not queries
          return;
        }
        const newQueryText = tab.query;
        const tabId = tab.id;

        // Find the query in trackedQueries
        const trackedQuery = trackedQueries.find(
          (query) => query.tabId === tabId
        );

        if (!trackedQuery) {
          // If the query is not in trackedQueries, ignore it
          return;
        }

        if (trackedQuery.reported) {
          // If the query has already been reported, ignore it
          return;
        }

        if (trackedQuery.queryText.trim() !== newQueryText.trim()) {
          // The query has been modified by the user, report this to the server
          sendGptFeedback(serviceId, trackedQuery.traceId, 'query-modified');
          trackedQuery.reported = true;
          return;
        }

        // Check if the query has finished or failed
        const runId = tab.queryRunId;
        if (!runId) {
          // Ignore queries that have not been run yet
          return;
        }

        const status = runningQueries[runId];
        if (status === 'finished' || status === 'error') {
          // The query has finished or failed, report this to the server
          const response =
            status === 'finished' ? 'query-success' : 'query-failed';
          sendGptFeedback(serviceId || '', trackedQuery.traceId, response);
          trackedQuery.reported = true;
        }
      });
    }, [
      runningQueries,
      sendGptFeedback,
      setTrackedQueries,
      tabs,
      trackedQueries
    ]);

    const startTrackingQuery = (query: TrackedQuery): void => {
      // Check if query with this tabId is already tracked
      const trackedQuery = trackedQueries.find(
        (trackedQuery) => trackedQuery.tabId === query.tabId
      );
      if (trackedQuery) {
        // If it is, update the traceId, and mark it as not reported
        trackedQuery.traceId = query.traceId;
        trackedQuery.reported = false;
        trackedQuery.queryText = query.queryText;
        return;
      }

      setTrackedQueries([...trackedQueries, query]);
    };

    return {
      trackedQueries,
      sendGptFeedback,
      startTrackingQuery
    };
  };

export default useQueryCorrectionTracking;
