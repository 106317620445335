import React, { ReactElement, ReactNode } from 'react';
import ReloadMetadataButton from 'src/components/TableView/TableList/ReloadMetadataButton';
import { useSetRestartServiceModalOpen } from 'src/state/service/restartService';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { logger } from 'src/lib/logger';
import {
  Container,
  IconButton,
  Separator,
  Tabs,
  Tooltip
} from '@clickhouse/click-ui';
import { useAtom } from 'jotai';
import { Resizable } from 're-resizable';
import TopBar from 'src/components/ConsoleTopBar';
import DatabaseSelector from 'src/components/DatabaseSelector';
import { marginStyle } from 'src/components/global-styles';
import QuerySidebar from 'src/components/QueryView/QueryList';
import TableSidebar from 'src/components/TableView/TableList';
import {
  useCurrentInstance,
  useIsCurrentInstanceAwakeStatus
} from 'src/instance/instanceController';
import { flex, gapSm } from 'src/lib/utility-styles';
import { mainSidebarOpen } from 'src/state/sidebar';
import {
  btnStyle,
  consoleSidebarContainer,
  tabContentStyle,
  tabsStyle,
  triggerListStyle
} from 'src/layout/SqlConsoleLayout/ConsoleLayout/styles';
import { loadDatabaseMetadata } from 'src/components/MetadataInitializer/metadataEmitter';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import styled from 'styled-components';

const MainPaneLayoutTopbar = styled(MainPaneLayout.Topbar)`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
`;

function logEvent(name: string, type: 'Focus' | 'Blur'): void {
  logger.track({
    view: 'sidebar',
    component: 'tableList',
    event: `${name}${type}`,
    interaction: 'trigger'
  });
}

const onDbChange = (): void => {
  void logger.track({
    view: 'sidebar',
    component: 'tableList',
    event: 'switcherSelect',
    interaction: 'click'
  });
};

function ConsoleLayout({
  children
}: {
  children: ReactNode;
}): ReactElement | null {
  const [openSidebar, setOpenSidebar] = useAtom(mainSidebarOpen);
  const { isStopped } = useIsCurrentInstanceAwakeStatus();
  const setRestartServiceModalOpen = useSetRestartServiceModalOpen();
  const database = useSelectedDatabaseValue();
  const currentInstance = useCurrentInstance();

  const refreshBtnClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();

    if (!currentInstance) {
      console.error('no selected service');
      return;
    }

    if (isStopped) {
      await new Promise((resolve, reject) => {
        setRestartServiceModalOpen(true, 'run this query', {
          resolve,
          reject
        });
      });
    }

    loadDatabaseMetadata({
      cached: false,
      database,
      serviceId: currentInstance.id
    });

    logger.track({
      view: 'sidebar',
      component: 'tableList',
      event: 'refreshButtonClick',
      interaction: 'click'
    });
  };

  return (
    <Container
      overflow="hidden"
      fillHeight
      maxWidth="100%"
      grow="1"
      isResponsive={false}
    >
      <Resizable
        defaultSize={{ width: 250, height: '100%' }}
        minWidth={250}
        maxWidth={600}
        css={consoleSidebarContainer}
        enable={{ right: true }}
      >
        <div css={[flex, marginStyle('0.75rem 1rem'), gapSm]}>
          <Tooltip>
            <Tooltip.Trigger>
              <IconButton
                icon="slide-out"
                type="ghost"
                css={btnStyle(openSidebar)}
                onClick={(): void => {
                  setOpenSidebar((openSidebar) => !openSidebar);
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Content>Toggle Sidebar</Tooltip.Content>
          </Tooltip>
          <DatabaseSelector
            onClick={(): void => logEvent('switcher', 'Focus')}
            onBlur={(): void => logEvent('switcher', 'Blur')}
            onSelect={onDbChange}
            data-testid="sidebar-db-selector"
          />
          <ReloadMetadataButton onClick={refreshBtnClick} />
        </div>
        <Separator size="xs" />
        <Tabs defaultValue="tables" css={tabsStyle}>
          <Tabs.TriggersList css={triggerListStyle}>
            <Tabs.Trigger value="tables" data-testid="tablesSidebarButton">
              Tables
            </Tabs.Trigger>
            <Tabs.Trigger value="queries" data-testid="queriesSidebarButton">
              Queries
            </Tabs.Trigger>
          </Tabs.TriggersList>
          <Tabs.Content value="tables" css={tabContentStyle}>
            <TableSidebar />
          </Tabs.Content>
          <Tabs.Content value="queries" css={tabContentStyle}>
            <QuerySidebar />
          </Tabs.Content>
        </Tabs>
      </Resizable>
      <MainPaneLayout overflow="hidden" isResponsive={false} fillHeight>
        <MainPaneLayoutTopbar
          showMenu={false}
          justifyContent="space-between"
          fillHeight
          maxHeight="3.5rem"
        >
          <TopBar />
        </MainPaneLayoutTopbar>
        <MainPaneLayout.Content
          grow="1"
          overflow="hidden"
          maxWidth="100%"
          alignItems="start"
        >
          {children}
        </MainPaneLayout.Content>
      </MainPaneLayout>
    </Container>
  );
}

export default ConsoleLayout;
