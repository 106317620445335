import { useCallback } from 'react';
import { useCurrentInstance } from 'src/instance/instanceController';
import { GettingStartedSteps } from '@cp/common/protocol/Instance';
import { useApiClient } from 'src/lib/controlPlane/client';
import { createToast } from '@clickhouse/click-ui';

export interface UseGettingStartedReturn extends GettingStartedSteps {
  updateGettingStarted: (props: Partial<GettingStartedSteps>) => void;
}

export const useGettingStarted = (): UseGettingStartedReturn => {
  const instance = useCurrentInstance();
  const apiClient = useApiClient();

  const updateGettingStarted = useCallback(
    (gettingStartedSteps: Partial<GettingStartedSteps>): void => {
      if (
        instance?.gettingStarted === undefined ||
        instance?.id === undefined
      ) {
        return;
      }

      apiClient.instance
        .updateGettingStarted({
          gettingStarted: gettingStartedSteps,
          instanceId: instance.id
        })
        .catch((e) => {
          createToast({
            title: 'Error updating the data',
            type: 'danger',
            description: 'Unabled to update the getting started steps'
          });
        });
    },
    [apiClient.instance, instance?.gettingStarted, instance?.id]
  );

  return {
    createService: instance?.gettingStarted?.createService ?? false,
    loadData: instance?.gettingStarted?.loadData ?? false,
    idlingOptions: instance?.gettingStarted?.idlingOptions ?? false,
    inviteMembers: instance?.gettingStarted?.inviteMembers ?? false,
    visitedIntegration: instance?.gettingStarted?.visitedIntegration ?? false,
    ...instance?.gettingStarted,
    updateGettingStarted
  };
};
