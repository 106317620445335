import { ReactElement } from 'react';

export const ContinuouslySvg = (): ReactElement => (
  <svg
    width="65"
    height="40"
    viewBox="0 0 65 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 20.3514H7.94488L10.4252 17.3243L14.3937 22.4324L16.874 20.3514L20.3465 21.2973L22.3307 18.2703L27.2913 17.3243L28.7795 19.7838L30.7638 23L34.2362 19.973L39.1969 18.4595L42.1732 21.1081L46.1417 16L49.1181 19.5946L54.0787 18.4595L59.0394 20.9189L64 19.5946"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);
