import { standardQuoteIdentifier } from 'shared';
import { formatNumber, formatPercentage } from 'src/lib/formatters/numberFormatter';
import { ColumnProps } from 'src/components/TableView/DataView/RightBar/TableInspector/types';
import { useSqlQuery } from 'src/lib/clickhouse/query';

type UseColumnTopDetails = {
  loading: boolean;
  topN: Array<{
    percentage: number;
    item: string;
    descriptor: string;
    count: number;
  }>;
  error: string | undefined;
};

type TopItem = {
  percentage: number;
  item: string;
  descriptor: string;
  count: number;
};

export function useColumnTopDetails({
  name,
  database,
  tableName
}: Omit<ColumnProps, 'dataUncompressedBytes'>): UseColumnTopDetails {
  const fullTableName = `${standardQuoteIdentifier(database)}.${standardQuoteIdentifier(tableName)}`;

  const sql = `
  WITH (SELECT count() from ${fullTableName}) as totalRecords
  SELECT approx_top_count(3)(${standardQuoteIdentifier(name)}) AS value, totalRecords
  FROM ${fullTableName}
  `;

  const [, { loading, data, error }] = useSqlQuery(sql);

  const parseTopN = (rowValue: string | null, totalRecords: string | null): Array<TopItem> => {
    if (!rowValue || !totalRecords) {
      return [];
    }

    const values = JSON.parse(rowValue ?? '[]') as Array<{
      item: string;
      count: string;
    }>;

    const totalRecordsAsNumber = !!totalRecords && totalRecords.length > 0 ? Number(totalRecords) : 0;
    const result = values.map(({ item, count }) => {
      const countsAsNumber = Number(count);
      const percentage = countsAsNumber > 0 && totalRecordsAsNumber > 0 ? countsAsNumber / totalRecordsAsNumber : 0;
      const formattedNumber = formatNumber(countsAsNumber);

      return {
        percentage: percentage,
        item,
        descriptor: `${formattedNumber} Records (${formatPercentage(percentage)})`,
        count: countsAsNumber
      };
    });

    const topNTotalRecords = result.reduce((acc, { count }) => acc + count, 0);
    const otherRecords = totalRecordsAsNumber - topNTotalRecords;
    const otherPercentage = otherRecords > 0 && totalRecordsAsNumber > 0 ? otherRecords / totalRecordsAsNumber : 0;
    const otherPercentageFormatted = otherPercentage > 0 ? `(${formatPercentage(otherPercentage)})` : '';

    result.push({
      percentage: otherPercentage,
      item: 'Other',
      descriptor: `${formatNumber(otherRecords)} Records ${otherPercentageFormatted} `,
      count: otherRecords
    });

    return result;
  };

  const topN =
    data?.rows && data.rows.length > 0 && data.rows[0].length > 0 ? parseTopN(data.rows[0][0], data.rows[0][1]) : [];

  return {
    loading,
    topN,
    error
  };
}
