import { ReactElement } from 'react';
import { Row } from 'shared/src/clickhouse';
import ConsoleQueryResultsTable from 'src/components/QueryView/Results/QueryResults/ConsoleQueryResultsTable';
import { SelectionPos } from 'src/components/primitives/lib/Spreadsheet/types';
import { PaginationPropsType } from 'src/components/primitives/lib/Spreadsheet/usePaginationState';
import { RowIteratorFunction } from 'src/lib/query/QueryState/useResultsView';
import { RunningQueryColumn } from 'src/lib/query/runningQueryTypes';
import { RightBarOption } from 'src/state/tabs/types';
interface QueryResultsProps {
  search: string;
  totals?: Row;
  selectedRow: number;
  selectedColumn: number;
  tabId: string;
  paginationProps: PaginationPropsType;
  filterLoading: boolean;
  columnWidths: Record<string, number>;
  updateRightBar: (type: RightBarOption, value: string) => void;
  columns?: RunningQueryColumn[];
  getRow: (index: number) => null | Row;
  rowIterator: RowIteratorFunction;
  visibleRowsChanged: (start: number, end: number) => void;
  numRows: number;
  pageSize: number;
  loading: boolean;
  onPaginationChange: (props: {
    currentPage?: number;
    pagSize?: number;
  }) => void;
  onPaginationSizeChange: (size: number) => void;
  onChangeFocus: (selectionPos: SelectionPos) => void;
  onColumnResize: (widths: Record<string, number>) => void;
}

const QueryResults = ({
  search,
  totals,
  selectedRow,
  selectedColumn,
  paginationProps,
  filterLoading,
  columnWidths,
  updateRightBar,
  columns,
  getRow,
  rowIterator,
  visibleRowsChanged,
  numRows,
  pageSize,
  loading,
  onPaginationChange,
  onChangeFocus,
  onColumnResize,
  onPaginationSizeChange
}: QueryResultsProps): ReactElement => {
  const filterApplied = search !== '';

  return (
    <ConsoleQueryResultsTable
      paginationState={paginationProps.paginationState}
      onPaginationChange={onPaginationChange}
      filterApplied={filterApplied}
      updateRightBar={updateRightBar}
      getRow={getRow}
      selectedRow={selectedRow}
      selectedColumn={selectedColumn}
      numRows={numRows}
      totals={totals}
      onChangeFocus={onChangeFocus}
      columns={columns}
      columnWidths={columnWidths}
      onColumnResize={onColumnResize}
      loading={loading || filterLoading}
      onPaginationSizeChange={onPaginationSizeChange}
      pageSize={pageSize}
      visibleRowsChanged={visibleRowsChanged}
      rowIterator={rowIterator}
    />
  );
};

export default QueryResults;
