import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { DatabaseName, useSelectedDatabaseValue, useSelectedDatabase } from 'src/metadata/selectedDatabase';
import { useTabActions } from 'src/state/tabs';
import { ResolveReject } from 'src/state/types';

import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useAtomCallback } from 'jotai/utils';

export interface PasswordCredential {
  connected: boolean;
  type: 'password';
  host: string;
  port: string;
  username: string;
  password?: string;
  database: string;
}

export interface ManagedCredential {
  connected: boolean;
  type: 'managed';
  database: string;
  serviceId: string;
}

export type Credential = ManagedCredential | PasswordCredential;

export const CONNECTION_DB_HOST = 'connection_db_host';
export const CONNECTION_DB_PORT = 'connection_db_port';
export const CONNECTION_DB_USER = 'connection_db_user';
export const CONNECTION_DB_DATABASE = 'connection_db_database';

const credentialsAtom = atom<Credential>({
  connected: false,
  database: 'default',
  serviceId: '',
  type: 'managed'
});

export const credentialsReadWriteAtom = atom(
  (get) => get(credentialsAtom),
  (_, set, newCredentials: Credential) => {
    if (newCredentials.type === 'password') {
      setSession(CONNECTION_DB_HOST, newCredentials.host);
      setSession(CONNECTION_DB_PORT, newCredentials.port);
      setSession(CONNECTION_DB_USER, newCredentials.username);
      setSession(CONNECTION_DB_DATABASE, newCredentials.database ?? 'default');
    }

    set(credentialsAtom, newCredentials);
  }
);
export function validatePasswordCredential(creds: PasswordCredential): PasswordCredential {
  const { port, username, password, database } = creds;
  let { host } = creds;
  try {
    const url = new URL(host || 'localhost');
    if (url.protocol === 'https:') {
      host = url.hostname;
    }
  } catch (e) {
    // not a url, nothing to do
  }

  return {
    connected: creds.connected || false,
    type: 'password',
    host: host || 'localhost',
    port: port || '8443',
    username: username || 'default',
    database: database || 'default',
    password: password
  };
}

export const modalOpen = atom<boolean>(false);
export const credentialClosePromisesAtom = atom<
  ResolveReject<Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>>[]
>([]);
const setCredentialModalOpenWriteAtom = atom(
  null,
  (
    get,
    set,
    open: boolean,
    promise?: ResolveReject<Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>>
  ) => {
    const current = get(modalOpen);
    // only update if truthiness changes to avoid weird text flashes
    if (!!open !== !!current) {
      set(modalOpen, open);
    }

    if (promise) {
      set(credentialClosePromisesAtom, [...get(credentialClosePromisesAtom), promise]);
    }
  }
);
export function useSetCredentialModalOpen(): (
  open: boolean,
  promise?: ResolveReject<Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>>
) => void {
  const setCredentialModalOpen = useSetAtom(setCredentialModalOpenWriteAtom);
  return setCredentialModalOpen;
}

function setSession(key: string, value: unknown): void {
  if (value && typeof value === 'string') {
    window.sessionStorage.setItem(key, value);
  }
}

export type SetCredentialModalOpenFn = (
  open: boolean,
  promise?: ResolveReject<Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>>
) => void;

type ConnectionCredentials = {
  cancelCredentialModal: () => void;
  confirmCredentialModal: (credentials: Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>) => void;
  credentialModalOpen: boolean;
  database: DatabaseName | undefined;
  selectedDatabase: DatabaseName;
  setCredentialModalOpen: SetCredentialModalOpenFn;
  updateCredentials: (values: Credential) => void;
  setSelectedDatabase: (serviceId: string, database: DatabaseName) => void;
  connected: boolean;
};

const isPresent = (value: string | boolean | null | undefined): boolean => {
  return !!value;
};

export function useConnectionCredentials(): ConnectionCredentials {
  const { closeTabs } = useTabActions();
  const [credentialsAtom, setCredentialsAtom] = useAtom(credentialsReadWriteAtom);
  const [credentialModalOpen] = useAtom(modalOpen);
  const [, setCredentialClosePromises] = useAtom(credentialClosePromisesAtom);
  const getCredentialClosePromises = useAtomCallback(
    useCallback((get) => {
      return get(credentialClosePromisesAtom);
    }, [])
  );
  const setCredentialModalOpen = useSetCredentialModalOpen();
  const { selectedDatabase: selectedDatabaseVal, setSelectedDatabase } = useSelectedDatabase();

  const updateCredentials = useCallback(
    (newCredentials: Credential) => {
      setCredentialsAtom(newCredentials);

      const currentValue = credentialsAtom;
      if (newCredentials.type === 'password' && currentValue.type === 'password') {
        const propChanged = (prop: keyof PasswordCredential): boolean => {
          if (currentValue.type !== 'password') {
            return true;
          }

          const currentCredentialValue = currentValue?.[prop];
          return (
            isPresent(currentCredentialValue) &&
            isPresent(newCredentials[prop]) &&
            newCredentials[prop] !== currentCredentialValue
          );
        };

        const shouldCloseTabs = propChanged('host') || propChanged('port');
        if (shouldCloseTabs) {
          closeTabs();
        }
      }
    },
    [credentialsAtom, closeTabs, setCredentialsAtom]
  );

  const cancelCredentialModal = useCallback((): void => {
    setCredentialModalOpen(false);
    getCredentialClosePromises().forEach((promise) => {
      promise.reject(new Error('Credentials required'));
    });
    setCredentialClosePromises([]);
  }, [getCredentialClosePromises, setCredentialClosePromises, setCredentialModalOpen]);

  const confirmCredentialModal = useCallback(
    (credentials: Pick<PasswordCredential, 'host' | 'port' | 'username' | 'password'>): void => {
      updateCredentials({
        ...credentials,
        connected: true,
        database: selectedDatabaseVal ?? 'system',
        type: 'password'
      });
      setCredentialModalOpen(false);
      getCredentialClosePromises().forEach((promise) => {
        promise.resolve(credentials);
      });
      setCredentialClosePromises([]);
    },
    [
      getCredentialClosePromises,
      selectedDatabaseVal,
      setCredentialClosePromises,
      setCredentialModalOpen,
      updateCredentials
    ]
  );

  const { database: currentDatabase, connected } = credentialsAtom;

  return {
    cancelCredentialModal,
    confirmCredentialModal,
    credentialModalOpen,
    database: currentDatabase,
    selectedDatabase: selectedDatabaseVal,
    setCredentialModalOpen,
    updateCredentials,
    setSelectedDatabase,
    connected
  };
}

export function useCredentials(): Credential {
  const database = useSelectedDatabaseValue();
  const credentials = useAtomValue(credentialsAtom);
  const serviceId = useCurrentInstanceId();
  return useMemo(() => {
    if (credentials.type === 'managed') {
      return {
        connected: credentials.connected ?? false,
        type: 'managed',
        serviceId: credentials.serviceId || serviceId || '',
        database: database ?? 'default'
      };
    } else {
      return validatePasswordCredential({
        ...credentials,
        database
      });
    }
  }, [credentials, database, serviceId, credentials.connected]);
}
