import {
  Container,
  Text,
  Spacer,
  Separator,
  Button,
  Link
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';

export interface SMTMigrationInfoProps {
  endTime: string;
  scheduledInstancesNames: string[];
  onClose: () => void;
}
export default function SMTMigrationInfo({
  endTime,
  scheduledInstancesNames,
  onClose
}: SMTMigrationInfoProps): ReactElement | null {
  if (scheduledInstancesNames.length === 0) {
    return null;
  }
  return (
    <>
      <Container
        orientation="vertical"
        data-testid="smt-migration-info-container"
      >
        <Text size="md">
          The following services require upcoming maintenance. During this
          maintenance period your services will be migrated from using the
          ReplicatedMergeTree engine to using the SharedMergeTree engine.{' '}
          <Link
            href="https://clickhouse.com/blog/clickhouse-cloud-boosts-performance-with-sharedmergetree-and-lightweight-updates"
            target="_blank"
            data-testid="maintenance-info-more-link"
          >
            Learn more
          </Link>
        </Text>
        <ul>
          {scheduledInstancesNames.map((name, i) => (
            <Text
              key={i}
              component="li"
              data-testid={`maintenance-info-instance-name-${name}`}
            >
              {name}
            </Text>
          ))}
        </ul>
        <Text data-testid="smt-migration-downtime-message">
          This maintenance process will involve downtime, so you may select a
          time to initiate the maintenance at your convenience. Please perform
          this maintenance before <b>{endTime} (local time).</b>
        </Text>
        <br />
        <Text>
          To initiate the maintenance, navigate to the Settings page of a
          service and select the 'Start maintenance' button.
        </Text>
      </Container>
      <Spacer />
      <Separator size="xs" />
      <Spacer />
      <Container justifyContent="end">
        <Button
          label="Close"
          onClick={onClose}
          data-testid="maintenance-window-info-close-button"
          type="secondary"
        />
      </Container>
    </>
  );
}
