import { Dialog } from '@clickhouse/click-ui';
import {
  Address,
  GetOrganizationBillingDetailsResponse,
  OrganizationDetailsToUpdate
} from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import { Organization } from '@cp/common/protocol/Organization';
import { TaxStatusType } from '@cp/common/protocol/Stripe';
import _ from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useUpdateBillingDetails } from 'src/billing/controllers/useUpdateBillingDetails';
import { AddressInfoForm } from 'src/components/BillingCardModal/AddressInfoForm';
import { BillingInfoWrapper } from 'src/components/BillingCardModal/BillingInfoWrapper';
import { EntityInfoForm } from 'src/components/BillingCardModal/EntityInfoForm';
import {
  useFormState,
  useNumEmployees,
  useWebsiteUrl
} from 'src/components/BillingCardModal/FormState';
import { StageIndicator } from 'src/components/BillingCardModal/StageIndicator';

interface BillingCardModalProps {
  organization: Organization;
  organizationBillingDetails: GetOrganizationBillingDetailsResponse;
  getOrganizationBillingDetails: () => Promise<GetOrganizationBillingDetailsResponse>;
  isOpen: boolean;
  loadingBillingDetails: boolean;
  onClose: () => void;
}

export type BillingCardModalStage =
  | 'ADDRESS_INFO'
  | 'ENTITY_TYPE_INFO'
  | 'BILLING_INFO';

type InfoSubmissionStage = Exclude<BillingCardModalStage, 'BILLING_INFO'>;

export default function BillingCardModal({
  organization,
  organizationBillingDetails,
  isOpen,
  loadingBillingDetails,
  getOrganizationBillingDetails,
  onClose
}: BillingCardModalProps): ReactElement | null {
  const [modalStage, setModalStage] =
    useState<BillingCardModalStage>('ADDRESS_INFO');
  const [formDetails, setFormDetails] = useFormState();
  const { numEmployees, setNumEmployees } = useNumEmployees();
  const { websiteUrl, setWebsiteUrl } = useWebsiteUrl();
  const { updateBillingDetails, isLoading } = useUpdateBillingDetails();
  useEffect(() => {
    setFormDetails({
      billingAddress: organizationBillingDetails.billingAddress,
      bothAddressesSame: _.isEqual(
        organizationBillingDetails.billingAddress,
        organizationBillingDetails.shippingAddress
      ),
      shippingAddress: organizationBillingDetails.shippingAddress,
      organizationIsABusiness:
        organizationBillingDetails.organizationIsABusiness ?? false,
      companyName: organizationBillingDetails.companyName,
      taxId: organizationBillingDetails.taxId,
      taxIdType: organizationBillingDetails.taxIdType,
      taxStatus: organizationBillingDetails.taxStatus ?? 'unregistered',
      businessType: organizationBillingDetails.businessType
    });
    setNumEmployees(organization.firmographics?.companySize);
    setWebsiteUrl(organization.firmographics?.websiteUrl ?? undefined);
  }, [
    organizationBillingDetails,
    setFormDetails,
    setNumEmployees,
    setWebsiteUrl
  ]);
  const handleSubmitInfo = async (
    currStage: InfoSubmissionStage
  ): Promise<void> => {
    const nextStage: BillingCardModalStage =
      currStage === 'ADDRESS_INFO' ? 'ENTITY_TYPE_INFO' : 'BILLING_INFO';
    if (currStage === 'ENTITY_TYPE_INFO') {
      const request: OrganizationDetailsToUpdate = {
        bothAddressesSame: formDetails.bothAddressesSame,
        billingAddress: formDetails.billingAddress as Address,
        shippingAddress:
          formDetails.bothAddressesSame === true
            ? undefined
            : (formDetails.shippingAddress as Address),
        organizationIsABusiness: formDetails.organizationIsABusiness,
        companyName: formDetails.companyName,
        companySize: formDetails.organizationIsABusiness
          ? numEmployees
          : undefined,
        websiteUrl: formDetails.organizationIsABusiness
          ? websiteUrl
          : undefined,
        taxStatus: formDetails.taxStatus as TaxStatusType,
        taxIdType: formDetails.taxIdType,
        taxId: formDetails.taxId,
        businessType: formDetails.businessType
      };
      await updateBillingDetails(organization.id, request);
    }
    setModalStage(nextStage);
  };

  if (!organization) {
    return null;
  }

  return (
    <Dialog open={isOpen}>
      <Dialog.Content
        onClose={() => {
          onClose();
          setModalStage('ADDRESS_INFO');
        }}
        showClose
        title="Billing Information"
      >
        <StageIndicator stage={modalStage} />
        {modalStage === 'ADDRESS_INFO' && (
          <AddressInfoForm
            onClose={onClose}
            onAdvance={async (): Promise<void> => {
              await handleSubmitInfo(modalStage);
            }}
            isLoading={isLoading || loadingBillingDetails}
          />
        )}
        {modalStage === 'ENTITY_TYPE_INFO' && (
          <EntityInfoForm
            onBack={(): void => {
              setModalStage('ADDRESS_INFO');
            }}
            onAdvance={(): void => {
              handleSubmitInfo(modalStage).catch(console.error);
            }}
            isLoading={isLoading || loadingBillingDetails}
            taxExempt={organizationBillingDetails.taxExempt ?? 'none'}
            hasTaxDetails={
              isDefined(organizationBillingDetails.taxId) &&
              isDefined(organizationBillingDetails.taxIdType)
            }
          />
        )}
        {modalStage === 'BILLING_INFO' && (
          <BillingInfoWrapper
            onBack={(): void => {
              setModalStage('ENTITY_TYPE_INFO');
            }}
            onAdvance={async () => {
              await getOrganizationBillingDetails();
              onClose();
              setModalStage('ADDRESS_INFO');
            }}
            organizationId={organization.id}
            canUpdatePaymentMethod={
              organizationBillingDetails.canUpdatePaymentMethod
            }
            billingAddress={formDetails.billingAddress}
          />
        )}
      </Dialog.Content>
    </Dialog>
  );
}
