import { ReactElement } from 'react';

import { useDataLimit } from 'src/components/primitives/lib/Charts/ChartUtils/hooks';
import ChartWrapper from 'src/components/primitives/lib/Charts/ChartWrapper';
import {
  DonutChart,
  PieChart,
  PieSeriesDescriptor
} from '@clickhouse/viz-house';

type VizHousePieChartProps = {
  animate?: boolean;
  footer?: string;
  data: Array<{ [key: string]: any }>;
  editable?: boolean;
  values: string;
  categories: string;
  showLegend?: boolean;
  subtitle?: string;
  title?: string;
  chartType?: 'pie' | 'doughnut';
};

const VizHousePieChart = (props: VizHousePieChartProps): ReactElement => {
  const {
    footer,
    data: dataProp,
    editable = false,
    values,
    subtitle,
    title,
    categories,
    showLegend,
    chartType = 'pie'
  } = props;
  const data = useDataLimit(dataProp);
  const seriesValues = data.map((row) => {
    return {
      name: row[categories],
      y: row[values]
    };
  });

  const series: Array<PieSeriesDescriptor> = [
    {
      name: categories,
      values: seriesValues
    }
  ];

  return (
    <ChartWrapper
      title={title}
      subtitle={subtitle}
      footer={footer}
      editable={editable}
    >
      {chartType === 'doughnut' ? (
        <DonutChart series={series} hasLegend={showLegend} innerSize="75%" />
      ) : (
        <PieChart series={series} hasLegend={showLegend} />
      )}
    </ChartWrapper>
  );
};

VizHousePieChart.displayName = 'PieChart';

export default VizHousePieChart;
