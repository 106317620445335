import { Select, SelectProps } from '@clickhouse/click-ui';
import { useImportModel } from 'src/state/dataLoading';
import { Table } from 'shared/src/clickhouse/types';

type TableName = Pick<Table, 'tableName'>;
interface TableSelectProps extends SelectProps {
  tables: TableName[];
}

export default function TableSelect({
  value,
  onSelect,
  tables = [],
  ...props
}: TableSelectProps): JSX.Element {
  const { importModel, updateImportModel } = useImportModel();

  const data = 'data' in importModel ? importModel.data : {};
  const selectedTable = value ?? data?.tableConfig?.config?.name ?? '';

  const changeTable = (value: string): void => {
    updateImportModel((draft) => {
      if (!('data' in draft) || !draft.data.tableConfig) {
        return;
      }

      draft.data.tableConfig.config.name = value;
    });
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  };

  return (
    <Select
      label="Table"
      error={
        selectedTable.length > 0 ? undefined : 'Please select the target table'
      }
      disabled={tables.length === 0}
      value={selectedTable}
      onSelect={changeTable}
      showSearch={tables.length > 10}
      data-testid="select-table-input"
      placeholder="Select a Table"
      {...props}
      className="fs-exclude"
    >
      {tables.map(({ tableName }) => (
        <Select.Item
          key={tableName}
          value={tableName}
          data-testid={`select-table-${tableName}`}
          className="fs-exclude"
        >
          {tableName}
        </Select.Item>
      ))}
    </Select>
  );
}
