import { RefObject, useEffect, useRef, useState } from 'react';
import { List } from 'react-virtualized';
import { SavedQueryResult } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { v4 as uuid } from 'uuid';

import { createToast } from 'src/components/primitives';
import { sidebarLogEvent } from 'src/components/QueryView/analytics';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useSocketIo } from 'src/lib/socketio';
import { useConnectionCredentials } from 'src/state/connection';
import { getCurrentServiceId } from 'src/state/service';
import { useTabActions, useTabs, useUpdateTab } from 'src/state/tabs';

import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';

import { newFolder } from 'src/components/QueryView/QueryList/Folders/newFolder';
import { FolderRef } from 'src/components/QueryView/QueryList/Folders/types';
import { NodeChangeType, NodeType, QueryType } from 'shared/src/types/queryFolders';
import { createQueryNode, insertNode } from 'shared/src/utils/queryFolders';

interface Props {
  folders: NodeType[];
  onChange: (tree: NodeType[], path?: string, type?: NodeChangeType, value?: NodeType) => void;
  queries: SavedQueryResult[];
}

interface UseFolders {
  createNewFolder: (userId: string, containingFolderId?: string | null, isLast?: boolean) => Promise<string>;
  folderRef: RefObject<FolderRef>;
  onCreateOption: {
    label: string;
    onClick: () => void;
  };
  onDelete: (id: string) => Promise<void>;
  queryNodes: Record<string, QueryType>;
  scrollRef: RefObject<List>;
  onRename: (id: string, name: string) => Promise<void>;
}

export function useFolders({ folders, onChange, queries }: Props): UseFolders {
  const { addNewQueryTab, useCloseTab } = useTabActions();
  const folderRef = useRef<FolderRef>(null);
  const scrollRef = useRef<List>(null);
  const closeTab = useCloseTab();
  const { selectedDatabase } = useConnectionCredentials();
  const { updateQuery, deleteQuery } = useSavedQueries();
  const tabs = useTabs();
  const updateTab = useUpdateTab();

  const createNewFolder = async (userId: string, containingFolderId: string | null = null): Promise<string> => {
    if (scrollRef.current) {
      scrollRef.current.scrollToPosition(0);
    }

    const newNode = newFolder(uuid(), userId);
    const { newTree, path } = insertNode({
      node: newNode,
      targetId: containingFolderId,
      tree: folders
    });

    if (folderRef.current) {
      folderRef.current.setHighlightedFolder(newNode.id);
    }

    onChange(newTree, path, 'create', newNode);

    return newNode.id;
  };

  const onCreateOption = {
    label: 'Create query',
    onClick: (): void => {
      addNewQueryTab();
      sidebarLogEvent('queryContextMenu', 'QueryCreate');
    }
  };

  const onDelete = async (id: string): Promise<void> => {
    try {
      await deleteQuery(id);
      const currentTabs = tabs;
      const tabIndex = currentTabs.findIndex((tab) => tab.type === 'query' && tab.queryId === id);
      if (tabIndex !== -1) {
        closeTab(tabIndex);
      }
    } catch (e) {
      if (e instanceof Error) {
        createToast('Error', 'alert', `Error deleting query: ${e.message}`);
      }
    }
  };

  const queryNodes = queries.reduce(
    (queryNodes, query) => ({
      ...queryNodes,
      [query.id]: createQueryNode(query)
    }),
    {}
  );

  const onRename = async (id: string, name: string): Promise<void> => {
    const currentTabs = tabs;
    const savedTab = currentTabs.find((tab) => tab.type === 'query' && tab.queryId === id);
    let query: string;
    if (savedTab && savedTab.type === 'query') {
      query = savedTab.query;
    } else {
      const savedQuery = queries.find((query) => query.id === id);
      query = savedQuery?.query ?? '';
    }

    const updatedQuery = await updateQuery({
      id,
      name,
      query: query ?? '',
      database: selectedDatabase,
      updatedAt: new Date().toISOString()
    });
    if (savedTab) {
      updateTab(savedTab.id, {
        title: updatedQuery?.name,
        updatedAt: updatedQuery?.updatedAt ?? undefined,
        editedAt: undefined,
        lastRunAt: undefined,
        query
      });
    }
  };

  return {
    createNewFolder,
    folderRef,
    onCreateOption,
    onDelete,
    queryNodes,
    scrollRef,
    onRename
  };
}

interface UseSavedQueryFolder {
  folders: NodeType[];
  persistFolders: (tree: NodeType[], path?: string, type?: NodeChangeType, value?: NodeType | string) => Promise<void>;
  loading: boolean;
}

export function useSavedQueryFolders(): UseSavedQueryFolder {
  const api = useApiClient();
  const currentServiceId = getCurrentServiceId() || '';
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState<NodeType[]>([]);
  const { client: socketIo } = useSocketIo();
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!socketIo || !currentServiceId) {
      return;
    }

    // Flag to prevent loading state update after unmount
    let unmounted = false;

    /**
     * Loads the folder tree from the backend
     * Side effect:
     * - sets the folders: setFolders
     * - sets the loading state: setLoading
     */
    const loadFolderTree = async (): Promise<void> => {
      if (!initializedRef.current) {
        setLoading(true);
      }
      try {
        const data = await api.getQueryFolderTree({
          serviceId: currentServiceId
        });
        const { tree } = data;
        const folderTree = JSON.parse(tree) as NodeType[];
        setFolders(folderTree);
        initializedRef.current = true;
      } catch (e) {
        createToast('Error', 'alert', errorMessage(e));
      } finally {
        if (!unmounted) {
          setLoading(false);
        }
      }
    };

    loadFolderTree().catch((e) => console.error(e));
    socketIo.on('foldersChanged', loadFolderTree);

    return (): void => {
      unmounted = true;
      socketIo.removeListener('foldersChanged', loadFolderTree);
    };
  }, [currentServiceId, socketIo]);

  /**
   * Sends folder tree to the backend to be persisted
   * @param folders - the folder tree
   */
  const persistFolders = async (
    tree: NodeType[],
    path?: string,
    type?: NodeChangeType,
    value?: NodeType | string
  ): Promise<void> => {
    try {
      await api.saveQueryFolderTree({
        path,
        serviceId: currentServiceId,
        tree: JSON.stringify(tree),
        type,
        value
      });
      setFolders(folders);
    } catch (error) {
      createToast('Error', 'alert', errorMessage(error));
    }
  };

  return {
    folders,
    persistFolders,
    loading
  };
}
