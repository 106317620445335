import { useEffect, useState } from 'react';

import {
  AssignSavedQueryInput,
  CreateSavedQueryInput,
  SavedQueryDTO,
  UpdateSavedQueryInput
} from 'shared/src/types/savedQuery';
import {
  assign,
  create,
  get,
  list,
  update,
  remove
} from 'src/lib/controlPlane/savedQuery';
import {
  LoadingState,
  SavedQueriesContext,
  SavedQueryResult
} from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { useHttpClient } from 'src/lib/http';
import { getCurrentServiceId } from 'src/state/service';
import { refetchSavedQueriesEmitter } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesEmitter';
import { useCallback } from 'react';

export const SavedQueriesProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [savedQueries, setSavedQueries] = useState<SavedQueryResult[]>([]);
  const [loadingState, setLoadingState] = useState<LoadingState>('initial');
  const [loadError, setLoadError] = useState('');
  const http = useHttpClient();

  const serviceId = getCurrentServiceId();
  /**
   * A function that reloads the saved queries from the server and updates the state.
   * Updates the loading and error flags accordingly.
   * @returns A Promise that resolves when the saved queries have been reloaded.
   */
  const refetch = useCallback(async (): Promise<void> => {
    if (!serviceId) {
      return;
    }
    setLoadingState('loading');
    setLoadError('');
    try {
      const result = await list(http, serviceId);
      setSavedQueries(result);
    } catch (e) {
      setLoadError(e instanceof Error ? e.message : 'Something went wrong');
    } finally {
      setLoadingState('done');
    }
  }, [serviceId, http]);

  useEffect(() => {
    const handler = (): void => {
      refetch().catch(console.error);
    };

    refetchSavedQueriesEmitter.on(handler);

    return () => {
      refetchSavedQueriesEmitter.off(handler);
    };
  }, [refetch]);

  useEffect(() => {
    if (!serviceId) {
      return;
    }

    refetch().catch(console.error);
  }, [serviceId]);

  /**
   * A function that returns a saved query with the given ID, or undefined if not found.
   * @param id The ID of the saved query to retrieve.
   * @returns The saved query with the given ID, or undefined if not found.
   */
  const getQueryById = (id: string) => {
    return savedQueries.find((query) => query.id === id);
  };

  /**
   * A function that updates a saved query on the server and reloads the saved queries.
   * @param query The updated saved query data.
   * @throw An error if update API reqest fails.
   * @returns A Promise that resolves with the updated saved query, or null if there is no service ID.
   */
  async function assignQuery(
    args: AssignSavedQueryInput
  ): Promise<SavedQueryDTO | null> {
    if (!serviceId) {
      return null;
    }
    const result = await assign(http, serviceId, args);
    await refetch();
    return result;
  }

  /**
   * A function that updates a saved query on the server and reloads the saved queries.
   * @param query The updated saved query data.
   * @throw An error if update API reqest fails.
   * @returns A Promise that resolves with the updated saved query, or null if there is no service ID.
   */
  async function updateQuery(
    query: UpdateSavedQueryInput
  ): Promise<SavedQueryResult | null> {
    if (!serviceId) {
      return null;
    }
    await update(http, serviceId, query);
    await refetch();
    return await get(http, serviceId, query.id);
  }
  /**
   * A function that creates a new saved query on the server and reloads the saved queries.
   * @param query The data for the new saved query.
   * @throw An error if create API reqest fails.
   * @returns A Promise that resolves with the new saved query, or null if there is no service ID.
   */
  async function createQuery(
    query: CreateSavedQueryInput
  ): Promise<SavedQueryResult | null> {
    if (!serviceId) {
      return null;
    }
    let result: SavedQueryResult | null = null;
    result = await create(http, serviceId, query);
    await refetch();
    return result;
  }

  /**
   * A function that deletes a saved query on the server.
   * @param id The ID of the saved query to delete.
   * @throw An error if remove API reqest fails.
   * @returns A Promise that resolves when the saved query has been deleted.
   */
  async function deleteQuery(id: string): Promise<void> {
    if (!serviceId) {
      return;
    }
    await remove(http, serviceId, { id });
    await refetch();
  }

  return (
    <SavedQueriesContext.Provider
      value={{
        assignQuery,
        savedQueries,
        getQueryById,
        loadingState,
        loadError,
        refetch,
        updateQuery,
        createQuery,
        deleteQuery
      }}
    >
      {children}
    </SavedQueriesContext.Provider>
  );
};
