import { ReactElement } from 'react';

import { IconProps } from 'src/components/icons/iconInterface';

function NotAllowedIcon({
  color = 'currentColor',
  ...props
}: IconProps): ReactElement {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 2C10.058 2 2 10.058 2 20s8.058 18 18 18 18-8.058 18-18S29.942 2 20 2zM32 8L8 32"
        stroke={color}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NotAllowedIcon;
