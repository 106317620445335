import { useOrgFeature } from 'src/lib/features';

export function useClickPipesPeerDBTileEnabled(): boolean {
  return useOrgFeature('FT_ORG_CLICKPIPES_PEERDB_TILE');
}

export function usePostgresClickpipeEnabled(): boolean {
  return useOrgFeature('FT_ORG_CLICKPIPES_POSTGRES');
}

export function useClickPipeAvroFlattenEnabled(): boolean {
  return useOrgFeature('FT_ORG_CLICKPIPES_AVRO_FLATTEN');
}

export function useClickPipeOffsetOverride(): boolean {
  return useOrgFeature('FT_ORG_CLICKPIPES_OFFSET_OVERRIDE');
}

export function useClickPipeCustomDelimiter(): boolean {
  return useOrgFeature('FT_ORG_CLICKPIPES_S3_CSV_CUSTOM_DELIMTER');
}
