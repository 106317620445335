import { useHttpClient } from 'src/lib/http';
import {
  DeleteSavedQueryUserAccessArgs,
  SavedQueryAccessApiClient,
  UpdateSavedQueryGlobalAccessArgs,
  UpdateSavedQueryUserAccessArgs
} from 'src/savedQuery/savedQueryAccessApiClient';
import { useMemo } from 'react';
import { createToast } from '@clickhouse/click-ui';
import { useSavedQueries } from 'src/components/QueryView/SavedQueriesProvider/savedQueriesHook';
import { SavedQueryPermissionsLevel } from 'shared/src/types/savedQuery';

interface SavedQueryAccessController {
  deleteSavedQueryUserAccess: (args: DeleteSavedQueryUserAccessArgs) => Promise<void>;
  updateSavedQueryGlobalAccess: (args: UpdateSavedQueryGlobalAccessArgs) => Promise<void>;
  updateSavedQueryUserAccess: (args: UpdateSavedQueryUserAccessArgs) => Promise<void>;
}

function assertValidPermissions(permissions: string): permissions is SavedQueryPermissionsLevel {
  if (!['none', 'read', 'write'].includes(permissions)) {
    throw new Error(`Invalid permissions ${permissions}`);
  }

  return true;
}

export function useSavedQueryAccessController(): SavedQueryAccessController {
  const httpClient = useHttpClient();
  const { refetch } = useSavedQueries();
  const savedQueryApiClient = useMemo(() => {
    return new SavedQueryAccessApiClient(httpClient);
  }, [httpClient]);

  const deleteSavedQueryUserAccess = async (args: DeleteSavedQueryUserAccessArgs): Promise<void> => {
    try {
      await savedQueryApiClient.deleteSavedQueryUserAccess(args);
      await refetch();
    } catch (e) {
      if (e instanceof Error) {
        createToast({
          title: 'Error deleting user access',
          type: 'danger',
          description: e.message
        });
      } else {
        console.error(e);
      }
    }
  };

  const updateSavedQueryGlobalAccess = async (args: UpdateSavedQueryGlobalAccessArgs): Promise<void> => {
    try {
      assertValidPermissions(args.permissions);
      await savedQueryApiClient.updateSavedQueryGlobalAccess(args);
      await refetch();
    } catch (e) {
      if (e instanceof Error) {
        createToast({
          title: 'Error updating global access',
          type: 'danger',
          description: e.message
        });
      } else {
        console.error(e);
      }
    }
  };

  const updateSavedQueryUserAccess = async (args: UpdateSavedQueryUserAccessArgs): Promise<void> => {
    try {
      assertValidPermissions(args.permissions);
      await savedQueryApiClient.updateSavedQueryUserAccess(args);
      await refetch();
    } catch (e) {
      if (e instanceof Error) {
        createToast({
          title: 'Error updating user access',
          type: 'danger',
          description: e.message
        });
      } else {
        console.error(e);
      }
    }
  };

  return {
    deleteSavedQueryUserAccess,
    updateSavedQueryGlobalAccess,
    updateSavedQueryUserAccess
  };
}
