import { ReactElement } from 'react';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import OrganizationDetails from 'src/pages/OrganizationDetailsPage/OrganizationDetails';

const OrganizationDetailsPage = (): ReactElement | null => {
  const user = useCurrentUserOrThrow();
  const currentOrganization = useCurrentOrganizationOrThrow();

  return (
    <OrganizationDetails organization={currentOrganization} userId={user?.id} />
  );
};

export default OrganizationDetailsPage;
