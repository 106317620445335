import { QuestionAnswerType } from '@cp/common/protocol/Account';
import { useCallback } from 'react';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';
interface ReturnProps {
  removeEntryQuestionnaire: () => Promise<void>;
  addUserEntryQuestionnaire: (questions: Array<QuestionAnswerType>) => Promise<void>;
}

const useEntryQuestionnaire = (): ReturnProps => {
  const apiClient = useApiClient();

  const removeEntryQuestionare = useCallback(async () => {
    try {
      await apiClient.updateUserDetails({
        pendingActionTypesToRemove: ['entry-questionnaire']
      });
    } catch (e) {
      createToast('Error', 'error', 'Failed to submit your questionnaire');
    }
  }, [apiClient]);

  const addUserEntryQuestionnaire = useCallback(
    async (questions: Array<{ question: string; answer: string }>) => {
      try {
        await apiClient.account.addUserEntryQuestionnaire({
          questions
        });
        await apiClient.updateUserDetails({
          pendingActionTypesToRemove: ['entry-questionnaire']
        });
      } catch (e) {
        createToast('Error', 'error', 'Something unexpected occurred');
      }
    },
    [apiClient]
  );
  return {
    removeEntryQuestionnaire: removeEntryQuestionare,
    addUserEntryQuestionnaire
  };
};

export default useEntryQuestionnaire;
