import { ReactElement, useState } from 'react';
import {
  Container,
  Icon,
  IconName,
  Panel,
  PanelProps,
  Text
} from '@clickhouse/click-ui';
import styled from 'styled-components';

const ImportPanel = styled(Panel)`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

function PanelCard({
  iconName,
  title,
  subTitle,
  ...props
}: {
  iconName: IconName;
  title: string;
  subTitle?: string;
} & PanelProps): ReactElement {
  const [hover, setHover] = useState(false);
  return (
    <ImportPanel
      color={hover ? 'muted' : 'default'}
      onMouseOver={(): void => setHover(true)}
      onMouseOut={(): void => setHover(false)}
      padding="xs"
      {...props}
    >
      <Container alignItems="end" gap="sm" isResponsive={false}>
        <Icon name={iconName} />
        <Text color="default">{title}</Text>
        {subTitle && (
          <Text color="muted" size="sm">
            {subTitle}
          </Text>
        )}
      </Container>
    </ImportPanel>
  );
}

export default PanelCard;
