import { InstanceBackup } from '@cp/common/protocol/Backup';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

export interface BackupsStateManager {
  backups: Array<InstanceBackup>;
  setBackups: (newBackups: Array<InstanceBackup>) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  backupsError: string | null;
  setBackupsError: (error: string | null) => void;
}

export const backupsAtom = atom<Array<InstanceBackup>>([]);
export const backupsLoadingAtom = atom<boolean>(false);
const errorAtom = atom<string | null>(null);

export function useBackupsStateManager(): BackupsStateManager {
  const [backups, setBackups] = useAtom(backupsAtom);
  const [loading, setLoading] = useAtom(backupsLoadingAtom);
  const [backupsError, setBackupsError] = useAtom(errorAtom);

  const setInstanceBackups = (newBackups: Array<InstanceBackup>): void => {
    setBackups(newBackups.sort((a, b) => b.createdAt - a.createdAt));
  };

  return {
    loading,
    setLoading,
    setBackups: useCallback(setInstanceBackups, [setBackups]),
    backups,
    backupsError,
    setBackupsError
  };
}
