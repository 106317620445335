import { createToast } from 'primitives';
import { useCallback, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';
import { errorMessage } from 'src/lib/errors/errorMessage';

export interface RequestAdditionalCredits {
  requestAdditionalCredits: (requestText: string) => Promise<void>;
  isLoading: boolean;
}

export const useRequestAdditionalCredits = (): RequestAdditionalCredits => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);

  const requestAdditionalCredits = useCallback(
    async (requestText: string): Promise<void> => {
      try {
        setIsLoading(true);
        await apiClient.billing.requestAdditionalCredits(requestText);
      } catch (e) {
        createToast('There was a problem submitting your request, please try again later', 'alert', errorMessage(e));
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [apiClient.billing]
  );
  return { requestAdditionalCredits, isLoading };
};
