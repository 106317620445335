import {
  Container,
  FormContainer,
  IconButton,
  Panel,
  Separator,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import CopyButton from 'src/components/CopyButton';
import PasswordDisplay from 'src/components/ServiceConnect/PasswordDisplay';
import PasswordResetInfo from 'src/components/ServiceConnect/PasswordResetInfo';
import { downloadText } from 'src/lib/utils/download';
import styled from 'styled-components';

const StretchDiv = styled.div`
  align-self: stretch;
`;

const CREDENTIALS_FILE_NAME = 'clickhouse_credentials.txt';

type CredentialsInfoProps = {
  username: string;
  hasManagePermission: boolean;
  password?: string;
  onPasswordReset?: () => Promise<void>;
  onTogglePassword?: (show: boolean) => void;
  showDownload?: boolean;
  usernameLabel?: string;
  passwordLabel?: string;
  credentialsFileName?: string;
  dataWarehouseId?: string;
};

/**
 * Component that displays two rows: username and password, together with copy buttons next to them.
 * If password is not provided, it displays a link to reset the password.
 */
const CredentialsInfo = ({
  username,
  password,
  hasManagePermission,
  onPasswordReset,
  onTogglePassword,
  showDownload,
  usernameLabel = 'Username',
  passwordLabel = 'Password',
  dataWarehouseId,
  credentialsFileName = CREDENTIALS_FILE_NAME
}: CredentialsInfoProps): ReactElement => {
  showDownload = showDownload ?? !!password;
  return (
    <Panel
      orientation="horizontal"
      color="muted"
      gap="none"
      padding="lg"
      fillWidth
    >
      <Container
        orientation="vertical"
        color="muted"
        alignItems="start"
        gap="none"
        padding="none"
        fillWidth={false}
        grow="1"
        isResponsive={false}
      >
        <FormContainer label={usernameLabel} htmlFor="">
          <Container gap="xs" justifyContent="start" isResponsive={false}>
            <Container component={Text} isResponsive={false}>
              {username}
            </Container>
            <CopyButton text={username} />
          </Container>
        </FormContainer>
        <Separator size="md" />
        <FormContainer label={passwordLabel} htmlFor="">
          <Container gap="xs" isResponsive={false} alignItems="start">
            {password && (
              <PasswordDisplay
                password={password}
                onToggle={onTogglePassword}
              />
            )}
            {!password && (
              <PasswordResetInfo
                onPasswordReset={
                  hasManagePermission ? onPasswordReset : undefined
                }
                dataWarehouseId={dataWarehouseId}
              />
            )}
          </Container>
        </FormContainer>
      </Container>
      {showDownload && password && (
        <>
          <StretchDiv>
            <Separator orientation="vertical" size="lg" />
          </StretchDiv>
          <Container
            alignItems="center"
            justifyContent="center"
            fillWidth={false}
            padding="none"
            isResponsive={false}
          >
            <Tooltip>
              <Tooltip.Trigger>
                <IconButton
                  icon="download"
                  onClick={() => {
                    const text = `${usernameLabel}: ${username}\n${passwordLabel}: ${password}`;
                    downloadText(text, credentialsFileName);
                  }}
                />
              </Tooltip.Trigger>
              <Tooltip.Content>
                <div>Download credentials</div>
              </Tooltip.Content>
            </Tooltip>
          </Container>
        </>
      )}
    </Panel>
  );
};

export default CredentialsInfo;
