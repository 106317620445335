import { createToast } from '@clickhouse/click-ui';
import { OrganizationDetailsToUpdate } from '@cp/common/protocol/Billing';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';

import { useCallback, useState } from 'react';
import { useApiClient } from 'src/lib/controlPlane/client';

export interface UpdateBillingDetails {
  updateBillingDetails: (organizationId: string, organizationDetails: OrganizationDetailsToUpdate) => Promise<void>;
  isLoading: boolean;
}
export const useUpdateBillingDetails = (): UpdateBillingDetails => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);
  const updateBillingDetails = useCallback(
    async (organizationId: string, organizationDetails: OrganizationDetailsToUpdate): Promise<void> => {
      try {
        setIsLoading(true);
        await apiClient.billing.updateOrganizationBillingDetails(organizationId, organizationDetails);
      } catch (e) {
        const errorMsg = getErrorMessageFromError(e);
        let errorDescription = 'Please try again later';
        if (errorMsg === 'INVALID_TAX_ID') {
          errorDescription = 'Tax ID is invalid';
        }
        if (errorMsg?.includes('TAX_ID_CANNOT_BE_REMOVED')) {
          errorDescription = 'Tax ID cannot be removed, please reach out to support.';
        }
        createToast({
          title: 'There was a problem updating your billing details',
          type: 'danger',
          description: errorDescription
        });
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [apiClient.billing]
  );
  return { updateBillingDetails, isLoading };
};
