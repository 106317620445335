import { OrganizationRole } from '@cp/common/protocol/Organization';
import { RpcRequest, WithEntityId, WithKeyId } from '@cp/common/utils/ProtocolUtils';
import { isNumberInRange, isStringInRange } from '@cp/common/utils/ValidationUtils';

export const OPENAPI_MAX_KEYS_PER_ORGANIZATION = 100;
export const OPENAPI_KEY_ID_LENGTH = 20;
export const OPENAPI_KEY_SECRET_LENGTH = 42;
/** Common prefix for all secrets to be locatable in logs. */
export const OPENAPI_KEY_SECRET_PREFIX = '4b1d';
export const OPENAPI_KEY_NAME_MIN_LENGTH = 1;
export const OPENAPI_KEY_NAME_MAX_LENGTH = 100;
export const OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS = Date.parse('2999-12-31T00:00:00.000Z');
export const OPENAPI_API_PATH = 'keys';

/** State of the OpenapiKey: enabled or disabled. */
export const OPENAPI_KEY_STATES = ['enabled', 'disabled'] as const;
export type OpenapiKeyState = (typeof OPENAPI_KEY_STATES)[number];

/** Defines a type of the entityId for the OpenapiKey. */
export const OPENAPI_ENTITY_TYPES = ['organization'] as const;
export type OpenapiKeyEntityType = (typeof OPENAPI_ENTITY_TYPES)[number];
export type OpenapiKeyActorType = 'user' | 'openapi-key';
export type OpenapiKeyRole = OrganizationRole | 'QUERY_ENDPOINTS';

/**
 * Type of the Openapi key authentication algorithm.
 * - plain: secret key is sent with every openapi request as a part of Authorization header..
 * - signed: secret key is used to sign Authentication header value. The signed value includes expiration date.
 */
export type OpenapiKeyAuthAlgorithm = 'plain' | 'signed';

/** See OpenapiBo for details. */
export interface OpenapiKey {
  id: string;
  keySuffix: string;
  algorithm: OpenapiKeyAuthAlgorithm;
  entityId: string;
  entityType: OpenapiKeyEntityType;
  name: string;
  roles: Array<OpenapiKeyRole>;
  createdByActorType: OpenapiKeyActorType;
  createdByActorId: string;
  createdByDetails: string;
  creationDate: number;
  expirationDate: number;
  useDate?: number;
  state: OpenapiKeyState;
}

export type OpenapiEntityKeysUpdatePayload = {
  /** List of all organization keys. */
  keys: Array<OpenapiKey>;
};

/** Set of all RPC actions for 'backup' handler. */
export type OpenapiKeyRpcAction = 'create' | 'delete' | 'update' | 'list' | 'describe';

export type OpenapiKeyRpcRequest<T extends OpenapiKeyRpcAction> = RpcRequest<T>;

export interface CreateOpenapiKeyRequest extends OpenapiKeyRpcRequest<'create'>, WithEntityId {
  name: string;
  expirationDate: number;
  roles: Array<OpenapiKeyRole>;
}

export interface CreateOpenapiKeyResponse {
  /** ID of the created key. */
  id: string;
  /** Public key id (like AWS_ACCESS_KEY_ID). Returned only during key creation. */
  key: string;
  /** Key secret (like AWS_ACCESS_KEY_SECRET). Returned only once during key creation. */
  secret: string;
  /** List of all keys for the same entity (organization). */
  keys: Array<OpenapiKey>;
}

export interface ListOpenapiKeysRequest extends OpenapiKeyRpcRequest<'list'> {
  entityId: string;
  entityType: OpenapiKeyEntityType;
}

export interface ListOpenapiKeysResponse {
  /** List of all keys for the same entity (organization). */
  keys: Array<OpenapiKey>;
}

export interface OpenapiKeyUpdate {
  name: string;
  state: OpenapiKeyState;
  expireAt: number;
  roles: Array<OpenapiKeyRole>;
}

/** Updates the key by id. At least one of the optional fields must be defined. */
export type UpdateOpenapiKeyRequest = OpenapiKeyRpcRequest<'update'> & WithKeyId & Partial<OpenapiKeyUpdate>;

/** Deletes the key by id. */
export type DeleteOpenapiKeyRequest = OpenapiKeyRpcRequest<'delete'> & WithKeyId;

export type DescribeOpenApiKeyRequest = OpenapiKeyRpcRequest<'describe'> & {
  openApiKeyId: string;
  openApiKeySecret: string;
};

export type DescribeOpenApiKeyResponse = OpenapiKey;

export function isOpenapiKeyName(value: unknown): value is string {
  return isStringInRange(value, OPENAPI_KEY_NAME_MIN_LENGTH, OPENAPI_KEY_NAME_MAX_LENGTH);
}

const ALL_VALID_OPENAPI_KEY_ROLES: Array<OpenapiKeyRole> = ['ADMIN', 'DEVELOPER', 'QUERY_ENDPOINTS'];

/** Returns true if the 'role' is a valid role constant in the organization. */
export function isOpenapiKeyRole(role: unknown): role is OpenapiKeyRole {
  return ALL_VALID_OPENAPI_KEY_ROLES.includes(role as OpenapiKeyRole);
}

export function isTimestampInFuture(value: unknown): value is number {
  return isNumberInRange(value, Date.now(), Infinity);
}

export function isFutureOpenapiKeyExpirationDate(value: unknown): value is number {
  return isTimestampInFuture(value) && value <= OPENAPI_KEY_NEVER_EXPIRE_DATE_MILLIS;
}

export function isOpenapiKeyId(value: unknown): value is string {
  return typeof value === 'string' && value.length === OPENAPI_KEY_ID_LENGTH;
}

export function isOpenapiKeyState(value: unknown): value is OpenapiKeyState {
  return OPENAPI_KEY_STATES.includes(value as OpenapiKeyState);
}

export function isExpiredOpenapiKey(key: { state: OpenapiKeyState; expirationDate: number | Date }): boolean {
  const expirationTimestamp =
    typeof key.expirationDate === 'number' ? key.expirationDate : key.expirationDate.getTime();
  return expirationTimestamp <= Date.now();
}

/** Returns true if the key's state is 'enabled' and expiration date is in the future. */
export function isActiveOpenapiKey(key: { state: OpenapiKeyState; expirationDate: number | Date }): boolean {
  return key.state === 'enabled' && !isExpiredOpenapiKey(key);
}

export function mapOpenapiKeyStateToDisplayName(state: OpenapiKeyState): string {
  return state === 'enabled' ? 'Enabled' : 'Disabled';
}

export function getOpenapiKeyDisplayStatus(key: { state: OpenapiKeyState; expirationDate: number | Date }): string {
  return isExpiredOpenapiKey(key) ? 'Expired' : mapOpenapiKeyStateToDisplayName(key.state);
}

/** Returns true if a member with 'actorRole' can create an Openapi key with 'keyRole'. */
export function checkCanCreateOpenapiKey(actorRole: OpenapiKeyRole, keyRole: OpenapiKeyRole): boolean {
  return actorRole === 'ADMIN' || keyRole === actorRole;
}

export interface OpenapiKeyActor {
  actorType: OpenapiKeyActorType;
  actorId: string;
}

export interface OpenapiKeyActorWithRole extends OpenapiKeyActor {
  role: OpenapiKeyRole;
}

/**
 *  Returns true if 'actorId' with 'role' can delete the key.
 *  'ADMIN' can delete any key. 'DEVELOPER' can delete only own keys.
 */
export function checkCanDeleteOpenapiKey(
  { actorType, actorId, role }: OpenapiKeyActorWithRole,
  key: { createdByActorType: OpenapiKeyActorType; createdByActorId: string }
): boolean {
  return role === 'ADMIN' || (actorType === key.createdByActorType && actorId === key.createdByActorId);
}

/** 'UPDATE' follows the same rules with 'DELETE'. */
export const checkCanUpdateOpenapiKey = checkCanDeleteOpenapiKey;
