import {
  Container,
  Pagination,
  SearchField,
  Select,
  Spacer,
  Table,
  TableHeaderType,
  TableRowType
} from '@clickhouse/click-ui';
import { memo } from 'react';
import { pageSizes } from 'src/pages/NotificationsPage/AllNotifications/useAllNotifications';

const headers: TableHeaderType[] = [
  {
    label: 'Time',
    sortPosition: 'start',
    isSortable: true,
    sortDir: 'desc',
    width: '260px'
  },
  {
    label: 'Severity',
    sortPosition: 'start',
    isSortable: true,
    width: '112px'
  },
  {
    label: 'Notify when',
    sortPosition: 'start',
    isSortable: true,
    width: '60%'
  },
  { label: 'Category', sortPosition: 'start', isSortable: true }
];

const severityOptions = [
  { value: '', label: 'All' },
  { value: 'info', label: 'Info' },
  { value: 'warning', label: 'Warning' },
  { value: 'critical', label: 'Critical' }
];

/**
 * TODO here should be
 * { value: '1d', label: 'Today' },
  { value: '2d', label: 'Yesterday' },
  { value: '1w', label: 'Last week' },
  { value: '2w', label: 'Last two weeks' },
  { value: '1M', label: 'Last month' }
  It will be implemented in the next notification UI iteration
 */
const timeOptions = [
  { value: '1d', label: 'Last 24 hours' },
  { value: '2d', label: 'Last 48 hours' },
  { value: '1w', label: 'One week' },
  { value: '2w', label: 'Two weeks' },
  { value: '1M', label: 'One month' }
];

const categoriesOptions = [
  { value: '', label: 'All' },
  { value: 'service', label: 'Service' },
  { value: 'billing', label: 'Billing' }
];

type AllNotificationsTabViewProps = {
  search: string;
  isLoading: boolean;
  onSearch: (value: string) => void;
  severity: string;
  onSelectSeverity: (value: string) => void;
  category: string;
  onSelectCategory: (value: string) => void;
  time: string;
  onSelectTime: (value: string) => void;
  page: number;
  onPageChange: (value: number) => void;
  limit: number;
  onPageSizeChange: (value: number) => void;
  onSort: (
    sortDir: 'asc' | 'desc',
    header: TableHeaderType,
    index: number
  ) => void;
  rows: TableRowType[];
  totalPages: number;
  totalRows: number;
};

export const AllNotificationsTabView = memo(
  ({
    search,
    onSearch,
    severity,
    onSelectSeverity,
    category,
    onSelectCategory,
    time,
    onSelectTime,
    page,
    isLoading,
    onPageChange,
    limit,
    onPageSizeChange,
    onSort,
    rows,
    totalPages,
    totalRows
  }: AllNotificationsTabViewProps) => {
    return (
      <Container orientation="vertical">
        <Spacer size="lg" />
        <Container gap="sm">
          <Container minWidth="50%">
            <SearchField
              width="100%"
              onChange={onSearch}
              placeholder="Search"
              value={search}
              // TODO search is disabled and will be implemented in phase 2
              disabled={true}
            />
          </Container>
          <Select
            onSelect={onSelectSeverity}
            value={severity}
            placeholder="Severity"
          >
            {severityOptions.map((option) => (
              <Select.Item
                key={option.value}
                value={option.value}
                label={option.label}
              ></Select.Item>
            ))}
          </Select>
          <Select
            onSelect={onSelectCategory}
            value={category}
            placeholder="Category"
          >
            {categoriesOptions.map((option) => (
              <Select.Item
                key={option.value}
                value={option.value}
                label={option.label}
              ></Select.Item>
            ))}
          </Select>
          <Select
            onSelect={onSelectTime}
            value={time}
            placeholder="Time period"
          >
            {timeOptions.map((option) => (
              <Select.Item
                key={option.value}
                value={option.value}
                label={option.label}
              ></Select.Item>
            ))}
          </Select>
        </Container>
        <Spacer size="lg" />
        <Table
          headers={headers}
          rows={rows}
          onSort={onSort}
          loading={isLoading}
        />
        <Spacer size="sm" />
        <Container padding="sm">
          <Pagination
            currentPage={page}
            onChange={onPageChange}
            totalPages={totalPages}
            rowCount={totalRows}
            pageSize={limit}
            maxRowsPerPageList={pageSizes}
            onPageSizeChange={onPageSizeChange}
            allowAllRows={false}
          />
        </Container>
      </Container>
    );
  }
);
