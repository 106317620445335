import { Dropdown, IconButton } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import {
  ChangeRoleFunction,
  DeleteInvitationFunction,
  RemoveUserFunction,
  ResendInvitationFunction
} from 'src/organization/organizationController';

interface ActionProps {
  user: string;
  userRole: 'ADMIN' | 'DEVELOPER';
  isInvite: boolean;
  organizationId: string;
  inviteExpired?: boolean;
  changeRole: ChangeRoleFunction;
  removeUser: RemoveUserFunction;
  deleteInvitation: DeleteInvitationFunction;
  resendInvitation: ResendInvitationFunction;
  resendLoading: boolean;
  changeToRole: 'ADMIN' | 'DEVELOPER';
}

function Actions({
  user,
  userRole,
  isInvite = false,
  inviteExpired = false,
  organizationId,
  changeRole,
  removeUser,
  deleteInvitation,
  resendInvitation,
  resendLoading,
  changeToRole
}: ActionProps): ReactElement {
  return (
    <Dropdown>
      <Dropdown.Trigger>
        <IconButton
          icon="dots-vertical"
          size="default"
          data-testid="actions-dropdown-trigger"
        />
      </Dropdown.Trigger>
      <Dropdown.Content side="bottom" align="end">
        {isInvite === false && (
          <>
            <Dropdown.Item
              onClick={(): void => {
                void changeRole(user, organizationId, changeToRole);
              }}
              data-testid={`change-role-to-${changeToRole.toLowerCase()}`}
            >
              Change role to {changeToRole.toLowerCase()}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(): void => {
                void removeUser(user, organizationId);
              }}
            >
              Remove
            </Dropdown.Item>
          </>
        )}
        {isInvite === true && (
          <Dropdown.Item
            onClick={(): void => {
              void deleteInvitation(user, organizationId);
            }}
          >
            Delete
          </Dropdown.Item>
        )}
        {isInvite && inviteExpired && (
          <Dropdown.Item
            onClick={(): void => {
              if (resendLoading) {
                return;
              }
              void resendInvitation(user, userRole);
            }}
            disabled={resendLoading}
            icon={resendLoading ? 'loading-animated' : undefined}
          >
            {resendLoading ? 'Resending' : 'Resend'}
          </Dropdown.Item>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
}
export default Actions;
