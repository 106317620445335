type ModifierKeyPrefix = '^' | '⌘';
/**
 * Returns the modifier key prefix for the current platform.
 * This is used to display keyboard shortcuts in the UI.
 *
 * On macOS, the modifier key prefix is '⌘'.
 * On Windows and Linux, the modifier key prefix is '^'.
 *
 * @returns The modifier key prefix for the current platform.
 */
export const getModifierKeyPrefix = (): ModifierKeyPrefix => {
  let modifierKeyPrefix: ModifierKeyPrefix = '^'; // control key
  if (navigator.platform.indexOf('Mac') === 0 || navigator.platform === 'iPhone') {
    modifierKeyPrefix = '⌘'; // command key
  }
  return modifierKeyPrefix;
};
