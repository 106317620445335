import { DEFAULT_COST_DISPLAY_PRECISION } from '@cp/common/protocol/Billing';
import humanize from 'humanize-string';

/** Spacer value between value & unit. */
export const UNIT_SPACER_BY_TYPE: Readonly<Record<string, string>> = {
  'bytes-letter': ' ',
  'binary-bytes-letter': ' ',
  'bytes-word': ' ',
  'binary-bytes-word': ' ',
  'numeric-symbols': '',
  'short-scale-letter': ' ',
  'short-scale-word': ' ',
  'human-readable-number': '',
  percentage: '',
  'cost-number': ''
};

export const UNITS_BY_TYPE: Readonly<Record<string, string[]>> = {
  'bytes-letter': ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  'binary-bytes-letter': ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'],
  'bytes-word': ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Petabytes'],
  'binary-bytes-word': ['Bytes', 'Kibibytes', 'Mebibytes', 'Gigibytes', 'Pebibytes'],
  'numeric-symbols': ['', 'k', 'M', 'G', 'T', 'P', 'E'],
  'short-scale-letter': ['', 'K', 'M', 'B', 'T'],
  'short-scale-word': ['', 'thousands', 'millions', 'billions', 'trillions'],
  'human-readable-number': [''],
  percentage: ['%'],
  'cost-number': ['']
};

export const formatEnum = (values: string | string[] | null | undefined): string => {
  let output = '';

  if (Array.isArray(values)) {
    const humanizedValues = values.map((value) => humanize(value));
    output = humanizedValues.join(', ');
  } else if (typeof values === 'string') {
    output = humanize(values);
  }

  return output;
};

// formats a number with commas as thousands separators
export function formatNumber(number: number, options: Intl.NumberFormatOptions = {}): string {
  return new Intl.NumberFormat('en-US', options).format(number);
}
// receives a ratio and formats as percentage
// ex: 0.5 -> 50%
// ex: 0.1234 -> 12.34%
// ex: 1 -> 100%
// ex: 0 -> 0%
export function formatPercentage(number: number, decimals = 2): string {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  }).format(number);
}

export const DEFAULT_NAN_TEXT = 'N/A';
/** Formats given size as a data size. */
export function formatDataSize(
  size: number,
  unitsType = 'bytes-letter',
  decimals = 2,
  nanText = DEFAULT_NAN_TEXT
): string {
  if (unitsType == '') {
    unitsType = 'bytes-letter';
  }
  if (Number.isNaN(size)) {
    return nanText;
  }
  const sign = size < 0 ? '-' : '';
  const absSize = Math.abs(size);

  const units = UNITS_BY_TYPE[unitsType];
  const sizeOf1Kb = unitsType === 'binary-bytes-letter' || unitsType == 'binary-bytes-word' ? 1024 : 1000;

  if (unitsType === 'human-readable-number') {
    decimals = size < 1 && size > -1 ? 8 : 2;
  }

  if (unitsType === 'cost-number') {
    decimals = DEFAULT_COST_DISPLAY_PRECISION;
  }

  if (size < 1 && size > -1) {
    return `${size.toFixed(Number.isInteger(size) ? 0 : decimals)}${units[0] === '' ? '' : ` ${units[0]}`}`;
  }
  const power = Math.min(Math.floor(Math.log(absSize) / Math.log(sizeOf1Kb)), units.length - 1);
  const sizeInUnits = absSize / Math.pow(sizeOf1Kb, power);
  const formattedSizeInUnits = !Number.isInteger(sizeInUnits)
    ? Math.round(sizeInUnits * Math.pow(10, decimals)) / Math.pow(10, decimals)
    : sizeInUnits;

  const unit = units[power];
  const spacer = UNIT_SPACER_BY_TYPE[unitsType];
  return unitsType === 'human-readable-number'
    ? `${sign}${formattedSizeInUnits.toLocaleString()}${spacer}${unit}`.trim()
    : `${sign}${formattedSizeInUnits}${spacer}${unit}`.trim();
}
