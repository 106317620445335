import { CardPromotion, Container } from '@clickhouse/click-ui';
import { Organization } from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';

interface AlertsSectionProps {
  currentOrg: Organization;
  hasInvitations: boolean;
  setInvitationsModalOpen: (b: boolean) => void;
}

export const AlertsSection = ({
  currentOrg,
  hasInvitations,
  setInvitationsModalOpen
}: AlertsSectionProps): ReactElement | null => {
  const hasManageBillingPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage-billing',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  if (!currentOrg) {
    return null;
  }

  const trialDaysRemaining =
    currentOrg.billingStatus === 'IN_TRIAL' &&
    currentOrg.cachedCommitmentState['TRIAL']
      ? currentOrg.cachedCommitmentState['TRIAL'].timeRemainingInDays
      : undefined;

  return (
    <Container orientation="vertical" padding="md" gap="xs">
      {trialDaysRemaining && hasManageBillingPermission && (
        <CardPromotion
          icon="clock"
          data-testid="trial-countdown-card"
          label={`Upgrade in the next ${trialDaysRemaining} days to unlock more features`}
          onClick={(): void =>
            navigateTo(routes.billingHome({ orgId: currentOrg.id }))
          }
        />
      )}
      {hasInvitations && (
        <CardPromotion
          data-testid="invitations-card"
          icon="alarm"
          label="You have a new invitation"
          onClick={(): void => setInvitationsModalOpen(true)}
        />
      )}
    </Container>
  );
};
