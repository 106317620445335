import { ReactElement } from 'react';
import { EditColumn } from 'shared/src/tableSchema';
import {
  getColumn,
  useEditTable
} from 'src/components/CreateTableView/context';
import { useVisited } from 'src/components/CreateTableView/useVisited';
import { MultiSelect } from '@clickhouse/click-ui';

interface ColumnMultiSelectProps {
  selectedColumnIds: string[];
  onChange: (val: string[]) => void;
  filter?: (column: EditColumn) => boolean;
  required?: boolean;
  label: string;
}

export function ColumnMultiSelect({
  selectedColumnIds,
  onChange,
  filter: filterProp,
  required = false,
  label
}: ColumnMultiSelectProps): ReactElement {
  const { tableState } = useEditTable();
  const [visited, visit] = useVisited();

  const filter = filterProp || ((): boolean => true);

  const columnIds = tableState.columnIds.filter((columnId) => {
    const column = getColumn(tableState, columnId);
    return column && filter(column);
  });

  return (
    <MultiSelect
      label={label}
      disabled={columnIds.length === 0}
      error={visited && required && selectedColumnIds.length === 0}
      onBlur={(): void => {
        if (!visited) {
          visit();
        }
      }}
      value={selectedColumnIds}
      onSelect={onChange}
      data-testid="tableOption-Columns"
    >
      {columnIds.map((columnId) => (
        <MultiSelect.Item key={columnId} value={columnId}>
          {tableState.columns[columnId]?.name ?? columnId}
        </MultiSelect.Item>
      ))}
    </MultiSelect>
  );
}
