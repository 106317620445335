import { useMemo } from 'react';

export function useBounds(numColumns: number, pageStart: number, rowsPerPage: number) {
  const bounds = useMemo(() => {
    return {
      left: 0,
      right: numColumns - 1,
      top: pageStart,
      bottom: pageStart + rowsPerPage - 1
    };
  }, [numColumns, pageStart, rowsPerPage]);

  return bounds;
}
