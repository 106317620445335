import { Icon, IconName, Tooltip } from '@clickhouse/click-ui';

import { Resizable } from 're-resizable';
import React, { useState } from 'react';

import styles from 'src/components/App/Sidebar/styles';
import {
  useQueriesNavigationLink,
  useTablesNavigationLink
} from 'src/components/App/Sidebar/useNavigationLinks';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import QuerySidebar from 'src/components/QueryView/QueryList';

import TableSidebar from 'src/components/TableView/TableList';
import config from 'src/lib/config';
import { useImportModel } from 'src/lib/dataLoading';
import { logger } from 'src/lib/logger';

import { routes } from 'src/lib/routes';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { useConnectionCredentials } from 'src/state/connection';
import {
  Sidebar as SidebarType,
  useSelectedSidebar
} from 'src/state/selectedSidebar';
import { getCurrentServiceId } from 'src/state/service';

interface SidebarLink {
  label: string;
  page: string;
  value: SidebarType;
  icon: IconName;
  dataTestId: string;

  navigate?: string;
}

const logoClick = (): void => {
  logger.track({
    view: 'navbar',
    component: 'logo',
    event: 'logoClick',
    interaction: 'click'
  });
  window.location.assign(config.controlPlane.host);
};

const hasSubSidebar: { [key: string]: boolean } = {
  query: true,
  table: true,
  imports: false
};

const Sidebar = React.memo(function Sidebar() {
  const role = useCurrentOrgUserRole();
  // RBAC migration skipped. code is not used (used for non unified console only)
  // todo remove related components
  const isAdmin: boolean = role === 'ADMIN';
  const { credentialModalOpen, setCredentialModalOpen } =
    useConnectionCredentials();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { importModel } = useImportModel();
  const { selectedSidebar, setSelectedSidebar } = useSelectedSidebar();
  const isResizable = ['table', 'query'].includes(selectedSidebar);

  const tablesLink = useTablesNavigationLink(selectedSidebar);
  const queriesLink = useQueriesNavigationLink(selectedSidebar);

  const leftMenuEnableSides = {
    right: isResizable
  };

  const serviceId = getCurrentServiceId();

  if (!serviceId) {
    return null;
  }

  const links: SidebarLink[] = [
    {
      label: 'Tables',
      page: '/',
      value: 'table',
      icon: 'table',
      dataTestId: 'tablesSidebarButton',
      navigate: tablesLink
    },
    {
      label: 'Queries',
      page: '/sql',
      value: 'query',
      icon: 'query',
      dataTestId: 'queriesSidebarButton',
      navigate: queriesLink
    }
  ];

  if (isAdmin) {
    const url = importModel.type
      ? routes.import({ serviceId: serviceId, importId: importModel.id })
      : routes.imports({ serviceId: serviceId });
    links.push({
      label: 'Imports',
      page: '/imports',
      value: 'imports',
      icon: 'upload',
      dataTestId: 'importsSidebarButton',
      navigate: url
    });
  }

  const withSubSidebar: boolean = hasSubSidebar[selectedSidebar] ?? false;

  return (
    <div css={styles.sidebarContainer}>
      <div css={styles.sidebar}>
        <div>
          <Tooltip
            open={tooltipOpen && config.env !== 'production'}
            onOpenChange={(open: boolean): void => setTooltipOpen(open)}
          >
            <Tooltip.Trigger>
              <div onClick={logoClick} css={styles.sidebarLogoContainer}>
                <Icon name="clickhouse" width="32" height="32" theme="dark" />
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content side="right" sideOffset={5} showArrow>
              {window._env.APP_VERSION ?? 'local'}
            </Tooltip.Content>
          </Tooltip>
          {links.map((link) => (
            <Tooltip key={link.label}>
              <Tooltip.Trigger>
                <div
                  css={styles.sidebarLink}
                  onClick={(): void => {
                    logger.track({
                      view: 'navbar',
                      component: 'navButtons',
                      event: `${link.value}ViewClick`,
                      interaction: 'click'
                    });
                    setSelectedSidebar(link.value);
                    if (link.navigate) {
                      navigateTo(link.navigate);
                    }
                  }}
                  key={link.value}
                  data-selected={selectedSidebar === link.value}
                  data-testid={link.dataTestId}
                >
                  <div
                    css={styles.sidebarIcon}
                    className="sidebar-icon-container"
                    data-selected={selectedSidebar === link.value}
                  >
                    <Icon name={link.icon} size="sm" />
                  </div>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Content sideOffset={5} side="right" showArrow>
                {link.label}
              </Tooltip.Content>
            </Tooltip>
          ))}
        </div>
        {!isAdmin && (
          <Tooltip>
            <Tooltip.Trigger>
              <div
                css={styles.sidebarLink}
                className="connection"
                onClick={(): void => {
                  logger.track({
                    view: 'navbar',
                    component: 'footer',
                    event: 'connectionViewClick',
                    interaction: 'click'
                  });
                  setCredentialModalOpen(true);
                }}
                data-selected={credentialModalOpen}
              >
                <div
                  css={styles.sidebarIcon}
                  data-selected={credentialModalOpen}
                >
                  <Icon name="connect-alt" />
                </div>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content showArrow sideOffset={5} side="right">
              Configure connection
            </Tooltip.Content>
          </Tooltip>
        )}
      </div>

      {withSubSidebar && (
        <Resizable
          defaultSize={{ width: 250, height: '100%' }}
          minWidth={250}
          maxWidth={600}
          css={styles.container}
          enable={leftMenuEnableSides}
        >
          {selectedSidebar === 'query' && <QuerySidebar />}

          {selectedSidebar === 'table' && <TableSidebar />}
        </Resizable>
      )}
    </div>
  );
});

export default Sidebar;
