import {
  Button,
  Container,
  Separator,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';

export interface MultiSTSMigrationInfoProps {
  startTime: string;
  duration: string;
  scheduledInstancesNames: string[];
  onClose: () => void;
}

export default function MultiSTSMigrationInfo({
  startTime,
  duration,
  scheduledInstancesNames,
  onClose
}: MultiSTSMigrationInfoProps): ReactElement {
  return (
    <>
      <Container orientation="vertical">
        <Text size="md">
          {`The following service(s) will enter a scheduled maintenance window
            for approximately ${duration} beginning `}
          <b>{startTime} (local time):</b>
        </Text>
        <ul>
          {scheduledInstancesNames.map((name, i) => (
            <Text
              key={i}
              component="li"
              data-testid={`maintenance-info-instance-name-${name}`}
            >
              {name}
            </Text>
          ))}
        </ul>
        <Text>
          Your service should remain accessible during the maintenance window
          with minimal performance degradation. You will not be billed for the
          duration of the maintenance window.
        </Text>
      </Container>
      <Spacer />
      <Separator size="xs" />
      <Spacer />
      <Container justifyContent="end">
        <Button
          label="Close"
          onClick={onClose}
          data-testid="maintenance-window-info-close-button"
          type="secondary"
        />
      </Container>
    </>
  );
}
