import { ReactElement } from 'react';
import { BillingCardModalStage } from 'src/components/BillingCardModal';
import { Container, Icon, useCUITheme } from '@clickhouse/click-ui';

export const StageIndicator = ({
  stage
}: {
  stage: BillingCardModalStage;
}): ReactElement => {
  const cuiTheme = useCUITheme();
  const colors = {
    default: cuiTheme.global.color.background.muted,
    accent: cuiTheme.global.color.accent.default
  };
  return (
    <Container
      alignItems="center"
      justifyContent="center"
      gap="none"
      orientation="horizontal"
      fillWidth={false}
      isResponsive={false}
    >
      <Icon
        name="dot"
        size="lg"
        color={stage === 'ADDRESS_INFO' ? colors.accent : colors.default}
      />
      <Icon
        name="dot"
        size="lg"
        color={stage === 'ENTITY_TYPE_INFO' ? colors.accent : colors.default}
      />
      <Icon
        name="dot"
        size="lg"
        color={stage === 'BILLING_INFO' ? colors.accent : colors.default}
      />
    </Container>
  );
};
