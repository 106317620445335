import { Button, Spacer, Text } from '@clickhouse/click-ui';
import { InstanceCloudProvider } from '@cp/common/protocol/Instance';
import { OrganizationPrivateEndpoint } from '@cp/common/protocol/Organization';
import { RegionId } from '@cp/common/protocol/Region';
import { ReactNode, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { useOrganizationController } from 'src/organization/organizationController';
import {
  useCurrentOrganizationOrThrow,
  useCurrentOrgUserRole
} from 'src/organization/organizationState';
import NoPrivateEndpoints from 'src/pages/PrivateEndpointsPage/NoEndpoints';
import { OrganizationPrivateEndpointFlyout } from 'src/pages/PrivateEndpointsPage/OrganizationPrivateEndpointFlyout';
import { OrganizationPrivateEndpointList } from 'src/pages/PrivateEndpointsPage/OrganizationPrivateEndpointList';

export function OrganizationPrivateEndpointsPage(): ReactNode | null {
  const currentOrganization = useCurrentOrganizationOrThrow();
  const hasManagePrivateEndpointsPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:service:manage-private-endpoints',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  const [flyoutOpen, setFlyoutOpen] = useState(false);

  const { updatePrivateEndpoints } = useOrganizationController();
  const onAddPrivateEndpoint = useCallback(() => {
    setFlyoutOpen(true);
  }, []);

  const hasPrivateEndpoints = currentOrganization.privateEndpoints.length > 0;
  const onCreatePrivateEndpoint = useCallback(
    (
      cloudProvider: InstanceCloudProvider,
      region: RegionId,
      id: string,
      description: string
    ) => {
      const newEndpoint: OrganizationPrivateEndpoint = {
        cloudProvider,
        id,
        description,
        region
      };
      const newOrganizationEndpoints = [
        ...currentOrganization.privateEndpoints,
        newEndpoint
      ];

      updatePrivateEndpoints({
        organizationId: currentOrganization.id,
        privateEndpoints: newOrganizationEndpoints
      })
        .then(() => setFlyoutOpen(false))
        .catch((e) => console.error(e));
    },
    [
      currentOrganization.id,
      currentOrganization.privateEndpoints,
      updatePrivateEndpoints
    ]
  );

  const onDeletePrivateEndpoint = useCallback(
    (endpointIndex: number) => {
      const newEndpoints = [...currentOrganization.privateEndpoints];
      newEndpoints.splice(endpointIndex, 1);
      void updatePrivateEndpoints({
        organizationId: currentOrganization.id,
        privateEndpoints: newEndpoints
      });
    },
    [
      currentOrganization.privateEndpoints,
      currentOrganization.id,
      updatePrivateEndpoints
    ]
  );

  return (
    <>
      <MainPaneLayout>
        <MainPaneLayout.Topbar label="Private endpoints">
          {hasManagePrivateEndpointsPermission && (
            <Button
              label="Add new endpoint"
              iconLeft="plus"
              onClick={(): void => {
                onAddPrivateEndpoint();
              }}
              data-testid="add-private-endpoint-button"
            />
          )}
        </MainPaneLayout.Topbar>
        <MainPaneLayout.Content>
          <Helmet>
            <title>Private endpoints - {currentOrganization.name}</title>
          </Helmet>
          <MaxWidthContainer
            orientation="vertical"
            padding="lg"
            alignItems="start"
            data-testid="members-page"
          >
            {hasPrivateEndpoints && (
              <>
                <Text color="default">
                  Create and manage private endpoints that can be used
                </Text>
                <Spacer />
                <OrganizationPrivateEndpointList
                  onDelete={onDeletePrivateEndpoint}
                />
              </>
            )}
            {!hasPrivateEndpoints && (
              <MaxWidthContainer
                alignItems="center"
                gap="md"
                orientation="vertical"
                padding="lg"
              >
                <NoPrivateEndpoints
                  onCreatePrivateEndpoint={onAddPrivateEndpoint}
                />
              </MaxWidthContainer>
            )}
          </MaxWidthContainer>
        </MainPaneLayout.Content>
      </MainPaneLayout>
      <OrganizationPrivateEndpointFlyout
        open={flyoutOpen}
        onOpenChange={setFlyoutOpen}
        onCreateEndpoint={onCreatePrivateEndpoint}
      />
    </>
  );
}
