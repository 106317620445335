import { useCallback, useMemo, useRef, useState } from 'react';

import { editor, Range } from 'monaco-editor';

import { StatementInfo } from 'src/lib/sql/parse/statementRanges';

interface RunStatementDecorationResult {
  decorations: editor.IModelDeltaDecoration[];
  flashStatement(statement: StatementInfo): void;
  cancelFlash(): void;
}

export const flashTimeoutSeconds = 1;

export function useRunStatementDecoration(model: editor.ITextModel | undefined): RunStatementDecorationResult {
  const [runStatement, setRunStatement] = useState<StatementInfo | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const decorations = useMemo(
    function memoizedDecorations() {
      if (!model) {
        return [];
      }

      const runStatementDecorations: editor.IModelDeltaDecoration[] = [];

      if (runStatement) {
        const startPosition = model.getPositionAt(runStatement.start);
        const endPosition = model.getPositionAt(runStatement.end);
        runStatementDecorations.push({
          range: Range.fromPositions(startPosition, endPosition),
          options: { inlineClassName: 'run-statement' }
        });
      }

      return runStatementDecorations;
    },
    [model, runStatement]
  );

  const flashStatement = useCallback(async (statement: StatementInfo) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      // if we're still in a flash, we need to clear it then wait for the next
      // animation frame to create the new one
      setRunStatement(null);
      await new Promise<void>((resolve) => requestAnimationFrame(() => resolve()));
    }
    setRunStatement(statement);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = undefined;
      setRunStatement(null);
    }, flashTimeoutSeconds * 1000);
  }, []);

  const cancelFlash = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
    setRunStatement(null);
  }, []);

  return {
    decorations,
    flashStatement,
    cancelFlash
  };
}
