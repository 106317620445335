import { ConfirmationDialog, Spacer, Text } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

interface RestartServiceModalProps {
  open: boolean;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}

function RestartServiceModal({
  open,
  message,
  onCancel,
  onConfirm
}: RestartServiceModalProps): ReactElement | null {
  if (!open) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="Restart this service?"
      primaryActionLabel="Restart service"
      secondaryActionLabel="Cancel"
      data-testid="restart-service-confirmation-dialog"
    >
      <Text>
        {`This service is currently stopped, to ${message}, it will need to be restarted.`}
      </Text>
      <Spacer />
    </ConfirmationDialog>
  );
}

export default RestartServiceModal;
