import { Button, Container, EllipsisContent, Text } from '@clickhouse/click-ui';
import { OrganizationInvitationDetails } from '@cp/common/protocol/Organization';
import { ReactElement, ReactNode } from 'react';
import {
  AcceptInvitationArgs,
  useMarkViewedInvitationsOnClose
} from 'src/state/notification/invitationController';

const InvitationListItem = ({
  invitation,
  acceptInvitation,
  testId
}: {
  invitation: OrganizationInvitationDetails;
  acceptInvitation: () => void;
  testId: string;
}): ReactElement => {
  return (
    <Container
      justifyContent="space-between"
      gap="xl"
      alignItems="end"
      isResponsive={false}
      data-testid={testId}
    >
      <Container orientation="vertical" isResponsive={false} overflow="hidden">
        <Text weight="bold">{invitation.organizationName}</Text>
        <EllipsisContent component={Text} color="muted">
          {invitation.inviterEmail}
        </EllipsisContent>
      </Container>
      {invitation.isExpired ? (
        <Button disabled>Invite expired</Button>
      ) : (
        <Button
          data-testid={`button-${testId}-accept`}
          onClick={acceptInvitation}
          type="primary"
        >
          Join organization
        </Button>
      )}
    </Container>
  );
};

export interface InvitationListProps {
  invitations: Array<OrganizationInvitationDetails>;
  acceptInvitation: (args: AcceptInvitationArgs) => Promise<void>;
  onClose: () => void;
  testId: string;
  children?: ReactNode;
  component?: string;
}

export const InvitationList = ({
  invitations,
  acceptInvitation,
  onClose,
  testId,
  children,
  component
}: InvitationListProps): ReactElement => {
  useMarkViewedInvitationsOnClose();
  return (
    <Container
      orientation="vertical"
      gap="md"
      isResponsive={false}
      data-testid={`${testId}-content`}
    >
      {invitations.map((invitation, i) => (
        <InvitationListItem
          invitation={invitation}
          acceptInvitation={(): void =>
            void acceptInvitation({ invitation, onClose, component })
          }
          key={invitation.id}
          testId={`${testId}-${i}`}
        />
      ))}
      {children}
    </Container>
  );
};
