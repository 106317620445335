import React, { ReactNode } from 'react';

import { css, SerializedStyles, Theme } from '@emotion/react';

interface Props {
  children: ReactNode | string;
}
const successStyle = (theme: Theme): SerializedStyles => css`
  padding: 15px;
  margin: 0;
  display: block;
  vertical-align: middle;
  text-align: left;
  color: ${theme.colors.alert.text.success};
  background: ${theme.colors.alert.bg.success};
  border-bottom: 1px solid ${theme.colors.alert.text.success};
`;

function SuccessBox({ children }: Props) {
  return <pre css={successStyle}>{children}</pre>;
}

export default React.memo(SuccessBox);
