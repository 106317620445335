import {
  Button,
  Container,
  Text as CUIText,
  Dialog,
  Separator,
  Spacer,
  SuccessAlert
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { useDataWarehousePassword } from 'src/dataWarehouses/controller/useDataWarehousePassword';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import ConnectWithContent from 'src/components/ServiceConnect/ConnectWithContent';
import CredentialsInfo from 'src/components/ServiceConnect/CredentialsInfo';
import useInstanceMySqlSettingsController from 'src/instance/instanceMySqlSettingsController';
import useInstanceMySqlSettingsStateManager from 'src/instance/InstanceMySqlSettingsState';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { Instance } from '@cp/common/protocol/Instance';

export interface ResetDataWarehousePasswordModalProps {
  onClose: () => void;
  dataWarehouseId: string;
  open: boolean;
}
interface SuccessDialogContentProps {
  mySqlPassword?: string;
  password?: string;
  instance?: Instance;
  instancesCount?: number;
}
const SuccessDialogContent = ({
  mySqlPassword = '',
  password = '',
  instance,
  instancesCount = 1
}: SuccessDialogContentProps): ReactElement => {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );
  const [showPassword, setShowPassword] = useState(false);
  const { updateMysqlSettings } = useInstanceMySqlSettingsController();
  const { mySqlSettingsLoading, mysqlSettings } =
    useInstanceMySqlSettingsStateManager();
  const nativesecure = instance?.endpoints['nativesecure'] ?? {
    hostname: '',
    port: 0
  };
  const nativesecureParams = {
    hostname: nativesecure.hostname,
    password: password ?? '',
    port: nativesecure.port.toString()
  };

  const https = instance?.endpoints['https'] ?? {
    hostname: '',
    port: 0
  };

  const httpsParams = {
    hostname: https.hostname,
    password: password ?? '',
    port: https.port.toString()
  };

  const mysqlParams = {
    canEnable: !!mysqlSettings?.passwordSet,
    featureFlagEnabled: instance?.cloudProvider !== 'azure',
    mySqlUsername: mysqlSettings?.username ?? '',
    mySqlPassword,
    isLoading: mySqlSettingsLoading,
    enabled: mysqlSettings?.enabled ?? false,
    onEnabledChange: (val: boolean): void => {
      if (instance) {
        void updateMysqlSettings({
          instanceId: instance?.id,
          organizationId: instance?.organizationId,
          enabled: val
        });
      }
    }
  };

  return (
    <>
      <SuccessAlert
        text={`Your password was reset for ${
          instancesCount > 1 ? 'all ' : ''
        }${instancesCount} service${
          instancesCount > 1 ? 's' : ''
        } in your warehouse. Make sure to update the settings of any clients that use these credentials.`}
      />
      <Spacer />

      <CredentialsInfo
        hasManagePermission={hasManageServicePermission}
        username={instance?.dbUsername ?? ''}
        password={password}
        onTogglePassword={setShowPassword}
        dataWarehouseId={instance?.dataWarehouseId}
        showDownload
      />
      <Spacer />
      <ConnectWithContent
        showPassword={showPassword}
        https={httpsParams}
        mysqlProps={mysqlParams}
        username={instance?.dbUsername ?? ''}
        nativesecure={nativesecureParams}
      />
    </>
  );
};

export const ResetDataWarehousePasswordModal = ({
  onClose,
  dataWarehouseId,
  open
}: ResetDataWarehousePasswordModalProps): ReactElement => {
  const {
    resetDataWarehousePassword,
    warehouse,
    loading,
    password,
    mySqlPassword,
    primaryInstance
  } = useDataWarehousePassword({
    dataWarehouseId
  });

  const [isSuccessFull, setIsSuccessFull] = useState(false);
  const onConfirm = async (): Promise<void> => {
    try {
      await resetDataWarehousePassword();
      setIsSuccessFull(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Content
        title="Reset service user password"
        onClose={onClose}
        showClose
      >
        {isSuccessFull ? (
          <SuccessDialogContent
            mySqlPassword={mySqlPassword}
            password={password}
            instance={primaryInstance}
            instancesCount={warehouse?.instances?.length}
          />
        ) : (
          <>
            <CUIText component="span">
              All services within your warehouse share the same DB credentials.
              If you reset the password, you will also reset the password for
              the following services:
            </CUIText>
            <ul>
              {warehouse?.instances.map((instance) => (
                <CUIText component="li" key={instance.name}>
                  {instance.name}
                </CUIText>
              ))}
            </ul>
            <CUIText component="span">This action cannot be undone.</CUIText>
          </>
        )}
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs" isResponsive={false}>
          <Dialog.Close
            label={isSuccessFull ? 'Close' : 'Cancel'}
            onClick={onClose}
          />
          {!isSuccessFull && (
            <Button
              onClick={(): void => void onConfirm().catch(console.error)}
              loading={loading}
            >
              Reset password
            </Button>
          )}
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
