import { Button, Container, Icon, Text, Title } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import ChatWithUsButton from 'src/components/SupportList/ChatWithUsButton';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { routes } from 'src/lib/routes';
import styled from 'styled-components';

const CustomContainer = styled(Container)`
  margin-top: -3.5rem;
`;

const NotFoundPage = (): ReactElement => {
  const viewAllServices = (): void => {
    navigateTo(routes.serviceList());
  };

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar />
      <MainPaneLayout.Content>
        <Container
          fillHeight
          fillWidth
          alignItems="center"
          justifyContent="center"
        >
          <CustomContainer
            orientation="vertical"
            maxWidth="min(50%, 420px)"
            alignItems="center"
            justifyContent="center"
            gap="md"
          >
            <Icon name="no-cloud" size="xxl" />
            <Title type="h2">Oh no, page not found</Title>
            <Text align="center">
              404 Error. We’re unable to find the page that requested, try
              checking the URL again.
            </Text>
            <Container gap="md">
              <ChatWithUsButton fillWidth />
              <Button iconLeft="services" onClick={viewAllServices} fillWidth>
                View all services
              </Button>
            </Container>
          </CustomContainer>
        </Container>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default NotFoundPage;
