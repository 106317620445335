import { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  Container,
  CheckboxMultiSelect,
  Pagination,
  Panel,
  SearchField,
  Spacer,
  Table,
  TableHeaderType,
  Title,
  Icon,
  Text
} from '@clickhouse/click-ui';
import {
  ResultColumn,
  ResultData,
  isResultData,
  isResultError
} from 'src/lib/clickhouse/query';
import { Row } from 'shared/src/clickhouse/types';
import { formatDateToTimeOrDate } from 'src/lib/formatters/dateTimeFormatter';
import {
  SortOrder,
  useGetRecentQueries
} from 'src/components/QueryInsights/queries';
import { QueryInsightsFlyout } from 'src/components/QueryInsights/QueryInsightsFlyout';
import { formatNumber } from 'src/lib/formatters/numberFormatter';
import { Galaxy } from 'galaxy';
import { SelectableTimePeriod } from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import styled from 'styled-components';
import { EllipsisContainer } from 'src/layout/GlobalStyles';
import { formatHumanReadableSize } from 'src/lib/formatters/sizeFormatter';

const resultsPerPage = 20;
const DEFAULT_HIDDEN_COLUMNS = ['Table', 'p90 (s)', 'p99 (s)'];
interface QueryInsightsTableProps {
  timePeriod: SelectableTimePeriod;
}
const RelativeContainer = styled(Container)`
  position: relative;
`;
const LoadingOverlay = styled(Panel)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
  background-color: #000;
  z-index: 1000;
`;

export const QueryInsightsTable = ({
  timePeriod
}: QueryInsightsTableProps): ReactElement => {
  const [results, setResults] = useState<ResultData>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [sortField, setSortField] = useState<string>('"Last run (UTC)"');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
  const [isLoading, setIsLoading] = useState(false);
  const getRecentQueries = useGetRecentQueries(
    timePeriod,
    sortField,
    sortOrder
  );

  useEffect(() => {
    const runRecentQueries = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const response = await getRecentQueries();

        if (isResultError(response)) {
          setHasError(true);
        }

        if (isResultData(response)) {
          setHasError(false);
          setResults(response);
        }
      } catch (error) {
        setHasError(true);
      }
      setIsLoading(false);
    };

    void runRecentQueries();
  }, [timePeriod, sortField, sortOrder]);

  if (hasError) {
    return (
      <Panel width="100%" orientation="vertical" height="300px" padding="none">
        <Spacer size="sm" />
        <Title type="h4" size="lg">
          There was a problem retrieving your recent queries, please try again.
        </Title>
      </Panel>
    );
  }

  if (results === undefined) {
    return <Loading />;
  }

  return (
    <QueryInsightsTableResults
      queries={results}
      setSortField={setSortField}
      setSortOrder={setSortOrder}
      sortField={sortField}
      sortOrder={sortOrder}
      isLoading={isLoading}
    />
  );
};

interface QueryInsightsTableResultsProps {
  queries: ResultData;
  setSortField: (sortField: string) => void;
  setSortOrder: (sortOrder: SortOrder) => void;
  sortField: string;
  sortOrder: SortOrder;
  isLoading: boolean;
}

const QueryInsightsTableResults = ({
  queries,
  setSortField,
  setSortOrder,
  sortField,
  sortOrder,
  isLoading
}: QueryInsightsTableResultsProps): ReactElement => {
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const [shouldShowFlyout, setShouldShowFlyout] = useState<boolean>(false);
  const [flyoutQueryHash, setFlyoutQueryHash] = useState<string>('');
  const openFlyout = (queryHash: string): void => {
    setShouldShowFlyout(true);
    setFlyoutQueryHash(queryHash);
  };

  const closeFlyout = (): void => {
    setShouldShowFlyout(false);
  };

  useEffect(() => {
    if (queries.columns.length) {
      setSelectedColumns(
        queries.columns
          .filter(defaultFilteredColumns)
          .map((column) => column.name)
      );
    }
  }, [queries.columns.length]);

  const filteredRows = applyFilter(queries.rows, currentFilter);

  const selectColumn = (newSelectedColumns: Array<string>): void => {
    // Checking the newSelectedColumns against queries.columns
    // maintains the original order of the columns. This is necessary
    // when showing and hiding columns to keep the data aligned.
    const columnsToShow: Array<string> = [];
    queries.columns.forEach((column: ResultColumn) => {
      if (newSelectedColumns.includes(column.name)) {
        columnsToShow.push(column.name);
      }
    });

    Galaxy.galaxy().track('queryInsights.tableColumns.changed', {
      interaction: 'click',
      visibleColums: columnsToShow
    });
    setSelectedColumns(columnsToShow);
  };

  return (
    <>
      <QueryInsightsFlyout
        handleClose={closeFlyout}
        isOpen={shouldShowFlyout}
        queryHash={flyoutQueryHash}
      />
      <Panel width="100%" orientation="vertical" height="300px" padding="none">
        <Container
          fillWidth
          justifyContent="space-between"
          orientation="horizontal"
        >
          <Title type="h3" size="md">
            Recent queries
          </Title>
          <Container
            maxWidth="400px"
            orientation="horizontal"
            gap="md"
            justifyContent="end"
          >
            <Container maxWidth="175px">
              <SearchField
                isFilter={true}
                onChange={setCurrentFilter}
                placeholder="Filter queries"
                value={currentFilter}
              />
            </Container>
            <Container maxWidth="175px">
              <CheckboxMultiSelect
                value={selectedColumns}
                onSelect={selectColumn}
                selectLabel="Columns"
                data-testid="columnsSelect"
              >
                {queries.columns
                  .filter(unneededColumns)
                  .map((column: ResultColumn) => {
                    return (
                      <CheckboxMultiSelect.Item
                        value={column.name}
                        key={column.name}
                      >
                        {column.name}
                      </CheckboxMultiSelect.Item>
                    );
                  })}
              </CheckboxMultiSelect>
            </Container>
          </Container>
        </Container>
        <PaginatedTable
          allColumns={queries.columns}
          filteredRows={filteredRows}
          openFlyout={openFlyout}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          sortField={sortField}
          visibleColumns={selectedColumns}
          isLoading={isLoading}
        />
        <Container padding="sm" />
      </Panel>
    </>
  );
};

interface PaginatedTable {
  allColumns: Array<ResultColumn>;
  filteredRows: Array<Array<string | null>>;
  openFlyout: (queryHash: string) => void;
  setSortField: (sortField: string) => void;
  setSortOrder: (sortOrder: SortOrder) => void;
  sortField: string;
  sortOrder: SortOrder;
  visibleColumns: Array<string>;
  isLoading: boolean;
}

const PaginatedTable = ({
  allColumns,
  filteredRows,
  openFlyout,
  setSortField,
  setSortOrder,
  sortField,
  sortOrder,
  visibleColumns,
  isLoading
}: PaginatedTable): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const tableHeaders = buildTableHeaders(
    setSortField,
    setSortOrder,
    sortField,
    sortOrder,
    visibleColumns
  );

  const filteredIndices: Array<number> = [];
  allColumns.forEach(({ name }, i) => {
    if (!visibleColumns.includes(name)) {
      filteredIndices.push(i);
    }
  });

  const rowIndex = (currentPage - 1) * resultsPerPage;
  const totalRows = filteredRows.length;
  const totalPages = Math.ceil(totalRows / resultsPerPage);

  // This guards against a user filtering the results down to a page that is smaller
  // than the current page. If a user is on page 20 and filters the results to two pages
  // this will correctly put them on the second page.
  useEffect(() => {
    if (currentPage !== 1 && currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }
  }, [totalPages]);

  const hashIndex = allColumns.findIndex((column) => {
    return column.name === 'normalized_query_hash';
  });

  const pageableRows = [];
  for (
    let paginationIndex = rowIndex;
    paginationIndex < rowIndex + resultsPerPage;
    paginationIndex++
  ) {
    const tableRow = filteredRows[paginationIndex];

    if (tableRow) {
      const items: Array<{ label: ReactNode }> = [];

      tableRow.forEach((cell, cellIndex) => {
        if (filteredIndices.includes(cellIndex)) {
          return;
        }
        items.push({
          label: (
            <EllipsisContainer as={Text} size="sm">
              {formatCell(cell as string, allColumns[cellIndex].name)}
            </EllipsisContainer>
          )
        });
      });

      pageableRows.push({
        className: 'recent-query-row fs-exclude',
        id: paginationIndex,
        items,
        onClick: () => {
          Galaxy.galaxy().track('queryInsights.flyout.opened', {
            interaction: 'click',
            queryHash: tableRow[hashIndex]
          });
          openFlyout(tableRow[hashIndex] as string);
        },
        style: { cursor: 'pointer' }
      });
    }
  }

  return (
    <>
      <RelativeContainer>
        {isLoading && (
          <LoadingOverlay padding="none">
            <Container
              fillWidth
              fillHeight
              alignItems="center"
              justifyContent="center"
            >
              <Icon name="horizontal-loading" size="xxl" />
            </Container>
          </LoadingOverlay>
        )}
        <Table headers={tableHeaders} rows={pageableRows} size="sm" />
      </RelativeContainer>
      <Container>
        <Pagination
          currentPage={currentPage}
          onChange={setCurrentPage}
          rowCount={totalRows}
          totalPages={totalPages}
        />
      </Container>
    </>
  );
};

const Loading = (): ReactElement => {
  return (
    <Panel width="100%" orientation="vertical" height="300px" padding="none">
      <Spacer size="sm" />
      <Container
        fillWidth={true}
        justifyContent="space-between"
        orientation="horizontal"
      >
        <Title type="h3" size="md">
          Recent queries
        </Title>
      </Container>
      <Table loading={true} headers={[]} rows={[]} />
    </Panel>
  );
};

const applyFilter = (rows: Array<Row>, filter: string): Array<Row> => {
  if (!rows.length || filter === '') {
    return rows;
  }

  return rows.filter((row: Row) => {
    return row.find((item: string | null) => {
      return item && item.toLowerCase().includes(filter.toLowerCase());
    });
  });
};

const unneededColumns = (column: ResultColumn): boolean => {
  return !['query_performance_raw', 'normalized_query_hash'].includes(
    column.name
  );
};
const defaultFilteredColumns = (column: ResultColumn): boolean => {
  return (
    !DEFAULT_HIDDEN_COLUMNS.includes(column.name) && unneededColumns(column)
  );
};
const nameToWidthMapping: { [key: string]: string } = {
  User: '15%',
  Runs: '7%',
  Errors: '7%',
  'p50 (s)': '7%',
  'p90 (s)': '7%',
  'p99 (s)': '7%',
  'Avg. Written rows': '10%',
  'Avg. Read rows': '9%',
  'Avg. Mem Usage': '10%',
  'Last run (UTC)': '10%'
};

const isSortFieldColumnName = (
  sortField: string,
  columnName: string
): boolean => {
  return sortField === `"${columnName}"`;
};

const ClickableContainer = styled(Container)`
  cursor: pointer;
`;

const buildTableHeaders = (
  setSortField: (sortField: string) => void,
  setSortOrder: (sortOrder: SortOrder) => void,
  sortField: string,
  sortOrder: SortOrder,
  visibleColumns: Array<string>
): Array<TableHeaderType> => {
  const TableHeader = ({
    columnName
  }: {
    columnName: string;
  }): ReactElement => {
    const iconName = sortOrder === 'ASC' ? 'chevron-up' : 'chevron-down';
    return (
      <ClickableContainer
        onClick={() => {
          if (!isSortFieldColumnName(sortField, columnName)) {
            setSortField(`"${columnName}"`);
            if (sortOrder !== 'ASC') {
              setSortOrder('ASC');
            }
          } else {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
          }
        }}
        orientation="horizontal"
        data-testid={`paginated-table-header-${columnName}`}
        data-sortorder={sortOrder}
      >
        {columnName}
        {isSortFieldColumnName(sortField, columnName) && (
          <Icon name={iconName} size="sm" />
        )}
      </ClickableContainer>
    );
  };

  const tableHeaders = visibleColumns.map((columnName: string) => {
    const width = nameToWidthMapping[columnName] ?? 'auto';

    return { label: <TableHeader columnName={columnName} />, width };
  });

  return tableHeaders;
};

const formatCell = (cell: string, headerName: string): ReactNode => {
  if (headerName === 'Table') {
    return convertTablesArrayToString(cell);
  }
  if (headerName === 'Last run (UTC)') {
    return formatDateToTimeOrDate(new Date(cell));
  }
  if (headerName === 'Avg. Mem Usage') {
    return formatHumanReadableSize(parseFloat(cell));
  }
  if (headerName === 'User' && cell.startsWith('sql-console:')) {
    return cell.substring(12);
  }
  if (
    [
      'Total time (ms)',
      'p50 (s)',
      'p99 (s)',
      'Avg. Written rows',
      'Avg. Read rows'
    ].includes(headerName)
  ) {
    return formatNumber(parseFloat(cell));
  }
  return cell;
};

const convertTablesArrayToString = (cell: string): ReactNode => {
  const tables = JSON.parse(cell) as Array<string>;
  if (!Array.isArray(tables)) {
    return '--';
  }
  if (!tables[0]) {
    return '--';
  }
  if (tables.length === 1) {
    return tables[0];
  }
  return tables.join(', ');
};
