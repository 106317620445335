import { ReactElement, useEffect } from 'react';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import { QueryInsights } from 'src/components/QueryInsights/QueryInsights';
import Helmet from 'src/components/Helmet/Helmet';
import SplitLayout from 'src/layout/SplitLayout';
import { Galaxy } from 'galaxy';

export function QueryInsightsPage(): ReactElement {
  const instance = useCurrentInstanceOrThrow();

  useEffect(() => {
    try {
      Galaxy.galaxy().track('queryInsights.page.load');
    } catch {
      console.log('queryInsights.page.load');
    }
  }, []);

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar
        label={`${instance.name} - Monitoring / Query insights`}
      />
      <MainPaneLayout.Content>
        <Helmet>
          <title>{instance.name} - Monitoring / Query insights</title>
        </Helmet>
        <SplitLayout>
          <QueryInsights />
        </SplitLayout>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
}
