import { Text, Link } from '@clickhouse/click-ui';
import { ReactElement } from 'react';

interface LanguageClientExtrasProps {
  title: string;
  docsLink: string;
  examplesLink: string;
}

const LanguageClientExtras = ({
  title,
  docsLink,
  examplesLink
}: LanguageClientExtrasProps): ReactElement => (
  <Text>
    Visit the documentation to learn how to query and insert the data using the{' '}
    <Link href={docsLink} target="_blank" icon="share-arrow">
      {title}
    </Link>
    See also the{' '}
    <Link href={examplesLink} target="_blank" icon="share-arrow">
      examples
    </Link>
    in the project repository.
  </Text>
);

export default LanguageClientExtras;
