import { ReactElement, ReactNode } from 'react';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { useInitQueryApiEndpointState } from 'src/queryEndpoints/queryEndpointsController/useInitQueryApiEndpointsState';

export function QueryApiEndpointsInitializer({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const serviceId = useCurrentInstanceOrThrow().id;
  useInitQueryApiEndpointState(serviceId);

  return <>{children}</>;
}
