import { useMemo } from 'react';
import { InstanceActionInput } from 'src/instance/instanceApiClient';
import { createToast } from '@clickhouse/click-ui';
import {
  generateClickHouseDbPassword,
  makeDoubleSha1HashBrowser,
  makeSha256Base64Browser
} from 'src/instance/instance';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useInstanceStateManager } from 'src/instance/instanceState';
import useInstanceMySqlSettingsStateManager from 'src/instance/InstanceMySqlSettingsState';
import { UpdateMysqlSettingsRequest } from '@cp/common/protocol/Instance';

interface InstanceMySqlSettingsController {
  loadMySqlSettings: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
  updateMysqlSettings: ({
    enabled,
    instanceId,
    organizationId
  }: Omit<UpdateMysqlSettingsRequest, 'rpcAction'>) => Promise<void>;
  backfillMysqlPassword: ({ instanceId, organizationId }: InstanceActionInput) => Promise<void>;
}

const useInstanceMySqlSettingsController = (): InstanceMySqlSettingsController => {
  const apiClient = useApiClient();
  const { setMySqlPassword } = useInstanceStateManager();
  const { setMysqlSettings, setMySqlSettingsLoading } = useInstanceMySqlSettingsStateManager();

  return useMemo(() => {
    /**
     * Load MySQL settings for a specific instance.
     * @param input - The instance and organization IDs.
     * @returns A promise that resolves when the settings have been loaded.
     */
    const loadMySqlSettings = async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      setMySqlSettingsLoading(true);
      try {
        const settings = await apiClient.instance.getMysqlSettings({
          instanceId,
          organizationId
        });
        setMysqlSettings(settings);
      } catch (e) {
        console.error(e);
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error fetching instance MySQL settings.'
        });
      } finally {
        setMySqlSettingsLoading(false);
      }
    };

    /**
     * Update MySQL settings for a specific instance.
     * @param input - The new settings, instance ID, and organization ID.
     * @returns A promise that resolves when the settings have been updated.
     */
    const updateMysqlSettings = async ({
      enabled,
      instanceId,
      organizationId
    }: Omit<UpdateMysqlSettingsRequest, 'rpcAction'>): Promise<void> => {
      try {
        await apiClient.instance.updateMysqlSettings({
          enabled,
          instanceId,
          organizationId
        });
        await loadMySqlSettings({ instanceId, organizationId });
      } catch (e) {
        console.error(e);
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error updating instance MySQL settings.'
        });
      }
    };

    /**
     * Generate and update MySQL password for a specific instance.
     * Updates the password in the instanceState as a side effect.
     * @param params - instance ID, and organization ID.
     * @returns A promise that resolves when the password has been backfilled.
     */
    const backfillMysqlPassword = async ({ instanceId, organizationId }: InstanceActionInput): Promise<void> => {
      try {
        const password = generateClickHouseDbPassword();
        const sha256Password = await makeSha256Base64Browser(password);
        const doubleSha1Password = await makeDoubleSha1HashBrowser(password);
        await apiClient.instance.backfillMysqlPassword({
          doubleSha1Password,
          sha256Password,
          instanceId,
          organizationId
        });
        setMySqlPassword(instanceId, password);
        createToast({
          title: 'Success',
          type: 'success',
          description: 'Password reset successfully.'
        });
      } catch (e) {
        console.error(e);
        createToast({
          title: 'Error',
          type: 'danger',
          description: 'Error resetting service MySQL password.'
        });
      }
    };

    return {
      loadMySqlSettings,
      updateMysqlSettings,
      backfillMysqlPassword
    };
  }, [apiClient.instance, setMySqlSettingsLoading, setMysqlSettings, setMySqlPassword]);
};

export default useInstanceMySqlSettingsController;
