import React, { useEffect } from 'react';

import { css } from '@emotion/react';
import { useDrop } from 'react-dnd-cjs';

import { getColumnType } from 'src/lib/chart';

import Column from './Column';
import { singleColumnStyle } from './styles';
import { useCUITheme } from '@clickhouse/click-ui';

const axisStyle = (theme) => css`
  /* Inter Semi Bold 11 */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  /* identical to box height, or 100% */
  display: flex;
  align-items: center;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  span {
    font-weight: 500;
    margin-left: 10px;
  }
`;

export default function DataDrop({
  axis,
  chartConfig,
  data,
  dataConfig,
  editColumn,
  onDragEnd,
  setHoveringData,
  removeColumn
}) {
  const cuiTheme = useCUITheme();

  const dragInfoStyle = (isOver) => (theme) => css`
    margin-top: 10px;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    height: 28px;
    color: ${cuiTheme.global.color.text.default};
    background: ${cuiTheme.global.color.background.muted};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    border: 1px dashed
      ${isOver
        ? cuiTheme.global.color.outline.default
        : cuiTheme.global.color.stroke.intense};
    border-radius: 4px;
  `;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'column',
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
    },
    drop: (item, monitor) => {
      return {
        dropZone: axis
      };
    },
    canDrop: (item, monitor) => {
      if (dataConfig.dataType === 'any') {
        return true;
      }

      return dataConfig.dataType === item.dataType;
    }
  });

  useEffect(() => {
    if (canDrop && isOver) {
      setHoveringData(true);
    } else {
      setHoveringData(false);
    }
  }, [canDrop, isOver]);

  if (!data) {
    return null;
  }

  if (dataConfig && dataConfig.type === 'column') {
    return (
      <div css={singleColumnStyle} ref={drop}>
        <div css={axisStyle}>
          <div>
            <strong>{dataConfig.label}</strong>
          </div>
          <span>({dataConfig.dataType})</span>
        </div>

        {chartConfig[axis] && (
          <Column
            axis={axis}
            removeColumn={removeColumn}
            column={chartConfig[axis]}
            onDragEnd={onDragEnd}
            source={axis}
            type={getColumnType(data, chartConfig[axis])}
          />
        )}
        {!chartConfig[axis] && (
          <div css={dragInfoStyle(isOver && canDrop)}>DRAG COLUMN HERE</div>
        )}
      </div>
    );
  } else if (dataConfig && dataConfig.type === 'columnArray') {
    return (
      <div ref={drop}>
        <div css={axisStyle}>
          <div>
            <strong>{dataConfig.label}</strong>
          </div>
          <span>({dataConfig.dataType})</span>
        </div>
        {Array.isArray(chartConfig[axis]) &&
          chartConfig[axis].map((column, i) => (
            <Column
              axis={axis}
              removeColumn={removeColumn}
              column={column}
              key={`chart-col-${i}`}
              onDragEnd={onDragEnd}
              source={axis}
              type={getColumnType(data, column.column)}
            />
          ))}
        <div css={dragInfoStyle(isOver && canDrop)}>DRAG COLUMNS HERE</div>
      </div>
    );
  } else {
    return null;
  }
}
