import { KeyboardEvent, ReactElement, useState } from 'react';
import { produce } from 'immer';
import { createToast } from 'primitives';
import { useHotkeys } from 'react-hotkeys-hook';
import IconButtonNotification from 'src/components/TableView/DataView/Toolbar/IconButtonNotification';
import SortOption from 'src/components/TableView/DataView/Toolbar/Sort/SortOption';
import { SchemaColumn } from 'shared/src/clickhouse/types';
import { InteractionType } from 'src/lib/logger';
import { Sort as SortType } from 'src/state/tabs/types';
import styles from 'src/components/TableView/DataView/Toolbar/Sort/styles';
import { Button, Popover } from '@clickhouse/click-ui';

const emptySort = {
  direction: 'ASC'
};

interface SortsProps {
  currentSorts: Array<SortType>;
  columns: Array<SchemaColumn>;
  logEvent: (
    component: string,
    event: string,
    intergration?: InteractionType
  ) => void;
  onSortApply: (sorts: Array<SortType>) => void;
}

export default function Sort({
  currentSorts,
  columns,
  logEvent,
  onSortApply
}: SortsProps): ReactElement {
  const [open, setOpen] = useState(false);
  const [cachedSorts, setCachedSorts] = useState<Array<SortType>>(
    currentSorts?.length > 0 ? currentSorts : [emptySort]
  );

  useHotkeys(
    'meta+backspace, ctrl+backspace',
    () => {
      if (cachedSorts.length > 0) {
        removeSort(cachedSorts.length - 1);
      }
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      enabled: (e, handler) => {
        if (cachedSorts.length > 0) {
          return (
            ((handler.meta || handler.ctrl) &&
              e.key.toLowerCase() === 'backspace') ??
            false
          );
        }
        return false;
      },
      preventDefault: true
    },
    [setCachedSorts, cachedSorts]
  );

  const getInvalidSort = (): SortType | undefined => {
    return cachedSorts.find(
      (sort) =>
        (sort.column ?? '').length === 0 ||
        !['ASC', 'DESC'].includes(sort.direction ?? '')
    );
  };

  const getInvalidSortMessage = (sort: SortType): string => {
    if ((sort.column ?? '').length) {
      return 'Select a column to apply sort';
    }
    return 'Select ascending or descending to apply sort';
  };

  const applySorts = (): void => {
    logEvent('filterSortPane', 'applyButtonClick');

    const invalidSort = getInvalidSort();

    if (invalidSort) {
      createToast(
        'Sort cannot be applied',
        'alert',
        getInvalidSortMessage(invalidSort)
      );
      return;
    }

    onSortApply(cachedSorts);
  };

  const resetSorts = (): void => {
    setCachedSorts([emptySort]);
    onSortApply([]);
  };

  const removeSort = (idx: number) => () => {
    logEvent('sortSection', 'deleteButtonClick');
    setCachedSorts(
      produce(cachedSorts, (draft) => {
        draft.splice(idx, 1);
        if (draft.length === 0) {
          draft = [emptySort];
        }
      })
    );

    if (cachedSorts.length === 1) {
      resetSorts();
    }
  };

  const updateSort = (idx: number) => (sort: SortType) => {
    setCachedSorts(
      produce(cachedSorts, (sorts) => {
        const currentSort = sorts[idx];
        Object.assign(currentSort, sort);
      })
    );
  };

  const close = (): void => {
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <IconButtonNotification
          icon="sort-alt"
          type="ghost"
          data-testid="toolbarSortBtn"
          count={currentSorts.length}
        />
      </Popover.Trigger>
      <Popover.Content showArrow sideOffset={10} side="bottom">
        <div
          css={styles.container}
          role="button"
          tabIndex={0}
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
            if (e.key === 'Enter' && !getInvalidSort()) {
              e.stopPropagation();
              applySorts();
            }
          }}
          data-testid="toolbarSortContent"
        >
          <div css={styles.header}>
            <div css={styles.headerColumn}>Sort by column</div>
            <div css={styles.headerCriteria}>Order by...</div>
          </div>
          <div css={styles.sortListContainer}>
            {cachedSorts.map((sort, i) => (
              <SortOption
                {...sort}
                key={i}
                removeSort={removeSort(i)}
                columns={columns}
                updateSort={updateSort(i)}
                logEvent={(
                  event: string,
                  interaction?: InteractionType
                ): void => logEvent('sortSection', event, interaction)}
              />
            ))}
          </div>
          <div css={styles.footer}>
            <div css={styles.footerSub}>
              <Button
                type="secondary"
                onClick={(): void =>
                  setCachedSorts((sorts) => [...sorts, emptySort])
                }
              >
                Add new Sort
              </Button>
              <Button
                css={styles.applyButton}
                onClick={(): void => {
                  applySorts();
                  close();
                }}
                color="yellow"
                disabled={!!getInvalidSort()}
              >
                Apply
              </Button>
            </div>
            {cachedSorts.length > 1 && (
              <Button onClick={resetSorts} type="secondary">
                Clear All
              </Button>
            )}
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
}
