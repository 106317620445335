import { BigStat, Container, Icon } from '@clickhouse/click-ui';
import { INSTANCE_BACKUP_INTERVAL_MILLIS } from '@cp/common/protocol/Backup';
import {
  INSTANCE_BACKUP_DEFAULT_UI_CUSTOM_PERIOD,
  InstanceBackupConfiguration
} from '@cp/common/protocol/Instance';
import {
  MILLIS_PER_HOUR,
  MILLIS_PER_MINUTE
} from '@cp/common/utils/DateTimeUtils';
import { ReactElement } from 'react';
import {
  formatMoment,
  humanizeDuration
} from 'src/lib/formatters/dateTimeFormatter';

export interface HeaderSectionProps {
  lastBackupTime: number;
  backupConfiguration: InstanceBackupConfiguration;
  loading: boolean;
}

export const HeaderSection = ({
  lastBackupTime,
  backupConfiguration,
  loading
}: HeaderSectionProps): ReactElement => {
  const backupFreq =
    backupConfiguration.customBackupPeriod ??
    INSTANCE_BACKUP_INTERVAL_MILLIS / MILLIS_PER_HOUR;
  const lastBackup =
    lastBackupTime === 0 ? 'N/A' : formatMoment(lastBackupTime);
  const nextBackup = calcNextBackupTime(lastBackupTime, backupConfiguration);
  const freq =
    backupFreq !== 24
      ? `Every ${humanizeDuration(backupFreq, 'hours')}`
      : 'Daily';

  return (
    <Container fillWidth justifyContent="space-between" gap="lg">
      <BigStat
        label="Last successful backup"
        size="sm"
        state="default"
        fillWidth
        height="98px"
        title={
          loading ? <Icon name="horizontal-loading" size="lg" /> : lastBackup
        }
      />

      <BigStat
        label="Next scheduled backup"
        size="sm"
        state="default"
        fillWidth
        height="98px"
        title={
          loading ? <Icon name="horizontal-loading" size="lg" /> : nextBackup
        }
      />
      <BigStat
        label="Backup frequency"
        size="sm"
        state="default"
        fillWidth
        height="98px"
        title={loading ? <Icon name="horizontal-loading" size="lg" /> : freq}
      />
    </Container>
  );
};

const calcNextBackupTime = (
  lastBackupTime: number,
  backupConfiguration: InstanceBackupConfiguration
): string => {
  let nextScheduledBackupTime = 0;
  if (backupConfiguration.customBackupStartTime !== undefined) {
    const now = new Date();
    const scheduledStartTime = new Date();
    const [startHour, startMinute] = backupConfiguration.customBackupStartTime
      .split(':')
      .map(Number);
    scheduledStartTime.setUTCHours(startHour, startMinute);

    // scheduled start time in the past, next backup will be performed tomorrow
    if (scheduledStartTime.getTime() < now.getTime()) {
      scheduledStartTime.setTime(
        scheduledStartTime.getTime() +
          INSTANCE_BACKUP_DEFAULT_UI_CUSTOM_PERIOD * MILLIS_PER_HOUR
      );
    }
    nextScheduledBackupTime = scheduledStartTime.getTime();
  } else {
    const intervalInHours =
      backupConfiguration.customBackupPeriod ??
      INSTANCE_BACKUP_DEFAULT_UI_CUSTOM_PERIOD;
    nextScheduledBackupTime =
      lastBackupTime + intervalInHours * MILLIS_PER_HOUR;
  }

  // do not be precise when less than 10 minutes because the cronJob might not run precisely at the given time,
  // and it also could take seconds to generate the backup
  if (nextScheduledBackupTime - Date.now() <= 10 * MILLIS_PER_MINUTE) {
    return 'Shortly';
  } else {
    return formatMoment(nextScheduledBackupTime);
  }
};
