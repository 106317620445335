import { STATES } from '@cp/common/protocol/Region';

export const STATES_SELECT = Object.fromEntries(
  Object.entries(STATES).map(([k, v]) => [
    k,
    v.map((r) => ({
      label: r.name,
      value: r.code.split('-')[1],
      dataTestId: 'state-option',
      dataTestIdValue: r.code.split('-')[1]
    }))
  ])
);
