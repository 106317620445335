import { ReactElement, useEffect, useRef, useState } from 'react';

import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { QueryProgress } from 'src/lib/query/streamingQuery';

import ProgressToolbarStatus from 'src/components/QueryView/Results/ResultToolbar/QueryProgress/ProgressToolbarStatus';

const SHOW_ICONS_MARGIN_HEIGHT = 55;
const SHOW_TITLES_MARGIN_WIDTH = 1200;

type ProgressToolbarStatusContainerProps = {
  status: RunningQueryStatusName;
  progress?: QueryProgress;
};

const ProgressToolbarStatusContainer = (
  props: ProgressToolbarStatusContainerProps
): ReactElement => {
  const [showIcons, setShowIcons] = useState(false);
  const controlRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = (): void => {
      if (!controlRef.current) {
        return;
      }
      const divHeight = controlRef.current.offsetHeight;
      const divWidth = controlRef.current.offsetWidth;
      // Show the icons if the div height is greater than one row height and the icons are not visible
      // Hide the icons if the div width is greater than the margin and the icons are visible
      setShowIcons(
        (oldShowIcon) =>
          (!oldShowIcon && divHeight > SHOW_ICONS_MARGIN_HEIGHT) ||
          (oldShowIcon && divWidth <= SHOW_TITLES_MARGIN_WIDTH)
      );
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [props.progress]);

  return (
    <div ref={controlRef}>
      <ProgressToolbarStatus
        status={props.status}
        progress={props.progress}
        showIcons={showIcons}
      />
    </div>
  );
};

export default ProgressToolbarStatusContainer;
