import { css } from '@emotion/react';

export const chartNoDataContainerStyle = css`
  display: flex;
  flex-flow: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const messageContainerStyle = css`
  display: inline-flex;
  flex-flow: column;
  margin: auto;
`;

export const messageStyle = css`
  margin-top: 15px;
`;

export const invalidConfigMessageStyle = (theme) => css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
`;

export const menuButtonStyle = (open) => css`
  position: absolute;
  top: 5px;
  right: 15px;
  display: ${open ? 'block' : 'none'};
`;

export const chartWrapperStyle = css`
  position: absolute;
  display: flex;
  flex-flow: column;
  height: calc(100% - 27px);
  width: 100%;
  padding: 10px 15px 15px 15px;

  &:hover {
    .chart-menu-button {
      display: block;
    }
  }
`;

export const chartContainer = css`
  position: relative;
  width: 100%;
  flex: 1;
  height: 100%;
`;
