import React from 'react';

import { css } from '@emotion/react';

const textStyle = (theme) => css`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: right;
  color: ${theme.colors.c4};
  fill: ${theme.colors.c4};
  user-select: inherit;
`;

function AxisTick(props) {
  const { charLimit, x, y, payload } = props;
  const value = payload.value
    ? payload.value.length > charLimit
      ? payload.value.substring(0, charLimit - 4) + '...'
      : payload.value
    : '';
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        className="custom-axis-tick"
        css={textStyle}
        x={0}
        y={0}
        textAnchor="end"
        transform="rotate(-50)"
      >
        {value}
      </text>
    </g>
  );
}

export default AxisTick;
