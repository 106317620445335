import { IconButton, IconButtonProps } from '@clickhouse/click-ui';
import styled from 'styled-components';

const CustomIconButton = styled(IconButton)<{ $count?: number }>`
  ${({ $count, theme }) =>
    $count && $count > 0
      ? `
      position: relative;
  &::after {
    content: "${$count}";
    position: absolute;
    bottom: calc(100% - 12px);
    left: calc(100% - 12px);
    font-weight: 500;
    font-size: 8px;
    background: ${theme.global.color.iconButton.badge.background};
    border-radius: 50%;
    color: ${theme.global.color.iconButton.badge.foreground};
    display: flex;
    place-items: center;
    min-width: 12px;
    aspect-ratio: 1/1;
    padding-left: 1ch;
    padding-right: 1ch;
  }
  `
      : ''}
`;
const IconButtonNotification = ({
  count,
  ...props
}: IconButtonProps & { count?: number }) => {
  return <CustomIconButton $count={count} {...props} />;
};

export default IconButtonNotification;
