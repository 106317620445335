import { KeyboardEvent, ReactElement, useState } from 'react';
import { produce } from 'immer';
import { createToast } from 'primitives';
import { useHotkeys } from 'react-hotkeys-hook';

import { SchemaColumn } from 'shared/src/clickhouse/types';
import IconButtonNotification from 'src/components/TableView/DataView/Toolbar/IconButtonNotification';
import { InteractionType } from 'src/lib/logger';
import { Filter as FilterType } from 'src/state/tabs/types';
import FilterOption from 'src/components/TableView/DataView/Toolbar/Filter/FilterOption';
import styles from 'src/components/TableView/DataView/Toolbar/Filter/styles';
import { Button, Popover } from '@clickhouse/click-ui';

const emptyFilter = {
  operator: '=',
  value: ''
};

interface FiltersProps {
  currentFilters: Array<FilterType>;
  columns: Array<SchemaColumn>;
  logEvent: (
    component: string,
    event: string,
    intergration?: InteractionType
  ) => void;
  onFilterApply: (filters: Array<FilterType>) => void;
}

export default function Filter({
  currentFilters,
  columns,
  logEvent,
  onFilterApply
}: FiltersProps): ReactElement {
  const [open, setOpen] = useState(false);
  const [cachedFilters, setCachedFilters] = useState<Array<FilterType>>(
    currentFilters?.length > 0 ? currentFilters : [emptyFilter]
  );

  useHotkeys(
    'meta+backspace, ctrl+backspace',
    () => {
      if (cachedFilters.length > 0) {
        removeFilter(cachedFilters.length - 1);
      }
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA'],
      enabled: (e, handler) => {
        if (cachedFilters.length > 0) {
          return (
            ((handler.meta || handler.ctrl) &&
              e.key.toLowerCase() === 'backspace') ??
            false
          );
        }
        return false;
      },
      preventDefault: true
    },
    [setCachedFilters, cachedFilters]
  );

  const getInvalidFilter = (): FilterType | undefined => {
    return cachedFilters.find((filter) => {
      if (
        filter.column &&
        filter.column.length > 0 &&
        filter.operator &&
        filter.operator.length > 0
      ) {
        if (['isNull', 'isNotNull'].includes(filter.operator || '')) {
          return false;
        }

        return (filter.value ?? '').length === 0;
      }

      return true;
    });
  };

  const getInvalidFilterMessage = (filter: FilterType): string => {
    let toastMessage = 'Input a value to apply filter';
    if ((filter.column ?? '').length === 0) {
      toastMessage = 'Select a column to apply filter';
    } else if ((filter.operator ?? '').length === 0) {
      toastMessage = 'Select a criterion to apply filter';
    }
    return toastMessage;
  };
  const applyFilters = (): void => {
    logEvent('filterSortPane', 'applyButtonClick');
    const invalidFilter = getInvalidFilter();

    if (invalidFilter) {
      createToast(
        'FilterOption cannot be applied',
        'alert',
        getInvalidFilterMessage(invalidFilter)
      );
      return;
    }

    onFilterApply(cachedFilters);
  };

  const resetFilters = (): void => {
    setCachedFilters([emptyFilter]);
    onFilterApply([]);
  };

  const removeFilter = (idx: number) => (): void => {
    logEvent('filterSection', 'deleteButtonClick');
    setCachedFilters(
      produce(cachedFilters, (filters) => {
        filters.splice(idx, 1);
        if (filters.length === 0) {
          filters.push(emptyFilter);
        }
      })
    );

    if (cachedFilters.length === 1) {
      resetFilters();
    }
  };

  const updateFilter =
    (idx: number) =>
    (filter: FilterType): void => {
      setCachedFilters(
        produce(cachedFilters, (filters) => {
          const currentFilter = filters[idx];
          Object.assign(currentFilter, filter);
        })
      );
    };

  const close = (): void => {
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <IconButtonNotification
          count={currentFilters.length}
          type="ghost"
          icon="filter"
          data-testid="toolbarFilterBtn"
        />
      </Popover.Trigger>
      <Popover.Content showArrow sideOffset={10} side="bottom">
        <div
          css={styles.container}
          role="button"
          tabIndex={0}
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
            if (e.key === 'Enter' && !getInvalidFilter()) {
              e.stopPropagation();
              applyFilters();
            }
          }}
          data-testid="toolbarFilterContent"
        >
          {cachedFilters.length > 0 && (
            <div css={styles.header}>
              <div css={styles.headerColumn}>Filter by column</div>
              <div css={styles.headerCriteria}>Criteria</div>
              <div css={styles.headerValue}>Value</div>
            </div>
          )}
          <div css={styles.filterListContainer}>
            {cachedFilters.map((filter, i) => (
              <FilterOption
                {...filter}
                key={i}
                removeFilter={removeFilter(i)}
                columns={columns}
                updateFilter={updateFilter(i)}
                logEvent={(
                  event: string,
                  interaction?: InteractionType
                ): void => logEvent('filterSection', event, interaction)}
              />
            ))}
          </div>
          <div css={styles.footer}>
            <div css={styles.footerSub}>
              <Button
                type="secondary"
                onClick={(): void =>
                  setCachedFilters((filters) => [...filters, emptyFilter])
                }
              >
                Add new Filter
              </Button>
              <Button
                css={styles.applyButton}
                onClick={(): void => {
                  applyFilters();
                  close();
                }}
                disabled={!!getInvalidFilter()}
              >
                Apply
              </Button>
            </div>

            {cachedFilters.length > 1 && (
              <Button
                css={styles.applyButton}
                onClick={resetFilters}
                type="secondary"
              >
                Clear All
              </Button>
            )}
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
}
