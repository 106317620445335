import {
  AcceptAccountTOSRequest,
  CheckUserHasAcceptedTOSRequest,
  CheckUserHasAcceptedTOSResponse,
  CreatePasswordChangeTicketResponse,
  InitializeUserSessionRequest,
  InitializeUserSessionResponse,
  MfaMethod,
  UserDetailsUpdate,
  UserPreferences
} from '@cp/common/protocol/Account';
import { useMemo } from 'react';
import { DatabaseStructure, TableDetails } from 'shared/src/clickhouse/types';
import { PasswordCredentialResponse, WakeServiceResponse } from 'shared/src/types/service';

import { ApiKeyApiClient } from 'src/apiKey/apiKeyApiClient';
import { BackupsApiClient } from 'src/backups/backupsApiClient';
import { BillingApiClient } from 'src/billing/billingApiClient';
import { DataLoadingApiClient, ListImportsProps } from 'src/dataloading/dataloadingApiClient';
import { InstanceApiClient } from 'src/instance/instanceApiClient';
import { Features } from 'src/lib/auth/types';
import { apiUrl, controlPlaneUrl } from 'src/lib/controlPlane/apiUrls';
import { MetricsApiClient } from 'src/metrics/metricsApiClient';
import { OrganizationApiClient } from 'src/organization/organizationApiClient';
import { SupportApiClient } from 'src/support/supportApiClient';
import { QueryEndpointApiClient } from 'src/queryEndpoints/queryEndpointApiClient';
import {
  ClickPipe,
  ErrorResponse,
  GetClickPipeMetricRequest,
  GetKafkaSampleRequest,
  GetKinesisSampleRequest,
  ListTopicsRequest,
  ListStreamsRequest,
  ObjectStorageConnectionCheckRequest,
  ObjectStorageSourceListing,
  ObjectStorageSourceListingRequest,
  SampleSchemaRequest,
  SampleSchemaResponse,
  SchemaRegistryRequest,
  PostgresSourceRequest,
  ValidatePostgresResponse,
  ListPublicationsResponse,
  ListClickhouseDatabasesResponse,
  ListTablesResponse,
  ListSchemasResponse,
  PostgresListTableRequest,
  PostgresValidateTablesRequest,
  ClickPipeModelWrapper
} from 'types/protocol';
import { ErrorResponse as SharedErrorResponse } from 'shared';
import { PASSWORD_CREDENTIAL_ERROR } from 'shared/src/errorCodes';
import { GptFeedbackParams } from 'types/protocol/gpt';
import { HttpClient, useHttpClient } from 'src/lib/http';
import { AccountApiClient } from 'src/account/accountApiClient';
import { S3HeadObjectRequest, S3HeadObjectResponse } from 'types/protocol/s3HeadObjectProtocol';
import { IntegrationApiClient } from 'src/integrations/integrationApiClient';
import { InstanceState } from '@cp/common/protocol/Instance';
import { DriftApiClient } from 'src/drift/driftApiClient';
import { NodeChangeType, NodeType } from 'shared/src/types/queryFolders';
import { ClickPipeSourceType } from 'shared/src/dataLoading';
import { Credential } from 'src/state/connection';
import { DashboardApiClient } from 'src/dashboard/dashboardApiClient';
import { NotificationApiClient } from 'src/notifications/notificationApiClient';
import { DataWarehouseApiClient } from 'src/dataWarehouses/dataWarehouseApiClient';

const ACCOUNT_API_PATH = '/api/account';

export interface Service {
  id: string;
  name: string;
  state: InstanceState;
  dbUsername: string;
  organizationId: string;
  featureFlags: string[];
  endpoints: {
    nativesecure: {
      hostname: string;
      port: number;
    };
    https: {
      hostname: string;
      port: number;
    };
  };
}

export interface ServicesPerOrganization {
  id: string;
  name: string;
  services: Service[];
}

interface ServicesResponse {
  data: ServicesPerOrganization[];
}

interface ServiceStatusResponse {
  data: {
    isAwake: boolean;
  };
}

interface ServiceStatus {
  isAwake: boolean;
}

export type saveQueryFolderTreeProps = {
  path?: string;
  serviceId: string;
  tree: string;
  type?: NodeChangeType;
  value?: NodeType | string;
};

export type SaveQueryFolderTreeResponse = {
  serviceId: string;
  tree: string;
  created_at: string;
  updatedAt: string;
};

export type getQueryFolderTreeProps = {
  serviceId: string;
};

export type GetQueryFolderTreeResponse = {
  serviceId: string;
  tree: string;
};

/**
 * Request parameters for the `getGptConstruction` function.
 */
export interface getGptConstructionRequest {
  requestText: string;
  serviceId: string;
  credential: Credential;
}

/**
 * Defines the structure for the request parameters required by the `getGptCorrection` function.
 * These parameters are used when making a request to the `/gpt/correct` endpoint.
 */
export interface GptCorrectionRequest {
  queryText: string; // The original query text.
  error: string; // The error message associated with the original query.
  serviceId: string; // The ID of the service instance.
  credential: Credential; // The credentials used to connect to the database.
}

/**
 * Defines the structure for the response returned by the `getGptCorrection` function.
 * This response is received from the `/gpt/correct` endpoint.
 */
export type GtpCorrectionResponse = {
  completion: string; // The corrected query text.
  traceId: string; // Autoblocks trace ID for the correction.
};

/**
 * Defines the structure for the response returned by the `getGptConstruction` function.
 * This response is received from the `/gpt/construct` endpoint.
 */
export type GtpConstructionResponse = {
  completion: string; // The constructed query text.
  traceId: string; // Autoblocks trace ID for the GPT request trace.
};

/**
 * The structure of a request for GPT inline code completion,
 * which is sent from the Web client to the API.
 */
export type GptInlineCodeCompletionRequest = {
  codeBeforeCursor: string;
  codeAfterCursor: string;
  serviceId: string;
  credential: Credential;
  databaseName?: string;
};

/**
 * Defines the structure API response from '/gpt/codecomplete'.
 */
type GptInlineCodeCompletionResponse = {
  completion: string;
};

type UserDetailsResponse = {
  role: string;
  features: Features;
};

export interface UserDetailsMfaUpdate {
  mfaPreferredMethod: MfaMethod;
}

export interface fetchMetadataProps {
  serviceId: string;
  cached: boolean;
  database: string;
  wakeService: boolean;
  isStopped: boolean;
  credentials: Credential;
}

export type ApiClient = {
  account: AccountApiClient;
  apiKey: ApiKeyApiClient;
  backups: BackupsApiClient;
  billing: BillingApiClient;
  dashboard: DashboardApiClient;
  dataloading: DataLoadingApiClient;
  instance: InstanceApiClient;
  warehouse: DataWarehouseApiClient;
  notification: NotificationApiClient;
  metrics: MetricsApiClient;
  organization: OrganizationApiClient;
  support: SupportApiClient;
  integration: IntegrationApiClient;
  queryEndpoints: QueryEndpointApiClient;
  drift: DriftApiClient;
  createClickPipe: (props: ClickPipeModelWrapper) => Promise<ClickPipe>;
  deletePipe: (serviceId: string, pipeId: string, pipeType: ClickPipeSourceType) => Promise<ClickPipe[]>;

  getClickPipeMetrics: (request: GetClickPipeMetricRequest, serviceId: string) => Promise<{}>;
  getGptConstruction: (request: getGptConstructionRequest, signal?: AbortSignal) => Promise<GtpConstructionResponse>;
  getGptCorrection: (request: GptCorrectionRequest, signal?: AbortSignal) => Promise<GtpCorrectionResponse>;
  getGptInlineCodeCompletion: (request: GptInlineCodeCompletionRequest, signal?: AbortSignal) => Promise<string>;
  getGptUsageConsent: (instanceId: string) => Promise<boolean>;
  postGptFeedback: (params: GptFeedbackParams) => Promise<void>;
  getQueryFolderTree: (props: getQueryFolderTreeProps) => Promise<GetQueryFolderTreeResponse>;
  getKafkaStreamingSample: (request: GetKafkaSampleRequest, serviceId: string) => Promise<SampleSchemaResponse>;
  getKafkaSampleSchema: (request: GetKafkaSampleRequest, serviceId: string) => Promise<SampleSchemaResponse>;
  getKinesisSampleSchema: (request: GetKinesisSampleRequest, serviceId: string) => Promise<SampleSchemaResponse>;
  getSampleSchema: (request: SampleSchemaRequest, serviceId: string) => Promise<SampleSchemaResponse>;
  getSchemaRegistry: (
    request: SchemaRegistryRequest,
    serviceId: string
  ) => Promise<{ schema: string; schemaType: string }>;
  getUserRoleAndFeatures: (serviceId: string) => Promise<UserDetailsResponse>;
  getRoleAndFeatures: (serviceId: string) => Promise<{ role: string; features: Features }>;
  listKafkaTopics: (request: ListTopicsRequest, serviceId: string) => Promise<Array<string>>;
  checkConnection: (request: ObjectStorageConnectionCheckRequest, serviceId: string) => Promise<boolean | null>;
  checkPostgresConnection: (request: PostgresSourceRequest, serviceId: string) => Promise<ValidatePostgresResponse>;
  listPostgresPublications: (request: PostgresSourceRequest, serviceId: string) => Promise<ListPublicationsResponse>;
  listPostgresTables: (request: PostgresListTableRequest, serviceId: string) => Promise<ListTablesResponse>;
  listPostgresSchemas: (request: PostgresSourceRequest, serviceId: string) => Promise<ListSchemasResponse>;
  validatePostgresTables: (
    request: PostgresValidateTablesRequest,
    serviceId: string
  ) => Promise<ValidatePostgresResponse>;
  listClickhouseDatabases: (serviceId: string) => Promise<ListClickhouseDatabasesResponse>;
  sourceListing: (request: ObjectStorageSourceListingRequest, serviceId: string) => Promise<ObjectStorageSourceListing>;
  listServices: () => Promise<ServicesPerOrganization[]>;
  listClickPipes: ({ serviceId }: ListImportsProps) => Promise<ClickPipe[]>;
  listKinesisStreams: (request: ListStreamsRequest, serviceId: string) => Promise<Array<string>>;
  pausePipe: (serviceId: string, pipeId: string) => Promise<ClickPipe>;
  resendEmailConfirmationLink(userId: string): Promise<void>;
  resumePipe: (serviceId: string, pipeId: string) => Promise<ClickPipe>;
  scalePipe: (serviceId: string, pipeId: string, replicas: number) => Promise<ClickPipe>;
  saveQueryFolderTree: (props: saveQueryFolderTreeProps) => Promise<SaveQueryFolderTreeResponse>;
  serviceStatus: (props: { orgId: string | null; serviceId: string | null }) => Promise<ServiceStatus>;
  setGptUsageConsent: (instanceId: string, consent: boolean) => Promise<void>;
  initializeUserSession: () => Promise<InitializeUserSessionResponse>;
  checkUserHasAcceptedTOS: () => Promise<CheckUserHasAcceptedTOSResponse>;
  acceptAccountTOS: () => Promise<void>;
  updateUserDetailsMfa: (userDetailsUpdate: UserDetailsMfaUpdate) => Promise<void>;
  updateUserDetails: (userDetailsUpdate: UserDetailsUpdate) => Promise<void>;
  getChangePasswordWithRedirectUrl: () => Promise<string>;
  fetchMetadata: (
    props: fetchMetadataProps
  ) => Promise<DatabaseStructure | PasswordCredentialResponse | WakeServiceResponse>;
  fetchTableDetails: (
    serviceId: string,
    database: string,
    tableName: string,
    credentials: Credential
  ) => Promise<TableDetails | null>;
  checkS3ObjectCredentials: (request: S3HeadObjectRequest) => Promise<boolean>;
  updateUserPreferences: (value: Partial<UserPreferences>) => Promise<void>;
};

export class ControlPlaneApiClient implements ApiClient {
  private readonly httpClient: HttpClient;

  apiKey: ApiKeyApiClient;
  instance: InstanceApiClient;
  warehouse: DataWarehouseApiClient;
  notification: NotificationApiClient;
  backups: BackupsApiClient;
  dashboard: DashboardApiClient;
  dataloading: DataLoadingApiClient;
  billing: BillingApiClient;
  metrics: MetricsApiClient;
  organization: OrganizationApiClient;
  support: SupportApiClient;
  account: AccountApiClient;
  integration: IntegrationApiClient;
  drift: DriftApiClient;
  queryEndpoints: QueryEndpointApiClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    if (httpClient == null) {
      throw new Error('httpClient is null');
    }

    this.apiKey = new ApiKeyApiClient(this.httpClient);
    this.backups = new BackupsApiClient(this.httpClient);
    this.billing = new BillingApiClient(this.httpClient);
    this.billing = new BillingApiClient(this.httpClient);
    this.dashboard = new DashboardApiClient(this.httpClient);
    this.dataloading = new DataLoadingApiClient(this.httpClient);
    this.instance = new InstanceApiClient(this.httpClient);
    this.warehouse = new DataWarehouseApiClient(this.httpClient);
    this.metrics = new MetricsApiClient(this.httpClient);
    this.organization = new OrganizationApiClient(this.httpClient);
    this.support = new SupportApiClient(this.httpClient);
    this.account = new AccountApiClient(this.httpClient);
    this.integration = new IntegrationApiClient(this.httpClient);
    this.drift = new DriftApiClient(this.httpClient);
    this.queryEndpoints = new QueryEndpointApiClient(this.httpClient);
    this.notification = new NotificationApiClient(this.httpClient);
  }

  async deletePipe(serviceId: string, pipeId: string, pipeType: ClickPipeSourceType): Promise<ClickPipe[]> {
    const response = await this.httpClient.destroy(
      apiUrl(`/deletePipes?serviceId=${serviceId}&pipeId=${pipeId}&pipeType=${pipeType}`),
      {}
    );
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async createClickPipe(props: ClickPipeModelWrapper): Promise<ClickPipe> {
    const response = await this.httpClient.post(apiUrl(`/createClickPipe?serviceId=${props.serviceId}`), {
      body: JSON.stringify(props)
    });

    if (!response.ok && response.status === 500) {
      throw new Error('Server Error: Could not create ClickPipe');
    } else if (!response.ok && response.status >= 400) {
      throw new Error((await response.json()).error);
    } else {
      return (await response.json()).data;
    }
  }

  /**
   * Sends a request to OpenAI GPT to construct an SQL query.
   * @param request - Request parameters, including the request text, service ID, and database name.
   * @param signal - Optional AbortSignal to cancel the request.
   * @returns A promise resolving to the constructed SQL query text.
   * @throws Error if request fails.
   */
  async getGptConstruction(request: getGptConstructionRequest, signal?: AbortSignal): Promise<GtpConstructionResponse> {
    const response = await this.httpClient.post(apiUrl('/gpt/construct'), {
      body: JSON.stringify(request),
      signal
    });

    if (!response.ok && response.status === 500) {
      throw new Error('Could not construct the query with GPT');
    } else if (!response.ok && response.status >= 400) {
      throw new Error((await response.json()).error);
    }
    const json = (await response.json()) as unknown as GtpConstructionResponse;
    return json;
  }

  async getGptInlineCodeCompletion(request: GptInlineCodeCompletionRequest, signal?: AbortSignal): Promise<string> {
    const response = await this.httpClient.post(apiUrl('/gpt/codecomplete'), {
      body: JSON.stringify(request),
      signal
    });

    if (response.ok) {
      const compitionResponse = (await response.json()) as GptInlineCodeCompletionResponse;
      return compitionResponse.completion;
    }
    if (response.status === 500) {
      throw new Error('Cannot do inline code completion with GPT');
    }
    const errorResponse = (await response.json()) as ErrorResponse;
    throw new Error(errorResponse.error);
  }

  /**
   * Sends a request to OpenAI GPT to correct an SQL query.
   * @param request - Request parameters, including the query text, error message, service ID, and database name.
   * @param signal - Optional AbortSignal to cancel the request.
   * @returns A promise resolving to the corrected SQL query text.
   * @throws Error if request fails.
   */
  async getGptCorrection(request: GptCorrectionRequest, signal?: AbortSignal): Promise<GtpCorrectionResponse> {
    const response = await this.httpClient.post(apiUrl('/gpt/correct'), {
      body: JSON.stringify(request),
      signal
    });

    if (!response.ok && response.status === 500) {
      throw new Error('Could not correct the query with GPT');
    } else if (!response.ok && response.status >= 400) {
      throw new Error((await response.json()).error);
    }
    const json = (await response.json()) as unknown as GtpCorrectionResponse;
    return json;
  }

  /**
   * Retrieves the current GPT usage consent status for a given service instance from the API.
   * @param instanceId - The ID of the service instance for which to retrieve the consent status.
   * @returns A Promise that resolves to a boolean indicating whether GPT usage is currently consented to.
   * @throws An error if the request fails or if the response indicates an error.
   */
  async getGptUsageConsent(instanceId: string) {
    const request = {
      rpcAction: 'getGptUsageConsent',
      instanceId
    };

    const response = await this.httpClient.post(
      controlPlaneUrl('/api/sql-console'),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok && response.status === 500) {
      throw new Error('Could not get GPT usage consent');
    } else if (!response.ok && response.status >= 400) {
      throw new Error((await response.json()).error);
    }
    const json = await response.json();
    return json.gptUsageConsent;
  }

  async postGptFeedback(params: GptFeedbackParams): Promise<void> {
    await this.httpClient.post(apiUrl('/gpt/feedback'), {
      body: JSON.stringify(params)
    });
  }

  async getQueryFolderTree({ serviceId }: getQueryFolderTreeProps): Promise<GetQueryFolderTreeResponse> {
    const url = apiUrl(`/queryFolderTree?serviceId=${encodeURIComponent(serviceId)}`);
    const response = await this.httpClient.get(url, {});

    const body = await response.json().catch(() => null);
    if (!response.ok) {
      const errorMessage = body ? body.error : `error ${response.status}`;
      throw new Error(`Cannot get query folder tree: ${errorMessage}`);
    }
    return body;
  }

  async getKafkaStreamingSample(request: GetKafkaSampleRequest, serviceId: string): Promise<SampleSchemaResponse> {
    const response = await this.httpClient.post(apiUrl(`/getKafkaSample?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      return await handleClickPipesError(response);
    } else {
      return (await response.json()).data;
    }
  }

  async getKafkaSampleSchema(request: GetKafkaSampleRequest, serviceId: string): Promise<SampleSchemaResponse> {
    const response = await this.httpClient.post(apiUrl(`/getKafkaSampleSchema?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      return await handleClickPipesError(response);
    } else {
      return (await response.json()).data;
    }
  }

  async getKinesisSampleSchema(request: GetKinesisSampleRequest, serviceId: string): Promise<SampleSchemaResponse> {
    const response = await this.httpClient.post(apiUrl(`/getKinesisSampleSchema?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      return await handleClickPipesError(response);
    } else {
      return (await response.json()).data;
    }
  }

  async getSampleSchema(request: SampleSchemaRequest, serviceId: string): Promise<SampleSchemaResponse> {
    const response = await this.httpClient.post(apiUrl(`/getSampleSchema?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      return await handleClickPipesError(response);
    } else {
      return (await response.json()).data;
    }
  }

  async getClickPipeMetrics(request: GetClickPipeMetricRequest, serviceId: string): Promise<{}> {
    const response = await this.httpClient.post(apiUrl(`/getClickPipeMetrics?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (!response.ok && response.status === 500) {
      throw new Error('Could not get clickpipes metrics');
    } else if (!response.ok && response.status >= 400) {
      throw new Error((await response.json()).error);
    } else {
      return await response.json();
    }
  }

  async getUserRoleAndFeatures(serviceId: string): Promise<UserDetailsResponse> {
    const request = {
      rpcAction: 'getUserRoleAndFeatures',
      instanceId: serviceId
    };
    const response = await this.httpClient.post(
      controlPlaneUrl('/api/sql-console'),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('Server Error: cannot get user role and features');
      } else if (response.status >= 400) {
        throw new Error((await response.json()).message);
      }
    }
    return response.json();
  }

  async listClickPipes({ serviceId }: ListImportsProps): Promise<ClickPipe[]> {
    const response = await this.httpClient.get(apiUrl(`/getPipes?serviceId=${serviceId}`), {});
    if (response.ok) {
      return response.json();
    } else {
      const json = await response.json();
      if ('error' in json) {
        throw new Error(json.error);
      } else if ('errors' in json) {
        throw new Error(json.errors.join(','));
      } else {
        throw new Error('Could not get pipes');
      }
    }
  }

  async getSchemaRegistry(
    request: SchemaRegistryRequest,
    serviceId: string
  ): Promise<{ schema: string; schemaType: string }> {
    const response = await this.httpClient.post(apiUrl(`/getSchemaRegistry?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (!response.ok && response.status >= 400) {
      throw new Error((await response.json()).error);
    } else {
      return (await response.json()).data;
    }
  }

  async listKinesisStreams(request: ListStreamsRequest, serviceId: string): Promise<Array<string>> {
    const response = await this.httpClient.post(apiUrl(`/listKinesisStreams?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      throw new Error((await response.json()).error);
    } else {
      const data = (await response.json()).data;
      if (data.items == null) {
        return [];
      }
      const parsed_response = data.items.map((item: { name: string }) => item.name);
      return parsed_response;
    }
  }
  async listKafkaTopics(request: ListTopicsRequest, serviceId: string): Promise<Array<string>> {
    const response = await this.httpClient.post(apiUrl(`/listKafkaTopics?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      throw new Error((await response.json()).error);
    } else {
      return (await response.json()).data;
    }
  }

  // null is for empty bucket
  async checkConnection(request: ObjectStorageConnectionCheckRequest, serviceId: string): Promise<boolean | null> {
    const listing = await this.sourceListing(request, serviceId);
    return listing.items === null ? null : Boolean(listing.items);
  }

  async checkPostgresConnection(request: PostgresSourceRequest, serviceId: string): Promise<ValidatePostgresResponse> {
    const response = await this.httpClient.post(apiUrl(`/validatePostgresConnection?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async listPostgresPublications(request: PostgresSourceRequest, serviceId: string): Promise<ListPublicationsResponse> {
    const response = await this.httpClient.post(apiUrl(`/listPostgresPublications?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async listPostgresSchemas(request: PostgresSourceRequest, serviceId: string): Promise<ListSchemasResponse> {
    const response = await this.httpClient.post(apiUrl(`/listPostgresSchemas?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async listPostgresTables(request: PostgresListTableRequest, serviceId: string): Promise<ListTablesResponse> {
    const response = await this.httpClient.post(apiUrl(`/listPostgresTables?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async validatePostgresTables(
    request: PostgresValidateTablesRequest,
    serviceId: string
  ): Promise<ValidatePostgresResponse> {
    const response = await this.httpClient.post(apiUrl(`/validatePostgresTables?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async listClickhouseDatabases(serviceId: string): Promise<ListClickhouseDatabasesResponse> {
    const response = await this.httpClient.get(apiUrl(`/listClickhouseDatabases?serviceId=${serviceId}`));
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  /**
   * Load organizations with services from the Control Plane
   * @returns Array of organizations with services
   */
  async listServices(): Promise<ServicesPerOrganization[]> {
    const response = await this.httpClient.post(
      controlPlaneUrl('/api/sql-console'),
      {
        body: JSON.stringify({
          rpcAction: 'getOrganizationsWithInstances'
        })
      },
      { includeAuthProviderHeader: false }
    );

    if (response.ok) {
      const result: ServicesResponse = await response.json();
      return result.data;
    } else {
      console.log('Could not fetch services: ', await response.text());
      throw new Error('Could not fetch services');
    }
  }

  async pausePipe(serviceId: string, pipeId: string): Promise<ClickPipe> {
    const response = await this.httpClient.post(apiUrl(`/pausePipe?serviceId=${serviceId}&pipeId=${pipeId}`), {});
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async resendEmailConfirmationLink(userId: string): Promise<void> {
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify({
          rpcAction: 'resendEmailConfirmation',
          userId
        })
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }

  async resumePipe(serviceId: string, pipeId: string): Promise<ClickPipe> {
    const response = await this.httpClient.post(apiUrl(`/resumePipe?serviceId=${serviceId}&pipeId=${pipeId}`), {});
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async scalePipe(serviceId: string, pipeId: string, replicas: number): Promise<ClickPipe> {
    const response = await this.httpClient.post(apiUrl(`/scalePipe?serviceId=${serviceId}&pipeId=${pipeId}`), {
      body: JSON.stringify({ count: replicas })
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error((await response.json()).error);
    }
  }

  async saveQueryFolderTree({
    path,
    serviceId,
    tree,
    type,
    value
  }: saveQueryFolderTreeProps): Promise<SaveQueryFolderTreeResponse> {
    const response = await this.httpClient.post(apiUrl('/queryFolderTree'), {
      body: JSON.stringify({
        path,
        serviceId,
        tree,
        type: type as string,
        value
      })
    });

    const body = await response.json().catch(() => null);
    if (!response.ok) {
      const errorMessage = body ? body.error : `error ${response.status}`;
      throw new Error(`Cannot save query folder tree: ${errorMessage}`);
    }
    return body;
  }

  async serviceStatus({
    orgId,
    serviceId
  }: {
    orgId: string | null;
    serviceId: string | null;
  }): Promise<ServiceStatus> {
    const response = await this.httpClient.post(apiUrl('/serviceStatus'), {
      body: JSON.stringify({
        orgId,
        serviceId
      })
    });
    const result: ServiceStatusResponse = await response.json();
    if (result.data) {
      return result.data;
    }

    return {
      isAwake: true
    };
  }

  /**
   * Sets the GPT usage consent status for a given service instance in the API.
   * @param instanceId - The ID of the service instance for which to set the consent status.
   * @param consent - A boolean indicating whether to consent to GPT usage (true) or revoke consent (false).
   * @returns A Promise that resolves when the consent status has been updated.
   * @throws An error if the HTTPS request fails.
   */
  async setGptUsageConsent(instanceId: string, consent: boolean): Promise<void> {
    const request = {
      rpcAction: 'updateGptUsageConsent',
      instanceId,
      gptUsageConsent: consent
    };

    const response = await this.httpClient.post(
      controlPlaneUrl('/api/sql-console'),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );

    if (response.ok) {
      return;
    }

    const body = (await response.json()) as SharedErrorResponse;
    if (body.message === 'FORBIDDEN') {
      throw new Error('Insufficient permissions to perform this operation');
    }
    throw new Error(body.message);
  }

  async getRoleAndFeatures(serviceId: string): Promise<UserDetailsResponse> {
    const client = new ControlPlaneApiClient(this.httpClient);
    const rolesAndFeatures = await client.getUserRoleAndFeatures(serviceId);
    return rolesAndFeatures;
  }

  async initializeUserSession(): Promise<InitializeUserSessionResponse> {
    const request: InitializeUserSessionRequest = {
      rpcAction: 'initializeUserSession',
      sourceApp: 'unified-console'
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error((await response.text()) ?? '');
    }
  }

  async checkUserHasAcceptedTOS(): Promise<CheckUserHasAcceptedTOSResponse> {
    const request: CheckUserHasAcceptedTOSRequest = {
      rpcAction: 'checkUserHasAcceptedTOS'
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (response.ok) {
      return (await response.json()) as unknown as CheckUserHasAcceptedTOSResponse;
    } else {
      throw new Error('Could not fetch checkUserHasAcceptedTOS');
    }
  }

  async acceptAccountTOS(): Promise<void> {
    const request: AcceptAccountTOSRequest = {
      rpcAction: 'acceptAccountTOS'
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error('Could not fetch acceptAccountTOS');
    }
  }

  /**
   * Send MFA preferred method (`NOMFA` or `SOFTWARE_TOKEN_MFA`) to the ControlPlane API
   * to update the user account record in the database.
   * @param {UserDetailsMfaUpdate} userDetailsUpdate - The new MFA details for the user.
   * @returns {Promise<void>} A promise that resolves when the update is complete.
   * @throws Will throw an error if the HTTP response is not ok.
   */
  async updateUserDetailsMfa(userDetailsUpdate: UserDetailsMfaUpdate): Promise<void> {
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify({
          rpcAction: 'updateUserDetails',
          ...userDetailsUpdate
        })
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }
  async updateUserDetails(userDetailsUpdate: UserDetailsUpdate): Promise<void> {
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify({
          rpcAction: 'updateUserDetails',
          ...userDetailsUpdate
        })
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      throw new Error((await response.text()) ?? '');
    }
  }

  async getChangePasswordWithRedirectUrl(): Promise<string> {
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify({
          rpcAction: 'createPasswordChangeTicket',
          returnUrl: controlPlaneUrl('/profile?auth0')
        })
      },
      { includeAuthProviderHeader: false }
    );
    if (response.ok) {
      const passwordChangeResponse = (await response.json()) as unknown as CreatePasswordChangeTicketResponse;
      return passwordChangeResponse.redirectUrl;
    } else {
      throw new Error('Could not get password change URL');
    }
  }
  async fetchMetadata({
    serviceId,
    database,
    cached,
    wakeService,
    isStopped,
    credentials
  }: fetchMetadataProps): Promise<DatabaseStructure | PasswordCredentialResponse | WakeServiceResponse> {
    const headers = wakeService
      ? {
          'Wake-Service': '1'
        }
      : undefined;

    const response = await this.httpClient.post(apiUrl('/databaseStructure'), {
      headers,
      body: JSON.stringify({ cached, credentials, database, serviceId })
    });

    if (!response.ok) {
      if (response.status >= 500) {
        throw new Error((await response.text()) ?? 'Internal Server Error');
      }

      const result = await response.json();
      throw new Error(result.error ?? (await response.text()) ?? 'Unexpected error');
    }

    if (response.status === 206) {
      const { data } = (await response.json()) as { data: string };
      return {
        wakeServiceConfirmation: !isStopped && data !== 'Service is stopped'
      };
    }

    if (response.status === 207) {
      const { error } = (await response.json()) as { error: string };
      return {
        passwordCredentialsRequired: error.includes(PASSWORD_CREDENTIAL_ERROR)
      };
    }

    const result = await response.json();
    return result.data ?? {};
  }

  async fetchTableDetails(
    serviceId: string,
    database: string,
    table: string,
    credentials: Credential
  ): Promise<TableDetails> {
    const pathname = `/services/${serviceId}/databases/${encodeURIComponent(database)}/${encodeURIComponent(table)}`;
    const response = await this.httpClient.post(apiUrl(pathname), {
      body: JSON.stringify({ credentials })
    });

    if (!response.ok) {
      throw new Error((await response.text()) ?? 'Internal Server Error');
    }

    const result = await response.json();
    return result.data;
  }

  async sourceListing(
    request: ObjectStorageSourceListingRequest,
    serviceId: string
  ): Promise<ObjectStorageSourceListing> {
    const response = await this.httpClient.post(apiUrl(`/sourceListing?serviceId=${serviceId}`), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      return await handleClickPipesError(response);
    } else {
      return (await response.json()).data;
    }
  }

  async checkS3ObjectCredentials(request: S3HeadObjectRequest): Promise<boolean> {
    const response = await this.httpClient.post(apiUrl('/s3HeadObject'), {
      body: JSON.stringify(request)
    });

    if (!response.ok) {
      throw new Error('Could not verify s3 object');
    } else {
      const { allowed } = (await response.json()) as S3HeadObjectResponse;
      return allowed;
    }
  }

  /**
   * Updates the user's preferences in the Control Plane.
   * @param value Partial set of user preferences to update. Only fields included in the value will be updated,
   * and any fields not included will remain unchanged.
   */
  async updateUserPreferences(value: Partial<UserPreferences>): Promise<void> {
    const request = {
      rpcAction: 'updateUserPreferences',
      preferences: value
    };
    const response = await this.httpClient.post(
      controlPlaneUrl(ACCOUNT_API_PATH),
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error('Server Error: cannot update user preferences');
      } else if (response.status >= 400) {
        throw new Error((await response.json()).message);
      }
    }
  }
}

/**
 * Helper function to handle the response from the ClickpipesPipes API.
 * 5** and 400 errors are not user-facing.
 */
async function handleClickPipesError(response: Response): Promise<any> {
  if (response.status >= 500 || response.status === 400) {
    throw new Error('Something went wrong. Please contact support.');
  } else {
    throw new Error((await response.json()).error);
  }
}

export function useApiClientWithHttp(httpClient: HttpClient): ApiClient {
  return useMemo(() => new ControlPlaneApiClient(httpClient), [httpClient]);
}

export function useApiClient(): ApiClient {
  const http = useHttpClient();
  return useApiClientWithHttp(http);
}
