import {
  AllocatedMemoryMetricArgs,
  InsertQpsMetricArgs,
  ReadThroughputMetricArgs,
  ResidentMemoryUsageMetricArgs,
  RowCountMetricArgs,
  S3StorageUsageMetricArgs,
  SelectQpsMetricArgs,
  SqlStatementsPerTypeMetricArgs,
  SuccessfulQueriesMetricArgs,
  TimePeriod,
  WriteThroughputMetricArgs
} from '@cp/common/protocol/Metrics';
import { MetricChartInput } from 'src/metrics/metricChart';
import { formatDataSize } from 'src/metrics/dataSize';

/**
 * Default color for all metric charts.
 * May be overridden per metric: see 'createMetricsChartInput'.
 */
export const DEFAULT_COLOR_FOR_METRICS = '#2CA300';

/**  Success series color for metric charts. */
export const SUCCESS_COLOR_FOR_METRICS = '#2CA300';

/** Error series color for metric charts. */
export const ERROR_COLOR_FOR_METRICS = '#C70F0F';

/**
 * Info series color for metric charts.
 * Used as a complementary color for success or default color for multi-series.
 */
export const INFO_COLOR_FOR_METRICS = '#057AFF';

export function getMetricsChartInput(timePeriod: TimePeriod, showResidentMemory: boolean): Array<MetricChartInput> {
  const binaryBytesFormatter = (value: number | string): string => {
    if (typeof value === 'string') {
      return value;
    }
    return formatDataSize(value, 'binary-bytes-letter', 1);
  };

  const s3StorageUsageReportInput: MetricChartInput<S3StorageUsageMetricArgs> = {
    title: 'Data stored over time',
    helpTooltipText: 'Statistics about the storage used.',
    tooltipDataSizePointFormat: 'bytes-letter',
    query: { type: 'S3_STORAGE_USAGE', timePeriod }
  };
  const allocatedMemoryReportInput: MetricChartInput<AllocatedMemoryMetricArgs> = {
    title: 'Memory allocation over time',
    helpTooltipText: 'Total memory allocated for all nodes in the cluster.',
    tooltipDataSizePointFormat: 'binary-bytes-letter',
    yAxisValueFormatter: binaryBytesFormatter,
    query: { type: 'ALLOCATED_MEMORY', timePeriod }
  };
  const residentMemoryUsageReportInput: MetricChartInput<ResidentMemoryUsageMetricArgs> = {
    title: 'Memory usage over time',
    helpTooltipText: 'Total memory used by all nodes in the cluster.',
    tooltipDataSizePointFormat: 'binary-bytes-letter',
    yAxisValueFormatter: binaryBytesFormatter,
    query: { type: 'RESIDENT_MEMORY_USAGE', timePeriod }
  };
  const successfulQueriesReportInput: MetricChartInput<SuccessfulQueriesMetricArgs> = {
    title: 'Query success rate over time',
    helpTooltipText: 'The distribution of successful vs failed queries.',
    yAxisValueFormat: 'short-scale-letter',
    query: { type: 'SUCCESSFUL_QUERIES', timePeriod },
    seriesOptions: [
      { name: 'Successful queries', color: SUCCESS_COLOR_FOR_METRICS },
      { name: 'Failed queries', color: ERROR_COLOR_FOR_METRICS }
    ]
  };
  const selectQpsReportInput: MetricChartInput<SelectQpsMetricArgs> = {
    title: 'Select queries per second',
    helpTooltipText: 'The number of SELECT queries.\nThe graph shows query rate per second.',
    tooltipDataSizePointFormat: 'short-scale-letter',
    yAxisValueFormat: 'short-scale-letter',
    tooltipValueSuffix: '/s',
    query: { type: 'SELECT_QPS', timePeriod }
  };
  const readThroughputReportInput: MetricChartInput<ReadThroughputMetricArgs> = {
    title: 'Read throughput per second',
    helpTooltipText:
      'The amount of (uncompressed) data read from ClickHouse.\n' +
      'The graph shows read throughput per second based on\n' +
      'both user-generated and system-generated activity.',
    tooltipDataSizePointFormat: 'bytes-letter',
    tooltipValueSuffix: '/s',
    query: { type: 'READ_THROUGHPUT', timePeriod }
  };
  const insertQpsReportInput: MetricChartInput<InsertQpsMetricArgs> = {
    title: 'Insert queries per second',
    helpTooltipText: 'The number of INSERT queries.\nThe graph shows query rate per second.',
    tooltipDataSizePointFormat: 'short-scale-letter',
    yAxisValueFormat: 'short-scale-letter',
    tooltipValueSuffix: '/s',
    query: { type: 'INSERT_QPS', timePeriod }
  };
  const writeThroughputReportInput: MetricChartInput<WriteThroughputMetricArgs> = {
    title: 'Write throughput per second',
    helpTooltipText:
      'The amount of (uncompressed) data written to ClickHouse.\n' +
      'The graph shows write throughput per second based on\n' +
      'both user-generated and system-generated activity.',
    tooltipDataSizePointFormat: 'bytes-letter',
    tooltipValueSuffix: '/s',
    query: { type: 'WRITE_THROUGHPUT', timePeriod }
  };
  const sqlStatementsPerTypeReportInput: MetricChartInput<SqlStatementsPerTypeMetricArgs> = {
    title: 'Query volume over time',
    helpTooltipText: 'The total number of SELECT and INSERT queries over time.',
    tooltipDataSizePointFormat: 'short-scale-letter',
    yAxisValueFormat: 'short-scale-letter',
    query: { type: 'SQL_STATEMENTS_PER_TYPE', timePeriod },
    seriesOptions: [
      { name: 'Selects', color: SUCCESS_COLOR_FOR_METRICS, type: 'line' },
      { name: 'Inserts', color: INFO_COLOR_FOR_METRICS, type: 'line' }
    ]
  };
  const rowCountReportInput: MetricChartInput<RowCountMetricArgs> = {
    title: 'Rows read and written over time',
    helpTooltipText: 'The total number of select and insert rows over time.',
    tooltipDataSizePointFormat: 'short-scale-letter',
    yAxisValueFormat: 'short-scale-letter',
    query: { type: 'ROW_COUNT', timePeriod },
    seriesOptions: [
      { name: 'Selected rows', color: SUCCESS_COLOR_FOR_METRICS, type: 'line' },
      { name: 'Inserted rows', color: INFO_COLOR_FOR_METRICS, type: 'line' }
    ]
  };

  // At HD (1920) resolution, we have 2 rows on the screen.
  // Different modes use different orders to keep related widgets close to each other.
  if (showResidentMemory) {
    return [
      s3StorageUsageReportInput,
      successfulQueriesReportInput,
      allocatedMemoryReportInput,
      residentMemoryUsageReportInput,
      selectQpsReportInput,
      readThroughputReportInput,
      insertQpsReportInput,
      writeThroughputReportInput,
      sqlStatementsPerTypeReportInput,
      rowCountReportInput
      // + Metrics feedback widget.
    ];
  }
  return [
    s3StorageUsageReportInput,
    allocatedMemoryReportInput,
    selectQpsReportInput,
    readThroughputReportInput,
    insertQpsReportInput,
    writeThroughputReportInput,
    sqlStatementsPerTypeReportInput,
    successfulQueriesReportInput,
    rowCountReportInput // + Metrics feedback widget.
  ];
}
