import React, { ReactElement } from 'react';

import { ClickPipeStatus, ClickPipesS3Status } from 'types/protocol';
import { Badge, BadgeState } from '@clickhouse/click-ui';

function clickPipeStatusToBadgeType(type: ClickPipesS3Status): BadgeState {
  switch (type) {
    case 'Provisioning':
    case 'Stopping':
    case 'Fetching':
      return 'info';
    case 'Running':
      return 'success';
    case 'Stopped':
      return 'warning';
    case 'Completed':
      return 'neutral';
  }

  return 'danger';
}

interface Props {
  type: ClickPipeStatus | ClickPipesS3Status;
}

function StatusBadge({ type }: Props): ReactElement {
  return (
    <Badge text={type} state={clickPipeStatusToBadgeType(type)} size="md" />
  );
}

export default React.memo(StatusBadge);
