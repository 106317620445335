import {
  Button,
  Container,
  Select,
  Spacer,
  Text,
  TextField,
  Tooltip
} from '@clickhouse/click-ui';
import { UserPreferences } from '@cp/common/protocol/Account';
import { isFederatedUserId, isSamlUserId } from '@cp/common/utils/AuthUtils';
import { validateName } from '@cp/common/utils/ValidationUtils';
import { KeyboardEvent, ReactElement, useState } from 'react';
import { MediumWidthContainer } from 'src/components/MediumWidthContainer';
import { useAuth } from 'src/components/auth';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import {
  Section,
  SectionSeparator,
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import { useUserController } from 'src/user/userController';
import { UserState, useUserStateManager } from 'src/user/userState';
import styled from 'styled-components';

const TooltipTrigger = styled(Tooltip.Trigger)`
  width: 100%;
`;

const ProfilePageView = ({ user }: ProfilePageProps): ReactElement => {
  const isEmailUser =
    !isSamlUserId(user.userId) && !isFederatedUserId(user.userId);
  const [name, setName] = useState<string>(user.name ?? '');
  const [validationMessage, setValidationMessage] = useState<string>();
  const nameNeedsSaving = name !== user.name && name !== '';
  const { updateName, changePasswordWithRedirect, updatePreferences } =
    useUserController();
  const selectedTheme = user.preferences?.theme;

  const validateAndSetName = (username: string): void => {
    const validationResult = validateName(username);
    setValidationMessage(validationResult.validationMessage);
    setName(username);
  };

  const saveUsername = (): void => {
    const validationResult = validateName(name);
    if (!validationResult.isValid) {
      return;
    }
    void updateName(name);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && nameNeedsSaving) {
      saveUsername();
    }
  };

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Profile" />
      <MainPaneLayout.Content>
        <MediumWidthContainer
          orientation="vertical"
          padding="lg"
          data-testid="profile-page"
        >
          <Section gap="xl">
            <Subsection>
              <SubsectionDescription gap="md">
                <SubsectionTitle>Personal information</SubsectionTitle>
                <Text color="muted">
                  Your email address is used to sign in and should we need to
                  contact you.
                </Text>
              </SubsectionDescription>
              <SubsectionControls gap="xl">
                <TextField
                  orientation="vertical"
                  type="text"
                  label="Full name"
                  error={validationMessage}
                  value={name}
                  onChange={validateAndSetName}
                  data-testid="profile-name-input"
                  onKeyDown={handleKeyDown}
                />
                <Tooltip>
                  <TooltipTrigger>
                    <TextField
                      orientation="vertical"
                      type="email"
                      label="Email address"
                      disabled
                      value={user.email}
                      onChange={() => {}}
                      data-testid="profile-email-input"
                    />
                  </TooltipTrigger>
                  <Tooltip.Content
                    showArrow
                    side="top"
                    sideOffset={-20}
                    align="end"
                    alignOffset={10}
                  >
                    Changing your email address is unavailable at this time.
                  </Tooltip.Content>
                </Tooltip>

                {nameNeedsSaving && (
                  <Container fillWidth={false}>
                    <Button
                      data-testid="profile-update-info-btn"
                      disabled={validationMessage !== undefined}
                      label="Save name"
                      onClick={saveUsername}
                      type="secondary"
                    />
                  </Container>
                )}
              </SubsectionControls>
            </Subsection>

            {isEmailUser && (
              <>
                <Subsection>
                  <SubsectionDescription gap="md">
                    <SubsectionTitle>Password</SubsectionTitle>
                    <Text color="muted">
                      Your password is used to sign into the ClickHouse Cloud
                      console.
                    </Text>
                  </SubsectionDescription>
                  <SubsectionControls gap="xl">
                    <TextField
                      orientation="vertical"
                      label="Current password"
                      disabled
                      value="••••••••••••••"
                      onChange={() => {}}
                      data-testid="profile-password-input"
                    />

                    <Container>
                      <Button
                        type="secondary"
                        label="Change password"
                        onClick={changePasswordWithRedirect}
                      />
                    </Container>
                  </SubsectionControls>
                </Subsection>
              </>
            )}
          </Section>
          <Spacer />
          <SectionSeparator />
          <Spacer />
          <Section gap="xl">
            <Subsection>
              <SubsectionDescription gap="md">
                <SubsectionTitle>Theme</SubsectionTitle>
                <Text color="muted">
                  Select how you would like to experience ClickHouse Cloud
                </Text>
              </SubsectionDescription>
              <SubsectionControls gap="xl">
                <Select
                  onSelect={(val: string): Promise<void> =>
                    updatePreferences({ theme: val } as UserPreferences)
                  }
                  value={selectedTheme ?? 'light'}
                >
                  <Select.Item value="light">Light</Select.Item>
                  <Select.Item value="dark">Dark</Select.Item>
                </Select>
              </SubsectionControls>
            </Subsection>
          </Section>
        </MediumWidthContainer>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

const ProfilePage = (): ReactElement | null => {
  const { currentUser } = useAuth();
  const { user } = useUserStateManager();
  if (!currentUser || !user) {
    return null;
  }
  return <ProfilePageView user={user} />;
};

interface ProfilePageProps {
  user: UserState;
}

export default ProfilePage;
