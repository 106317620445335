import { ReactElement } from 'react';

import { IconProps } from 'src/components/icons/iconInterface';

function CloseIcon({
  color = 'currentColor',
  ...props
}: IconProps): ReactElement {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1_173)">
        <path
          d="M0.507978 12.36C0.597568 12.4463 0.707718 12.5082 0.827978 12.54C0.945974 12.571 1.07 12.5707 1.18784 12.5391C1.30568 12.5075 1.41326 12.4458 1.49998 12.36L6.49998 7.36L11.5 12.36C11.5867 12.4458 11.6943 12.5075 11.8121 12.5391C11.93 12.5707 12.054 12.571 12.172 12.54C12.2948 12.5149 12.4067 12.452 12.492 12.36C12.5787 12.2748 12.6385 12.1659 12.664 12.047C12.6953 11.929 12.6953 11.805 12.664 11.687C12.6384 11.5684 12.5786 11.4599 12.492 11.375L7.49198 6.367L12.492 1.367C12.621 1.23572 12.6932 1.05904 12.6932 0.874997C12.6932 0.690956 12.621 0.514275 12.492 0.382997C12.4052 0.293032 12.2939 0.230457 12.172 0.202997C12.054 0.171612 11.9299 0.171612 11.812 0.202997C11.6919 0.229444 11.583 0.292306 11.5 0.382997L6.49998 5.383L1.49998 0.382997C1.41474 0.291034 1.30283 0.228086 1.17998 0.202997C1.06203 0.171612 0.937926 0.171612 0.819978 0.202997C0.699926 0.229444 0.590965 0.292306 0.507978 0.382997C0.420864 0.468804 0.358656 0.576632 0.327978 0.694997C0.301834 0.813573 0.301834 0.936421 0.327978 1.055C0.358978 1.175 0.418978 1.279 0.507978 1.367L5.50798 6.367L0.507978 11.375C0.420709 11.4611 0.358489 11.5693 0.327978 11.688C0.296789 11.8053 0.294046 11.9284 0.319978 12.047C0.351978 12.167 0.413978 12.271 0.507978 12.359V12.36Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_173">
          <rect width="13" height="13" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
