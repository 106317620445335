import {
  Button,
  Container,
  Flyout,
  Label,
  Select,
  Separator,
  Spacer,
  Text,
  TextAreaField,
  TextField,
  Title
} from '@clickhouse/click-ui';
import { OrganizationPrivateEndpoint } from '@cp/common/protocol/Organization';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { PrivateEndpointDocsLink } from 'src/pages/SettingsPage/PrivateEndpointDocsLink';
import { Instance } from '@cp/common/protocol/Instance';
import { EndpointIdLink } from 'src/components/PrivateEndpointIdLink';
import { useInstancePrivateEndpointConfig } from 'src/instance/useInstancePrivateEndpointConfig';
import { InstancePrivateEndpointSupportingInformation } from 'src/components/PrivateEndpointSupportingInfo';

interface EndpointOptionProps {
  endpoint: OrganizationPrivateEndpoint;
}

function EndpointOption({ endpoint }: EndpointOptionProps): ReactElement {
  //click/genericMenu/item/typography/label/default
  return (
    <div>
      <Text size="sm">{endpoint.id}</Text>
      <Text size="sm" color="muted">
        {endpoint.description}
      </Text>
    </div>
  );
}

interface PrivateEndpointFlyoutProps {
  instance: Instance;
  endpoints: Array<OrganizationPrivateEndpoint>;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onCreateAndAddToInstance: (id: string, description: string) => void;
  onAddToInstance: (privateEndpointId: string) => void;
}

export function PrivateEndpointFlyout({
  instance,
  endpoints,
  open,
  onOpenChange,
  onCreateAndAddToInstance,
  onAddToInstance
}: PrivateEndpointFlyoutProps): ReactElement {
  const [selectedExistingEndpoint, setSelectedExistingEndpoint] =
    useState<string>();
  const [newEndpointId, setNewEndpointId] = useState('');
  const [newEndpointDescription, setNewEndpointDescription] = useState('');
  const endpointConfig = useInstancePrivateEndpointConfig(instance);

  useEffect(() => {
    if (open) {
      // clear form when it is opened
      setSelectedExistingEndpoint(undefined);
      setNewEndpointId('');
      setNewEndpointDescription('');
    }
  }, [open]);

  const endpointOptions = useMemo(() => {
    return endpoints.map((endpoint) => ({
      value: endpoint.id,
      label: <EndpointOption endpoint={endpoint} />,
      'data-testid': `existing-endpoint-option-${endpoint.id}`
    }));
  }, [endpoints]);

  const create = useCallback(() => {
    if (selectedExistingEndpoint) {
      onAddToInstance(selectedExistingEndpoint);
    } else {
      onCreateAndAddToInstance(newEndpointId, newEndpointDescription);
    }
  }, [
    selectedExistingEndpoint,
    newEndpointId,
    newEndpointDescription,
    onAddToInstance,
    onCreateAndAddToInstance
  ]);

  return (
    <Flyout open={open} onOpenChange={onOpenChange}>
      <Flyout.Content
        strategy="fixed"
        size="default"
        data-testid="privateEndpointFlyout"
      >
        <Flyout.Header>
          <Container padding="none" gap="md" orientation="vertical">
            <Title type="h1" size="xl">
              Set up private endpoint
            </Title>
            <Text color="muted">
              {
                'For step-by-step directions on how to set up a private endpoint, see our docs. '
              }
            </Text>
            <PrivateEndpointDocsLink cloudProvider={instance.cloudProvider} />
          </Container>
        </Flyout.Header>
        <Flyout.Body align="default">
          <Flyout.Element>
            <Container orientation="vertical" fillWidth gap="md">
              {endpointOptions.length > 0 && (
                <>
                  <Title type="h2" size="sm">
                    Select existing endpoint?
                  </Title>
                  <Select
                    label="Endpoint ID"
                    options={endpointOptions}
                    value={selectedExistingEndpoint}
                    onSelect={setSelectedExistingEndpoint}
                    data-testid="existing-private-endpoint-id-select"
                  />
                  <Separator size="sm" />
                </>
              )}
              {!selectedExistingEndpoint && (
                <>
                  <Title type="h2" size="sm">
                    Create new endpoint
                  </Title>
                  <InstancePrivateEndpointSupportingInformation
                    privateDnsHostname={endpointConfig?.privateDnsHostname}
                    endpointServiceId={endpointConfig?.endpointServiceId}
                  />
                  <Separator size="sm" />
                  <Container fillWidth orientation="vertical">
                    <Label htmlFor="endpointId">
                      <Container
                        fillWidth
                        justifyContent="space-between"
                        padding="none"
                      >
                        <span>
                          {'Endpoint ID '}
                          <EndpointIdLink
                            cloudProvider={instance.cloudProvider}
                          />
                        </span>
                      </Container>
                    </Label>
                    <Spacer size="sm" />
                    <TextField
                      width="100%"
                      id="endpointId"
                      placeholder="Endpoint ID"
                      value={newEndpointId}
                      onChange={setNewEndpointId}
                      data-testid="endpoint-id-input"
                    />
                  </Container>
                  <TextAreaField
                    label="Endpoint description"
                    value={newEndpointDescription}
                    onChange={setNewEndpointDescription}
                    placeholder="Add a note to help you identify this endpoint."
                    data-testid="endpoint-description-input"
                    rows={3}
                  />
                </>
              )}
            </Container>
          </Flyout.Element>
        </Flyout.Body>
        <Flyout.Footer>
          <Flyout.Close label="Cancel" />
          <Button onClick={create}>Create endpoint</Button>
        </Flyout.Footer>
      </Flyout.Content>
    </Flyout>
  );
}
