import { RowElement } from 'shared/src/clickhouse';
import { ROLE_CLICKPIPES_DESTINATION_ONLY } from 'shared/src/clickpipes';
import { dbAdminRole, dbDeveloperRole, defaultAdminRole } from 'shared/src/roles';
import { useSqlQuery } from 'src/lib/clickhouse/query';

const internalRoles: ReadonlySet<string> = new Set([
  dbAdminRole,
  dbDeveloperRole,
  ROLE_CLICKPIPES_DESTINATION_ONLY,
  defaultAdminRole
]);

function isNotInternalRole(role: RowElement): role is string {
  return Boolean(role && internalRoles.has(role) === false);
}

function filterOutInternalRoles(roles: ReadonlyArray<RowElement>): ReadonlyArray<string> {
  return roles.filter(isNotInternalRole);
}

type UseRoles = [() => Promise<void>, ReadonlyArray<string> | undefined];

export const useRoles = (): UseRoles => {
  const [reload, { data }] = useSqlQuery('show roles;');
  const roles = data && filterOutInternalRoles(data.rows.flat());
  return [reload, roles];
};
