import {
  Button,
  Container,
  GridContainer,
  Icon,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import { Instance } from '@cp/common/protocol/Instance';
import { REGION_BY_ID } from '@cp/common/protocol/Region';
import { getMaintenanceStatus } from '@cp/common/utils/MaintenanceWindowUtils';
import { ReactElement } from 'react';
import BlockedRegionPanel from 'src/components/BlockedRegion';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useServiceLimitReachedModal } from 'src/components/ServiceLimitReachedModal/ServiceLimitReachedModalHook';
import { useGettingStartedFeature } from 'src/instance/controller/useGettingStartedFeature';
import { useMainSidebar } from 'src/layout/SidebarWrapper/SidebarWrapper';
import { useIsRegionBlocked } from 'src/lib/blockedRegion';
import { useUserFeature } from 'src/lib/features';
import { useByocOnboardingStatus } from 'src/lib/byoc/byocOnboardingStatus';
import { routes } from 'src/lib/routes';
import { ServiceCard } from 'src/pages/ServiceListPage/ServiceCard';
import { useSelectService } from 'src/state/service/selectService';
import { navigateToService } from 'src/components/DataWarehouseList/navigateToService';

type ServiceListProps = {
  instances: Array<Instance>;
  hasManageServicePermission: boolean;
  isLoading?: boolean;
};

const ByocProvisioning = (): ReactElement => {
  return (
    <Container
      orientation="vertical"
      alignItems="center"
      gap="xs"
      padding="xl"
      data-testid="no-services-message"
    >
      <Spacer size="xxl" />
      <Container
        orientation="vertical"
        padding="lg"
        alignItems="center"
        maxWidth="415px"
        gap="xs"
      >
        <Icon name="dots-horizontal" height="80px" width="80px" />
        <Title type="h1" size="md">
          You cannot create services at the moment
        </Title>
        <Spacer size="sm" />
        <Text align="center">
          Finishing your AWS ClickHouse infrastructure set up
        </Text>
        <Text align="center">This process might take up to 40 minutes.</Text>
      </Container>
    </Container>
  );
};

const EmptyServiceList = ({
  hasManageServicePermission
}: {
  hasManageServicePermission: boolean;
}): ReactElement => {
  const { createNewServiceOrShowTrialLimitModal } =
    useServiceLimitReachedModal();

  return (
    <Container
      orientation="vertical"
      alignItems="center"
      gap="xs"
      padding="xl"
      data-testid="no-services-message"
    >
      <Spacer size="xxl" />
      <Container
        orientation="vertical"
        padding="lg"
        alignItems="center"
        maxWidth="415px"
        gap="xs"
      >
        <Icon name="clickhouse" height="80px" width="80px" />
        <Title type="h1" size="md">
          No ClickHouse services
        </Title>
        <Spacer size="sm" />
        <Text align="center">
          Get started by creating a high-powered serverless ClickHouse database
          in minutes.
        </Text>
        <Spacer size="sm" />
        {hasManageServicePermission && (
          <Button
            label="Create new service"
            iconLeft="plus"
            onClick={createNewServiceOrShowTrialLimitModal}
            data-testid="create-service-button"
          />
        )}
      </Container>
    </Container>
  );
};

const ServiceList = ({ instances }: ServiceListProps): ReactElement => {
  const { isDesktop } = useMainSidebar();
  const selectService = useSelectService();
  const showNewOnboarding = useUserFeature('FT_USER_SHOW_NEW_ONBOARDING');
  const { hasGettingStarted } = useGettingStartedFeature();

  const services = instances
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((instance) => {
      const maintenanceStatus = getMaintenanceStatus(instance);
      return (
        <ServiceCard
          title={instance.name}
          isPrimaryInstance={instance.isPrimary}
          instanceTier={instance.instanceTier}
          version={`v${instance.clickhouseVersion}`}
          status={instance.state}
          provider={instance.cloudProvider}
          region={`${REGION_BY_ID[instance.regionId]?.name} (${REGION_BY_ID[
            instance.regionId
          ]?.displayId})`}
          serviceId={instance.id}
          dataWarehouseId={instance.dataWarehouseId}
          isEncrypted={!!instance.customerManagedEncryption?.keyArn}
          key={instance.id}
          maintenanceStatus={maintenanceStatus}
          onClick={() => {
            const serviceId = instance.id;
            if (showNewOnboarding && hasGettingStarted) {
              navigateTo(routes.gettingStarted({ serviceId }));
            } else {
              navigateToService({
                serviceId,
                state: instance.state,
                isDesktop
              });
            }
            selectService({ serviceId });
          }}
          data-testid="instance"
          isDesktop={isDesktop}
        />
      );
    });
  return (
    <GridContainer
      gap="md"
      gridTemplateColumns="repeat(auto-fill, minmax(375px, 0.3fr))"
      maxWidth="100%"
      data-testid="service-list"
    >
      {services}
    </GridContainer>
  );
};

export const ServiceListPageContents = ({
  instances,
  hasManageServicePermission,
  isLoading
}: ServiceListProps): ReactElement => {
  const byocOnboardingStatus = useByocOnboardingStatus();
  const isBlocked = useIsRegionBlocked();

  if (isLoading) {
    return (
      <Container
        fillHeight
        maxHeight="80vh"
        justifyContent="center"
        data-testid="service-list-loading"
      >
        <Icon size="xxl" name="loading-animated" />
      </Container>
    );
  }

  if (isBlocked) {
    return (
      <Container
        orientation="vertical"
        justifyContent="center"
        maxWidth="650px"
      >
        <BlockedRegionPanel showContinueButton={false} />
      </Container>
    );
  }

  if (byocOnboardingStatus === 'pending') {
    return <ByocProvisioning />;
  } else if (instances.length === 0) {
    return (
      <EmptyServiceList
        hasManageServicePermission={hasManageServicePermission}
      />
    );
  } else {
    return (
      <ServiceList
        instances={instances}
        hasManageServicePermission={hasManageServicePermission}
      />
    );
  }
};
