import { InteractionType, logger } from 'src/lib/logger';

export const logEvent = (event: string, interaction: InteractionType = 'click'): void => {
  logger.track({
    view: 'queryView',
    component: 'chartConfig',
    event,
    interaction
  });
};
