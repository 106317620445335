import {
  Container,
  EllipsisContent,
  GridContainer,
  Text
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import styled from 'styled-components';

type HorizontalBarsProps = {
  data: Array<{
    percentage: number;
    item: string;
    descriptor: string;
  }>;
};

const RelativeContainer = styled(Container)`
  position: relative;
  height: 100%;
  height: 28px;
`;

const PercentageBar = styled.div<{ percentage: number }>`
  background-color: ${({ theme }): string =>
    theme.global.color.feedback.info.background};
  height: 28px;
  width: ${({ percentage }): string => `${percentage * 100}%`};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const Label = styled(Container)`
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
`;

export function HorizontalBar({
  percentage,
  item
}: {
  percentage: number;
  item: string;
}): ReactElement {
  return (
    <RelativeContainer>
      <Label component="div" weight="mono" fillHeight padding="sm">
        <EllipsisContent>{item}</EllipsisContent>
      </Label>
      <PercentageBar percentage={percentage} />
    </RelativeContainer>
  );
}
export function HorizontalBars({ data }: HorizontalBarsProps): ReactElement {
  return (
    <GridContainer gridTemplateColumns="1fr 1fr" gap="md">
      {data.map(({ percentage, item, descriptor }) => (
        <>
          <HorizontalBar percentage={percentage} item={item} />
          <Text color="muted" weight="mono" align="right">
            {descriptor}
          </Text>
        </>
      ))}
    </GridContainer>
  );
}
