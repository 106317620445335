import { ReactElement } from 'react';
import { KafkaSourceOption } from 'src/components/ImportRightBar/types';
import { DialogProps, Flyout } from '@clickhouse/click-ui';

type Props = DialogProps & Omit<KafkaSourceOption, 'type' | 'id'>;

function KafkaSourceRightBar({
  onOpenChange,
  data,
  format
}: Props): ReactElement {
  return (
    <Flyout open onOpenChange={onOpenChange}>
      <Flyout.Content
        className="view-kafka-source-data"
        data-testid="kafka-source-rightbar"
        strategy="fixed"
      >
        <Flyout.Header
          title="Source Data"
          description="This data is fetched from Kafka, from the oldest offset."
        />
        <Flyout.Body>
          <div className="fs-exclude">
            <Flyout.CodeBlock
              wrapLines
              language={format}
              fillHeight
              statement={data}
            />
          </div>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
}

export default KafkaSourceRightBar;
