import { Container, Panel, Text, Title } from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import CopyButton from 'src/components/CopyButton';

interface SupportingInfoProps {
  title: string;
  value: string;
}

function SupportingInfo({ title, value }: SupportingInfoProps): ReactNode {
  return (
    <Container
      orientation="vertical"
      fillWidth
      justifyContent="start"
      alignItems="start"
    >
      <Text color="muted" size="sm">
        {title}
      </Text>
      <Container padding="xs" fillWidth gap="sm">
        <Container grow="1">
          <Text size="md">{value}</Text>
        </Container>
        <CopyButton text={value} />
      </Container>
    </Container>
  );
}

interface PrivateEndpointSupportingInformationProps {
  endpointServiceId?: string;
  privateDnsHostname?: string;
}

export function OrganizationPrivateEndpointSupportingInformation({
  endpointServiceId
}: PrivateEndpointSupportingInformationProps): ReactNode {
  return (
    <Panel hasBorder color="muted" orientation="vertical" alignItems="start">
      <Title type="h3" size="sm">
        Supporting information
      </Title>
      <SupportingInfo
        title="Service name"
        value={endpointServiceId ?? 'Loading...'}
      />
    </Panel>
  );
}

export function InstancePrivateEndpointSupportingInformation({
  endpointServiceId,
  privateDnsHostname
}: PrivateEndpointSupportingInformationProps): ReactNode {
  return (
    <Panel hasBorder color="muted" orientation="vertical" alignItems="start">
      <Title type="h3" size="sm">
        Supporting information
      </Title>
      <SupportingInfo
        title="Service name"
        value={endpointServiceId ?? 'Loading...'}
      />
      <SupportingInfo
        title="DNS name"
        value={privateDnsHostname ?? 'Loading...'}
      />
    </Panel>
  );
}
