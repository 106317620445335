import { createToast } from '@clickhouse/click-ui';
import { InvitationUpdatedPayload } from '@cp/common/protocol/Account';
import { OrganizationInvitationDetails } from '@cp/common/protocol/Organization';
import { useCallback, useEffect, useRef } from 'react';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useApiClient } from 'src/lib/controlPlane/client';
import { logger } from 'src/lib/logger';
import { useWebSocketsNotification } from 'src/lib/websockets/useWebSocketsNotification';
import { useOrganizationStateManager } from 'src/organization/organizationState';
import { useInvitationsState } from 'src/state/notification/invitation';

export function useInitInvitationState(): void {
  const api = useApiClient();
  const initializedUser = useRef<string | null>(null);
  const [, setInvitations] = useInvitationsState();
  const user = useCurrentUserOrThrow();

  useWebSocketsNotification<InvitationUpdatedPayload>({
    subscriptionDetails: {
      type: 'INVITE_UPDATE',
      objId: user.email
    },
    handler: (message) => {
      if (message.payload.invitations) {
        setInvitations(message.payload.invitations);
      }
    },
    dependencies: [user.email]
  });

  useEffect(() => {
    if (!initializedUser.current) {
      void api.initializeUserSession().then((userDetails) => {
        setInvitations(userDetails.invitations);
      });
      initializedUser.current = user.id;
    }

    return () => {
      initializedUser.current = null;
    };
  }, [user.id]);
}

interface UseInvitationControllerHook {
  acceptInvitation: (args: AcceptInvitationArgs) => Promise<void>;
  removeExpiredInvitations: () => void;
  invitations: OrganizationInvitationDetails[];
}

export interface AcceptInvitationArgs {
  invitation: OrganizationInvitationDetails;
  onClose: () => void;
  component?: string;
}

export function useInvitationController(): UseInvitationControllerHook {
  const api = useApiClient();
  const [invitations, setInvitations] = useInvitationsState();
  const { setCurrentOrganizationId, setOrganizations } = useOrganizationStateManager();

  const acceptInvitation = useCallback(
    async ({ invitation, onClose, component = 'InvitationsModal' }: AcceptInvitationArgs): Promise<void> => {
      const response = await api.organization.acceptInvitation(invitation.invitationKey);

      logger.track({
        event: 'acceptInvitation',
        interaction: 'click',
        component,
        view: 'sidebar'
      });

      setCurrentOrganizationId(response.organizationId);
      createToast({
        type: 'success',
        title: 'Invitation accepted',
        description: `Successfully joined ${response.organization?.name ?? 'the organization'}`
      });
      onClose();
    },
    [api.organization, setCurrentOrganizationId, setOrganizations]
  );

  const removeExpiredInvitations = useCallback((): void => {
    const expiredInvitationKeys = invitations
      .filter((invite) => invite.isExpired)
      .map((invite) => invite.invitationKey);

    setInvitations((invites) => invites.filter((invite) => !expiredInvitationKeys.includes(invite.invitationKey)));
  }, [invitations, setInvitations]);

  return {
    acceptInvitation,
    removeExpiredInvitations,
    invitations
  };
}

export function useMarkViewedInvitationsOnClose(): void {
  const api = useApiClient();
  const [invitations] = useInvitationsState();

  useEffect(() => {
    // This function will be called when the component is unmounted
    console.log('useMarkViewedInvitationsOnClose');
    const expiredInvitationKeys = invitations
      .filter((invite) => invite.isExpired)
      .map((invite) => invite.invitationKey);

    if (expiredInvitationKeys.length === 0) {
      return (): void => {};
    }

    console.log('Marking invitations as viewed:', expiredInvitationKeys);
    return (): void => {
      api.organization
        .markViewedInvitations(expiredInvitationKeys)
        .catch((error) => console.error('Error marking invitations as viewed:', error));
    };
  }, [api.organization, invitations]);
}
