import { GrowthBook } from '@growthbook/growthbook-react';
import config from 'src/lib/config';
import { Galaxy } from 'galaxy';

let growthBook: GrowthBook | null = null;

export const getGrowthBook = (enableDevMode: boolean): GrowthBook => {
  if (growthBook) {
    return growthBook;
  }

  if (enableDevMode) {
    console.log('GrowthBook dev mode enabled');
  }

  growthBook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: config.growthBook?.clientKey,
    enableDevMode,
    subscribeToChanges: true,
    trackingCallback: (experiment, result): void => {
      try {
        const galaxy = Galaxy.galaxy();
        galaxy.track('growthbook.experiment.viewed', {
          interaction: 'trigger',
          experimentId: experiment.key,
          variationId: result.key
        });
      } catch (error) {
        console.warn('Galaxy not initialized yet.', error);
        console.log(
          'growthbook.experiment.viewed',
          JSON.stringify({ experimentId: experiment.key, variationId: result.key })
        );
      }
    }
  });
  growthBook.init({ streaming: true }).catch(console.error);

  return growthBook;
};

type GrowthBookUserAttributes = {
  userId: string;
  email: string;
};

export const setGrowthBookUser = ({ userId, email }: GrowthBookUserAttributes): void => {
  if (!growthBook) {
    console.log('growthbook not initialized. Returning...');
    return;
  }

  growthBook
    .setAttributes({
      id: userId,
      email,
      browser: navigator.userAgent,
      language: navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    .catch(console.error);
};

export const clearGrowthBook = (): void => {
  if (!growthBook) {
    return;
  }
  growthBook.setAttributes({}).catch(console.error);
};

export const setGrowthBookOrgId = (orgId: string): void => {
  if (!growthBook) {
    return;
  }
  growthBook
    .setAttributeOverrides({
      orgId
    })
    .catch(console.error);
};
