import { ReactElement, ReactNode } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { theme } from 'primitives';
import { useCUITheme } from '@clickhouse/click-ui';

function ThemeProvider({ children }: { children: ReactNode }): ReactElement {
  const cuiTheme = useCUITheme();

  return (
    <EmotionThemeProvider theme={{ ...theme.lightTheme, ...cuiTheme }}>
      {children}
    </EmotionThemeProvider>
  );
}

export default ThemeProvider;
