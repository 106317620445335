import { isAwsRegionId } from '@cp/common/protocol/Region';
import { useState } from 'react';
import { Draft } from 'immer';
import { useAtom } from 'jotai';
import { useApiClient } from 'src/lib/controlPlane/client';
import { getCurrentServiceIdOrFail } from 'src/state/service';
import { useCurrentInstanceOrThrow } from 'src/instance/instanceController';
import { getKinesisConfigWithCredentials } from 'src/lib/dataLoading/clickpipes/kinesis';
import {
  ClickPipeKinesisImport,
  IamBased,
  KinesisImport,
  KinesisAuthType
} from 'shared/src/dataLoading/types';
import { kinesisStreamsAtom } from 'src/state/dataLoading';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';
import {
  Alert,
  Button,
  Container,
  Link,
  PasswordField,
  Select,
  Spacer,
  Text,
  TextField
} from '@clickhouse/click-ui';

const AMAZON_REGIONS = [
  'af-south-1',
  'ap-east-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-south-1',
  'ap-southeast-1',
  'ap-southeast-2',
  'ca-central-1',
  'eu-central-1',
  'eu-north-1',
  'eu-south-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'me-south-1',
  'sa-east-1',
  'us-east-1',
  'us-east-2',
  'us-gov-east-1',
  'us-gov-west-1',
  'us-west-1',
  'us-west-2'
] as const;

type ConnectionStatus = 'unknown' | 'error' | 'success';
type FlattenedFieldKey = 'integrationName' | 'description' | 'region';

type FieldKey = FlattenedFieldKey | 'password' | 'username' | 'role_arn';

const isPresent = (value: string | undefined): boolean =>
  typeof value === 'string' && value.length > 0;

const defaultErrorState: Record<string, string | undefined> = {};

function validateConnectionObject(
  importModel: ClickPipeKinesisImport
): Map<FieldKey, string> {
  const { auth } = importModel.data;
  const errors: Map<FieldKey, string> = new Map();
  if (auth.type === 'iam_user') {
    if (!isPresent(auth.username)) {
      errors.set('username', 'Field is required');
    }
    if (!isPresent(auth.password)) {
      errors.set('password', 'Field is required');
    }
  }
  if (auth.type === 'iam_role') {
    if (!isPresent(auth.role_arn)) {
      errors.set('role_arn', 'Field is required');
    }
  }

  if (!isPresent(importModel.data.region)) {
    errors.set('region', 'Field is required');
  }

  return errors;
}

const docsLink =
  'https://clickhouse.com/docs/en/integrations/clickpipes/kinesis';

export function SetupKinesisConnection(): JSX.Element | null {
  const api = useApiClient();
  const serviceId = getCurrentServiceIdOrFail();
  const [loading, setLoading] = useState(false);
  const [, setStreams] = useAtom(kinesisStreamsAtom);
  const instance = useCurrentInstanceOrThrow();

  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>('unknown');

  const [connectionError, setConnectionError] = useState<string | undefined>(
    undefined
  );
  const [errors, setErrors] = useState(defaultErrorState);

  const { importModel, updateImportModel } =
    useClickPipesImport<ClickPipeKinesisImport>();

  const data = importModel.data;
  const { regionId } = instance;

  const onFieldChangeHandler =
    (field: FlattenedFieldKey, trim: boolean = false) =>
    (value: string) => {
      setConnectionStatus('unknown');
      setError(field, undefined);
      updateImportModel<Draft<KinesisImport>>((model) => {
        const newValue = trim ? value.trim() : value;
        model.data[field] = newValue;
      });
    };

  const setIntegrationName = onFieldChangeHandler('integrationName');
  const setRegion = onFieldChangeHandler('region');
  const setDescription = onFieldChangeHandler('description');

  const setAuthType = (_type: string): void => {
    setConnectionStatus('unknown');
    setError('username', undefined);
    setError('password', undefined);

    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      const type = _type as KinesisAuthType;

      if (type === 'iam_user') {
        model.data.auth = {
          type: type,
          username: '',
          password: ''
        };
      } else if (type === 'iam_role') {
        model.data.auth = {
          type: type,
          role_arn: ''
        };
      }
    });
  };

  const setAccessKey = (access_key_id: string): void => {
    setConnectionStatus('unknown');
    setError('username', undefined);
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      if ('password' in model.data.auth) {
        const credentials = {
          username: access_key_id,
          password: model.data.auth?.password ?? '',
          type: model.data.auth.type
        };

        model.data.auth = credentials;
      }
    });
  };

  const setIamSecret = (secret_key: string): void => {
    setConnectionStatus('unknown');
    setError('password', undefined);
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      if ('username' in model.data.auth) {
        const credentials = {
          username: model.data.auth.username,
          password: secret_key,
          type: model.data.auth.type
        };

        model.data.auth = credentials;
      }
    });
  };

  const setIamRole = (role: string): void => {
    setConnectionStatus('unknown');
    setError('role_arn', undefined);
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      const auth = model.data.auth as IamBased;

      model.data.auth = {
        ...auth,
        role_arn: role
      };
    });
  };

  const setError = (key: FieldKey, error: string | undefined): void => {
    setErrors((oldErrors) => {
      const newErrors = { ...oldErrors };
      error === undefined ? delete newErrors[key] : (newErrors[key] = error);
      return newErrors;
    });
  };

  const validateModel = (): boolean => {
    let valid = true;

    if (!isPresent(importModel.data.integrationName)) {
      valid = false;
      setError('integrationName', 'Field is required');
    }

    const validationErrors = validateConnectionObject(importModel);
    for (const [field, message] of validationErrors.entries()) {
      setError(field, message);
    }

    return valid && validationErrors.size === 0;
  };

  const checkConnection = async (): Promise<boolean> => {
    try {
      const validationErrors = validateConnectionObject(importModel);
      for (const [field, message] of validationErrors.entries()) {
        setError(field, message);
      }
      if (validationErrors.size === 0) {
        const request = getKinesisConfigWithCredentials(importModel);
        const streams = await api.listKinesisStreams(request, serviceId);
        if (streams.length === 0) {
          setConnectionError('No streams found');
          setConnectionStatus('error');
          return false;
        }
        setStreams(streams);
        setConnectionError(undefined);
        // TODO handle no topics
        setConnectionStatus('success');
        return true;
      }
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message ?? "Can't connect to the data source.";
        setConnectionError(message);
        setConnectionStatus('error');
      } else {
        setConnectionError("Can't connect to the data source.");
        setConnectionStatus('error');
      }
    }

    return false;
  };

  const validateSetupConnection = async (): Promise<void> => {
    const valid = validateModel();
    if (!valid) {
      return;
    }

    if (connectionStatus === 'unknown') {
      const valid = await checkConnection();

      if (!valid) {
        return;
      }
    }

    updateImportModel<Draft<KinesisImport>>((model) => {
      model.data.step = 2;
    });
  };

  const nextStep = async (): Promise<void> => {
    setLoading(true);
    await validateSetupConnection();
    setLoading(false);
  };

  const back = (): void => {
    updateImportModel((draft: Draft<ClickPipeKinesisImport>) => {
      draft.data.step = 0;
    });
  };

  const isNextStepDisabled = loading || connectionStatus === 'error';
  return (
    <Container orientation="vertical" gap="md">
      <Text>
        If you need any help to connect your Amazon Kinesis{' '}
        <Link href={docsLink} target="_blank" rel="noreferrer">
          access the documentation
        </Link>
        for more details.
      </Text>
      <Container orientation="vertical" gap="md" maxWidth="800px">
        <TwoColsContainer>
          <TextField
            label="Integration name"
            error={errors['integrationName']}
            name="integrationName"
            value={data.integrationName ?? ''}
            onChange={setIntegrationName}
            placeholder="My new Kinesis pipe"
            data-testid="integration-name"
            autoComplete="off"
            className="fs-exclude"
          />
          <TextField
            label="Description"
            error={errors['description']}
            name="description"
            value={data.description ?? ''}
            onChange={setDescription}
            placeholder="Custom Kinesis ingestion"
            data-testid="description"
            autoComplete="off"
            className="fs-exclude"
          />
        </TwoColsContainer>

        <Select
          label="Amazon region"
          onSelect={setRegion}
          showSearch={true}
          value={data.region}
          error={errors['region']}
          data-testid="kinesis-amazon-region"
        >
          {AMAZON_REGIONS.map((region) => (
            <Select.Item key={region} value={region}>
              {region}
            </Select.Item>
          ))}
        </Select>

        <Select
          label="Authentication method"
          onSelect={setAuthType}
          value={data.auth.type}
          data-testid="auth-type"
        >
          <Select.Item value="iam_user">Credentials</Select.Item>
          <Select.Item value="iam_role" disabled={!isAwsRegionId(regionId)}>
            IAM role
          </Select.Item>
        </Select>

        {data.auth?.type === 'iam_user' && (
          <>
            <TextField
              label="IAM Access Key"
              error={errors['username']}
              name="username"
              placeholder="Select your IAM access key"
              value={data.auth.username}
              onChange={setAccessKey}
              data-testid="username"
              className="fs-exclude"
            />

            <PasswordField
              label="IAM Secret"
              error={errors['password']}
              name="password"
              placeholder="Input your IAM secret"
              value={data.auth.password}
              onChange={setIamSecret}
              data-testid="password"
              className="fs-exclude"
            />
          </>
        )}

        {/* TODO: Add a section explaining how to create the AWS IAM Role */}
        {data.auth?.type === 'iam_role' && (
          <TextField
            label="IAM ARN role"
            name="role"
            value={data.auth.role_arn}
            error={errors['role_arn']}
            placeholder="Input your IAM role"
            onChange={setIamRole}
            data-testid="iam_arn_role"
            className="fs-exclude"
          />
        )}
        {!loading && connectionStatus === 'error' && connectionError && (
          <Alert
            text={connectionError}
            state="danger"
            size="medium"
            showIcon={false}
          />
        )}
      </Container>

      <Spacer size="xs" />
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          Back
        </Button>
        <Button
          onClick={(): void => void nextStep()}
          loading={loading}
          disabled={isNextStepDisabled}
          data-testid="setup-conn-next-step"
        >
          Next: Incoming Data
        </Button>
      </Container>
      <Spacer size="xs" />
    </Container>
  );
}
