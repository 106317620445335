import { Container, Icon, IconName, Select, Text } from '@clickhouse/click-ui';
import { ReactNode } from 'react';
import { assertTruthy } from '@cp/common/utils/Assert';
import { Country, Region, RegionId } from '@cp/common/protocol/Region';

const countryToIconName: Record<Country, IconName> = {
  us: 'usa',
  de: 'de',
  ie: 'ie',
  sg: 'sg',
  in: 'in',
  nl: 'nl',
  au: 'au',
  ca: 'ca',
  br: 'br',
  za: 'za',
  se: 'sw',
  gb: 'gb',
  jp: 'jp',
  ch: 'ch'
};

interface RegionSelectorProps {
  regions: Array<Region>;
  selectedRegionId: RegionId | undefined;
  onRegionChange: (newRegion: RegionId) => void;
  disabled?: boolean;
}

export function RegionSelector({
  regions,
  selectedRegionId,
  onRegionChange,
  disabled
}: RegionSelectorProps): ReactNode {
  return (
    <Select
      label="Region"
      value={selectedRegionId}
      data-testid="service-region-select"
      disabled={disabled}
      onSelect={(value) => {
        const newRegion = regions.find((member) => member.id === value);
        assertTruthy(newRegion, 'Invalid region');
        onRegionChange(newRegion.id);
      }}
    >
      {regions.map((region) => (
        <Select.Item
          value={region.id}
          key={region.id}
          data-testid="region-option"
        >
          <Container
            padding="none"
            gap="md"
            alignItems="center"
            isResponsive={false}
          >
            <Icon size="sm" name={countryToIconName[region.country]} />
            <Text>
              {region.name} ({region.displayId})
            </Text>
          </Container>
        </Select.Item>
      ))}
    </Select>
  );
}
