import { DRIFT_API_PATH, GetDriftIdentityJwtRequest, GetDriftIdentityJwtResponse } from '@cp/common/protocol/Drift';
import { getErrorMessageFromError } from '@cp/common/utils/HttpError';
import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';

const DRIFT_CP_API_PATH = `${config.controlPlane.apiHost}/api/${DRIFT_API_PATH}`;
export class DriftApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  rpcRequest(request: GetDriftIdentityJwtRequest, url = DRIFT_CP_API_PATH): Promise<Response> {
    return this.httpClient.post(
      url,
      {
        body: JSON.stringify(request)
      },
      { includeAuthProviderHeader: false }
    );
  }

  async getUserJwt(): Promise<GetDriftIdentityJwtResponse> {
    const getDriftIdentityJwtRequest: GetDriftIdentityJwtRequest = {
      rpcAction: 'getDriftIdentityJwt'
    };

    const response = await this.rpcRequest(getDriftIdentityJwtRequest);

    if (!response.ok) {
      const error = (await response.json()) as unknown;
      throw new Error(getErrorMessageFromError(error));
    }

    return (await response.json()) as unknown as GetDriftIdentityJwtResponse;
  }
}
