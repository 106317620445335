import { ReactElement, useEffect, useRef } from 'react';
import { AddTabButton } from 'src/components/App/MainView/TabBar/AddTabButton';
import { HomeTab } from 'src/components/App/MainView/TabBar/HomeTab';
import Tabs from 'src/components/App/MainView/TabBar/Tabs';
import { flexFixedSize } from 'src/components/global-styles';
import type { TableDrop } from 'shared/src/types/query';
import { useSocketIo } from 'src/lib/socketio';
import { useIsRestoringTabs, useRemoveTableTabs } from 'src/state/tabs';
import { Tab as TabType } from 'src/state/tabs/types';

import RunningQueryList from 'src/components/RunningQueryList';

import RemoveTabModal from 'src/components/App/MainView/TabBar/RemoveTabModal';
import ServiceSelector from 'src/components/App/MainView/TabBar/ServiceSelector';
import styles from 'src/components/App/MainView/TabBar/styles';
import { useRemoveTabs } from 'src/components/App/MainView/TabBar/useRemoveTabs';

interface Props {
  onNewTabClick: () => void;
  onTabClick: (idx: number, tab?: TabType) => void;
  selectedTabIndex: number;
  tabs: TabType[];
}

export default function TabBar({
  onNewTabClick,
  onTabClick,
  selectedTabIndex,
  tabs
}: Props): ReactElement {
  const isRestoringTabs = useIsRestoringTabs();
  const { getMenuOptions, closeModalProps, getStatus, closeCurrentTab, log } =
    useRemoveTabs(tabs);
  const containerRef = useRef<HTMLDivElement>(null);

  const removeTableTabs = useRemoveTableTabs();

  const { client: socketIo } = useSocketIo();

  useEffect(() => {
    const listener = async (tableDrops: TableDrop[]): Promise<void> => {
      removeTableTabs(tableDrops);
    };

    if (socketIo) {
      socketIo.on('tableDrops', listener);
    }

    return () => {
      socketIo?.removeListener('tableDrops', listener);
    };
  }, [socketIo]);

  return (
    <>
      <div css={styles.tabBarContainer}>
        <div css={styles.container} ref={containerRef}>
          {!isRestoringTabs && (
            <>
              <HomeTab
                active={selectedTabIndex === -1}
                onClick={(e): void => {
                  e.preventDefault();
                  log('home', 'focus', 'trigger');
                  onTabClick(-1);
                }}
                hasTabs={tabs.length > 0}
              />
              <Tabs
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onTabClick={onTabClick}
                onTabClose={closeCurrentTab}
                getMenuOptions={getMenuOptions}
                getStatus={getStatus}
                log={log}
                containerWidth={
                  containerRef && containerRef.current
                    ? containerRef.current.clientWidth - 76
                    : undefined
                }
              />
              <AddTabButton onClick={onNewTabClick} />
            </>
          )}
        </div>
        <div css={flexFixedSize}>
          <RunningQueryList />
        </div>
        <div css={flexFixedSize}>
          <ServiceSelector />
        </div>
      </div>
      <RemoveTabModal {...closeModalProps} />
    </>
  );
}
