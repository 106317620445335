import React, {
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState
} from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { DataPreview } from 'src/lib/dataLoading/DataPreviewer';
import { flex, alignCenter, mr10, alignSelfTop } from 'src/lib/utility-styles';
import {
  FileType,
  FileUrlAccessKeyIdCredentials,
  extractFileTypeAndCompression
} from 'shared/src/dataLoading';
import { useDebounceFunction } from 'src/lib/hooks';

import FileSize from 'src/components/ImportView/ImportForm/SourceInput/FileSize';
import FileUrlInput from 'src/components/ImportView/ImportForm/SourceInput/FileUrlInput';
import {
  Button,
  Container,
  Icon,
  PasswordField,
  Popover,
  TextField
} from '@clickhouse/click-ui';
import { marginStyle } from 'src/components/global-styles';
import { useImportModel } from 'src/lib/dataLoading';
import { isCloudEnv } from 'shared/src/isCloudEnv';
import { useApiClient } from 'src/lib/controlPlane/client';
import { isSupportedS3Url } from 'shared/src/s3/s3';

const textStyle = css({
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

type ValidUrlResponse =
  | { valid: true; message: null }
  | { valid: false; message: string };

function validateUrl(url: string): ValidUrlResponse {
  try {
    new URL(url);
    return { valid: true, message: null };
  } catch (error) {
    return { valid: false, message: 'Please provide a valid URL' };
  }
}

type Props = {
  url: string | undefined;
  sizeBytes: number | null | undefined;
  setName: (name: string) => void;
  setUrl: (url: string) => void;
  setFileType: (fileType: FileType | null) => void;
  setPreviewData: (data: DataPreview | null) => void;
  getDataPreview: (
    url: string,
    credentials?: FileUrlAccessKeyIdCredentials
  ) => Promise<DataPreview>;
  loading: boolean;
  setLoading: (value: boolean) => void;
  error: string | null;
  setError: (message: string | null) => void;
};

const urlSourceInputStyle = css({
  display: 'grid',
  gridTemplateColumns: '1fr auto auto',
  alignItems: 'flex-end',
  width: '100%',
  gap: '0 12px'
});

const colorStyle = (color: string): SerializedStyles =>
  css({
    color
  });

function LoadingState({
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <div css={[flex, alignCenter]} {...props}>
      <Icon size="sm" name="loading-animated" css={mr10} />
      <div>Checking path</div>
    </div>
  );
}

export type AWSCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
};

type CredentialsInputProps = {
  url: string;
  onChange: (credentials: AWSCredentials) => void;
};

function CredentialsInput({
  url,
  onChange
}: CredentialsInputProps): ReactElement {
  const api = useApiClient();
  const { setHasValidCredentials, hasValidCredentials } = useImportModel();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [accessKeyId, setAccessKeyId] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');
  const [credentialsError, setCredentialsError] = useState<string | null>(null);

  async function checkIfS3IsAccessible(): Promise<boolean> {
    setLoading(true);
    try {
      const allowed = await api.checkS3ObjectCredentials({
        url,
        credentials: {
          accessKeyId,
          secretAccessKey
        }
      });
      return allowed;
    } finally {
      setLoading(false);
    }
  }

  const save = async (): Promise<void> => {
    try {
      const allowed = await checkIfS3IsAccessible();
      if (!allowed) {
        setCredentialsError('Invalid credentials');
        setHasValidCredentials(false);
        return;
      }

      setHasValidCredentials(true);
      onChange({ accessKeyId, secretAccessKey });
      setOpen(false);
    } catch (error) {
      setCredentialsError('Invalid credentials');
      setHasValidCredentials(false);
    }
  };

  return (
    <Popover open={open}>
      <Popover.Trigger>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          Configure S3 Access
        </Button>
      </Popover.Trigger>
      <Popover.Content side="bottom" showArrow={true}>
        <Container orientation="vertical" gap="sm">
          <TextField
            error={credentialsError}
            label="Access Key ID"
            value={accessKeyId}
            onChange={(value: string): void => setAccessKeyId(value)}
            placeholder="Enter your access key id"
          />
          <PasswordField
            error={credentialsError}
            label="Secret Access Key"
            value={secretAccessKey}
            onChange={(value: string): void => setSecretAccessKey(value)}
            placeholder="Enter your secret access key"
          />
          <Button
            fillWidth
            loading={loading}
            disabled={loading}
            label="Save"
            onClick={() => {
              save().catch(console.error);
            }}
          />
        </Container>
      </Popover.Content>
    </Popover>
  );
}

function UrlSourceInput({
  url,
  setName,
  setUrl,
  setFileType,
  setPreviewData,
  getDataPreview,
  sizeBytes,
  children,
  loading,
  setLoading,
  error,
  setError
}: PropsWithChildren<Props>): ReactElement | null {
  const {
    fileType,
    hasValidPassword,
    setRequiresCredentials,
    requiresCredentials,
    setCredentials,
    hasValidCredentials,
    setHasValidCredentials
  } = useImportModel();

  const updateDataPreview = useCallback(
    function updateDataPreview(
      value: string,
      credentials?: FileUrlAccessKeyIdCredentials
    ): void {
      const loadPreviewData = async (): Promise<void> => {
        try {
          const dataPreview = await getDataPreview(value, credentials);
          setName(value);
          setPreviewData(dataPreview);
          setFileType(dataPreview.fileType);
        } catch (error) {
          setPreviewData(null);
          setFileType(null);
          setError(
            'Could not read data from remote url using current file format'
          );
        } finally {
          setLoading(false);
        }
      };

      loadPreviewData().catch(console.error);
    },
    [getDataPreview, setError, setFileType, setLoading, setName, setPreviewData]
  );

  const debouncedUpdateDataPreview = useDebounceFunction(
    updateDataPreview,
    250
  );

  const isS3PasswordProtected = async (url: string): Promise<boolean> => {
    if (!isSupportedS3Url(isCloudEnv(), url)) {
      return false;
    }

    let queriableUrl = url;
    if (new URL(url).protocol === 's3://') {
      queriableUrl = url.replace('s3://', 'https://s3.amazonaws.com/');
    }

    try {
      const isReachableResponse = await fetch(queriableUrl, { method: 'HEAD' });
      return !isReachableResponse.ok;
    } catch (error) {
      console.error(error);
      return true;
    }
  };

  const onUrlChange = async (value: string): Promise<void> => {
    const { fileType } = extractFileTypeAndCompression(value);
    setUrl(value);
    setError(null);
    setFileType(fileType);
    setRequiresCredentials(false);
    setHasValidCredentials(false);
    setCredentials(undefined);

    const { valid, message } = validateUrl(value);

    if (!valid) {
      setError(valid ? null : message);
      setLoading(false);
      setPreviewData(null);
      return;
    }

    const requiresPassword = await isS3PasswordProtected(value);

    if (requiresPassword) {
      setRequiresCredentials(true);
    }

    if (!requiresPassword) {
      setLoading(true);
      debouncedUpdateDataPreview(value);
    }
  };

  const onAddCredentials = (credentials: AWSCredentials): void => {
    setLoading(true);
    setError(null);
    const awsCredentials: FileUrlAccessKeyIdCredentials = {
      type: 'accessKeyId',
      ...credentials
    };
    setCredentials(awsCredentials);

    if (fileType) {
      debouncedUpdateDataPreview(url ?? '', awsCredentials);
    }
  };

  const hasUrl = !!url && url.length > 0;
  const isValid =
    !error &&
    !loading &&
    hasUrl &&
    (!requiresCredentials || (requiresCredentials && hasValidPassword));

  return (
    <div css={urlSourceInputStyle}>
      <FileUrlInput
        url={url ?? ''}
        onChange={onUrlChange}
        error={!loading && error ? error : undefined}
      />

      {!loading && requiresCredentials && url && !hasValidCredentials && (
        <CredentialsInput url={url} onChange={onAddCredentials} />
      )}

      {!loading && !error && (
        <FileSize
          bytes={sizeBytes}
          css={[alignSelfTop, marginStyle({ top: 34 }), textStyle]}
        />
      )}

      {loading && (
        <LoadingState css={[alignSelfTop, marginStyle({ top: 34 })]} />
      )}

      {isValid && (
        <Icon
          size="sm"
          name="check-in-circle"
          css={[
            alignSelfTop,
            marginStyle({ top: 34, right: 12 }),
            colorStyle('green')
          ]}
        />
      )}
      {!error && children}
    </div>
  );
}

const MemoizedUrlSourceInput = React.memo(UrlSourceInput);
MemoizedUrlSourceInput.displayName = 'UrlSourceInput';
export default MemoizedUrlSourceInput;
