import { NotificationSeverity } from '@cp/common/protocol/NotificationInventory';

type IconName = 'information' | 'warning' | 'cross';
export const getIconNameBySeverity = (severity?: NotificationSeverity): IconName => {
  switch (severity) {
    case 'info':
      return 'information';
    case 'warning':
      return 'warning';
    case 'critical':
      return 'cross';
    default:
      return 'information';
  }
};

export const getContainerTypeBySeverity = (severity?: NotificationSeverity): 'info' | 'warning' | 'danger' => {
  switch (severity) {
    case 'info':
      return 'info';
    case 'warning':
      return 'warning';
    case 'critical':
      return 'danger';
    default:
      return 'info';
  }
};
