import { useImportModel } from 'src/lib/dataLoading';
import { isClickPipesImport } from 'shared/src/dataLoading';

import ImportForm from 'src/components/ImportView/ImportForm';
import ClickPipesImportForm from 'src/components/ImportView/ClickPipesImportForm';
import SampleDataForm from 'src/components/ImportView/SampleDataForm';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';

function ImportView(): JSX.Element | null {
  const { importModel } = useImportModel();

  if (importModel.type === 'sample_data') {
    return (
      <MaxWidthContainer orientation="vertical" padding="xl">
        <SampleDataForm />
      </MaxWidthContainer>
    );
  }

  if (isClickPipesImport(importModel)) {
    return (
      <MaxWidthContainer orientation="vertical" padding="xl">
        <ClickPipesImportForm />
      </MaxWidthContainer>
    );
  }

  return (
    <MaxWidthContainer orientation="vertical" padding="xl">
      <ImportForm />
    </MaxWidthContainer>
  );
}

export default ImportView;
