import { ALLOW_DENY, AuthorizationDecision, Permission, Policy, RoleMapping } from '@cp/common/protocol/Authorization';
import { assertTruthy } from '@cp/common/utils/Assert';
import { checkRolesHasPermission } from '@cp/common/utils/Authorization';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import * as thisModule from 'src/authorization/authorizationState';
import { useCurrentInstance } from 'src/instance/instanceController';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import { useSystemFeature } from 'src/lib/features';
import { useCurrentOrganization, useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';

const policiesState = atom<Policy[]>([]);
const roleMappingsState = atom<RoleMapping[]>([]);

interface PolicyStateManager {
  policies: Policy[];
  setPolicies: (i: Policy[]) => void;
  roleMappings: RoleMapping[];
  setRoleMappings: (i: RoleMapping[]) => void;
  getRoleIdsForActorTargetEntityPair: (actorEntityId: string, targetEntityId: string) => string[];
}

export const useAuthorizationStateManager = (): PolicyStateManager => {
  const [policies, setPoliciesAtom] = useAtom(policiesState);
  const setPolicies = useCallback(
    (policies: Policy[]): void => {
      setPoliciesAtom((state) => setPoliciesInState(state, policies));
    },
    [setPoliciesAtom]
  );

  const [roleMappings, setRoleMappingsAtom] = useAtom(roleMappingsState);
  const setRoleMappings = useCallback(
    (roleMappings: RoleMapping[]): void => {
      setRoleMappingsAtom((state) => setRoleMappingsInState(state, roleMappings));
    },
    [setRoleMappingsAtom]
  );

  const getRoleIdsForActorTargetEntityPair = (actorEntityId: string, targetEntityId: string): string[] =>
    roleMappings
      .filter(
        (roleMapping) => roleMapping.actorEntityId === actorEntityId && roleMapping.targetEntityId === targetEntityId
      )
      .map((roleMapping) => roleMapping.roleId);
  return { policies, setPolicies, roleMappings, setRoleMappings, getRoleIdsForActorTargetEntityPair };
};

const setPoliciesInState = (state: Policy[], policies: Policy[]): Policy[] => policies;
const setRoleMappingsInState = (state: RoleMapping[], roleMappings: RoleMapping[]): RoleMapping[] => roleMappings;

export const useCurrentUserApplicableRoleIds = (): string[] => {
  const { user } = useUserStateManager();
  return user.roleIds ?? [];
};

/**
 * Get the roleIds of user, organizationUser, and organization
 * for the current user in the current organization.
 */
export const useCurrentUserAndOrganizationApplicableRoleIdsOrThrow = (): string[] => {
  const userRoles = useCurrentUserApplicableRoleIds();
  const user = useCurrentUserOrThrow();
  const organization = useCurrentOrganizationOrThrow();
  const organizationUser = organization.users[user.id];
  assertTruthy(organizationUser, 'user has no access to the current organization');

  return userRoles.concat(organization.roleIds ?? []).concat(organizationUser.roleIds ?? []);
};

/**
 * Get the current user's roleIds for the current instance.
 */
export const useCurrentInstanceUserApplicableRoleIds = (): string[] => {
  const user = useCurrentUserOrThrow();
  const instance = useCurrentInstance();
  const { getRoleIdsForActorTargetEntityPair } = useAuthorizationStateManager();

  if (!instance) {
    return [];
  }

  return getRoleIdsForActorTargetEntityPair(user.id, instance.id);
};

export const useCheckRolesHasPermission = (
  roleIds: string[],
  permission: Permission,
  entityTarget: string,
  legacyRoleBasedDecision: boolean
): boolean => {
  const shouldUseFineGrainedAuthorization = useSystemFeature('FT_SYS_USE_FINE_GRAINED_AUTHORIZATION');
  const { policies } = thisModule.useAuthorizationStateManager();

  if (!shouldUseFineGrainedAuthorization) {
    return legacyRoleBasedDecision;
  }

  return (
    getAuthorizationDecision({
      allPolicies: policies,
      roleIds,
      permission,
      entityTarget
    }).allowDeny === ALLOW_DENY.ALLOW
  );
};

export const useUserAndOrgRolesHasPermissionForInstance = (
  permission: Permission,
  legacyRoleBasedDecision: boolean
): boolean => {
  const instance = useCurrentInstance();
  const roleIds = useCurrentUserAndOrganizationApplicableRoleIdsOrThrow();
  const instanceUserRoleIds = useCurrentInstanceUserApplicableRoleIds();
  return useCheckRolesHasPermission(
    roleIds.concat(instanceUserRoleIds),
    permission,
    instance?.id ?? '',
    legacyRoleBasedDecision
  );
};

export const useUserAndOrgRolesHasPermissionForOrganization = (
  permission: Permission,
  legacyRoleBasedDecision: boolean
): boolean => {
  const organization = useCurrentOrganization();
  const roleIds = useCurrentUserAndOrganizationApplicableRoleIdsOrThrow();
  return useCheckRolesHasPermission(roleIds, permission, organization?.id ?? '', legacyRoleBasedDecision);
};

interface GetAuthorizationDecisionArgs {
  allPolicies: Policy[];
  roleIds: string[];
  permission: Permission;
  entityTarget?: string;
}

export const getAuthorizationDecision = ({
  allPolicies,
  roleIds,
  permission,
  entityTarget
}: GetAuthorizationDecisionArgs): AuthorizationDecision => {
  const p = allPolicies.filter(
    (policy) =>
      roleIds.includes(policy.roleId) &&
      policy.permissionId === permission &&
      (policy.entityTarget === entityTarget || !policy.entityTarget)
  );

  return checkRolesHasPermission(p, permission);
};
