import { CpUserDetails } from '@cp/common/protocol/Account';
import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

export type UserState = Partial<CpUserDetails>;
const userState = atom<UserState>({});

interface UserStateManager {
  user: UserState;
  setUser: (i: UserState) => void;
  updateUser: (user: Partial<UserState>) => void;
}

const useUserStateManager = (): UserStateManager => {
  const [user, setUserAtom] = useAtom(userState);

  const setUser = useCallback(
    (user: UserState): void => {
      setUserAtom((state) => setUserInState(state, user));
    },
    [setUserAtom]
  );

  /** Update or set a part of the state. */
  const updateUser = useCallback(
    (user: Partial<UserState>): void => {
      setUserAtom((state) => updateUserInState(state, user));
    },
    [setUserAtom]
  );

  return { user, setUser, updateUser };
};

export const setUserInState = (state: UserState, user: UserState): UserState => user;

export const updateUserInState = (state: UserState, user: Partial<UserState>): UserState => ({
  ...state,
  ...user
});

export { useUserStateManager };
