import { RunningQueryColumn, RunningQueryError, RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { QueryMetrics, QueryProgress } from 'src/lib/query/streamingQuery';

import { useQuery } from 'src/lib/query/QueryState';
import { Row } from 'shared/src/clickhouse/types';

export function useQueryStatus(runId: string | null | undefined): RunningQueryStatusName {
  const query = useQuery(runId);
  return query?.status ?? 'new';
}

export function useQueryColumns(runId: string | null | undefined): undefined | RunningQueryColumn[] {
  const query = useQuery(runId);
  return query?.result?.columns;
}

export function useQueryError(runId: string | null | undefined): undefined | RunningQueryError {
  const query = useQuery(runId);
  return query?.result?.error;
}

export function useQueryMessage(runId: string | null | undefined): undefined | string {
  const query = useQuery(runId);
  return query?.result?.message;
}

export function useQueryMetrics(runId: string | null | undefined): undefined | QueryMetrics {
  const query = useQuery(runId);
  return query?.result?.metrics;
}

export function useQueryProgress(runId: string | null | undefined): undefined | QueryProgress {
  const query = useQuery(runId);
  return query?.result?.progress;
}

export function useQueryRawData(runId: string | null | undefined): string | undefined {
  const query = useQuery(runId);
  const rawDataLines = query?.result?.rawData;
  if (!rawDataLines) {
    return undefined;
  }
  return rawDataLines.join('\n');
}

export function useQueryTotals(runId: string | null | undefined): undefined | Row {
  const query = useQuery(runId);
  return query?.totals;
}
