import { Checkbox, Container, Label } from '@clickhouse/click-ui';
import { QuestionAnswerType } from '@cp/common/protocol/Account';
import { shuffle } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import QuestionnaireAnswerWithInput from 'src/components/QuestionnaireAnswerWithInput/QuestionnaireAnswerWithInput';
import { ReasonListType, wrapQuestionAnswers } from 'src/lib/questionnaire';

// eslint-disable-next-line react-refresh/only-export-components
export const deleteReasonOptions: string[] = [
  'Too expensive',
  'Too complicated to use',
  'Trial too short',
  'Still evaluating the product',
  'Missing features',
  'Technical issues'
];

const reasonWithInput = new Set<string>(['Missing features']);

interface ExitQuestionnaireProps {
  onValidQuestionnaireChange: (isValid: boolean) => void;
  onReasonListChange: (reasons: Array<QuestionAnswerType>) => void;
}
export const ExistQuestionnaire = ({
  onValidQuestionnaireChange,
  onReasonListChange
}: ExitQuestionnaireProps): ReactElement => {
  const [deleteReasonList, setDeleteReasonList] = useState<ReasonListType>(
    () => {
      return shuffle(deleteReasonOptions).map((reason) => ({
        label: reason,
        checked: false,
        hasInput: reasonWithInput.has(reason)
      }));
    }
  );
  const [deleteReasonOther, setDeleteReasonOther] = useState({
    value: '',
    checked: false
  });
  const [hasOtherError, setHasOtherError] = useState(false);
  const deleteReasonQuestion = 'Why did you decide to delete your service?';

  const onCheckedDeleteReasonChange = (index: number) => (checked: boolean) => {
    setDeleteReasonList((reasonList) => {
      const newReasonList = [...reasonList];
      newReasonList[index].checked = checked;
      return newReasonList;
    });
  };

  const onValueDeleteReasonChange = (index: number) => (value: string) => {
    setDeleteReasonList((reasonList) => {
      const newReasonList = [...reasonList];
      newReasonList[index].value = value;
      return newReasonList;
    });
  };

  useEffect(() => {
    // Check if the questionnaire form is valid.
    let isValid =
      deleteReasonList.some((reason) => reason.checked) ||
      deleteReasonOther.checked;
    // Check if all the checked options are valid (are not missing input value).
    for (const reason of deleteReasonList) {
      if (reason.checked) {
        isValid = isValid && (!reason.hasInput || !!reason.value);
      }
    }
    // Check that the Other option is valid if checked.
    if (deleteReasonOther.checked) {
      isValid = isValid && !!deleteReasonOther.value && !hasOtherError;
    }
    onValidQuestionnaireChange(isValid);
    // Wrap the checked answers.
    const deleteQuestion = wrapQuestionAnswers(
      deleteReasonQuestion,
      deleteReasonList,
      deleteReasonOther
    );
    onReasonListChange([deleteQuestion]);
  }, [
    deleteReasonList,
    deleteReasonOther,
    hasOtherError,
    onValidQuestionnaireChange,
    onReasonListChange
  ]);

  return (
    <Container
      orientation="vertical"
      gap="md"
      overflow="hidden"
      data-testid="exit-questionnaire-container"
    >
      <Label>{deleteReasonQuestion}</Label>
      <Container orientation="vertical" gap="xs">
        {deleteReasonList.map((reason, index) => {
          if (reason.hasInput) {
            return (
              <QuestionnaireAnswerWithInput
                key={index}
                label={reason.label}
                value={reason.value || ''}
                checked={!!reason.checked}
                onCheckedChange={onCheckedDeleteReasonChange(index)}
                onChange={onValueDeleteReasonChange(index)}
                onErrorChange={setHasOtherError}
              />
            );
          } else {
            return (
              <Checkbox
                key={`delete-reason-${index}`}
                label={reason.label}
                checked={reason.checked}
                onCheckedChange={onCheckedDeleteReasonChange(index)}
                data-testid="delete-reason-checkbox"
              />
            );
          }
        })}
        <QuestionnaireAnswerWithInput
          label="Other"
          value={deleteReasonOther.value}
          checked={deleteReasonOther.checked}
          onCheckedChange={(checked) =>
            setDeleteReasonOther((value) => ({ ...value, checked }))
          }
          onChange={(value) =>
            setDeleteReasonOther((reason) => ({ ...reason, value }))
          }
          onErrorChange={setHasOtherError}
        />
      </Container>
    </Container>
  );
};
