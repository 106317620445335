import { Draft } from 'immer';
import { useAtom } from 'jotai';
import { createToast } from 'primitives';
import { ReactElement, useEffect, useState } from 'react';
import { KinesisStreamFormat } from 'shared/src/dataLoading';
import {
  Button,
  Container,
  Spacer,
  Select,
  NumberField,
  Switch
} from '@clickhouse/click-ui';

import { errorMessage } from 'src/lib/errors/errorMessage';
import { DataPreviewer } from 'src/lib/dataLoading/DataPreviewer';
import { getKinesisConfigWithCredentials } from 'src/lib/dataLoading/clickpipes/kinesis';
import {
  ClickPipeKinesisImport,
  KinesisOffsetType
} from 'shared/src/dataLoading';
import { useTables } from 'src/metadata/metadataState';
import { kinesisStreamsAtom } from 'src/state/dataLoading';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useSelectedDatabaseValue } from 'src/metadata/selectedDatabase';
import TwoColsContainer from 'src/components/ImportView/ClickPipesImportForm/TwoColsContainer';
import {
  convertSampleSchemaToColumns,
  isNoDataReceivedError
} from 'src/lib/dataLoading/clickpipes/samples';

import { useApiClient } from 'src/lib/controlPlane/client';
import Sample from 'src/components/ImportView/ClickPipesImportForm/IncomingData/Sample';
import { useClickPipesImport } from 'src/components/ImportView/ClickPipesImportForm/hooks';

export function KinesisSample(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [_, enforceUpdate] = useState<unknown[]>([]);
  const [error, setError] = useState<string | null>(null);
  const tables = useTables();
  const api = useApiClient();
  const database = useSelectedDatabaseValue();
  const [loadTimestamp, setLoadTimestamp] = useState(false);
  const serviceId = useCurrentInstanceId() ?? '';

  const [sample, setSample] = useState<string | null>(null);
  const [flattenSample, setFlattenSample] = useState<string | null>(null);

  const [streams] = useAtom(kinesisStreamsAtom);
  const { importModel, updateImportModel, setTableConfig } =
    useClickPipesImport<ClickPipeKinesisImport>();

  function setTimestamp(value: string): void {
    if (parseInt(value) < 0 || parseInt(value) > 9007199254740991) {
      setError('Timestamp must be a valid unix timestamp');
      return;
    }
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      setError(null);
      model.data.timestamp = value;
    });
  }
  function setStream(value: string): void {
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      setError(null);
      model.data.streamName = value;
    });
  }
  function setOffset(value: string): void {
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      setError(null);
      setSample(null);
      if (
        typeof value === 'string' &&
        (value === 'LATEST' ||
          value === 'TRIM_HORIZON' ||
          value === 'AT_TIMESTAMP')
      ) {
        model.data.startingOffset = value;
      }
    });
  }

  function setEnhancedFanout(value: boolean): void {
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      setError(null);
      model.data.enhancedFanout = value;
    });
  }
  function setFlatten(value: boolean): void {
    updateImportModel<Draft<ClickPipeKinesisImport>>((model) => {
      setError(null);
      model.data.flatten = value;
    });
  }
  useEffect(() => {
    const dataPreviewer = new DataPreviewer(api, serviceId, tables, database);
    const updateSample = async (
      streamName: string,
      format: KinesisStreamFormat,
      startingOffset: KinesisOffsetType,
      flatten: boolean = false
    ): Promise<void> => {
      setLoading(true);
      setError(null);
      const configWithCredentials =
        getKinesisConfigWithCredentials(importModel);
      try {
        const { samples, schema, sampleValues } =
          await api.getKinesisSampleSchema(
            {
              ...configWithCredentials,
              streamName,
              format,
              startingOffset,
              timestamp: importModel.data.timestamp,
              flatten
            },
            serviceId
          );

        updateImportModel((draft: Draft<ClickPipeKinesisImport>) => {
          draft.data.sourceSchema = [...schema];
          draft.data.rowsPreview = sampleValues;
        });

        const tableConfig = dataPreviewer.generateTableConfig(
          'newTable',
          streamName,
          convertSampleSchemaToColumns(schema)
        );
        setTableConfig(tableConfig);

        const sample =
          samples.length > 0 ? JSON.stringify(samples[0], null, 2) : null;
        if (flatten) {
          setFlattenSample(sample);
        } else {
          setSample(sample);
        }
      } catch (error) {
        setError(errorMessage(error));
        setSample(null);
      } finally {
        setLoading(false);
      }
    };
    if (
      importModel.data.streamName &&
      importModel.data.startingOffset &&
      (loadTimestamp || importModel.data.startingOffset !== 'AT_TIMESTAMP')
    ) {
      setLoadTimestamp(false);
      updateSample(
        importModel.data.streamName,
        importModel.data.format,
        importModel.data.startingOffset,
        importModel.data.flatten
      ).catch(setError);
    } else {
      setSample(null);
    }
  }, [
    importModel.data.streamName,
    importModel.data.startingOffset,
    importModel.data.flatten,
    loadTimestamp
  ]); // this dependency array is enough. If you add more, you might end on an infinite loop
  const updateSampleFunc = (): void => {
    setLoadTimestamp(true);
  };

  const back = (): void => {
    updateImportModel((draft: Draft<ClickPipeKinesisImport>) => {
      draft.data.step = 1;
    });
  };

  const next = (): void => {
    if (loading) {
      return;
    }
    if (importModel.data.streamName && importModel.data.startingOffset) {
      updateImportModel((draft: Draft<ClickPipeKinesisImport>) => {
        draft.data.step = 3;
      });
    } else {
      createToast(
        'Error',
        'alert',
        "Can't proceed with current configuration. No data found"
      );
    }
  };

  const isNextStepDisabled = Boolean(
    loading ||
      error ||
      !importModel.data.streamName ||
      !importModel.data.startingOffset ||
      sample === null
  );

  return (
    <Container orientation="vertical" gap="md" maxWidth="800px">
      <TwoColsContainer>
        <Select
          label="Kinesis stream"
          onSelect={setStream}
          value={importModel.data.streamName}
          data-testid="kinesis-stream"
        >
          {streams.map((stream) => (
            <Select.Item key={stream} value={stream}>
              {stream}
            </Select.Item>
          ))}
        </Select>
        <Select
          label="Data format"
          value={importModel.data.format}
          disabled={true}
          data-testid="data-format"
        >
          <Select.Item key="JSONEachRow" value="JSONEachRow">
            JSONEachRow
          </Select.Item>
        </Select>
      </TwoColsContainer>
      <Select
        label="Starting offset"
        value={importModel.data.startingOffset as string}
        onSelect={setOffset}
        disabled={!importModel.data.streamName}
        data-testid="kinesis-starting-offset"
      >
        <Select.Item key="TRIM_HORIZON" value="TRIM_HORIZON">
          Earliest (Trim Horizon)
        </Select.Item>
        <Select.Item key="LATEST" value="LATEST">
          Latest
        </Select.Item>
        <Select.Item key="AT_TIMESTAMP" value="AT_TIMESTAMP">
          At Timestamp
        </Select.Item>
      </Select>
      {importModel.data.startingOffset === 'AT_TIMESTAMP' && (
        <TwoColsContainer>
          {/* TODO: Switch to Date/Time picker when available and allows second selection  */}
          <NumberField
            min="0"
            max="9007199254740991"
            placeholder="Unix timestamp"
            value={importModel.data.timestamp}
            loading={false}
            onChange={setTimestamp}
          ></NumberField>
          <Button loading={loading} onClick={updateSampleFunc}>
            Fetch Sample
          </Button>
        </TwoColsContainer>
      )}
      <Sample
        format={importModel.data.format}
        sample={sample}
        setFlatten={setFlatten}
        flattenSample={flattenSample}
        flatten={importModel.data.flatten}
        loading={loading}
        error={error}
        offsetInfo={{
          offset: undefined,
          partition: undefined,
          timestamp: undefined
        }}
      />
      <Switch
        onCheckedChange={setEnhancedFanout}
        label="Use Kinesis Enhanced Fan-Out"
        checked={Boolean(importModel.data.enhancedFanout)}
        data-testid="offset-selection-switch"
        orientation="horizontal"
        dir="end"
      />
      <Spacer size="xs" />
      <Container gap="md">
        <Button type="secondary" onClick={back}>
          Back
        </Button>
        {error && isNoDataReceivedError(error) ? (
          <Button onClick={(): void => enforceUpdate([])}>Retry</Button>
        ) : (
          <Button
            onClick={next}
            loading={loading}
            disabled={isNextStepDisabled}
            data-testid="incoming-data-next-step"
          >
            Next: Parse Information
          </Button>
        )}
      </Container>
    </Container>
  );
}
