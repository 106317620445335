import { MutableRefObject, RefObject, useMemo } from 'react';

import { SerializedStyles, Theme } from '@emotion/react';
// export function applyTheme ({ classes, theme }) {
//   if (Array.isArray(classes)) {
//     return classes.map(applyTheme)
//   } else if (typeof classes === 'function') {
//     return classes(theme)
//   } else {
//     return classes
//   }
// }

type RefFunctionOrMutableRef<T> = MutableRefObject<T> | ((value: T) => void);
export function setRef<T>(ref: RefFunctionOrMutableRef<T>, value: T): void {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null) {
    ref.current = value;
  }
}

export function useForkRef<T>(refA: RefObject<T>, refB: RefObject<T>): ((value: T) => void) | null {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior
   */
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }

    return (instance: T) => {
      setRef(refA, instance);
      setRef(refB, instance);
    };
  }, [refA, refB]);
}

type StylesOrThemeFunction = SerializedStyles | ((theme: Theme) => SerializedStyles);

type StyledMap<T> = {
  [Property in keyof T]: SerializedStyles;
};

export function useClasses<T extends Partial<Record<keyof T, StylesOrThemeFunction>>>(
  theme: Theme,
  propClasses: T | undefined
): StyledMap<T> {
  return useMemo(() => {
    const obj = {} as StyledMap<T>;
    Object.entries(propClasses || {}).forEach(([className, css]) => {
      const key = className as keyof T;
      if (typeof css === 'function') {
        obj[key] = css(theme);
      } else {
        obj[key] = css as SerializedStyles;
      }
    });

    return obj;
  }, [theme, propClasses]);
}

export function calculateHexColorAlpha(opacity: number) {
  const alpha = Math.round(opacity * 255)
    .toString(16)
    .toUpperCase();
  return alpha.length === 1 ? `0${alpha}` : alpha;
}
