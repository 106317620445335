import React from 'react';

import { css, Theme } from '@emotion/react';

import CloseIcon from 'src/components/icons/CloseIcon';

const containerStyle =
  (height = 14) =>
  (theme: Theme) => css`
    display: flex;
    stroke: ${theme.global.color.text.muted};
    transition: color 0.5s ease-in-out;
    height: ${height}px;
    width: ${height}px;
    cursor: pointer;
    svg {
      height: ${height}px;
      width: ${height}px;
    }
    &:hover,
    svg:hover {
      stroke: ${theme.global.color.text.default};
    }
  `;

interface CloseBtnProps extends React.HTMLAttributes<HTMLElement> {
  height?: number;
}

function CloseButton({ height, ...props }: CloseBtnProps) {
  return (
    <span css={containerStyle(height)} {...props}>
      <CloseIcon width={height} height={height} />
    </span>
  );
}

export default React.memo(CloseButton);
