import {
  EllipsisContent,
  FormContainer,
  Container,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import React, { ReactElement } from 'react';

import SuccessRoundedIcon from 'src/components/icons/SuccessRoundedIcon';

import FileSize from 'src/components/ImportView/ImportForm/SourceInput/FileSize';
import styled from 'styled-components';

interface Props {
  fileName: string;
  sizeBytes: number;
}

const CustomEllipsisContent = styled(EllipsisContent)`
  max-width: 250px;
`;

const FileUploadDetailsContainer = styled(Container)`
  min-height: 32px;
`;

function FileUploadDetails({ fileName, sizeBytes }: Props): ReactElement {
  return (
    <FormContainer label="File name" htmlFor="">
      <FileUploadDetailsContainer gap="xs" isResponsive={false}>
        <Tooltip>
          <Tooltip.Trigger>
            <CustomEllipsisContent component={Text} className="fs-exclude">
              {fileName}
            </CustomEllipsisContent>
          </Tooltip.Trigger>
          <Tooltip.Content className="fs-exclude">{fileName}</Tooltip.Content>
        </Tooltip>

        <FileSize bytes={sizeBytes} />

        <SuccessRoundedIcon />
      </FileUploadDetailsContainer>
    </FormContainer>
  );
}

export default React.memo(FileUploadDetails);
