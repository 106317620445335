import { ReactElement, useEffect } from 'react';
import { useInstanceController } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import useInstanceMySqlSettingsController from 'src/instance/instanceMySqlSettingsController';
import useInstanceMySqlSettingsStateManager from 'src/instance/InstanceMySqlSettingsState';
import {
  ServiceConnectContent,
  ServiceConnectModal
} from 'src/components/ServiceConnect/ServiceConnectView';
import { Instance } from '@cp/common/protocol/Instance';

export interface ServiceConnectModalLayoutProps {
  isModal: boolean;
  open?: boolean;
  description?: string;
  onClose?: () => void;
  instance?: Instance;
  showDownload?: boolean;
  showContent?: boolean;
}

/**
 * Container component for ServiceConnectModal.
 */
export const ServiceConnect = ({
  instance,
  isModal,
  description,
  open,
  onClose,
  showDownload,
  showContent
}: ServiceConnectModalLayoutProps): ReactElement | null => {
  const instanceId = instance?.id ?? '';
  const organizationId = instance?.organizationId || '';

  const { resetInstancePassword } = useInstanceController();
  const { loadMySqlSettings, updateMysqlSettings } =
    useInstanceMySqlSettingsController();
  const { mySqlSettingsLoading, mysqlSettings } =
    useInstanceMySqlSettingsStateManager();
  const { instanceState } = useInstanceStateManager();
  const { instancePasswords, mySqlPasswords } = instanceState;
  const instancePassword = instancePasswords[instanceId] ?? '';
  const mySqlPassword = mySqlPasswords[instanceId] ?? '';

  useEffect(() => {
    if (!instanceId || (!open && isModal)) {
      return;
    }
    loadMySqlSettings({
      instanceId,
      organizationId
    }).catch(console.error);
  }, [open, isModal, instanceId, loadMySqlSettings, organizationId]);

  const mySqlEnabled = mysqlSettings?.enabled ?? false;

  const nativesecure = instance?.endpoints['nativesecure'] ?? {
    hostname: '',
    port: 0
  };
  const https = instance?.endpoints['https'] ?? {
    hostname: '',
    port: 0
  };

  if (!instance) {
    return null;
  }

  const nativesecureParams = {
    hostname: nativesecure.hostname,
    password: instancePassword,
    port: nativesecure.port.toString()
  };

  const httpsParams = {
    hostname: https.hostname,
    password: instancePassword,
    port: https.port.toString()
  };

  const mysqlParams = {
    canEnable: !!mysqlSettings?.passwordSet,
    mySqlUsername: mysqlSettings?.username ?? '',
    mySqlPassword,
    isLoading: mySqlSettingsLoading,
    enabled: mySqlEnabled,
    onEnabledChange: (val: boolean): void => {
      void updateMysqlSettings({
        instanceId,
        organizationId,
        enabled: val
      });
    }
  };

  const handlePasswordReset = async (): Promise<void> => {
    await resetInstancePassword({
      instanceId: instance.id,
      organizationId: instance.organizationId
    });
    await loadMySqlSettings({
      instanceId,
      organizationId
    });
  };

  return isModal ? (
    <ServiceConnectModal
      description={description}
      open={open}
      onClose={onClose}
      serviceName={instance?.name}
      onPasswordReset={handlePasswordReset}
      username={instance.dbUsername}
      nativesecure={nativesecureParams}
      https={httpsParams}
      mysqlProps={mysqlParams}
      showDownload={showDownload}
      showContent={showContent}
      dataWarehouseId={instance?.dataWarehouseId}
    />
  ) : (
    <ServiceConnectContent
      description={description}
      onPasswordReset={handlePasswordReset}
      username={instance.dbUsername}
      nativesecure={nativesecureParams}
      https={httpsParams}
      mysqlProps={mysqlParams}
      showDownload={showDownload}
      showContent={showContent}
      dataWarehouseId={instance?.dataWarehouseId}
    />
  );
};
