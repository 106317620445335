import { ReactElement, useRef, useState } from 'react';

import { EditColumn } from 'shared/src/tableSchema';

import { nullableSwitch } from 'src/components/CreateTableView/styles';
import { ConfirmationDialog, Switch } from '@clickhouse/click-ui';

function isNullable(type: string): boolean {
  return type.startsWith('Nullable(') && type.endsWith(')');
}

function toggledNullable(type: string): string {
  if (isNullable(type)) {
    return type.substring('Nullable('.length, type.length - 1);
  } else {
    return `Nullable(${type})`;
  }
}

interface Props {
  column: EditColumn | null;
  onSubmit: (changes: Partial<Omit<EditColumn, 'id'>>) => void;
}

function ToggleNullable({ column, onSubmit }: Props): ReactElement | null {
  const [open, setOpen] = useState(false);
  const switchRef = useRef<HTMLButtonElement>(null);
  if (column === null) {
    return null;
  }

  const onCancel = () => {
    setOpen(false);
    switchRef.current && switchRef.current.focus();
  };
  const apply = () => {
    onSubmit({ type: toggledNullable(column?.type) });
    onCancel();
  };

  const value = isNullable(column.type);

  const onSwitch = () => {
    if (value) {
      onSubmit({ type: toggledNullable(column?.type) });
    } else {
      setOpen(true);
    }
  };

  const confirmationDialogMessage = `Nullable columns may not be used in a table's sort key, and for tables
  with any substantial volume of data, specifying a sort key allows
  ClickHouse to achieve optimal performance. Additionally, nullable
  columns may increase the compressed size of your table and should be
  used sparingly.`;

  return (
    <>
      <div css={nullableSwitch}>
        <Switch
          ref={switchRef}
          disabled={column.removed}
          aria-label={
            value
              ? `Remove nullability for column ${column.name}`
              : `Make column ${column.name} nullable`
          }
          checked={value}
          onCheckedChange={onSwitch}
          className="toggle-switch"
        />
      </div>

      <ConfirmationDialog
        title="Warning: unrecommended table configuration"
        open={open}
        onCancel={onCancel}
        onConfirm={apply}
        message={confirmationDialogMessage}
        primaryActionLabel="Continue anyway"
      />
    </>
  );
}

export default ToggleNullable;
