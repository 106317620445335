import { useCallback, useEffect, useMemo, useState } from 'react';

import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useCredentials } from 'src/state/connection';
import { InsufficientPermissionsError, QueryItem, useLiveQueriesModel } from 'src/state/liveQueries/model';
import { useIsCurrentInstanceAwakeStatus } from 'src/instance/instanceController';

// Keep track of the number of active useLiveQueries hooks that are subscribed
// to information about queries, and what type of information they are subscribed
// to. This will affect whether we do polling, and what kind of polling we do
// (full query list vs a simple query to see if there are any active queries)
interface LiveQueriesSubscriptionCounts {
  // number of hooks subscribed to full listing
  fullListing: number;

  // number of hooks only interested in whether active query exists
  statusOnly: number;
}

type SubscriptionType = keyof LiveQueriesSubscriptionCounts;

const maxConsecutiveFailures = 5;

const storage = createJSONStorage<Array<QueryItem> | null>(() => sessionStorage);

const ranQueriesAtom = atomWithStorage<Array<QueryItem> | null>('liveQueries', null, storage);

const hasActiveQueriesAtom = atom<boolean>(false);

const loadingQueriesConsecutiveFailures = atom(0);

const insufficientPermissionsAtom = atom(false);

const liveQueriesSubscriptionCountsAtom = atom<LiveQueriesSubscriptionCounts>({
  fullListing: 0,
  statusOnly: 0
});

export const menuClosedIntervalMilliseconds = 4000;
export const menuOpenIntervalMilliseconds = 1000;

type UpdateType = SubscriptionType | 'none';

// What type of update we should perform given the current subscriptions
function useUpdateType(): UpdateType {
  const [subscriptionCounts] = useAtom(liveQueriesSubscriptionCountsAtom);
  if (subscriptionCounts.fullListing > 0) {
    return 'fullListing';
  } else if (subscriptionCounts.statusOnly > 0) {
    return 'statusOnly';
  } else {
    return 'none';
  }
}

export function useLiveQueriesUpdater(): void {
  const setQueries = useSetAtom(ranQueriesAtom);
  const setHasActiveQueries = useSetAtom(hasActiveQueriesAtom);
  const model = useLiveQueriesModel();
  const credentials = useCredentials();

  const [isFocused, setIsFocused] = useState(true);
  const [consecutiveFailures, setConsecutiveFailures] = useAtom(loadingQueriesConsecutiveFailures);
  const [insufficientPermissions, setInsufficientPermissions] = useAtom(insufficientPermissionsAtom);

  const updateType = useUpdateType();

  const tooManyConsecutiveFailures = consecutiveFailures >= maxConsecutiveFailures;

  const { isAwake } = useIsCurrentInstanceAwakeStatus();

  const isConnected = !!credentials && credentials.connected;
  const isPolling = isFocused && !tooManyConsecutiveFailures && isAwake && !insufficientPermissions && isConnected;

  useEffect(() => {
    const focus = (): void => {
      setIsFocused(true);
    };

    const blur = (): void => {
      setIsFocused(false);
    };

    window.addEventListener('focus', focus);
    window.addEventListener('blur', blur);

    return (): void => {
      window.removeEventListener('focus', focus);
      window.removeEventListener('blur', blur);
    };
  }, []);

  const loadFullList = useCallback(async () => {
    const runningQueries = await model.loadFullList();
    setQueries(runningQueries);
    const hasActiveQueries = !!runningQueries.find((query) => query.status === 'running');
    setHasActiveQueries(hasActiveQueries);
  }, [model, setQueries, setHasActiveQueries]);

  const loadRunningState = useCallback(async () => {
    const count = await model.loadRunningCount();
    setHasActiveQueries(count > 0);
  }, [model, setHasActiveQueries]);

  const poll = useCallback(async () => {
    if (updateType !== 'none') {
      try {
        if (updateType === 'fullListing') {
          await loadFullList();
        } else if (updateType === 'statusOnly') {
          await loadRunningState();
        }
      } catch (error) {
        console.error(error);

        if (error instanceof InsufficientPermissionsError) {
          setInsufficientPermissions(true);
        } else {
          setConsecutiveFailures((n) => n + 1);
        }

        return;
      }
    }

    setConsecutiveFailures(0);
  }, [setConsecutiveFailures, setInsufficientPermissions, loadFullList, loadRunningState, updateType]);

  useEffect(() => {
    if (!isPolling || updateType === 'none') {
      return;
    }

    const intervalMilliseconds =
      updateType === 'fullListing' ? menuOpenIntervalMilliseconds : menuClosedIntervalMilliseconds;

    let finalized = false;
    let interval: undefined | NodeJS.Timeout;
    // Start polling
    setTimeout(() => {
      void poll().then(() => {
        if (!finalized) {
          interval = setInterval(() => void poll(), intervalMilliseconds);
        }
      });
    });

    return (): void => {
      // Notify polling process to end. Next time the polling timer fires, it
      // will exit and not reschedule itself.
      finalized = true;
      clearTimeout(interval);
    };
  }, [poll, isPolling, updateType]);
}

export function useClearLiveQueries(): () => void {
  const setQueries = useSetAtom(ranQueriesAtom);

  return () => {
    setQueries(null);
  };
}

export interface LiveQueriesResult {
  queries: Array<QueryItem>;
  failed: boolean;
  hasActiveQueries: boolean;
  insufficientPermissions: boolean;
  loading: boolean;
}

export function useLiveQueries(type: SubscriptionType): LiveQueriesResult {
  const [queries] = useAtom(ranQueriesAtom);
  const [consecutiveFailures] = useAtom(loadingQueriesConsecutiveFailures);
  const [hasActiveQueries] = useAtom(hasActiveQueriesAtom);
  const [insufficientPermissions] = useAtom(insufficientPermissionsAtom);
  const setLiveQueriesSubscriptions = useSetAtom(liveQueriesSubscriptionCountsAtom);

  useEffect(() => {
    // increment subscription count on mount
    setLiveQueriesSubscriptions((oldCounts) => ({
      ...oldCounts,
      [type]: oldCounts[type] + 1
    }));

    return () => {
      // decrement subscription count on unmount
      setLiveQueriesSubscriptions((oldCounts) => ({
        ...oldCounts,
        [type]: oldCounts[type] - 1
      }));
    };
  }, [type, setLiveQueriesSubscriptions]);

  const queriesList = useMemo(() => {
    return queries ?? [];
  }, [queries]);

  const failed = consecutiveFailures > maxConsecutiveFailures;
  return {
    queries: queriesList,
    failed,
    hasActiveQueries,
    insufficientPermissions,
    loading: queries === null
  };
}
