import { useUserStateManager } from 'src/user/userState';
import { PendingUserAction } from '@cp/common/protocol/PendingUserActions';
import { usePendingUserActionController } from 'src/lib/pendingUserActions/usePendingUserActionController';

/**
 * Custom hook to get the pending user actions.
 * @returns {PendingUserAction[]} An array of pending user actions.
 */
export const useGetPendingUserActions = (): PendingUserAction[] => {
  const { user } = useUserStateManager();
  const { unsentPendingActions } = usePendingUserActionController();
  if (!user || unsentPendingActions.length > 0) {
    // User not authenticated or local pending actions has not been yet applied to the server.
    return unsentPendingActions;
  }
  const { pendingActions } = user;
  return pendingActions || [];
};
