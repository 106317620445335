import config from 'src/lib/config';
import { HttpClient } from 'src/lib/http';
import {
  ListQueryEndpointsResponse,
  QueryEndpointDetail,
  ServiceQueryEndpointResponse
} from 'types/protocol/queryEndpoints';
import {
  ExecuteQueryResult,
  GetQueryEndpointStatsResponse,
  GetQueryEndpointsStatsResult
} from 'shared/src/queryEndpoints/protocol/index';
import { SelectableTimePeriod } from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { ServiceQueryAPIEndpoint } from '@prisma/client';
import { JSONLinesTransformStream } from 'src/lib/stream/JSONLinesTransformStream';
import { streamToAsyncIterator } from 'src/lib/stream/streamToAsyncIterator';

type UpdateQueryEndpointArgs = {
  endpointId: string;
  openApiKeys: Array<string>;
  roles: Array<string>;
  allowedOrigins: string;
};

export type UpsertServiceQueryApiEndpointInput = {
  openApiKeys: Array<string>;
  roles: Array<string>;
  allowedOrigins: string;
};

type CreateQueryEndpointArgs = {
  queryId: string;
  openApiKeys: Array<string>;
  roles: Array<string>;
  allowedOrigins: string;
};

export type QueryEndpointValidationError = {
  error: string;
  details: {
    field: string;
    message: string;
  };
};

type ListQueryEndpointsParams = {
  serviceId: string;
  queryId?: string;
  status?: 'active' | 'inactive';
};

type ExecuteQueryArgs = {
  serviceId: string;
  sql: string;
  variables: Record<string, unknown>;
};

type ExecuteQueryBody = { sql: string; format: string; queryVariables?: Record<string, unknown> };

export class QueryEndpointApiClient {
  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getServiceQueryApiEndpoint(serviceId: string): Promise<ServiceQueryAPIEndpoint | null> {
    const res = await this.httpClient.get(`${config.apiUrl}/query-endpoints/services/${serviceId}/config`);

    if (!res.ok && res.status !== 404) {
      throw new Error('Failed to get service query endpoints');
    } else if (!res.ok && res.status === 404) {
      return null;
    }

    const { data } = (await res.json()) as ServiceQueryEndpointResponse;

    if (data.status === 'inactive') {
      return null;
    }

    return data;
  }

  async upsertServiceQueryApiEndpoint(
    serviceId: string,
    input: UpsertServiceQueryApiEndpointInput
  ): Promise<ServiceQueryAPIEndpoint> {
    const res = await this.httpClient.post(`${config.apiUrl}/query-endpoints/services/${serviceId}/config`, {
      body: JSON.stringify(input)
    });

    if (!res.ok) {
      throw new Error('Failed to get service query endpoints');
    }

    const { data } = (await res.json()) as ServiceQueryEndpointResponse;
    return data;
  }

  async deleteServiceQueryApiEndpoint(serviceId: string): Promise<boolean> {
    const res = await this.httpClient.destroy(`${config.apiUrl}/query-endpoints/services/${serviceId}/config`);

    if (!res.ok) {
      throw new Error(`Failed to delete service query endpoint: ${serviceId}`);
    }

    return true;
  }

  async listQueryEndpoints({ serviceId, queryId, status }: ListQueryEndpointsParams): Promise<QueryEndpointDetail[]> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('serviceId', serviceId);
    if (queryId) {
      urlSearchParams.append('queryId', queryId);
    }

    if (status) {
      urlSearchParams.append('status', status);
    }

    const res = await this.httpClient.get(
      `${config.apiUrl}/query-endpoints?${urlSearchParams.toString()}`,
      {},
      {
        includeAuthProviderHeader: true
      }
    );

    if (!res.ok) {
      throw new Error('Failed to list query endpoints');
    } else {
      const { data } = (await res.json()) as ListQueryEndpointsResponse;
      return data;
    }
  }

  async updateQueryEndpoint({
    endpointId,
    openApiKeys,
    roles,
    allowedOrigins
  }: UpdateQueryEndpointArgs): Promise<[true, QueryEndpointDetail] | [false, QueryEndpointValidationError]> {
    const res = await this.httpClient.put(
      `${config.apiUrl}/query-endpoints/${endpointId}`,
      {
        body: JSON.stringify({
          openApiKeys,
          roles,
          allowedOrigins
        })
      },
      { includeAuthProviderHeader: true }
    );

    if (res.ok) {
      return [true, (await res.json()).data];
    } else {
      if (res.status === 400) {
        return [false, await res.json()];
      }
      console.error(await res.text());
      throw new Error('Failed to update query endpoint');
    }
  }

  async createQueryEndpoint({
    queryId,
    openApiKeys,
    roles,
    allowedOrigins
  }: CreateQueryEndpointArgs): Promise<[true, QueryEndpointDetail] | [false, QueryEndpointValidationError]> {
    const res = await this.httpClient.post(
      `${config.apiUrl}/query-endpoints`,
      {
        body: JSON.stringify({
          queryId,
          openApiKeys,
          roles,
          allowedOrigins
        })
      },
      { includeAuthProviderHeader: true }
    );

    if (res.ok) {
      return [true, (await res.json()).data];
    } else {
      if (res.status === 400) {
        return [false, await res.json()];
      }
      console.error(await res.text());
      throw new Error('Failed to create query endpoint');
    }
  }

  async disableQueryEndpoint(endpointId: string): Promise<void> {
    const res = await this.httpClient.post(`${config.apiUrl}/query-endpoints/${endpointId}/disable`, {});

    if (!res.ok) {
      throw new Error('Failed to disable query endpoint');
    }
  }

  async getQueryEndpointStats(endpointId: string, period: SelectableTimePeriod): Promise<GetQueryEndpointsStatsResult> {
    const urlQueryParams = new URLSearchParams();
    urlQueryParams.append('period', period as string);
    const res = await this.httpClient.get(
      `${config.apiUrl}/query-endpoints/${endpointId}/stats?${urlQueryParams.toString()}`
    );

    if (!res.ok) {
      throw new Error('Failed to get query endpoint stats');
    } else {
      const response = (await res.json()) as GetQueryEndpointStatsResponse;
      return response.data;
    }
  }

  /**
   * Makes a request to the generic API query endpoint to execute a SQL query and return results
   *
   * @async
   * @param {string} serviceId - The service id / instance id of the currently active service
   * @param {string} sql - The sql query that gets executed
   * @param {Object} [variables] - An optional collection of key-value pairs representing variables. Keys are variable names and values are variable values.
   *
   * @example
   * queryApiEndpoint({ serviceId: '1234', sql: 'select * from system.query_log limit 5' })
   *
   * @example
   * queryApiEndpoint({ serviceId: '1234', sql: 'select * from system.query_log where queryId = {queryId: String}', variables: { queryId: '1234' } })
   *
   * @throws
   * @returns {Array<Record<string, unknown>>} - An array of objects representing rows from a ClickHouse query. Keys of the object represent columns and values represent row values.
   * @example
   * event_time: "2024-06-07 19:50:14"
   *
   */
  async executeQuery({ serviceId, sql, variables }: ExecuteQueryArgs): Promise<ExecuteQueryResult> {
    const body: ExecuteQueryBody = {
      sql,
      format: 'JSONEachRow'
    };

    if (Object.values(variables).length) {
      body.queryVariables = variables;
    }

    const res = await this.httpClient.post(`${config.apiUrl}/services/${serviceId}/query?`, {
      body: JSON.stringify(body)
    });

    if (!res.ok || !res.body) {
      throw new Error('Failed to fetch query');
    }

    const readableStream = res.body.pipeThrough(new JSONLinesTransformStream());

    const result = [];
    for await (const jsonObject of streamToAsyncIterator(readableStream)) {
      result.push(jsonObject as Record<string, unknown>);
    }

    return result;
  }
}
