import { useState, useEffect } from 'react';
import { GetQueryEndpointsStatsResult } from 'shared/src/queryEndpoints/protocol/index';
import { SelectableTimePeriod } from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { useApiClient } from 'src/lib/controlPlane/client';

type UseQueryApiEndpointStats = {
  stats: GetQueryEndpointsStatsResult | null;
  loading: boolean;
  error: string | null;
};
const useQueryApiEndpointStats = (endpointId: string, period: SelectableTimePeriod): UseQueryApiEndpointStats => {
  const [stats, setStats] = useState<GetQueryEndpointsStatsResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const apiClient = useApiClient();

  useEffect(() => {
    const fetchStats = async (): Promise<void> => {
      try {
        setLoading(true);
        setError(null);

        // Make an API request to fetch the stats based on endpointId and period

        const data = await apiClient.queryEndpoints.getQueryEndpointStats(endpointId, period);

        setStats(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch stats');
        setLoading(false);
      }
    };

    fetchStats().catch(console.error);
  }, [endpointId, period, apiClient.queryEndpoints]);

  return { stats, loading, error };
};

export default useQueryApiEndpointStats;
