import { Link } from '@clickhouse/click-ui';
import { InstanceCloudProvider } from '@cp/common/protocol/Instance';
import { ReactNode } from 'react';

export interface EndpointIdLinkProps {
  cloudProvider: InstanceCloudProvider;
}

const providerEndpointIdLinks: Partial<Record<InstanceCloudProvider, string>> =
  {
    aws: 'https://clickhouse.com/docs/en/manage/security/aws-privatelink#2-create-a-service-endpoint',
    gcp: 'https://clickhouse.com/docs/en/manage/security/gcp-private-service-connect#create-service-endpoint',
    azure:
      'https://clickhouse.com/docs/en/cloud/security/azure-privatelink#obtaining-private-endpoint-resourceguid'
  };

export function EndpointIdLink({
  cloudProvider
}: EndpointIdLinkProps): ReactNode | null {
  const href = providerEndpointIdLinks[cloudProvider];
  if (!href) {
    return null;
  }
  return (
    <Link target="_blank" size="sm" href={href}>
      Where can I find this?
    </Link>
  );
}
