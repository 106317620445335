import { Buffer } from 'buffer';
import { ReactElement, ReactNode } from 'react';
import Routes from 'src/Routes';
import FatalErrorBoundary from 'src/components/FatalErrorBoundary';
import { NavigationProvider } from 'src/components/NavigationProvider/NavigationProvider';
import { RecaptchaProvider } from 'src/components/RecaptchaProvider';
import FatalErrorPage from 'src/pages/FatalErrorPage/FatalErrorPage';
import 'src/index.css';
import 'src/toastify.css';

// polyfill for Buffer
window.Buffer = Buffer;

const App = (): ReactElement => {
  return (
    <FatalErrorBoundary page={(): ReactNode => <FatalErrorPage />}>
      <NavigationProvider>
        <RecaptchaProvider>
          <Routes />
        </RecaptchaProvider>
      </NavigationProvider>
    </FatalErrorBoundary>
  );
};
export default App;
