import {
  Container,
  Dropdown,
  EllipsisContent,
  Icon,
  Separator,
  SidebarNavigationTitle,
  Spacer,
  Text
} from '@clickhouse/click-ui';
import { Organization } from '@cp/common/protocol/Organization';
import { widthStyle } from 'global-styles';
import { truncate } from 'lodash';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForOrganization } from 'src/authorization/authorizationState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { CustomSidebarItem } from 'src/layout/ServiceSidebar/SidebarItem';
import { useMainSidebar } from 'src/layout/SidebarWrapper/SidebarWrapper';
import { routes } from 'src/lib/routes';
import { w100 } from 'src/lib/utility-styles';
import {
  useCurrentOrgUserRole,
  useOrganizationStateManager
} from 'src/organization/organizationState';

export interface SidebarOrgSelectorProps {
  currentOrg: Organization;
  orgs: Array<Organization>;
}

export const SidebarOrgSelector = ({
  currentOrg,
  orgs
}: SidebarOrgSelectorProps): ReactElement | null => {
  const hasManageBillingPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage-billing',
      useCurrentOrgUserRole() === 'ADMIN'
    );
  const hasManageOrganizationPermission =
    useUserAndOrgRolesHasPermissionForOrganization(
      'control-plane:organization:manage',
      useCurrentOrgUserRole() === 'ADMIN'
    );

  const { currentOrganizationId, switchCurrentOrganization } =
    useOrganizationStateManager();
  const { closeSidebar } = useMainSidebar();

  if (orgs.length === 0 || !currentOrg) {
    return null;
  }
  const hasMultipleOrgs = orgs.length > 0;

  const handleOrgSwitch = (id: string): void => {
    if (currentOrganizationId === id) {
      return;
    }
    switchCurrentOrganization(id);
    navigateTo(routes.root());
    closeSidebar();
  };

  return (
    <Container
      padding="md"
      orientation="vertical"
      gap="xxs"
      isResponsive={false}
    >
      <SidebarNavigationTitle label="Organization" />
      <Spacer size="xs" />
      <Dropdown>
        <Dropdown.Trigger css={[w100]}>
          <CustomSidebarItem
            $collapsible={false}
            data-testid="organization-name-drop-down-button"
            type="main"
            icon="building"
            label={
              <Container
                gap="xs"
                padding="none"
                justifyContent="space-between"
                isResponsive={false}
              >
                <EllipsisContent>{currentOrg.name}</EllipsisContent>{' '}
                <Icon name="chevron-right" />
              </Container>
            } //need to create an org state so that we can pull currentOrg somehow.  hardcoded for now.
          />
        </Dropdown.Trigger>
        <Dropdown.Content side="right" align="end" sideOffset={6}>
          {hasMultipleOrgs && (
            <>
              <div css={w100}>
                <Dropdown.Sub>
                  <Dropdown.Trigger sub data-testid="current-org-menu-trigger">
                    <Text size="md">
                      {truncate(currentOrg.name, { length: 24 })}
                    </Text>
                    <Text color="muted" size="sm">
                      Switch organizations
                    </Text>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    sub
                    side="right"
                    align="start"
                    css={widthStyle(220)}
                  >
                    {orgs
                      .sort((a, b) => b.createdAt - a.createdAt)
                      .map((org) => {
                        return (
                          <Dropdown.Item
                            key={org.id}
                            onClick={(): void => handleOrgSwitch(org.id)}
                            data-testid={`organization-${org.id}`}
                          >
                            {org.name}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Content>
                </Dropdown.Sub>
              </div>
              <Separator size="sm" />
            </>
          )}
          {hasManageBillingPermission && (
            <Dropdown.Item
              data-testid="current-org-menu-billing"
              onClick={() => {
                navigateTo(routes.billingHome({ orgId: currentOrg.id }));
                closeSidebar();
              }}
            >
              Billing
            </Dropdown.Item>
          )}
          {hasManageBillingPermission && (
            <Dropdown.Item
              data-testid="current-org-menu-usage"
              onClick={() => {
                navigateTo(routes.billingUsage({ orgId: currentOrg.id }));
                closeSidebar();
              }}
            >
              Usage breakdown
            </Dropdown.Item>
          )}
          <Dropdown.Item
            data-testid="current-org-menu-users"
            onClick={() => {
              navigateTo(routes.membersPage({ orgId: currentOrg.id }));
              closeSidebar();
            }}
          >
            Users and roles
          </Dropdown.Item>
          <Dropdown.Item
            data-testid="current-org-menu-private-endpoints"
            onClick={() => {
              navigateTo(routes.privateEndpointsPage({ orgId: currentOrg.id }));
              closeSidebar();
            }}
          >
            Private endpoints
          </Dropdown.Item>
          <Dropdown.Item
            data-testid="current-org-menu-api-keys"
            onClick={() => {
              navigateTo(routes.apiKeysPage({ orgId: currentOrg.id }));
              closeSidebar();
            }}
          >
            API keys
          </Dropdown.Item>
          {hasManageOrganizationPermission && (
            <Dropdown.Item
              data-testid="current-org-menu-audit"
              onClick={() => {
                navigateTo(routes.auditPage({ orgId: currentOrg.id }));
                closeSidebar();
              }}
            >
              Audit
            </Dropdown.Item>
          )}
          <Dropdown.Item
            data-testid="current-org-menu-details"
            onClick={() => {
              navigateTo(routes.orgDetails({ orgId: currentOrg.id }));
              closeSidebar();
            }}
          >
            Organization details
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown>
    </Container>
  );
};
