import { css, SerializedStyles } from '@emotion/react';
import { CSSProperties } from 'react';

type marginType = {
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
};

const colorStyle = (color: string): SerializedStyles => css`
  color: ${color};
`;

const marginStyle = (margin: marginType | string | number): SerializedStyles => {
  if (typeof margin === 'object') {
    return css`
      ${margin?.top && `margin-top: ${typeof margin.top === 'number' ? `${margin.top}px` : margin.top}`};
      ${margin?.right && `margin-right: ${typeof margin.right === 'number' ? `${margin.right}px` : margin.right}`};
      ${margin?.bottom && `margin-bottom: ${typeof margin.bottom === 'number' ? `${margin.bottom}px` : margin.bottom}`};
      ${margin?.left && `margin-left: ${typeof margin.left === 'number' ? `${margin.left}px` : margin.left}`};
    `;
  } else {
    return css`
      margin: ${typeof margin === 'number' ? `${margin}px` : margin};
    `;
  }
};

const gridCol = (col = 1, gap = 10): SerializedStyles => css`
  display: grid;
  grid-template-columns: ${'max-content '.repeat(col)};
  grid-gap: ${gap}px;
  & > * {
    width: max-content !important;
  }
`;

const leftBarControlsStyle = css({
  padding: '1rem',
  width: '100%'
});

const transparentBtn = css`
  background: transparent;
  color: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
`;

const widthStyle = (width: string | number): SerializedStyles => css`
  width: 100%;
  max-width: ${typeof width === 'string' ? width : `${width}px`};
`;

const virtualizedScrollPosition = { willChange: 'scroll-position' }; //for the purpose of showing tooltip and other context element correctly
const virtualizedListStyle: CSSProperties = {
  overflowY: 'scroll'
}; //This is inorder for the list to have padding all the time

const flexFixedSize = css`
  flex: 0 0 auto;
`;

const paddingStyle = (padding: marginType | string | number): SerializedStyles => {
  if (typeof padding === 'object') {
    return css`
      ${padding?.top && `padding-top: ${typeof padding.top === 'number' ? `${padding.top}px` : padding.top}`};
      ${padding?.right && `padding-right: ${typeof padding.right === 'number' ? `${padding.right}px` : padding.right}`};
      ${padding?.bottom &&
      `padding-bottom: ${typeof padding.bottom === 'number' ? `${padding.bottom}px` : padding.bottom}`};
      ${padding?.left && `padding-left: ${typeof padding.left === 'number' ? `${padding.left}px` : padding.left}`};
    `;
  } else {
    return css`
      padding: ${typeof padding === 'number' ? `${padding}px` : padding};
    `;
  }
};
const codeBlockStyle: SerializedStyles = css({
  height: '100%',
  pre: {
    height: '100%'
  }
});

const hFull: SerializedStyles = css({
  height: '100%'
});

export {
  colorStyle,
  codeBlockStyle,
  marginStyle,
  paddingStyle,
  gridCol,
  hFull,
  leftBarControlsStyle,
  transparentBtn,
  widthStyle,
  virtualizedScrollPosition,
  virtualizedListStyle,
  flexFixedSize
};
