import {
  Badge,
  BadgeState,
  Button,
  Container,
  DangerAlert,
  Flyout,
  Icon,
  IconName,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';

import { assertNever } from '@cp/common/utils/Assert';
import { DataImport } from '@prisma/client';
import React, { ReactElement, useState } from 'react';
import { ImportModel, ImportStatus } from 'shared/src/dataLoading';
import CancelDataImportConfirmDialog from 'src/components/DataImports/DataImportDetail/CancelDataImportConfirmDialog';
import ChunkDetails from 'src/components/DataImports/DataImportDetail/ChunkDetails';
import ChunkStatuses from 'src/components/DataImports/DataImportDetail/ChunkStatuses';
import {
  useDataImport,
  useDataLoadingController
} from 'src/dataloading/dataloadingController';
import {
  ChunkSummaryModel,
  DataImportWithChunksModel
} from 'src/dataloading/models';

type DataImportDetailProps = {
  onOpenChange: (open: boolean) => void;
  dataImport: DataImport;
};

type ImportDetailProps = {
  isLoading: boolean;
  dataImport: DataImportWithChunksModel | null;
  error: string | null;
};

const ImportDetails = ({
  isLoading,
  dataImport,
  error
}: ImportDetailProps): ReactElement => {
  const [selectedChunkNumber, setSelectedChunkNumber] = React.useState<
    number | null
  >(null);

  const onSelectChunk = (chunk: ChunkSummaryModel | null): void => {
    if (chunk) {
      setSelectedChunkNumber(chunk.chunkNumber);
    } else {
      setSelectedChunkNumber(null);
    }
  };

  const selectedChunk =
    dataImport
      ?.chunks()
      .find((chunk) => chunk.chunkNumber === selectedChunkNumber) ?? null;

  if (error && !isLoading) {
    return <DangerAlert text={error} />;
  }

  if (isLoading || !dataImport) {
    return (
      <Container orientation="vertical">
        <Icon name="loading" size="lg" />
      </Container>
    );
  }

  const getStateFromStatus = (status: ImportStatus): BadgeState => {
    switch (status) {
      case 'queued':
        return 'warning';
      case 'canceled':
      case 'failed_splitting':
      case 'failed':
      case 'stopped':
        return 'danger';
      case 'splitting':
      case 'queued_for_importing':
      case 'importing':
        return 'info';
      case 'success':
        return 'success';
      default:
        return 'warning';
    }
  };

  const getIconFromStatus = (status: ImportStatus): IconName => {
    switch (status) {
      case 'queued':
        return 'clock';
      case 'canceled':
      case 'failed_splitting':
      case 'failed':
      case 'stopped':
        return 'warning';
      case 'splitting':
      case 'queued_for_importing':
      case 'importing':
        return 'horizontal-loading';
      case 'success':
        return 'check';
      default:
        return 'clock';
    }
  };

  const getTextFromStatus = (status: ImportStatus): string => {
    switch (status) {
      case 'queued':
        return 'Queued';
      case 'canceled':
        return 'Canceled';
      case 'failed_splitting':
        return 'Failed';
      case 'failed':
        return 'Failed';
      case 'stopped':
        return 'Stopped';
      case 'splitting':
      case 'queued_for_importing':
      case 'importing':
        return 'Importing';
      case 'success':
        return 'Success';
      default:
        assertNever(status as never, `Unknown status ${status}`);
    }
  };

  return (
    <Container
      orientation="vertical"
      alignItems="start"
      padding="md"
      maxWidth="500px"
      gap="xs"
    >
      <div>
        <Badge
          text={getTextFromStatus(dataImport.status as ImportStatus)}
          state={getStateFromStatus(dataImport.status as ImportStatus)}
          icon={getIconFromStatus(dataImport.status as ImportStatus)}
        />
      </div>
      <Spacer size="sm" />

      <Title type="h2">Last run</Title>
      <Text color="muted">{dataImport.getLastAttemptFormatted()}</Text>

      {dataImport.status === 'failed_splitting' && dataImport.error && (
        <DangerAlert text={dataImport.error} />
      )}

      {dataImport.chunks().length > 0 && (
        <Container wrap="wrap" gap="xs">
          <ChunkStatuses
            chunks={dataImport.chunks()}
            selectedChunk={selectedChunk}
            onSelectChunk={onSelectChunk}
          />

          {selectedChunk && (
            <ChunkDetails
              chunk={selectedChunk}
              onClose={(): void => {
                onSelectChunk(null);
              }}
            />
          )}
        </Container>
      )}
    </Container>
  );
};

function getImportType(dataImport: DataImport): string {
  const importModel = dataImport.data as unknown as ImportModel;
  switch (importModel.type) {
    case 'file_upload':
      return 'File';
    case 'file_url':
      return 'URL';
    case 'sample_data':
      return 'Sample Data';
    default:
      assertNever(
        importModel.type as never,
        `Unknown import type ${importModel.type as string}`
      );
  }
}

export const DataImportDetail = (
  props: DataImportDetailProps
): ReactElement => {
  const { onOpenChange } = props;

  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

  const { isLoading, error, dataImport } = useDataImport(
    props.dataImport.serviceId,
    props.dataImport.id
  );

  const dataLoadingController = useDataLoadingController();

  const upToDateDataImport = dataImport || props.dataImport;
  const title = `${upToDateDataImport.name} Progress`;
  const { isRetrying } = dataLoadingController;
  const allowedToRetry = !isLoading && !isRetrying && dataImport?.isRetryable();

  const onClickViewData = (): void => {
    dataLoadingController.openTableView(upToDateDataImport);
  };

  const hasFooter =
    dataImport &&
    (dataImport.isFailed() ||
      !dataImport?.isStopped() ||
      dataImport.isImporting() ||
      dataImport.isSuccessful());

  function retry(): void {
    dataLoadingController
      .retryImport(upToDateDataImport.id)
      .catch(console.error);
  }

  function closeCancelDialog(): void {
    setConfirmCancelOpen(false);
  }

  const openCancelDialog = (): void => {
    setConfirmCancelOpen(true);
  };

  const isCancelable =
    !!dataImport && !(dataImport.isStopped() || dataImport.isSuccessful());

  return (
    <Flyout open onOpenChange={onOpenChange}>
      <Flyout.Content
        className="view-dataimport-details"
        data-testid="dataimport-details-rightbar"
        strategy="fixed"
      >
        <Flyout.Header title={title} />
        <Flyout.Body align="top">
          <ImportDetails
            isLoading={isLoading}
            dataImport={dataImport ?? null}
            error={error ?? null}
          />
          {dataImport && (
            <CancelDataImportConfirmDialog
              open={confirmCancelOpen}
              serviceId={dataImport.serviceId}
              dataImportId={dataImport.id}
              onClose={closeCancelDialog}
            />
          )}
        </Flyout.Body>
        {hasFooter && (
          <Flyout.Footer>
            {!dataImport.isStopped() && (
              <Button
                onClick={onClickViewData}
                data-testid="view-data-import-button"
              >
                View Data
              </Button>
            )}

            {allowedToRetry && (
              <Button
                type="primary"
                onClick={retry}
                disabled={!allowedToRetry}
                loading={isLoading}
                data-testid="retry-import-button"
              >
                Retry
              </Button>
            )}
            {isCancelable && (
              <Button
                type="danger"
                onClick={openCancelDialog}
                disabled={isLoading}
                data-testid="cancel-import-button"
              >
                Cancel
              </Button>
            )}
          </Flyout.Footer>
        )}
      </Flyout.Content>
    </Flyout>
  );
};

const MemoizedDataImportDetail = React.memo(DataImportDetail);
MemoizedDataImportDetail.displayName = 'DataImportDetail';
export default MemoizedDataImportDetail;
