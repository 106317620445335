import { HTMLAttributes, ReactElement, ReactNode } from 'react';
import { Resizable } from 're-resizable';
import { LogFn } from 'src/lib/logger';
import { Flyout, FlyoutProps } from '@clickhouse/click-ui';
import { createToast } from 'primitives';
import styled from 'styled-components';

export const ResizableContainer = styled(Resizable)`
  height: 100%;
  gap: inherit;
  display: flex;
  flex-direction: column;
  max-width: 100% !important;
  --flyout-width: attr(width);
`;
const FlyoutContent = styled(Flyout.Content)`
  min-width: fit-content;
`;

interface ContainerProps extends FlyoutProps {
  children: ReactNode;
  header: string;
  description?: string;
  isString?: boolean;
  logEvent?: LogFn;
  container?: HTMLElement | null;
  size?: 'narrow' | 'wide' | 'default';
  minWidth?: number;
  width?: number;
}

export default function ResizableFlyout({
  children,
  header,
  description,
  logEvent,
  container,
  open,
  onOpenChange: onOpenChangeProp,
  size = 'narrow',
  width = 350,
  minWidth = 350,
  ...props
}: ContainerProps): ReactElement {
  return (
    <div data-testid={open ? 'flyout-open' : 'flyout-closed'}>
      <Flyout
        open={open}
        onOpenChange={(open): void => {
          if (!open) {
            logEvent && logEvent('cancelButtonClick');
          }
          if (typeof onOpenChangeProp === 'function') {
            onOpenChangeProp(open);
          }
        }}
        {...props}
      >
        <FlyoutContent container={container} size={size}>
          <ResizableContainer
            enable={{ left: true }}
            defaultSize={{
              width,
              height: '100%'
            }}
            minWidth={minWidth}
            maxWidth={800}
            {...props}
          >
            <Flyout.Header title={header} description={description} />
            {children}
          </ResizableContainer>
        </FlyoutContent>
      </Flyout>
    </div>
  );
}

function ResizableBody({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>): ReactElement {
  return (
    <Flyout.Body {...props}>
      <Flyout.Element>{children}</Flyout.Element>
    </Flyout.Body>
  );
}
ResizableFlyout.Body = ResizableBody;

interface ResizableCodeProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'onCopy'> {
  children: string;
  language?: string;
  successHeader?: string;
  successMessage?: string;
}

function ResizableCode({
  children,
  language = 'plaintext',
  successHeader,
  successMessage,
  ...props
}: ResizableCodeProps): ReactElement {
  const onCopy = (): void => {
    if (
      typeof successHeader === 'string' &&
      typeof successMessage === 'string'
    ) {
      createToast(successHeader, 'success', successMessage);
    }
  };

  return (
    <Flyout.Body className="fs-exclude" {...props}>
      <Flyout.CodeBlock
        wrapLines
        language={language}
        onCopy={onCopy}
        fillHeight
        statement={children}
      />
    </Flyout.Body>
  );
}

ResizableFlyout.Code = ResizableCode;
ResizableFlyout.Footer = Flyout.Footer;
ResizableFlyout.Close = Flyout.Close;
