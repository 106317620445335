import { Icon, Tooltip } from '@clickhouse/click-ui';
import {
  ConnectionStrategy,
  JwtWithConnectionStrategy
} from '@cp/common/protocol/Auth0Jwt';
import { isInternalUserEmail } from '@cp/common/utils/TestUsers';
import { jwtDecode } from 'jwt-decode';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'src/components/auth';
import { useCurrentUserOrThrow } from 'src/lib/auth/AuthenticationClientHooks';
import styled from 'styled-components';

const AuthMethodIndicatorDot = (): ReactNode => {
  const { getAccessToken } = useAuth();
  // connectionStrategy in accessToken could be auth0, google-oauth2, samlp
  const [connectionStrategy, setConnectionStrategy] =
    useState<ConnectionStrategy>('auth0');
  const user = useCurrentUserOrThrow();
  const shouldShowIndicatorDot = isInternalUserEmail(user.email);

  useEffect(() => {
    const getConnectionStrategy = async (): Promise<void> => {
      const token = await getAccessToken();
      if (token) {
        const decoded: JwtWithConnectionStrategy = jwtDecode(token);
        if (decoded?.connectionStrategy) {
          setConnectionStrategy(decoded?.connectionStrategy);
        }
      }
    };

    void getConnectionStrategy();
  }, [getAccessToken]);

  if (!shouldShowIndicatorDot) {
    return null;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <CustomIcon
          $connectionStrategy={connectionStrategy}
          name="dot"
          size="lg"
          data-testid="auth-method-indicator-icon"
        />
      </Tooltip.Trigger>
      <Tooltip.Content showArrow side="top" align="start">
        Logged in via {getConnectionStrategyName(connectionStrategy)} (visible
        to internal users only)
      </Tooltip.Content>
    </Tooltip>
  );
};

const getConnectionStrategyName = (connectionStrategy: ConnectionStrategy) => {
  switch (connectionStrategy) {
    case 'google-oauth2':
      return 'Google Social';
    case 'samlp':
      return 'SAML';
    default:
      return 'Email/Password';
  }
};

const CustomIcon = styled(Icon)<{ $connectionStrategy: ConnectionStrategy }>`
  ${({ theme, $connectionStrategy }): string => `
    color: ${((): string => {
      if ($connectionStrategy === 'google-oauth2') {
        return theme.click.alert.color.iconForeground.danger;
      } else if ($connectionStrategy === 'samlp') {
        return theme.click.alert.color.iconForeground.info;
      }

      return theme.click.alert.color.iconForeground.default;
    })()};
  `};
`;

export default AuthMethodIndicatorDot;
