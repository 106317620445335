import { css, SerializedStyles, Theme } from '@emotion/react';

const elapseStyle = (theme: Theme): SerializedStyles => css`
  color: ${theme.global.color.text.muted};
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px 20px;
  .running-indicator {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;
export default {
  elapseStyle,
  resultToolbar: (theme: Theme): SerializedStyles => css`
    padding: 7px 10px;
    border-bottom: 1px solid ${theme.global.color.stroke.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  section: css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  `,
  progressToolbarStyle: (theme: Theme): SerializedStyles => css`
    ${elapseStyle(theme)}
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  `,
  progressToolbarItemStyle: css`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  `
};
