/** Make sure the given value is within the rang [min, max] (inclusive). Returns the clamped value. */
import { assertTruthy } from '@cp/common/utils/Assert';

export function clamp(min: number, value: number, max: number): number {
  assertTruthy(min <= max, 'min value must be smaller or equal to max value');
  return Math.min(max, Math.max(min, value));
}

export function roundToTwo(amount: number): number {
  return roundToDecimals(amount, 2);
}

/**
 * Returns `amount`, rounded to `decimals` decimal points.
 * `decimals` must be a non-negative integer.
 */
export function roundToDecimals(amount: number, decimals: number): number {
  assertTruthy(decimals >= 0, 'decimals must be non negative');
  assertTruthy(Math.trunc(decimals) === decimals, 'decimals must be integer');

  const amountStr = amount.toString();

  if (!amountStr.includes('e')) {
    // If amount does not have an exponent, use the original method.
    return +(Math.round(+(amount + `e+${decimals}`)) + `e-${decimals}`);
  }

  // Split into coefficient and exponent and convert them to numbers.
  const [coefficient, exponent] = amountStr.split('e').map(Number);

  if (exponent >= 0) {
    // Large numbers aren't going to change on the decimal level.
    return amount;
  }

  // Adjust the exponent to reflect the desired decimal places.
  const adjustedExponent = exponent + decimals;

  // Construct the number to round by adding `e+${adjustedExponent}` or `e-${adjustedExponent}`.
  const amountToRound = +(coefficient + `e${adjustedExponent > 0 ? '+' : ''}${adjustedExponent}`);

  // Round the number and construct the rounded result.
  return +(Math.round(amountToRound) + `e-${decimals}`);
}

/** Sums the values of a specified field on an array of objects. */
export function sumFields<T>(items: Array<T>, fieldName: keyof T): number {
  if (items.length === 0) {
    return 0;
  }
  assertTruthy(
    items.every((item) => typeof item[fieldName] === 'number'),
    'fieldName must point to a number field'
  );
  return items.reduce((total, item) => total + (item[fieldName] as number), 0);
}

/** Returns a random int between min (inclusive) and max (exclusive) */
export function randInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

/** Returns the highest threshold that value passed. If no such value was found, returns 0. */
export function findPassedThreshold<T extends number>(value: number, thresholds: T[]): T | 0 {
  const sortedThresholds = thresholds.sort((a, b) => a - b);
  const remainingThresholds = sortedThresholds.filter((currentThreshold) => currentThreshold <= value);
  // The highest threshold that was passed
  return remainingThresholds.pop() ?? 0;
}
