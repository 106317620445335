import { UserPreferences } from '@cp/common/protocol/Account';
import {
  INSTANCE_CLOUD_PROVIDERS,
  type InstanceCloudProvider,
  type IpAccessListEntry,
  MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH,
  VOLUNTARY_INSTANCE_STATE_TYPE,
  VoluntaryInstanceState
} from '@cp/common/protocol/Instance';
import type { OrganizationPrivateEndpoint, OrganizationRole } from '@cp/common/protocol/Organization';
import { isAwsRegionId, isAzureRegionId, isGcpRegionId, REGION_BY_ID, type RegionId } from '@cp/common/protocol/Region';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_REPLY_LENGTH,
  MAX_SUBJECT_LENGTH,
  SUPPORT_CASE_EVENT_TYPE_ARRAY,
  SUPPORT_CASE_PRIORITY_ARRAY,
  SUPPORT_CASE_STATUS_ARRAY,
  type SupportCaseEventType,
  type SupportCasePriority,
  type SupportCaseStatus
} from '@cp/common/protocol/Support';
import type { SubscriptionDetails } from '@cp/common/protocol/WebSocket';
import { assertTruthy, fail } from '@cp/common/utils/Assert';
import { normalizeEmail } from '@cp/common/utils/MiscUtils';
import {
  $v,
  ArrayConstraints,
  makeArrayValidator,
  ObjectValidator,
  undefinedOr,
  ValidationContextProvider
} from '@cp/common/utils/Validator';

const ALL_VALID_ORGANIZATION_ROLES: Array<OrganizationRole> = ['ADMIN', 'DEVELOPER'];
const VALID_EMAIL_PATTERN =
  /^[-!#$%&'*+/\d=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/\d=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z\d])*\.[a-zA-Z](-?[a-zA-Z\d])+$/;

/** Returns true if the given value is a valid full username: First Last.*/
export const isUserName = isSimpleName;

/** Returns true if the given value is a valid organization name. */
export const isOrganizationName = isSimpleName;

/** Checks if the given value is a valid CP-Web password. */
export function isPassword(value: string): boolean {
  return (
    isValidPasswordLength(value) &&
    checkPasswordContainUppercaseChars(value) &&
    checkPasswordContainLowercaseChars(value) &&
    checkPasswordContainNumbers(value) &&
    checkPasswordContainSpecialChars(value)
  );
}

export function isValidPasswordLength(password: unknown): password is string {
  return typeof password === 'string' && password.length >= 12;
}

export function checkPasswordContainUppercaseChars(password?: string): boolean {
  return isNotEmpty(password) && !!password.match(/[A-Z]/g);
}

export function checkPasswordContainLowercaseChars(password?: string): boolean {
  return isNotEmpty(password) && !!password.match(/[a-z]/g);
}

export function checkPasswordContainNumbers(password?: string): boolean {
  return isNotEmpty(password) && !!password.match(/[0-9]/g);
}

export function checkPasswordContainSpecialChars(password?: string): boolean {
  return isNotEmpty(password) && !!password.match(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/g);
}

/**
 * TODO: deprecated: this method is used in both validate...() and is...() methods.
 *  The validate...() must throw an error, while is...() must not.
 */
function validateCorrectStringType(value: unknown): void {
  if (value !== null && value !== undefined && typeof value !== 'string') {
    throw new Error(`Unexpected input type ${typeof value}`);
  }
}

/** Returns true if the value is not an empty string (undefined/null are considered empty). */
export function isNotEmpty(value: unknown): value is string {
  return typeof value === 'string' && !!value;
}

/** Returns true, if the value is a string with length from 'min' to 'max' (both inclusive). */
export function isStringInRange(value: unknown, min: number, max: number): value is string {
  return typeof value === 'string' && value.length >= min && value.length <= max;
}

/** Returns true, if the value is a number from 'min' to 'max' (both inclusive). */
export function isNumberInRange(value: unknown, min: number, max: number): value is number {
  return typeof value === 'number' && value >= min && value <= max;
}

/** Returns true if the value is an empty string or undefined. */
export function isEmpty(value: string | undefined | null): value is string | undefined | null {
  validateCorrectStringType(value);
  return !isNotEmpty(value);
}

// noinspection JSUnusedGlobalSymbols
export function validateEmpty(value: string | undefined | null, errorCode?: string): string | undefined | null {
  if (isEmpty(value)) return value;
  throw new Error(errorCode);
}

export function isValidRecord(value: unknown): value is Record<string, string | boolean | number> {
  return (
    typeof value === 'object' &&
    Object.keys(value as Record<string, string | boolean | number>).every((k) => isNotEmpty(k)) &&
    Object.values(value as Record<string, string | boolean | number>).every(
      (v) => isNumber(v) || isBoolean(v) || isNotEmpty(v)
    )
  );
}

/**
 * Returns true if the value is an array that is not an empty.
 * See isEmptyArray() for details.
 */
export function isNotEmptyArray<T>(value: Array<T> | undefined): value is Array<T> {
  return Array.isArray(value) && !isEmptyArray(value);
}

export function validateNotEmptyArray<T>(value: Array<T> | undefined, errorCode?: string): Array<T> {
  if (isNotEmptyArray(value)) return value;
  throw new Error(errorCode);
}

/** Validates that the given 'array' is an array of valid entries. */
export function validateArray<E>(
  array: unknown,
  singleEntryValidator: (e: E, errorCode?: string) => e is E,
  errorCode?: string
): Array<E> {
  if (!Array.isArray(array)) throw new Error(errorCode);
  for (const e of array) {
    singleEntryValidator(e, errorCode);
  }
  return array;
}

/** Returns true if the value is an empty array and false if the value is undefined. */
export function isEmptyArray<T>(value: Array<T> | undefined): value is Array<T> {
  return Array.isArray(value) && value.length === 0;
}

// noinspection JSUnusedGlobalSymbols
export function validateEmptyArray<T>(value: Array<T> | undefined, errorCode?: string): Array<T> {
  if (isEmptyArray(value)) return value;
  throw new Error(errorCode);
}

/** Returns true if the array is an array of numbers. */
export function isArrayOfNumbers(value: unknown, constraints?: ArrayConstraints): value is number[] {
  if (!value || !Array.isArray(value)) return false;
  if (constraints?.minLength && value.length < constraints.minLength) return false;
  if (constraints?.maxLength && value.length > constraints.maxLength) return false;
  return value.every(isNumber);
}

/** Returns true if the array is an array of strings. */
export function isArrayOfStrings(value: unknown, constraints?: ArrayConstraints): value is string[] {
  if (!value || !Array.isArray(value)) return false;
  if (constraints?.minLength !== undefined && value.length < constraints.minLength) return false;
  if (constraints?.maxLength !== undefined && value.length > constraints.maxLength) return false;
  return value.every(isString);
}

/**
 * Returns undefined if the entered string contains valid comma separated email values
 * @param csEmails
 * @returns undefined if the entered string contains valid comma separated email values or a string with error message if not
 */
export const validateCommaSeparatedEmails = (csEmails: string): string | undefined => {
  if (
    csEmails.length === 0 ||
    csEmails
      .replace(/\s/g, '')
      .split(',')
      .every((enteredEmail) => isEmail(enteredEmail))
  ) {
    return undefined;
  }

  return 'must contain one or more comma separated email address(es)';
};

/**
 * Returns true if 'email' is a valid email address string.
 * Took code from the 'email-validator' package.
 */
export function isEmail(email: unknown): email is string {
  if (typeof email !== 'string') return false;
  if (!email) return false;
  if (email.length > 254) return false;

  const isValid = VALID_EMAIL_PATTERN.test(email);
  if (!isValid) return false;

  // More checking of things regex can't handle
  const parts = email.split('@');
  if (parts[0].length > 64) return false;

  const domainParts = parts[1].split('.');
  return !domainParts.some(function (part) {
    return part.length > 63;
  });
}

export function validateEmail(email: unknown, errorCode = 'INVALID_EMAIL'): string {
  if (isEmail(email)) return email;
  throw new Error(errorCode);
}

export function validateAndNormalizeEmail(email: string): string {
  return validateEmail(normalizeEmail(email));
}

/** Returns true if the 'id' is a valid string region id (key) registered in 'regionMap'. */
export function isRegionId(id: unknown): id is RegionId {
  return isNotEmpty(id) && Object.keys(REGION_BY_ID).includes(id);
}

/** Returns true if the 'id' is a valid string region ID and is not experimental. */
export function isNotExperimentalRegionId(id: unknown): id is RegionId {
  return isRegionId(id) && !REGION_BY_ID[id].isExperimental;
}

export function validateRegionId(id: string | undefined, errorCode = 'INVALID_REGION_ID'): RegionId {
  if (isRegionId(id)) return id;
  throw new Error(errorCode);
}

/** Returns true if the 'role' is a valid role constant in the organization. */
export function isOrganizationRole(role: unknown): role is OrganizationRole {
  return ALL_VALID_ORGANIZATION_ROLES.includes(role as OrganizationRole);
}

export function validateOrganizationRole(
  role: OrganizationRole | undefined,
  errorCode = 'INVALID_ROLE'
): OrganizationRole {
  if (isOrganizationRole(role)) return role;
  throw new Error(errorCode);
}

/** Returns true if 'clientId' is a valid client id string. */
export function isClientId(clientId: string | undefined): clientId is string {
  // TODO: improve this check.
  return isNotEmpty(clientId);
}

export function validateClientId(clientId: string | undefined, errorCode = 'INVALID_CLIENT_ID'): string {
  if (isClientId(clientId)) return clientId;
  throw new Error(errorCode);
}

/** Returns true if the array is not empty and contains valid SubscriptionDetails objects. */
export function isSubscriptionDetailsArray(
  subscriptions: Array<SubscriptionDetails> | undefined
): subscriptions is Array<SubscriptionDetails> {
  return isNotEmptyArray(subscriptions) && subscriptions.every(isSubscriptionDetails);
}

export function validateSubscriptionDetailsArray(
  subscriptions: Array<SubscriptionDetails> | undefined,
  errorCode = 'INVALID_SUBSCRIPTION_DETAILS_ARRAY'
): Array<SubscriptionDetails> {
  if (isSubscriptionDetailsArray(subscriptions)) return subscriptions;
  throw new Error(errorCode);
}

/** Returns true if 'value' is a valid 'SubscriptionDetails' structure and has no other fields. */
export function isSubscriptionDetails(value: SubscriptionDetails | undefined): value is SubscriptionDetails {
  if (value === null || typeof value !== 'object' || !hasOnlyKeys(value, ['objId', 'type'])) {
    return false;
  }
  const type: unknown = (value as SubscriptionDetails).type;
  const objId: unknown = (value as SubscriptionDetails).objId;
  //TODO: validate 'type' to fit 'WebSocketsMessageType'.
  return typeof type === 'string' && (objId === undefined || (typeof objId === 'string' && objId.length > 0));
}

export const MIN_INSTANCE_NAME_LENGTH = 1;
export const MAX_INSTANCE_NAME_LENGTH = 50;
export const MAX_USER_NAME_LENGTH = 50;

const INSTANCE_NAME_REGEXP = new RegExp("^[A-Za-z0-9-_/.()\\[\\]' ]*$");

/** Returns true if 'name' is a valid instance name: a non-empty string with length <= 50. */
export function isInstanceName(value: unknown): value is string {
  return (
    isStringInRange(value, MIN_INSTANCE_NAME_LENGTH, MAX_INSTANCE_NAME_LENGTH) &&
    value === value.trim() &&
    INSTANCE_NAME_REGEXP.test(value)
  );
}

/** Returns true if 'name' is a valid datawarehouse name: a non-empty string with length <= 50. */
export function isDataWarehouseName(value: unknown): value is string {
  return (
    isStringInRange(value, MIN_INSTANCE_NAME_LENGTH, MAX_INSTANCE_NAME_LENGTH) &&
    value === value.trim() &&
    INSTANCE_NAME_REGEXP.test(value)
  );
}

export function isVoluntaryInstanceState(value: unknown): value is VoluntaryInstanceState {
  return typeof value === 'string' && VOLUNTARY_INSTANCE_STATE_TYPE.includes(value as VoluntaryInstanceState);
}

/** Returns true if 'obj' has only keys listed in the 'keys'. Object can't be an array. */
export function hasOnlyKeys(obj: unknown, keys: string[]): boolean {
  if (!isObject(obj) || Array.isArray(obj)) return false;
  return [...Object.keys(obj)].every((key) => keys.includes(key));
}

export function isObject(obj: unknown): obj is object {
  return typeof obj === 'object';
}

/** Error response fields. */
export interface ErrorResponseMixin {
  message: string;
  details?: string;
}

export function isIpAccessListEntry(entry: unknown): entry is IpAccessListEntry {
  if (!entry || typeof entry !== 'object' || Array.isArray(entry)) {
    return false;
  }
  const result = entry as IpAccessListEntry;
  return (
    isIpAccessSource(result.source) &&
    isStringInRange(result.description ?? '', 0, MAX_IP_ACCESS_LIST_DESCRIPTION_LENGTH)
  );
}

const GCP_PRIVATE_ENDPOINT_REGEXP = /^[0-9]+$/;
const AWS_PRIVATE_ENDPOINT_REGEXP = /^vpce-[0-9a-f]{17}$/;

export function isInstanceCloudProvider(provider: unknown): provider is InstanceCloudProvider {
  return INSTANCE_CLOUD_PROVIDERS.includes(provider as InstanceCloudProvider);
}

export function isGcpPrivateEndpointId(id: unknown): id is string {
  return typeof id === 'string' && !!id.match(GCP_PRIVATE_ENDPOINT_REGEXP);
}

export function isAwsPrivateEndpointId(id: unknown): id is string {
  return typeof id === 'string' && !!id.match(AWS_PRIVATE_ENDPOINT_REGEXP);
}

export const isAzurePrivateEndpointId = isUuid;

export function isPrivateEndpointId(id: unknown): id is string {
  return isAwsPrivateEndpointId(id) || isGcpPrivateEndpointId(id) || isAzurePrivateEndpointId(id);
}

export const organizationPrivateEndpointListValidator: ObjectValidator<OrganizationPrivateEndpoint> = {
  id: $v(isString),
  cloudProvider: $v(isInstanceCloudProvider),
  description: $v(isString),
  region: undefinedOr($v(isRegionId)),
  $o: assertValidPrivateEndpoint
};

export function assertValidPrivateEndpoint(
  endpoint: Pick<OrganizationPrivateEndpoint, 'id' | 'region' | 'cloudProvider'>,
  context: ValidationContextProvider | undefined
): void {
  const prefix = context ? (typeof context === 'string' ? context : context()) : '';

  if (endpoint.cloudProvider === 'gcp') {
    assertTruthy(isGcpPrivateEndpointId(endpoint.id), `${prefix} Invalid GCP private endpoint: ${endpoint.id}`);
    assertTruthy(isGcpRegionId(endpoint.region), `${prefix} Invalid GCP region: ${endpoint.region}`);
  } else if (endpoint.cloudProvider === 'aws') {
    assertTruthy(isAwsPrivateEndpointId(endpoint.id), `${prefix} Invalid AWS private endpoint: ${endpoint.id}`);
    assertTruthy(isAwsRegionId(endpoint.region), `${prefix} Invalid AWS region: ${endpoint.region}`);
  } else if (endpoint.cloudProvider === 'azure') {
    assertTruthy(isAzurePrivateEndpointId(endpoint.id), `${prefix} Invalid Azure private endpoint: ${endpoint.id}`);
    assertTruthy(isAzureRegionId(endpoint.region), `${prefix} Invalid Azure region: ${endpoint.region}`);
  } else {
    fail(`${prefix} Unsupported cloud provider for private endpoint: ${endpoint.cloudProvider}`);
  }
}

export const ipAccessListValidator = makeArrayValidator<IpAccessListEntry>($v(isIpAccessListEntry), {
  uniqueByIdentity: (e) => e.source
});

const IP4_REGEXP_PATTERN =
  '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
const IP4_REGEXP = new RegExp(`^${IP4_REGEXP_PATTERN}$`);

/** IP4 related constants. Used to validate 'unknown' (unsafe) input before calling RegExp. */
const MIN_IP4_ADDRESS_LENGTH = 7;
const MAX_IP4_ADDRESS_LENGTH = 15;

/** Returns true if the value is a valid IP4 address. */
export function isIp4Address(value: unknown): value is string {
  return (
    typeof value === 'string' &&
    value.length >= MIN_IP4_ADDRESS_LENGTH &&
    value.length <= MAX_IP4_ADDRESS_LENGTH &&
    IP4_REGEXP.test(value)
  );
}

const IP4_CIDR_REGEX_PATTERN = `${IP4_REGEXP_PATTERN}\\/(3[0-2]|[12]?[0-9])`;
const IP4_CIDR_REGEX = new RegExp(`^${IP4_CIDR_REGEX_PATTERN}$`);

/** Returns true if the value is a valid CIDR mask. */
export function isIp4Cidr(value: unknown): value is string {
  return (
    typeof value === 'string' &&
    value.length >= MIN_IP4_ADDRESS_LENGTH + 2 && // x.x.x.x/1
    value.length <= MAX_IP4_ADDRESS_LENGTH + 3 && // x.x.x.x/12
    IP4_CIDR_REGEX.test(value)
  );
}

/** Returns true if the value is a valid IP Access List entry 'source' value. */
export function isIpAccessSource(value: unknown): value is string {
  return isIp4Address(value) || isIp4Cidr(value);
}

/** Check that 'date' is a valid date in 'YYYY-MM-DD' format with no time part. */
export function isValidISODateFormat(value: unknown): value is string {
  if (typeof value !== 'string' || value.length !== 10) {
    return false;
  }
  const tokens = value.split('-');
  if (tokens.length !== 3) {
    return false;
  }
  const year = Number(tokens[0]);
  const month = Number(tokens[1]);
  const dayInMonth = Number(tokens[2]);
  const date = new Date(value);
  return date.getUTCFullYear() === year && month == date.getUTCMonth() + 1 && dayInMonth == date.getUTCDate();
}

// Opting to apply minimal URL validation based on https://stackoverflow.com/questions/1410311/regular-expression-for-url-validation-in-javascript
const URL_REGEXP_PATTERN = '^(http|https):\\/\\/[^ "]+$';
const URL_REGEXP = new RegExp(URL_REGEXP_PATTERN);

/** Returns true if the value is a valid URL string. */
export function isValidUrl(value: unknown): value is string {
  return typeof value === 'string' && URL_REGEXP.test(value);
}

/**
 * A simple name is a string that contains only letters from any language, numbers, hyphen (-), space ( ), period (.) and underscore (_).
 * These patterns are used to sanitize
 */
const SIMPLE_NAME_REGEXP = new RegExp('^[\\p{L}\\p{M}0-9-_. ]*$', 'u');

/** Simple name related constants. Used to validate 'unknown' (unsafe) input before calling RegExp. */
export const MIN_SIMPLE_NAME_LENGTH = 1;
export const MAX_SIMPLE_NAME_LENGTH = 50;

/** Returns true if the value is a simple name (see definition above). */
export function isSimpleName(value: unknown): value is string {
  const removeCharactersAllowedOnce = (val: string): string => val.replace("'", '');
  return (
    typeof value === 'string' &&
    value.trim().length >= MIN_SIMPLE_NAME_LENGTH &&
    value.trim().length <= MAX_SIMPLE_NAME_LENGTH &&
    SIMPLE_NAME_REGEXP.test(removeCharactersAllowedOnce(value))
  );
}

/** Replace problem characters in user name such as ", (, etc with underscore character for security reasons. */
export function sanitizeUserName(userName: string): string {
  return userName.replace(/[^\p{L}\p{M}0-9-_. ]/gu, '_');
}

const UUID4_REG_EX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/** Returns true if the value is a valid 'uuid' (v1..v5) string. */
export function isUuid(value: unknown): value is string {
  return typeof value === 'string' && UUID4_REG_EX.test(value);
}

/**
 * Compares any two objects by value, recursively.
 * Handles plain JSON objects, sets of primitive values and dates.
 */
export function isEqual(a: unknown, b: unknown): boolean {
  // If they point to the same instance
  if (a === b) {
    return true;
  }

  // Values are of different type.
  if (typeof a != 'object' || typeof b !== 'object' || a == null || b == null) {
    return false;
  }
  // If they point to the same instance of date
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  // Check if both of the objects have the same number of keys
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) {
    return false;
  }

  // If they are both an instance of Set check that all elements exist in both
  if (a instanceof Set && b instanceof Set) {
    return a.size == b.size && [...a].every((entry) => b.has(entry));
  }

  // Check recursively for every key in both objects.
  return keys.every((k) => isEqual((a as Record<string, unknown>)[k], (b as Record<string, unknown>)[k]));
}

export function isValidFeedbackText(value: unknown): value is string {
  return isStringInRange(value, 1, 10_000);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isSupportCaseSubject(value: unknown): value is string {
  return isStringInRange(value, 1, MAX_SUBJECT_LENGTH);
}

export function isSupportCaseDescription(value: unknown): value is string {
  return isStringInRange(value, 1, MAX_DESCRIPTION_LENGTH);
}

export function isSupportCaseReply(value: unknown): value is string {
  return isStringInRange(value, 1, MAX_REPLY_LENGTH);
}

export function isSupportCaseId(value: unknown): value is string {
  return isStringInRange(value, 18, 18);
}

export function isSupportCaseStatus(value: unknown): value is SupportCaseStatus {
  return typeof value === 'string' && SUPPORT_CASE_STATUS_ARRAY.includes(value as any);
}

export function isSupportCaseType(value: unknown): value is SupportCaseEventType {
  return typeof value === 'string' && SUPPORT_CASE_EVENT_TYPE_ARRAY.includes(value as any);
}

export function isSupportCasePriority(value: unknown): value is SupportCasePriority {
  return typeof value === 'string' && SUPPORT_CASE_PRIORITY_ARRAY.includes(value as any);
}

const HEX_STRING_REGEX = /^[0-9a-fA-F]*$/;

/** Returns true if 'value' is a string that contains only hexadecimal characters or is empty. */
export function isHexString(value: unknown): value is string {
  return isString(value) && HEX_STRING_REGEX.test(value);
}

/** Returns true if 'value' is a hex string of length = 64. */
export function isHexSha256String(value: unknown): value is string {
  return isString(value) && value.length === 64 && isHexString(value);
}

const ISO_8601 = new RegExp(/^\d{4}-\d\d-\d\d(T\d\d:\d\d:\d\d\.\d{3}Z)?$/i);
const ISO_8601_FULL = new RegExp(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{3}Z?$/i);

export type IsoDateFormat = 'DATE_ONLY' | 'DATE_TIME';

/** Returns true if 'value' is an ISO-8601 formatted date with or without time (see format parameter). */
export function isIsoDateString(value: unknown, format: IsoDateFormat = 'DATE_ONLY'): value is string {
  switch (format) {
    case 'DATE_ONLY':
      return isString(value) && ISO_8601.test(value);
    case 'DATE_TIME':
      return isString(value) && ISO_8601_FULL.test(value);
  }
}

/** Returns true if 'value' is a HH:mm formatted time string. */
export function isValidTimeHHMMString(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false;
  }

  const timePatternRegex: RegExp = /^(?:[0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5]\d$/;
  return timePatternRegex.test(value);
}

/** Returns true if 'value' is a HH:00 formatted time string. */
export function isValidHourOClock(value: unknown): boolean {
  if (isValidTimeHHMMString(value) && typeof value === 'string') {
    return value.endsWith('00');
  }

  return false;
}

export function isPartialUserPreferences(value: unknown): value is Partial<UserPreferences> {
  if (value === undefined || value === null || typeof value !== 'object' || Array.isArray(value)) {
    return false;
  }
  const obj = value as UserPreferences;
  return (
    (obj.isGptInlineCodeCompletionEnabled === undefined || typeof obj.isGptInlineCodeCompletionEnabled === 'boolean') &&
    (obj.theme === undefined || typeof obj.theme === 'string')
    // Add more field validation here as UserPreferences are extended
  );
}

export type NameValidationResult = {
  isValid: boolean;
  validationMessage?: string;
};

export const validateName = (name: string, maxNameLength: number = MAX_SIMPLE_NAME_LENGTH): NameValidationResult => {
  if (name.trim() === '') {
    return { isValid: false, validationMessage: 'Please enter a valid name.' };
  }

  if (name.length > maxNameLength) {
    return {
      isValid: false,
      validationMessage: `Maximum length is ${maxNameLength} characters. Yours is ${name.length} characters.`
    };
  }

  if (!isSimpleName(name)) {
    return {
      isValid: false,
      validationMessage:
        'Invalid name. Accepted characters include letters, numbers, hyphens, spaces, periods, and underscores.'
    };
  }

  return { isValid: true };
};
