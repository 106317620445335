import { Permission } from '@cp/common/protocol/Authorization';

// All notification names
export const NOTIFICATION_NAMES = [
  'billing_credit_passed_50pc_threshold',
  'billing_credit_passed_75pc_threshold',
  'billing_credit_passed_90pc_threshold',
  'billing_payment_failed',
  'service_vertical_autoscaling',
  'service_vertical_autoscaling_clip_up',
  'service_vertical_autoscaling_clip_down'
] as const;
export type NotificationName = (typeof NOTIFICATION_NAMES)[number];

export const NOTIFICATION_EMAIL_TEMPLATE_IDS = [
  'd-7ca3edfcfaa3434293c2f8251a431fa3',
  'd-5706772a2e8449b6aa21c0ad805ff4a0',
  'd-e41dd3d4bd0446158f11326a8e4a4f6b',
  'd-592c590dfea94f86af55f7f8d9fc108a',
  'd-f97a08c3b3814f64980f88fb1b9294b2',
  'd-9e63cf4c30344754b60524ee732df3bb',
  'd-65d96fc95e104d5cbb70b71e4f46cb95'
] as const;
export type NotificationEmailTemplateId = (typeof NOTIFICATION_EMAIL_TEMPLATE_IDS)[number];

export const NOTIFICATION_INVENTORY = {
  service_vertical_autoscaling: {
    title: 'Cluster scaling completed',
    category: 'service',
    severity: 'info',
    isConfigurable: true,
    sendGridTemplateId: 'd-7ca3edfcfaa3434293c2f8251a431fa3'
  },
  service_vertical_autoscaling_clip_up: {
    title: 'Cluster can not be scaled up',
    description: 'Cluster can not be scaled up as max autoscaling settings are below recommendation based on usage',
    category: 'service',
    severity: 'critical',
    isConfigurable: true,
    sendGridTemplateId: 'd-5706772a2e8449b6aa21c0ad805ff4a0'
  },
  service_vertical_autoscaling_clip_down: {
    title: 'Cluster can not be scaled down',
    description: 'Cluster can not be scaled down as min autoscaling settings are above recommendation based on usage',
    category: 'service',
    severity: 'warning',
    isConfigurable: true,
    sendGridTemplateId: 'd-e41dd3d4bd0446158f11326a8e4a4f6b'
  },
  billing_credit_passed_50pc_threshold: {
    title: 'Prepaid Credit Passed 50% Threshold',
    category: 'billing',
    severity: 'info',
    isConfigurable: true,
    sendGridTemplateId: 'd-592c590dfea94f86af55f7f8d9fc108a'
  },
  billing_credit_passed_75pc_threshold: {
    title: 'Prepaid Credit Passed 75% Threshold',
    category: 'billing',
    severity: 'warning',
    isConfigurable: true,
    sendGridTemplateId: 'd-f97a08c3b3814f64980f88fb1b9294b2'
  },
  billing_credit_passed_90pc_threshold: {
    title: 'Prepaid Credit Passed 90% Threshold',
    category: 'billing',
    severity: 'critical',
    isConfigurable: true,
    sendGridTemplateId: 'd-9e63cf4c30344754b60524ee732df3bb'
  },
  billing_payment_failed: {
    title: 'Payment failed',
    category: 'billing',
    severity: 'critical',
    isConfigurable: false,
    sendGridTemplateId: 'd-65d96fc95e104d5cbb70b71e4f46cb95'
  }
} satisfies Record<NotificationName, NotificationType>;

/// Also: service, warehouse, user, organization in the future
export const NOTIFICATION_RESOURCE_TYPES = ['service', 'organization'] as const;
export type NotificationResourceType = (typeof NOTIFICATION_RESOURCE_TYPES)[number];

// Also: security, error in the future
export const NOTIFICATION_CATEGORIES = ['billing', 'service'] as const;
export type NotificationCategory = (typeof NOTIFICATION_CATEGORIES)[number];

export const NOTIFICATION_SEVERITIES = ['info', 'warning', 'critical'] as const;
export type NotificationSeverity = (typeof NOTIFICATION_SEVERITIES)[number];
export const NOTIFICATION_SEVERITY_LEVELS: Record<NotificationSeverity, number> = {
  info: 0,
  warning: 1,
  critical: 2
};

export type NotificationType = {
  /**
   * Title is used in UI to list all notifications in the settings page.
   * Please make sure it's clear, short and understandable for the user.
   */
  title: string;
  /**
   * Description is used in UI in flyout in the settings page.
   * Please make sure it's clear and understandable for the user.
   */
  description?: string;
  category: NotificationCategory;
  severity: NotificationSeverity;
  /**
   * If true, the notification can be configured by the user in the settings page.
   */
  isConfigurable: boolean;
  requiredPermission?: Permission;

  /**
   * Template id for email.
   * Null should be explicitly set if there is no email channel for the notification.
   */
  sendGridTemplateId: NotificationEmailTemplateId | null;
};

// webhook, slack in the future
export const NOTIFICATION_CHANNEL_TYPES = ['ui', 'email'] as const;
export type NotificationChannelType = (typeof NOTIFICATION_CHANNEL_TYPES)[number];
