import {
  Container,
  DangerAlert,
  IconButton,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import React, { ReactElement } from 'react';
import { ChunkSummaryModel } from 'src/dataloading/models';

type Props = {
  chunk: ChunkSummaryModel;
  onClose: () => void;
};

const ChunkDetails = ({ chunk, onClose }: Props): ReactElement | null => {
  if (
    !['failed_moving_partitions', 'failed_importing', 'canceled'].includes(
      chunk.status
    )
  ) {
    return null;
  }
  return (
    <Container orientation="vertical" gap="xxs" alignItems="start">
      <Container alignItems="start" justifyContent="space-between">
        <Title type="h3" size="sm">
          Chunk {chunk.chunkNumber + 1}
        </Title>
        <IconButton icon="cross" type="ghost" onClick={onClose} />
      </Container>
      <Text>{chunk.url}</Text>
      <Text>Last attempt: {chunk.getLastAttemptFormatted()}</Text>
      <Spacer size="sm" />
      <DangerAlert text={chunk.error} />
    </Container>
  );
};
const MemoizedChunkDetails = React.memo(ChunkDetails);
MemoizedChunkDetails.displayName = 'ChunkDetails';
export default MemoizedChunkDetails;
