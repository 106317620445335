import {
  Avatar,
  Container,
  EllipsisContent,
  Icon,
  IconName,
  Tooltip
} from '@clickhouse/click-ui';
import { IdentityProvider } from '@cp/common/protocol/Account';
import { ReactElement } from 'react';
import styled from 'styled-components';

const FixedAvatar = styled(Avatar)`
  flex-shrink: 0;
  padding-top: 1px;
`;

const CapitalizeSpan = styled.span`
  text-transform: capitalize;
`;

export const getInitials = (userName: string): string => {
  const userNameSplit = userName.trim().split(' ');
  return userNameSplit.length > 1
    ? [
        userNameSplit[0].charAt(0),
        userNameSplit[userNameSplit.length - 1].charAt(0)
      ].join('')
    : userNameSplit[0].slice(0, 2);
};

export const generateNameAvatar = (userName: string): ReactElement => {
  const initials = getInitials(userName);
  return (
    <Container
      orientation="horizontal"
      gap="md"
      alignItems="center"
      fillWidth={false}
      isResponsive={false}
    >
      <FixedAvatar text={initials} textSize="md" data-testid="avatar-cell" />
      <EllipsisContent data-testid="name-cell">{userName}</EllipsisContent>
    </Container>
  );
};

const providerToIcon: Record<IdentityProvider, IconName> = {
  GOOGLE: 'google',
  MICROSOFT: 'cloud-keys', // TODO: Replace with Microsoft icon. We don't have it yet.
  OKTA: 'cloud-keys',
  COGNITO: 'email',
  AUTH0: 'email'
};

const getIdentityProviderDisplayName = (provider: IdentityProvider): string => {
  switch (provider) {
    case 'GOOGLE':
      return 'Google';
    case 'MICROSOFT':
      return 'Microsoft';
    case 'OKTA':
      return 'SSO';
    default:
      return 'email';
  }
};

export const getAuthIcons = (
  providers: Array<IdentityProvider>
): ReactElement => {
  const migratedUser =
    providers.filter((idp) => ['COGNITO', 'AUTH0'].includes(idp)).length === 2;
  const filteredProviders = migratedUser
    ? providers.filter((idp) => idp !== 'COGNITO')
    : providers;
  return (
    <Container
      orientation="horizontal"
      gap="sm"
      fillWidth={false}
      isResponsive={false}
    >
      {filteredProviders.map((provider) => (
        <Tooltip key={provider}>
          <Tooltip.Trigger>
            <Icon
              name={providerToIcon[provider] ?? 'email'}
              width="1rem"
              height="1rem"
              data-testid={`identity-provider-icon-${provider}`}
            />
          </Tooltip.Trigger>
          <Tooltip.Content>
            Signed in with&nbsp;
            <CapitalizeSpan>
              {getIdentityProviderDisplayName(provider)}
            </CapitalizeSpan>
          </Tooltip.Content>
        </Tooltip>
      ))}
    </Container>
  );
};
