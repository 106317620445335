import { Folder, NodeType } from 'shared/src/types/queryFolders';

export const newFolder = (newFolderId: string, userId: string): Folder => {
  return {
    children: [],
    creatorId: userId,
    id: newFolderId,
    title: 'New folder'
  };
};

const folderWithNewSubfolder = (
  folder: Folder,
  newFolderId: string,
  targetId: string | null,
  userId: string
): Folder => {
  if (targetId === folder.id) {
    return {
      ...folder,
      children: [newFolder(newFolderId, userId), ...folder.children]
    };
  } else {
    return {
      ...folder,
      children: listWithNewFolder(userId, folder.children, newFolderId, targetId)
    };
  }
};

export const listWithNewFolder = (
  userId: string,
  oldObjects: NodeType[] = [],
  newFolderId: string,
  targetId: string | null,
  isLast = false
): NodeType[] => {
  if (!targetId) {
    const createdFolder = newFolder(newFolderId, userId);
    if (isLast) {
      return [...oldObjects, createdFolder];
    }
    return [createdFolder, ...oldObjects];
  } else {
    const nonFolderTargetIndex = oldObjects.findIndex((obj) => !obj.children && obj.id === targetId);
    if (nonFolderTargetIndex !== -1) {
      return [
        ...oldObjects.slice(0, nonFolderTargetIndex),
        {
          ...newFolder(newFolderId, userId),
          children: [oldObjects[nonFolderTargetIndex]]
        },
        ...oldObjects.slice(nonFolderTargetIndex + 1)
      ];
    } else {
      return oldObjects.map((obj) => {
        if (obj.children) {
          return folderWithNewSubfolder(obj, newFolderId, targetId, userId);
        } else {
          return obj;
        }
      });
    }
  }
};
