import { ReactElement, useState } from 'react';

import { ConfirmationDialog } from '@clickhouse/click-ui';

interface ConfirmNodeDeleteProps {
  open: boolean;
  title?: string;
  onCancel: () => void;
  onDeleteFolder: () => Promise<void>;
  onDeleteQuery: () => Promise<void>;
  type?: 'folder' | 'query';
}

export function ConfirmNodeDelete({
  open,
  title,
  type,
  onCancel,
  onDeleteFolder,
  onDeleteQuery
}: ConfirmNodeDeleteProps): ReactElement | null {
  const [deleting, setDeleting] = useState(false);

  if (open !== true) {
    return null;
  }

  const saveAction = (action: () => Promise<void>) => {
    return () => {
      setDeleting(true);
      action()
        .then(() => setDeleting(false))
        .catch(() => setDeleting(false));
    };
  };

  const message =
    type === 'folder'
      ? `${title} contains one or more saved queries. Do you want to delete the contents of this folder? Any queries with query endpoints enabled will have those endpoints disabled as well.`
      : `Are you sure you want to delete ${title}? If the query has an API endpoint enabled it will be disabled as well.`;
  return (
    <ConfirmationDialog
      title={`Delete ${type === 'folder' ? 'folder' : 'query'}?`}
      open={open}
      onCancel={onCancel}
      onConfirm={saveAction(type === 'folder' ? onDeleteFolder : onDeleteQuery)}
      primaryActionLabel="Delete"
      secondaryActionLabel="Cancel"
      message={message}
      loading={deleting}
    />
  );
}
