import { ReactElement, Suspense } from 'react';
import {
  Container,
  ContainerProps,
  Separator,
  Title
} from '@clickhouse/click-ui';
import styled from 'styled-components';
import { SidebarTrigger } from 'src/layout/SidebarWrapper/SidebarWrapper';

const TopBar = styled(Container)`
  padding-inline: 1.5rem;
`;

const MainPaneLayout = (props: ContainerProps): ReactElement => {
  return (
    <Container
      fillHeight
      orientation="vertical"
      alignItems="start"
      justifyContent="start"
      {...props}
    />
  );
};

const Topbar = ({
  label = '',
  children,
  showMenu = true,
  ...props
}: ContainerProps & {
  label?: string;
  showMenu?: boolean;
}): ReactElement => {
  return (
    <>
      <TopBar
        isResponsive={false}
        justifyContent="space-between"
        fillHeight
        maxHeight="3.5rem"
        {...props}
      >
        <SidebarTrigger
          showMenu={showMenu}
          justifyContent="start"
          isResponsive={false}
          fillWidth
          fillHeight
        >
          {label.length > 0 && (
            <Container justifyContent="start" isResponsive={false}>
              <Title size="sm" type="h2">
                {label}
              </Title>
            </Container>
          )}
          {children}
        </SidebarTrigger>
      </TopBar>
      <Separator size="xs" />
    </>
  );
};
MainPaneLayout.Topbar = Topbar;

const Content = ({ children, ...props }: ContainerProps): ReactElement => {
  return (
    <Container
      orientation="vertical"
      grow="1"
      overflow="hidden"
      justifyContent="start"
      alignItems="start"
      isResponsive={false}
      {...props}
    >
      <Container orientation="vertical" overflow="auto" fillHeight>
        <Suspense fallback={null}>{children}</Suspense>
      </Container>
    </Container>
  );
};
MainPaneLayout.Content = Content;
export default MainPaneLayout;
