import {
  Button,
  Container,
  Icon,
  IconName,
  Panel,
  Spacer,
  Text,
  Title
} from '@clickhouse/click-ui';
import {
  CardPaymentMethod,
  GetOrganizationBillingDetailsResponse,
  INVOICE_STATUS_RAW
} from '@cp/common/protocol/Billing';
import { isDefined } from '@cp/common/protocol/Common';
import {
  Organization,
  OrganizationBillingStatus,
  OrganizationPaymentStateType,
  OrganizationTackleSubscription
} from '@cp/common/protocol/Organization';
import { isTackleMarketplace } from '@cp/common/protocol/Tackle';
import { ReactElement } from 'react';
import BillingInfo from 'src/components/BillingInfo/BillingInfo';
import Helmet from 'src/components/Helmet/Helmet';
import { MaxWidthContainer } from 'src/components/MaxWidthContainer';
import MainPaneLayout from 'src/layout/MainPaneLayout';
import SplitLayout from 'src/layout/SplitLayout';
import config from 'src/lib/config';
import BillingHeaderSection from 'src/pages/OrganizationBillingPage/BillingHeaderSection';
import styled from 'styled-components';

type NoCreditCardViewProps = {
  onOpenBillingCardModal: () => void;
  isLoading: boolean;
};
export const NoCreditCardView = ({
  onOpenBillingCardModal,
  isLoading
}: NoCreditCardViewProps): ReactElement => {
  return (
    <MaxWidthContainer>
      <Container
        orientation="vertical"
        gap="xs"
        padding="xs"
        data-testid="add-credit-card-page"
        fillWidth
        justifyContent="center"
        alignItems="center"
      >
        <Spacer size="xs" />
        <Container
          orientation="vertical"
          padding="none"
          color="default"
          alignItems="center"
          maxWidth="400px"
        >
          <Icon name="credit-card" height="80px" width="80px" />

          <Title type="h1" size="md">
            Add your credit card
          </Title>
          <Spacer size="sm" />
          <Text align="center" color="muted">
            Add a credit card to continue using ClickHouse once your free trial
            ends.
          </Text>

          <Spacer size="md" />
          <Button
            align="center"
            iconLeft="plus"
            label="Credit card"
            type="primary"
            onClick={onOpenBillingCardModal}
            data-testid="add-card-button"
            loading={isLoading}
          />
        </Container>
      </Container>
    </MaxWidthContainer>
  );
};

type AWSAccountIdViewProps = {
  enabled: boolean;
  tackleState?: OrganizationTackleSubscription;
};

export const AWSAccountIdView = ({
  enabled,
  tackleState
}: AWSAccountIdViewProps): ReactElement | null => {
  if (!enabled || !tackleState?.awsAccountId) {
    return null;
  }

  return (
    <Container gap="xs" fillWidth={false}>
      <Text size="md">AWS Account:</Text>
      <Text size="md" weight="semibold">
        {tackleState?.awsAccountId}
      </Text>
    </Container>
  );
};

type TacklePaymentMethodViewProps = {
  enabled: boolean;
  tackleState?: OrganizationTackleSubscription;
};

const TacklePaymentMethodView = ({
  enabled,
  tackleState
}: TacklePaymentMethodViewProps): ReactElement | null => {
  if (!enabled || !tackleState?.marketplace) {
    return null;
  }

  const marketplace = tackleState?.marketplace ?? 'aws';
  if (!isTackleMarketplace(marketplace)) {
    return null;
  }

  let marketplaceProdPageUrl = '';
  switch (marketplace) {
    case 'aws':
      marketplaceProdPageUrl = config.tackle.awsMarketplaceProdPageUrl;
      break;
    case 'gcp':
      marketplaceProdPageUrl = config.tackle.gcpMarketplaceProdPageUrl;
      break;
    case 'azure':
      marketplaceProdPageUrl = config.tackle.azureMarketplaceProdPageUrl;
      break;
  }

  return (
    <Container
      orientation="vertical"
      alignItems="start"
      data-testid={marketplace + '-marketplace-link'}
      fillWidth
    >
      <Panel orientation="horizontal" fillWidth>
        <Container
          orientation="horizontal"
          justifyContent="space-between"
          gap="lg"
          fillWidth
          data-testid-tackle-customerid={tackleState?.customerId}
        >
          <Container orientation="horizontal" gap="lg" fillWidth={false}>
            <Icon name={marketplace as IconName} size="xxl" />
            <Button
              onClick={(): void => {
                window?.open(marketplaceProdPageUrl, '_blank');
              }}
              align="left"
              label={`View in ${marketplace.toUpperCase()} Marketplace`}
              type="primary"
              iconRight="popout"
            />
          </Container>
          <AWSAccountIdView
            enabled={marketplace === 'aws'}
            tackleState={tackleState}
          />
        </Container>
      </Panel>
      <Spacer size="xxl" />
    </Container>
  );
};

type BillingPageProps = {
  currentOrganization: Organization;
  organizationBillingDetails: GetOrganizationBillingDetailsResponse;
  onOpenBillingCardModal: () => void;
  onEditBillingContact: () => void;
  isLoading: boolean;
};

type BillingHistoryViewProps = {
  tackleSubscriptionActive: boolean;
  organizationBillingDetails: GetOrganizationBillingDetailsResponse;
  currentOrganization: Organization;
  onOpenBillingCardModal: () => void;
  onEditBillingContact: () => void;
  isLoading: boolean;
};

function checkIfTackleSubscriptionActive(
  billingStatus: OrganizationBillingStatus,
  paymentState: OrganizationPaymentStateType,
  tackleState?: OrganizationTackleSubscription
): boolean {
  return (
    paymentState === 'TACKLE' &&
    !['IN_MP_GRACE_PERIOD', 'DECOMMISSIONED'].includes(billingStatus) &&
    // If there's a cancellation date on the tackleState, this MP subscription isn't active
    !isDefined(tackleState?.clickhouseCancellationDate)
  );
}

function checkIfNoCreditCardViewShouldBeVisible(
  billingStatus: OrganizationBillingStatus,
  hasPaymentMethod: boolean
): boolean {
  const statusesToHide = new Set<OrganizationBillingStatus>([
    'IN_NONPAYMENT_GRACE_PERIOD',
    'PAID',
    'PREPAID',
    'REVIEW_MANUALLY',
    'IN_MP_REMORSE_PERIOD',
    'IN_MP_GRACE_PERIOD'
  ]);
  return !hasPaymentMethod && !statusesToHide.has(billingStatus);
}

const CustomSplitLayout = styled(SplitLayout)<{ $hide: boolean }>`
  ${({ $hide }): string => ($hide ? 'background: none;' : '')};
`;

const BillingPage = ({
  currentOrganization,
  organizationBillingDetails,
  onOpenBillingCardModal,
  onEditBillingContact,
  isLoading
}: BillingPageProps): ReactElement => {
  const tackleSubscriptionActive = checkIfTackleSubscriptionActive(
    currentOrganization.billingStatus,
    currentOrganization.paymentState,
    currentOrganization.tackleState
  );
  const hasPaymentMethod =
    !!organizationBillingDetails.paymentMethod && tackleSubscriptionActive;

  const showNoCreditCardView = checkIfNoCreditCardViewShouldBeVisible(
    currentOrganization.billingStatus,
    hasPaymentMethod
  );

  return (
    <MainPaneLayout>
      <MainPaneLayout.Topbar label="Billing" />
      <MainPaneLayout.Content>
        <Helmet>
          <title>Billing - ClickHouse Cloud</title>
        </Helmet>
        <CustomSplitLayout $hide={showNoCreditCardView}>
          <MaxWidthContainer overflow="hidden">
            <Container
              orientation="vertical"
              padding="lg"
              alignItems="start"
              data-testid="billing-page"
              gap="xxl"
              fillWidth
            >
              {showNoCreditCardView && (
                <NoCreditCardView
                  onOpenBillingCardModal={onOpenBillingCardModal}
                  isLoading={isLoading}
                />
              )}

              {!showNoCreditCardView && (
                <BillingHeaderSection
                  enabled={!tackleSubscriptionActive}
                  nextInvoice={organizationBillingDetails.nextInvoiceDate}
                  card={
                    organizationBillingDetails.paymentMethod as CardPaymentMethod
                  }
                  billingContact={organizationBillingDetails.billingContact}
                  paymentState={currentOrganization.paymentState}
                  onOpenBillingCardModal={onOpenBillingCardModal}
                  onEditBillingContact={onEditBillingContact}
                  isLoading={isLoading}
                />
              )}
              <Container orientation="vertical">
                <TacklePaymentMethodView
                  enabled={tackleSubscriptionActive}
                  tackleState={currentOrganization.tackleState}
                />
                <BillingInfo
                  showInvoices={
                    currentOrganization.paymentState !== 'TACKLE' &&
                    (currentOrganization.billingStatus !== 'IN_TRIAL' ||
                      organizationBillingDetails.invoices.length > 0)
                  }
                  invoices={organizationBillingDetails.invoices.map((i) => ({
                    periodStartDate: i.periodStartDate,
                    periodEndDate: i.periodEndDate,
                    invoiceId: i.invoiceId,
                    amount: i.amount,
                    currency: i.currency,
                    billId: i.billId,
                    status: INVOICE_STATUS_RAW[i.status],
                    invoicePdfDownloadLink: i.invoicePdfDownloadLink,
                    invoicePaymentLink: i.invoicePaymentLink,
                    organizationId: currentOrganization.id
                  }))}
                  creditBalances={organizationBillingDetails.creditBalances
                    .map((cb) => ({
                      periodStartDate: cb.periodStartDate,
                      periodEndDate: cb.periodEndDate,
                      amountTotal: cb.amountTotal,
                      amountSpent: cb.amountSpent,
                      amountRemaining: cb.amountRemaining
                    }))
                    .sort((a, b) => b.periodEndDate - a.periodEndDate)}
                  usageStatements={organizationBillingDetails.billUsageStatements
                    .map((us) => ({
                      periodStartDate: us.periodStartDate,
                      periodEndDate: us.periodEndDate,
                      billId: us.billId,
                      billGrossTotal: us.billGrossTotal,
                      billNetTotal: us.billNetTotal,
                      creditApplied: us.creditApplied,
                      locked: us.locked,
                      organizationId: currentOrganization.id
                    }))
                    .sort((a, b) => b.periodEndDate - a.periodEndDate)}
                  isLoading={isLoading}
                />
              </Container>
            </Container>
          </MaxWidthContainer>
        </CustomSplitLayout>
      </MainPaneLayout.Content>
    </MainPaneLayout>
  );
};

export default BillingPage;
