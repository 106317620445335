import { ClientError } from 'api/src/lib/errorHandling';
import { useEffect, useState } from 'react';

// ClickHouse Cloud Status API Docs: https://status.clickhouse.com/api
export const STATUS_PAGE_BASE_URL = 'https://status.clickhouse.com';
const STATUS_API_VERSION = 'api/v2';
const STATUS_ENDPOINT = 'status.json';
const UNRESOLVED_INCIDENTS_ENDPOINT = 'incidents/unresolved.json';

export type StatusIndicator = 'none' | 'minor' | 'major' | 'critical';
type StatusDescription = 'All systems operational' | 'Partial system outage' | 'Major service outage';
export type IncidentImpact = 'none' | 'minor' | 'major' | 'critical';

interface StatusApiResponse {
  status: {
    description: StatusDescription;
    indicator: StatusIndicator;
  };
}
interface Incident {
  id: string;
  name: string;
  impact: IncidentImpact;
}
interface IncidentsApiResponse {
  incidents: Array<Incident>;
}

interface CloudStatus {
  statusIndicator: StatusIndicator | '';
  statusText: StatusDescription | '';
  incidentName: string;
  incidentId: string;
  incidentImpact: IncidentImpact | '';
}

export const useCloudStatus = (): CloudStatus => {
  const [statusText, setStatusText] = useState('');
  const [statusIndicator, setStatusIndicator] = useState('');
  const [incidentName, setIncidentName] = useState('');
  const [incidentId, setIncidentId] = useState('');
  const [incidentImpact, setIncidentImpact] = useState('');

  const setIncidentDetails = (): void => {
    fetch(`${STATUS_PAGE_BASE_URL}/${STATUS_API_VERSION}/${UNRESOLVED_INCIDENTS_ENDPOINT}`)
      .then((res) => {
        if (!res.ok) {
          throw new ClientError(res.status, 'Invalid response from server');
        }
        return res.json();
      })
      .then((data: Required<IncidentsApiResponse>) => {
        const latestIncident = data.incidents[0];
        setIncidentName(latestIncident.name);
        setIncidentId(latestIncident.id);
        setIncidentImpact(latestIncident.impact);
      })
      .catch((e) => {
        console.error('Error getting incident details', e);
      });
  };

  useEffect(() => {
    fetch(`${STATUS_PAGE_BASE_URL}/${STATUS_API_VERSION}/${STATUS_ENDPOINT}`)
      .then((res) => {
        if (!res.ok) {
          throw new ClientError(res.status, 'Invalid response from server');
        }
        return res.json();
      })
      .then((data: Required<StatusApiResponse>) => {
        const statusIndicator = data.status.indicator;
        setStatusText(data.status.description);
        setStatusIndicator(statusIndicator);
        if (statusIndicator !== 'none') {
          setIncidentDetails();
        }
      })
      .catch((e) => {
        console.error('Error while calling Status API', e);
      });
  }, []);

  return <CloudStatus>{
    statusIndicator,
    statusText,
    incidentName,
    incidentId,
    incidentImpact
  };
};
