import { BigStat, Container } from '@clickhouse/click-ui';
import { CardPaymentMethod } from '@cp/common/protocol/Billing';
import { OrganizationPaymentStateType } from '@cp/common/protocol/Organization';
import { ReactElement } from 'react';
import { formatDateShort } from 'src/lib/formatters/dateTimeFormatter';
import { HeaderBoxContentContainer } from 'src/pages/OrganizationBillingPage/pageElements';

export interface HeaderSectionProps {
  enabled: boolean;
  nextInvoice: number | undefined;
  //NB: we'll need to add some functions for onCardEditClick and onBillingContactEditClick.
  card: CardPaymentMethod | undefined;
  billingContact: string;
  paymentState: OrganizationPaymentStateType;
  onOpenBillingCardModal: () => void;
  onEditBillingContact: () => void;
  isLoading: boolean;
}

const BillingHeaderSection = ({
  enabled,
  nextInvoice,
  card,
  billingContact,
  paymentState,
  onOpenBillingCardModal,
  onEditBillingContact,
  isLoading
}: HeaderSectionProps): ReactElement | null => {
  if (!enabled) {
    return null;
  }
  const nextInvoiceDateString = nextInvoice
    ? formatDateShort(nextInvoice)
    : null;
  return (
    <Container fillWidth gap="lg" alignItems="start">
      {nextInvoice && paymentState !== 'TACKLE' && (
        <BigStat
          title={nextInvoiceDateString}
          label="Next invoice due"
          size="sm"
          fillWidth
          maxWidth="368px"
        />
      )}
      {card && (
        <BigStat
          title={
            <HeaderBoxContentContainer
              text={card.last4}
              cardIcon={card.brand}
              testId="edit-card-btn"
              onEditClick={onOpenBillingCardModal}
              isLoading={isLoading}
            />
          }
          label="Credit card"
          size="sm"
          fillWidth
          maxWidth="368px"
        />
      )}
      {!card && (
        <BigStat
          title={
            <HeaderBoxContentContainer
              text="+ Credit card"
              testId="edit-card-btn"
              onEditClick={onOpenBillingCardModal}
              isLoading={isLoading}
            />
          }
          label="Add payment method"
          size="sm"
          fillWidth
          maxWidth="368px"
        />
      )}
      <BigStat
        title={
          <HeaderBoxContentContainer
            text={billingContact}
            testId="edit-billing-contact-btn"
            onEditClick={onEditBillingContact}
            isLoading={isLoading}
          />
        }
        label="Billing contact"
        size="sm"
        fillWidth
        maxWidth="368px"
      />
    </Container>
  );
};

export default BillingHeaderSection;
