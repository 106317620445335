import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useRef,
  useState
} from 'react';
import {
  Button,
  Container,
  GridContainer,
  Label,
  Link,
  Panel,
  RadioGroup,
  Spacer,
  SuccessAlert,
  Text as CUIText
} from '@clickhouse/click-ui';
import useNetworkSettings from 'src/instance/controller/useNetworkSettings';
import { useNetworkAccessList } from 'src/instance/controller/useNetworkAccessList';
import { NetworkInput } from 'src/pages/SettingsPage/NetworkInput';
import styled from 'styled-components';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { routes } from 'src/lib/routes';

const CustomRadioGroup = styled(RadioGroup)`
  margin: auto;
`;

export interface NetworkAccessListPanelProps {
  instanceId: string;
  onSubmitClick: (type: 'idlingOption' | 'accessList') => void;
}

export const NetworkAccessListPanel = ({
  instanceId,
  onSubmitClick
}: NetworkAccessListPanelProps): ReactElement => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const {
    networkType,
    onSubmit: onSubmitSettings,
    ipList: ipsAccessList
  } = useNetworkSettings(instanceId);
  const {
    hasUpdateIpAddressListPermission,
    duplicateList,
    invalidIpList,
    IP_ADDRESS_INPUT_COLUMN,
    addItem,
    onValueChange,
    removeItem,
    onSubmit: onSubmitProp,
    onRadioGroupChange: onRadioGroupChangeProp,
    addCurrentIp,
    onBlur,
    disabledSaveBtn,
    ipOption,
    ipList
  } = useNetworkAccessList({
    ipsAccessList,
    open: true,
    type: networkType,
    onSubmit: onSubmitSettings
  });
  const ipListLength = ipList.length;
  const onRadioGroupChange = useCallback(
    (value: string): void => {
      onRadioGroupChangeProp(value);
      setIsChanged(true);
    },
    [onRadioGroupChangeProp]
  );
  const onSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      onSubmitProp(e);
      onSubmitClick('accessList');
      setIsSubmitted(true);
      setIsChanged(false);
    },
    [onSubmitClick, onSubmitProp]
  );

  if (isSubmitted) {
    return (
      <SuccessAlert
        title="IP filtering set"
        size="medium"
        text={
          <Container isResponsive={false} wrap="wrap" gap="xxs">
            Your service will connect from{' '}
            {ipOption === 'specificLocations' ? 'specific locations' : ipOption}
            .
            <span>
              <Link size="sm" onClick={() => setIsSubmitted(false)}>
                Edit answer
              </Link>
              |
            </span>
            <Link
              size="sm"
              onClick={() =>
                navigateTo(routes.serviceSettings({ serviceId: instanceId }))
              }
            >
              Go to network settings
            </Link>
          </Container>
        }
      />
    );
  }
  return (
    <Panel color="muted" fillWidth>
      <CUIText weight="bold" align="center" fillWidth>
        Where do you want to connect from?
      </CUIText>
      <Container
        fillWidth
        justifyContent="center"
        alignItems="center"
        isResponsive={false}
      >
        <CUIText
          color="muted"
          align="center"
          weight="normal"
          component={Container}
          maxWidth="600px"
        >
          To ensure an extra layer of security for your service, we recommend
          only allowing traffic from IP addresses that you know and trust.
        </CUIText>
      </Container>
      <Container orientation="vertical" alignItems="center">
        <CustomRadioGroup
          orientation="vertical"
          value={ipOption}
          onValueChange={onRadioGroupChange}
          data-testid="specific-location-block"
          disabled={!hasUpdateIpAddressListPermission}
          inline
        >
          <RadioGroup.Item value="anywhere" label="Anywhere" />
          <RadioGroup.Item
            value="specificLocations"
            label="Specific locations"
          />
          <RadioGroup.Item value="nowhere" label="Nowhere" />
        </CustomRadioGroup>
        {ipOption === 'specificLocations' && (
          <Container orientation="vertical" gap="xs" ref={ref}>
            <Spacer size="md" />
            <Container
              fillWidth
              alignItems="center"
              justifyContent="space-between"
              isResponsive={false}
            >
              <CUIText weight="semibold" fillWidth size="sm">
                Access list
              </CUIText>
              {hasUpdateIpAddressListPermission && (
                <Container
                  gap="xs"
                  justifyContent="end"
                  fillWidth
                  isResponsive={false}
                >
                  <Button iconLeft="plus" onClick={addItem} type="secondary">
                    Add new IP
                  </Button>
                  <Button
                    iconLeft="plus"
                    onClick={addCurrentIp}
                    type="secondary"
                  >
                    Use current IP
                  </Button>
                </Container>
              )}
            </Container>
            <GridContainer
              gridTemplateColumns={IP_ADDRESS_INPUT_COLUMN}
              alignItems="start"
            >
              <Label>IP address or CIDR</Label>
              <Label>Description</Label>
            </GridContainer>
            {ipList.map((ip, index) => (
              <NetworkInput
                key={`ip-${index}`}
                gridTemplateColumns={IP_ADDRESS_INPUT_COLUMN}
                onChange={onValueChange(index)}
                disabled={!hasUpdateIpAddressListPermission}
                onRemove={removeItem(index)}
                source={ip.source}
                description={ip.description}
                showIpDeleteButton={ipListLength > 1}
                onBlur={onBlur}
                duplicateList={duplicateList}
                invalidIpList={invalidIpList}
              />
            ))}
          </Container>
        )}
        {isChanged && (
          <>
            <Spacer />
            <Button
              fillWidth={false}
              onClick={onSubmit}
              disabled={ipOption === 'specificLocations' && disabledSaveBtn}
            >
              Submit
            </Button>
          </>
        )}
      </Container>
    </Panel>
  );
};
