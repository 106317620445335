import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { shortcutSymbol } from 'src/lib/platform';
import {
  useCreateColumn,
  useEditTable
} from 'src/components/CreateTableView/context';
import { EditColumn, EditColumnId, TableState } from 'shared/src/tableSchema';
import {
  Button,
  Container,
  Icon,
  Table,
  TableHeaderType,
  TableRowType,
  Text,
  Tooltip
} from '@clickhouse/click-ui';
import ColumnName from 'src/components/CreateTableView/ColumnOptions/ColumnName';
import ColumnDefaultValue from 'src/components/CreateTableView/ColumnOptions/ColumnDefaultValue';
import ToggleNullable from 'src/components/CreateTableView/ToggleNullable';
import ColumnType from 'src/components/CreateTableView/ColumnOptions/ColumnType';
import omit from 'lodash/omit';
import styled from 'styled-components';
interface ColumnType extends Omit<TableRowType, 'id'> {
  id: string;
}

const getColumn = (
  table: TableState,
  columnId: EditColumnId
): EditColumn | null => {
  return table.columns[columnId] ?? null;
};

// td one is a temp fix that needs to be fixed in click-ui
const TableContainer = styled(Container)`
  td > div {
    height: auto;
  }
`;
const headers: Array<TableHeaderType> = [
  { label: 'Name' },
  { label: 'Type' },
  { label: 'Default value', width: '150px' },
  {
    label: (
      <>
        Nullable
        <Tooltip>
          <Tooltip.Trigger>
            <Icon name="info-in-circle" size="xs" />
          </Tooltip.Trigger>
          <Tooltip.Content side="top">
            Nullable columns may increase the compressed size of your table and
            should be used sparingly
          </Tooltip.Content>
        </Tooltip>
      </>
    ),
    width: '100px'
  }
];

export function Columns({
  allowDeletion = false
}: {
  allowDeletion?: boolean;
}): ReactElement {
  const { tableState, setTableState } = useEditTable();
  const createColumn = useCreateColumn();
  const containerRef = useRef<HTMLTableElement>(null);
  const numColumns = useRef(tableState.columnIds.length);

  useEffect(() => {
    numColumns.current = tableState.columnIds.length;
  });

  const change =
    (columnId: string) => (changes: Partial<Omit<EditColumn, 'id'>>) => {
      setTableState((oldState) => {
        const col = getColumn(oldState, columnId);
        if (!col) {
          throw new Error("Can't modify deleted column");
        }
        return {
          ...oldState,
          columns: {
            ...oldState.columns,
            [columnId]: {
              ...oldState.columns[columnId],
              ...changes
            }
          }
        };
      });
    };

  const focusLastColumn = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const lastIndex = numColumns.current - 1;
      const inputs = document.querySelectorAll(
        `[data-column-index="${lastIndex}"] input`
      );
      if (inputs && inputs.length > 0) {
        const input = inputs[0];
        if (input instanceof HTMLInputElement) {
          input.focus();
        }
      }
    }
  }, []);

  useHotkeys(
    'meta+enter, ctrl+enter',
    () => {
      createColumn();
      setTimeout(() => {
        focusLastColumn();
      }, 0);
    },
    {
      enableOnFormTags: ['INPUT', 'TEXTAREA']
    },
    [createColumn, focusLastColumn]
  );

  const columns: Array<ColumnType> = tableState.columnIds.flatMap(
    (colId, index) => {
      const column = getColumn(tableState, colId);
      if (column) {
        return {
          id: colId,

          'data-testid': `column-${index}`,
          'data-column-index': index,
          items: [
            {
              label: (
                <ColumnName
                  column={column}
                  change={change(colId)}
                  columns={Object.values(tableState.columns)}
                />
              )
            },
            {
              label: <ColumnType column={column} change={change(colId)} />
            },
            {
              label: (
                <ColumnDefaultValue column={column} change={change(colId)} />
              )
            },
            {
              label: <ToggleNullable column={column} onSubmit={change(colId)} />
            }
          ],
          isDeleted: column.removed
        };
      }
      return [];
    }
  );
  const onDelete = (item: TableRowType): void => {
    const { id, isDeleted } = item as ColumnType;
    setTableState((oldState) => {
      const col = getColumn(oldState, id);
      if (!col) {
        throw new Error("Can't modify deleted column");
      }

      if (allowDeletion || col.allowDeletion) {
        const columnIds = oldState.columnIds.filter((colId) => colId !== id);
        const columns = omit(oldState.columns, id);

        if (columnIds.length === 0) {
          const newColId = uuid();
          return {
            ...oldState,
            columnIds: [newColId],
            columns: {
              [newColId]: {
                originalName: '',
                name: '',
                type: '',
                defaultExpression: '',
                autoFocus: true,
                allowDeletion: true
              }
            }
          };
        }

        return {
          ...oldState,
          columnIds,
          columns
        };
      }
      return {
        ...oldState,
        columns: {
          ...oldState.columns,
          [item.id]: {
            ...col,
            removed: isDeleted
          }
        }
      };
    });
  };

  return (
    <TableContainer orientation="vertical" gap="xs" padding="none">
      <Table
        ref={containerRef}
        headers={headers}
        rows={columns}
        onDelete={onDelete}
        data-testid="test-abcd"
      />
      <div>
        <Button
          type="secondary"
          onClick={createColumn}
          data-testid="add-column-button"
          iconLeft="plus"
        >
          <div>Add column</div>
          <Text color="muted" size="xs">
            {shortcutSymbol}+↵
          </Text>
        </Button>
      </div>
    </TableContainer>
  );
}
