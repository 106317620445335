export const keywords = [
  'ADD',
  'AFTER',
  'ALIAS',
  'ALL',
  'ALTER',
  'AND',
  'ANTI',
  'ANY',
  'ARRAY',
  'AS',
  'ASCENDING',
  'ASOF',
  'AST',
  'ASYNC',
  'ATTACH',
  'BETWEEN',
  'BOTH',
  'BY',
  'CASE',
  'CAST',
  'CHECK',
  'CLEAR',
  'CLUSTER',
  'CODEC',
  'COLLATE',
  'COLUMN',
  'COMMENT',
  'CONSTRAINT',
  'CREATE',
  'CROSS',
  'CUBE',
  'CURRENT',
  'DATABASE',
  'DATABASES',
  'DATE',
  'DAY',
  'DEDUPLICATE',
  'DEFAULT',
  'DELAY',
  'DELETE',
  'DESC',
  'DESCENDING',
  'DESCRIBE',
  'DETACH',
  'DICTIONARIES',
  'DICTIONARY',
  'DISK',
  'DISTINCT',
  'DISTRIBUTED',
  'DROP',
  'ELSE',
  'END',
  'ENGINE',
  'EVENTS',
  'EXISTS',
  'EXPLAIN',
  'EXPRESSION',
  'EXTRACT',
  'FETCHES',
  'FINAL',
  'FIRST',
  'FLUSH',
  'FOLLOWING',
  'FOR',
  'FORMAT',
  'FREEZE',
  'FROM',
  'FULL',
  'FUNCTION',
  'GLOBAL',
  'GRANULARITY',
  'GROUP',
  'HAVING',
  'HIERARCHICAL',
  'HOUR',
  'ID',
  'IF',
  'ILIKE',
  'IN',
  'INDEX',
  'INF',
  'INJECTIVE',
  'INNER',
  'INSERT',
  'INTERVAL',
  'INTO',
  'IS',
  'IS_OBJECT_ID',
  'JOIN',
  'KEY',
  'KILL',
  'LAST',
  'LAYOUT',
  'LEADING',
  'LEFT',
  'LIFETIME',
  'LIKE',
  'LIMIT',
  'LIVE',
  'LOCAL',
  'LOGS',
  'MATERIALIZE',
  'MATERIALIZED',
  'MAX',
  'MERGES',
  'MIN',
  'MINUTE',
  'MODIFY',
  'MONTH',
  'MOVE',
  'MUTATION',
  'NAN_SQL',
  'NO',
  'NOT',
  'NULL_SQL',
  'NULLS',
  'OFFSET',
  'ON',
  'OPTIMIZE',
  'OR',
  'ORDER',
  'OUTER',
  'OUTFILE',
  'OVER',
  'PARTITION',
  'POPULATE',
  'PRECEDING',
  'PREWHERE',
  'PRIMARY',
  'PRIVILEGES',
  'PROJECTION',
  'QUARTER',
  'RANGE',
  'RELOAD',
  'REMOVE',
  'RENAME',
  'REPLACE',
  'REPLICA',
  'REPLICATED',
  'RIGHT',
  'ROLLUP',
  'ROW',
  'ROWS',
  'ROLES',
  'SAMPLE',
  'SECOND',
  'SELECT',
  'SEMI',
  'SENDS',
  'SET',
  'SETTINGS',
  'SHOW',
  'SOURCE',
  'START',
  'STOP',
  'SUBSTRING',
  'SYNC',
  'SYNTAX',
  'SYSTEM',
  'TABLE',
  'TABLES',
  'TEMPORARY',
  'TEST',
  'THEN',
  'TIES',
  'TIMEOUT',
  'TIMESTAMP',
  'TO',
  'TOP',
  'TOTALS',
  'TRAILING',
  'TRIM',
  'TRUNCATE',
  'TTL',
  'TYPE',
  'UNBOUNDED',
  'UNION',
  'UPDATE',
  'USE',
  'USING',
  'UUID',
  'VALUES',
  'VIEW',
  'VOLUME',
  'WATCH',
  'WEEK',
  'WHEN',
  'WHERE',
  'WINDOW',
  'WITH',
  'YEAR',
  'JSON_FALSE',
  'JSON_TRUE',
  'IDENTIFIER',
  'FLOATING_LITERAL',
  'OCTAL_LITERAL',
  'DECIMAL_LITERAL',
  'HEXADECIMAL_LITERAL',
  'STRING_LITERAL',
  'ARROW',
  'ASTERISK',
  'BACKQUOTE',
  'BACKSLASH',
  'BACKUP',
  'COLON',
  'COMMA',
  'CONCAT',
  'DASH',
  'DOT',
  'EQ_DOUBLE',
  'EQ_SINGLE',
  'GE',
  'GT',
  'LBRACE',
  'LBRACKET',
  'LE',
  'LPAREN',
  'LT',
  'NOT_EQ',
  'PERCENT',
  'PLUS',
  'QUERY',
  'QUOTE_DOUBLE',
  'QUOTE_SINGLE',
  'RBRACE',
  'RBRACKET',
  'RPAREN',
  'RESTORE',
  'SEMICOLON',
  'SLASH',
  'UNDERSCORE',
  'MULTI_LINE_COMMENT',
  'SINGLE_LINE_COMMENT',
  'WHITESPACE'
];
