import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo
} from 'react';

import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';

import { useRefCallback } from 'src/lib/hooks';
import {
  EditColumnId,
  emptyTableState,
  EditColumn,
  TableState,
  SortKey
} from 'shared/src/tableSchema';

export interface CreateTableState {
  tableState: TableState;
  previewOpen: boolean;
  queryError: string | undefined;
}

export interface CreateTableProviderState extends CreateTableState {
  setTableState: React.Dispatch<React.SetStateAction<TableState>>;
  setPreviewOpen: (open: boolean) => void;
  setQueryError: (err: string | undefined) => void;
}

export const blankValue: CreateTableProviderState = {
  tableState: emptyTableState(''),
  setTableState: () => null,
  previewOpen: false,
  setPreviewOpen: () => null,
  queryError: undefined,
  setQueryError: () => null
};

const EditTableContext = createContext<CreateTableProviderState>(blankValue);

interface EditTableProviderProps {
  state: CreateTableState;
  onStateChange: (newState: CreateTableState) => void;
  children: ReactNode;
}

export function EditTableProvider({
  children,
  state,
  onStateChange
}: EditTableProviderProps): ReactElement {
  const setTableState = useRefCallback(
    (valOrFunc: TableState | ((old: TableState) => TableState)) => {
      let newValue: TableState;

      if (typeof valOrFunc === 'function') {
        newValue = valOrFunc(state.tableState);
      } else {
        newValue = valOrFunc;
      }

      onStateChange({
        ...state,
        tableState: newValue
      });
    }
  );

  const setPreviewOpen = useRefCallback((previewOpen: boolean) => {
    onStateChange({
      ...state,
      previewOpen
    });
  });

  const setQueryError = useRefCallback((queryError: string | undefined) => {
    onStateChange({
      ...state,
      queryError
    });
  });

  const contextValue = useMemo<CreateTableProviderState>(
    () => ({
      tableState: state.tableState,
      setTableState,
      previewOpen: state.previewOpen,
      setPreviewOpen,
      queryError: state.queryError,
      setQueryError
    }),
    [state, setTableState, setPreviewOpen, setQueryError]
  );

  return (
    <EditTableContext.Provider value={contextValue}>
      {children}
    </EditTableContext.Provider>
  );
}

export function useEditTable(): CreateTableProviderState {
  return useContext(EditTableContext);
}

type ColumnChanges = Partial<Omit<EditColumn, 'id'>>;
type ChangeColumnFunc = (changes: ColumnChanges) => void;

interface UseColumnResult {
  column: EditColumn | null;
  change: ChangeColumnFunc;
  remove: () => void;
  reAdd: () => void;
}

export function getColumn(
  table: TableState,
  columnId: EditColumnId
): EditColumn | null {
  return table.columns[columnId] ?? null;
}

export function useColumn(
  columnId: EditColumnId,
  allowDeletion: boolean
): UseColumnResult {
  const { tableState, setTableState } = useEditTable();
  const column = getColumn(tableState, columnId);

  const change: ChangeColumnFunc = useCallback(
    (changes) => {
      setTableState((oldState) => {
        const col = getColumn(oldState, columnId);
        if (!col) {
          throw new Error("Can't modify deleted column");
        }
        return {
          ...oldState,
          columns: {
            ...oldState.columns,
            [columnId]: {
              ...oldState.columns[columnId],
              ...changes
            }
          }
        };
      });
    },
    [setTableState, columnId]
  );

  const toggleRemoved = useCallback(
    (removed: boolean) => {
      setTableState((oldState) => {
        const col = getColumn(oldState, columnId);
        if (!col) {
          throw new Error("Can't modify deleted column");
        }
        return {
          ...oldState,
          columns: {
            ...oldState.columns,
            [columnId]: {
              ...oldState.columns[columnId],
              removed
            }
          }
        };
      });
    },
    [setTableState, columnId]
  );

  const removePermanently = useCallback(() => {
    setTableState((oldState) => ({
      ...oldState,
      columnIds: oldState.columnIds.filter((id) => id !== columnId),
      columns: omit(oldState.columns, columnId)
    }));
  }, [setTableState, columnId]);

  return {
    column,
    change,
    remove: () => (allowDeletion ? removePermanently() : toggleRemoved(true)),
    reAdd: () => toggleRemoved(false)
  };
}

export type CreateColumnFunction = () => EditColumnId;

export function useCreateColumn(): CreateColumnFunction {
  const { setTableState } = useEditTable();

  return useCallback(() => {
    const colId = uuid();

    setTableState(
      (oldState): TableState => ({
        ...oldState,
        columnIds: [...oldState.columnIds, colId],
        columns: {
          ...oldState.columns,
          [colId]: {
            originalName: '',
            name: '',
            type: '',
            defaultExpression: '',
            autoFocus: true,
            allowDeletion: true
          }
        }
      })
    );

    return colId;
  }, [setTableState]);
}

export function getSortKey(tableState: TableState): SortKey {
  return tableState.orderBy;
}

export function useSortKey(): SortKey {
  const { tableState } = useEditTable();
  return useMemo(() => tableState.orderBy, [tableState.orderBy]);
}
