import { Container, Panel, Separator, Text, Title } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { formatMoment } from 'src/lib/formatters/dateTimeFormatter';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';
import CaseTimeline from 'src/components/SupportCase/CaseTimeline';
import ReadMore from 'src/components/ReadMore/ReadMore';
import CaseDetails from 'src/components/SupportCase/CaseDetails';
import { useSupportCases } from 'src/support/controller/useSupportCases';
import SupportLayout from 'src/layout/SupportLayout';
import { useSupportCase } from 'src/support/controller/useSupportCase';
import { useParams } from 'react-router-dom';

function SupportCasePage(): ReactElement {
  const supportCases = useSupportCases();
  const { caseId } = useParams();
  const { data, isLoading } = useSupportCase(caseId ?? '');

  if (!isLoading && !data) {
    return <NotFoundPage />;
  }

  return (
    <SupportLayout
      supportCases={supportCases.data}
      isLoading={supportCases.isLoading}
      error={supportCases.error}
    >
      {data && (
        <Container orientation="vertical" padding="none" gap="none">
          <Panel
            padding="lg"
            color="muted"
            orientation="vertical"
            fillWidth
            alignItems="start"
          >
            <Title type="h2">{data?.subject}</Title>
            <Text color="muted">{formatMoment(data?.lastModifiedDate)}</Text>
            <Container maxWidth="80%">
              <ReadMore maxLength={256}>{data?.description}</ReadMore>
            </Container>
          </Panel>
          <Separator size="xs" />
          <Container
            padding="lg"
            gap="xl"
            alignItems="start"
            justifyContent="space-between"
          >
            <CaseTimeline events={data.events} caseId={data.id} />
            <CaseDetails {...data} />
          </Container>
        </Container>
      )}
    </SupportLayout>
  );
}

export default SupportCasePage;
