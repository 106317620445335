import { ConfirmationDialog } from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';

interface DeleteDataWarehouseDialogProps {
  open: boolean;
  warehouseName?: string;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

export const DeleteDataWarehouseDialog = ({
  open,
  warehouseName = '',
  onCancel,
  onConfirm: onConfirmProp
}: DeleteDataWarehouseDialogProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const onConfirm = async (): Promise<void> => {
    setLoading(true);
    try {
      await onConfirmProp();
      onCancel();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      data-testid="delete-warehouse-dialog"
      message={`${warehouseName} is being deleted`}
      onCancel={() => {
        onCancel();
      }}
      onConfirm={onConfirm}
      primaryActionLabel="Confirm"
      secondaryActionLabel="Cancel"
      title={`Please confirm you want to delete warehouse: ${warehouseName}`}
      disabled={loading}
    />
  );
};
