import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  BadgeState,
  ButtonGroup,
  CodeBlock,
  Container,
  EllipsisContent,
  Flyout,
  GridContainer,
  InlineCodeBlock,
  Pagination,
  Panel,
  SidebarCollapsibleTitle,
  SidebarNavigationItem,
  Spacer,
  Tabs,
  Text,
  Title
} from '@clickhouse/click-ui';
import {
  QueryType,
  useGetFlyoutDetails,
  useGetFlyoutHistory,
  useGetFlyoutQueryTypeForTimePeriod,
  useGetQueryRunDetails
} from 'src/components/QueryInsights/queries';
import {
  QueryResult,
  ResultData,
  isResultData,
  isResultError
} from 'src/lib/clickhouse/query';
import { formatNumber } from 'src/lib/formatters/numberFormatter';
import {
  SelectableTimePeriod,
  TimeSelect,
  getTimePeriodLabel
} from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import { QueryInsightsChart } from 'src/components/QueryInsights/QueryInsightsChart';
import { QueryCount } from 'src/components/QueryInsights/QueryInsights';
import { formatFullDateForQueryInsights } from 'src/lib/formatters/dateTimeFormatter';
import HorizontalLoadingIcon from 'src/components/icons/HorizontalLoadingIcon';
import { Galaxy } from 'galaxy';
import { Row, RowElement } from 'shared/src/clickhouse';
import { TimePeriod } from '@cp/common/protocol/Metrics';
import CopyButton from 'src/components/CopyButton';
import { formatHumanReadableSize } from 'src/lib/formatters/sizeFormatter';
import styled from 'styled-components';
import { ExpandableCodeBlock } from 'src/components/ExpandableCodeBlock';

interface QueryInsightsFlyoutProps {
  handleClose: () => void;
  isOpen: boolean;
  queryHash: string;
}

const FullWidthText = styled(Text)`
  width: 100%;
`;

const InfoItem = styled(SidebarNavigationItem)`
  cursor: default;
  height: 2.5rem;
`;

const CollapsibleInfoItem = styled(SidebarCollapsibleTitle)`
  height: 2.5rem;
  border-radius: ${({ theme }) =>
    theme.click.sidebar.navigation['item'].radii.all};
  &:hover {
    background-color: ${({ theme }) =>
      theme.click.sidebar['main'].navigation['item'].color.background.hover};
  }
`;

export const QueryInsightsFlyout = ({
  handleClose,
  isOpen,
  queryHash
}: QueryInsightsFlyoutProps): ReactElement => {
  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<SelectableTimePeriod>('LAST_HOUR');

  const [selectedQueryType, setSelectedQueryType] =
    useState<QueryType>('allQueries');

  const handleTimeChange = (timePeriod: SelectableTimePeriod): void => {
    if (timePeriod !== selectedTimePeriod) {
      Galaxy.galaxy().track('queryInsights.flyoutTimePeriod.changed', {
        interaction: 'click',
        timePeriod
      });
      setSelectedTimePeriod(timePeriod);
    }
  };

  const handleSelectQueryType = (queryType: string): void => {
    if (selectedQueryType !== queryType) {
      Galaxy.galaxy().track('queryInsights.flyoutQueryType.changed', {
        interaction: 'click',
        queryType
      });
      setSelectedQueryType(queryType as QueryType);
    }
  };

  const handleOpenChange = (): void => {
    setSelectedTimePeriod('LAST_HOUR');
    handleClose();
    Galaxy.galaxy().track('queryInsights.flyout.closed', {
      interaction: 'click',
      queryHash
    });
  };

  const onTabChange = (value: string) => {
    Galaxy.galaxy().track('queryInsights.flyoutTab.changed', {
      interaction: 'click',
      newTab: value
    });
  };

  return (
    <Flyout open={isOpen} onOpenChange={handleOpenChange}>
      <Flyout.Content size="wide" strategy="fixed">
        <Flyout.Header title="Query information" />
        <Flyout.Body align="top">
          <CommonHeader
            handleTimeChange={handleTimeChange}
            onSelectQueryType={handleSelectQueryType}
            queryHash={queryHash}
            selectedQueryType={selectedQueryType}
            selectedTimePeriod={selectedTimePeriod}
          />
          <Tabs
            defaultValue="details"
            css={{ width: '100%' }}
            onValueChange={onTabChange}
          >
            <Tabs.TriggersList>
              <Tabs.Trigger value="details" css={{ width: '50%' }}>
                Query info
              </Tabs.Trigger>
              <Tabs.Trigger value="history" css={{ width: '50%' }}>
                Query history
              </Tabs.Trigger>
            </Tabs.TriggersList>
            <Tabs.Content value="details">
              {queryHash ? (
                <RunDetails
                  queryHash={queryHash}
                  selectedTimePeriod={selectedTimePeriod}
                />
              ) : (
                <GenericProblem />
              )}
            </Tabs.Content>
            <Tabs.Content value="history">
              <QueryHistory
                queryHash={queryHash}
                selectedQueryType={selectedQueryType}
                selectedTimePeriod={selectedTimePeriod}
              />
            </Tabs.Content>
          </Tabs>
        </Flyout.Body>
      </Flyout.Content>
    </Flyout>
  );
};

interface CommonHeaderProps {
  handleTimeChange: (timePeriod: TimePeriod) => void;
  onSelectQueryType: (queryType: QueryType) => void;
  queryHash: string;
  selectedQueryType: QueryType;
  selectedTimePeriod: SelectableTimePeriod;
}

const CommonHeader = ({
  handleTimeChange,
  onSelectQueryType,
  queryHash,
  selectedQueryType,
  selectedTimePeriod
}: CommonHeaderProps): ReactElement => {
  return (
    <Container orientation="vertical">
      <Container
        maxWidth="100%"
        justifyContent="end"
        orientation="horizontal"
        padding="md"
      >
        <TimeSelect
          onSelect={handleTimeChange}
          selectedPeriod={selectedTimePeriod}
          isCompact={true}
        />
      </Container>
      <FlyoutChart
        onSelectQueryType={onSelectQueryType}
        queryHash={queryHash}
        selectedQueryType={selectedQueryType}
        selectedTimePeriod={selectedTimePeriod}
      />
    </Container>
  );
};

const GenericProblem = (): ReactElement => {
  return (
    <Panel width="100%" orientation="vertical" height="300px">
      <Title type="h3" size="xl">
        There was a problem retrieving results, please try again.
      </Title>
    </Panel>
  );
};

interface NoResultsProps {
  selectedTimePeriod?: SelectableTimePeriod;
}

const NoResults = ({ selectedTimePeriod }: NoResultsProps): ReactElement => {
  return (
    <Container orientation="vertical" gap="xs" padding="lg">
      <Panel width="100%" orientation="vertical" height="300px">
        <Title type="h3" size="xl">
          This query has no results
          {!!selectedTimePeriod &&
            ` for the ${getTimePeriodLabel(selectedTimePeriod).toLowerCase()}`}
        </Title>
      </Panel>
    </Container>
  );
};

interface LoadingProps {
  height?: string;
}

const Loading = ({ height = '300px' }: LoadingProps): ReactElement => {
  return (
    <Panel width="100%" orientation="vertical" height={height}>
      <Title type="h3" size="xl">
        <HorizontalLoadingIcon height="8" />
      </Title>
    </Panel>
  );
};

interface RunDetailsProps {
  queryHash: string;
  selectedTimePeriod: SelectableTimePeriod;
}

const RunDetails = ({
  queryHash,
  selectedTimePeriod
}: RunDetailsProps): ReactElement => {
  const [flyoutDetails, setFlyoutDetails] = useState<ResultData>();
  const [hasQueryError, setHasQueryError] = useState<boolean>(false);
  const getFlyoutDetails = useGetFlyoutDetails(selectedTimePeriod, queryHash);

  useEffect(() => {
    const queryFlyoutDetails = async (): Promise<void> => {
      try {
        const details = await getFlyoutDetails();

        if (isResultData(details)) {
          setFlyoutDetails(details);
          setHasQueryError(false);
        }

        if (isResultError(details)) {
          setHasQueryError(true);
        }
      } catch {
        setFlyoutDetails(undefined);
        setHasQueryError(true);
      }
    };

    if (queryHash) {
      void queryFlyoutDetails();
    }
  }, [queryHash, selectedTimePeriod]);

  if (hasQueryError) {
    return <GenericProblem />;
  }

  if (!flyoutDetails) {
    return <Loading />;
  }

  if (Array.isArray(flyoutDetails.rows) && !flyoutDetails.rows.length) {
    return <NoResults />;
  }

  const [queryRun] = flyoutDetails.rows;
  const [
    queryText,
    runDate,
    successfulRuns,
    failedRuns,
    users,
    _quantiles,
    p50,
    p90,
    p99,
    writtenRows,
    readRows,
    memUsage
  ] = queryRun;

  return (
    <Container orientation="vertical" padding="md">
      <Container fillWidth={true} orientation="horizontal">
        <CodeBlock
          language="sql"
          showLineNumbers={false}
          wrapLines={true}
          className="fs-exclude"
        >
          {`${queryText}`}
        </CodeBlock>
      </Container>
      <Container fillWidth={true} orientation="vertical">
        <Spacer size="lg" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Last run</Text>
          <Text>
            {formatFullDateForQueryInsights(new Date(runDate as string))}
          </Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Successful runs</Text>
          <Text>{formatNumber(parseFloat(successfulRuns as string))}</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Errors</Text>
          <Text>{formatNumber(parseFloat(failedRuns as string))}</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Run by</Text>
          <Text>{JSON.parse(users as string).join(', ')}</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Avg. Written Rows</Text>
          <Text>{writtenRows}</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Avg. Read Rows</Text>
          <Text>{readRows}</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Avg. Memory Usage</Text>
          <Text>{formatHumanReadableSize(parseFloat(memUsage ?? '0'))}</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>p50 latency</Text>
          <Text>{p50}s</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>p90 latency</Text>
          <Text>{p90}s</Text>
        </Container>
        <Spacer size="md" />
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>p99 latency</Text>
          <Text>{p99}s</Text>
        </Container>
        <Spacer size="md" />
      </Container>
    </Container>
  );
};

const queryTypeLabelMap: { [key: string]: string } = {
  allQueries: 'All runs',
  errors: 'Errors'
};

interface FlyoutChartProps {
  onSelectQueryType: (queryType: QueryType) => void;
  queryHash: string;
  selectedQueryType: QueryType;
  selectedTimePeriod: SelectableTimePeriod;
}

const FlyoutChart = ({
  onSelectQueryType,
  queryHash,
  selectedQueryType,
  selectedTimePeriod
}: FlyoutChartProps): ReactElement => {
  const [chartQueries, setChartQueries] = useState<QueryResult>();
  const [hasChartError, setHasChartError] = useState<boolean>(false);

  const [allQueriesCount, setAllQueriesCount] = useState<string>('-');
  const [errorsCount, setErrorsCount] = useState<string>('-');

  const resetResults = (): void => {
    setChartQueries(undefined);
    setAllQueriesCount('-');
    setErrorsCount('-');
  };

  const handleSelectQueryType = (queryType: string): void => {
    if (selectedQueryType !== queryType) {
      onSelectQueryType(queryType as QueryType);
      setChartQueries(undefined);
    }
  };

  const getAllFlyoutQueriesForTimePeriod = useGetFlyoutQueryTypeForTimePeriod(
    selectedTimePeriod,
    'allQueries',
    queryHash
  );

  const getSlowFlyoutQueriesForTimePeriod = useGetFlyoutQueryTypeForTimePeriod(
    selectedTimePeriod,
    'slowQueries',
    queryHash
  );

  const getFlyoutQueryErrorsForTimePeriod = useGetFlyoutQueryTypeForTimePeriod(
    selectedTimePeriod,
    'errors',
    queryHash
  );

  useEffect(() => {
    resetResults();
  }, [queryHash]);

  // TODO: consider refacttoring this to reduce duplication between here and QueryInsightsGraph
  // https://github.com/ClickHouse/control-plane/issues/8283
  useEffect(() => {
    const runAllQueriesForTimePeriod = async (): Promise<void> => {
      try {
        const [allQueries, slowQueries, queryErrors]: Array<QueryResult> =
          await Promise.all([
            getAllFlyoutQueriesForTimePeriod(),
            getSlowFlyoutQueriesForTimePeriod(),
            getFlyoutQueryErrorsForTimePeriod()
          ]);

        const hasResultError =
          isResultError(allQueries) ||
          isResultError(slowQueries) ||
          isResultError(queryErrors);

        if (hasResultError) {
          setHasChartError(true);
          return;
        }

        setHasChartError(false);

        setAllQueriesCount(String(allQueries.rows.reduce(queryCountSum, 0)));
        setErrorsCount(String(queryErrors.rows.reduce(queryCountSum, 0)));

        switch (selectedQueryType) {
          case 'allQueries': {
            setChartQueries(allQueries);
            break;
          }
          case 'slowQueries': {
            setChartQueries(slowQueries);
            break;
          }
          case 'errors': {
            setChartQueries(queryErrors);
            break;
          }
        }
      } catch {
        setHasChartError(true);
      }
    };

    void runAllQueriesForTimePeriod();
  }, [selectedTimePeriod, selectedQueryType, queryHash]);

  if (hasChartError) {
    return <GenericProblem />;
  }

  if (!chartQueries) {
    return <Loading />;
  }

  if (isResultError(chartQueries)) {
    return <GenericProblem />;
  }
  const buttonGroupOptions = [
    {
      label: (
        <Panel
          padding="none"
          gap="none"
          color="transparent"
          alignItems="start"
          orientation="vertical"
        >
          <FullWidthText
            size="md"
            weight="medium"
            align="center"
            data-testid="flyout-all-runs"
          >
            All runs
          </FullWidthText>
          <FullWidthText size="sm" align="center">
            <QueryCount
              allQueriesCount={allQueriesCount}
              queryType="allQueries"
              statCount={allQueriesCount}
            />
          </FullWidthText>
        </Panel>
      ),
      value: 'allQueries'
    },
    {
      label: (
        <Panel
          padding="none"
          gap="none"
          color="transparent"
          alignItems="start"
          orientation="vertical"
        >
          <FullWidthText
            size="md"
            weight="medium"
            align="center"
            data-testid="flyout-errors"
          >
            Errors
          </FullWidthText>
          <FullWidthText size="sm" align="center">
            <QueryCount
              allQueriesCount={allQueriesCount}
              queryType="errors"
              statCount={errorsCount}
            />
          </FullWidthText>
        </Panel>
      ),
      value: 'errors',
      disabled: errorsCount === '0'
    }
  ];
  return (
    <Container
      orientation="vertical"
      padding="md"
      data-testid="query-insights-flyout-container"
    >
      <Container orientation="horizontal" fillWidth={true}>
        <ButtonGroup
          fillWidth={true}
          selected={selectedQueryType}
          onClick={handleSelectQueryType}
          options={buttonGroupOptions}
        />
      </Container>
      <Spacer size="md" />
      {chartQueries.rows.length === 0 ? (
        <NoResults selectedTimePeriod={selectedTimePeriod} />
      ) : (
        <Panel padding="sm" fillWidth>
          <Container fillWidth>
            <QueryInsightsChart
              chartDeployment="flyout"
              isLoading={chartQueries === undefined}
              hasError={hasChartError}
              height={'175px'}
              queries={chartQueries}
              queryType={selectedQueryType}
            />
          </Container>
        </Panel>
      )}
    </Container>
  );
};

const getBadgeState = (status: string): BadgeState => {
  switch (status) {
    case 'cancelled': {
      return 'neutral';
    }
    case 'failed': {
      return 'danger';
    }
    default: {
      return 'success';
    }
  }
};

interface QueryHistoryProps {
  queryHash: string;
  selectedQueryType: QueryType;
  selectedTimePeriod: SelectableTimePeriod;
}

const QueryHistory = ({
  queryHash,
  selectedQueryType,
  selectedTimePeriod
}: QueryHistoryProps): ReactElement => {
  const [historyResults, setHistoryResults] = useState<QueryResult>();
  const [hasResultsError, setHasResultsError] = useState<boolean>(false);

  const getFlyoutHistory = useGetFlyoutHistory(selectedTimePeriod, queryHash);

  useEffect(() => {
    const runFlyoutHistoryQuery = async (): Promise<void> => {
      try {
        const results = await getFlyoutHistory();

        if (isResultError(results)) {
          setHasResultsError(true);
          return;
        }

        setHasResultsError(false);
        setHistoryResults(results);
      } catch {
        setHasResultsError(true);
      }
    };

    void runFlyoutHistoryQuery();
  }, [queryHash, selectedTimePeriod]);

  if (hasResultsError) {
    return <GenericProblem />;
  }

  if (!historyResults) {
    return <Loading />;
  }

  if (isResultError(historyResults)) {
    return <GenericProblem />;
  }

  const filteredHistoryResults =
    selectedQueryType === 'allQueries'
      ? historyResults.rows
      : historyResults.rows.filter((row) => {
          return row[1] === 'failed';
        });

  if (!filteredHistoryResults.length) {
    return <NoResults />;
  }

  return (
    <Container fillWidth={true} orientation="vertical" padding="md">
      <GridContainer fillWidth={true} gridTemplateColumns="50% 25% 25%">
        <Title type="h3" size="sm">
          Time
        </Title>
        <Title type="h3" size="sm">
          Duration
        </Title>
        <Title type="h3" size="sm">
          Status
        </Title>
      </GridContainer>
      <Spacer size="sm" />
      <PaginatedHistoryResults
        filteredHistoryResults={filteredHistoryResults}
        selectedQueryType={selectedQueryType}
      />
    </Container>
  );
};

interface PaginatedHistoryResultsProps {
  filteredHistoryResults: Array<Row>;
  selectedQueryType: QueryType;
}

const resultsPerPage = 20;

const PaginatedHistoryResults = ({
  filteredHistoryResults,
  selectedQueryType
}: PaginatedHistoryResultsProps): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const rowIndex = (currentPage - 1) * resultsPerPage;
  const totalRows = filteredHistoryResults.length;
  const totalPages = Math.ceil(totalRows / resultsPerPage);

  const pageableResults = [];
  for (
    let paginationIndex = rowIndex;
    paginationIndex < rowIndex + resultsPerPage;
    paginationIndex++
  ) {
    const row = filteredHistoryResults[paginationIndex];
    if (row) {
      pageableResults.push(row);
    }
  }

  // This guards against a user filtering the results down to a page that is smaller
  // than the current page. If a user is on page 20 and filters the results to two pages
  // this will correctly put them on the second page.
  useEffect(() => {
    if (currentPage !== 1 && currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }
  }, [totalPages]);

  return (
    <>
      {pageableResults.map((row) => {
        const [_date, _status, queryId] = row;
        return (
          <Fragment key={queryId}>
            <HistoryResult row={row} selectedQueryType={selectedQueryType} />
            <Spacer size="md" />
          </Fragment>
        );
      })}
      {totalRows > resultsPerPage && (
        <Container>
          <Pagination
            currentPage={currentPage}
            onChange={setCurrentPage}
            rowCount={totalRows}
            totalPages={totalPages}
          />
        </Container>
      )}
    </>
  );
};

interface HistoryResultProps {
  row: Row;
  selectedQueryType: QueryType;
}

const HistoryResult = ({
  row,
  selectedQueryType
}: HistoryResultProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, status, queryId, _user, duration] = row;

  const handleExpansionToggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <SidebarCollapsibleTitle
      icon={isOpen ? 'chevron-down' : 'chevron-right'}
      iconDir="start"
      label={
        <GridContainer gridTemplateColumns="50% 20% 30%">
          <Text>{date}</Text> <Text>{duration}s</Text>{' '}
          <Container fillWidth={false}>
            <Badge
              size="sm"
              state={getBadgeState(status as string)}
              text={status}
            />
          </Container>
        </GridContainer>
      }
      onOpenChange={handleExpansionToggle}
      open={isOpen}
    >
      <QueryRunDetails
        queryId={queryId as string}
        selectedQueryType={selectedQueryType}
      />
    </SidebarCollapsibleTitle>
  );
};

interface QueryRunDetailsProps {
  queryId: string;
  selectedQueryType: QueryType;
}

const QueryRunDetails = ({
  queryId,
  selectedQueryType
}: QueryRunDetailsProps): ReactNode => {
  const [queryRunDetails, setQueryRunDetails] = useState<QueryResult>();
  const [hasResultsError, setHasResultsError] = useState<boolean>(false);

  const getQueryRunDetails = useGetQueryRunDetails(queryId);

  useEffect(() => {
    const runGetQueryRunDetails = async (): Promise<void> => {
      try {
        const results = await getQueryRunDetails();
        if (isResultError(results)) {
          setHasResultsError(true);
          setQueryRunDetails(undefined);
          return;
        }

        if (isResultData(results)) {
          setHasResultsError(false);
          setQueryRunDetails(results);
        }
      } catch {
        setHasResultsError(true);
      }
    };

    void runGetQueryRunDetails();
  }, []);

  if (!queryRunDetails) {
    return <Loading height="auto" />;
  }

  if (hasResultsError) {
    return <GenericProblem />;
  }

  if (!isResultData(queryRunDetails)) {
    return <NoResults />;
  }

  const [
    [
      rowsWritten,
      rowsRead,
      _resultRows,
      resultSize,
      memoryUsage,
      queryProfiling,
      querySettings,
      _exceptionCode,
      exception,
      _formattedQuery
    ]
  ] = queryRunDetails.rows;

  return (
    <Container fillWidth={true} orientation="vertical" padding="md">
      {_exceptionCode !== '0' && (
        <>
          <Alert
            title="Error Message"
            text={exception}
            state="danger"
            size="medium"
            showIcon={false}
          />
          <Spacer size="sm" />
        </>
      )}
      <Container fillWidth={true} orientation="vertical" justifyContent="start">
        <ExpandableCodeBlock
          language="sql"
          wrapLines
          showLineNumbers
          contents={`${_formattedQuery}`}
        />
        <Spacer size="sm" />
      </Container>
      <InfoItem
        label={
          <Container
            fillWidth={true}
            orientation="horizontal"
            justifyContent="space-between"
          >
            <Text>Query ID</Text>
            <Container
              orientation="horizontal"
              gap="xs"
              justifyContent="end"
              maxWidth="80%"
            >
              <EllipsisContent>
                <InlineCodeBlock>{queryId}</InlineCodeBlock>
              </EllipsisContent>
              <CopyButton text={queryId} />
            </Container>
          </Container>
        }
      />
      <InfoItem
        label={
          <Container
            fillWidth={true}
            orientation="horizontal"
            justifyContent="space-between"
          >
            <Text>Rows read</Text>
            <Text>{rowsRead}</Text>
          </Container>
        }
      />
      <InfoItem
        label={
          <Container
            fillWidth={true}
            orientation="horizontal"
            justifyContent="space-between"
          >
            <Text>Rows written</Text>
            <Text>{rowsWritten}</Text>
          </Container>
        }
      />
      <InfoItem
        label={
          <Container
            fillWidth={true}
            orientation="horizontal"
            justifyContent="space-between"
          >
            <Text>Result Size</Text>
            <Text>{resultSize}</Text>
          </Container>
        }
      />
      <InfoItem
        label={
          <Container
            fillWidth={true}
            orientation="horizontal"
            justifyContent="space-between"
          >
            <Text>Memory usage</Text>
            <Text>{memoryUsage}</Text>
          </Container>
        }
      />

      {querySettings && <QuerySettings querySettings={querySettings} />}
      {queryProfiling && <QueryProfiling queryProfiling={queryProfiling} />}
    </Container>
  );
};

interface QuerySettingsProps {
  querySettings: RowElement;
}

const QuerySettings = ({ querySettings }: QuerySettingsProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleExpansionToggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <CollapsibleInfoItem
      icon={isOpen ? 'chevron-down' : 'chevron-right'}
      label={
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Settings</Text>
          <Text color="muted">(click to expand)</Text>
        </Container>
      }
      onOpenChange={handleExpansionToggle}
      iconDir="end"
      open={isOpen}
      selected={true}
    >
      <Container padding="md">
        <KeyValueBlock jsonString={querySettings as string} />
      </Container>
    </CollapsibleInfoItem>
  );
};

interface QueryProfilingProps {
  queryProfiling: RowElement;
}

const QueryProfiling = ({
  queryProfiling
}: QueryProfilingProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleExpansionToggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <CollapsibleInfoItem
      icon={isOpen ? 'chevron-down' : 'chevron-right'}
      label={
        <Container orientation="horizontal" justifyContent="space-between">
          <Text>Profile Events</Text>
          <Text color="muted">(click to expand)</Text>
        </Container>
      }
      onOpenChange={handleExpansionToggle}
      open={isOpen}
      iconDir="end"
    >
      <Container padding="md">
        <KeyValueBlock jsonString={queryProfiling as string} />
      </Container>
    </CollapsibleInfoItem>
  );
};

interface KeyValueBlockProps {
  jsonString: string;
}

const KeyValueBlock = ({ jsonString }: KeyValueBlockProps): ReactNode => {
  try {
    const parsedObject = JSON.parse(jsonString) as {
      [key: string]: string;
    };

    const keyValuesWitNewlines = Object.entries(parsedObject)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');

    return <CodeBlock wrapLines={true}>{keyValuesWitNewlines}</CodeBlock>;
  } catch {
    return null;
  }
};

const queryCountSum = (total: number, [, queryCount]: Row): number => {
  return parseInt(queryCount as string, 10) + total;
};
