// TODO: move this component out of primitives
import { ReactNode, useMemo, HTMLAttributes } from 'react';
import { useIsCurrentInstanceAwakeStatus } from 'src/instance/instanceController';

import { randomSuggestion } from 'src/components/primitives/lib/ClickhouseLoading/loadingSuggestions';
import {
  loadingStyle,
  loadingIconStyle,
  containerStyle,
  suggestion
} from 'src/components/primitives/lib/ClickhouseLoading/styles';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  isFullScreen?: boolean;
  isWaking?: boolean;
  showTip?: boolean;
}

export type platformOptions = 'mac' | 'windows' | 'linux';

const ClickhouseLoading = ({
  children,
  isFullScreen,
  isWaking: isWakingProp,
  showTip = false,
  ...props
}: LoadingProps): JSX.Element => {
  const { isAwaking } = useIsCurrentInstanceAwakeStatus();

  const loadingSuggestion = useMemo(() => {
    if (!showTip) {
      return null;
    }
    let platform: platformOptions = 'linux';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
      platform = 'windows';
    } else if (window.navigator.appVersion.indexOf('Mac') !== -1) {
      platform = 'mac';
    }
    return randomSuggestion(platform);
  }, [showTip]);

  return (
    <div css={loadingStyle} data-fullscreen={isFullScreen} {...props}>
      <div css={containerStyle}>
        <img
          css={loadingIconStyle}
          src="/images/ClickHouse-Loading.gif"
          alt="ClickHouse Loading"
          loading="lazy"
        />
        <div css={suggestion}>
          {children}
          {!children && showTip && loadingSuggestion && (
            <div>Tip: {loadingSuggestion}</div>
          )}
          {(isAwaking || isWakingProp) && (
            <div>Waking your service. This may take a minute...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClickhouseLoading;
