import {
  Container,
  Icon,
  Spacer,
  Text as CUIText,
  Title
} from '@clickhouse/click-ui';
import { ReactElement } from 'react';

export const ByocProvisioning = (): ReactElement => {
  return (
    <Container
      orientation="vertical"
      alignItems="center"
      gap="xs"
      padding="xl"
      data-testid="no-services-message"
    >
      <Spacer size="xxl" />
      <Container
        orientation="vertical"
        padding="lg"
        alignItems="center"
        maxWidth="415px"
        gap="xs"
      >
        <Icon name="dots-horizontal" height="80px" width="80px" />
        <Title type="h1" size="md">
          You cannot create services at the moment
        </Title>
        <Spacer size="sm" />
        <CUIText align="center">
          Finishing your AWS ClickHouse infrastructure set up
        </CUIText>
        <CUIText align="center">
          This process might take up to 40 minutes.
        </CUIText>
      </Container>
    </Container>
  );
};
