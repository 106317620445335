import { ByocCloudProviderConfig } from '@cp/common/protocol/Organization';
import { RegionId } from '@cp/common/protocol/Region';
import { assertTruthy } from '@cp/common/utils/Assert';

/** Find byocId in byoc config for provider matching given accountId and regionId */
export function getByocIdForAccountAndRegion(
  byocProviderConfig: ByocCloudProviderConfig,
  accountId: string,
  regionId: RegionId
): string {
  const accountConfig = byocProviderConfig[accountId];
  assertTruthy(accountConfig, 'Cannot find BYOC account configuration');
  const regionConfig = accountConfig.regions[regionId];
  assertTruthy(regionConfig, 'Cannot find region in BYOC account configuration');
  return regionConfig.byocId;
}
