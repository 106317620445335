import { MouseEvent, ReactElement } from 'react';
import { ContextMenu, FileTabStatusType, FileTabs } from '@clickhouse/click-ui';
import { css } from '@emotion/react';
import { InteractionType } from 'src/lib/logger';
import { Tab as TabType } from 'src/state/tabs/types';
import { StatusType } from 'src/components/ConsoleTopBar/types';
import { useUpdateTab } from 'src/state/tabs';

const contextContentStyle = css({
  minWidth: 'var(--radix-dropdown-menu-trigger-width)'
});

interface Props {
  index: number;
  log: (
    type: TabType['type'] | 'home',
    event: string,
    interaction?: InteractionType
  ) => void;
  duplicateTab: (index: number) => void;
  closeCurrentTab: (index: number) => void;
  closeAllTabs: () => void;
  closeAllTabsToRight: (index: number) => void;
  closeAllOtherTabs: (index: number) => void;
  status: StatusType;
  text: string;
  selected?: boolean;
}

function getFileStatusFromStatus(status: StatusType): FileTabStatusType {
  if (status === 'finished') {
    return 'success';
  }
  if (status === 'error') {
    return 'danger';
  }
  if (status === 'unsaved') {
    return 'warning';
  }
  return 'default';
}

function Tab({
  id,
  index,
  selected,
  type,
  log,
  duplicateTab: duplicateTabProp,
  closeCurrentTab,
  closeAllTabs: closeAllTabsProp,
  closeAllTabsToRight: closeAllTabsToRightProp,
  closeAllOtherTabs,
  preview,
  status,
  text
}: Props & TabType): ReactElement {
  const updateTab = useUpdateTab();

  let icon = undefined;
  if (type === 'query') {
    icon = status === 'running' ? 'loading-animated' : 'query';
  } else if (['createTable', 'table'].includes(type)) {
    icon = 'table';
  }
  const duplicateTab = (): void => {
    duplicateTabProp(index);
    log(type, 'contextMenuDuplicate');
  };

  const updatePreview = (): void => {
    updateTab(id, {
      preview: false
    });
  };

  const closeTab = (): void => {
    log(type, 'contextMenuClose');
    closeCurrentTab(index);
  };
  const closeAllTabs = (): void => {
    log(type, 'contextMenuCloseAll');
    closeAllTabsProp();
  };
  const closeTabsToRight = (): void => {
    log(type, 'contextMenuCloseToRight');
    closeAllTabsToRightProp(index);
  };
  const closeOtherTabs = (): void => {
    log(type, 'contextMenuCloseOthers');
    closeAllOtherTabs(index);
  };

  return (
    <ContextMenu
      onOpenChange={(open: boolean): void => {
        if (!open) {
          log(type, 'contextMenuClose');
        }
      }}
    >
      <ContextMenu.Trigger
        onDoubleClick={updatePreview}
        onContextMenu={(e: MouseEvent<HTMLDivElement>): void => {
          const position = (e.target as HTMLElement)
            .closest('[data-tab-element]')
            ?.getBoundingClientRect();
          if (position) {
            e.clientX = position.left;
            e.clientY = position.bottom;
          }
        }}
        data-testid={`tab-${selected ? 'selected' : 'inactive'}`}
      >
        <FileTabs.Tab
          index={index}
          icon={icon}
          preview={preview}
          text={text}
          data-tab-element=""
          data-testid="tabTitle"
          status={getFileStatusFromStatus(status)}
        />
      </ContextMenu.Trigger>
      <ContextMenu.Content
        side="bottom"
        align="start"
        css={contextContentStyle}
      >
        {type === 'query' && (
          <ContextMenu.Item onClick={duplicateTab}>
            Duplicate tab
          </ContextMenu.Item>
        )}
        <ContextMenu.Item onClick={closeTab}>Close tab</ContextMenu.Item>
        <ContextMenu.Item onClick={closeAllTabs}>
          Close all tabs
        </ContextMenu.Item>
        <ContextMenu.Item onClick={closeTabsToRight}>
          Close tabs to the right
        </ContextMenu.Item>
        <ContextMenu.Item onClick={closeOtherTabs}>
          Close other tabs
        </ContextMenu.Item>
      </ContextMenu.Content>
    </ContextMenu>
  );
}

export default Tab;
