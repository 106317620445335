import { css, Theme, SerializedStyles } from '@emotion/react';

const resizerDragMargin = 3;
const resizeHandlerSize = 6;

export const resizerHandlerDragArea = (isDragging: boolean) => css`
  position: absolute;
  top: 0;
  right: ${-resizerDragMargin}px;
  bottom: 0;
  opacity: ${isDragging ? 1 : 0};
  padding: 0 ${resizerDragMargin}px;
  width: ${resizeHandlerSize + 2 * resizerDragMargin}px;
  cursor: col-resize;
  &:hover {
    opacity: 1;
  }
`;

export const resizerHandler = (theme: Theme): SerializedStyles => css`
  width: ${resizeHandlerSize}px;
  height: 100%;
  background: ${theme.global.color.accent.default};
`;

export const tableHeaderStyle = css`
  width: 100%;
  display: flex !important;
  align-items: center;

  &:hover .hoverHide,
  &:focus-within .hoverHide {
    display: none;
  }

  &:not(:hover):not(:focus-within) .hoverShow {
    display: none;
  }
`;

export const columnNameStyle = css`
  display: block;
  flex: 1 1 100%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  &[data-header='true'] {
    height: 100%;
  }
`;

export const sortIcon = (theme: Theme) => css`
  color: ${theme.global.color.accent.default};
  height: 12px;
  width: 12px;
  display: flex;
  margin-left: 10px;
  &[data-asc='true'] {
    transform: rotate(180deg);
  }
`;
