import { ReactElement, useRef } from 'react';
import { CUIThemeType, Panel, Title, useCUITheme } from '@clickhouse/click-ui';
import {
  QueryResult,
  ResultData,
  isResultData
} from 'src/lib/clickhouse/query';
import {
  DEFAULT_COLOR_FOR_METRICS,
  getXAxisDateTimeOptions,
  calculateTickPosition
} from 'src/components/primitives/lib/Charts/Highcharts/Formatters';
import { TimePeriodLabel } from 'src/components/primitives/lib/TimeSelect/TimeSelect';
import HorizontalLoadingIcon from 'src/components/icons/HorizontalLoadingIcon';
import {
  SelectableLatency,
  getSelectedLatencyIndex
} from 'src/components/QueryInsights/QueryInsights';
import {
  QueryType,
  getLabelForQueryType
} from 'src/components/QueryInsights/queries';
import {
  AxisLabelsFormatterContext,
  TooltipFormatter,
  TooltipFormmatterArgs,
  XYChart,
  XYChartProps,
  XYChartValue,
  XYSeriesDescriptor
} from '@clickhouse/viz-house';
import { formatDataSize } from 'src/lib/formatters/numberFormatter';

const tooltipDateFormat = (timePeriod?: TimePeriodLabel): string => {
  // See https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat.
  switch (timePeriod) {
    case 'Last hour': {
      return '%B %e, %l:%M:%S %p'; // Up to minutes: April 24, 4:20:35 PM
    }
    case 'Last 24 hours': {
      return '%B %e, %l:%M %p'; // Up to minutes: April 24, 4:20 PM.
    }
    case 'Last week':
    case 'Last month': {
      return '%B %e, %l %p'; // Up to hours: April 24, 4 PM.
    }
    case 'Last year': {
      return '%B %e, %Y'; // Up to days: April 24, 2022.
    }
  }
  return '%Y-%m-%d';
};

interface LoadingProps {
  height?: string;
}

const Loading = ({ height = '300px' }: LoadingProps): ReactElement => {
  return (
    <Panel width="100%" orientation="vertical" height={height}>
      <Title type="h4" size="lg">
        <HorizontalLoadingIcon height="8" />
      </Title>
    </Panel>
  );
};

type ChartDeployment = 'main' | 'flyout';

interface QueryInsightsChartProps {
  chartDeployment: ChartDeployment;
  isLoading: boolean;
  hasError: boolean;
  height?: string;
  queries?: QueryResult;
  queryType: QueryType;
  timePeriodLabel?: TimePeriodLabel;
  selectedLatency?: SelectableLatency;
}

export const QueryInsightsChart = ({
  chartDeployment,
  isLoading = false,
  hasError = false,
  height = '300px',
  queries,
  queryType,
  timePeriodLabel,
  selectedLatency
}: QueryInsightsChartProps): ReactElement => {
  const cuiTheme = useCUITheme();

  if (hasError) {
    return (
      <Panel width="100%" orientation="vertical" height={height}>
        <Title type="h4" size="lg">
          There was a problem retrieving{' '}
          {getLabelForQueryType(queryType).toLowerCase()}, please try again.
        </Title>
      </Panel>
    );
  }

  if (isLoading) {
    return <Loading height={height} />;
  }

  if (isResultData(queries)) {
    if (queries.rows.length === 0) {
      return (
        <Panel width="100%" orientation="vertical" height={height}>
          <Title size="md" type="h3">
            0 {getLabelForQueryType(queryType).toLowerCase()} results
          </Title>
        </Panel>
      );
    }

    const series = generateSeries(
      queries,
      queryType,
      cuiTheme,
      selectedLatency
    );

    const overrides = generateHighChartsOverrides(
      cuiTheme,
      series,
      chartDeployment,
      queryType,
      timePeriodLabel
    );

    const title = queryType ? `${getLabelForQueryType(queryType)}` : '';

    const tooltipFormatter: TooltipFormatter = ({
      yValue
    }: TooltipFormmatterArgs): string => {
      return formatDataSize(yValue || 0, 'short-scale-letter');
    };

    return (
      <XYChart
        height={height}
        tooltipFormatter={tooltipFormatter}
        highChartsPropsOverrides={overrides}
        series={series}
        title={title}
        width="100%"
        yAxis={{ title }}
      />
    );
  }

  return <Loading />;
};

const getBasicSeriesData = (
  queries: ResultData
): XYSeriesDescriptor['values'] => {
  return queries.rows.map((row): XYChartValue => {
    return {
      x: new Date(row[0] as string).getTime(),
      y: parseFloat(row[1] as string)
    };
  });
};

const generateSeries = (
  queries: ResultData,
  queryType: QueryType,
  cuiTheme: CUIThemeType,
  selectedLatency?: SelectableLatency
): Array<XYSeriesDescriptor> => {
  const errorLineColor = cuiTheme.global.color.feedback.danger.foreground;
  if (selectedLatency) {
    const insertValues: Array<XYChartValue> = [];
    const otherValues: Array<XYChartValue> = [];
    const selectValues: Array<XYChartValue> = [];

    if (queryType === 'errors') {
      const seriesData = getBasicSeriesData(queries);

      const series: XYSeriesDescriptor = {
        color: errorLineColor,
        name: 'Queries',
        type: 'line',
        values: seriesData
      };

      return [series];
    }

    if (queryType === 'slowQueries') {
      queries.rows.forEach((row): void => {
        const [date, kind, countRaw] = row;
        const count = JSON.parse(countRaw as string) as Array<string>;
        const series = {
          x: new Date(date as string).getTime(),
          y:
            count.length > 0
              ? parseFloat(count[getSelectedLatencyIndex(selectedLatency)])
              : 0
        };

        if (kind === 'Insert') {
          insertValues.push(series);
        }
        if (kind === 'Other') {
          otherValues.push(series);
        }
        if (kind === 'Select') {
          selectValues.push(series);
        }
      });
    }

    if (queryType === 'allQueries') {
      queries.rows.forEach((row): void => {
        const [date, kind, count] = row;
        const series = {
          x: new Date(date as string).getTime(),
          y: parseFloat(count as string)
        };

        if (kind === 'Insert') {
          insertValues.push(series);
        }
        if (kind === 'Other') {
          otherValues.push(series);
        }
        if (kind === 'Select') {
          selectValues.push(series);
        }
      });
    }

    const insertSeries: XYSeriesDescriptor = {
      color: cuiTheme.global.color.chart.bars.blue,
      name: 'Insert',
      type: 'line',
      values: insertValues
    };

    const otherSeries: XYSeriesDescriptor = {
      color: cuiTheme.global.color.chart.bars.fuchsia,
      name: 'Other',
      type: 'line',
      values: otherValues
    };

    const selectSeries: XYSeriesDescriptor = {
      color: DEFAULT_COLOR_FOR_METRICS,
      name: 'Select',
      type: 'line',
      values: selectValues
    };

    return [selectSeries, insertSeries, otherSeries];
  }

  const seriesData = getBasicSeriesData(queries);
  const series: XYSeriesDescriptor = {
    color: queryType === 'errors' ? errorLineColor : DEFAULT_COLOR_FOR_METRICS,
    name: 'Queries',
    type: 'line',
    values: seriesData
  };

  return [series];
};

const generateHighChartsOverrides = (
  cuiTheme: CUIThemeType,
  series: Array<XYSeriesDescriptor>,
  chartDeployment: ChartDeployment,
  queryType?: QueryType,
  timePeriodLabel?: TimePeriodLabel
): XYChartProps['highChartsPropsOverrides'] => {
  return {
    legend: {
      enabled: queryType !== 'errors' && chartDeployment === 'flyout',
      itemHoverStyle: { color: cuiTheme.global.color.text.default },
      itemStyle: { color: cuiTheme.global.color.text.muted }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    time: {
      useUTC: false
    },
    tooltip: {
      xDateFormat: tooltipDateFormat(timePeriodLabel)
    },
    xAxis: {
      ...getXAxisDateTimeOptions(),
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      labels: {
        formatter: function (this: AxisLabelsFormatterContext): string {
          if (queryType === 'slowQueries') {
            return `${this.value} ms`;
          }
          return this.value as string;
        }
      },
      tickPositioner: function (): Array<number> {
        let max = 0;
        series.forEach((dataSeries: XYSeriesDescriptor) => {
          max =
            dataSeries.values.reduce((maxY: number, values): number => {
              const { y } = values as XYChartValue;

              return Math.max(maxY, y);
            }, max) ?? 0;
        });

        return calculateTickPosition(max);
      }
    }
  };
};
