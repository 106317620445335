import { useCallback, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { DataWarehouseWithInstances, useDataWarehouseStateManager } from 'src/dataWarehouses/dataWarehousesState';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { createToast } from '@clickhouse/click-ui';
import { Galaxy } from 'galaxy';
import { useInstanceStateManager } from 'src/instance/instanceState';
import { DataWarehouse } from '@cp/common/protocol/DataWarehouse';
import { Instance } from '@cp/common/protocol/Instance';

export interface UseDataWarehousesWithInstancesType {
  warehouses: Array<DataWarehouseWithInstances>;
  isLoading: boolean;
  renameWarehouse: (props: { dataWarehouseId: string; name: string }) => Promise<void>;
  deleteWarehouse: (props: { dataWarehouseId: string }) => Promise<void>;
  onFastUpgrade: (props: { dataWarehouseId: string }) => Promise<void>;
}

function useInstancesByDataWarehouseId(): Record<string, Array<Instance>> {
  const { instances } = useInstanceStateManager();
  return useMemo(() => groupBy(Object.values(instances), 'dataWarehouseId'), [instances]);
}

function createDataWarehouseWithInstances(
  dataWarehouse: DataWarehouse,
  instancesByDataWarehouseId: Record<string, Array<Instance>>
): DataWarehouseWithInstances {
  const warehouseInstances = instancesByDataWarehouseId[dataWarehouse.id] ?? [];
  const orderedInstances = orderBy(warehouseInstances, ['isPrimary', 'creationDate'], ['desc', 'desc']);
  return { ...dataWarehouse, instances: orderedInstances };
}

export const useCurrentOrganizationDataWarehousesWithInstances = (): UseDataWarehousesWithInstancesType => {
  const { warehouses, isDataWarehouseStateInitialized } = useDataWarehouseStateManager();
  const currentOrganization = useCurrentOrganizationOrThrow();
  const apiClient = useApiClient();
  const instancesByDataWarehouseId = useInstancesByDataWarehouseId();

  const renameWarehouse = useCallback(
    async ({ dataWarehouseId, name }: { dataWarehouseId: string; name: string }) => {
      try {
        Galaxy.galaxy().track('WarehousePage.warehouseDropdown.onRenameWarehouse', {
          interaction: 'trigger',
          name
        });

        await apiClient.warehouse.rename({
          organizationId: currentOrganization.id,
          dataWarehouseId,
          name
        });

        createToast({
          type: 'success',
          title: 'Warehouse renamed successfully ',
          description: `Warehoue has been renamed to ${name}`
        });
      } catch (e) {
        createToast({
          type: 'danger',
          title: 'Error',
          description: 'Unable to rename warehouse'
        });
      }
    },
    [apiClient.warehouse, currentOrganization.id]
  );

  const deleteWarehouse = useCallback(
    async ({ dataWarehouseId }: { dataWarehouseId: string }) => {
      try {
        Galaxy.galaxy().track('WarehousePage.warehouseDropdown.onDeleteWarehouse', {
          interaction: 'trigger'
        });

        await apiClient.warehouse.delete({ organizationId: currentOrganization.id, dataWarehouseId });
        createToast({
          type: 'success',
          title: 'Warehouse deleted successfully ',
          description: 'Warehoue has been deleted successfully'
        });
      } catch (e) {
        createToast({
          type: 'danger',
          title: 'Error',
          description: 'Unable to rename warehouse'
        });
      }
    },
    [apiClient.warehouse, currentOrganization.id]
  );

  const onFastUpgrade = useCallback(
    async ({ dataWarehouseId }: { dataWarehouseId: string }) => {
      try {
        Galaxy.galaxy().track('WarehousePage.warehouseDropdown.onFastUpgrade', {
          interaction: 'trigger'
        });

        await apiClient.warehouse.fastUpgrade({
          organizationId: currentOrganization.id,
          dataWarehouseId
        });
        createToast({
          type: 'success',
          title: 'Warehouse upgraded successfully ',
          description: 'Warehoue has been upgraded successfully'
        });
      } catch (e) {
        createToast({
          type: 'danger',
          title: 'Error',
          description: 'Unable to upgrade warehouse'
        });
      }
    },
    [apiClient.warehouse, currentOrganization.id]
  );
  const orgWarehouseList = useMemo(
    () => Object.values(warehouses).filter((warehouse) => warehouse.organizationId == currentOrganization.id),
    [warehouses, currentOrganization.id]
  );

  const orgWarehouseWithInstancesList: Array<DataWarehouseWithInstances> = useMemo(
    () => orgWarehouseList.map((warehouse) => createDataWarehouseWithInstances(warehouse, instancesByDataWarehouseId)),
    [orgWarehouseList, instancesByDataWarehouseId]
  );

  return {
    warehouses: orgWarehouseWithInstancesList,
    isLoading: !isDataWarehouseStateInitialized,
    renameWarehouse,
    deleteWarehouse,
    onFastUpgrade
  };
};

export function useDataWarehouseWithInstances(dataWarehouseId: string): DataWarehouseWithInstances | undefined {
  const instancesByDataWarehouseId = useInstancesByDataWarehouseId();
  const { warehouses } = useDataWarehouseStateManager();
  const warehouse: DataWarehouse | undefined = warehouses[dataWarehouseId];
  return useMemo(
    () => (warehouse ? createDataWarehouseWithInstances(warehouse, instancesByDataWarehouseId) : undefined),
    [warehouse, instancesByDataWarehouseId]
  );
}
