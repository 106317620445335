import { Button, SplitButton, Text, Tooltip } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import { LogFn } from 'src/lib/logger';
import { getModifierKeyPrefix } from 'src/lib/modiferKeyPrefix';
import { RunningQueryStatusName } from 'src/lib/query/runningQueryTypes';
import { flex, gapSm, spaceBetween } from 'src/lib/utility-styles';
import { minWidthStyle } from 'src/components/QueryView/Toolbar/style';

interface RunButtonMenuProps {
  status: RunningQueryStatusName;
  runQuery: (runAll?: boolean) => void;
  runCurrentStatement: () => void;
  cancelQuery: () => void;
  log: LogFn;
  disabled: boolean;
}

const RunButtonMenu = ({
  runQuery,
  runCurrentStatement,
  cancelQuery,
  status,
  log,
  disabled
}: RunButtonMenuProps): ReactElement => {
  if (status === 'running') {
    const onClick = (): void => {
      cancelQuery();
      log('cancelButton', 'click');
    };
    return (
      <div css={minWidthStyle(90)}>
        <Button
          data-testid="cancelQueryBtn"
          onClick={onClick}
          fillWidth
          iconLeft="cross"
        >
          Cancel
        </Button>
      </div>
    );
  }

  const onToggleOptionsButton = (open: boolean): void => {
    log(`runOptions${open ? 'Open' : 'Blur'}`, 'trigger');
  };

  const modifierKeyPrefix = getModifierKeyPrefix();

  const options = [
    {
      label: (
        <div css={[flex, spaceBetween, gapSm]}>
          <Text color="default">All Commands</Text>
          <Text color="muted">{modifierKeyPrefix} + ⏎</Text>
        </div>
      ),
      onClick: (): void => {
        runQuery(true);
        log('runOptionsAllCommandsRun', 'click');
      }
    },
    {
      label: (
        <div css={[flex, spaceBetween, gapSm]}>
          <Text color="default">At Cursor</Text>
          <Text color="muted">{modifierKeyPrefix} + ⇧ + ⏎</Text>
        </div>
      ),
      onClick: (): void => {
        runCurrentStatement();
        log('runOptionsAtCursorRun', 'click');
      }
    }
  ];

  return (
    <div css={minWidthStyle(90)}>
      <Tooltip>
        <Tooltip.Trigger>
          <SplitButton
            onClick={(): void => {
              runQuery();
              log('runButtonClick', 'click');
            }}
            menu={options}
            onOpenChange={onToggleOptionsButton}
            disabled={disabled}
            icon="play"
            data-testid="runQueryBtn"
          >
            Run
          </SplitButton>
        </Tooltip.Trigger>
        <Tooltip.Content>Run Query</Tooltip.Content>
      </Tooltip>
    </div>
  );
};

export default RunButtonMenu;
