import { InstanceTier } from '@cp/common/protocol/Instance';
import { RegionId } from '@cp/common/protocol/Region';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { ReactElement, useEffect } from 'react';
import { useInstanceAutoScalingController } from 'src/instance/instanceAutoScalingController';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useServiceFeatureFlag } from 'src/state/service';
import { Section } from 'src/lib/pageElements';
import {
  IdlingSubsection,
  MemorySubsection
} from 'src/pages/SettingsPage/ScalingControlsSection';
import { Spacer } from '@clickhouse/click-ui';
import { isOrgByoc } from '@cp/common/utils/OrganizationUtils';

const NewServiceScalingControlSection = ({
  selectedInstanceTier,
  selectedRegion
}: {
  selectedInstanceTier: InstanceTier;
  selectedRegion: RegionId;
}): ReactElement | null => {
  const org = useCurrentOrganizationOrThrow();
  const isPayingOrg = isPayingStatus(org.billingStatus);
  const isByocOrg = isOrgByoc(org);
  const isCustomValuesFeatureEnabled = useServiceFeatureFlag(
    'FT_CUSTOM_AUTOSCALE_VALUES'
  );
  const { fetchNewInstanceAutoScalingSettings } =
    useInstanceAutoScalingController();

  const temporaryInstanceId =
    selectedInstanceTier === 'Production'
      ? 'newInstanceProd'
      : 'newInstanceDev';

  useEffect(() => {
    void fetchNewInstanceAutoScalingSettings(
      'newInstanceProd',
      'Production',
      [],
      selectedRegion,
      isCustomValuesFeatureEnabled,
      isPayingOrg,
      isByocOrg,
      false,
      undefined
    );
    void fetchNewInstanceAutoScalingSettings(
      'newInstanceDev',
      'Development',
      [],
      selectedRegion,
      isCustomValuesFeatureEnabled,
      isPayingOrg,
      isByocOrg,
      false,
      undefined
    );
  }, [
    selectedRegion,
    fetchNewInstanceAutoScalingSettings,
    isCustomValuesFeatureEnabled,
    isPayingOrg,
    isByocOrg
  ]);

  return (
    <Section>
      <MemorySubsection
        instanceId={temporaryInstanceId}
        instanceTier={selectedInstanceTier}
      />
      <Spacer size="lg" />
      <IdlingSubsection instanceId={temporaryInstanceId} />
      <Spacer />
    </Section>
  );
};

export default NewServiceScalingControlSection;
