import { createToast, Text } from '@clickhouse/click-ui';
import { USER_NOT_FOUND } from '@cp/common/protocol/Account';
import {
  Address,
  OrganizationDetailsToUpdate
} from '@cp/common/protocol/Billing';
import {
  ORG_WITH_SAME_NAME_ALREADY_EXISTS,
  ORGANIZATION_LIMIT_REACHED
} from '@cp/common/protocol/Organization';
import { TaxStatusType } from '@cp/common/protocol/Stripe';
import { ReactElement, useState } from 'react';
import { useHandleTackleSubscription } from 'src/billing/controllers/useHandleTackleSubscription';
import {
  useFormState,
  useNumEmployees,
  useWebsiteUrl
} from 'src/components/BillingCardModal/FormState';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { errorMessage } from 'src/lib/errors/errorMessage';
import { usePendingUserActionController } from 'src/lib/pendingUserActions/usePendingUserActionController';
import {
  useCurrentOrganizationOrThrow,
  useOrganizationStateManager
} from 'src/organization/organizationState';

import MarketplaceOnboardingPage, {
  PageType
} from 'src/pages/MarketplaceOnboarding/MarketplaceOnboardingPage';

const MarketplaceOnboardingView = (): ReactElement => {
  const currentOrganization = useCurrentOrganizationOrThrow();
  const [formDetails] = useFormState();
  const { numEmployees } = useNumEmployees();
  const { websiteUrl } = useWebsiteUrl();
  const { removeTackleSubscriptionAction } = usePendingUserActionController();
  const { handleTackleSubscription } = useHandleTackleSubscription();
  const [isLoading, setIsLoading] = useState(false);
  const { switchCurrentOrganization } = useOrganizationStateManager();

  const showErrorToast = (message: string): void => {
    createToast({
      type: 'danger',
      title: 'Error',
      description: <Text data-testid="tackle-error-toast">{message}</Text>
    });
  };

  const handleTackleSubscriptionError = (
    serverErrorMessage: string | undefined
  ): void => {
    switch (serverErrorMessage) {
      case 'INVALID_PRODUCT_ID':
        removeTackleSubscriptionAction();
        showErrorToast(
          'Invalid product. Please restart the flow on the marketplace'
        );
        navigateTo('/');
        return;
      case 'INVALID_NAME':
        showErrorToast(
          'User name does not adhere to requirements. Try removing special characters'
        );
        return;
      case ORGANIZATION_LIMIT_REACHED:
      case USER_NOT_FOUND:
      case ORG_WITH_SAME_NAME_ALREADY_EXISTS:
        showErrorToast(
          'An error has occurred. Please try again or contact support'
        );
        return;
      case 'INVALID_ADDRESS':
      case 'BILLING_ADDRESS_IS_REQUIRED':
        showErrorToast('An address is required');
        return;
      case 'INVALID_COMPANY_NAME':
        showErrorToast('Company name is required');
        return;
      case 'INVALID_WEBSITE_URL':
        showErrorToast('Website URL is required');
        return;
      case 'INVALID_COMPANY_SIZE':
        showErrorToast('Company size is required');
        return;
      case 'ORGANIZATION_IS_A_BUSINESS_IS_REQUIRED':
        showErrorToast('Please specify if this is a business or individual');
        return;
      default:
        // These error messages arrive in the form of `SUBSCRIPTION_TOKEN_IS_ALREADY_IN_USE: ${orgWithTheSameSubscription.id}`
        if (
          serverErrorMessage?.startsWith('SUBSCRIPTION_TOKEN_IS_ALREADY_IN_USE')
        ) {
          removeTackleSubscriptionAction();
          showErrorToast(
            'This AWS account is already associated to another organization. Please restart the flow on the marketplace or contact support'
          );
          navigateTo('/');
          return;
        }
        if (serverErrorMessage?.startsWith('INVALID_PRODUCT_ID')) {
          removeTackleSubscriptionAction();
          showErrorToast(
            'Invalid product. Please restart the flow on the marketplace'
          );
          navigateTo('/');
          return;
        }
    }
  };

  const onCompleteSignUp = async (pageType: PageType): Promise<void> => {
    const organizationId = currentOrganization.id;
    const billigAddressRequest: OrganizationDetailsToUpdate = {
      bothAddressesSame: formDetails.bothAddressesSame,
      billingAddress: formDetails.billingAddress as Address,
      shippingAddress:
        formDetails.bothAddressesSame === true
          ? undefined
          : (formDetails.shippingAddress as Address)
    };
    const request =
      pageType === 'business'
        ? {
            // Collect information both from the billing address form and the firmographics.
            ...billigAddressRequest,
            companyName: formDetails.companyName,
            companySize: numEmployees,
            websiteUrl,
            taxStatus: formDetails.taxStatus as TaxStatusType,
            taxIdType: formDetails.taxIdType,
            taxId: formDetails.taxId,
            businessType: formDetails.businessType,
            organizationIsABusiness: true
          }
        : {
            ...billigAddressRequest,
            organizationIsABusiness: false
          };
    try {
      setIsLoading(true);
      const newOrganizationId = await handleTackleSubscription(request);
      removeTackleSubscriptionAction();
      if (newOrganizationId && newOrganizationId !== organizationId) {
        // If the organization id has changed, we need to navigate to the new organization
        switchCurrentOrganization(newOrganizationId);
      }
      navigateTo('/');
    } catch (e) {
      handleTackleSubscriptionError(errorMessage(e));
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MarketplaceOnboardingPage
      onCompleteSignUp={onCompleteSignUp}
      isLoading={isLoading}
    />
  );
};

export default MarketplaceOnboardingView;
