import { Policy, RoleMapping } from '@cp/common/protocol/Authorization';
import { UserFeatureId } from '@cp/common/protocol/features/UserFeatures';
import { Organization, OrganizationInvitationDetails, OrganizationRole } from '@cp/common/protocol/Organization';
import { PendingUserAction, PendingUserActionType } from '@cp/common/protocol/PendingUserActions';
import { registerStatusCodeByErrorToken } from '@cp/common/utils/HttpError';
import { RpcRequest, WithOrganizationId } from '@cp/common/utils/ProtocolUtils';
import { OrganizationFeatureId } from '@cp/common/protocol/features';
import { Instance } from '@cp/common/protocol/Instance';

/** URL path for account handler: /api/account. */
export const ACCOUNT_API_PATH = 'account';

/** Country codes we block. As passed by 'CloudFront-Viewer-Country' HTTP request header. */
export const RESTRICTED_COUNTRY_CODES = ['RU', 'CN'];

/** Error codes. */
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
registerStatusCodeByErrorToken(USER_NOT_FOUND, 404);

/**  Max number of email confirmation emails user can request per hour */
export const USER_MAX_CONFIRMATION_EMAILS_PER_HOUR = 10;

/** Set of all RPC actions for 'account' handler. */
export type AccountRpcAction =
  | 'addUserEntryQuestionnaire'
  | 'addUserExitQuestionnaire'
  | 'addUserToCloudWaitlist'
  | 'getConfirmEmailLastRetry'
  | 'initializeUserSession'
  | 'resendEmailConfirmation'
  | 'storeSignInMetadata'
  | 'getSignInMetadata'
  | 'updateUserDetails'
  | 'updateUserFeatureFlags'
  | 'sendForgotPasswordEmail'
  | 'checkRegionAccess'
  | 'createUserPrototypeByEmail'
  | 'createPasswordChangeTicket'
  | 'checkUserHasAcceptedTOS'
  | 'acceptAccountTOS'
  | 'getUserSessionDetails'
  | 'sendRequestPOC'
  | 'updateUserPreferences';

export type AccountRpcRequest<T extends AccountRpcAction> = RpcRequest<T>;

export interface SignUpRequest {
  email: string;
  password: string;
  name: string;
}

export interface SignUpResponse {
  activated: boolean;
}

export interface ResendEmailConfirmationRequest extends AccountRpcRequest<'resendEmailConfirmation'> {
  userId: string;
}

/**
 * A request to send a forgot password email to the user's email address.
 */
export interface SendForgotPasswordRequest extends AccountRpcRequest<'sendForgotPasswordEmail'> {
  /**
   * The email address of the user requesting a password reset.
   */
  email: string;
  /**
   * Additional metadata associated with the client application that initiated the request.
   * Must include `requestTime` and `device` fields.
   */
  clientMetadata: Partial<Record<ClientMetadataKey, string>>;
}

export interface EmailConfirmLastRetryRequest extends AccountRpcRequest<'getConfirmEmailLastRetry'> {
  userId: string;
}

export type MfaMethod =
  /** Mfa is disabled */
  | 'NOMFA'

  /** sms verification */
  | 'SMS_MFA'

  /**
   * Totp token generated by an authenticator app
   * (e.g. Google Authenticator, 1Password, Okta verifier etc)
   */
  | 'SOFTWARE_TOKEN_MFA'

  /**
   * Unknown mfa verification method
   */
  | 'UNKNOWN_MFA';

/** TODO(#8036): change OKTA to SAML. */
export type IdentityProvider = 'GOOGLE' | 'MICROSOFT' | 'OKTA' | 'COGNITO' | 'AUTH0';

/** Partial update payload for UpdateUserDetailsRequest. */
export interface UserDetailsUpdate {
  name?: string;
  contractVersionIds?: string;

  /** List of pending action types to remove. Processed before 'pendingActionsToAdd'. */
  pendingActionTypesToRemove?: Array<PendingUserActionType>;

  /** List of pending actions to add. If there is another action with the same type it is overwritten. */
  pendingActionsToAdd?: Array<PendingUserAction>;
  mfaPreferredMethod?: MfaMethod;
  identityProviders?: Array<IdentityProvider>;
  preferences?: Partial<UserPreferences>;
}

export type UpdateUserDetailsRequest = AccountRpcRequest<'updateUserDetails'> & UserDetailsUpdate;
export type UpdateUserDetailsResponse = GetUserDetailsResponse;

export interface UserCloudWaitlistRegistration {
  waitlistName: 'azure';
}

export type AddUserToCloudWaitlistRequest = AccountRpcRequest<'addUserToCloudWaitlist'> & UserCloudWaitlistRegistration;

export interface QuestionAnswerType {
  question: string;
  answer: string;
}
export interface UserQuestionnaire {
  questions: Array<QuestionAnswerType>;
}

export type UserExitQuestionnaire = UserQuestionnaire & WithOrganizationId;

export type POCRequest = AccountRpcRequest<'sendRequestPOC'> & {
  name: string;
  email: string;
  description: string;
  recaptchaToken: string;
};

export type AddUserEntryQuestionnaireRequest = AccountRpcRequest<'addUserEntryQuestionnaire'> & UserQuestionnaire;
export type AddUserExitQuestionnaireRequest = AccountRpcRequest<'addUserExitQuestionnaire'> &
  UserQuestionnaire &
  WithOrganizationId;

/** For original definition take a look at Auth0 docs - https://auth0.com/docs/customize/actions/flows-and-triggers/login-flow/event-object. */
export type Auth0AuthenticationMethod = 'federated' | 'pwd' | 'passkey' | 'sms' | 'email' | 'phone_number' | 'mock';

export type TrackerParams = {
  /** Galaxy Session Id passed during sign-in or sign-up. */
  glxid: string;
  /** Marketing params passed during sign-in or sign-up. */
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  loc: string;
  ad_group: string;
  gclid: string;
  referrer: string;
};

/** User details provided by the AuthProvider. */
export interface AuthProviderUserProfile {
  /** User ID as stored in MongoDB. */
  userId: string;
  email: string;
  name: string;
  initials: string;
  sub: string;
  signInMetadataId?: string;
  mfaPreferredMethod?: MfaMethod;
  identityProviders?: Array<IdentityProvider>;
  /**
   * Last seen tackle token passed during sign-in or sign-up.
   * Used to create pending action on initial user creation.
   * Serialized json.
   */
  tackleTokenJson?: string;
  tracker?: Partial<TrackerParams>;
}

export interface GetConfirmEmailLastRetryResponse {
  lastConfirmationTime: number;
}

/**
 * Type of the user identity provider.
 * Different types of providers support different subset of operations.
 * For example, 'Social' does not support password reset or setup of MFA on CP side:
 * these can only be done on the original IDP side.
 * */
export type UserIdentityProviderType = 'database' | 'social' | 'saml';

/** User details response: auth provider + CP fields. */
export interface GetUserDetailsResponse extends Omit<AuthProviderUserProfile, 'sub'> {
  /** List of enabled user-level features. */
  features: Array<UserFeatureId>;
  externalUserId?: string;

  /**
   * User IPv4 address. Not stored in database and re-computed by the server side during the request.
   * May be empty if the server failed to get the address.
   */
  ipAddress: string;

  /**
   * Country name for the current user session.
   * Origin: 'CloudFront-Viewer-Country'.
   * See
   * https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/using-cloudfront-headers.html,
   * https://aws.amazon.com/about-aws/whats-new/2020/07/cloudfront-geolocation-headers,
   * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  countryCode?: string;

  /** A set of restrictions on what a user can do outside a specific organization. */
  restrictions: UserRestrictions;

  /** List of pending action for the user. */
  pendingActions: Array<PendingUserAction>;
  /** Whether the user made a request from a region blocked country */
  regionBlocked?: boolean;
  /** The mfa method currently active for the user */
  mfaPreferredMethod?: MfaMethod;
  identityProviderType: UserIdentityProviderType;
  preferences?: Partial<UserPreferences>;
  acceptedTOSContractId?: string | undefined;
  roleIds?: string[];
}

/** Type alias. To avoid having response in the code that is far from the network stack. */
export type CpUserDetails = GetUserDetailsResponse;

export type SourceApp = 'legacy-cp' | 'unified-console';

export type InitializeUserSessionRequest = AccountRpcRequest<'initializeUserSession'> & {
  sourceApp?: SourceApp;
};

export interface InitializeUserSessionResponse extends GetUserDetailsResponse {
  organizations: Array<Organization>;
  invitations: Array<OrganizationInvitationDetails>;
  policies: Array<Policy>;
  roleMappings: Array<RoleMapping>;
}

export type GetUserSessionDetailsRequest = AccountRpcRequest<'getUserSessionDetails'>;

type OrgId = string;
type InstanceId = string;

export type OrganizationSummary = Pick<Organization, 'id' | 'name' | 'users'>;

export const instanceSummaryFields = [
  'id',
  'name',
  'organizationId',
  'regionId',
  'state',
  'dbUsername',
  'endpoints',
  'defaultDatabaseRoleMappings',
  'dataWarehouseId',
  'isPrimary',
  'sqlConsoleRolesDefined',
  'database',
  'features'
] as const;

export interface InstanceSummary extends Pick<Instance, (typeof instanceSummaryFields)[number]> {
  primaryInstanceId: string;
  primaryInstanceHostname: string;
}

export type GetUserSessionDetailsResponse = Pick<AuthProviderUserProfile, 'userId' | 'name' | 'email'> & {
  userFeatures: Array<UserFeatureId>;
  orgFeatures: Record<OrgId, Array<OrganizationFeatureId>>;
  orgRoles: Record<OrgId, OrganizationRole>;
  organizations: Record<OrgId, OrganizationSummary>;
  instances: Record<InstanceId, InstanceSummary>;
};

export interface UserRestrictions {
  /** How many organizations can this user be a member of (affect both invitation acceptance and organization creation). */
  maxOrganizationCount: number;
  /**
   * Can the user voluntarily create new organizations beyond the default org (org they joined with an invitation or created on sign-up).
   * This does not apply to situations when an org is created for the user indirectly.
   * For example: Leaving the last org you're a member of or being removed from the last org you're a member of.
   */
  canCreateOrganizations: boolean;
  /** Will the next organization created by this user be able to start a trial. */
  canClaimTrial: boolean;
}

export function getDefaultUserRestrictions(): UserRestrictions {
  return {
    maxOrganizationCount: 1,
    canCreateOrganizations: false,
    canClaimTrial: true
  };
}

// USER_DETAILS_UPDATED
export interface UserDetailsUpdatedPayload {
  userDetails: GetUserDetailsResponse;
}

/** Payload of 'INVITE_UPDATE' ws message. */
export type InvitationUpdatedPayload = {
  invitations: Array<OrganizationInvitationDetails>;
};

export type ClientMetadataKey =
  | 'uri_params'
  | 'organizationName'
  | 'recaptchaToken'
  | 'signInMetadataId'
  | 'requestTime'
  | 'device'
  | 'location';

/** Used to enable/disable experiment flags. */
export interface UpdateExperimentFlagRequest extends AccountRpcRequest<'updateUserFeatureFlags'> {
  flags: Record<string, boolean>;
}

export interface TotpAuthenticatorAppSetUpData {
  /** Enrollment secret code. Same as '&secret=' in qrCodeString. */
  secret: string;
  /**
   * QR code string returned by the Auth provider.
   * Example: otpauth://totp/ch-local-dev:username%2Bdb2%40clickhouse.com?secret=K4XFEXSYENEUU2ZDNV5EKRBKHAYTSRD5&issuer=ch-local-dev&algorithm=SHA1&digits=6&period=30
   */
  qrCodeString: string;
}

export type CheckRegionAccessRequest = AccountRpcRequest<'checkRegionAccess'>;

export interface CreateUserPrototypeByEmailRequest extends AccountRpcRequest<'createUserPrototypeByEmail'> {
  email: string;
  fullName: string;
  metadataId: string;
  recaptchaToken: string;
}

export interface CreatePasswordChangeTicketRequest extends AccountRpcRequest<'createPasswordChangeTicket'> {
  /** The URL to redirect user after the password is changed. */
  returnUrl: string;
}

export interface CreatePasswordChangeTicketResponse {
  /** The IDP side URL to redirect user to change the password. */
  redirectUrl: string;
}

export interface CheckUserHasAcceptedTOSRequest extends AccountRpcRequest<'checkUserHasAcceptedTOS'> {}

export interface CheckUserHasAcceptedTOSResponse {
  /** Contract Id if user has already accepted Terms Of Service. */
  contractId?: string;
}

export interface AcceptAccountTOSRequest extends AccountRpcRequest<'acceptAccountTOS'> {}

/** Reusable constant for CognitoOffline pool. Used only in 'development' mode. */
export const COGNITO_OFFLINE_POOL_ID = 'us-east-2_0sujzemjx';

/** Reusable constant for CognitoOffline pool web client. Used only in 'development' mode. */
export const COGNITO_OFFLINE_WEB_CLIENT_ID = 'dejwvmtw9u65l6b4hvlmpy8kf';

export interface UserPreferences {
  theme: 'dark' | 'light';
  /**
   * Indicates whether the user has GPT Inline Code Completion enabled.
   */
  isGptInlineCodeCompletionEnabled: boolean;
}

export interface UpdateUserPreferencesRequest extends AccountRpcRequest<'updateUserPreferences'> {
  preferences: Partial<UserPreferences>;
}
