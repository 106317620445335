import { GetAutoScalingLimitsResponse, GetMemorySizeOptionsInput } from '@cp/common/protocol/AutoScaling';
import { InstanceBackupStatus } from '@cp/common/protocol/Backup';
import { OrganizationFeatureId } from '@cp/common/protocol/features';
import {
  BYOC_INSTANCE_MEMORY_SIZES,
  defaultInstanceMemorySizes,
  INSTANCE_BACKUP_DEFAULT_RETENTION_PERIOD,
  InstanceMemorySizeOptions,
  InstanceTier,
  MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG,
  MAX_AUTOSCALING_RANGE_FOR_PAID_ORG_GCP
} from '@cp/common/protocol/Instance';
import { isAwsRegionId, isGCPRegion, RegionId } from '@cp/common/protocol/Region';
import { isPayingStatus } from '@cp/common/utils/BillingUtils';
import { MILLIS_PER_HOUR } from '@cp/common/utils/DateTimeUtils';

export function getInstanceMemorySizeOptions(
  regionId: RegionId,
  instanceTier: InstanceTier,
  isCustomValuesFeatureEnabled: boolean,
  isPayingOrg: boolean,
  isByocInstance: boolean,
  customAutoscaleValues: Array<number> = [],
  dpAutoScalingLimits: GetAutoScalingLimitsResponse
): InstanceMemorySizeOptions {
  let sizes = defaultInstanceMemorySizes;

  const isDevInstance = instanceTier === 'Development';
  const maxMemoryValue = isGCPRegion(regionId)
    ? MAX_AUTOSCALING_RANGE_FOR_PAID_ORG_GCP
    : MAX_AUTOSCALING_PRODUCTION_MEMORY_PAID_ORG;

  if (isDevInstance) {
    sizes = [16, 48, 96, 192, maxMemoryValue];
  } else if (isByocInstance) {
    sizes = BYOC_INSTANCE_MEMORY_SIZES;
  } else {
    sizes[sizes.length - 1] = maxMemoryValue;
  }

  // Make sure our sizes fit within the restrictions specified by data plane
  customAutoscaleValues = customAutoscaleValues.filter(
    (size) => size >= dpAutoScalingLimits.minMemoryGb && size <= dpAutoScalingLimits.maxMemoryGb
  );

  if (!isDevInstance && !isByocInstance) {
    sizes = sizes.filter((size) => size >= dpAutoScalingLimits.minMemoryGb && size <= dpAutoScalingLimits.maxMemoryGb);
  }

  // The max tick should be blocked for nonpaying non-byoc production services.
  let enableMaxTick = isPayingOrg || isDevInstance || isByocInstance;
  if (isCustomValuesFeatureEnabled && customAutoscaleValues.length > 0) {
    const valuesSet = new Set([...sizes, ...customAutoscaleValues]);

    sizes = new Array(...valuesSet).sort((n1, n2) => n1 - n2);
    // Enable the max tick even for nonpaying services in case it is provided as a custom autoscale value.

    enableMaxTick = enableMaxTick || customAutoscaleValues.includes(sizes[sizes.length - 1]);
  }

  const max = enableMaxTick ? sizes.length - 1 : sizes.length - 2;
  const selectableDomain = { min: 0, max };

  return { sizes, selectableDomain };
}

export function getMemorySizeOptionsForNewInstance({
  regionId,
  instanceTier,
  billingStatus,
  autoScalingLimits,
  orgIsByoc
}: GetMemorySizeOptionsInput): InstanceMemorySizeOptions {
  const isPayingOrg = isPayingStatus(billingStatus);

  const customValuesFeatureEnabled = false;
  const customAutoscaleValues = new Array<number>();

  return getInstanceMemorySizeOptions(
    regionId,
    instanceTier,
    customValuesFeatureEnabled,
    isPayingOrg,
    orgIsByoc ?? false,
    customAutoscaleValues,
    autoScalingLimits
  );
}

/**
 * Selects UX/OpenAPI visible backups from the list of all backups.
 *
 * @param allBackups list of backups
 * @param backupRetentionPeriod retention period in hours, defaults to 24 hours if undefined
 * @param moment a date/moment in time from which to identify the backups that are inside the retention period
 * */
export function filterBackupsInRetentionPeriod<
  T extends { id: string; status: InstanceBackupStatus; createdAt: Date | number }
>(
  allBackups: Array<T>,
  backupRetentionPeriod: number = INSTANCE_BACKUP_DEFAULT_RETENTION_PERIOD,
  moment: Date = new Date()
): Array<T> {
  const backupsInRetentionWindow: Array<T> = [];
  const retentionThreshold: number = moment.getTime() - backupRetentionPeriod * MILLIS_PER_HOUR;
  let allRelevantBackupsAdded = false;

  // sort DESC by createdAt
  allBackups.sort((a, b) => {
    const timeA = a.createdAt instanceof Date ? a.createdAt.getTime() : a.createdAt;
    const timeB = b.createdAt instanceof Date ? b.createdAt.getTime() : b.createdAt;

    return timeB - timeA; // For descending order
  });

  for (const backup of allBackups) {
    const createdAt = backup.createdAt instanceof Date ? backup.createdAt.getTime() : backup.createdAt;
    if (retentionThreshold <= createdAt && createdAt <= moment.getTime()) {
      backupsInRetentionWindow.push(backup);
    } else if (createdAt < retentionThreshold && backup.status === 'done' && !allRelevantBackupsAdded) {
      if (backupsInRetentionWindow.length > 0) {
        allRelevantBackupsAdded = true;
      }
      backupsInRetentionWindow.push(backup);
    } else if (allRelevantBackupsAdded) {
      // the array is sorted desc, no point continuing iterating
      break;
    }
  }

  return backupsInRetentionWindow;
}

export function isCustomerManagedEncryptionAvailable(
  regionId: RegionId,
  instanceTier: InstanceTier,
  orgFeatures: Array<OrganizationFeatureId>,
  isOrgByoc: boolean
): boolean {
  return (
    isAwsRegionId(regionId) &&
    instanceTier !== 'Development' &&
    orgFeatures.includes('FT_ORG_CUSTOMER_MANAGED_ENCRYPTION') &&
    !isOrgByoc
  );
}
