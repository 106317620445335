import {
  Alert,
  Button,
  ButtonGroup,
  Container,
  Label,
  Select,
  Separator,
  Spacer,
  TextField
} from '@clickhouse/click-ui';
import { BUSINESS_TYPE_ARRAY, BusinessType } from '@cp/common/protocol/Billing';
import {
  COMPANY_SIZE_ARRAY,
  CompanySize
} from '@cp/common/protocol/Organization';
import {
  filterTaxIdTypesByCountry,
  isTaxStatusType,
  isTaxSupportedCountry,
  TaxExemptType,
  TaxIdDatumType
} from '@cp/common/protocol/Stripe';
import { ReactElement } from 'react';
import {
  useAddressesSame,
  useBillingAddressCountry,
  useBusinessType,
  useCompanyName,
  useIsEntityInfoFormValid,
  useNumEmployees,
  useOrganizationType,
  useShippingAddressCountry,
  useTaxId,
  useTaxInfo,
  useWebsiteUrl
} from 'src/components/BillingCardModal/FormState';

type EntityStageProps = {
  onAdvance: () => void;
  onBack: () => void;
  isLoading: boolean;
  taxExempt?: TaxExemptType;
  hasTaxDetails: boolean;
};
export const EntityInfoForm = ({
  onBack,
  onAdvance,
  isLoading,
  taxExempt,
  hasTaxDetails
}: EntityStageProps): ReactElement => {
  const [organizationIsABusiness, setOrganizationIsABusiness] =
    useOrganizationType();
  const { numEmployees, setNumEmployees } = useNumEmployees();
  const { businessType, setBusinessType, businessTypeError } =
    useBusinessType();
  const { websiteUrl, setWebsiteUrl } = useWebsiteUrl();
  const { companyName, setCompanyName, companyNameError } = useCompanyName();
  const { taxId, setTaxId, taxIdError } = useTaxId();
  const { taxInfo, setTaxInfo } = useTaxInfo();
  const { billingAddressCountry } = useBillingAddressCountry();
  const { shippingAddressCountry } = useShippingAddressCountry();
  const { bothAddressesSame } = useAddressesSame();
  const countryCode = bothAddressesSame
    ? billingAddressCountry ?? 'None'
    : shippingAddressCountry ?? billingAddressCountry ?? 'None';
  const taxOptions = filterTaxIdTypesByCountry(countryCode, hasTaxDetails);
  const stripeSupportsCountry = isTaxSupportedCountry(countryCode);
  const selectedTaxIdInfo = taxOptions.find(
    (c) => c.taxId === taxInfo.taxIdType && c.country !== 'None'
  );
  const hasError = !!(companyNameError || taxIdError);
  const canBeSubmitted = useIsEntityInfoFormValid();

  return (
    <>
      <Container
        orientation="vertical"
        gap="md"
        alignItems="start"
        data-testid="billing-card-modal-entity-form"
        fillWidth={false}
      >
        <Container
          orientation="vertical"
          gap="xs"
          data-testid="billing-card-modal-entity-form-btn-group"
          alignItems="start"
          fillWidth
        >
          <Label>I am a</Label>
          <ButtonGroup
            onClick={(value: string) => {
              value === 'business'
                ? setOrganizationIsABusiness(true)
                : setOrganizationIsABusiness(false);
            }}
            options={[
              {
                label: (
                  <div data-testid="organization-is-a-business-button">
                    Business
                  </div>
                ),
                value: 'business'
              },
              {
                label: (
                  <div data-testid="organization-is-an-individual-button">
                    Individual
                  </div>
                ),
                value: 'individual'
              }
            ]}
            selected={organizationIsABusiness ? 'business' : 'individual'}
            type="default"
            fillWidth
          />
        </Container>
        {organizationIsABusiness && (
          <>
            <Container
              orientation="horizontal"
              justifyContent="space-between"
              gap="md"
              fillWidth
            >
              <TextField
                dir="start"
                label="Company or organization"
                orientation="vertical"
                type="text"
                value={companyName}
                error={companyNameError}
                placeholder="Please enter a company or organization name"
                onChange={setCompanyName}
                data-testid="company-name"
              />
            </Container>
            <Container
              orientation="horizontal"
              justifyContent="space-between"
              gap="md"
              fillWidth
            >
              <Select
                onSelect={(value: string): void => {
                  setBusinessType(value as BusinessType);
                }}
                value={businessType}
                error={businessTypeError}
                label="Business type"
                placeholder="Please select business type"
                data-testid="business-type-select"
              >
                {BUSINESS_TYPE_ARRAY.map((businessType) => (
                  <Select.Item
                    value={businessType}
                    key={businessType}
                    data-testid="business-type-option"
                    data-testid-value={businessType.replace(' ', '-')}
                  >
                    {businessType}
                  </Select.Item>
                ))}
              </Select>
              <Select
                onSelect={(value: string): void => {
                  setNumEmployees(value as CompanySize);
                }}
                value={numEmployees}
                label="Number of employees"
                placeholder="Please select number of employees"
                data-testid="company-size-select"
              >
                {COMPANY_SIZE_ARRAY.map((size) => (
                  <Select.Item
                    value={size}
                    key={size}
                    data-testid="company-size-option"
                    data-testid-value={`company-size-option-${size}`}
                  >
                    {size}
                  </Select.Item>
                ))}
              </Select>
            </Container>
            <Container orientation="vertical" gap="md" fillWidth>
              <TextField
                dir="start"
                label="Website"
                orientation="vertical"
                type="text"
                placeholder="Please enter a website url"
                value={websiteUrl}
                onChange={setWebsiteUrl}
                data-testid="website-url"
              />
            </Container>
            <Container orientation="vertical" gap="md" fillWidth>
              <Select
                onSelect={(value: string) => {
                  if (isTaxStatusType(value)) {
                    setTaxInfo({
                      taxStatus: value,
                      taxIdType: undefined
                    });
                  } else {
                    setTaxInfo({
                      taxStatus: 'registered',
                      taxIdType: value as TaxIdDatumType
                    });
                  }
                }}
                value={taxInfo.taxIdType ?? taxInfo.taxStatus}
                label="Tax status"
                data-testid="tax_id_type_select"
              >
                {taxOptions.map((option) => (
                  <Select.Item
                    value={option.taxId ?? option.taxStatus ?? 'unknown'}
                    key={option.description}
                    data-testid="tax-id-type-option"
                  >
                    {option.description}
                  </Select.Item>
                ))}
              </Select>
              {selectedTaxIdInfo && (
                <TextField
                  dir="start"
                  label="Tax ID"
                  orientation="vertical"
                  type="text"
                  data-testid="tax-id"
                  value={taxId}
                  error={taxIdError}
                  placeholder={`e.g. ${selectedTaxIdInfo.example}`}
                  onChange={setTaxId}
                />
              )}
            </Container>
          </>
        )}
      </Container>
      {organizationIsABusiness &&
        taxInfo.taxStatus === 'exempt' &&
        taxExempt !== 'exempt' && (
          <>
            <Spacer size="md" />
            <Alert
              showIcon
              size="small"
              state="info"
              text="Please send proof of your tax exemption to tax-exempt@clickhouse.com"
              type="default"
              data-testid="system-message-text"
            />
          </>
        )}
      {organizationIsABusiness && !stripeSupportsCountry && (
        <>
          <Spacer size="md" />
          <Alert
            showIcon
            size="small"
            state="info"
            text="Please send an email with your Tax Id to ar@clickhouse.com"
            type="default"
            data-testid="system-message-text"
          />
        </>
      )}
      <Separator size="lg" orientation="horizontal" />
      <Container
        justifyContent="end"
        gap="md"
        fillWidth={false}
        isResponsive={false}
      >
        <Button
          onClick={onBack}
          type="secondary"
          label="Back"
          data-testid="company-details-back-button"
          iconLeft="arrow-left"
        />
        <Button
          align="center"
          loading={isLoading}
          disabled={
            isLoading ||
            (organizationIsABusiness && hasError) ||
            !canBeSubmitted
          }
          iconRight="arrow-right"
          label="Credit card details"
          data-testid="company-details-update-button"
          type="primary"
          onClick={onAdvance}
        />
      </Container>
    </>
  );
};
