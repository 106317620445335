import { useEffect } from 'react';
import { TextField } from '@clickhouse/click-ui';
import { ClickPipeKafkaType } from 'shared/src/dataLoading';

interface Props {
  type?: ClickPipeKafkaType;
  value?: string;
  errors: Record<string, string | undefined>;
  placeholder: string;
  onChange: (value: string) => void;
  setPassword: (value: string) => void;
}

export function UsernameInput(props: Props): JSX.Element | null {
  // Azure EventHub's Kafka Surface username is always '$ConnectionString' So we
  // will not show the username field to the user and set it in the background.
  const azureEventHubsUser = '$ConnectionString';
  const defaultUsername =
    props.type == 'azureeventhub'
      ? azureEventHubsUser
      : props.value
      ? props.value
      : '';

  useEffect(() => {
    props.onChange(defaultUsername);
  }, [defaultUsername]);

  if (props.type === 'azureeventhub') {
    return null;
  } else if (props.type === 'confluent') {
    return (
      <TextField
        label={'API key'}
        error={props.errors['username']}
        name={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        data-testid="username"
        className="fs-exclude"
      />
    );
  } else {
    return (
      <TextField
        label={'Username'}
        error={props.errors['username']}
        name={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        data-testid="username"
        className="fs-exclude"
      />
    );
  }
}
