import { css, SerializedStyles, Theme } from '@emotion/react';

export const axisColumnContainer = (theme: Theme): SerializedStyles => css`
  background: ${theme.global.color.background.default};
  border-radius: 4px;
  border: 1px solid ${theme.global.color.stroke.default};
  overflow: auto;
`;

export const columnStyle = (theme: Theme): SerializedStyles => css`
  position: initial;
  border: 1px solid ${theme.global.color.stroke.default};
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 0px 5px 0px 10px;
  height: 30px;
  width: 100%;
  height: 28px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  cursor: grab;
  opacity: 1;
  z-index: 2;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  &[data-dragging='true'] {
    opacity: 0;
  }
`;

export const columnListStyle = css`
  margin-top: 13px;
`;

export const columnNameStyle = css`
  flex-grow: 1;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const dataTypeStyle = (theme: Theme): SerializedStyles => css`
  padding: 2px 9px 3px 9px;
  background-color: ${theme.global.color.background.muted};
  border-radius: 4px;
`;

export const columnActionsContainer = css`
  display: flex;
  align-items: center;
  margin-left: 10px;
  flex-shrink: 0;
`;

export const columnIconStyle: SerializedStyles = css`
  flex-shrink: 0;
  margin-left: 5px;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const singleColumnStyle = css`
  position: relative;
`;

export const flyoutBodyStyle: SerializedStyles = css`
  overflow: hidden;
`;

export const generalTabsContentStyle: SerializedStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
`;

export const chartSelectStyle: SerializedStyles = css`
  flex-shrink: 0;
`;

export const columnListSectionStyle: SerializedStyles = css`
  width: 100%;

  flex: 5 20 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
`;

export const columnListDroppableStyle: SerializedStyles = css`
  flex: 1 1 auto;
`;

export const advancedTabsContainerStyle: SerializedStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  padding: 1rem;
`;

export const avancedContentStyle: SerializedStyles = css`
  height: 100%;
  overflow-y: scroll;
`;

export const formElementStyle: SerializedStyles = css`
  width: 100%;
`;

export const tabStyle = css`
  flex: 1;
`;

export const tooltipZIndex = css`
  z-index: 1001;
`;
