import { Spacer, Title } from '@clickhouse/click-ui';
import { ReactElement } from 'react';
import CodeBlockWithPassword from 'src/components/ServiceConnect/CodeBlockWithPassword';
import {
  getPasswordSubstitution,
  PASSWORD_MASK
} from 'src/components/ServiceConnect/Tabs/ConnectionStrings';
import { FullWidthCodeBlock } from 'src/components/ServiceConnect/Tabs/FullWidthCodeBlock';
import LanguageClientExtras from 'src/components/ServiceConnect/Tabs/LanguageClientExtras';

const getNodejsClientCode = (
  hostname: string,
  username: string,
  password: string,
  port: string
): string => {
  return `import { createClient } from '@clickhouse/client'

void (async () => {
  const client = createClient({
    url: 'https://${hostname}:${port}',
    username: '${username}',
    password: '${getPasswordSubstitution(password)}',
  })
  const rows = await client.query({
    query: 'SELECT 1',
    format: 'JSONEachRow',
  })
  console.log('Result: ', await rows.json())
})()`;
};

interface NodejsConnectionTabProps {
  hostname: string;
  username: string;
  password: string;
  port: string;
  showPassword?: boolean;
}

const NodejsConnectionTab = ({
  hostname,
  username,
  password,
  port,
  showPassword
}: NodejsConnectionTabProps): ReactElement => {
  const unmasked = getNodejsClientCode(hostname, username, password, port);
  const masked = password
    ? getNodejsClientCode(hostname, username, PASSWORD_MASK, port)
    : undefined;

  return (
    <>
      <Title type="h3">Installation</Title>
      <FullWidthCodeBlock
        language="bash"
        showLineNumbers
        wrapLines
        data-testid="service-connect-code-block-nodejs-install"
      >
        npm i @clickhouse/client
      </FullWidthCodeBlock>
      <Title type="h3">Usage</Title>
      <CodeBlockWithPassword
        unmaskedString={unmasked}
        maskedString={masked}
        language="javascript"
        data-testid="service-connect-code-block-nodejs-usage"
        showPassword={showPassword}
      />
      <Spacer />
      <LanguageClientExtras
        title="Node.js Client"
        docsLink="https://clickhouse.com/docs/en/integrations/language-clients/javascript"
        examplesLink="https://github.com/ClickHouse/clickhouse-js/tree/main/examples"
      />
    </>
  );
};

export default NodejsConnectionTab;
