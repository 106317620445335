import { ConfirmationDialog, Select } from '@clickhouse/click-ui';
import { ALLOW_DENY } from '@cp/common/protocol/Authorization';
import { Organization } from '@cp/common/protocol/Organization';
import { ReactElement, useMemo, useState } from 'react';
import {
  getAuthorizationDecision,
  useAuthorizationStateManager
} from 'src/authorization/authorizationState';
import { useSystemFeature } from 'src/lib/features';

interface BillingContactModalProps {
  billingContact: string;
  isOpen: boolean;
  organization: Organization;
  onChange: (billingContact: string) => Promise<void>;
  onClose: () => void;
}

export function BillingContactModal({
  billingContact,
  isOpen,
  organization,
  onChange,
  onClose
}: BillingContactModalProps): ReactElement {
  const shouldUseFineGrainedAuthorization = useSystemFeature(
    'FT_SYS_USE_FINE_GRAINED_AUTHORIZATION'
  );

  const { policies } = useAuthorizationStateManager();
  const manageBillingUsers = useMemo(() => {
    if (shouldUseFineGrainedAuthorization) {
      return Object.values(organization.users).filter(
        (user) =>
          getAuthorizationDecision({
            allPolicies: policies,
            roleIds: user.roleIds ?? [],
            permission: 'control-plane:organization:manage-billing'
          }).allowDeny === ALLOW_DENY.ALLOW
      );
    }

    return Object.values(organization.users).filter(
      (user) => user.role === 'ADMIN'
    );
  }, [organization.users, policies, shouldUseFineGrainedAuthorization]);

  const [selectedBillingContact, setSelectedBillingContact] = useState<
    string | undefined
  >(billingContact);

  const saveBillingContact = (): void => {
    void onChange(selectedBillingContact as string);
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onCancel={onClose}
      title="Update billing contact"
      onConfirm={saveBillingContact}
      primaryActionLabel="Save"
      secondaryActionLabel="Cancel"
      disabled={billingContact === selectedBillingContact}
    >
      <Select
        onSelect={setSelectedBillingContact}
        value={selectedBillingContact}
        label="Billing contact"
      >
        {manageBillingUsers.map((user) => {
          return (
            <Select.Item value={user.email} key={user.userId}>
              {user.email}
            </Select.Item>
          );
        })}
      </Select>
    </ConfirmationDialog>
  );
}
