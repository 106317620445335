import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { justifyCenter } from '@utility-styles';
import {
  Alert,
  Icon,
  Link,
  Text,
  MultiSelect,
  EllipsisContent
} from '@clickhouse/click-ui';
import { useRoles } from 'src/components/RoleSelector/useRoles';

const linkStyle = css({
  display: 'inline-block',
  padding: '2px 2px 2px 16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const linkIconStyle = css({
  display: 'inline'
});

interface RoleSelectorProps {
  value?: string[];
  onSelect: (value: string[]) => void;
  extraRoles?: Array<{ value: string; label: string }>;
}

export function RoleSelector({
  value,
  onSelect,
  extraRoles = []
}: RoleSelectorProps): JSX.Element | null {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reload, roles] = useRoles();

  useEffect(
    function () {
      reload()
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [reload]
  );

  if (loading) {
    return (
      <Icon
        height="20px"
        name="loading-animated"
        width="1rem"
        css={justifyCenter}
      />
    );
  }

  if (error || !roles) {
    return (
      <Alert
        showIcon
        size="medium"
        state="danger"
        text="failed to fetch roles."
      />
    );
  }

  return (
    <MultiSelect
      multiple
      value={value}
      onSelect={onSelect}
      orientation="horizontal"
      data-testid="role-selector"
    >
      {extraRoles.map((r) => (
        <MultiSelect.Item value={r.value} key={r.value}>
          {r.label}
        </MultiSelect.Item>
      ))}

      {roles.length > 0 ? (
        <MultiSelect.Group heading="Custom roles" key="custom_roles">
          {roles.map((r) => (
            <MultiSelect.Item value={r} key={r}>
              {r}
            </MultiSelect.Item>
          ))}
        </MultiSelect.Group>
      ) : (
        <EllipsisContent
          component={Link}
          href="https://clickhouse.com/docs/en/security/cloud-access-management"
          target="_blank"
          size="md"
          weight="normal"
        >
          <Text css={linkStyle}>
            Create a custom role{' '}
            <Icon height="12px" name="popout" css={linkIconStyle} />
          </Text>
        </EllipsisContent>
      )}
    </MultiSelect>
  );
}
