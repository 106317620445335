export interface ImportMeta {
  env: {
    CLIENT_API_URL?: string;
    CLIENT_WEB_URL?: string;
    CLIENT_CI?: string;
    CLIENT_REMOTE_DEBUG?: string;
    CLIENT_MOCKED?: string;
    CLIENT_LOG_TESTS?: string;
  };
}

const environment: ImportMeta['env'] = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  CLIENT_API_URL: import.meta.env.CLIENT_API_URL,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  CLIENT_WEB_URL: import.meta.env.CLIENT_WEB_URL,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  CLIENT_CI: import.meta.env.CLIENT_CI,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  CLIENT_REMOTE_DEBUG: import.meta.env.CLIENT_REMOTE_DEBUG,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  CLIENT_MOCKED: import.meta.env.CLIENT_MOCKED,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  CLIENT_LOG_TESTS: import.meta.env.CLIENT_LOG_TESTS
};

export default environment;
