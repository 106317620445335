import { ReactElement } from 'react';

import { css, keyframes } from '@emotion/react';

import { IconProps } from 'src/components/icons/iconInterface';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
`;

const svgStyle = css`
  animation:
    ${rotate} 0.7s linear infinite,
    ${fadeInOut} 2s linear infinite;
`;

const LoadingIcon = ({
  color = 'currentColor',
  ...props
}: IconProps): ReactElement => {
  return (
    <svg
      width={13}
      height={13}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={svgStyle}
      {...props}
    >
      <path
        opacity={0.6}
        d="M11.375 7H9.75"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.625 7H3.25"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={0.4}
        d="M6.5 2.125V3.75"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 11.875V10.25"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={0.7}
        d="M9.94521 10.445L8.79688 9.29663"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={0.3}
        d="M3.05518 3.55493L4.20351 4.70327"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity={0.5}
        d="M8.79688 4.70327L9.94521 3.55493"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.20351 9.29663L3.05518 10.445"
        stroke={color}
        strokeWidth={1.18182}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoadingIcon;
