import {
  Container,
  createToast,
  Separator,
  TextField
} from '@clickhouse/click-ui';
import {
  CloudProvider,
  convertCloudProviderToInstanceCloudProvider,
  InstanceTier,
  isDevAvailableInCloudProvider
} from '@cp/common/protocol/Instance';
import { ByocCloudProviderConfig } from '@cp/common/protocol/Organization';
import {
  getAvailableRegionsByStage,
  Region,
  REGION_BY_ID,
  RegionId
} from '@cp/common/protocol/Region';
import { selectRegionsByNearestCountryOrContinent } from '@cp/common/protocol/RegionUtils';
import { truthy } from '@cp/common/utils/Assert';
import { randInt } from '@cp/common/utils/MathUtils';
import {
  getOrganizationCloudProviders,
  isOrgByoc
} from '@cp/common/utils/OrganizationUtils';
import { validateName } from '@cp/common/utils/ValidationUtils';
import { Galaxy } from 'galaxy';
import { ReactElement, useCallback, useState } from 'react';
import AzureWaitlist from 'src/components/CreateNewService/AzureWaitlist';
import CloudProviderSelection from 'src/components/CreateNewService/CloudProviderSelection';
import ServiceForm from 'src/components/CreateNewService/ServiceForm';
import config from 'src/lib/config';
import { useUserFeature } from 'src/lib/features';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import { useUserStateManager } from 'src/user/userState';
import styled from 'styled-components';

export function isRegionAllowedByByocConfig(
  byocProviderConfig: ByocCloudProviderConfig | null,
  byocAccountId: string | undefined,
  regionId: RegionId
): boolean {
  if (!byocProviderConfig) {
    // not a byoc org, allow any
    return true;
  } else {
    return (
      byocAccountId !== undefined &&
      regionId in byocProviderConfig[byocAccountId].regions
    );
  }
}

/** Returns list of regions available to create a new instance for the given organization state. */
export function getAvailableRegions(
  cloudProviders: Array<CloudProvider> = [],
  useExperimentalRegions = false,
  whiteListedRegionNames = '',
  serviceTier: InstanceTier = 'Production',
  byocProviderConfig: ByocCloudProviderConfig | null = null,
  byocAccountId: string | undefined = undefined
): Array<Region> {
  const cloudProviderRegions = [...Object.values(REGION_BY_ID)]
    .filter((r) => cloudProviders.includes(r.cloudProvider))
    .map((r) => r.id);
  const whitelistedRegionNames = whiteListedRegionNames
    .split(',')
    .map((regionName) => regionName.trim());
  return getAvailableRegionsByStage(config.env)
    .map<Region>((regionId) => truthy(REGION_BY_ID[regionId], 'Invalid region'))
    .filter(
      (region) =>
        (serviceTier !== 'Development' || region.isDevAvailable) &&
        cloudProviderRegions.includes(region.id) &&
        isRegionAllowedByByocConfig(
          byocProviderConfig,
          byocAccountId,
          region.id
        ) &&
        (useExperimentalRegions ||
          !region.isExperimental ||
          whitelistedRegionNames.includes(region.name))
    );
}

export function getDefaultRegion(
  countryCode = 'US',
  availableRegions: Region[]
): Region {
  const selectedRegions = selectRegionsByNearestCountryOrContinent(
    countryCode,
    availableRegions
  );

  const selectedRegionIndex = Math.floor(
    Math.random() * selectedRegions.length
  );
  return selectedRegions[selectedRegionIndex];
}
const AnimatedContainer = styled(Container)<{ $show: boolean }>`
  ${({ $show }): string => `
  height: ${$show ? 'auto' : '0px'};
  visibility: ${$show ? 'visible' : 'hidden'};
  transition: height 500ms ease-in-out;
`}
`;

export const CreateNewService = ({
  onCreateService,
  serviceName: serviceNameProp = '',
  type
}: {
  onCreateService: (serviceId: string) => void;
  serviceName?: string;
  type?: 'default' | 'onboarding';
}): ReactElement | null => {
  const currentOrg = useCurrentOrganizationOrThrow();
  const availableCloudProviders = getOrganizationCloudProviders(currentOrg);
  const [selectedProvider, setSelectedProvider] = useState<CloudProvider>(
    availableCloudProviders[randInt(0, availableCloudProviders.length)]
  );

  const isExperimentalRegionsFlagEnabled = useUserFeature('NEW_DP_REGIONS');
  const { user } = useUserStateManager();
  const [serviceTier, setServiceTier] = useState<InstanceTier>('Production');
  const [serviceName, setServiceName] = useState<string>(serviceNameProp);
  const [validationMessage, setValidationMessage] = useState<string>();

  const handleNameError = useCallback((error: string): void => {
    createToast({
      title: 'Error',
      type: 'danger',
      description: error
    });
    setValidationMessage(error);
  }, []);

  const onNameChange = (newName: string): void => {
    const validationResult = validateName(newName);
    setServiceName(newName);
    setValidationMessage(validationResult.validationMessage);
  };

  const selectedInstanceProvider =
    convertCloudProviderToInstanceCloudProvider(selectedProvider);
  const byocConfig = currentOrg.byocConfig;
  const byocProviderConfig = byocConfig
    ? byocConfig[selectedInstanceProvider] ?? null
    : null;

  const getProviderRegions = (
    provider: CloudProvider,
    serviceTier: InstanceTier
  ): Array<Region> =>
    getAvailableRegions(
      [provider],
      isExperimentalRegionsFlagEnabled,
      currentOrg.regionsWhiteList,
      serviceTier,
      byocProviderConfig
    );
  const [providerAvailableRegions, setProviderAvailableRegions] = useState(
    getProviderRegions(selectedProvider, serviceTier)
  );
  const initialRegion = getDefaultRegion(
    user.countryCode,
    providerAvailableRegions
  );
  const [selectedRegion, setSelectedRegion] = useState<RegionId>(
    initialRegion.id
  );
  const byocAccountIds = Object.keys(byocProviderConfig ?? {});
  const [selectedByocAccount, setSelectedByocAccount] = useState<
    string | undefined
  >(byocAccountIds[0]);

  const handleServiceTierChange = (tier: InstanceTier): void => {
    if (tier === serviceTier) {
      return;
    }
    const regions = getProviderRegions(selectedProvider, tier);
    setProviderAvailableRegions(regions);
    if (!regions.find(({ id }) => id === selectedRegion)) {
      // Update selected region if it is not available anymore - pick randomly another region in proximity.
      const previousRegion = REGION_BY_ID[selectedRegion];
      const newServiceId = getDefaultRegion(previousRegion.country, regions).id;
      setSelectedRegion(newServiceId);
    }
    setServiceTier(tier);
  };

  const handleProviderChange = (provider: CloudProvider): void => {
    if (provider === selectedProvider) {
      return;
    }
    let currentServiceTier = serviceTier;
    if (
      !isDevAvailableInCloudProvider(provider) &&
      serviceTier === 'Development'
    ) {
      setServiceTier('Production');
      currentServiceTier = 'Production';
    }
    const regions = getProviderRegions(provider, currentServiceTier);
    setProviderAvailableRegions(regions);
    setSelectedProvider(provider);
    setSelectedRegion(
      getDefaultRegion(
        user.countryCode,
        getProviderRegions(provider, currentServiceTier)
      ).id
    );
    Galaxy.galaxy().track(
      `${
        type === 'default' ? 'service' : 'onboarding'
      }Page.createNewService.providerChange`,
      {
        interaction: 'click',
        provider
      }
    );
  };

  const isAzureEnabled =
    currentOrg.features.includes('FT_ORG_AZURE') ||
    currentOrg.features.includes('FT_ORG_AZURE_PRIVATE_PREVIEW');
  const comingSoon = !isAzureEnabled && selectedProvider === 'AZURE';

  const joinWaitlist = (): void => {
    handleProviderChange(availableCloudProviders[0]);
  };

  return (
    <Container data-testid="create-new-service" orientation="vertical" gap="lg">
      <TextField
        error={validationMessage}
        placeholder="A short, unique name for your service"
        label="Service name"
        value={serviceName}
        autoFocus={serviceName.length === 0}
        onChange={onNameChange}
        data-testid="service-name-input"
      />
      <Separator size="xs" />
      <CloudProviderSelection
        selectedProvider={selectedProvider}
        onProviderChange={handleProviderChange}
        availableProviders={
          isAzureEnabled || currentOrg.tackleState || isOrgByoc(currentOrg)
            ? availableCloudProviders
            : [...availableCloudProviders, 'AZURE']
        }
      />

      <Container gap="none" orientation="vertical">
        <AnimatedContainer $show={comingSoon} data-testid="azure-coming-soon">
          <AzureWaitlist
            onClick={joinWaitlist}
            setSelectedProvider={setSelectedProvider}
          />
        </AnimatedContainer>
        <AnimatedContainer
          $show={!comingSoon}
          data-testid="service-form-section"
        >
          <ServiceForm
            currentOrganizationId={currentOrg.id}
            regions={providerAvailableRegions}
            byocAccounts={byocProviderConfig}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            selectedByocAccount={selectedByocAccount}
            setSelectedByocAccount={setSelectedByocAccount}
            serviceTier={serviceTier}
            onServiceTierChange={handleServiceTierChange}
            onCreateService={onCreateService}
            serviceName={serviceName}
            handleNameError={handleNameError}
            shouldShowAzureToc={
              currentOrg.features.includes('FT_ORG_AZURE_PRIVATE_PREVIEW') &&
              !currentOrg.privatePreviewTermsAccepted
            }
            disableTierSelection={
              !isDevAvailableInCloudProvider(selectedProvider) ||
              isOrgByoc(currentOrg)
            }
            type={type}
          />
        </AnimatedContainer>
      </Container>
    </Container>
  );
};
