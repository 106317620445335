import { Dialog } from '@clickhouse/click-ui';
import { MaintenanceWindowInfoMetadata } from '@cp/common/protocol/Instance';
import { ReactElement } from 'react';
import MultiSTSMigrationInfo from 'src/components/MaintenanceWindowInfoModal/MultiSTSMigrationInfo';
import SMTMigrationInfo from 'src/components/MaintenanceWindowInfoModal/SMTMigrationInfo';
import {
  formatTime,
  humanizeDuration
} from 'src/lib/formatters/dateTimeFormatter';

interface Props {
  isOpen: boolean;
  windows: Array<MaintenanceWindowInfoMetadata>;
  onClose: () => void;
}

export default function MaintenanceWindowInfoModal({
  isOpen,
  windows,
  onClose
}: Props): ReactElement {
  if (windows.length === 0) {
    return <></>;
  }
  const firstWindow = windows[0];
  const duration = humanizeDuration(
    firstWindow.endMaintenanceTime - firstWindow.startMaintenanceTime,
    'milliseconds'
  );
  const startTime = firstWindow
    ? formatTime(firstWindow.startMaintenanceTime)
    : '';
  const endTime = firstWindow ? formatTime(firstWindow.endMaintenanceTime) : '';
  const windowName = firstWindow.maintenanceInfoName
    ? ` - ${firstWindow.maintenanceInfoName}`
    : '';
  const scheduledInstancesNames = windows.map((window) => window.instanceName);

  let infoContent: ReactElement;
  switch (firstWindow.migrationKind) {
    case 'SMT':
      infoContent = (
        <SMTMigrationInfo
          endTime={endTime}
          scheduledInstancesNames={scheduledInstancesNames}
          onClose={onClose}
        ></SMTMigrationInfo>
      );
      break;
    case 'MultiSTS':
    default:
      infoContent = (
        <MultiSTSMigrationInfo
          startTime={startTime}
          duration={duration}
          scheduledInstancesNames={scheduledInstancesNames}
          onClose={onClose}
        ></MultiSTSMigrationInfo>
      );
      break;
  }
  const title = firstWindow.initiateWhenStopped
    ? `Maintenance available${windowName}`
    : `Scheduled maintenance${windowName}`;
  return (
    <Dialog open={isOpen && windows.length > 0}>
      <Dialog.Content
        title={title}
        showClose
        onClose={onClose}
        data-testid="maintenance-window-info-dialog"
      >
        {infoContent}
      </Dialog.Content>
    </Dialog>
  );
}
