import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import config from 'src/lib/config';
import { ApiClient, ServicesPerOrganization } from 'src/lib/controlPlane/client';
import { routes } from 'src/lib/routes';
import { getCurrentService } from 'src/state/service';

class ServiceInitializer {
  private api: ApiClient;

  constructor(
    api: ApiClient,
    private setOrgsWithServices: (orgs: ServicesPerOrganization[]) => void
  ) {
    this.api = api;
  }

  async initialize(): Promise<void> {
    const servicesPerOrg = await this.api.listServices();
    const orgsWithServices = servicesPerOrg.flatMap((org) => {
      if (org.services.length > 0) {
        return org;
      }
      return [];
    });
    this.setOrgsWithServices(orgsWithServices);

    const allServices = servicesPerOrg.flatMap((org) => org.services);

    if (allServices.length === 0) {
      // this means the user has nothing to do in SQL-Console and should create a service in CP
      // TODO: Whenever the logic of onboarding and service creation moves to SQL-Console this should be changed accordingly
      // navigateTo(routes.root());
      window.location.href = config.controlPlane.host;
      return;
    }

    const currentService = getCurrentService(orgsWithServices);

    if (currentService) {
      return;
    } else if (allServices.length === 1) {
      const service = allServices[0];
      const url = routes.home({ serviceId: service.id });
      navigateTo(url);
    } else {
      const url = routes.services();
      navigateTo(url);
    }
  }
}

export default ServiceInitializer;
