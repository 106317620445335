export const statusCode = (error: any): number => {
  let statusCode = 500;

  if (error instanceof ClientError) {
    statusCode = error.code || 400;
  }

  return statusCode;
};

export const errorMessage = (error: any): string => {
  if (error instanceof Error || error instanceof ClientError) {
    return error.message;
  } else if (error?.error && typeof error.error === 'string') {
    return error.error;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return String(error);
  }
};

export class ClientError extends Error {
  code: number;

  constructor(code: number, message: string) {
    super(message);
    this.code = code;
  }
}
