import { Select } from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import { sidebarLogEvent } from 'src/components/QueryView/analytics';
import { ApiEndpointsList } from 'src/components/QueryView/QueryList/ApiEndpointsList';

import { QueryHistory } from 'src/components/QueryView/QueryList/History';
import Queries from 'src/components/QueryView/QueryList/Queries';
import styles from 'src/components/QueryView/QueryList/styles';
import {
  useNumApiEndpoints,
  useQueryApiEndpoints
} from 'src/queryEndpoints/stateManager';

const switcherLogName = 'switcher';

const queriesList = ['queries', 'myQueries', 'sharedQueries', 'unownedQueries'];

export default function QueryList(): ReactElement {
  const [selectedValue, setSelectedValue] = useState('queries');
  const numApiEndpoints = useNumApiEndpoints();
  const endpoints = useQueryApiEndpoints();

  const onSelectChange = (value: string): void => {
    setSelectedValue(value);
    sidebarLogEvent(switcherLogName, 'Select');
  };

  return (
    <div css={styles.container}>
      <div css={styles.databaseSelectorRow}>
        <Select
          data-testid="queryListSwitcher"
          value={selectedValue}
          onSelect={onSelectChange}
          onClick={(): void =>
            sidebarLogEvent(switcherLogName, 'Focus', 'trigger')
          }
          onBlur={(): void =>
            sidebarLogEvent(switcherLogName, 'Blur', 'trigger')
          }
        >
          <Select.Item value="queries">All queries</Select.Item>
          <Select.Item value="myQueries">My queries</Select.Item>
          <Select.Item value="sharedQueries">Shared with me</Select.Item>
          <Select.Item value="unownedQueries">Archived queries</Select.Item>
          <Select.Item value="history">Query history</Select.Item>
          <Select.Item value="endpoints">
            API endpoints {`(${numApiEndpoints})`}
          </Select.Item>
        </Select>
      </div>
      {selectedValue === 'history' && <QueryHistory />}
      {queriesList.includes(selectedValue) && (
        <Queries queryFilter={selectedValue} />
      )}
      {selectedValue === 'endpoints' && (
        <ApiEndpointsList endpoints={endpoints} />
      )}
    </div>
  );
}
