import { Container, IconButton } from '@clickhouse/click-ui';
import { flexFixedSize } from 'global-styles';
import { ReactElement } from 'react';
import { useUserAndOrgRolesHasPermissionForInstance } from 'src/authorization/authorizationState';
import Tabs from 'src/components/ConsoleTopBar/Tabs';
import RunningQueryList from 'src/components/RunningQueryList';
import { useCurrentOrgUserRole } from 'src/organization/organizationState';
import { useConnectionCredentials } from 'src/state/connection';

function ConsoleTopBar(): ReactElement {
  const hasManageServicePermission = useUserAndOrgRolesHasPermissionForInstance(
    'control-plane:service:manage',
    useCurrentOrgUserRole() === 'ADMIN'
  );

  const { setCredentialModalOpen } = useConnectionCredentials();
  return (
    <>
      <Container isResponsive={false} fillHeight overflow="hidden">
        <Tabs />
      </Container>
      <Container isResponsive={false} fillHeight fillWidth={false}>
        {!hasManageServicePermission && (
          <IconButton
            icon="key"
            type="ghost"
            onClick={(): void => {
              setCredentialModalOpen(true);
            }}
          />
        )}
        <div css={flexFixedSize}>
          <RunningQueryList />
        </div>
      </Container>
    </>
  );
}

export default ConsoleTopBar;
