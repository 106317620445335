import { ReactElement } from 'react';
import {
  Container,
  IconButton,
  Select,
  SelectOptionListItem,
  TextField
} from '@clickhouse/click-ui';
import { OrganizationRole } from '@cp/common/protocol/Organization';
import {
  OnChangeProp,
  onEmailBlurProp
} from 'src/inviteMembers/controller/useInviteMembers';
import styled from 'styled-components';

export interface InviteMemberElementProps {
  email?: string;
  role?: OrganizationRole | null;
  index: number;
  onChange: (props: OnChangeProp) => void;
  onEmailBlur: (props: onEmailBlurProp) => void;
  onEmailFocus: (props: { index: number }) => void;
  error?: string;
  onDelete?: (props: { index: number }) => void;
}
const IconButtonContainer = styled.div`
  margin-top: 1.5rem;
`;

const roleOptions: Array<SelectOptionListItem> = [
  {
    label: 'Admin',
    value: 'ADMIN',
    'data-testid': 'role-option',
    'data-testid-value': 'ADMIN'
  },
  {
    label: 'Developer',
    value: 'DEVELOPER',
    'data-testid': 'role-option',
    'data-testid-value': 'DEVELOPER'
  }
];

export const InviteMemberElement = ({
  email = '',
  role,
  onChange,
  index,
  onEmailBlur,
  onEmailFocus,
  error,
  onDelete
}: InviteMemberElementProps): ReactElement => {
  return (
    <Container
      orientation="horizontal"
      padding="none"
      gap="lg"
      alignItems="start"
      fillWidth
    >
      <TextField
        label="Invite members"
        data-testid={`email-input-${index}`}
        placeholder="Email address"
        type="text"
        onChange={(inputValue): void => {
          onChange({
            email: inputValue,
            index
          });
        }}
        onFocus={() => {
          onEmailFocus({ index });
        }}
        onBlur={(): void => {
          onEmailBlur({ email, index });
        }}
        value={email}
        error={error}
      />
      <Select
        value={role ?? undefined}
        label="Role"
        data-testid={`role-input-${index}`}
        onSelect={(value): void => {
          onChange({
            role: value as OrganizationRole,
            index
          });
        }}
        options={roleOptions}
      />
      {typeof onDelete === 'function' && (
        <IconButtonContainer>
          <IconButton icon="cross" onClick={() => onDelete({ index })} />
        </IconButtonContainer>
      )}
    </Container>
  );
};
