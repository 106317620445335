import { GettingStartedSteps } from '@cp/common/protocol/Instance';

type VideoType = {
  type: 'video';
  href: string;
  text: never;
};
type HrefType = {
  type: 'href';
  href: string;
  text: string;
};

export type GettingStartedOptionType = VideoType | HrefType;
export const GETTING_STARTED_OPTIONS: Record<keyof GettingStartedSteps, Array<GettingStartedOptionType>> = {
  createService: [
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/guides/developer/cascading-materialized-views',
      text: 'Advanced tutorial'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/guides/creating-tables',
      text: 'Creating tables'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/guides/inserting-data',
      text: 'Inserting data'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/sql-reference',
      text: 'SQL reference'
    }
  ],
  loadData: [
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/migration/clickhouse-to-cloud',
      text: 'Migrating from ClickHouse to ClickHouse Cloud'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/migration/clickhouse-local',
      text: 'Migrating to ClickHouse using clickhouse-local'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/migration/etl-tool-to-clickhouse',
      text: 'Ingest data using a 3rd-party ETL tool'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/integrations/postgresql',
      text: 'Connecting ClickHouse to PostgreSQL'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/integrations/mysql',
      text: 'Integrating MySQL with ClickHouse'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/migrations/snowflake',
      text: 'Migrating from Snowflake to ClickHouse Cloud'
    }
  ],
  idlingOptions: [
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/manage/scaling#automatic-idling',
      text: 'Automatic idling'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/manage/scaling',
      text: 'How autoscaling works in ClickHouse Cloud'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/manage/backups#how-backups-work-in-clickhouse-cloud',
      text: 'How backups work'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/manage/openapi',
      text: 'Managing API keys'
    }
  ],
  inviteMembers: [
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/security/cloud-access-management',
      text: 'Access control in ClickHouse Cloud'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/security/cloud-access-management#sql-console-roles',
      text: 'Creating SQL console roles'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/security/secure-s3',
      text: 'S3 role-based access'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/security/shared-responsibility-model',
      text: 'Security best practices'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/cloud/security/cmek',
      text: 'Customer managed Encryption Keys (CMEK)'
    }
  ],
  visitedIntegration: [
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/integrations/clickpipes',
      text: 'ClickPipes'
    },
    {
      type: 'href',
      href: 'https://clickhouse.com/docs/en/integrations',
      text: 'Integration docs'
    }
  ]
};
