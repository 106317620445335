type FunctionalVoidRef<T> = (value: T | null) => void;
type ReferencesArray<T> = (FunctionalVoidRef<T> | React.ForwardedRef<T | null>)[];
export const mergeRef =
  <T>(references: ReferencesArray<T>) =>
  (value: T | null): void => {
    references.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
