import { CHStructureDescriber } from 'src/lib/dataLoading/describe';
import { parseJsonDataFromString } from 'src/lib/dataLoading/parseData/json';
import { parseTsvRowsFromData } from 'src/lib/dataLoading/parseData/tsv';
import { FileFormatType, FileType } from 'shared/src/dataLoading';
import { RowPreview } from 'shared/src/dataLoading';
import { assertCsvFormatType, assertJsonFormatType, assertTsvFormatType } from 'shared/src/dataLoading/utils';
import { EditColumn } from 'shared/src/tableSchema';

import { parseCsvDataFromInput } from 'src/lib/dataLoading/parseData/csv';

export const MAX_SCHEMA_INFERENCE_SIZE = 20;

export interface PreviewData {
  rows: RowPreview[][];
  columns: EditColumn[];
  separator?: string;
}

export async function parseData(
  fileSampleRaw: string,
  formatType: FileFormatType | undefined,
  fileType: FileType,
  describer: CHStructureDescriber
): Promise<PreviewData> {
  if (!fileType || !formatType) {
    return {
      columns: [],
      rows: []
    };
  }

  switch (fileType) {
    case 'text/csv': {
      assertCsvFormatType(formatType);

      const [rows, meta] = await parseCsvDataFromInput(fileSampleRaw, formatType);

      if (!meta) {
        throw new Error('Could not parse csv file');
      }

      const columns = await describer.describeCsvFromData(fileSampleRaw, {
        separator: meta.delimiter,
        format: formatType
      });

      return { columns, rows, separator: meta.delimiter };
    }
    case 'text/tab-separated-values': {
      assertTsvFormatType(formatType);
      const [rows, rawData] = await parseTsvRowsFromData(fileSampleRaw, formatType);
      const columns = await describer.describeTsvFromData(rawData, formatType);
      return { columns, rows };
    }
    case 'application/json': {
      assertJsonFormatType(formatType);
      const [rows, rawData] = parseJsonDataFromString(fileSampleRaw);
      const columns = await describer.describeJsonFromData(rawData, formatType);
      return { columns, rows };
    }

    default:
      return {
        columns: [],
        rows: []
      };
  }
}
