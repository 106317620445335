import {
  Container,
  Icon,
  IconName,
  IconButton,
  Title
} from '@clickhouse/click-ui';
import { CreditCardBrand } from '@cp/common/protocol/Billing';
import { truncate } from 'lodash';
import { ReactElement } from 'react';

export const HeaderBoxContentContainer = ({
  text,
  cardIcon,
  testId,
  onEditClick,
  isLoading
}: ContentContainerProps): ReactElement => (
  <Container justifyContent="space-between" isResponsive={false}>
    <Container gap="md" isResponsive={false}>
      {(cardIcon as IconName) && (
        <Icon name={cardIcon as IconName} width="1.75rem" height="1.5rem" />
      )}
      {isLoading ? (
        <Icon name="horizontal-loading" />
      ) : (
        <Title type="h4" size="md" data-testid={testId + '-text'}>
          {cardIcon && '**** '}
          {truncate(text || '', { length: 28 })}
        </Title>
      )}
    </Container>
    {testId && onEditClick && (
      <IconButton
        type="ghost"
        icon="pencil"
        data-testid={testId}
        onClick={onEditClick}
        size="sm"
      />
    )}
  </Container>
);

export interface ContentContainerProps {
  text: string | null;
  cardIcon?: CreditCardBrand;
  testId?: string;
  onEditClick?: () => void;
  isLoading: boolean;
}
