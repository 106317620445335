import type { languages } from 'monaco-editor';

import { keywords } from 'src/lib/monaco-ch-language/tokens';

const conf: languages.LanguageConfiguration = {
  comments: {
    lineComment: '--',
    blockComment: ['/*', '*/']
  },
  brackets: [
    ['{', '}'],
    ['[', ']'],
    ['(', ')']
  ],
  autoClosingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" }
  ],
  surroundingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" }
  ]
};

const language: languages.IMonarchLanguage = {
  defaultToken: '',
  tokenPostfix: '.sql',
  ignoreCase: true,
  brackets: [
    { open: '[', close: ']', token: 'delimiter.square' },
    { open: '(', close: ')', token: 'delimiter.parenthesis' }
  ],
  keywords: keywords,
  operators: ['NULL'],
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
  builtinFunctions: [
    'abbrev',
    'abs',
    'acldefault',
    'aclexplode',
    'acos',
    'acosd',
    'acosh',
    'age',
    'any',
    'area',
    'array_agg',
    'array_append',
    'array_cat',
    'array_dims',
    'array_fill',
    'array_length',
    'array_lower',
    'array_ndims',
    'array_position',
    'array_positions',
    'array_prepend',
    'array_remove',
    'array_replace',
    'array_to_json',
    'array_to_string',
    'array_to_tsvector',
    'array_upper',
    'ascii',
    'asin',
    'asind',
    'asinh',
    'atan',
    'atan2',
    'atan2d',
    'atand',
    'atanh',
    'avg',
    'bit',
    'bit_and',
    'bit_count',
    'bit_length',
    'bit_or',
    'bit_xor',
    'bool_and',
    'bool_or',
    'bound_box',
    'box',
    'brin_desummarize_range',
    'brin_summarize_new_values',
    'brin_summarize_range',
    'broadcast',
    'btrim',
    'cardinality',
    'cbrt',
    'ceil',
    'ceiling',
    'center',
    'char_length',
    'character_length',
    'chr',
    'circle',
    'clock_timestamp',
    'coalesce',
    'col_description',
    'concat',
    'concat_ws',
    'convert',
    'convert_from',
    'convert_to',
    'corr',
    'cos',
    'cosd',
    'cosh',
    'cot',
    'cotd',
    'count',
    'covar_pop',
    'covar_samp',
    'cume_dist',
    'current_catalog',
    'current_database',
    'current_date',
    'current_query',
    'current_role',
    'current_schema',
    'current_schemas',
    'current_setting',
    'current_time',
    'current_timestamp',
    'current_user',
    'currval',
    'cursor_to_xml',
    'cursor_to_xmlschema',
    'date_bin',
    'date_part',
    'date_trunc',
    'database_to_xml',
    'database_to_xml_and_xmlschema',
    'database_to_xmlschema',
    'decode',
    'degrees',
    'dense_rank',
    'diagonal',
    'diameter',
    'div',
    'encode',
    'enum_first',
    'enum_last',
    'enum_range',
    'every',
    'exp',
    'extract',
    'factorial',
    'family',
    'first_value',
    'floor',
    'format',
    'format_type',
    'gcd',
    'gen_random_uuid',
    'generate_series',
    'generate_subscripts',
    'get_bit',
    'get_byte',
    'get_current_ts_config',
    'gin_clean_pending_list',
    'greatest',
    'grouping',
    'has_any_column_privilege',
    'has_column_privilege',
    'has_database_privilege',
    'has_foreign_data_wrapper_privilege',
    'has_function_privilege',
    'has_language_privilege',
    'has_schema_privilege',
    'has_sequence_privilege',
    'has_server_privilege',
    'has_table_privilege',
    'has_tablespace_privilege',
    'has_type_privilege',
    'height',
    'host',
    'hostmask',
    'inet_client_addr',
    'inet_client_port',
    'inet_merge',
    'inet_same_family',
    'inet_server_addr',
    'inet_server_port',
    'initcap',
    'isclosed',
    'isempty',
    'isfinite',
    'isopen',
    'json_agg',
    'json_array_elements',
    'json_array_elements_text',
    'json_array_length',
    'json_build_array',
    'json_build_object',
    'json_each',
    'json_each_text',
    'json_extract_path',
    'json_extract_path_text',
    'json_object',
    'json_object_agg',
    'json_object_keys',
    'json_populate_record',
    'json_populate_recordset',
    'json_strip_nulls',
    'json_to_record',
    'json_to_recordset',
    'json_to_tsvector',
    'json_typeof',
    'jsonb_agg',
    'jsonb_array_elements',
    'jsonb_array_elements_text',
    'jsonb_array_length',
    'jsonb_build_array',
    'jsonb_build_object',
    'jsonb_each',
    'jsonb_each_text',
    'jsonb_extract_path',
    'jsonb_extract_path_text',
    'jsonb_insert',
    'jsonb_object',
    'jsonb_object_agg',
    'jsonb_object_keys',
    'jsonb_path_exists',
    'jsonb_path_match',
    'jsonb_path_query',
    'jsonb_path_query_array',
    'jsonb_path_exists_tz',
    'jsonb_path_query_first',
    'jsonb_path_query_array_tz',
    'jsonb_path_query_first_tz',
    'jsonb_path_query_tz',
    'jsonb_path_match_tz',
    'jsonb_populate_record',
    'jsonb_populate_recordset',
    'jsonb_pretty',
    'jsonb_set',
    'jsonb_set_lax',
    'jsonb_strip_nulls',
    'jsonb_to_record',
    'jsonb_to_recordset',
    'jsonb_to_tsvector',
    'jsonb_typeof',
    'justify_days',
    'justify_hours',
    'justify_interval',
    'lag',
    'last_value',
    'lastval',
    'lcm',
    'lead',
    'least',
    'left',
    'length',
    'line',
    'ln',
    'localtime',
    'localtimestamp',
    'log',
    'log10',
    'lower',
    'lower_inc',
    'lower_inf',
    'lpad',
    'lseg',
    'ltrim',
    'macaddr8_set7bit',
    'make_date',
    'make_interval',
    'make_time',
    'make_timestamp',
    'make_timestamptz',
    'makeaclitem',
    'masklen',
    'max',
    'md5',
    'min',
    'min_scale',
    'mod',
    'mode',
    'multirange',
    'netmask',
    'network',
    'nextval',
    'normalize',
    'now',
    'npoints',
    'nth_value',
    'ntile',
    'nullif',
    'num_nonnulls',
    'num_nulls',
    'numnode',
    'obj_description',
    'octet_length',
    'overlay',
    'parse_ident',
    'path',
    'pclose',
    'percent_rank',
    'percentile_cont',
    'percentile_disc',
    'phraseto_tsquery',
    'pi',
    'plainto_tsquery',
    'point',
    'polygon',
    'popen',
    'position',
    'power',
    'pqserverversion',
    'query_to_xml',
    'query_to_xml_and_xmlschema',
    'query_to_xmlschema',
    'querytree',
    'quote_ident',
    'quote_literal',
    'quote_nullable',
    'radians',
    'radius',
    'random',
    'range_agg',
    'range_intersect_agg',
    'range_merge',
    'rank',
    'regexp_match',
    'regexp_matches',
    'regexp_replace',
    'regexp_split_to_array',
    'regexp_split_to_table',
    'regr_avgx',
    'regr_avgy',
    'regr_count',
    'regr_intercept',
    'regr_r2',
    'regr_slope',
    'regr_sxx',
    'regr_sxy',
    'regr_syy',
    'repeat',
    'replace',
    'reverse',
    'right',
    'round',
    'row_number',
    'row_security_active',
    'row_to_json',
    'rpad',
    'rtrim',
    'scale',
    'schema_to_xml',
    'schema_to_xml_and_xmlschema',
    'schema_to_xmlschema',
    'session_user',
    'set_bit',
    'set_byte',
    'set_config',
    'set_masklen',
    'setseed',
    'setval',
    'setweight',
    'sha224',
    'sha256',
    'sha384',
    'sha512',
    'shobj_description',
    'sign',
    'sin',
    'sind',
    'sinh',
    'slope',
    'split_part',
    'sprintf',
    'sqrt',
    'starts_with',
    'statement_timestamp',
    'stddev',
    'stddev_pop',
    'stddev_samp',
    'string_agg',
    'string_to_array',
    'string_to_table',
    'strip',
    'strpos',
    'substr',
    'substring',
    'sum',
    'suppress_redundant_updates_trigger',
    'table_to_xml',
    'table_to_xml_and_xmlschema',
    'table_to_xmlschema',
    'tan',
    'tand',
    'tanh',
    'text',
    'timeofday',
    'timezone',
    'to_ascii',
    'to_char',
    'to_date',
    'to_hex',
    'to_json',
    'to_number',
    'to_regclass',
    'to_regcollation',
    'to_regnamespace',
    'to_regoper',
    'to_regoperator',
    'to_regproc',
    'to_regprocedure',
    'to_regrole',
    'to_regtype',
    'to_timestamp',
    'to_tsquery',
    'to_tsvector',
    'transaction_timestamp',
    'translate',
    'trim',
    'trim_array',
    'trim_scale',
    'trunc',
    'ts_debug',
    'ts_delete',
    'ts_filter',
    'ts_headline',
    'ts_lexize',
    'ts_parse',
    'ts_rank',
    'ts_rank_cd',
    'ts_rewrite',
    'ts_stat',
    'ts_token_type',
    'tsquery_phrase',
    'tsvector_to_array',
    'tsvector_update_trigger',
    'tsvector_update_trigger_column',
    'txid_current',
    'txid_current_if_assigned',
    'txid_current_snapshot',
    'txid_snapshot_xip',
    'txid_snapshot_xmax',
    'txid_snapshot_xmin',
    'txid_status',
    'txid_visible_in_snapshot',
    'unistr',
    'unnest',
    'upper',
    'upper_inc',
    'upper_inf',
    'user',
    'var_pop',
    'var_samp',
    'variance',
    'version',
    'websearch_to_tsquery',
    'width',
    'width_bucket',
    'xml_is_well_formed',
    'xml_is_well_formed_content',
    'xml_is_well_formed_document',
    'xmlagg',
    'xmlcomment',
    'xmlconcat',
    'xmlelement',
    'xmlexists',
    'xmlforest',
    'xmlparse',
    'xmlpi',
    'xmlroot',
    'xmlserialize',
    'xpath',
    'xpath_exists'
  ],
  builtinVariables: [],
  pseudoColumns: [],
  tokenizer: {
    root: [
      { include: '@comments' },
      { include: '@whitespace' },
      { include: '@pseudoColumns' },
      { include: '@numbers' },
      { include: '@strings' },
      { include: '@complexIdentifiers' },
      { include: '@scopes' },
      [/[;,.]/, 'delimiter'],
      [/[()]/, '@brackets'],
      [
        /[\w@#$]+/,
        {
          cases: {
            '@operators': 'operator',
            '@builtinVariables': 'predefined',
            '@builtinFunctions': 'predefined',
            '@keywords': 'keyword',
            '@default': 'identifier'
          }
        }
      ],
      [/[<>=!%&+\-*/|~^]/, 'operator']
    ],
    whitespace: [[/\s+/, 'white']],
    comments: [
      [/--+.*/, 'comment'],
      [/# .*/, 'comment'], // in clickhouse # must be followed by space or !
      [/#!.*/, 'comment'], // in clickhouse # must be followed by space or !
      [/\/\*/, { token: 'comment.quote', next: '@comment' }]
    ],
    comment: [
      [/[^*/]+/, 'comment'],
      [/\*\//, { token: 'comment.quote', next: '@pop' }],
      [/./, 'comment']
    ],
    pseudoColumns: [
      [
        /[$][A-Za-z_][\w@#$]*/,
        {
          cases: {
            '@pseudoColumns': 'predefined',
            '@default': 'identifier'
          }
        }
      ]
    ],
    numbers: [
      [/0[xX][0-9a-fA-F]*/, 'number'],
      [/[$][+-]*\d*(\.\d*)?/, 'number'],
      [/((\d+(\.\d*)?)|(\.\d+))([eE][-+]?\d+)?/, 'number']
    ],
    strings: [[/'/, { token: 'string', next: '@string' }]],
    string: [
      [/[^\\']+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/''/, 'string'],
      [/'/, { token: 'string', next: '@pop' }]
    ],
    complexIdentifiers: [[/"/, { token: 'identifier.quote', next: '@quotedIdentifier' }]],
    quotedIdentifier: [
      [/[^"]+/, 'identifier'],
      [/""/, 'identifier'],
      [/"/, { token: 'identifier.quote', next: '@pop' }]
    ],
    scopes: []
  }
};
export { conf, language };
