import { HeatmapChartValue, HeatmapSeriesDescriptor, YValue } from '@clickhouse/viz-house';

interface DataItem {
  [key: string]: number | string;
}

export type VizHouseHeatmapChartProps = {
  data: DataItem[];
  xAxis: string;
  yAxis: string;
  zAxis: string;
  title?: string;

  footer?: string;
  editable?: boolean;
  subtitle?: string;

  xAxisTickVertical?: boolean;
  xAxisLabel?: string;
  yAxisLabel?: string;

  showDataLabel?: boolean;
};

type SeriesAndCategories = {
  series: HeatmapSeriesDescriptor[];
  categories: string[];
};

/**
 * Converts the input data to a series format for a viz-house heatmap chart and extracts unique categories.
 * @param {VizHouseHeatmapChartProps} chartProps - The input data object containing the data and axis information.
 * @returns {{ series: HeatmapSeriesDescriptor[], categories: Record<string | number, boolean> }} - The converted
 *          series data for the heatmap chart and the unique categories.
 */
export function convertToSeriesAndCategories(chartProps: VizHouseHeatmapChartProps): SeriesAndCategories {
  try {
    const { data, xAxis, yAxis, zAxis, title } = chartProps;
    if (!data || !xAxis || !yAxis || !zAxis) {
      throw new Error('Missing required fields in the input data.');
    }

    // Make a list of unique categories
    const categoryIndex: Record<string | number, number> = {};
    const categories: string[] = [];
    data.forEach((item) => {
      const category = item[yAxis];
      if (categoryIndex[category] === undefined) {
        categories.push(category.toString());
        categoryIndex[category] = categories.length - 1;
      }
    });

    // Build the series data
    const values: HeatmapChartValue[] = data.map((item) => {
      const category = item[yAxis];
      const idx = categoryIndex[category];
      return {
        x: item[xAxis],
        y: idx,
        value: item[zAxis] as YValue
      };
    });

    return {
      series: [
        {
          name: title ?? '',
          values: values
        }
      ],
      categories
    };
  } catch (error) {
    console.error('Error converting data to series', error);
    return {
      series: [],
      categories: []
    };
  }
}
