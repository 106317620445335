import { css } from '@emotion/react';

export default {
  rowStyle: css({
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    gap: '1rem 1.5rem',
    'input[type=number]': {
      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    }
  }),
  hostDetails: css({
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    width: '100%',
    gap: 'inherit'
  }),

  inputLabelStyle: css({
    width: '100%',
    label: {
      minWidth: 57
    }
  }),

  modalStyle: css({
    width: '50%'
  }),
  footerStyle: css({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    button: {
      minWidth: '10rem'
    }
  })
};
