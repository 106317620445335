import { type UsageMetric, type UsageMetricViewType } from '@cp/common/protocol/Billing';
import type { DeepReadonly } from '@cp/common/protocol/Common';
import type { OrganizationBillingStatus } from '@cp/common/protocol/Organization';
import { truthy } from '@cp/common/utils/Assert';

/** See https://www.figma.com/file/K7PTQpRcJL5lwEjftWo19l/Pricing-page. */
const SHORT_USAGE_METRIC_NAME_BY_TYPE: Readonly<Record<UsageMetric, string>> = {
  COMPUTE_UNIT_MINUTES: 'Compute',
  STORAGE_GB_MINUTES_TABLES: 'Storage',
  STORAGE_GB_MINUTES_PAID_BACKUPS: 'Backups'
};

/** See https://www.figma.com/file/nY7msWLhHz0PNRdMsd5w4Q/Billing-updates?node-id=219%3A6893&t=bOUo8LYDhWIgfdVL-0 */
const SHORT_USAGE_VIEW_TYPE_NAME: Readonly<Record<UsageMetricViewType, string>> = {
  BILLING: 'Billing Units',
  WEBSITE: 'Website Units',
  UNITS: 'Units',
  COST: 'Cost',
  CHC: '(CHCs)'
};

/**
 * Returns a short display name for the usage metric.
 * This name may be used for table column names or chart series.
 */
export function getShortUsageMetricName(metric: UsageMetric): string {
  return SHORT_USAGE_METRIC_NAME_BY_TYPE[metric];
}

/**
 * Returns a short display name for the usage metric.
 * This name may be used for table column names or chart series.
 */
export function getShortUsageMetricNameWithViewType(metric: UsageMetric, viewType: UsageMetricViewType): string {
  return ['COST', 'UNITS'].includes(viewType)
    ? SHORT_USAGE_VIEW_TYPE_NAME[viewType]
    : `${getShortUsageMetricName(metric)} ${SHORT_USAGE_VIEW_TYPE_NAME[viewType]}`;
}

/** See https://www.figma.com/file/K7PTQpRcJL5lwEjftWo19l/Pricing-page. */
const USAGE_METRIC_DESCRIPTION_BY_TYPE: DeepReadonly<Record<UsageMetric, Record<UsageMetricViewType, string>>> = {
  COMPUTE_UNIT_MINUTES: {
    BILLING:
      'Compute Billing Units\n' + '1 compute unit = 8GB RAM, 1CPU\n' + 'Metering unit: per compute unit / minute',
    WEBSITE:
      'Compute Website Units \n' +
      '1 Production Unit = 24GB RAM, 6 vCPUs\n' +
      '1 Development Unit = 16GB RAM, 2 vCPUs\n' +
      'Metering unit: per compute unit / hour\n' +
      '\n' +
      'View pricing page for more details.',
    UNITS:
      'Compute Units \n' +
      '1 Production Unit = 24GB RAM, 6 vCPUs\n' +
      '1 Development Unit = 16GB RAM, 2 vCPUs\n' +
      'Metering unit: per compute unit / hour\n' +
      '\n' +
      'View pricing page for more details.',
    COST: '',
    CHC: ''
  },
  STORAGE_GB_MINUTES_TABLES: {
    BILLING:
      'Storage Billing Units.\n' +
      'Data is compressed before it’s stored, saving you up to 90%.\n' +
      'Metering unit: per 1GB / minute',
    WEBSITE:
      'Storage Website Units\n' +
      'Data is compressed before it’s stored, saving you up to 90%.\n' +
      'Metering unit: per 1TB / month\n' +
      '\n' +
      'View pricing page for more details.',
    UNITS:
      'Storage Units\n' +
      'Data is compressed before it’s stored, saving you up to 90%.\n' +
      'Metering unit: per 1TB / month\n' +
      '\n' +
      'View pricing page for more details.',
    COST: '',
    CHC: ''
  },
  STORAGE_GB_MINUTES_PAID_BACKUPS: {
    BILLING:
      'Backups Billing Units.\n' +
      'Data is compressed before it’s stored, saving you up to 90%.\n' +
      'Metering unit: per 1GB / minute',
    WEBSITE:
      'Storage Website Units\n' +
      'Data is compressed before it’s stored, saving you up to 90%.\n' +
      'Metering unit: per 1TB / month\n' +
      '\n' +
      'View pricing page for more details.',
    UNITS:
      'Storage Units\n' +
      'Data is compressed before it’s stored, saving you up to 90%.\n' +
      'Metering unit: per 1TB / month\n' +
      '\n' +
      'View pricing page for more details.',
    COST: '',
    CHC: ''
  }
};

/** Returns a detailed description for the metric: 2-3 sentences. Used in help tooltips. */
export function getMetricDetailedDescription(metric: UsageMetric): Readonly<Record<UsageMetricViewType, string>> {
  return truthy(USAGE_METRIC_DESCRIPTION_BY_TYPE[metric], `Unknown metric type: ${metric}`);
}

/** Returns true if the metric string is a valid usage metric. */
export function isValidUsageMetric(metric: unknown): metric is UsageMetric {
  return typeof metric === 'string' && !!SHORT_USAGE_METRIC_NAME_BY_TYPE[metric as UsageMetric];
}

/** Returns true if this organization billing status indicates that the org is paying. */
export function isPayingStatus(billingStatus: OrganizationBillingStatus): boolean {
  return ['PAID', 'REVIEW_MANUALLY', 'PREPAID', 'IN_MP_REMORSE_PERIOD', 'EMPLOYEE'].includes(billingStatus);
}
