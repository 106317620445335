import copy from 'copy-to-clipboard';

import { createToast } from 'src/components/primitives/lib/Toast';

import { Row } from 'src/components/primitives/lib/Spreadsheet/types';

function copyRows(rows: Row[]) {
  if (rows.length === 1 && rows[0].length === 1) {
    copy(rows[0][0] ?? '', { format: 'text/plain' });
    return;
  }

  const table = document.createElement('table');
  table.style.position = 'absolute';
  table.style.width = '0px';
  table.style.height = '0px';
  table.style.overflow = 'hidden';

  for (const row of rows) {
    if (row) {
      const tableRow = document.createElement('tr');
      for (const cell of row) {
        const tableCell = document.createElement('td');
        tableCell.innerText = cell ?? '';
        tableRow.appendChild(tableCell);
      }
      table.appendChild(tableRow);
    }
  }

  document.body.appendChild(table);

  const selection = window.getSelection();
  if (selection) {
    // Should not be null except in weird non-displayed iframe situations in
    // Firefox as far as I can tell
    const range = document.createRange();
    range.selectNodeContents(table);
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    document.body.removeChild(table);
  }
}

export function copyRowsAndNotify(rows: Row[]) {
  try {
    copyRows(rows);
    createToast('Copied to clipboard!', 'info', 'You can now paste this data as text');
  } catch (e) {
    console.log('error', e);
    createToast('Failed to copy to clipboard!', 'alert', 'Failed to copy value to clipboard.');
  }
}
