import { NotificationCategory, NotificationSeverity } from '@cp/common/protocol/NotificationInventory';
import { GetNotificationRequest, Notification } from '@cp/common/protocol/Notifications';
import { useCallback, useEffect, useState } from 'react';
import { createToast } from 'src/components/primitives';
import { useApiClient } from 'src/lib/controlPlane/client';
import { useSearchParam } from 'src/lib/routes/useSearchParam';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';

type useAllNotificationsReturnType = {
  notifications: Notification[];
  onSort: (sortDir: 'asc' | 'desc', header: any, index: number) => void;
  page: number;
  totalRows: number;
  totalPages: number;
  limit: number;
  search: string;
  severity: string;
  category: string;
  time: string;
  onPageChange: (pageNumber: number) => void;
  onPageSizeChange: (size: number) => void;
  onSearch: (value: string) => void;
  onSelectSeverity: (value: string) => void;
  onSelectCategory: (value: string) => void;
  onSelectTime: (value: string) => void;
  isLoading: boolean;
};

export const pageSizes = [10, 20, 50, 100];

export const useAllNotifications = (): useAllNotificationsReturnType => {
  // TODO implement sort
  const [sort, setSort] = useState<[number, 'asc' | 'desc']>([0, 'asc']);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useSearchParam('page', 1, { type: 'number' });
  const [limit, setLimit] = useSearchParam('limit', pageSizes[0], { type: 'number' });
  const [search, setSearch] = useSearchParam('search', '', { type: 'string' });
  const [severity, setSeverity] = useSearchParam('severity', '', { type: 'string' });
  const [category, setCategory] = useSearchParam('category', '', { type: 'string' });
  const [time, setTime] = useSearchParam('time', '1w', { type: 'string' }); // in short format, e.g. 1d, 2d, 1w, 2w, 1m

  // TODO implement sort
  const onSort = useCallback((sortDir: 'asc' | 'desc', header: any, index: number): void => {
    setSort([index, sortDir]);
  }, []);

  const client = useApiClient();
  const organization = useCurrentOrganizationOrThrow();

  useEffect(() => {
    const q: Pick<GetNotificationRequest, 'search' | 'severity' | 'category'> = {};
    if (search) {
      q['search'] = search;
    }
    if (severity) {
      q['severity'] = severity as NotificationSeverity;
    }
    if (category) {
      q['category'] = category as NotificationCategory;
    }
    setIsLoading(true);
    void client.notification
      .getNotifications({
        organizationId: organization.id,
        pagination: {
          limit,
          offset: (page - 1) * limit
        },
        timeFrom: time,
        ...q
      })
      .then((res) => {
        if (res.notifications.length === 0 && res.pagination.total > 0 && page > 1) {
          // If page is empty and there are multiple pages, go to the previous page
          setPage((prev) => prev - 1);
          return;
        }
        setNotifications(res.notifications);
        setTotalRows(res.pagination.total);
        setTotalPages(res.pagination.total_pages);
      })
      .catch((e) => {
        createToast('Failed to fetch notifications', 'warning', '');
        console.error('Failed to fetch notifications', e);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [client, organization, limit, page, severity, category, search, time, setPage]);

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setPage(pageNumber);
    },
    [setPage]
  );

  const onPageSizeChange = useCallback(
    (size: number) => {
      if (pageSizes.includes(size)) {
        setLimit(size);
      }
    },
    [setLimit]
  );

  return {
    notifications,
    onSort,
    page,
    totalRows,
    totalPages,
    limit,
    search,
    severity,
    category,
    time,
    isLoading,
    onPageChange,
    onPageSizeChange,
    onSearch: setSearch,
    onSelectSeverity: setSeverity,
    onSelectCategory: setCategory,
    onSelectTime: setTime
  };
};
