import {
  ConfirmationDialog,
  Icon,
  IconName,
  Label,
  Panel,
  Spacer,
  Text,
  Title,
  Container
} from '@clickhouse/click-ui';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: ${({ theme }): string =>
      theme.click.card.primary.space.sm.gap};
  }
`;

interface LiProps {
  iconName?: IconName;
  iconColor?: string;
  text: string;
}
const Li = ({ iconName = 'dot', iconColor, text }: LiProps): ReactElement => (
  <li>
    <Container gap="xs">
      <Icon name={iconName} color={iconColor} size="sm" />
      <Text>{text}</Text>
    </Container>
  </li>
);

interface DescriptionPanelProps {
  title: string;
  label: string;
  children: ReactNode;
}
const DescriptionPanel = ({
  title,
  label,
  children
}: DescriptionPanelProps): ReactElement => (
  <Container orientation="vertical">
    <Spacer />
    <Label>{label}</Label>
    <Spacer size="sm" />
    <Panel color="muted" orientation="vertical" fillWidth alignItems="start">
      <Title type="h6" size="sm">
        {title}
      </Title>
      {children}
    </Panel>
  </Container>
);

const DevDescriptionPanel = (): ReactElement => (
  <DescriptionPanel label="Current selection" title="Development">
    <Ul>
      <Li text="Great for smallesssr workloads and starter projects" />
      <Li text="Up to 1 TB storage and 16 GB total memory" />
      <Li text="Competitive monthly pricing" />
    </Ul>
  </DescriptionPanel>
);

const ProdDescriptionPanel = (): ReactElement => (
  <DescriptionPanel title="Production" label="Available upgrade">
    <Ul>
      <Li iconName="check" text="Recommended for production workloads" />
      <Li iconName="check" text="Unlimited storage with 24 GB+ total memory" />
      <Li iconName="check" text="Usage based pricing" />
      <Li iconName="check" text="Dedicated CPU" />
      <Li iconName="check" text="Replicated across 3AZs" />
    </Ul>
  </DescriptionPanel>
);
interface UpgradeDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const UpgradeDialog = ({
  open,
  onCancel,
  onConfirm
}: UpgradeDialogProps): ReactElement => (
  <ConfirmationDialog
    open={open}
    title="Upgrade to production"
    primaryActionLabel="Upgrade to Production"
    onConfirm={onConfirm}
    onCancel={onCancel}
    showClose
  >
    <Text color="muted">
      Upgrading will create and populate a new Production tier service and may
      take up to one hour to complete. New data inserted during this time will
      not be migrated and should be manually copied.
    </Text>
    <DevDescriptionPanel />
    <ProdDescriptionPanel />
  </ConfirmationDialog>
);
