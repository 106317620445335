import { ReactElement } from 'react';
import { TextField } from '@clickhouse/click-ui';
import { useVisited } from 'src/components/CreateTableView/useVisited';
import { OptionProps } from 'src/components/CreateTableView/ColumnOptions/types';
import { EditColumn } from 'shared/src/tableSchema';

interface Props extends OptionProps {
  columns: Array<EditColumn>;
}
function ColumnName({ column, columns, change }: Props): ReactElement {
  const [nameVisited, visitName] = useVisited();
  const hasName = column !== null && column.name.length > 0;

  const isDuplicateName =
    hasName &&
    columns.some(
      (otherCol) => otherCol.name === column?.name && otherCol !== column
    );
  return (
    <TextField
      error={
        (!column.removed && nameVisited && !column.name) || isDuplicateName
      }
      disabled={column.removed}
      onBlur={visitName}
      value={column.name}
      placeholder="Enter column name"
      onChange={(name: string): void => {
        change({ name });
      }}
      autoFocus={column.autoFocus ?? false}
      className="fs-exclude"
    />
  );
}

export default ColumnName;
