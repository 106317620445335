import type { ReactNode } from 'react';
import { DashboardConfig } from 'shared/src/types/dashboard';
import { FilterConfigFlyout } from 'src/components/Dashboards/DashboardView/FilterConfigFlyout';
import { ObjectConfigFlyout } from 'src/components/Dashboards/DashboardView/ObjectConfigFlyout';
import { useDashboardFlyoutType } from 'src/state/dashboard';

interface DashboardFlyoutProps {
  config: DashboardConfig;
  dashboardId: string;
  editable: boolean;
}

export function DashboardFlyout({
  config,
  dashboardId,
  editable
}: DashboardFlyoutProps): ReactNode {
  const flyoutType = useDashboardFlyoutType();

  switch (flyoutType) {
    case 'filterConfig':
      return <FilterConfigFlyout config={config} dashboardId={dashboardId} />;
    case 'objectConfig':
      if (editable) {
        return <ObjectConfigFlyout />;
      }

      return null;
    default:
      return null;
  }
}
