import { atom, useAtom } from 'jotai';
import { useGptConsent } from 'src/lib/gpt/GptConsentHook';
import { useEffect } from 'react';

// Return type of the useGptConsentModal hook.
type UseGptConsentModalResult = {
  isGptConsentModalOpen: boolean;
  hideGptConsentModal: (accepted: boolean) => void;
  /**
   * Show the GPT consent modal.
   * @returns Promise<boolean> - whether the user accepted the consent.
   */
  showGptConsentModal: () => Promise<boolean>;
};

type CloseCallbacks = {
  onClose: (accepted: boolean) => void;
};

const isOpenAtom = atom(false);
const closeCallbacksAtom = atom<CloseCallbacks>({
  onClose: () => {}
});

/**
 * This hook handles visibility state of the GPT consent modal.
 * @returns UseGptConsentModalResult
 */
export function useGptConsentModal(): UseGptConsentModalResult {
  const [isGptConsentModalOpen, setGptConsentModalOpen] = useAtom(isOpenAtom);
  const [onClose, setOnClose] = useAtom(closeCallbacksAtom);
  const { hasConsent } = useGptConsent();

  useEffect(() => {
    if (hasConsent && isGptConsentModalOpen) {
      // If the user already gave consent (e.g. in another window), close the modal
      setGptConsentModalOpen(false);
      onClose.onClose?.(true);
    }
  }, [hasConsent, onClose, setGptConsentModalOpen, isGptConsentModalOpen]);

  /**
   * Hide the GPT consent modal
   * @param accepted - whether the user accepted the consent.
   */
  const hideGptConsentModal = (accepted: boolean): void => {
    setGptConsentModalOpen(false);
    onClose.onClose(accepted);
  };

  const showGptConsentModal = (): Promise<boolean> => {
    setGptConsentModalOpen(true);
    return new Promise((resolve) => {
      setOnClose({
        onClose: (accepted) => {
          resolve(accepted);
        }
      });
    });
  };

  return {
    isGptConsentModalOpen,
    hideGptConsentModal,
    showGptConsentModal
  };
}
