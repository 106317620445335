import { Container, ContainerProps } from '@clickhouse/click-ui';
import { useAtomValue } from 'jotai';
import { ReactElement, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useAuth } from 'src/components/auth';
import PaymentAlertModals from 'src/components/PaymentAlertModals';
import {
  useCurrentInstance,
  useCurrentOrganizationInstances
} from 'src/instance/instanceController';
import { MainSidebarContainer } from 'src/layout/GlobalSidebarStyles';
import InvitationsModal from 'src/layout/ServiceSidebar/InvitationsModal';
import { AlertsSection } from 'src/layout/ServiceSidebar/SidebarAlertsSection';
import { SidebarFooter } from 'src/layout/ServiceSidebar/SidebarFooterSection';
import { SidebarHeader } from 'src/layout/ServiceSidebar/SidebarHeaderSection';
import { SidebarMainNav } from 'src/layout/ServiceSidebar/SidebarMainSection';
import { SidebarOrgSelector } from 'src/layout/ServiceSidebar/SidebarOrgSelector';
import { MainSidebarBody } from 'src/layout/SidebarWrapper/SidebarWrapper';
import { useOrganizationStateManager } from 'src/organization/organizationState';
import { useInvitationController } from 'src/state/notification/invitationController';
import { mainSidebarOpen } from 'src/state/sidebar';
import { useUserStateManager } from 'src/user/userState';

interface ServiceSidebarProps
  extends Omit<
    ContainerProps,
    'orientation' | 'fillWidth' | 'fillHeight' | 'maxWidth'
  > {
  container: HTMLDivElement | null;
  notificationContainer: HTMLDivElement | null;
}

const ServiceSidebar = ({
  container,
  notificationContainer,
  ...props
}: ServiceSidebarProps): ReactElement | null => {
  const { logOut, currentUser } = useAuth();
  const { user } = useUserStateManager();
  const { organizations, currentOrganizationId } =
    useOrganizationStateManager();

  const currentService = useCurrentInstance();
  const currentInstances = useCurrentOrganizationInstances();

  const openSidebar = useAtomValue(mainSidebarOpen);
  const consoleOpened = useMatch('/services/:serviceId/console/*');
  const dashboardsOpened = useMatch('/services/:serviceId/dashboards/*');

  const [invitationsModalOpen, setInvitationsModalOpen] = useState(false);
  const { acceptInvitation, invitations } = useInvitationController();

  if (!currentUser) {
    return null;
  }

  return (
    <MainSidebarBody
      header={
        <SidebarHeader
          currentUser={user}
          logOut={logOut}
          numInvitations={invitations?.length}
          setInvitationsModalOpen={setInvitationsModalOpen}
          notificationContainer={notificationContainer}
        />
      }
      container={container}
      width={
        !openSidebar && (consoleOpened || dashboardsOpened) ? '0px' : '250px'
      }
    >
      <Container
        orientation="vertical"
        alignItems="start"
        fillHeight
        overflow="auto"
        isResponsive={false}
        maxWidth="100%"
        wrap="nowrap"
      >
        <MainSidebarContainer
          orientation="vertical"
          fillHeight
          isResponsive={false}
          fillWidth
          {...props}
        >
          <SidebarMainNav
            currentService={currentService}
            services={currentInstances}
          />
          <AlertsSection
            currentOrg={organizations[currentOrganizationId]}
            hasInvitations={invitations.length > 0}
            setInvitationsModalOpen={setInvitationsModalOpen}
          />
          <SidebarOrgSelector
            currentOrg={organizations[currentOrganizationId]}
            orgs={Object.values(organizations)}
          />
          <SidebarFooter />
          <PaymentAlertModals />
        </MainSidebarContainer>
        <InvitationsModal
          acceptInvitation={acceptInvitation}
          invitations={invitations}
          invitationsModalOpen={invitationsModalOpen && invitations.length > 0}
          onClose={(): void => setInvitationsModalOpen(false)}
        />
      </Container>
    </MainSidebarBody>
  );
};

export default ServiceSidebar;
