import { OpenapiKey, OpenapiKeyRole } from '@cp/common/protocol/OpenapiKey';
import capitalize from 'lodash/capitalize';
import { ReactElement, useState } from 'react';
import {
  Text,
  Select,
  Spacer,
  Container,
  Icon,
  IconButton,
  Button,
  Dialog
} from '@clickhouse/click-ui';
import { NewKeyInfo, useApiKeys } from 'src/apiKey/apiKeyController';
import {
  useCurrentOrganization,
  useOrganizationRole
} from 'src/organization/organizationState';
import CreateKeyModal from 'src/pages/ApiKeysPage/CreateKeyModal';
import NewKeyInfoComponent from 'src/pages/ApiKeysPage/NewKeyInfo';

type ShowKeyModalProps = {
  newKeyInfo: NewKeyInfo;
  onClose: () => void;
};

function ShowKeyModal({
  newKeyInfo,
  onClose
}: ShowKeyModalProps): ReactElement {
  return (
    <Dialog open modal>
      <Dialog.Content title="Review your key" onClose={onClose} showClose>
        <NewKeyInfoComponent newKeyInfo={newKeyInfo} />
        <Container justifyContent="end" gap="xs">
          <Button onClick={onClose}>I've copied it!</Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
}

type SelectedKeyProps = {
  openApiKey: OpenapiKey;
  onRemove: () => void;
};

function SelectedKey({ openApiKey, onRemove }: SelectedKeyProps): ReactElement {
  const roles = openApiKey.roles
    .map((r) => capitalize(r.toLowerCase()))
    .join(', ');
  const last4CharsKey = `*********${openApiKey.keySuffix}`;

  return (
    <Container
      gap="sm"
      data-testid={`selected-open-api-key-${openApiKey.keySuffix}`}
    >
      <Container
        orientation="vertical"
        justifyContent="center"
        alignItems="center"
        maxWidth="24px"
      >
        <Icon name="keys" size="lg" />
      </Container>
      <Container orientation="vertical">
        <Text size="md">{openApiKey.name}</Text>
        <Container gap="xs">
          <Text color="default" size="sm">
            {last4CharsKey}
          </Text>
          <Text color="muted" size="sm">
            ({roles})
          </Text>
        </Container>
      </Container>
      <IconButton
        icon="cross"
        onClick={onRemove}
        data-testid={`selected-open-api-key-${openApiKey.keySuffix}-remove-btn`}
      />
    </Container>
  );
}

type QueryEndpointsKeysFormProps = {
  endpointKeys: Array<string>;
  onAddKey: (key: string) => void;
  onRemoveKey: (key: string) => void;
  error?: string;
};

export function QueryEndpointsKeysForm({
  endpointKeys,
  onAddKey,
  onRemoveKey,
  error
}: QueryEndpointsKeysFormProps): ReactElement {
  const currentOrg = useCurrentOrganization();
  const role = useOrganizationRole(currentOrg?.id ?? '');
  const {
    loading: isLoadingKeys,
    keys,
    newKeyInfo,
    clearNewKeyInfo,
    createOrEditKey,
    openCreateKeyModal,
    closeCreateKeyModal,
    createKeyModalOpen: isCreateKeyModalOpen
  } = useApiKeys({ organizationId: currentOrg?.id ?? '' });

  const [keyDetailsOpen, setKeyDetailsOpen] = useState(false);

  const keysById = keys.reduce(
    (acc, key) => {
      acc[key.id] = key;
      return acc;
    },
    {} as Record<string, OpenapiKey>
  );

  const selectedKeys: Array<OpenapiKey> = keys
    .filter((key) => endpointKeys.includes(key.id))
    .map((key) => keysById[key.id]);

  const nonSelectedKeys = keys.filter((key) => !endpointKeys.includes(key.id));

  const onCreateKey = async (options: {
    customExpiry: string;
    expiry: string;
    name: string;
    role: OpenapiKeyRole;
  }): Promise<void> => {
    try {
      await createOrEditKey(options);
      setKeyDetailsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const showNewKeyModal = newKeyInfo && keyDetailsOpen;
  const showCreateKeyModal = !showNewKeyModal && isCreateKeyModalOpen && role;

  return (
    <Container gap="sm" orientation="vertical">
      {showNewKeyModal && (
        <ShowKeyModal
          newKeyInfo={newKeyInfo}
          onClose={(): void => {
            setKeyDetailsOpen(false);
            clearNewKeyInfo();
          }}
        />
      )}

      {showCreateKeyModal && (
        <CreateKeyModal
          editingKey={null}
          onClose={closeCreateKeyModal}
          onSubmit={onCreateKey}
          open={isCreateKeyModalOpen}
        />
      )}

      <Text color="muted">
        Your endpoint will be accessed through an API key
      </Text>
      <Container gap="sm">
        <Select
          defaultValue=""
          value=""
          onSelect={(key) => {
            onAddKey(key);
          }}
          data-testid="select-api-key-dropdown"
          placeholder="Select API key"
          disabled={isLoadingKeys || nonSelectedKeys.length === 0}
          error={error}
        >
          {nonSelectedKeys.map((key) => (
            <Select.Item key={key.id} value={key.id} data-testid={key.id}>
              {key.name}
            </Select.Item>
          ))}
        </Select>
        <Button
          type="secondary"
          disabled={isCreateKeyModalOpen}
          onClick={openCreateKeyModal}
        >
          New API Key
        </Button>
      </Container>
      <Spacer size="sm" />
      {selectedKeys.map((key, idx) => (
        <SelectedKey
          key={`key-${idx}`}
          openApiKey={key}
          onRemove={() => {
            onRemoveKey(key.id);
          }}
        />
      ))}
    </Container>
  );
}
