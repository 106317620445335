import { ReactElement } from 'react';
import { Spacer, Text, Container, Switch } from '@clickhouse/click-ui';
import {
  Subsection,
  SubsectionControls,
  SubsectionDescription,
  SubsectionTitle
} from 'src/lib/pageElements';
import { useGptConsent } from 'src/lib/gpt/GptConsentHook';
import { useIsInlineCodeCompletionEnabled } from 'src/lib/gpt/GptFeatureFlagHook';
import { useGptConsentModal } from 'src/lib/gpt/GptConsentModalHook';
import GptConsentModal from 'src/components/QueryView/GptConsentModal';
import { useUserPreferencesController } from 'src/user/userPreferencesController';

export function GptAccessSection(): ReactElement | null {
  const { hasConsent, acceptConsent } = useGptConsent();
  const { showGptConsentModal } = useGptConsentModal();
  const isInlineCodeCompetionFeatureFlagEnabled =
    useIsInlineCodeCompletionEnabled();
  const { isGptInlineCodeCompletionEnabled, toggleGptInlineCodeCompletion } =
    useUserPreferencesController();

  return (
    <>
      <Subsection
        justifyContent="space-between"
        data-testid="gpt-access-section"
      >
        <SubsectionDescription>
          <SubsectionTitle>Generative AI</SubsectionTitle>
          <Spacer size="sm" />
          <Text color="muted">
            Use LLM providers such as OpenAI and Amazon Bedrock to build or fix
            queries.
          </Text>
        </SubsectionDescription>

        <SubsectionControls>
          <Spacer size="md" />
          <Container gap="md" orientation="vertical">
            <Switch
              checked={hasConsent}
              dir="end"
              label="Enable Generative AI features"
              orientation="horizontal"
              onCheckedChange={(value: boolean): void => {
                if (value) {
                  showGptConsentModal().catch(console.error);
                } else {
                  acceptConsent(false).catch(console.error);
                }
              }}
            />
            {isInlineCodeCompetionFeatureFlagEnabled && (
              <Switch
                checked={!!isGptInlineCodeCompletionEnabled}
                dir="end"
                label="Enable Inline Code Completion"
                orientation="horizontal"
                disabled={!hasConsent}
                onCheckedChange={(value: boolean): void => {
                  toggleGptInlineCodeCompletion(value).catch(console.error);
                }}
              />
            )}
          </Container>
        </SubsectionControls>
      </Subsection>
      <GptConsentModal />
    </>
  );
}
