import {
  Instance,
  InstanceMaintenanceWindow,
  MaintenanceWindowStatus,
  MaintenanceWindowInfoMetadata
} from '@cp/common/protocol/Instance';
import { MILLIS_PER_MINUTE } from '@cp/common/utils/DateTimeUtils';

/**
 * Return true if the maintenance window haven't ended yet,
 * and it already began or about to begin in the next notificationMinutes.
 */
export function isMaintenanceWindowNotificationShown(
  maintenanceWindow: InstanceMaintenanceWindow,
  isMaintenanceInProgress = false
): boolean {
  const now = Date.now();
  const millisBeforeStart = maintenanceWindow.notificationMinutes * MILLIS_PER_MINUTE;
  return (
    (maintenanceWindow.endMaintenanceTime > now || isMaintenanceInProgress) &&
    maintenanceWindow.startMaintenanceTime < now + millisBeforeStart
  );
}

export function getMaintenanceWindows(instances: Array<Instance>): Array<MaintenanceWindowInfoMetadata> {
  const windows: Array<MaintenanceWindowInfoMetadata> = [];
  for (const instance of instances) {
    for (const maintenanceWindow of instance.maintenanceWindows) {
      // Assuming maintenanceWindows to be sorted by start time.
      if (isMaintenanceWindowNotificationShown(maintenanceWindow, !!instance.activeMaintenanceKind)) {
        // Found a maintenance window that haven't ended yet and begins in the next notificationMinutes.
        windows.push({ ...maintenanceWindow, instanceName: instance.name });
        break;
      }
    }
  }
  return windows;
}

export function getMaintenanceStatus(instance: Instance): MaintenanceWindowStatus {
  const isMaintenanceInProgress = !!instance.activeMaintenanceKind;
  const window = instance.maintenanceWindows.find((w) => isMaintenanceWindowNotificationShown(w));

  // prettier-ignore
  return isMaintenanceInProgress
    ? 'in-progress'
    : !window
      ? undefined : window.initiateWhenStopped? 'available' : 'scheduled';
}
