import {
  Button,
  Container,
  Select,
  Text,
  TextAreaField,
  TextField,
  Title
} from '@clickhouse/click-ui';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_SUBJECT_LENGTH,
  SUPPORT_CASE_CATEGORY,
  SUPPORT_CASE_PRIORITY_TO_DESCRIPTION_MAP,
  SupportCasePriority
} from '@cp/common/protocol/Support';
import { assertTruthy } from '@cp/common/utils/Assert';
import {
  isSupportCasePriority,
  validateCommaSeparatedEmails
} from '@cp/common/utils/ValidationUtils';
import { ReactElement, useCallback, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import FileUploadElement from 'src/components/FileUploadElement/FileUploadElement';
import { MediumWidthContainer } from 'src/components/MediumWidthContainer';
import { navigateTo } from 'src/components/NavigationProvider/navigationEmitter';
import { useCurrentOrganizationInstances } from 'src/instance/instanceController';
import { useInstanceStateManager } from 'src/instance/instanceState';
import SupportLayout from 'src/layout/SupportLayout';
import { routes } from 'src/lib/routes';
import { useParams } from 'src/lib/routes/useParams';
import { useCurrentOrganizationOrThrow } from 'src/organization/organizationState';
import useCreateCaseEvent from 'src/support/controller/useCreateCaseEvent';
import { useSupportCases } from 'src/support/controller/useSupportCases';

interface FormErrors {
  additionalEmailsError?: string;
}

function SupportNewCasePage(): ReactElement {
  const supportCases = useSupportCases();
  const { serviceId } = useParams();
  const organization = useCurrentOrganizationOrThrow();
  const { instances } = useInstanceStateManager();
  const currentInstances = useCurrentOrganizationInstances();
  const [searchParams] = useSearchParams();

  const initialInstanceId =
    searchParams.get('instanceId') &&
    currentInstances.some(
      (instance) => instance.id === searchParams.get('instanceId')
    )
      ? searchParams.get('instanceId') || ''
      : 'n/a';
  const [instanceId, setInstanceId] = useState(initialInstanceId);
  const [formErrors, SetFormErrors] = useState<FormErrors>({});
  const selectedServiceTier = instanceId
    ? currentInstances.find((instance) => instance.id === instanceId)
        ?.instanceTier
    : undefined;

  const isPriorityDisabled = useCallback(
    (priority: SupportCasePriority): boolean => {
      // TODO (#10669): remove the use of organizationBo.supportOnly
      return (
        priority !== 'SEV_3' &&
        ['Development', undefined].includes(selectedServiceTier) &&
        !organization.supportOnly &&
        organization.billingStatus !== 'SUPPORT_ONLY'
      );
    },
    [selectedServiceTier, organization]
  );

  const isCategorySelected = (category: string | null): boolean => {
    return (
      !!category &&
      ([...SUPPORT_CASE_CATEGORY].includes(category) ||
        (category === 'Other' && categoryOther.length > 0))
    );
  };

  const priorityOptions: Array<{ label: string; value: SupportCasePriority }> =
    Object.entries(SUPPORT_CASE_PRIORITY_TO_DESCRIPTION_MAP)
      .filter(
        (entry) =>
          // Since SEV_1 < SEV_2, lexicographically speaking, we can use >= to determine if a specific priority is allowed.
          isSupportCasePriority(entry[0]) &&
          entry[0] >= organization.restrictions.maxCasePriority &&
          !isPriorityDisabled(entry[0])
      )
      .flatMap((entry) => {
        assertTruthy(isSupportCasePriority(entry[0]), 'Invalid case priority');
        return {
          label: entry[1],
          value: entry[0]
        };
      });

  const categoryOptions = [...SUPPORT_CASE_CATEGORY].map((cat) => ({
    label: cat,
    value: cat
  }));
  categoryOptions.push({ label: 'Other', value: 'Other' });
  const [priority, setPriority] = useState(
    searchParams.get('severity') ?? priorityOptions[0].value
  );
  const [subject, setSubject] = useState(searchParams.get('subject') ?? '');
  const [subjectVisited, setSubjectVisited] = useState(false);
  const [description, setDescription] = useState(
    searchParams.get('description') ?? ''
  );
  const [category, setCategory] = useState(searchParams.get('category'));
  const [categoryOther, setCategoryOther] = useState('');
  const [otherCategoryVisited, setOtherCategoryVisited] = useState(false);
  const [descriptionVisited, setDescriptionVisited] = useState(false);
  const [additionalCommaSeparatedEmails, setAdditionalCommaSeparatedEmails] =
    useState('');
  const ref = useRef<HTMLInputElement>(null);

  const location = useLocation();

  const {
    progress,
    status,
    fileList,
    onAttachmentChange,
    createCase,
    isLoading
  } = useCreateCaseEvent();

  const onCancel = (): void => {
    if (location.key !== 'default') {
      navigateTo(-1);
    } else {
      navigateTo(routes.support({ serviceId }));
    }
  };

  const validateFormData = (): void => {
    const additionalEmailsError = validateCommaSeparatedEmails(
      additionalCommaSeparatedEmails
    );
    SetFormErrors({ ...formErrors, additionalEmailsError });
  };

  const onConfirm = (): void => {
    const instance = instances[instanceId];
    const additionalEmails =
      additionalCommaSeparatedEmails.length > 0
        ? additionalCommaSeparatedEmails.split(',')
        : [];
    const caseCategory =
      category === 'Other' ? `Other - ${categoryOther}` : category;
    assertTruthy(!!caseCategory, 'Case category can never be empty or null');

    createCase(
      organization.id,
      priority as SupportCasePriority,
      subject,
      description,
      caseCategory,
      additionalEmails,
      instance
    )
      .then((response) => {
        if (response && response.caseId) {
          navigateTo(
            routes.supportCase({ serviceId, caseId: response.caseId })
          );
        } else {
          console.error('Unable to get caseId');
          // Another toast will catch the error in useCreateCaseEvent
        }
      })
      .catch(console.error);
  };
  const isDisabled =
    !isSupportCasePriority(priority) ||
    isPriorityDisabled(priority) ||
    subject.length === 0 ||
    description.length === 0 ||
    !isCategorySelected(category) ||
    typeof validateCommaSeparatedEmails(additionalCommaSeparatedEmails) ===
      'string' ||
    isLoading;

  if (!priorityOptions.some((option) => option.value === priority)) {
    setPriority(priorityOptions[0].value);
  }

  return (
    <SupportLayout
      supportCases={supportCases.data}
      isLoading={supportCases.isLoading}
      error={supportCases.error}
    >
      <MediumWidthContainer
        orientation="vertical"
        padding="lg"
        gap="md"
        alignItems="start"
      >
        <Title type="h2">Open new case</Title>
        <Container
          padding="none"
          gap="lg"
          orientation="vertical"
          alignItems="start"
          fillWidth
        >
          <Select
            label="Cloud service"
            value={instanceId}
            onSelect={setInstanceId}
          >
            <Select.Item value="n/a">N/A</Select.Item>
            {currentInstances.map((instance) => (
              <Select.Item key={instance.id} value={instance.id}>
                {instance.name}
              </Select.Item>
            ))}
          </Select>
          <Select
            label="Priority"
            value={priority}
            onSelect={setPriority}
            options={priorityOptions}
            data-testid="support-case-creation-priority-selector"
          />
          <Container alignItems="end" gap="sm">
            <Select
              label="Category"
              value={category ?? ''}
              onSelect={setCategory}
              options={categoryOptions}
              data-testid="support-case-creation-category-selector"
            />
            {category === 'Other' && (
              <Container>
                <TextField
                  ref={ref}
                  value={categoryOther}
                  onChange={setCategoryOther}
                  error={otherCategoryVisited && categoryOther.length === 0}
                  onBlur={() => setOtherCategoryVisited(true)}
                  data-testid="support-case-creation-other-category-input"
                />
              </Container>
            )}
          </Container>
          <TextField
            label="Subject"
            value={subject}
            onChange={setSubject}
            maxLength={MAX_SUBJECT_LENGTH}
            error={
              subjectVisited && subject.length === 0
                ? 'Please enter a subject'
                : undefined
            }
            onBlur={(): void => setSubjectVisited(true)}
          />
          <TextAreaField
            label="Description"
            value={description}
            onChange={setDescription}
            maxLength={MAX_DESCRIPTION_LENGTH}
            error={
              descriptionVisited && description.length === 0
                ? 'Please enter a detailed description'
                : undefined
            }
            onBlur={(): void => setDescriptionVisited(true)}
          />
          <FileUploadElement
            onChange={onAttachmentChange}
            status={status}
            progress={progress}
            fileList={fileList}
          />
          <Container gap="md" fillWidth orientation="vertical">
            <TextField
              label="Notify others"
              placeholder="Email address"
              value={additionalCommaSeparatedEmails}
              onChange={(value: string) => {
                setAdditionalCommaSeparatedEmails(value);
                if (value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}/) != null) {
                  validateFormData();
                }
              }}
              onMouseOutCapture={() => validateFormData()}
              onMouseLeave={() => {
                validateFormData();
              }}
              onBlur={() => validateFormData()}
              error={formErrors.additionalEmailsError}
              type="email"
            />
            <Text size="xs">Separate multiple emails by comma</Text>
          </Container>
          <Container justifyContent="end" gap="md" isResponsive={false}>
            <Button type="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              disabled={isDisabled}
              loading={isLoading}
              data-testid="support-new-case-page-confirm-button"
            >
              Open case
            </Button>
          </Container>
        </Container>
      </MediumWidthContainer>
    </SupportLayout>
  );
}

export default SupportNewCasePage;
