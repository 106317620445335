import { ReactElement } from 'react';

import { css, SerializedStyles, Theme } from '@emotion/react';
import { Container, ContainerProps } from '@clickhouse/click-ui';

import { alignCenter } from 'src/lib/utility-styles';

const elementStyle = (): SerializedStyles => css`
  padding: 0.5rem 0;
`;

// hide border for the last child
const withBorder = (theme: Theme): SerializedStyles => css`
  border-bottom: 1px solid ${theme.global.color.stroke.default};
  &:last-child {
    border-bottom: none;
  }
`;

interface ElementProps extends ContainerProps {
  hasBorder?: boolean;
}
export const LineContainer = (props: ElementProps): ReactElement => {
  return (
    <Container
      css={[elementStyle, alignCenter, props.hasBorder && withBorder]}
      justifyContent="space-between"
      alignItems="start"
      gap="sm"
      fillWidth
      minWidth="fit-content"
      {...props}
    />
  );
};
