export function emptyAsyncIterable<T>(): AsyncIterable<T> {
  return {
    [Symbol.asyncIterator]() {
      return {
        next: async () => {
          return { value: undefined, done: true };
        }
      };
    }
  };
}

export function emptyIterable<T>(): Iterable<T> {
  return {
    [Symbol.iterator]() {
      return {
        next: () => {
          return { value: undefined, done: true };
        }
      };
    }
  };
}

export function rangeIterator(low: number, high: number): Iterable<number> {
  return {
    *[Symbol.iterator]() {
      for (let i = low; i < high; ++i) {
        yield i;
      }
    }
  };
}
