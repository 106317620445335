import { useCallback } from 'react';
import { useTheme } from '@emotion/react';
import { DataAxisConfig } from 'src/components/primitives/lib/Charts/ChartUtils/axis';
import { XYSeriesDescriptor } from '@clickhouse/viz-house';

type UseSeriesColorsResult = (config: DataAxisConfig, seriesIndex: number) => Partial<XYSeriesDescriptor>;

export function useSeriesColors(): UseSeriesColorsResult {
  const theme = useTheme();
  const colors = theme.colors.chart.values;

  /**
   * Gets the color and fillColor for a data series based on its configuration and index.
   * @param {DataAxisConfig} config - The configuration object for the data axis.
   * @param {number} seriesIndex - The 0-based series number.
   * @returns {Partial<XYSeriesDescriptor>} An object containing color and fillColor properties.
   */
  const getSeriesColors = useCallback(
    (config: DataAxisConfig, seriesIndex: number): Partial<XYSeriesDescriptor> => {
      return {
        color: config.color || colors[seriesIndex % colors.length],
        fillColor: [
          [0, (config.color || colors[seriesIndex % colors.length]) + 'CC'],
          [1, (config.color || colors[seriesIndex % colors.length]) + '00']
        ]
      };
    },
    [colors]
  );

  return getSeriesColors;
}
