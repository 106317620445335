import { useCallback, useEffect, useRef, useState } from 'react';

import { Column, ColumnResizeHandler, RowElement } from 'primitives/lib/Spreadsheet/types';
import { Grid } from 'react-virtualized';

const DEFAULT_COLUMN_SIZE = 100;

const minColumnWidth = 32;
export const maxAutoResizeColumnWidthRatio = 0.6;
export const minResizeWidthBreakPoint = 800;
export const cellPadding = 2;

export function getMaxAutoResizeColumnWidth(containerWidth: number): number {
  let result = containerWidth;
  if (containerWidth >= minResizeWidthBreakPoint) {
    result = containerWidth * maxAutoResizeColumnWidthRatio;
  }
  return result;
}

interface CellHtmlElement {
  offsetWidth: number;
}

export function getMaxCellsSize(cells: CellHtmlElement[]): number {
  return Math.max(...cells.map((cell) => cell.offsetWidth)) + cellPadding;
}

export function getNewSize(
  containerWidth: number,
  cells: CellHtmlElement[],
  previousColumnWidths: number[] | undefined,
  columnWidths: number[],
  columnIndex: number
): number {
  const maxAutoResizeColumnWidth = getMaxAutoResizeColumnWidth(containerWidth);
  const cellsMax = getMaxCellsSize(cells);

  let newSize = Math.min(maxAutoResizeColumnWidth, Math.max(minColumnWidth, cellsMax));

  if (typeof previousColumnWidths !== 'undefined' && columnWidths[columnIndex] === cellsMax) {
    newSize = previousColumnWidths[columnIndex];
  }

  return newSize;
}

const defaultColumnsSize = 100;
const rowNumberSize = 72;

export function calculateInitialColumnWidths(
  containerWidth: number,
  columns: Column[],
  rows: RowElement[][]
): number[] {
  const defaultSizes = columns.map((col) => col.width || defaultColumnsSize);

  const totalUsedSpace = defaultSizes.reduce((result, width) => result + width, 0);

  const freeSpace = Math.max(containerWidth - totalUsedSpace - rowNumberSize, 0);

  if (freeSpace === 0) {
    return defaultSizes;
  }

  const maxSpaceConsumedPerColumn = columns.reduce(
    (widths, column, currentIndex) => {
      for (const row of rows) {
        const currentWidth = widths[currentIndex];
        const cellTextWidth = row[currentIndex] ? JSON.stringify(row[currentIndex]).length : currentWidth;

        widths[currentIndex] = currentWidth < cellTextWidth ? cellTextWidth : currentWidth;
      }

      return widths;
    },
    columns.map((col) => col.name.length)
  );

  const total = maxSpaceConsumedPerColumn.reduce((result, width) => result + width, 0);

  const spaceToAddPerColumn = maxSpaceConsumedPerColumn.map((width) => {
    const ratio = width / total;
    const extraSpace = freeSpace * ratio;
    return extraSpace;
  });

  const result = defaultSizes.map((width, idx) => width + spaceToAddPerColumn[idx]);

  return result;
}

export function useCalculatedColumnWidths(
  columnWidthsRef: React.MutableRefObject<number[]>,
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
  columns: Column[],
  getRow: (index: number) => RowElement[] | null,
  numRows: number
): void {
  const columnWidthsCalculatedRef = useRef(false);

  const calculateInitialWidths = useCallback(
    function calculateInitialWidths(columns: Column[]): number[] | null {
      if (!containerRef.current) {
        return null;
      }

      const rows: RowElement[][] = [];
      for (let i = 0; i < Math.min(numRows, 100); i++) {
        const row = getRow(i);
        if (row) {
          rows.push(row);
        }
      }

      if (rows.length === 0) {
        return null;
      }

      const result = calculateInitialColumnWidths(containerRef.current.offsetWidth, columns, rows);

      return result;
    },
    [getRow, numRows, containerRef]
  );

  useEffect(() => {
    const hasNonDefaultColumnWidth = columnWidthsRef.current.reduce((hasNonDefaultColumnWidth, width) => {
      return hasNonDefaultColumnWidth || width !== DEFAULT_COLUMN_SIZE;
    }, false);

    // skip initial calculation if we're using a cached value
    if (hasNonDefaultColumnWidth) {
      columnWidthsCalculatedRef.current = true;
    } else {
      if (containerRef.current && !columnWidthsCalculatedRef.current) {
        const widths = calculateInitialWidths(columns);
        if (widths) {
          columnWidthsCalculatedRef.current = true;
          columnWidthsRef.current = widths;
        }
      }
    }
  }, [calculateInitialWidths, columns, columnWidthsRef, containerRef]);
}

const minWidth = 32;

function textWidth(numChars: number): number {
  const digitWidth = 8;
  const padding = 16;
  return Math.max(minWidth, padding + numChars * digitWidth);
}

function calcRowNumberWidth(numRows: number): number {
  if (numRows === 0) {
    return minWidth;
  } else {
    const numDigits = Math.floor(Math.log10(numRows)) + 1;
    return textWidth(numDigits);
  }
}

export interface ResizeColumnState {
  isResizing: boolean;
  resize: (column: number) => (size: number) => void;
  finishResize: (column: number) => (size: number) => void;
  autoResizeColumn: (column: number) => () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  columnWidth: (index: number) => number;
}

interface UseResizableColumnsProps {
  columns: Column[];
  onColumnResize: ColumnResizeHandler | undefined;
  headerGridRef: React.RefObject<Grid>;
  gridRef: React.RefObject<Grid>;
  getRow: (index: number) => RowElement[] | null;
  numRows: number;
  showRowNumber: boolean;
  maxRowNumber: number;
  hasTotals: boolean;
}

export function useResizableColumns({
  columns,
  onColumnResize,
  headerGridRef,
  gridRef,
  getRow,
  numRows,
  showRowNumber,
  maxRowNumber,
  hasTotals
}: UseResizableColumnsProps): ResizeColumnState {
  const [isResizing, setIsResizing] = useState(false);

  const columnWidthsRef = useRef(columns.map((col) => col.width || DEFAULT_COLUMN_SIZE));
  const previousColumnWidthsRef = useRef<number[]>();
  const containerRef = useRef<HTMLDivElement>(null);

  const resize = useCallback(
    (index: number) => (newSize: number) => {
      setIsResizing(true);
      const clampedSize = Math.max(minColumnWidth, newSize);
      if (clampedSize !== columnWidthsRef.current[index] && headerGridRef.current && gridRef.current) {
        const newWidths = [...columnWidthsRef.current];
        newWidths[index] = clampedSize;
        columnWidthsRef.current = newWidths;
        headerGridRef.current && headerGridRef.current.recomputeGridSize({ columnIndex: index });
        gridRef.current && gridRef.current.recomputeGridSize({ columnIndex: index });
      }
    },
    [gridRef, headerGridRef]
  );

  const finishResize = useCallback(
    (index: number) => (newSize: number) => {
      setTimeout(() => {
        setIsResizing(false);
      }, 100);
      const clampedSize = Math.max(minColumnWidth, newSize);
      const newWidths = [...columnWidthsRef.current];
      newWidths[index] = clampedSize;
      columnWidthsRef.current = newWidths;

      const widthRecords = newWidths.map((width, i) => ({
        index: i,
        name: columns[i]?.name,
        width
      }));

      headerGridRef.current && headerGridRef.current.recomputeGridSize({ columnIndex: index });
      gridRef.current && gridRef.current.recomputeGridSize({ columnIndex: index });
      typeof onColumnResize === 'function' && onColumnResize(widthRecords);
    },
    [columns, onColumnResize, gridRef, headerGridRef]
  );

  const autoResizeColumn = useCallback(
    (columnIndex: number) => () => {
      if (containerRef.current) {
        // remove style.width from cells, measure their unconstrained width, & resize column to maximum
        const cellsList = containerRef.current.querySelectorAll<HTMLElement>(`*[data-column="${columnIndex}"]`);
        const cells = [...cellsList];

        const oldSettings = cells.map((cell) => ({
          width: cell.style.width,
          position: cell.style.position
        }));

        for (const cell of cells) {
          cell.style.width = 'auto';
          cell.style.position = 'absolute';
        }

        const newSize = getNewSize(
          containerRef.current.offsetWidth,
          cells,
          previousColumnWidthsRef.current,
          columnWidthsRef.current,
          columnIndex
        );

        if (!previousColumnWidthsRef.current) {
          previousColumnWidthsRef.current = [...columnWidthsRef.current];
        }

        cells.forEach((cell, i) => {
          cell.style.width = oldSettings[i].width;
          cell.style.position = oldSettings[i].position;
        });

        finishResize(columnIndex)(newSize);
      }
    },
    [finishResize]
  );

  useCalculatedColumnWidths(columnWidthsRef, containerRef, columns, getRow, numRows);

  let rowNumberWidth = calcRowNumberWidth(maxRowNumber);
  if (hasTotals) {
    const totalWidth = textWidth('Totals'.length);
    rowNumberWidth = Math.max(rowNumberWidth, totalWidth);
  }

  useEffect(() => {
    if (showRowNumber && headerGridRef.current && gridRef.current) {
      headerGridRef.current && headerGridRef.current.recomputeGridSize({ columnIndex: 0 });
      gridRef.current && gridRef.current.recomputeGridSize({ columnIndex: 0 });
    }
  }, [rowNumberWidth, showRowNumber, gridRef, headerGridRef]);

  const columnWidth = (index: number): number => {
    if (showRowNumber) {
      if (index === 0) {
        return rowNumberWidth;
      } else {
        return columnWidthsRef.current[index - 1] || DEFAULT_COLUMN_SIZE;
      }
    } else {
      return columnWidthsRef.current[index] || DEFAULT_COLUMN_SIZE;
    }
  };

  return {
    isResizing,
    resize,
    finishResize,
    autoResizeColumn,
    containerRef,
    columnWidth
  };
}
