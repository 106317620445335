import { DataWarehouse } from '@cp/common/protocol/DataWarehouse';
import { Instance } from '@cp/common/protocol/Instance';
import { atom, useAtom } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback } from 'react';

export interface DataWarehouseWithInstances extends DataWarehouse {
  instances: Array<Instance>;
}

interface WarehouseStateAtomType {
  warehouses: Record<string, DataWarehouse>;
  isDataWarehouseStateInitialized: boolean;
}

interface UseDataWarehouseStateManagerReturnType extends WarehouseStateAtomType {
  setDataWarehouses: (dataWarehouses: Array<DataWarehouse>) => void;
  updateDataWarehouse: (dataWarehouse: DataWarehouse) => void;
}

const dataWarehouseStateAtom = atom<WarehouseStateAtomType>({
  warehouses: {},
  isDataWarehouseStateInitialized: false
});

function convertWarehouseListToMap(warehouses: Array<DataWarehouse>): Record<string, DataWarehouse> {
  return Object.fromEntries(warehouses.map((warehouse) => [warehouse.id, warehouse]));
}

export const useDataWarehouseStateManager = (): UseDataWarehouseStateManagerReturnType => {
  const [{ warehouses, isDataWarehouseStateInitialized }, setWarehouses] = useAtom(dataWarehouseStateAtom);

  const setDataWarehouses = useCallback(
    (dataWarehouses: Array<DataWarehouse>): void => {
      setWarehouses({
        warehouses: convertWarehouseListToMap(dataWarehouses),
        isDataWarehouseStateInitialized: true
      });
    },
    [setWarehouses]
  );

  const updateDataWarehouse = useCallback(
    (dataWarehouse: DataWarehouse) => {
      setWarehouses(({ warehouses: warehouseProp, ...props }) => {
        const newWarehouses = Object.values(cloneDeep(warehouseProp));
        const warehouseIndex = newWarehouses.findIndex((warehouse) => warehouse.id === dataWarehouse.id);
        newWarehouses[warehouseIndex] = dataWarehouse;
        return {
          warehouses: convertWarehouseListToMap(newWarehouses),
          ...props
        };
      });
    },
    [setWarehouses]
  );

  return {
    warehouses,
    isDataWarehouseStateInitialized,
    setDataWarehouses,
    updateDataWarehouse
  };
};
