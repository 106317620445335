import {
  Button,
  Container,
  Dialog,
  Separator,
  Spacer,
  Text,
  TextAreaField
} from '@clickhouse/click-ui';
import { ReactElement, useState } from 'react';
import useRequestIntegration from 'src/integrations/controller/useRequestIntegration';

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const RequestIntegrationDialog = ({
  open,
  onOpenChange: onOpenChangeProp
}: Props): ReactElement => {
  const [value, onChange] = useState('');
  const onOpenChange = (open: boolean): void => {
    onOpenChangeProp(open);
    if (!open) {
      onChange('');
    }
  };

  const onClose = (): void => {
    onOpenChange(false);
  };

  const { loading, sendRequestIntegration } = useRequestIntegration(onClose);

  return (
    <Dialog modal open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        title="Request new integration"
        data-testid="request-integration-dialog"
        showClose
      >
        <Text size="md">
          Please indicate the integration that you’d like to see included and a
          brief summary of how you’d like to use it.
        </Text>
        <Spacer />
        <TextAreaField
          label="Request"
          placeholder="Integration name and description"
          value={value}
          onChange={onChange}
          rows={10}
          data-testid="request-integration-input"
        />
        <Separator size="xl" />
        <Container justifyContent="end" gap="xs">
          <Dialog.Close label="Close" onClick={onClose} />
          <Button
            loading={loading}
            disabled={value.length === 0 || loading}
            fillWidth={false}
            data-testid="submit-request-integration-button"
            onClick={sendRequestIntegration(value)}
          >
            Request integration
          </Button>
        </Container>
      </Dialog.Content>
    </Dialog>
  );
};
