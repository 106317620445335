import React from 'react';
import { SearchField, SearchFieldProps } from '@clickhouse/click-ui';
import { marginStyle } from 'global-styles';
import { css } from '@emotion/react';
import ReloadMetadataButton from 'src/components/TableView/TableList/ReloadMetadataButton';

interface Props extends SearchFieldProps {
  enableUnifiedConsole: boolean;
  refreshBtnClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const searchContainer = css`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 32px;
  gap: 10px;
  width: inherit;
  padding: 1rem;
`;

function TableListSearch({
  enableUnifiedConsole,
  refreshBtnClick,
  ...props
}: Props): React.ReactElement {
  if (enableUnifiedConsole) {
    return (
      <div
        css={marginStyle('1rem')}
        data-testid="tables-sidebar-search-container"
      >
        <SearchField
          {...props}
          data-testid="tables-sidebar-search-field"
          className="fs-exclude"
        />
      </div>
    );
  }
  return (
    <div
      css={searchContainer}
      data-testid="tables-sidebar-search-container"
      className="fs-exclude"
    >
      <SearchField {...props} data-testid="tables-sidebar-search-field" />
      <ReloadMetadataButton onClick={refreshBtnClick} />
    </div>
  );
}

export default TableListSearch;
