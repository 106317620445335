import React, { ReactElement } from 'react';
import { produce } from 'immer';

import { useImportModel } from 'src/lib/dataLoading';
import { NewSampleDataImport } from 'shared/src/dataLoading';
import { S3_SAMPLES, DataSample } from 'shared/src/dataLoading/samples';
import { CardPrimary, Container, Link, Spacer } from '@clickhouse/click-ui';

type SampleDataChoiceProps = { sample: DataSample };

function SampleDataChoice({ sample }: SampleDataChoiceProps): ReactElement {
  const { importModel, setImportModel } = useImportModel();

  const selectDataSet = (): void => {
    const newImportModel = produce(importModel, (draft) => {
      const model = draft as NewSampleDataImport;
      const data = model.data || { dataset: sample.name };
      model.name = sample.name;
      data.dataset = sample.name;
      model.data = data;
      model.data.formatType = sample.fileFormat;
      model.data.fileType = sample.fileType;
    });

    setImportModel(newImportModel);
  };

  return (
    <CardPrimary
      size="sm"
      icon={sample.icon}
      title={`${sample.name}\n(${sample.size})`}
      alignContent="center"
      description={
        <div>
          {sample.description}{' '}
          <Link
            href={sample.docLink}
            target="_blank"
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            Read more
          </Link>
        </div>
      }
      onClick={selectDataSet}
      onButtonClick={selectDataSet}
      infoText="Get started"
    />
  );
}

function SampleDataSelector(): ReactElement {
  return (
    <>
      <Spacer size="sm" />
      <Container gap="lg" alignItems="stretch">
        {S3_SAMPLES.map((sample, idx) => (
          <SampleDataChoice sample={sample} key={`sample-${idx}`} />
        ))}
      </Container>
    </>
  );
}

export default React.memo(SampleDataSelector);
