import React, { ReactElement } from 'react';
import {
  CardSecondary,
  Link,
  Title,
  Text,
  type IconName,
  Spacer
} from '@clickhouse/click-ui';
import KafkaDataCard from 'src/components/ImportView/ImportTypeSelector/KafkaDataCard';
import { useTabActions } from 'src/state/tabs';
import styles from 'src/components/HomeView/styles';

interface CardData {
  pills: Array<string>;
  title: string;
  description: string;
  icon: IconName;
  url: string;
}

const cardList: Array<CardData> = [
  {
    pills: ['Tutorial'],
    title: 'Quick start tutorial',
    description:
      'Create a table, insert data from an s3 bucket, and use SQL queries to analyze data in ClickHouse',
    icon: 'speaker',
    url: 'https://clickhouse.com/docs/en/quick-start'
  },
  {
    pills: ['Data'],
    title: 'Example datasets',
    description:
      'Import any of these 15 datasets to get familiar with the ClickHouse SQL interface and discover performance capabilities',
    icon: 'data',
    url: 'https://clickhouse.com/docs/en/getting-started/example-datasets'
  },
  {
    pills: ['Reference'],
    title: 'SQL documentation',
    description:
      'Complete documentation of ClickHouse SQL including statements, syntax, DDL, functions, data types and more',
    icon: 'database',
    url: 'https://clickhouse.com/docs/en/sql-reference'
  },
  {
    pills: ['Reference'],
    title: 'ClickHouse FAQ',
    description:
      'Frequently asked questions about ClickHouse, column-oriented databases, OLAP, operations and use cases',
    icon: 'question',
    url: 'https://clickhouse.com/docs/en/faq'
  }
];

function HomeView(): ReactElement {
  const { addNewQueryTab } = useTabActions();
  const openQuery = (e: React.MouseEvent): void => {
    e.preventDefault();
    addNewQueryTab({
      title: 'Untitled query',
      type: 'query',
      saved: false
    });
  };
  return (
    <div css={styles.container}>
      <Title type="h2">ClickHouse SQL - Home</Title>
      <Spacer size="sm" />
      <Text color="muted">
        Click on a table or <Link onClick={openQuery}>create a new query</Link>
        to get started
      </Text>
      <Spacer size="md" />
      <div css={styles.cardListContainer}>
        {cardList.map(({ url, description, ...cardProps }) => (
          <a
            key={cardProps.title}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            css={styles.fitContentWidth}
          >
            <CardSecondary
              key={cardProps.title}
              icon={cardProps.icon}
              title={cardProps.title}
              badgeText={cardProps.pills[0]}
              description={description}
              infoText="Read more"
            />
          </a>
        ))}
        <KafkaDataCard type="secondary" />
      </div>
    </div>
  );
}

export default HomeView;
